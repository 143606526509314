import React, { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../common/Button'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { FormControl } from '../../../common/FormControl'
import { TextInput } from '../../../common/TextInput'
import { formatOrganisationNumber } from '../../../../helpers/organisation_number'
import { theme } from '../../../../theme/theme'
import { Checkbox } from '../../../common/Checkbox'
import { largeNumberFormattingWithSpaces } from '../../../../helpers/number'
import { InlineLink } from '../../../common/InlineLink'
import {
  DATA_PROCESSING_AGREEMENT_LINK,
  TERMS_OF_SERVICE_LINK,
  USER_AGREEMENT_LINK,
} from '../../../../constants/Links'
import { societyUpgradeSchema } from '../../../../forms/schemas/society_upgrade'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { Alert } from '../../../common/Alert'
import { AlertType } from '../../../common/Alert/alert-type'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { IPartnerModel } from '../../../../interfaces/models/partners.interfaces'
import { capitalize } from '../../../../helpers/string'

interface PaymentFormProps {
  onSubmit: () => void
  onClose: () => void
  loading: boolean
  costSummary: {
    baseCost: number
    cost: number
    discount?: number | undefined
    partner?: IPartnerModel
  }
  discountCodePartnerNameId: undefined | string
  upgradeErrored: boolean
}

export const PaymentForm = observer(
  ({
    onSubmit,
    onClose,
    loading,
    costSummary,
    discountCodePartnerNameId,
    upgradeErrored,
  }: PaymentFormProps): JSX.Element => {
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { translate } = useAppTranslation()
    const { getErrorMessage } = useFormErrorMessage()

    const getDefaultValues = useCallback((): InferType<
      typeof societyUpgradeSchema
    > => {
      const { billingEmail } = society
      return {
        billingEmail: billingEmail || '',
        acceptsLegal: false,
        discountCodePartnerNameId,
      }
    }, [society, discountCodePartnerNameId])

    const {
      control,
      handleSubmit,
      trigger,

      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyUpgradeSchema),
      defaultValues: getDefaultValues(),
    })

    useEffect(() => {
      trigger()
    }, [trigger])

    return (
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-6 md:flex-row md:gap-40">
          <div className="flex w-full flex-col gap-4">
            <p style={theme.textVariants.lead}>
              {translate('invoiceView.forms.payment.sections.invoiceDetails')}
            </p>
            <FormControl
              label={translate('common.form.labels.society')}
              name="society"
            >
              <TextInput
                onChange={() => null}
                onBlur={() => null}
                disabled
                value={society.name}
              />
            </FormControl>
            {society.organisationNumber && (
              <FormControl
                label={translate('common.form.labels.organisationNumber')}
                name="organisationNumber"
              >
                <TextInput
                  onChange={() => null}
                  onBlur={() => null}
                  disabled
                  value={formatOrganisationNumber(society.organisationNumber)}
                />
              </FormControl>
            )}
            <Controller
              control={control}
              render={({ field: { value, name, onChange, onBlur } }) => (
                <FormControl
                  label={translate('common.form.labels.email')}
                  name={name}
                  error={
                    errors.billingEmail && getErrorMessage(errors.billingEmail)
                  }
                >
                  <TextInput
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled
                    value={value}
                  />
                </FormControl>
              )}
              name="billingEmail"
            />
          </div>
          <div className="flex w-full flex-col gap-4">
            <p style={theme.textVariants.lead}>
              {translate('invoiceView.forms.payment.sections.orderInformation')}
            </p>
            <FormControl
              label={translate('invoiceView.forms.payment.labels.yearlyCost')}
              name="cost"
            >
              <TextInput
                onChange={() => null}
                onBlur={() => null}
                disabled
                value={`${largeNumberFormattingWithSpaces(
                  costSummary?.baseCost
                )} SEK`}
              />
            </FormControl>
            {costSummary.discount && (
              <FormControl
                label={translate('common.form.labels.discount')}
                name="cost"
                note={
                  costSummary.partner
                    ? {
                        note: translate('common.fee.discountViaString', {
                          partnerName: capitalize(costSummary.partner.name),
                        }),
                      }
                    : undefined
                }
              >
                <TextInput
                  className="text-green"
                  onChange={() => null}
                  onBlur={() => null}
                  disabled
                  value={`- ${largeNumberFormattingWithSpaces(
                    costSummary.discount
                  )} SEK`}
                />
              </FormControl>
            )}
            <FormControl
              label={translate('invoiceView.forms.payment.labels.validity')}
              name="validity"
            >
              <TextInput
                onChange={() => null}
                onBlur={() => null}
                disabled
                value={`1 ${translate(
                  'common.singularPlural.years.singular'
                ).toLowerCase()}`}
              />
            </FormControl>
          </div>
        </div>
        <div className="border-1 mt-6 border-y py-5">
          <p className="flex justify-between" style={theme.textVariants.lead}>
            <span className="flex-1">
              {translate('invoiceView.forms.payment.cost.totalCost')}
            </span>
            <span>
              {largeNumberFormattingWithSpaces(
                costSummary.cost + costSummary.cost * 0.25
              )}{' '}
              SEK
            </span>
          </p>
          <p className="mt-2 flex justify-between">
            <span className="flex-1">
              {translate('invoiceView.forms.payment.cost.vat')}
            </span>
            <span>
              {largeNumberFormattingWithSpaces(costSummary.cost * 0.25)} SEK
            </span>
          </p>
        </div>
        <div className="flex items-center">
          <Controller
            control={control}
            render={({ field: { value, name, onChange } }) => (
              <Checkbox
                name={name}
                value={value}
                type="checkbox"
                onChange={onChange}
              />
            )}
            name="acceptsLegal"
          />

          <div
            style={theme.textVariants.base}
            className="ml-2 inline md:flex md:flex-wrap md:whitespace-pre"
          >
            <span>
              {translate(
                'invoiceView.forms.payment.agreement.preUserAgreement'
              )}{' '}
            </span>
            <InlineLink
              text={translate(
                'invoiceView.forms.payment.agreement.userAgreement'
              )}
              url={USER_AGREEMENT_LINK}
              urlIsExternal
            />
            {', '}
            <InlineLink
              text={translate(
                'invoiceView.forms.payment.agreement.termsOfService'
              )}
              url={TERMS_OF_SERVICE_LINK}
              urlIsExternal
            />{' '}
            <span>
              {' '}
              {translate(
                'invoiceView.forms.payment.agreement.postUserAgreement'
              )}{' '}
            </span>
            <InlineLink
              text={translate(
                'invoiceView.forms.payment.agreement.personalDataAgreement'
              )}
              url={DATA_PROCESSING_AGREEMENT_LINK}
              urlIsExternal
            />
          </div>
        </div>

        {upgradeErrored && (
          <Alert
            text={translate('invoiceView.forms.payment.errors.upgradeErrored')}
            type={AlertType.DANGER}
          />
        )}

        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-3">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
            variant={ButtonVariant.TEXT}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid || loading || upgradeErrored}
            loading={loading}
            label={translate('common.actions.order')}
            type="submit"
          />
        </div>
      </form>
    )
  }
)
