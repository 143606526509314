import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from './useStores'
import { SocietyModel } from '../state/models/society'

export const useNonBoardMemberResidentUsersOptions = (
  society: SnapshotOut<typeof SocietyModel> | undefined
): { value: string; label: string }[] => {
  const { unitStore } = useStores()

  const boardMemberIds = society?.boardMembersList
    ? society?.boardMembersList
        .map((_member) => _member.userId)
        .filter((member) => member)
    : []
  const nominatingCommitteeMemberIds = society?.nominatingCommitteeList
    ? society?.nominatingCommitteeList
        .map((_member) => _member.userId)
        .filter((member) => member)
    : []

  const boardMemberOrNominatingCommitteeMemberIds = [
    ...boardMemberIds,
    ...nominatingCommitteeMemberIds,
  ]

  const residentUsers = unitStore.residentUsersForSociety(
    society?._id as string
  )
  const nonBoardMemberResidentUsers = residentUsers.filter(
    (_user) => !boardMemberOrNominatingCommitteeMemberIds.includes(_user._id)
  )

  const nonBoardMemberResidentUsersOptions = nonBoardMemberResidentUsers.map(
    (_user) => ({
      value: _user._id,
      label: _user.fullName,
    })
  )

  return nonBoardMemberResidentUsersOptions
}
