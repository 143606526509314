import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from './useStores'
import { useUnitsString } from './useUnitsString'
import {
  isUserAdminInSociety,
  translateRoles,
  userBoardMemberRoles,
} from '../helpers/society'
import { UserModel } from '../state/models/user'
import { SocietyModel } from '../state/models/society'

export const useUserRoleUnitsString = (
  user: SnapshotOut<typeof UserModel> | undefined,
  society: SnapshotOut<typeof SocietyModel> | undefined
): string[] => {
  const { unitStore } = useStores()
  const userUnits = user && unitStore.userUnits(user._id, society?._id)
  const unitsString = useUnitsString(userUnits || [], false)

  let userRoles: string[] = []

  if (user && isUserAdminInSociety(society, user._id)) {
    userRoles = [...userRoles, 'admin']
  }

  const _userBoardMemberRoles = user && userBoardMemberRoles(society, user._id)
  if (_userBoardMemberRoles) {
    userRoles = [...userRoles, ..._userBoardMemberRoles]
  }

  const userRoleUnit = [...translateRoles(userRoles), unitsString].filter(
    (_string) => _string
  ) as string[]
  return userRoleUnit
}
