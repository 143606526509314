import { types } from 'mobx-state-tree'

export const StatisticsModel = types.model('Statistics', {
  _id: types.identifier,
  totalUsers: types.number,
  adminUsers: types.number,
  boardUsers: types.number,
  residentUsers: types.number,
  posts: types.number,
  postLikes: types.number,
  postComments: types.number,
})
