import { AxiosResponse } from 'axios'
import { queryOptions } from '@tanstack/react-query'
import { NAdmin } from '../interfaces/services/admin.interfaces'
import { createUrl } from './helpers'
import axiosAuth from './config'

export const hasPermissions = (): Promise<
  AxiosResponse<NAdmin.NHasPermissions.IResponse>
> => {
  return axiosAuth.get<NAdmin.NHasPermissions.IResponse>(
    createUrl('/admin/has-permissions')
  )
}

export const searchUser = (
  data: NAdmin.NSearchUser.IRequestBody
): Promise<AxiosResponse<NAdmin.NSearchUser.IResponse>> => {
  return axiosAuth.post<NAdmin.NSearchUser.IResponse>(
    createUrl(`/admin/search-user`),
    data
  )
}

export const searchSociety = (
  data: NAdmin.NSearchSociety.IRequestBody
): Promise<AxiosResponse<NAdmin.NSearchSociety.IResponse>> => {
  return axiosAuth.post<NAdmin.NSearchSociety.IResponse>(
    createUrl(`/admin/search-society`),
    data
  )
}

export const searchSocietyAuditLogs = (
  id: string,
  queryParameters: NAdmin.NSearchAuditLogs.IRequestQuery
): Promise<AxiosResponse<NAdmin.NSearchAuditLogs.IResponse>> => {
  return axiosAuth.get<NAdmin.NSearchAuditLogs.IResponse>(
    createUrl(`/admin/search-society-audit-logs/${id}`, { ...queryParameters })
  )
}

export const getPendingInvoices = (): Promise<
  AxiosResponse<NAdmin.NGetPendingInvoices.IResponse>
> => {
  return axiosAuth.get<NAdmin.NGetPendingInvoices.IResponse>(
    createUrl('/admin/pending-invoices')
  )
}

export const loginAsUser = (
  data: NAdmin.NLoginAsUser.IRequestBody
): Promise<AxiosResponse<NAdmin.NLoginAsUser.IResponse>> => {
  return axiosAuth.post<NAdmin.NLoginAsUser.IResponse>(
    createUrl('/admin/login-as-user'),
    data
  )
}

export const setSocietyPartner = (
  data: NAdmin.NSetSocietyPartner.IRequestBody
): Promise<AxiosResponse<NAdmin.NSetSocietyPartner.IResponse>> => {
  return axiosAuth.post<NAdmin.NSetSocietyPartner.IResponse>(
    createUrl('/admin/set-society-partner'),
    data
  )
}

export const changeUserPassword = (
  data: NAdmin.NChangeUserPassword.IRequestBody
): Promise<AxiosResponse<NAdmin.NChangeUserPassword.IResponse>> => {
  return axiosAuth.post<NAdmin.NChangeUserPassword.IResponse>(
    createUrl('/admin/change-user-password'),
    data
  )
}

export const changeUserEmail = (
  data: NAdmin.NChangeUserEmail.IRequestBody
): Promise<AxiosResponse<NAdmin.NChangeUserEmail.IResponse>> => {
  return axiosAuth.post<NAdmin.NChangeUserEmail.IResponse>(
    createUrl('/admin/change-user-email'),
    data
  )
}

export const inactivateSociety = (
  data: NAdmin.NInactivateSociety.IRequestBody
): Promise<AxiosResponse<NAdmin.NInactivateSociety.IResponse>> => {
  return axiosAuth.post<NAdmin.NInactivateSociety.IResponse>(
    createUrl('/admin/inactivate-society'),
    data
  )
}

export const patchSociety = (
  body: NAdmin.NPatchSociety.IRequestBody
): Promise<AxiosResponse<NAdmin.NPatchSociety.IResponse>> => {
  return axiosAuth.post<NAdmin.NPatchSociety.IResponse>(
    createUrl(`/admin/patch-society`),
    body
  )
}

export const patchSocietyWebsite = (
  body: NAdmin.NPatchSocietyWebsite.IRequestBody
): Promise<AxiosResponse<NAdmin.NPatchSocietyWebsite.IResponse>> => {
  return axiosAuth.post<NAdmin.NPatchSocietyWebsite.IResponse>(
    createUrl(`/admin/patch-society-website`),
    body
  )
}

export const deleteSociety = (
  body: NAdmin.NDeleteSociety.IRequestBody
): Promise<AxiosResponse<NAdmin.NDeleteSociety.IResponse>> => {
  return axiosAuth.post<NAdmin.NDeleteSociety.IResponse>(
    createUrl(`/admin/delete-society`),
    body
  )
}

export const deleteUser = (
  body: NAdmin.NDeleteUser.IRequestBody
): Promise<AxiosResponse<NAdmin.NDeleteUser.IResponse>> => {
  return axiosAuth.post<NAdmin.NDeleteUser.IResponse>(
    createUrl(`/admin/delete-user`),
    body
  )
}

export const getSocietiesByIds = (
  body: NAdmin.NGetSocietiesByIds.IRequestBody
): Promise<AxiosResponse<NAdmin.NGetSocietiesByIds.IResponse>> => {
  return axiosAuth.post<NAdmin.NGetSocietiesByIds.IResponse>(
    createUrl(`/admin/get-societies-by-ids`),
    body
  )
}

export const upgradeSociety = (
  body: NAdmin.NUpgradeSociety.IRequestBody
): Promise<AxiosResponse<NAdmin.NUpgradeSociety.IResponse>> => {
  return axiosAuth.post<NAdmin.NUpgradeSociety.IResponse>(
    createUrl(`/admin/upgrade-society`),
    body
  )
}

export const downgradeSociety = (
  body: NAdmin.NDowngradeSociety.IRequestBody
): Promise<AxiosResponse<NAdmin.NDowngradeSociety.IResponse>> => {
  return axiosAuth.post<NAdmin.NDowngradeSociety.IResponse>(
    createUrl(`/admin/downgrade-society`),
    body
  )
}

const convertJsonBlobToCsvBlob = async (jsonBlob: Blob): Promise<Blob> => {
  const parsedResponse = JSON.parse(await jsonBlob.text())
  const json = parsedResponse.data
  const headerObject = json.find(
    (_society: object) =>
      Object.prototype.hasOwnProperty.call(_society, 'activatedPartner') &&
      Object.prototype.hasOwnProperty.call(_society, 'activatedAt') &&
      Object.prototype.hasOwnProperty.call(_society, 'rawActivatedAt')
  )
  const header = Object.keys(headerObject ?? json[0])
  const csv = [
    header.join(','),
    ...json.map((row: never) =>
      header
        .map((field) => {
          const value = row[field]
          if (value === null || value === undefined) {
            return ''
          }
          return `"${String(value).replace(/"/g, '""')}"`
        })
        .join(',')
    ),
  ].join('\r\n')
  const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  return csvBlob
}

export const downloadSocietyList = async (): Promise<void> => {
  await axiosAuth({
    url: createUrl('/admin/download-societies'),
    method: 'POST',
    responseType: 'blob',
  }).then(async (resp) => {
    const csvBlob = await convertJsonBlobToCsvBlob(resp.data)
    const href = URL.createObjectURL(csvBlob)

    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', 'activated-societies.csv')
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  })
}

export const listWebinars = async (): Promise<
  AxiosResponse<NAdmin.NGetWebinar.IResponse>
> => {
  return axiosAuth.get<NAdmin.NGetWebinar.IResponse>(
    createUrl('/admin/webinars')
  )
}

export const createWebinar = async (
  body: NAdmin.NCreateWebinar.IRequestBody
): Promise<AxiosResponse<NAdmin.NCreateWebinar.IResponse>> => {
  return axiosAuth.post<NAdmin.NCreateWebinar.IResponse>(
    createUrl('/admin/webinars'),
    body
  )
}

export const patchWebinar = async ({
  id,
  body,
}: {
  body: NAdmin.NPatchWebinar.IRequestBody
  id: string
}): Promise<AxiosResponse<NAdmin.NPatchWebinar.IResponse>> => {
  return axiosAuth.patch<NAdmin.NPatchWebinar.IResponse>(
    createUrl(`/admin/webinars/${id}`),
    body
  )
}

export const removeWebinar = async ({
  id,
}: {
  id: string
}): Promise<AxiosResponse<NAdmin.NRemoveWebinar.IResponse>> => {
  return axiosAuth.delete<NAdmin.NRemoveWebinar.IResponse>(
    createUrl(`/admin/webinars/${id}`)
  )
}

export const getWebinar = async (
  id?: string
): Promise<AxiosResponse<NAdmin.NGetWebinar.IResponse>> => {
  return axiosAuth.get<NAdmin.NGetWebinar.IResponse>(
    createUrl(`/admin/webinars/${id}`)
  )
}

export const adminQueryKeys = {
  all: [{ scope: 'admin' }] as const,

  webinars: () =>
    queryOptions({
      queryKey: [{ ...adminQueryKeys.all[0], entity: 'webinars' }] as const,
      queryFn: () => listWebinars(),
    }),

  webinar: (webinar?: string) =>
    queryOptions({
      queryKey: [
        { ...adminQueryKeys.webinars().queryKey[0], webinar },
      ] as const,
      queryFn: ({ queryKey }) => getWebinar(queryKey[0]?.webinar),
    }),
}
