import * as yup from 'yup'

export const societyPollCreateSchema = yup
  .object({
    title: yup.string().required(),
    options: yup
      .array(
        yup.object({
          text: yup.string().required(),
        })
      )
      .required(),
    societyId: yup.string().required(),
    userId: yup.string().required(),
    type: yup.string().oneOf(['per-user', 'per-unit']).required(),
  })
  .required()
