import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useParams } from 'react-router-dom'
import { queryKeys } from '../../../api/services'
import { Icon, IconChoices } from '../../../components/common/Icon'
import { useLocale } from '../../../hooks/useLocale'
import { theme } from '../../../theme/theme'
import { largeNumberFormattingWithSpaces } from '../../../helpers/number'
import { addGATracking } from '../../../helpers/attribution'
import {
  DORUNNER_UTM_CAMPAIGN,
  DORUNNER_UTM_MEDIUM,
  DORUNNER_UTM_SOURCE,
} from '../../../constants/Attribution'
import { Spinner } from '../../../components/common/Spinner'
import { ServiceNavigation } from '../ServiceNavigation'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'

const Products = (): React.ReactNode => {
  const locale = useLocale()
  const { service: provider, product } = useParams()
  const { data } = useQuery({
    ...queryKeys.Service(provider),
    throwOnError: true,
  })
  const { data: providers } = useQuery({
    ...queryKeys.providers(),
    throwOnError: true,
  })
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }

  const serviceCategory = data?.data.data.find(
    (service) => service.title.sv === product
  )

  const toPageTitle = (): string => {
    const foundProvider = providers?.data.data.find((p) => p.id === provider)
    if (foundProvider) {
      return locale === 'en' ? foundProvider.title.en : foundProvider.title.sv
    }
    return ''
  }

  const currentPageTitle = (): string => {
    if (serviceCategory) {
      return locale === 'en'
        ? serviceCategory.title.en
        : serviceCategory.title.sv
    }
    return ''
  }

  return (
    <div className="pb-4">
      <ServiceNavigation
        toPageTitle={toPageTitle()}
        currentPageTitle={currentPageTitle()}
      />
      <ul>
        {!serviceCategory && (
          <div className="grid place-items-center">
            <Spinner />
          </div>
        )}
        {serviceCategory?.services?.map((service) => {
          return (
            <li key={service.name.sv}>
              <a
                href={addGATracking(
                  service.url,
                  DORUNNER_UTM_SOURCE,
                  DORUNNER_UTM_MEDIUM,
                  DORUNNER_UTM_CAMPAIGN
                )}
                rel="noreferrer"
                target="_blank"
                className="w-full mb-2 overflow-hidden rounded-md shadow-md border block p-2"
              >
                <div className="w-full flex items-center gap-4">
                  <img
                    src={service.imageUrl}
                    alt={locale === 'en' ? service.name.en : service.name.sv}
                    className="w-10 h-10"
                  />
                  <div className="flex w-full p-2 gap-4 justify-between">
                    <div>
                      <p style={theme.textVariants.baseBold}>
                        {locale === 'en' ? service.name.en : service.name.sv}
                      </p>
                      <p style={theme.textVariants.base}>
                        {largeNumberFormattingWithSpaces(service?.price)} SEK
                      </p>
                    </div>
                    <Icon
                      flexItems="items-center"
                      icon={IconChoices.EXTERNAL_LINK_2}
                    />
                  </div>
                </div>
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export { Products }
