import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import React from 'react'
import { isUserAdminInSociety } from '../../../../../../../helpers/society'
import { useAppTranslation } from '../../../../../../../hooks/useAppTranslation'
import { useCurrentSociety } from '../../../../../../../hooks/useCurrentSociety'
import { useModal } from '../../../../../../../hooks/useModal'
import { useStores } from '../../../../../../../hooks/useStores'
import { useToastNotifications } from '../../../../../../../hooks/useToastNotification'
import { NSocietiesWebsites } from '../../../../../../../interfaces/services/societies-websites.interfaces'
import { SocietyWebsiteModel } from '../../../../../../../state/models/society-website'
import { Button, ButtonVariant } from '../../../../../../common/Button'
import { ConfirmationModal } from '../../../../../../common/ConfirmationModal'
import { ToastType } from '../../../../../../common/Toast/toast-type'
import { CreateUpdateSocietyWebsiteFAQItemModal } from '../CreateUpdateSocietyWebsiteFAQItemModal'

interface SocietyWebsiteQuestionsAndAnswersItemContentProps {
  website: SnapshotOut<typeof SocietyWebsiteModel>
  questionId: string
  sectionId: string
  content: string | undefined
}

export const SocietyWebsiteQuestionsAndAnswersItemContent = observer(
  ({
    website,
    questionId,
    sectionId,
    content,
  }: SocietyWebsiteQuestionsAndAnswersItemContentProps): JSX.Element => {
    const { societyWebsitesStore, authenticationStore } = useStores()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const {
      show: showEditModal,
      open: openEditModal,
      close: closeEditModal,
    } = useModal()
    const {
      show: showConfirmationModal,
      open: openConfirmationModal,
      close: closeConfirmationModal,
    } = useModal()

    const isAdmin = isUserAdminInSociety(
      society,
      authenticationStore.userId as string
    )

    const removeQuestion = async (): Promise<void> => {
      const data: NSocietiesWebsites.NPatch.IRequestBody = {
        brokerQuestion: {
          ...website.brokerQuestion,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          sections: [
            ...(website.brokerQuestion?.sections
              ? website.brokerQuestion.sections.map((_section) => ({
                  ..._section,
                  questions: _section.questions?.filter(
                    (_question) => _question._id !== questionId
                  ),
                }))
              : []),
          ],
        },
      }

      const status = await societyWebsitesStore.patchWebsite(website._id, data)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate(
            'createUpdateQuestionsAndAnswers.flashMessage.deleteQuestionSuccess'
          )
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate(
            'createUpdateQuestionsAndAnswers.flashMessage.deleteQuestionFailure'
          )
        )
      }
    }

    return (
      <>
        <div className="flex flex-col py-4">
          <div>{content}</div>
          {isAdmin && (
            <div className="mt-6 flex flex-row space-x-3">
              <Button
                onClick={openEditModal}
                label={translate('common.actions.edit')}
              />
              <Button
                variant={ButtonVariant.DANGER}
                onClick={openConfirmationModal}
                label={translate('common.actions.delete')}
              />
            </div>
          )}
        </div>
        <CreateUpdateSocietyWebsiteFAQItemModal
          website={website}
          questionId={questionId}
          sectionId={sectionId}
          show={showEditModal}
          close={closeEditModal}
        />
        <ConfirmationModal
          title={translate(
            'createUpdateQuestionsAndAnswers.confirmationModal.title'
          )}
          description={translate(
            'createUpdateQuestionsAndAnswers.confirmationModal.description'
          )}
          show={showConfirmationModal}
          close={closeConfirmationModal}
          onConfirm={removeQuestion}
          deleteMode
        />
      </>
    )
  }
)
