import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { atcb_action as atcbAction } from 'add-to-calendar-button'
import { Link } from 'react-router-dom'
import { theme } from '../../../../theme/theme'
import { useModal } from '../../../../hooks/useModal'
import { EventModel } from '../../../../state/models/event'
import { capitalize } from '../../../../helpers/string'
import {
  formatDateWithFormat,
  getDateTime,
  ignoreTimezoneDate,
  sameDay,
} from '../../../../helpers/date'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { Dropdown, Option } from '../../../common/Dropdown'
import { IconChoices } from '../../../common/Icon'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { CreateUpdateEventModal } from '../../Event/CreateUpdateEventModal'
import { ConfirmationModal } from '../../../common/ConfirmationModal'
import { useStores } from '../../../../hooks/useStores'
import { Button, ButtonVariant } from '../../../common/Button'
import { useAddToCalendarConfig } from '../../../../hooks/useAddToCalendarConfig'
import { BoardRoomEventModel } from '../../../../state/models/boardroom-event'
import { reverseUrl } from '../../../../navigation/reverseUrl'

interface CalendarListItemProps {
  event:
    | SnapshotOut<typeof EventModel>
    | SnapshotOut<typeof BoardRoomEventModel>
  displayEdit?: boolean
  boardRoomEvent?: boolean
}

export const CalendarListItem = observer(
  ({
    event,
    displayEdit,
    boardRoomEvent,
  }: CalendarListItemProps): JSX.Element => {
    const { eventStore, boardRoomEventStore } = useStores()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const {
      show: showEditModal,
      open: openEditModal,
      close: closeEditModal,
    } = useModal()
    const {
      show: showConfirmationModal,
      open: openConfirmationModal,
      close: closeConfirmationModal,
    } = useModal()
    const config = useAddToCalendarConfig(event)

    const deleteEvent = async (): Promise<void> => {
      const status = boardRoomEvent
        ? await boardRoomEventStore.deleteEvent(event._id)
        : await eventStore.deleteEvent(event._id)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('createUpdateEvent.flashMessage.deleteEventSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('createUpdateEvent.flashMessage.deleteEventFailure')
        )
      }
    }

    const onAddToCalendar = (): void => {
      atcbAction(config)
    }

    const dropdownOptions: Option[] = [
      {
        value: 'edit',
        label: translate('common.actions.edit'),
        onClick: openEditModal,
      },
      {
        value: 'addToCalendar',
        label: translate('createUpdateEvent.addToCalendar'),
        onClick: onAddToCalendar,
      },
      {
        value: 'remove',
        label: translate('common.actions.delete'),
        onClick: openConfirmationModal,
        destructive: true,
      },
    ]

    return (
      <>
        <p style={theme.textVariants.caption} className="mb-1">
          {capitalize(
            formatDateWithFormat(
              ignoreTimezoneDate(new Date(event.startDate)),
              'eee d MMMM yyyy'
            )
          )}
          {!sameDay(new Date(event.startDate), new Date(event.endDate)) &&
          event.endDate > event.startDate
            ? ` - ${capitalize(
                formatDateWithFormat(
                  ignoreTimezoneDate(new Date(event.endDate)),
                  'eee d MMMM yyyy'
                )
              )}`
            : ''}
        </p>
        <Link
          className="b-1 flex border-y border-neutral-80 py-2"
          to={reverseUrl(
            boardRoomEvent
              ? 'management:calendar-detail'
              : 'society:calendar-detail',
            {
              id: event.societyId,
              eventId: event._id,
            }
          )}
        >
          <div className="b-1 mr-2 mt-1 flex flex-col border-r border-neutral-80 pr-2">
            {event.isAllDay ? (
              <p style={theme.textVariants.caption}>
                {translate('createUpdateEvent.form.labels.isAllDay')}
              </p>
            ) : (
              <>
                <p style={theme.textVariants.caption}>
                  {getDateTime(ignoreTimezoneDate(new Date(event.startDate)))}
                </p>
                <p style={theme.textVariants.caption}>
                  {getDateTime(ignoreTimezoneDate(new Date(event.endDate)))}
                </p>
              </>
            )}
          </div>
          <div className="flex flex-1 flex-col">
            <p style={theme.textVariants.baseBold}>{event.title}</p>
            {event.location && (
              <p style={theme.textVariants.base}>{event.location}</p>
            )}
          </div>
          {displayEdit && (
            <Dropdown options={dropdownOptions}>
              <Button
                icon={IconChoices.MORE}
                variant={ButtonVariant.TEXT}
                size="sm"
                className="h-10 w-10"
                disableTabIndex
              />
            </Dropdown>
          )}
        </Link>
        {showEditModal && (
          <CreateUpdateEventModal
            show={showEditModal}
            close={closeEditModal}
            event={event as SnapshotOut<typeof EventModel>}
            boardRoomEvent={boardRoomEvent}
          />
        )}
        {showConfirmationModal && (
          <ConfirmationModal
            title={translate('createUpdateEvent.confirmationModal.deleteTitle')}
            description={translate(
              'createUpdateEvent.confirmationModal.deleteDescription'
            )}
            show={showConfirmationModal}
            close={closeConfirmationModal}
            onConfirm={deleteEvent}
            deleteMode
          />
        )}
      </>
    )
  }
)
