import React from 'react'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { Badge } from '../../common/Badge'

interface ContractStatusBadgeProps {
  status: string
  className?: string
}

export const ContractStatusBadge = ({
  status,
  className,
}: ContractStatusBadgeProps): JSX.Element => {
  const { translate } = useAppTranslation()

  const getBadgeColorClass = (): string => {
    switch (status) {
      case 'active':
        return 'bg-brandGreen'
      case 'terminated':
        return 'bg-red'
      default:
        return 'bg-brandGreen'
    }
  }

  const getBadgeText = (): string => {
    switch (status) {
      case 'active':
        return translate('createUpdateContractForm.status.active')
      case 'terminated':
        return translate('createUpdateContractForm.status.terminated')
      default:
        return translate('createUpdateContractForm.status.active')
    }
  }
  return (
    <Badge
      text={getBadgeText()}
      colorClass={getBadgeColorClass()}
      className={className}
    />
  )
}
