import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useStores } from '../../../../hooks/useStores'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { societyDocumentFolderCreateSchema } from '../../../../forms/schemas/society_document_folder_create'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { CreateUpdateFolderForm } from '../CreateUpdateFolderForm'

interface CreateUpdateFolderModalProps {
  show: boolean
  close: () => void
  section: string
  parentId: string
  children?: React.ReactNode
  id?: string
}

export const CreateUpdateFolderModal = observer(
  ({
    id,
    parentId,
    section,
    children,
    show,
    close,
  }: CreateUpdateFolderModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { documentStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!id
    const folder = id ? documentStore.documents.get(id) : undefined

    const showErrorMessage = (): void => {
      const message = updateMode
        ? 'createUpdateFolder.flashMessage.updateFolderFailure'
        : 'createUpdateFolder.flashMessage.createFolderFailure'
      setToastNotification(ToastType.DANGER, translate(message))
    }

    const showSuccessMessage = (): void => {
      const message = updateMode
        ? 'createUpdateFolder.flashMessage.updateFolderSuccess'
        : 'createUpdateFolder.flashMessage.createFolderSuccess'
      setToastNotification(ToastType.SUCCESS, translate(message))
    }

    const onSubmit = async (
      data: InferType<typeof societyDocumentFolderCreateSchema>
    ): Promise<void> => {
      if (updateMode) {
        const status = await documentStore.patchDocument(id, { ...data })

        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      } else {
        const status = await documentStore.createFolder(
          data.name,
          parentId,
          society._id,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          section,
          'none'
        )
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      showErrorMessage()
    }

    const loading =
      documentStore.creatingFolder === 'pending' ||
      documentStore.updatingDocument === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate(
                  updateMode
                    ? 'createUpdateFolder.editFolderTitle'
                    : 'createUpdateFolder.addNewFolderTitle'
                )}
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <CreateUpdateFolderForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  folder={folder}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
