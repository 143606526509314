import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { TrackingEventType, TrackingProperty } from '../types/tracking'
import { useTracking } from './useTracking'
import { useStores } from './useStores'

export const usePageViews = (): void => {
  const { trackingStore } = useStores()
  const location = useLocation()
  const { track } = useTracking()

  useEffect(() => {
    if (trackingStore.lastTrackingPathName !== location.pathname) {
      track({
        event: TrackingEventType.PageView,
        properties: { [TrackingProperty.PageName]: location.pathname },
      })
      trackingStore.setLastTrackingPathName(location.pathname)
    }
  }, [location, track, trackingStore, trackingStore.lastTrackingPathName])
}
