import React from 'react'
import { theme } from '../../../theme/theme'
import Checkmark from '../../../assets/images/checkmark_green.svg'
import Cross from '../../../assets/images/cross.svg'

interface ToggleProps {
  enabled: boolean
  onChange: (value: boolean) => void
  name: string
  label?: string
  disabled?: boolean
  wrapperClassName?: string
  loading?: boolean
}

export const Toggle = ({
  disabled,
  name,
  enabled,
  label,
  onChange,
  wrapperClassName,
  loading,
}: ToggleProps): JSX.Element => {
  return (
    <label
      htmlFor={name}
      className={`${
        disabled ? '' : 'cursor-pointer'
      } flex select-none items-center ${wrapperClassName}`}
    >
      {label && (
        <p style={theme.textVariants.base} className="mr-3">
          {label}
        </p>
      )}
      <div className="relative">
        <input
          type="checkbox"
          id={name}
          readOnly
          disabled={disabled}
          className="sr-only"
          onClick={() => {
            !loading && onChange(!enabled)
          }}
          checked={enabled}
        />
        <div
          className={`block h-[24px] w-[40px] rounded-full ${
            enabled ? 'bg-brandGreen' : 'bg-neutral-70'
          }
          ${disabled && 'opacity-50'}
          `}
        />
        <div
          className={`absolute left-[2px] top-[2px] flex h-[20px] w-[20px] items-center justify-center 
          rounded-full bg-white shadow-md transition duration-300 ease-in-out ${
            !enabled ? null : 'translate-x-4 transform'
          }`}
        >
          {loading ? (
            <div
              className={`h-3 w-3 animate-spin rounded-full border-2
                border-solid border-t-transparent
                ${enabled ? 'border-brandGreen' : 'border-neutral-70'}
              `}
            />
          ) : (
            <>
              {enabled ? (
                <Checkmark
                  color="red"
                  className="h-[10px] w-[10px] text-neutral-90"
                  alt="enabled"
                />
              ) : (
                <Cross
                  color="red"
                  className="h-[10px] w-[10px] text-neutral-90"
                  alt="disabled"
                />
              )}
            </>
          )}
        </div>
      </div>
    </label>
  )
}
