import i18next from 'i18next'
import * as yup from 'yup'

const minNumberOfBillingUnits = 3

export const adminEditSocietySchema = yup
  .object({
    organisationNumber: yup.string().when('societyTypeDisplayName', {
      is: (type: string) => type !== 'neighbourhood',
      then: (schema) =>
        schema
          .required()
          .matches(
            /^\d{6}-?\d{4}$/,
            `${i18next.t('form.errors.organisationNumber')}`
          ),
    }),
    addressCo: yup.string(),
    addressStreet: yup.string(),
    addressCity: yup.string(),
    addressBillingCity: yup.string(),
    addressBillingCounty: yup.string(),
    addressBillingMunicipality: yup.string(),
    billingUnits: yup.number().when('societyTypeDisplayName', {
      is: (type: string) => type !== 'neighbourhood',
      then: (schema) =>
        schema.required().min(
          3,
          i18next.t('form.errors.minNumber', {
            minNumber: minNumberOfBillingUnits,
          })
        ),
    }),
    societyTypeDisplayName: yup.string().required(),
    locked: yup.boolean().required(),
    trialPeriodEndDate: yup.date().required(),
  })
  .required()
