import React, { KeyboardEvent } from 'react'
import { theme } from '../../../theme/theme'

interface Tab {
  title: string
}

interface SwitchTabBarProps {
  tabs: Tab[]
  activeTabBar: number
  setActiveTabBar: (index: number) => void
  customOnClick?: () => void
}

interface SwitchTabProps {
  tab: Tab
  activeTabBar: number
  setActiveTabBar: (index: number) => void
  index: number
  customOnClick?: () => void
}

const SwitchTab = ({
  tab,
  activeTabBar,
  setActiveTabBar,
  index,
  customOnClick,
}: SwitchTabProps): JSX.Element => {
  const onClick = (): void => {
    setActiveTabBar(index)
    customOnClick && customOnClick()
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      setActiveTabBar(index)
    }
  }

  return (
    <div
      className={`flex h-full w-full cursor-pointer items-center
        justify-center py-[2px] px-4
        ${activeTabBar === index ? 'rounded-md bg-white shadow-tab' : ''}
      `}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      role="menuitem"
      onClick={onClick}
    >
      <p style={{ ...theme.textVariants.captionBold }}>{tab.title}</p>
    </div>
  )
}

export const SwitchTabBar = ({
  tabs,
  activeTabBar,
  setActiveTabBar,
  customOnClick,
}: SwitchTabBarProps): JSX.Element => {
  return (
    <div className="min-h-8 flex h-8 px-[23px]">
      <div className="flex h-full w-full items-center justify-center rounded-md bg-neutral-95 p-[2px]">
        {tabs.map((tab, index) => (
          <SwitchTab
            key={tab.title}
            tab={tab}
            activeTabBar={activeTabBar}
            setActiveTabBar={setActiveTabBar}
            index={index}
            customOnClick={customOnClick}
          />
        ))}
      </div>
    </div>
  )
}
