import React from 'react'
import { observer } from 'mobx-react-lite'
import { v4 as uuid } from 'uuid'
import { SnapshotOut } from 'mobx-state-tree'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { theme } from '../../../../theme/theme'
import { useStores } from '../../../../hooks/useStores'
import { RequestModel } from '../../../../state/models/request'
import {
  AccordionSection,
  AccordionSectionItem,
} from '../../../common/AccordionSection'
import { UserModel } from '../../../../state/models/user'
import { RequestAccordionSectionContent } from './RequestAccordionSectionContent'
import { UnitRole } from '../../../../types/unit-roles'
import { getUnitRole } from '../../../../helpers/translations/unit-roles'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'

interface RequestsModalProps {
  show: boolean
  close: () => void
  requests: SnapshotOut<typeof RequestModel>[]
  children?: React.ReactNode
}

export const RequestsModal = observer(
  ({ children, show, close, requests }: RequestsModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { userStore, unitStore, societyEntrancesStore } = useStores()

    const getRequestSectionData = (
      _request: SnapshotOut<typeof RequestModel>
    ): AccordionSectionItem => {
      const { unitId, userId, role } = _request
      const user = userStore.users.get(userId) as
        | SnapshotOut<typeof UserModel>
        | undefined
      const unit = unitId ? unitStore.units.get(unitId) : undefined

      const getRoleUnitEntranceText = (): string => {
        const strings = []
        if (role) {
          strings.push(translate(getUnitRole(role as UnitRole) as string))
        }
        if (unit && unit.entranceId) {
          const entrance = societyEntrancesStore.entrances.get(unit.entranceId)
          if (entrance) strings.push(entrance.addressStreet)
        }
        if (unit) {
          const unitTitle = unit.title ? `(${unit.title})` : ''
          strings.push(`${unit.titleLegal} ${unitTitle}`)
        }

        return strings.join(', ')
      }

      return {
        id: uuid(),
        title: user?.fullName || '',
        subtitle: getRoleUnitEntranceText(),
        content: <RequestAccordionSectionContent request={_request} />,
      }
    }

    const accordionSectionItems = [] as AccordionSectionItem[]

    requests.forEach((_request) => {
      const user = userStore.users.get(_request.userId) as
        | SnapshotOut<typeof UserModel>
        | undefined

      // User can be null if the requesting user has removed their account
      if (user) {
        accordionSectionItems.push(getRequestSectionData(_request))
      }
    })

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                <p style={theme.textVariants.h3}>
                  {translate('registerRequest.modal.title')}
                </p>
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <AccordionSection
                  className="mb-2 md:w-full"
                  items={accordionSectionItems}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
