import React from 'react'
import { useStores } from '../../../hooks/useStores'
import { LikesUserListItem } from '../LikesUserListItem'

interface LikesModalContentProps {
  likes: string[]
  societyId: string
}

export const LikesModalContent = ({
  likes,
  societyId,
}: LikesModalContentProps): JSX.Element => {
  const { userStore } = useStores()

  return (
    <div className="flex flex-col">
      {likes.map((_user, i) => {
        const user = userStore.users.get(_user)
        return user ? (
          <LikesUserListItem
            key={user._id}
            index={i}
            user={user}
            societyId={societyId}
          />
        ) : null
      })}
    </div>
  )
}
