import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import { TextInput } from '../../../common/TextInput'
import { invoiceDetailsSchema } from '../../../../forms/schemas/invoice_details'
import { FormButtons } from '../../../common/FormButtons'
import { FormSpacing } from '../../../common/FormSpacing'

interface InvoiceDetailsForm {
  onError: (error: unknown) => void
  onSubmit: (data: InferType<typeof invoiceDetailsSchema>) => Promise<void>
  loading: boolean
}

export const InvoiceDetailsForm = observer(
  ({ loading, onError, onSubmit }: InvoiceDetailsForm): JSX.Element => {
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { getErrorMessage } = useFormErrorMessage()

    const getDefaultValues = useCallback((): InferType<
      typeof invoiceDetailsSchema
    > => {
      const {
        addressBillingCo,
        addressBillingStreet,
        addressBillingZip,
        addressBillingCity,
        billingEmail,
        billingRefText,
      } = society
      return {
        addressBillingCo: addressBillingCo || '',
        addressBillingStreet: addressBillingStreet || '',
        addressBillingZip: addressBillingZip || '',
        addressBillingCity: addressBillingCity || '',
        billingEmail: billingEmail || '',
        billingCompany: society.name || '',
        billingOrgNo: society.organisationNumber || '',
        billingRefText: billingRefText || '',
      }
    }, [society])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(invoiceDetailsSchema),
      defaultValues: getDefaultValues(),
    })

    return (
      <>
        <FormSpacing className="lg:w-1/3">
          <Controller
            control={control}
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                label={translate('invoiceView.details.form.labels.recipient')}
                error={
                  errors.billingEmail && getErrorMessage(errors.billingEmail)
                }
                note={{ required: true }}
                name={name}
              >
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.billingEmail}
                  placeholder="exempel@boappa.se"
                  type="email"
                />
              </FormControl>
            )}
            name="billingEmail"
          />
          <Controller
            control={control}
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                label={translate('common.form.labels.reference')}
                error={
                  errors.billingRefText &&
                  getErrorMessage(errors.billingRefText)
                }
                name={name}
              >
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.billingRefText}
                />
              </FormControl>
            )}
            name="billingRefText"
          />
          <Controller
            control={control}
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                label={translate('common.form.labels.society')}
                error={
                  errors.billingCompany &&
                  getErrorMessage(errors.billingCompany)
                }
                name={name}
                note={{ locked: true }}
              >
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled
                  error={!!errors.billingCompany}
                />
              </FormControl>
            )}
            name="billingCompany"
          />
          <Controller
            control={control}
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                label={translate('common.form.labels.organisationNumber')}
                error={
                  errors.billingOrgNo && getErrorMessage(errors.billingOrgNo)
                }
                name={name}
                note={{ locked: true }}
              >
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled
                  error={!!errors.billingOrgNo}
                />
              </FormControl>
            )}
            name="billingOrgNo"
          />
          <Controller
            control={control}
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                label={translate('common.form.labels.addressCareOf')}
                error={
                  errors.addressBillingCo &&
                  getErrorMessage(errors.addressBillingCo)
                }
                name={name}
              >
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.addressBillingCo}
                />
              </FormControl>
            )}
            name="addressBillingCo"
          />
          <Controller
            control={control}
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                label={translate(
                  'invoiceView.details.form.labels.billingStreet'
                )}
                error={
                  errors.addressBillingStreet &&
                  getErrorMessage(errors.addressBillingStreet)
                }
                name={name}
              >
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.addressBillingStreet}
                />
              </FormControl>
            )}
            name="addressBillingStreet"
          />
          <Controller
            control={control}
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                label={translate('common.form.labels.addressZip')}
                error={
                  errors.addressBillingZip &&
                  getErrorMessage(errors.addressBillingZip)
                }
                name={name}
              >
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.addressBillingZip}
                />
              </FormControl>
            )}
            name="addressBillingZip"
          />
          <Controller
            control={control}
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                label={translate('common.form.labels.addressCity')}
                error={
                  errors.addressBillingCity &&
                  getErrorMessage(errors.addressBillingCity)
                }
                name={name}
              >
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.addressBillingCity}
                />
              </FormControl>
            )}
            name="addressBillingCity"
          />
        </FormSpacing>
        <FormButtons
          className="lg:w-1/3"
          buttonsRight={[
            {
              variant: ButtonVariant.PRIMARY,
              disabled: !isValid || loading,
              loading,
              label: translate('common.actions.save'),
              type: 'submit',
              onClick: handleSubmit(onSubmit, onError),
            },
          ]}
        />
      </>
    )
  }
)
