import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { EventModel } from '../../../state/models/event'
import { BoardRoomEventModel } from '../../../state/models/boardroom-event'
import { theme } from '../../../theme/theme'

import {
  formatDateWithFormat,
  ignoreTimezoneDate,
  sameDay,
} from '../../../helpers/date'

import { EventAttendancePreview } from './EventAttendancePreview'
import { EventDetailViewTabs } from './EventDetailView'

interface EventDetailRowProps {
  title: string
  description?: string
  content?: JSX.Element
}

export const EventDetailRow = ({
  title,
  content,
  description,
}: EventDetailRowProps): JSX.Element => {
  return (
    <div className="flex flex-col space-y-2">
      <p className="text-neutral-30" style={theme.textVariants.lead}>
        {title}
      </p>
      {description && <p style={theme.textVariants.base}>{description}</p>}
      {content && content}
    </div>
  )
}

interface EventDetailInformationProps {
  event:
    | SnapshotOut<typeof EventModel>
    | SnapshotOut<typeof BoardRoomEventModel>
  boardRoomEvent: boolean | undefined
  setActiveTab: (tab: EventDetailViewTabs) => void
}

export const EventDetailInformation = observer(
  ({
    event,
    boardRoomEvent,
    setActiveTab,
  }: EventDetailInformationProps): JSX.Element => {
    const { translate } = useAppTranslation()

    return (
      <div className="w-full lg:w-1/2">
        <div className="flex flex-col gap-4">
          {event.isAllDay ? (
            <EventDetailRow
              title={translate('eventDetailView.headers.date')}
              description={`${formatDateWithFormat(
                ignoreTimezoneDate(new Date(event.startDate)),
                'd MMMM yyyy'
              )}${
                !sameDay(new Date(event.startDate), new Date(event.endDate)) &&
                event.endDate > event.startDate
                  ? ` - ${formatDateWithFormat(
                      ignoreTimezoneDate(new Date(event.endDate)),
                      'd MMMM yyyy'
                    )}`
                  : ''
              }
              `}
            />
          ) : (
            <>
              <EventDetailRow
                title={translate('createUpdateEvent.form.labels.startDate')}
                description={formatDateWithFormat(
                  ignoreTimezoneDate(new Date(event.startDate)),
                  "d MMMM yyyy 'kl.' HH:mm"
                )}
              />
              <EventDetailRow
                title={translate('createUpdateEvent.form.labels.endDate')}
                description={formatDateWithFormat(
                  ignoreTimezoneDate(new Date(event.endDate)),
                  "d MMMM yyyy 'kl.' HH:mm"
                )}
              />
            </>
          )}
          {event.location && (
            <EventDetailRow
              title={translate('createUpdateEvent.form.labels.location')}
              description={event.location}
            />
          )}
          {event.description && (
            <EventDetailRow
              title={translate('createUpdateEvent.form.labels.description')}
              description={event.description}
            />
          )}
          {event.attendanceEnabled && (
            <EventAttendancePreview
              event={event}
              boardRoomEvent={boardRoomEvent}
              setActiveTab={setActiveTab}
            />
          )}
        </div>
      </div>
    )
  }
)
