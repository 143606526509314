import React, { Dispatch, SetStateAction } from 'react'
import { useAtom } from 'jotai'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useModal } from '../../../hooks/useModal'
import { Button, ButtonVariant } from '../../common/Button'
import { ChatHeaderState, chatHeaderStateAtom } from '../atom'
import { JoinGroupModal } from '../JoinGroupModal'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'

interface ChatJoinGroupChatBarProps {
  roomId: string | undefined
  showSettings: boolean
  setShowSettings: Dispatch<SetStateAction<boolean>>
}

export const ChatJoinGroupChatBar = ({
  roomId,
  showSettings,
  setShowSettings,
}: ChatJoinGroupChatBarProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const { chatRoomStore } = useStores()
  const { setToastNotification } = useToastNotifications()
  const [, setChatHeaderState] = useAtom(chatHeaderStateAtom)
  const {
    show: showJoinGroupModal,
    open: openJoinGroupModal,
    close: closeJoinGroupModal,
  } = useModal()

  const onDetailsClick = (): void => {
    setShowSettings(!showSettings)
  }

  const onCloseJoinGroupModal = (): void => {
    setChatHeaderState(ChatHeaderState.None)
    chatRoomStore.setSelectedChatRoom(undefined)
    closeJoinGroupModal()
  }

  const onGroupJoin = async (): Promise<void> => {
    if (roomId) {
      const room = await chatRoomStore.joinRoom(roomId)

      if (room) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('joinGroupChat.flashMessage.joinGroupChatSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('joinGroupChat.flashMessage.joinGroupChatFailure')
        )
      }
      setChatHeaderState(ChatHeaderState.None)
    }
  }

  const loading = chatRoomStore.joiningChatRoom === 'pending'

  return (
    <>
      <div className="bg-neutral-98 p-4 md:py-6 md:pl-6 md:pr-[39px]">
        <div
          className="flex h-36 flex-col items-center justify-center gap-3 rounded-xl border
          border-neutral-90 bg-neutral-94"
        >
          <div className="flex gap-3">
            <Button
              label={translate('joinGroupChat.details')}
              type="button"
              variant={ButtonVariant.DEFAULT}
              onClick={onDetailsClick}
            />
            <Button
              label={translate('joinGroupChat.join')}
              type="button"
              variant={ButtonVariant.PRIMARY}
              onClick={onGroupJoin}
              loading={loading}
            />
          </div>
          <button className="underline" onClick={openJoinGroupModal}>
            {translate('joinGroupChat.backToGroupChats')}
          </button>
        </div>
      </div>
      <JoinGroupModal
        show={showJoinGroupModal}
        close={onCloseJoinGroupModal}
        onItemSelect={closeJoinGroupModal}
      />
    </>
  )
}
