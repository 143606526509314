import React from 'react'
import { SimpleLink } from '../../../../../components/common/SimpleLink'

export const DataProtectionOfficer = (): JSX.Element => {
  return (
    <div>
      Om ni som förening har några frågor kring hur Boappa AB behandlar
      personuppgifter, tveka inte att kontakta vår dataskyddsansvarig på{' '}
      <SimpleLink
        href="mailto:dataskydd@boappa.se"
        text="dataskydd@boappa.se"
      />
    </div>
  )
}
