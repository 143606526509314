import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useNavigate } from 'react-router-dom'
import { IconChoices } from '../../common/Icon'
import { MenuItem } from '../../common/MenuItem'
import { useModal } from '../../../hooks/useModal'
import { ConfirmationModal } from '../../common/ConfirmationModal'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'
import { UnableToLeaveSocietyModal } from '../LeaveSociety/UnableToLeaveSocietyModal'
import { SocietyModel } from '../../../state/models/society'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { Spinner } from '../../common/Spinner'

interface LeaveSocietyMenuItemProps {
  society: SnapshotOut<typeof SocietyModel>
}

export const LeaveSocietyMenuItem = observer(
  ({ society }: LeaveSocietyMenuItemProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { societyStore, authenticationStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const {
      show: showLeaveConfirmationModal,
      open: openLeaveConfirmationModal,
      close: closeLeaveConfirmationModal,
    } = useModal()
    const {
      show: showUnableToLeaveModal,
      open: openUnableToLeaveModal,
      close: closeUnableToLeaveModal,
    } = useModal()
    const navigate = useNavigate()
    const userId = authenticationStore.userId as string

    const showSuccessMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('leaveSociety.flashMessage.leaveSocietySuccess')
      )
    }

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('leaveSociety.flashMessage.leaveSocietyFailure')
      )
    }

    const leaveSociety = async (): Promise<void> => {
      const status = await societyStore.removeSelfFromSociety(society._id)
      societyStore.getUserSocieties(userId)
      navigate(reverseUrl('society'))
      if (status) {
        showSuccessMessage()
      } else {
        showErrorMessage()
      }
    }

    const canLeaveSociety = societyStore.canRemoveUserFromSociety(
      userId,
      society._id
    )

    const loading = societyStore.removingSelfFromSociety === 'pending'

    return (
      <>
        {!loading ? (
          <MenuItem
            title={translate('societySideBar.items.labels.leaveSociety')}
            icon={IconChoices.LEAVE_SOCIETY}
            destructive
            onClick={
              canLeaveSociety
                ? openLeaveConfirmationModal
                : openUnableToLeaveModal
            }
          />
        ) : (
          <Spinner />
        )}
        <ConfirmationModal
          title={translate('leaveSociety.confirmationModal.title')}
          description={translate('leaveSociety.confirmationModal.description')}
          show={showLeaveConfirmationModal}
          close={closeLeaveConfirmationModal}
          onConfirm={leaveSociety}
          deleteMode={false}
          confirmationButtonLabel={translate('common.actions.leave')}
        />
        <UnableToLeaveSocietyModal
          show={showUnableToLeaveModal}
          close={closeUnableToLeaveModal}
        />
      </>
    )
  }
)
