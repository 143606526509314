import * as yup from 'yup'
import i18next from '../../i18n/i18n'

// Taken from https://stackoverflow.com/questions/61634973/yup-validation-of-website-using-url-very-strict
// Very flexible, allows urls such as:
// www.test-my-skills.gov.cz/0999asd-xzc88?0-_/sad%20123/@asdas
// asdasd.com/asdasd/asdasd/asdasd/@asasd
// https://www.somehow.com/@aasd
// https://www.test.facebook.com/@sdas
// http://www.computer.com.au/
const regMatch =
  // eslint-disable-next-line max-len, no-useless-escape
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/

export const societyContactCreateSchema = yup
  .object()
  .shape(
    {
      name: yup.string().required(),
      title: yup.string().required(),
      description: yup.string(),
      phone: yup.string(),
      phoneCountry: yup.string(),
      email: yup.string().when('email', (val) => {
        const emailValue = val[0]
        if (emailValue !== '' && emailValue !== undefined) {
          return yup.string().email()
        }
        return yup.string().notRequired()
      }),
      website: yup.string().when('website', (val) => {
        const websiteValue = val[0]
        if (websiteValue !== '' && websiteValue !== undefined) {
          return yup
            .string()
            .matches(regMatch, i18next.t('form.errors.invalidWebsite'))
        }
        return yup.string().notRequired()
      }),
      mediaId: yup.string().nullable(),
      societyId: yup.string().required(),
      notificationSettings: yup
        .string()
        .oneOf(['none', 'feed', 'notification+feed']),
    },
    [
      ['website', 'website'],
      ['email', 'email'],
    ] // avoid cyclic dependency
  )
  .required()
