import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import Compressor from 'compressorjs'
import { Button, ButtonVariant } from '../../common/Button'
import { HorizontalFormSection } from '../../common/HorizontalFormSection'
import { IconChoices } from '../../common/Icon'
import { ToastType } from '../../common/Toast/toast-type'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useAuthenticatedUserId } from '../../../hooks/useAuthenticatedUserId'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { UserAvatar } from '../../common/Avatar'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { Label } from '../../common/Label'
import { convertBase64 } from '../../../api/helpers'
import { ConfirmationModal } from '../../common/ConfirmationModal'
import { useModal } from '../../../hooks/useModal'
import { Spinner } from '../../common/Spinner'
import { DeleteAccountModal } from './DeleteAccountModal'
import { ChangePassword } from '../ChangePassword'
import { ChangeEmail } from '../ChangeEmail'
import { isImageType } from '../../../helpers/types'

export const ProfileSettings = observer((): JSX.Element => {
  const { userStore } = useStores()
  const { translate } = useAppTranslation()
  const { setToastNotification } = useToastNotifications()
  const [uploadingPicture, setUploadingPicture] = useState(false)
  const isMobile = useIsMobile()
  const {
    show: showDeleteProfilePictureModal,
    open: openDeleteProfilePictureModal,
    close: closeDeleteProfilePictureModal,
  } = useModal()
  const {
    show: showDeleteProfileModal,
    open: openDeleteProfileModal,
    close: closeDeleteProfileModal,
  } = useModal()

  const userId = useAuthenticatedUserId() as string
  const user = userStore.users.get(userId)

  const showErrorMessage = (message: string): void => {
    setToastNotification(ToastType.DANGER, message)
  }

  const showSuccessMessage = (message: string): void => {
    setToastNotification(ToastType.SUCCESS, message)
  }

  const uploadPicture = async (base64: string): Promise<void> => {
    setUploadingPicture(true)

    try {
      const status = await userStore.updateProfilePicture('image', base64)
      if (status) {
        showSuccessMessage(
          translate('profileSettings.flashMessage.updateProfilePictureSuccess')
        )
      } else {
        showErrorMessage(
          translate('profileSettings.flashMessage.updateProfilePictureFailure')
        )
      }
    } catch (error) {
      showErrorMessage(translate('flashMessage.somethingWentWrongError'))
    }

    setUploadingPicture(false)
  }

  const deleteProfilePicture = async (): Promise<void> => {
    const status = await userStore.removeProfilePicture()
    if (status) {
      showSuccessMessage(
        translate('profileSettings.flashMessage.deleteProfilePictureSuccess')
      )
    } else {
      showErrorMessage(
        translate('profileSettings.flashMessage.deleteProfilePictureFailure')
      )
    }
  }

  const onUploadImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files) {
      return
    }
    const images = Array.from(e.target.files)
    if (images.length === 0) {
      return
    }
    const image = images[0]
    if (!isImageType(image.type)) {
      setToastNotification(
        ToastType.DANGER,
        translate('uploadImage.invalidImageType')
      )
      return
    }
    // eslint-disable-next-line no-new
    new Compressor(image, {
      quality: 0.8,
      success: async (result) => {
        const base64 = await convertBase64(result)
        await uploadPicture(base64 as string)
      },
    })
  }

  return (
    <div className="space-y-10">
      <HorizontalFormSection
        title={translate('profileSettings.profilePictureTitle')}
        icon={IconChoices.IMAGE}
        iconPlacement="left"
        borderBottom
      >
        <div className="flex items-center gap-5">
          <div className="rounded-full p-1 shadow">
            <UserAvatar size={isMobile ? 80 : 90} user={user} />
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex gap-2">
              <div
                className="flex cursor-pointer items-center
                rounded border border border-solid border-neutral-80
                bg-white px-4 py-3 text-black hover:bg-neutral-96"
              >
                {uploadingPicture ? (
                  <Spinner />
                ) : (
                  <>
                    <Label
                      className="cursor-pointer"
                      name="profile-image-input"
                      label={translate(
                        'profileSettings.labels.updateProfilePicture'
                      )}
                    />
                    <input
                      id="profile-image-input"
                      type="file"
                      accept="image/png, image/jpg, image/jpeg"
                      className="hidden cursor-pointer"
                      onChange={onUploadImage}
                    />
                  </>
                )}
              </div>
              {user?.avatarId && (
                <Button
                  variant={ButtonVariant.TEXT}
                  onClick={openDeleteProfilePictureModal}
                  icon={IconChoices.DELETE_TRASH}
                />
              )}
            </div>
          </div>
        </div>
      </HorizontalFormSection>
      <ChangeEmail />
      <ChangePassword />
      <HorizontalFormSection
        title={translate('profileSettings.deleteAccount.title')}
        iconPlacement="left"
        subtitle={translate('profileSettings.deleteAccount.text')}
        icon={IconChoices.WARNING_AMBER}
      >
        <div className="flex justify-end">
          <Button
            label={translate('common.actions.delete')}
            onClick={openDeleteProfileModal}
            variant={ButtonVariant.DANGER}
          />
        </div>
      </HorizontalFormSection>
      {showDeleteProfileModal && (
        <DeleteAccountModal
          show={showDeleteProfileModal}
          close={closeDeleteProfileModal}
        />
      )}
      <ConfirmationModal
        title={translate('profileSettings.confirmationModal.title')}
        description={translate('profileSettings.confirmationModal.description')}
        show={showDeleteProfilePictureModal}
        close={closeDeleteProfilePictureModal}
        onConfirm={deleteProfilePicture}
      />
    </div>
  )
})
