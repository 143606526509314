import { SnapshotOut } from 'mobx-state-tree'
import { flatten } from './array'
import { UserModel } from '../state/models/user'
import { UnitModel } from '../state/models/unit'
import { sortStringsAlphabetically } from './sorting'
import { capitalize } from './string'
import i18next from '../i18n/i18n'
import { SocietyEntranceModel } from '../state/models/society-entrance'

export const getUsersWithoutUnits = (
  users: SnapshotOut<typeof UserModel>[],
  units: SnapshotOut<typeof UnitModel>[]
): string[] => {
  const userWithUnitIds = flatten(
    units
      .filter((unit) => unit.title !== 'UNASSIGNED')
      .map((unit) => unit.residentsList.map((resident) => resident.userId))
  )
  const nonUndefinedUsers = users.filter(
    (_user) => _user !== undefined
  ) as SnapshotOut<typeof UserModel>[]
  const userIds = nonUndefinedUsers.map((user) => user._id)
  const userIdsWithoutUnits = userIds.filter(
    (id) => !userWithUnitIds.includes(id)
  )

  return userIdsWithoutUnits
}

export interface ExtendedUnitModel extends SnapshotOut<typeof UnitModel> {
  entrance: SnapshotOut<typeof SocietyEntranceModel>
}

export const groupUnitsPerAddress = (
  units: ExtendedUnitModel[]
): { address: string; units: SnapshotOut<typeof UnitModel>[] }[] => {
  const sortedUnitsPerAddress = units.reduce(
    (
      prev: {
        [key: string]: SnapshotOut<typeof UnitModel>[]
      },
      unit
    ) => {
      if (!unit.entrance) {
        return prev
      }

      const address = unit.entrance.addressStreet
      if (address) {
        if (!(address in prev)) {
          // eslint-disable-next-line no-param-reassign
          prev[address] = [unit]
        } else {
          prev[address].push(unit)
        }
      }

      return prev
    },
    {}
  )

  // Return array sorted by address
  return Object.keys(sortedUnitsPerAddress)
    .map((key) => ({
      address: key,
      units: sortedUnitsPerAddress[key],
    }))
    .sort((a, b) => sortStringsAlphabetically(a.address, b.address))
}

export const getUnitString = (
  unit: ExtendedUnitModel | undefined,
  isSamf?: boolean | undefined,
  managementMode?: boolean | undefined
): string | null => {
  if (!unit) {
    return null
  }
  if (unit.isUnassigned) {
    return i18next.t('common.unit.unassigned')
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const entranceAddressStreet = unit.entrance?.addressStreet
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      capitalize(unit.entrance?.addressStreet)
    : undefined
  const unitTitleLegal = unit.titleLegal
    ? capitalize(unit.titleLegal.toLowerCase())
    : undefined
  const unitTitle = capitalize(unit.title.toLowerCase())

  const getBrfUnitString = (): string => {
    if (unitTitle && unitTitleLegal) {
      if (unitTitle === unitTitleLegal) {
        return unitTitle
      }
      return `${unitTitleLegal} (${unitTitle})`
    }
    return [unitTitle, unitTitleLegal]
      .filter((_str) => _str !== undefined)
      .join(', ')
  }

  const getSamfUnitString = (): string => {
    if (!managementMode) {
      if (unitTitleLegal) {
        return `${unitTitle} (${unitTitleLegal})`
      }
      return `${unitTitle}`
    }
    if (unitTitleLegal) {
      return `${unitTitleLegal} (${unitTitle})`
    }
    return `${unitTitle}`
  }

  if (isSamf) {
    return getSamfUnitString()
  }
  return `${[entranceAddressStreet || undefined, getBrfUnitString()]
    .filter((_str) => _str !== undefined)
    .join(', ')}`
}

export const getFloorFromTitleLegal = (titleLegal?: string): number => {
  if (!titleLegal || titleLegal.length < 4) {
    return 0
  }
  return (parseInt(titleLegal[0], 10) - 1) * 10 + parseInt(titleLegal[1], 10)
}
