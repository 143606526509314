import React, { ReactNode } from 'react'
import { theme } from '../../../../theme/theme'

interface PostThumbnailsProps {
  header: string
  children: ReactNode
}

export const PostThumbnails = ({
  header,
  children,
}: PostThumbnailsProps): JSX.Element => {
  return (
    <div className="flex flex-col gap-2">
      <p style={theme.textVariants.baseBold}>{header}</p>
      <div className="flex flex-wrap gap-3 rounded-t bg-white">{children}</div>
    </div>
  )
}
