import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'
import { MediaModel } from './media'
import { SocietyModel } from './society'

const SocietyWebsiteBrokerQuestionsQuestionModel = types.model(
  'SocietyWebsiteBrokerQuestionsQuestion',
  {
    _id: types.string,
    order: types.maybe(types.number), // TODO: Update interfaces
    question: types.maybe(types.string),
    answer: types.maybe(types.string),
  }
)

export const SocietyWebsiteBrokerQuestionsSectionModel = types.model(
  'SocietyWebsiteBrokerQuestionsSection',
  {
    _id: types.string,
    title: types.maybe(types.string),
    order: types.maybe(types.number), // TODO: Update interfaces
    questions: types.maybe(
      types.array(SocietyWebsiteBrokerQuestionsQuestionModel)
    ),
  }
)

const SocietyWebsiteBrokerQuestionsModel = types.model(
  'SocietyWebsiteBrokerQuestions',
  {
    show: types.maybeNull(types.boolean),
    sections: types.maybe(
      types.array(SocietyWebsiteBrokerQuestionsSectionModel)
    ),
  }
)

export const SocietyWebsiteContactRecipientModel = types.model(
  'SocietyWebsiteContactRecipient',
  {
    userId: types.maybe(types.string),
    userEmail: types.maybe(types.string),
  }
)

const SocietyWebsiteContactModel = types.model('SocietyWebsiteContact', {
  show: types.maybe(types.boolean),
  recipientId: types.maybeNull(types.string),
  recipientsCC: types.maybe(types.array(SocietyWebsiteContactRecipientModel)),
})

const SocietyWebsitePictureModel = types.model('SocietyWebsitePicture', {
  media: types.reference(MediaModel),
  description: types.maybe(types.string),
})

const SocietyWebsitePicturesModel = types.model('SocietyWebsitePictures', {
  show: types.maybe(types.boolean),
  pictures: types.array(SocietyWebsitePictureModel),
})

export const SocietyWebsiteFolder = types.model('SocietyFolder', {
  _id: types.identifier,
  name: types.string,
  documentsList: types.array(
    types.model('SocietyDocument', {
      documentId: types.string,
    })
  ),
})

export const SocietyWebsiteModel = types.model('SocietyWebsite', {
  _id: types.identifier,
  ...timestampTypes,
  society: types.reference(SocietyModel),
  slug: types.string,
  published: types.maybe(types.boolean),
  displayName: types.maybe(types.string),
  displayCityState: types.maybe(types.string),
  pictureMain: types.maybe(types.reference(MediaModel)),
  description: types.maybeNull(types.string),
  brokerQuestion: types.maybe(SocietyWebsiteBrokerQuestionsModel),
  contact: types.maybe(SocietyWebsiteContactModel),
  societyPictures: types.maybe(SocietyWebsitePicturesModel),
  showDocuments: types.maybeNull(types.boolean),
  showMap: types.maybe(types.boolean),
  foldersList: types.maybe(types.array(SocietyWebsiteFolder)),
})
