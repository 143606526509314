import React from 'react'
import { useSpring, animated, easings } from '@react-spring/web'
import { theme } from '../../../theme/theme'

export interface ProgressBar {
  percentage: number
  height?: string
  backgroundColor?: string
  text?: string
  animate?: boolean
  easing?: (t: number) => number
  duration?: number
}

export const ProgressBar = ({
  percentage,
  height,
  backgroundColor,
  text,
  animate,
  easing = easings.easeOutBounce,
  duration = 400,
}: ProgressBar): JSX.Element => {
  const roundedPercentage = percentage.toFixed(2)

  const springs = useSpring({
    from: { width: '0%' },
    to: { width: `${roundedPercentage}%` },
    config: {
      duration,
      easing,
    },
  })

  const barStyle = animate ? { ...springs } : { width: `${roundedPercentage}%` }

  return (
    <div
      className={`relative flex justify-between
        overflow-hidden rounded-md bg-neutral-85
        ${height || 'h-3'}
      `}
      style={{ ...theme.textVariants.baseBold }}
    >
      <animated.div
        className={`absolute h-full rounded-md
          ${backgroundColor || 'bg-brandGreen'}
        `}
        style={barStyle}
      />
      {text && (
        <p
          className={`z-10 ml-4 flex flex-wrap items-center
            ${height || 'h-3'}
          `}
        >
          {text}
        </p>
      )}
      {text && (
        <p
          className={`z-10 mr-4 flex flex-wrap items-center ${height || 'h-3'}`}
        >
          {roundedPercentage}%
        </p>
      )}
    </div>
  )
}
