import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { Button, ButtonVariant } from '../../../../components/common/Button'
import { TextInput } from '../../../../components/common/TextInput'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { useStores } from '../../../../hooks/useStores'
import { theme } from '../../../../theme/theme'
import { ToastType } from '../../../../components/common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { FormControl } from '../../../../components/common/FormControl'
import { retrievePasswordSchema } from '../../../../forms/schemas/retrieve_password'
import { reverseUrl } from '../../../../navigation/reverseUrl'

interface RetrievePasswordFormProps {
  resetPassword: (password: string) => void
}

export const RetrievePasswordForm = observer(
  ({ resetPassword }: RetrievePasswordFormProps): JSX.Element => {
    const { userStore } = useStores()
    const { translate } = useAppTranslation()
    const { getErrorMessage } = useFormErrorMessage()
    const { setToastNotification } = useToastNotifications()

    const getDefaultValues = (): InferType<typeof retrievePasswordSchema> => {
      return {
        password: '',
        passwordConfirmation: '',
      }
    }

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(retrievePasswordSchema),
      defaultValues: getDefaultValues(),
    })

    const onSubmit = async (
      data: InferType<typeof retrievePasswordSchema>
    ): Promise<void> => {
      const { password } = data
      resetPassword(password)
    }

    const onError = (): void => {
      if (!isValid) return
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const loading = userStore.resettingPassword === 'pending'

    return (
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className="flex flex-col gap-4"
      >
        <Controller
          control={control}
          name="password"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              error={errors.password && getErrorMessage(errors.password)}
              name={name}
              label={translate(
                'authenticationRetrievePasswordView.form.labels.password'
              )}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type="password"
                error={!!errors.password}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="passwordConfirmation"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              error={
                errors.passwordConfirmation &&
                getErrorMessage(errors.passwordConfirmation)
              }
              name={name}
              label={translate(
                'authenticationRetrievePasswordView.form.labels.passwordConfirmation'
              )}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type="password"
                error={!!errors.passwordConfirmation}
              />
            </FormControl>
          )}
        />
        <div className="mt-4 flex flex-col gap-4 md:mt-12">
          <Button
            type="submit"
            style={{
              ...theme.textVariants.paragraph,
            }}
            className="flex h-14 w-full items-center justify-center"
            label={translate(
              'authenticationRetrievePasswordView.retrievePasswordButtonTitle'
            )}
            loading={loading}
            disabled={!isValid || loading}
            variant={ButtonVariant.PRIMARY}
          />
        </div>
        <div className="mt-2 mb-4 flex flex-col justify-center gap-1 md:mb-0 lg:flex-row">
          <p>
            {translate('authenticationRetrievePasswordView.problemsWithLink')}
          </p>
          <Link
            className="underline hover:text-blue"
            to={reverseUrl('reset-password')}
          >
            {translate('authenticationRetrievePasswordView.sendNewLink')}
          </Link>
        </div>
      </form>
    )
  }
)
