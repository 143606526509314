/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useContext } from 'react'
import { RootStoreContext } from '../state/stores/root'

/**
 * A hook that screens can use to gain access to our stores, with
 * `const { someStore, someOtherStore } = useStores()`,
 * or less likely: `const rootStore = useStores()`
 */
export const useStores = () => useContext(RootStoreContext)
