import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { UserModelOccupation } from '../../../../state/models/user'
import { theme } from '../../../../theme/theme'
import { getOccupationIndustry } from '../../../../helpers/translations/occupation'
import { OccupationIndustry } from '../../../../types/occupation'

interface UserOccupationProps {
  occupation: SnapshotOut<typeof UserModelOccupation> | undefined
}

export const UserOccupation = observer(
  ({ occupation }: UserOccupationProps) => {
    const { translate } = useAppTranslation()

    if (
      occupation === null ||
      occupation === undefined ||
      [occupation.company, occupation.title, occupation.industry]
        .map((str) => str || null)
        .filter((str) => str !== null).length === 0
    ) {
      return null
    }

    return (
      <div className="flex flex-col space-y-3">
        <div className="flex items-center gap-3">
          <p className="text-neutral-30" style={theme.textVariants.caption}>
            {translate('userView.occupationTitle')}
          </p>
        </div>
        <div className="flex flex-col gap-3">
          {occupation.title && (
            <p style={theme.textVariants.base}>{occupation.title}</p>
          )}
          {occupation.company && (
            <p style={theme.textVariants.base}>{occupation.company}</p>
          )}
          {occupation.industry && (
            <p style={theme.textVariants.base}>
              {translate(
                getOccupationIndustry(
                  occupation.industry as OccupationIndustry
                ) as string
              )}
            </p>
          )}
        </div>
      </div>
    )
  }
)
