import React from 'react'
import { TableColumn } from '../TableHeader'
import { theme } from '../../../../theme/theme'

export interface TableRow {
  key: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  onPress?: () => void
}

interface TableRowsProps {
  rows: TableRow[]
  columns: TableColumn[]
}

export const TableRows = ({ rows, columns }: TableRowsProps): JSX.Element => {
  return (
    <tbody>
      {rows.map((row) => (
        <tr
          key={row.key}
          onClick={row.onPress ? row.onPress : undefined}
          className={`h-12 odd:bg-white even:bg-neutral-98 ${
            row.onPress && 'cursor-pointer hover:bg-neutral-90'
          }`}
        >
          {columns.map((column) => {
            return (
              <td
                className="py-1 pl-2"
                key={column.key}
                style={theme.textVariants.base}
              >
                {column.renderItem && column.accessor
                  ? column.renderItem(row.data[column.accessor])
                  : row.data[column.key]}
              </td>
            )
          })}
        </tr>
      ))}
    </tbody>
  )
}
