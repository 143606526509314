import { types } from 'mobx-state-tree'

export const roleTypes = types.enumeration('Roles', [
  'resident',
  'admin',
  'board-member',
  'admin-society',
  'board-society',
])

export const timestampTypes = {
  createdAt: types.string,
  updatedAt: types.string,
}

export const stateType = types.enumeration('State', [
  'none',
  'pending',
  'done',
  'error',
])
