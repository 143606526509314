import React, { useCallback, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { InferType } from 'yup'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { theme } from '../../../../theme/theme'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { societyManagementSettingsSchema } from '../../../../forms/schemas/society_management_settings'
import { NSocieties } from '../../../../interfaces/services/societies.interfaces'
import { useStores } from '../../../../hooks/useStores'
import { Toggle } from '../../../../components/common/Toggle'
import { Label } from '../../../../components/common/Label'
import { ToastType } from '../../../../components/common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ViewBase } from '../../../../components/common/ViewBase'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { TextArea } from '../../../../components/common/TextArea'
import { debounce } from '../../../../helpers/performance'

export const SocietySettingsView = observer((): JSX.Element => {
  const latestDataRef = useRef({})
  const { translate } = useAppTranslation()
  const { societyStore } = useStores()
  const { setToastNotification } = useToastNotifications()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:settings', {
      '{{ societyName }}': society.name,
    })
  )

  const getDefaultValues = {
    postingDisabled: society?.residentPostingDisabledByAdmin || false,
    chatOneOnOneDisabled: society?.settings?.chatOneOnOneDisabled || false,
    chatInterestsDisabled: society?.settings?.chatInterestsDisabled || false,
    chatAskBoardDisabled: society?.settings?.chatAskBoardDisabled || false,
    chatAskBoardDisabledResidentMessage:
      society?.settings?.chatAskBoardDisabledResidentMessage || '',
  }

  const { control, watch } = useForm({
    resolver: yupResolver(societyManagementSettingsSchema),
    defaultValues: getDefaultValues,
  })

  const chatAskBoardDisabled = watch('chatAskBoardDisabled')

  const sendPatch = useCallback(
    async (
      data: InferType<typeof societyManagementSettingsSchema>
    ): Promise<void> => {
      const body: NSocieties.NPatch.IRequestBody = {
        residentPostingDisabledByAdmin: data.postingDisabled,
        residentsCanPost: !data.postingDisabled,
        settings: {
          ...(society.settings == null ? {} : society.settings),
          residentPostingDisabled: data.postingDisabled,
          chatOneOnOneDisabled: data.chatOneOnOneDisabled,
          chatInterestsDisabled: data.chatInterestsDisabled,
          chatAskBoardDisabled: data.chatAskBoardDisabled,
          chatAskBoardDisabledResidentMessage:
            data.chatAskBoardDisabledResidentMessage,
        },
      }
      const success = await societyStore.patchSociety(society._id, body)
      if (success) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('societySettingsView.updateSettingsSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }
    },
    [
      setToastNotification,
      society._id,
      society.settings,
      societyStore,
      translate,
    ]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSendPatch = useCallback(
    debounce(() => sendPatch(latestDataRef.current), 1000),
    [latestDataRef, sendPatch]
  )

  useEffect(() => {
    const subscription = watch(async (data, { name }) => {
      latestDataRef.current = data
      if (name === 'chatAskBoardDisabledResidentMessage') {
        debouncedSendPatch()
      } else {
        sendPatch(data)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, debouncedSendPatch, sendPatch])

  const updatingSociety = societyStore.updatingSociety === 'pending'

  return (
    <ErrorBoundary>
      <ViewBase title={translate('societySettingsView.title')}>
        <div className="mt-6 w-full space-y-8">
          <div className="space-y-5">
            <p style={theme.textVariants.h3}>
              {translate('societySettingsView.feedSettings.title')}
            </p>
            <Controller
              control={control}
              name="postingDisabled"
              render={({ field: { value, onChange, name } }) => (
                <div
                  className="flex h-full w-full flex-col items-start justify-between 
                  space-y-3 md:w-1/2 md:flex-row md:items-center md:space-x-10 md:space-y-0"
                >
                  <Label
                    name="postingDisabledLabel"
                    label={translate(
                      'societySettingsView.feedSettings.disablePostingTitle'
                    )}
                    style={theme.textVariants.baseBold}
                  />
                  <Toggle
                    name={name}
                    enabled={value || false}
                    onChange={onChange}
                    loading={updatingSociety}
                  />
                </div>
              )}
            />
            <p style={theme.textVariants.base} className="md:w-1/3">
              {translate(
                'societySettingsView.feedSettings.disablePostingDisclaimerText'
              )}
            </p>
          </div>
          <div className="border-1 border-b md:w-1/2" />
          <div className="space-y-5">
            <p style={theme.textVariants.h3}>
              {translate('societySettingsView.chatSettings.title')}
            </p>
            <Controller
              control={control}
              name="chatOneOnOneDisabled"
              render={({ field: { value, onChange, name } }) => (
                <div
                  className="flex flex-col items-start justify-between space-y-3 
                    md:w-1/2 md:flex-row md:items-center md:space-x-10 md:space-y-0"
                >
                  <Label
                    name="chatOneOnOneDisabledLabel"
                    label={translate(
                      'societySettingsView.chatSettings.disableOneOnOneTitle'
                    )}
                    style={theme.textVariants.baseBold}
                  />
                  <Toggle
                    name={name}
                    enabled={value || false}
                    onChange={onChange}
                    loading={updatingSociety}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="chatInterestsDisabled"
              render={({ field: { value, onChange, name } }) => (
                <div
                  className="flex flex-col items-start justify-between space-y-3 md:w-1/2 
                    md:flex-row md:items-center md:space-x-10 md:space-y-0"
                >
                  <Label
                    name="chatInterestsDisabledLabel"
                    label={translate(
                      'societySettingsView.chatSettings.disableInterestsTitle'
                    )}
                    style={theme.textVariants.baseBold}
                  />
                  <Toggle
                    name={name}
                    enabled={value || false}
                    onChange={onChange}
                    loading={updatingSociety}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="chatAskBoardDisabled"
              render={({ field: { value, onChange, name } }) => (
                <div
                  className="flex flex-col items-start justify-between space-y-3 md:w-1/2 
                    md:flex-row md:items-center md:space-x-10 md:space-y-0"
                >
                  <Label
                    name="chatInterestsDisabledLabel"
                    label={translate(
                      'societySettingsView.chatSettings.disableAskBoardTitle'
                    )}
                    style={theme.textVariants.baseBold}
                  />
                  <Toggle
                    name={name}
                    enabled={value || false}
                    onChange={onChange}
                    loading={updatingSociety}
                  />
                </div>
              )}
            />
            {chatAskBoardDisabled && (
              <Controller
                control={control}
                name="chatAskBoardDisabledResidentMessage"
                render={({ field: { value, onChange, onBlur } }) => (
                  <div className="flex flex-col space-y-3 md:w-1/2">
                    <Label
                      name="chatAskBoardDisabledResidentMessage"
                      label={translate(
                        'societySettingsView.chatSettings.chatAskBoardDisabledResidentMessageTitle'
                      )}
                      style={theme.textVariants.baseBold}
                    />
                    <TextArea
                      placeholder={translate(
                        'societySettingsView.chatSettings.chatAskBoardDisabledResidentMessagePlaceholder'
                      )}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  </div>
                )}
              />
            )}
          </div>
        </div>
      </ViewBase>
    </ErrorBoundary>
  )
})
