import { SnapshotOut } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { SocietyModel } from '../../../../state/models/society'
import { IconChoices } from '../../../common/Icon'
import { LinkSection } from '../LinkSection'
import { useBostadsratternaLinks } from './useBostadsratternaLinks'
import { useSamfalligheternaLinks } from './useSamfalligheternaLinks'

export const useManagementLinks = (
  society: SnapshotOut<typeof SocietyModel>,
  societyLockedForUser: boolean
): LinkSection[] => {
  const { translate } = useAppTranslation()
  const bostadsratternaLinks = useBostadsratternaLinks(society)
  const samfalligheternaLinks = useSamfalligheternaLinks(society)
  const authUserId = useAuthenticatedUserId() as string

  const isUserAdmin = isUserAdminInSociety(society, authUserId)

  const id = society._id

  const managementLinks: LinkSection[] = [
    {
      id: uuid(),
      title: translate('societySideBar.sections.board.managementTitle'),
      sublinks: [
        {
          title: translate('managementDocumentsView.title'),
          icon: IconChoices.FOLDER,
          url: reverseUrl('management:documents', { id }),
          disabled: societyLockedForUser,
        },
        {
          title: translate('boardContactsView.title'),
          icon: IconChoices.GROUP,
          url: reverseUrl('management:contacts', { id }),
          disabled: societyLockedForUser,
        },
        {
          title: translate('boardCalendarView.title'),
          icon: IconChoices.CALENDAR,
          url: reverseUrl('management:calendar', { id }),
          disabled: societyLockedForUser,
          newFeature: new Date('2024-03-24') > new Date(),
        },
        {
          title: translate('societyContractsView.title'),
          icon: IconChoices.TASK,
          url: reverseUrl('management:contracts', { id }),
          newFeature: new Date('2024-03-24') > new Date(),
        },
        {
          title: translate('societyStatisticsExportsView.title'),
          icon: IconChoices.EQUALIZER,
          url: reverseUrl('management:statistics-exports', { id }),
          disabled: societyLockedForUser,
        },
        {
          title: translate('societyInformationView.title'),
          icon: IconChoices.INFORMATION,
          url: reverseUrl('management:information', { id }),
        },
        {
          title: translate('gdprView.title'),
          icon: IconChoices.LOCK,
          url: reverseUrl('management:gdpr', { id }),
          disabled: societyLockedForUser,
        },
      ],
    },
    ...(isUserAdmin
      ? [
          {
            id: uuid(),
            title: translate('societySideBar.sections.board.adminTitle'),
            sublinks: [
              {
                title: translate('invoiceView.title'),
                icon: IconChoices.PAYMENT,
                url: reverseUrl('management:billing', { id }),
              },
              {
                title: translate('registerView.title'),
                icon: IconChoices.GROUPS,
                url: reverseUrl('management:register', { id }),
                disabled: societyLockedForUser,
              },
              {
                title: translate('adminRightsView.title'),
                icon: IconChoices.BOARD_PORTAL,
                url: reverseUrl('management:admin-rights', { id }),
                disabled: societyLockedForUser,
              },
              {
                title: translate('societyWebsiteView.title'),
                icon: IconChoices.DOMAIN,
                url: reverseUrl('management:website', { id }),
                disabled: societyLockedForUser,
              },
              {
                title: translate('societyBlockedUsersView.title'),
                icon: IconChoices.BLOCKED_USER,
                url: reverseUrl('management:blocked-users', { id }),
                disabled: societyLockedForUser,
              },
              {
                title: translate('societySettingsView.title'),
                icon: IconChoices.TUNE,
                url: reverseUrl('management:settings', { id }),
                disabled: societyLockedForUser,
              },
            ],
          },
        ]
      : []),
    ...bostadsratternaLinks,
    ...samfalligheternaLinks,
  ]
  return managementLinks
}
