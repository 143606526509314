import React from 'react'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { Button, ButtonVariant } from '../../../common/Button'
import { theme } from '../../../../theme/theme'

interface UnableToLeaveSocietyModalProps {
  show: boolean
  close: () => void
}

export const UnableToLeaveSocietyModal = ({
  show,
  close,
}: UnableToLeaveSocietyModalProps): JSX.Element => {
  const { translate } = useAppTranslation()

  return (
    <>
      <Modal show={show}>
        {{
          header: (
            <ModalHeader onClose={close}>
              {translate('leaveSociety.unableToLeaveModal.title')}
            </ModalHeader>
          ),
          body: (
            <ModalBody>
              <p style={theme.textVariants.base}>
                {translate('leaveSociety.unableToLeaveModal.description')}
              </p>
              <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-3">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  label={translate('common.actions.close')}
                  type="submit"
                  onClick={close}
                />
              </div>
            </ModalBody>
          ),
        }}
      </Modal>
    </>
  )
}
