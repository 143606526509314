import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { societyFAQSectionCreateSchema } from '../../../../../forms/schemas/society_faq_section_create'
import { ToastType } from '../../../../common/Toast/toast-type'
import { isUserAdminInSociety } from '../../../../../helpers/society'
import { useFormErrorMessage } from '../../../../../hooks/useFormErrorMessage'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { useCurrentSociety } from '../../../../../hooks/useCurrentSociety'
import { useStores } from '../../../../../hooks/useStores'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { TextInput } from '../../../../common/TextInput'
import { FormControl } from '../../../../common/FormControl'
import { Button, ButtonVariant } from '../../../../common/Button'

interface CreateUpdateQuestionsAndAnswersSectionFormProps {
  onError: (error: unknown) => void
  onSubmit: (
    data: InferType<typeof societyFAQSectionCreateSchema>
  ) => Promise<void>
  onClose: () => void
  loading: boolean
  questionId?: string
  sectionId?: string
}

export const CreateUpdateQuestionsAndAnswersSectionForm = observer(
  ({
    sectionId,
    loading,
    onError,
    onSubmit,
    onClose,
  }: CreateUpdateQuestionsAndAnswersSectionFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { authenticationStore, faqsStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()
    const { getErrorMessage } = useFormErrorMessage()
    const section = sectionId ? faqsStore.sections.get(sectionId) : undefined

    const updateMode = !!sectionId

    const isAdmin = isUserAdminInSociety(
      society,
      authenticationStore.userId as string
    )

    if (!isAdmin || (updateMode && sectionId === undefined)) {
      onClose()
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const getDefaultValues = useCallback((): InferType<
      typeof societyFAQSectionCreateSchema
    > => {
      return {
        section: updateMode ? section?.section || '' : '',
      }
    }, [section, updateMode])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyFAQSectionCreateSchema),
      defaultValues: getDefaultValues(),
    })

    return (
      <>
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate(
                'createUpdateQuestionsAndAnswersSectionForm.labels.section'
              )}
              error={errors.section && getErrorMessage(errors.section)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.section}
              />
            </FormControl>
          )}
          name="section"
        />
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-3">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
            variant={ButtonVariant.TEXT}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid || loading}
            label={translate(
              updateMode ? 'common.actions.save' : 'common.actions.add'
            )}
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </>
    )
  }
)
