import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { SocietyModel } from '../../../state/models/society'
import { SocietySideBarCallout } from '../SocietySideBarCallout'
import { SocietyTypes } from '../../../types/society-type'

export const useManagementCallout = (
  society: SnapshotOut<typeof SocietyModel>,
  societyLocked: boolean
): SocietySideBarCallout | null => {
  const { unitStore } = useStores()
  const { translate } = useAppTranslation()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { isSamf } = society

  if (society.societyTypeDisplayName === SocietyTypes.NEIGHBOURHOOD) {
    return null
  }

  const unitsForSociety = unitStore
    .unitsForSociety(society._id)
    .filter((unit) => unit.title !== 'UNASSIGNED')

  const unitsForSocietyWithoutResidents = unitsForSociety.filter(
    (_unit) => _unit.residentsList.length === 0
  )

  const getManagementCallout = (): SocietySideBarCallout | null => {
    const { billingUnits } = society
    const unitCountForSociety = unitsForSociety.length

    const numberOfUnitsWithResidents =
      unitCountForSociety - unitsForSocietyWithoutResidents.length

    if (societyLocked) {
      return {
        text: translate('societySideBar.callouts.societyLocked.title'),
        path: reverseUrl('management:billing-payment', {
          id: society._id,
        }),
        warning: true,
      }
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { active: activeTrial, daysLeft } = society.activeTrial
    if (activeTrial && daysLeft && daysLeft > 0) {
      return {
        text:
          daysLeft > 1
            ? translate(
                'societySideBar.callouts.societyTrial.titleDaysPlural',
                {
                  days: daysLeft,
                }
              )
            : translate('societySideBar.callouts.societyTrial.titleDays', {
                days: daysLeft,
              }),

        path: reverseUrl('management:billing-payment', {
          id: society._id,
        }),
      }
    }

    if (
      billingUnits &&
      unitCountForSociety < billingUnits * 0.9 &&
      unitCountForSociety
    ) {
      // unitCountForSociety should not be more than 10% lower than billingUnits
      if (isSamf) {
        return {
          text: translate(
            'societySideBar.callouts.incompleteRegister.titleSamf'
          ),
          path: reverseUrl('management:register-guidance', {
            id: society._id,
          }),
        }
      }
      return {
        text: translate('societySideBar.callouts.incompleteRegister.title'),
        path: reverseUrl('management:register-guidance', { id: society._id }),
      }
    }

    if (
      numberOfUnitsWithResidents < unitCountForSociety * 0.9 &&
      unitCountForSociety
    ) {
      return {
        text: `Endast ${numberOfUnitsWithResidents} av ${unitsForSociety.length} hushåll inloggade`,
        path: reverseUrl('management:member-guidance', { id: society._id }),
      }
    }

    if (
      !(numberOfUnitsWithResidents < unitCountForSociety * 0.9) &&
      !(billingUnits && unitCountForSociety < billingUnits * 0.9) &&
      unitCountForSociety
    ) {
      return null
    }

    return null
  }

  return getManagementCallout()
}
