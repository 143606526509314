import { observer } from 'mobx-react-lite'
import React from 'react'
import {
  getPartnerId,
  ManagementPartner,
} from '../../../components/society/ManagementPartner/management_partner'
import { societyHasActivatedPartner } from '../../../helpers/society'
import { useStores } from '../../../hooks/useStores'
import { UnauthorizedView } from '../../../views/error/UnauthorizedView'

interface PartnerRouteProps {
  children: React.ReactNode
  partner: ManagementPartner
}

const PartnerRoute = observer(
  ({ children, partner }: PartnerRouteProps): JSX.Element | null => {
    const { societyStore } = useStores()
    const { selectedSociety } = societyStore

    const canAccessPartnerRoute = societyHasActivatedPartner(
      selectedSociety,
      getPartnerId(partner)
    )

    return <>{canAccessPartnerRoute ? children : <UnauthorizedView />}</>
  }
)

PartnerRoute.displayName = 'PartnerRoute'
export { PartnerRoute }
