import React, { ButtonHTMLAttributes, CSSProperties } from 'react'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../Icon'
import { Spinner } from '../Spinner'

interface ButtonBaseProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  wrapperClassName?: string
  disableTabIndex?: boolean
}

export const ButtonBase = React.forwardRef<HTMLButtonElement, ButtonBaseProps>(
  function ButtonBase(
    {
      children,
      type,
      className,
      disabled,
      wrapperClassName,
      disableTabIndex,
      ...props
    }: ButtonBaseProps,
    ref
  ): JSX.Element {
    const tabIndex = disableTabIndex ? { tabIndex: -1 } : {}
    return (
      <div className={`inline-block ${wrapperClassName ?? ''}`}>
        <button
          ref={ref}
          disabled={disabled}
          type={type}
          className={`
            flex flex-row items-center whitespace-nowrap
            ${className ?? ''}
            ${disabled ? 'cursor-not-allowed opacity-50' : ''}
            `}
          {...props}
          {...tabIndex}
        >
          {children}
        </button>
      </div>
    )
  }
)

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DEFAULT = 'default',
  TEXT = 'text',
  DANGER = 'danger',
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string | JSX.Element | undefined
  icon?: IconChoices
  iconColor?: string
  iconSize?: number
  loading?: boolean
  wrapperClassName?: string
  size?: 'xs' | 'sm' | 'sm-wide' | 'md' | 'lg' | 'no-padding'
  variant?: ButtonVariant
  iconPlacement?: 'left' | 'right'
  type?: 'submit' | 'reset' | 'button'
  justifyContent?: string
  useFocus?: boolean
  avatar?: JSX.Element | undefined
  round?: boolean
  disableTabIndex?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      label,
      type = 'button',
      disabled,
      className,
      wrapperClassName,
      icon,
      iconSize,
      iconColor,
      loading,
      size,
      variant = ButtonVariant.DEFAULT,
      iconPlacement = 'left',
      justifyContent = 'justify-center',
      useFocus = false,
      avatar,
      round = false,
      disableTabIndex = false,
      ...props
    }: ButtonProps,
    ref
  ): JSX.Element {
    const getIconColor = (): string => {
      if (iconColor) {
        return iconColor
      }
      switch (variant) {
        case 'primary':
          return 'white'
        case 'danger':
          return 'red'
        default:
          return 'black'
      }
    }

    const getButtonSize = (): string => {
      switch (size) {
        case 'xs':
          return 'px-2 py-1'
        case 'sm':
          return 'px-4 py-3'
        case 'sm-wide':
          return 'px-7 py-3'
        case 'lg':
          return 'px-6 py-4'
        case 'no-padding':
          return 'p-0'
        default: // sm
          return 'px-4 py-3'
      }
    }

    const getIconSize = (): number => {
      switch (size) {
        case 'sm-wide':
          return 16
        case 'sm':
          return 16
        default:
          return 24
      }
    }

    const getLabelTextStyle = (): CSSProperties => {
      switch (size) {
        case 'xs':
          return { ...theme.textVariants.captionBold }
        case 'sm':
          return { ...theme.textVariants.caption }
        default:
          return { ...theme.textVariants.base }
      }
    }

    const getButtonColor = (): string => {
      switch (variant) {
        case ButtonVariant.PRIMARY:
          return `bg-black border border-black text-white ${
            !disabled ? 'hover:bg-black-hover' : ''
          } focus:outline-blue/50`
        case ButtonVariant.SECONDARY:
          return `bg-gray-light text-primary-black ${
            !disabled ? 'hover:bg-neutral-90' : ''
          } focus:outline-blue/50`
        case ButtonVariant.TEXT:
          return `bg-white text-black border-none
          ${!disabled ? 'hover:bg-gray-light' : ''} focus:outline-sbab-blue/50`
        case ButtonVariant.DANGER:
          return `bg-white border
            border-solid border-red text-red
            ${!disabled ? 'hover:bg-red/10' : ''}
            focus:outline-blue/50
            `
        default: // default white button
          return `bg-white text-black border
            border-solid border-neutral-80
            ${!disabled ? 'hover:bg-gray-light' : ''}
            focus:outline-blue/50
            `
      }
    }

    const buttonSize = getButtonSize()
    const buttonColor = getButtonColor()
    const textStyle = getLabelTextStyle()
    const _iconColor = getIconColor()
    const _spinnerColor = variant === 'primary' ? 'white' : 'brandGreen'

    return (
      <ButtonBase
        ref={ref}
        type={type}
        className={`${buttonColor} ${buttonSize}
          ${useFocus ? 'focus:outline focus:outline-1' : ''}
          ${round ? 'rounded-full' : 'rounded'}
          ${className ?? ''}`}
        disabled={disabled}
        style={textStyle}
        wrapperClassName={wrapperClassName}
        disableTabIndex={disableTabIndex}
        {...props}
      >
        <div
          className={`relative flex min-w-0 grow items-center gap-x-1.5 ${justifyContent}
            ${iconPlacement === 'right' ? 'flex-row-reverse' : 'flex-row'}
          `}
        >
          {loading && (
            <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
              <Spinner color={_spinnerColor} />
            </div>
          )}
          {icon && (
            <Icon
              className={`${loading ? 'invisible' : ''}`}
              icon={icon}
              color={_iconColor}
              size={iconSize || getIconSize()}
            />
          )}
          {(label || avatar) && (
            <div
              className={`flex h-6 min-w-0 shrink items-center gap-x-2 text-ellipsis
                ${loading ? 'invisible' : ''}
              `}
              style={theme.textVariants.base}
            >
              {avatar}
              {label}
            </div>
          )}
        </div>
      </ButtonBase>
    )
  }
)
