/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import {
  getEntrancesPerSociety as apiGetEntrancesPerSociety,
  patchEntrance as apiPatchEntrance,
  createEntrance as apiCreateEntrance,
  deleteEntrance as apiDeleteEntrance,
} from '../../api/society-entrances'
import { stateType } from '../types/common'
import { setObject } from './helpers'
import { SocietyEntranceModel } from '../models/society-entrance'
import { ISocietyEntranceModel } from '../../interfaces/models/society-entrance.interfaces'
import { NSocietyEntrances } from '../../interfaces/services/society-entrances.interfaces'
import { sortAdresses } from '../../helpers/sorting'

export const SocietyEntranceStore = types
  .model('SocietyEntranceStore')
  .props({
    entrances: types.map(SocietyEntranceModel),
    fetchingEntrances: stateType,
    updatingEntrance: stateType,
    creatingEntrance: stateType,
  })
  .views((self) => ({
    entrancesForSociety(
      societyId: string
    ): SnapshotOut<typeof SocietyEntranceModel>[] {
      return (
        // @ts-ignore
        (values(self.entrances) as SnapshotOut<typeof SocietyEntranceModel>[])
          .filter((entrance) => entrance.societyId === societyId)
          .sort((a, b) => sortAdresses(a.addressStreet, b.addressStreet))
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.entrances.clear()
      self.fetchingEntrances = 'none'
      self.updatingEntrance = 'none'
      self.creatingEntrance = 'none'
    },
    setEntrances: (entrances: ISocietyEntranceModel[]) => {
      entrances.forEach((entrance) => {
        setObject<typeof SocietyEntranceModel>(
          // @ts-ignore
          self.entrances,
          SocietyEntranceModel,
          entrance
        )
      })
    },
  }))
  .actions((self) => ({
    getEntrancesPerSociety: flow(function* getEntrancesPerSociety(
      societyId: string
    ) {
      self.fetchingEntrances = 'pending'
      try {
        const resp = yield apiGetEntrancesPerSociety(societyId)
        const data = resp.data as NSocietyEntrances.NGetPerSocieties.IResponse
        const entrances = data.data
        self.setEntrances(entrances)

        self.fetchingEntrances = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingEntrances = 'error'
      }
    }),
    patchEntrance: flow(function* patchEntrance(
      id: string,
      body: NSocietyEntrances.NPatch.IRequestBody
    ) {
      self.updatingEntrance = 'pending'
      try {
        const resp = yield apiPatchEntrance(id, body)
        const data = resp.data as NSocietyEntrances.NPatch.IResponse
        const entrance = data.data

        if (entrance !== null) {
          self.setEntrances([entrance])
        }

        self.updatingEntrance = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingEntrance = 'error'
        return false
      }
    }),
    createEntrance: flow(function* createEntrance(
      body: NSocietyEntrances.NCreate.IRequestBody
    ) {
      self.creatingEntrance = 'pending'
      try {
        const resp = yield apiCreateEntrance(body)
        const data = resp.data as NSocietyEntrances.NCreate.IResponse
        const entrance = data.data

        if (entrance !== null) {
          self.setEntrances([entrance])
        }

        self.creatingEntrance = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingEntrance = 'error'
        return false
      }
    }),
    deleteEntrance: flow(function* deleteEntrance(id: string) {
      try {
        yield apiDeleteEntrance(id)
        self.entrances.delete(id)
        return true
      } catch (error) {
        captureException(error)
        return false
      }
    }),
  }))
