import React from 'react'
import { Link } from 'react-router-dom'
import { Icon, IconChoices } from '../Icon'

interface InlineLinkInnerProps {
  text: string
}

export const InlineLinkInner = ({
  text,
}: InlineLinkInnerProps): JSX.Element => {
  return (
    <>
      <span className="mr-1 underline">{text}</span>
      <Icon icon={IconChoices.EXTERNAL_LINK} size={14} />
    </>
  )
}

interface InlineLinkProps {
  text: string
  url: string
  urlIsExternal: boolean
}

export const InlineLink = ({
  text,
  url,
  urlIsExternal,
}: InlineLinkProps): JSX.Element => {
  return urlIsExternal ? (
    <a
      href={url}
      target="_blank"
      rel="noreferrer noopener"
      className="flex items-center"
    >
      <InlineLinkInner text={text} />
    </a>
  ) : (
    <Link className="flex items-center" to={url}>
      <InlineLinkInner text={text} />
    </Link>
  )
}
