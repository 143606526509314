import React from 'react'
import { addMilliseconds } from 'date-fns'
import { theme } from '../../../../theme/theme'
import { ITimeSlot } from '../FacilityCalendar/interfaces'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'

interface TimeSlotTimeProps extends ITimeSlot {
  fullDay?: boolean
  prefix?: string
  className?: string
}

export const TimeSlotTime = ({
  startDate,
  endDate,
  fullDay,
  prefix,
  className,
}: TimeSlotTimeProps): JSX.Element | null => {
  const { translate } = useAppTranslation()
  return (
    <p style={theme.textVariants.base} className={`${className ?? ''}`}>
      {fullDay ? (
        translate('facilityView.calendar.fullDay')
      ) : (
        <>
          {prefix ? `${prefix} ` : ''}
          {/* Pad getUTCHours instead of formatting for convience with timezones */}
          {`${startDate.getUTCHours()}`.padStart(2, '0')}:
          {`${startDate.getUTCMinutes()}`.padStart(2, '0')}
          {' - '}
          {`${addMilliseconds(endDate, 1).getUTCHours()}`.padStart(2, '0')}:
          {`${addMilliseconds(endDate, 1).getUTCMinutes()}`.padStart(2, '0')}
        </>
      )}
    </p>
  )
}
