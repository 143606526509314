import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useNavigate } from 'react-router-dom'
import { UserModel } from '../../../../state/models/user'
import { theme } from '../../../../theme/theme'
import { UserAvatar } from '../../../common/Avatar'
import { Card } from '../../../common/Card'
import { CardBody } from '../../../common/Card/CardBody'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { UserInterestBadge } from './UserInterestBadge'
import { Badge } from '../../../common/Badge'

interface UserMatchProps {
  user: SnapshotOut<typeof UserModel>
}

/*
  In this component we are calculating how many badges to render inside 
  the card. This is dependent on the cards bounds (compared with each badge).
  We then find the correct amount of badges to render and remove one 
  badge of those to fit a badge that contains a number for the badges
  that are not rendered
*/
export const UserMatch = observer(({ user }: UserMatchProps) => {
  const navigate = useNavigate()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const cardRef = useRef<HTMLDivElement>(null)
  const [badgeIndexes, setBadgeIndexes] = useState<number[]>([])

  const navigateToUser = (): void => {
    navigate(
      reverseUrl('user:society-detail', {
        id: user._id,
        societyId: society._id,
      })
    )
  }

  const lowestIndexOutOfBounds = Math.min(...badgeIndexes)

  const updateIndexOutOfBounds = (index: number): void => {
    if (index < lowestIndexOutOfBounds) {
      setBadgeIndexes((prev) => [...prev, index])
    }
  }

  const interests = user.aboutInfo?.interests

  const cardBottomLimit = cardRef.current?.getBoundingClientRect().bottom

  const numberOfInterestsNotRendered =
    interests && interests.length - lowestIndexOutOfBounds + 1

  return (
    <Card
      key={user._id}
      onClick={navigateToUser}
      className="max-h-28 w-full md:w-80"
      ref={cardRef}
    >
      {{
        body: (
          <CardBody className="flex items-start justify-between">
            <div className="flex min-w-0 flex-col gap-2 text-left">
              <p
                className="inline-block overflow-hidden text-ellipsis whitespace-nowrap"
                style={theme.textVariants.captionBold}
                title={user.fullName}
                translate="no"
              >
                {user.fullName}
              </p>
              <div className="w-50 flex flex-wrap gap-1">
                {interests?.map((interest, index) => (
                  <UserInterestBadge
                    key={interest}
                    interest={interest}
                    cardBottomLimit={cardBottomLimit}
                    index={index}
                    updateIndexOutOfBounds={updateIndexOutOfBounds}
                    lowestIndexOutOfBounds={lowestIndexOutOfBounds}
                  />
                ))}
                {interests &&
                  interests.length > 1 &&
                  badgeIndexes.length > 0 && (
                    <Badge
                      padding="px-1.5"
                      colorClass="bg-brandGreen/10"
                      textColorClass="text-brandGreen"
                      borderColorClass="border-brandGreen"
                      text={`+${numberOfInterestsNotRendered}`}
                    />
                  )}
              </div>
            </div>
            <UserAvatar user={user} size={52} />
          </CardBody>
        ),
      }}
    </Card>
  )
})
