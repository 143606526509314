import * as yup from 'yup'

export const societyHiddenWidgetsSchema = yup
  .object({
    board: yup.boolean(),
    contacts: yup.boolean(),
    calendar: yup.boolean(),
    documents: yup.boolean(),
    faqs: yup.boolean(),
    facilities: yup.boolean(),
    rules: yup.boolean(),
    sales: yup.boolean(),
    reportProblem: yup.boolean(),
    // stays: yup.boolean(),
    'local-offers': yup.boolean(),
    marketplace: yup.boolean(),
    services: yup.boolean(),
    'find-friends': yup.boolean(),
  })
  .required()
