import { AxiosResponse } from 'axios'
import { createUrl } from './helpers'
import axiosAuth from './config'
import { NSocietyEntrances } from '../interfaces/services/society-entrances.interfaces'

export const getEntrancesPerSociety = (
  societyId: string
): Promise<AxiosResponse<NSocietyEntrances.NGetPerSocieties.IResponse>> => {
  const body: NSocietyEntrances.NGetPerSocieties.IRequestBody = {
    societyIds: [societyId],
  }
  return axiosAuth.post<NSocietyEntrances.NGetPerSocieties.IResponse>(
    createUrl(`/society-entrances/get-per-societies`),
    body
  )
}

export const patchEntrance = (
  id: string,
  body: NSocietyEntrances.NPatch.IRequestBody
): Promise<AxiosResponse<NSocietyEntrances.NPatch.IResponse>> => {
  return axiosAuth.patch<NSocietyEntrances.NPatch.IResponse>(
    createUrl(`/society-entrances/entrance/${id}`),
    body
  )
}

export const createEntrance = (
  body: NSocietyEntrances.NCreate.IRequestBody
): Promise<AxiosResponse<NSocietyEntrances.NCreate.IResponse>> => {
  return axiosAuth.post<NSocietyEntrances.NCreate.IResponse>(
    createUrl(`/society-entrances/entrance`),
    body
  )
}

export const deleteEntrance = (
  id: string
): Promise<AxiosResponse<NSocietyEntrances.NDel.IResponse>> => {
  return axiosAuth.delete<NSocietyEntrances.NDel.IResponse>(
    createUrl(`/society-entrances/entrance/${id}`)
  )
}
