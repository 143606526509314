import { AxiosResponse } from 'axios'
import { createUrl } from './helpers'
import axiosAuth from './config'
import { NSocietyWidgets } from '../interfaces/services/society-widgets.interfaces'

export const getWidgetsPerSociety = (
  societyId: string
): Promise<AxiosResponse<NSocietyWidgets.NGetPerSocieties.IResponse>> => {
  return axiosAuth.post<NSocietyWidgets.NGetPerSocieties.IResponse>(
    createUrl('/society-widgets/get-per-societies'),
    { societyIds: [societyId] }
  )
}

export const createWidget = (
  body: NSocietyWidgets.NCreate.IRequestBody
): Promise<AxiosResponse<NSocietyWidgets.NCreate.IResponse>> => {
  return axiosAuth.post<NSocietyWidgets.NCreate.IResponse>(
    createUrl(`/society-widgets/widget`),
    body
  )
}

export const getWidget = (
  id: string
): Promise<AxiosResponse<NSocietyWidgets.NGetById.IResponse>> => {
  return axiosAuth.get<NSocietyWidgets.NGetById.IResponse>(
    createUrl(`/society-widgets/widget/${id}`)
  )
}

export const patchWidget = (
  id: string,
  body: NSocietyWidgets.NPatch.IRequestBody
): Promise<AxiosResponse<NSocietyWidgets.NPatch.IResponse>> => {
  return axiosAuth.patch<NSocietyWidgets.NPatch.IResponse>(
    createUrl(`/society-widgets/widget/${id}`),
    body
  )
}

export const deleteWidget = (
  id: string
): Promise<AxiosResponse<NSocietyWidgets.NDel.IResponse>> => {
  return axiosAuth.delete<NSocietyWidgets.NDel.IResponse>(
    createUrl(`/society-widgets/widget/${id}`)
  )
}
