import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { FormControl } from '../../../../../../components/common/FormControl'
import { useFormErrorMessage } from '../../../../../../hooks/useFormErrorMessage'
import {
  Button,
  ButtonVariant,
} from '../../../../../../components/common/Button'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { SocietyModel } from '../../../../../../state/models/society'
import { TextInput } from '../../../../../../components/common/TextInput'
import { useStores } from '../../../../../../hooks/useStores'
import { formatOrganisationNumber } from '../../../../../../helpers/organisation_number'
import { OrganisationNumberTextInput } from '../../../../../../components/common/OrganisationNumberTextInput'
import { adminInvoiceDetailsSchema } from '../../../../../../forms/schemas/admin_edit_invoice_details'
import { DateTimeInput } from '../../../../../../components/common/DateTimeInput'

interface EditSocietyInvoiceModalContentProps {
  onError: (error: unknown) => void
  onSubmit: (data: InferType<typeof adminInvoiceDetailsSchema>) => Promise<void>
  onClose: () => void
  society?: SnapshotOut<typeof SocietyModel>
}

export const EditSocietyInvoiceModalContent = observer(
  ({
    onError,
    onSubmit,
    onClose,
    society,
  }: EditSocietyInvoiceModalContentProps): JSX.Element => {
    const { societyStore } = useStores()
    const { translate } = useAppTranslation()
    const { getErrorMessage } = useFormErrorMessage()
    const [
      alreadyExistingOrganisationNumber,
      setAlreadyExistingOrganisationNumber,
    ] = useState<string | null>(null)
    const [isOrganisationNumberFocused, setIsOrganisationNumberFocused] =
      useState(false)

    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
      watch,
    } = useForm({
      mode: 'all',
      resolver: yupResolver(adminInvoiceDetailsSchema),
      defaultValues: {
        organisationNumber: society?.organisationNumber
          ? `${society?.organisationNumber}`
          : '',
        addressBillingCo: society.addressBillingCo || '',
        addressBillingStreet: society.addressBillingStreet || '',
        addressBillingZip: society.addressBillingZip || '',
        addressBillingCity: society.addressBillingCity || '',
        billingEmail: society.billingEmail || '',
        billingCompany: society.name || '',
        billingRefText: society.billingRefText || '',
        lastInvoiceDate: society?.lastInvoiceDate
          ? new Date(society?.lastInvoiceDate)
          : undefined,
      },
    })

    const getSocietyByOrganisationNumber = useCallback(
      async (
        organisationNumber: string
      ): Promise<SnapshotOut<typeof SocietyModel> | null> => {
        const societies =
          await societyStore.getSocietiesByOrganisationNumber(
            organisationNumber
          )
        return societies.length > 0 ? societies[0] : null
      },
      [societyStore]
    )

    const watchOrganisationNumber = watch('organisationNumber')

    useEffect(() => {
      if (!watchOrganisationNumber) {
        return
      }

      const trimmedOrganisationNumber = watchOrganisationNumber.replace('-', '')

      const fetchSociety = async (): Promise<void> => {
        if (isOrganisationNumberFocused) {
          const existingSociety = await getSocietyByOrganisationNumber(
            trimmedOrganisationNumber
          )

          if (existingSociety) {
            setAlreadyExistingOrganisationNumber(trimmedOrganisationNumber)
          }
        }
      }

      if (trimmedOrganisationNumber.length === 10) {
        fetchSociety()
      }
    }, [
      isOrganisationNumberFocused,
      watchOrganisationNumber,
      getSocietyByOrganisationNumber,
    ])

    const parsedOrganisationNumber = `${society?.organisationNumber}`

    const customOrganisatioNumberError =
      watchOrganisationNumber &&
      watchOrganisationNumber.replace('-', '') !==
        parsedOrganisationNumber?.replace('-', '') &&
      watchOrganisationNumber.replace('-', '') ===
        alreadyExistingOrganisationNumber?.replace('-', '')
        ? {
            message: translate(
              'createSocietyForm.errors.societyAlreadyActivated'
            ),
            ref: {
              name: 'organisationNumber',
            },
            type: 'matches',
          }
        : undefined

    return (
      <form
        className="flex h-full flex-col gap-6"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('invoiceView.details.form.labels.recipient')}
              error={
                errors.billingEmail && getErrorMessage(errors.billingEmail)
              }
              note={{ required: true }}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.billingEmail}
                placeholder="exempel@boappa.se"
                type="email"
              />
            </FormControl>
          )}
          name="billingEmail"
        />

        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('common.form.labels.reference')}
              error={
                errors.billingRefText && getErrorMessage(errors.billingRefText)
              }
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.billingRefText}
              />
            </FormControl>
          )}
          name="billingRefText"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('common.form.labels.society')}
              error={
                errors.billingCompany && getErrorMessage(errors.billingCompany)
              }
              name={name}
              note={{ locked: true }}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled
                error={!!errors.billingCompany}
              />
            </FormControl>
          )}
          name="billingCompany"
        />
        <Controller
          control={control}
          name="organisationNumber"
          render={({ field: { value, name, onChange, onBlur } }) => {
            const formattedValue = value ? formatOrganisationNumber(value) : ''
            return (
              <FormControl
                name={name}
                label={translate('common.form.labels.organisationNumber')}
                error={
                  errors.organisationNumber
                    ? getErrorMessage(errors.organisationNumber)
                    : customOrganisatioNumberError &&
                      getErrorMessage(customOrganisatioNumberError)
                }
              >
                <OrganisationNumberTextInput
                  className="pl-2"
                  value={formattedValue}
                  onChange={onChange}
                  onBlur={() => {
                    setIsOrganisationNumberFocused(false)
                    if (onBlur) {
                      onBlur()
                    }
                  }}
                  onFocus={() => {
                    setIsOrganisationNumberFocused(true)
                  }}
                />
              </FormControl>
            )
          }}
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('common.form.labels.addressCareOf')}
              error={
                errors.addressBillingCo &&
                getErrorMessage(errors.addressBillingCo)
              }
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.addressBillingCo}
              />
            </FormControl>
          )}
          name="addressBillingCo"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('invoiceView.details.form.labels.billingStreet')}
              error={
                errors.addressBillingStreet &&
                getErrorMessage(errors.addressBillingStreet)
              }
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.addressBillingStreet}
              />
            </FormControl>
          )}
          name="addressBillingStreet"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('common.form.labels.addressZip')}
              error={
                errors.addressBillingZip &&
                getErrorMessage(errors.addressBillingZip)
              }
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.addressBillingZip}
              />
            </FormControl>
          )}
          name="addressBillingZip"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('common.form.labels.addressCity')}
              error={
                errors.addressBillingCity &&
                getErrorMessage(errors.addressBillingCity)
              }
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.addressBillingCity}
              />
            </FormControl>
          )}
          name="addressBillingCity"
        />
        <Controller
          control={control}
          name="lastInvoiceDate"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label="Senast skickade faktura"
              error={
                errors.lastInvoiceDate &&
                getErrorMessage(errors.lastInvoiceDate)
              }
              name={name}
            >
              <DateTimeInput
                onChange={(date) => {
                  onChange(date)
                }}
                value={value}
              />
            </FormControl>
          )}
        />
        <div className="flex flex-wrap justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid && !customOrganisatioNumberError}
            label={translate('common.actions.save')}
            type="submit"
          />
        </div>
      </form>
    )
  }
)
