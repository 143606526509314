import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Section, SectionList } from '../../common/SectionList'
import { BoardListItem } from './BoardListItem'
import { BoardRole } from '../../../types/board-roles'
import { translateRole } from '../../../helpers/society'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { sortBoardMembersList } from './sortBoardMembersList'
import { BoardMemberModel } from '../../../state/models/society'

export const BoardList = observer((): JSX.Element => {
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }

  const getSections = useCallback((): Section[] => {
    return [
      {
        title: '',
        item: (
          <div>
            {sortBoardMembersList(society).map((member) => {
              return <BoardListItem key={member.userId} boardMember={member} />
            })}
          </div>
        ),
      },
      ...(society.nominatingCommitteeList &&
      society.nominatingCommitteeList.length > 0
        ? [
            {
              title: translateRole(BoardRole.NOMINATING_COMMITTEE) || '',
              item: (
                <div>
                  {society.nominatingCommitteeList?.map((member) => {
                    const boardMember = {
                      userId: member.userId,
                      role: BoardRole.NOMINATING_COMMITTEE,
                    } as SnapshotOut<typeof BoardMemberModel>
                    return (
                      <BoardListItem
                        key={boardMember.userId}
                        boardMember={boardMember}
                      />
                    )
                  })}
                </div>
              ),
            },
          ]
        : []),
    ]
  }, [society])

  return <SectionList sections={getSections()} />
})
