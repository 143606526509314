/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'

export const NotificationPreferencesAuthor = types.enumeration(
  'NotificationPreferencesAuthor',
  ['meAsAuthor', 'residents', 'board', 'admin', 'boStore']
)

export const NotificationType = types.enumeration('NotificationType', [
  'email-verification',
  'email-verification-link',
  'hey-new-posts',
  'hey-new-comments',
  'hey-new-likes',
  'feed-new-likes',
  'feed-new-comments',
  'chat-interests-new-channel',
  'chat-board-new-message',
  'chat-interests-new-message',
  'chat-oneonone-new-message',
  'chat-questions-new-message',
  'chat-questions-new-message-residents',
  'chat-questions-new-message-board',
  'service-report',
  'admins-society-request-accepted',
  'send-reset-password',
  'send-reset-password-link',
  'password-reset-success',
  'society-request-accepted',
  'society-request-shared-home',
  'society-request-member',
  'society-request-non-member',
  'society-activated-shared',
  'society-activated-legal',
  'society-activated-nonlegal',
  'register-upload-instructions',
  'invite-all-to-household',
  'invite-all-to-society',
  'invite-all-admin-to-society',
  'invite-all-board-to-society',
  'invite-to-neighbourhood',
  'invite-to-shared-home',
  'invite-to-apartment-building',
  'user-added-to-unit',
  'report-post',
  'bostore-reminder',
  'bostore-lead-b2c',
  'bostore-lead-b2b',
  'bostore-hemfixare-form',
  'bostore-stolt-smide-design-form',
  'bostore-energy-declaration-homeowner-form',
  'bostore-radon-inspection',
  'bostore-sales-inspection',
  'bostore-status-inspection',
  'bostore-suggestion-email',
  'bostore-pro-energy-declaration-form',
  'bostore-pro-maintenance-plan',
  'bostore-pro-radon-inspection',
  'bostore-pro-guestit-society-form',
  'bostore-pro-guestit-resident-form',
  'bostore-pro-ovk',
  'local-offer-new',
  'partner-ask-support',
  'partner-activate-society',
  'app-new-inspirations',
  'facility-booking-new',
  'feed-new-posts', // Legacy type that exists on Mats account but specified nowhere.
  'service-report-coop', // Not defined in interfaces
  'service-report-hoa',
  'contract-monitoring',
])

export const NotificationModel = types
  .model('Notification', {
    _id: types.identifier,
    ...timestampTypes,
    societyId: types.maybe(types.string),
    data: types.maybe(types.string),
    isRead: types.boolean,
    isSeen: types.boolean,
    primaryDocId: types.maybe(types.string),
    secondaryDocId: types.maybe(types.string),
    authorId: types.maybe(types.string),
    type: NotificationType,
  })
  .actions((self) => ({
    setRead() {
      self.isRead = true
    },
    setUnread() {
      self.isRead = false
    },
    setSeen() {
      self.isSeen = true
    },
  }))
