import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'

import { SocietyModel } from '../../../state/models/society'
import { Callout } from '../Callout'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { reverseUrl } from '../../../navigation/reverseUrl'

export interface LockedSocietiesCallout {
  societies: SnapshotOut<typeof SocietyModel>[]
  className?: string
}

export const LockedSocietiesCallout = observer(
  ({ societies, className }: LockedSocietiesCallout): JSX.Element => {
    const { translate } = useAppTranslation()
    return (
      <div className={`flex flex-col gap-2 ${className ?? ''}`}>
        {societies.map((society) => (
          <Callout
            key={society._id}
            text={translate('common.callout.namedSocietyLocked.title', {
              societyName: society.name,
            })}
            path={reverseUrl('management:billing-payment', {
              id: society._id,
            })}
            warning
          />
        ))}
      </div>
    )
  }
)
