/* eslint-disable max-len */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { SnapshotOut } from 'mobx-state-tree'
import { Modal } from '../../../../../../common/Modal'
import { ModalHeader } from '../../../../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../../../../hooks/useAppTranslation'
import { useStores } from '../../../../../../../hooks/useStores'
import { ToastType } from '../../../../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../../../../hooks/useToastNotification'
import { societyWebsiteFAQQuestionCreateSchema } from '../../../../../../../forms/schemas/society_website_faq_question_create'
import { CreateUpdateSocietyWebsiteFAQItemForm } from '../CreateUpdateSocietyWebsiteFAQItemForm'
import { NSocietiesWebsites } from '../../../../../../../interfaces/services/societies-websites.interfaces'
import { SocietyWebsiteModel } from '../../../../../../../state/models/society-website'

interface CreateUpdateSocietyWebsiteFAQItemModalProps {
  website: SnapshotOut<typeof SocietyWebsiteModel>
  show: boolean
  close: () => void
  children?: React.ReactNode
  questionId?: string
  sectionId?: string
}

export const CreateUpdateSocietyWebsiteFAQItemModal = observer(
  ({
    website,
    questionId,
    sectionId,
    children,
    show,
    close,
  }: CreateUpdateSocietyWebsiteFAQItemModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { societyWebsitesStore } = useStores()
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!questionId

    const showErrorMessage = (): void => {
      const message = updateMode
        ? 'createUpdateQuestionsAndAnswers.flashMessage.updateQuestionFailure'
        : 'createUpdateQuestionsAndAnswers.flashMessage.createQuestionFailure'
      setToastNotification(ToastType.DANGER, translate(message))
    }

    const showSuccessMessage = (): void => {
      const message = updateMode
        ? 'createUpdateQuestionsAndAnswers.flashMessage.updateQuestionSuccess'
        : 'createUpdateQuestionsAndAnswers.flashMessage.createQuestionSuccess'
      setToastNotification(ToastType.SUCCESS, translate(message))
    }

    const onSubmit = async (
      data: InferType<typeof societyWebsiteFAQQuestionCreateSchema>
    ): Promise<void> => {
      if (updateMode) {
        const formData: NSocietiesWebsites.NPatch.IRequestBody = {
          brokerQuestion: {
            ...website.brokerQuestion,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sections: website.brokerQuestion?.sections?.map((_section) => ({
              ..._section,
              questions: _section.questions?.map((_question) => {
                if (_question._id === questionId) {
                  return {
                    ..._question,
                    ...data,
                  }
                }
                return _question
              }),
            })),
          },
        }
        const status = await societyWebsitesStore.patchWebsite(
          website._id,
          formData
        )
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      } else {
        const formData: NSocietiesWebsites.NPatch.IRequestBody = {
          brokerQuestion: {
            ...website.brokerQuestion,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sections: website.brokerQuestion?.sections?.map((_section) => {
              if (_section._id === sectionId) {
                return {
                  ..._section,
                  questions: [
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ..._section.questions,
                    {
                      ...data,
                      order: _section.questions ? _section.questions.length : 0,
                    },
                  ],
                }
              }
              return _section
            }),
          },
        }
        const status = await societyWebsitesStore.patchWebsite(
          website._id,
          formData
        )
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      showErrorMessage()
    }

    const loading = societyWebsitesStore.updatingWebsite === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate(
                  updateMode
                    ? 'createUpdateQuestionsAndAnswers.editQuestionTitle'
                    : 'createUpdateQuestionsAndAnswers.addNewQuestionTitle'
                )}
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <CreateUpdateSocietyWebsiteFAQItemForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  sectionId={sectionId}
                  questionId={questionId}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
