import { useMemo } from 'react'
import { getCountries } from '../../../helpers/country'
import { sortStringsAlphabetically } from '../../../helpers/sorting'
import {
  getEducationDegree,
  getEducationField,
  getEducationLevel,
} from '../../../helpers/translations/education'
import { getGender } from '../../../helpers/translations/genders'
import { getInterest } from '../../../helpers/translations/interests'
import { getOccupationIndustry } from '../../../helpers/translations/occupation'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import {
  EducationDegree,
  EducationField,
  EducationLevel,
} from '../../../types/education'
import { Genders } from '../../../types/gender'
import { Interests } from '../../../types/interests'
import { OccupationIndustry } from '../../../types/occupation'
import { IDropdownSelectOption } from '../../common/DropdownSelect/IDropdownSelectOption'
import { useLocale } from '../../../hooks/useLocale'

export const useDropdownOptions = (): {
  genderOptions: IDropdownSelectOption[]
  occupationIndustryOptions: IDropdownSelectOption[]
  educationLevelOptions: IDropdownSelectOption[]
  educationDegreeOptions: IDropdownSelectOption[]
  educationFieldOptions: IDropdownSelectOption[]
  interestsOptions: IDropdownSelectOption[]
  countryOptions: IDropdownSelectOption[]
} => {
  const { translate } = useAppTranslation()
  const locale = useLocale()

  const genderOptions = useMemo(
    () =>
      Object.values(Genders).map((val) => ({
        value: val as string,
        label: translate(getGender(val) as string),
      })),
    [translate]
  )

  const occupationIndustryOptions = useMemo(
    () =>
      Object.values(OccupationIndustry).map((val) => ({
        value: val as string,
        label: translate(getOccupationIndustry(val) as string),
      })),
    [translate]
  )

  const educationLevelOptions = useMemo(
    () =>
      Object.values(EducationLevel).map((val) => ({
        value: val as string,
        label: translate(getEducationLevel(val) as string),
      })),
    [translate]
  )

  const educationDegreeOptions = useMemo(
    () =>
      Object.values(EducationDegree).map((val) => ({
        value: val as string,
        label: translate(getEducationDegree(val) as string),
      })),
    [translate]
  )

  const educationFieldOptions = useMemo(
    () =>
      Object.values(EducationField).map((val) => ({
        value: val as string,
        label: translate(getEducationField(val) as string),
      })),
    [translate]
  )

  const interestsOptions = useMemo(
    () =>
      Object.values(Interests)
        .map((val) => ({
          value: val as string,
          label: translate(getInterest(val) as string),
        }))
        .sort((a, b) =>
          sortStringsAlphabetically(a.label || '', b.label || '')
        ),
    [translate]
  )

  const countryOptions = getCountries(
    locale && locale.match('en') ? 'en' : 'sv'
  )

  return {
    genderOptions,
    occupationIndustryOptions,
    educationLevelOptions,
    educationDegreeOptions,
    educationFieldOptions,
    interestsOptions,
    countryOptions,
  }
}
