import { atom } from 'jotai'

export enum ChatHeaderState {
  None = 0,
  StartNewChat = 1,
  StartNewBoardChat = 2,
  JoinChat = 3,
  AskBoardNewMessage = 4,
  FindArchivedChatsResident = 5,
  FindArchivedChats = 6,
}

export const chatHeaderStateAtom = atom(ChatHeaderState.None)

export enum ChatErrorState {
  None = 0,
  EmptyAskBoard = 1,
  PendingRequest = 2,
  NoSociety = 3,
  ChatDisabled = 4,
  EmptyChat = 5,
  EmptyBoardChat = 6,
  EmptyBoardAnswerResident = 7,
}

export const chatErrorStateAtom = atom(ChatErrorState.None)
