import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../hooks/useStores'
import { PollModel } from '../../../state/models/poll'
import { PostPoll } from '../PostPoll'

interface PostPollsProps {
  pollIds: string[]
  disabled?: boolean
}

export const PostPolls = observer(
  ({ pollIds, disabled }: PostPollsProps): JSX.Element => {
    const { pollStore } = useStores()

    const polls = pollIds
      .map((_id): SnapshotOut<typeof PollModel> | undefined =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pollStore.polls.get(_id)
      )
      .filter((value): value is SnapshotOut<typeof PollModel> => !!value)

    return (
      <>
        {polls &&
          polls.length > 0 &&
          polls.map((poll) => (
            <div key={poll._id} className="flex flex-col">
              <div className="my-0 border-b border-neutral-80 first:hidden" />
              <PostPoll poll={poll} disabled={disabled} />
            </div>
          ))}
      </>
    )
  }
)
