/* eslint-disable @typescript-eslint/no-explicit-any */
export const uniqueBy = <T>(uniqueKey: keyof T, objects: T[]): T[] => {
  const ids = objects.map((object) => object[uniqueKey])
  return objects.filter(
    (object, index) => !ids.includes(object[uniqueKey], index + 1)
  )
}

export const unique = <T>(array: T[]): T[] => {
  function onlyUnique(value: T, index: number, self: T[]): boolean {
    return self.indexOf(value) === index
  }
  return array.filter(onlyUnique)
}

export const flatten = <T>(arr: T[][]): T[] => {
  return arr.reduce((flat, toFlatten: any) => {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    )
  }, [])
}

export const sliceIntoChunks = <T>(arr: T[], chunkSize: number): T[][] => {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

export function mapAsync<T, U>(
  array: T[],
  callbackfn: (value: T, index: number, array: T[]) => Promise<U>
): Promise<U[]> {
  return Promise.all(array.map(callbackfn))
}

export async function filterAsync<T>(
  array: T[],
  callbackfn: (value: T, index: number, array: T[]) => Promise<boolean>
): Promise<T[]> {
  const filterMap = await mapAsync(array, callbackfn)
  return array.filter((value, index) => filterMap[index])
}

export function groupBy<T>(
  array: T[],
  fn: (item: T) => any
): { key: string; items: T[] }[] {
  return Object.entries(
    array.reduce<Record<string, T[]>>((prev, curr) => {
      const groupKey = fn(curr)
      const group = prev[groupKey] || []
      group.push(curr)
      return { ...prev, [groupKey]: group }
    }, {})
  ).map((item) => ({
    key: item[0],
    items: item[1],
  }))
}
