import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ViewBase } from '../../../../components/common/ViewBase'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useStores } from '../../../../hooks/useStores'
import { IconChoices } from '../../../../components/common/Icon'
import { theme } from '../../../../theme/theme'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { Button, ButtonVariant } from '../../../../components/common/Button'
import { IllustrationChoices } from '../../../../components/common/Illustration'
import { useModal } from '../../../../hooks/useModal'
import { FixedHeightMediaImageWithBackground } from '../../../../components/common/Image'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../../components/common/Toast/toast-type'
import { ConfirmationModal } from '../../../../components/common/ConfirmationModal'
// eslint-disable-next-line max-len
import { CreateUpdateMarketplaceItemModal } from '../../../../components/society/MarketplaceItem/CreateUpdateMarketplaceItemModal'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { formatRelative } from '../../../../helpers/date'
import { UserAvatar } from '../../../../components/common/Avatar'
import { useIsMobile } from '../../../../hooks/useIsMobile'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { MediaGalleryModal } from '../../../../components/common/MediaGalleryModal'
import { isUserAdminInSociety } from '../../../../helpers/society'

export const MarketplaceDetailView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { chatRoomStore, resourcesStore, userStore } = useStores()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const { setToastNotification } = useToastNotifications()
  const navigate = useNavigate()
  const { resourceId } = useParams()
  const isMobile = useIsMobile()
  const {
    show: showEditModal,
    open: openEditModal,
    close: closeEditModal,
  } = useModal()
  const {
    show: showDeleteConfirmationModal,
    open: openDeleteConfirmationModal,
    close: closeDeleteConfirmationModal,
  } = useModal()
  const {
    show: showMediaGalleryModal,
    open: openMediaGalleryModal,
    close: closeMediaGalleryModal,
  } = useModal()
  const userId = useAuthenticatedUserId() as string
  const userIsSocietyAdmin = isUserAdminInSociety(society, userId as string)

  const resource = resourceId
    ? resourcesStore.resources.get(resourceId)
    : undefined

  useDocumentTitle(
    reverseDocumentTitle('society:marketplace-detail', {
      '{{ resourceName }}': resource?.title,
      '{{ societyName }}': society.name,
    })
  )

  useEffect(() => {
    if (society._id) {
      resourcesStore.getResourcesPerSociety(society._id)
    }
  }, [resourcesStore, society._id])

  const listingUser = resource
    ? userStore.users.get(resource?.userId)
    : undefined

  const userCreatedListing = resource?.userId === userId

  const deleteMarketplaceItem = async (): Promise<void> => {
    if (resource) {
      const status = await resourcesStore.deleteResource(resource._id)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('marketplaceDetailView.flashMessage.deleteListingSuccess')
        )
        navigate(reverseUrl('society:marketplace', { id: society._id }))
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('marketplaceDetailView.flashMessage.deleteListingFailure')
        )
      }
    } else {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }
  }

  const goToChatRoom = async (): Promise<void> => {
    if (resource) {
      const chatRoom = await chatRoomStore.getUserRoom(resource.userId)
      chatRoom && navigate(reverseUrl('chat:detail', { id: chatRoom._id }))
      !chatRoom && navigate(reverseUrl('chat'))
    }
  }

  const goToUserProfile = (): void => {
    return navigate(
      reverseUrl('user:society-detail', {
        id: listingUser?._id,
        societyId: society._id,
      })
    )
  }

  const loading = resourcesStore.fetchingResources === 'pending'
  const loadingChat = chatRoomStore.fetchingChatRoom === 'pending'

  const buttons = [
    ...(userCreatedListing
      ? [
          {
            label: translate('common.actions.edit'),
            icon: IconChoices.EDIT,
            onClick: openEditModal,
          },
        ]
      : []),
    ...(userCreatedListing || userIsSocietyAdmin
      ? [
          {
            label: translate('common.actions.delete'),
            icon: IconChoices.DELETE_TRASH,
            variant: ButtonVariant.DANGER,
            onClick: openDeleteConfirmationModal,
          },
        ]
      : []),
  ]

  const errorView = !resource
    ? {
        title: translate('marketplaceDetailView.errors.notFound.title'),
        subtitle: translate('marketplaceDetailView.errors.notFound.subtitle'),
        illustration: IllustrationChoices.EMPTY,
      }
    : undefined

  const onImageClick = (): void => {
    openMediaGalleryModal()
  }

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('marketplaceListView.title')}
        showBackButton
        buttons={buttons}
        errorView={errorView}
        loading={loading}
      >
        <div className="w-full lg:w-1/2">
          {resource?.mediaId && (
            <FixedHeightMediaImageWithBackground
              mediaId={resource.mediaId}
              wrapperClassNames="mb-2 md:h-1/2 lg:h-1/4"
              onClick={onImageClick}
            />
          )}
          <p style={theme.textVariants.base} className="mb-4 text-neutral-30">
            {translate('marketplaceDetailView.labels.published')}:{' '}
            {resource && formatRelative(Date.parse(resource.createdAt))}
          </p>
          <p className="mb-3 " style={theme.textVariants.lead}>
            {resource?.title}
          </p>
          <p style={theme.textVariants.h3} className="mb-6 border-b pb-8">
            {resource?.isFree || resource?.cost === '0'
              ? translate('marketplaceListView.item.free')
              : resource?.costString}
          </p>
          <div className="mb-6 flex flex-col border-b pb-6">
            <p style={theme.textVariants.base} className="mb-1 text-neutral-30">
              {translate('marketplaceDetailView.labels.createdBy')}:
            </p>
            <div className="flex justify-between">
              <div className="flex items-center gap-3">
                <UserAvatar user={listingUser} />
                <p style={theme.textVariants.captionBold} translate="no">
                  {listingUser?.fullName}
                </p>
              </div>
              <div className="flex gap-3">
                {!userCreatedListing && (
                  <Button
                    icon={IconChoices.CHAT}
                    label={
                      !isMobile
                        ? translate('common.actions.message')
                        : undefined
                    }
                    loading={loadingChat}
                    onClick={goToChatRoom}
                  />
                )}
                <Button
                  icon={IconChoices.PERSON}
                  label={!isMobile ? translate('common.profile') : undefined}
                  onClick={goToUserProfile}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <p className="text-neutral-30" style={theme.textVariants.base}>
              {translate('common.description')}
            </p>
            <p style={theme.textVariants.base}>{resource?.description}</p>
          </div>
        </div>
      </ViewBase>
      <CreateUpdateMarketplaceItemModal
        show={showEditModal}
        close={closeEditModal}
        resource={resource}
      />
      <ConfirmationModal
        title={translate('marketplaceDetailView.confirmationModal.deleteTitle')}
        description={translate(
          'marketplaceDetailView.confirmationModal.deleteDescription'
        )}
        show={showDeleteConfirmationModal}
        close={closeDeleteConfirmationModal}
        onConfirm={deleteMarketplaceItem}
        deleteMode
      />
      {resource?.mediaId && (
        <MediaGalleryModal
          show={showMediaGalleryModal}
          close={closeMediaGalleryModal}
          mediaIds={[resource.mediaId]}
        />
      )}
    </ErrorBoundary>
  )
})
