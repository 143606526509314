import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Button, ButtonVariant } from '../../../common/Button'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { IconChoices } from '../../../common/Icon'
import { SocietyWebsiteModel } from '../../../../state/models/society-website'
import { openUrl } from '../../../../helpers/url'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { stringToSlug } from '../../../../helpers/slug'

interface SocietyWebsiteBottomBarProps {
  website: SnapshotOut<typeof SocietyWebsiteModel>
  isValid?: boolean
  loading?: boolean
}

export const SocietyWebsiteBottomBar = observer(
  ({
    website,
    isValid,
    loading,
  }: SocietyWebsiteBottomBarProps): JSX.Element => {
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { translate } = useAppTranslation()

    const websiteUrl = `https://boappa.se/${stringToSlug(
      `${website.slug}-${society.addressCity}`
    )}`

    const openWebsite = (): void => {
      openUrl(websiteUrl)
    }

    return (
      <div className="mt-6 mb-4 space-y-6 md:mt-10">
        <div className="border-t" />
        <div className="flex justify-between">
          <Button
            variant={ButtonVariant.DEFAULT}
            label={translate('societyWebsiteView.visitWebsite')}
            icon={IconChoices.WEBSITE}
            onClick={openWebsite}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            label={translate('common.actions.save')}
            disabled={!isValid}
            loading={loading}
            type="submit"
          />
        </div>
      </div>
    )
  }
)
