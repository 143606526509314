import { useCallback, useMemo } from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { uniqueBy } from '../../../helpers/array'
import { sortStringsAlphabetically } from '../../../helpers/sorting'
import { useStores } from '../../../hooks/useStores'
import { ChatRoomModel } from '../../../state/models/chat-room'
import { UserWithSocietyId } from '../StartNewChatHeader'
import { SocietyModel } from '../../../state/models/society'

export interface UserWithSocietyIdAndRoom {
  user: UserWithSocietyId
  room: SnapshotOut<typeof ChatRoomModel> | undefined
}

interface useUsersWithChatRoomProps {
  isGroupchat: boolean
  watchRecipients: unknown[] | undefined
  watchSociety: string | undefined
}

interface useUsersWithChatRoomReturnType {
  usersWithChatRoom: UserWithSocietyIdAndRoom[]
}

export const useUsersWithChatRoom = ({
  isGroupchat,
  watchRecipients,
  watchSociety,
}: useUsersWithChatRoomProps): useUsersWithChatRoomReturnType => {
  const { societyStore, unitStore, authenticationStore, chatRoomStore } =
    useStores()

  const getUserSocieties = useCallback((): SnapshotOut<
    typeof SocietyModel
  >[] => {
    if (
      watchSociety &&
      (isGroupchat || (watchRecipients && watchRecipients.length > 1))
    ) {
      const selectedSociety = societyStore.societies.get(
        watchSociety
      ) as SnapshotOut<typeof SocietyModel>
      return [selectedSociety]
    }
    return societyStore.sortedSocieties.filter(
      (_society) => !_society.settings?.chatOneOnOneDisabled
    )
  }, [
    isGroupchat,
    societyStore.societies,
    societyStore.sortedSocieties,
    watchRecipients,
    watchSociety,
  ])

  const usersWithSocietyId = useMemo(
    () =>
      uniqueBy(
        '_id',
        getUserSocieties()
          .reduce((_users: UserWithSocietyId[], _society) => {
            return [
              ..._users,
              ...unitStore
                .residentUsersForSociety(_society._id)
                .map((_user) => ({ ..._user, societyId: _society._id })),
            ]
          }, [])
          .filter(
            (_userSociety) =>
              _userSociety._id !== (authenticationStore.userId as string)
          )
          .sort((a, b) =>
            sortStringsAlphabetically(
              [a.name, a.surname].join(' '),
              [b.name, b.surname].join(' ')
            )
          )
      ),
    [unitStore, getUserSocieties, authenticationStore.userId]
  )

  const usersWithChatRoom: UserWithSocietyIdAndRoom[] = useMemo(
    () =>
      usersWithSocietyId.map((_user) => {
        return {
          user: _user,
          room: chatRoomStore.oneOnOneChatRooms.find((_chatRoom) =>
            _chatRoom.membersList
              .map((_member) => _member.userId)
              .includes(_user._id)
          ),
        }
      }),
    [chatRoomStore.oneOnOneChatRooms, usersWithSocietyId]
  )

  return { usersWithChatRoom }
}
