import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SocietyWebsiteBase } from '../SocietyWebsiteBase'
import {
  SocietyWebsiteContactRecipientModel,
  SocietyWebsiteModel,
} from '../../../../state/models/society-website'
import { useStores } from '../../../../hooks/useStores'
import { societyWebsiteContactSchema } from '../../../../forms/schemas/society_website_contact'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { Toggle } from '../../../common/Toggle'
import { FormControl } from '../../../common/FormControl'
import { SelectDropdown } from '../../../common/SelectDropdown'
import { Icon, IconChoices } from '../../../common/Icon'
import { Button, ButtonVariant } from '../../../common/Button'
import { TextInput } from '../../../common/TextInput'
import { ConfirmationModal } from '../../../common/ConfirmationModal'
import { useModal } from '../../../../hooks/useModal'
import { theme } from '../../../../theme/theme'
import { TextTooltip } from '../../../common/Tooltip'

interface SocietyWebsiteContactProps {
  website: SnapshotOut<typeof SocietyWebsiteModel>
}

export const SocietyWebsiteContact = observer(
  ({ website }: SocietyWebsiteContactProps): JSX.Element => {
    const { societyWebsitesStore, userStore } = useStores()
    const [selectedDeleteIndex, setSelectedDeleteIndex] = useState(0)
    const {
      show: showConfirmationModal,
      open: openConfirmationModal,
      close: closeConfirmationModal,
    } = useModal()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const { getErrorMessage } = useFormErrorMessage()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const [referenceElement, setReferenceElement] = useState<Element | null>(
      null
    )

    const getRecipientEmail = (
      recipient: SnapshotOut<typeof SocietyWebsiteContactRecipientModel>
    ): string | undefined => {
      if (recipient.userEmail) {
        return recipient.userEmail
      }
      if (recipient.userId) {
        const user = userStore.users.get(recipient.userId)
        if (user) {
          return user.email
        }
      }
      return undefined
    }

    const getDefaultValues = (): InferType<
      typeof societyWebsiteContactSchema
    > => {
      return {
        show:
          website.contact?.show !== undefined ? website.contact.show : false,
        recipientId: website.contact?.recipientId || undefined,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        recipientsCC: website.contact?.recipientsCC?.map((recipient) => ({
          userEmail: getRecipientEmail(recipient),
        })),
      }
    }

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyWebsiteContactSchema),
      defaultValues: getDefaultValues(),
    })

    const { fields, append, remove } = useFieldArray({
      name: 'recipientsCC',
      control,
    })

    const onSubmit = async (
      data: InferType<typeof societyWebsiteContactSchema>
    ): Promise<void> => {
      const status = await societyWebsitesStore.patchWebsite(website._id, {
        contact: data,
      })
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('societyWebsiteContact.flashMessage.updateSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('societyWebsiteContact.flashMessage.updateFailure')
        )
      }
    }

    const onError = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('societyWebsiteContact.flashMessage.updateFailure')
      )
    }

    const addNewEmail = (): void => {
      append({ userEmail: '' }, { shouldFocus: true })
    }

    const openDeleteConfirmationModal = (index: number): void => {
      setSelectedDeleteIndex(index)
      openConfirmationModal()
    }

    const deleteCC = (): void => {
      remove(selectedDeleteIndex)
    }

    const boardMembers = society?.boardMembersList
      ? society.boardMembersList.map((boardMember) => {
          const user = userStore.users.get(boardMember.userId)

          return {
            label: user?.fullName || '',
            value: user?._id || '',
          }
        })
      : []

    const loading = societyWebsitesStore.updatingWebsite === 'pending'

    return (
      <form
        className="w-full max-w-3xl"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <SocietyWebsiteBase
          website={website}
          isValid={isValid}
          loading={loading}
        >
          <div className="max-w-screen-sm space-y-6">
            <Controller
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Toggle
                  label={translate('societyWebsite.showOnWebsite')}
                  name={name}
                  enabled={value || false}
                  onChange={onChange}
                />
              )}
              name="show"
            />
            <div className="space-y-4">
              <p style={theme.textVariants.base}>
                {translate('societyWebsiteContact.form.recipientLabel')}
              </p>
              <Controller
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <FormControl
                    label={translate('societyWebsiteContact.form.recipientId')}
                    name={name}
                    error={
                      errors.recipientId && getErrorMessage(errors.recipientId)
                    }
                  >
                    <SelectDropdown
                      value={value}
                      onChange={onChange}
                      options={boardMembers}
                      error={!!errors.recipientId}
                    />
                  </FormControl>
                )}
                name="recipientId"
              />
            </div>
            <div className="space-y-4">
              <div className="flex space-x-4">
                <p style={theme.textVariants.base}>
                  {translate('societyWebsiteContact.form.copy')}
                </p>
                <TextTooltip
                  title={translate('societyWebsiteContact.form.copyTooltip')}
                  referenceElement={referenceElement}
                >
                  <Icon
                    icon={IconChoices.QUESTION_MARK}
                    ref={setReferenceElement}
                  />
                </TextTooltip>
              </div>
              {fields.map((field, index) => (
                <>
                  <Controller
                    control={control}
                    key={field.id}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FormControl
                        className="w-full"
                        error={
                          errors.recipientsCC?.[index]?.userEmail?.type ===
                          'required'
                            ? translate('form.errors.required')
                            : ''
                        }
                        name={`recipientsCC.${index}.userEmail`}
                      >
                        <TextInput
                          className="w-full"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!errors.recipientsCC?.[index]?.userEmail}
                          onDeleteIconPress={() =>
                            openDeleteConfirmationModal(index)
                          }
                          type="email"
                        />
                      </FormControl>
                    )}
                    name={`recipientsCC.${index}.userEmail`}
                  />
                </>
              ))}
            </div>
            <Button
              icon={IconChoices.PLUS_SIGN}
              label={translate('societyWebsiteContact.form.addMore')}
              variant={ButtonVariant.DEFAULT}
              onClick={addNewEmail}
            />
          </div>
        </SocietyWebsiteBase>
        {showConfirmationModal && (
          <ConfirmationModal
            title={translate(
              'societyWebsiteContact.confirmationModal.removeEmailTitle'
            )}
            description={translate(
              'societyWebsiteContact.confirmationModal.removeEmailText'
            )}
            show={showConfirmationModal}
            close={closeConfirmationModal}
            onConfirm={() => deleteCC()}
            deleteMode
          />
        )}
      </form>
    )
  }
)
