import React from 'react'
import { FormatOptionLabelMeta } from 'react-select'
import { UserWithSocietyId } from '../components/chat/StartNewChatHeader'
import { UserAvatar } from '../components/common/Avatar'
import { theme } from '../theme/theme'

type optionLabelReturnType = {
  (data: unknown, { context }: FormatOptionLabelMeta<unknown>): JSX.Element
}

export const useFormatUserOptionLabel = (): optionLabelReturnType => {
  const formatOptionLabel = (
    data: unknown,
    { context }: FormatOptionLabelMeta<unknown>
  ): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataAsAny = data as any
    const user = dataAsAny.data as UserWithSocietyId

    return context === 'menu' ? (
      <div className="flex items-center gap-3">
        <UserAvatar user={user} />
        <p translate="no" style={theme.textVariants.base}>
          {user.fullName}
        </p>
      </div>
    ) : (
      <p translate="no" style={theme.textVariants.base}>
        {user.fullName}
      </p>
    )
  }

  return formatOptionLabel
}
