import React from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { SnapshotOut } from 'mobx-state-tree'
import { theme } from '../../../../theme/theme'
import { FacilityModel } from '../../../../state/models/facility'
import { Avatar } from '../../../common/Avatar'
import { ListCard } from '../../../common/ListCard'
import { Option } from '../../../common/Dropdown'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ConfirmationModal } from '../../../common/ConfirmationModal'
import { useModal } from '../../../../hooks/useModal'
import { useStores } from '../../../../hooks/useStores'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { reverseUrl } from '../../../../navigation/reverseUrl'

interface FacilityListItemProps {
  facility: SnapshotOut<typeof FacilityModel>
  userIsAdmin?: boolean
}

export const FacilityListItem = observer(
  ({ facility, userIsAdmin }: FacilityListItemProps): JSX.Element => {
    const { facilitiesStore } = useStores()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const {
      show: showConfirmationModal,
      open: openConfirmationModal,
      close: closeConfirmationModal,
    } = useModal()
    const navigate = useNavigate()

    const navigateToCreateUpdateFacilityView = (): void => {
      navigate(
        reverseUrl('society:facilities-update', {
          id: facility.societyId,
          facilityId: facility._id,
        })
      )
    }

    const dropdownOptions: Option[] | undefined = userIsAdmin
      ? [
          {
            value: 'edit',
            label: translate('common.actions.edit'),
            onClick: navigateToCreateUpdateFacilityView,
          },
          {
            value: 'remove',
            label: translate('common.actions.delete'),
            onClick: openConfirmationModal,
            destructive: true,
          },
        ]
      : undefined

    const deleteFacility = async (): Promise<void> => {
      const status = await facilitiesStore.deleteFacility(facility._id)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('createUpdateFacility.flashMessage.deleteFacilitySuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('createUpdateFacility.flashMessage.deleteFacilityFailure')
        )
      }
    }

    return (
      <ListCard
        toPath={reverseUrl('society:facilities-detail', {
          id: facility.societyId,
          facilityId: facility._id,
        })}
        dropdownOptions={dropdownOptions}
      >
        <>
          <div className="flex items-center">
            <Avatar mediaId={facility?.coverPhotoId} placeholder="booking" />
            <p
              style={theme.textVariants.baseBold}
              className="ml-4 line-clamp-1"
            >
              {facility.name}
            </p>
          </div>
          <ConfirmationModal
            title={translate(
              'createUpdateFacility.confirmationModal.deleteTitle'
            )}
            description={translate(
              'createUpdateFacility.confirmationModal.deleteDescription'
            )}
            show={showConfirmationModal}
            close={closeConfirmationModal}
            onConfirm={deleteFacility}
            deleteMode
          />
        </>
      </ListCard>
    )
  }
)
