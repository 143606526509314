/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, getRoot } from 'mobx-state-tree'
import { captureException } from '@sentry/react'
import { IPollModel } from '../../interfaces/models/polls.interfaces'
import {
  vote as apiVote,
  unvote as apiUnvote,
  create as apiCreate,
  update as apiUpdate,
} from '../../api/polls'
import { PollModel, PollOptionModel } from '../models/poll'
import { stateType } from '../types/common'
import { RootStore } from './root'
import { setObject } from './helpers'
import { NPolls } from '../../interfaces/services/polls.interfaces'

export const PollStore = types
  .model('PollStore')
  .props({
    polls: types.map(PollModel),
    pollOptions: types.optional(types.map(PollOptionModel), {}),
    voting: stateType,
    creatingPoll: stateType,
    updatingPoll: stateType,
  })
  .actions((self) => ({
    reset: () => {
      self.polls.clear()
      self.pollOptions.clear()
      self.voting = 'none'
      self.creatingPoll = 'none'
      self.updatingPoll = 'none'
    },
    processPolls: (polls: IPollModel[]) => {
      polls.forEach((poll) => {
        poll.options.forEach((pollOption) => {
          setObject<typeof PollOptionModel>(
            // @ts-ignore
            self.pollOptions,
            PollOptionModel,
            pollOption
          )
        })
        try {
          // @ts-ignore
          setObject<typeof PollModel>(self.polls, PollModel, {
            ...poll,
            options: poll.options.map((option) => option._id),
            votes: poll.votes.map((vote) => ({
              ...vote,
              option: vote.optionId,
            })),
          })
        } catch (error) {
          captureException(error)
        }
      })
    },
  }))
  .actions((self) => ({
    setPolls: (polls: IPollModel[]) => {
      self.processPolls(polls)
    },
  }))
  .actions((self) => ({
    vote: flow(function* vote(
      pollId: string,
      optionId: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) {
      self.voting = 'pending'

      const { authenticationStore } = getRoot<RootStore>(self)
      const poll = self.polls.get(pollId)
      try {
        yield apiVote(pollId, optionId)
        self.voting = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.voting = 'error'
        poll?.removeVote(authenticationStore.userId as string)
        return false
      }
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    unvote: flow(function* unvote(pollId: string) {
      self.voting = 'pending'

      const { authenticationStore } = getRoot<RootStore>(self)
      const poll = self.polls.get(pollId)
      try {
        yield apiUnvote(pollId)
        poll?.removeVote(authenticationStore.userId as string)
        self.voting = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.voting = 'error'
        return false
      }
    }),
    createPoll: flow(function* createPoll(body: NPolls.NCreate.IRequestBody) {
      self.creatingPoll = 'pending'
      try {
        const resp = yield apiCreate(body)
        const data = resp.data as NPolls.NCreate.IResponse
        const poll = data.data

        if (poll !== null) {
          self.setPolls([poll])
        }

        self.creatingPoll = 'done'
        return poll
      } catch (error) {
        captureException(error)
        self.creatingPoll = 'error'
        return false
      }
    }),
    patchPoll: flow(function* patchPoll(
      id: string,
      body: NPolls.NPatch.IRequestBody
    ) {
      self.updatingPoll = 'pending'
      try {
        const resp = yield apiUpdate(id, body)
        const data = resp.data as NPolls.NPatch.IResponse
        const poll = data.data

        if (poll !== null) {
          self.setPolls([poll])
        }

        self.updatingPoll = 'done'
        return poll
      } catch (error) {
        captureException(error)
        self.updatingPoll = 'error'
        return false
      }
    }),
  }))
