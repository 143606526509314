import i18next from 'i18next'
import * as yup from 'yup'

export const societyBlockCreateSchema = yup
  .object({
    userIds: yup
      .array(yup.string())
      .min(1, i18next.t('createUpdateBlockedUserForm.error.minItems'))
      .required(),
  })
  .required()
