import { SnapshotOut } from 'mobx-state-tree'
import { dateTimeAsUTCWithoutCompensation } from '../../../../helpers/date'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { BookingModel } from '../../../../state/models/booking'
import { SocietyModel } from '../../../../state/models/society'

export const useDeleteBookingAllowed = (
  booking: SnapshotOut<typeof BookingModel>,
  society: SnapshotOut<typeof SocietyModel>
): { allowed: boolean; otherUsersOwnsBooking: boolean } => {
  const userId = useAuthenticatedUserId() as string
  const authenticatedUserOwnsBooking = booking?.userId === userId
  const userIsAdmin = isUserAdminInSociety(society, userId)
  const bookingIsInThePast =
    new Date(booking?.endDate) < dateTimeAsUTCWithoutCompensation(new Date())

  return {
    allowed:
      !bookingIsInThePast && (authenticatedUserOwnsBooking || userIsAdmin),
    otherUsersOwnsBooking: !authenticatedUserOwnsBooking,
  }
}
