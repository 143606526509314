import React, { ReactNode } from 'react'
import { Button, ButtonVariant } from '../../../../components/common/Button'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useModal } from '../../../../hooks/useModal'
import { CreateWebinarModal } from './CreateWebinarModal'
import { WebinarList } from './WebinarList'
import { theme } from '../../../../theme/theme'

const Webinars = (): ReactNode => {
  const { translate } = useAppTranslation()
  const {
    close: closeCreateWebinar,
    open: openCreateWebinar,
    show: showCreateWebinar,
  } = useModal()
  return (
    <div>
      <Button
        className="px-16 mb-2"
        size="sm-wide"
        label={translate('Nytt webinar')}
        variant={ButtonVariant.PRIMARY}
        onClick={() => openCreateWebinar()}
      />

      <p style={theme.textVariants.h2} translate="no">
        Webinars
      </p>
      <p className="text-neutral-40 mb-6" style={theme.textVariants.base}>
        Lista över alla kommande webinar, webinars från föregående dagar kommer
        inte visas
      </p>
      <WebinarList />

      {showCreateWebinar && (
        <CreateWebinarModal
          close={closeCreateWebinar}
          show={showCreateWebinar}
        />
      )}
    </div>
  )
}

export { Webinars }
