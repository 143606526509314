import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useSpring, animated, easings, useInView } from '@react-spring/web'
import { theme } from '../../../theme/theme'
import { NotificationBadge } from '../../notifications/NotificationBadge'
import { Button, ButtonVariant } from '../Button'
import { Icon, IconChoices } from '../Icon'

interface Tab {
  title: string
  icon?: IconChoices
  displayNotificationBadge?: boolean
}

interface TabProps {
  tab: Tab
  setActiveTab: (index: number) => void
  refCallback: (el: HTMLButtonElement | null) => void
  index: number
}

const Tab = ({
  tab,
  setActiveTab,
  refCallback,
  index,
}: TabProps): JSX.Element => {
  const handleOnClick = (): void => {
    setActiveTab(index)
  }
  return (
    <button
      key={tab.title}
      ref={(el) => {
        refCallback(el)
      }}
      style={{ ...theme.textVariants.baseBold }}
      className="flex h-fit flex-row items-center justify-center px-6 py-3"
      onClick={handleOnClick}
      role="menuitem"
      tabIndex={0}
    >
      {tab.icon && <Icon icon={tab.icon} />}
      <div className="flex flex-row gap-2 whitespace-nowrap">
        <p>{tab.title}</p>
        {tab.displayNotificationBadge && (
          <NotificationBadge className="mt-[7px]" show absoluteOffset={false} />
        )}
      </div>
    </button>
  )
}

export interface TabBar {
  tabs: Tab[]
  activeTab: number
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
  className?: string
}

interface TabBarProps extends TabBar {
  className?: string
  fullWidthMobile?: boolean
  disableScroll?: boolean
  children?: ReactNode
}

export const TabBar = ({
  tabs,
  activeTab,
  setActiveTab,
  className,
  fullWidthMobile = true,
  disableScroll = false,
  children,
}: TabBarProps): JSX.Element => {
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0)
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0)
  const [showArrows, setShowArrows] = useState(false)

  const lastButtonRef = useRef<HTMLDivElement>(null)
  const firstButtonRef = useRef<HTMLDivElement>(null)
  const tabsRef = useRef<HTMLButtonElement[]>([])

  const [firstRef, firstElementInView] = useInView()
  const [lastRef, lastElementInView] = useInView()

  const [springs, api] = useSpring(() => ({
    x: 0,
    config: easings.easeInCubic,
  }))

  const handleLeftClick = (): void => {
    if (firstRef.current && firstButtonRef.current) {
      const delta =
        firstButtonRef.current.getBoundingClientRect().right -
        firstRef.current.getBoundingClientRect().right
      const shiftX = delta > 100 ? 100 : delta
      api.start({
        x: springs.x.get() + shiftX,
      })
    }
  }

  const handleRightClick = (): void => {
    if (lastRef.current && lastButtonRef.current) {
      const delta =
        lastRef.current.getBoundingClientRect().left -
        lastButtonRef.current.getBoundingClientRect().left
      const shiftX = delta > 100 ? 100 : delta
      api.start({
        x: springs.x.get() - shiftX,
      })
    }
  }

  useEffect(() => {
    if (firstElementInView && lastElementInView) {
      setShowArrows(false)
    } else {
      setShowArrows(true)
    }
  }, [firstElementInView, lastElementInView])

  const calculateXShift = useCallback(
    (currentTab: HTMLButtonElement) => {
      const customShift = (currentTab.offsetWidth * 4) / 3
      const isInitialized =
        lastButtonRef.current &&
        firstButtonRef.current &&
        lastButtonRef.current.getBoundingClientRect().left > 0 &&
        firstButtonRef.current.getBoundingClientRect().right > 0

      if (isInitialized) {
        const tab = currentTab.getBoundingClientRect()
        const lastButton = lastButtonRef.current.getBoundingClientRect()
        const firstButton = firstButtonRef.current.getBoundingClientRect()
        const last = lastRef.current.getBoundingClientRect()
        const first = firstRef.current.getBoundingClientRect()

        const shouldShiftRight = tab.right > lastButton.left

        if (shouldShiftRight) {
          const remainingSpace = last.left - lastButton.left
          const isOutsideContainer = tab.left - customShift < firstButton.right
          if (isOutsideContainer) {
            return tab.left - firstButton.right
          }
          if (customShift > remainingSpace) {
            return remainingSpace
          }
          return customShift
        }

        const shouldShiftLeft = tab.left < firstButton.right

        if (shouldShiftLeft) {
          const remainingSpace = firstButton.right - first.right
          const isOutsideContainer = tab.right + customShift > lastButton.left
          if (isOutsideContainer) {
            return -(lastButton.left - tab.right)
          }
          if (customShift > remainingSpace) {
            return -remainingSpace
          }
          return -customShift
        }
      }
      return 0
    },
    [firstRef, lastRef]
  )

  useEffect((): (() => void) => {
    const setTabPosition = (): void => {
      const currentTab = tabsRef.current[activeTab] as
        | HTMLButtonElement
        | undefined
      if (!currentTab) {
        return
      }
      const shiftX = calculateXShift(currentTab)
      currentTab &&
        api.start({
          x: springs.x.get() - shiftX,
        })
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0)
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0)
    }

    setTabPosition()
    window.addEventListener('resize', setTabPosition)

    return () => window.removeEventListener('resize', setTabPosition)
  }, [activeTab, api, calculateXShift, springs.x, tabs])

  return disableScroll ? (
    <div
      className={`relative flex w-full flex-row border-b border-neutral-90 ${
        className ?? ''
      }`}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={tab.title}
          tab={tab}
          setActiveTab={setActiveTab}
          index={index}
          refCallback={(el: HTMLButtonElement | null) => {
            if (el) {
              tabsRef.current[index] = el
            }
          }}
        />
      ))}
      <span
        className="absolute bottom-0 block h-0.5 bg-brandGreen transition-all duration-300 ease-out"
        style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
      />
      {children}
    </div>
  ) : (
    <div
      className={`block ${className ?? ''}
        ${showArrows && fullWidthMobile ? '-mx-3' : ''}
      `}
    >
      <div className="flex w-full">
        <div
          ref={firstButtonRef}
          className={`flex w-fit items-center
            ${showArrows ? '' : 'hidden'}
        `}
        >
          <Button
            variant={ButtonVariant.TEXT}
            icon={IconChoices.CHEVRON_LEFT}
            iconSize={18}
            round
            size="no-padding"
            className="p-[10px]"
            onClick={handleLeftClick}
            disabled={firstElementInView}
          />
        </div>
        <div className="relative flex flex-grow overflow-hidden whitespace-nowrap border-b border-neutral-90">
          <animated.div className="flex" style={springs}>
            <div ref={firstRef} />
            {tabs.map((tab, index) => (
              <Tab
                key={tab.title}
                tab={tab}
                setActiveTab={setActiveTab}
                index={index}
                refCallback={(el: HTMLButtonElement | null) => {
                  if (el) {
                    tabsRef.current[index] = el
                  }
                }}
              />
            ))}
            <span
              className="absolute bottom-0 block h-0.5 bg-brandGreen transition-all duration-300 ease-out"
              style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
            />
            <div ref={lastRef} />
          </animated.div>
        </div>
        <div
          ref={lastButtonRef}
          className={`flex w-fit items-center
        ${showArrows ? '' : 'hidden'}
        `}
        >
          <Button
            variant={ButtonVariant.TEXT}
            icon={IconChoices.CHEVRON_RIGHT}
            iconSize={18}
            round
            size="no-padding"
            className="p-[10px]"
            onClick={handleRightClick}
            disabled={lastElementInView}
          />
        </div>
      </div>
      {children}
    </div>
  )
}
