import React, { ReactNode } from 'react'
import { theme } from '../../../theme/theme'
import { FormSpacing } from '../FormSpacing'
import { Icon, IconChoices } from '../Icon'

interface HorizontalFormSectionProps {
  title: string
  subtitle?: string
  children: React.ReactNode
  style?: React.CSSProperties
  fillWidth?: boolean
  className?: string
  customIcon?: ReactNode
  icon?: IconChoices
  iconPlacement?: 'right' | 'left'
  borderBottom?: boolean
  borderBottomClassName?: string
  hideTitleColumn?: boolean
}

export const HorizontalFormSection = ({
  title,
  subtitle,
  children,
  style,
  className,
  customIcon,
  icon,
  fillWidth = false,
  iconPlacement = 'right',
  borderBottom,
  borderBottomClassName,
  hideTitleColumn,
}: HorizontalFormSectionProps): JSX.Element => {
  return (
    <>
      <div
        className={`mt-6 flex w-full flex-col md:mt-12 md:flex-row ${
          className ?? ''
        }`}
        style={style}
      >
        {!hideTitleColumn && (
          <div
            className={`mb-4 md:mb-0 ${fillWidth ? 'md:w-full' : 'md:w-2/6'}`}
          >
            <div
              className={`flex items-center ${
                iconPlacement === 'left' ? 'flex-row-reverse justify-end' : ''
              }`}
            >
              <p
                style={theme.textVariants.baseBold}
                className={`${iconPlacement === 'right' ? 'mr-2' : 'ml-2'}`}
              >
                {title}
              </p>
              {customIcon && customIcon}
              {!customIcon && icon && <Icon icon={icon} />}
            </div>
            {subtitle && (
              <p
                className="mt-1 text-neutral-50"
                style={theme.textVariants.base}
              >
                {subtitle}
              </p>
            )}
          </div>
        )}
        <FormSpacing className={`${fillWidth ? '' : 'md:w-2/6'}`}>
          {children}
        </FormSpacing>
      </div>
      {borderBottom && (
        <div
          className={`border-1 border-b pb-6 md:w-4/6 md:pb-12 ${
            borderBottomClassName ?? ''
          }`}
        />
      )}
    </>
  )
}
