import React, { ReactNode } from 'react'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../Icon'
import { AlertType } from './alert-type'

interface AlertProps {
  text?: string
  customText?: ReactNode
  type?: AlertType
  className?: string
}

export const Alert = ({
  text,
  customText,
  className,
  type = AlertType.INFO,
}: AlertProps): JSX.Element => {
  const getIcon = (): IconChoices => {
    switch (type) {
      case AlertType.SUCCESS:
        return IconChoices.CHECKMARK
      case AlertType.WARNING:
        return IconChoices.IMPORTANT
      case AlertType.DANGER:
        return IconChoices.EXCLAMATION_ALERT
      default: // Info
        return IconChoices.INFORMATION
    }
  }

  const getColorClasses = (): string => {
    switch (type) {
      case AlertType.SUCCESS:
        return 'bg-brandGreen/10 border-brandGreen/40 text-brandGreen'
      case AlertType.WARNING:
        return 'bg-yellow/10 border-yellow/40 text-black/70'
      case AlertType.DANGER:
        return 'bg-red/10 border-red/40 text-red/70'
      default: // Info
        return 'bg-blue/10 border-blue/40 text-blue/90'
    }
  }

  const getIconColor = (): string => {
    switch (type) {
      case AlertType.SUCCESS:
        return 'green'
      case AlertType.WARNING:
        return theme.colors.flashMessage.warning
      case AlertType.DANGER:
        return 'red'
      default: // Info
        return 'blue'
    }
  }

  const icon = getIcon()
  const iconColor = getIconColor()
  const color = getColorClasses()

  return (
    <div
      className={`${color} flex space-x-2.5 rounded border px-4 py-3 ${
        className ?? ''
      }`}
      role="alert"
    >
      <Icon icon={icon} color={iconColor} />
      {!customText && text && <p style={theme.textVariants.baseBold}>{text}</p>}
      {customText && customText}
    </div>
  )
}
