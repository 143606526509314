import * as yup from 'yup'

export const createWebinarSchema = yup
  .object({
    startDate: yup.date().required(),
    type: yup.string().required(),
    url: yup.string().min(1).required(),
    published: yup.boolean().required(),
  })
  .required()

export const patchWebinarSchema = yup
  .object({
    startDate: yup.date(),
    type: yup.string(),
    url: yup.string().min(1),
    published: yup.boolean(),
  })
  .required()
