import React from 'react'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../../common/Icon'

interface StatusMessageProps {
  text: string
}

export const StatusMessage = ({ text }: StatusMessageProps): JSX.Element => {
  return (
    <div className="flex items-center rounded bg-brandGreen p-4" role="alert">
      <div>
        <Icon
          icon={IconChoices.INFORMATION}
          size={30}
          color={theme.colors.white}
        />
      </div>
      <p style={theme.textVariants.base} className="ml-3 text-white">
        {text}
      </p>
    </div>
  )
}
