import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorBoundary } from '../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { theme } from '../../../theme/theme'
import { BackButton } from '../../../components/common/BackButton'
import { IllustrationChoices } from '../../../components/common/Illustration'
import { CreateSocietyTypeCard } from './CreateSocietyTypeCard'
import { SocietyType } from './SocietyType'
import { useDocumentTitle } from '../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../navigation/reverseDocumentTitle'

export const CreateSocietyTypeSelectionView = (): JSX.Element => {
  const { translate } = useAppTranslation()
  const navigate = useNavigate()
  useDocumentTitle(reverseDocumentTitle('create-society:pick-type'))

  const goBack = (): void => {
    navigate(-1)
  }

  return (
    <ErrorBoundary>
      <div className="h-full w-full space-y-10 overflow-y-auto p-6">
        <div className="flex items-center space-x-4">
          <BackButton onClick={goBack} />
          <p style={theme.textVariants.h2}>
            {translate('createSocietyTypeSelectionView.title')}
          </p>
        </div>
        <div className="flex w-full flex-col space-y-4">
          <div className="flex w-full flex-row flex-wrap gap-6">
            <CreateSocietyTypeCard
              className="w-full md:w-80"
              type={SocietyType.REAL_ESTATE_DEVELOPER}
              title={translate(
                'createSocietyTypeSelectionView.labels.realEstateDeveloper'
              )}
              illustration={IllustrationChoices.ARCHITECT}
            />
            <CreateSocietyTypeCard
              className="w-full md:w-80"
              type={SocietyType.HOUSING_COOPERATIVE}
              title={translate(
                'createSocietyTypeSelectionView.labels.housingCooperative'
              )}
              illustration={IllustrationChoices.BUILDINGS}
            />
            <CreateSocietyTypeCard
              className="w-full md:w-80"
              type={SocietyType.HOMEOWNERS_ASSOCIATION}
              title={translate(
                'createSocietyTypeSelectionView.labels.homeownersAssociation'
              )}
              illustration={IllustrationChoices.WATERING_PLANT}
            />
            <CreateSocietyTypeCard
              className="w-full md:w-80"
              type={SocietyType.NEIGHBOURHOOD}
              title={translate(
                'createSocietyTypeSelectionView.labels.neighbourhood'
              )}
              illustration={IllustrationChoices.CHECKLIST}
            />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}
