import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { SocietyInformationForm } from './SocietyInformationForm'
import { societyAboutSchema } from '../../../../forms/schemas/society_about'
import { ToastType } from '../../../../components/common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { useStores } from '../../../../hooks/useStores'
import { NSocieties } from '../../../../interfaces/services/societies.interfaces'
import { ViewBase } from '../../../../components/common/ViewBase'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'

export const SocietyInformationView = observer((): JSX.Element => {
  const { societyStore } = useStores()
  const { translate } = useAppTranslation()
  const { setToastNotification } = useToastNotifications()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:information', {
      '{{ societyName }}': society.name,
    })
  )

  const onSubmit = async (
    data: InferType<typeof societyAboutSchema>
  ): Promise<void> => {
    const formData: NSocieties.NPatch.IRequestBody = data
    // Never send org nr since we cannot edit it here anyway
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { organisationNumber, ...rest } = formData
    const status = await societyStore.patchSociety(society._id, rest)

    if (status) {
      setToastNotification(
        ToastType.SUCCESS,
        translate('flashMessage.changesSaved')
      )
    } else {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.changesNotSavedError')
      )
    }
  }

  const onError = (): void => {
    setToastNotification(
      ToastType.DANGER,
      translate(
        'societyCreateUpdateResourceScreens.flashMessage.createResourceFailure'
      )
    )
  }

  const loading = societyStore.updatingSociety === 'pending'

  return (
    <ErrorBoundary>
      <ViewBase title={translate('societyInformationView.title')}>
        <SocietyInformationForm
          onError={onError}
          onSubmit={onSubmit}
          loading={loading}
          society={society}
        />
      </ViewBase>
    </ErrorBoundary>
  )
})
