import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useStores } from '../../../../hooks/useStores'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { CreateUpdateBlockedUserForm } from '../CreateUpdateBlockedUserForm'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { societyBlockCreateSchema } from '../../../../forms/schemas/society_block_create'

interface CreateUpdateBlockedUserModalProps {
  show: boolean
  close: () => void
  children?: React.ReactNode
}

export const CreateUpdateBlockedUserModal = observer(
  ({
    children,
    show,
    close,
  }: CreateUpdateBlockedUserModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { societyStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()

    const onSubmit = async (
      data: InferType<typeof societyBlockCreateSchema>
    ): Promise<void> => {
      const selectedUserIds = data.userIds
        .map((_userId) => _userId)
        .filter((_userId) => _userId !== undefined) as string[]
      const status = await societyStore.addBlockedUsers(
        society._id,
        selectedUserIds
      )
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('societyBlockedUsersView.flashMessage.userBlockAddSuccess')
        )
        close()
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('societyBlockedUsersView.flashMessage.userBlockAddFailure')
        )
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const loading =
      societyStore.fetchingSociety === 'pending' ||
      societyStore.updatingBlockedUsers === 'pending'

    return (
      <>
        {children}
        <Modal show={show} size="md" minHeight={400}>
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate('createUpdateBlockedUserModal.title')}
              </ModalHeader>
            ),
            body: (
              <ModalBody className="justify-between">
                <CreateUpdateBlockedUserForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
