import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { TextInput } from '../../../common/TextInput'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import { societyContractCreateSchema } from '../../../../forms/schemas/society_contract_create'
import { ContractModel } from '../../../../state/models/contract'
import { parsedDateOrUndefined } from '../../../../helpers/date'
import { NullableDateTimeInput } from '../../../common/DateTimeInput'
import { Checkbox } from '../../../common/Checkbox'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { useStores } from '../../../../hooks/useStores'
import { DropZone, DropZoneFile } from '../../../common/DropZone'
import { theme } from '../../../../theme/theme'
import { DropdownSelect } from '../../../common/DropdownSelect'
import { DropdownItemContent } from '../../../feed/DropdownItemContent'
import { TextArea } from '../../../common/TextArea'
import { IconChoices } from '../../../common/Icon'

interface CreateUpdateContractFormProps {
  onError: (error: unknown) => void
  onSubmit: (
    data: InferType<typeof societyContractCreateSchema>
  ) => Promise<void>
  onClose: () => void
  loading: boolean
  contract?: SnapshotOut<typeof ContractModel>
}

export const CreateUpdateContractForm = observer(
  ({
    contract,
    loading,
    onError,
    onSubmit,
    onClose,
  }: CreateUpdateContractFormProps): JSX.Element => {
    const [uploadingDocuments, setUploadingDocuments] = useState(false)
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    const { documentStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { getErrorMessage } = useFormErrorMessage()

    const updateMode = !!contract

    const getDefaultValues = useCallback((): InferType<
      typeof societyContractCreateSchema
    > => {
      return {
        title: updateMode ? contract?.title || '' : '',
        company: updateMode ? contract?.company || '' : '',
        description: updateMode ? contract?.description || '' : '',

        documentIds: updateMode
          ? contract?.documentIds?.map((id) => {
              const document = documentStore.documents.get(id)
              return {
                id,
                name: document?.name,
              }
            }) || []
          : [],

        societyId: updateMode
          ? contract?.societyId || society._id
          : society._id,

        running: updateMode ? contract?.running : undefined,
        startDate: updateMode
          ? parsedDateOrUndefined(contract?.startDate) || undefined
          : undefined,
        endDate: updateMode
          ? parsedDateOrUndefined(contract?.endDate) || undefined
          : undefined,
        notificationDate: updateMode
          ? parsedDateOrUndefined(contract?.notificationDate) || undefined
          : undefined,

        contactName: updateMode ? contract?.contactName || '' : '',
        contactEmail: updateMode ? contract?.contactEmail || '' : '',
        contactPhone: updateMode ? contract?.contactPhone || '' : '',
        contactWebsite: updateMode ? contract?.contactWebsite || '' : '',

        status: updateMode
          ? (contract.status as 'active' | 'terminated')
          : 'active',
      }
    }, [society, contract, updateMode, documentStore.documents])

    const {
      control,
      handleSubmit,
      watch,
      setValue,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyContractCreateSchema),
      defaultValues: getDefaultValues(),
    })

    const { fields, append, remove } = useFieldArray({
      control,
      name: 'documentIds',
    })

    const watchStartDate = watch('startDate')
    const watchEndDate = watch('endDate')
    const watchNotificationDate = watch('notificationDate')

    const statusDropdownOptions = [
      {
        value: 'active',
        label: translate('createUpdateContractForm.status.active'),
      },
      {
        value: 'terminated',
        label: translate('createUpdateContractForm.status.terminated'),
      },
    ]

    const handleDocumentUpload = async (file: DropZoneFile): Promise<void> => {
      setUploadingDocuments(true)
      const docId = await documentStore.createDocument(
        file.type,
        file.base64,
        file.name,
        file.name,
        undefined
      )

      if (docId) {
        append({ id: docId, name: file.name })
      }
      setUploadingDocuments(false)
    }

    return (
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Controller
          control={control}
          name="title"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateContractForm.labels.title')}
              error={errors.title && getErrorMessage(errors.title)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.title}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="company"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateContractForm.labels.company')}
              error={errors.company && getErrorMessage(errors.company)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.company}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateContractForm.labels.description')}
              error={errors.description && getErrorMessage(errors.description)}
              name={name}
            >
              <TextArea
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.description}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="status"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate('createUpdateContractForm.labels.status')}
              error={errors.status && getErrorMessage(errors.status)}
              name={name}
            >
              <DropdownSelect
                value={value}
                onChange={onChange}
                options={statusDropdownOptions}
                dropdownWidth="w-full"
                renderItemContent={(option) => (
                  <DropdownItemContent option={option} />
                )}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="running"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate('createUpdateContractForm.labels.running')}
              error={errors.running && getErrorMessage(errors.running)}
              name={name}
            >
              <div className="flex items-center space-x-2">
                <Checkbox
                  name={name}
                  onChange={onChange}
                  value={value}
                  type="checkbox"
                />
                <p>
                  {value ? translate('common.yes') : translate('common.no')}
                </p>
              </div>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="startDate"
          render={({ field: { name, onChange } }) => (
            <FormControl
              label={translate('createUpdateContractForm.labels.startDate')}
              error={errors.startDate && getErrorMessage(errors.startDate)}
              name={name}
            >
              <NullableDateTimeInput
                onChange={onChange}
                // For some reason the field value would not update properly. Forcing watch.
                value={watchStartDate}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="endDate"
          render={({ field: { name, onChange } }) => (
            <FormControl
              label={translate('createUpdateContractForm.labels.endDate')}
              error={errors.endDate && getErrorMessage(errors.endDate)}
              name={name}
            >
              <NullableDateTimeInput
                onChange={(date) => {
                  if (date && date <= new Date()) {
                    setValue('status', 'terminated', {
                      shouldValidate: true,
                      shouldDirty: true,
                    })
                  }
                  onChange(date)
                }}
                minDate={watchStartDate}
                // For some reason the field value would not update properly. Forcing watch.
                value={watchEndDate}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="notificationDate"
          render={({ field: { name, onChange } }) => (
            <FormControl
              label={translate(
                'createUpdateContractForm.labels.notificationDate'
              )}
              error={
                errors.notificationDate &&
                getErrorMessage(errors.notificationDate)
              }
              name={name}
            >
              <NullableDateTimeInput
                onChange={onChange}
                minDate={new Date()}
                // For some reason the field value would not update properly. Forcing watch.
                value={watchNotificationDate}
              />
            </FormControl>
          )}
        />
        <p style={theme.textVariants.base} className="mt-2">
          {translate('createUpdateContractForm.headers.contactDetails')}
        </p>
        <Controller
          control={control}
          name="contactName"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateContractForm.labels.contactName')}
              error={errors.contactName && getErrorMessage(errors.contactName)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.contactName}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="contactEmail"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateContractForm.labels.contactEmail')}
              error={
                errors.contactEmail && getErrorMessage(errors.contactEmail)
              }
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.contactEmail}
                type="email"
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="contactPhone"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateContractForm.labels.contactPhone')}
              error={
                errors.contactPhone && getErrorMessage(errors.contactPhone)
              }
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.contactPhone}
                type="tel"
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="contactWebsite"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate(
                'createUpdateContractForm.labels.contactWebsite'
              )}
              error={
                errors.contactWebsite && getErrorMessage(errors.contactWebsite)
              }
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.contactWebsite}
              />
            </FormControl>
          )}
        />
        <p style={theme.textVariants.base} className="mt-2">
          {translate('createUpdateContractForm.headers.documents')}
        </p>
        <Controller
          control={control}
          render={() => (
            <DropZone
              onUpload={(status, file) => {
                if (status === 'accepted') {
                  handleDocumentUpload(file)
                } else {
                  setToastNotification(
                    ToastType.DANGER,
                    translate('flashMessage.somethingWentWrongError')
                  )
                }
              }}
              multiple
            />
          )}
          name="documentIds"
        />
        <div className="flex flex-col gap-2">
          {fields.map((field, index) => (
            <div
              key={field.name}
              className="flex w-full flex-row items-center space-x-4"
            >
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl className="w-full" name={`files.${index}.name`}>
                    <div className="flex w-full flex-row gap-4">
                      <TextInput
                        wrapperClassName="w-full"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled
                        error={!!errors.documentIds?.[index]?.name}
                      />
                      <Button
                        className="h-12 w-12"
                        icon={IconChoices.DELETE_TRASH}
                        variant={ButtonVariant.DANGER}
                        onClick={() => remove(index)}
                      />
                    </div>
                  </FormControl>
                )}
                name={`documentIds.${index}.name`}
              />
            </div>
          ))}
        </div>
        <div className="mt-4 flex flex-shrink-0 flex-wrap items-center justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            disabled={!isValid || loading}
            label={translate(
              updateMode ? 'common.actions.save' : 'common.actions.add'
            )}
            loading={uploadingDocuments}
            type="submit"
            variant={ButtonVariant.PRIMARY}
          />
        </div>
      </form>
    )
  }
)
