import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { captureException } from '@sentry/react'
import { InferType } from 'yup'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'
import { Modal } from '../../common/Modal'
import { ModalHeader } from '../../common/Modal/ModalHeader'
import { theme } from '../../../theme/theme'
import { ModalBody } from '../../common/Modal/ModalBody'
import { Button, ButtonVariant } from '../../common/Button'
import { IconChoices } from '../../common/Icon'
import {
  downloadApartmentRegister,
  downloadMemberRegister,
  uploadApartmentRegister,
  uploadMemberRegister,
} from '../../../api/societies'
import { openUrl } from '../../../helpers/url'
import { RegisterExcelImportForm } from './RegisterExcelImportForm'
import { societyRegisterUploadSchema } from '../../../forms/schemas/society_register_upload'
import { delay } from '../../../helpers/delay'

export enum ExcelImportModalVariant {
  ENTRANCE_UNIT = 0,
  MEMBER = 1,
}

interface RegisterExcelImportModalProps {
  show: boolean
  close: () => void
  variant: ExcelImportModalVariant
  children?: React.ReactNode
}

export const RegisterExcelImportModal = observer(
  ({
    show,
    close,
    variant,
    children,
  }: RegisterExcelImportModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()
    const [downloadingRegister, setDownloadingRegister] = useState(false)
    const [uploadingRegister, setUploadingRegister] = useState(false)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isSamf } = society

    const showSuccessMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('registerExcelImport.flashMessage.uploadRegisterSuccess')
      )
    }

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('registerExcelImport.flashMessage.uploadRegisterFailure')
      )
    }

    const onSubmit = async (
      data: InferType<typeof societyRegisterUploadSchema>
    ): Promise<void> => {
      setUploadingRegister(true)
      const formData = new FormData()
      const blob = await fetch(data.file.base64).then((res) => res.blob())
      formData.append('file', blob, data.file.name)

      const response =
        variant === ExcelImportModalVariant.MEMBER
          ? await uploadMemberRegister(society._id, formData)
          : await uploadApartmentRegister(society._id, formData)

      if (response.status) {
        showSuccessMessage()
        /*
            Reload app to reset entrances/units/members
            Resetting store/fetch data adds a lot of complexity so I opted for this simple solution.
            
            Note: Delay 500ms to show successMessage
            */
        await delay(500)
        window.location.reload()
      } else {
        showErrorMessage()
      }
      setUploadingRegister(false)
    }

    const onError = (): void => {
      setToastNotification(ToastType.DANGER, 'TODO')
    }

    const _downloadRegister = async (): Promise<void> => {
      setDownloadingRegister(true)
      const response =
        variant === ExcelImportModalVariant.MEMBER
          ? await downloadMemberRegister(society._id, true)
          : await downloadApartmentRegister(society._id)
      if (response) {
        try {
          const { endpoint } = response.data.data
          openUrl(endpoint)
        } catch (error) {
          setToastNotification(
            ToastType.DANGER,
            translate('flashMessage.somethingWentWrongError')
          )
          captureException(error)
        }
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }
      setDownloadingRegister(false)
    }

    const loading = uploadingRegister
    const buttonsDisabled = downloadingRegister || loading

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                <div>
                  <p style={theme.textVariants.h3}>
                    {variant === ExcelImportModalVariant.ENTRANCE_UNIT
                      ? translate(
                          isSamf
                            ? 'registerExcelImport.modal.entranceUnitTitleSamf'
                            : 'registerExcelImport.modal.entranceUnitTitle'
                        )
                      : translate('registerExcelImport.modal.memberTitle')}
                  </p>
                </div>
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <p style={theme.textVariants.baseBold}>
                  {variant === ExcelImportModalVariant.ENTRANCE_UNIT
                    ? translate(
                        isSamf
                          ? 'registerExcelImport.modal.entranceUnitSubtitleSamf'
                          : 'registerExcelImport.modal.entranceUnitSubtitle'
                      )
                    : translate('registerExcelImport.modal.memberSubtitle')}
                </p>
                <div style={theme.textVariants.base} className="mt-1 mb-3">
                  {(variant === ExcelImportModalVariant.ENTRANCE_UNIT
                    ? translate(
                        isSamf
                          ? 'registerExcelImport.modal.entranceUnitDescriptionSamf'
                          : 'registerExcelImport.modal.entranceUnitDescription'
                      )
                    : translate('registerExcelImport.modal.memberDescription')
                  )
                    .split('\n\n')
                    .map((_str) => (
                      <p key={_str} style={theme.textVariants.base}>
                        {_str}
                      </p>
                    ))}
                </div>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  label="Ladda ner register"
                  icon={IconChoices.DOWNLOAD}
                  onClick={_downloadRegister}
                  loading={downloadingRegister}
                  disabled={buttonsDisabled}
                />
                <RegisterExcelImportForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  className="mt-2"
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
