import React, { ReactNode } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { Modal } from '../../../../../components/common/Modal'
import { ModalHeader } from '../../../../../components/common/Modal/ModalHeader'
import { ModalBody } from '../../../../../components/common/Modal/ModalBody'
import { TextInput } from '../../../../../components/common/TextInput'
import { FormControl } from '../../../../../components/common/FormControl'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { useFormErrorMessage } from '../../../../../hooks/useFormErrorMessage'
import { DateTimeInput } from '../../../../../components/common/DateTimeInput'
import { ToastType } from '../../../../../components/common/Toast/toast-type'
import { Button, ButtonVariant } from '../../../../../components/common/Button'
import { patchWebinarSchema } from '../../../../../forms/schemas/webinar'
import { Toggle } from '../../../../../components/common/Toggle'
import { DropdownSelect } from '../../../../../components/common/DropdownSelect'
import { DropdownItemContent } from '../../../../../components/feed/DropdownItemContent'
import { useIsMobile } from '../../../../../hooks/useIsMobile'
import {
  adminQueryKeys,
  patchWebinar,
  removeWebinar,
} from '../../../../../api/admin'
import { IWebinarModel } from '../../../../../interfaces/models/webinars.interface'

const EditWebinarModal = ({
  show,
  close,
  item,
}: {
  show: boolean
  close: () => void
  item: IWebinarModel
}): ReactNode => {
  const { translate } = useAppTranslation()

  const { setToastNotification } = useToastNotifications()
  const { getErrorMessage } = useFormErrorMessage()
  const client = useQueryClient()
  const { mutate } = useMutation({
    mutationFn: patchWebinar,
    onError: () => {
      setToastNotification(ToastType.DANGER, 'Något gick fel')
    },
    onSuccess: () => {
      setToastNotification(ToastType.SUCCESS, `Webinar uppdaterat`)
      close()
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: adminQueryKeys.webinars().queryKey })
    },
  })
  const isMobile = useIsMobile()
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<InferType<typeof patchWebinarSchema>>({
    resolver: yupResolver(patchWebinarSchema),
    mode: 'all',
    defaultValues: {
      published: item.published,
      startDate: new Date(item.startDate),
      type: item.type,
      url: item.url,
    },
  })
  const onSubmit = (data: InferType<typeof patchWebinarSchema>): void => {
    const type = data?.type === 'intro' ? 'intro' : 'gettingStarted'
    mutate({
      id: item._id,
      body: {
        ...data,
        type,
      },
    })
  }
  const onInvalid = (): void => {
    setToastNotification(
      ToastType.DANGER,
      translate('flashMessage.somethingWentWrongError')
    )
  }

  const typeOptions = ['intro', 'gettingStarted'].map((option) => ({
    value: option,
    label: translate(`adminWebinar.options.${option}`),
  }))

  const { mutate: removeWebinarMutation, isPending: removeWebinarIsPending } =
    useMutation({
      mutationFn: removeWebinar,
      onError: () => {
        setToastNotification(ToastType.DANGER, 'Något gick fel')
      },
      onSuccess: () => {
        setToastNotification(ToastType.SUCCESS, `Webinar borttaget`)
        close()
      },
      onSettled: () => {
        client.invalidateQueries({
          queryKey: adminQueryKeys.webinars().queryKey,
        })
      },
    })

  const onRemoveWebinar = (): void => {
    removeWebinarMutation({ id: item._id })
  }
  return (
    <Modal show={show} size="md">
      {{
        header: <ModalHeader onClose={close}>Uppdatera webinar</ModalHeader>,
        body: (
          <ModalBody className="max-h-[700px] overflow-y-auto overflow-visible">
            <form
              className="flex flex-col gap-4"
              onSubmit={handleSubmit(onSubmit, onInvalid)}
            >
              <Controller
                control={control}
                name="startDate"
                render={({ field: { onChange, value, name } }) => (
                  <FormControl
                    className="flex flex-1"
                    label={translate('createUpdateEvent.form.labels.startDate')}
                    error={
                      errors.startDate && getErrorMessage(errors.startDate)
                    }
                    name={name}
                  >
                    <DateTimeInput
                      timeInterval={1}
                      onChange={onChange}
                      minDate={new Date()}
                      value={value}
                      showTimeSelect
                    />
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="type"
                render={({ field: { name, value, onChange } }) => (
                  <FormControl name={name} label={translate('Webinar typ')}>
                    <DropdownSelect
                      value={value}
                      options={typeOptions}
                      onChange={onChange}
                      dropdownWidth={isMobile ? 'w-full' : 'w-60'}
                      dropdownContentWidth="w-fit"
                      dropdownPosition="bottom-right"
                      renderItemContent={(option) => (
                        <DropdownItemContent option={option} />
                      )}
                    />
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name="url"
                render={({ field: { value, name, onChange, onBlur } }) => (
                  <FormControl
                    className="flex flex-1"
                    label="Länk"
                    error={errors.url && getErrorMessage(errors.url)}
                    name={name}
                  >
                    <TextInput
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      error={!!errors.url}
                    />
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="published"
                render={({ field: { onChange, value, name } }) => (
                  <Toggle
                    wrapperClassName="w-fit mt-1"
                    label={translate('publicerad')}
                    name={name}
                    enabled={value || false}
                    onChange={onChange}
                  />
                )}
              />

              <div className="flex gap-8 justify-between pt-8">
                <Button
                  type="button"
                  className="px-16 self-start"
                  label={translate('common.actions.delete')}
                  variant={ButtonVariant.DANGER}
                  onClick={() => onRemoveWebinar()}
                  disabled={removeWebinarIsPending}
                />
                <div className="flex gap-8">
                  <Button
                    type="button"
                    className="px-16"
                    label={translate('avbryt')}
                    variant={ButtonVariant.SECONDARY}
                    onClick={() => close()}
                  />
                  <Button
                    type="submit"
                    className="px-16"
                    label={translate('Uppdatera webinar')}
                    variant={ButtonVariant.PRIMARY}
                    disabled={!isValid}
                  />
                </div>
              </div>
            </form>
          </ModalBody>
        ),
      }}
    </Modal>
  )
}

export { EditWebinarModal }
