import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { TextInput } from '../../../common/TextInput'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import { UnitModel } from '../../../../state/models/unit'
import { unitCreateSchema } from '../../../../forms/schemas/society_unit_create'
import { useStores } from '../../../../hooks/useStores'
import { SelectDropdown } from '../../../common/SelectDropdown'
import { getFloorFromTitleLegal } from '../../../../helpers/units'

interface CreateUpdateRegisterUnitItemFormProps {
  onError: () => void
  onSubmit: (data: InferType<typeof unitCreateSchema>) => Promise<void>
  onClose: () => void
  loading: boolean
  unit?: SnapshotOut<typeof UnitModel>
}

export const CreateUpdateRegisterUnitItemForm = observer(
  ({
    unit,
    loading,
    onError,
    onSubmit,
    onClose,
  }: CreateUpdateRegisterUnitItemFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { getErrorMessage } = useFormErrorMessage()
    const { societyEntrancesStore } = useStores()

    const updateMode = !!unit

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isSamf } = society

    const isNeighbourhood = society.societyTypeDisplayName === 'neighbourhood'

    const getDefaultValues = useCallback((): InferType<
      typeof unitCreateSchema
    > => {
      return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        entranceId: updateMode ? unit?.entranceId || '' : '',
        title: updateMode ? unit?.title || '' : '',
        titleLegal: updateMode ? unit?.titleLegal || '' : '',
        floor: updateMode ? unit?.floor : 0,
        societyId: updateMode ? unit?.societyId || society._id : society._id,
      }
    }, [society, unit, updateMode])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
      setValue,
    } = useForm({
      mode: 'all',
      resolver: yupResolver(unitCreateSchema),
      defaultValues: getDefaultValues(),
    })

    const entranceOptions = societyEntrancesStore
      .entrancesForSociety(society._id)
      .map((_entrance) => ({
        label: _entrance.addressStreet as string,
        value: _entrance._id,
      }))

    return (
      <>
        <Controller
          control={control}
          name="entranceId"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate(
                isSamf
                  ? 'registerView.unit.labels.entranceSamf'
                  : 'registerView.unit.labels.entrance'
              )}
              error={errors.entranceId && getErrorMessage(errors.entranceId)}
              name={name}
            >
              <SelectDropdown
                options={entranceOptions}
                value={value}
                onChange={onChange}
                error={!!errors.entranceId}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="titleLegal"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate(
                isSamf
                  ? 'registerView.unit.labels.titleLegalSamf'
                  : 'registerView.unit.labels.titleLegal'
              )}
              error={errors.titleLegal && getErrorMessage(errors.titleLegal)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={(e) => {
                  onChange(e)
                  if (!isSamf && !isNeighbourhood) {
                    setValue(
                      'floor',
                      getFloorFromTitleLegal(
                        (e.target as HTMLInputElement).value
                      )
                    )
                  }
                }}
                onBlur={onBlur}
                error={!!errors.titleLegal}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="title"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate(
                isSamf
                  ? 'registerView.unit.labels.titleSamf'
                  : 'registerView.unit.labels.title'
              )}
              error={errors.title && getErrorMessage(errors.title)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.title}
              />
            </FormControl>
          )}
        />

        {!isSamf && !isNeighbourhood && (
          <Controller
            control={control}
            name="floor"
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                label={translate('registerView.unit.labels.floor')}
                helperText={translate(
                  'common.form.helperTexts.calculatedAutomatically'
                )}
                error={errors.floor && getErrorMessage(errors.floor)}
                name={name}
                disabled
              >
                <TextInput
                  disabled
                  value={`${value}`}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.floor}
                />
              </FormControl>
            )}
          />
        )}

        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            disabled={!isValid || loading}
            variant={ButtonVariant.PRIMARY}
            label={
              updateMode
                ? translate('common.actions.save')
                : translate('common.actions.create')
            }
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </>
    )
  }
)
