import React, { useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { UserModel } from '../../../state/models/user'
import { SocietyModel } from '../../../state/models/society'
import { getListSectionSortedAndGroupedUsers } from '../../../helpers/user'
import { ResidentListItem } from './ResidentListItem'
import { Section, SectionList } from '../../common/SectionList'
import { ResidentUserType } from '../../../state/stores/units'

interface ResidentListProps {
  users: SnapshotOut<typeof UserModel>[]
  society: SnapshotOut<typeof SocietyModel> | undefined
  editUserUnitsMode?: ResidentUserType
  deleteUserOption?: boolean
}

export const ResidentList = observer(
  ({
    users,
    society,
    editUserUnitsMode,
    deleteUserOption = false,
  }: ResidentListProps): JSX.Element => {
    const usersList = useMemo(
      () => getListSectionSortedAndGroupedUsers(users),
      [users]
    )

    const getSections = useCallback((): Section[] => {
      return usersList.map((section) => ({
        title: section.title,
        item: (
          <div>
            {section.data.map((user) => (
              <ResidentListItem
                key={user._id}
                user={user}
                society={society}
                editUserUnitsMode={editUserUnitsMode}
                deleteUserOption={deleteUserOption}
              />
            ))}
          </div>
        ),
      }))
    }, [usersList, society, editUserUnitsMode, deleteUserOption])

    return <SectionList sections={getSections()} />
  }
)
