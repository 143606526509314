import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { SnapshotOut } from 'mobx-state-tree'
import {
  ChatErrorState,
  ChatHeaderState,
  chatErrorStateAtom,
  chatHeaderStateAtom,
} from '../atom'
import { ActiveTabBar } from '../ChatSideBarContent'
import { ChatRoomModel } from '../../../state/models/chat-room'
import { useStores } from '../../../hooks/useStores'

interface useChatErrorStateProps {
  activeTabBar: ActiveTabBar
  activeTab: number
  currentChatRooms: SnapshotOut<typeof ChatRoomModel>[]
  showSpinner: boolean | null
}

export const useChatErrorState = ({
  activeTabBar,
  activeTab,
  currentChatRooms,
  showSpinner,
}: useChatErrorStateProps): void => {
  const { authenticationStore, societyStore, requestStore } = useStores()
  const [, setChatErrorState] = useAtom(chatErrorStateAtom)
  const [chatHeaderState] = useAtom(chatHeaderStateAtom)
  const societies = societyStore.sortedSocieties
  const userId = authenticationStore.userId as string
  const isUserInAtleastOneSociety = societies.length > 0
  const userPendingRequests = requestStore.userPendingSocietyRequests(userId)
  const isOneOnOneChatDisabledForAllSocieties = societies.every(
    (_society) => _society.settings?.chatOneOnOneDisabled
  )
  const isGroupChatDisabledForAllSocieties = societies.every(
    (_society) => _society.settings?.chatInterestsDisabled
  )

  useEffect(() => {
    const chatIsEmpty =
      isUserInAtleastOneSociety && currentChatRooms.length === 0 && !showSpinner

    const pendingSocietyRequest =
      !isUserInAtleastOneSociety && userPendingRequests.length > 0

    const userHasNoSociety =
      !isUserInAtleastOneSociety &&
      userPendingRequests.length === 0 &&
      !showSpinner

    const emptyOneOnOneAndGroup =
      chatIsEmpty && activeTabBar === ActiveTabBar.RESIDENT && activeTab === 0

    // TODO:L Might want to look at how we handle if one is disabled but not the other in webapp?
    const chatDisabled =
      isOneOnOneChatDisabledForAllSocieties &&
      isGroupChatDisabledForAllSocieties &&
      !showSpinner

    const chatIsEmptyAndAskBoard =
      chatIsEmpty && activeTabBar === ActiveTabBar.RESIDENT && activeTab === 1

    const chatIsEmptyAndBoardChat =
      chatIsEmpty && activeTabBar === ActiveTabBar.BOARD && activeTab === 0

    const chatIsEmptyAndBoardAnswerResidentsChat =
      chatIsEmpty && activeTabBar === ActiveTabBar.BOARD && activeTab === 1

    if (
      chatHeaderState !== ChatHeaderState.None &&
      chatHeaderState !== ChatHeaderState.FindArchivedChats &&
      chatHeaderState !== ChatHeaderState.FindArchivedChatsResident
    ) {
      setChatErrorState(ChatErrorState.None)
      return
    }

    if (pendingSocietyRequest) {
      setChatErrorState(ChatErrorState.PendingRequest)
      return
    }

    if (userHasNoSociety) {
      setChatErrorState(ChatErrorState.NoSociety)
      return
    }

    if (chatDisabled) {
      setChatErrorState(ChatErrorState.ChatDisabled)
      return
    }

    if (emptyOneOnOneAndGroup) {
      setChatErrorState(ChatErrorState.EmptyChat)
      return
    }

    if (chatIsEmptyAndAskBoard) {
      setChatErrorState(ChatErrorState.EmptyAskBoard)
      return
    }

    if (chatIsEmptyAndBoardChat) {
      setChatErrorState(ChatErrorState.EmptyBoardChat)
      return
    }

    if (chatIsEmptyAndBoardAnswerResidentsChat) {
      setChatErrorState(ChatErrorState.EmptyBoardAnswerResident)
      return
    }

    setChatErrorState(ChatErrorState.None)
  }, [
    activeTab,
    activeTabBar,
    chatHeaderState,
    currentChatRooms,
    isGroupChatDisabledForAllSocieties,
    isOneOnOneChatDisabledForAllSocieties,
    isUserInAtleastOneSociety,
    setChatErrorState,
    showSpinner,
    userPendingRequests.length,
  ])
}
