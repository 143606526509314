import React, { ReactNode, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { useStores } from '../../hooks/useStores'
import { Post } from '../../components/feed/Post'
import { InfiniteScroll } from '../../components/common/InfiniteScroll'
import { Container } from '../../components/common/Container'
import { PostCreateButton } from '../../components/feed/PostCreateButton'
import { PostFilterButton } from '../../components/feed/PostFilterButton/PostFilterButton'
import { PostFilterSocietyButton } from '../../components/feed/PostFilterSocietyButton'
import { ErrorView } from '../error/ErrorView'
import { IllustrationChoices } from '../../components/common/Illustration'
import { CreateEditPostModal } from '../../components/feed/CreateEditPostModal'
import { useModal } from '../../hooks/useModal'
import { SocietyInvites } from '../../components/society/SocietyInvite/SocietyInvites'
import { usePreApprovedList } from '../../hooks/usePreApprovedList'
import { useAppTranslation } from '../../hooks/useAppTranslation'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../navigation/reverseDocumentTitle'
import { SpinnerWrapper } from '../../components/common/SpinnerWrapper'
import { reverseUrl } from '../../navigation/reverseUrl'
import { ButtonVariant } from '../../components/common/Button'
import { StatusMessages } from '../../components/status/StatusMessages'
import { useUserFeedSocieties } from './useUserFeedSocieties'
import { isUserAdminInSociety } from '../../helpers/society'
import { LockedSocietiesCallout } from '../../components/society/LockedSocietiesCallout'
import { BlockedCallout } from '../../components/blocked/BlockedCallout'

interface ErrorViewWrapper {
  children: ReactNode
  loading?: boolean
  noSocietyErrorView?: ErrorView
  pendingRequestErrorView?: ErrorView
  allSocietiesLockedForUserErrorView?: ErrorView
}

const ErrorViewWrapper = ({
  children,
  loading,
  noSocietyErrorView,
  pendingRequestErrorView,
  allSocietiesLockedForUserErrorView,
}: ErrorViewWrapper): JSX.Element => {
  if (loading) return <SpinnerWrapper />
  if (pendingRequestErrorView) return <ErrorView {...pendingRequestErrorView} />
  if (noSocietyErrorView) return <ErrorView {...noSocietyErrorView} />
  if (allSocietiesLockedForUserErrorView)
    return <ErrorView {...allSocietiesLockedForUserErrorView} />
  return <>{children}</>
}

export const FeedView = observer((): JSX.Element => {
  useDocumentTitle(reverseDocumentTitle('feed'))
  const { translate } = useAppTranslation()
  const {
    postStore,
    authenticationStore,
    notificationsStore,
    societyStore,
    requestStore,
    unitStore,
  } = useStores()
  const {
    show: showCreatePostModal,
    open: openCreatePostModal,
    close: closeCreatePostModal,
  } = useModal({ preventClose: true })
  const isMobile = useIsMobile()
  const preApprovedList = usePreApprovedList()
  const navigate = useNavigate()

  const userId = authenticationStore.userId as string

  const userHasNoSocieties = societyStore.sortedSocieties.length === 0

  const userPendingRequests = requestStore.userPendingSocietyRequests(userId)

  const { societies: societiesWhereUserCanPost } = useUserFeedSocieties({
    userId,
  })

  const hasDoneInitLoad = societyStore.hasFetchedSocietiesOnce

  const posts = postStore.feed

  const loading =
    postStore.fetchingFeed === 'pending' && !postStore.hasFetchedPostsOnce

  useEffect(() => {
    if (societyStore.fetchingSocieties !== 'pending') {
      societyStore.getUserSocieties(userId)
    }
  }, [societyStore, userId])

  useEffect(() => {
    if (unitStore.fetchingUnits !== 'pending') {
      unitStore.getUserUnits()
    }
  }, [unitStore])

  useEffect(() => {
    !loading && postStore.getFeed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsStore, postStore])

  const loadMorePosts = (): void => {
    if (postStore.fetchingFeed !== 'pending') {
      postStore.getFeed(true)
    }
  }

  const lockedSocietiesForUser = societiesWhereUserCanPost.filter(
    (society) => isUserAdminInSociety(society, userId) && society.locked
  )
  const allSocietiesLockedForUser =
    societiesWhereUserCanPost.length === lockedSocietiesForUser.length

  const noFilterMatch =
    posts.length === 0 &&
    postStore.postFilters.length + postStore.societyFilters.length > 0

  const noSocietyErrorView =
    !societyStore.selectedSociety && hasDoneInitLoad
      ? {
          title: translate('feedView.emptyState.noSociety.title'),
          subtitle: translate('feedView.emptyState.noSociety.subtitle'),
          illustration: IllustrationChoices.EMPTY,
          buttons: [
            {
              id: uuid(),
              button: {
                label: translate('feedView.emptyState.noSociety.buttonTitle'),
                onClick: () => navigate(reverseUrl('find-society')),
                variant: ButtonVariant.PRIMARY,
              },
            },
          ],
        }
      : undefined

  const pendingRequestErrorView =
    userPendingRequests.length > 0 && userHasNoSocieties && hasDoneInitLoad
      ? {
          title: translate('feedView.emptyState.pendingRequest.title'),
          subtitle: translate('feedView.emptyState.pendingRequest.subtitle'),
          illustration: IllustrationChoices.WAITING,
          buttons: [
            {
              id: uuid(),
              button: {
                label: translate(
                  'feedView.emptyState.pendingRequest.buttonTitle'
                ),
                onClick: () => navigate(reverseUrl('find-society')),
                variant: ButtonVariant.PRIMARY,
              },
            },
          ],
        }
      : undefined

  const allSocietiesLockedForUserErrorView =
    societiesWhereUserCanPost.length > 0 && allSocietiesLockedForUser
      ? {
          title: translate('invoiceView.basic.locked.title'),
          subtitle: translate('invoiceView.basic.locked.subtitle'),
          illustration: IllustrationChoices.EMPTY,
          className: 'px-4 py-16',
          buttons: [
            {
              id: 'pay-annual-fee',
              button: {
                label: translate('common.button.toPayment'),
                onClick: () =>
                  navigate(
                    reverseUrl('management:billing-payment', {
                      id: societiesWhereUserCanPost[0]._id,
                    })
                  ),
                variant: ButtonVariant.PRIMARY,
              },
            },
          ],
        }
      : undefined

  const feedIsEmpty = postStore.hasFetchedPostsOnce && posts.length === 0
  const userCanPostInAtLeastOneSociety = societiesWhereUserCanPost.length > 0

  const emptyFeedErrorView = {
    title: translate('feedView.emptyState.emptyFeed.title'),
    subtitle: translate('feedView.emptyState.emptyFeed.subtitle'),
    illustration: IllustrationChoices.EMPTY,
    buttons: [
      ...(userCanPostInAtLeastOneSociety
        ? [
            {
              id: uuid(),
              button: {
                label: translate('feedView.emptyState.emptyFeed.buttonTitle'),
                onClick: openCreatePostModal,
                variant: ButtonVariant.PRIMARY,
              },
            },
          ]
        : []),
    ],
  }

  return (
    <div className="flex h-full flex-1 flex-col items-center overflow-y-scroll bg-gray-100">
      {preApprovedList.length > 0 && (
        <SocietyInvites preApproved={preApprovedList} />
      )}

      <Container className="pb-0 md:max-w-4xl md:pb-0" overflowScrollY={false}>
        {(!allSocietiesLockedForUser ||
          societyStore.userIsBlockedSocietyIds.length > 0) && (
          <div className="mb-3 flex flex-col gap-3">
            {!allSocietiesLockedForUser && (
              <LockedSocietiesCallout societies={lockedSocietiesForUser} />
            )}
            <BlockedCallout />
          </div>
        )}

        <ErrorViewWrapper
          noSocietyErrorView={noSocietyErrorView}
          pendingRequestErrorView={pendingRequestErrorView}
          allSocietiesLockedForUserErrorView={
            allSocietiesLockedForUserErrorView
          }
          loading={loading}
        >
          {postStore.hasFetchedPostsOnce && (
            <InfiniteScroll
              key="infiniteFeedScroll"
              onEndReached={loadMorePosts}
              spinnerCustomStyles="absolute top-24 -translate-y-[50%]"
            >
              {/* TODO: ?? add notifications to these filter buttons? */}
              <div className="mb-3 flex justify-end gap-3">
                <PostFilterButton
                  postFilters={postStore.postFilters}
                  isMobile={isMobile}
                />
                <PostFilterSocietyButton
                  societyFilters={postStore.societyFilters}
                  isMobile={isMobile}
                />
              </div>
              <StatusMessages authenticated />
              {userCanPostInAtLeastOneSociety && (
                <PostCreateButton
                  userId={userId}
                  onClick={openCreatePostModal}
                />
              )}
              <CreateEditPostModal
                show={showCreatePostModal}
                close={closeCreatePostModal}
              />
              {feedIsEmpty ? (
                <ErrorView {...emptyFeedErrorView} className="mt-14" />
              ) : (
                <>
                  {posts.length > 0 &&
                    posts.map((_post) => (
                      <Post key={_post._id} postId={_post._id} />
                    ))}
                </>
              )}
              {noFilterMatch && (
                <ErrorView
                  className="mt-14 gap-6 md:mt-28 md:gap-10"
                  title={translate('feedView.emptyFilter.title')}
                  subtitle={translate('feedView.emptyFilter.subtitle')}
                  illustration={IllustrationChoices.EMPTY}
                />
              )}
            </InfiniteScroll>
          )}
        </ErrorViewWrapper>
      </Container>
    </div>
  )
})
