import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Badge } from '../../../../common/Badge'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { getInterest } from '../../../../../helpers/translations/interests'
import { Interests } from '../../../../../types/interests'

interface UserInterestBadgeProps {
  interest: string
  cardBottomLimit: number | undefined
  index: number
  updateIndexOutOfBounds: (index: number) => void
  lowestIndexOutOfBounds: number
}

export const UserInterestBadge = observer(
  ({
    interest,
    cardBottomLimit,
    index,
    updateIndexOutOfBounds,
    lowestIndexOutOfBounds,
  }: UserInterestBadgeProps) => {
    const { translate } = useAppTranslation()
    const [isOutOfBounds, setIsOutOfBounds] = useState<boolean>()
    const [isInsideBounds, setIsInsideBounds] = useState<boolean>(false)
    const badgeRef = useRef<HTMLDivElement>(null)

    const isLastBadge = index === lowestIndexOutOfBounds - 1
    const badgeBottom = badgeRef.current?.getBoundingClientRect().bottom

    useEffect(() => {
      if (badgeBottom && cardBottomLimit) {
        const outOfBounds = badgeBottom > cardBottomLimit
        const insideBounds = badgeBottom < cardBottomLimit
        setIsOutOfBounds(outOfBounds)
        setIsInsideBounds(insideBounds)
        outOfBounds && updateIndexOutOfBounds(index)
      }
    }, [
      badgeBottom,
      cardBottomLimit,
      index,
      isInsideBounds,
      updateIndexOutOfBounds,
    ])

    return isOutOfBounds || isLastBadge ? null : (
      <Badge
        className={isInsideBounds ? 'opacity-1' : 'opacity-0'}
        colorClass="bg-brandGreen/10"
        ref={badgeRef}
        key={interest}
        textColorClass="text-brandGreen"
        borderColorClass="border-brandGreen"
        text={translate(getInterest(interest as Interests) as string)}
      />
    )
  }
)
