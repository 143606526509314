import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { IconChoices } from '../../../../components/common/Icon'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useCallbackDebugger } from '../../../../hooks/useEffectAndCallbackDebugger'
import { societyHiddenWidgetsSchema } from '../../../../forms/schemas/society_hidden_widgets'
import { NSocieties } from '../../../../interfaces/services/societies.interfaces'
import { useStores } from '../../../../hooks/useStores'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../../components/common/Toast/toast-type'
import { FunctionSettingsRow } from '../../../../components/society/FunctionSettings/FunctionSettingsRow'

export const FunctionSettingsViewForm = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { societyStore } = useStores()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const { setToastNotification } = useToastNotifications()

  const hiddenWidgets = society?.hiddenSocietyWidgets

  const getDefaultValues = useCallbackDebugger((): InferType<
    typeof societyHiddenWidgetsSchema
  > => {
    return {
      board: !hiddenWidgets?.includes('board'),
      contacts: !hiddenWidgets?.includes('contacts'),
      calendar: !hiddenWidgets?.includes('calendar'),
      documents: !hiddenWidgets?.includes('documents'),
      faqs: !hiddenWidgets?.includes('faqs'),
      facilities: !hiddenWidgets?.includes('facilities'),
      rules: !hiddenWidgets?.includes('rules'),
      reportProblem: !hiddenWidgets?.includes('reportProblem'),
      'local-offers': !hiddenWidgets?.includes('local-offers'),
      sales: !hiddenWidgets?.includes('sales'),
      marketplace: !hiddenWidgets?.includes('marketplace'),
      services: !hiddenWidgets?.includes('services'),
      'find-friends': !hiddenWidgets?.includes('find-friends'),
    }
  }, [hiddenWidgets])

  const { control, watch } = useForm({
    resolver: yupResolver(societyHiddenWidgetsSchema),
    defaultValues: getDefaultValues(),
  })

  useEffect(() => {
    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const showSuccessMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('flashMessage.changesSaved')
      )
    }

    const subscription = watch(async (data) => {
      const societyId = societyStore?.selectedSociety?._id

      if (!societyId) {
        showErrorMessage()
        return
      }

      const hiddenSocietyWidgets = Object.keys(data).filter((key) => !data[key])
      const formData: NSocieties.NPatch.IRequestBody = {
        hiddenSocietyWidgets,
      }

      const status = await societyStore.patchSociety(societyId, formData)

      if (status) {
        showSuccessMessage()
      } else {
        showErrorMessage()
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, societyStore, setToastNotification, translate])

  return (
    <div className="space-y-2">
      <FunctionSettingsRow
        label={translate('residentListView.title')}
        name="resident-list"
        icon={IconChoices.RESIDENT_LIST}
        value
        disabled
      />
      <Controller
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <FunctionSettingsRow
            label={translate('boardListView.title')}
            name={name}
            icon={IconChoices.BOARD}
            value={value}
            onChange={onChange}
          />
        )}
        name="board"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <FunctionSettingsRow
            label={translate('contactsView.title')}
            name={name}
            icon={IconChoices.GROUP}
            value={value}
            onChange={onChange}
          />
        )}
        name="contacts"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <FunctionSettingsRow
            label={translate('societyCalendarListView.title')}
            name={name}
            icon={IconChoices.CALENDAR}
            value={value}
            onChange={onChange}
          />
        )}
        name="calendar"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <FunctionSettingsRow
            label={translate('documentsView.title')}
            name={name}
            icon={IconChoices.FOLDER}
            value={value}
            onChange={onChange}
          />
        )}
        name="documents"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <FunctionSettingsRow
            label={translate('facilitiesView.title')}
            name={name}
            icon={IconChoices.LAUNDRY}
            value={value}
            onChange={onChange}
          />
        )}
        name="facilities"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <FunctionSettingsRow
            label={translate('questionsAndAnswersView.title')}
            name={name}
            icon={IconChoices.QUESTION_MARK}
            value={value}
            onChange={onChange}
          />
        )}
        name="faqs"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <FunctionSettingsRow
            label={translate('houseRulesView.title')}
            name={name}
            icon={IconChoices.LIST}
            value={value}
            onChange={onChange}
          />
        )}
        name="rules"
      />

      <Controller
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <FunctionSettingsRow
            label={translate('localOffersView.title')}
            name={name}
            icon={IconChoices.LOCAL_OFFER}
            value={value}
            onChange={onChange}
          />
        )}
        name="local-offers"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <FunctionSettingsRow
            label={translate('reportProblemView.title')}
            name={name}
            icon={IconChoices.REPORT}
            value={value}
            onChange={onChange}
          />
        )}
        name="reportProblem"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <FunctionSettingsRow
            label={translate('marketplaceListView.title')}
            name={name}
            icon={IconChoices.STORE}
            value={value}
            onChange={onChange}
          />
        )}
        name="marketplace"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <FunctionSettingsRow
            label={translate('serviceView.title')}
            name={name}
            icon={IconChoices.OFFER}
            value={value}
            onChange={onChange}
          />
        )}
        name="services"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <FunctionSettingsRow
            label={translate('findFriendsView.title')}
            name={name}
            icon={IconChoices.GROUP}
            value={value}
            onChange={onChange}
          />
        )}
        name="find-friends"
      />
    </div>
  )
})
