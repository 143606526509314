import React, { useRef } from 'react'
import { InferType } from 'yup'
import { observer } from 'mobx-react-lite'
import { Modal } from '../../../../common/Modal'
import { ModalHeader } from '../../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../../common/Modal/ModalBody'
import { PreviewPostModalContent } from './PreviewPostModalContent'
import { societyCreatePostSchema } from '../../../../../forms/schemas/society_create_post'
import { ModalFooter } from '../../../../common/Modal/ModalFooter'
import { Button } from '../../../../common/Button'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'

interface PreviewPostModalProps {
  show: boolean
  close: () => void
  createPostData: InferType<typeof societyCreatePostSchema>
}

export const PreviewPostModal = observer(
  ({ show, close, createPostData }: PreviewPostModalProps): JSX.Element => {
    const ref = useRef(null)
    const { translate } = useAppTranslation()

    const postData = {
      author: createPostData.author,
      role: createPostData.role,
      society: createPostData.society,
      isAlert: createPostData.isAlert,
      pollIds: createPostData.pollIds,
      eventsIds: createPostData.eventsIds,
      groupsIds: createPostData.groupsIds,
      media: createPostData.media,
      documentsIds: createPostData.documentsIds,
      message: createPostData.message,
      accessBoardMember: createPostData.accessBoardMember,
      accessAdminMember: createPostData.accessAdminMember,
      accessUsersIds: createPostData.accessUsersIds,
      createdAt: new Date().toUTCString(),
      updatedAt: '',
    }

    return (
      <Modal ref={ref} show={show} size="lg">
        {{
          header: (
            <ModalHeader onClose={close}>
              {translate('previewPost.title')}
            </ModalHeader>
          ),
          body: (
            <ModalBody>
              <PreviewPostModalContent postData={postData} />
            </ModalBody>
          ),
          footer: (
            <ModalFooter>
              <Button
                label={translate('common.actions.close')}
                onClick={close}
              />
            </ModalFooter>
          ),
        }}
      </Modal>
    )
  }
)
