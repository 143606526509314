import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'

export const RequestModel = types.model('RequestModel', {
  _id: types.identifier,
  ...timestampTypes,
  userId: types.string,
  role: types.maybe(types.string),
  status: types.maybe(types.string),
  archive: types.maybe(types.boolean),
  societyId: types.maybe(types.string),
  unitId: types.maybe(types.string),
  message: types.maybe(types.string),
})
