/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'
import { EventAttendantModel } from './event-attendant'

export const EventModel = types.model('Event', {
  _id: types.identifier,
  ...timestampTypes,
  title: types.string,
  description: types.maybe(types.string),
  location: types.maybe(types.string),
  isAllDay: types.maybe(types.boolean),
  startDate: types.string,
  endDate: types.string,
  societyId: types.string,
  userId: types.string,
  notificationSettings: types.maybe(types.string),
  attendanceEnabled: types.maybe(types.boolean),
  attendance: types.maybe(types.array(EventAttendantModel)),
})
