import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../../hooks/useStores'
import { Card } from '../../../../components/common/Card'
import { CardBody } from '../../../../components/common/Card/CardBody'
import { theme } from '../../../../theme/theme'

export const DiscountCodes = observer((): JSX.Element => {
  const { settingsStore } = useStores()

  const discountCodes = settingsStore.listDiscountCodes

  useEffect(() => {
    settingsStore.getAllSettings()
  }, [settingsStore])

  return (
    <div className="h-5/6 w-full space-y-4">
      {discountCodes.map((discountCode) => (
        <Card key={discountCode.partnerNameId} className="w-[480px]">
          {{
            body: (
              <CardBody className="flex flex-row justify-center space-x-1">
                <p style={theme.textVariants.baseBold}>{discountCode.code}</p>
                <p
                  style={theme.textVariants.base}
                >{`(${discountCode.partnerNameId})`}</p>
              </CardBody>
            ),
          }}
        </Card>
      ))}
    </div>
  )
})
