/* eslint-disable max-len */
import React from 'react'
import { RouteObject } from 'react-router-dom'
import { AdminRightsView } from '../../../views/society/Management/AdminRightsView'
import { SocietySettingsView } from '../../../views/society/Management/SocietySettingsView'
import { InvoiceView } from '../../../views/society/Management/InvoiceView'
import { RegisterView } from '../../../views/society/Management/RegisterView'
import { SocietyWebsiteView } from '../../../views/society/Management/SocietyWebsiteView'
import { SocietyBlockedUsersView } from '../../../views/society/Management/SocietyBlockedUsersView'

const useSocietyAdminRoutes = (): RouteObject[] => {
  return [
    {
      path: 'billing/*',
      element: <InvoiceView />,
    },
    {
      path: 'register/*',
      element: <RegisterView />,
    },
    {
      path: 'admin-rights',
      element: <AdminRightsView />,
    },
    {
      path: 'website',
      element: <SocietyWebsiteView />,
    },
    {
      path: 'blocked-users',
      element: <SocietyBlockedUsersView />,
    },

    {
      path: 'settings',
      element: <SocietySettingsView />,
    },
  ]
}

useSocietyAdminRoutes.displayName = 'useSocietyAdminRoutes'
export { useSocietyAdminRoutes }
