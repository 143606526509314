import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../../hooks/useStores'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { BoardList } from '../../../../components/society/BoardList'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useModal } from '../../../../hooks/useModal'
// eslint-disable-next-line max-len
import { CreateUpdateBoardMemberModal } from '../../../../components/society/BoardList/CreateUpdateBoardMemberModal'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ButtonVariant } from '../../../../components/common/Button'
import { ViewBase } from '../../../../components/common/ViewBase'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { IconChoices } from '../../../../components/common/Icon'
import { IllustrationChoices } from '../../../../components/common/Illustration'

export const BoardListView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { societyStore, authenticationStore } = useStores()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('society:board', {
      '{{ societyName }}': society.name,
    })
  )
  const {
    show: showCreateModal,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModal()

  const isAdmin = isUserAdminInSociety(
    society,
    authenticationStore.userId as string
  )

  const loading = societyStore.fetchingSociety === 'pending'

  const societyBoardMemberList = society.boardMembersList
  const societyNominatingCommitteeList = society.nominatingCommitteeList

  const errorView =
    !loading &&
    societyBoardMemberList?.length === 0 &&
    societyNominatingCommitteeList?.length === 0
      ? {
          title: translate('boardListView.emptyState.title'),
          subtitle: translate('boardListView.emptyState.subtitle'),
          illustration: IllustrationChoices.EMPTY,
        }
      : undefined

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('boardListView.title')}
        buttons={[
          ...(isAdmin
            ? [
                {
                  variant: ButtonVariant.PRIMARY,
                  onClick: openCreateModal,
                  label: translate('boardListView.addBoardMember'),
                  icon: IconChoices.PLUS_SIGN,
                },
              ]
            : []),
        ]}
        loading={loading}
        errorView={errorView}
      >
        <BoardList />
      </ViewBase>
      {isAdmin && (
        <CreateUpdateBoardMemberModal
          show={showCreateModal}
          close={closeCreateModal}
        />
      )}
    </ErrorBoundary>
  )
})
