import React from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { InferType } from 'yup'
import { FormControl } from '../../../../../../components/common/FormControl'
import { useFormErrorMessage } from '../../../../../../hooks/useFormErrorMessage'
import {
  Button,
  ButtonVariant,
} from '../../../../../../components/common/Button'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { TextInput } from '../../../../../../components/common/TextInput'
import { ISocietyWebsiteModel } from '../../../../../../interfaces/models/societies-websites.interfaces'
import { adminEditSocietyWebsiteSchema } from '../../../../../../forms/schemas/admin_edit_society_website'

interface EditSocietyWebsiteModalContentProps {
  onError: (error: unknown) => void
  onSubmit: (
    data: InferType<typeof adminEditSocietyWebsiteSchema>
  ) => Promise<void>
  onClose: () => void
  website: ISocietyWebsiteModel
}

export const EditSocietyWebsiteModalContent = observer(
  ({
    onError,
    onSubmit,
    onClose,
    website,
  }: EditSocietyWebsiteModalContentProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { getErrorMessage } = useFormErrorMessage()

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(adminEditSocietyWebsiteSchema),
      defaultValues: {
        slug: website.slug ?? '',
        displayName: website.displayName ?? '',
        displayCityState: website.displayCityState ?? '',
      },
    })

    return (
      <form
        className="flex h-full flex-col gap-6"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Controller
          control={control}
          name="slug"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              error={errors.slug && getErrorMessage(errors.slug)}
              name={name}
              label="Slug"
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.slug}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="displayName"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              error={errors.displayName && getErrorMessage(errors.displayName)}
              name={name}
              label="Namn"
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.displayName}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="displayCityState"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              error={
                errors.displayCityState &&
                getErrorMessage(errors.displayCityState)
              }
              name={name}
              label="Stad"
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.displayCityState}
              />
            </FormControl>
          )}
        />

        <div className="flex flex-wrap justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid}
            label={translate('common.actions.save')}
            type="submit"
          />
        </div>
      </form>
    )
  }
)
