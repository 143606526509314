import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { SocietyModel } from '../../../../state/models/society'
import { ModalFooter } from '../../../common/Modal/ModalFooter'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { Button } from '../../../common/Button'
import { InactiveSociety } from '../../FindSociety/InactiveSociety'
import { RequestJoinSocietyForm } from '../../FindSociety/RequestiJoinSocietyForm'

interface AlreadyExistingSocietyModalProps {
  show: boolean
  close: () => void
  society: SnapshotOut<typeof SocietyModel> | undefined | null
}

export const AlreadyExistingSocietyModal = observer(
  ({ show, close, society }: AlreadyExistingSocietyModalProps): JSX.Element => {
    const { translate } = useAppTranslation()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const showInactiveForm = society?.status === 'inactive'

    return (
      <Modal show={show} size="sm">
        {{
          header: (
            <ModalHeader onClose={close}>
              {translate('createSocietyForm.headers.societyAlreadyExists')}
            </ModalHeader>
          ),
          body: (
            <ModalBody>
              {showInactiveForm ? (
                <>{society && <InactiveSociety society={society} />}</>
              ) : (
                <>
                  {society && (
                    <RequestJoinSocietyForm
                      society={society}
                      onFinish={close}
                    />
                  )}
                </>
              )}
            </ModalBody>
          ),
          footer: (
            <ModalFooter>
              <Button
                className="w-full"
                wrapperClassName="w-full"
                label={translate('common.actions.cancel')}
                onClick={close}
              />
            </ModalFooter>
          ),
        }}
      </Modal>
    )
  }
)
