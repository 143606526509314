import React, { ReactNode } from 'react'
import { AccordionContextProvider } from './useAccordionContext'

interface AccordionProps {
  children: ReactNode
  className?: string
  style?: React.CSSProperties
  hasBorder?: boolean
  activeItem?: number | null
}

export const Accordion = ({
  children,
  className,
  style,
  hasBorder,
  activeItem,
}: AccordionProps): JSX.Element => {
  return (
    <AccordionContextProvider activeItemForceUpdated={activeItem}>
      <div
        style={style}
        className={`${hasBorder ? 'rounded border' : ''} ${className ?? ''}`}
      >
        {children}
      </div>
    </AccordionContextProvider>
  )
}
