import i18next from 'i18next'
import { SnapshotOut } from 'mobx-state-tree'
import { getEducationField } from '../../../helpers/translations/education'
import { getInterest } from '../../../helpers/translations/interests'
import { getOccupationIndustry } from '../../../helpers/translations/occupation'
import { UserModel } from '../../../state/models/user'
import { EducationField } from '../../../types/education'
import { Interests } from '../../../types/interests'
import { OccupationIndustry } from '../../../types/occupation'
import { CategoryMatch } from './Matches/CategoryMatch'
import { SubcategoryMatch } from './Matches/SubcategoryMatch'

export const getMatches = (
  user: SnapshotOut<typeof UserModel>,
  residentUsers: (SnapshotOut<typeof UserModel> | undefined)[]
): CategoryMatch[] => {
  const matches: CategoryMatch[] = []
  const _definedResidentUsers = residentUsers.filter(
    (_user) => _user !== undefined
  ) as SnapshotOut<typeof UserModel>[]
  const nonUserResidents = _definedResidentUsers.filter(
    (_user) => _user._id !== user._id
  )

  const occupationEducationMatches: SubcategoryMatch[] = []

  // Occupation
  const userIndustry = user.aboutInfo?.occupation?.industry
  if (userIndustry) {
    const matchedIndustryUsers = nonUserResidents.filter(
      (_user) => _user?.aboutInfo?.occupation?.industry === userIndustry
    )
    if (matchedIndustryUsers.length > 0) {
      occupationEducationMatches.push({
        title: i18next.t(
          getOccupationIndustry(userIndustry as OccupationIndustry) as string
        ),
        users: matchedIndustryUsers,
      })
    }
  }

  // Education
  const userEducation = user.aboutInfo?.education
  if (userEducation) {
    if (userEducation.institute) {
      const matchedInstituteUsers = nonUserResidents.filter(
        (_user) =>
          _user?.aboutInfo?.education?.institute === userEducation.institute
      )
      if (matchedInstituteUsers.length > 0) {
        occupationEducationMatches.push({
          title: userEducation.institute,
          users: matchedInstituteUsers,
        })
      }
    }
    if (userEducation.field) {
      const matchedFieldUsers = nonUserResidents.filter(
        (_user) => _user?.aboutInfo?.education?.field === userEducation.field
      )
      if (matchedFieldUsers.length > 0) {
        occupationEducationMatches.push({
          title: i18next.t(
            getEducationField(userEducation.field as EducationField) as string
          ),
          users: matchedFieldUsers,
        })
      }
    }
  }

  if (occupationEducationMatches.length > 0) {
    matches.push({
      category: i18next.t('findFriends.categories.occupationAndEducation'),
      subcategories: occupationEducationMatches,
    })
  }

  // Interests
  const userInterests = user.aboutInfo?.interests
  const interestMatches: SubcategoryMatch[] = []
  userInterests?.forEach((_interest) => {
    const matchedInterestUsers = nonUserResidents.filter((_user) =>
      _user.aboutInfo?.interests?.includes(_interest)
    )
    if (matchedInterestUsers.length > 0) {
      interestMatches.push({
        title: i18next.t(getInterest(_interest as Interests) as string),
        users: matchedInterestUsers,
      })
    }
  })
  if (interestMatches.length > 0) {
    matches.push({
      category: i18next.t('findFriends.categories.interests'),
      subcategories: interestMatches,
    })
  }

  return matches
}
