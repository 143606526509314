/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, getRoot, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import { IChatMessageModel } from '../../interfaces/models/chat-messages.interfaces'
import { getRoomMessages as apiGetRoomMessages } from '../../api/chat'
import { NChat } from '../../interfaces/services/chat.interfaces'
import { stateType } from '../types/common'
import { ChatMessageModel } from '../models/chat-message'
import { RootStore } from './root'
import { setObject } from './helpers'
import { sortByDate } from '../../helpers/sorting'

export const GET_ROOM_MESSAGE_LIMIT = 20

export const ChatMessageStore = types
  .model('ChatMessageStore')
  .props({
    chatMessages: types.map(ChatMessageModel),
    fetchingChatMessages: stateType,
    readingMessageState: types.map(stateType),
  })
  .views((self) => ({
    getChatRoomMessages(id: string): SnapshotOut<typeof ChatMessageModel>[] {
      return (
        // @ts-ignore
        (values(self.chatMessages) as SnapshotOut<typeof ChatMessageModel>[])
          .filter((message) => message.roomId === id)
          .sort((a, b) => sortByDate(a.createdAt, b.createdAt, true))
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.chatMessages.clear()
      self.fetchingChatMessages = 'none'
      self.readingMessageState.clear()
    },
    readingMessage: (id: string): string => {
      const _readingMessage = self.readingMessageState.get(id)
      if (_readingMessage) {
        return _readingMessage
      }

      return 'none'
    },
    setChatMessages: (messages: IChatMessageModel[]) => {
      messages.forEach((message) => {
        setObject<typeof ChatMessageModel>(
          // @ts-ignore
          self.chatMessages,
          ChatMessageModel,
          message
        )
      })
    },
  }))
  .actions((self) => ({
    setChatMessageReadByUser: (messageId: string, userId: string) => {
      self.readingMessageState.set(messageId, 'pending')
      const message = self.chatMessages.get(messageId)
      if (message) {
        self.setChatMessages([
          {
            ...message,
            // @ts-ignore
            readBy: [
              ...(message.readBy || []),
              { userId, readTime: new Date() },
            ],
          },
        ])
      }
      self.readingMessageState.set(messageId, 'done')
    },
    getRoomMessages: flow(function* getRoomMessages(
      roomId: string,
      fetchNextPage = false
    ) {
      self.fetchingChatMessages = 'pending'
      try {
        const { getChatRoomMessages } = self
        const chatMessages = getChatRoomMessages(roomId)
        const date = chatMessages[chatMessages.length - 1]?.createdAt
          ? +new Date(chatMessages[chatMessages.length - 1]?.createdAt)
          : undefined
        const query = fetchNextPage
          ? { lastKnownDate: date, limit: GET_ROOM_MESSAGE_LIMIT }
          : {}
        const resp = yield apiGetRoomMessages(roomId, query)
        const data = resp.data as NChat.NGetRoomMessages.IResponse
        const messages = data.data

        const users = data?.populated?.users
        const media = data?.populated?.media

        const { userStore, mediaStore } = getRoot<RootStore>(self)
        if (media) {
          mediaStore.setMedia(media)
        }
        if (users) {
          userStore.setUsers(users)
        }

        self.setChatMessages(messages)

        self.fetchingChatMessages = 'done'
        return messages.length
      } catch (error) {
        captureException(error)
        self.fetchingChatMessages = 'error'
        return 0
      }
    }),
  }))
