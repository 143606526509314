import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Container } from '../../components/common/Container'
import { ErrorBoundary } from '../../components/common/ErrorBoundary'
import { SpinnerWrapper } from '../../components/common/SpinnerWrapper'
import { TabBar } from '../../components/common/TabBar'
import { ChangeLanguage } from '../../components/settings/ChangeLanguage'
import { MyInfo } from '../../components/settings/MyInfo'
import { NotificationSettings } from '../../components/settings/NotificationSettings'
import { ProfileSettings } from '../../components/settings/ProfileSettings'
import { useAppTranslation } from '../../hooks/useAppTranslation'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useStores } from '../../hooks/useStores'
import { reverseDocumentTitle } from '../../navigation/reverseDocumentTitle'
import { useSettingsTabs } from './useSettingsTabs'
import { RouteErrorView } from '../error/RouteErrorView'
import { useAuth } from '../../hooks/useAuth'

export enum ActiveTab {
  PROFILE_SETTINGS = 0,
  MY_INFO = 1,
  CHANGE_LANGUAGE = 2,
  NOTIFICATION_SETTINGS = 3,
  ADMIN_TOOLS = 4,
}

export const SettingsView = observer((): JSX.Element => {
  useDocumentTitle(reverseDocumentTitle('settings'))
  const { userId } = useAuth()
  const { translate } = useAppTranslation()
  const { adminStore, userStore, societyStore } = useStores()
  const user = userId ? userStore.users.get(userId) : undefined
  const location = useLocation()
  const { getTabUrl, getTabState } = useSettingsTabs()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<ActiveTab>(
    getTabState(location.pathname)
  )

  useEffect(() => {
    adminStore.getPermissionsStatus()
    if (userId) {
      societyStore.getUserSocieties(userId)
    }
  }, [adminStore, societyStore, userId])

  // When a new activeTab is set, we change the navigation to that tab as well
  useEffect(() => {
    const url = getTabUrl(activeTab)
    if (location.pathname !== url) {
      navigate(url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  // When a new location is set (e.g. backbutton) we change the activeTab as well
  useEffect(() => {
    setActiveTab(getTabState(location.pathname))
  }, [getTabState, location.pathname])

  const tabs = [
    {
      title: translate('profileSettings.title'),
    },
    {
      title: translate('settingsView.tabBar.tabs.myInfo'),
    },
    {
      title: translate('changeLanguage.title'),
    },
    {
      title: translate('settingsView.tabBar.tabs.notificationSettings'),
    },
  ]

  return (
    <ErrorBoundary>
      <Container>
        <TabBar tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        {!user ? (
          <SpinnerWrapper className="mt-8" />
        ) : (
          <Routes>
            <Route
              path="account"
              element={<ProfileSettings />}
              errorElement={<RouteErrorView />}
            />
            <Route
              path="my-info"
              element={<MyInfo />}
              errorElement={<RouteErrorView />}
            />
            <Route
              path="language"
              element={<ChangeLanguage />}
              errorElement={<RouteErrorView />}
            />
            <Route
              path="notifications"
              element={<NotificationSettings />}
              errorElement={<RouteErrorView />}
            />
          </Routes>
        )}
      </Container>
    </ErrorBoundary>
  )
})
