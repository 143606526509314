import React from 'react'
import { Observer, observer } from 'mobx-react-lite'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { InferType } from 'yup'
import { SnapshotOut } from 'mobx-state-tree'
import { DropdownSelect } from '../../common/DropdownSelect'
import { societyCreatePostSchema } from '../../../forms/schemas/society_create_post'
import { DropdownItemContent } from '../DropdownItemContent'
import { SocietyAvatarWithMediaId } from '../../common/Avatar'
import { FormControl } from '../../common/FormControl'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { SocietyModel } from '../../../state/models/society'
import { useIsMobile } from '../../../hooks/useIsMobile'

interface SocietyDropdownProps {
  setValue: UseFormSetValue<InferType<typeof societyCreatePostSchema>>
  control: Control<InferType<typeof societyCreatePostSchema>>
  societies: SnapshotOut<typeof SocietyModel>[]
}

export const SocietyDropdown = observer(
  ({ setValue, control, societies }: SocietyDropdownProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const isMobile = useIsMobile()

    const onCustomChange = (): void => {
      setValue('role', 'resident')
      setValue('groupsIds', [])
    }

    const dropdownOptions = societies.map((society) => ({
      value: society._id,
      label: society.name,
      image: (
        <SocietyAvatarWithMediaId size={30} mediaId={society?.coverPhotoId} />
      ),
    }))

    const shouldRender = societies.length > 1

    return (
      <>
        {shouldRender && (
          <Controller
            control={control}
            name="society"
            render={({ field: { name, value, onChange } }) => (
              <FormControl
                name={name}
                label={translate('common.form.labels.society')}
              >
                <DropdownSelect
                  value={value}
                  onChange={(val: unknown) => {
                    onChange(val)
                    onCustomChange()
                  }}
                  options={dropdownOptions}
                  dropdownWidth={isMobile ? 'w-full' : 'w-60'}
                  {...(!isMobile && {
                    dropdownContentWidth: 'w-fit',
                    dropdownPosition: 'bottom-right',
                  })}
                  renderItemContent={(option) => (
                    <Observer>
                      {() => <DropdownItemContent option={option} />}
                    </Observer>
                  )}
                />
              </FormControl>
            )}
          />
        )}
      </>
    )
  }
)
