import { useEffect, useState } from 'react'

interface WindowDimensionsReturnType {
  width: number
  height: number
}

export const useWindowDimensions = (): WindowDimensionsReturnType => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = (): void => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize) // cleanup
  }, [width, height])

  return { width, height }
}
