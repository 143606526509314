import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useStores } from '../../../../hooks/useStores'
import { CreateUpdateContractForm } from '../CreateUpdateContractForm'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ContractModel } from '../../../../state/models/contract'
import { societyContractCreateSchema } from '../../../../forms/schemas/society_contract_create'

interface CreateUpdateContractModalProps {
  show: boolean
  close: () => void
  children?: React.ReactNode
  contract?: SnapshotOut<typeof ContractModel>
}

export const CreateUpdateContractModal = observer(
  ({
    contract,
    children,
    show,
    close,
  }: CreateUpdateContractModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { contractStore } = useStores()
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!contract

    const showErrorMessage = (): void => {
      const message = updateMode
        ? 'createUpdateContractModal.flashMessage.updateContractFailure'
        : 'createUpdateContractModal.flashMessage.createContractFailure'
      setToastNotification(ToastType.DANGER, translate(message))
    }

    const showSuccessMessage = (): void => {
      const message = updateMode
        ? 'createUpdateContractModal.flashMessage.updateContractSuccess'
        : 'createUpdateContractModal.flashMessage.createContractSuccess'
      setToastNotification(ToastType.SUCCESS, translate(message))
    }

    const onSubmit = async (
      data: InferType<typeof societyContractCreateSchema>
    ): Promise<void> => {
      const _data = {
        ...data,
        documentIds: data.documentIds.map((document) => document.id),
      }
      if (updateMode) {
        const status = await contractStore.updateContract(contract._id, _data)
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      } else {
        const status = await contractStore.createContract(_data)
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      showErrorMessage()
    }

    const loading =
      contractStore.creatingContract === 'pending' ||
      contractStore.updatingContract === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate(
                  updateMode
                    ? 'createUpdateContractModal.editContractTitle'
                    : 'createUpdateContractModal.addNewContractTitle'
                )}
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <CreateUpdateContractForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  contract={contract}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
