// Event types from old app
export enum TrackingEventType {
  AppResumed = 'App Resumed',
  AppInitialized = 'App Initialized',
  PageView = 'Page View',
}

// Properties from old app
export enum TrackingProperty {
  Action = 'Action',
  Category = 'Category',
  Device = 'Device',
  IntegrationType = 'Integration type',
  PageName = 'Page Name',
  PartnerId = 'Partner Id',
  PartnerName = 'Partner Name',
  Platform = 'Platform',
  BannerName = 'Banner Name',
  OS = 'OS',
  Time = 'time',
  Segment = 'Partner Page Segment',
  SocietyId = 'Society Id',
  Status = 'Status',
  Store = 'Store',
  Name = 'Name',
  DistinctId = 'distinct_id',
  UserAppVersion = 'User App Version',
  UserRole = 'User Role',
}
