import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { SnapshotOut } from 'mobx-state-tree'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { FormControl } from '../../../common/FormControl'
import { SelectDropdown } from '../../../common/SelectDropdown'
import { societyAdminCreateSchema } from '../../../../forms/schemas/society_admin_create'
import { useUniqueNonAdminUsers } from '../../../../hooks/useUniqueNonAdminUsers'
import { useStores } from '../../../../hooks/useStores'
import { ExtendedUnitModel, getUnitString } from '../../../../helpers/units'
import { UnitModel } from '../../../../state/models/unit'

interface CreateUpdateAdminFormProps {
  onError: (error: unknown) => void
  onSubmit: (data: InferType<typeof societyAdminCreateSchema>) => Promise<void>
  onClose: () => void
  loading: boolean
}

export const CreateUpdateAdminForm = observer(
  ({
    loading,
    onError,
    onSubmit,
    onClose,
  }: CreateUpdateAdminFormProps): JSX.Element => {
    const { unitStore, societyEntrancesStore } = useStores()
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const uniqueNonAdminUsers = useUniqueNonAdminUsers(society)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isSamf } = society

    const userOptions = uniqueNonAdminUsers.map((_user) => {
      const userUnits = unitStore.userUnits(_user._id, society._id)
      const unitStrings = userUnits.map(
        (_unit: SnapshotOut<typeof UnitModel>) => {
          const entrance =
            _unit.entranceId &&
            societyEntrancesStore.entrances.get(_unit.entranceId)
          return getUnitString(
            { ..._unit, entrance } as ExtendedUnitModel,
            isSamf
          )
        }
      )
      const unitString =
        unitStrings.length > 0 ? ` - ${unitStrings.join(',')}` : ''
      return {
        value: _user._id,
        label: _user.fullName + unitString,
      }
    })

    const getDefaultValues = useCallback((): InferType<
      typeof societyAdminCreateSchema
    > => {
      return {
        userIds: [],
      }
    }, [])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyAdminCreateSchema),
      defaultValues: getDefaultValues(),
    })

    return (
      <>
        <Controller
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <FormControl
              label={translate('createUpdateAdminForm.labels.users')}
              name={name}
              error={errors.userIds && errors.userIds.message}
            >
              <SelectDropdown
                value={value}
                onChange={onChange}
                options={userOptions}
                error={!!errors.userIds}
                isMulti
                menuListHeight={200}
              />
            </FormControl>
          )}
          name="userIds"
        />
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-3">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
            variant={ButtonVariant.TEXT}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid || loading}
            label={translate('common.actions.save')}
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </>
    )
  }
)
