import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { addMonths, endOfMonth, startOfMonth } from 'date-fns'
import { FacilityModel } from '../../../../state/models/facility'
import { Button } from '../../../common/Button'
import { formatDateWithFormat } from '../../../../helpers/date'
import { capitalize } from '../../../../helpers/string'
import { theme } from '../../../../theme/theme'
import { Icon, IconChoices } from '../../../common/Icon'
import { useStores } from '../../../../hooks/useStores'
import { FacilityBookingsList } from '../FacilityBookingsList'
import { Divider } from '../../../common/Divider'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useIsMobile } from '../../../../hooks/useIsMobile'

interface FacilityBookingsProps {
  facility: SnapshotOut<typeof FacilityModel>
}

export const FacilityBookings = observer(
  ({ facility }: FacilityBookingsProps): JSX.Element | null => {
    const { bookingsStore } = useStores()
    const { translate } = useAppTranslation()
    const isMobile = useIsMobile()
    const [selectedStartOfMonthDate, setSelectedStartOfMonthDate] = useState(
      startOfMonth(new Date())
    )

    const addMonthsToSelected = (months: number): void => {
      const newStartOfMonthDate = startOfMonth(
        addMonths(selectedStartOfMonthDate, months)
      )
      setSelectedStartOfMonthDate(
        startOfMonth(addMonths(selectedStartOfMonthDate, months))
      )
      bookingsStore.getBookingsPerFacility(
        facility._id,
        newStartOfMonthDate,
        endOfMonth(newStartOfMonthDate)
      )
    }

    useEffect(() => {
      bookingsStore.getBookingsPerFacility(
        facility._id,
        selectedStartOfMonthDate,
        endOfMonth(selectedStartOfMonthDate)
      )
      // Only run on initialization
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <div className="w-full">
        <div className="mb-6 flex items-center justify-between">
          {isMobile ? (
            <button
              onClick={() => addMonthsToSelected(-1)}
              className="md:hidden"
            >
              <Icon icon={IconChoices.ARROW_LEFT} />
            </button>
          ) : (
            <Button
              label={translate('facilityView.bookings.buttons.previousMonth')}
              icon={IconChoices.ARROW_LEFT}
              onClick={() => addMonthsToSelected(-1)}
            />
          )}
          <p style={theme.textVariants.lead}>
            {capitalize(
              formatDateWithFormat(selectedStartOfMonthDate, 'MMMM yyyy')
            )}
          </p>
          {isMobile ? (
            <button
              onClick={() => addMonthsToSelected(1)}
              className="md:hidden"
            >
              <Icon icon={IconChoices.ARROW_RIGHT} />
            </button>
          ) : (
            <Button
              label={translate('facilityView.bookings.buttons.nextMonth')}
              icon={IconChoices.ARROW_RIGHT}
              iconPlacement="right"
              onClick={() => addMonthsToSelected(1)}
            />
          )}
        </div>
        <Divider />
        <FacilityBookingsList
          facility={facility}
          startOfMonthDate={selectedStartOfMonthDate}
        />
      </div>
    )
  }
)
