import { SnapshotOut } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import config from '../../../../config'
import { societyHasActivatedPartner } from '../../../../helpers/society'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { SocietyModel } from '../../../../state/models/society'
import { IconChoices } from '../../../common/Icon'
import { LinkSection } from '../LinkSection'

export const useBostadsratternaLinks = (
  society: SnapshotOut<typeof SocietyModel>
): LinkSection[] => {
  const { translate } = useAppTranslation()

  const id = society._id

  const bostadsratternaLinks: LinkSection[] = societyHasActivatedPartner(
    society,
    config.BOSTADSRATTERNA_PARTNER_ID
  )
    ? [
        {
          id: uuid(),
          title: 'Bostadsrätterna',
          sublinks: [
            {
              title: translate(
                'bostadsratterna.bostadsratternaGuidanceView.title'
              ),
              icon: IconChoices.QUESTION_MARK,
              url: reverseUrl('management:bostadsratterna-guidance', { id }),
            },
            {
              title: translate(
                'bostadsratterna.bostadsratternaBenefitsView.title'
              ),
              icon: IconChoices.HEART,
              url: reverseUrl('management:bostadsratterna-benefits', { id }),
            },
            {
              title: translate(
                'bostadsratterna.bostadsratternaSchoolView.title'
              ),
              icon: IconChoices.SCHOOL,
              url: reverseUrl('management:bostadsratterna-school', { id }),
            },
            {
              title: translate(
                'bostadsratterna.bostadsratternaStatutesView.title'
              ),
              icon: IconChoices.TASK,
              url: reverseUrl('management:bostadsratterna-statutes', { id }),
            },
            {
              title: translate(
                'bostadsratterna.bostadsratternaFormsView.title'
              ),
              icon: IconChoices.ASSIGNMENT,
              url: reverseUrl('management:bostadsratterna-forms', { id }),
            },
          ],
        },
      ]
    : []
  return bostadsratternaLinks
}
