/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ViewBase } from '../../../../components/common/ViewBase'
import { SocietyWebsiteGeneralInformation } from '../../../../components/society/SocietyWebsite/SocietyWebsiteGeneralInformation'
import { SocietyWebsiteImages } from '../../../../components/society/SocietyWebsite/SocietyWebsiteImages'
import { SocietyWebsiteDocuments } from '../../../../components/society/SocietyWebsite/SocietyWebsiteDocuments'
import { SocietyWebsiteQuestionsAndAnswers } from '../../../../components/society/SocietyWebsite/SocietyWebsiteQuestionsAndAnswers'
import { SocietyWebsiteContact } from '../../../../components/society/SocietyWebsite/SocietyWebsiteContact'
import { useStores } from '../../../../hooks/useStores'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { SocietyWebsiteModel } from '../../../../state/models/society-website'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { ButtonVariant } from '../../../../components/common/Button'
import { IconChoices } from '../../../../components/common/Icon'
import { useModal } from '../../../../hooks/useModal'
import { CreateUpdateSocietyWebsiteFAQSectionModal } from '../../../../components/society/SocietyWebsite/SocietyWebsiteQuestionsAndAnswers/SocietyWebsiteQuestionsAndAnswersSection/CreateUpdateSocietyWebsiteFAQSectionModal'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'

export enum ActiveTabBar {
  GENERAL_INFORMATION = 0,
  IMAGES = 1,
  DOCUMENTS = 2,
  FAQ = 3,
  CONTACT = 4,
}

interface SocietyWebsiteTabBarProps {
  activeTab: ActiveTabBar
  website: SnapshotOut<typeof SocietyWebsiteModel>
}

const SocietyWebsiteTabBar = ({
  activeTab,
  website,
}: SocietyWebsiteTabBarProps): JSX.Element => {
  switch (activeTab) {
    case ActiveTabBar.GENERAL_INFORMATION:
      return <SocietyWebsiteGeneralInformation website={website} />
    case ActiveTabBar.IMAGES:
      return <SocietyWebsiteImages website={website} />
    case ActiveTabBar.DOCUMENTS:
      return <SocietyWebsiteDocuments website={website} />
    case ActiveTabBar.FAQ:
      return <SocietyWebsiteQuestionsAndAnswers website={website} />
    default:
      return <SocietyWebsiteContact website={website} />
  }
}

export const SocietyWebsiteView = observer((): JSX.Element => {
  const { societyWebsitesStore } = useStores()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:website', {
      '{{ societyName }}': society.name,
    })
  )
  const { translate } = useAppTranslation()
  const userId = useAuthenticatedUserId()
  const [activeTab, setActiveTab] = useState<ActiveTabBar>(
    ActiveTabBar.GENERAL_INFORMATION
  )
  const isAdmin = isUserAdminInSociety(society, userId as string)
  const {
    show: showCreateSectionModal,
    open: openCreateSectionModal,
    close: closeCreateSectionModal,
  } = useModal()

  useEffect(() => {
    society._id && societyWebsitesStore.getWebsitePerSociety(society._id)
  }, [society._id, societyWebsitesStore])

  const tabs = [
    {
      title: translate('societyWebsiteView.tabBar.generalInformation'),
    },
    {
      title: translate('societyWebsiteView.tabBar.images'),
    },
    {
      title: translate('societyWebsiteView.tabBar.documents'),
    },
    {
      title: translate('societyWebsiteView.tabBar.questionsAndAnswers'),
    },
    {
      title: translate('societyWebsiteView.tabBar.contact'),
    },
  ]

  const website = society
    ? (societyWebsitesStore.websiteForSociety(society._id) as SnapshotOut<
        typeof SocietyWebsiteModel
      >)
    : undefined

  const buttons = [
    ...(isAdmin
      ? [
          ...(activeTab === ActiveTabBar.FAQ
            ? [
                {
                  label: translate(
                    'createUpdateQuestionsAndAnswers.addNewSectionTitle'
                  ),
                  variant: ButtonVariant.PRIMARY,
                  icon: IconChoices.PLUS_SIGN,
                  onClick: openCreateSectionModal,
                },
              ]
            : []),
        ]
      : []),
  ]

  const getSubtitleText = (): JSX.Element => {
    return (
      <>
        <p className="mt-2 text-neutral-40">
          {translate('societyWebsiteView.subTitle')}
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="text-neutral-40 underline hover:text-brandGreen"
            href="https://boappa.se/sidor/publiceringsvillkor"
          >
            {translate('societyWebsiteView.subTitleLinkText')}
          </a>
          .
        </p>
      </>
    )
  }

  const loading = societyWebsitesStore.fetchingWebsites === 'pending'

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('societyWebsiteView.title')}
        subtitle={getSubtitleText()}
        tabBar={{ tabs, setActiveTab, activeTab }}
        buttons={buttons}
        loading={loading}
      >
        {website && (
          <SocietyWebsiteTabBar activeTab={activeTab} website={website} />
        )}
      </ViewBase>
      {website && showCreateSectionModal && (
        <CreateUpdateSocietyWebsiteFAQSectionModal
          website={website}
          show={showCreateSectionModal}
          close={closeCreateSectionModal}
        />
      )}
    </ErrorBoundary>
  )
})
