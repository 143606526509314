import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from './useStores'
import { SocietyModel } from '../state/models/society'
import { uniqueBy } from '../helpers/array'
import { UserModel } from '../state/models/user'

export const useUniqueNonAdminUsers = (
  society: SnapshotOut<typeof SocietyModel> | undefined
): SnapshotOut<typeof UserModel>[] => {
  const { unitStore } = useStores()
  const residentUsers = unitStore.residentUsersForSociety(
    society?._id as string
  )

  const adminIds = society?.adminsList
    ? society.adminsList.map((admin) => admin.userId as string)
    : []

  const nonAdminUsers = residentUsers.filter(
    (user) => !adminIds.includes(user._id)
  )
  const uniqueNonAdminUsers = uniqueBy('_id', nonAdminUsers)

  return uniqueNonAdminUsers
}
