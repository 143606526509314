/* eslint-disable max-len */
import React, { KeyboardEvent, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import Cookies from 'universal-cookie'
import { useAtom } from 'jotai'
import { addDays } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { ViewBase } from '../../../../components/common/ViewBase'
import { theme } from '../../../../theme/theme'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { OnboardingSection } from '../../../../components/society/OnboardingSection'
import { CookieKeys } from '../../../../types/cookies'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { reverseUrl, ViewNames } from '../../../../navigation/reverseUrl'
import {
  managementActiveAccordionItemStateAtom,
  residentActiveAccordionItemStateAtom,
  selectedSocietySidebarMenuItemStateAtom,
} from '../../../../components/society/SocietySideBarContent/societySideBarSharedState'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'

export enum OnboardingSections {
  BOARD_DOCUMENTS = 1,
  REGISTER = 2,
  FAQ = 3,
  SOCIETY_WEBSITE = 4,
}

enum AccordionType {
  MANAGEMENT = 0,
  RESIDENT = 1,
}

const onboardingSectionsWithActiveItemIndex = [
  {
    section: OnboardingSections.BOARD_DOCUMENTS,
    accordionIndex: 0,
    type: AccordionType.MANAGEMENT,
    url: 'management:documents',
  },
  {
    section: OnboardingSections.REGISTER,
    accordionIndex: 1,
    type: AccordionType.MANAGEMENT,
    url: 'management:register',
  },
  {
    section: OnboardingSections.FAQ,
    accordionIndex: 0,
    type: AccordionType.RESIDENT,
    url: 'society:faq',
  },
  {
    section: OnboardingSections.SOCIETY_WEBSITE,
    accordionIndex: 1,
    type: AccordionType.MANAGEMENT,
    url: 'management:website',
  },
]

export const OnboardingView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('society:onboarding', {
      '{{ societyName }}': society.name,
    })
  )
  const [currentSection, setCurrentSection] = useState<OnboardingSections>(
    OnboardingSections.BOARD_DOCUMENTS
  )
  const [, setManagementActiveAccordionIndex] = useAtom(
    managementActiveAccordionItemStateAtom
  )
  const [, setResidentActiveAccordionIndex] = useAtom(
    residentActiveAccordionItemStateAtom
  )
  const [, setSelectedMenuItem] = useAtom(
    selectedSocietySidebarMenuItemStateAtom
  )

  const navigate = useNavigate()
  const userId = useAuthenticatedUserId() as string

  // Apparently for non string enums Object.keys returns both the keys and values, so need to divide by 2
  const totalSteps = Object.keys(OnboardingSections).length / 2

  const goForward = (): void => {
    setCurrentSection(currentSection + 1)
  }

  const goBack = (): void => {
    setCurrentSection(currentSection - 1)
  }

  const setOnboardingCookieAsViewed = (): void => {
    const cookies = new Cookies()
    const oboardingViewValue = CookieKeys.ONBOARDING_VIEWED.replace(
      '{% id %}',
      userId
    )
    cookies.set(oboardingViewValue, true, {
      sameSite: true,
      secure: true,
      expires: addDays(new Date(), 900),
    })
  }

  const finishGuide = (): void => {
    setOnboardingCookieAsViewed()
    setSelectedMenuItem(undefined)
    setManagementActiveAccordionIndex(null)
    setResidentActiveAccordionIndex(null)
    navigate(reverseUrl('society:detail', { id: society._id }))
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLParagraphElement>): void => {
    if (e.key === 'Enter') {
      finishGuide()
    }
  }

  // Open management documents society sidebar accordion section on initial render
  useEffect(() => {
    setManagementActiveAccordionIndex(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // This useEffect updates the society side bar open states depending on current onboarding section
  useEffect(() => {
    const societySidebarOnboardingItem =
      onboardingSectionsWithActiveItemIndex.find(
        (item) => item.section === currentSection
      )
    if (societySidebarOnboardingItem) {
      if (societySidebarOnboardingItem.type === AccordionType.MANAGEMENT) {
        setResidentActiveAccordionIndex(null)
        setManagementActiveAccordionIndex(
          societySidebarOnboardingItem.accordionIndex
        )
      } else {
        setResidentActiveAccordionIndex(
          societySidebarOnboardingItem.accordionIndex
        )
        setManagementActiveAccordionIndex(null)
      }
      setSelectedMenuItem(
        reverseUrl(societySidebarOnboardingItem.url as ViewNames, {
          id: society._id,
        })
      )
    }
  }, [
    currentSection,
    setManagementActiveAccordionIndex,
    setResidentActiveAccordionIndex,
    setSelectedMenuItem,
    society._id,
  ])

  // TODO: Subtitle translations are in swedish only atm, update after copy is done
  const onboardingSections = [
    {
      step: OnboardingSections.BOARD_DOCUMENTS,
      title: translate('onboardingView.onboardingSections.documents.title'),
      subtitle: translate(
        'onboardingView.onboardingSections.documents.subtitle'
      ),
    },
    {
      step: OnboardingSections.REGISTER,
      title: translate('onboardingView.onboardingSections.register.title'),
      subtitle: translate(
        'onboardingView.onboardingSections.register.subtitle'
      ),
    },
    {
      step: OnboardingSections.FAQ,
      title: translate('onboardingView.onboardingSections.faq.title'),
      subtitle: translate('onboardingView.onboardingSections.faq.subtitle'),
    },
    {
      step: OnboardingSections.SOCIETY_WEBSITE,
      title: translate(
        'onboardingView.onboardingSections.societyWebsite.title'
      ),
      subtitle: translate(
        'onboardingView.onboardingSections.societyWebsite.subtitle'
      ),
    },
  ]

  const currentSectionData = onboardingSections.find(
    (section) => section.step === currentSection
  )

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('onboardingView.title', { societyName: society.name })}
        subtitle={translate('onboardingView.subtitle')}
      >
        <div className="mb-10 flex flex-col space-y-1">
          <p style={theme.textVariants.baseBold}>
            {translate('onboardingView.explanationTitle')}
          </p>
          <p style={theme.textVariants.base} className="max-w-[800px]">
            {translate('onboardingView.explanationText')}
          </p>
        </div>
        {currentSectionData && (
          <OnboardingSection
            {...currentSectionData}
            totalSteps={totalSteps}
            goBack={goBack}
            goForward={goForward}
            finishGuide={finishGuide}
          />
        )}
        {currentSection !== totalSteps && (
          <p
            role="presentation"
            onKeyDown={handleKeyDown}
            className="mt-6 w-full content-end text-center underline hover:cursor-pointer hover:text-brandGreen md:mt-10"
            style={theme.textVariants.base}
            onClick={finishGuide}
          >
            {translate('onboardingView.skipText')}
          </p>
        )}
      </ViewBase>
    </ErrorBoundary>
  )
})
