import React from 'react'
import { theme } from '../../../theme/theme'

interface PostCalloutProps {
  text: string
}

export const PostCallout = ({ text }: PostCalloutProps): JSX.Element => {
  return (
    <div className="flex items-center rounded-md bg-brandLightGreen p-3">
      <p className="my-0" style={theme.textVariants.base}>
        {text}
      </p>
    </div>
  )
}
