import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useModal } from '../../../hooks/useModal'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { useUnitsString } from '../../../hooks/useUnitsString'
import { ChatRoomModel } from '../../../state/models/chat-room'
import { UserModel } from '../../../state/models/user'
import { theme } from '../../../theme/theme'
import { UserAvatar } from '../../common/Avatar'
import { ConfirmationModal } from '../../common/ConfirmationModal'
import { IconChoices } from '../../common/Icon'
import { ToastType } from '../../common/Toast/toast-type'
import { UserListItem } from '../../common/UserListItem/UserListItem'

interface UserListProps {
  user: SnapshotOut<typeof UserModel>
  chatRoom: SnapshotOut<typeof ChatRoomModel>
}

export const UserList = ({ user, chatRoom }: UserListProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const { chatRoomStore, unitStore } = useStores()
  const userUnits = unitStore.userUnits(user._id, chatRoom.societyId)
  const unitsString = useUnitsString(userUnits)
  const { setToastNotification } = useToastNotifications()
  const {
    show: showDeleteModal,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModal()

  const isAdmin = user._id === chatRoom.adminId
  const icon = isAdmin ? IconChoices.ADMIN : IconChoices.DELETE

  const deleteChatRoomMemberEvent = async (): Promise<void> => {
    const status = await chatRoomStore.removeChatRoomMember(
      chatRoom._id,
      user._id
    )
    if (status) {
      setToastNotification(
        ToastType.SUCCESS,
        translate('chatSettings.flashMessage.deleteChatRoomMemberSuccess')
      )
    } else {
      setToastNotification(
        ToastType.DANGER,
        translate('chatSettings.flashMessage.deleteChatRoomMemberFailure')
      )
    }
  }

  return (
    <>
      {isAdmin ? (
        <UserListItem
          avatar={<UserAvatar user={user} />}
          name={user.name || ''}
          text={unitsString || ''}
          textStyle={theme.textVariants.baseBold}
          icon={icon}
          disabled
        />
      ) : (
        <UserListItem
          avatar={<UserAvatar user={user} />}
          name={user.name || ''}
          text={unitsString || ''}
          textStyle={theme.textVariants.baseBold}
          icon={icon}
          onIconClick={openDeleteModal}
        />
      )}
      <ConfirmationModal
        title={translate('chatSettings.alert.chatRoomMemberDeleteTitle')}
        description={translate('chatSettings.alert.chatRoomMemberDeleteText')}
        show={showDeleteModal}
        close={closeDeleteModal}
        onConfirm={deleteChatRoomMemberEvent}
      />
    </>
  )
}
