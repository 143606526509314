/* eslint-disable max-len */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { ErrorBoundary } from '../../../../../components/common/ErrorBoundary'
import { ViewBase } from '../../../../../components/common/ViewBase'
import { ManagementPartnerCard } from '../../../../../components/society/ManagementPartner/ManagementPartnerCard'
import { ManagementPartner } from '../../../../../components/society/ManagementPartner/management_partner'
import { ManagementPartnerBase } from '../../../../../components/society/ManagementPartner/ManagementPartnerBase'
import { SAMFALLIGHETERNA_BENEFITS_LINK } from '../../../../../constants/Links'
import { useCurrentSociety } from '../../../../../hooks/useCurrentSociety'
import { useDocumentTitle } from '../../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../../navigation/reverseDocumentTitle'

export const SamfalligheternaBenefitsView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:samfalligheterna-benefits', {
      '{{ societyName }}': society.name,
    })
  )

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('samfalligheterna.samfalligheternaBenefitsView.title')}
      >
        <ManagementPartnerBase
          partner={ManagementPartner.VILLAAGARNA}
          description={translate(
            'samfalligheterna.samfalligheternaBenefitsView.description'
          )}
        >
          <ManagementPartnerCard
            url={SAMFALLIGHETERNA_BENEFITS_LINK}
            title={translate(
              'samfalligheterna.samfalligheternaBenefitsView.benefitsTitle'
            )}
            text={translate(
              'samfalligheterna.samfalligheternaBenefitsView.benefitsText'
            )}
          />
        </ManagementPartnerBase>
      </ViewBase>
    </ErrorBoundary>
  )
})
