import { AxiosResponse } from 'axios'
import { NDocuments } from '../interfaces/services/documents.interfaces'
import { createUrl } from './helpers'
import axiosAuth from './config'

export const getPerSocieties = (
  societyIds: string[]
): Promise<AxiosResponse<NDocuments.NGetPerSocieties.IResponse>> => {
  const body: NDocuments.NGetPerSocieties.IRequestBody = {
    societyIds,
  }
  return axiosAuth.post<NDocuments.NGetPerSocieties.IResponse>(
    createUrl('/documents/get-per-societies'),
    body
  )
}

export const createDocument = (
  body: NDocuments.NCreate.IRequestBody
): Promise<AxiosResponse<NDocuments.NCreate.IResponse>> => {
  return axiosAuth.post<NDocuments.NCreate.IResponse>(
    createUrl('/documents/document'),
    body
  )
}

export const patchDocument = (
  id: string,
  body: NDocuments.NPatch.IRequestBody
): Promise<AxiosResponse<NDocuments.NPatch.IResponse>> => {
  return axiosAuth.patch<NDocuments.NPatch.IResponse>(
    createUrl(`/documents/document/${id}`),
    body
  )
}

export const deleteDocument = (
  id: string
): Promise<AxiosResponse<NDocuments.NDel.IResponse>> => {
  return axiosAuth.delete<NDocuments.NDel.IResponse>(
    createUrl(`/documents/document/${id}`)
  )
}
