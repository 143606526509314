import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonVariant } from '../../../../components/common/Button'
import { TextInput } from '../../../../components/common/TextInput'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { useStores } from '../../../../hooks/useStores'
import { theme } from '../../../../theme/theme'
import { ToastType } from '../../../../components/common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { FormControl } from '../../../../components/common/FormControl'
import { resetPasswordSchema } from '../../../../forms/schemas/reset_password'

interface ResetPasswordFormProps {
  setResetPasswordEmailSent: () => void
}

export const ResetPasswordForm = observer(
  ({ setResetPasswordEmailSent }: ResetPasswordFormProps): JSX.Element => {
    const { userStore } = useStores()
    const navigate = useNavigate()
    const { translate } = useAppTranslation()
    const { getErrorMessage } = useFormErrorMessage()
    const { setToastNotification } = useToastNotifications()

    const getDefaultValues = (): InferType<typeof resetPasswordSchema> => {
      return {
        email: '',
      }
    }

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'onBlur',
      reValidateMode: 'onChange',
      resolver: yupResolver(resetPasswordSchema),
      defaultValues: getDefaultValues(),
    })

    const onSubmit = async (
      data: InferType<typeof resetPasswordSchema>
    ): Promise<void> => {
      const { email } = data

      const emailRegisteredStatus = await userStore.isEmailRegistered(
        email.toLowerCase()
      )

      if (!emailRegisteredStatus) {
        setToastNotification(
          ToastType.DANGER,
          translate(
            'authenticationResetPasswordView.flashMessage.accountDoesNotExist'
          )
        )
        return
      }

      const sendResetPasswordStatus = await userStore.sendResetPassword(
        email.toLowerCase()
      )

      if (sendResetPasswordStatus) {
        setToastNotification(
          ToastType.SUCCESS,
          translate(
            'authenticationResetPasswordView.flashMessage.passwordResetSuccess'
          )
        )
        setResetPasswordEmailSent()
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate(
            'authenticationResetPasswordView.flashMessage.passwordResetFailure'
          )
        )
      }
    }

    const onError = (): void => {
      if (!isValid) return
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const loading =
      userStore.checkingEmailRegistered === 'pending' ||
      userStore.sendingPasswordReset === 'pending'

    return (
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className="flex flex-col gap-4"
      >
        <Controller
          control={control}
          name="email"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              error={errors.email && getErrorMessage(errors.email)}
              name={name}
              label={translate(
                'authenticationResetPasswordView.form.labels.email'
              )}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type="email"
                error={!!errors.email}
              />
            </FormControl>
          )}
        />
        <div className="mt-4 flex flex-col gap-4 md:mt-12">
          <Button
            type="submit"
            style={{
              ...theme.textVariants.paragraph,
            }}
            className="flex h-14 w-full items-center justify-center"
            label={translate(
              'authenticationResetPasswordView.resetPasswordButtonTitle'
            )}
            onClick={handleSubmit(onSubmit, onError)}
            loading={loading}
            variant={ButtonVariant.PRIMARY}
          />
          <Button
            style={{
              ...theme.textVariants.paragraph,
            }}
            className="flex h-14 w-full items-center justify-center"
            label={translate('common.actions.cancel')}
            onClick={() => navigate(-1)}
            disabled={loading}
          />
        </div>
      </form>
    )
  }
)
