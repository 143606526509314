import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Icon, IconChoices } from '../../../components/common/Icon'
import { queryKeys } from '../../../api/services'
import { theme } from '../../../theme/theme'
import { useLocale } from '../../../hooks/useLocale'
import { Spinner } from '../../../components/common/Spinner'
import { ServiceNavigation } from '../ServiceNavigation'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'

const ProviderServices = (): React.ReactNode => {
  const { service: provider } = useParams()
  const { translate } = useAppTranslation()
  const { data } = useQuery({
    ...queryKeys.Service(provider),
    throwOnError: true,
  })

  const { data: providers } = useQuery({
    ...queryKeys.providers(),
    throwOnError: true,
  })

  const locale = useLocale()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const services = data?.data.data
  const currentPageTitle = (): string => {
    const foundProvider = providers?.data.data.find((p) => p.id === provider)
    if (foundProvider) {
      return locale === 'en' ? foundProvider.title.en : foundProvider.title.sv
    }
    return ''
  }

  return (
    <div className="pb-4">
      <ServiceNavigation
        toPageTitle={translate('serviceView.title')}
        currentPageTitle={currentPageTitle()}
      />
      <ul>
        {!services && (
          <div className="grid place-items-center">
            <Spinner />
          </div>
        )}
        {services?.map((service) => {
          return (
            <li key={service.title.sv}>
              <Link
                to={`./${service.title.sv}`}
                className="w-full mb-2 overflow-hidden rounded-md shadow-md border block p-2"
              >
                <div className="w-full">
                  <div className="flex w-full p-2 gap-4 justify-between">
                    <div>
                      <p style={theme.textVariants.baseBold}>
                        {locale === 'en' ? service.title.en : service.title.sv}
                      </p>
                    </div>
                    <Icon
                      flexItems="items-center"
                      icon={IconChoices.ARROW_RIGHT}
                    />
                  </div>
                </div>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export { ProviderServices }
