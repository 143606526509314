import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import Fuse from 'fuse.js'
import { v4 as uuid } from 'uuid'
import { useStores } from '../../../../hooks/useStores'
import { MarketplaceList } from '../../../../components/society/MarketplaceList'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { IconChoices } from '../../../../components/common/Icon'
import { ButtonVariant } from '../../../../components/common/Button'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
// eslint-disable-next-line
import { CreateUpdateMarketplaceItemModal } from '../../../../components/society/MarketplaceItem/CreateUpdateMarketplaceItemModal'
import { useModal } from '../../../../hooks/useModal'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { ViewBase } from '../../../../components/common/ViewBase'
import { IllustrationChoices } from '../../../../components/common/Illustration'
import { ButtonPropsWithId, ErrorView } from '../../../error/ErrorView'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { useUserBlockedInSociety } from '../../../../hooks/useUserBlockedInSociety'
import { BlockedCallout } from '../../../../components/blocked/BlockedCallout'

export const MarketplaceListView = observer((): JSX.Element => {
  const [searchString, setSearchString] = useState('')
  const { resourcesStore, authenticationStore, unitStore } = useStores()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const userBlockedInSociety = useUserBlockedInSociety(society._id)
  useDocumentTitle(
    reverseDocumentTitle('society:marketplace', {
      '{{ societyName }}': society.name,
    })
  )
  const { translate } = useAppTranslation()
  const {
    show: showCreateModal,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModal()

  const resources = resourcesStore.resourcesForSociety(society._id)

  useEffect(() => {
    if (society._id) {
      resourcesStore.getResourcesPerSociety(society._id)
    }
  }, [resourcesStore, society._id])

  const userSocietyUnits = useMemo(() => {
    return authenticationStore.userId
      ? unitStore.userUnits(
          authenticationStore.userId as string,
          society._id,
          true
        )
      : []
  }, [authenticationStore.userId, society, unitStore])

  const getFilteredResources = useCallback(() => {
    const options = {
      threshold: 0.3,
      keys: ['title', 'description'],
    }

    const fuse = new Fuse(resources, options)

    const filteredResources =
      searchString === ''
        ? resources
        : fuse.search(searchString).map((result) => result.item)

    return filteredResources
  }, [resources, searchString])

  const filteredResources = getFilteredResources()

  const onChangeSearchString = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    setSearchString(event.currentTarget.value)
  }

  const dataIsBeingFetched = resourcesStore.fetchingResources === 'pending'
  const noListings = filteredResources.length === 0

  const buttons =
    !userBlockedInSociety && !noListings && userSocietyUnits.length > 0
      ? [
          {
            label: translate('marketplaceListView.buttons.createListing'),

            variant: ButtonVariant.PRIMARY,
            icon: IconChoices.PLUS_SIGN,

            onClick: openCreateModal,
          },
        ]
      : []

  const errorViewButtons: ButtonPropsWithId[] | undefined =
    userSocietyUnits.length > 0
      ? ([
          {
            id: uuid(),
            button: {
              label: translate('marketplaceListView.buttons.createListing'),
              variant: ButtonVariant.PRIMARY,
              icon: IconChoices.PLUS_SIGN,
              onClick: openCreateModal,
            },
          },
        ] as ButtonPropsWithId[])
      : undefined

  const errorView: ErrorView | undefined =
    noListings && searchString === ''
      ? {
          title: translate('marketplaceListView.emptyState.title'),
          subtitle:
            userSocietyUnits.length > 0
              ? translate('marketplaceListView.emptyState.subtitle')
              : translate('marketplaceListView.emptyState.subtitleMissingUnit'),
          buttons: errorViewButtons,
          illustration: IllustrationChoices.EMPTY,
        }
      : undefined

  const noListingsFoundView: ErrorView | undefined =
    noListings && searchString !== ''
      ? {
          title: translate('marketplaceListView.noListingFound.title'),
          subtitle: translate('marketplaceListView.noListingFound.subtitle'),
          illustration: IllustrationChoices.EMPTY,
        }
      : undefined

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('marketplaceListView.title')}
        subtitle={translate('marketplaceListView.subtitle')}
        buttons={buttons}
        searchBar={{ value: searchString, onChange: onChangeSearchString }}
        loading={dataIsBeingFetched}
        errorView={errorView || noListingsFoundView}
      >
        {userBlockedInSociety && (
          <BlockedCallout selectedSocietyId={society._id} className="mb-4" />
        )}
        <MarketplaceList resources={filteredResources} />
      </ViewBase>
      <CreateUpdateMarketplaceItemModal
        show={showCreateModal}
        close={closeCreateModal}
      />
    </ErrorBoundary>
  )
})
