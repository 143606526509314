import { ViewNames } from './reverseUrl'
import i18n from '../i18n/i18n'

const _BASE_TITLE = 'Boappa'

const pathTitleMap: { [key in ViewNames]: string[] } = {
  index: [],
  login: [i18n.t('authenticationSignInView.title')],
  signup: [i18n.t('authenticationSignUpView.title')],
  'reset-password': [i18n.t('authenticationResetPasswordView.title')],
  'verify-email': [i18n.t('verifyEmailView.title')],
  feed: [i18n.t('feedView.title')],
  chat: [i18n.t('chatView.title')],
  'chat:detail': [i18n.t('chatView.title')],
  admin: [i18n.t('adminView.title')],
  'admin:users': [i18n.t('adminView.title')],
  'admin:societies': [i18n.t('adminView.title')],
  'admin:discount-codes': [i18n.t('adminView.title')],
  'admin:pending-invoices': [i18n.t('adminView.title')],
  'admin:twilight-zone': [i18n.t('adminView.title')],
  'admin:audit-logs': [i18n.t('adminView.title')],
  'admin:data-export': [i18n.t('adminView.title')],
  'admin:webinars': [i18n.t('adminView.title')],
  settings: [i18n.t('common.settings')],
  'settings-account': [i18n.t('common.settings')],
  'settings-my-info': [i18n.t('common.settings')],
  'settings-language': [i18n.t('common.settings')],
  'settings-notifications': [i18n.t('common.settings')],
  'settings-superadmin': [i18n.t('common.settings')],

  'post:detail': [],

  'user:detail': ['{{ userName }}'],
  'user:society-detail': ['{{ userName }}', '{{ societyName }}'],

  'find-society': [i18n.t('findSocietyView.title')],
  'find-society:confirm-activation-detail': [
    i18n.t('findSocietyConfirmActivationView.title'),
  ],
  'find-society:confirm-activation-units-detail': [
    i18n.t('findSocietyConfirmActivationView.title'),
  ],
  'create-society': [i18n.t('createSocietyTypeSelectionView.title')],
  'create-society:pick-type': [i18n.t('createSocietyTypeSelectionView.title')],
  'activated-society': [i18n.t('activatedSocietyConfirmationView.title')],

  society: [i18n.t('societyView.title')],
  'society:home': [i18n.t('societyView.title')],
  'society:detail': [i18n.t('societyView.title')],
  'society:marketplace': [
    i18n.t('marketplaceListView.title'),
    '{{ societyName }}',
  ],
  'society:marketplace-detail': [
    '{{ resourceName }}',
    i18n.t('marketplaceListView.title'),
    '{{ societyName }}',
  ],
  'society:onboarding': [
    i18n.t('onboardingView.subtitle'),
    '{{ societyName }}',
  ],
  'society:find-friends': [
    i18n.t('findFriendsView.title'),
    '{{ societyName }}',
  ],
  'society:resident-list': [
    i18n.t('residentListView.title'),
    '{{ societyName }}',
  ],
  'society:board': [i18n.t('boardListView.title'), '{{ societyName }}'],
  'society:contacts': [i18n.t('contactsView.title'), '{{ societyName }}'],
  'society:calendar': [
    i18n.t('societyCalendarListView.title'),
    '{{ societyName }}',
  ],
  'society:calendar-detail': [
    `{{ eventName }}`,
    i18n.t('societyCalendarListView.title'),
    '{{ societyName }}',
  ],
  'society:calendar-detail-information': [
    `{{ eventName }}`,
    i18n.t('societyCalendarListView.title'),
    '{{ societyName }}',
  ],
  'society:calendar-detail-attendants': [
    `{{ eventName }}`,
    i18n.t('societyCalendarListView.title'),
    '{{ societyName }}',
  ],
  'society:facilities': [i18n.t('facilitiesView.title'), '{{ societyName }}'],
  'society:facilities-create': [
    i18n.t('createUpdateFacility.createFacilityTitle'),
    i18n.t('facilitiesView.title'),
    '{{ societyName }}',
  ],
  'society:facilities-detail': [
    '{{ facilityName }}',
    i18n.t('facilitiesView.title'),
    '{{ societyName }}',
  ],
  'society:facilities-detail-calendar': [
    '{{ facilityName }}',
    i18n.t('facilitiesView.title'),
    '{{ societyName }}',
  ],
  'society:facilities-detail-information': [
    '{{ facilityName }}',
    i18n.t('facilitiesView.title'),
    '{{ societyName }}',
  ],
  'society:facilities-detail-bookings': [
    '{{ facilityName }}',
    i18n.t('facilitiesView.title'),
    '{{ societyName }}',
  ],
  'society:facilities-update': [
    i18n.t('createUpdateFacility.editFacilityTitle'),
    '{{ facilityName }}',
    i18n.t('facilitiesView.title'),
    '{{ societyName }}',
  ],
  'society:faq': [i18n.t('questionsAndAnswersView.title'), '{{ societyName }}'],
  'society:house-rules': [i18n.t('houseRulesView.title'), '{{ societyName }}'],
  'society:documents': [i18n.t('documentsView.title'), '{{ societyName }}'],
  'society:documents-detail': [
    '{{ documentName }}',
    i18n.t('documentsView.title'),
    '{{ societyName }}',
  ],
  'society:widgets-detail': ['{{ widgetName }}', '{{ societyName }}'],
  'society:local-offers': [
    i18n.t('localOffersView.title'),
    '{{ societyName }}',
  ],
  'society:local-offers-detail': [
    '{{ localOfferName }}',
    i18n.t('localOffersView.title'),
    '{{ societyName }}',
  ],
  'society:report-problem': [
    i18n.t('reportProblemView.title'),
    '{{ societyName }}',
  ],
  // services
  'society:services': [i18n.t('serviceView.title'), '{{ societyName }}'],
  'society:services-service': [
    i18n.t('serviceView.title'),
    '{{ societyName }}',
  ],
  'society:services-service-product': [
    i18n.t('serviceView.title'),
    '{{ societyName }}',
  ],

  // Management routes outside management portal
  'management:function-settings': [
    i18n.t('common.settings'),
    '{{ societyName }}',
  ],

  // Callout
  'management:member-guidance': ['{{ societyName }}'],
  'management:register-guidance': ['{{ societyName }}'],

  // Management board work area
  'management:documents': [
    i18n.t('managementDocumentsView.title'),
    '{{ societyName }}',
  ],
  'management:documents-detail': [
    '{{ documentName }}',
    i18n.t('managementDocumentsView.title'),
    '{{ societyName }}',
  ],
  'management:contacts': [
    i18n.t('boardContactsView.title'),
    '{{ societyName }}',
  ],
  'management:calendar': [
    i18n.t('boardCalendarView.title'),
    '{{ societyName }}',
  ],
  'management:calendar-detail': [
    '{{ eventName }}',
    i18n.t('boardCalendarView.title'),
    '{{ societyName }}',
  ],
  'management:calendar-detail-information': [
    '{{ eventName }}',
    i18n.t('boardCalendarView.title'),
    '{{ societyName }}',
  ],
  'management:calendar-detail-attendants': [
    '{{ eventName }}',
    i18n.t('boardCalendarView.title'),
    '{{ societyName }}',
  ],
  'management:statistics-exports': [
    i18n.t('societyStatisticsExportsView.title'),
    '{{ societyName }}',
  ],
  'management:information': [
    i18n.t('societyInformationView.title'),
    '{{ societyName }}',
  ],
  'management:contracts': [
    i18n.t('societyContractsView.title'),
    '{{ societyName }}',
  ],
  'management:contracts-detail': [
    '{{ contractTitle }}',
    i18n.t('societyContractsView.title'),
    '{{ societyName }}',
  ],
  'management:gdpr': [i18n.t('gdprView.title'), '{{ societyName }}'],

  // Management admin area
  'management:billing': [i18n.t('invoiceView.title'), '{{ societyName }}'],
  'management:billing-payment': [
    i18n.t('invoiceView.title'),
    '{{ societyName }}',
  ],
  'management:billing-details': [
    i18n.t('invoiceView.title'),
    '{{ societyName }}',
  ],
  'management:register': [i18n.t('registerView.title'), '{{ societyName }}'],
  // TabBar
  'management:register-entrance': [
    i18n.t('registerView.title'),
    '{{ societyName }}',
  ],
  'management:register-unit': [
    i18n.t('registerView.title'),
    '{{ societyName }}',
  ],
  'management:register-member': [
    i18n.t('registerView.title'),
    '{{ societyName }}',
  ],
  'management:register-nonmember': [
    i18n.t('registerView.title'),
    '{{ societyName }}',
  ],
  'management:register-group': [
    i18n.t('registerView.title'),
    '{{ societyName }}',
  ],
  'management:admin-rights': [
    i18n.t('adminRightsView.title'),
    '{{ societyName }}',
  ],
  'management:website': [
    i18n.t('societyWebsiteView.title'),
    '{{ societyName }}',
  ],
  'management:blocked-users': [
    i18n.t('societyBlockedUsersView.title'),
    '{{ societyName }}',
  ],
  'management:settings': [
    i18n.t('societySettingsView.title'),
    '{{ societyName }}',
  ],

  // Management bostadsratterna area
  'management:bostadsratterna-guidance': [
    i18n.t('bostadsratterna.bostadsratternaGuidanceView.title'),
    '{{ societyName }}',
  ],
  'management:bostadsratterna-school': [
    i18n.t('bostadsratterna.bostadsratternaSchoolView.title'),
    '{{ societyName }}',
  ],
  'management:bostadsratterna-forms': [
    i18n.t('bostadsratterna.bostadsratternaFormsView.title'),
    '{{ societyName }}',
  ],
  'management:bostadsratterna-statutes': [
    i18n.t('bostadsratterna.bostadsratternaStatutesView.title'),
    '{{ societyName }}',
  ],
  'management:bostadsratterna-benefits': [
    i18n.t('bostadsratterna.bostadsratternaBenefitsView.title'),
    '{{ societyName }}',
  ],

  // Management samfalligheterna area
  'management:samfalligheterna-guidance': [
    i18n.t('samfalligheterna.samfalligheternaGuidanceView.title'),
    '{{ societyName }}',
  ],
  'management:samfalligheterna-school': [
    i18n.t('samfalligheterna.samfalligheternaSchoolView.title'),
    '{{ societyName }}',
  ],
  'management:samfalligheterna-benefits': [
    i18n.t('samfalligheterna.samfalligheternaBenefitsView.title'),
    '{{ societyName }}',
  ],
}

export const reverseDocumentTitle = (
  viewName: ViewNames,
  kwargs?: { [key: string]: string | undefined }
): string => {
  const kwargKeys = kwargs ? Object.keys(kwargs) : undefined
  if (!kwargs || !kwargKeys) {
    return [...pathTitleMap[viewName], _BASE_TITLE].join(' · ')
  }

  const processedTitles = pathTitleMap[viewName].map((_title) => {
    if (kwargKeys.includes(_title)) {
      const kwargValue = kwargs[_title]
      if (!kwargValue) {
        return _title
      }
      return kwargValue || _title
    }
    return _title
  })
  return [...processedTitles, _BASE_TITLE].join(' · ')
}
