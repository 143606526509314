import React, { ReactNode, useState } from 'react'
import { usePopperBase } from '../../../hooks/usePopperBase'
import { theme } from '../../../theme/theme'
import { Button, ButtonProps } from '../Button'
import { Icon } from '../Icon'

interface PopoverProps {
  children: ReactNode
  buttonProps: ButtonProps
  useButtonIconOnly?: boolean
}

export const Popover = ({
  children,
  buttonProps,
  useButtonIconOnly,
}: PopoverProps): JSX.Element => {
  const [show, setShow] = useState<boolean>(false)
  const {
    styles,
    attributes,
    setReferenceElement,
    setArrowElement,
    setPopperElement,
  } = usePopperBase()

  const toggleShow = (): void => {
    setShow(!show)
  }

  return (
    <>
      {useButtonIconOnly && buttonProps.icon ? (
        <div className="w-5" ref={setReferenceElement}>
          <Icon icon={buttonProps.icon} onClick={toggleShow} />
        </div>
      ) : (
        <Button
          {...buttonProps}
          ref={setReferenceElement}
          onClick={toggleShow}
        />
      )}
      {show && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="rounded border"
        >
          {children}
          <div ref={setArrowElement} style={styles.arrow} />
        </div>
      )}
    </>
  )
}

interface TextPopoverProps {
  title: string
  text: string
  buttonProps: ButtonProps
  useButtonIconOnly?: boolean
}

export const TextPopover = ({
  buttonProps,
  title,
  text,
  useButtonIconOnly,
}: TextPopoverProps): JSX.Element => {
  return (
    <Popover useButtonIconOnly={useButtonIconOnly} buttonProps={buttonProps}>
      <div className="flex w-44 flex-col space-y-1 rounded bg-black p-4 text-white">
        <p style={theme.textVariants.captionBold}>{title}</p>
        <p style={theme.textVariants.caption}>{text}</p>
      </div>
    </Popover>
  )
}
