import React, { useCallback } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { Button, ButtonVariant } from '../../../../common/Button'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { theme } from '../../../../../theme/theme'
// eslint-disable-next-line max-len
import {
  weeklySchedule as weeklyScheduleSchema,
  societyFacilityWeeklyScheduleCreateSchema,
} from '../../../../../forms/schemas/society_facility_weekly_schedule_create'
import { Toggle } from '../../../../common/Toggle'
import { TimePicker } from '../../../../common/TimePicker'
import { useWeekdays } from '../useWeekdays'

interface WeeklyScheduleFormProps {
  onError: (error: unknown) => void
  onSubmit: (
    data: InferType<typeof societyFacilityWeeklyScheduleCreateSchema>
  ) => Promise<void>
  onClose: () => void
  weeklySchedule: InferType<typeof weeklyScheduleSchema>
}

export const WeeklyScheduleForm = observer(
  ({
    weeklySchedule,
    onError,
    onSubmit,
    onClose,
  }: WeeklyScheduleFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const weekdays = useWeekdays()

    const getDefaultValues = useCallback((): InferType<
      typeof societyFacilityWeeklyScheduleCreateSchema
    > => {
      return { weeklySchedule }
    }, [weeklySchedule])

    const {
      control,
      handleSubmit,
      watch,
      formState: { isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyFacilityWeeklyScheduleCreateSchema),
      defaultValues: getDefaultValues(),
    })

    const { fields } = useFieldArray({
      name: 'weeklySchedule',
      control,
    })

    const processSelectedEndTime = (startTime: Date, endTime: Date): Date => {
      // Make sure same year, month, date as startTime
      endTime.setUTCFullYear(startTime.getUTCFullYear())
      endTime.setUTCMonth(startTime.getUTCMonth())
      endTime.setUTCDate(startTime.getUTCDate())

      const _sanityCheckedDate = endTime <= startTime ? startTime : endTime

      return _sanityCheckedDate
    }

    return (
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className="flex w-full flex-col space-y-4"
      >
        <div className="flex w-full justify-between border-b pb-3">
          <p className="w-3/12 md:w-1/4">
            {translate('createUpdateFacility.form.headers.day')}
          </p>
          <p className="mr-1 w-4/12 md:mr-4 md:w-1/4">
            {translate('createUpdateFacility.form.headers.startTime')}
          </p>
          <p className="mr-1 w-4/12 md:mr-4 md:w-1/4">
            {translate('createUpdateFacility.form.headers.endTime')}
          </p>
          <p className="w-3/12">
            {translate('createUpdateFacility.form.headers.active')}
          </p>
        </div>
        {fields.map((field, index) => {
          const weekDay = weekdays[index]
          const watchStartTime = watch(`weeklySchedule.${index}.startTime`)
          const watchEndTime = watch(`weeklySchedule.${index}.endTime`)

          return (
            <div
              key={field.id}
              className="flex w-full items-center justify-between"
            >
              <p className="w-3/12 md:w-1/4" style={theme.textVariants.base}>
                {weekDay}
              </p>
              <div className="mr-1 flex w-4/12 md:mr-4 md:w-1/4">
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TimePicker
                      timeInterval={30}
                      date={new Date(value)}
                      disableRawInput
                      onChange={(val) =>
                        onChange(
                          val >= new Date(watchEndTime)
                            ? new Date(watchEndTime)
                            : val
                        )
                      }
                    />
                  )}
                  name={`weeklySchedule.${index}.startTime`}
                />
              </div>
              <div className="mr-1 w-4/12 md:mr-4 md:w-1/4">
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TimePicker
                      timeInterval={30}
                      date={new Date(value)}
                      disableRawInput
                      onChange={(val: Date) => {
                        onChange(
                          processSelectedEndTime(new Date(watchStartTime), val)
                        )
                      }}
                    />
                  )}
                  name={`weeklySchedule.${index}.endTime`}
                />
              </div>
              <div className="w-3/12">
                <Controller
                  control={control}
                  render={({ field: { value, onChange, name } }) => (
                    <div style={{ flex: 0.8, alignItems: 'flex-end' }}>
                      <Toggle
                        name={name}
                        enabled={value || false}
                        onChange={onChange}
                      />
                    </div>
                  )}
                  name={`weeklySchedule.${index}.allowed`}
                />
              </div>
            </div>
          )
        })}
        <p style={theme.textVariants.base}>
          {translate('createUpdateFacility.weeklyScheduleDescription')}
        </p>
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            disabled={!isValid}
            label={translate('common.actions.save')}
            variant={ButtonVariant.PRIMARY}
            type="submit"
          />
        </div>
      </form>
    )
  }
)
