import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Navigate, useParams, useSearchParams } from 'react-router-dom'
import { Container } from '../../components/common/Container'
import { Post } from '../../components/feed/Post'
import { useStores } from '../../hooks/useStores'
import { ButtonVariant } from '../../components/common/Button'
import { LinkButton } from '../../components/common/LinkButton'
import { reverseUrl } from '../../navigation/reverseUrl'
import { useAppTranslation } from '../../hooks/useAppTranslation'
import { IconChoices } from '../../components/common/Icon'
import { SpinnerWrapper } from '../../components/common/SpinnerWrapper'
import { ErrorView } from '../error/ErrorView'
import { IllustrationChoices } from '../../components/common/Illustration'
import { useAuth } from '../../hooks/useAuth'

export const PostView = observer((): JSX.Element => {
  const { userId } = useAuth()
  const { id } = useParams()
  const { societyStore, postStore } = useStores()
  const { hasFetchedSocietiesOnce } = societyStore
  const { translate } = useAppTranslation()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (userId && !hasFetchedSocietiesOnce) {
      societyStore.getUserSocieties(userId)
    }
    if (id) {
      postStore.getPost(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!id) {
    return <Navigate to="/not-found" replace />
  }

  const post = id ? postStore.posts.get(id) : undefined
  const loading = id ? postStore.fetchingPost(id) === 'pending' : false

  if (!post && loading) {
    return <SpinnerWrapper />
  }

  return (
    <div className="flex flex-1 flex-col items-center overflow-y-scroll bg-gray-100">
      <Container className="md:max-w-4xl md:pb-0" overflowScrollY={false}>
        <LinkButton
          className="hover:bg-neutral-92"
          variant={ButtonVariant.TEXT}
          label={translate('feedView.title')}
          icon={IconChoices.ARROW_LEFT}
          size="sm"
          url={reverseUrl('feed')}
          urlIsExternal={false}
        />

        {post ? (
          <div className="pb-1 md:pb-6">
            <Post
              key={id}
              postId={id}
              displayComments
              scrollToId={searchParams.get('commentId') || undefined}
            />
          </div>
        ) : (
          <ErrorView
            illustration={IllustrationChoices.EMPTY}
            title={translate('postView.errorView.removed.title')}
            subtitle={translate('postView.errorView.removed.subtitle')}
            titleWrapperClassName="mt-4"
          />
        )}
      </Container>
    </div>
  )
})
