import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../../../../hooks/useStores'
import { useToastNotifications } from '../../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../../../components/common/Toast/toast-type'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import {
  Button,
  ButtonVariant,
} from '../../../../../../components/common/Button'
import { ConfirmationModal } from '../../../../../../components/common/ConfirmationModal'
import { useModal } from '../../../../../../hooks/useModal'
import { theme } from '../../../../../../theme/theme'
import { useAdminSociety } from '../../useAdminSociety'

interface DeletionStageProps {
  close: () => void
  societyId?: string
}

export const DeletionStage = observer(
  ({ societyId, close }: DeletionStageProps): JSX.Element => {
    const { adminStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()
    const {
      show: showDeleteSocietyModal,
      open: openDeleteSocietyModal,
      close: closeDeleteSocietyModal,
    } = useModal()
    const { adminSociety } = useAdminSociety(societyId)

    const deleteSociety = async (): Promise<void> => {
      const status =
        societyId &&
        (await adminStore.deleteSociety({
          societyId,
        }))
      if (status) {
        setToastNotification(ToastType.SUCCESS, 'Förening raderad')
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }
      close()
    }

    const loading = adminStore.deletingSociety === 'pending'

    return (
      <>
        <div className="flex h-full w-full flex-col gap-6">
          <div className="flex flex-col">
            <div className="flex flex-col items-center justify-center gap-10">
              <p style={theme.textVariants.h3}>
                Du tar nu bort följande förening:
              </p>
              <div>
                <p style={theme.textVariants.base}>
                  Namn: {adminSociety?.name}
                </p>
                <p style={theme.textVariants.base}>
                  Org nr: {adminSociety?.organisationNumber}
                </p>
              </div>
              <p
                className="animate-bounce text-red"
                style={theme.textVariants.h3}
              >
                Detta går inte att ångra!
              </p>
            </div>
          </div>
          <div className="flex flex-wrap justify-end gap-4">
            <Button label="Avbryt" onClick={close} />
            <Button
              variant={ButtonVariant.DANGER}
              label="Radera"
              onClick={openDeleteSocietyModal}
              loading={loading}
              disabled={loading}
            />
          </div>
        </div>
        <ConfirmationModal
          title="Radera förening"
          description="Är du säker på att du vill radera föreningen? Detta går inte att ångra."
          show={showDeleteSocietyModal}
          close={closeDeleteSocietyModal}
          onConfirm={deleteSociety}
          confirmationButtonLabel="Radera"
        />
      </>
    )
  }
)
