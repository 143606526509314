import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../hooks/useStores'
import { IconChoices } from '../../common/Icon'
import { Button } from '../../common/Button'
import { Notification } from '../Notification'
import { NotificationBadge } from '../NotificationBadge'
import { Dropdown } from '../../common/Dropdown'
import { INotificationModel } from '../../../interfaces/models/notifications.interfaces'

interface NotificationButtonControllerProps {
  navBarHeight?: number
  buttonSize?: 'sm' | 'md' | 'lg' | 'no-padding'
}

export const NotificationButtonController = observer(
  ({
    navBarHeight,
    buttonSize,
  }: NotificationButtonControllerProps): JSX.Element => {
    const [showNotifications, setShowNotifications] = useState(false)
    const { notificationsStore, societyStore, requestStore } = useStores()
    const { sortedNotifications } = notificationsStore
    const displayNotificationBadge = notificationsStore.unseenCount > 0

    useEffect(() => {
      const { hasFetchedRequestsOnce } = requestStore
      const societies = societyStore.sortedSocieties
      const societyIds = societies.map((_society) => _society._id)
      if (
        societyIds.length > 0 &&
        societyStore.fetchingPreApprovedSocieties !== 'pending' &&
        !hasFetchedRequestsOnce
      ) {
        requestStore.getRequestsPerSocieties(societyIds)
      }
    }, [
      societyStore.sortedSocieties,
      requestStore,
      societyStore.fetchingPreApprovedSocieties,
    ])

    const filteredNotifications = sortedNotifications.filter(
      (notification) =>
        notification.type !== 'hey-new-posts' &&
        !/^chat.*message/.test(notification.type) &&
        notification.type !== 'user-added-to-unit' &&
        notification.type !== 'invite-all-admin-to-society'
    )

    const setNotificationsAsSeen = (
      _notifications: INotificationModel[]
    ): void => {
      const unseenNotifications = _notifications.filter(
        (notification) =>
          !/^chat.*message/.test(notification.type) &&
          notification.isSeen === false
      )
      if (unseenNotifications.length > 0) {
        notificationsStore.setNotificationsSeen(
          unseenNotifications.map((notification) => notification._id)
        )
      }
    }

    const onClick = async (): Promise<void> => {
      if (!showNotifications) {
        const _notifications = await notificationsStore.getList()
        setNotificationsAsSeen(_notifications)
      }
    }

    const dropdownOptions = filteredNotifications.map((notification) => ({
      value: notification._id,
      label: notification._id,
      customContent: (
        <Notification key={notification._id} notification={notification} />
      ),
    }))

    const loading =
      !notificationsStore.hasFetchedNotificationsOnce &&
      notificationsStore.fetchingNotifications === 'pending'

    return (
      <Dropdown
        dropdownClassName="max-h-[400px] md:max-h-[600px] overflow-y-auto"
        style={{
          height: navBarHeight ? navBarHeight - 1 : navBarHeight,
          width: navBarHeight,
        }}
        dropdownPosition="bottom-left"
        dropdownContentWidth="w-fit"
        options={dropdownOptions}
        openChanged={(open) => setShowNotifications(open)}
      >
        <NotificationBadge
          show={displayNotificationBadge}
          className="h-full w-full"
        >
          <Button
            wrapperClassName="h-full w-full"
            icon={IconChoices.BELL}
            onClick={onClick}
            className="h-full w-full rounded-none border-none bg-transparent"
            size={buttonSize}
            disableTabIndex
            loading={loading}
          />
        </NotificationBadge>
      </Dropdown>
    )
  }
)
