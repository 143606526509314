/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import {
  getHouseRulesPerSociety as apiGetHouseRulesPerSociety,
  getHouseRule as apiGetHouseRule,
  createHouseRule as apiCreateHouseRule,
  updateHouseRule as apiUpdateHouseRule,
  deleteHouseRule as apiDeleteHouseRule,
} from '../../api/house-rules'
import { stateType } from '../types/common'
import { setObject } from './helpers'
import { HouseRuleModel } from '../models/house-rule'
import { IHouseRuleModel } from '../../interfaces/models/house-rules.interfaces'
import { NHouseRules } from '../../interfaces/services/house-rules.interfaces'
import { sortStringsAlphabetically } from '../../helpers/sorting'

export const HouseRuleStore = types
  .model('HouseRuleStore')
  .props({
    houseRules: types.map(HouseRuleModel),
    fetchingHouseRules: stateType,
    updatingHouseRule: stateType,
    creatingHouseRule: stateType,
  })
  .views((self) => ({
    sortedHouseRulesForSociety(
      societyId: string
    ): SnapshotOut<typeof HouseRuleModel>[] {
      // @ts-ignore
      return (
        // @ts-ignore
        (values(self.houseRules) as SnapshotOut<typeof HouseRuleModel>[])
          // @ts-ignore
          .filter((rule) => rule.society._id === societyId)
          .sort((a, b) => sortStringsAlphabetically(a.section, b.section))
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.houseRules.clear()
      self.fetchingHouseRules = 'none'
      self.updatingHouseRule = 'none'
      self.creatingHouseRule = 'none'
    },
    setRules: (rules: IHouseRuleModel[]) => {
      rules.forEach((rule) => {
        // @ts-ignore
        setObject<typeof HouseRuleModel>(self.houseRules, HouseRuleModel, rule)
      })
    },
  }))
  .actions((self) => ({
    getHouseRulesPerSociety: flow(function* getHouseRulesPerSociety(
      societyId: string
    ) {
      self.fetchingHouseRules = 'pending'
      try {
        const resp = yield apiGetHouseRulesPerSociety(societyId)
        const data = resp.data as NHouseRules.NGetPerSocieties.IResponse
        const houseRules = data.data

        self.setRules(houseRules)

        self.fetchingHouseRules = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingHouseRules = 'error'
      }
    }),
    getHouseRule: flow(function* getHouseRule(id: string) {
      self.fetchingHouseRules = 'pending'
      try {
        const resp = yield apiGetHouseRule(id)
        const data = resp.data as NHouseRules.NGetById.IResponse
        const houseRule = data.data

        self.setRules([houseRule])

        self.fetchingHouseRules = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingHouseRules = 'error'
      }
    }),
    updateHouseRule: flow(function* updateHouseRule(
      id: string,
      body: NHouseRules.NPatch.IRequestBody
    ) {
      self.updatingHouseRule = 'pending'
      try {
        const resp = yield apiUpdateHouseRule(id, body)
        const data = resp.data as NHouseRules.NPatch.IResponse
        const houseRule = data.data

        if (houseRule !== null) {
          self.setRules([houseRule])
        }

        self.updatingHouseRule = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingHouseRule = 'error'
        return false
      }
    }),
    createHouseRule: flow(function* createHouseRule(
      body: NHouseRules.NCreate.IRequestBody
    ) {
      self.creatingHouseRule = 'pending'
      try {
        const resp = yield apiCreateHouseRule(body)
        const data = resp.data as NHouseRules.NCreate.IResponse
        const houseRule = data.data

        if (houseRule !== null) {
          self.setRules([houseRule])
        }

        self.creatingHouseRule = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingHouseRule = 'error'
        return false
      }
    }),
    deleteHouseRule: flow(function* deleteHouseRule(id: string) {
      try {
        yield apiDeleteHouseRule(id)
        self.houseRules.delete(id)
        return true
      } catch (error) {
        captureException(error)
        return false
      }
    }),
  }))
