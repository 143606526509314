import { SnapshotOut } from 'mobx-state-tree'
import React, { useState } from 'react'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { SocietyModel } from '../../../../state/models/society'
import { theme } from '../../../../theme/theme'
import { TabBar } from '../../../common/TabBar'
import { SocietyInformation } from '../SocietyInformation'
import { JoinInactiveSocietyBoard } from './JoinInactiveSocietyBoard'
import { JoinInactiveSocietyMember } from './JoinInactiveSocietyMember'

export enum ActiveTabBar {
  MEMBER = 0,
  BOARD = 1,
}

interface InactiveSocietyProps {
  society: SnapshotOut<typeof SocietyModel>
}

export const InactiveSociety = ({
  society,
}: InactiveSocietyProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const [activeTab, setActiveTab] = useState<ActiveTabBar>(ActiveTabBar.MEMBER)

  return (
    <div className="mr-6 flex flex-col md:mr-2">
      <p style={theme.textVariants.h3}>{translate('inactiveSociety.title')}</p>
      <p className="max-w-96 my-4" style={theme.textVariants.base}>
        {translate('inactiveSociety.subtitle')}
      </p>
      <SocietyInformation alignTextStart society={society} />
      <div className="mt-6 border-t" />
      <TabBar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        className="mt-2 md:mt-4"
        tabs={[
          {
            title: translate('inactiveSociety.tabs.member'),
          },
          {
            title: translate('inactiveSociety.tabs.board'),
          },
        ]}
      />
      <div className="w-full py-2 md:py-4">
        {ActiveTabBar.MEMBER === activeTab ? (
          <>{society && <JoinInactiveSocietyMember society={society} />}</>
        ) : (
          <>{society && <JoinInactiveSocietyBoard society={society} />}</>
        )}
      </div>
    </div>
  )
}
