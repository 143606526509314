export const isDevEnv = (): boolean => {
  if (import.meta.env.VITE_APP_VERCEL_ENV === 'production') {
    return false
  }
  if (
    import.meta.env.VITE_APP_VERCEL_ENV === 'preview' ||
    import.meta.env.VITE_APP_VERCEL_ENV === 'development' ||
    import.meta.env.VITE_APP_DEPLOY_PREVIEW === 'preview' ||
    import.meta.env.DEV ||
    import.meta.env.MODE === 'test'
  ) {
    return true
  }
  return false
}
