import React from 'react'
import { captureException } from '@sentry/react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { Modal } from '../../../../../components/common/Modal'
import { ModalHeader } from '../../../../../components/common/Modal/ModalHeader'
import { ModalBody } from '../../../../../components/common/Modal/ModalBody'
import { useStores } from '../../../../../hooks/useStores'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../../components/common/Toast/toast-type'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { EditSocietyModalContent } from './EditSocietyModalContent'
import { adminEditSocietySchema } from '../../../../../forms/schemas/admin_edit_society'
import { formatOrganisationNumber } from '../../../../../helpers/organisation_number'
import { useAdminSociety } from '../useAdminSociety'

interface EditSocietyModalProps {
  show: boolean
  close: () => void
  resetSearchResult: () => void
  societyId?: string
}

export const EditSocietyModal = observer(
  ({ show, close, societyId }: EditSocietyModalProps): JSX.Element => {
    const { adminStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()
    const { adminSociety, triggerRefresh } = useAdminSociety(societyId)

    const initialOrganisationNumber = adminSociety?.organisationNumber

    const onSubmit = async (
      data: InferType<typeof adminEditSocietySchema>
    ): Promise<void> => {
      const { organisationNumber, ...rest } = data
      // Avoid sending organisationNumber if it hasn't been changed, backend doesn't allow already existing
      // organisation numbers
      const dataToSend =
        initialOrganisationNumber &&
        organisationNumber &&
        formatOrganisationNumber(`${initialOrganisationNumber}`) ===
          formatOrganisationNumber(`${organisationNumber}`)
          ? rest
          : data
      const status =
        societyId &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (await adminStore.patchSociety({ societyId, data: dataToSend }))

      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('Förening uppdaterad')
        )
        triggerRefresh()
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }
      close()
    }

    const onError = (error: unknown): void => {
      captureException(error)
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    return (
      <Modal show={show} size="xl">
        {{
          header: <ModalHeader onClose={close}>Uppdatera förening</ModalHeader>,
          body: (
            <ModalBody className="overflow-y-auto">
              {adminSociety && (
                <EditSocietyModalContent
                  onSubmit={onSubmit}
                  onError={onError}
                  onClose={close}
                  society={adminSociety}
                />
              )}
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
