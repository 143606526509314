import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../../hooks/useStores'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { QuestionsAndAnswersItemContent } from './QuestionsAndAnswersItem/QuestionsAndAnswersItemContent'
import { useModal } from '../../../../hooks/useModal'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { CreateUpdateQuestionsAndAnswersSectionModal } from './CreateUpdateQuestionsAndAnswersSectionModal'
// eslint-disable-next-line max-len
import { CreateUpdateQuestionsAndAnswersItemModal } from './QuestionsAndAnswersItem/CreateUpdateQuestionsAndAnswersItemModal'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { ConfirmationModal } from '../../../common/ConfirmationModal'
import { AccordionSection } from '../../../common/AccordionSection'
import { Option } from '../../../common/Dropdown'

interface QuestionsAndAnswersSectionProps {
  title: string
  id: string
}

export const QuestionsAndAnswersSection = observer(
  ({ title, id }: QuestionsAndAnswersSectionProps): JSX.Element => {
    const { faqsStore } = useStores()
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()
    const {
      show: showCreateQuestionModal,
      open: openCreateQuestionModal,
      close: closeCreateQuestionModal,
    } = useModal()
    const {
      show: showEditSectionModal,
      open: openEditSectionModal,
      close: closeEditSectionModal,
    } = useModal()
    const {
      show: showRemoveSectionConfirmationModal,
      open: openRemoveSectionConfirmationModal,
      close: closeRemoveSectionConfirmationModal,
    } = useModal()
    const authUserId = useAuthenticatedUserId()
    const questions = faqsStore.getQuestionsForSection(id)

    const isAdmin = isUserAdminInSociety(society, authUserId as string)

    const accordionQuestions = questions.map((question) => ({
      ...question,
      content: question.answer,
      title: question.question,
    }))

    const removeSection = async (): Promise<void> => {
      const status = await faqsStore.deleteFaqSection(id)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate(
            'createUpdateQuestionsAndAnswers.flashMessage.deleteSectionSuccess'
          )
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate(
            'createUpdateQuestionsAndAnswers.flashMessage.deleteSectionFailure'
          )
        )
      }
    }

    const dropdownOptions: Option[] | undefined = isAdmin
      ? [
          {
            value: 'create',
            label: translate(
              'createUpdateQuestionsAndAnswers.addNewQuestionTitle'
            ),
            onClick: () => openCreateQuestionModal(),
          },
          {
            value: 'edit',
            label: translate('common.actions.edit'),
            onClick: () => openEditSectionModal(),
          },
          {
            value: 'remove',
            label: translate('common.actions.delete'),
            onClick: () => openRemoveSectionConfirmationModal(),
            destructive: true,
          },
        ]
      : undefined

    return (
      <div className="mb-10 w-full space-y-10">
        <AccordionSection
          title={title}
          items={accordionQuestions.map((_question) => ({
            id: _question._id,
            title: `${_question.title}`,
            content: (
              <QuestionsAndAnswersItemContent
                sectionId={id}
                questionId={_question._id}
                content={_question.content}
              />
            ),
          }))}
          dropdownOptions={dropdownOptions}
          itemsEmptyText={translate(
            'questionsAndAnswersView.emptyState.noQuestionsAndAnswersText'
          )}
        />
        <CreateUpdateQuestionsAndAnswersItemModal
          sectionId={id}
          show={showCreateQuestionModal}
          close={closeCreateQuestionModal}
        />
        <CreateUpdateQuestionsAndAnswersSectionModal
          sectionId={id}
          show={showEditSectionModal}
          close={closeEditSectionModal}
        />
        <ConfirmationModal
          title={translate(
            'createUpdateQuestionsAndAnswers.removeSectionConfirmationModal.title'
          )}
          description={translate(
            'createUpdateQuestionsAndAnswers.removeSectionConfirmationModal.description'
          )}
          show={showRemoveSectionConfirmationModal}
          close={closeRemoveSectionConfirmationModal}
          onConfirm={removeSection}
          deleteMode
        />
      </div>
    )
  }
)
