import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { postFilterSchema } from '../../../forms/schemas/post_filter'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { theme } from '../../../theme/theme'
import { Button, ButtonVariant } from '../../common/Button'
import { Dropdown } from '../../common/Dropdown'
import { IconChoices } from '../../common/Icon'
import { ToastType } from '../../common/Toast/toast-type'
import { PostFilterDropdownOption } from '../PostFilterDropdownOption'

interface PostFilterButtonProps {
  postFilters: string[]
  isMobile: boolean
}

export const PostFilterButton = observer(
  ({ postFilters, isMobile }: PostFilterButtonProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const { postStore } = useStores()

    const {
      control,
      handleSubmit,
      setValue,
      watch,
      formState: { isValid },
    } = useForm<InferType<typeof postFilterSchema>>({
      mode: 'all',
      defaultValues: {
        all: postFilters.length === 0 || postFilters.includes('all') || false,
        board: postFilters.includes('board') || false,
        neighbour: postFilters.includes('neighbour') || false,
        important: postFilters.includes('important') || false,
      },
    })

    const watchAll = watch('all')
    const watchBoard = watch('board')
    const watchNeighbour = watch('neighbour')
    const watchImportant = watch('important')

    const selectedFilters = useMemo(() => {
      return [watchAll, watchBoard, watchNeighbour, watchImportant]
    }, [watchAll, watchBoard, watchImportant, watchNeighbour])

    const onCustomChange = (name: string, checked: boolean): void => {
      // If all is selected deselect all suboptions
      if (name === 'all' && checked) {
        setValue('board', false)
        setValue('neighbour', false)
        setValue('important', false)
      }
      // If a suboption is selected disable all
      if (name !== 'all' && checked) {
        setValue('all', false)
      }
    }

    // When no option is selected enable all
    useEffect(() => {
      !selectedFilters.includes(true) && setValue('all', true)
    }, [selectedFilters, setValue])

    const onSubmit = (data: InferType<typeof postFilterSchema>): void => {
      const filters = Object.entries(data)
        .filter(([key, value]) => value === true && key !== 'all')
        .map((filter) => filter[0])

      postStore.setPostFilters(filters)
      postStore.getFeed()
    }

    const onError = (): void => {
      if (!isValid) return
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const dropdownOptions = [
      {
        value: 'all',
        label: translate('feedView.filterFeed.postsFilterAllOptionValue'),
        customContent: (
          <Controller
            control={control}
            name="all"
            render={({ field: { name, value, onChange } }) => (
              <PostFilterDropdownOption
                name={name}
                onChange={onChange}
                onCustomChange={onCustomChange}
                label={translate(
                  'feedView.filterFeed.postsFilterAllOptionValue'
                )}
                value={value}
              />
            )}
          />
        ),
      },
      {
        value: 'board',
        label: translate('feedView.filterFeed.postsFilterBoardOptionValue'),
        customContent: (
          <Controller
            control={control}
            name="board"
            render={({ field: { name, value, onChange } }) => (
              <PostFilterDropdownOption
                name={name}
                onChange={onChange}
                onCustomChange={onCustomChange}
                label={translate(
                  'feedView.filterFeed.postsFilterBoardOptionValue'
                )}
                value={value}
              />
            )}
          />
        ),
      },
      {
        value: 'neighbour',
        label: translate(
          'feedView.filterFeed.postsFilterNeighboursOptionValue'
        ),
        customContent: (
          <Controller
            control={control}
            name="neighbour"
            render={({ field: { name, value, onChange } }) => (
              <PostFilterDropdownOption
                name={name}
                onChange={onChange}
                onCustomChange={onCustomChange}
                label={translate(
                  'feedView.filterFeed.postsFilterNeighboursOptionValue'
                )}
                value={value}
              />
            )}
          />
        ),
      },
      {
        value: 'important',
        label: translate('feedView.filterFeed.postsFilterImportantOptionValue'),
        customContent: (
          <Controller
            control={control}
            name="important"
            render={({ field: { name, value, onChange } }) => (
              <PostFilterDropdownOption
                name={name}
                onChange={onChange}
                onCustomChange={onCustomChange}
                label={translate(
                  'feedView.filterFeed.postsFilterImportantOptionValue'
                )}
                value={value}
              />
            )}
          />
        ),
      },
    ]

    const submitButton = (
      <div className="flex h-full w-full items-center justify-center px-6 pb-6 pt-3">
        <Button
          className="px-16"
          label={translate('common.actions.confirm')}
          variant={ButtonVariant.PRIMARY}
          onClick={handleSubmit(onSubmit, onError)}
        />
      </div>
    )

    return (
      <div className="flex flex-col gap-4">
        <Dropdown
          className="w-fit"
          dropdownPosition="bottom-left"
          dropdownContentWidth="w-64"
          options={dropdownOptions}
          submitButton={submitButton}
        >
          <Button
            label={
              isMobile ? undefined : translate('feedView.filterFeed.title')
            }
            disableTabIndex
            iconPlacement="left"
            icon={IconChoices.FILTER}
            iconColor={
              postFilters.length > 0 && !postFilters.includes('all')
                ? theme.colors.brandGreen
                : 'black'
            }
          />
        </Dropdown>
      </div>
    )
  }
)
