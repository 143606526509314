import React, { ReactNode, useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { SnapshotOut } from 'mobx-state-tree'
import { useAtom } from 'jotai'
import { UserAvatar } from '../../components/common/Avatar'
import { useStores } from '../../hooks/useStores'
import { MediaModel } from '../../state/models/media'
import { Button, ButtonVariant } from '../../components/common/Button'
import { useAppTranslation } from '../../hooks/useAppTranslation'
import { Icon, IconChoices } from '../../components/common/Icon'
import {
  isUserAdminInSociety,
  isUserBoardMemberInSociety,
  translateRoles,
  userBoardMemberRoles,
} from '../../helpers/society'
import { theme } from '../../theme/theme'
import { SocietyModel } from '../../state/models/society'
import { useUnitsString } from '../../hooks/useUnitsString'
import { sanitizeMobileNumber } from '../../helpers/phone'
import { UserInterests } from './UserInfo/UserInterests'
import { UserEducation } from './UserInfo/UserEducation'
import { UserOccupation } from './UserInfo/UserOccupation'
import { UserPresentation } from './UserInfo/UserPresentation'
import { ErrorBoundary } from '../../components/common/ErrorBoundary'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../navigation/reverseDocumentTitle'
import { reverseUrl } from '../../navigation/reverseUrl'
import { Badge } from '../../components/common/Badge'
import { getDateDate } from '../../helpers/date'
import { useAuthenticatedUserId } from '../../hooks/useAuthenticatedUserId'
import {
  ChatHeaderState,
  chatHeaderStateAtom,
} from '../../components/chat/atom'

interface UserViewSectionProps {
  children: ReactNode
}

const UserViewSection = ({ children }: UserViewSectionProps): JSX.Element => {
  return (
    <div className="mt-4 rounded bg-white p-4 shadow md:px-8 md:py-6">
      {children}
    </div>
  )
}

export const UserView = observer((): JSX.Element | null => {
  const { userStore, societyStore, mediaStore, unitStore, chatRoomStore } =
    useStores()
  const { translate } = useAppTranslation()
  const navigate = useNavigate()
  const [, setChatHeaderState] = useAtom(chatHeaderStateAtom)
  const { id, societyId } = useParams()
  const isMobile = useIsMobile()
  const currentUserId = userStore.currentUser()?._id
  const user = id ? userStore.users.get(id) : undefined
  const authUserId = useAuthenticatedUserId() as string
  const society = societyId
    ? (societyStore.societies.get(societyId) as SnapshotOut<
        typeof SocietyModel
      >)
    : undefined
  const currentUserIsAdmin = isUserAdminInSociety(society, authUserId)
  const currentUserIsBoard = isUserBoardMemberInSociety(society, authUserId)

  const isAdminOrBoard = currentUserIsAdmin || currentUserIsBoard

  useDocumentTitle(
    society
      ? reverseDocumentTitle('user:society-detail', {
          '{{ societyName }}': society.name,
          '{{ userName }}': user?.fullName,
        })
      : reverseDocumentTitle('user:detail', {
          '{{ userName }}': user?.fullName,
        })
  )

  useEffect(() => {
    if (id) {
      userStore.getUser(id)
    }
    if (societyId) {
      societyStore.getSociety(societyId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const userUnits = user && unitStore.userUnits(user._id, society?._id)
  const unitsString = useUnitsString(userUnits || [], false)

  const getRole = useCallback((): string | undefined | null => {
    let roles: string[] = []
    if (society) {
      roles = id ? userBoardMemberRoles(society, id) || [] : []
    }
    const boardMemberRoles =
      roles.length > 0 ? translateRoles(roles) : undefined
    return boardMemberRoles && boardMemberRoles.length > 0
      ? boardMemberRoles[0]
      : ''
  }, [society, id])

  const societyCoverPhoto =
    (society?.coverPhotoId &&
      (mediaStore.media.get(society.coverPhotoId) as SnapshotOut<
        typeof MediaModel
      >)) ||
    undefined

  const goBack = (): void => {
    navigate(-1)
  }

  const goToChatRoom = async (): Promise<void> => {
    if (id) {
      const chatRoom = await chatRoomStore.getUserRoom(id)

      if (chatRoom) {
        setChatHeaderState(ChatHeaderState.None)
        navigate(reverseUrl('chat:detail', { id: chatRoom._id }))
      } else {
        chatRoomStore.setSelectedChatRoom(undefined)
        setChatHeaderState(ChatHeaderState.StartNewChat)
        navigate(reverseUrl('chat', undefined, { recipientId: id }))
      }
    }
  }

  const userRole = getRole()

  const loadingChat = chatRoomStore.fetchingChatRoom === 'pending'

  return (
    <ErrorBoundary>
      <div className="w-full overflow-y-scroll bg-neutral-96">
        <div className="mt-4 grid w-full grid-cols-12 md:mt-10">
          <div className="col-span-0 ml-10 md:col-span-2">
            {!isMobile && (
              <Button
                className="hover:bg-neutral-92"
                icon={IconChoices.ARROW_LEFT}
                size="sm"
                variant={ButtonVariant.TEXT}
                label={translate('common.actions.back')}
                onClick={goBack}
              />
            )}
          </div>
          <div className="col-span-12 mb-4 flex w-full flex-col px-2 md:col-span-8 md:px-0">
            {societyCoverPhoto?.url && (
              <img
                className="h-28 w-full rounded-t border-4 border-white object-cover md:h-56"
                src={societyCoverPhoto?.url}
                alt="society banner"
              />
            )}
            <div className="flex flex-row rounded-b bg-white p-4 shadow md:p-8">
              <div className="flex w-full flex-row justify-center md:justify-start">
                <div
                  className={`${
                    societyCoverPhoto?.url ? 'mt-[-35px]' : ''
                  } flex w-full flex-col items-start ${
                    societyCoverPhoto?.url ? 'md:mt-[-70px]' : ''
                  }`}
                >
                  <div
                    className="flex w-full flex-col items-center 
                    place-self-center md:items-start md:place-self-auto"
                  >
                    <div className="rounded-full border-4 border-white">
                      <UserAvatar size={isMobile ? 80 : 124} user={user} />
                    </div>
                    <div className="flex flex-col items-center gap-4 md:items-start">
                      <div className="flex flex-col items-center gap-1 md:items-start">
                        <p style={theme.textVariants.h3} translate="no">
                          {user?.fullName}
                        </p>
                        {userRole && (
                          <Badge
                            text={userRole}
                            colorClass="bg-badge-green"
                            textColorClass="text-green"
                          />
                        )}
                        {user && (
                          <p
                            className="text-neutral-40"
                            style={theme.textVariants.caption}
                          >
                            {translate('userView.dateTitle')}{' '}
                            {getDateDate(Date.parse(user.createdAt))}
                          </p>
                        )}
                      </div>
                      {user && currentUserId !== id && (
                        <Button
                          className="mt-2"
                          icon={IconChoices.CHAT}
                          label={translate('common.actions.message')}
                          onClick={goToChatRoom}
                          loading={loadingChat}
                          size="sm"
                          variant={ButtonVariant.PRIMARY}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full space-y-8">
                    <div className="mt-6 flex w-full flex-col space-y-4">
                      <div className="flex w-full [overflow-wrap:anywhere]">
                        <Icon icon={IconChoices.HOME} className="mr-2" />
                        <p style={theme.textVariants.base} translate="no">
                          {society?.name}
                        </p>
                      </div>
                      {unitsString && (
                        <div className="flex w-full [overflow-wrap:anywhere]">
                          <Icon
                            icon={IconChoices.HOUSE_HOUSE}
                            className="mr-2"
                          />
                          <p style={theme.textVariants.base}>{unitsString}</p>
                        </div>
                      )}
                      {isAdminOrBoard && (
                        <>
                          {user?.email && (
                            <div className="flex w-full [overflow-wrap:anywhere]">
                              <Icon icon={IconChoices.EMAIL} className="mr-2" />
                              <p style={theme.textVariants.base} translate="no">
                                {user.email}
                              </p>
                            </div>
                          )}
                          {user?.mobile && (
                            <div className="flex">
                              <Icon icon={IconChoices.PHONE} className="mr-2" />
                              <p
                                style={theme.textVariants.base}
                                className="truncate"
                                translate="no"
                              >
                                +46{sanitizeMobileNumber(user.mobile)}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {user?.aboutInfo?.presentation && (
              <UserViewSection>
                <UserPresentation
                  name={user?.name}
                  presentation={user?.aboutInfo?.presentation}
                />
              </UserViewSection>
            )}
            {user?.aboutInfo?.interests &&
              user?.aboutInfo?.interests?.length > 0 && (
                <UserViewSection>
                  <UserInterests interests={user?.aboutInfo?.interests} />
                </UserViewSection>
              )}
            {user?.aboutInfo?.education &&
              Object.values(user?.aboutInfo?.education).some(
                (val) => val !== ''
              ) && (
                <UserViewSection>
                  <UserEducation education={user?.aboutInfo?.education} />
                </UserViewSection>
              )}
            {user?.aboutInfo?.occupation &&
              Object.values(user?.aboutInfo?.occupation).some(
                (val) => val !== ''
              ) && (
                <UserViewSection>
                  <UserOccupation occupation={user?.aboutInfo?.occupation} />
                </UserViewSection>
              )}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
})
