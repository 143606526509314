import { captureException } from '@sentry/react'
import { SnapshotOut } from 'mobx-state-tree'
import { UserModel } from '../state/models/user'
import { sortStringsAlphabetically } from './sorting'

/**
 * Returns user initials capitalized
 */
export const getUserInitials = (
  user?: SnapshotOut<typeof UserModel>
): string => {
  if (user == null || user === undefined) {
    return ''
  }

  const name = user.name ? user.name : ''
  const surname = user.surname ? user.surname : ''
  let initials = ''

  if (name.length > 0) {
    initials = initials.concat(name[0].toUpperCase())
  }

  // If user has many last names, use first letter of last name
  if (surname.length > 0) {
    const lastNames = surname.split(' ')
    const lastName = lastNames[0]
    const letter = lastName != null && lastName.length > 0 ? lastName[0] : ''

    initials = initials.concat(letter.toUpperCase())
  }

  initials = initials.trim()

  // if we dont have a name we want to use the email for it
  if (initials == null || initials.length === 0) {
    if (user.email != null && user.email.length > 0) {
      const baseDomainSplit = user.email.split('@')
      const domainSplit = baseDomainSplit[1].split(/\.|_|-/g)
      const userSplit = baseDomainSplit[0].split(/\.|_|-/g)

      initials = userSplit
        .concat(domainSplit)
        .slice(0, 2)
        .map((s) => s[0])
        .join('')
        .toUpperCase()
    }
  }

  return initials
}

export interface SectionListUserGroup {
  title: string
  data: SnapshotOut<typeof UserModel>[]
}

// Returns a list of sections, where each section contains
// a group of users with a name starting with the same letter
export const getListSectionSortedAndGroupedUsers = (
  users: (SnapshotOut<typeof UserModel> | undefined)[]
): SectionListUserGroup[] => {
  const nonUndefinedUsers = users.filter(
    (_user) => _user !== undefined
  ) as SnapshotOut<typeof UserModel>[]

  const groupedUsersMap = nonUndefinedUsers.reduce(
    (
      prev: {
        [key: string]: { title: string; data: SnapshotOut<typeof UserModel>[] }
      },
      user
    ) => {
      const localPrev = prev
      try {
        const characterGroup = [user.surname, user.name]
          .join(' ')[0]
          .toUpperCase()
        if (!(characterGroup in localPrev)) {
          localPrev[characterGroup] = { title: characterGroup, data: [user] }
        } else {
          localPrev[characterGroup].data.push(user)
        }
      } catch (error) {
        captureException(error, { extra: { users } })
      }
      return localPrev
    },
    {}
  )

  const groupedUsers = Object.values(groupedUsersMap)

  const groupedUsersWithSortedGroups = groupedUsers.sort((a, b) =>
    sortStringsAlphabetically(a.title, b.title)
  )

  return groupedUsersWithSortedGroups
}
