import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import BoappaLogo from '../../../assets/icons/boappa.svg'
import { theme } from '../../../theme/theme'
import { ResetPasswordForm } from './ResetPasswordForm'
import { useDocumentTitle } from '../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../navigation/reverseDocumentTitle'
import { Button } from '../../../components/common/Button'
import { useAuth } from '../../../hooks/useAuth'
import { reverseUrl } from '../../../navigation/reverseUrl'

export const ResetPasswordView = observer((): JSX.Element => {
  const { userId } = useAuth()
  const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState(false)
  const { translate } = useAppTranslation()
  const navigate = useNavigate()
  useDocumentTitle(reverseDocumentTitle('reset-password'))

  if (userId) {
    return <Navigate to={reverseUrl('login')} />
  }

  return (
    <div className="flex h-full flex-col md:flex-row">
      <BoappaLogo
        className="absolute inset-x-8 w-16 md:w-40"
        alt="boappa logo"
      />
      <div className="mt-24 flex flex-1 justify-center overflow-y-auto md:mt-0 md:items-center">
        <div className="flex w-[90%] flex-col gap-4 md:w-[50%] xl:w-[30%]">
          {resetPasswordEmailSent ? (
            <>
              <p style={theme.textVariants.h1}>
                {translate(
                  'authenticationResetPasswordView.confirmationScreen.title'
                )}
              </p>
              <p style={theme.textVariants.paragraph}>
                {translate(
                  'authenticationResetPasswordView.confirmationScreen.subtitle'
                )}
              </p>
              <Button
                style={{
                  ...theme.textVariants.paragraph,
                }}
                className="flex h-14 w-full items-center justify-center"
                label={translate('common.actions.back')}
                onClick={() => navigate(-1)}
              />
            </>
          ) : (
            <>
              <p style={theme.textVariants.h1}>
                {translate('authenticationResetPasswordView.title')}
              </p>
              <ResetPasswordForm
                setResetPasswordEmailSent={() =>
                  setResetPasswordEmailSent(true)
                }
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
})
