import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'
import { SocietyModel } from './society'

export const FAQSectionModel = types.model('FAQSection', {
  _id: types.identifier,
  ...timestampTypes,
  society: types.reference(SocietyModel),
  section: types.string,
})

export const FAQQuestionModel = types.model('FAQQuestion', {
  _id: types.identifier,
  ...timestampTypes,
  section: types.reference(FAQSectionModel),
  question: types.maybe(types.string),
  answer: types.maybe(types.string),
})
