import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { UserModel } from '../../../state/models/user'
import { AdminRightsListItem } from './AdminRightsListItem'

interface AdminRightsListProps {
  admins: {
    user: SnapshotOut<typeof UserModel>
    role: string
  }[]
}

export const AdminRightsList = observer(
  ({ admins }: AdminRightsListProps): JSX.Element => {
    return (
      <div className="h-full w-full space-y-4">
        {admins.map((admin) => (
          <AdminRightsListItem key={admin.user._id} admin={admin} />
        ))}
      </div>
    )
  }
)
