/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, SnapshotOut, getRoot } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import { DocumentModel, DocumentSectionType } from '../models/document'
import { NDocuments } from '../../interfaces/services/documents.interfaces'
import { IDocumentModel } from '../../interfaces/models/documents.interfaces'
import {
  getPerSocieties as apiGetPerSocieties,
  createDocument as apiCreateDocument,
  deleteDocument as apiDeleteDocument,
  patchDocument as apiPatchDocument,
} from '../../api/documents'
import { stateType } from '../types/common'
import { setObject } from './helpers'
import { sortStringsAlphabetically } from '../../helpers/sorting'
import { RootStore } from './root'

export const DocumentStore = types
  .model('DocumentStore')
  .props({
    documents: types.map(DocumentModel),
    fetchingDocuments: stateType,
    creatingDocument: stateType,
    updatingDocument: stateType,
    creatingFolder: stateType,
    deletingDocument: stateType,
    hasFetchedDocumentsOnce: types.boolean,
  })
  .views((self) => ({
    get sortedDocuments(): SnapshotOut<typeof DocumentModel>[] {
      return (
        // @ts-ignore
        (values(self.documents) as SnapshotOut<typeof DocumentModel>[]).sort(
          (a, b) => sortStringsAlphabetically(a.name, b.name)
        )
      )
    },
  }))
  .views((self) => ({
    getSocietyDocuments(
      societyId: string,
      parentId: string | undefined = undefined,
      fileName: string | undefined = undefined,
      section: typeof DocumentSectionType | undefined = undefined
    ): SnapshotOut<typeof DocumentModel>[] {
      let societyDocuments = self.sortedDocuments.filter(
        (document) => document.societyId && document.societyId === societyId
      )

      if (parentId) {
        societyDocuments = societyDocuments.filter(
          (document) => document.parentId === parentId
        )
      }

      if (fileName) {
        societyDocuments = societyDocuments.filter(
          (document) => document.filename === fileName
        )
      }

      if (section) {
        societyDocuments = societyDocuments.filter(
          // @ts-ignore
          (document) => document.section === section
        )
      }

      return societyDocuments
    },
    getChildDocuments(id: string): SnapshotOut<typeof DocumentModel>[] {
      return self.sortedDocuments.filter((document) => document.parentId === id)
    },
  }))
  .views((self) => ({
    getRootDocument(
      societyId: string,
      section: typeof DocumentSectionType
    ): SnapshotOut<typeof DocumentModel> | undefined {
      const rootDocuments = self.getSocietyDocuments(
        societyId,
        undefined,
        '/',
        section
      )
      if (rootDocuments.length > 0) {
        return rootDocuments[0]
      }
      return undefined
    },
  }))
  .views((self) => ({
    getRootDocuments(
      societyId: string,
      section: typeof DocumentSectionType
    ): SnapshotOut<typeof DocumentModel>[] {
      const rootIds = self
        .getSocietyDocuments(societyId, undefined, '/', section)
        .map((document) => document._id)
      const documents: SnapshotOut<typeof DocumentModel>[] = rootIds.reduce(
        (acc: SnapshotOut<typeof DocumentModel>[], rootId) => {
          return [
            ...acc,
            ...self.getSocietyDocuments(societyId, rootId, undefined, section),
          ]
        },
        []
      )
      return documents
    },
    getAllSubDocuments(id: string): SnapshotOut<typeof DocumentModel>[] {
      let documents: SnapshotOut<typeof DocumentModel>[] = []
      let idsToCheck = [id]

      while (idsToCheck.length > 0) {
        const _id = idsToCheck.pop()
        if (_id !== undefined) {
          const children = self.getChildDocuments(_id)
          documents = [...documents, ...children]
          idsToCheck = [
            ...idsToCheck,
            ...children.map((document) => document._id),
          ]
        }
      }

      return documents
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.documents.clear()
      self.fetchingDocuments = 'none'
      self.creatingDocument = 'none'
      self.creatingFolder = 'none'
      self.deletingDocument = 'none'
      self.updatingDocument = 'none'
      self.hasFetchedDocumentsOnce = false
    },
    setDocuments: (documents: IDocumentModel[]) => {
      documents.forEach((document) => {
        // @ts-ignore
        setObject<typeof DocumentModel>(self.documents, DocumentModel, {
          ...document,
          societyId: document.societyId,
        })
      })
    },
    setHasFetchDocumentsOnce: (val: boolean) => {
      self.hasFetchedDocumentsOnce = val
    },
  }))
  .actions((self) => ({
    getDocumentsForSociety: flow(function* getDocumentsForSociety(
      societyId: string
    ) {
      self.fetchingDocuments = 'pending'
      try {
        const resp = yield apiGetPerSocieties([societyId])
        const data = resp.data as NDocuments.NGetPerSocieties.IResponse

        self.setDocuments(data.data)

        self.setHasFetchDocumentsOnce(true)
        self.fetchingDocuments = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingDocuments = 'error'
      }
    }),
    createDocument: flow(function* createDocument(
      mimeType: string,
      base64: string,
      name: string,
      filename: string,
      parentId?: string,
      societyId?: string,
      section?: typeof DocumentSectionType,
      notificationSettings?: string
    ) {
      self.creatingDocument = 'pending'
      try {
        // @ts-ignore
        const body: NDocuments.NCreate.IRequestBody = {
          ...{
            data: base64,
            name,
            filename,
            type: filename.split('.').pop(),
          },
          ...(parentId !== undefined && { parentId }),
          ...(societyId !== undefined && { societyId }),
          ...(section !== undefined && { section }),
          ...(notificationSettings !== undefined && { notificationSettings }),
        }
        const resp = yield apiCreateDocument(body)
        const data = resp.data as NDocuments.NCreate.IResponse
        const document = data.data

        if (document) {
          self.setDocuments([document])
        }

        self.creatingDocument = 'done'
        return document?._id
      } catch (error) {
        captureException(error)
        self.creatingDocument = 'error'
        return undefined
      }
    }),
    createFolder: flow(function* createFolder(
      name: string,
      parentId?: string,
      societyId?: string,
      section?: typeof DocumentSectionType,
      notificationSettings?: string
    ) {
      self.creatingFolder = 'pending'
      try {
        // @ts-ignore
        const body: NDocuments.NCreate.IRequestBody = {
          ...{
            name,
            filename: name,
            type: 'folder',
          },
          ...(parentId !== undefined && { parentId }),
          ...(societyId !== undefined && { societyId }),
          ...(section !== undefined && { section }),
          ...(notificationSettings !== undefined && { notificationSettings }),
        }
        const resp = yield apiCreateDocument(body)
        const data = resp.data as NDocuments.NCreate.IResponse
        const document = data.data

        if (document) {
          self.setDocuments([document])
        }

        self.creatingFolder = 'done'
        return document?._id
      } catch (error) {
        captureException(error)
        self.creatingFolder = 'error'
        return undefined
      }
    }),
    patchDocument: flow(function* patchDocument(
      id: string,
      body: NDocuments.NPatch.IRequestBody
    ) {
      self.updatingDocument = 'pending'
      try {
        const resp = yield apiPatchDocument(id, body)
        const data = resp.data as NDocuments.NPatch.IResponse
        const document = data.data

        if (document !== null) {
          self.setDocuments([document])
        }

        self.updatingDocument = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingDocument = 'error'
        return false
      }
    }),
    deleteDocument: flow(function* deleteDocument(id: string) {
      self.deletingDocument = 'pending'
      try {
        yield apiDeleteDocument(id)

        const { postStore } = getRoot<RootStore>(self)
        const postsReferringDocument = postStore.sortedPosts
          .filter((_post) => _post.documentsIds.includes(id))
          .map((_post) => _post._id)
        postsReferringDocument.forEach((_id) => postStore.deletePost(_id))

        self.documents.delete(id)
        self.deletingDocument = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.deletingDocument = 'error'
        return false
      }
    }),
  }))
