import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { SnapshotOut } from 'mobx-state-tree'
import { yupResolver } from '@hookform/resolvers/yup'
import { societyCreatePostSchema } from '../../../../forms/schemas/society_create_post'
import { useStores } from '../../../../hooks/useStores'
import { FormControl } from '../../../common/FormControl'
import { TextArea } from '../../../common/TextArea'
import { AttachmentsContainer } from '../../AttachmentsContainer'
import { RoleDropdown } from '../../RoleDropdown'
import { SocietyDropdown } from '../../SocietyDropdown'
import { VisibilityDropdown } from '../../VisibilityDropdown'
import { useUserFeedSocieties } from '../../../../views/feed/useUserFeedSocieties'
import { PostModel } from '../../../../state/models/post'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { Button, ButtonVariant } from '../../../common/Button'
import { SocietyModel } from '../../../../state/models/society'
import {
  isUserAdminInSociety,
  isUserBoardMemberInSociety,
} from '../../../../helpers/society'
import { useMessageTextAreaRows } from './useMessageTextAreaRows'
import { useModal } from '../../../../hooks/useModal'
import { PreviewPostModal } from './PreviewPostModal'
import { useSocietyLockedForCurrentUser } from '../../../../hooks/useSocietyLockedForCurrentUser'
import { Callout } from '../../../society/Callout'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { useUserBlockedInSociety } from '../../../../hooks/useUserBlockedInSociety'
import { BlockedCallout } from '../../../blocked/BlockedCallout'

interface CreateEditPostModalContentProps {
  post?: SnapshotOut<typeof PostModel>
  onError: (error: unknown) => void
  onSubmit: (data: InferType<typeof societyCreatePostSchema>) => Promise<void>
  onClose: () => void
  loading: boolean
}

export const CreateEditPostModalContent = observer(
  ({
    post,
    onError,
    onSubmit,
    onClose,
    loading,
  }: CreateEditPostModalContentProps): JSX.Element => {
    const { authenticationStore, societyStore, groupStore } = useStores()
    const { translate } = useAppTranslation()
    const { getErrorMessage } = useFormErrorMessage()
    const userId = authenticationStore.userId as string
    const { societies } = useUserFeedSocieties({ userId })
    const messageTextAreaRows = useMessageTextAreaRows()
    const [loadingAttachment, setLoadingAttachment] = useState(false)

    const {
      show: showPreviewPostModal,
      open: openPreviewPostModal,
      close: closePreviewPostModal,
    } = useModal()

    const getDefaultValues = {
      message: post?.message || '',
      author: post?.author || userId,
      role: post?.role || 'resident',
      society: post?.society || '',
      isAlert: post?.isAlert || false,
      pollIds: post?.pollIds || [],
      eventsIds: post?.eventsIds || [],
      groupsIds: post?.groupsIds || [],
      media: post?.media || [],
      documentsIds: post?.documentsIds || [],
      accessBoardMember: post?.accessBoardMember || false,
      accessAdminMember: post?.accessAdminMember || false,
      accessUsersIds: post?.accessUsersIds || [],
    }

    const {
      control,
      watch,
      handleSubmit,
      setValue,
      getValues,
      formState: { errors, isValid },
    } = useForm<InferType<typeof societyCreatePostSchema>>({
      mode: 'all',
      resolver: yupResolver(societyCreatePostSchema),
      defaultValues: getDefaultValues,
    })

    const watchRole = watch('role')
    const watchPollIds = watch('pollIds')
    const watchEventsIds = watch('eventsIds')
    const watchGroupsIds = watch('groupsIds')
    const watchSociety = watch('society')
    const watchAccessBoardMember = watch('accessBoardMember')
    const watchAccessAdminMember = watch('accessAdminMember')
    const watchIsAlert = watch('isAlert')

    // effect to handle init values and custom changes to role
    useEffect(() => {
      const postSociety = societyStore.societies.get(watchSociety) as
        | SnapshotOut<typeof SocietyModel>
        | undefined
      const isBoardMemberOfSelectedSociety = isUserBoardMemberInSociety(
        postSociety,
        userId
      )
      const isAdminOfSelectedSociety = isUserAdminInSociety(postSociety, userId)
      if (!post?.society && watchSociety === '') {
        setValue('society', societies[0]._id)
      }
      if (post?.role) {
        return
      }
      if (
        postSociety?.residentPostingDisabledByAdmin &&
        isAdminOfSelectedSociety
      ) {
        setValue('role', 'admin')
      } else if (
        postSociety?.residentPostingDisabledByAdmin &&
        isBoardMemberOfSelectedSociety
      ) {
        setValue('role', 'board-member')
      } else {
        setValue('role', 'resident')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [societyStore.societies, userId, watchSociety])

    useEffect(() => {
      if (groupStore.fetchingGroups !== 'pending' && watchSociety !== '') {
        groupStore.getGroupsPerSociety(watchSociety)
      }
    }, [groupStore, watchSociety])

    const watchSocietyModel = societyStore.societies.get(watchSociety) as
      | SnapshotOut<typeof SocietyModel>
      | undefined
    const selectedSocietyLockedForUser =
      useSocietyLockedForCurrentUser(watchSocietyModel)
    const userBlockedInSelectedSociety = useUserBlockedInSociety(watchSociety)

    return (
      <>
        <form
          className="flex h-full flex-col"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <div className="flex h-full flex-col gap-6 overflow-y-auto px-6 pb-6 md:pb-8">
            <div className="flex flex-col flex-wrap gap-4 md:flex-row">
              {!post?.society && (
                <SocietyDropdown
                  societies={societies}
                  setValue={setValue}
                  control={control}
                />
              )}
              <RoleDropdown
                setValue={setValue}
                control={control}
                watchRole={watchRole}
                watchSociety={watchSociety}
                userId={userId}
              />
              <VisibilityDropdown
                setValue={setValue}
                control={control}
                watchRole={watchRole}
                watchSociety={watchSociety}
                watchGroupsIds={watchGroupsIds}
                watchAccessAdminMember={watchAccessAdminMember}
                watchAccessBoardMember={watchAccessBoardMember}
              />
            </div>
            {selectedSocietyLockedForUser && (
              <div>
                <Callout
                  text={translate(
                    'societySideBar.callouts.societyLocked.title'
                  )}
                  path={reverseUrl('management:billing-payment', {
                    id: watchSociety,
                  })}
                  warning
                />
              </div>
            )}
            {userBlockedInSelectedSociety && (
              <BlockedCallout selectedSocietyId={watchSociety} />
            )}
            <div className="flex flex-col gap-3">
              <Controller
                control={control}
                name="message"
                render={({ field: { value, name, onChange, onBlur } }) => (
                  <FormControl
                    label={translate('common.form.labels.message')}
                    name={name}
                    error={errors.message && getErrorMessage(errors.message)}
                  >
                    <TextArea
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.message}
                      fixedHeight
                      rows={messageTextAreaRows}
                    />
                  </FormControl>
                )}
              />
              <div className="flex flex-row gap-2">
                <AttachmentsContainer
                  control={control}
                  setValue={setValue}
                  setLoadingAttachment={setLoadingAttachment}
                  watchSociety={watchSociety}
                  watchEventsIds={watchEventsIds}
                  watchRole={watchRole}
                  watchPollIds={watchPollIds}
                  watchIsAlert={watchIsAlert}
                  post={post}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-end gap-4 px-6 py-6 md:px-12 md:py-8">
            <Button
              label={translate('common.actions.cancel')}
              onClick={onClose}
            />
            <Button
              label={translate('previewPost.preview')}
              onClick={openPreviewPostModal}
              variant={ButtonVariant.SECONDARY}
            />
            <Button
              variant={ButtonVariant.PRIMARY}
              loading={loading}
              disabled={
                userBlockedInSelectedSociety ||
                selectedSocietyLockedForUser ||
                !isValid ||
                loading ||
                loadingAttachment
              }
              label={
                post
                  ? translate('common.actions.save')
                  : translate('feedView.create')
              }
              type="submit"
            />
          </div>
        </form>
        <PreviewPostModal
          show={showPreviewPostModal}
          close={closePreviewPostModal}
          createPostData={getValues()}
        />
      </>
    )
  }
)
