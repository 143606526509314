import React from 'react'

interface ContainerProps {
  children: React.ReactNode
  overflowScrollY?: boolean
  className?: string
}

const Container = ({
  children,
  overflowScrollY = true,
  className,
}: ContainerProps): JSX.Element => {
  return (
    <div
      className={`h-full w-full p-4 md:p-6
        ${className ?? ''}
        ${overflowScrollY ? 'overflow-y-auto' : ''}
      `}
    >
      {children}
    </div>
  )
}

Container.displayName = 'Container'
export { Container }
