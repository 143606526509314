import React from 'react'
import { observer } from 'mobx-react-lite'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { societyCreatePostSchema } from '../../../forms/schemas/society_create_post'
import { SocietyModel } from '../../../state/models/society'
import {
  isUserAdminInSociety,
  isUserBoardMemberInSociety,
} from '../../../helpers/society'
import { useStores } from '../../../hooks/useStores'
import { DropdownSelect } from '../../common/DropdownSelect'
import { PostAvatarWithMediaId } from '../Post/PostAvatar'
import { PostAuthorWithId } from '../Post/PostAuthor'
import { UserModel } from '../../../state/models/user'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { DropdownItemContent } from '../DropdownItemContent'
import { FormControl } from '../../common/FormControl'
import { useIsMobile } from '../../../hooks/useIsMobile'

interface RoleDropdownProps {
  watchRole: string
  watchSociety: string
  setValue: UseFormSetValue<InferType<typeof societyCreatePostSchema>>
  control: Control<InferType<typeof societyCreatePostSchema>>
  userId: string
}

type roleOption = {
  title: string
  image: JSX.Element
  role: string
  customText: JSX.Element
}

export const RoleDropdown = observer(
  ({
    watchRole,
    watchSociety,
    userId,
    setValue,
    control,
  }: RoleDropdownProps): JSX.Element => {
    const { societyStore, userStore } = useStores()
    const isMobile = useIsMobile()
    const { translate } = useAppTranslation()
    const user = userStore.users.get(userId) as
      | SnapshotOut<typeof UserModel>
      | undefined
    const postSociety = societyStore.societies.get(watchSociety) as
      | SnapshotOut<typeof SocietyModel>
      | undefined
    const isBoardMemberOfSelectedSociety = isUserBoardMemberInSociety(
      postSociety,
      userId
    )

    const isAdminOfSelectedSociety = isUserAdminInSociety(postSociety, userId)

    const onCustomChange = (role: unknown): void => {
      if (typeof role === 'string') {
        setValue('role', role)
        if (watchRole !== 'resident' && role === 'resident') {
          setValue('groupsIds', [])
          setValue('isAlert', false)
        }
      }
    }

    const createRoleOption = (
      title: string | undefined,
      role: string
    ): roleOption | null => {
      if (!title || !user || !postSociety) return null
      return {
        title,
        image: (
          <PostAvatarWithMediaId
            role={role}
            authorId={user._id}
            societyId={postSociety._id}
            avatarSize="sm"
          />
        ),
        role,
        customText: <PostAuthorWithId role={role} authorId={user._id} />,
      }
    }

    const options = [
      !postSociety?.residentPostingDisabledByAdmin &&
        createRoleOption(user?.fullName, 'resident'),
      isAdminOfSelectedSociety &&
        createRoleOption(
          translate('postCreateUpdateBase.roleSheet.admin'),
          'admin'
        ),
      isAdminOfSelectedSociety &&
        createRoleOption(
          translate('postCreateUpdateBase.roleSheet.admin'),
          'admin-society'
        ),
      isBoardMemberOfSelectedSociety &&
        createRoleOption(
          translate('postCreateUpdateBase.roleSheet.board'),
          'board-member'
        ),
      isBoardMemberOfSelectedSociety &&
        createRoleOption(
          translate('postCreateUpdateBase.roleSheet.board'),
          'board-society'
        ),
    ]

    const optionsWithoutNullableValues = options.filter(
      (value): value is roleOption => !!value
    )

    const dropdownOptions = optionsWithoutNullableValues.map((option) => ({
      value: option.role,
      label: option.title,
      customText: option.customText,
      image: option.image,
    }))

    const shouldRender =
      isBoardMemberOfSelectedSociety || isAdminOfSelectedSociety

    return (
      <>
        {shouldRender && (
          <Controller
            control={control}
            name="role"
            render={({ field: { name, value } }) => (
              <FormControl
                name={name}
                label={translate('common.form.labels.from')}
              >
                <DropdownSelect
                  value={value}
                  options={dropdownOptions}
                  onChange={(val: unknown) => {
                    onCustomChange(val)
                  }}
                  dropdownWidth={isMobile ? 'w-full' : 'w-60'}
                  dropdownContentWidth="w-fit"
                  dropdownPosition="bottom-right"
                  renderItemContent={(option) => (
                    <DropdownItemContent option={option} />
                  )}
                />
              </FormControl>
            )}
          />
        )}
      </>
    )
  }
)
