import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../../../../hooks/useStores'

interface AdminListModalContentProps {
  adminList?: string[]
}

export const AdminListModalContent = observer(
  ({ adminList }: AdminListModalContentProps): JSX.Element => {
    const { userStore } = useStores()

    const admins = adminList?.map((adminId) => {
      const user = userStore.users.get(adminId)
      return user
    })

    return (
      <div className="flex h-full flex-col gap-6">
        {admins?.map((admin) => (
          <div key={admin?._id} className="flex gap-4">
            <p className="w-60">{admin?.fullName}</p>
            <p>{admin?._id}</p>
          </div>
        ))}
      </div>
    )
  }
)
