import React from 'react'
import { theme } from '../../../../../theme/theme'

export const PersonalDataAdvice = (): JSX.Element => {
  return (
    <>
      <p>
        Nedan följer några punkter som kan hjälpa er som förening i ert
        GDPR-arbete. Notera att nedan endast är tips och alltså inte någon
        juridisk rådgivning. Ansvaret att följa GDPR för den
        personuppgiftsbehandling som ni genomför vilar på er som förening.
      </p>
      <p style={theme.textVariants.captionBold} className="mt-4">
        1. Inventering
      </p>
      <p>
        Vilka personuppgifter behandlar föreningen? Gå igenom och diskutera
        behandlingarna på ett styrelsemöte. Vad har ni för ändamål (syfte) med
        respektive behandling? Vad har ni för uppladdade dokument? Vilka
        personuppgifter behöver ni verkligen och hur raderas inaktuella
        personuppgifter? Behöver ni föra ett s.k. behandlingsregister?
      </p>
      <p style={theme.textVariants.captionBold} className="mt-4">
        2. Rättslig grund
      </p>
      <p>
        All behandling av personuppgifter kräver en rättslig grund. Styrelse och
        bostadsrättsföreningar har bland annat skyldighet att föra
        medlemsförteckning och lägenhetsregister (bostadsrättslagen (1991:614) 9
        kap. 8 §, samt lag (2018:672) om ekonomiska föreningar 5 kap.). För
        andra behandlingar kan exempelvis, intresseavvägning, fullgörande av
        avtal och samtycke vara lämpliga rättsliga grunder.
      </p>
      <p style={theme.textVariants.captionBold} className="mt-4">
        3. Informera era medlemmar
      </p>
      <p>
        Era medlemmar har rätt att veta vilka av deras personuppgifter ni
        behandlar och även att få ytterligare information, innan behandlingarna
        påbörjas. Lägg förslagsvis upp en kopia av den informationen i Boappa så
        att era medlemmar enkelt kan hitta den.
      </p>
      <p style={theme.textVariants.captionBold} className="mt-4">
        4. Skydda personuppgifterna
      </p>
      <p>
        Se till att medlemmarnas personuppgifter är tekniskt skyddade,
        exempelvis genom säker lösenordskyddad lagring, och att de även är
        föremål för organisatoriska säkerhetsåtgärder, såsom att de inte är
        tillgängliga för fler personer än nödvändigt.
      </p>
      <p style={theme.textVariants.captionBold} className="mt-4">
        5. Avtala med alla leverantörer
      </p>
      <p>
        Ni behöver ingå personuppgiftsbiträdesavtal med föreningens samtliga
        personuppgiftsbiträden, så som e-postleverantörer, förvaltare och
        självklart Boappa.
      </p>
      <p style={theme.textVariants.captionBold} className="mt-4">
        6. Uppfyll era medlemmars rättigheter
      </p>
      <p>
        Era medlemmar har ett antal rättigheter enligt GDPR som ni är ansvariga
        för att uppfylla. Säkerställ att era medlemmar kan använda sig av dessa
        rättigheter.
      </p>
      <p style={theme.textVariants.captionBold} className="mt-4">
        7. Dokumentera
      </p>
      <p>
        Alla avvägningar ni gör bör dokumenteras för att kunna visas upp på
        begäran från Integritetsskyddsmyndigheten.
      </p>
    </>
  )
}
