import React, { HTMLAttributeAnchorTarget, ReactNode, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
// eslint-disable-next-line import/no-named-default
import { default as ReactLinkify } from 'react-linkify'

interface PostInnerProps {
  children?: ReactNode
  target?: HTMLAttributeAnchorTarget
  secure?: boolean
  follow?: boolean
  textClassName?: string
}

export const Linkify = observer(
  ({
    children,
    target = '_blank',
    secure = true,
    follow = false,
    textClassName = 'text-brandGreen',
  }: PostInnerProps): JSX.Element => {
    const rel = useMemo(
      () =>
        [
          ...(secure ? ['noopener', 'noreferrer'] : []),
          ...(follow ? [] : ['nofollow']),
        ].join(' '),
      [follow, secure]
    )

    return (
      <ReactLinkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a
            target={target}
            rel={rel}
            href={decoratedHref}
            key={key}
            className={`${textClassName} underline`}
          >
            {decoratedText}
          </a>
        )}
      >
        {children}
      </ReactLinkify>
    )
  }
)
