import React from 'react'
import { observer } from 'mobx-react-lite'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { ITimeSlot } from '../FacilityCalendar/interfaces'
import { TimeSlotTime } from '../TimeSlotTime'

interface TimeSlotProps extends ITimeSlot {
  onClick: () => void
  selected: boolean
  fullDay?: boolean
  userHasReachedMaxNumberOfBookings: boolean
}

export const TimeSlot = observer(
  ({
    startDate,
    endDate,
    bookingUserId,
    onClick,
    selected,
    fullDay,
    userHasReachedMaxNumberOfBookings,
  }: TimeSlotProps): JSX.Element | null => {
    const userId = useAuthenticatedUserId() as string

    const authenticatedUserOwnsBooking =
      bookingUserId && bookingUserId === userId

    const otherUserOwnsBooking = bookingUserId && !authenticatedUserOwnsBooking

    return (
      <button
        className={`border-1 rounded-md border border-neutral-80 px-5 py-4 shadow-sm 
      ${selected ? 'bg-neutral-30 text-white' : ''}
      ${
        userHasReachedMaxNumberOfBookings && !authenticatedUserOwnsBooking
          ? 'text-neutral-80'
          : ''
      }
      ${bookingUserId !== undefined || selected ? '' : 'hover:bg-gray-light'}
       ${authenticatedUserOwnsBooking ? 'bg-greenDark text-white' : ''}
      `}
        disabled={
          bookingUserId !== undefined ||
          (userHasReachedMaxNumberOfBookings && !authenticatedUserOwnsBooking)
        }
        onClick={onClick}
      >
        <TimeSlotTime
          startDate={startDate}
          endDate={endDate}
          fullDay={fullDay}
          className={otherUserOwnsBooking ? 'opacity-40' : ''}
        />
      </button>
    )
  }
)
