import React from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { FormControl } from '../../../../../../components/common/FormControl'
import { useFormErrorMessage } from '../../../../../../hooks/useFormErrorMessage'
import {
  Button,
  ButtonVariant,
} from '../../../../../../components/common/Button'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { adminSetMasterAdminSchema } from '../../../../../../forms/schemas/admin_set_master_admin'
import { SelectDropdown } from '../../../../../../components/common/SelectDropdown'
import { SocietyModel } from '../../../../../../state/models/society'
import { useStores } from '../../../../../../hooks/useStores'

interface SetMasterAdminModalContentProps {
  onError: (error: unknown) => void
  onSubmit: (data: InferType<typeof adminSetMasterAdminSchema>) => Promise<void>
  onClose: () => void
  society?: SnapshotOut<typeof SocietyModel>
  adminsList?: string[]
}

export const SetMasterAdminModalContent = observer(
  ({
    onError,
    onSubmit,
    onClose,
    society,
    adminsList,
  }: SetMasterAdminModalContentProps): JSX.Element => {
    const { userStore } = useStores()
    const { translate } = useAppTranslation()
    const { getErrorMessage } = useFormErrorMessage()

    const currentMasterAdminUserId = society?.masterAdminUserId

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(adminSetMasterAdminSchema),
      defaultValues: {
        masterAdminUserId: currentMasterAdminUserId || '',
      },
    })

    const admins =
      adminsList?.map((admin) => {
        const user = userStore.users.get(admin)
        return {
          value: admin,
          label: user?.fullName || '',
        }
      }) || []

    return (
      <form
        className="flex h-full flex-col gap-6"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Controller
          control={control}
          name="masterAdminUserId"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              name={name}
              error={
                errors.masterAdminUserId &&
                getErrorMessage(errors.masterAdminUserId)
              }
            >
              <SelectDropdown
                options={admins}
                value={value}
                onChange={onChange}
                error={!!errors.masterAdminUserId}
              />
            </FormControl>
          )}
        />
        <div className="flex flex-wrap justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid}
            label={translate('common.actions.save')}
            type="submit"
          />
        </div>
      </form>
    )
  }
)
