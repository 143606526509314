import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useStores } from '../../../../hooks/useStores'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { CreateUpdateHouseRuleForm } from '../CreateUpdateHouseRuleForm'
import { HouseRuleModel } from '../../../../state/models/house-rule'
import { houseRuleCreateSchema } from '../../../../forms/schemas/house_rule_create'
import { NHouseRules } from '../../../../interfaces/services/house-rules.interfaces'

interface CreateUpdateHouseRuleModalProps {
  show: boolean
  close: () => void
  children?: React.ReactNode
  houseRule?: SnapshotOut<typeof HouseRuleModel>
}

export const CreateUpdateHouseRuleModal = observer(
  ({
    houseRule,
    children,
    show,
    close,
  }: CreateUpdateHouseRuleModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { houseRulesStore } = useStores()
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!houseRule

    const showErrorMessage = (): void => {
      const message = updateMode
        ? 'createUpdateHouseRule.flashMessage.updateHouseRuleFailure'
        : 'createUpdateHouseRule.flashMessage.createHouseRuleFailure'
      setToastNotification(ToastType.DANGER, translate(message))
    }

    const showSuccessMessage = (): void => {
      const message = updateMode
        ? 'createUpdateHouseRule.flashMessage.updateHouseRuleSuccess'
        : 'createUpdateHouseRule.flashMessage.createHouseRuleSuccess'
      setToastNotification(ToastType.SUCCESS, translate(message))
    }

    const onSubmit = async (
      data: InferType<typeof houseRuleCreateSchema>
    ): Promise<void> => {
      if (updateMode) {
        const status = await houseRulesStore.updateHouseRule(
          houseRule._id,
          data as NHouseRules.NPatch.IRequestBody
        )

        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      } else {
        const status = await houseRulesStore.createHouseRule(
          data as NHouseRules.NCreate.IRequestBody
        )
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      showErrorMessage()
    }

    const loading =
      houseRulesStore.creatingHouseRule === 'pending' ||
      houseRulesStore.updatingHouseRule === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate(
                  updateMode
                    ? 'createUpdateHouseRule.editHouseRuleTitle'
                    : 'createUpdateHouseRule.addNewHouseRuleTitle'
                )}
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <CreateUpdateHouseRuleForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  houseRule={houseRule}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
