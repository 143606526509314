import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import React from 'react'
import { Badge } from '../../../../components/common/Badge'
import { getInterest } from '../../../../helpers/translations/interests'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { UserModelInterests } from '../../../../state/models/user'
import { theme } from '../../../../theme/theme'
import { Interests } from '../../../../types/interests'

interface UserInterestsProps {
  interests: SnapshotOut<typeof UserModelInterests> | undefined
}

export const UserInterests = observer(({ interests }: UserInterestsProps) => {
  const { translate } = useAppTranslation()

  if (!interests || interests.length === 0) {
    return null
  }

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex items-center gap-3">
        <p className="text-neutral-30" style={theme.textVariants.caption}>
          {translate('userView.interestsTitle')}
        </p>
      </div>
      <div className="w-90 flex flex-row flex-wrap gap-3">
        {interests.map((interest) => (
          <Badge
            colorClass="bg-brandGreen/10"
            textColorClass="text-brandGreen"
            borderColorClass="border-brandGreen"
            key={interest}
            text={translate(getInterest(interest as Interests) as string)}
          />
        ))}
      </div>
    </div>
  )
})
