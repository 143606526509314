import { AxiosResponse } from 'axios'
import { createUrl } from './helpers'
import axiosAuth from './config'
import { NLocalOffers } from '../interfaces/services/local-offers.interfaces'

export const LOCAL_OFFERS_POPULATE = ['media']

export const getLocalOffersPerSocieties = (
  societyId: string
): Promise<AxiosResponse<NLocalOffers.NGetPerSocieties.IResponse>> => {
  const body: NLocalOffers.NGetPerSocieties.IRequestBody = {
    societyIds: [societyId],
  }
  return axiosAuth.post<NLocalOffers.NGetPerSocieties.IResponse>(
    createUrl('/local-offers/get-per-societies', {
      populate: LOCAL_OFFERS_POPULATE.join(';'),
    }),
    body
  )
}

export const getLocalOffer = (
  id: string
): Promise<AxiosResponse<NLocalOffers.NGetById.IResponse>> => {
  return axiosAuth.get<NLocalOffers.NGetById.IResponse>(
    createUrl(`/local-offers/local-offer/${id}`, {
      populate: LOCAL_OFFERS_POPULATE.join(';'),
    })
  )
}

export const patchLocalOffer = (
  id: string,
  body: NLocalOffers.NPatch.IRequestBody
): Promise<AxiosResponse<NLocalOffers.NPatch.IResponse>> => {
  return axiosAuth.patch<NLocalOffers.NPatch.IResponse>(
    createUrl(`/local-offers/local-offer/${id}`),
    body
  )
}

export const createLocalOffer = (
  body: NLocalOffers.NCreate.IRequestBody
): Promise<AxiosResponse<NLocalOffers.NCreate.IResponse>> => {
  return axiosAuth.post<NLocalOffers.NCreate.IResponse>(
    createUrl(`/local-offers/local-offer`),
    body
  )
}

export const deleteLocalOffer = (
  id: string
): Promise<AxiosResponse<NLocalOffers.NDel.IResponse>> => {
  return axiosAuth.delete<NLocalOffers.NDel.IResponse>(
    createUrl(`/local-offers/local-offer/${id}`)
  )
}
