import { useEffect } from 'react'
import { useAuth } from '../../../hooks/useAuth'
import { useStores } from '../../../hooks/useStores'

export const useSocietyLoader = (id: string | undefined): void => {
  const { userId } = useAuth()
  const { societyStore, unitStore, societyWidgetsStore } = useStores()

  useEffect(() => {
    if (userId && societyStore.fetchingSocieties !== 'pending') {
      societyStore.getUserSocieties(userId)
      id && societyStore.setSelectedSociety(id)
    }
  }, [id, societyStore, userId])

  useEffect(() => {
    if (unitStore.fetchingUnits !== 'pending') {
      unitStore.getUserUnits()
    }
  }, [unitStore])

  useEffect(() => {
    if (id && societyWidgetsStore.fetchingWidgets !== 'pending') {
      societyWidgetsStore.getWidgetsPerSociety(id)
    }
  }, [id, societyWidgetsStore])
}
