import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { UnitModel } from '../../../state/models/unit'
import { capitalize } from '../../../helpers/string'
import { theme } from '../../../theme/theme'
import { Button, ButtonVariant } from '../../common/Button'
import { useModal } from '../../../hooks/useModal'
import { ConfirmationModal } from '../../common/ConfirmationModal'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'
import { CreateUpdateRegisterUnitItemModal } from './CreateUpdateRegisterUnitItemModal'
import { Icon, IconChoices } from '../../common/Icon'
import { RegisterUnitItemSectionContent } from './RegisterUnitItemSectionContent'

interface RegisterUnitItemProps {
  unit: SnapshotOut<typeof UnitModel>
  enableDelete?: boolean
  enableUpdate?: boolean
}

export const RegisterUnitItem = observer(
  ({
    unit,
    enableDelete = true,
    enableUpdate = true,
  }: RegisterUnitItemProps): JSX.Element => {
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { unitStore, societyEntrancesStore } = useStores()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const {
      show: showConfirmationModal,
      open: openConfirmationModal,
      close: closeConfirmationModal,
    } = useModal()
    const {
      show: showEditModal,
      open: openEditModal,
      close: closeEditModal,
    } = useModal()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isSamf } = society

    const deleteEntrance = async (): Promise<void> => {
      const status = await unitStore.deleteUnit(unit._id)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate(
            isSamf
              ? 'registerUnit.flashMessage.deleteUnitSamfSuccess'
              : 'registerUnit.flashMessage.deleteUnitSuccess'
          )
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate(
            isSamf
              ? 'registerUnit.flashMessage.deleteUnitSamfFailure'
              : 'registerUnit.flashMessage.deleteUnitFailure'
          )
        )
      }
    }

    const entrance =
      unit.entranceId && societyEntrancesStore.entrances.get(unit.entranceId)

    return (
      <div className="mb-4 space-y-4">
        <RegisterUnitItemSectionContent
          title={translate(
            isSamf
              ? 'registerView.unit.labels.entranceSamf'
              : 'registerView.unit.labels.entrance'
          )}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          text={entrance?.addressStreet}
        />
        {unit.titleLegal && (
          <RegisterUnitItemSectionContent
            title={translate(
              isSamf
                ? 'registerView.unit.labels.titleLegalSamf'
                : 'registerView.unit.labels.titleLegal'
            )}
            text={capitalize(unit.titleLegal.toLowerCase())}
          />
        )}
        {unit.title && (
          <RegisterUnitItemSectionContent
            title={translate(
              isSamf
                ? 'registerView.unit.labels.titleSamf'
                : 'registerView.unit.labels.title'
            )}
            text={capitalize(unit.title.toLowerCase())}
          />
        )}
        {!isSamf && unit.floor !== undefined && (
          <RegisterUnitItemSectionContent
            title={translate('registerView.unit.labels.floor')}
            text={`${unit.floor}`}
          />
        )}
        {unit.residentsList.length > 0 && (
          <RegisterUnitItemSectionContent
            title={translate('registerUnit.modal.residents')}
            unit={unit}
          />
        )}
        {unit.residentsList.length === 0 && (
          <div className="flex">
            <Icon icon={IconChoices.EXCLAMATION_ALERT} className="mr-2" />
            <p style={theme.textVariants.base}>
              {translate('registerView.unit.warnings.noResidents')}{' '}
              {translate(
                isSamf
                  ? 'common.singularPlural.unit.singularSamf'
                  : 'common.singularPlural.unit.singular'
              ).toLowerCase()}
              .
            </p>
          </div>
        )}
        {(enableDelete || enableUpdate) && (
          <div className="space-x-3">
            {enableUpdate && (
              <Button
                label={translate('common.actions.edit')}
                onClick={openEditModal}
              />
            )}
            {enableDelete && (
              <Button
                label={translate('common.actions.delete')}
                variant={ButtonVariant.DANGER}
                onClick={openConfirmationModal}
              />
            )}
          </div>
        )}
        {enableDelete && (
          <ConfirmationModal
            title={translate(
              isSamf
                ? 'registerUnit.confirmationDialog.deleteSamfTitle'
                : 'registerUnit.confirmationDialog.deleteTitle'
            )}
            description={translate(
              isSamf
                ? 'registerUnit.confirmationDialog.deleteSamfDescription'
                : 'registerUnit.confirmationDialog.deleteDescription'
            )}
            show={showConfirmationModal}
            close={closeConfirmationModal}
            onConfirm={deleteEntrance}
            deleteMode
          />
        )}
        {enableUpdate && (
          <CreateUpdateRegisterUnitItemModal
            show={showEditModal}
            close={closeEditModal}
            unit={unit}
          />
        )}
      </div>
    )
  }
)
