import React from 'react'
import { useAppTranslation } from '../../hooks/useAppTranslation'
import { theme } from '../../theme/theme'

const ServiceTitle = (): React.ReactNode => {
  const { translate } = useAppTranslation()
  return (
    <div>
      <p className="mb-3" style={theme.textVariants.h2} translate="no">
        {translate('serviceView.title')}
      </p>
      <p className="mb-6" style={theme.textVariants.base}>
        {translate('serviceView.subtitle')}
      </p>
    </div>
  )
}

export default ServiceTitle
