import React from 'react'
import { observer } from 'mobx-react-lite'
import { BlockedUserListItem } from './BlockedUserListItem'

interface BlockedUsersListProps {
  users: {
    userId: string
  }[]
}

export const BlockedUsersList = observer(
  ({ users }: BlockedUsersListProps): JSX.Element => {
    return (
      <div className="h-full w-full space-y-4">
        {users.map((user) => (
          <BlockedUserListItem key={user.userId} userId={user.userId} />
        ))}
      </div>
    )
  }
)
