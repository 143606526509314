import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../../../common/Button'
import { useCurrentSociety } from '../../../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { useFormErrorMessage } from '../../../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../../../common/FormControl'
import { useToastNotifications } from '../../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../../common/Toast/toast-type'
import { useStores } from '../../../../../../hooks/useStores'
import { isUserAdminInSociety } from '../../../../../../helpers/society'
import { TextArea } from '../../../../../common/TextArea'
import { TextInput } from '../../../../../common/TextInput'
// eslint-disable-next-line max-len
import { societyWebsiteFAQQuestionCreateSchema } from '../../../../../../forms/schemas/society_website_faq_question_create'

interface CreateUpdateQuestionsAndAnswersItemFormProps {
  onError: (error: unknown) => void
  onSubmit: (
    data: InferType<typeof societyWebsiteFAQQuestionCreateSchema>
  ) => Promise<void>
  onClose: () => void
  loading: boolean
  questionId?: string
  sectionId?: string
}

export const CreateUpdateQuestionsAndAnswersItemForm = observer(
  ({
    sectionId,
    questionId,
    loading,
    onError,
    onSubmit,
    onClose,
  }: CreateUpdateQuestionsAndAnswersItemFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { authenticationStore, faqsStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()
    const { getErrorMessage } = useFormErrorMessage()
    const question = questionId
      ? faqsStore.questions.get(questionId)
      : undefined
    const section = sectionId ? faqsStore.sections.get(sectionId) : undefined

    const updateMode = !!questionId

    const isAdmin = isUserAdminInSociety(
      society,
      authenticationStore.userId as string
    )

    if (!isAdmin || (updateMode && questionId === undefined)) {
      onClose()
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const getDefaultValues = useCallback((): InferType<
      typeof societyWebsiteFAQQuestionCreateSchema
    > => {
      return {
        question: updateMode ? question?.question || '' : '',
        answer: updateMode ? question?.answer || '' : '',
        section: updateMode
          ? question?.section.section || ''
          : section?.section,
      }
    }, [question, updateMode, section])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyWebsiteFAQQuestionCreateSchema),
      defaultValues: getDefaultValues(),
    })

    return (
      <>
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate(
                'createUpdateQuestionsAndAnswersItemForm.labels.question'
              )}
              error={errors.question && getErrorMessage(errors.question)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.question}
              />
            </FormControl>
          )}
          name="question"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate(
                'createUpdateQuestionsAndAnswersItemForm.labels.answer'
              )}
              error={errors.answer && getErrorMessage(errors.answer)}
              name={name}
            >
              <TextArea
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.answer}
              />
            </FormControl>
          )}
          name="answer"
        />
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-3">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
            variant={ButtonVariant.TEXT}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid || loading}
            label={translate(
              updateMode ? 'common.actions.save' : 'common.actions.add'
            )}
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </>
    )
  }
)
