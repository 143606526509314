import { useCallback } from 'react'
import { getOneOnOneUserId } from '../helpers/chat'
import { useAuthenticatedUserId } from './useAuthenticatedUserId'
import { useStores } from './useStores'

export function useChatRoomTitle(id: string | undefined): string {
  const { chatRoomStore, userStore } = useStores()
  const authenticatedUserId = useAuthenticatedUserId()
  const chatRoom = id ? chatRoomStore.chatRooms.get(id) : undefined

  const getTitle = useCallback((): string => {
    if (!chatRoom) {
      // TODO: Handle
      return ''
    }

    if (chatRoom.type === 'oneonone') {
      const otherUserId = getOneOnOneUserId(authenticatedUserId, chatRoom)
      const otherUser = otherUserId
        ? userStore.users.get(otherUserId)
        : undefined
      if (otherUser) {
        return otherUser.fullName
      }
    }

    return chatRoom.name
  }, [authenticatedUserId, chatRoom, userStore.users])

  return getTitle()
}
