/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, getRoot, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import { IChatRoomModel } from '../../interfaces/models/chat-rooms.interfaces'
import {
  getUserRoomsOneOnOne as apiGetUserRoomsOneOnOne,
  getUserRoomsInterests as apiGetUserRoomsInterests,
  getUserRoomsQuestions as apiGetUserRoomsQuestions,
  getUserRoomsBoard as apiGetUserRoomsBoard,
  getRoomById as apiGetRoomById,
  setRoomRead as apiSetRoomRead,
  createRoom as apiCreateRoom,
  removeRoom as apiRemoveRoom,
  patchRoom as apiPatchRoom,
  joinRooms as apiJoinRooms,
  getUserRoom as apiGetUserRoom,
  leaveRooms as apiLeaveRooms,
  toggleHideUntilNotification as apiToggleHideUntilNotification,
} from '../../api/chat'
import { NChat } from '../../interfaces/services/chat.interfaces'
import { stateType } from '../types/common'
import { ChatRoomModel } from '../models/chat-room'
import { RootStore } from './root'
import { setObject } from './helpers'
import { sortByDate } from '../../helpers/sorting'

export const ChatRoomStore = types
  .model('ChatRoomStore')
  .props({
    chatRooms: types.map(ChatRoomModel),
    selectedChatRoom: types.maybe(types.string),
    fetchingChatRoomsOneOnOne: stateType,
    fetchingChatRoomsInterests: stateType,
    fetchingChatRoomsQuestions: stateType,
    fetchingChatRoomsBoard: stateType,
    fetchingChatRoom: stateType,
    creatingChatRoom: stateType,
    updatingChatRoom: stateType,
    joiningChatRoom: stateType,
    togglingHideUntilNotification: stateType,
    societyFilters: types.array(types.string),
    hasFetchedUserRoomsOneOnOneOnce: types.boolean,
    hasFetchedUserRoomsInterestsOnce: types.boolean,
    hasFetchedUserRoomsQuestionsResidentOnce: types.boolean,
    hasFetchedUserRoomsQuestionsBoardOnce: types.boolean,
    hasFetchedUserRoomsBoardOnce: types.boolean,
    lastReceivedSocketMessageId: types.maybe(types.string),
  })
  .views((self) => ({
    get sortedChatRooms(): SnapshotOut<typeof ChatRoomModel>[] {
      return (
        // @ts-ignore
        (values(self.chatRooms) as SnapshotOut<typeof ChatRoomModel>[]).sort(
          (a, b) =>
            sortByDate(
              a.lastMessageDate ? new Date(a.lastMessageDate) : a.createdAt,
              b.lastMessageDate ? new Date(b.lastMessageDate) : b.createdAt,
              true
            )
        )
      )
    },
    get sortedChatRoomsWhereUserIsMember(): SnapshotOut<
      typeof ChatRoomModel
    >[] {
      return (
        // @ts-ignore
        (values(self.chatRooms) as SnapshotOut<typeof ChatRoomModel>[])
          .sort((a, b) =>
            sortByDate(
              a.lastMessageDate ? new Date(a.lastMessageDate) : a.createdAt,
              b.lastMessageDate ? new Date(b.lastMessageDate) : b.createdAt,
              true
            )
          )
          .filter((chatRoom) =>
            chatRoom.membersList
              .map((_member) => _member.userId)
              .includes(
                getRoot<RootStore>(self).authenticationStore.userId || 'nonUser'
              )
          )
      )
    },
    sortChatRooms(
      rooms: SnapshotOut<typeof ChatRoomModel>[]
    ): SnapshotOut<typeof ChatRoomModel>[] {
      return rooms.sort((a, b) =>
        sortByDate(
          a.lastMessageDate ? new Date(a.lastMessageDate) : a.createdAt,
          b.lastMessageDate ? new Date(b.lastMessageDate) : b.createdAt,
          true
        )
      )
    },
    filterOnChatRoomType(
      rooms: SnapshotOut<typeof ChatRoomModel>[],
      type: string
    ): SnapshotOut<typeof ChatRoomModel>[] {
      return rooms.filter((chatRoom) => chatRoom.type === type)
    },
  }))
  .views((self) => ({
    get filteredChatRooms() {
      let fitleredChatRooms = self.sortedChatRooms

      if (self.societyFilters.length > 0) {
        fitleredChatRooms = fitleredChatRooms.filter((chatRoom) =>
          self.societyFilters.includes(chatRoom.societyId)
        )
      }

      return fitleredChatRooms
    },
  }))
  .views((self) => ({
    get oneOnOneChatRooms() {
      return self.filterOnChatRoomType(self.filteredChatRooms, 'oneonone')
    },
    get interestsChatRooms() {
      return self.filterOnChatRoomType(self.filteredChatRooms, 'interests')
    },
    get questionsChatRooms() {
      return self.filterOnChatRoomType(self.filteredChatRooms, 'questions')
    },
    get boardChatRooms() {
      return self.filterOnChatRoomType(self.filteredChatRooms, 'board')
    },
    get hasFetchedChatRoomsInitially() {
      return (
        self.hasFetchedUserRoomsOneOnOneOnce &&
        self.hasFetchedUserRoomsInterestsOnce
      )
    },
  }))
  .views((self) => ({
    residentQuestionsChatRooms(userId: string) {
      return self.questionsChatRooms.filter((_room) =>
        _room.membersList.map((_member) => _member.userId).includes(userId)
      )
    },
    nonUserQuestionsChatRooms(userId: string) {
      return self.questionsChatRooms.filter(
        (_room) =>
          !_room.membersList.map((_member) => _member.userId).includes(userId)
      )
    },
    interestsChatRoomsParticipatingIn(userId: string) {
      return self.interestsChatRooms.filter((_room) =>
        _room.membersList.map((_member) => _member.userId).includes(userId)
      )
    },
    openInterestsChatRoomsNotParticipatingIn(userId: string) {
      return self.interestsChatRooms.filter(
        (_chatRoom) =>
          !_chatRoom.membersList
            .map((_member) => _member.userId)
            .includes(userId)
      )
    },

    get interestsChatRoomsNotCreatedFromPost() {
      return self.interestsChatRooms.filter(
        (_chatRoom) => !_chatRoom.isCreatedFromPost
      )
    },
  }))
  .views((self) => ({
    archivedQuestionsChatRooms(userId: string, isResident: boolean) {
      const chatRooms = isResident
        ? self.residentQuestionsChatRooms(userId)
        : self.questionsChatRooms
      return chatRooms.filter(
        (_chatRoom) =>
          !!_chatRoom.userSettings.find(
            (_setting) =>
              _setting.userId === userId &&
              _setting.hideUntilNotification === true
          )
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.chatRooms.clear()
      self.selectedChatRoom = undefined
      self.fetchingChatRoomsOneOnOne = 'none'
      self.fetchingChatRoomsInterests = 'none'
      self.fetchingChatRoomsQuestions = 'none'
      self.fetchingChatRoomsBoard = 'none'
      self.fetchingChatRoom = 'none'
      self.updatingChatRoom = 'none'
      self.joiningChatRoom = 'none'
      self.hasFetchedUserRoomsOneOnOneOnce = false
      self.hasFetchedUserRoomsInterestsOnce = false
      self.hasFetchedUserRoomsQuestionsBoardOnce = false
      self.hasFetchedUserRoomsQuestionsResidentOnce = false
      self.hasFetchedUserRoomsBoardOnce = false
      self.lastReceivedSocketMessageId = undefined
    },
    setChatRooms: (chatRooms: IChatRoomModel[]) => {
      chatRooms.forEach((chatRoom) => {
        // @ts-ignore
        setObject<typeof ChatRoomModel>(self.chatRooms, ChatRoomModel, {
          ...chatRoom,
          societyId: chatRoom.societyId,
        })
      })
    },
    setSelectedChatRoom: (id: string | undefined) => {
      self.selectedChatRoom = id
    },
    deleteChatRooms: (ids: string[]): void => {
      ids.forEach((_id) => self.chatRooms.delete(_id))
    },
    resetFetchingChatRoomState: () => {
      self.fetchingChatRoom = 'done'
    },
    setSocietyFilters: (societyIds: string[]) => {
      // @ts-ignore
      self.societyFilters = societyIds
    },
    setHasFetchedUserRoomsOneOnOneOnce: (val: boolean) => {
      self.hasFetchedUserRoomsOneOnOneOnce = val
    },
    setHasFetchedUserRoomsInterestsOnce: (val: boolean) => {
      self.hasFetchedUserRoomsInterestsOnce = val
    },
    setHasFetchedUserRoomsQuestionsResidentOnce: (val: boolean) => {
      self.hasFetchedUserRoomsQuestionsResidentOnce = val
    },
    setHasFetchedUserRoomsQuestionsBoardOnce: (val: boolean) => {
      self.hasFetchedUserRoomsQuestionsBoardOnce = val
    },
    setHasFetchedUserRoomsBoardOnce: (val: boolean) => {
      self.hasFetchedUserRoomsBoardOnce = val
    },
    setLastReceivedSocketMessageId(id: string) {
      self.lastReceivedSocketMessageId = id
    },
  }))
  .actions((self) => ({
    getUserRoomsOneOnOne: flow(function* getUserRoomsOneOnOne(
      fetchNextPage = false
    ) {
      self.fetchingChatRoomsOneOnOne = 'pending'
      try {
        const { oneOnOneChatRooms } = self
        const lastMessageDate =
          oneOnOneChatRooms[oneOnOneChatRooms.length - 1]?.lastMessageDate
        const date = lastMessageDate
          ? +new Date(lastMessageDate).getTime()
          : undefined
        const query = fetchNextPage ? { lastKnownDate: date } : {}
        const resp = yield apiGetUserRoomsOneOnOne({ ...query, limit: 20 })
        const data = resp.data as NChat.NGetUserRoomsOneOnOne.IResponse
        const chatRooms = data.data

        const users = data?.populated?.users
        const media = data?.populated?.media
        const societies = data?.populated?.societies

        const { messages } = data.computed

        const { userStore, mediaStore, societyStore, chatMessageStore } =
          getRoot<RootStore>(self)
        mediaStore.setMedia(media)
        societyStore.setSocieties(societies)
        if (users) {
          userStore.setUsers(users)
        }

        self.setChatRooms(chatRooms)

        if (messages) {
          chatMessageStore.setChatMessages(messages)
        }

        self.setHasFetchedUserRoomsOneOnOneOnce(true)

        self.fetchingChatRoomsOneOnOne = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingChatRoomsOneOnOne = 'error'
      }
    }),
    getUserRoomsInterests: flow(function* getUserRoomsInterests(
      fetchNextPage = false,
      allOpen = false
    ) {
      self.fetchingChatRoomsInterests = 'pending'
      try {
        const { interestsChatRoomsNotCreatedFromPost } = self
        const lastMessageDate =
          interestsChatRoomsNotCreatedFromPost[
            interestsChatRoomsNotCreatedFromPost.length - 1
          ]?.lastMessageDate
        const date = lastMessageDate
          ? +new Date(lastMessageDate).getTime()
          : undefined
        const query = fetchNextPage ? { lastKnownDate: date } : {}
        const resp = yield apiGetUserRoomsInterests({
          ...query,
          allOpen,
          limit: allOpen ? 100 : 40,
        })
        const data = resp.data as NChat.NGetUserRoomsInterests.IResponse
        const chatRooms = data.data
        const users = data?.populated?.users
        const media = data?.populated?.media
        const societies = data?.populated?.societies
        const { messages } = data.computed

        const { userStore, mediaStore, societyStore, chatMessageStore } =
          getRoot<RootStore>(self)
        mediaStore.setMedia(media)
        societyStore.setSocieties(societies)
        if (users) {
          userStore.setUsers(users)
        }

        self.setChatRooms(chatRooms)

        if (messages) {
          chatMessageStore.setChatMessages(messages)
        }

        self.setHasFetchedUserRoomsInterestsOnce(true)

        self.fetchingChatRoomsInterests = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingChatRoomsInterests = 'error'
      }
    }),
    getUserRoomsQuestions: flow(function* getUserRoomsQuestions(
      userId,
      role,
      fetchNextPage = false
    ) {
      self.fetchingChatRoomsQuestions = 'pending'
      try {
        const existingChatRooms =
          role === 'board'
            ? self.nonUserQuestionsChatRooms(userId)
            : self.residentQuestionsChatRooms(userId)

        const lastMessageDate =
          existingChatRooms[existingChatRooms.length - 1]?.lastMessageDate

        const date = lastMessageDate
          ? +new Date(lastMessageDate).getTime()
          : undefined
        const query = fetchNextPage ? { lastKnownDate: date } : {}
        const resp = yield apiGetUserRoomsQuestions({
          ...query,
          limit: 20,
          role,
        })
        const data = resp.data as NChat.NGetUserRoomsQuestions.IResponse
        const chatRooms = data.data
        const users = data?.populated?.users
        const media = data?.populated?.media
        const societies = data?.populated?.societies
        const { messages } = data.computed

        const { userStore, mediaStore, societyStore, chatMessageStore } =
          getRoot<RootStore>(self)
        mediaStore.setMedia(media)
        societyStore.setSocieties(societies)
        if (users) {
          userStore.setUsers(users)
        }

        self.setChatRooms(chatRooms)

        if (messages) {
          chatMessageStore.setChatMessages(messages)
        }

        if (role === 'board') {
          self.setHasFetchedUserRoomsQuestionsBoardOnce(true)
        } else {
          self.setHasFetchedUserRoomsQuestionsResidentOnce(true)
        }

        self.fetchingChatRoomsQuestions = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingChatRoomsQuestions = 'error'
      }
    }),
    getUserRoomsBoard: flow(function* getUserRoomsBoard(fetchNextPage = false) {
      self.fetchingChatRoomsBoard = 'pending'
      try {
        const { boardChatRooms } = self
        const lastMessageDate =
          boardChatRooms[boardChatRooms.length - 1]?.lastMessageDate
        const date = lastMessageDate
          ? +new Date(lastMessageDate).getTime()
          : undefined
        const query = fetchNextPage ? { lastKnownDate: date } : {}
        const resp = yield apiGetUserRoomsBoard({ ...query, limit: 20 })
        const data = resp.data as NChat.NGetUserRoomsBoard.IResponse
        const chatRooms = data.data
        const users = data?.populated?.users
        const media = data?.populated?.media
        const societies = data?.populated?.societies
        const { messages } = data.computed

        const { userStore, mediaStore, societyStore, chatMessageStore } =
          getRoot<RootStore>(self)
        mediaStore.setMedia(media)
        societyStore.setSocieties(societies)
        if (users) {
          userStore.setUsers(users)
        }

        self.setChatRooms(chatRooms)

        if (messages) {
          chatMessageStore.setChatMessages(messages)
        }

        self.setHasFetchedUserRoomsBoardOnce(true)

        self.fetchingChatRoomsBoard = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingChatRoomsBoard = 'error'
      }
    }),
    getRoom: flow(function* getRoom(id: string) {
      self.fetchingChatRoom = 'pending'
      try {
        const resp = yield apiGetRoomById(id)
        const data = resp.data as NChat.NGetRoomById.IResponse
        const chatRoom = data.data
        const users = data?.populated?.users
        const media = data?.populated?.media
        const societies = data?.populated?.societies
        const { messages } = data.computed

        const { userStore, mediaStore, societyStore, chatMessageStore } =
          getRoot<RootStore>(self)
        mediaStore.setMedia(media)
        societyStore.setSocieties(societies)
        if (users) {
          userStore.setUsers(users)
        }

        self.setChatRooms([chatRoom])

        if (messages) {
          chatMessageStore.setChatMessages(messages)
        }

        self.fetchingChatRoom = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingChatRoom = 'error'
      }
    }),
    createRoom: flow(function* createRoom(
      body: NChat.NCreateRoom.IRequestBody
    ) {
      self.creatingChatRoom = 'pending'
      try {
        const resp = yield apiCreateRoom(body)
        const data = resp.data as NChat.NCreateRoom.IResponse
        const room = data.data

        if (room !== null) {
          self.setChatRooms([room])
        }

        self.creatingChatRoom = 'done'
        return room
      } catch (error) {
        captureException(error)
        self.creatingChatRoom = 'error'
        return undefined
      }
    }),
    joinRoom: flow(function* joinRoom(roomId: string) {
      self.joiningChatRoom = 'pending'
      try {
        const body = { roomIds: [roomId] }
        const resp = yield apiJoinRooms(body)
        const data = resp.data as NChat.NJoinRooms.IResponse
        const rooms = data.data

        if (rooms?.length > 0) {
          const room = rooms[0]
          self.setChatRooms([room])
          self.joiningChatRoom = 'done'
          return room
        }

        self.joiningChatRoom = 'done'
        return undefined
      } catch (error) {
        captureException(error)
        self.joiningChatRoom = 'error'
        return undefined
      }
    }),
    setRoomRead: flow(function* setRoomRead(chatRoom: IChatRoomModel) {
      const room = self.chatRooms.get(chatRoom._id)
      const oldUnreadCount = room?.unreadCount ? room.unreadCount : 0
      room?.setUnreadCount(0)
      try {
        yield apiSetRoomRead(chatRoom._id)
      } catch (error) {
        captureException(error)
        room?.setUnreadCount(oldUnreadCount)
      }
    }),
    toggleHideUntilNotification: flow(function* toggleHideUntilNotification(
      chatRoomId: string,
      value: boolean
    ) {
      self.togglingHideUntilNotification = 'pending'
      try {
        const { authenticationStore } = getRoot<RootStore>(self)
        const _chatRoom = self.chatRooms.get(chatRoomId)
        yield apiToggleHideUntilNotification(chatRoomId, value)
        if (_chatRoom) {
          _chatRoom.toggleHideUntilNotification(
            // @ts-ignore
            authenticationStore.userId,
            value
          )
        }
        self.togglingHideUntilNotification = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.togglingHideUntilNotification = 'error'
        return false
      }
    }),
    removeRoom: flow(function* remove(id: string) {
      try {
        yield apiRemoveRoom(id)
        self.chatRooms.delete(id)
        return true
      } catch (error) {
        captureException(error)
        return false
      }
    }),
    leaveRoom: flow(function* leaveRoom(id: string) {
      try {
        yield apiLeaveRooms({ roomIds: [id] })
        self.chatRooms.delete(id)
        return true
      } catch (error) {
        captureException(error)
        return false
      }
    }),
    updateChatRoomAvatar: flow(function* updateChatRoomAvatar(
      chatRoomId: string,
      mediaType: 'image' | 'video',
      base64: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Generator<any> {
      self.updatingChatRoom = 'pending'
      const { mediaStore, chatRoomStore } = getRoot<RootStore>(self)
      try {
        const mediaId = yield mediaStore.createMedia(mediaType, base64)
        if (mediaId === undefined) {
          throw new Error("Couldn't create media")
        }
        const resp = yield apiPatchRoom(chatRoomId, {
          avatarId: mediaId as string,
        })
        // @ts-ignore
        const data = resp.data as NChat.NPatchRoom.IResponse
        const chatRoom = data.data as IChatRoomModel
        chatRoomStore.setChatRooms([chatRoom])
        self.updatingChatRoom = 'done'
      } catch (error) {
        self.updatingChatRoom = 'error'
        captureException(error)
      }
    }),
    removeChatRoomAvatar: flow(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function* removeChatRoomAvatar(chatRoomId: string): Generator<any> {
        self.updatingChatRoom = 'pending'
        const { chatRoomStore } = getRoot<RootStore>(self)
        try {
          const resp = yield apiPatchRoom(chatRoomId, {
            // @ts-ignore
            avatarId: null,
          })
          // @ts-ignore
          const data = resp.data as NChat.NPatchRoom.IResponse
          const chatRoom = data.data as IChatRoomModel
          chatRoomStore.setChatRooms([chatRoom])
          self.updatingChatRoom = 'done'
          return true
        } catch (error) {
          self.updatingChatRoom = 'error'
          captureException(error)
          return false
        }
      }
    ),
    addChatRoomMembers: flow(function* addChatRoomMembers(
      chatRoomId: string,
      memberIds: string[]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Generator<any> {
      self.updatingChatRoom = 'pending'
      const { chatRoomStore } = getRoot<RootStore>(self)
      const currentChatRoom = chatRoomStore.chatRooms.get(chatRoomId)
      const newMembers = memberIds.map((memberId) => ({ userId: memberId }))
      const members = currentChatRoom?.membersList?.concat(newMembers)
      try {
        const resp = yield apiPatchRoom(chatRoomId, {
          membersList: members,
        })
        // @ts-ignore
        const data = resp.data as NChat.NPatchRoom.IResponse
        const chatRoom = data.data as IChatRoomModel
        chatRoomStore.setChatRooms([chatRoom])
        self.updatingChatRoom = 'done'
      } catch (error) {
        self.updatingChatRoom = 'error'
        captureException(error)
      }
    }),
    removeChatRoomMember: flow(function* addChatRoomMembers(
      chatRoomId: string,
      memberId: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Generator<any> {
      self.updatingChatRoom = 'pending'
      const { chatRoomStore } = getRoot<RootStore>(self)
      const currentChatRoom = chatRoomStore.chatRooms.get(chatRoomId)
      const members = currentChatRoom?.membersList?.filter(
        (member) => member.userId !== memberId
      )
      try {
        const resp = yield apiPatchRoom(chatRoomId, {
          membersList: members,
        })
        // @ts-ignore
        const data = resp.data as NChat.NPatchRoom.IResponse
        const chatRoom = data.data as IChatRoomModel
        chatRoomStore.setChatRooms([chatRoom])
        self.updatingChatRoom = 'done'
        return true
      } catch (error) {
        self.updatingChatRoom = 'error'
        captureException(error)
        return false
      }
    }),
    getUserRoom: flow(function* getUserRoom(userId: string) {
      self.fetchingChatRoom = 'pending'
      try {
        const resp = yield apiGetUserRoom(userId)
        if (resp.data.data && Object.keys(resp.data.data).length !== 0) {
          const data = resp.data as NChat.NGetUserRoom.IResponse
          const chatRoom = data.data
          if (chatRoom) {
            const users = data?.populated?.users
            const media = data?.populated?.media
            const societies = data?.populated?.societies
            const { messages } = data.computed
            const { userStore, mediaStore, societyStore, chatMessageStore } =
              getRoot<RootStore>(self)
            mediaStore.setMedia(media)
            societyStore.setSocieties(societies)
            if (users) {
              userStore.setUsers(users)
            }

            self.setChatRooms([chatRoom])

            if (messages) {
              chatMessageStore.setChatMessages(messages)
            }
          }

          self.fetchingChatRoom = 'done'
          return chatRoom
        }
        self.fetchingChatRoom = 'done'
        return undefined
      } catch (error) {
        self.fetchingChatRoom = 'error'
        captureException(error)
        return undefined
      }
    }),
  }))
