import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import Fuse from 'fuse.js'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { useStores } from '../../../../hooks/useStores'
import { ContactList } from '../../../../components/society/ContactList'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { ButtonVariant } from '../../../../components/common/Button'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { CreateUpdateContactModal } from '../../../../components/society/Contact/CreateUpdateContactModal'
import { useModal } from '../../../../hooks/useModal'
import { ViewBase } from '../../../../components/common/ViewBase'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { IllustrationChoices } from '../../../../components/common/Illustration'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'

export const BoardContactsView = observer((): JSX.Element => {
  const { societyBoardroomContactsStore, authenticationStore } = useStores()
  const { translate } = useAppTranslation()
  const [searchString, setSearchString] = useState('')
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:contacts', {
      '{{ societyName }}': society.name,
    })
  )
  const {
    show: showCreateModal,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModal()

  const isAdmin = isUserAdminInSociety(
    society,
    authenticationStore.userId as string
  )

  const contacts = societyBoardroomContactsStore.contactForSociety(society._id)

  useEffect(() => {
    societyBoardroomContactsStore.getContactsPerSociety(society._id)
  }, [societyBoardroomContactsStore, society._id])

  const onChangeSearchString = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    setSearchString(event.currentTarget.value)
  }

  const getFilteredContacts = useCallback(() => {
    const options = {
      threshold: 0.2,
      keys: ['name', 'title'],
    }

    const fuse = new Fuse(contacts, options)

    return searchString === ''
      ? contacts
      : fuse.search(searchString).map((result) => result.item)
  }, [contacts, searchString])

  const filteredContacts = getFilteredContacts()

  const loading =
    societyBoardroomContactsStore.fetchingContacts === 'none' ||
    societyBoardroomContactsStore.fetchingContacts === 'pending'

  const buttons = isAdmin
    ? [
        {
          variant: ButtonVariant.PRIMARY,
          onClick: openCreateModal,
          label: translate('common.actions.add'),
        },
      ]
    : []

  const errorView =
    !loading && contacts.length === 0
      ? {
          title: translate('contactsView.emptyState.title'),
          subtitle: translate('contactsView.emptyState.subtitle'),
          illustration: IllustrationChoices.EMPTY,
        }
      : undefined

  const noSearchResultErrorView =
    !loading && filteredContacts.length === 0 && searchString !== ''
      ? {
          title: translate('contactsView.emptyState.noSearchResultsTitle'),
          subtitle: translate(
            'contactsView.emptyState.noSearchResultsSubtitle'
          ),
          illustration: IllustrationChoices.EMPTY,
        }
      : undefined

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('boardContactsView.title')}
        buttons={buttons}
        searchBar={{
          value: searchString,
          onChange: onChangeSearchString,
        }}
        loading={loading}
        errorView={errorView}
        noSearchResultErrorView={noSearchResultErrorView}
      >
        <ContactList contacts={getFilteredContacts()} management />
      </ViewBase>
      {isAdmin && (
        <CreateUpdateContactModal
          management
          show={showCreateModal}
          close={closeCreateModal}
        />
      )}
    </ErrorBoundary>
  )
})
