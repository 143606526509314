import React from 'react'
import { observer } from 'mobx-react-lite'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { ErrorView } from '../ErrorView'
import { Container } from '../../../components/common/Container'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { IllustrationChoices } from '../../../components/common/Illustration'
import { ButtonVariant } from '../../../components/common/Button'
import { useWindowDimensions } from '../../../hooks/useWindowDimensions'
import { useIsMobile } from '../../../hooks/useIsMobile'

export const UnauthorizedView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const navigate = useNavigate()
  const { height } = useWindowDimensions()
  const isMobile = useIsMobile()

  return (
    <Container className="flex h-full items-center justify-center">
      <ErrorView
        title={translate('unauthorizedView.title')}
        subtitle={translate('unauthorizedView.subtitle')}
        titleWrapperClassName="mt-8 md:mt-16"
        illustration={IllustrationChoices.EMPTY}
        illustrationHeight={isMobile ? height * 0.35 : height * 0.25}
        buttons={[
          {
            id: uuid(),
            button: {
              label: translate('unauthorizedView.buttonLabel'),
              onClick: () => navigate(-1),
              variant: ButtonVariant.PRIMARY,
            },
          },
        ]}
      />
    </Container>
  )
})
