import { captureException } from '@sentry/react'
import { addDays } from 'date-fns'
import Cookies from 'universal-cookie'
import { TWILIGHT_PREFIX } from '../state/stores/admin'
import { CookieKeys } from '../types/cookies'

// Safari doesn't consider localhost a secure context and will ignore any
// attempts to set cookies with the secure flag
const isSecureOrigin =
  typeof window !== 'undefined' && window.location.protocol === 'https:'

interface SplitBearerToken {
  header: string
  payload: string
  signature: string
}

export const splitBearerToken = (token: string): SplitBearerToken | null => {
  const parts = token.split('.')
  try {
    return {
      header: parts[0],
      payload: parts[1],
      signature: parts[2],
    }
  } catch (error) {
    captureException(error)
    return null
  }
}

const setBearerCookies = (
  bearerToken: SplitBearerToken,
  prefix?: string
): void => {
  const cookies = new Cookies()
  cookies.set(
    `${prefix || ''}${CookieKeys.HEADER_PAYLOAD}`,
    `${bearerToken.header}.${bearerToken.payload}`,
    {
      sameSite: true,
      secure: isSecureOrigin,
      expires: addDays(new Date(), 30),
      path: '/',
    }
  )
  cookies.set(`${prefix || ''}${CookieKeys.SIGNATURE}`, bearerToken.signature, {
    sameSite: true,
    secure: isSecureOrigin,
    expires: addDays(new Date(), 30),
    path: '/',
  })
}

export const setSecureBearerCookies = (
  token: string,
  prefix?: string
): boolean => {
  const _splitBearerTokens = splitBearerToken(token)

  if (_splitBearerTokens) {
    setBearerCookies(_splitBearerTokens, prefix)
  } else {
    return false
  }

  return true
}

export const setSecureUserIdCookie = (
  userId: string,
  prefix?: string
): void => {
  const cookies = new Cookies()
  cookies.set(`${prefix || ''}${CookieKeys.USER_ID}`, userId, {
    sameSite: true,
    secure: isSecureOrigin,
    expires: addDays(new Date(), 30),
    path: '/',
  })
}

export const getBearerTokenFromCookies = (): string | null => {
  const cookies = new Cookies()

  const twilightHeaderAndPayload = cookies.get(
    `${TWILIGHT_PREFIX}${CookieKeys.HEADER_PAYLOAD}`
  )
  const twilightSignature = cookies.get(
    `${TWILIGHT_PREFIX}${CookieKeys.SIGNATURE}`
  )
  if (twilightHeaderAndPayload && twilightSignature) {
    return `${twilightHeaderAndPayload}.${twilightSignature}`
  }

  const headerAndPayload = cookies.get(CookieKeys.HEADER_PAYLOAD)
  const signature = cookies.get(CookieKeys.SIGNATURE)
  if (!headerAndPayload || !signature) return null
  return `${headerAndPayload}.${signature}`
}

export const checkIfTwilightZone = (): boolean => {
  const cookies = new Cookies()

  const twilightHeaderAndPayload = cookies.get(
    `${TWILIGHT_PREFIX}${CookieKeys.HEADER_PAYLOAD}`
  )
  const twilightSignature = cookies.get(
    `${TWILIGHT_PREFIX}${CookieKeys.SIGNATURE}`
  )
  const twilightUser = cookies.get(`${TWILIGHT_PREFIX}${CookieKeys.USER_ID}`)

  if (twilightHeaderAndPayload && twilightSignature && twilightUser) {
    return true
  }
  return false
}
