import { useEffect } from 'react'
import { captureException } from '@sentry/react'
import { useStores } from './useStores'

// Fetch status messages from separate server as to display
// disruptances in our services
export const useStatusMessages = (): void => {
  const { statusStore } = useStores()

  // Fetch when started
  useEffect(() => {
    try {
      statusStore.getStatusMessages()
    } catch (error) {
      captureException(error)
    }
  }, [statusStore])
}
