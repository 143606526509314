import React from 'react'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { DateTimeInput } from '../DateTimeInput/DateTimeInput'

interface TimePickerProps {
  date: Date
  onChange: (date: Date) => void
  timeInterval: number
  disableRawInput?: boolean
}

export const TimePicker = ({
  date,
  timeInterval,
  onChange,
  disableRawInput,
}: TimePickerProps): JSX.Element => {
  const { translate } = useAppTranslation()
  return (
    <DateTimeInput
      value={date}
      onChange={onChange}
      timeInterval={timeInterval}
      showTimeSelect
      showTimeSelectOnly
      timeCaption={translate('common.time')}
      dateFormat="HH:mm"
      disableRawInput={disableRawInput}
    />
  )
}
