import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { ErrorView } from '../../../views/error/ErrorView'
import { IllustrationChoices } from '../../common/Illustration'
import { InfiniteScroll } from '../../common/InfiniteScroll'
import { ChatRoomsListItem } from '../ChatRoomsListItem'
import { useJoinRoomData } from './useJoinGroupData'

interface JoinGroupModalContentProps {
  modalRef: React.MutableRefObject<HTMLDivElement | null>
  close: () => void
}

export const JoinGroupModalContent = observer(
  ({ modalRef, close }: JoinGroupModalContentProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { currentChatRooms, loadMoreChatRooms, showSpinner } =
      useJoinRoomData()

    const loading = currentChatRooms.length > 0 ? false : showSpinner
    const noRooms = !showSpinner && currentChatRooms.length === 0

    return noRooms ? (
      <ErrorView
        title={translate('joinGroupChat.errorViews.noChatRooms.title')}
        subtitle={translate('joinGroupChat.errorViews.noChatRooms.subtitle')}
        illustration={IllustrationChoices.EMPTY}
        illustrationHeight={250}
        illustrationWidth={187}
      />
    ) : (
      <div className="flex flex-col">
        <InfiniteScroll
          key="infiniteGroupChatScroll" // Add key to prompt rerendering of component on state change
          onEndReached={loadMoreChatRooms}
          loading={loading || false}
          rootRef={modalRef}
        >
          {currentChatRooms?.map((_chatRoom) => (
            <ChatRoomsListItem
              className="border-b border-neutral-80 p-4 hover:bg-neutral-96"
              key={_chatRoom._id}
              chatRoomId={_chatRoom._id}
              customOnClick={close}
            />
          ))}
        </InfiniteScroll>
      </div>
    )
  }
)
