import React from 'react'
import { observer } from 'mobx-react-lite'
import BostadsratternaLogo from '../../../../assets/icons/bostadsratterna_text.svg'
import SamfalligheternaLogo from '../../../../assets/icons/samfalligheterna.svg'
import { ManagementPartner } from '../management_partner'

interface ManagementPartnerLogoProps {
  partner: ManagementPartner
}

export const ManagementPartnerLogo = observer(
  ({ partner }: ManagementPartnerLogoProps): JSX.Element => {
    return partner === ManagementPartner.VILLAAGARNA ? (
      <SamfalligheternaLogo height={35} />
    ) : (
      <BostadsratternaLogo />
    )
  }
)
