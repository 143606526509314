import React from 'react'
import { Icon, IconChoices } from '../Icon'
import { theme } from '../../../theme/theme'

interface IconToggleProps {
  isFirstSelected: boolean
  onChange: (value: boolean) => void
  firstIcon: IconChoices
  secondIcon: IconChoices
  name?: string
  disabled?: boolean
}

export const IconToggle = ({
  disabled,
  name,
  isFirstSelected,
  firstIcon,
  secondIcon,
  onChange,
}: IconToggleProps): JSX.Element => {
  const toggleSelected = (): void => {
    onChange(!isFirstSelected)
  }

  return (
    <label
      htmlFor={name}
      className={`flex select-none items-center ${
        disabled ? '' : 'cursor-pointer'
      } `}
    >
      <div className="relative">
        <input
          type="checkbox"
          id={name}
          readOnly
          disabled={disabled}
          className="sr-only"
          onClick={toggleSelected}
          checked={isFirstSelected}
        />
        <div
          className={`block h-10 w-[86px] rounded bg-neutral-94 ${
            disabled && 'opacity-50'
          }
          `}
        />
        <div
          className={`absolute left-2 top-1 flex items-center justify-center 
          rounded p-1 ${isFirstSelected ? 'bg-white shadow-md' : ''} `}
        >
          <Icon
            icon={firstIcon}
            color={`${isFirstSelected ? theme.colors.brandGreen : 'black'}`}
            size={24}
          />
        </div>
        <div
          className={`absolute right-2 top-1 flex items-center justify-center 
          rounded p-1 ${!isFirstSelected ? 'bg-white shadow-md' : ''}`}
        >
          <Icon
            icon={secondIcon}
            color={`${!isFirstSelected ? theme.colors.brandGreen : 'black'}`}
            size={24}
          />
        </div>
      </div>
    </label>
  )
}
