import i18next from 'i18next'
import * as yup from 'yup'

export const adminInvoiceDetailsSchema = yup
  .object({
    organisationNumber: yup.string().when('societyTypeDisplayName', {
      is: (type: string) => type !== 'neighbourhood',
      then: (schema) =>
        schema
          .required()
          .matches(
            /^\d{6}-?\d{4}$/,
            `${i18next.t('form.errors.organisationNumber')}`
          ),
    }),
    addressBillingCo: yup.string(),
    addressBillingStreet: yup.string(),
    addressBillingZip: yup.string(),
    addressBillingCity: yup.string(),
    billingEmail: yup.string().email().required(),
    billingCompany: yup.string(),
    billingRefText: yup.string(),
    lastInvoiceDate: yup.date(),
  })
  .required()
