import React from 'react'
import { observer } from 'mobx-react-lite'
import { UserAvatar } from '../../../common/Avatar'
import { theme } from '../../../../theme/theme'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useStores } from '../../../../hooks/useStores'
import { useModal } from '../../../../hooks/useModal'
import { CreateUpdateBlockedUserModal } from '../CreateUpdateBlockedUserModal'
import { Dropdown } from '../../../common/Dropdown'
import { Button, ButtonVariant } from '../../../common/Button'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { IconChoices } from '../../../common/Icon'
import { ConfirmationModal } from '../../../common/ConfirmationModal'

interface BlockedUserListItemProps {
  userId: string
}

export const BlockedUserListItem = observer(
  ({ userId }: BlockedUserListItemProps): JSX.Element | null => {
    const { userStore, societyStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const { show: showEditModal, close: closeEditModal } = useModal()
    const {
      show: showDeleteBlockConfirmationModal,
      close: closeDeleteBlockConfirmationModal,
      open: openDeleteBlockConfirmationModal,
    } = useModal()

    const user = userStore.users.get(userId)

    if (!user) {
      return null
    }

    const removeBlockedUser = async (): Promise<void> => {
      const status = await societyStore.removeBlockedUser(society._id, user._id)

      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate(
            'societyBlockedUsersView.flashMessage.userBlockRemoveSuccess'
          )
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate(
            'societyBlockedUsersView.flashMessage.userBlockRemoveFailure'
          )
        )
      }
    }

    const dropdownOptions = [
      {
        value: 'remove',
        label: translate('common.actions.remove'),
        onClick: openDeleteBlockConfirmationModal,
        destructive: true,
      },
    ]

    return (
      <div className="flex flex-row items-center border-b pb-4">
        <div className="flex flex-10 flex-row items-center">
          <UserAvatar user={user} />
          <div className="ml-4 flex flex-col justify-start">
            <p
              className="text-neutral-30"
              style={theme.textVariants.base}
              translate="no"
            >
              {user.fullName}
            </p>
          </div>
        </div>

        <Dropdown options={dropdownOptions} className="mr-3">
          <Button
            variant={ButtonVariant.TEXT}
            className="h-10 w-10 border-none bg-transparent hover:bg-neutral-90"
            icon={IconChoices.MORE}
            disableTabIndex
          />
        </Dropdown>

        <CreateUpdateBlockedUserModal
          show={showEditModal}
          close={closeEditModal}
        />
        <ConfirmationModal
          title={translate('societyBlockedUsersView.alert.deleteBlockTitle')}
          description={translate(
            'societyBlockedUsersView.alert.deleteBlockText'
          )}
          show={showDeleteBlockConfirmationModal}
          close={closeDeleteBlockConfirmationModal}
          onConfirm={removeBlockedUser}
        />
      </div>
    )
  }
)
