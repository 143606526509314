/* eslint-disable @typescript-eslint/no-namespace */
import axios, { AxiosResponse } from 'axios'

export declare namespace NStatus {
  namespace NGetMessages {
    type IResponse = {
      error_message: string
      data: {
        id: number
        sv: string
        en: string
        display_in_app: boolean
      }[]
    }
  }
}

export const getStatusMessages = (): Promise<
  AxiosResponse<NStatus.NGetMessages.IResponse>
> => {
  return axios.get<NStatus.NGetMessages.IResponse>(
    'https://status.boappa.se/api/v1/messages'
  )
}
