import React, { useState } from 'react'
import { castToSnapshot, SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import {
  PollModel,
  PollOptionModel,
  PollVotesType,
} from '../../../state/models/poll'
import { theme } from '../../../theme/theme'
import { useStores } from '../../../hooks/useStores'
import { flatten } from '../../../helpers/array'
import { Poll } from '../Poll'

interface PostPollProps {
  poll: SnapshotOut<typeof PollModel>
  disabled?: boolean
}

export const PostPoll = observer(
  ({ poll, disabled }: PostPollProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { authenticationStore, pollStore, unitStore, postStore } = useStores()
    const [selectedValue, setSelectedValue] = useState<string | null>(null)
    const [showResults, setShowResults] = useState(false)

    const userUnits = unitStore.userUnits(
      authenticationStore.userId as string,
      poll.societyId
    )

    const householdUserIds = flatten(
      userUnits.map((_unit) =>
        _unit.residentsList.map((_resident) => _resident.userId)
      )
    )?.filter((_id) => _id !== (authenticationStore.userId as string))

    const votedUserIds = poll?.votes?.map((_vote) => _vote.userId)

    const userVoted =
      (votedUserIds &&
        votedUserIds.includes(authenticationStore.userId as string)) ||
      false

    const householdMemberVoted =
      (votedUserIds &&
        householdUserIds
          .map((_householdUserIds) => votedUserIds.includes(_householdUserIds))
          .includes(true)) ||
      false

    const unvote = async (): Promise<void> => {
      setSelectedValue(null)
      await pollStore.unvote(poll._id)
      await postStore.getFeed()
      setShowResults(false)
    }

    const vote = async (
      option: SnapshotOut<typeof PollOptionModel>
    ): Promise<void> => {
      await pollStore.vote(poll._id, option._id)
      await postStore.getFeed()
    }

    const getVotesString = (
      votes: SnapshotOut<typeof PollVotesType> | undefined
    ): string => {
      if (votes) {
        return votes.length === 1
          ? `1 ${translate('poll.votesSingular')}`
          : `${votes.length} ${translate('poll.votesPlural')}`
      }

      return `0 ${translate('poll.votesPlural')}`
    }

    const userCanVote = (): boolean => {
      if (poll.type === 'per-unit') {
        if (!userUnits || userUnits.length === 0 || householdMemberVoted) {
          return false
        }
      }
      return true
    }

    const onRegretVote = (): void => {
      !disabled && unvote()
    }

    const onBackToVote = (): void => {
      !disabled && setShowResults(false)
    }

    const onShowResults = (): void => {
      !disabled && setShowResults(true)
    }

    const renderRegretVote = userCanVote() && userVoted
    const renderBackToVote = userCanVote() && !userVoted && showResults
    const renderShowResults = userCanVote() && !userVoted && !showResults
    const renderHouseAlreadyVoted = !userCanVote() && householdMemberVoted

    const voteInProgress = pollStore.voting === 'pending'

    return (
      <div className="flex flex-col gap-4 rounded-md border border-neutral-40 p-6">
        <p style={theme.textVariants.lead}>{poll.title}</p>
        <div className="flex flex-col gap-3">
          {poll.options.map((_option, i) => {
            const option = castToSnapshot(_option) as SnapshotOut<
              typeof PollOptionModel
            >
            return (
              <Poll
                key={option._id}
                option={option}
                poll={poll}
                index={i}
                userVoted={userVoted}
                householdMemberVoted={householdMemberVoted}
                showResults={showResults}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                vote={vote}
                loading={voteInProgress}
                disabled={disabled}
              />
            )
          })}
        </div>
        <div className="flex justify-between">
          <p style={theme.textVariants.base}>{getVotesString(poll.votes)}</p>
          {renderRegretVote && (
            <button onClick={onRegretVote} disabled={disabled}>
              {translate('poll.regretVote')}
            </button>
          )}
          {renderBackToVote && (
            <button onClick={onBackToVote} disabled={disabled}>
              {translate('poll.vote')}
            </button>
          )}
          {renderShowResults && (
            <button onClick={onShowResults} disabled={disabled}>
              <p style={theme.textVariants.base}>
                {translate('poll.showResults')}
              </p>
            </button>
          )}
          {renderHouseAlreadyVoted && (
            <p style={theme.textVariants.base}>
              {translate('poll.householdAlreadyVoted')}
            </p>
          )}
        </div>
      </div>
    )
  }
)
