import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { Button, ButtonVariant } from '../../common/Button'
import { FormControl } from '../../common/FormControl'
import { HorizontalFormSection } from '../../common/HorizontalFormSection'
import { IconChoices } from '../../common/Icon'
import { TextInput } from '../../common/TextInput'
import { ToastType } from '../../common/Toast/toast-type'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useFormErrorMessage } from '../../../hooks/useFormErrorMessage'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { updatePasswordSchema } from '../../../forms/schemas/update_password'

export const ChangePassword = observer((): JSX.Element => {
  const { userStore } = useStores()
  const { translate } = useAppTranslation()
  const { setToastNotification } = useToastNotifications()
  const { getErrorMessage } = useFormErrorMessage()

  const getDefaultValues = (): InferType<typeof updatePasswordSchema> => {
    return {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    }
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(updatePasswordSchema),
    defaultValues: getDefaultValues(),
  })

  const onSubmit = async (
    data: InferType<typeof updatePasswordSchema>
  ): Promise<void> => {
    const status = await userStore.updatePassword(
      data.newPassword,
      data.currentPassword
    )
    if (status) {
      setToastNotification(
        ToastType.SUCCESS,
        translate('changePassword.flashMessage.updatePasswordSuccess')
      )
      reset()
    } else {
      setToastNotification(
        ToastType.DANGER,
        translate('changePassword.flashMessage.updatePasswordFailure')
      )
    }
  }
  const onError = (): void => {
    setToastNotification(
      ToastType.DANGER,
      translate('flashMessage.somethingWentWrongError')
    )
  }

  const loading = userStore.updatingPassword === 'pending'

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <HorizontalFormSection
        title={translate('changePassword.title')}
        icon={IconChoices.PASSWORD}
        iconPlacement="left"
        borderBottom
      >
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('changePassword.labels.currentPassword')}
              error={
                errors.currentPassword &&
                getErrorMessage(errors.currentPassword)
              }
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.currentPassword}
                type="password"
              />
            </FormControl>
          )}
          name="currentPassword"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('changePassword.labels.newPassword')}
              error={errors.newPassword && getErrorMessage(errors.newPassword)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.newPassword}
                type="password"
              />
            </FormControl>
          )}
          name="newPassword"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('changePassword.labels.newPasswordConfirmation')}
              error={
                errors.newPasswordConfirmation &&
                getErrorMessage(errors.newPasswordConfirmation)
              }
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.newPasswordConfirmation}
                type="password"
              />
            </FormControl>
          )}
          name="newPasswordConfirmation"
        />
        <div className="mt-4 flex justify-end">
          <Button
            label={translate('common.actions.saveChanges')}
            type="submit"
            loading={loading}
            disabled={loading || !isValid}
            variant={ButtonVariant.PRIMARY}
          />
        </div>
      </HorizontalFormSection>
    </form>
  )
})
