import React from 'react'
import { Link } from 'react-router-dom'
import { Icon, IconChoices } from '../../components/common/Icon'
import { theme } from '../../theme/theme'

const ServiceNavigation = ({
  currentPageTitle,
  toPageTitle,
}: {
  currentPageTitle: string
  toPageTitle: string
}): React.ReactNode => {
  return (
    <div className="mb-6 grid grid-cols-3 justify-center">
      <Link className="flex gap-2" to="..">
        <Icon icon={IconChoices.ARROW_LEFT} />
        <p style={theme.textVariants.base}>{toPageTitle}</p>
      </Link>

      <p style={theme.textVariants.baseBold} className="place-self-center">
        {currentPageTitle}
      </p>
    </div>
  )
}

export { ServiceNavigation }
