import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { useAuthenticatedUserId } from '../../../../../hooks/useAuthenticatedUserId'
import { useStores } from '../../../../../hooks/useStores'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { reverseUrl } from '../../../../../navigation/reverseUrl'
import { SocietyModel } from '../../../../../state/models/society'
import { theme } from '../../../../../theme/theme'
import { Button, ButtonVariant } from '../../../../common/Button'
import { ToastType } from '../../../../common/Toast/toast-type'

interface JoinInactiveSocietyBoardProps {
  society: SnapshotOut<typeof SocietyModel>
}

export const JoinInactiveSocietyBoard = observer(
  ({ society }: JoinInactiveSocietyBoardProps): JSX.Element => {
    const { societyStore, requestStore } = useStores()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const navigate = useNavigate()
    const userId = useAuthenticatedUserId() as string

    const showErrorMessage = (message: string): void => {
      setToastNotification(ToastType.DANGER, message)
    }

    const showSuccessMessage = (message: string): void => {
      setToastNotification(ToastType.SUCCESS, message)
    }

    const navigateToSociety = (): void => {
      navigate(reverseUrl('society'))
    }

    const navigateToSocietyConfirmActivation = (): void => {
      navigate(
        reverseUrl('find-society:confirm-activation-detail', {
          id: society._id,
        }),
        { replace: true, state: { navigatingFromSocietyCreated: false } }
      )
    }

    const onJoinClick = async (): Promise<void> => {
      const status = await requestStore.sendRequest({
        societyId: society._id as string,
        userId,
      })

      if (status) {
        showSuccessMessage(
          translate('inactiveSociety.flashMessage.joinSocietySuccess')
        )
        await societyStore.getUserSocieties(userId)
        navigateToSociety()
      } else {
        showErrorMessage(
          translate('inactiveSociety.flashMessage.joinSocietyFailure')
        )
      }
    }

    const onActivateClick = async (): Promise<void> => {
      const requestStatus = await requestStore.sendRequest({
        societyId: society._id,
        userId,
      })
      if (!requestStatus) {
        showErrorMessage(
          translate('inactiveSociety.flashMessage.activateSocietyFailure')
        )
      } else {
        const activateStatus = societyStore.activateSociety(society._id)
        if (!activateStatus) {
          showErrorMessage(
            translate('inactiveSociety.flashMessage.activateSocietyFailure')
          )
        } else {
          showSuccessMessage(
            translate('inactiveSociety.flashMessage.activateSocietySuccess')
          )
          navigateToSocietyConfirmActivation()
        }
      }
    }

    return (
      <div className="mb-4 mr-4 flex flex-col">
        <p className="mt-4" style={theme.textVariants.lead}>
          {translate('inactiveSociety.board.adminTitle')}
        </p>
        <p className="max-w-96 mt-4" style={theme.textVariants.base}>
          {translate('inactiveSociety.board.adminSubtitle')}
        </p>
        <Button
          wrapperClassName="w-full"
          className="my-10 w-full"
          variant={ButtonVariant.PRIMARY}
          label={translate('common.actions.activate')}
          onClick={onActivateClick}
        />
        <div className="border-t" />
        <p className="mt-10" style={theme.textVariants.lead}>
          {translate('inactiveSociety.board.boardTitle')}
        </p>
        <p className="max-w-96 mt-4" style={theme.textVariants.base}>
          {translate('inactiveSociety.board.boardSubtitle')}
        </p>
        <Button
          wrapperClassName="w-full"
          className="mt-10 w-full"
          variant={ButtonVariant.DEFAULT}
          label={translate('common.actions.join')}
          onClick={onJoinClick}
        />
      </div>
    )
  }
)
