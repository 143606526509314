import React from 'react'

interface CardBodyProps {
  children: React.ReactNode
  className?: string
  padding?: string
}

export const CardBody = ({
  children,
  className,
  padding,
}: CardBodyProps): JSX.Element => {
  return (
    <div className={`${padding ?? 'p-4'} ${className ?? ''}`}>{children}</div>
  )
}
