import React, { FormEvent } from 'react'
import { theme } from '../../../theme/theme'
import { Checkbox } from '../../common/Checkbox'

interface PostFilterDropdownOptionProps {
  name: string
  onChange: (e: FormEvent<HTMLInputElement>) => void
  onCustomChange?: (name: string, checked: boolean) => void
  label: string
  value: NonNullable<boolean | undefined>
}

export const PostFilterDropdownOption = ({
  name,
  onChange,
  onCustomChange,
  label,
  value,
}: PostFilterDropdownOptionProps): JSX.Element => {
  return (
    <>
      <label
        htmlFor={name}
        className="flex h-full w-full cursor-pointer items-center py-4 px-5 hover:bg-neutral-96"
      >
        <div className="flex items-center gap-3">
          <Checkbox
            name={name}
            value={value}
            onChange={(val: FormEvent<HTMLInputElement>) => {
              onChange(val)
              onCustomChange && onCustomChange(name, val.currentTarget.checked)
            }}
          />
          <p style={theme.textVariants.base}>{label}</p>
        </div>
      </label>
    </>
  )
}
