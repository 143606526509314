import { useStores } from '../../hooks/useStores'

interface HasFetchedChatRoomsType {
  hasFetchedOneOnOneAndInterestsChatRoomsOnce: boolean
  hasUserFetchedChatRooms: boolean
}

export const useHasUserFetchedChatRooms = (): HasFetchedChatRoomsType => {
  const { chatRoomStore } = useStores()
  const {
    hasFetchedUserRoomsOneOnOneOnce,
    hasFetchedUserRoomsInterestsOnce,
    hasFetchedUserRoomsQuestionsResidentOnce,
    hasFetchedUserRoomsBoardOnce,
  } = chatRoomStore

  const hasFetchedOneOnOneAndInterestsChatRoomsOnce =
    hasFetchedUserRoomsOneOnOneOnce && hasFetchedUserRoomsInterestsOnce

  const hasUserFetchedChatRooms =
    hasFetchedOneOnOneAndInterestsChatRoomsOnce &&
    hasFetchedUserRoomsQuestionsResidentOnce &&
    hasFetchedUserRoomsBoardOnce

  return {
    hasFetchedOneOnOneAndInterestsChatRoomsOnce,
    hasUserFetchedChatRooms,
  }
}
