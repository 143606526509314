export enum OccupationIndustry {
  MARKETING = 'sales+marketing+ads',
  ADMIN_OFFICE_MANAGEMENT = 'admin+office_mgmt',
  HEALTHCARE = 'healthcare',
  CUSTOMER_SUPPORT = 'customer_support',
  HUMAN_RESOURCES_LEGAL = 'human_resources+legal',
  SERVICE_RETAIL_TOURISM = 'service+retail+tourism',
  FINANCE_BANKING_INSURANCE = 'finance+banking+insurance',
  EDUCATION = 'education',
  INDUSTRY_PRODUCTION = 'industry+production',
  IT_DATA = 'it+data',
  ENGINEERING = 'engineering',
  LOGISTICS_PURCHASING = 'logistics+purchasing',
  MANAGEMENT = 'management',
  ENVIRONMENT = 'environment',
  CONSTRUCTION = 'construction',
  CULTURE_MEDIA = 'culture+media',
  OTHER = 'other',
  NONE = 'none',
}
