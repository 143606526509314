import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkBreaks from 'remark-breaks'
import rehypeExternalLinks from 'rehype-external-links'
import { observer } from 'mobx-react-lite'
import { isEmptyString } from '../../../../helpers/string'

interface PostTextProps {
  message: string
  isAutomaticallyCreatedPost: boolean
}

export const PostText = observer(
  ({
    message,
    isAutomaticallyCreatedPost,
  }: PostTextProps): JSX.Element | null => {
    let parsedMessage = message.replace(/&nbsp;-/gi, '-')
    parsedMessage = parsedMessage.replace(/&nbsp;##/gi, '##')

    return (
      <>
        {!isAutomaticallyCreatedPost && !isEmptyString(parsedMessage) && (
          // Secure by default and sanitizes html
          <ReactMarkdown
            className="prose"
            remarkPlugins={[remarkGfm, remarkBreaks]}
            rehypePlugins={[[rehypeExternalLinks, { target: 'blank' }]]}
            components={{
              p: ({ ...props }) => <p className="break-words" {...props} />,
            }}
          >
            {parsedMessage}
          </ReactMarkdown>
        )}
      </>
    )
  }
)
