import * as yup from 'yup'

const notificationSettingSchema = yup.object({
  mobile: yup.boolean(),
  email: yup.boolean(),
})

export const userNotificationsSchema = yup
  .object({
    masterSwitch: notificationSettingSchema,
    general: yup.object({
      membershipRequests: notificationSettingSchema,
    }),
    feed: yup.object({
      postFromAdmins: notificationSettingSchema,
      commentOnAdminPosts: notificationSettingSchema,
      postFromNeighbours: notificationSettingSchema,
      commentOnNeighbourPosts: notificationSettingSchema,
    }),
    chat: yup.object({
      neighbours: notificationSettingSchema,
      groups: notificationSettingSchema,
      board: notificationSettingSchema,
      questions: notificationSettingSchema,
    }),
  })
  .required()
