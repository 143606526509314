/* eslint-disable max-len */
const _BASE_ROUTE = ''
const _LOGIN_ROUTE = '/login'
const _SIGNUP_ROUTE = '/signup'
const _RESET_PASSWORD_ROUTE = '/reset-password'
const _VERIFY_EMAIL = '/verify-email'
const _FEED_BASE = `${_BASE_ROUTE}/feed`
const _CHAT_BASE = `${_BASE_ROUTE}/chat`
const _CHAT_DETAIL = `${_CHAT_BASE}/:id`
const _USER_BASE = `${_BASE_ROUTE}/user`
const _USER_DETAIL = `${_USER_BASE}/:id`
const _USER_SOCIETY_DETAIL = `${_USER_DETAIL}/society/:societyId`
const _ADMIN_BASE = `${_BASE_ROUTE}/admin`
const _SETTINGS_BASE = `${_BASE_ROUTE}/settings`
const _POST_DETAIL = `${_BASE_ROUTE}/post/:id`
const _FIND_SOCIETY_BASE = `${_BASE_ROUTE}/find-society`
const _ACTIVATED_SOCIETY_CONFIRMATION_BASE = `${_BASE_ROUTE}/activated-society`
const _CREATE_SOCIETY_BASE = `${_BASE_ROUTE}/create-society`
const _SOCIETY_BASE = `${_BASE_ROUTE}/society`
const _SOCIETY_BASE_ROUTE = `${_SOCIETY_BASE}/:id`
const _SOCIETY_RESIDENT_BASE_ROUTE = `${_SOCIETY_BASE_ROUTE}/resident`
const _SOCIETY_MANAGEMENT_BASE_ROUTE = `${_SOCIETY_BASE_ROUTE}/management`
const _SOCIETY_MANAGEMENT_BOARD_BASE_ROUTE = `${_SOCIETY_MANAGEMENT_BASE_ROUTE}/board`
const _SOCIETY_MANAGEMENT_ADMIN_BASE_ROUTE = `${_SOCIETY_MANAGEMENT_BASE_ROUTE}/admin`
const _SOCIETY_MANAGEMENT_BOSTADSRATTERNA_BASE_ROUTE = `${_SOCIETY_MANAGEMENT_BASE_ROUTE}/bostadsratterna`
const _SOCIETY_MANAGEMENT_SAMFALLIGHETERNA_BASE_ROUTE = `${_SOCIETY_MANAGEMENT_BASE_ROUTE}/samfalligheterna`
const _SOCIETY_RESIDENT_NEIGHBORHOOD_BASE_ROUTE = `${_SOCIETY_RESIDENT_BASE_ROUTE}/neighborhood`
const _SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE = `${_SOCIETY_RESIDENT_BASE_ROUTE}/functions`
const _SOCIETY_RESIDENT_FACILITIES_BASE_ROUTE = `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/facilities`

export type ViewNames =
  | 'index'
  | 'login'
  | 'signup'
  | 'reset-password'
  | 'verify-email'
  | 'feed'
  | 'chat'
  | 'chat:detail'
  | 'admin'
  | 'admin:users'
  | 'admin:societies'
  | 'admin:discount-codes'
  | 'admin:pending-invoices'
  | 'admin:twilight-zone'
  | 'admin:audit-logs'
  | 'admin:data-export'
  | 'admin:webinars'
  | 'settings'
  | 'settings-account'
  | 'settings-my-info'
  | 'settings-language'
  | 'settings-notifications'
  | 'settings-superadmin'
  | 'post:detail'
  | 'find-society'
  | 'find-society:confirm-activation-detail'
  | 'find-society:confirm-activation-units-detail'
  | 'activated-society'
  | 'create-society'
  | 'create-society:pick-type'
  | 'user:detail'
  | 'user:society-detail'
  | 'society'
  | 'society:detail'
  | 'society:home'
  | 'society:marketplace'
  | 'society:marketplace-detail'
  | 'society:onboarding'
  | 'society:find-friends'
  | 'society:resident-list'
  | 'society:board'
  | 'society:contacts'
  | 'society:calendar'
  | 'society:calendar-detail'
  | 'society:calendar-detail-information'
  | 'society:calendar-detail-attendants'
  | 'society:facilities'
  | 'society:facilities-create'
  | 'society:facilities-update'
  | 'society:facilities-detail'
  | 'society:facilities-detail-calendar'
  | 'society:facilities-detail-information'
  | 'society:facilities-detail-bookings'
  | 'society:faq'
  | 'society:house-rules'
  | 'society:documents'
  | 'society:documents-detail'
  | 'society:widgets-detail'
  | 'society:local-offers'
  | 'society:local-offers-detail'
  | 'society:services'
  | 'society:services-service'
  | 'society:services-service-product'
  | 'management:member-guidance'
  | 'management:register-guidance'
  | 'society:report-problem'
  | 'management:documents'
  | 'management:documents-detail'
  | 'management:contacts'
  | 'management:statistics-exports'
  | 'management:information'
  | 'management:contracts'
  | 'management:contracts-detail'
  | 'management:calendar'
  | 'management:calendar-detail'
  | 'management:calendar-detail-information'
  | 'management:calendar-detail-attendants'
  | 'management:gdpr'
  | 'management:billing'
  | 'management:billing-payment'
  | 'management:billing-details'
  | 'management:register'
  | 'management:register-entrance'
  | 'management:register-unit'
  | 'management:register-member'
  | 'management:register-nonmember'
  | 'management:register-group'
  | 'management:admin-rights'
  | 'management:website'
  | 'management:blocked-users'
  | 'management:settings'
  | 'management:function-settings'
  | 'management:bostadsratterna-guidance'
  | 'management:bostadsratterna-school'
  | 'management:bostadsratterna-forms'
  | 'management:bostadsratterna-statutes'
  | 'management:bostadsratterna-benefits'
  | 'management:samfalligheterna-guidance'
  | 'management:samfalligheterna-school'
  | 'management:samfalligheterna-benefits'

const pathRouteMap: { [key in ViewNames]: string } = {
  index: _BASE_ROUTE,
  login: _LOGIN_ROUTE,
  signup: _SIGNUP_ROUTE,
  'reset-password': _RESET_PASSWORD_ROUTE,
  'verify-email': _VERIFY_EMAIL,
  feed: _FEED_BASE,
  chat: _CHAT_BASE,
  'chat:detail': _CHAT_DETAIL,
  admin: _ADMIN_BASE,
  'admin:users': `${_ADMIN_BASE}/users`,
  'admin:societies': `${_ADMIN_BASE}/societies`,
  'admin:discount-codes': `${_ADMIN_BASE}/discount-codes`,
  'admin:pending-invoices': `${_ADMIN_BASE}/pending-invoices`,
  'admin:audit-logs': `${_ADMIN_BASE}/audit-logs`,
  'admin:twilight-zone': `${_ADMIN_BASE}/twilight-zone`,
  'admin:data-export': `${_ADMIN_BASE}/data-export`,
  'admin:webinars': `${_ADMIN_BASE}/webinars`,
  settings: _SETTINGS_BASE,
  'settings-account': `${_SETTINGS_BASE}/account`,
  'settings-my-info': `${_SETTINGS_BASE}/my-info`,
  'settings-language': `${_SETTINGS_BASE}/language`,
  'settings-notifications': `${_SETTINGS_BASE}/notifications`,
  'settings-superadmin': `${_SETTINGS_BASE}/superadmin`,

  'post:detail': _POST_DETAIL,

  'user:detail': _USER_DETAIL,
  'user:society-detail': _USER_SOCIETY_DETAIL,

  'find-society': _FIND_SOCIETY_BASE,
  'find-society:confirm-activation-detail': `${_FIND_SOCIETY_BASE}/confirm-activation/:id`,
  'find-society:confirm-activation-units-detail': `${_FIND_SOCIETY_BASE}/confirm-activation-units/:id`,
  'create-society': _CREATE_SOCIETY_BASE,
  'create-society:pick-type': `${_CREATE_SOCIETY_BASE}/pick-type`,
  'activated-society': _ACTIVATED_SOCIETY_CONFIRMATION_BASE,

  society: _SOCIETY_BASE,
  'society:detail': _SOCIETY_BASE_ROUTE,
  'society:home': `${_SOCIETY_BASE_ROUTE}/home`,
  'society:onboarding': `${_SOCIETY_BASE_ROUTE}/onboarding`,
  // Society resident routes
  'society:marketplace': `${_SOCIETY_RESIDENT_NEIGHBORHOOD_BASE_ROUTE}/marketplace`,
  'society:marketplace-detail': `${_SOCIETY_RESIDENT_NEIGHBORHOOD_BASE_ROUTE}/marketplace/:resourceId`,
  'society:find-friends': `${_SOCIETY_RESIDENT_NEIGHBORHOOD_BASE_ROUTE}/find-friends`,
  'society:resident-list': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/resident-list`,
  'society:board': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/board`,
  'society:contacts': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/contacts`,
  'society:calendar': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/calendar`,
  'society:calendar-detail': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/calendar/:eventId`,
  'society:calendar-detail-information': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/calendar/:eventId/information`,
  'society:calendar-detail-attendants': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/calendar/:eventId/attendants`,
  'society:facilities': _SOCIETY_RESIDENT_FACILITIES_BASE_ROUTE,
  'society:facilities-create': `${_SOCIETY_RESIDENT_FACILITIES_BASE_ROUTE}/create`,
  'society:facilities-detail': `${_SOCIETY_RESIDENT_FACILITIES_BASE_ROUTE}/:facilityId`,
  'society:facilities-detail-calendar': `${_SOCIETY_RESIDENT_FACILITIES_BASE_ROUTE}/:facilityId/calendar`,
  'society:facilities-detail-information': `${_SOCIETY_RESIDENT_FACILITIES_BASE_ROUTE}/:facilityId/information`,
  'society:facilities-detail-bookings': `${_SOCIETY_RESIDENT_FACILITIES_BASE_ROUTE}/:facilityId/bookings`,
  'society:facilities-update': `${_SOCIETY_RESIDENT_FACILITIES_BASE_ROUTE}/:facilityId/update`,
  'society:faq': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/questions-and-answers`,
  'society:house-rules': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/house-rules`,
  'society:documents': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/documents`,
  'society:documents-detail': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/documents/:documentId`,
  'society:widgets-detail': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/widgets/:widgetId`,
  'society:local-offers': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/local-offers`,
  'society:local-offers-detail': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/local-offers/:offerId`,
  'society:report-problem': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/report-problem`,
  'society:services': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/services`,
  'society:services-service': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/services/:service`,
  'society:services-service-product': `${_SOCIETY_RESIDENT_FUNCTIONS_BASE_ROUTE}/services/:service/:product`,

  // Management routes outside management portal
  'management:function-settings': `${_SOCIETY_MANAGEMENT_BASE_ROUTE}/function-settings`,

  // Callout
  'management:member-guidance': `${_SOCIETY_MANAGEMENT_BASE_ROUTE}/member-guidance`,
  'management:register-guidance': `${_SOCIETY_MANAGEMENT_BASE_ROUTE}/register-guidance`,

  // Management board work area
  'management:documents': `${_SOCIETY_MANAGEMENT_BOARD_BASE_ROUTE}/documents`,
  'management:documents-detail': `${_SOCIETY_MANAGEMENT_BOARD_BASE_ROUTE}/documents/:documentId`,
  'management:contacts': `${_SOCIETY_MANAGEMENT_BOARD_BASE_ROUTE}/contacts`,
  'management:calendar': `${_SOCIETY_MANAGEMENT_BOARD_BASE_ROUTE}/calendar`,
  'management:calendar-detail': `${_SOCIETY_MANAGEMENT_BOARD_BASE_ROUTE}/calendar/:eventId`,
  'management:calendar-detail-information': `${_SOCIETY_MANAGEMENT_BOARD_BASE_ROUTE}/calendar/:eventId/information`,
  'management:calendar-detail-attendants': `${_SOCIETY_MANAGEMENT_BOARD_BASE_ROUTE}/calendar/:eventId/attendants`,
  'management:statistics-exports': `${_SOCIETY_MANAGEMENT_BOARD_BASE_ROUTE}/statistics-exports`,
  'management:information': `${_SOCIETY_MANAGEMENT_BOARD_BASE_ROUTE}/information`,
  'management:contracts': `${_SOCIETY_MANAGEMENT_BOARD_BASE_ROUTE}/contracts`,
  'management:contracts-detail': `${_SOCIETY_MANAGEMENT_BOARD_BASE_ROUTE}/contracts/:contractId`,
  'management:gdpr': `${_SOCIETY_MANAGEMENT_BOARD_BASE_ROUTE}/gdpr`,

  // Management admin area
  'management:billing': `${_SOCIETY_MANAGEMENT_ADMIN_BASE_ROUTE}/billing`,
  'management:billing-payment': `${_SOCIETY_MANAGEMENT_ADMIN_BASE_ROUTE}/billing/payment`,
  'management:billing-details': `${_SOCIETY_MANAGEMENT_ADMIN_BASE_ROUTE}/billing/details`,
  'management:register': `${_SOCIETY_MANAGEMENT_ADMIN_BASE_ROUTE}/register`,
  'management:register-entrance': `${_SOCIETY_MANAGEMENT_ADMIN_BASE_ROUTE}/register/entrance-list`,
  'management:register-unit': `${_SOCIETY_MANAGEMENT_ADMIN_BASE_ROUTE}/register/unit-list`,
  'management:register-member': `${_SOCIETY_MANAGEMENT_ADMIN_BASE_ROUTE}/register/member-list`,
  'management:register-nonmember': `${_SOCIETY_MANAGEMENT_ADMIN_BASE_ROUTE}/register/non-member-list`,
  'management:register-group': `${_SOCIETY_MANAGEMENT_ADMIN_BASE_ROUTE}/register/group-list`,
  'management:admin-rights': `${_SOCIETY_MANAGEMENT_ADMIN_BASE_ROUTE}/admin-rights`,
  'management:website': `${_SOCIETY_MANAGEMENT_ADMIN_BASE_ROUTE}/website`,
  'management:blocked-users': `${_SOCIETY_MANAGEMENT_ADMIN_BASE_ROUTE}/blocked-users`,
  'management:settings': `${_SOCIETY_MANAGEMENT_ADMIN_BASE_ROUTE}/settings`,

  // Management bostadsratterna area
  'management:bostadsratterna-guidance': `${_SOCIETY_MANAGEMENT_BOSTADSRATTERNA_BASE_ROUTE}/guidance`,
  'management:bostadsratterna-school': `${_SOCIETY_MANAGEMENT_BOSTADSRATTERNA_BASE_ROUTE}/school`,
  'management:bostadsratterna-forms': `${_SOCIETY_MANAGEMENT_BOSTADSRATTERNA_BASE_ROUTE}/forms`,
  'management:bostadsratterna-statutes': `${_SOCIETY_MANAGEMENT_BOSTADSRATTERNA_BASE_ROUTE}/statutes`,
  'management:bostadsratterna-benefits': `${_SOCIETY_MANAGEMENT_BOSTADSRATTERNA_BASE_ROUTE}/benefits`,

  // Management samfalligheterna area
  'management:samfalligheterna-guidance': `${_SOCIETY_MANAGEMENT_SAMFALLIGHETERNA_BASE_ROUTE}/guidance`,
  'management:samfalligheterna-school': `${_SOCIETY_MANAGEMENT_SAMFALLIGHETERNA_BASE_ROUTE}/school`,
  'management:samfalligheterna-benefits': `${_SOCIETY_MANAGEMENT_SAMFALLIGHETERNA_BASE_ROUTE}/benefits`,
}

export const processUrl = (
  path: string,
  kwargs?: { [key: string]: string | undefined }
): string => {
  if (!kwargs) {
    return path
  }

  let _processedPath: string = path
  Object.keys(kwargs).forEach((_key) => {
    const _value = kwargs[_key]
    if (_value) {
      _processedPath = _processedPath.replace(`:${_key}`, _value)
    }
  })

  return _processedPath
}

export const reverseUrl = (
  viewName: ViewNames,
  kwargs?: { [key: string]: string | undefined },
  queryParams?: { [key: string]: string }
): string => {
  if (!(viewName in pathRouteMap)) {
    return '/not-found'
  }

  const processedUrl = processUrl(pathRouteMap[viewName], kwargs)

  if (queryParams && Object.keys(queryParams).length > 0) {
    const urlSearchParams = new URLSearchParams(queryParams).toString()
    return `${processedUrl}?${urlSearchParams}`
  }

  return processedUrl
}
