import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useNavigate, Link } from 'react-router-dom'
import { UserAvatar } from '../../../common/Avatar'
import { theme } from '../../../../theme/theme'
import { BoardMemberModel } from '../../../../state/models/society'
import { useStores } from '../../../../hooks/useStores'
import {
  isUserAdminInSociety,
  translateRole,
} from '../../../../helpers/society'
import { IconChoices } from '../../../common/Icon'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { Dropdown } from '../../../common/Dropdown'
import { useModal } from '../../../../hooks/useModal'
import { CreateUpdateBoardMemberModal } from '../CreateUpdateBoardMemberModal'
import { Button, ButtonVariant } from '../../../common/Button'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { ConfirmationModal } from '../../../common/ConfirmationModal'
import { UserModel } from '../../../../state/models/user'

interface BoardListItemProps {
  boardMember: SnapshotOut<typeof BoardMemberModel>
}

export const BoardListItem = observer(
  ({ boardMember }: BoardListItemProps): JSX.Element => {
    const { authenticationStore, userStore, societyStore } = useStores()
    const navigate = useNavigate()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const {
      show: showEditModal,
      open: openEditModal,
      close: closeEditModal,
    } = useModal()
    const {
      show: showDeleteConfirmationModal,
      close: closeDeleteConfirmationModal,
      open: openDeleteConfirmationModal,
    } = useModal()

    const user = userStore.users.get(boardMember.userId) as
      | SnapshotOut<typeof UserModel>
      | undefined

    const isAdmin = isUserAdminInSociety(
      society,
      authenticationStore.userId as string
    )

    const deleteBoardMember = async (): Promise<void> => {
      const status = await societyStore.deleteUserRole([
        {
          societyId: society._id,
          userId: boardMember.userId,
          boardRole: true,
          nominatingCommitteeRole: true,
        },
      ])
      if (status) {
        await societyStore.getSociety(society?._id as string)

        setToastNotification(
          ToastType.SUCCESS,
          translate('boardListView.flashMessage.deleteBoardMemberSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('boardListView.flashMessage.deleteContactFailure')
        )
      }
    }

    const dropdownOptions = [
      {
        value: 'edit',
        label: translate('boardListItem.dropdown.editRole'),
        onClick: () => openEditModal(),
      },
      {
        value: 'remove',
        label: translate('common.actions.remove'),
        onClick: openDeleteConfirmationModal,
        destructive: true,
      },
    ]

    const getUserRoute = (): string => {
      return reverseUrl('user:society-detail', {
        id: boardMember.userId,
        societyId: society?._id,
      })
    }

    const userOnClick = (): void => {
      return navigate(getUserRoute())
    }

    return (
      <>
        <div className="flex flex-row items-center rounded-sm border-b py-5 px-4">
          <div className="flex flex-10 flex-row items-center">
            <UserAvatar user={user} onClick={userOnClick} />
            <div className="ml-4 flex flex-col justify-start">
              <p style={theme.textVariants.baseBold}>
                {translateRole(boardMember.role)}
              </p>
              <Link
                className="hover:underline"
                to={getUserRoute()}
                style={theme.textVariants.baseBold}
              >
                <p
                  className="text-gray-600"
                  style={theme.textVariants.base}
                  translate="no"
                >
                  {user?.fullName}
                </p>
              </Link>
            </div>
          </div>
          {isAdmin && (
            <Dropdown options={dropdownOptions}>
              <Button
                variant={ButtonVariant.TEXT}
                size="xs"
                icon={IconChoices.MORE}
                className="h-10 w-10 bg-transparent hover:bg-neutral-90"
                disableTabIndex
              />
            </Dropdown>
          )}
        </div>
        <ConfirmationModal
          title={translate('boardListView.alert.deleteBoardMemberTitle')}
          description={translate('boardListView.alert.deleteBoardMemberText')}
          show={showDeleteConfirmationModal}
          close={closeDeleteConfirmationModal}
          onConfirm={deleteBoardMember}
        />
        <CreateUpdateBoardMemberModal
          show={showEditModal}
          close={closeEditModal}
          userId={boardMember.userId}
        />
      </>
    )
  }
)
