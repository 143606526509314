import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../../hooks/useStores'
import { SpinnerWrapper } from '../../../../components/common/SpinnerWrapper'
import { ButtonVariant } from '../../../../components/common/Button'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useModal } from '../../../../hooks/useModal'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { ViewBase } from '../../../../components/common/ViewBase'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { IllustrationChoices } from '../../../../components/common/Illustration'
import { BlockedUsersList } from '../../../../components/society/BlockedUsersList'
// eslint-disable-next-line max-len
import { CreateUpdateBlockedUserModal } from '../../../../components/society/BlockedUsersList/CreateUpdateBlockedUserModal'

export const SocietyBlockedUsersView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { societyStore } = useStores()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:blocked-users', {
      '{{ societyName }}': society.name,
    })
  )

  const {
    show: showCreateModal,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModal()

  const blockedUsers = society.blockList ? society.blockList : []

  const loading =
    societyStore.updatingAdminUsers === 'pending' ||
    societyStore.updatingMasterAdmin === 'pending'

  if (societyStore.fetchingSocieties === 'none') {
    return <SpinnerWrapper />
  }

  const errorView =
    !loading && blockedUsers.length === 0
      ? {
          title: translate('societyBlockedUsersView.emptyState.title'),
          subtitle: translate('societyBlockedUsersView.emptyState.subtitle'),
          illustration: IllustrationChoices.EMPTY,
        }
      : undefined

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('societyBlockedUsersView.title')}
        subtitle={translate('societyBlockedUsersView.subtitle')}
        buttons={[
          {
            variant: ButtonVariant.PRIMARY,
            onClick: openCreateModal,
            label: translate('common.actions.add'),
          },
        ]}
        errorView={errorView}
      >
        {loading ? (
          <SpinnerWrapper />
        ) : (
          <BlockedUsersList users={blockedUsers} />
        )}
      </ViewBase>
      <CreateUpdateBlockedUserModal
        show={showCreateModal}
        close={closeCreateModal}
      />
    </ErrorBoundary>
  )
})
