import { AxiosResponse } from 'axios'
import { NMedia } from '../interfaces/services/media.interfaces'
import { createUrl } from './helpers'
import axiosAuth from './config'

export const getMedia = (
  id: string
): Promise<AxiosResponse<NMedia.NGetById.IResponse>> => {
  return axiosAuth.get<NMedia.NGetById.IResponse>(
    createUrl(`/media/media/${id}`)
  )
}

// *** Below function is not needed currently, but in case Joel adds more granular permissions in the future
// and we need to call via e.g. our axiosAuih and this is how we could do it with returning a blob and then
// saving it in the component ***
// export const getDownloadMediaViaProxy = (id: string): any => {
//   return axios.get(createUrl(`/media/fetch/${id}`), {
//     responseType: 'blob',
//   })
// }

// *** Use in component ***
//   getDownloadMediaViaProxy(media._id as string)
//     .then((res: any) => res.data)
//     .then((blob: any) => {
//       saveAs(blob, media._id.slice(0, 7) as string)
//     })

export const createMedia = (
  mediaType: 'image' | 'video',
  data: string
): Promise<AxiosResponse<NMedia.NCreate.IResponse>> => {
  const body: NMedia.NCreate.IRequestBody = {
    cat: mediaType,
    data,
  }
  return axiosAuth.post<NMedia.NCreate.IResponse>(
    createUrl('/media/media'),
    body
  )
}

export const deleteMedia = (
  id: string
): Promise<AxiosResponse<NMedia.NDel.IResponse>> => {
  return axiosAuth.delete<NMedia.NDel.IResponse>(
    createUrl(`/media/media/${id}`)
  )
}
