import { SnapshotOut } from 'mobx-state-tree'
import { sortStringsAlphabetically } from '../../../../helpers/sorting'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useStores } from '../../../../hooks/useStores'
import { UserModel } from '../../../../state/models/user'

export const useSortedAdminsList = (): {
  user: SnapshotOut<typeof UserModel>
  role: string
}[] => {
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const { userStore } = useStores()
  const { translate } = useAppTranslation()

  const sortedAdminsList = (): {
    user: SnapshotOut<typeof UserModel>
    role: string
  }[] => {
    if (!society || !society.adminsList) {
      return []
    }
    const nonMasterAdmins = society?.adminsList
      .map(
        (item) =>
          userStore.users.get(item.userId) as
            | SnapshotOut<typeof UserModel>
            | undefined
      )
      .filter((user) => user?._id !== society?.masterAdminUserId)
      .sort((a, b) =>
        sortStringsAlphabetically(a?.fullName, b?.fullName)
      ) as unknown as SnapshotOut<typeof UserModel>[]

    const masterAdminUser = society.masterAdminUserId
      ? userStore.users.get(society.masterAdminUserId)
      : undefined

    return [
      ...(masterAdminUser
        ? [
            {
              user: masterAdminUser,
              role: translate('common.user.masterAdmin'),
            },
          ]
        : []),
      ...nonMasterAdmins.map((user) => ({
        user,
        role: translate('common.user.admin'),
      })),
    ]
  }

  return sortedAdminsList()
}
