import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../common/Button'
import { TextInput } from '../../../common/TextInput'
import { FormControl } from '../../../common/FormControl'
import { SocietyModel } from '../../../../state/models/society'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { translateRole } from '../../../../helpers/society'
import { BoardRole } from '../../../../types/board-roles'
import { useStores } from '../../../../hooks/useStores'
import { IconChoices } from '../../../common/Icon'
import { SelectDropdown } from '../../../common/SelectDropdown'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { societyConfirmActivationSchema } from '../../../../forms/schemas/society_confirm_activation'
import { HorizontalFormSection } from '../../../common/HorizontalFormSection'

interface FindSocietyConfirmActivationFormProps {
  society: SnapshotOut<typeof SocietyModel>
  navigatingFromSocietyCreated?: boolean
}

export const FindSocietyConfirmActivationForm = observer(
  ({
    society,
    navigatingFromSocietyCreated,
  }: FindSocietyConfirmActivationFormProps): JSX.Element => {
    const { societyStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()
    const { getErrorMessage } = useFormErrorMessage()
    const navigate = useNavigate()
    const userId = useAuthenticatedUserId() as string
    const boardRoleOptions = Object.values(BoardRole).map((val) => ({
      value: val as string,
      label: translateRole(val) as string | '',
    }))

    const getDefaultValues = (): InferType<
      typeof societyConfirmActivationSchema
    > => {
      return {
        role: BoardRole.PRESIDENT,
        boardMembers: [],
      }
    }

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyConfirmActivationSchema),
      defaultValues: getDefaultValues(),
    })

    const { fields, append, remove } = useFieldArray({
      name: 'boardMembers',
      control,
    })

    const showErrorMessage = (message: string): void => {
      setToastNotification(ToastType.DANGER, message)
    }

    const showSuccessMessage = (message: string): void => {
      setToastNotification(ToastType.SUCCESS, message)
    }

    const navigateToSociety = (): void => {
      navigate(reverseUrl('society'), { replace: true })
    }

    const navigateToActivatedSocietyConfirmation = (): void => {
      navigate(reverseUrl('activated-society'), { replace: true })
    }

    const onSubmit = async (
      data: InferType<typeof societyConfirmActivationSchema>
    ): Promise<void> => {
      const status = await societyStore.createUserRole([
        {
          societyId: society._id,
          userId,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          boardRole: data.role,
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...(data.boardMembers
          ? data.boardMembers.map((_boardMember) => ({
              societyId: society._id,
              userEmail: _boardMember.email as string,
              boardRole: BoardRole.MEMBER,
              unitRole: 'member',
            }))
          : []),
      ])
      // CreateUserRole does not send an email for some reason.
      // Force send of invite emails
      societyStore.inviteAllPreApprovedUsers(society._id)

      if (status) {
        showSuccessMessage(
          data.boardMembers && data.boardMembers.length > 0
            ? translate(
                'findSocietyConfirmActivationForm.flashMessage.invitesSentSuccess'
              )
            : translate('flashMessage.changesSaved')
        )
        if (navigatingFromSocietyCreated) {
          navigateToSociety()
        } else {
          navigateToActivatedSocietyConfirmation()
        }
      } else {
        showErrorMessage(translate('flashMessage.somethingWentWrongError'))
      }
    }

    const onError = (): void => {
      showErrorMessage(translate('flashMessage.somethingWentWrongError'))
    }

    const addNewEmail = (): void => {
      append({ email: '' }, { shouldFocus: true })
    }

    const loading = societyStore.creatingUserRole === 'pending'

    return (
      <form
        className="flex w-full flex-col space-y-4 md:space-y-10"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <HorizontalFormSection
          title={translate('findSocietyConfirmActivationForm.roleTitle')}
        >
          <Controller
            control={control}
            name="role"
            render={({ field: { value, name, onChange } }) => (
              <FormControl
                error={errors.role && getErrorMessage(errors.role)}
                name={name}
              >
                <SelectDropdown
                  options={boardRoleOptions}
                  value={value}
                  onChange={onChange}
                  error={!!errors.role}
                />
              </FormControl>
            )}
          />
        </HorizontalFormSection>
        <div className="my-10 w-full border-t md:w-4/6" />
        <HorizontalFormSection
          title={translate(
            'findSocietyConfirmActivationForm.inviteBoardMembersTitle'
          )}
        >
          <Button
            icon={IconChoices.PLUS_SIGN}
            label={translate('common.button.addMore')}
            onClick={addNewEmail}
          />
        </HorizontalFormSection>
        <div className="w-full md:w-4/6">
          {fields.map((field, index) => (
            <div
              key={field.id}
              className="flex w-full flex-row items-center space-x-4"
            >
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    className="w-full"
                    error={
                      errors.boardMembers?.[index]?.email
                        ? translate('form.errors.required')
                        : ''
                    }
                    name={`boardMembers.${index}.email`}
                  >
                    <TextInput
                      className="w-full"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.boardMembers?.[index]?.email}
                      placeholder={translate(
                        'findSocietyConfirmActivationForm.enterEmail'
                      )}
                      onDeleteIconPress={() => remove(index)}
                      type="email"
                    />
                  </FormControl>
                )}
                name={`boardMembers.${index}.email`}
              />
            </div>
          ))}
        </div>
        <div className="flex w-full flex-col justify-end md:w-4/6 md:flex-row">
          <Button
            wrapperClassName="w-full md:w-1/6"
            className="w-full"
            variant={ButtonVariant.PRIMARY}
            type="submit"
            label={translate('common.button.save')}
            loading={loading}
            disabled={!isValid || loading}
          />
        </div>
      </form>
    )
  }
)
