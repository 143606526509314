import React from 'react'
import { observer } from 'mobx-react-lite'
import { Card } from '../../common/Card'
import { Icon, IconChoices } from '../../common/Icon'
import { theme } from '../../../theme/theme'

interface RegisterMemberBadgeCardBaseProps {
  count: number
  label: string
  onClick: () => void
}

export const RegisterMemberBadgeCardBase = observer(
  ({
    count,
    label,
    onClick,
  }: RegisterMemberBadgeCardBaseProps): JSX.Element | null => {
    return (
      <Card className="w-full hover:bg-neutral-96 md:max-w-xs">
        {{
          body: (
            <button
              className="flex items-center justify-between p-4"
              onClick={onClick}
            >
              <div className="mr-4 flex items-center">
                <div
                  className="mr-4 flex items-center justify-center rounded-full bg-brandGreen"
                  style={{ height: 40, width: 40 }}
                >
                  <span
                    className="text-white"
                    style={theme.textVariants.baseBold}
                  >
                    {count > 9 ? '9+' : count}
                  </span>
                </div>
                <p style={theme.textVariants.baseBold}>{label}</p>
              </div>
              <Icon icon={IconChoices.ARROW} />
            </button>
          ),
        }}
      </Card>
    )
  }
)
