import React from 'react'

interface CardFooterProps {
  children: React.ReactNode
  className?: string
}

export const CardFooter = ({
  children,
  className,
}: CardFooterProps): JSX.Element => {
  return <div className={`${className ?? ''}`}>{children}</div>
}
