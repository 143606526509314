import { useCallback, useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../hooks/useStores'
import { ChatRoomModel } from '../../../state/models/chat-room'
import { ChatHeaderState, chatHeaderStateAtom } from '../atom'

type TabFetchingStatus = 'done' | 'none' | 'pending' | 'error'

interface FindArchivedChatsDataReturnType {
  currentChatRooms: SnapshotOut<typeof ChatRoomModel>[]
  loadMoreChatRooms: () => void
  showSpinner: boolean | null
}

export const useFindArchivedChatsData = (): FindArchivedChatsDataReturnType => {
  const { authenticationStore, chatRoomStore } = useStores()
  const [showSpinner, setShowSpinner] = useState<boolean | null>(true)
  const userId = authenticationStore.userId as string
  const [chatHeaderState] = useAtom(chatHeaderStateAtom)

  const isCurrentTabStatus = useCallback(
    (status: TabFetchingStatus): boolean => {
      return chatRoomStore.fetchingChatRoomsQuestions === status
    },
    [chatRoomStore]
  )

  const getListData = (): SnapshotOut<typeof ChatRoomModel>[] => {
    const isResident =
      chatHeaderState === ChatHeaderState.FindArchivedChatsResident
    return chatRoomStore.archivedQuestionsChatRooms(userId, isResident)
  }

  const getUserRoomsData = useCallback(
    async (fetchNextPage: boolean): Promise<void> => {
      if (isCurrentTabStatus('pending')) {
        return
      }
      await chatRoomStore.getUserRoomsQuestions(fetchNextPage, true)
    },
    [chatRoomStore, isCurrentTabStatus]
  )

  const loadMoreChatRooms = (): void => {
    !isCurrentTabStatus('pending') && getUserRoomsData(true)
  }

  useEffect(() => {
    getUserRoomsData(false)
  }, [getUserRoomsData])

  const currentChatRooms = getListData()

  const dataFetchDone = isCurrentTabStatus('done')

  useEffect(() => {
    setShowSpinner(!dataFetchDone)
  }, [currentChatRooms, dataFetchDone])

  return {
    loadMoreChatRooms,
    currentChatRooms,
    showSpinner,
  }
}
