/* eslint-disable no-param-reassign */
import { SnapshotOut, types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'
import { ResidentRoleType } from './resident'

const BoardRole = types.enumeration('Role', [
  'president',
  'vicepresident',
  'treasury',
  'member',
  'secretary',
  'alternate',
  'advisor',
  'nominating-committee',
])

export const SocietyTypeDisplayNameRole = types.enumeration(
  'societyTypeDisplayName',
  [
    'housing_cooperative',
    'housing_cooperative_construction',
    'homeowners_association',
    'non_profit_association',
    'economic_association',
    'neighbourhood',
    'apartment_building',
    'boat_club',
    'shared',
  ]
)

export const HiddenWidgetRole = types.enumeration('HiddenWidget', [
  'board',
  'contacts',
  'calendar',
  'documents',
  'faqs',
  'facilities',
  'rules',
  'sales',
  'services',
  'reportProblem',
  'stays',
  'local-offers',
  'marketplace',
  'find-friends',
])

export const BoardMemberModel = types.model({
  userId: types.string,
  role: BoardRole,
})

export const ActivatedPartnerModel = types.model({
  partnerId: types.string,
  // Misspelled in API
  boardFolderSructureCreated: types.maybe(types.boolean),
  societyFolderSructureCreated: types.maybe(types.boolean),
})

const ReminderStatus = types.enumeration('PreApprovedReminderStatus', [
  'notSent',
  'sending',
  'sent',
])

export const PreApprovedModel = types
  .model({
    _id: types.identifier,
    unitId: types.maybe(types.string),
    unitTitle: types.maybe(types.string),
    userEmail: types.string,
    societyId: types.string,
    role: ResidentRoleType,
    reminderStatus: ReminderStatus,
  })
  .actions((self) => ({
    setReminderStatus(status: SnapshotOut<typeof ReminderStatus>) {
      self.reminderStatus = status
    },
  }))

export const SocietyModel = types
  .model('Society', {
    _id: types.identifier,
    ...timestampTypes,
    name: types.string,
    accountType: types.maybe(
      types.enumeration('SocietyAccountType', ['basic', 'premium'])
    ),
    accountTypeChangeDate: types.maybeNull(types.string),
    lastInvoiceDate: types.maybeNull(types.string),
    organisationNumber: types.maybe(types.string),
    masterAdminUserId: types.maybeNull(types.string),
    adminsList: types.maybe(
      types.array(
        types.model({
          userId: types.string,
        })
      )
    ),
    preApprovedList: types.maybe(
      types.array(types.reference(PreApprovedModel))
    ),
    societyTypeDisplayName: types.maybe(SocietyTypeDisplayNameRole),
    boardMembersList: types.maybe(types.array(BoardMemberModel)),
    nominatingCommitteeList: types.maybe(
      types.array(
        types.model({
          userId: types.string,
        })
      )
    ),
    coverPhotoId: types.maybeNull(types.string),
    addressCo: types.maybeNull(types.string),
    addressStreet: types.maybe(types.string),
    addressZip: types.maybe(types.string),
    addressCity: types.maybeNull(types.string),
    billingOrgNo: types.maybe(types.string),
    billingRefText: types.maybeNull(types.string),
    billingCompany: types.maybe(types.string),
    billingEmail: types.maybeNull(types.string),
    billingUnits: types.maybe(types.maybeNull(types.integer)),
    reportProblemEmailCC: types.maybe(types.array(types.string)),
    addressBillingCo: types.maybeNull(types.string),
    addressBillingStreet: types.maybe(types.string),
    addressBillingZip: types.maybe(types.string),
    addressBillingCity: types.maybe(types.string),
    addressBillingMunicipality: types.maybe(types.string),
    addressBillingCounty: types.maybe(types.string),
    residentsCanPost: types.maybe(types.boolean),
    residentPostingDisabledByAdmin: types.maybe(types.boolean),
    settings: types.maybe(
      types.model({
        widgetsDisabled: types.maybe(types.array(types.string)),
        residentPostingDisabled: types.maybe(types.boolean),
        chatBoardDisabled: types.maybe(types.boolean),
        chatOneOnOneDisabled: types.maybe(types.boolean),
        chatInterestsDisabled: types.maybe(types.boolean),
        chatQuestionsDisabled: types.maybe(types.boolean),
        chatAskBoardDisabled: types.maybe(types.boolean),
        chatAskBoardDisabledResidentMessage: types.maybe(types.string),
      })
    ),
    activatedPartnerData: types.maybe(types.array(ActivatedPartnerModel)),
    hiddenSocietyWidgets: types.maybe(types.array(HiddenWidgetRole)),
    blockList: types.maybe(types.array(types.model({ userId: types.string }))),
    member: types.boolean,
    trialPeriodEndDate: types.maybe(types.string),
    locked: types.maybe(types.boolean),
  })
  .views((self) => ({
    get premiumCost(): number {
      switch (self.societyTypeDisplayName) {
        case 'homeowners_association':
        case 'economic_association':
        case 'non_profit_association':
          return 2000
        case 'housing_cooperative':
          if (!self.billingUnits) {
            return 2000
          }

          if (self.billingUnits <= 50) {
            return 2000
          }
          if (self.billingUnits <= 100) {
            return 4000
          }
          return 6000
        default:
          return 2000
      }
    },
    get isSamf(): boolean | undefined {
      if (!self.societyTypeDisplayName) {
        return undefined
      }
      if (
        [
          'homeowners_association',
          'non_profit_association',
          'economic_association',
        ].includes(self.societyTypeDisplayName)
      ) {
        return true
      }
      return false
    },
    get lastSentInvoiceDate(): Date | undefined {
      const { lastInvoiceDate: lastInvoiceDateString } = self
      if (!lastInvoiceDateString) {
        return undefined
      }

      const lastInvoiceDate = new Date(lastInvoiceDateString)
      // In some cases lastInvoiceDate gets set to 2000-01-01 on the backend to force a new invoice
      const defaultPlaceholderDate = new Date('2000-01-01')

      if (
        lastInvoiceDate.getFullYear() ===
          defaultPlaceholderDate.getFullYear() &&
        lastInvoiceDate.getMonth() === defaultPlaceholderDate.getMonth() &&
        lastInvoiceDate.getDate() === defaultPlaceholderDate.getDate()
      ) {
        return undefined
      }

      return lastInvoiceDate
    },
    get activeTrial(): { active: boolean; daysLeft: number | undefined } {
      if (
        self.accountType === 'premium' ||
        (self.societyTypeDisplayName &&
          ![
            'housing_cooperative',
            'homeowners_association',
            'economic_association',
          ].includes(self.societyTypeDisplayName)) ||
        !self.trialPeriodEndDate
      ) {
        return { active: false, daysLeft: undefined }
      }

      const timeDiff =
        new Date(self.trialPeriodEndDate).getTime() - new Date().getTime()

      const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24))

      return { active: daysLeft > 0, daysLeft }
    },
  }))
  .actions((self) => ({
    removePreApprovedUser(id: string): void {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      self.preApprovedList = self.preApprovedList?.filter(
        (preApproved) => preApproved?._id !== id
      )
    },
    preApprovedForUnit(
      unitId: string
    ): SnapshotOut<typeof PreApprovedModel>[] | undefined {
      return self.preApprovedList?.filter(
        (_preApproved) => _preApproved?.unitId === unitId
      )
    },
  }))

export const SearchSocietyModel = types.model('Society', {
  _id: types.identifier,
  name: types.string,
  addressCity: types.maybeNull(types.string),
  status: types.maybe(
    // TODO: pending is not present in bo-interfaces but exist on objects
    types.enumeration('SocietyStatus', ['inactive', 'active', 'pending'])
  ),
  coverPhotoId: types.maybe(types.string),
  societyTypeDisplayName: types.maybe(SocietyTypeDisplayNameRole),
})
