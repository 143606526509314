import React from 'react'
import i18n from '../../../i18n/i18n'
import { Dropdown, Option } from '../../common/Dropdown'
import { Button } from '../../common/Button'
import { LocalStorageKeys } from '../../../types/local-storage'

export interface LanguagePicker {
  className?: string
}

export const LanguagePicker = ({ className }: LanguagePicker): JSX.Element => {
  const swedish = {
    label: 'Svenska 🇸🇪',
    value: 'sv-SE',
  }
  const english = {
    label: 'English 🇬🇧',
    value: 'en-EN',
  }

  const changeLanguage = (locale: string): void => {
    localStorage.setItem(LocalStorageKeys.LOCALE, locale)
    i18n.changeLanguage(locale)
    document.documentElement.lang = locale.includes('sv') ? 'sv' : 'en'
  }

  const languageOptions: Option[] = [
    { ...swedish, onClick: () => changeLanguage(swedish.value) },
    { ...english, onClick: () => changeLanguage(english.value) },
  ]

  const selectedLanguage = i18n.language.includes('sv') ? swedish : english

  return (
    <div className={className ?? ''}>
      <Dropdown options={languageOptions}>
        <Button label={selectedLanguage.label} value={selectedLanguage.value} />
      </Dropdown>
    </div>
  )
}
