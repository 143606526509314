import { ConnectDropTarget, useDrop } from 'react-dnd'
import { ItemType } from '../item-type'
import { useStores } from '../../../../hooks/useStores'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'

interface DocumentDroppable {
  isOver: boolean
  drop: ConnectDropTarget
}

export function useDocumentDroppable(folderId: string): DocumentDroppable {
  const { documentStore } = useStores()
  const { translate } = useAppTranslation()
  const { setToastNotification } = useToastNotifications()

  const showErrorMessage = (): void => {
    setToastNotification(
      ToastType.DANGER,
      translate('flashMessage.somethingWentWrongError')
    )
  }

  const updateDocument = async (documentId: string): Promise<void> => {
    const id = documentId
    const updatedDocument = { parentId: folderId }
    const status =
      id && (await documentStore.patchDocument(id, updatedDocument))
    if (!status) {
      showErrorMessage()
    }
  }

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [ItemType.FOLDER, ItemType.DOCUMENT],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (monitor) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const draggableId = monitor._id
      if (draggableId !== folderId) {
        updateDocument(draggableId)
      }
    },
  }))

  return { isOver, drop }
}
