import React from 'react'
import { observer } from 'mobx-react-lite'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { theme } from '../../../../theme/theme'

interface UserPresentationProps {
  name: string | null | undefined
  presentation: string | null | undefined
}

export const UserPresentation = observer(
  ({ name, presentation }: UserPresentationProps) => {
    const { translate } = useAppTranslation()

    if (!presentation) {
      return null
    }

    return (
      <div className="flex flex-col space-y-3">
        <div className="flex items-center gap-3">
          <p className="text-neutral-30" style={theme.textVariants.caption}>
            {translate('userView.presentationTitle', { name })}
          </p>
        </div>
        {presentation && <p style={theme.textVariants.base}>{presentation}</p>}
      </div>
    )
  }
)
