import React, { useState } from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { MediaModel } from '../../../../state/models/media'
import { Image } from '../../../common/Image'
import { useModal } from '../../../../hooks/useModal'
import { MediaGalleryModal } from '../../../common/MediaGalleryModal'

interface ChatMessageImagesProps {
  media: SnapshotOut<typeof MediaModel>[] | undefined
  messageAuthorIsCurrentUser: boolean
}

export const ChatMessageImages = ({
  media,
  messageAuthorIsCurrentUser,
}: ChatMessageImagesProps): JSX.Element => {
  const {
    show: showMediaGalleryModal,
    open: openMediaGalleryModal,
    close: closeMediaGalleryModal,
  } = useModal()
  const [mediaGalleryIndex, setMediaGalleryIndex] = useState<number>(0)
  const mediaIds = media?.map((_media) => _media._id)

  const openModal = (i: number): void => {
    setMediaGalleryIndex(i)
    openMediaGalleryModal()
  }

  const getMediaWidth = (): string => {
    switch (media?.length) {
      case 1:
        return 'flex-1/1'
      case 2:
        return 'flex-1/2'
      default:
        return 'flex-1/3'
    }
  }

  return (
    <>
      {media && media.length > 0 && (
        <div
          className={`-mr-1 flex max-w-xl flex-col
          ${messageAuthorIsCurrentUser ? 'self-end' : ''}
        `}
        >
          <div className="flex w-full flex-wrap justify-end">
            {media.map((_media, i) => (
              <div key={_media._id} className={getMediaWidth()}>
                <Image
                  url={_media.url}
                  imageClassName="rounded-2xl p-1 cursor-pointer"
                  onClick={() => openModal(i)}
                />
                {_media._id && (
                  <MediaGalleryModal
                    show={showMediaGalleryModal}
                    close={closeMediaGalleryModal}
                    mediaIds={[_media._id]}
                  />
                )}
              </div>
            ))}
            {mediaIds && mediaIds.length > 0 && (
              <MediaGalleryModal
                show={showMediaGalleryModal}
                close={closeMediaGalleryModal}
                mediaIds={mediaIds}
                mediaGalleryIndex={mediaGalleryIndex}
                setMediaGalleryIndex={setMediaGalleryIndex}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}
