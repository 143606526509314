import { ReportProblemSpace } from '../../types/report-problem-space'
import { translateEnumDict } from './helpers'

const spaceTranslations: { [key: string]: string } = {
  [ReportProblemSpace.APARTMENT]: 'reportProblemSpace.apartment',
  [ReportProblemSpace.BUILDING]: 'reportProblemSpace.building',
  [ReportProblemSpace.SURROUNDINGS]: 'reportProblemSpace.surroundings',
  [ReportProblemSpace.COMMON_AREAS]: 'reportProblemSpace.commonAreas',
  [ReportProblemSpace.OTHER]: 'reportProblemSpace.other',
}

export const getReportProblemSpace = (
  space: ReportProblemSpace
): string | null => {
  return translateEnumDict(spaceTranslations, space)
}
