import React from 'react'
import { getDateDay, getDateTime } from '../../../helpers/date'
import { capitalize } from '../../../helpers/string'
import { theme } from '../../../theme/theme'

interface PostDateTimeProps {
  createdAt: string
}

export const PostDateTime = ({ createdAt }: PostDateTimeProps): JSX.Element => {
  return (
    <div className="flex flex-col items-center">
      <p style={theme.textVariants.base} className="mb-1">
        {capitalize(getDateDay(Date.parse(createdAt)))}
      </p>
      <p style={theme.textVariants.base} className="m-0">
        {getDateTime(Date.parse(createdAt))}
      </p>
    </div>
  )
}
