import React, { useEffect, useState } from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { captureException } from '@sentry/react'
import { v4 as uuid } from 'uuid'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { NotificationModel } from '../../../state/models/notification'
import { mapAsync } from '../../../helpers/array'
import { NotificationData } from '../../../types/notifications'
import { removeMarkdown, replaceHtml } from '../../../helpers/string'
import { useNotificationHelpers } from './useNotificationHelpers'

interface useNotificationMessageProps {
  notification: SnapshotOut<typeof NotificationModel>
  notificationData: NotificationData | undefined
}

interface ReturnType {
  message: (string | JSX.Element)[]
  renderError: boolean
  notificationMessageLoading: boolean
}

export const useNotificationMessage = ({
  notification,
  notificationData,
}: useNotificationMessageProps): ReturnType => {
  const { societyStore, userStore } = useStores()
  const { translate } = useAppTranslation()
  const { getTranslationKey } = useNotificationHelpers({
    notification,
    notificationData,
  })

  const [message, setMessage] = useState<(string | JSX.Element)[]>([])
  const [renderError, setRenderError] = useState(false)
  const [notificationMessageLoading, setNotificationMessageLoading] =
    useState(true)

  const getBoldText = (_string: string): JSX.Element => {
    return (
      <span key={uuid()} style={{ fontWeight: 'bold' }}>
        {_string}
      </span>
    )
  }

  const getSocietyText = async (): Promise<JSX.Element> => {
    if (notificationData?.society) {
      const { name } = notificationData.society
      return getBoldText(name)
    }
    if (notification.societyId) {
      const society = societyStore.societies.get(notification.societyId)
      if (society) {
        const { name } = society
        if (name) {
          return getBoldText(name)
        }
      }
    }

    return <></>
  }

  const getUserText = async (): Promise<JSX.Element> => {
    if (notificationData?.from) {
      const { name, surname } = notificationData.from
      return getBoldText(`${name} ${surname}`)
    }

    if (notification.authorId) {
      const user = userStore.users.get(notification.authorId)
      if (user) {
        const { name, surname } = user
        if (name && surname) {
          return getBoldText(`${name} ${surname}`)
        }
      }
    }

    setRenderError(true)
    return <></>
  }

  const getMessageValue = async (
    key: string,
    _notificationData: NotificationData
  ): Promise<JSX.Element> => {
    switch (key) {
      case '{% userName %}':
        return getUserText()
      case '{% societyName %}':
        return getSocietyText()
      case '{% roomName %}': {
        return getBoldText(_notificationData.room.name)
      }
      case '{% contractTitle %}': {
        return getBoldText(_notificationData.contract.title)
      }
      case '{% message %}': {
        return (
          <span key={uuid()}>
            {removeMarkdown(replaceHtml(_notificationData.post.message))}
          </span>
        )
      }
      case '{% comment %}': {
        return (
          <span key={uuid()}>
            {removeMarkdown(replaceHtml(_notificationData.comment.message))}
          </span>
        )
      }
      default:
        return <span key={uuid()}>{key}</span>
    }
  }

  useEffect(() => {
    const processAndSetNotificationMessage = async (): Promise<void> => {
      if (!notificationData) {
        setRenderError(true)
        return
      }

      try {
        const test = await getTranslationKey(notification.type)
        const notificationMessage = translate(test)
        const splitMessage = notificationMessage.split(/({% [A-Za-z]+ %})/)
        const _message = await mapAsync(splitMessage, async (_string) => {
          try {
            return getMessageValue(_string, notificationData)
          } catch (error) {
            captureException(error, {
              extra: { notification, notificationData, notificationMessage },
            })
            setRenderError(true)
            return <></>
          }
        })

        setMessage(_message)
      } catch (error) {
        captureException(error, {
          extra: { notification, notificationData },
        })
        setRenderError(true)
      }
      setNotificationMessageLoading(false)
    }
    processAndSetNotificationMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    message,
    renderError,
    notificationMessageLoading,
  }
}
