import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { reportPostSchema } from '../../../../forms/schemas/report_post'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { Button, ButtonVariant } from '../../../common/Button'
import { FormControl } from '../../../common/FormControl'
import { TextArea } from '../../../common/TextArea'

interface ReportPostModalContentProps {
  onError: (error: unknown) => void
  onSubmit: (data: InferType<typeof reportPostSchema>) => Promise<void>
  onClose: () => void
  loading: boolean
}

export const ReportPostModalContent = ({
  onError,
  onSubmit,
  onClose,
  loading,
}: ReportPostModalContentProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const { getErrorMessage } = useFormErrorMessage()

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(reportPostSchema),
    defaultValues: {
      message: '',
    },
  })

  return (
    <form
      className="flex h-full flex-col gap-6"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Controller
        control={control}
        name="message"
        render={({ field: { value, name, onChange, onBlur } }) => (
          <FormControl
            label={translate('reportPost.form.labels.message')}
            name={name}
            error={errors.message && getErrorMessage(errors.message)}
          >
            <TextArea
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!errors.message}
            />
          </FormControl>
        )}
      />
      <div className="flex flex-wrap justify-end gap-4">
        <Button label={translate('common.actions.cancel')} onClick={onClose} />
        <Button
          variant={ButtonVariant.PRIMARY}
          loading={loading}
          disabled={!isValid || loading}
          label={translate('reportPost.buttons.submitTitle')}
          type="submit"
        />
      </div>
    </form>
  )
}
