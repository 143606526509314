/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, getRoot, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import {
  getRequestsPerSociety as apiGetRequestsPerSociety,
  getRequestsPerUser as apiGetRequestsPerUser,
  getRequest as apiGetRequest,
  denyRequest as apiDenyRequest,
  acceptUsers as apiAcceptUsers,
  createRequest as apiCreateRequest,
} from '../../api/requests'
import { stateType } from '../types/common'
import { RootStore } from './root'
import { setObject } from './helpers'
import { RequestModel } from '../models/request'
import { IRequestModel } from '../../interfaces/models/requests.interfaces'
import { NRequests } from '../../interfaces/services/requests.interfaces'
import { PreApprovedModel } from '../models/society'
import { ResidentUserType } from './units'

export const RequestStore = types
  .model('RequestStore')
  .props({
    requests: types.map(RequestModel),
    fetchingRequests: stateType,
    fetchingRequest: stateType,
    denyingRequest: stateType,
    acceptingRequest: stateType,
    acceptingPreApproved: stateType,
    sendingRequest: stateType,
    hasFetchedRequestsOnce: types.boolean,
  })
  .views((self) => ({
    requestsForSociety(
      societyId: string,
      typeFilter?: ResidentUserType
    ): SnapshotOut<typeof RequestModel>[] {
      let requests = // @ts-ignore
        (values(self.requests) as SnapshotOut<typeof RequestModel>[]).filter(
          (_request) => _request.societyId === societyId
        )

      if (typeFilter === ResidentUserType.MEMBER) {
        requests = requests.filter(
          (_request) =>
            _request.role === 'member' || _request.role === 'resident'
        )
      }
      if (typeFilter === ResidentUserType.NON_MEMBER) {
        requests = requests.filter(
          (_request) =>
            _request.role !== 'member' && _request.role !== 'resident'
        )
      }

      return requests
    },
  }))
  .views((self) => ({
    userPendingSocietyRequests(userId: string): string[] {
      // @ts-ignore
      return (
        // @ts-ignore
        (values(self.requests) as SnapshotOut<typeof RequestModel>[])
          // @ts-ignore
          .filter((_request) => _request.userId === userId)
          .map((_request) => _request.societyId)
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.requests.clear()
      self.fetchingRequests = 'none'
      self.fetchingRequest = 'none'
      self.denyingRequest = 'none'
      self.acceptingRequest = 'none'
      self.sendingRequest = 'none'
      self.hasFetchedRequestsOnce = false
    },
    setRequests: (requests: IRequestModel[]) => {
      requests.forEach((_request) => {
        // @ts-ignore
        setObject<typeof RequestModel>(self.requests, RequestModel, {
          ..._request,
          user: _request.userId,
        })
      })
    },
    resetFetchingRequestState: () => {
      self.fetchingRequest = 'done'
    },
    setHasFetchedRequestsOnce: (val: boolean) => {
      self.hasFetchedRequestsOnce = val
    },
  }))
  .actions((self) => ({
    getRequestsPerSocieties: flow(function* getRequestsPerSocieties(
      societyIds: string[]
    ) {
      self.fetchingRequests = 'pending'
      try {
        const resp = yield apiGetRequestsPerSociety(societyIds)
        const data = resp.data as NRequests.NGetPerSocieties.IResponse
        const requests = data.data
        const { users } = data.populated

        const { userStore } = getRoot<RootStore>(self)
        if (users) {
          userStore.setUsers(users)
        }

        self.setRequests(requests)
        self.setHasFetchedRequestsOnce(true)

        self.fetchingRequests = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.fetchingRequests = 'error'
        return false
      }
    }),
    getRequestsPerUser: flow(function* getRequestsPerUser(userId: string) {
      self.fetchingRequests = 'pending'
      try {
        const resp = yield apiGetRequestsPerUser(userId)
        const data = resp.data as NRequests.NGetPerUser.IResponse
        const requests = data.data

        const currentUserRequestIds = // @ts-ignore
          (values(self.requests) as SnapshotOut<typeof RequestModel>[])
            // @ts-ignore
            .filter((_request) => _request.userId === userId)
            .map((_request) => _request._id)
        const updatedUserRequestIds = requests.map((_request) => _request._id)
        currentUserRequestIds.forEach((_id) => {
          if (!updatedUserRequestIds.includes(_id)) {
            self.requests.delete(_id)
          }
        })

        self.setRequests(requests)

        self.fetchingRequests = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.fetchingRequests = 'error'
        return false
      }
    }),
    getRequest: flow(function* getRequest(id: string) {
      self.fetchingRequest = 'pending'
      try {
        const resp = yield apiGetRequest(id)
        const data = resp.data as NRequests.NGetById.IResponse
        const request = data.data
        const { users } = data.populated

        const { userStore } = getRoot<RootStore>(self)
        if (users) {
          userStore.setUsers(users)
        }

        self.setRequests([request])

        self.fetchingRequest = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.fetchingRequest = 'error'
        return false
      }
    }),
    denyRequest: flow(function* denyRequest(id: string) {
      self.denyingRequest = 'pending'
      try {
        yield apiDenyRequest(id)
        self.requests.delete(id)
        self.denyingRequest = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.denyingRequest = 'error'
        return false
      }
    }),
    acceptRequest: flow(function* acceptRequest(
      id: string,
      unitRole: string,
      entranceId: string,
      unitId: string
    ) {
      self.acceptingRequest = 'pending'
      try {
        // @ts-ignore
        yield apiAcceptUsers({ data: [{ id, unitRole, entranceId, unitId }] })

        self.requests.delete(id)

        self.acceptingRequest = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.acceptingRequest = 'error'
        return false
      }
    }),
    acceptPreApproved: flow(function* acceptPreApproved(
      preApproved: SnapshotOut<typeof PreApprovedModel>,
      userId: string
    ) {
      self.acceptingPreApproved = 'pending'
      try {
        yield apiCreateRequest({
          userId,
          societyId: preApproved.societyId,
          unitId: preApproved.unitId,
          // @ts-ignore
          role: preApproved.role,
          message: 'preapproved',
        })

        const { societyStore } = getRoot<RootStore>(self)
        societyStore.removePreApproved(preApproved._id)

        self.acceptingPreApproved = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.acceptingPreApproved = 'error'
        return false
      }
    }),
    sendRequest: flow(function* sendRequest(
      body: NRequests.NCreate.IRequestBody
    ) {
      self.sendingRequest = 'pending'
      try {
        const resp = yield apiCreateRequest(body)
        const data = resp.data as NRequests.NCreate.IResponse
        const request = data.data

        if (request) {
          self.setRequests([request])
        }

        self.sendingRequest = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.sendingRequest = 'error'
        return false
      }
    }),
  }))
