import React from 'react'
import { InferType } from 'yup'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../hooks/useStores'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { IconChoices } from '../../common/Icon'
import { DropdownSelect } from '../../common/DropdownSelect'
import { societyCreatePostSchema } from '../../../forms/schemas/society_create_post'
import { DropdownItemContent } from '../DropdownItemContent'
import { FormControl } from '../../common/FormControl'
import { useIsMobile } from '../../../hooks/useIsMobile'

interface VisibilityDropdownProps {
  setValue: UseFormSetValue<InferType<typeof societyCreatePostSchema>>
  control: Control<InferType<typeof societyCreatePostSchema>>
  watchRole: string
  watchSociety: string
  watchGroupsIds: (string | undefined)[] | undefined
  watchAccessAdminMember: boolean | undefined
  watchAccessBoardMember: boolean | undefined
}

type visibilityOption = {
  label: string
  value: string
  icon: IconChoices
}

export const VisibilityDropdown = observer(
  ({
    setValue,
    control,
    watchRole,
    watchSociety,
    watchGroupsIds,
    watchAccessAdminMember,
    watchAccessBoardMember,
  }: VisibilityDropdownProps): JSX.Element => {
    const isMobile = useIsMobile()
    const { groupStore } = useStores()
    const { translate } = useAppTranslation()
    const groups = groupStore.groupsForSociety(watchSociety)

    const onCustomChange = (value: unknown): void => {
      if (typeof value === 'string') {
        if (value === 'all') {
          setValue('groupsIds', [])
          setValue('accessBoardMember', false)
          setValue('accessAdminMember', false)
        } else if (value === 'board') {
          setValue('accessBoardMember', !watchAccessBoardMember)
        } else if (value === 'admins') {
          setValue('accessAdminMember', !watchAccessAdminMember)
        } else if (watchGroupsIds?.includes(value)) {
          setValue(
            'groupsIds',
            watchGroupsIds?.filter((_id) => _id !== value)
          )
        } else {
          setValue('groupsIds', [...(watchGroupsIds || []), value])
        }
      }
    }

    const getVisibilityString = (): string | undefined => {
      if (
        watchGroupsIds?.length === 0 &&
        !watchAccessAdminMember &&
        !watchAccessBoardMember
      ) {
        return translate('postCreateUpdateBase.visibilitySheet.all')
      }

      const adminBoardVisiblityStrings = []

      if (watchAccessAdminMember) {
        adminBoardVisiblityStrings.push(
          translate('postCreateUpdateBase.visibilitySheet.admin')
        )
      }

      if (watchAccessBoardMember) {
        adminBoardVisiblityStrings.push(
          translate('postCreateUpdateBase.visibilitySheet.board')
        )
      }

      return [
        ...adminBoardVisiblityStrings,
        ...(watchGroupsIds
          ?.map((_id) => groupStore.groups.get(_id as string)?.name)
          .filter((_string) => _string !== undefined) as string[]),
      ].join(', ')
    }

    const createVisibilityOption = (
      label: string,
      value: string
    ): visibilityOption | null => {
      return {
        label,
        value,
        icon: IconChoices.GROUPS,
      }
    }

    const options = [
      createVisibilityOption(
        translate('postCreateUpdateBase.visibilitySheet.all'),
        'all'
      ),
      createVisibilityOption(
        translate('postCreateUpdateBase.visibilitySheet.board'),
        'board'
      ),
      createVisibilityOption(
        translate('postCreateUpdateBase.visibilitySheet.admin'),
        'admins'
      ),
      ...groups.map((group) => createVisibilityOption(group.name, group._id)),
    ]

    const optionsWithoutNullableValues = options.filter(
      (value): value is visibilityOption => !!value
    )

    const dropdownOptions = optionsWithoutNullableValues.map((option) => ({
      value: option.value,
      label: option.label,
      icon: option.icon,
      showCheckmark:
        (option.value === 'all' &&
          watchGroupsIds?.length === 0 &&
          !watchAccessBoardMember &&
          !watchAccessAdminMember) ||
        (watchAccessBoardMember && option.value === 'board') ||
        (watchAccessAdminMember && option.value === 'admins') ||
        (watchGroupsIds && watchGroupsIds.includes(option.value)),
    }))

    const shouldRender = watchRole !== 'resident'

    return (
      <>
        {shouldRender && (
          <Controller
            control={control}
            name="role"
            render={({ field: { name, value } }) => (
              <FormControl
                name={name}
                label={translate('common.form.labels.to')}
              >
                <DropdownSelect
                  customLabel={getVisibilityString()}
                  value={value}
                  options={dropdownOptions}
                  onChange={(val: unknown) => {
                    onCustomChange(val)
                  }}
                  dropdownWidth={isMobile ? 'w-full' : 'w-60'}
                  renderItemContent={(option) => (
                    <DropdownItemContent option={option} />
                  )}
                />
              </FormControl>
            )}
          />
        )}
      </>
    )
  }
)
