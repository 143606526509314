import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../hooks/useStores'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { QuestionsAndAnswersSection } from './QuestionsAndAnswersSection'

export const QuestionsAndAnswers = observer((): JSX.Element => {
  const { faqsStore } = useStores()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const data = faqsStore.faqSectionsForSociety(society._id)

  return (
    <div className="w-full">
      {data.map((item) => (
        <QuestionsAndAnswersSection
          key={item._id}
          id={item._id}
          title={item.section}
        />
      ))}
    </div>
  )
})
