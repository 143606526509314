import React, { useEffect } from 'react'
import { captureException } from '@sentry/react'
import { InferType } from 'yup'
import { observer } from 'mobx-react-lite'
import { Modal } from '../../../../../components/common/Modal'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { ModalHeader } from '../../../../../components/common/Modal/ModalHeader'
import { ModalBody } from '../../../../../components/common/Modal/ModalBody'
import { ResetPasswordModalContent } from './ResetPasswordModalContent'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../../components/common/Toast/toast-type'
import { adminResetPasswordSchema } from '../../../../../forms/schemas/admin_reset_password'
import { useStores } from '../../../../../hooks/useStores'

interface ResetPasswordModalProps {
  show: boolean
  close: () => void
  userId?: string
}

export const ResetPasswordModal = observer(
  ({ show, close, userId }: ResetPasswordModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { adminStore, userStore } = useStores()
    const { setToastNotification } = useToastNotifications()

    const user = userId ? userStore.users.get(userId) : undefined

    const onSubmit = async (
      data: InferType<typeof adminResetPasswordSchema>
    ): Promise<void> => {
      const status =
        user &&
        (await adminStore.changeUserPassword({
          userId: user._id,
          userEmail: user.email,
          newPassword: data.password,
        }))
      if (status) {
        close()
        setToastNotification(ToastType.SUCCESS, 'Uppdaterade lösenordet')
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    useEffect(() => {
      if (userId) {
        userStore.getUser(userId)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Modal show={show}>
        {{
          header: <ModalHeader onClose={close}>Ändra lösenord</ModalHeader>,
          body: (
            <ModalBody className="max-h-96 overflow-y-auto">
              <ResetPasswordModalContent
                onError={onError}
                onSubmit={onSubmit}
                onClose={close}
              />
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
