import { ProcessedDocument } from '../types/document-upload'

export enum DocumentActions {
  ADD = 'add',
  REMOVE = 'remove',
  SET_UPLOADED = 'setUploaded',
  SET_DESCRIPTION = 'setDescription',
}

interface DocumentActionBase {
  document: ProcessedDocument
}
interface AddDocumentAction extends DocumentActionBase {
  type: DocumentActions.ADD
}

interface RemoveDocumentAction {
  type: DocumentActions.REMOVE
  id: string
}

interface SetUploadedDocumentAction extends DocumentActionBase {
  type: DocumentActions.SET_UPLOADED
  id: string
}

export type DocumentUploadActions =
  | AddDocumentAction
  | RemoveDocumentAction
  | SetUploadedDocumentAction
