import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../hooks/useStores'
import { EventModel } from '../../../state/models/event'
import { PostEvent } from '../PostEvent/PostEvent'
import { Divider } from '../../common/Divider'

interface PostEventsProps {
  eventsIds: string[]
  disabled?: boolean
}

export const PostEvents = observer(
  ({ eventsIds, disabled }: PostEventsProps): JSX.Element | null => {
    const { eventStore } = useStores()

    const events = eventsIds
      .map((_id): SnapshotOut<typeof EventModel> | undefined =>
        eventStore.events.get(_id)
      )
      .filter((value): value is SnapshotOut<typeof EventModel> => !!value)

    if (events.length === 0) {
      return null
    }

    return (
      <>
        <Divider />
        {events.map((event) => (
          <div key={event._id} className="flex flex-col">
            <div className="my-0 border-b border-neutral-80 first:hidden" />
            <PostEvent key={event._id} event={event} disabled={disabled} />
          </div>
        ))}
      </>
    )
  }
)
