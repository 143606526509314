import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { theme } from '../../../theme/theme'
import { Button, ButtonProps } from '../Button'
import { TabBar } from '../TabBar'
import { SearchBar } from '../SearchBar'
import { SpinnerWrapper } from '../SpinnerWrapper'
import { ErrorView } from '../../../views/error/ErrorView'
import { BackButton } from '../BackButton'
import { useAppTranslation } from '../../../hooks/useAppTranslation'

interface ErrorViewWrapper {
  children: ReactNode
  childrenClassName?: string
  loading?: boolean
  errorView?: ErrorView
  noSearchResultErrorView?: ErrorView
}

const ErrorViewWrapper = ({
  children,
  childrenClassName,
  loading,
  errorView,
  noSearchResultErrorView,
}: ErrorViewWrapper): JSX.Element => {
  if (loading) return <SpinnerWrapper />
  if (noSearchResultErrorView) return <ErrorView {...noSearchResultErrorView} />
  if (errorView) return <ErrorView {...errorView} />
  return <div className={`w-full pb-4 ${childrenClassName}`}>{children}</div>
}

interface ViewBaseProps {
  children: ReactNode
  childrenClassName?: string
  title?: string
  subtitle?: string | ReactNode
  backUrl?: string
  showBackButton?: boolean
  tabBar?: TabBar
  buttons?: ButtonProps[]
  searchBar?: SearchBar
  className?: string
  loading?: boolean
  errorView?: ErrorView
  noSearchResultErrorView?: ErrorView
}

export const ViewBase = ({
  children,
  childrenClassName,
  title,
  subtitle,
  showBackButton,
  tabBar,
  buttons,
  searchBar,
  className,
  loading,
  errorView,
  noSearchResultErrorView,
  backUrl,
}: ViewBaseProps): JSX.Element => {
  const navigate = useNavigate()
  const { translate } = useAppTranslation()

  const goBack = (): void => {
    if (backUrl) {
      navigate(backUrl)
    } else {
      navigate(-1)
    }
  }

  return (
    <div
      className={`mb-6 flex h-full w-full flex-col items-start ${
        className ?? ''
      }`}
    >
      <div className="mb-6 w-full">
        <div className="flex w-full flex-col justify-between md:flex-row">
          <div>
            <div className="flex items-center gap-2">
              {(backUrl || showBackButton) && <BackButton onClick={goBack} />}
              {title && (
                <p style={theme.textVariants.h2} className="mb-0">
                  {title}
                </p>
              )}
            </div>
            {subtitle && (
              <>
                {typeof subtitle === 'string' || subtitle instanceof String ? (
                  <p
                    style={theme.textVariants.base}
                    className="mt-2 whitespace-pre-line text-neutral-40"
                  >
                    {subtitle}
                  </p>
                ) : (
                  subtitle
                )}
              </>
            )}
            {searchBar && (
              <SearchBar
                className="mt-4"
                widthClassName="w-full md:w-[300px]"
                {...searchBar}
                placeholder={translate('searchBar.placeholder.filter')}
              />
            )}
          </div>
          {buttons && buttons.length > 0 && (
            <div className="mt-2 flex flex-col flex-wrap justify-end gap-2 md:mt-0 md:flex-row md:gap-3">
              {buttons.map((_button) => (
                <Button
                  key={`${_button.label}`}
                  className="w-full"
                  {..._button}
                />
              ))}
            </div>
          )}
        </div>
        {tabBar && <TabBar className="mt-4 max-w-4xl" {...tabBar} />}
      </div>
      <ErrorViewWrapper
        loading={loading}
        errorView={errorView}
        noSearchResultErrorView={noSearchResultErrorView}
        childrenClassName={childrenClassName}
      >
        {children}
      </ErrorViewWrapper>
    </div>
  )
}
