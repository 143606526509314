import React, { Dispatch, SetStateAction } from 'react'
import { observer } from 'mobx-react-lite'
import { Control, Controller, UseFormHandleSubmit } from 'react-hook-form'
import { InferType } from 'yup'
import { useAtom } from 'jotai'
import { captureException } from '@sentry/react'
import { chatMessageSchema } from '../../../forms/schemas/chat_message'
import { useStores } from '../../../hooks/useStores'
import { Button, ButtonVariant } from '../../common/Button'
import { NChat } from '../../../interfaces/services/chat.interfaces'
import { UserWithSocietyIdAndRoom } from '../ChatRoom/useUsersWithChatRoom'
import { ChatHeaderState, chatHeaderStateAtom } from '../atom'
import { TextArea } from '../../common/TextArea'
import { IconChoices } from '../../common/Icon'
import { ChatJoinGroupChatBar } from '../ChatJoinGroupChatBar/ChatJoinGroupChatBar'
import { ProcessedMedia } from '../../../types/image-upload'
import { MessageBarImages } from './MessageBarImages'
import { MessageBarUpload } from './MessageBarUpload'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { postSendMessage } from '../../../api/chat'

interface ChatMessageBarProps {
  roomId: string | undefined
  usersWithChatRoom: UserWithSocietyIdAndRoom[]
  handleSubmit: UseFormHandleSubmit<InferType<typeof chatMessageSchema>>
  disabled: boolean
  control: Control<InferType<typeof chatMessageSchema>>
  watchRecipients: string[] | undefined
  showSettings: boolean
  setShowSettings: Dispatch<SetStateAction<boolean>>
  onUploadImage: (e: React.ChangeEvent<HTMLInputElement>) => void
  images: ProcessedMedia[]
  onImageRemove: (image: ProcessedMedia) => void
  imageUploadDisabled: boolean
  resetForm: () => void
}

export const ChatMessageBar = observer(
  ({
    roomId,
    usersWithChatRoom,
    handleSubmit,
    disabled,
    control,
    watchRecipients,
    showSettings,
    setShowSettings,
    onUploadImage,
    images,
    onImageRemove,
    imageUploadDisabled,
    resetForm,
  }: ChatMessageBarProps): JSX.Element => {
    const { authenticationStore, chatMessageStore, chatRoomStore, userStore } =
      useStores()
    const [chatHeaderState, setChatHeaderState] = useAtom(chatHeaderStateAtom)
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()

    const sendMessage = async (
      _roomId: string,
      data: InferType<typeof chatMessageSchema>
    ): Promise<void> => {
      try {
        const resp = await postSendMessage(_roomId, {
          message: data.message,
          mediaIds: data.mediaIds,
        })

        const newMessage = resp.data.data
        if (!newMessage) {
          setToastNotification(
            ToastType.DANGER,
            translate('flashMessage.somethingWentWrongError')
          )
          return
        }
        chatMessageStore.setChatMessages([newMessage])
        chatRoomStore.chatRooms
          .get(_roomId)
          ?.updateLastMessageDate(Date.parse(newMessage.createdAt))
      } catch (error) {
        captureException(error)
      }
    }

    const initiateNewBoardChat = async (
      data: InferType<typeof chatMessageSchema>
    ): Promise<void> => {
      if (!data.groupChatTitle) {
        return
      }
      const createRoomData: NChat.NCreateRoom.IRequestBody = {
        name: data.groupChatTitle,
        type: 'board',
        membersList: [],
        societyId: data.society as string,
      }

      const room = await chatRoomStore.createRoom(createRoomData)
      if (!room) {
        // TODO: Handle
        return
      }
      await sendMessage(room._id, data)
      setChatHeaderState(ChatHeaderState.None)
      chatRoomStore.setSelectedChatRoom(room._id)
    }

    const initiateNewOneOnOneChat = async (
      selectedRecipients: UserWithSocietyIdAndRoom[],
      data: InferType<typeof chatMessageSchema>
    ): Promise<void> => {
      const recipient = selectedRecipients[0]
      const createRoomData: NChat.NCreateRoom.IRequestBody = {
        name: `${userStore.currentUser()?.fullName} / ${
          recipient.user.fullName
        }`,
        societyId: recipient.user.societyId,
        type: 'oneonone',
        membersList: [
          { userId: authenticationStore.userId as string },
          { userId: recipient.user._id },
        ],
      }

      const room = await chatRoomStore.createRoom(createRoomData)
      if (!room) {
        // TODO: Handle
        return
      }
      await sendMessage(room._id, data)
      setChatHeaderState(ChatHeaderState.None)
      chatRoomStore.setSelectedChatRoom(room._id)
    }

    const initiateNewGroupChat = async (
      selectedRecipients: UserWithSocietyIdAndRoom[],
      data: InferType<typeof chatMessageSchema>
    ): Promise<void> => {
      const userId = userStore.currentUser()?._id
      if (!data.groupChatTitle || !data.society || !data.isPrivate || !userId) {
        return
      }
      const recipients = selectedRecipients
      const body: NChat.NCreateRoom.IRequestBody = {
        name: data.groupChatTitle,
        type: 'interests',
        membersList: recipients
          .map((_user) => ({ userId: _user.user._id }))
          .concat([{ userId }]),
        societyId: data.society as string,
        isPrivate: data.isPrivate === 'true',
      }
      const room = await chatRoomStore.createRoom(body)
      if (!room) {
        // TODO: Handle
        return
      }
      await sendMessage(room._id, data)
      setChatHeaderState(ChatHeaderState.None)
      chatRoomStore.setSelectedChatRoom(room._id)
    }

    const onSubmit = async (
      data: InferType<typeof chatMessageSchema>
    ): Promise<void> => {
      if (roomId) {
        await sendMessage(roomId, data)
        setChatHeaderState(ChatHeaderState.None)
      }
      if (
        !roomId &&
        data.recipients?.length === 0 &&
        chatHeaderState !== ChatHeaderState.StartNewBoardChat
      ) {
        return
      }
      if (
        !roomId &&
        data.recipients?.length === 0 &&
        chatHeaderState === ChatHeaderState.StartNewBoardChat
      ) {
        await initiateNewBoardChat(data)
      }
      // We retrieve the corresponding rich object for every recipient
      // ['129837123', '123781236'] => [Obj, Obj]
      const selectedRecipients = usersWithChatRoom.filter((user) => {
        return data.recipients?.some((userId) => userId === user.user._id)
      })

      if (!roomId && selectedRecipients.length === 1 && !data.isGroupchat) {
        await initiateNewOneOnOneChat(selectedRecipients, data)
      } else if (selectedRecipients.length >= 1 && data.isGroupchat) {
        await initiateNewGroupChat(selectedRecipients, data)
      }
      resetForm()
    }

    const noRecipients = watchRecipients && watchRecipients.length === 0
    const shouldDisableSendButton =
      noRecipients && chatHeaderState === ChatHeaderState.StartNewChat

    // const onTextAreaEnterSubmit = (): void => {
    //   handleSubmit(onSubmit)()
    // }

    return chatHeaderState !== ChatHeaderState.JoinChat ? (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex-none bg-neutral-98 p-4 md:py-4 md:px-4"
      >
        <div className="flex flex-1 gap-1 self-end">
          <MessageBarUpload
            control={control}
            imageUploadDisabled={imageUploadDisabled}
            onUploadImage={onUploadImage}
          />
          <div className="flex w-full flex-col rounded-xl bg-neutral-92">
            <MessageBarImages images={images} onImageRemove={onImageRemove} />
            <Controller
              control={control}
              name="message"
              render={({ field: { value, onChange, onBlur } }) => (
                <TextArea
                  className="bg-neutral-92"
                  roundedClassName="rounded-xl"
                  placeholder="Aa"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  rows={1}
                  removeBorders
                  fixedHeight={false}
                  // handleSubmit={onTextAreaEnterSubmit}
                />
              )}
            />
          </div>
          <div className="flex self-end">
            <Button
              type="submit"
              disabled={disabled || shouldDisableSendButton}
              className="h-11 w-11 border-none bg-transparent"
              size="no-padding"
              iconSize={20}
              variant={ButtonVariant.TEXT}
              iconColor="black"
              icon={IconChoices.SEND}
            />
          </div>
        </div>
      </form>
    ) : (
      <ChatJoinGroupChatBar
        roomId={roomId}
        showSettings={showSettings}
        setShowSettings={setShowSettings}
      />
    )
  }
)
