/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useLocation, useParams } from 'react-router-dom'
import { ErrorBoundary } from '../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { theme } from '../../../theme/theme'
import { useStores } from '../../../hooks/useStores'
import { SocietyModel } from '../../../state/models/society'
import { FindSocietyConfirmActivationForm } from '../../../components/find-society/FindSociety/FindSocietyConfirmActivationForm'
import { useDocumentTitle } from '../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../navigation/reverseDocumentTitle'

export const FindSocietyViewConfirmActivationView = observer(
  (): JSX.Element => {
    const { societyStore } = useStores()
    useDocumentTitle(
      reverseDocumentTitle('find-society:confirm-activation-detail')
    )
    const { translate } = useAppTranslation()
    const { id } = useParams()
    const { state } = useLocation()
    const { navigatingFromSocietyCreated } = state

    const society = id
      ? (societyStore.societies.get(id) as SnapshotOut<typeof SocietyModel>)
      : undefined

    useEffect(() => {
      if (id) {
        societyStore.getSociety(id)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <ErrorBoundary>
        <div className="h-full w-full overflow-y-auto p-6">
          <>
            <p style={theme.textVariants.h2}>
              {translate('findSocietyConfirmActivationView.title')}
            </p>
            <p className="mt-2" style={theme.textVariants.base}>
              {translate('findSocietyConfirmActivationView.subtitle')}
            </p>
            {society && (
              <FindSocietyConfirmActivationForm
                navigatingFromSocietyCreated={!!navigatingFromSocietyCreated}
                society={society}
              />
            )}
          </>
        </div>
      </ErrorBoundary>
    )
  }
)
