import { useEffect } from 'react'
import { captureException } from '@sentry/react'
import { useStores } from './useStores'

export const useBlockedSocietyIds = (): void => {
  const { societyStore, authenticationStore } = useStores()

  // Fetch when started if authenticated
  useEffect(() => {
    if (authenticationStore.isAuthenticated) {
      try {
        societyStore.getUserBlockedSocietyIds()
      } catch (error) {
        captureException(error)
      }
    }
  }, [
    societyStore,
    authenticationStore.userId,
    authenticationStore.isAuthenticated,
  ])
}
