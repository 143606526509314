export const stringToSlug = (str: string): string => {
  let processedString = str
  processedString = str.replace(/^\s+|\s+$/g, '') // trim
  processedString = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñçěščřžýúůďťň·/_,:;'
  const to = 'aaaaeeeeiiiioooouuuuncescrzyuudtn------'

  for (let i = 0, l = from.length; i < l; i += 1) {
    processedString = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  processedString = str
    .replace('.', '-') // replace a dot by a dash
    .replace(/[^a-zA-Z0-9 -_]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by a dash
    .replace(/-+/g, '-') // collapse dashes
    .replace(/\//g, '') // collapse all forward-slashes

  return processedString.toLowerCase()
}
