import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useStores } from '../../../../hooks/useStores'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ReportProblemSettingsForm } from '../ReportProblemSettingsForm'
import { societyReportSettingsSchema } from '../../../../forms/schemas/society_report_settings'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'

interface ReportProblemSettingsModalProps {
  show: boolean
  close: () => void
}

export const ReportProblemSettingsModal = observer(
  ({ show, close }: ReportProblemSettingsModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { societyStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()

    const showSuccessMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('flashMessage.changesSaved')
      )
    }

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.changesNotSavedError')
      )
    }

    const onSubmit = async (
      data: InferType<typeof societyReportSettingsSchema>
    ): Promise<void> => {
      const recipients = [
        data.recipient,
        ...(data.recipientsCC ? data.recipientsCC.map((cc) => cc.email) : []),
      ]
      const status = await societyStore.patchSociety(society._id, {
        reportProblemEmailCC: recipients,
      })
      if (status) {
        close()
        showSuccessMessage()
      } else {
        showErrorMessage()
      }
    }

    const onError = (): void => {
      showErrorMessage()
    }

    const loading = societyStore.updatingSociety === 'pending'

    return (
      <>
        <Modal show={show} size="md">
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate('reportProblemSettings.modal.title')}
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <ReportProblemSettingsForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
