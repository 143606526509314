import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { InferType } from 'yup'
import { Button, ButtonVariant } from '../../common/Button'
import { HorizontalFormSection } from '../../common/HorizontalFormSection'
import { IconChoices } from '../../common/Icon'
import { ToastType } from '../../common/Toast/toast-type'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useAuthenticatedUserId } from '../../../hooks/useAuthenticatedUserId'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { UserModel } from '../../../state/models/user'
import { useDropdownOptions } from './useDropdownOptions'
import { userInfoSchema } from '../../../forms/schemas/user_info'
import { sanitizeMobileNumber } from '../../../helpers/phone'
import { NUsers } from '../../../interfaces/services/users.interfaces'
import { FormControl } from '../../common/FormControl'
import { TextInput } from '../../common/TextInput'
import { useFormErrorMessage } from '../../../hooks/useFormErrorMessage'
import { SelectDropdown } from '../../common/SelectDropdown'
import { TextArea } from '../../common/TextArea'
import { DateTimeInput } from '../../common/DateTimeInput'

export const MyInfo = observer((): JSX.Element => {
  const { userStore } = useStores()
  const { translate } = useAppTranslation()
  const { setToastNotification } = useToastNotifications()
  const { getErrorMessage } = useFormErrorMessage()
  const {
    genderOptions,
    occupationIndustryOptions,
    educationLevelOptions,
    educationDegreeOptions,
    educationFieldOptions,
    interestsOptions,
    countryOptions,
  } = useDropdownOptions()
  const userId = useAuthenticatedUserId() as string
  const user = userStore.users.get(userId) as SnapshotOut<typeof UserModel>

  const getDefaultValues = useCallback((): InferType<typeof userInfoSchema> => {
    return {
      name: user?.name || '',
      surname: user?.surname || '',
      occupationCompany: user?.aboutInfo?.occupation?.company || '',
      occupationTitle: user?.aboutInfo?.occupation?.title || '',
      occupationIndustry: user?.aboutInfo?.occupation?.industry || '',
      educationLevel: user?.aboutInfo?.education?.level || '',
      educationCountry: user?.aboutInfo?.education?.country || '',
      educationInstitute: user?.aboutInfo?.education?.institute || '',
      educationField: user?.aboutInfo?.education?.field || '',
      educationDegree: user?.aboutInfo?.education?.degree || '',
      presentation: user?.aboutInfo?.presentation || '',
      interests: user?.aboutInfo?.interests || [],
      birthDate: user?.birthDate ? new Date(user.birthDate) : undefined,
      gender: user?.gender || '',
      mobile: user?.mobile ? `+46${sanitizeMobileNumber(user?.mobile)}` : '',
      mobileCountry: 'se',
    }
  }, [user])

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(userInfoSchema),
    defaultValues: getDefaultValues(),
  })

  const onSubmit = async (
    data: InferType<typeof userInfoSchema>
  ): Promise<void> => {
    const formData: NUsers.NPatch.IRequestBody = {
      name: data.name,
      surname: data.surname,
      birthDate: data.birthDate,
      gender: data.gender,
      mobile: data.mobile,
      mobileCountry: data.mobileCountry,
      aboutInfo: {
        interests: data.interests as string[],
        occupation: {
          company: data.occupationCompany,
          title: data.occupationTitle,
          industry: data.occupationIndustry,
        },
        education: {
          level: data.educationLevel,
          country: data.educationCountry,
          institute: data.educationInstitute,
          field: data.educationField,
          degree: data.educationDegree,
        },
        presentation: data.presentation,
      },
    }
    const status = await userStore.patchUser(user._id, formData)
    if (status) {
      setToastNotification(
        ToastType.SUCCESS,
        translate('flashMessage.changesSaved')
      )
    } else {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.changesNotSavedError')
      )
    }
  }

  const onError = (): void => {
    if (!isValid) return
    setToastNotification(
      ToastType.DANGER,
      translate('flashMessage.somethingWentWrongError')
    )
  }

  const loading = userStore.updatingUser === 'pending'

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <HorizontalFormSection
        title={translate('myInfo.sections.titles.general')}
        icon={IconChoices.INFORMATION}
        iconPlacement="left"
        borderBottom
      >
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('common.form.labels.name')}
              error={errors.name && getErrorMessage(errors.name)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.name}
              />
            </FormControl>
          )}
          name="name"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('common.form.labels.surname')}
              error={errors.surname && getErrorMessage(errors.surname)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.surname}
              />
            </FormControl>
          )}
          name="surname"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('common.form.labels.phoneNumber')}
              error={errors.mobile && getErrorMessage(errors.mobile)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.mobile}
              />
            </FormControl>
          )}
          name="mobile"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate('common.form.labels.gender')}
              error={errors.gender && getErrorMessage(errors.gender)}
              name={name}
            >
              <SelectDropdown
                value={value}
                onChange={onChange}
                options={genderOptions}
              />
            </FormControl>
          )}
          name="gender"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate('user.birthDate')}
              error={errors.birthDate && getErrorMessage(errors.birthDate)}
              name={name}
            >
              <DateTimeInput value={value} onChange={onChange} />
            </FormControl>
          )}
          name="birthDate"
        />
      </HorizontalFormSection>
      <HorizontalFormSection
        title={translate('myInfo.sections.titles.work')}
        icon={IconChoices.WORK}
        iconPlacement="left"
        borderBottom
      >
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('user.aboutInfo.occupation.company')}
              error={
                errors.occupationCompany &&
                getErrorMessage(errors.occupationCompany)
              }
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.occupationCompany}
              />
            </FormControl>
          )}
          name="occupationCompany"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('user.aboutInfo.occupation.title')}
              error={
                errors.occupationTitle &&
                getErrorMessage(errors.occupationTitle)
              }
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.occupationTitle}
              />
            </FormControl>
          )}
          name="occupationTitle"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate('user.aboutInfo.occupation.industry')}
              error={
                errors.occupationIndustry &&
                getErrorMessage(errors.occupationIndustry)
              }
              name={name}
            >
              <SelectDropdown
                value={value}
                onChange={onChange}
                options={occupationIndustryOptions}
              />
            </FormControl>
          )}
          name="occupationIndustry"
        />
      </HorizontalFormSection>
      <HorizontalFormSection
        title={translate('myInfo.sections.titles.education')}
        icon={IconChoices.SCHOOL}
        iconPlacement="left"
        borderBottom
      >
        <Controller
          control={control}
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate('user.aboutInfo.education.level')}
              error={
                errors.educationLevel && getErrorMessage(errors.educationLevel)
              }
              name={name}
            >
              <SelectDropdown
                value={value}
                onChange={onChange}
                options={educationLevelOptions}
              />
            </FormControl>
          )}
          name="educationLevel"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate('user.aboutInfo.education.country')}
              error={
                errors.educationCountry &&
                getErrorMessage(errors.educationCountry)
              }
              name={name}
            >
              <SelectDropdown
                value={value}
                onChange={onChange}
                options={countryOptions}
              />
            </FormControl>
          )}
          name="educationCountry"
        />

        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('user.aboutInfo.education.institute')}
              error={
                errors.educationInstitute &&
                getErrorMessage(errors.educationInstitute)
              }
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.educationInstitute}
              />
            </FormControl>
          )}
          name="educationInstitute"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate('user.aboutInfo.education.degree')}
              error={
                errors.educationDegree &&
                getErrorMessage(errors.educationDegree)
              }
              name={name}
            >
              <SelectDropdown
                value={value}
                onChange={onChange}
                options={educationDegreeOptions}
              />
            </FormControl>
          )}
          name="educationDegree"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate('user.aboutInfo.education.field')}
              error={
                errors.educationField && getErrorMessage(errors.educationField)
              }
              name={name}
            >
              <SelectDropdown
                value={value}
                onChange={onChange}
                options={educationFieldOptions}
              />
            </FormControl>
          )}
          name="educationField"
        />
      </HorizontalFormSection>
      <HorizontalFormSection
        title={translate('myInfo.sections.titles.about')}
        icon={IconChoices.CONTACT_PAGE}
        iconPlacement="left"
        borderBottom
      >
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('user.aboutInfo.presentation')}
              error={
                errors.presentation && getErrorMessage(errors.presentation)
              }
              name={name}
            >
              <TextArea
                placeholder={translate(
                  'user.aboutInfo.presentationPlaceholder'
                )}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.presentation}
              />
            </FormControl>
          )}
          name="presentation"
        />
      </HorizontalFormSection>
      <HorizontalFormSection
        title={translate('myInfo.sections.titles.interests')}
        icon={IconChoices.INTERESTS}
        iconPlacement="left"
        borderBottom
      >
        <Controller
          control={control}
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate('user.aboutInfo.interestCategories')}
              name={name}
            >
              <SelectDropdown
                isMulti
                value={value}
                onChange={onChange}
                options={interestsOptions}
                menuPlacement="top"
                height="fit-content"
              />
            </FormControl>
          )}
          name="interests"
        />
      </HorizontalFormSection>
      <HorizontalFormSection title="">
        <div className="flex justify-end">
          <Button
            label={translate('common.actions.save')}
            type="submit"
            loading={loading}
            disabled={loading || !isDirty}
            variant={ButtonVariant.PRIMARY}
          />
        </div>
      </HorizontalFormSection>
    </form>
  )
})
