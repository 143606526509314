import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { theme } from '../../../../theme/theme'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { useStores } from '../../../../hooks/useStores'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { SocietyWidgetModel } from '../../../../state/models/society-widget'
import { societyWidgetCreateSchema } from '../../../../forms/schemas/society_widget_create'
import { CreateUpdateWidgetForm } from '../CreateUpdateWidgetForm'

interface CreateUpdateWidgetModalProps {
  show: boolean
  close: () => void
  widget?: SnapshotOut<typeof SocietyWidgetModel>
  children?: React.ReactNode
}

export const CreateUpdateWidgetModal = observer(
  ({
    show,
    close,
    widget,
    children,
  }: CreateUpdateWidgetModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { societyWidgetsStore } = useStores()
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!widget

    const showSuccessMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate(
          updateMode
            ? 'functionSettingsView.flashMessage.updateWidgetSuccess'
            : 'functionSettingsView.flashMessage.createWidgetSuccess'
        )
      )
    }

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate(
          updateMode
            ? 'functionSettingsView.flashMessage.updateWidgetFailure'
            : 'functionSettingsView.flashMessage.createWidgetFailure'
        )
      )
    }

    const onSubmit = async (
      data: InferType<typeof societyWidgetCreateSchema>
    ): Promise<void> => {
      if (updateMode) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const status = await societyWidgetsStore.updateWidget(widget._id, data)
        if (status) {
          showSuccessMessage()
          close()
        } else {
          showErrorMessage()
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const status = await societyWidgetsStore.createWidget(data)
        if (status) {
          showSuccessMessage()
          close()
        } else {
          showErrorMessage()
        }
      }
    }

    const getModalTitle = (): string => {
      return translate(
        updateMode
          ? 'functionSettingsView.modal.editTitle'
          : 'functionSettingsView.modal.createTitle'
      )
    }

    const loading =
      societyWidgetsStore.creatingWidget === 'pending' ||
      societyWidgetsStore.updatingWidget === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                <div>
                  <p style={theme.textVariants.h3}>{getModalTitle()}</p>
                </div>
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <CreateUpdateWidgetForm
                  onError={showErrorMessage}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  widget={widget}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
