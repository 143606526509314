/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, getRoot, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import {
  getContactsPerSociety as apiGetContactsPerSociety,
  getContact as apiGetContact,
  patchContact as apiPatchContact,
  createContact as apiCreateContact,
  deleteContact as apiDeleteContact,
} from '../../api/society-contacts'
import { stateType } from '../types/common'
import { RootStore } from './root'
import { setObject } from './helpers'
import { SocietyContactModel } from '../models/society-contact'
import { ISocietyContactModel } from '../../interfaces/models/society-contacts.interfaces'
import { NSocietyContacts } from '../../interfaces/services/society-contacts.interface'
import { sortStringsAlphabetically } from '../../helpers/sorting'

export const SocietyContactStore = types
  .model('SocietyContactStore')
  .props({
    contacts: types.map(SocietyContactModel),
    fetchingContacts: stateType,
    updatingContact: stateType,
    creatingContact: stateType,
  })
  .views((self) => ({
    contactForSociety(
      societyId: string
    ): SnapshotOut<typeof SocietyContactModel>[] {
      return (
        // @ts-ignore
        (values(self.contacts) as SnapshotOut<typeof SocietyContactModel>[])
          // @ts-ignore
          .filter((contact) => contact.societyId === societyId)
          .sort((a, b) => sortStringsAlphabetically(a.name, b.name))
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.contacts.clear()
      self.fetchingContacts = 'none'
      self.updatingContact = 'none'
      self.creatingContact = 'none'
    },
    setContacts: (contacts: ISocietyContactModel[]) => {
      contacts.forEach((contact) => {
        setObject<typeof SocietyContactModel>(
          // @ts-ignore
          self.contacts,
          SocietyContactModel,
          {
            ...contact,
          }
        )
      })
    },
  }))
  .actions((self) => ({
    getContactsPerSociety: flow(function* getContactsPerSociety(
      societyId: string
    ) {
      self.fetchingContacts = 'pending'
      try {
        const resp = yield apiGetContactsPerSociety(societyId)
        const data = resp.data as NSocietyContacts.NGetPerSocieties.IResponse
        const contacts = data.data
        const { media } = data.populated

        const { mediaStore } = getRoot<RootStore>(self)
        if (media) {
          mediaStore.setMedia(media)
        }

        self.setContacts(contacts)

        self.fetchingContacts = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingContacts = 'error'
      }
    }),
    getContact: flow(function* getContact(id: string) {
      self.fetchingContacts = 'pending'
      try {
        const resp = yield apiGetContact(id)
        const data = resp.data as NSocietyContacts.NGetById.IResponse
        const contact = data.data
        const { media } = data.populated

        const { mediaStore } = getRoot<RootStore>(self)
        if (media) {
          mediaStore.setMedia(media)
        }

        self.setContacts([contact])

        self.fetchingContacts = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingContacts = 'error'
      }
    }),
    patchContact: flow(function* patchContact(
      id: string,
      body: NSocietyContacts.NPatch.IRequestBody
    ) {
      self.updatingContact = 'pending'
      try {
        const resp = yield apiPatchContact(id, body)
        const data = resp.data as NSocietyContacts.NPatch.IResponse
        const contact = data.data

        if (contact !== null) {
          self.setContacts([contact])
        }

        self.updatingContact = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingContact = 'error'
        return false
      }
    }),
    createContact: flow(function* createContact(
      body: NSocietyContacts.NCreate.IRequestBody
    ) {
      self.creatingContact = 'pending'
      try {
        const resp = yield apiCreateContact(body)
        const data = resp.data as NSocietyContacts.NCreate.IResponse
        const contact = data.data

        if (contact !== null) {
          self.setContacts([contact])
        }

        self.creatingContact = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingContact = 'error'
        return false
      }
    }),
    deleteContact: flow(function* deleteContact(id: string) {
      try {
        yield apiDeleteContact(id)

        const { postStore } = getRoot<RootStore>(self)
        const postsReferringContact = postStore.sortedPosts
          .filter((_post) => _post.societyContactsIds.includes(id))
          .map((_post) => _post._id)
        postsReferringContact.forEach((_id) => postStore.deletePost(_id))

        self.contacts.delete(id)
        return true
      } catch (error) {
        captureException(error)
        return false
      }
    }),
  }))
