import { SnapshotOut } from 'mobx-state-tree'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { UserModel } from '../../../state/models/user'
import { theme } from '../../../theme/theme'
import { UserAvatar } from '../../common/Avatar'
import { Icon, IconChoices } from '../../common/Icon'

interface LikesUserListItemProps {
  index: number
  user: SnapshotOut<typeof UserModel>
  societyId: string
}

export const LikesUserListItem = ({
  index,
  user,
  societyId,
}: LikesUserListItemProps): JSX.Element => {
  const navigate = useNavigate()

  const navigateToUser = (): void => {
    navigate(
      reverseUrl('user:society-detail', {
        id: user._id,
        societyId,
      })
    )
  }

  return (
    <button
      onClick={navigateToUser}
      className={`flex items-center justify-between rounded-lg  p-4 hover:bg-neutral-96
      ${index === 0 ? '' : 'border-t border-neutral-90'}
    `}
    >
      <div className="flex items-center gap-4">
        <UserAvatar user={user} />
        <p style={theme.textVariants.baseBold} translate="no">
          {user.fullName}
        </p>
      </div>
      <Icon icon={IconChoices.ARROW} size={26} />
    </button>
  )
}
