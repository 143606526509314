import { useEffect } from 'react'
import { TrackingEventType } from '../types/tracking'
import { useTracking } from './useTracking'

export const useAppInitializedTracking = (): void => {
  const { track } = useTracking()

  useEffect(() => {
    // We decided to drop the AppInitialized event in Mixpanel to reduce the number of events
    // and only track AppResumed. Therefore we will track AppResumed here instead to still track
    // web app events.
    track({ event: TrackingEventType.AppResumed })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
