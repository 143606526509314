import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import { GroupModel } from '../../../../state/models/group'
import { AccordionSection } from '../../../common/AccordionSection'
import { getGroupSubtitle } from '../helpers'
import { useStores } from '../../../../hooks/useStores'
import { UnitModel } from '../../../../state/models/unit'
import { getUnitTitle } from '../../RegisterUnitList/helpers'
import { RegisterUnitItem } from '../../RegisterUnitItem'
import { Option } from '../../../common/Dropdown'

interface RegisterGroupUnitListItemProps {
  group: SnapshotOut<typeof GroupModel>
  dropdownOptions: Option[]
}

export const RegisterGroupUnitListItem = observer(
  ({ group, dropdownOptions }: RegisterGroupUnitListItemProps): JSX.Element => {
    const { unitStore } = useStores()

    // Prevents this group listview from breaking when the register has changed
    const unitsList = group.unitsList
      ? group.unitsList
          ?.map((listItem) => {
            const _unit = unitStore.units.get(listItem.unitId) as
              | SnapshotOut<typeof UnitModel>
              | undefined
            return _unit
          })
          .filter((_unit): _unit is SnapshotOut<typeof UnitModel> => !!_unit)
      : []

    return (
      <AccordionSection
        className="md:w-1/2 md:max-w-full"
        key={group._id}
        title={group.name}
        subtitle={getGroupSubtitle(group)}
        dropdownOptions={dropdownOptions}
        items={unitsList.map((_unit) => ({
          id: uuid(),
          title: _unit ? getUnitTitle(_unit) : '',
          content: (
            <RegisterUnitItem
              unit={_unit}
              enableDelete={false}
              enableUpdate={false}
            />
          ),
        }))}
      />
    )
  }
)
