import * as yup from 'yup'
import punycode from 'punycode'

// Overrides the yup built-in email validation logic. By default yup uses the
// regex from WHATWG to validate URLs, which matches what we support, but the
// regex requires domains to be punycoded before validation. We want to store
// the original UTF-8 address, so the encoding is only done for the validation
//
// Recommendation about overriding yup email validation:
// eslint-disable-next-line max-len
// https://github.com/jquense/yup/blob/a58f02d2f6164c46a9757a818eebac582f7a441c/README.md#stringemailmessage-string--function-schema

// Taken from HTML spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
const rEmail =
  // eslint-disable-next-line max-len, no-useless-escape
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

yup.addMethod(yup.string, 'email', function validateEmail(message) {
  return this.test({
    name: 'email',
    message,
    params: { regex: rEmail },
    skipAbsent: true,
    test: (value: yup.Maybe<string>) => {
      if (!value) return false

      // Note: this deprecation notice is invalid, we're using the punycode
      // package from npm, not the built-in node module
      const encodedValue = punycode.toASCII(value)
      return encodedValue.search(rEmail) !== -1
    },
  })
})
