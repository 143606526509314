import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'

export const MediaModel = types.model('Media', {
  _id: types.identifier,
  ...timestampTypes,
  cat: types.enumeration('MediaCategory', ['image', 'video']),
  url: types.maybe(types.string),
  thumb: types.maybeNull(types.string), // API returns null here.
})
