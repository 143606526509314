import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { householdPreApprovedCreateSchema } from '../../../../forms/schemas/household_pre_approved_create'
import { UnitRole } from '../../../../types/unit-roles'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { Button, ButtonVariant } from '../../../common/Button'
import { IconChoices } from '../../../common/Icon'
import { TextInput } from '../../../common/TextInput'
import { FormControl } from '../../../common/FormControl'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { useStores } from '../../../../hooks/useStores'
import { ToastType } from '../../../common/Toast/toast-type'

interface AddPersonProps {
  unitId: string
  societyId: string
}

export const AddPerson = observer(
  ({ unitId, societyId }: AddPersonProps): JSX.Element => {
    const { societyStore } = useStores()
    const [showEmailField, setShowEmailField] = useState(false)
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const { getErrorMessage } = useFormErrorMessage()

    const getDefaultValues = (): InferType<
      typeof householdPreApprovedCreateSchema
    > => {
      return {
        userEmail: '',
        unitRole: UnitRole.RESIDENT,
      }
    }

    const {
      control,
      handleSubmit,
      reset,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(householdPreApprovedCreateSchema),
      defaultValues: getDefaultValues(),
    })

    const showSuccessMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('manageHousehold.flashMessage.sendInviteSuccess')
      )
    }

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('manageHousehold.flashMessage.sendInviteFailure')
      )
    }

    const onSubmit = async (
      data: InferType<typeof householdPreApprovedCreateSchema>
    ): Promise<void> => {
      const status = await societyStore.createUserRole([
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        {
          ...data,
          unitIdOrTitle: unitId,
          societyId,
          userEmail: data.userEmail.toLowerCase(),
        },
      ])
      if (status) {
        await societyStore.getSociety(societyId)
        setShowEmailField(false)
        reset()
        showSuccessMessage()
      } else {
        showErrorMessage()
      }
    }

    const onError = (): void => {
      //
    }

    const loading = societyStore.creatingUserRole === 'pending'

    return (
      <div className="mt-6">
        {showEmailField ? (
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Controller
              control={control}
              name="userEmail"
              render={({ field: { value, onChange, name, onBlur } }) => (
                <FormControl
                  label={translate('common.form.labels.email')}
                  name={name}
                  error={errors.userEmail && getErrorMessage(errors.userEmail)}
                >
                  <div className="flex items-center justify-between">
                    <TextInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      wrapperClassName="flex-1"
                      type="email"
                    />
                    <Button
                      label={translate('common.actions.invite')}
                      size="sm"
                      className="ml-3"
                      type="submit"
                      loading={loading}
                      icon={IconChoices.PLUS_SIGN}
                      disabled={!isValid || loading}
                      variant={ButtonVariant.PRIMARY}
                    />
                  </div>
                </FormControl>
              )}
            />
          </form>
        ) : (
          <Button
            label={translate('manageHousehold.buttons.addPerson')}
            icon={IconChoices.PLUS_SIGN}
            onClick={() => setShowEmailField(true)}
          />
        )}
      </div>
    )
  }
)
