export const getNumberOfBookingRows = (
  startTime: Date,
  endTime: Date,
  bookingGranularity: number,
  fullDayBookingGranularity: boolean
): number => {
  if (fullDayBookingGranularity) {
    return 1
  }

  // 00:00 - 00:00
  if (
    startTime.getUTCHours() === endTime.getUTCHours() &&
    startTime.getUTCMinutes() === endTime.getUTCMinutes() &&
    // Only for 00:00
    startTime.getUTCHours() === 0 &&
    startTime.getUTCMinutes() === 0
  ) {
    return Math.floor(24 / bookingGranularity)
  }

  return Math.floor(
    Math.abs(endTime.getTime() - startTime.getTime()) /
      // Divide to get hours difference
      (60 * 60 * 1000) /
      bookingGranularity
  )
}
