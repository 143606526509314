import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { useModal } from '../../../hooks/useModal'
import { useAuthenticatedUserId } from '../../../hooks/useAuthenticatedUserId'
import {
  isUserAdminInSociety,
  isUserBoardMemberInSociety,
} from '../../../helpers/society'
import { useDocumentTitle } from '../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../navigation/reverseDocumentTitle'
import { IllustrationChoices } from '../../../components/common/Illustration'
import { ErrorBoundary } from '../../../components/common/ErrorBoundary'
import { ViewBase } from '../../../components/common/ViewBase'
import { EventModel } from '../../../state/models/event'
import { BoardRoomEventModel } from '../../../state/models/boardroom-event'
import { ConfirmationModal } from '../../../components/common/ConfirmationModal'
import { CreateUpdateEventModal } from '../../../components/society/Event/CreateUpdateEventModal'
import { ToastType } from '../../../components/common/Toast/toast-type'
import { IconChoices } from '../../../components/common/Icon'
import { ButtonVariant } from '../../../components/common/Button'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { useEventTabs } from './useEventTabs'
import { RouteErrorView } from '../../error/RouteErrorView'
import { EventDetailInformation } from './EventDetailInformation'
import { EventDetailAttendants } from './EventDetailAttendants'

export enum EventDetailViewTabs {
  INFORMATION = 0,
  ATTENDANTS = 1,
}

interface EventDetailViewProps {
  boardRoomEvent?: boolean
}

export const EventDetailView = observer(
  ({ boardRoomEvent }: EventDetailViewProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { eventStore, boardRoomEventStore } = useStores()
    const { society } = useCurrentSociety()
    const location = useLocation()
    const navigate = useNavigate()
    const { getTabUrl, getTabState } = useEventTabs(boardRoomEvent)
    const [activeTab, setActiveTab] = useState<EventDetailViewTabs>(
      getTabState(location.pathname)
    )
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()
    const { eventId } = useParams()

    const {
      show: showEditModal,
      open: openEditModal,
      close: closeEditModal,
    } = useModal()
    const {
      show: showDeleteConfirmationModal,
      open: openDeleteConfirmationModal,
      close: closeDeleteConfirmationModal,
    } = useModal()

    const userId = useAuthenticatedUserId() as string

    const userIsSocietyAdmin = isUserAdminInSociety(society, userId as string)
    const userIsBoardMember = isUserBoardMemberInSociety(society, userId)

    const getEvent = ():
      | SnapshotOut<typeof EventModel>
      | SnapshotOut<typeof BoardRoomEventModel>
      | undefined => {
      if (!eventId) {
        return undefined
      }

      if (boardRoomEvent) {
        return boardRoomEventStore.events.get(eventId)
      }

      return eventStore.events.get(eventId)
    }

    const event = getEvent()

    useEffect(() => {
      if (eventId && !event) {
        if (boardRoomEvent) {
          boardRoomEventStore.getEvent(eventId)
        } else {
          eventStore.getEvent(eventId)
        }
      }
    }, [eventId, event, boardRoomEventStore, eventStore, boardRoomEvent])

    // When a new activeTab is set, we change the navigation to that tab as well
    useEffect(() => {
      const url = getTabUrl(activeTab)
      if (location.pathname !== url) {
        navigate(url)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab])

    useDocumentTitle(
      reverseDocumentTitle(
        boardRoomEvent
          ? 'management:calendar-detail'
          : 'society:calendar-detail',
        {
          '{{ eventName }}': event?.title,
          '{{ societyName }}': society.name,
        }
      )
    )

    const deleteEvent = async (): Promise<void> => {
      if (!event) {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
        return
      }
      const status = boardRoomEvent
        ? await boardRoomEventStore.deleteEvent(event._id)
        : await eventStore.deleteEvent(event._id)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('createUpdateEvent.flashMessage.deleteEventSuccess')
        )
        navigate(
          reverseUrl(
            boardRoomEvent ? 'management:calendar' : 'society:calendar',
            { id: event.societyId }
          )
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('createUpdateEvent.flashMessage.deleteEventFailure')
        )
      }
    }

    const loading = boardRoomEvent
      ? boardRoomEventStore.fetchingEvents === 'pending'
      : eventStore.fetchingEvents === 'pending'

    const buttons =
      userIsSocietyAdmin || (boardRoomEvent && userIsBoardMember)
        ? [
            {
              label: translate('common.actions.edit'),
              icon: IconChoices.EDIT,
              onClick: openEditModal,
            },

            {
              label: translate('common.actions.delete'),
              icon: IconChoices.DELETE_TRASH,
              variant: ButtonVariant.DANGER,
              onClick: openDeleteConfirmationModal,
            },
          ]
        : undefined

    const errorView = !event
      ? {
          title: translate('eventDetailView.errors.notFound.title'),
          subtitle: translate('eventDetailView.errors.notFound.subtitle'),
          illustration: IllustrationChoices.EMPTY,
        }
      : undefined

    const tabs = [
      {
        title: translate('eventDetailView.tabBar.information'),
      },
      ...(event?.attendanceEnabled
        ? [
            {
              title: translate('eventDetailView.tabBar.attendance'),
            },
          ]
        : []),
    ]

    return (
      <ErrorBoundary>
        <ViewBase
          title={
            event?.title ||
            translate(
              boardRoomEvent
                ? 'boardCalendarView.title'
                : 'societyCalendarListView.title'
            )
          }
          showBackButton
          backUrl={reverseUrl(
            boardRoomEvent ? 'management:calendar' : 'society:calendar',
            {
              id: event?.societyId,
            }
          )}
          buttons={buttons}
          errorView={errorView}
          loading={loading}
          tabBar={{ tabs, setActiveTab, activeTab }}
        >
          {event && (
            <Routes>
              <Route
                path="information"
                element={
                  <EventDetailInformation
                    event={event}
                    boardRoomEvent={boardRoomEvent}
                    setActiveTab={setActiveTab}
                  />
                }
                errorElement={<RouteErrorView />}
              />
              <Route
                path="attendants"
                element={
                  <EventDetailAttendants
                    event={event}
                    boardRoomEvent={boardRoomEvent}
                  />
                }
                errorElement={<RouteErrorView />}
              />
            </Routes>
          )}
        </ViewBase>
        {showEditModal && (
          <CreateUpdateEventModal
            show={showEditModal}
            close={closeEditModal}
            event={event as SnapshotOut<typeof EventModel>}
            boardRoomEvent={boardRoomEvent}
          />
        )}
        {showDeleteConfirmationModal && (
          <ConfirmationModal
            title={translate('createUpdateEvent.confirmationModal.deleteTitle')}
            description={translate(
              'createUpdateEvent.confirmationModal.deleteDescription'
            )}
            show={showDeleteConfirmationModal}
            close={closeDeleteConfirmationModal}
            onConfirm={deleteEvent}
            deleteMode
          />
        )}
      </ErrorBoundary>
    )
  }
)
