import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { theme } from '../../../../theme/theme'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { ExportsSection } from '../../../../components/society/SocietyStatisticsAndExports/ExportsSection'
// eslint-disable-next-line max-len
import { StatisticsSection } from '../../../../components/society/SocietyStatisticsAndExports/StatisticsSection'
import { TabBar } from '../../../../components/common/TabBar'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { useStores } from '../../../../hooks/useStores'

export enum ActiveTabBar {
  STATISTICS = 0,
  EXPORTS = 1,
}

export const SocietyStatisticsExportsView = observer((): JSX.Element => {
  const { statisticsStore, facilitiesStore } = useStores()
  const [activeTab, setActiveTab] = useState<ActiveTabBar>(
    ActiveTabBar.STATISTICS
  )
  const { translate } = useAppTranslation()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:statistics-exports', {
      '{{ societyName }}': society.name,
    })
  )

  useEffect(() => {
    statisticsStore.getSocietyStatistics(society._id)
    facilitiesStore.getFacilitiesPerSociety(society._id)
  }, [society._id, facilitiesStore, statisticsStore])

  const renderContent = (): JSX.Element => {
    switch (activeTab) {
      case ActiveTabBar.EXPORTS:
        return <ExportsSection society={society} />
      default:
        return <StatisticsSection society={society} />
    }
  }

  return (
    <ErrorBoundary>
      <div className="flex h-full w-full flex-col items-start">
        <h2 style={theme.textVariants.h2}>
          {translate('societyStatisticsExportsView.title')}
        </h2>
        <div>
          <TabBar
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            className="mt-2 md:mt-4"
            tabs={[
              {
                title: translate(
                  'societyStatisticsExportsView.tabBar.statistics'
                ),
              },
              {
                title: translate('societyStatisticsExportsView.tabBar.exports'),
              },
            ]}
          />
        </div>
        <div className="w-full py-2 md:py-4">{renderContent()}</div>
      </div>
    </ErrorBoundary>
  )
})
