import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { useAtom } from 'jotai'
import { ChatRoom } from '../../components/chat/ChatRoom'
import { SideBar } from '../../components/common/SideBar'
import { ChatSideBarContent } from '../../components/chat/ChatSideBarContent'
import { useStores } from '../../hooks/useStores'
import {
  ChatHeaderState,
  chatHeaderStateAtom,
} from '../../components/chat/atom'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../navigation/reverseDocumentTitle'
import { useHasUserFetchedChatRooms } from './useHasFetchedChatRooms'
import { useAuth } from '../../hooks/useAuth'
import { isUserBoardMemberInSociety } from '../../helpers/society'

export const ChatView = observer((): JSX.Element => {
  const { userId } = useAuth()
  useDocumentTitle(reverseDocumentTitle('chat'))
  const { chatRoomStore, societyStore } = useStores()
  const { id: chatRoomId } = useParams()
  const { hasFetchedOneOnOneAndInterestsChatRoomsOnce } =
    useHasUserFetchedChatRooms()
  const [chatHeaderState] = useAtom(chatHeaderStateAtom)
  const societies = societyStore.sortedSocieties

  const isOneOnOneChatDisabledForAllSocieties = societies.every(
    (_society) => _society.settings?.chatOneOnOneDisabled
  )
  const isGroupChatDisabledForAllSocieties = societies.every(
    (_society) => _society.settings?.chatInterestsDisabled
  )

  const isBoardMemberInOneSociety =
    userId &&
    societies.some((_society) => isUserBoardMemberInSociety(_society, userId))

  const chatDisabled =
    isOneOnOneChatDisabledForAllSocieties && isGroupChatDisabledForAllSocieties

  useEffect(() => {
    if (!chatRoomStore.hasFetchedChatRoomsInitially) {
      chatRoomStore.getUserRoomsOneOnOne()
      chatRoomStore.getUserRoomsInterests()
    }
    if (
      !chatRoomStore.hasFetchedUserRoomsBoardOnce &&
      isBoardMemberInOneSociety
    ) {
      chatRoomStore.getUserRoomsBoard()
    }
    if (userId) {
      if (!chatRoomStore.hasFetchedUserRoomsQuestionsResidentOnce) {
        chatRoomStore.getUserRoomsQuestions(userId, 'resident')
      }
      if (
        isBoardMemberInOneSociety &&
        !chatRoomStore.hasFetchedUserRoomsQuestionsBoardOnce
      ) {
        chatRoomStore.getUserRoomsQuestions(userId, 'board')
      }
      societyStore.getUserSocieties(userId, ['units', 'units.entrances'])
    }
    if (chatRoomId) {
      chatRoomStore.setSelectedChatRoom(chatRoomId)
      chatRoomStore.getRoom(chatRoomId)
    }
  }, [
    chatRoomId,
    chatRoomStore,
    isBoardMemberInOneSociety,
    societyStore,
    userId,
  ])

  useEffect(() => {
    if (
      !chatDisabled &&
      chatRoomStore.sortedChatRoomsWhereUserIsMember.length > 0 &&
      hasFetchedOneOnOneAndInterestsChatRoomsOnce &&
      !chatRoomStore.selectedChatRoom &&
      chatHeaderState === ChatHeaderState.None
    ) {
      chatRoomStore.setSelectedChatRoom(
        chatRoomStore.sortedChatRoomsWhereUserIsMember[0]._id
      )
      // TODO: set route here as well?
    }
  }, [
    chatRoomStore,
    chatRoomStore.selectedChatRoom,
    chatRoomStore.sortedChatRooms,
    chatHeaderState,
    chatDisabled,
    hasFetchedOneOnOneAndInterestsChatRoomsOnce,
  ])

  return (
    <>
      <SideBar className="gap-gutter flex" padding={false}>
        <ChatSideBarContent chatDisabled={chatDisabled} />
      </SideBar>
      <div className="flex flex-1">
        <ChatRoom />
      </div>
    </>
  )
})
