import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import Compressor from 'compressorjs'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { societyAboutSchema } from '../../../../forms/schemas/society_about'
import { TextInput } from '../../../../components/common/TextInput'
import { FormControl } from '../../../../components/common/FormControl'
import { SocietyModel } from '../../../../state/models/society'
import { HorizontalFormSection } from '../../../../components/common/HorizontalFormSection'
import { FormButtons } from '../../../../components/common/FormButtons'
import { getNumberOfUnitsLabel } from '../../../../helpers/society'
import { formatOrganisationNumber } from '../../../../helpers/organisation_number'
import { ButtonVariant } from '../../../../components/common/Button'
import { convertBase64 } from '../../../../api/helpers'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { useStores } from '../../../../hooks/useStores'
import { ToastType } from '../../../../components/common/Toast/toast-type'
import { MediaImage } from '../../../../components/common/Image'
import SocietyPlaceholder from '../../../../assets/illustrations/society_placeholder.svg'
import { Spinner } from '../../../../components/common/Spinner'
import { Label } from '../../../../components/common/Label'
import { isImageType } from '../../../../helpers/types'

interface UpdatePictureButton {
  onUploadImage: (e: React.ChangeEvent<HTMLInputElement>) => void
  uploadingPicture: boolean
}

const UpdatePictureButton = ({
  onUploadImage,
  uploadingPicture,
}: UpdatePictureButton): JSX.Element => {
  const { translate } = useAppTranslation()

  return (
    <div
      className="flex max-h-12 w-36 cursor-pointer items-center justify-center rounded border bg-black 
      px-4 py-3 text-white hover:bg-black-hover w-max"
    >
      {uploadingPicture ? (
        <Spinner />
      ) : (
        <>
          <Label
            className="cursor-pointer"
            name="chat-image-input"
            label={translate(
              'societyInformationView.labels.updateSocietyPicture'
            )}
          />
          <input
            id="chat-image-input"
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            className="h-full w-0 w-full max-w-0 cursor-pointer opacity-0"
            onChange={onUploadImage}
          />
        </>
      )}
    </div>
  )
}

interface SocietyInformationFormProps {
  onError: () => void
  onSubmit: (data: InferType<typeof societyAboutSchema>) => Promise<void>

  loading: boolean
  society: SnapshotOut<typeof SocietyModel>
}

export const SocietyInformationForm = observer(
  ({
    society,
    loading,
    onError,
    onSubmit,
  }: SocietyInformationFormProps): JSX.Element => {
    const { mediaStore } = useStores()
    const { translate } = useAppTranslation()
    const { getErrorMessage } = useFormErrorMessage()
    const [uploadingPicture, setUploadingPicture] = useState(false)
    const { setToastNotification } = useToastNotifications()

    const getDefaultValues = useCallback((): InferType<
      typeof societyAboutSchema
    > => {
      return {
        name: society.name || '',
        organisationNumber:
          formatOrganisationNumber(`${society.organisationNumber}`) || '',
        billingUnits: society.billingUnits || 0,
        addressCo: society.addressCo || '',
        addressStreet: society.addressStreet || '',
        addressZip: society.addressZip || '',
        addressCity: society.addressCity || '',
        coverPhotoId: society.coverPhotoId || undefined,
      }
    }, [society])

    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyAboutSchema),
      defaultValues: getDefaultValues(),
    })

    const uploadImage = async (base64: string): Promise<void> => {
      setUploadingPicture(true)

      try {
        const mediaId = await mediaStore.createMedia('image', base64)
        if (!mediaId) {
          throw new Error('no mediaId returned by createMedia')
        }

        setValue('coverPhotoId', mediaId, {
          shouldValidate: true,
          shouldDirty: true,
        })
      } catch (error) {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }

      setUploadingPicture(false)
    }

    const onUploadImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (!e.target.files) {
        return
      }
      const images = Array.from(e.target.files)
      if (images.length === 0) {
        return
      }
      const image = images[0]
      if (!isImageType(image.type)) {
        setToastNotification(
          ToastType.DANGER,
          translate('uploadImage.invalidImageType')
        )
        return
      }
      // eslint-disable-next-line no-new
      new Compressor(image, {
        quality: 0.8,
        success: async (result) => {
          const base64 = await convertBase64(result)
          await uploadImage(base64 as string)
        },
      })
    }

    const onDeleteCoverPhoto = (): void => {
      setValue('coverPhotoId', '', {
        shouldValidate: true,
        shouldDirty: true,
      })
    }

    return (
      <>
        <HorizontalFormSection
          title={translate(
            'societyInformationView.formSections.societyPicture'
          )}
        >
          <Controller
            control={control}
            name="coverPhotoId"
            render={({ field: { value, name } }) => (
              <FormControl
                error={errors.name && getErrorMessage(errors.name)}
                name={name}
              >
                {value ? (
                  <div className="flex w-full flex-col items-end gap-4">
                    <MediaImage
                      className="h-72 rounded border border-neutral-80 bg-neutral-96"
                      mediaId={value}
                      onDeleteClick={onDeleteCoverPhoto}
                      shouldCloseTopRight
                      objectFit="object-contain"
                    />
                    <UpdatePictureButton
                      onUploadImage={onUploadImage}
                      uploadingPicture={uploadingPicture}
                    />
                  </div>
                ) : (
                  <div className="flex flex-col items-end gap-4">
                    <div className="flex h-72 w-full rounded border border-neutral-80 bg-neutral-96">
                      <SocietyPlaceholder alt="society placeholder image" />
                    </div>
                    <UpdatePictureButton
                      onUploadImage={onUploadImage}
                      uploadingPicture={uploadingPicture}
                    />
                  </div>
                )}
              </FormControl>
            )}
          />
        </HorizontalFormSection>
        <HorizontalFormSection
          title={translate('societyInformationView.formSections.societyTitle')}
        >
          <Controller
            control={control}
            name="name"
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                error={errors.name && getErrorMessage(errors.name)}
                name={name}
              >
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label={translate('common.form.labels.name')}
                  error={!!errors.name}
                />
              </FormControl>
            )}
          />

          {/* Neighbourhoods have automatically generated organisation numbers. */}
          {society.societyTypeDisplayName !== 'neighbourhood' && (
            <Controller
              control={control}
              name="organisationNumber"
              render={({ field: { value, name, onChange, onBlur } }) => (
                <FormControl
                  error={
                    errors.organisationNumber &&
                    getErrorMessage(errors.organisationNumber)
                  }
                  name={name}
                >
                  <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={translate('common.form.labels.organisationNumber')}
                    error={!!errors.organisationNumber}
                    disabled
                  />
                </FormControl>
              )}
            />
          )}

          <Controller
            control={control}
            name="billingUnits"
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                error={
                  errors.billingUnits && getErrorMessage(errors.billingUnits)
                }
                name={name}
              >
                <TextInput
                  value={`${value}`}
                  onChange={onChange}
                  onBlur={onBlur}
                  label={getNumberOfUnitsLabel(society)}
                  error={!!errors.billingUnits}
                  disabled
                />
              </FormControl>
            )}
          />
        </HorizontalFormSection>

        <HorizontalFormSection
          title={translate('societyInformationView.formSections.addressTitle')}
        >
          <Controller
            control={control}
            name="addressCo"
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                error={errors.addressCo && getErrorMessage(errors.addressCo)}
                name={name}
              >
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label={translate('common.form.labels.addressCareOf')}
                  error={!!errors.addressCo}
                />
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="addressStreet"
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                error={
                  errors.addressStreet && getErrorMessage(errors.addressStreet)
                }
                name={name}
              >
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label={translate('common.form.labels.addressStreet')}
                  error={!!errors.addressStreet}
                />
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="addressZip"
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                error={errors.addressZip && getErrorMessage(errors.addressZip)}
                name={name}
              >
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label={translate('common.form.labels.addressZip')}
                  error={!!errors.addressZip}
                />
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="addressCity"
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                error={
                  errors.addressCity && getErrorMessage(errors.addressCity)
                }
                name={name}
              >
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label={translate('common.form.labels.addressCity')}
                  error={!!errors.addressCity}
                />
              </FormControl>
            )}
          />
        </HorizontalFormSection>
        <div className="w-full md:w-4/6">
          <FormButtons
            buttonsRight={[
              {
                label: translate('common.actions.save'),
                variant: ButtonVariant.PRIMARY,
                onClick: handleSubmit(onSubmit, onError),
                loading,
                disabled: !isValid || loading,
              },
            ]}
          />
        </div>
      </>
    )
  }
)
