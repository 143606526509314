import * as yup from 'yup'
import { unitRoleOptions } from './helpers'

export const societyUserUnitUpdateSchema = yup
  .object({
    _id: yup.string(),
    societyId: yup.string().required(),
    entranceId: yup.string(),
    unitIdOrTitle: yup.string().required(),
    userId: yup.string().required(),
    unitRole: yup.string().oneOf(unitRoleOptions).required(),
  })
  .required()

export const societyUserUnitsUpdateSchema = yup
  .object({
    units: yup.array(societyUserUnitUpdateSchema).min(1).required(),
  })
  .required()
