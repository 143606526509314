import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Link } from 'react-router-dom'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { SearchBar } from '../../common/SearchBar'
import { useStores } from '../../../hooks/useStores'
import { SearchSocietyModel } from '../../../state/models/society'
import { FindSocietyListItem } from './FindSocietyListItem'
import { FormControl } from '../../common/FormControl'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../../common/Icon'
import { SpinnerWrapper } from '../../common/SpinnerWrapper'
import { FindSocietyContent } from './FindSocietyContent'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { SocietyTypes } from '../../../types/society-type'
import { useIsMobile } from '../../../hooks/useIsMobile'

export const FindSociety = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const [selectedSociety, setSelectedSociety] = useState<
    SnapshotOut<typeof SearchSocietyModel> | undefined
  >()
  const { societyStore } = useStores()
  const [searchString, setSearchString] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchTimeout, setSearchTimeout] = useState<any>(0)
  const [searchTimeoutInProgress, setSearchTimeoutInProgress] = useState(false)
  const isMobile = useIsMobile()

  const onChangeSearchString = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    const searchWord = event.currentTarget.value
    if (searchString === searchWord) return
    setSearchString(searchWord)
    if (searchTimeout) clearTimeout(searchTimeout)
    if (searchWord !== '') {
      setSearchTimeoutInProgress(true)
    }

    setSearchTimeout(
      setTimeout(() => {
        if (searchWord !== '') {
          societyStore.searchSocieties(searchWord)
        }

        setSearchTimeoutInProgress(false)
      }, 400)
    )
  }

  useEffect(() => {
    if (selectedSociety?._id) {
      societyStore.getSociety(selectedSociety?._id, false)
    }
  }, [selectedSociety, societyStore])

  const societies = searchString ? societyStore.sortedSearchResultSocieties : []

  const loading =
    societyStore.searchingSocieties === 'pending' || searchTimeoutInProgress

  const userSocietyIds = societyStore.sortedSocieties.map(
    (_society) => _society._id
  )

  const onFocusSearch = (): void => {
    if (isMobile) {
      setSelectedSociety(undefined)
    }
  }

  const createSocietyPickTypeRoute = reverseUrl('create-society:pick-type')
  const createSocietyRoute = reverseUrl('create-society')

  return (
    <div className={`flex h-full ${isMobile ? 'flex-col' : ''}`}>
      <div
        className={`flex h-full flex-1 flex-col px-0 pt-4 md:flex-1 md:px-14 md:pt-0 ${
          !isMobile ? 'border-r' : ''
        }`}
      >
        <FormControl
          className="mt-4"
          name="searchbar"
          label={translate('findSociety.labels.search')}
        >
          <SearchBar
            widthClassName="w-full"
            className="w-full pr-6"
            value={searchString}
            onChange={onChangeSearchString}
            onFocus={onFocusSearch}
          />
        </FormControl>
        {searchString === '' && (
          <>
            <Link
              to={createSocietyRoute}
              state={{
                societyTypeDisplayName:
                  SocietyTypes.HOUSING_COOPERATIVE_CONSTRUCTION,
              }}
              className="mt-2 flex w-60 space-x-2"
            >
              <p className="hover:underline">
                {translate('findSociety.labels.brfDeveloper')}
              </p>
              <Icon icon={IconChoices.ARROW} color={theme.colors.brandGreen} />
            </Link>
            <Link
              to={createSocietyPickTypeRoute}
              className="mt-2 flex w-80 space-x-2 md:w-full"
            >
              <p className="hover:underline">
                {translate('findSociety.labels.cannotFindSociety')}
              </p>
              <Icon icon={IconChoices.ARROW} color={theme.colors.brandGreen} />
            </Link>
          </>
        )}
        {loading ? (
          <SpinnerWrapper className="mt-4" />
        ) : (
          <>
            {societies.length > 0 && (
              <div className="mt-6 flex min-h-0 grow flex-col overflow-auto pr-6">
                {societies.map((_society) =>
                  !userSocietyIds.includes(_society._id) ? (
                    <FindSocietyListItem
                      selected={selectedSociety?._id === _society._id}
                      onClick={() => {
                        setSelectedSociety(_society)
                        if (isMobile) {
                          setSearchString('')
                        }
                      }}
                      key={_society._id}
                      society={_society}
                    />
                  ) : (
                    <FindSocietyListItem
                      selected={selectedSociety?._id === _society._id}
                      key={_society._id}
                      society={_society}
                    />
                  )
                )}
              </div>
            )}
          </>
        )}
      </div>
      <div className="ml-0 mt-6 flex-2 overflow-y-auto py-4 px-2 md:mt-0 md:ml-6 md:flex-1 md:py-0 md:px-14">
        {selectedSociety && <FindSocietyContent society={selectedSociety} />}
      </div>
    </div>
  )
})
