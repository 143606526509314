import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ViewBase } from '../../../../components/common/ViewBase'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useStores } from '../../../../hooks/useStores'
import { IconChoices } from '../../../../components/common/Icon'
import { ButtonVariant } from '../../../../components/common/Button'
import { IllustrationChoices } from '../../../../components/common/Illustration'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { useModal } from '../../../../hooks/useModal'
// eslint-disable-next-line max-len
import { CreateUpdateLocalOfferModal } from '../../../../components/society/LocalOffer/CreateUpdateLocalOfferModal'
import { LocalOfferListItem } from '../../../../components/society/LocalOfferListItem'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'

export const LocalOffersView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { localOffersStore, authenticationStore } = useStores()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('society:local-offers', {
      '{{ societyName }}': society.name,
    })
  )
  const {
    show: showCreateModal,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModal()

  const isAdmin = isUserAdminInSociety(
    society,
    authenticationStore.userId as string
  )

  useEffect(() => {
    if (society._id) localOffersStore.getLocalOffersPerSociety(society._id)
  }, [localOffersStore, society._id])

  const localOffers = localOffersStore.sortedLocalOffersForSociety(society._id)
  const loading = localOffersStore.fetchingLocalOffers === 'pending'

  const errorView =
    !loading && localOffers.length === 0
      ? {
          title: translate('localOffersView.emptyState.title'),
          subtitle: translate('localOffersView.emptyState.subtitle'),
          illustration: IllustrationChoices.EMPTY,
        }
      : undefined

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('localOffersView.title')}
        errorView={errorView}
        buttons={
          isAdmin
            ? [
                {
                  label: translate('common.actions.add'),
                  variant: ButtonVariant.PRIMARY,
                  icon: IconChoices.PLUS_SIGN,
                  onClick: openCreateModal,
                },
              ]
            : undefined
        }
      >
        <div className="flex flex-wrap gap-4">
          {localOffers.map((_offer) => (
            <LocalOfferListItem key={_offer._id} localOffer={_offer} />
          ))}
        </div>
      </ViewBase>
      <CreateUpdateLocalOfferModal
        show={showCreateModal}
        close={closeCreateModal}
      />
    </ErrorBoundary>
  )
})
