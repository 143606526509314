import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { commentSchema } from '../../../forms/schemas/comment'
import { useStores } from '../../../hooks/useStores'
import { Button, ButtonVariant } from '../../common/Button'
import { TextArea } from '../../common/TextArea'
import { IconChoices } from '../../common/Icon'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { ToastType } from '../../common/Toast/toast-type'

interface CommentInputProps {
  postId: string
}

export const CommentInput = observer(
  ({ postId }: CommentInputProps): JSX.Element => {
    const { commentStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const {
      control,
      handleSubmit,
      setValue,
      formState: { isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(commentSchema),
      defaultValues: { comment: '' },
    })

    const onSubmit = async (
      data: InferType<typeof commentSchema>
    ): Promise<void> => {
      const success = await commentStore.create(postId, data.comment)
      if (success) {
        setValue('comment', '')
      } else {
        showErrorMessage()
      }
    }

    const onError = (): void => {
      showErrorMessage()
    }

    // const onTextAreaEnterSubmit = (): void => {
    //   handleSubmit(onSubmit)()
    // }

    return (
      <form onSubmit={handleSubmit(onSubmit, onError)} className="mt-2 flex">
        <div className="flex flex-1 gap-2 self-end">
          <div className="flex w-full flex-col">
            <Controller
              control={control}
              name="comment"
              render={({ field: { value, onChange, onBlur } }) => (
                <TextArea
                  placeholder={translate('post.commentInputPlaceholder')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  rows={1}
                  fixedHeight={false}
                  // handleSubmit={onTextAreaEnterSubmit}
                />
              )}
            />
          </div>
          <div className="flex self-end bg-white">
            <Button
              type="submit"
              disabled={!isValid}
              className="h-11 w-11 border-none"
              size="no-padding"
              iconSize={18}
              variant={ButtonVariant.PRIMARY}
              icon={IconChoices.SEND}
            />
          </div>
        </div>
      </form>
    )
  }
)
