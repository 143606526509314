/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'

export const GroupModel = types.model('Group', {
  _id: types.identifier,
  ...timestampTypes,
  societyId: types.string,
  membersList: types.maybe(
    types.array(
      types.model('GroupMember', {
        _id: types.identifier,
        // Use string and not identifier since we can't use populate on groups endpoint.
        // Due to this, we can't guarantee a reference and instead use string and filter
        // on undefined references.
        userId: types.string,
      })
    )
  ),
  unitsList: types.maybe(
    types.array(
      types.model('GroupMember', {
        _id: types.identifier,
        // Use string and not identifier since we can't use populate on groups endpoint,
        // Due to this, we can't guarantee a reference and instead use string and filter
        //  on undefined references.
        unitId: types.string,
      })
    )
  ),
  name: types.string,
})
