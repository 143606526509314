import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { PreApprovedModel } from '../../../../state/models/society'
import { theme } from '../../../../theme/theme'
import { Button, ButtonVariant } from '../../../common/Button'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { PreApprovedListItem } from './PreApprovedListItem'
import { useStores } from '../../../../hooks/useStores'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { useModal } from '../../../../hooks/useModal'
import { ConfirmationModal } from '../../../common/ConfirmationModal'

interface PreApprovedModalProps {
  show: boolean
  close: () => void
  preApproved: SnapshotOut<typeof PreApprovedModel>[]
  children?: React.ReactNode
}

export const PreApprovedModal = observer(
  ({
    children,
    show,
    close,
    preApproved,
  }: PreApprovedModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { societyStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const {
      show: showRemindAllConfirmationModal,
      open: openRemindAllConfirmationModal,
      close: closeRemindAllConfirmationModal,
    } = useModal()

    const sendAllUsersReminder = async (): Promise<void> => {
      const status = await societyStore.inviteAllPreApprovedUsers(society._id)
      if (status) {
        close()
        setToastNotification(
          ToastType.SUCCESS,
          translate('preApproved.flashMessage.remindAllSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('preApproved.flashMessage.remindAllFailure')
        )
      }
    }

    const invitingAllPreApproved =
      societyStore.invitingAllPreApprovedUsers === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                <div>
                  <p style={theme.textVariants.h3}>
                    {translate('preApproved.title')}
                  </p>

                  <p style={theme.textVariants.base}>
                    {translate('preApproved.subtitle')}
                  </p>
                </div>
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <div>
                  {preApproved.map((_preApproved, index) => {
                    return (
                      <PreApprovedListItem
                        key={_preApproved._id}
                        preApproved={_preApproved}
                        className={
                          index === preApproved.length - 1 ? 'border-b' : ''
                        }
                        disableButtons={invitingAllPreApproved}
                      />
                    )
                  })}
                </div>
                <div className="mt-2 self-end">
                  <Button
                    label={translate('common.actions.cancel')}
                    variant={ButtonVariant.TEXT}
                    onClick={() => close()}
                    className="mr-4"
                  />
                  <Button
                    label={translate('preApproved.buttons.remindAllTitle')}
                    variant={ButtonVariant.PRIMARY}
                    onClick={openRemindAllConfirmationModal}
                    disabled={invitingAllPreApproved}
                    loading={invitingAllPreApproved}
                  />
                </div>
              </ModalBody>
            ),
          }}
        </Modal>
        <ConfirmationModal
          title={translate('preApproved.confirmationDialog.remindAllTitle')}
          description={translate(
            'preApproved.confirmationDialog.remindAllDescription'
          )}
          show={showRemindAllConfirmationModal}
          close={closeRemindAllConfirmationModal}
          onConfirm={sendAllUsersReminder}
          confirmationButtonLabel={translate('common.actions.send')}
        />
      </>
    )
  }
)
