import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { theme } from '../../../../theme/theme'
import { DropdownSelect } from '../../../common/DropdownSelect'
import { useStores } from '../../../../hooks/useStores'
import { FormControl } from '../../../common/FormControl'
import { unique } from '../../../../helpers/array'
import { SocietyModel } from '../../../../state/models/society'
import { UserAvatar } from '../../../common/Avatar'
import { PreApprovedListItem } from './PreApprovedListItem'
import { useHouseholdMembersPreApproved } from './useHouseholdMembersPreApproved'
import { AddPerson } from './AddPerson'
import { ErrorView } from '../../../../views/error/ErrorView'
import { IllustrationChoices } from '../../../common/Illustration'
import { useAuth } from '../../../../hooks/useAuth'

export const ManageHouseholdModalContent = observer((): JSX.Element => {
  const { societyStore, unitStore } = useStores()
  const { translate } = useAppTranslation()
  const { userId } = useAuth()

  const units = unitStore.userUnits(userId as string, undefined)

  const societiesWithAssignedUnit = unique(
    units.map((_unit) => _unit.societyId)
  )
    .map(
      (_societyId) =>
        societyStore.societies.get(_societyId) as
          | SnapshotOut<typeof SocietyModel>
          | undefined
    )
    .filter((_society) => _society !== undefined) as SnapshotOut<
    typeof SocietyModel
  >[]

  const [selectedSocietyId, setSelectedSocietyId] = useState(
    societiesWithAssignedUnit[0]?._id
  )

  const { householdUsers, preApprovedUsers } =
    useHouseholdMembersPreApproved(selectedSocietyId)

  const societyOptions = societiesWithAssignedUnit.map((_society) => ({
    value: _society._id,
    label: _society.name,
  }))

  /*
  We have some room for improvement here for users with multiple units in a society.
  This will not support that. 

  I'm copying the behaviour from the current app, and we can improve this in the future.
  */
  const selectedUnit = units.find(
    (_unit) => _unit.societyId === selectedSocietyId
  )

  if (societyOptions.length === 0) {
    return (
      <ErrorView
        title={translate('manageHousehold.errorViews.noSociety.title')}
        subtitle={translate('manageHousehold.errorViews.noSociety.subtitle')}
        illustration={IllustrationChoices.EMPTY}
        className="mb-10"
      />
    )
  }

  return (
    <>
      <div className="flex flex-col">
        <p style={theme.textVariants.lead}>
          {translate('manageHousehold.title')}
        </p>
        <p style={theme.textVariants.base}>
          {translate('manageHousehold.subtitle')}
        </p>
        <FormControl
          label={translate('common.form.labels.society')}
          name="society"
        >
          <DropdownSelect
            value={selectedSocietyId}
            options={societyOptions}
            dropdownWidth="w-64"
            onChange={setSelectedSocietyId}
          />
        </FormControl>
        <div className="mt-6 mb-4 border-b" />
        <p style={theme.textVariants.lead} className="mb-6">
          {translate('manageHousehold.form.headers.household')}
        </p>
        <div className="flex flex-col gap-4">
          {householdUsers &&
            householdUsers.map((_user) => (
              <div key={_user._id} className="flex items-center">
                <UserAvatar user={_user} />
                <p
                  style={theme.textVariants.baseBold}
                  className="ml-2"
                  translate="no"
                >
                  {_user.fullName}
                </p>
              </div>
            ))}
        </div>
        {selectedUnit && selectedSocietyId && (
          <AddPerson unitId={selectedUnit._id} societyId={selectedSocietyId} />
        )}
        {preApprovedUsers && preApprovedUsers.length > 0 && (
          <div className="mb-6">
            <div className="mt-6 mb-4 border-b" />
            <p style={theme.textVariants.lead} className="mb-4">
              {translate('manageHousehold.form.headers.pendingInvites')}
            </p>
            <div className="flex flex-col gap-4">
              {preApprovedUsers.map((preApproved) => (
                <PreApprovedListItem
                  key={preApproved._id}
                  preApproved={preApproved}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
})
