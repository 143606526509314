import React from 'react'

interface FormSpacingProps {
  children: React.ReactNode
  className?: string
}

export const FormSpacing = ({
  children,
  className,
}: FormSpacingProps): JSX.Element => {
  return (
    <div className={`flex flex-col gap-4 ${className ?? ''}`}>{children}</div>
  )
}
