import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, UserAvatar } from '../components/common/Avatar'
import { getOneOnOneUserId } from '../helpers/chat'
import { reverseUrl } from '../navigation/reverseUrl'
import { useAuthenticatedUserId } from './useAuthenticatedUserId'
import { useStores } from './useStores'

export function useChatRoomAvatar(
  id: string | undefined,
  size: number | undefined = 40
): JSX.Element {
  const { chatRoomStore, userStore } = useStores()
  const authenticatedUserId = useAuthenticatedUserId()
  const chatRoom = id ? chatRoomStore.chatRooms.get(id) : undefined
  const navigate = useNavigate()

  const getAvatar = useCallback((): JSX.Element => {
    if (!chatRoom) {
      // TODO: Handle
      return <Avatar size={size} mediaId={undefined} />
    }

    if (chatRoom.type === 'interests' && !chatRoom.avatarId) {
      return <Avatar size={size} mediaId={undefined} placeholder="interests" />
    }

    if (chatRoom.type === 'oneonone') {
      const otherUserId = getOneOnOneUserId(authenticatedUserId, chatRoom)
      const otherUser = otherUserId
        ? userStore.users.get(otherUserId)
        : undefined

      if (otherUser) {
        return (
          <UserAvatar
            size={size}
            user={otherUser}
            onClick={() =>
              navigate(
                reverseUrl('user:society-detail', {
                  id: otherUser._id,
                  societyId: chatRoom.societyId,
                })
              )
            }
          />
        )
      }
    }

    return <Avatar size={size} mediaId={chatRoom.avatarId} />
  }, [authenticatedUserId, chatRoom, size, userStore.users, navigate])

  return getAvatar()
}
