import { SnapshotOut } from 'mobx-state-tree'
import { useEffect } from 'react'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { useStores } from '../../../../hooks/useStores'
import { FacilityModel } from '../../../../state/models/facility'

// Fetch bookings for all authenticated user units
export const useUserUnitBookings = (
  facility: SnapshotOut<typeof FacilityModel>
): void => {
  const { unitStore, bookingsStore } = useStores()
  const userId = useAuthenticatedUserId() as string

  useEffect(() => {
    if (facility.societyId) {
      unitStore.getUnitsPerSociety(facility.societyId)
      if (userId) {
        unitStore.userUnits(userId, facility.societyId).forEach((_unit) => {
          bookingsStore.getBookingsPerUnit(_unit._id, new Date())
        })
      }
    }
    // don't fetch multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
