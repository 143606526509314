/* eslint-disable max-len */
import React from 'react'
import { RouteObject } from 'react-router-dom'
import { BoardContactsView } from '../../../views/society/Management/BoardContactsView'
import { GDPRView } from '../../../views/society/Management/GDPRView'
import { SocietyInformationView } from '../../../views/society/Management/SocietyInformationView'
import { SocietyStatisticsExportsView } from '../../../views/society/Management/SocietyStatisticsExportsView'
import { BostadsratternaGuidanceView } from '../../../views/society/Management/Bostadsratterna/BostadsratternaGuidanceView'
import { BostadsratternaSchoolView } from '../../../views/society/Management/Bostadsratterna/BostadsratternaSchoolView'
import { BostadsratternaFormsView } from '../../../views/society/Management/Bostadsratterna/BostadsratternaFormsView'
import { BostadsratternaBenefitsView } from '../../../views/society/Management/Bostadsratterna/BostadsratternaBenefitsView'
import { BostadsratternaStatutesView } from '../../../views/society/Management/Bostadsratterna/BostadsratternaStatutesView'
import { PartnerRoute } from '../PartnerRoute'
import { SamfalligheternaGuidanceView } from '../../../views/society/Management/Samfalligheterna/SamfalligheternaGuidanceView'
import { SamfalligheternaSchoolView } from '../../../views/society/Management/Samfalligheterna/SamfalligheternaSchoolView'
import { SamfalligheternaBenefitsView } from '../../../views/society/Management/Samfalligheterna/SamfalligheternaBenefitsView'
import { ManagementPartner } from '../../../components/society/ManagementPartner/management_partner'
import { ManagementDocumentsView } from '../../../views/society/Management/ManagementDocumentsView'
import { FunctionSettingsView } from '../../../views/society/Management/FunctionSettingsView'
import { RegisterGuidanceView } from '../../../views/society/Management/RegisterGuidanceView'
import { MemberGuidanceView } from '../../../views/society/Management/MemberGuidanceView'
import { SocietyAdminRoot } from '../../../views/society/SocietyAdminRoot'
import { useSocietyAdminRoutes } from './useSocietyAdminRoutes'
import { SocietyContractsView } from '../../../views/society/Management/SocietyContractsView'
import { SocietyContractDetailView } from '../../../views/society/Management/SocietyContractDetailView'
import { BoardCalendarView } from '../../../views/society/Management/BoardCalendarView'
import { EventDetailView } from '../../../views/society/EventDetailView'

const useSocietyManagementRoutes = (): RouteObject[] => {
  const societyAdminRoutes = useSocietyAdminRoutes()

  return [
    {
      path: 'register-guidance',
      element: <RegisterGuidanceView />,
    },
    {
      path: 'member-guidance',
      element: <MemberGuidanceView />,
    },
    {
      path: 'function-settings',
      element: <FunctionSettingsView />,
    },
    {
      path: 'board/documents',
      element: <ManagementDocumentsView />,
    },
    {
      path: 'board/contacts',
      element: <BoardContactsView />,
    },
    {
      path: 'board/calendar',
      element: <BoardCalendarView />,
    },
    {
      path: 'board/calendar/:eventId/*',
      element: <EventDetailView boardRoomEvent />,
    },
    {
      path: 'board/statistics-exports',
      element: <SocietyStatisticsExportsView />,
    },
    {
      path: 'board/documents/:documentId',
      element: <ManagementDocumentsView />,
    },
    {
      path: 'board/information',
      element: <SocietyInformationView />,
    },
    {
      path: 'board/contracts',
      element: <SocietyContractsView />,
    },
    {
      path: 'board/contracts/:contractId',
      element: <SocietyContractDetailView />,
    },
    {
      path: 'board/gdpr',
      element: <GDPRView />,
    },
    {
      path: 'admin',
      element: <SocietyAdminRoot />,
      children: societyAdminRoutes,
    },
    {
      path: 'bostadsratterna/guidance',
      element: (
        <PartnerRoute partner={ManagementPartner.BOSTADSRATTERNA}>
          <BostadsratternaGuidanceView />
        </PartnerRoute>
      ),
    },
    {
      path: 'bostadsratterna/benefits',
      element: (
        <PartnerRoute partner={ManagementPartner.BOSTADSRATTERNA}>
          <BostadsratternaBenefitsView />
        </PartnerRoute>
      ),
    },
    {
      path: 'bostadsratterna/school',
      element: (
        <PartnerRoute partner={ManagementPartner.BOSTADSRATTERNA}>
          <BostadsratternaSchoolView />
        </PartnerRoute>
      ),
    },
    {
      path: 'bostadsratterna/statutes',
      element: (
        <PartnerRoute partner={ManagementPartner.BOSTADSRATTERNA}>
          <BostadsratternaStatutesView />
        </PartnerRoute>
      ),
    },
    {
      path: 'bostadsratterna/forms',
      element: (
        <PartnerRoute partner={ManagementPartner.BOSTADSRATTERNA}>
          <BostadsratternaFormsView />
        </PartnerRoute>
      ),
    },
    {
      path: 'samfalligheterna/guidance',
      element: (
        <PartnerRoute partner={ManagementPartner.VILLAAGARNA}>
          <SamfalligheternaGuidanceView />
        </PartnerRoute>
      ),
    },
    {
      path: 'samfalligheterna/school',
      element: (
        <PartnerRoute partner={ManagementPartner.VILLAAGARNA}>
          <SamfalligheternaSchoolView />
        </PartnerRoute>
      ),
    },
    {
      path: 'samfalligheterna/benefits',
      element: (
        <PartnerRoute partner={ManagementPartner.VILLAAGARNA}>
          <SamfalligheternaBenefitsView />
        </PartnerRoute>
      ),
    },
  ]
}

useSocietyManagementRoutes.displayName = 'useSocietyManagementRoutes'
export { useSocietyManagementRoutes }
