import React from 'react'
import { Noop } from 'react-hook-form'
import { TextInput } from '../TextInput'

interface OrganisationNumberTextInputProps {
  value: string
  className?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void
  onBlur: Noop
  onFocus?: () => void
}

export const OrganisationNumberTextInput = ({
  value,
  className,
  onChange,
  onBlur,
  onFocus,
}: OrganisationNumberTextInputProps): JSX.Element | null => {
  const getParsedOrganisationNumber = (val: string): string => {
    const newValue = val.replace(/-/g, '').substring(0, 10)
    return newValue
  }
  return (
    <TextInput
      className={className}
      value={value}
      onChange={(e) => {
        const element = e.currentTarget
        const caret = element.selectionStart
        window.requestAnimationFrame(() => {
          element.selectionStart = caret
          element.selectionEnd = caret
        })
        const newValue = getParsedOrganisationNumber(e.currentTarget.value)
        onChange(newValue)
      }}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  )
}
