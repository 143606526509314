/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'
import { ResidentRoleType } from './resident'

export const UnitResidentModel = types.model('UnitResident', {
  userId: types.string,
  role: ResidentRoleType,
})

export const UnitModel = types.model('Unit', {
  _id: types.identifier,
  ...timestampTypes,
  societyId: types.string,
  title: types.string,
  titleLegal: types.maybe(types.string),
  isUnassigned: types.maybe(types.boolean),
  entranceId: types.maybe(types.string),
  floor: types.maybe(types.integer),
  residentsList: types.array(UnitResidentModel),
})
