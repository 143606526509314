import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useTranslation } from 'react-i18next'
import { Button, ButtonVariant } from '../../common/Button'
import { HorizontalFormSection } from '../../common/HorizontalFormSection'
import { IconChoices } from '../../common/Icon'
import { ToastType } from '../../common/Toast/toast-type'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useAuthenticatedUserId } from '../../../hooks/useAuthenticatedUserId'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { NUsers } from '../../../interfaces/services/users.interfaces'
import { UserModel } from '../../../state/models/user'
import { LanguageListItem } from './LanguageListItem'
import { LocalStorageKeys } from '../../../types/local-storage'
import { getLanguageCode } from '../../../helpers/translations/helpers'

export const ChangeLanguage = observer((): JSX.Element => {
  const { userStore } = useStores()
  const { i18n } = useTranslation()
  const { translate } = useAppTranslation()
  const { setToastNotification } = useToastNotifications()
  const [selectedLocale, setSelectedLocale] = useState<string | undefined>(
    undefined
  )
  const userId = useAuthenticatedUserId() as string
  const user = userStore.users.get(userId) as SnapshotOut<typeof UserModel>

  // Name and description should not be translated
  const languages = [
    {
      name: 'Svenska',
      description: 'Samtlig information i Boappa angiven på Svenska',
      flag: '🇸🇪',
      translatedName: translate('common.languages.swedish'),
      locale: 'sv-SE',
    },
    {
      name: 'English',
      description: 'The language in Boappa is written in English',
      flag: '🇬🇧',
      translatedName: translate('common.languages.english'),
      locale: 'en-EN',
    },
  ]

  useEffect(() => {
    const getLanguage = (): string => {
      if (!user?.language) {
        return 'se'
      }
      return user.language === 'se' ? 'sv-SE' : 'en-EN'
    }
    const language = getLanguage()
    setSelectedLocale(language || i18n.language)
  }, [user?.language, i18n.language])

  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()

    const formData: NUsers.NPatch.IRequestBody = {
      language: getLanguageCode(selectedLocale),
    }
    const status = await userStore.patchUser(user._id, formData)

    if (status) {
      localStorage.setItem(LocalStorageKeys.LOCALE, selectedLocale || 'sv-SE')
      i18n.changeLanguage(selectedLocale || 'sv')
      document.documentElement.lang = selectedLocale?.includes('sv')
        ? 'sv'
        : 'en'
      setToastNotification(
        ToastType.SUCCESS,
        translate('flashMessage.changesSaved')
      )
    } else {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.changesNotSavedError')
      )
    }
  }

  const loading = userStore.updatingUser === 'pending'

  return (
    <form onSubmit={onSubmit}>
      <HorizontalFormSection
        title={translate('changeLanguage.title')}
        icon={IconChoices.WEBSITE}
        iconPlacement="left"
      >
        {languages.map((_language) => (
          <LanguageListItem
            key={_language.name}
            language={_language.name}
            flag={_language.flag}
            locale={_language.locale}
            selected={_language.locale === selectedLocale}
            setSelectedLocale={setSelectedLocale}
            description={_language.description}
          />
        ))}
      </HorizontalFormSection>
      <HorizontalFormSection title="">
        <div className="flex justify-end">
          <Button
            label={translate('common.actions.save')}
            type="submit"
            loading={loading}
            disabled={loading}
            variant={ButtonVariant.PRIMARY}
          />
        </div>
      </HorizontalFormSection>
    </form>
  )
})
