import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { societyRequestSchema } from '../../../../forms/schemas/society_request'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { UnitRole } from '../../../../types/unit-roles'
import { useStores } from '../../../../hooks/useStores'
import { getUnitRole } from '../../../../helpers/translations/unit-roles'
import { ToastType } from '../../../common/Toast/toast-type'
import { NRequests } from '../../../../interfaces/services/requests.interfaces'
import { SocietyAvatar } from '../../../common/Avatar'
import { SocietyModel } from '../../../../state/models/society'
import { theme } from '../../../../theme/theme'
import { FormControl } from '../../../common/FormControl'
import { SelectDropdown } from '../../../common/SelectDropdown'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { Button, ButtonVariant } from '../../../common/Button'
import { SocietyInformation } from '../SocietyInformation'

interface RequestJoinSocietyFormProps {
  society: SnapshotOut<typeof SocietyModel>
  onFinish?: () => void
}

export const RequestJoinSocietyForm = observer(
  ({ society, onFinish }: RequestJoinSocietyFormProps): JSX.Element => {
    const {
      societyEntrancesStore,
      unitStore,
      requestStore,
      authenticationStore,
    } = useStores()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const { getErrorMessage } = useFormErrorMessage()

    const getDefaultValues = (): InferType<typeof societyRequestSchema> => {
      return {
        unitId: '',
        entranceId: '',
        role: UnitRole.MEMBER,
      }
    }

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
      watch,
      setValue,
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyRequestSchema),
      defaultValues: getDefaultValues(),
    })

    const watchEntranceId = watch('entranceId')

    const entrances =
      societyEntrancesStore.entrancesForSociety(society._id) || []
    const entranceOptions = entrances.map((entrance) => ({
      label: entrance.addressStreet || '',
      value: entrance._id,
    }))

    const units = unitStore.unitsForSociety(society._id) || []

    const entranceUnitOptions = units
      .filter((unit) => {
        return unit.entranceId === watchEntranceId
      })
      .map((unit) => {
        if (unit.isUnassigned) {
          return {
            value: unit._id,
            label: translate('common.unit.unassigned'),
          }
        }
        return {
          value: unit._id,
          label: `${unit.titleLegal} (${unit.title})`,
        }
      })

    const unitRoleOptions = Object.values(UnitRole).map((val) => ({
      value: val as string,
      label: translate(getUnitRole(val) as string),
    }))

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('requestJoinSocietyForm.flashMessage.sendRequestFailure')
      )
    }

    const showSuccessMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('requestJoinSocietyForm.flashMessage.sendRequestSuccess')
      )
    }

    const onSubmit = async (
      data: InferType<typeof societyRequestSchema>
    ): Promise<void> => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const body: NRequests.NCreate.IRequestBody = {
        ...data,
        userId: authenticationStore.userId as string,
        societyId: society._id as string,
      }

      const status = await requestStore.sendRequest(body)
      if (status) {
        showSuccessMessage()
        if (onFinish) onFinish()
      } else {
        showErrorMessage()
      }
    }

    const onError = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const societyIsSamf = society.isSamf

    const title = societyIsSamf
      ? translate('requestJoinSocietyForm.titleSamf')
      : translate('requestJoinSocietyForm.titleBrf')

    const loading = requestStore.sendingRequest === 'pending'

    return (
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit, onError)}>
        <p style={theme.textVariants.h3}>{title}</p>
        <div className="flex flex-col items-center gap-1">
          <SocietyAvatar society={society} size={96} />
          <SocietyInformation society={society} />
        </div>
        <div className="border-b" />
        <Controller
          control={control}
          name="entranceId"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate(
                societyIsSamf
                  ? 'common.form.labels.entranceSamf'
                  : 'common.form.labels.entrance'
              )}
              error={errors.entranceId && getErrorMessage(errors.entranceId)}
              name={name}
            >
              <SelectDropdown
                options={entranceOptions}
                value={value}
                onChange={(val) => {
                  onChange(val)
                  setValue('unitId', '', { shouldValidate: true })
                }}
                error={!!errors.entranceId}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="unitId"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate(
                societyIsSamf
                  ? 'common.form.labels.unitSamf'
                  : 'common.form.labels.unitBrf'
              )}
              error={errors.unitId && getErrorMessage(errors.unitId)}
              name={name}
              infoTooltipTitle={translate(
                'requestJoinSocietyForm.tooltip.unit'
              )}
            >
              <SelectDropdown
                options={entranceUnitOptions}
                value={value}
                onChange={onChange}
                error={!!errors.unitId}
                menuPlacement="auto"
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="role"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate('common.form.labels.unitRole')}
              error={errors.role && getErrorMessage(errors.role)}
              name={name}
              infoTooltipTitle={translate(
                'requestJoinSocietyForm.tooltip.role'
              )}
            >
              <SelectDropdown
                options={unitRoleOptions}
                value={value}
                onChange={onChange}
                error={!!errors.role}
              />
            </FormControl>
          )}
        />
        <Button
          wrapperClassName="w-full"
          className="w-full"
          variant={ButtonVariant.PRIMARY}
          loading={loading}
          disabled={!isValid || loading}
          label={translate('requestJoinSocietyForm.sendRequest')}
          type="submit"
        />
      </form>
    )
  }
)
