import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'

export const ContractModel = types.model('ContractModel', {
  _id: types.identifier,
  ...timestampTypes,
  title: types.string,
  company: types.maybe(types.string),
  description: types.maybe(types.string),

  documentIds: types.maybe(types.array(types.string)),
  societyId: types.string,

  running: types.maybe(types.boolean),
  startDate: types.maybe(types.union(types.string, types.null)),
  endDate: types.maybe(types.union(types.string, types.null)),
  notificationDate: types.maybe(types.union(types.string, types.null)),

  contactName: types.maybe(types.string),
  contactEmail: types.maybe(types.string),
  contactPhone: types.maybe(types.string),
  contactWebsite: types.maybe(types.string),

  status: types.enumeration('ContractStatus', ['active', 'terminated']),
})
