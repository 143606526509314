/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, getRoot, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import {
  PreApprovedModel,
  SearchSocietyModel,
  SocietyModel,
} from '../models/society'
import { ISocietiesModel } from '../../interfaces/models/societies.interfaces'
import { NSocieties } from '../../interfaces/services/societies.interfaces'
import {
  getUserSocieties as apiGetUserSocieties,
  getSociety as apiGetSociety,
  createSociety as apiCreateSociety,
  listSocieties as apiListSocieties,
  patchSociety as apiPatchSociety,
  searchSocieties as apiSearchSocieties,
  addAdminUsers as apiAddAdminUsers,
  removeAdminUser as apiRemoveAdminUser,
  addBlockedUsers as apiAddBlockedUsers,
  removeBlockedUser as apiRemoveBlockedUser,
  removePreApprovedUser as apiRemovePreApprovedUser,
  sendPreApprovedUserInviteReminder as apiSendPreApprovedUserInviteReminder,
  inviteAllPreApprovedUsers as apiInviteAllPreApprovedUsers,
  createUserRole as apiCreateUserRole,
  deleteUserRole as apiDeleteUserRole,
  removeUserFromSociety as apiRemoveUserFromSociety,
  removeSelfFromSociety as apiRemoveSelfFromSociety,
  activateSociety as apiActivateSociety,
  getUserBlockedSocietyIds as apiGetUserBlockedSocietyIds,
} from '../../api/societies'
import { stateType } from '../types/common'
import { RootStore } from './root'
import { setObject } from './helpers'
import { sortStringsAlphabetically } from '../../helpers/sorting'

export const SocietyStore = types
  .model('SocietyStore')
  .props({
    societies: types.map(SocietyModel),
    // More limited data in search so save in another map
    searchResultSocieties: types.map(SearchSocietyModel),
    searchResult: types.array(SearchSocietyModel),
    userIsBlockedSocietyIds: types.array(types.string),
    preApproved: types.map(PreApprovedModel),
    selectedSocietyId: types.maybe(types.string),
    creatingSociety: stateType,
    fetchingSocieties: stateType,
    fetchingSociety: stateType,
    updatingSociety: stateType,
    fetchingPreApprovedSocieties: stateType,
    fetchingSocietiesByOrganisationNumber: stateType,
    denyingPreApproved: stateType,
    searchingSocieties: stateType,
    updatingAdminUsers: stateType,
    updatingBlockedUsers: stateType,
    updatingMasterAdmin: stateType,
    invitingAllPreApprovedUsers: stateType,
    creatingUserRole: stateType,
    deletingUserRole: stateType,
    removingUserFromSociety: stateType,
    removingSelfFromSociety: stateType,
    activatingSociety: stateType,
    hasFetchedSocietiesOnce: types.boolean,
  })
  .views((self) => ({
    get sortedSocieties(): SnapshotOut<typeof SocietyModel>[] {
      // @ts-ignore
      return (values(self.societies) as SnapshotOut<typeof SocietyModel>[])
        .filter((_society) => _society.member === true)
        .sort((a, b) => sortStringsAlphabetically(a.name, b.name))
    },
    get sortedSearchResultSocieties(): SnapshotOut<
      typeof SearchSocietyModel
    >[] {
      return (
        values(self.searchResult) as SnapshotOut<typeof SearchSocietyModel>[]
      ).sort((a, b) => sortStringsAlphabetically(a.name, b.name))
    },
  }))
  .views((self) => ({
    get selectedSociety(): SnapshotOut<typeof SocietyModel> | undefined {
      // @ts-ignore
      if (self.selectedSocietyId) {
        // @ts-ignore
        return self.societies.get(self.selectedSocietyId)
      }
      if (self.societies.size > 0) {
        return self.sortedSocieties[0]
      }

      return undefined
    },
    getUserPreApproved(
      userEmail: string
    ): SnapshotOut<typeof PreApprovedModel>[] {
      // @ts-ignore
      return (
        // @ts-ignore
        (values(self.preApproved) as SnapshotOut<typeof PreApprovedModel>[]) //
          .filter((_preApproved) => _preApproved.userEmail === userEmail)
      )
    },
    canRemoveUserFromSociety(userId: string, societyId: string): boolean {
      const _society = self.societies.get(societyId)
      if (!_society) {
        return false
      }

      if (!_society.masterAdminUserId) {
        return true
      }

      const { unitStore } = getRoot<RootStore>(self)
      const _residentUsers = unitStore.residentUsersForSociety(societyId)

      // Safeguard for a problem where the backend did not return this property on the object
      // (meaning we could send delete request for the master admin). Better disallow removal in this case
      if (
        !Object.prototype.hasOwnProperty.call(_society, 'masterAdminUserId')
      ) {
        return false
      }

      if (_society.masterAdminUserId === userId && _residentUsers.length > 1) {
        // If the user is master admin and there are other users in the society
        // we cannot allow the user to delete their account
        return false
      }

      return true
    },
    get sortedSocietiesWithChatInterestsEnabled(): SnapshotOut<
      typeof SocietyModel
    >[] {
      return self.sortedSocieties.filter(
        (_society) => !_society.settings?.chatInterestsDisabled
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.societies.clear()
      self.searchResultSocieties.clear()
      self.searchResult.clear()
      self.userIsBlockedSocietyIds.clear()
      self.preApproved.clear()
      self.selectedSocietyId = undefined
      self.creatingSociety = 'none'
      self.fetchingSocieties = 'none'
      self.fetchingSociety = 'none'
      self.updatingSociety = 'none'
      self.searchingSocieties = 'none'
      self.updatingAdminUsers = 'none'
      self.updatingBlockedUsers = 'none'
      self.updatingMasterAdmin = 'none'
      self.invitingAllPreApprovedUsers = 'none'
      self.creatingUserRole = 'none'
      self.deletingUserRole = 'none'
      self.removingUserFromSociety = 'none'
      self.removingSelfFromSociety = 'none'
      self.activatingSociety = 'none'
      self.hasFetchedSocietiesOnce = false
    },
    setSocieties: (societies: ISocietiesModel[], member = true) => {
      societies.forEach((society) => {
        if (society.preApprovedList) {
          society.preApprovedList.forEach((preApproved) => {
            setObject<typeof PreApprovedModel>(
              // @ts-ignore
              self.preApproved,
              PreApprovedModel,
              {
                ...preApproved,
                reminderStatus: 'notSent',
                societyId: society._id,
              }
            )
          })
        }
        // @ts-ignore
        setObject<typeof SocietyModel>(self.societies, SocietyModel, {
          ...society,
          organisationNumber: society.organisationNumber
            ? `${society.organisationNumber}`
            : undefined, // For some reason, backend sometimes return as string
          adminsList: society.adminsList?.map((user) => ({
            userId: user.userId,
          })),
          boardMembersList: society.boardMembersList?.map((boardMember) => ({
            ...boardMember,
            userId: boardMember.userId,
          })),
          nominatingCommitteeList: society.nominatingCommitteeList?.map(
            (_member) => ({
              userId: _member.userId,
            })
          ),
          preApprovedList: society.preApprovedList?.map(
            // @ts-ignore
            (preApproved) => preApproved._id
          ),
          coverPhotoId: society.coverPhotoId,
          addressZip: `${society.addressZip}`,
          member,
        })
      })
    },
    setSelectedSociety: (id: string) => {
      self.selectedSocietyId = id
    },
    removePreApproved: (id: string) => {
      const _preApproved = self.preApproved.get(id)
      if (!_preApproved) {
        throw new Error('Pre Approved not found')
      }
      const _society = self.societies.get(_preApproved.societyId)
      if (!_society) {
        throw new Error('Pre Approved society not found')
      }
      _society.removePreApprovedUser(id)
      self.preApproved.delete(id)
    },
    setHasFetchedSocietiesOnce: (val: boolean) => {
      self.hasFetchedSocietiesOnce = val
    },
  }))
  .actions((self) => ({
    getUserSocieties: flow(function* getUserSocieties(
      userId: string,
      extraPopulate?: string[]
    ) {
      self.fetchingSocieties = 'pending'
      try {
        const resp = yield apiGetUserSocieties(userId, extraPopulate)
        const data = resp.data as NSocieties.NGetUserSocieties.IResponse
        const societies = data.data

        const users = data?.populated?.users
        const media = data?.populated?.media
        const units = data?.populated?.units

        const { userStore, mediaStore, unitStore, societyEntrancesStore } =
          getRoot<RootStore>(self)
        if (users) {
          userStore.setUsers(users)
        }
        if (media) {
          mediaStore.setMedia(media)
        }
        // @ts-ignore
        if ('society-entrances' in data.populated) {
          societyEntrancesStore.setEntrances(
            data.populated['society-entrances']
          )
        }
        if (units) {
          unitStore.setUnits(units)
        }

        self.setSocieties(societies)
        self.setHasFetchedSocietiesOnce(true)

        self.fetchingSocieties = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingSocieties = 'error'
      }
    }),
    createSociety: flow(function* createSociety(
      body: NSocieties.NCreate.IRequestBody
    ) {
      self.creatingSociety = 'pending'
      try {
        const resp = yield apiCreateSociety(body)
        const data = resp.data as NSocieties.NCreate.IResponse
        const society = data.data

        if (society) {
          self.setSocieties([society])
        }

        self.creatingSociety = 'done'
        return society
      } catch (error) {
        captureException(error)
        self.creatingSociety = 'error'
        return undefined
      }
    }),
    getSociety: flow(function* getSociety(id: string, member = true) {
      self.fetchingSociety = 'pending'
      try {
        const resp = yield apiGetSociety(id)
        const data = resp.data as NSocieties.NGetById.IResponse
        const society = data.data

        const users = data?.populated?.users
        const media = data?.populated?.media
        const units = data?.populated?.units

        const { userStore, mediaStore, unitStore, societyEntrancesStore } =
          getRoot<RootStore>(self)
        if (users) {
          userStore.setUsers(users)
        }
        if (media) {
          mediaStore.setMedia(media)
        }
        if ('society-entrances' in data.populated) {
          societyEntrancesStore.setEntrances(
            data.populated['society-entrances']
          )
        }
        if (units) {
          unitStore.setUnits(units)
        }

        self.setSocieties([society], member)
        self.setHasFetchedSocietiesOnce(true)

        self.fetchingSociety = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingSociety = 'error'
      }
    }),
    patchSociety: flow(function* patchSociety(
      id: string,
      body: NSocieties.NPatch.IRequestBody
    ) {
      self.updatingSociety = 'pending'
      try {
        const resp = yield apiPatchSociety(id, body)
        const data = resp.data as NSocieties.NPatch.IResponse
        const society = data.data

        if (society !== null) {
          self.setSocieties([society])
        }

        self.updatingSociety = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingSociety = 'error'
        return false
      }
    }),
    searchSocieties: flow(function* searchSocieties(term: string) {
      self.searchingSocieties = 'pending'
      try {
        const resp = yield apiSearchSocieties(term)
        const data = resp.data as NSocieties.NSearch.IResponse
        const societies = data.data

        societies.forEach((_society) =>
          setObject<typeof SearchSocietyModel>(
            // @ts-ignore
            self.searchResultSocieties,
            SearchSocietyModel,
            {
              ..._society,
            }
          )
        )
        // @ts-ignore
        self.searchResult = societies.map((_society) =>
          SearchSocietyModel.create(_society)
        )

        self.searchingSocieties = 'done'
      } catch (error) {
        captureException(error)
        self.searchingSocieties = 'error'
      }
    }),
    getPreApprovedSocieties: flow(function* getPreApprovedSocieties(
      userId: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Generator<any> {
      self.fetchingPreApprovedSocieties = 'pending'
      try {
        const { mediaStore, unitStore, societyEntrancesStore, userStore } =
          getRoot<RootStore>(self)

        let user = userStore.users.get(userId)
        if (!user) {
          yield userStore.getUser(userId)
          user = userStore.users.get(userId)
        }

        if (!user || !user.email) {
          self.fetchingPreApprovedSocieties = 'error'
          return false
        }

        const resp = yield apiListSocieties({
          isSearchByPreApproved: true,
          isSearchByOrgNumber: false,
          isSearchByName: false,
          limit: 10,
          term: user.email,
        })
        // @ts-ignore
        const data = resp.data as NSocieties.NSearch.IResponse
        const societies = data.data

        const { media, units } = data.populated

        if (media) {
          mediaStore.setMedia(media)
        }
        if ('society-entrances' in data.populated) {
          societyEntrancesStore.setEntrances(
            data.populated['society-entrances']
          )
        }
        if (units) {
          unitStore.setUnits(units)
        }

        // TODO: Check pre approved diff and remove stuff

        // Workaround for societies where user is member and in preapproved
        // This is a bug that should be resolved by the backend, as this should not be allowed.
        const filteredSocieties = societies.filter((_society) => {
          const _existingSociety = self.societies.get(_society._id)
          if (_existingSociety && _existingSociety.member) {
            return false
          }
          return true
        })

        self.setSocieties(filteredSocieties, false)

        self.fetchingPreApprovedSocieties = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.fetchingPreApprovedSocieties = 'error'
        return false
      }
    }),
    getSocietiesByOrganisationNumber: flow(
      function* getSocietyByOrganisationNumber(
        organisationNumber: string
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ): Generator<any> {
        self.fetchingPreApprovedSocieties = 'pending'
        try {
          const resp = yield apiListSocieties({
            isSearchByOrgNumber: true,
            term: organisationNumber,
          })
          // @ts-ignore
          const data = resp.data as NSocieties.NSearch.IResponse
          const societies = data.data
          self.fetchingPreApprovedSocieties = 'done'
          return societies
        } catch (error) {
          captureException(error)
          self.fetchingPreApprovedSocieties = 'error'
          return []
        }
      }
    ),
    addAdminUsers: flow(function* addAdminUsers(id: string, userIds: string[]) {
      self.updatingAdminUsers = 'pending'
      try {
        const resp = yield apiAddAdminUsers(id, userIds)
        const data = resp.data as NSocieties.NUpdateAdminUsers.IResponse
        const society = data.data

        if (society !== null) {
          self.setSocieties([society])
        }

        self.updatingAdminUsers = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingAdminUsers = 'error'
        return false
      }
    }),
    removeAdminUser: flow(function* removeAdminUser(
      id: string,
      userId: string
    ) {
      self.updatingAdminUsers = 'pending'
      try {
        const resp = yield apiRemoveAdminUser(id, userId)
        const data = resp.data as NSocieties.NRemoveAdminUsers.IResponse
        const society = data.data

        if (society !== null) {
          self.setSocieties([society])
        }

        self.updatingAdminUsers = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingAdminUsers = 'error'
        return false
      }
    }),
    addBlockedUsers: flow(function* addBlockedUsers(
      id: string,
      userIds: string[]
    ) {
      self.updatingBlockedUsers = 'pending'
      try {
        const resp = yield apiAddBlockedUsers(id, userIds)
        const data = resp.data as NSocieties.NCreateBlockListUsers.IResponse
        const society = data.data

        if (society !== null) {
          self.setSocieties([society])
        }

        self.updatingBlockedUsers = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingBlockedUsers = 'error'
        return false
      }
    }),
    removeBlockedUser: flow(function* removeBlockedUser(
      id: string,
      userId: string
    ) {
      self.updatingBlockedUsers = 'pending'
      try {
        const resp = yield apiRemoveBlockedUser(id, userId)
        const data = resp.data as NSocieties.NDeleteBlockListUser.IResponse
        const society = data.data

        if (society !== null) {
          self.setSocieties([society])
        }

        self.updatingBlockedUsers = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingBlockedUsers = 'error'
        return false
      }
    }),
    sendPreApprovedUserInviteReminder: flow(
      function* sendPreApprovedUserInviteReminder(
        societyId: string,
        id: string
      ) {
        const preApproved = self.preApproved.get(id)

        if (preApproved) {
          preApproved.setReminderStatus('sending')
          try {
            yield apiSendPreApprovedUserInviteReminder(
              societyId,
              preApproved.userEmail
            )
            preApproved.setReminderStatus('sent')
            return true
          } catch (error) {
            captureException(error)
            preApproved.setReminderStatus('notSent')
            return false
          }
        } else {
          captureException(new Error(`preApproved with id ${id} not found`))
          return false
        }
      }
    ),
    removePreApprovedUser: flow(function* removePreApprovedUser(
      societyId: string,
      id: string
    ) {
      const society = self.societies.get(societyId)

      const preApproved = self.preApproved.get(id)

      if (preApproved) {
        try {
          const { unitId, userEmail } = preApproved
          yield apiRemovePreApprovedUser(societyId, { unitId, userEmail })
          if (society) {
            society.removePreApprovedUser(id)
            self.preApproved.delete(id)
          }
          return true
        } catch (error) {
          captureException(error)
          return false
        }
      } else {
        captureException(new Error(`preApproved with id ${id} not found`))
        return false
      }
    }),
    inviteAllPreApprovedUsers: flow(function* inviteAllPreApprovedUsers(
      societyId: string
    ) {
      self.invitingAllPreApprovedUsers = 'pending'

      try {
        yield apiInviteAllPreApprovedUsers(societyId)

        self.invitingAllPreApprovedUsers = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.invitingAllPreApprovedUsers = 'error'

        return false
      }
    }),
  }))
  .actions((self) => ({
    updateMasterAdmin: flow(function* updateMasterAdmin(
      id: string,
      userId: string
    ) {
      self.updatingMasterAdmin = 'pending'
      try {
        const society = self.societies.get(id)
        if (
          !society?.adminsList?.map((admin) => admin.userId).includes(userId)
        ) {
          const addAdminStatus = yield self.addAdminUsers(id, [userId])
          if (!addAdminStatus) {
            return false
          }
        }

        const patchSocietyStatus = yield self.patchSociety(id, {
          masterAdminUserId: userId,
        })
        if (!patchSocietyStatus) {
          return false
        }

        yield self.getSociety(id)

        self.updatingMasterAdmin = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingMasterAdmin = 'error'
        return false
      }
    }),
    createUserRole: flow(function* createUserRole(
      body: NSocieties.NAddUsersRoles.IRequestBody
    ) {
      self.creatingUserRole = 'pending'

      try {
        yield apiCreateUserRole(body, true)
        self.creatingUserRole = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingUserRole = 'error'
        return false
      }
    }),
    deleteUserRole: flow(function* deleteUserRole(
      body: NSocieties.NRemoveUsersRoles.IRequestBody
    ) {
      self.deletingUserRole = 'pending'

      try {
        yield apiDeleteUserRole(body)
        self.deletingUserRole = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.deletingUserRole = 'error'
        return false
      }
    }),
    denyPreApproved: flow(function* denyPreApproved(
      preApproved: SnapshotOut<typeof PreApprovedModel>
    ) {
      self.denyingPreApproved = 'pending'

      try {
        const body: NSocieties.NRemoveUsersRoles.IRequestBody = [
          {
            societyId: preApproved.societyId,
            unitIdOrTitle: preApproved.unitId,
            userEmail: preApproved.userEmail,
            // @ts-ignore
            unitRole: preApproved.role,
          },
        ]
        yield apiDeleteUserRole(body)
        self.removePreApproved(preApproved._id)

        self.denyingPreApproved = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.denyingPreApproved = 'error'
        return false
      }
    }),
    removeUserFromSociety: flow(function* removeUserFromSociety(
      societyId: string,
      userId: string
    ) {
      self.removingUserFromSociety = 'pending'

      try {
        yield apiRemoveUserFromSociety(userId, societyId)
        self.removingUserFromSociety = 'error'
        return true
      } catch (error) {
        captureException(error)
        self.removingUserFromSociety = 'error'
        return false
      }
    }),
    removeSelfFromSociety: flow(function* removeSelfFromSociety(
      societyId: string
    ) {
      self.removingSelfFromSociety = 'pending'

      try {
        yield apiRemoveSelfFromSociety(societyId)

        const { chatRoomStore, notificationsStore, postStore } =
          getRoot<RootStore>(self)

        // Delete chat rooms
        const chatRoomIdsToDelete = chatRoomStore.sortedChatRooms
          .filter((_room) => _room.societyId === societyId)
          .map((_room) => _room._id)
        chatRoomStore.deleteChatRooms(chatRoomIdsToDelete)

        // Delete posts
        const postIdsToDelete = postStore.sortedPosts
          .filter((_post) => _post.society === societyId)
          .map((_post) => _post._id)
        postStore.deletePosts(postIdsToDelete)

        // Delete notifications
        const notificationIdsToDelete = notificationsStore.sortedNotifications
          .filter((_notification) => _notification.societyId === societyId)
          .map((_notification) => _notification._id)
        notificationsStore.deleteNotifications(notificationIdsToDelete)

        self.selectedSocietyId = undefined

        self.societies.delete(societyId)
        self.removingSelfFromSociety = 'error'
        return true
      } catch (error) {
        captureException(error)
        self.removingSelfFromSociety = 'error'
        return false
      }
    }),
    activateSociety: flow(function* activateSociety(id: string) {
      self.activatingSociety = 'pending'
      try {
        const resp = yield apiActivateSociety(id)
        const data = resp.data as NSocieties.NActivateSociety.IResponse
        const society = data.data

        if (society) {
          self.setSocieties([society])
        }

        self.activatingSociety = 'done'
      } catch (error) {
        captureException(error)
        self.activatingSociety = 'error'
      }
    }),
    getUserBlockedSocietyIds: flow(function* getUserBlockedSocietyIds() {
      try {
        const resp = yield apiGetUserBlockedSocietyIds()
        const data = resp.data as NSocieties.NGetUserBlockedSocietyIds.IResponse
        const societyIds = data.data

        if (societyIds) {
          // @ts-ignore
          self.userIsBlockedSocietyIds = societyIds
        }
      } catch (error) {
        captureException(error)
      }
    }),
  }))
