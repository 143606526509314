/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree'

export const TrackingStore = types
  .model('TrackingStore')
  .props({
    lastTrackingPathName: types.string,
  })
  .actions((self) => ({
    reset: () => {
      self.lastTrackingPathName = '/'
    },
  }))
  .actions((self) => ({
    setLastTrackingPathName: (val: string) => {
      self.lastTrackingPathName = val
    },
  }))
