import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import Fuse, { FuseResult } from 'fuse.js'
import { useAtom } from 'jotai'
import {
  isUserAdminInSociety,
  isUserBoardMemberInSociety,
} from '../../../helpers/society'
import { useAuthenticatedUserId } from '../../../hooks/useAuthenticatedUserId'
import { useStores } from '../../../hooks/useStores'
import { DropdownSelect } from '../../common/DropdownSelect'
import { IconChoices } from '../../common/Icon'
import { MenuHeader } from '../../common/MenuHeader'
import { MenuItem } from '../../common/MenuItem'
import { SearchBar } from '../../common/SearchBar'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { SocietySideBarCallout } from '../SocietySideBarCallout'
import { useManagementCallout } from './useManagementCallout'
import { LeaveSocietyMenuItem } from './LeaveSocietyMenuItem'
import {
  managementActiveAccordionItemStateAtom,
  residentActiveAccordionItemStateAtom,
  selectedSocietySidebarMenuItemStateAtom,
} from './societySideBarSharedState'
import { SocietySideBarAccordion } from './SocietySideBarAccordion'
import { useResidentLinks } from './SocietySideBarLinks/useResidentLinks'
import { useManagementLinks } from './SocietySideBarLinks/useManagementLinks'
import { MediaImage } from '../../common/Image'
import { useWindowDimensions } from '../../../hooks/useWindowDimensions'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { LinkSection, SubLink } from './LinkSection'
import { KUNDO_SUPPORT_LINK } from '../../../constants/Links'
import { Button, ButtonVariant } from '../../common/Button'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { useSocietyLockedForCurrentUser } from '../../../hooks/useSocietyLockedForCurrentUser'

const SocietySideBarContent = observer((): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const { societyStore, unitStore } = useStores()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const societyLockedForUser = useSocietyLockedForCurrentUser(society)
  const [selectedMenuItem, setSelectedMenuItem] = useAtom(
    selectedSocietySidebarMenuItemStateAtom
  )
  const [searchString, setSearchString] = useState('')
  const authUserId = useAuthenticatedUserId()
  const { translate } = useAppTranslation()
  const [managementActiveAccordionIndex] = useAtom(
    managementActiveAccordionItemStateAtom
  )
  const [residentActiveAccordionIndex] = useAtom(
    residentActiveAccordionItemStateAtom
  )
  const { id } = useParams()
  const currentUserIsAdmin = isUserAdminInSociety(society, authUserId as string)
  const currentUserIsBoard = isUserBoardMemberInSociety(
    society,
    authUserId as string
  )
  const { height: windowHeight } = useWindowDimensions()
  const isMobile = useIsMobile()

  useEffect(() => {
    if (society._id) {
      unitStore.getUnitsPerSociety(society._id)
    }
  }, [society, unitStore])

  const onChangeSearchString = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    setSearchString(event.currentTarget.value)
  }

  const settingsLink = reverseUrl('management:function-settings', { id })
  const findSocietyLink = reverseUrl('find-society')

  const fuseOptions = {
    threshold: 0.2,
    keys: ['title', 'sublinks.title'],
    includeMatches: true,
  }

  const childrenFuseOptions = {
    threshold: 0.2,
    keys: ['title'],
    includeMatches: true,
  }

  const managementCallout = useManagementCallout(society, societyLockedForUser)
  const residentLinks = useResidentLinks(society, societyLockedForUser)
  const managementLinks = useManagementLinks(society, societyLockedForUser)

  const fuseManagement = new Fuse(managementLinks, fuseOptions)
  const fuseResident = new Fuse(residentLinks, fuseOptions)

  const getLinkSectionSearchResult = (
    result: FuseResult<LinkSection>
  ): (SubLink | undefined)[] => {
    const fuseResidentSublinks = new Fuse(
      result.item.sublinks,
      childrenFuseOptions
    )
    const sublinks = fuseResidentSublinks
      .search(searchString)
      .map((_result) => _result.item)
    return sublinks
  }

  const filteredManagementLinks =
    searchString === ''
      ? managementLinks
      : fuseManagement.search(searchString).map((result) => ({
          ...result.item,
          sublinks: getLinkSectionSearchResult(result),
        }))

  const filteredResidentLinks =
    searchString === ''
      ? residentLinks
      : fuseResident.search(searchString).map((result) => ({
          ...result.item,
          sublinks: getLinkSectionSearchResult(result),
        }))

  const showCoverPhoto = society.coverPhotoId && !isMobile && windowHeight > 720

  const onNewSocietyClick = (): void => {
    navigate(findSocietyLink)
  }

  const newSocietyButton = (
    <div className="flex h-full w-full items-center justify-center p-3">
      <Button
        wrapperClassName="flex w-full"
        className="grow py-2"
        label={translate('findSociety.labels.newSociety')}
        variant={ButtonVariant.DEFAULT}
        onClick={onNewSocietyClick}
        icon={IconChoices.PLUS_SIGN}
      />
    </div>
  )

  const isCurrentlyInOnboarding =
    location.pathname === reverseUrl('society:onboarding', { id: society._id })

  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <div className="flex flex-col gap-4 px-6 pt-6">
        {showCoverPhoto && (
          <MediaImage
            mediaId={society.coverPhotoId}
            className="overflow-hidden rounded"
            style={{ maxHeight: windowHeight / 2 }}
          />
        )}
        <DropdownSelect
          value={society._id}
          options={societyStore.sortedSocieties.map((_society) => ({
            value: _society._id,
            label: _society.name,
          }))}
          onChange={(value) =>
            navigate(reverseUrl('society:detail', { id: value }))
          }
          dropdownWidth="w-full"
          submitButton={newSocietyButton}
        />
        <SearchBar
          widthClassName="w-full"
          className="w-full"
          value={searchString}
          onChange={onChangeSearchString}
          placeholder={translate('societySideBar.searchBar.placeholder')}
        />
      </div>
      <div className="flex min-h-0 w-full grow flex-col gap-3 overflow-auto">
        <div className="flex grow flex-col px-6">
          {(currentUserIsAdmin || currentUserIsBoard) && (
            <>
              {filteredManagementLinks.length > 0 && (
                <MenuHeader
                  className="mt-4"
                  label={translate('societySideBar.sections.board.title')}
                />
              )}
              {managementCallout && (
                <div className="my-2">
                  <SocietySideBarCallout
                    {...managementCallout}
                    disabled={isCurrentlyInOnboarding}
                  />
                </div>
              )}
              <SocietySideBarAccordion
                links={filteredManagementLinks}
                activeAccordionIndex={managementActiveAccordionIndex}
              />
            </>
          )}
          {!currentUserIsAdmin && !currentUserIsBoard && (
            <>
              <MenuHeader
                className="mt-4"
                label={translate(
                  'societySideBar.sections.resident.residentFunctionsTitle'
                )}
              />
            </>
          )}
          {(currentUserIsAdmin || currentUserIsBoard) && (
            <div className="my-4 border-b" />
          )}
          <SocietySideBarAccordion
            links={filteredResidentLinks}
            activeAccordionIndex={residentActiveAccordionIndex}
          />
          <div className="my-4 border-b" />
          <div>
            {currentUserIsAdmin && (
              <MenuItem
                title={translate('functionSettingsView.title')}
                icon={IconChoices.ICON_TOGGLE}
                url={settingsLink}
                isSelected={selectedMenuItem === settingsLink}
                onSelect={() => setSelectedMenuItem(settingsLink)}
                disabled={isCurrentlyInOnboarding || societyLockedForUser}
              />
            )}
            <MenuItem
              title={translate('supportView.title')}
              icon={IconChoices.SUPPORT}
              url={KUNDO_SUPPORT_LINK}
              urlIsExternal
            />
          </div>
          <div className="mb-2 mt-4 flex flex-1 justify-center md:mb-4 md:items-end">
            <LeaveSocietyMenuItem society={society} />
          </div>
        </div>
      </div>
    </div>
  )
})

SocietySideBarContent.displayName = 'SocietySideBarContent'
export { SocietySideBarContent }
