import { SnapshotOut } from 'mobx-state-tree'
import { ActivatedPartnerModel, SocietyModel } from '../state/models/society'
import i18next from '../i18n/i18n'

export const isUserAdminInSociety = (
  society: SnapshotOut<typeof SocietyModel> | null | undefined,
  userId: string
): boolean => {
  if (society == null) {
    return false
  }

  if (society?.masterAdminUserId === userId) {
    return true
  }

  const admin = (society.adminsList == null ? [] : society.adminsList).find(
    (adminUser) => userId === adminUser.userId
  )

  return admin != null
}

export const isUserBoardMemberInSociety = (
  society: SnapshotOut<typeof SocietyModel> | null | undefined,
  userId: string
): boolean => {
  const boardMember =
    society &&
    society?.boardMembersList?.find((member) => member.userId === userId)

  if (boardMember) {
    return true
  }
  return false
}

export const userBoardMemberRoles = (
  society: SnapshotOut<typeof SocietyModel> | null | undefined,
  userId: string
): string[] | undefined => {
  return society?.boardMembersList
    ?.filter((member) => member.userId === userId)
    .filter((member) => member)
    .map((member) => member.role)
}

export const translateRole = (role: string): string | null => {
  const translations: { [key: string]: string | null } = {
    admin: i18next.t('society.roles.admin'),
    president: i18next.t('society.boardRoles.chairman'),
    vicepresident: i18next.t('society.boardRoles.viceChairman'),
    treasury: i18next.t('society.boardRoles.treasury'),
    member: i18next.t('society.boardRoles.member'),
    secretary: i18next.t('society.boardRoles.secretary'),
    alternate: i18next.t('society.boardRoles.alternate'),
    advisor: i18next.t('society.boardRoles.advisor'),
    'nominating-committee': i18next.t('society.boardRoles.nominatingCommittee'),
  }
  return translations[role]
}
export const translateRoles = (
  roles: string[] | undefined
): (string | null)[] => {
  if (!roles) {
    return []
  }
  return roles.map((role) => translateRole(role))
}

export const getSocietyPartnerData = (
  society: SnapshotOut<typeof SocietyModel> | null | undefined,
  partnerId: string
): SnapshotOut<typeof ActivatedPartnerModel> | undefined => {
  if (society === undefined || society === null) {
    return undefined
  }

  return society?.activatedPartnerData?.find(
    (partner) => partner.partnerId === partnerId
  )
}

export const societyHasActivatedPartner = (
  society: SnapshotOut<typeof SocietyModel> | null | undefined,
  partnerId: string
): boolean => {
  if (society === undefined || society === null) {
    return false
  }

  const partnerIds = society?.activatedPartnerData?.map(
    (partner) => partner.partnerId
  )
  if (!partnerIds) {
    return false
  }

  return partnerIds?.includes(partnerId)
}

export const getNumberOfUnitsLabel = (
  society: SnapshotOut<typeof SocietyModel> | null | undefined
): string => {
  if (society === undefined || society === null) {
    return `${i18next.t('common.numberOfunits.apartmentBuilding')}`
  }

  switch (society.societyTypeDisplayName) {
    case 'housing_cooperative':
      return `${i18next.t('common.numberOfunits.housingCooperative')}`
    case 'housing_cooperative_construction':
      return `${i18next.t(
        'common.numberOfunits.housingCooperativeConstruction'
      )}`
    case 'homeowners_association':
      return `${i18next.t('common.numberOfunits.homeownersAssociatoon')}`
    case 'non_profit_association':
      return `${i18next.t('common.numberOfunits.nonProfitAssociation')}`
    case 'economic_association':
      return `${i18next.t('common.numberOfunits.economicAssociation')}`
    case 'neighbourhood':
      return `${i18next.t('common.numberOfunits.neighbourhood')}`
    case 'apartment_building':
      return `${i18next.t('common.numberOfunits.apartmentBuilding')}`
    case 'boat_club':
      return `${i18next.t('common.numberOfunits.boatClub')}`
    case 'shared':
      return `${i18next.t('common.numberOfunits.shared')}`
    default:
      return `${i18next.t('common.numberOfunits.apartmentBuilding')}`
  }
}
