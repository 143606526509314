import React, { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { ManagementPartner } from '../management_partner'
import { ManagementPartnerLogo } from '../ManagementPartnerLogo'
import { theme } from '../../../../theme/theme'

interface ManagementPartnerBaseProps {
  children: ReactNode
  partner: ManagementPartner
  description?: string
}

export const ManagementPartnerBase = observer(
  ({
    children,
    partner,
    description,
  }: ManagementPartnerBaseProps): JSX.Element => {
    return (
      <>
        <ManagementPartnerLogo partner={partner} />
        {description && (
          <p className="mt-6 w-full md:w-3/5" style={theme.textVariants.base}>
            {description}
          </p>
        )}
        <div className="mt-6 w-full space-y-4 lg:w-2/5">{children}</div>
      </>
    )
  }
)
