/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { flow, types, getRoot, SnapshotOut } from 'mobx-state-tree'
import { captureException } from '@sentry/react'
import { ICommentModel } from '../../interfaces/models/comments.interfaces'
import {
  toggleLike as apiToggleLike,
  createComment as apiCreateComment,
  deleteComment as apiDeleteComment,
  patchComment as apiPatchComment,
} from '../../api/comments'
import { RootStore } from './root'
import { stateType } from '../types/common'
import { CommentModel } from '../models/comment'
import { setObject } from './helpers'
import { NComments } from '../../interfaces/services/comments.interfaces'

export const CommentStore = types
  .model('CommentStore')
  .props({
    comments: types.map(CommentModel),
    creatingComment: stateType,
    deletingComment: stateType,
    updatingComment: stateType,
  })
  .actions((self) => ({
    reset: () => {
      self.comments.clear()
      self.creatingComment = 'none'
      self.deletingComment = 'none'
      self.updatingComment = 'none'
    },
    setComments: (comments: ICommentModel[]) => {
      comments.forEach((comment) => {
        // @ts-ignore
        setObject<typeof CommentModel>(self.comments, CommentModel, comment)
      })
    },
  }))
  .actions((self) => ({
    create: flow(function* toggleLike(postId: string, comment: string) {
      self.creatingComment = 'pending'
      try {
        const resp = yield apiCreateComment({
          postId,
          message: comment,
          role: 'resident',
          author: '',
        })
        const _comment = resp.data.data as ICommentModel
        self.setComments([_comment])

        const { postStore } = getRoot<RootStore>(self)
        const _post = postStore.posts.get(postId)
        // @ts-ignore
        _post?.addComment(_comment._id)

        self.creatingComment = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingComment = 'error'
        return false
      }
    }),
    deleteComment: flow(function* deleteComment(id: string) {
      self.deletingComment = 'pending'
      try {
        yield apiDeleteComment(id)

        const { postStore } = getRoot<RootStore>(self)

        // set type to not be undefined so that try/catch catches errors
        const _comment = self.comments.get(id) as
          | SnapshotOut<typeof CommentModel>
          | undefined

        if (_comment) {
          const _post = postStore.posts.get(_comment.postId)
          _post?.removeComment(id)
        }

        self.comments.delete(id)

        self.deletingComment = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.deletingComment = 'error'
        return false
      }
    }),
    patchComment: flow(function* patchComment(
      id: string,
      body: NComments.NPatch.IRequestBody
    ) {
      self.updatingComment = 'pending'
      try {
        const resp = yield apiPatchComment(id, body)
        const _comment = resp.data.data as ICommentModel
        if (_comment) {
          self.setComments([_comment])
        }

        self.updatingComment = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingComment = 'error'
        return false
      }
    }),
    toggleLike: flow(function* toggleLike(id: string, like: boolean) {
      const { authenticationStore } = getRoot<RootStore>(self)
      const _comment = self.comments.get(id)

      _comment?.toggleLike(authenticationStore.userId as string, like)

      try {
        yield apiToggleLike(id, like)
      } catch (error) {
        captureException(error)
        _comment?.toggleLike(authenticationStore.userId as string, !like)
      }
    }),
  }))
