import React from 'react'
import { Outlet } from 'react-router-dom'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import {
  isUserAdminInSociety,
  isUserBoardMemberInSociety,
} from '../../../helpers/society'
import { UnauthorizedView } from '../../error/UnauthorizedView'
import { useAuth } from '../../../hooks/useAuth'

const SocietyManagementRoot = (): JSX.Element => {
  const { userId } = useAuth()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  if (userId === undefined) {
    throw new Error('userId returned undefined')
  }

  const currentUserIsAdmin = isUserAdminInSociety(society, userId)
  const currentUserIsBoard = isUserBoardMemberInSociety(society, userId)

  if (!(currentUserIsAdmin || currentUserIsBoard)) {
    return <UnauthorizedView />
  }

  return <Outlet />
}

SocietyManagementRoot.displayName = 'SocietyManagementRoot'
export { SocietyManagementRoot }
