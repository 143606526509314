import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { ResourceModel } from '../../../../../state/models/resource'
import { MediaImage } from '../../../../common/Image'
import { reverseUrl } from '../../../../../navigation/reverseUrl'
import { theme } from '../../../../../theme/theme'

interface MarketplaceItemProps {
  resource: SnapshotOut<typeof ResourceModel>
}

export const MarketplaceItem = observer(
  ({ resource }: MarketplaceItemProps): JSX.Element | null => {
    const { translate } = useAppTranslation()

    return (
      <Link
        className="flex w-full cursor-pointer flex-row items-center rounded-lg p-4 hover:bg-neutral-96"
        to={reverseUrl('society:marketplace-detail', {
          id: resource.societyId,
          resourceId: resource._id,
        })}
        style={{ textDecoration: 'none' }}
      >
        <div className="flex flex-1 gap-4">
          <MediaImage
            mediaId={resource.mediaId}
            className="h-[120px] w-[120px]"
            imageClassName="rounded"
          />
          <div className="flex flex-1 flex-col justify-between">
            <div>
              <span
                className="break-all text-black line-clamp-1"
                style={theme.textVariants.lead}
              >
                {resource.title}
              </span>
              <p
                className="text-neutral-50 line-clamp-1"
                style={theme.textVariants.base}
              >
                {resource.description}
              </p>
            </div>
            <span
              style={theme.textVariants.baseBold}
              className="mt-1 text-black"
            >
              {resource.isFree || resource.cost === '0'
                ? translate('marketplaceListView.item.free')
                : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //  @ts-ignore
                  resource.costString}
            </span>
          </div>
        </div>
      </Link>
    )
  }
)
