import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { useAuthenticatedUserId } from '../../../../../hooks/useAuthenticatedUserId'
import { useStores } from '../../../../../hooks/useStores'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { reverseUrl } from '../../../../../navigation/reverseUrl'
import { SocietyModel } from '../../../../../state/models/society'
import { theme } from '../../../../../theme/theme'
import { Button, ButtonVariant } from '../../../../common/Button'
import { ToastType } from '../../../../common/Toast/toast-type'

interface JoinInactiveSocietyMemberProps {
  society: SnapshotOut<typeof SocietyModel>
}

export const JoinInactiveSocietyMember = observer(
  ({ society }: JoinInactiveSocietyMemberProps): JSX.Element => {
    const { societyStore, requestStore } = useStores()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const navigate = useNavigate()
    const userId = useAuthenticatedUserId() as string

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('inactiveSociety.flashMessage.joinSocietyFailure')
      )
    }

    const showSuccessMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('inactiveSociety.flashMessage.joinSocietySuccess')
      )
    }

    const navigateToSociety = (): void => {
      navigate(reverseUrl('society'))
    }

    const onJoinClick = async (): Promise<void> => {
      const status = await requestStore.sendRequest({
        societyId: society._id as string,
        userId,
      })

      if (status) {
        showSuccessMessage()
        await societyStore.getUserSocieties(userId)
        navigateToSociety()
      } else {
        showErrorMessage()
      }
    }

    return (
      <div className="flex flex-col">
        <p className="mt-4" style={theme.textVariants.lead}>
          {translate('inactiveSociety.member.title')}
        </p>
        <p className="max-w-96 mt-4" style={theme.textVariants.base}>
          {translate('inactiveSociety.member.subtitle')}
        </p>
        <Button
          wrapperClassName="w-full"
          className="mt-10 w-full"
          variant={ButtonVariant.PRIMARY}
          label={translate('common.actions.join')}
          onClick={onJoinClick}
        />
      </div>
    )
  }
)
