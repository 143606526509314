/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'

export const ChatRoomModel = types
  .model('ChatRoom', {
    _id: types.identifier,
    ...timestampTypes,
    name: types.string,
    lastMessageDate: types.maybe(types.number),
    unreadCount: types.maybe(types.number),
    type: types.enumeration('ChatType', [
      'oneonone',
      'interests',
      'board',
      'questions',
    ]),
    societyId: types.string,
    membersList: types.array(
      types.model({
        userId: types.string,
        hideUntilNotification: types.maybe(types.boolean),
      })
    ),
    userSettings: types.array(
      types.model({
        userId: types.string,
        hideUntilNotification: types.maybe(types.boolean),
      })
    ),
    avatarId: types.maybeNull(types.string),
    adminId: types.maybe(types.string),
    isPrivate: types.maybe(types.boolean),
    isCreatedFromPost: types.maybe(types.boolean),
  })
  .actions((self) => ({
    updateLastMessageDate(date: number) {
      self.lastMessageDate = date
    },
    toggleHideUntilNotification(userId: string, value: boolean) {
      const settingUserIds = self.userSettings.map(
        (_setting) => _setting.userId
      )
      if (settingUserIds.includes(userId)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        self.userSettings = self.userSettings.map((_setting) => {
          if (_setting.userId !== userId) {
            return _setting
          }

          return {
            ..._setting,
            hideUntilNotification: value,
          }
        })
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        self.userSettings = [
          ...self.userSettings,
          {
            userId,
            hideUntilNotification: value,
          },
        ]
      }
    },
    setUnreadCount(count: number) {
      self.unreadCount = count
    },
    incrementUnreadCount() {
      if (self.unreadCount) {
        self.unreadCount += 1
      } else {
        self.unreadCount = 1
      }
    },
  }))
