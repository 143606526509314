import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useStores } from '../../../../hooks/useStores'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { societyFacilityCreateSchema } from '../../../../forms/schemas/society_facility_create'
import { NFacilities } from '../../../../interfaces/services/facilities.interfaces'
import { ToastType } from '../../../../components/common/Toast/toast-type'
// eslint-disable-next-line max-len
import { CreateUpdateFacilityForm } from '../../../../components/society/FacilitiesList/CreateUpdateFacilityForm'
import { ViewBase } from '../../../../components/common/ViewBase'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'

export const CreateUpdateFacilityView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { facilitiesStore, societyStore, bookingsStore } = useStores()
  const { setToastNotification } = useToastNotifications()
  const { facilityId } = useParams()
  const navigate = useNavigate()

  const facility = facilityId
    ? facilitiesStore.facilities.get(facilityId)
    : undefined

  const society = facility
    ? societyStore.societies.get(facility.societyId)
    : undefined

  useEffect(() => {
    society?._id && facilitiesStore.getFacilitiesPerSociety(society._id)
    facility?._id && bookingsStore.getBookingsPerFacility(facility._id)
  }, [bookingsStore, facilitiesStore, facility?._id, society?._id])

  const updateMode = !!facility

  useDocumentTitle(
    updateMode
      ? reverseDocumentTitle('society:facilities-update', {
          '{{ societyName }}': society?.name,
          '{{ facilityName }}': facility?.name,
        })
      : reverseDocumentTitle('society:facilities-create', {
          '{{ societyName }}': society?.name,
        })
  )

  const showErrorMessage = (): void => {
    const message = updateMode
      ? 'createUpdateFacility.flashMessage.updateFacilityFailure'
      : 'createUpdateFacility.flashMessage.createFacilityFailure'
    setToastNotification(ToastType.DANGER, translate(message))
  }

  const showSuccessMessage = (): void => {
    const message = updateMode
      ? 'createUpdateFacility.flashMessage.updateFacilitySuccess'
      : 'createUpdateFacility.flashMessage.createFacilitySuccess'
    setToastNotification(ToastType.SUCCESS, translate(message))
  }

  const goBack = (): void => {
    navigate(-1)
  }

  const onSubmit = async (
    data: InferType<typeof societyFacilityCreateSchema>
  ): Promise<void> => {
    const bookingAvailabilityStartDate = data.bookingAvailabilityStartDate
      ? new Date(data.bookingAvailabilityStartDate)
      : null
    const bookingAvailabilityEndDate = data.bookingAvailabilityEndDate
      ? new Date(data.bookingAvailabilityEndDate)
      : null

    const getBookingAvailabilityEndDate = (): null | Date => {
      if (data.bookableUntilFurtherNotice) {
        return null
      }
      return bookingAvailabilityEndDate
        ? new Date(bookingAvailabilityEndDate.setUTCHours(0, 0, 0, 0))
        : null
    }

    const formData: NFacilities.NCreate.IRequestBody = {
      ...data,
      bookingAvailability: [
        {
          ...(bookingAvailabilityStartDate && {
            startDate: new Date(
              bookingAvailabilityStartDate?.setUTCHours(0, 0, 0, 0)
            ),
          }),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          endDate: getBookingAvailabilityEndDate(),
        },
      ],
    }

    if (updateMode) {
      const status = await facilitiesStore.patchFacility(facility._id, formData)
      if (status) {
        showSuccessMessage()
        goBack()
      } else {
        showErrorMessage()
      }
    } else {
      const status = await facilitiesStore.createFacility(formData)
      if (status) {
        goBack()
        showSuccessMessage()
      } else {
        showErrorMessage()
      }
    }
  }

  const onError = (error: unknown): void => {
    captureException(error)
    showErrorMessage()
  }

  const loading =
    facilitiesStore.creatingFacility === 'pending' ||
    facilitiesStore.updatingFacility === 'pending'

  return (
    <ViewBase
      title={translate(
        updateMode
          ? 'createUpdateFacility.editFacilityTitle'
          : 'createUpdateFacility.createFacilityTitle'
      )}
      showBackButton
    >
      <CreateUpdateFacilityForm
        onError={onError}
        onSubmit={onSubmit}
        onClose={goBack}
        loading={loading}
        facility={facility}
      />
    </ViewBase>
  )
})
