import React from 'react'
import { Outlet } from 'react-router-dom'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../navigation/reverseDocumentTitle'
import { useCurrentSociety } from '../../hooks/useCurrentSociety'

const ServicesViewContainer = (): React.ReactNode => {
  const { society } = useCurrentSociety()
  useDocumentTitle(
    reverseDocumentTitle('society:services', {
      '{{ societyName }}': society?.name,
    })
  )
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  return (
    <div className="flex w-full overflow-y-scroll">
      <div className="max-w-[550px] w-full">
        <Outlet />
      </div>
    </div>
  )
}

export { ServicesViewContainer }
