import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { FormControl } from '../../../../../../components/common/FormControl'
import { useFormErrorMessage } from '../../../../../../hooks/useFormErrorMessage'
import {
  Button,
  ButtonVariant,
} from '../../../../../../components/common/Button'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { SocietyModel } from '../../../../../../state/models/society'
import { adminEditSocietySchema } from '../../../../../../forms/schemas/admin_edit_society'
import { TextInput } from '../../../../../../components/common/TextInput'
import { useStores } from '../../../../../../hooks/useStores'
import { SocietyTypes } from '../../../../../../types/society-type'
import { SelectDropdown } from '../../../../../../components/common/SelectDropdown'
import { getSocietyType } from '../../../../../../helpers/translations/societyType'
import { formatOrganisationNumber } from '../../../../../../helpers/organisation_number'
import { OrganisationNumberTextInput } from '../../../../../../components/common/OrganisationNumberTextInput'
import { Checkbox } from '../../../../../../components/common/Checkbox'
import { DateTimeInput } from '../../../../../../components/common/DateTimeInput'

interface EditSocietyModalContentProps {
  onError: (error: unknown) => void
  onSubmit: (data: InferType<typeof adminEditSocietySchema>) => Promise<void>
  onClose: () => void
  society?: SnapshotOut<typeof SocietyModel>
}

export const EditSocietyModalContent = observer(
  ({
    onError,
    onSubmit,
    onClose,
    society,
  }: EditSocietyModalContentProps): JSX.Element => {
    const { societyStore } = useStores()
    const { translate } = useAppTranslation()
    const { getErrorMessage } = useFormErrorMessage()
    const [
      alreadyExistingOrganisationNumber,
      setAlreadyExistingOrganisationNumber,
    ] = useState<string | null>(null)
    const [isOrganisationNumberFocused, setIsOrganisationNumberFocused] =
      useState(false)

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
      watch,
    } = useForm({
      mode: 'all',
      resolver: yupResolver(adminEditSocietySchema),
      defaultValues: {
        organisationNumber: society?.organisationNumber
          ? `${society?.organisationNumber}`
          : '',
        addressStreet: society?.addressStreet ?? '',
        addressCo: society?.addressCo ?? '',
        addressCity: society?.addressCity ?? '',
        billingUnits: society?.billingUnits ?? 3,
        addressBillingCity: society?.addressBillingCity ?? '',
        addressBillingCounty: society?.addressBillingCounty ?? '',
        addressBillingMunicipality: society?.addressBillingMunicipality ?? '',
        societyTypeDisplayName: society?.societyTypeDisplayName ?? '',
        locked: society?.locked || false,
        trialPeriodEndDate: society?.trialPeriodEndDate
          ? new Date(society?.trialPeriodEndDate)
          : new Date(new Date().setDate(new Date().getDate() + 14)),
      },
    })

    const getSocietyByOrganisationNumber = useCallback(
      async (
        organisationNumber: string
      ): Promise<SnapshotOut<typeof SocietyModel> | null> => {
        const societies =
          await societyStore.getSocietiesByOrganisationNumber(
            organisationNumber
          )
        return societies.length > 0 ? societies[0] : null
      },
      [societyStore]
    )

    const watchOrganisationNumber = watch('organisationNumber')

    useEffect(() => {
      if (!watchOrganisationNumber) {
        return
      }

      const trimmedOrganisationNumber = watchOrganisationNumber.replace('-', '')

      const fetchSociety = async (): Promise<void> => {
        if (isOrganisationNumberFocused) {
          const existingSociety = await getSocietyByOrganisationNumber(
            trimmedOrganisationNumber
          )

          if (existingSociety) {
            setAlreadyExistingOrganisationNumber(trimmedOrganisationNumber)
          }
        }
      }

      if (trimmedOrganisationNumber.length === 10) {
        fetchSociety()
      }
    }, [
      isOrganisationNumberFocused,
      watchOrganisationNumber,
      getSocietyByOrganisationNumber,
    ])

    const parsedOrganisationNumber = `${society?.organisationNumber}`

    const customOrganisatioNumberError =
      watchOrganisationNumber &&
      watchOrganisationNumber.replace('-', '') !==
        parsedOrganisationNumber?.replace('-', '') &&
      watchOrganisationNumber.replace('-', '') ===
        alreadyExistingOrganisationNumber?.replace('-', '')
        ? {
            message: translate(
              'createSocietyForm.errors.societyAlreadyActivated'
            ),
            ref: {
              name: 'organisationNumber',
            },
            type: 'matches',
          }
        : undefined

    const societyTypesOptions = Object.values(SocietyTypes).map((val) => ({
      value: val as string,
      label: translate(getSocietyType(val as SocietyTypes) as string),
    }))

    return (
      <form
        className="flex h-full flex-col gap-6"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Controller
          control={control}
          name="organisationNumber"
          render={({ field: { value, name, onChange, onBlur } }) => {
            const formattedValue = value ? formatOrganisationNumber(value) : ''
            return (
              <FormControl
                name={name}
                label={translate('common.form.labels.organisationNumber')}
                error={
                  errors.organisationNumber
                    ? getErrorMessage(errors.organisationNumber)
                    : customOrganisatioNumberError &&
                      getErrorMessage(customOrganisatioNumberError)
                }
              >
                <OrganisationNumberTextInput
                  className="pl-2"
                  value={formattedValue}
                  onChange={onChange}
                  onBlur={() => {
                    setIsOrganisationNumberFocused(false)
                    if (onBlur) {
                      onBlur()
                    }
                  }}
                  onFocus={() => {
                    setIsOrganisationNumberFocused(true)
                  }}
                />
              </FormControl>
            )
          }}
        />
        <Controller
          control={control}
          name="societyTypeDisplayName"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label="Typ av förening"
              error={
                errors.societyTypeDisplayName &&
                getErrorMessage(errors.societyTypeDisplayName)
              }
              name={name}
            >
              <SelectDropdown
                options={societyTypesOptions}
                value={value}
                onChange={onChange}
                error={!!errors.societyTypeDisplayName}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="locked"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label="Låst"
              error={errors.locked && getErrorMessage(errors.locked)}
              name={name}
            >
              <div className="flex space-x-2">
                <Checkbox
                  name={name}
                  onChange={onChange}
                  value={value}
                  type="checkbox"
                />
                <p>{value ? 'Ja' : 'Nej'}</p>
              </div>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="trialPeriodEndDate"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label="Provperiod slutar"
              error={errors.locked && getErrorMessage(errors.locked)}
              name={name}
            >
              <DateTimeInput
                onChange={(date) => {
                  onChange(date)
                }}
                minDate={new Date()}
                value={value}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="addressCo"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              name={name}
              error={errors.addressCo && getErrorMessage(errors.addressCo)}
              label={translate('common.form.labels.addressCareOf')}
            >
              <TextInput
                className="pl-2"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="addressStreet"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              name={name}
              error={
                errors.addressStreet && getErrorMessage(errors.addressStreet)
              }
              label={translate('common.form.labels.addressStreet')}
            >
              <TextInput
                className="pl-2"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="addressCity"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              error={errors.addressCity && getErrorMessage(errors.addressCity)}
              name={name}
              label={translate('common.form.labels.addressCity')}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.addressCity}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="addressBillingCity"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label="Fakturering - ort"
              error={
                errors.addressBillingCity &&
                getErrorMessage(errors.addressBillingCity)
              }
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.addressBillingCity}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="addressBillingMunicipality"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              error={
                errors.addressBillingMunicipality &&
                getErrorMessage(errors.addressBillingMunicipality)
              }
              name={name}
              label="Fakturering - kommun"
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.addressBillingMunicipality}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="addressBillingCounty"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              error={
                errors.addressBillingCounty &&
                getErrorMessage(errors.addressBillingCounty)
              }
              name={name}
              label="Fakturering - län"
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.addressBillingCounty}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="billingUnits"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              name={name}
              label="Antalet lägenheter/fastigheter"
              error={
                errors.billingUnits && getErrorMessage(errors.billingUnits)
              }
            >
              <TextInput
                className="pl-2"
                value={`${value}`}
                onChange={onChange}
                onBlur={onBlur}
                type="number"
              />
            </FormControl>
          )}
        />
        <div className="flex flex-wrap justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid && !customOrganisatioNumberError}
            label={translate('common.actions.save')}
            type="submit"
          />
        </div>
      </form>
    )
  }
)
