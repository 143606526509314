import * as yup from 'yup'
import i18next from '../../i18n/i18n'

const minPasswordLength = 8

export const updatePasswordSchema = yup
  .object({
    currentPassword: yup.string().required(),
    newPassword: yup
      .string()
      .required()
      .min(
        minPasswordLength,
        i18next.t('form.errors.minLength', { minLength: minPasswordLength })
      ),
    newPasswordConfirmation: yup
      .string()
      .required()
      .oneOf(
        [yup.ref('newPassword')],
        i18next.t('form.errors.passwordConfirmation')
      ),
  })
  .required()
