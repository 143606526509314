import React, { useCallback, useState } from 'react'
import Fuse from 'fuse.js'
import { observer } from 'mobx-react-lite'
import { SpinnerWrapper } from '../../common/SpinnerWrapper'
import { ErrorView } from '../../../views/error/ErrorView'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { IllustrationChoices } from '../../common/Illustration'
import { useStores } from '../../../hooks/useStores'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { RegisterGroupListItemBase } from './RegisterGroupListItemBase'
import { SearchBar } from '../../common/SearchBar'

export const RegisterGroupList = observer((): JSX.Element => {
  const [searchString, setSearchString] = useState('')
  const { translate } = useAppTranslation()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const { societyStore, groupStore } = useStores()

  const loading = societyStore.fetchingSociety === 'pending'

  const groups = groupStore.groupsForSociety(society._id)

  const getFilteredGroups = useCallback(() => {
    const options = {
      threshold: 0.1,
      keys: ['name'],
    }

    const fuse = new Fuse(groups, options)

    const filteredEntrances =
      searchString === ''
        ? groups
        : fuse.search(searchString).map((result) => result.item)

    return filteredEntrances
  }, [groups, searchString])

  const onSearchBarChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setSearchString(e.currentTarget.value)
  }

  if (loading) {
    return <SpinnerWrapper />
  }

  if (!loading && groups.length === 0) {
    return (
      <ErrorView
        title={translate('common.emptyState.title')}
        subtitle={translate('common.emptyState.subtitle')}
        illustration={IllustrationChoices.EMPTY}
      />
    )
  }

  return (
    <div className="w-full space-y-10">
      <SearchBar
        className="w-full md:w-80"
        widthClassName="flex grow"
        value={searchString}
        onChange={onSearchBarChange}
      />
      {getFilteredGroups().map((group) => (
        <RegisterGroupListItemBase key={group._id} group={group} />
      ))}
    </div>
  )
})
