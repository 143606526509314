import React from 'react'
import { saveAs } from 'file-saver'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { createUrl } from '../../../api/helpers'
import { useStores } from '../../../hooks/useStores'
import { DocumentModel } from '../../../state/models/document'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../../common/Icon'
import { Divider } from '../../common/Divider'

interface PostDocumentsProps {
  documentsIds: string[]
  disabled?: boolean
}

export const PostDocuments = observer(
  ({ documentsIds, disabled }: PostDocumentsProps): JSX.Element => {
    const { documentStore } = useStores()
    const documents = documentsIds
      .map((_id): SnapshotOut<typeof DocumentModel> | undefined =>
        documentStore.documents.get(_id)
      )
      .filter((value): value is SnapshotOut<typeof DocumentModel> => !!value)

    const downloadDocument = (id: string, filename: string): void => {
      if (!disabled) {
        saveAs(createUrl(`/documents/fetch/${id}`), filename)
      }
    }

    return (
      <>
        {documents && documents.length > 0 && (
          <>
            <Divider />
            <div className="flex flex-col gap-4">
              {documents.map((doc) => (
                <button
                  key={doc._id}
                  className={`flex min-w-0 flex-row items-center gap-3 whitespace-nowrap rounded 
                  bg-neutral-96 py-4 px-5 ${
                    !disabled ? 'hover:bg-neutral-90' : ''
                  }`}
                  onClick={() => downloadDocument(doc._id, doc.filename)}
                  disabled={disabled}
                >
                  <Icon icon={IconChoices.DOCUMENT} size={24} />
                  <span
                    className="overflow-hidden text-ellipsis"
                    style={theme.textVariants.baseBold}
                  >
                    {doc.name}
                  </span>
                  <Icon icon={IconChoices.ARROW} size={24} highlighted />
                </button>
              ))}
            </div>
          </>
        )}
      </>
    )
  }
)
