import React from 'react'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../Icon'
import { ToastType } from './toast-type'

interface ToastProps {
  text?: string
  title: string
  customClose: (
    e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void
  width?: number
  maxWidth?: number
  minWidth?: number
  type?: ToastType
}

export const Toast = ({
  text,
  title,
  customClose,
  width = 300,
  maxWidth = 600,
  minWidth = 200,
  type = ToastType.TEXT,
}: ToastProps): JSX.Element | null => {
  const getIcon = (_type: ToastType): IconChoices | null => {
    switch (_type) {
      case ToastType.SUCCESS:
        return IconChoices.CHECKMARK_ROUND
      case ToastType.INFORMATION:
        return IconChoices.INFORMATION
      case ToastType.DANGER:
        return IconChoices.REPORT
      default:
        return null
    }
  }

  const getBackgroundColor = (_type: ToastType): string => {
    switch (_type) {
      case ToastType.INFORMATION:
        return 'bg-toast-information'
      case ToastType.SUCCESS:
        return 'bg-toast-success'
      case ToastType.DANGER:
        return 'bg-toast-error'
      default:
        return 'bg-toast-gray'
    }
  }

  const icon = getIcon(type)
  const bgColor = getBackgroundColor(type)

  return (
    <div
      style={{ width, maxWidth, minWidth }}
      className={`pointer-events-auto mx-auto block flex justify-between rounded-lg p-4
        shadow-lg ${bgColor}`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="flex gap-3 text-white">
        {icon && <Icon icon={icon} color="white" />}
        <div className="flex flex-col">
          <p style={theme.textVariants.baseBold} className="break-words">
            {title}
          </p>
          {text && (
            <p style={theme.textVariants.base} className="break-words">
              {text}
            </p>
          )}
        </div>
      </div>
      <button onClick={customClose}>
        <Icon color="white" icon={IconChoices.CLOSE_CROSS} />
      </button>
    </div>
  )
}
