/* eslint-disable max-len */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { useStores } from '../../../../../../hooks/useStores'
import { useToastNotifications } from '../../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../../common/Toast/toast-type'
import { Modal } from '../../../../../common/Modal'
import { ModalHeader } from '../../../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../../../common/Modal/ModalBody'
import { CreateUpdateSocietyWebsiteFAQSectionForm } from '../CreateUpdateSocietyWebsiteFAQSectionForm'
import { NSocietiesWebsites } from '../../../../../../interfaces/services/societies-websites.interfaces'
import { societyWebsiteFAQSectionCreateSchema } from '../../../../../../forms/schemas/society_website_faq_section_create'
import { SocietyWebsiteModel } from '../../../../../../state/models/society-website'

interface CreateUpdateSocietyWebsiteFAQSectionModalProps {
  website: SnapshotOut<typeof SocietyWebsiteModel>
  show: boolean
  close: () => void
  children?: React.ReactNode
  sectionId?: string
}

export const CreateUpdateSocietyWebsiteFAQSectionModal = observer(
  ({
    website,
    sectionId,
    children,
    show,
    close,
  }: CreateUpdateSocietyWebsiteFAQSectionModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { societyWebsitesStore } = useStores()
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!sectionId

    const showErrorMessage = (): void => {
      const message = updateMode
        ? 'createUpdateQuestionsAndAnswers.flashMessage.updateSectionFailure'
        : 'createUpdateQuestionsAndAnswers.flashMessage.createSectionFailure'
      setToastNotification(ToastType.DANGER, translate(message))
    }

    const showSuccessMessage = (): void => {
      const message = updateMode
        ? 'createUpdateQuestionsAndAnswers.flashMessage.updateSectionSuccess'
        : 'createUpdateQuestionsAndAnswers.flashMessage.createSectionSuccess'
      setToastNotification(ToastType.SUCCESS, translate(message))
    }

    const getUpdatedSection = (
      data: InferType<typeof societyWebsiteFAQSectionCreateSchema>
    ): NSocietiesWebsites.NPatch.IRequestBody => {
      return {
        brokerQuestion: {
          ...website.brokerQuestion,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          sections: website.brokerQuestion?.sections?.map((_section) => {
            if (_section._id === sectionId) {
              return {
                ..._section,
                title: data.title,
              }
            }
            return _section
          }),
        },
      }
    }

    const getNewSection = (
      data: InferType<typeof societyWebsiteFAQSectionCreateSchema>
    ): NSocietiesWebsites.NPatch.IRequestBody => {
      return {
        brokerQuestion: {
          ...website.brokerQuestion,
          sections: [
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...(website.brokerQuestion?.sections
              ? website.brokerQuestion.sections
              : []),
            {
              title: data.title,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              questions: [],
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              order: website.brokerQuestion?.sections
                ? website.brokerQuestion.sections.length
                : 0,
            },
          ],
        },
      }
    }

    const onSubmit = async (
      data: InferType<typeof societyWebsiteFAQSectionCreateSchema>
    ): Promise<void> => {
      if (updateMode) {
        const formData = getUpdatedSection(data)
        const status = await societyWebsitesStore.patchWebsite(
          website._id,
          formData
        )

        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      } else {
        const formData = getNewSection(data)
        const status = await societyWebsitesStore.patchWebsite(
          website._id,
          formData
        )
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      showErrorMessage()
    }

    const loading = societyWebsitesStore.updatingWebsite === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate(
                  updateMode
                    ? 'createUpdateQuestionsAndAnswers.editSectionTitle'
                    : 'createUpdateQuestionsAndAnswers.addNewSectionTitle'
                )}
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <CreateUpdateSocietyWebsiteFAQSectionForm
                  website={website}
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  sectionId={sectionId}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
