/* eslint-disable max-len */
import React from 'react'
import Checklist from '../../../assets/illustrations/checklist.svg'
import SocietyPlaceholder from '../../../assets/illustrations/society_placeholder.svg'
import Bug from '../../../assets/illustrations/bug.svg'
import Empty from '../../../assets/illustrations/empty.svg'
import Sun from '../../../assets/illustrations/sun.svg'
import Lemonade from '../../../assets/illustrations/lemonade.svg'
import Waiting from '../../../assets/illustrations/waiting.svg'
import Architect from '../../../assets/illustrations/architect.svg'
import Buildings from '../../../assets/illustrations/buildings.svg'
import WateringPlant from '../../../assets/illustrations/watering_plant.svg'
import Happy from '../../../assets/illustrations/happy.svg'
import Conversation from '../../../assets/illustrations/conversation.svg'
import NoteTaking from '../../../assets/illustrations/note_taking.svg'
import NewMessage from '../../../assets/illustrations/new_message.svg'
import Winners from '../../../assets/illustrations/winners.svg'
import Konfetti from '../../../assets/illustrations/konfetti.svg'
import PersonCheckingPhone from '../../../assets/illustrations/person_checking_phone.svg'

export enum IllustrationChoices {
  SOCIETY_PLACEHOLDER = 'society_placeholder',
  BUG = 'bug',
  EMPTY = 'empty',
  SUN = 'sun',
  LEMONADE = 'lemonade',
  WAITING = 'waiting',
  CHECKLIST = 'checklist',
  ARCHITECT = 'architect',
  BUILDINGS = 'buildings',
  WATERING_PLANT = 'watering_plant',
  HAPPY = 'happy',
  CONVERSATION = 'conversation',
  NOTE_TAKING = 'note_taking',
  NEW_MESSAGE = 'new_message',
  WINNERS = 'winners',
  KONFETTI = 'konfetti',
  PERSON_CHECKING_PHONE = 'person_checking_phone',
}
interface IllustrationProps {
  illustrationChoice: IllustrationChoices
  width?: number
  height?: number
  scale?: number
  style?: React.CSSProperties
}

export const Illustration = ({
  illustrationChoice,
  width,
  height,
  scale = 1,
  style,
}: IllustrationProps): JSX.Element | null => {
  const getIllustration = (): {
    SelectedIllustration:
      | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
      | undefined
    illustrationWidth: number
    illustrationHeight: number
  } => {
    switch (illustrationChoice) {
      case IllustrationChoices.SOCIETY_PLACEHOLDER:
        return {
          SelectedIllustration: SocietyPlaceholder,
          illustrationWidth: 768,
          illustrationHeight: 366,
        }
      case IllustrationChoices.BUG:
        return {
          SelectedIllustration: Bug,
          illustrationWidth: 279,
          illustrationHeight: 185,
        }
      case IllustrationChoices.EMPTY:
        return {
          SelectedIllustration: Empty,
          illustrationWidth: 185,
          illustrationHeight: 151,
        }
      case IllustrationChoices.SUN:
        return {
          SelectedIllustration: Sun,
          illustrationWidth: 198,
          illustrationHeight: 171,
        }
      case IllustrationChoices.LEMONADE:
        return {
          SelectedIllustration: Lemonade,
          illustrationWidth: 85,
          illustrationHeight: 66,
        }
      case IllustrationChoices.WAITING:
        return {
          SelectedIllustration: Waiting,
          illustrationWidth: 197,
          illustrationHeight: 182,
        }
      case IllustrationChoices.CHECKLIST:
        return {
          SelectedIllustration: Checklist,
          illustrationWidth: 186,
          illustrationHeight: 142,
        }
      case IllustrationChoices.ARCHITECT:
        return {
          SelectedIllustration: Architect,
          illustrationWidth: 196,
          illustrationHeight: 176,
        }
      case IllustrationChoices.BUILDINGS:
        return {
          SelectedIllustration: Buildings,
          illustrationWidth: 190,
          illustrationHeight: 130,
        }
      case IllustrationChoices.WATERING_PLANT:
        return {
          SelectedIllustration: WateringPlant,
          illustrationWidth: 203,
          illustrationHeight: 170,
        }
      case IllustrationChoices.HAPPY:
        return {
          SelectedIllustration: Happy,
          illustrationWidth: 190,
          illustrationHeight: 234,
        }
      case IllustrationChoices.CONVERSATION:
        return {
          SelectedIllustration: Conversation,
          illustrationWidth: 186,
          illustrationHeight: 137,
        }
      case IllustrationChoices.NOTE_TAKING:
        return {
          SelectedIllustration: NoteTaking,
          illustrationWidth: 198,
          illustrationHeight: 196,
        }
      case IllustrationChoices.NEW_MESSAGE:
        return {
          SelectedIllustration: NewMessage,
          illustrationWidth: 189,
          illustrationHeight: 157,
        }
      case IllustrationChoices.WINNERS:
        return {
          SelectedIllustration: Winners,
          illustrationWidth: 203,
          illustrationHeight: 180,
        }
      case IllustrationChoices.KONFETTI:
        return {
          SelectedIllustration: Konfetti,
          illustrationWidth: 328,
          illustrationHeight: 328,
        }
      case IllustrationChoices.PERSON_CHECKING_PHONE:
        return {
          SelectedIllustration: PersonCheckingPhone,
          illustrationWidth: 182,
          illustrationHeight: 205,
        }
      default:
        return {
          SelectedIllustration: undefined,
          illustrationWidth: -1,
          illustrationHeight: -1,
        }
    }
  }

  const { SelectedIllustration, illustrationWidth, illustrationHeight } =
    getIllustration()

  const illustrationAspectRatio = illustrationHeight / illustrationWidth

  const getHeight = (): number | undefined => {
    if (height) {
      return height
    }
    if (width) {
      return illustrationAspectRatio * width
    }
    return undefined
  }

  const getWidth = (): number | undefined => {
    if (width) {
      return width
    }
    return undefined
  }

  const _height = getHeight()
  const _width = getWidth()

  if (!SelectedIllustration) {
    return null
  }

  return (
    <SelectedIllustration
      width={(_width || illustrationWidth) * scale}
      height={(_height || illustrationHeight) * scale}
      style={style}
    />
  )
}
