import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Compressor from 'compressorjs'
import { SocietyWebsiteBase } from '../SocietyWebsiteBase'
import { SocietyWebsiteModel } from '../../../../state/models/society-website'
import { imageUploadReducer } from '../../../../reducers/image-upload'
import { useStores } from '../../../../hooks/useStores'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { Toggle } from '../../../common/Toggle'
import { MediaImage } from '../../../common/Image'
import {
  DropZone,
  DropZoneFile,
  DropZoneVariant,
} from '../../../common/DropZone'
import { ImageActions } from '../../../../actions/image-upload'
import { NSocietiesWebsites } from '../../../../interfaces/services/societies-websites.interfaces'
import { societyWebsiteImagesUploadSchema } from '../../../../forms/schemas/society_website_images_upload'
import { ProcessedImage } from '../../../../types/image-upload'
import { theme } from '../../../../theme/theme'
import { convertBase64 } from '../../../../api/helpers'
import { Button, ButtonVariant } from '../../../common/Button'
import { IconChoices } from '../../../common/Icon'
import { TextInput } from '../../../common/TextInput'
import { isImageType } from '../../../../helpers/types'

interface SocietyWebsiteImagesProps {
  website: SnapshotOut<typeof SocietyWebsiteModel>
}

export const SocietyWebsiteImages = observer(
  ({ website }: SocietyWebsiteImagesProps): JSX.Element => {
    const { societyWebsitesStore, mediaStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const [uploadingPicture, setUploadingPicture] = useState(false)
    const [saving, setSaving] = useState(false)
    const { translate } = useAppTranslation()
    const [showImages, setShowImages] = useState<boolean>(
      website?.societyPictures?.show !== undefined
        ? website.societyPictures?.show
        : true
    )

    const [pictureMainState, pictureMainDispatch] = useReducer(
      imageUploadReducer,
      {
        images: website.pictureMain
          ? [
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                id: website.pictureMain?._id,
                uploading: false,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                uri: website.pictureMain?.url,
              },
            ]
          : [],
      }
    )

    const initialSocietyPictures = website.societyPictures?.pictures.map(
      (picture) => ({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        id: picture.media._id as string,
        uploading: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        uri: picture.media.url as string,
        description: picture.description,
      })
    )

    const [societyPicturesState, societyPicturesDispatch] = useReducer(
      imageUploadReducer,
      {
        images: initialSocietyPictures || [],
      }
    )

    const getDefaultValues = useMemo(() => {
      return {
        mainPictureId: [],
        societyPictureIds: [],
        show: false,
      }
    }, [])

    const {
      handleSubmit,
      setValue,
      control,
      formState: { isValid },
    } = useForm<InferType<typeof societyWebsiteImagesUploadSchema>>({
      mode: 'all',
      resolver: yupResolver(societyWebsiteImagesUploadSchema),
      defaultValues: getDefaultValues,
    })

    useEffect(() => {
      setValue(
        'mainPictureId',
        pictureMainState.images
          .map((_image) => _image.id)
          .filter((_id) => _id !== undefined),
        { shouldValidate: true }
      )
    }, [pictureMainState, setValue])

    useEffect(() => {
      setValue(
        'societyPictureIds',
        societyPicturesState.images
          .map((_image) => _image.id)
          .filter((_id) => _id !== undefined),
        { shouldValidate: true }
      )
    }, [societyPicturesState, setValue])

    const onSubmit = async (): Promise<void> => {
      setSaving(true)
      const data: NSocietiesWebsites.NPatch.IRequestBody = {
        ...(pictureMainState.images.length > 0 && {
          pictureMainId: pictureMainState.images[0].id,
        }),
        societyPictures: {
          show: showImages,
          pictures: societyPicturesState.images.map((image) => ({
            mediaId: image.id,
            description: image.description || '',
          })),
        },
      }

      const status = await societyWebsitesStore.patchWebsite(website._id, data)

      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('flashMessage.changesSaved')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.changesNotSavedError')
        )
      }
      setSaving(false)
    }

    const uploadMainImage = async (file: DropZoneFile): Promise<void> => {
      setUploadingPicture(true)
      const image = {
        uri: file.uri,
        base64: file.base64,
      }

      try {
        pictureMainDispatch({ type: ImageActions.ADD, image })

        const mediaId = await mediaStore.createMedia('image', image.base64)

        pictureMainDispatch({
          type: ImageActions.SET_UPLOADED,
          mediaId: mediaId as string,
          image,
        })
      } catch (error) {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }

      setUploadingPicture(false)
    }

    const compressAndUploadImage = async (
      image: File | Blob
    ): Promise<void> => {
      return new Promise((resolve, reject) => {
        if (!isImageType(image.type)) {
          setToastNotification(
            ToastType.DANGER,
            translate('uploadImage.invalidImageType')
          )
          reject()
        }
        // eslint-disable-next-line no-new
        new Compressor(image, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success: async (result) => {
            const _image: ProcessedImage = {
              uri: URL.createObjectURL(image),
            }

            societyPicturesDispatch({ type: ImageActions.ADD, image: _image })

            const base64 = await convertBase64(result)
            const id = await mediaStore.createMedia('image', base64 as string)

            societyPicturesDispatch({
              type: ImageActions.SET_UPLOADED,
              mediaId: id as string,
              image: _image,
            })

            resolve()
          },
          error: (error) => reject(error),
        })
      })
    }

    const onUploadSubImages = async (
      e: React.ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
      setUploadingPicture(true)
      const images = e.target.files && e.target.files
      if (!images) {
        return
      }
      const promises = Array.from(images).map((image) =>
        compressAndUploadImage(image)
      )
      await Promise.allSettled(promises)
      setUploadingPicture(false)
      e.target.value = ''
    }

    const onError = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.changesNotSavedError')
      )
    }

    const onShowChange = (): void => {
      setShowImages(!showImages)
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const mainPictureId = pictureMainState.images[0]?.id

    const onMainImageRemove = (): void => {
      pictureMainDispatch({
        type: ImageActions.REMOVE,
        id: mainPictureId as string,
      })
    }

    const onSubImageRemove = (imageId: string): void => {
      societyPicturesDispatch({
        type: ImageActions.REMOVE,
        id: imageId,
      })
    }

    const onDescriptionChange = (
      e: React.FormEvent<HTMLInputElement>,
      imageId: string
    ): void => {
      societyPicturesDispatch({
        type: ImageActions.SET_DESCRIPTION,
        description: e.currentTarget.value,
        id: imageId,
      })
    }

    return (
      <form
        className="w-full max-w-3xl"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <SocietyWebsiteBase
          className="mb-6 flex flex-col gap-6"
          isValid={isValid}
          website={website}
          loading={uploadingPicture || saving}
        >
          <p style={theme.textVariants.baseBold}>
            {translate(
              'societyManagementWebsiteImages.headers.primaryImageTitle'
            )}
          </p>
          <div className="flex flex-col gap-2">
            {mainPictureId ? (
              <MediaImage
                className="h-72 rounded border border-neutral-80 bg-neutral-96"
                objectFit="object-contain"
                mediaId={mainPictureId}
                onDeleteClick={onMainImageRemove}
                shouldCloseTopRight
              />
            ) : (
              <DropZone
                loading={uploadingPicture}
                onUpload={(status, file: DropZoneFile) => {
                  if (status === 'accepted') {
                    uploadMainImage(file)
                  } else {
                    setToastNotification(
                      ToastType.DANGER,
                      translate('flashMessage.somethingWentWrongError')
                    )
                  }
                }}
                variant={DropZoneVariant.MEDIA}
              />
            )}
            <p style={theme.textVariants.caption}>
              {translate(
                'societyManagementWebsiteImages.recommendedPrimaryImageSizeText'
              )}
            </p>
          </div>
          <p style={theme.textVariants.baseBold} className="mt-2 md:mt-6">
            {translate(
              'societyManagementWebsiteImages.headers.societyImagesTitle'
            )}
          </p>
          <Toggle
            label={translate('societyWebsite.showOnWebsite')}
            name="societyWebsiteFAQShow"
            enabled={showImages}
            onChange={onShowChange}
            wrapperClassName="flex flex-row-reverse gap-2 w-fit"
          />
          <div className="flex flex-wrap justify-center gap-8 sm:justify-start">
            {societyPicturesState.images.map((image) => (
              <div
                className="box-border flex w-[234px] flex-col
                  rounded border border-neutral-80 "
                key={image.uri}
              >
                <MediaImage
                  className="h-44 w-[234px]"
                  mediaId={image.id}
                  onDeleteClick={() => onSubImageRemove(image.id as string)}
                  shouldCloseTopRight
                />
                <TextInput
                  value={image.description}
                  onBlur={() => null}
                  borderless
                  placeholder={translate(
                    'societyManagementWebsiteImages.addImageDescription'
                  )}
                  onChange={(e) => onDescriptionChange(e, image.id as string)}
                />
              </div>
            ))}
          </div>
          <label
            htmlFor="society-picture-input"
            className="w-fit cursor-pointer"
          >
            <Button
              className="pointer-events-none"
              variant={ButtonVariant.DEFAULT}
              size="sm"
              label={translate(
                'societyManagementWebsiteImages.addImagesButton'
              )}
              icon={IconChoices.PLUS_SIGN}
            />
          </label>
          <Controller
            control={control}
            name="societyPictureIds"
            render={() => (
              <input
                id="society-picture-input"
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                className="hidden"
                multiple
                onChange={onUploadSubImages}
              />
            )}
          />
        </SocietyWebsiteBase>
      </form>
    )
  }
)
