import React from 'react'
import { Button } from '../Button'
import { IconChoices } from '../Icon'

interface BackButtonProps {
  onClick: () => void
}

export const BackButton = ({ onClick }: BackButtonProps): JSX.Element => {
  return (
    <Button
      type="button"
      className="h-10 w-10 border-none bg-neutral-96 opacity-75 hover:opacity-100"
      size="no-padding"
      iconSize={24}
      icon={IconChoices.ARROW_LEFT}
      onClick={onClick}
    />
  )
}
