import { AxiosResponse } from 'axios'
import { createUrl } from './helpers'
import axiosAuth from './config'
import { NHouseRules } from '../interfaces/services/house-rules.interfaces'

export const getHouseRulesPerSociety = (
  societyId: string
): Promise<AxiosResponse<NHouseRules.NGetPerSocieties.IResponse>> => {
  const body: NHouseRules.NGetPerSocieties.IRequestBody = {
    societyIds: [societyId],
  }
  return axiosAuth.post<NHouseRules.NGetPerSocieties.IResponse>(
    createUrl(`/house-rules/get-per-societies`),
    body
  )
}

export const getHouseRule = (
  id: string
): Promise<AxiosResponse<NHouseRules.NGetById.IResponse>> => {
  return axiosAuth.get<NHouseRules.NGetById.IResponse>(
    createUrl(`/house-rules/rule/${id}`)
  )
}

export const createHouseRule = (
  body: NHouseRules.NCreate.IRequestBody
): Promise<AxiosResponse<NHouseRules.NCreate.IResponse>> => {
  return axiosAuth.post<NHouseRules.NCreate.IResponse>(
    createUrl(`/house-rules/rule`),
    body
  )
}

export const updateHouseRule = (
  id: string,
  body: NHouseRules.NPatch.IRequestBody
): Promise<AxiosResponse<NHouseRules.NPatch.IResponse>> => {
  return axiosAuth.patch<NHouseRules.NPatch.IResponse>(
    createUrl(`/house-rules/rule/${id}`),
    body
  )
}

export const deleteHouseRule = (
  id: string
): Promise<AxiosResponse<NHouseRules.NDel.IResponse>> => {
  return axiosAuth.delete<NHouseRules.NDel.IResponse>(
    createUrl(`/house-rules/rule/${id}`)
  )
}
