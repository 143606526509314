import React, { useCallback, useState } from 'react'
import Fuse from 'fuse.js'
import { observer } from 'mobx-react-lite'
import { SpinnerWrapper } from '../../common/SpinnerWrapper'
import { ErrorView } from '../../../views/error/ErrorView'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { IllustrationChoices } from '../../common/Illustration'
import { useStores } from '../../../hooks/useStores'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { ResidentUserType } from '../../../state/stores/units'
import { SearchBar } from '../../common/SearchBar'
import { RegisterMemberRequestCard } from './RegisterMemberRequestCard'
import { RegisterMemberPreApprovedCard } from './RegisterMemberPreApprovedCard'
import { ResidentList } from '../ResidentList'

interface RegisterMemberListProps {
  residentUserType: ResidentUserType
}

export const RegisterMemberList = observer(
  ({ residentUserType }: RegisterMemberListProps): JSX.Element => {
    const [searchString, setSearchString] = useState('')
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { societyStore, unitStore } = useStores()

    const usersWithUnits = unitStore.residentUsersForSocietyWithUnits(
      society._id,
      residentUserType
    )

    const getFilteredUsers = useCallback(() => {
      const options = {
        threshold: 0.2,
        keys: [
          'user.fullName',
          'user.name',
          'user.surname',
          'units.title',
          'units.titleLegal',
        ],
      }

      const fuse = new Fuse(usersWithUnits, options)

      const filteredUsers =
        searchString === ''
          ? usersWithUnits
          : fuse.search(searchString).map((result) => result.item)

      return filteredUsers.map((_user) => _user.user)
    }, [usersWithUnits, searchString])

    const onSearchBarChange = (e: React.FormEvent<HTMLInputElement>): void => {
      setSearchString(e.currentTarget.value)
    }

    const loading = societyStore.fetchingSociety === 'pending'

    if (loading) {
      return <SpinnerWrapper />
    }

    const users = getFilteredUsers()

    return (
      <div className="flex w-full flex-col gap-6">
        <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
          <RegisterMemberRequestCard residentUserType={residentUserType} />
          <RegisterMemberPreApprovedCard residentUserType={residentUserType} />
        </div>
        <SearchBar
          className="w-full md:w-80"
          widthClassName="flex grow"
          value={searchString}
          onChange={onSearchBarChange}
        />
        {!loading && users.length > 0 && (
          <ResidentList
            users={users}
            society={society}
            editUserUnitsMode={residentUserType}
            deleteUserOption
          />
        )}
        {!loading && users.length === 0 && searchString === '' && (
          <ErrorView
            title={translate('registerView.emptyState.member.title')}
            illustration={IllustrationChoices.EMPTY}
          />
        )}
        {!loading && users.length === 0 && searchString !== '' && (
          <ErrorView
            title={translate('registerView.emptyState.member.noSearchResults')}
            illustration={IllustrationChoices.EMPTY}
          />
        )}
      </div>
    )
  }
)
