import React, {
  Children,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { SpinnerWrapper } from '../SpinnerWrapper'

interface InfiniteScrollProps {
  onEndReached: () => void
  children?: ReactNode
  loading?: boolean
  rootRef?: React.MutableRefObject<HTMLDivElement | null>
  className?: string
  spinnerCustomStyles?: string
  overflow?: string
}

export const InfiniteScroll = ({
  children,
  onEndReached,
  loading,
  rootRef,
  className,
  spinnerCustomStyles,
  overflow = 'overflow-auto',
}: InfiniteScrollProps): JSX.Element => {
  const [prevChildrenCount, setPrevChildrenCount] = useState(0)
  const loader = useRef(null)

  useEffect(() => {
    children && setPrevChildrenCount(Children.count(children))
  }, [children])

  // IntersectionObserver infinite scroll from
  // https://medium.com/suyeonme/react-how-to-implement-an-infinite-scroll-749003e9896a
  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0]
      if (
        target.isIntersecting &&
        prevChildrenCount !== Children.count(children)
      ) {
        onEndReached()
      }
    },
    [children, onEndReached, prevChildrenCount]
  )

  useEffect(() => {
    const option = {
      root: rootRef?.current || null,
      threshold: 0.5,
    }
    const _observer = new IntersectionObserver(handleObserver, option)
    if (loader.current) _observer.observe(loader.current)
  }, [handleObserver, rootRef])

  return (
    <div className="relative mb-2 flex min-h-0 flex-1 flex-col">
      {loading && <SpinnerWrapper className={spinnerCustomStyles} />}
      <div className={`w-full grow ${overflow} ${className ?? ''}`}>
        {children}
        <div ref={loader} />
        <div className="h-2" />
      </div>
    </div>
  )
}
