import React, { KeyboardEvent } from 'react'
import { saveAs } from 'file-saver'
import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { Icon, IconChoices } from '../Icon'
import { MediaModel } from '../../../state/models/media'
import { createUrl } from '../../../api/helpers'

interface MediaGalleryModalFooterProps {
  media: SnapshotOut<typeof MediaModel> | undefined
}

export const MediaGalleryModalFooter = ({
  media,
}: MediaGalleryModalFooterProps): JSX.Element => {
  const { translate } = useAppTranslation()

  const downloadImage = (): void => {
    media &&
      saveAs(
        createUrl(`/media/fetch/${media._id as string}`),
        media._id.slice(0, 7) as string
      )
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      downloadImage()
    }
  }

  return (
    <div
      className="flex gap-2"
      role="button"
      aria-label="Download"
      onClick={downloadImage}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <p className="m-0">{translate('mediaGallery.downloadImageButtonText')}</p>
      <Icon icon={IconChoices.DOWNLOAD} highlighted />
    </div>
  )
}
