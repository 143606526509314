import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../../hooks/useStores'
import { BookingModel } from '../../../../state/models/booking'
import { SocietyModel } from '../../../../state/models/society'
import { FacilityModel } from '../../../../state/models/facility'
import { useIsMobile } from '../../../../hooks/useIsMobile'
import { useModal } from '../../../../hooks/useModal'
import { useDeleteBookingAllowed } from './useDeleteBookingAllowed'
import { DeleteBookingConfirmationModal } from '../DeleteBookingConfirmationModal'
import { FacilityBookingListItemInner } from './FacilityBookingListItemInner'

export interface FacilityBookingListItem {
  booking: SnapshotOut<typeof BookingModel>
  facility: SnapshotOut<typeof FacilityModel>
}

export const FacilityBookingListItem = observer(
  ({ booking, facility }: FacilityBookingListItem): JSX.Element | null => {
    const { societyStore } = useStores()

    const isMobile = useIsMobile()
    const {
      show: showDeleteBookingModal,
      open: openDeleteBookingModal,
      close: closeDeleteBookingModal,
    } = useModal()

    const society = societyStore.societies.get(
      booking.societyId
    ) as SnapshotOut<typeof SocietyModel>

    const { allowed: deleteBookingAllowed, otherUsersOwnsBooking } =
      useDeleteBookingAllowed(booking, society)

    return (
      <>
        {isMobile ? (
          <button
            className="w-full"
            disabled={!deleteBookingAllowed}
            onClick={deleteBookingAllowed ? openDeleteBookingModal : undefined}
          >
            <FacilityBookingListItemInner
              booking={booking}
              facility={facility}
              openDeleteBookingModal={openDeleteBookingModal}
              deleteBookingAllowed={deleteBookingAllowed}
            />
          </button>
        ) : (
          <FacilityBookingListItemInner
            booking={booking}
            facility={facility}
            openDeleteBookingModal={openDeleteBookingModal}
            deleteBookingAllowed={deleteBookingAllowed}
          />
        )}
        <DeleteBookingConfirmationModal
          showModal={showDeleteBookingModal}
          closeModal={closeDeleteBookingModal}
          booking={booking}
          otherUsersOwnsBooking={otherUsersOwnsBooking}
        />
      </>
    )
  }
)
