import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Modal } from '../../../../../components/common/Modal'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { ModalHeader } from '../../../../../components/common/Modal/ModalHeader'
import { ModalBody } from '../../../../../components/common/Modal/ModalBody'
import { DeleteUserModalContent } from './DeleteUserModalContent'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../../components/common/Toast/toast-type'
import { useStores } from '../../../../../hooks/useStores'
import { Spinner } from '../../../../../components/common/Spinner'

interface DeleteUserModalProps {
  show: boolean
  close: () => void
  resetSearchResult: () => void
  userId: string
}

export const DeleteUserModal = observer(
  ({
    show,
    close,
    userId,
    resetSearchResult,
  }: DeleteUserModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { adminStore, userStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const [deletingUser, setDeletingUser] = useState(false)

    const onSubmit = async (): Promise<void> => {
      setDeletingUser(true)
      const status = await adminStore.deleteUser({ userId })
      if (status) {
        resetSearchResult()
        close()
        setToastNotification(ToastType.SUCCESS, 'Användare raderad')
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }
      setDeletingUser(false)
    }

    useEffect(() => {
      userStore.getUser(userId)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Modal show={show}>
        {{
          header: <ModalHeader onClose={close}>Radera användare</ModalHeader>,
          body: (
            <ModalBody className="max-h-96 overflow-y-auto">
              {deletingUser ? (
                <Spinner />
              ) : (
                <DeleteUserModalContent
                  onSubmit={onSubmit}
                  onClose={close}
                  userId={userId}
                />
              )}
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
