import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { EventModel } from '../../../state/models/event'
import { BoardRoomEventModel } from '../../../state/models/boardroom-event'
import { useAuthenticatedUserId } from '../../../hooks/useAuthenticatedUserId'
import { Button } from '../../../components/common/Button'
import { useStores } from '../../../hooks/useStores'
import { IconChoices } from '../../../components/common/Icon'
import { theme } from '../../../theme/theme'
import { useAppTranslation } from '../../../hooks/useAppTranslation'

interface EventAttendanceButtonProps {
  label: string
  active: boolean
  backgroundColorClassName: string
  onClick: () => void
  icon?: IconChoices
  iconColor?: string
  loading?: boolean
  disabled?: boolean
}

export const EventAttendanceButton = ({
  label,
  active,
  backgroundColorClassName,
  onClick,
  icon,
  iconColor,
  loading,
  disabled,
}: EventAttendanceButtonProps): JSX.Element => {
  return (
    <Button
      iconColor={iconColor || theme.colors.white}
      icon={icon}
      label={label}
      className={`
      bg-transparent border-${backgroundColorClassName} border-1
      hover:bg-${backgroundColorClassName}
      hover:bg-opacity-70
      ${active ? `bg-${backgroundColorClassName}` : ''}`}
      loading={loading}
      onClick={onClick}
      disabled={disabled}
    />
  )
}

interface EventAttendanceButtonsProps {
  event:
    | SnapshotOut<typeof EventModel>
    | SnapshotOut<typeof BoardRoomEventModel>
  boardRoomEvent: boolean | undefined
}

export const EventAttendanceButtons = observer(
  ({ event, boardRoomEvent }: EventAttendanceButtonsProps): JSX.Element => {
    const userId = useAuthenticatedUserId() as string
    const { translate } = useAppTranslation()
    const { eventStore, boardRoomEventStore } = useStores()

    const userAttendance = event?.attendance?.find(
      (attendance) => attendance.userId === userId
    )
    const userAttending = userAttendance?.status === 'attending'
    const userNotAttending = userAttendance?.status === 'not_attending'

    const loading = boardRoomEvent
      ? boardRoomEventStore.updatingEventAttendance === 'pending'
      : eventStore.updatingEventAttendance === 'pending'

    return (
      <div className="flex gap-2">
        <EventAttendanceButton
          label={translate('common.eventActions.attendance.attending')}
          icon={IconChoices.CHECKMARK}
          iconColor={theme.colors.brandGreen}
          active={userAttending}
          backgroundColorClassName="brandGreen"
          loading={loading}
          disabled={loading || userAttending}
          onClick={() => {
            if (boardRoomEvent) {
              boardRoomEventStore.updateEventAttendance(event._id, 'attending')
            } else {
              eventStore.updateEventAttendance(event._id, 'attending')
            }
          }}
        />
        <EventAttendanceButton
          label={translate('common.eventActions.attendance.notAttending')}
          icon={IconChoices.CROSS}
          iconColor={theme.colors.red}
          active={userNotAttending}
          backgroundColorClassName="red"
          loading={loading}
          disabled={loading || userNotAttending}
          onClick={() => {
            if (boardRoomEvent) {
              boardRoomEventStore.updateEventAttendance(
                event._id,
                'not_attending'
              )
            } else {
              eventStore.updateEventAttendance(event._id, 'not_attending')
            }
          }}
        />
      </div>
    )
  }
)
