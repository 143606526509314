import React from 'react'
import { SimpleLink } from '../../../../../components/common/SimpleLink'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { theme } from '../../../../../theme/theme'

export const BoappaVendor = (): JSX.Element => {
  const { translate } = useAppTranslation()
  const vendors = [
    { name: 'exoscale', url: 'https://www.exoscale.com/' },
    { name: 'billogram', url: 'https://billogram.com/' },
    { name: 'mailchimp', url: 'https://mailchimp.com/' },
    { name: 'mixpanel', url: 'https://mixpanel.com/' },
    { name: 'firebase', url: 'https://firebase.google.com/' },
    { name: 'cloudflare', url: 'https://www.cloudflare.com/' },
  ]

  return (
    <>
      <div className="mb-4">
        {translate('gdprView.boappaVendor.description')}
      </div>
      <div className="space-y-2">
        {vendors.map((vendor) => {
          const vendorTranslationBaseString = `gdprView.boappaVendor.vendors.${vendor.name}`
          return (
            <div key={vendor.name}>
              <SimpleLink
                style={theme.textVariants.baseBold}
                href={vendor.url}
                text={translate(`${vendorTranslationBaseString}.name`)}
              />{' '}
              - {translate(`${vendorTranslationBaseString}.description`)}
              {'\n'}
            </div>
          )
        })}
      </div>
    </>
  )
}
