import React from 'react'
import { Link } from 'react-router-dom'
import { Button, ButtonProps, ButtonVariant } from '../Button'

export interface LinkButtonProps extends ButtonProps {
  url: string
  urlIsExternal?: boolean
  variant?: ButtonVariant
}

export const LinkButton = ({
  url,
  urlIsExternal = true,
  variant = ButtonVariant.PRIMARY,
  ...buttonProps
}: LinkButtonProps): JSX.Element => {
  const button = <Button variant={variant} tabIndex={-1} {...buttonProps} />

  return urlIsExternal ? (
    <a href={url} target="_blank" rel="noreferrer noopener">
      {button}
    </a>
  ) : (
    <Link to={url}>{button}</Link>
  )
}
