/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import {
  getFaqsPerSociety as apiGetFaqsPerSociety,
  patchFaq as apiPatchFaq,
  createFaq as apiCreateFaq,
  deleteFaq as apiDeleteFaq,
} from '../../api/faqs'
import { stateType } from '../types/common'
import { setObject } from './helpers'
import { FAQQuestionModel, FAQSectionModel } from '../models/faq'
import { IFAQModel } from '../../interfaces/models/faqs.interfaces'
import { NFaqs } from '../../interfaces/services/faqs.interfaces'
import { uniqueBy } from '../../helpers/array'
import { sortStringsAlphabetically } from '../../helpers/sorting'

interface FaqSectionModel extends IFAQModel {
  questions?: IFAQModel[]
}

const separateSectionsAndQuestions = (data: IFAQModel[]): FaqSectionModel[] => {
  const rootSections = [
    // @ts-ignore
    ...new Map(data.map((item) => [item.section, item])).values(),
  ]
  // Unfortunately backend returns an array that might include multiple
  // objects with the same name (no uniqueness) and there's no foreign key
  // relationship between questions and sections. Instead, the non-unique
  // string field "section" should be use to determine which questions belong
  // to which section.
  const uniqueRootSections = uniqueBy('section', rootSections)

  const questions = data.filter(
    (section) => 'question' in section && section.question !== ''
  )

  const processedSections = uniqueRootSections.reduce(
    (prev: FaqSectionModel[], curr) => {
      const section = {
        ...curr,
        questions: questions.filter(
          (question) => question.section === curr.section
        ),
      }
      prev.push(section)
      return prev
    },
    []
  )

  return processedSections
}

export const FAQStore = types
  .model('FAQStore')
  .props({
    sections: types.map(FAQSectionModel),
    questions: types.map(FAQQuestionModel),
    fetchingFAQs: stateType,
    updatingFAQ: stateType,
    creatingFAQ: stateType,
    deletingFAQ: stateType,
  })
  .views((self) => ({
    get sortedFAQSections() {
      return (
        // @ts-ignore
        (values(self.sections) as SnapshotOut<typeof FAQSectionModel>[]).sort(
          (a, b) => sortStringsAlphabetically(a.section, b.section)
        )
      )
    },
  }))
  .views((self) => ({
    faqSectionsForSociety(
      societyId: string
    ): SnapshotOut<typeof FAQSectionModel>[] {
      return (
        self.sortedFAQSections
          // @ts-ignore
          .filter((section) => section.society._id === societyId)
          .sort((a, b) => sortStringsAlphabetically(a.section, b.section))
      )
    },
    getQuestionsForSection(
      sectionId: string
    ): SnapshotOut<typeof FAQQuestionModel>[] {
      // @ts-ignore
      return (
        // @ts-ignore
        values(self.questions as SnapshotOut<typeof FAQQuestionModel>[])
          // @ts-ignore
          .filter((question) => question.section._id === sectionId)
          // @ts-ignore
          .sort((a, b) => sortStringsAlphabetically(a.question, b.question))
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.sections.clear()
      self.questions.clear()
      self.fetchingFAQs = 'none'
      self.updatingFAQ = 'none'
      self.creatingFAQ = 'none'
      self.deletingFAQ = 'none'
    },
    setFaqQuestions: (questions: IFAQModel[], sectionId: string) => {
      questions.forEach((question) => {
        // @ts-ignore
        setObject<typeof FAQQuestionModel>(self.questions, FAQQuestionModel, {
          ...question,
          section: sectionId,
        })
      })
    },
  }))
  .actions((self) => ({
    setFaqSections: (sections: FaqSectionModel[]) => {
      sections.forEach((section) => {
        if (section.questions) {
          self.setFaqQuestions(section.questions, section._id)
        }
        // @ts-ignore
        setObject<typeof FAQSectionModel>(self.sections, FAQSectionModel, {
          ...section,
          society: section.societyId,
          // questions: section.questions?.map((question) => question._id),
        })
      })
    },
  }))
  .actions((self) => ({
    getFaqsPerSociety: flow(function* getFaqsPerSociety(societyId: string) {
      self.fetchingFAQs = 'pending'
      try {
        const resp = yield apiGetFaqsPerSociety(societyId)
        const data = resp.data as NFaqs.NGetPerSocieties.IResponse
        const sections = data.data

        const processedSections = separateSectionsAndQuestions(sections)

        self.setFaqSections(processedSections)

        self.fetchingFAQs = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingFAQs = 'error'
      }
    }),
    patchFaqSection: flow(function* patchFaqSection(
      id: string,
      body: NFaqs.NPatch.IRequestBody
    ) {
      self.updatingFAQ = 'pending'
      try {
        const storeSectionQuestion = self.getQuestionsForSection(id)
        const storeSectionQuestionIds = storeSectionQuestion.map(
          (question) => question._id
        )
        const resp = yield apiPatchFaq(id, body)
        const data = resp.data as NFaqs.NPatch.IResponse
        const section = data.data

        if (section !== null) {
          self.setFaqSections([section])
        }

        // Update "section" for each question
        if (storeSectionQuestionIds && body.section) {
          const responses = yield Promise.all(
            storeSectionQuestionIds.map((_id) =>
              apiPatchFaq(_id, {
                section: body.section,
              })
            )
          )
          const respQuestions = responses.map(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (_response: any) => _response.data.data
          )
          self.setFaqQuestions(respQuestions, id)
        }

        self.updatingFAQ = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingFAQ = 'error'
        return false
      }
    }),
    patchFaqQuestion: flow(function* patchFaqQuestion(
      id: string,
      body: NFaqs.NPatch.IRequestBody,
      sectionId: string
    ) {
      self.updatingFAQ = 'pending'
      try {
        const resp = yield apiPatchFaq(id, body)
        const data = resp.data as NFaqs.NPatch.IResponse
        const question = data.data

        if (question !== null) {
          self.setFaqQuestions([question], sectionId)
        }
        self.updatingFAQ = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingFAQ = 'error'
        return false
      }
    }),
    createFaqSection: flow(function* createFaqSection(
      body: NFaqs.NCreate.IRequestBody
    ) {
      self.creatingFAQ = 'pending'
      try {
        const resp = yield apiCreateFaq(body)
        const data = resp.data as NFaqs.NCreate.IResponse
        const section = data.data

        if (section !== null) {
          self.setFaqSections([section])
        }

        self.creatingFAQ = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingFAQ = 'error'
        return false
      }
    }),
    createFaqQuestion: flow(function* createFaqQuestion(
      body: NFaqs.NCreate.IRequestBody,
      sectionId: string
    ) {
      self.creatingFAQ = 'pending'
      try {
        const resp = yield apiCreateFaq(body)
        const data = resp.data as NFaqs.NCreate.IResponse
        const question = data.data

        if (question !== null) {
          self.setFaqQuestions([question], sectionId)
        }

        self.creatingFAQ = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingFAQ = 'error'
        return false
      }
    }),
    deleteFaqSection: flow(function* deleteFaqSection(id: string) {
      try {
        yield apiDeleteFaq(id)

        // Questions need to be manually deleted
        const questions = self.getQuestionsForSection(id)
        if (questions.length > 0) {
          const questionIds = questions.map((question) => question._id)
          yield Promise.all(questionIds.map((_id) => apiDeleteFaq(_id)))

          questionIds.forEach((_id) => {
            self.questions.delete(_id)
          })
        }

        self.sections.delete(id)
        return true
      } catch (error) {
        captureException(error)
        return false
      }
    }),
    deleteFaqQuestion: flow(function* deleteFaqQuestion(id: string) {
      try {
        yield apiDeleteFaq(id)
        self.questions.delete(id)
        return true
      } catch (error) {
        captureException(error)
        return false
      }
    }),
  }))
