/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, SnapshotOut, getRoot } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import {
  IEventAttendanceStatus,
  IEventModel,
} from '../../interfaces/models/events.interfaces'
import {
  getEventsPerSociety as apiGetEventsPerSociety,
  createEvent as apiCreateEvent,
  updateEvent as apiUpdateEvent,
  getEvent as apiGetEvent,
  deleteEvent as apiDeleteEvent,
  eventAttendance as apiEventAttendance,
} from '../../api/events'
import { NEvents } from '../../interfaces/services/events.interfaces'
import { EventModel } from '../models/event'
import { stateType } from '../types/common'
import { setObject } from './helpers'
import { sortByDate } from '../../helpers/sorting'
import { RootStore } from './root'

export const EventStore = types
  .model('EventStore')
  .props({
    events: types.map(EventModel),
    fetchingEvents: stateType,
    creatingEvent: stateType,
    updatingEvent: stateType,
    updatingEventAttendance: stateType,
  })
  .views((self) => ({
    get sortedEvents(): SnapshotOut<typeof EventModel>[] {
      // @ts-ignore
      return (values(self.events) as SnapshotOut<typeof EventModel>[]).sort(
        (a, b) => sortByDate(a.startDate, b.startDate, true)
      )
    },
  }))
  .views((self) => ({
    eventsForSociety(societyId: string): SnapshotOut<typeof EventModel>[] {
      return self.sortedEvents.filter(
        (event: SnapshotOut<typeof EventModel>) => event.societyId === societyId
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.events.clear()
      self.fetchingEvents = 'none'
      self.creatingEvent = 'none'
      self.updatingEvent = 'none'
      self.updatingEventAttendance = 'none'
    },
    setEvents: (events: IEventModel[]) => {
      events.forEach((event) => {
        // @ts-ignore
        setObject(self.events, EventModel, {
          ...event,
          startDate: event.startDate as unknown as string,
          endDate: event.endDate as unknown as string,
          societyId: event.societyId,
        })
      })
    },
  }))
  .actions((self) => ({
    getEventsPerSociety: flow(function* getEventsPerSociety(societyId: string) {
      self.fetchingEvents = 'pending'
      try {
        const resp = yield apiGetEventsPerSociety(societyId)
        const data = resp.data as NEvents.NGetPerSocieties.IResponse
        const events = data.data

        self.setEvents(events)

        self.fetchingEvents = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingEvents = 'error'
      }
    }),
    createEvent: flow(function* createEvent(
      body: NEvents.NCreate.IRequestBody
    ) {
      self.creatingEvent = 'pending'
      try {
        const resp = yield apiCreateEvent(body)
        const data = resp.data as NEvents.NCreate.IResponse
        const event = data.data

        if (event !== null) {
          self.setEvents([event])
        }

        self.creatingEvent = 'done'
        return event
      } catch (error) {
        captureException(error)
        self.creatingEvent = 'error'
        return false
      }
    }),
    getEvent: flow(function* getEvent(id: string) {
      self.fetchingEvents = 'pending'
      try {
        const resp = yield apiGetEvent(id)
        const data = resp.data as NEvents.NGetById.IResponse
        const event = data.data

        self.setEvents([event])

        self.fetchingEvents = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingEvents = 'error'
      }
    }),
    patchEvent: flow(function* patchEvent(
      id: string,
      body: NEvents.NPatch.IRequestBody
    ) {
      self.updatingEvent = 'pending'
      try {
        const resp = yield apiUpdateEvent(id, body)
        const data = resp.data as NEvents.NPatch.IResponse
        const event = data.data

        if (event !== null) {
          self.setEvents([event])
        }

        self.updatingEvent = 'done'
        return event
      } catch (error) {
        captureException(error)
        self.updatingEvent = 'error'
        return false
      }
    }),
    deleteEvent: flow(function* deleteEvent(id: string) {
      try {
        yield apiDeleteEvent(id)

        const { postStore } = getRoot<RootStore>(self)
        const postsReferringEvent = postStore.sortedPosts
          .filter((_post) => _post.eventsIds.includes(id))
          .map((_post) => _post._id)
        postsReferringEvent.forEach((_id) => postStore.deletePost(_id))

        self.events.delete(id)
        return true
      } catch (error) {
        captureException(error)
        return false
      }
    }),
    updateEventAttendance: flow(function* updateEventAttendance(
      id: string,
      status: IEventAttendanceStatus
    ) {
      self.updatingEventAttendance = 'pending'
      try {
        const resp = yield apiEventAttendance(id, { status })
        const data = resp.data as NEvents.NAttendance.IResponse
        const event = data.data

        if (event !== null) {
          self.setEvents([event])
        }

        self.updatingEventAttendance = 'done'
        return event
      } catch (error) {
        self.updatingEventAttendance = 'done'
        captureException(error)
        return false
      }
    }),
  }))
