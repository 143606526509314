import { AxiosResponse } from 'axios'
import { NBoardRoomEvents } from '../interfaces/services/boardroom-events.interfaces'
import { createUrl } from './helpers'
import axiosAuth from './config'

export const getBoardRoomEventsPerSociety = (
  societyId: string
): Promise<AxiosResponse<NBoardRoomEvents.NGetPerSocieties.IResponse>> => {
  const body: NBoardRoomEvents.NGetPerSocieties.IRequestBody = {
    societyIds: [societyId],
  }
  return axiosAuth.post<NBoardRoomEvents.NGetPerSocieties.IResponse>(
    createUrl('/boardroom-events/get-per-societies'),
    body
  )
}

export const getBoardRoomEvent = (
  id: string
): Promise<AxiosResponse<NBoardRoomEvents.NGetById.IResponse>> => {
  return axiosAuth.get<NBoardRoomEvents.NGetById.IResponse>(
    createUrl(`/boardroom-events/event/${id}`)
  )
}

export const createBoardRoomEvent = (
  body: NBoardRoomEvents.NCreate.IRequestBody
): Promise<AxiosResponse<NBoardRoomEvents.NCreate.IResponse>> => {
  return axiosAuth.post<NBoardRoomEvents.NCreate.IResponse>(
    createUrl(`/boardroom-events/event`),
    body
  )
}

export const updateBoardRoomEvent = (
  id: string,
  body: NBoardRoomEvents.NPatch.IRequestBody
): Promise<AxiosResponse<NBoardRoomEvents.NPatch.IResponse>> => {
  return axiosAuth.patch<NBoardRoomEvents.NPatch.IResponse>(
    createUrl(`/boardroom-events/event/${id}`),
    body
  )
}

export const deleteBoardRoomEvent = (
  id: string
): Promise<AxiosResponse<NBoardRoomEvents.NDel.IResponse>> => {
  return axiosAuth.delete<NBoardRoomEvents.NDel.IResponse>(
    createUrl(`/boardroom-events/event/${id}`)
  )
}

export const boardRoomEventAttendance = (
  id: string,
  body: NBoardRoomEvents.NAttendance.IRequestBody
): Promise<AxiosResponse<NBoardRoomEvents.NAttendance.IResponse>> => {
  return axiosAuth.post<NBoardRoomEvents.NAttendance.IResponse>(
    createUrl(`/boardroom-events/event/${id}/attendance`),
    body
  )
}
