import * as yup from 'yup'
import { weeklySchedule } from './society_facility_weekly_schedule_create'

export const societyFacilityCreateSchema = yup
  .object({
    name: yup.string().required(),
    societyId: yup.string().required(),
    booking: yup.boolean().required(),
    notificationSettings: yup
      .string()
      .oneOf(['none', 'feed', 'notification', 'notification+feed'])
      .required(),
    rules: yup
      .string()
      .oneOf([
        'one-active',
        'two-active',
        'three-active',
        'four-active',
        'five-active',
        'unlimited',
      ]),
    visibility: yup.string().oneOf(['all', 'adminonly', 'board']),
    coverPhotoId: yup.string(),
    description: yup.string(),
    bookingGranularity: yup
      .string()
      .oneOf(['1hr', '2hr', '3hr', '4hr', '5hr', 'full-day']),

    bookingMessage: yup.string(),
    weeklySchedule,
    blockedDates: yup.array(yup.string()),
    notificationEmails: yup.array(yup.string()),
    userId: yup.string(),
    bookingAvailabilityStartDate: yup.string(),
    bookingAvailabilityEndDate: yup.string().nullable(),
    bookableUntilFurtherNotice: yup.boolean(),
  })
  .required()
