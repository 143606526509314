import { observer } from 'mobx-react-lite'
import React, { createContext, useContext, useMemo } from 'react'
import { tracking, TrackingEvent } from '../tracking'
import { useStores } from './useStores'

interface TrackingProviderProps {
  children: React.ReactNode
}

interface ITrackingContext {
  track: (event: TrackingEvent) => void
}

const TrackingContext = createContext<ITrackingContext>({
  track: () => null,
})

const TrackingProvider = observer(
  ({ children }: TrackingProviderProps): JSX.Element => {
    const { authenticationStore } = useStores()
    const { userId } = authenticationStore

    const isMobile = (): boolean => {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    }

    const value = useMemo(
      () => ({
        track: (event: TrackingEvent) =>
          tracking.track({
            event: event.event,
            properties: {
              time: new Date().getTime(),
              distinct_id: userId || 'no-auth',
              Platform: 'browser',
              Device: isMobile() ? 'mobile' : 'desktop',
              ...event.properties,
            },
          }),
      }),
      [userId]
    )

    return (
      <TrackingContext.Provider value={value}>
        {children}
      </TrackingContext.Provider>
    )
  }
)

TrackingProvider.displayName = 'TrackingProvider'
export { TrackingProvider }

export const useTracking = (): ITrackingContext => useContext(TrackingContext)
