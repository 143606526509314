import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Container } from '../../../components/common/Container'
import { SideBar } from '../../../components/common/SideBar'
import { SocietySideBarContent } from '../../../components/society/SocietySideBarContent'
import { useStores } from '../../../hooks/useStores'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { useSocietyLoader } from '../../../navigation/society/hooks/useSocietyLoader'

const SocietyRoot = observer((): JSX.Element => {
  const { societyStore } = useStores()
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  useSocietyLoader(id)

  useEffect(() => {
    societyStore.selectedSociety?._id &&
      location.pathname === `/society/${id}` &&
      navigate(
        reverseUrl('society:home', { id: societyStore.selectedSociety._id }),
        { replace: true }
      )
  }, [id, location.pathname, navigate, societyStore.selectedSociety])

  return (
    <>
      {societyStore.selectedSociety && (
        <>
          <SideBar
            className="gap-gutter flex"
            padding={false}
            headerClassName="px-6 pt-6"
          >
            <SocietySideBarContent />
          </SideBar>
          <div className="relative w-full min-w-0 overflow-y-auto">
            <Container>
              <Outlet />
            </Container>
          </div>
        </>
      )}
    </>
  )
})

SocietyRoot.displayName = 'SocietyRoot'
export { SocietyRoot }
