import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import React from 'react'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { SocietyModel } from '../../../../../state/models/society'
import { theme } from '../../../../../theme/theme'
import {
  Illustration,
  IllustrationChoices,
} from '../../../../common/Illustration'

interface CanNotDeleteAccountProps {
  societiesPreventingAccountDeletion: SnapshotOut<typeof SocietyModel>[]
}

export const CanNotDeleteAccount = observer(
  ({
    societiesPreventingAccountDeletion,
  }: CanNotDeleteAccountProps): JSX.Element => {
    const { translate } = useAppTranslation()

    return (
      <div className="flex flex-col gap-3">
        <div className="flex justify-center">
          <Illustration illustrationChoice={IllustrationChoices.CHECKLIST} />
        </div>
        <p style={theme.textVariants.h3}>
          {translate('deleteAccountModal.canNotDeleteTitle')}
        </p>
        <p style={theme.textVariants.base}>
          {translate('deleteAccountModal.canNotDeleteText')}
        </p>
        <div className="mt-1">
          {societiesPreventingAccountDeletion.map((_society) => (
            <p key={_society._id} style={theme.textVariants.baseBold}>
              {_society.name}
            </p>
          ))}
        </div>
      </div>
    )
  }
)
