/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, SnapshotOut, getRoot } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
// import * as Notifications from 'expo-notifications'
import { NNotifications } from '../../interfaces/services/notifications.interfaces'
import { INotificationModel } from '../../interfaces/models/notifications.interfaces'
import { NotificationModel } from '../models/notification'
import {
  list as apiList,
  setRead as apiSetRead,
  setSeen as apiSetSeen,
  setTypesSeen as apiSetTypesSeen,
} from '../../api/notifications'
import { stateType } from '../types/common'
import { setObject } from './helpers'
import { sortByDate } from '../../helpers/sorting'
import { RootStore } from './root'
import { LocalStorageKeys } from '../../types/local-storage'

export const NotificationsStore = types
  .model('NotificationsStore')
  .props({
    notifications: types.map(NotificationModel),
    fetchingNotifications: stateType,
    unseenCount: types.integer,
    unseenOneOnOneChatCount: types.integer,
    unseenInterestsChatCount: types.integer,
    unseenBoardChatCount: types.integer,
    unseenBoardQuestionsChatCount: types.integer,
    unseenResidentsQuestionsChatCount: types.integer,
    hasFetchedNotificationsOnce: types.boolean,
  })
  .views((self) => ({
    get sortedNotifications(): SnapshotOut<typeof NotificationModel>[] {
      return (
        // @ts-ignore
        (values(self.notifications) as SnapshotOut<typeof NotificationModel>[]) //
          .sort((a, b) => sortByDate(a.createdAt, b.createdAt, true))
      )
    },
    get unseenChatCount(): number {
      return [
        self.unseenOneOnOneChatCount,
        self.unseenInterestsChatCount,
        self.unseenBoardChatCount,
        self.unseenBoardQuestionsChatCount,
        self.unseenResidentsQuestionsChatCount,
      ].reduce((partialSum, a) => partialSum + a, 0)
    },
    get unseenResidentChatCount(): number {
      return [
        self.unseenOneOnOneChatCount,
        self.unseenInterestsChatCount,
        self.unseenResidentsQuestionsChatCount,
      ].reduce((partialSum, a) => partialSum + a, 0)
    },
    get unseenBoardCount(): number {
      return [
        self.unseenBoardChatCount,
        self.unseenBoardQuestionsChatCount,
      ].reduce((partialSum, a) => partialSum + a, 0)
    },
  }))
  .views((self) => ({
    get lastKnownDate() {
      const { sortedNotifications } = self
      const date =
        sortedNotifications[sortedNotifications.length - 1]?.createdAt
      return date ? new Date(date) : undefined
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.notifications.clear()
      self.fetchingNotifications = 'none'
      self.unseenCount = 0
      self.unseenOneOnOneChatCount = 0
      self.unseenInterestsChatCount = 0
      self.unseenBoardChatCount = 0
      self.unseenBoardQuestionsChatCount = 0
      self.unseenResidentsQuestionsChatCount = 0
      self.hasFetchedNotificationsOnce = false
    },
    setNotifications: (notifications?: INotificationModel[]) => {
      notifications?.forEach((notification) => {
        setObject<typeof NotificationModel>(
          // @ts-ignore
          self.notifications,
          NotificationModel,
          notification
        )
      })
    },
    setUnseenOneOnOneChatCount: (count: number) => {
      self.unseenOneOnOneChatCount = count
      localStorage.setItem(
        LocalStorageKeys.NOTIFICATIONS_ONE_ON_ONE_CHAT_UNSEEN_COUNT,
        count.toString()
      )
    },
    setUnseenInterestsChatCount: (count: number) => {
      self.unseenInterestsChatCount = count
      localStorage.setItem(
        LocalStorageKeys.NOTIFICATIONS_INTERESTS_CHAT_UNSEEN_COUNT,
        count.toString()
      )
    },
    setUnseenBoardChatCount: (count: number) => {
      self.unseenBoardChatCount = count
      localStorage.setItem(
        LocalStorageKeys.NOTIFICATIONS_BOARD_CHAT_UNSEEN_COUNT,
        count.toString()
      )
    },
    setUnseenBoardQuestionsChatCount: (count: number) => {
      self.unseenBoardQuestionsChatCount = count
      localStorage.setItem(
        LocalStorageKeys.NOTIFICATIONS_BOARD_QUESTIONS_CHAT_UNSEEN_COUNT,
        count.toString()
      )
    },
    setUnseenResidentsQuestionsChatCount: (count: number) => {
      self.unseenResidentsQuestionsChatCount = count
      localStorage.setItem(
        LocalStorageKeys.NOTIFICATIONS_RESIDENTS_QUESTIONS_CHAT_UNSEEN_COUNT,
        count.toString()
      )
    },
    deleteNotifications: (ids: string[]): void => {
      ids.forEach((_id) => self.notifications.delete(_id))
    },
    setHasFetchedNotificationsOnce: (val: boolean) => {
      self.hasFetchedNotificationsOnce = val
    },
  }))
  .actions((self) => ({
    loadOneOnOneUnseenCountFromLocalStorage: async () => {
      const countString = localStorage.getItem(
        LocalStorageKeys.NOTIFICATIONS_ONE_ON_ONE_CHAT_UNSEEN_COUNT
      )
      if (countString) {
        const count = parseInt(countString, 10)
        if (!Number.isNaN(count)) {
          self.setUnseenOneOnOneChatCount(count)
        }
      }
    },
    loadInterestsUnseenCountFromLocalStorage: async () => {
      const countString = localStorage.getItem(
        LocalStorageKeys.NOTIFICATIONS_INTERESTS_CHAT_UNSEEN_COUNT
      )
      if (countString) {
        const count = parseInt(countString, 10)
        if (!Number.isNaN(count)) {
          self.setUnseenInterestsChatCount(count)
        }
      }
    },
    loadBoardUnseenCountFromLocalStorage: async () => {
      const countString = localStorage.getItem(
        LocalStorageKeys.NOTIFICATIONS_BOARD_CHAT_UNSEEN_COUNT
      )
      if (countString) {
        const count = parseInt(countString, 10)
        if (!Number.isNaN(count)) {
          self.setUnseenBoardChatCount(count)
        }
      }
    },
    loadBoardQuestionsUnseenCountFromLocalStorage: async () => {
      const countString = localStorage.getItem(
        LocalStorageKeys.NOTIFICATIONS_BOARD_QUESTIONS_CHAT_UNSEEN_COUNT
      )
      if (countString) {
        const count = parseInt(countString, 10)
        if (!Number.isNaN(count)) {
          self.setUnseenBoardQuestionsChatCount(count)
        }
      }
    },
    loadResidentsQuestionsUnseenCountFromLocalStorage: async () => {
      const countString = localStorage.getItem(
        LocalStorageKeys.NOTIFICATIONS_RESIDENTS_QUESTIONS_CHAT_UNSEEN_COUNT
      )
      if (countString) {
        const count = parseInt(countString, 10)
        if (!Number.isNaN(count)) {
          self.setUnseenResidentsQuestionsChatCount(count)
        }
      }
    },
    updateUnseenCount: (unseenObject: { [key: string]: number }) => {
      try {
        const unseenObjectKeys = Object.keys(unseenObject)

        const notificationScreenFilterKeys = [
          'chat-oneonone-new-message',
          'chat-interests-new-message',
          'chat-board-new-message',
          'chat-questions-new-message-board',
          'chat-questions-new-message-residents',
          'hey-new-posts',
          'user-added-to-unit',
          'invite-all-admin-to-society',
        ]

        const notificationScreenUnseenCount = unseenObjectKeys
          .filter((key) => !notificationScreenFilterKeys.includes(key))
          .map((key) => unseenObject[key])
          .reduce((a, b) => a + b, 0)

        const oneOnOneCount = unseenObject['chat-oneonone-new-message']
          ? unseenObject['chat-oneonone-new-message']
          : 0
        self.setUnseenOneOnOneChatCount(oneOnOneCount)

        const interestsCount = unseenObject['chat-interests-new-message']
          ? unseenObject['chat-interests-new-message']
          : 0
        self.setUnseenInterestsChatCount(interestsCount)

        const boardCount = unseenObject['chat-board-new-message']
          ? unseenObject['chat-board-new-message']
          : 0
        self.setUnseenBoardChatCount(boardCount)

        const boardQuestionsCount = unseenObject[
          'chat-questions-new-message-board'
        ]
          ? unseenObject['chat-questions-new-message-board']
          : 0
        self.setUnseenBoardQuestionsChatCount(boardQuestionsCount)

        const residentQuestionsCount = unseenObject[
          'chat-questions-new-message-residents'
        ]
          ? unseenObject['chat-questions-new-message-residents']
          : 0
        self.setUnseenResidentsQuestionsChatCount(residentQuestionsCount)

        self.unseenCount = notificationScreenUnseenCount
      } catch (error) {
        captureException(error)
      }
    },
  }))
  .actions((self) => ({
    afterCreate() {
      // Initially load counts from storage
      self.loadOneOnOneUnseenCountFromLocalStorage()
      self.loadInterestsUnseenCountFromLocalStorage()
      self.loadBoardUnseenCountFromLocalStorage()
      self.loadBoardQuestionsUnseenCountFromLocalStorage()
      self.loadResidentsQuestionsUnseenCountFromLocalStorage()
    },
    getList: flow(function* getList(fetchNextPage = false) {
      self.fetchingNotifications = 'pending'
      try {
        const query = fetchNextPage ? { lastKnownDate: self.lastKnownDate } : {}
        const resp = yield apiList(query)
        const data = resp.data as NNotifications.NList.IResponse
        const notifications = data.data
        const users = data?.populated?.users
        const media = data?.populated?.media

        const { userStore, mediaStore } = getRoot<RootStore>(self)
        if (users) {
          userStore.setUsers(users)
        }
        if (media) {
          // @ts-ignore
          mediaStore.setMedia(media)
        }

        self.setNotifications(notifications)
        self.setHasFetchedNotificationsOnce(true)

        self.fetchingNotifications = 'done'
        return notifications
      } catch (error) {
        captureException(error)
        self.fetchingNotifications = 'error'
        return []
      }
    }),
    setNotificationRead: flow(function* setNotificationRead(id: string) {
      const notification = self.notifications.get(id)
      notification?.setRead()
      try {
        yield apiSetRead({ notificationIds: [id] })
      } catch (error) {
        captureException(error)
        notification?.setUnread()
      }
    }),
    setNotificationsSeen: flow(function* setNotificationsSeen(
      notificationIds: string[]
    ) {
      try {
        yield apiSetSeen({ notificationIds })
        notificationIds.forEach((id) => {
          const notification = self.notifications.get(id)
          notification?.setSeen()
        })
      } catch (error) {
        captureException(error)
      }
    }),
    setNotificationTypesSeen: flow(function* setNotificationTypesSeen(
      notificationTypes: string[]
    ) {
      try {
        yield apiSetTypesSeen({ notificationTypes })
      } catch (error) {
        captureException(error)
      }
    }),
  }))
