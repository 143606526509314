import React, { Component, ErrorInfo, ReactNode } from 'react'
import { captureException } from '@sentry/react'
import { v4 as uuid } from 'uuid'
import { BUG_REPORT_FORM_LINK } from '../../../constants/Links'
import { openUrl } from '../../../helpers/url'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { ButtonVariant } from '../Button'
import { ErrorView } from '../../../views/error/ErrorView'
import { IllustrationChoices } from '../Illustration'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

export const FallbackErrorView = (): JSX.Element => {
  const { translate } = useAppTranslation()

  const openBugReportFormLink = (): void => {
    openUrl(BUG_REPORT_FORM_LINK, false)
  }

  return (
    <ErrorView
      title={translate('errorBoundary.errorFallbackTitle')}
      subtitle={translate('errorBoundary.errorFallbackSubtitle')}
      illustration={IllustrationChoices.BUG}
      buttons={[
        {
          id: uuid(),
          button: {
            label: translate('errorBoundary.reportBugButtonTitle'),
            onClick: openBugReportFormLink,
            variant: ButtonVariant.PRIMARY,
          },
        },
      ]}
    />
  )
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    captureException(error, {
      extra: {
        object: Object.keys({ ...errorInfo })
          .map((key) => ({
            [key]: JSON.stringify(errorInfo[key as keyof ErrorInfo]),
          }))
          .reduce((result, current) => {
            return Object.assign(result, current)
          }, {}),
      },
    })
  }

  public render(): ReactNode {
    const { hasError } = this.state
    if (hasError) {
      return <FallbackErrorView />
    }

    const { children } = this.props
    if (children) {
      return children
    }

    return <></>
  }
}

export default ErrorBoundary
