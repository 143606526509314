import React from 'react'
import { IconChoices } from '../Icon'
import { BreadcrumbItem } from './BreadcrumbItem'

export interface BreadcrumbItemData {
  _id: string
  title: string
  url: string
}

interface BreadcrumbProps {
  items: BreadcrumbItemData[]
  rootIcon: IconChoices
  rootIconSize?: number
  rootLabel?: string
}

export const Breadcrumb = ({
  items,
  rootIcon,
  rootIconSize = 24,
  rootLabel,
}: BreadcrumbProps): JSX.Element => {
  return (
    <div className="flex flex-wrap gap-2">
      {items.map((item, index) => {
        return (
          <div className="flex space-x-2" key={item._id}>
            <BreadcrumbItem
              index={index}
              item={item}
              totalItems={items.length - 1}
              rootIcon={rootIcon}
              rootIconSize={rootIconSize}
              rootLabel={rootLabel}
            />
          </div>
        )
      })}
    </div>
  )
}
