import React, { useState } from 'react'
import { theme } from '../../../theme/theme'

import { openUrl } from '../../../helpers/url'
import { Spinner } from '../../common/Spinner'
import { Icon, IconChoices } from '../../common/Icon'
import { Card } from '../../common/Card'

export type ExportCard = {
  title: string
  getExportLink: () => Promise<string>
}

interface ExportCardProps extends ExportCard {
  className?: string
}

export const ExportCard = ({
  title,
  getExportLink,
  className,
}: ExportCardProps): JSX.Element => {
  const [fetchingExport, setFetchingExport] = useState(false)

  const onClick = async (): Promise<void> => {
    setFetchingExport(true)
    const url = await getExportLink()
    openUrl(url)
    setFetchingExport(false)
  }
  return (
    <Card
      className={`${
        className ?? ''
      } max-w-none hover:bg-neutral-96 md:max-w-lg`}
    >
      {{
        body: (
          <button
            className="flex flex-1 flex-row items-center justify-between p-4 text-left"
            onClick={onClick}
          >
            <p style={theme.textVariants.baseBold} className="line-clamp-1">
              {title}
            </p>
            {fetchingExport ? (
              <Spinner />
            ) : (
              // Add my-1 so Spinner and icon have same size on click
              <Icon className="my-1" icon={IconChoices.DOWNLOAD} />
            )}
          </button>
        ),
      }}
    </Card>
  )
}
