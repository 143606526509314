import React, { KeyboardEvent } from 'react'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../Icon'

export interface InformationRowOptions {
  title: string
  titleStyle?: React.CSSProperties
  icon?: IconChoices
  iconColor?: string
  text?: string
  onClick?: () => void
  hideElementRight?: boolean
}

interface InformationTableProps {
  rows: InformationRowOptions[]
  className?: string
}

interface InformationRowProps extends InformationRowOptions {
  index: number
  className?: string
}

const InformationRow = ({
  title,
  titleStyle,
  icon,
  iconColor,
  text,
  onClick,
  hideElementRight = false,
  index,
  className,
}: InformationRowProps): JSX.Element => {
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter' && onClick) {
      onClick()
    }
  }

  const additionalProps = {
    ...(onClick && { onClick }),
    ...(onClick && { role: 'button' }),
    ...(onClick && { tabIndex: index }),
    ...(onClick && { onKeyDown: handleKeyDown }),
  }

  return (
    <div
      className={`flex w-full flex-col justify-between rounded py-3
        ${className ?? ''}
        ${onClick ? 'hover:bg-gray-light' : ''}
      `}
      {...additionalProps}
    >
      <div className="flex gap-2">
        {icon && <Icon color={iconColor} icon={icon} />}
        <p style={titleStyle || theme.textVariants.baseBold}>{title}</p>
      </div>
      {!hideElementRight && <p className="text-neutral-40">{text}</p>}
    </div>
  )
}

export const InformationTable = ({
  rows,
  className,
}: InformationTableProps): JSX.Element => {
  return (
    <>
      {rows.map((row, index) => (
        <InformationRow
          key={row.title}
          index={index}
          title={row.title}
          titleStyle={row.titleStyle}
          icon={row.icon}
          iconColor={row.iconColor}
          text={row.text}
          onClick={row.onClick}
          hideElementRight={row.hideElementRight}
          className={className}
        />
      ))}
    </>
  )
}
