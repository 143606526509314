import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { theme } from '../../../theme/theme'
import { Toggle } from '../../common/Toggle'

export interface NotificationSettingsSwitch {
  value: { mobile?: boolean | undefined; email?: boolean | undefined }
  onChange: (value: {
    mobile?: boolean | undefined
    email?: boolean | undefined
  }) => void
  name: string
  disableMobile?: boolean
}

export const NotificationSettingsSwitch = observer(
  ({
    value,
    onChange,
    name,
    disableMobile,
  }: NotificationSettingsSwitch): JSX.Element => {
    const { translate } = useAppTranslation()
    const { userStore } = useStores()

    const _onChange = (_value: boolean, delivery: 'mobile' | 'email'): void => {
      const prev = value || { mobile: undefined, email: undefined }
      const updated =
        delivery === 'mobile' ? { mobile: _value } : { email: _value }
      onChange({ ...prev, ...updated })
    }

    const updatingUser = userStore.updatingUser === 'pending'

    return (
      <div className="flex gap-x-10">
        <div className="flex items-center gap-x-3">
          <p style={theme.textVariants.base}>
            {translate('notificationSettings.options.mobile')}
          </p>
          <Toggle
            name={`${name}_mobile`}
            enabled={value && value.mobile !== undefined ? value.mobile : false}
            onChange={(_value: boolean) => _onChange(_value, 'mobile')}
            disabled={disableMobile}
            loading={updatingUser}
          />
        </div>
        <div className="flex items-center gap-x-3">
          <p style={theme.textVariants.base}>
            {translate('notificationSettings.options.email')}
          </p>
          <Toggle
            name={`${name}_email`}
            enabled={value && value.email !== undefined ? value.email : false}
            onChange={(_value: boolean) => _onChange(_value, 'email')}
            loading={updatingUser}
          />
        </div>
      </div>
    )
  }
)
