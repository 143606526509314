import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../../hooks/useStores'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
// eslint-disable-next-line max-len
import { FunctionSettingsCustomWidget } from '../../../../components/society/FunctionSettings/FunctionSettingsCustomWidget'

export const FunctionSettingsCustomWidgets = observer((): JSX.Element => {
  const { societyWidgetsStore } = useStores()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }

  const widgets = society
    ? societyWidgetsStore.widgetsForSociety(society._id)
    : []

  return (
    <div className="mt-2 space-y-2">
      {widgets.map((widget) => (
        <FunctionSettingsCustomWidget key={widget._id} widget={widget} />
      ))}
    </div>
  )
})
