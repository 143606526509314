import { useStores } from '../../../hooks/useStores'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { ActiveTabBar } from './ChatSideBarContent'

interface GetTabsReturnType {
  getTabs: () => { title: string; displayNotificationBadge: boolean }[]
}

export const useTabBarOptions = (
  activeTabBar: ActiveTabBar,
  chatDisabled: boolean
): GetTabsReturnType => {
  const { notificationsStore } = useStores()
  const { translate } = useAppTranslation()

  const getTabs = (): {
    title: string
    displayNotificationBadge: boolean
  }[] => {
    if (activeTabBar === ActiveTabBar.RESIDENT) {
      return [
        ...(!chatDisabled
          ? [
              {
                title: translate(
                  'chatRoomsSidebar.tabs.oneOnOneAndInterestsTitle'
                ),
                displayNotificationBadge:
                  notificationsStore.unseenOneOnOneChatCount > 0 ||
                  notificationsStore.unseenInterestsChatCount > 0,
              },
            ]
          : []),
        {
          title: translate('chatRoomsSidebar.tabs.questionsTitle'),
          displayNotificationBadge:
            notificationsStore.unseenResidentsQuestionsChatCount > 0,
        },
      ]
    }
    return [
      {
        title: translate('chatRoomsSidebar.tabs.boardTitle'),
        displayNotificationBadge: notificationsStore.unseenBoardChatCount > 0,
      },
      {
        title: translate('chatRoomsSidebar.tabs.answerResidentsTitle'),
        displayNotificationBadge:
          notificationsStore.unseenBoardQuestionsChatCount > 0,
      },
    ]
  }

  return { getTabs }
}
