import React, { ReactNode, useState } from 'react'
import { usePopperBase } from '../../../hooks/usePopperBase'
import { theme } from '../../../theme/theme'

interface TooltipProps {
  children: {
    wrappedElement: ReactNode
    tooltipContent: ReactNode
  }
  referenceElement: Element | null
  className?: string
}

const Tooltip = ({
  children,
  referenceElement,
  className,
}: TooltipProps): JSX.Element => {
  const [show, setShow] = useState<boolean>(false)
  const { styles, attributes, setArrowElement, setPopperElement } =
    usePopperBase(referenceElement)
  const { wrappedElement, tooltipContent } = children

  const onHoverStart = (): void => {
    setShow(true)
  }

  const onHoverEnd = (): void => {
    setShow(false)
  }

  return (
    <>
      <div
        className={className}
        onMouseEnter={onHoverStart}
        onMouseLeave={onHoverEnd}
      >
        {wrappedElement}
      </div>
      {show && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="z-20 rounded border"
        >
          {tooltipContent}
          <div ref={setArrowElement} style={styles.arrow} />
        </div>
      )}
    </>
  )
}

interface TextTooltipProps {
  children: ReactNode
  referenceElement: Element | null
  title?: string
  text?: string
  className?: string
  useFlexibleWidth?: boolean
}

export const TextTooltip = ({
  children,
  title,
  text,
  referenceElement,
  className,
  useFlexibleWidth,
}: TextTooltipProps): JSX.Element => {
  return (
    <Tooltip className={className} referenceElement={referenceElement}>
      {{
        tooltipContent: (
          <div
            className={`flex ${
              useFlexibleWidth ? '' : 'w-44'
            } flex-col space-y-1 rounded bg-black p-4 text-white`}
          >
            {title && <p style={theme.textVariants.captionBold}>{title}</p>}
            {text && (
              <p
                className="whitespace-pre-line"
                style={theme.textVariants.caption}
              >
                {text}
              </p>
            )}
          </div>
        ),
        wrappedElement: children,
      }}
    </Tooltip>
  )
}
