/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, getRoot, SnapshotOut } from 'mobx-state-tree'
import { captureException } from '@sentry/react'
import { NUsers } from '../../interfaces/services/users.interfaces'
import { IUserModel } from '../../interfaces/models/users.interfaces'
import { IMediaModel } from '../../interfaces/models/media.interfaces'
import { UserModel } from '../models/user'
import { stateType } from '../types/common'
import {
  getUser as apiGetUser,
  patchUser as apiPatchUser,
  updateEmail as apiUpdateEmail,
  updatePassword as apiUpdatePassword,
  isEmailRegistered as apiIsEmailRegistered,
  sendResetPassword as apiSendResetPassword,
  resetPassword as apiResetPassword,
  deleteUser as apiDeleteUser,
  resendVerifyCode as apiResendVerifyCode,
  verifyUser as apiVerifyUser,
} from '../../api/users'
import { RootStore } from './root'
import { setObject } from './helpers'

export const UserStore = types
  .model('UserStore')
  .props({
    users: types.map(UserModel),
    fetchingUser: stateType,
    updatingUser: stateType,
    deletingUser: stateType,
    updatingEmail: stateType,
    updatingPassword: stateType,
    checkingEmailRegistered: stateType,
    sendingPasswordReset: stateType,
    resettingPassword: stateType,
    resendingVerificationCode: stateType,
    updatingProfilePicture: stateType,
    verifyingUser: stateType,
    hasFetchedUserOnce: types.boolean,
  })
  .actions((self) => ({
    reset: () => {
      self.users.clear()
      self.fetchingUser = 'none'
      self.updatingUser = 'none'
      self.deletingUser = 'none'
      self.updatingEmail = 'none'
      self.checkingEmailRegistered = 'none'
      self.sendingPasswordReset = 'none'
      self.resettingPassword = 'none'
      self.resendingVerificationCode = 'none'
      self.updatingProfilePicture = 'none'
      self.hasFetchedUserOnce = false
    },
    setUsers: (users: IUserModel[]) => {
      users.forEach((user) => {
        // @ts-ignore
        setObject<typeof UserModel>(self.users, UserModel, {
          ...user,
          fullName: [user.name, user.surname].join(' '),
          avatarId: user.avatarId,
        })
      })
    },
    currentUser: (): SnapshotOut<typeof UserModel> | null => {
      // @ts-ignore
      const { authenticationStore } = getRoot<RootStore>(self)
      // @ts-ignore
      return self.users.get(authenticationStore.userId as string)
    },
    setHasFetchedUserOnce: (val: boolean) => {
      self.hasFetchedUserOnce = val
    },
  }))
  .actions((self) => ({
    getUser: flow(function* getUser(id: string) {
      self.fetchingUser = 'pending'
      try {
        const resp = yield apiGetUser(id)
        const data = resp.data as NUsers.NGetById.IResponse
        const user = data.data
        const media = data.populated.media as IMediaModel[]

        const { mediaStore } = getRoot<RootStore>(self)
        mediaStore.setMedia(media)

        self.setUsers([user])

        self.fetchingUser = 'done'
        self.setHasFetchedUserOnce(true)
        return resp.status
      } catch (error) {
        captureException(error)
        self.fetchingUser = 'error'
        return error?.response?.status || 500
      }
    }),
    updateProfilePicture: flow(function* updateProfilePicture(
      mediaType: 'image' | 'video',
      base64: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Generator<any> {
      self.updatingProfilePicture = 'pending'
      const { authenticationStore, mediaStore } = getRoot<RootStore>(self)
      try {
        const mediaId = yield mediaStore.createMedia(mediaType, base64)
        if (mediaId === undefined) {
          captureException(new Error("Couldn't create media"))
          return false
        }
        const resp = yield apiPatchUser(authenticationStore.userId as string, {
          avatarId: mediaId as string,
        })
        // @ts-ignore
        const data = resp.data as NUsers.NGetById.IResponse
        const user = data.data
        self.setUsers([user])
        self.updatingProfilePicture = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingProfilePicture = 'error'
        return false
      }
    }),
    removeProfilePicture: flow(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function* removeProfilePicture(): Generator<any> {
        self.updatingProfilePicture = 'pending'
        const { authenticationStore } = getRoot<RootStore>(self)
        try {
          const resp = yield apiPatchUser(
            authenticationStore.userId as string,
            {
              // @ts-ignore
              avatarId: null,
            }
          )
          // @ts-ignore
          const data = resp.data as NUsers.NGetById.IResponse
          const user = data.data
          self.setUsers([user])
          self.updatingProfilePicture = 'done'
          return true
        } catch (error) {
          captureException(error)
          self.updatingProfilePicture = 'error'
          return false
        }
      }
    ),
    patchUser: flow(function* patchUser(
      id: string,
      body: NUsers.NPatch.IRequestBody,
      parameters?: {
        [key: string]: string
      }
    ) {
      self.updatingUser = 'pending'
      try {
        const resp = yield apiPatchUser(id, body, parameters)
        const data = resp.data as NUsers.NPatch.IResponse
        const user = data.data

        if (user !== null) {
          self.setUsers([user])
        }

        self.updatingUser = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingUser = 'error'
        return false
      }
    }),
    deleteUser: flow(function* deleteUser(
      id: string,
      body: NUsers.NDelete.IRequestBody
    ) {
      self.deletingUser = 'pending'
      try {
        yield apiDeleteUser(id, body)

        self.deletingUser = 'done'
        return true
      } catch (error) {
        // Do not capture error since it's invalid password provided by user
        self.deletingUser = 'error'
        return false
      }
    }),
    updateEmail: flow(function* updateEmail(
      userId: string,
      email: string,
      password: string
    ) {
      self.updatingEmail = 'pending'
      try {
        const user = self.users.get(userId)
        yield apiUpdateEmail(email, password)
        user?.updateEmail(email)

        self.updatingEmail = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingEmail = 'error'
        return false
      }
    }),
    updatePassword: flow(function* updateEmail(
      newPassword: string,
      oldPassword: string
    ) {
      self.updatingPassword = 'pending'
      try {
        const resp = yield apiUpdatePassword(newPassword, oldPassword)
        const data = resp.data as NUsers.NUpdatePassword.IResponse
        const success = data.data

        if (success) {
          self.updatingPassword = 'done'
        } else {
          self.updatingPassword = 'error'
        }

        return success
      } catch (error) {
        captureException(error)
        self.updatingPassword = 'error'
        return false
      }
    }),
    isEmailRegistered: flow(function* isEmailRegistered(email: string) {
      self.checkingEmailRegistered = 'pending'
      try {
        const resp = yield apiIsEmailRegistered(email)
        const data = resp.data as NUsers.NIsEmailRegistered.IResponse

        const emailIsRegistered = data.data

        self.checkingEmailRegistered = 'done'

        return emailIsRegistered
      } catch (error) {
        captureException(error)
        self.checkingEmailRegistered = 'error'
        return false
      }
    }),
    sendResetPassword: flow(function* sendResetPassword(email: string) {
      self.sendingPasswordReset = 'pending'
      try {
        const resp = yield apiSendResetPassword(email)
        const data = resp.data as NUsers.NSendResetPassword.IResponse
        const sendResetPasswordSuccess = data.data

        self.sendingPasswordReset = 'done'
        return sendResetPasswordSuccess
      } catch (error) {
        captureException(error)
        self.sendingPasswordReset = 'error'
        return false
      }
    }),
    resetPassword: flow(function* resetPassword(
      token: string,
      password: string
    ) {
      self.resettingPassword = 'pending'
      try {
        const resp = yield apiResetPassword(token, password)
        const data = resp.data as NUsers.NResetPassword.IResponse
        const resetPasswordSuccess = data.data

        self.resettingPassword = 'done'
        return resetPasswordSuccess
      } catch (error) {
        captureException(error)
        self.resettingPassword = 'error'
        return false
      }
    }),
    resendVerificationCode: flow(function* resendVerificationCode(
      email: string
    ) {
      self.resendingVerificationCode = 'pending'
      try {
        yield apiResendVerifyCode(email)
        self.resendingVerificationCode = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.resendingVerificationCode = 'error'
        return false
      }
    }),
    verifyUser: flow(function* verifyUser(code: string) {
      self.verifyingUser = 'pending'
      try {
        const resp = yield apiVerifyUser({
          code,
        })
        const data = resp.data as NUsers.NVerifyUser.IResponse
        const { token, user } = data.data

        self.verifyingUser = 'done'
        return { token, userId: user._id }
      } catch (error) {
        captureException(error)
        self.verifyingUser = 'error'
        return false
      }
    }),
  }))
