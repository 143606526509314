import React, { KeyboardEvent, ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface CardProps {
  children: {
    header?: ReactNode
    body?: ReactNode
    footer?: ReactNode
  }
  minWidth?: number
  className?: string
  backgroundColorClassName?: string
  onClick?: () => void
  url?: string
  openInNewTab?: boolean
}

export const Card = React.forwardRef<HTMLDivElement, CardProps>(function Card(
  {
    children,
    className,
    backgroundColorClassName = 'bg-white',
    minWidth = 200,
    onClick,
    url,
    openInNewTab,
  }: CardProps,
  ref
): JSX.Element {
  const { header, body, footer } = children

  const _className = `block flex flex-col justify-between rounded-lg shrink-0 
     border border-neutral-80 text-center shadow ${backgroundColorClassName} ${
       className ?? ''
     } ${
       onClick || url
         ? 'focus:outline-sbab-blue/50 pointer-events-auto cursor-pointer hover:bg-neutral-96'
         : ''
     }`

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter' && onClick) {
      onClick()
    }
  }

  if (url && openInNewTab) {
    return (
      <a
        className={_className}
        style={{ minWidth }}
        href={url}
        target="_blank"
        rel="noreferrer noopener"
      >
        {header}
        {body}
        {footer}
      </a>
    )
  }

  if (url) {
    return (
      <Link className={_className} style={{ minWidth }} to={url}>
        {header}
        {body}
        {footer}
      </Link>
    )
  }

  return (
    <div
      className={`${_className} ${
        onClick ? 'cursor-pointer' : 'cursor-default'
      }`}
      style={{ minWidth }}
      role="button"
      tabIndex={onClick ? 0 : -1}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      ref={ref}
    >
      {header}
      {body}
      {footer}
    </div>
  )
})
