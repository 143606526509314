import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useNavigate, Link } from 'react-router-dom'
import { theme } from '../../../../theme/theme'
import { useStores } from '../../../../hooks/useStores'
import { Divider } from '../../../common/Divider'
import { UserAvatar } from '../../../common/Avatar'
import { UserModel } from '../../../../state/models/user'
import { SocietyModel } from '../../../../state/models/society'
import { capitalize } from '../../../../helpers/string'
import {
  formatDateWithFormat,
  ignoreTimezoneDate,
} from '../../../../helpers/date'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useIsMobile } from '../../../../hooks/useIsMobile'
import { Button, ButtonVariant } from '../../../common/Button'
import { FacilityBookingListItem } from './FacilityBookingListItem'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { useUserUnitsString } from '../../../../hooks/useUserUnitsString'

interface FacilityBookingListItemInner extends FacilityBookingListItem {
  openDeleteBookingModal: () => void
  deleteBookingAllowed: boolean
}

export const FacilityBookingListItemInner = observer(
  ({
    openDeleteBookingModal,
    deleteBookingAllowed,
    booking,
    facility,
  }: FacilityBookingListItemInner): JSX.Element | null => {
    const { userStore, societyStore } = useStores()
    const { translate } = useAppTranslation()
    const isMobile = useIsMobile()
    const navigate = useNavigate()

    const user = userStore.users.get(booking.userId) as SnapshotOut<
      typeof UserModel
    >

    const society = societyStore.societies.get(booking.societyId) as
      | SnapshotOut<typeof SocietyModel>
      | undefined

    const userUnitsString = useUserUnitsString(user, society)

    const getUserRoute = (): string => {
      return reverseUrl('user:society-detail', {
        id: user._id,
        societyId: society?._id,
      })
    }

    const userOnClick = (): void => {
      return navigate(getUserRoute())
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const timeSpanString =
      facility?.bookingGranularity === 'full-day'
        ? translate('facilityView.calendar.fullDay')
        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          booking.getTimeSpanString()

    return (
      <>
        <div className="flex items-center justify-between py-5">
          <div className="flex items-center">
            <UserAvatar user={user} onClick={userOnClick} />
            <div className="ml-2 pr-2">
              <Link
                className="hover:underline"
                to={getUserRoute()}
                style={theme.textVariants.baseBold}
                translate="no"
              >
                {user.fullName}
              </Link>
              {userUnitsString && (
                <div className="mt-[2px] flex items-center">
                  <p
                    className="text-gray-600 line-clamp-1"
                    style={
                      isMobile
                        ? theme.textVariants.caption
                        : theme.textVariants.base
                    }
                  >
                    {userUnitsString}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-end">
            {!isMobile && deleteBookingAllowed && (
              <Button
                label={translate('facilityView.bookings.buttons.deleteBooking')}
                variant={ButtonVariant.DANGER}
                className="mr-6"
                onClick={openDeleteBookingModal}
              />
            )}
            <div className="flex flex-col text-right">
              <p
                style={
                  isMobile
                    ? theme.textVariants.caption
                    : theme.textVariants.base
                }
              >
                {capitalize(
                  formatDateWithFormat(
                    ignoreTimezoneDate(new Date(booking.startDate)),
                    isMobile ? 'd MMM YYY' : 'd MMMM YYY'
                  )
                )}
              </p>
              <p
                style={
                  isMobile
                    ? theme.textVariants.caption
                    : theme.textVariants.base
                }
                className="text-neutral-40"
              >
                {timeSpanString}
              </p>
            </div>
          </div>
        </div>
        <Divider />
      </>
    )
  }
)
