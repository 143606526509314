import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../../hooks/useStores'
import { PreApprovedModel } from '../../../../state/models/society'
import { UserModel } from '../../../../state/models/user'

export const useHouseholdMembersPreApproved = (
  societyId: string | undefined
): {
  householdUsers: SnapshotOut<typeof UserModel>[] | undefined
  preApprovedUsers: SnapshotOut<typeof PreApprovedModel>[] | undefined
} => {
  const { unitStore, authenticationStore, userStore, societyStore } =
    useStores()

  const society = societyId ? societyStore.societies.get(societyId) : undefined

  if (!society) {
    return { householdUsers: undefined, preApprovedUsers: undefined }
  }

  const userId = authenticationStore.userId as string
  const currentUser = userStore.users.get(userId)

  const units = unitStore.userUnits(userId, undefined)

  const unit = units.find((_unit) => _unit.societyId === societyId)
  const otherUsersInUnit = unit?.residentsList
    .map((_resident) => userStore.users.get(_resident.userId))
    .filter((_user) => _user?._id !== userId) as
    | SnapshotOut<typeof UserModel>[]
    | undefined

  const preApprovedUsers =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (society && unit ? society.preApprovedForUnit(unit._id) : []) as
      | SnapshotOut<typeof PreApprovedModel>[]
      | undefined

  const householdUsers = [currentUser, ...(otherUsersInUnit || [])].filter(
    (_user) => _user !== undefined
  ) as SnapshotOut<typeof UserModel>[]

  return { householdUsers, preApprovedUsers }
}
