import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { theme } from '../../../../theme/theme'
import { FindFriends } from '../../../../components/society/FindFriends'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ViewBase } from '../../../../components/common/ViewBase'
import { useStores } from '../../../../hooks/useStores'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { UserModel } from '../../../../state/models/user'
import { getMatches } from '../../../../components/society/FindFriends/helpers'
import { ErrorView } from '../../../error/ErrorView'
import { IllustrationChoices } from '../../../../components/common/Illustration'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { LinkButton } from '../../../../components/common/LinkButton'

export const FindFriendsView = observer((): JSX.Element => {
  const { unitStore, authenticationStore, userStore } = useStores()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const { translate } = useAppTranslation()
  useDocumentTitle(
    reverseDocumentTitle('society:find-friends', {
      '{{ societyName }}': society.name,
    })
  )

  const user = userStore.users.get(authenticationStore.userId as string)
  const residentUsers = unitStore.residentUsersForSociety(society._id)

  const categoryMatches = getMatches(
    user as SnapshotOut<typeof UserModel>,
    residentUsers
  )

  const loading = unitStore.fetchingUnits === 'pending'

  const errorView: ErrorView | undefined =
    !loading && categoryMatches.length === 0
      ? {
          title: translate('findFriendsView.errorView.noMatches.title'),
          subtitle: translate('findFriendsView.errorView.noMatches.subtitle'),
          illustration: IllustrationChoices.CONVERSATION,
          titleWrapperClassName: 'mt-4',
        }
      : undefined

  return (
    <ErrorBoundary>
      <ViewBase title={translate('findFriendsView.title')} loading={loading}>
        <p style={theme.textVariants.base} className="mb-4">
          {translate('findFriendsView.description')}
        </p>
        <p style={theme.textVariants.base} className="mb-8">
          {translate('findFriendsView.extra')}
        </p>
        <LinkButton
          urlIsExternal={false}
          url={reverseUrl('settings-my-info')}
          label={translate('findFriendsView.updateYourProfile')}
        />
        {errorView ? (
          <ErrorView {...errorView} />
        ) : (
          <FindFriends categoryMatches={categoryMatches} />
        )}
      </ViewBase>
    </ErrorBoundary>
  )
})
