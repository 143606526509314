import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../../common/Button'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { TextInput } from '../../../../common/TextInput'
import { useFormErrorMessage } from '../../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../../common/FormControl'
import { DocumentModel } from '../../../../../state/models/document'
import { societyDocumentUpdateSchema } from '../../../../../forms/schemas/society_document_update'

interface UpdateDocumentFormProps {
  onError: (error: unknown) => void
  onSubmit: (
    data: InferType<typeof societyDocumentUpdateSchema>
  ) => Promise<void>
  onClose: () => void
  loading: boolean
  document: SnapshotOut<typeof DocumentModel>
}

export const UpdateDocumentForm = observer(
  ({
    document,
    loading,
    onError,
    onSubmit,
    onClose,
  }: UpdateDocumentFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { getErrorMessage } = useFormErrorMessage()

    const getDefaultValues = useCallback((): InferType<
      typeof societyDocumentUpdateSchema
    > => {
      return {
        name: document.name || '',
      }
    }, [document])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyDocumentUpdateSchema),
      defaultValues: getDefaultValues(),
    })

    return (
      <>
        <Controller
          control={control}
          name="name"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateDocumentForm.labels.name')}
              error={errors.name && getErrorMessage(errors.name)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.name}
              />
            </FormControl>
          )}
        />
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid || loading}
            label={translate('common.actions.save')}
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </>
    )
  }
)
