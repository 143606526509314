import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { Button, ButtonVariant } from '../../../../components/common/Button'
import { TextInput } from '../../../../components/common/TextInput'
import { signUpSchema } from '../../../../forms/schemas/sign_up'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { useStores } from '../../../../hooks/useStores'
import { theme } from '../../../../theme/theme'
import { Checkbox } from '../../../../components/common/Checkbox'
import { isEmailRegistered } from '../../../../api/users'
import i18n from '../../../../i18n/i18n'
import { TermsOfServiceText } from '../TermsOfServiceText'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../../components/common/Toast/toast-type'
import { FormControl } from '../../../../components/common/FormControl'
import { LocalStorageKeys } from '../../../../types/local-storage'
import { NUsers } from '../../../../interfaces/services/users.interfaces'
import { UserModel } from '../../../../state/models/user'
import { getLanguageCode } from '../../../../helpers/translations/helpers'

export const SignUpForm = observer((): JSX.Element => {
  const { authenticationStore, userStore, signUpStore } = useStores()
  const [loadingIsEmailRegistered, setLoadingIsEmailRegistered] =
    useState(false)
  const { translate } = useAppTranslation()
  const { getErrorMessage } = useFormErrorMessage()
  const { setToastNotification } = useToastNotifications()
  const localStorageValue = localStorage.getItem(LocalStorageKeys.LOCALE)

  const getLanguage = (): 'se' | 'en' => {
    if (i18n.language.includes('sv')) {
      return 'se'
    }
    if (i18n.language.includes('en')) {
      return 'en'
    }
    return 'se'
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<InferType<typeof signUpSchema>>({
    mode: 'all',
    resolver: yupResolver(signUpSchema),
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      password: '',
      confirmPassword: '',
      legalAge: false,
      termsOfService: false,
    },
  })

  const updateUserLanguageBeforeLogin = async (
    user: SnapshotOut<typeof UserModel> | undefined
  ): Promise<void> => {
    try {
      const languageCode = getLanguageCode(localStorageValue)
      const formData: NUsers.NPatch.IRequestBody = {
        language: languageCode,
      }
      if (user) {
        await userStore.patchUser(user._id, formData)
      }
    } catch (error) {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
      captureException(error)
    }
  }

  const onSubmit = async (
    data: InferType<typeof signUpSchema>
  ): Promise<void> => {
    const { name, surname, email, password } = data
    if (!isValid) return

    try {
      setLoadingIsEmailRegistered(true)
      const resp = await isEmailRegistered(email)
      setLoadingIsEmailRegistered(false)
      const emailAlreadyRegistered = resp.data.data

      if (emailAlreadyRegistered) {
        setToastNotification(
          ToastType.DANGER,
          translate('form.errors.emailAlreadyInUse')
        )
        return
      }
      const userId = await signUpStore.createUser(
        name as string,
        surname as string,
        email as string,
        password,
        getLanguage()
      )

      const loginResponse = await authenticationStore.login(
        email as string,
        data.password
      )

      if (userId && loginResponse.userId) {
        await userStore.getUser(userId)
        const user = userStore.currentUser() as
          | SnapshotOut<typeof UserModel>
          | undefined
        await updateUserLanguageBeforeLogin(user)
      }
    } catch (error) {
      setLoadingIsEmailRegistered(false)
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
      captureException(error)
    }
  }

  const onError = (): void => {
    if (!isValid) return
    setToastNotification(
      ToastType.DANGER,
      translate('flashMessage.somethingWentWrongError')
    )
  }

  const loading =
    signUpStore.creatingAccount === 'pending' ||
    authenticationStore.loggingIn === 'pending' ||
    loadingIsEmailRegistered

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      className="flex flex-col gap-4"
    >
      <div className="flex flex-col gap-4 md:flex-row">
        <Controller
          control={control}
          name="name"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              error={errors.name && getErrorMessage(errors.name)}
              name={name}
              className="grow"
              label={translate('authenticationSignUpView.form.labels.name')}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.name}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="surname"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              error={errors.surname && getErrorMessage(errors.surname)}
              name={name}
              className="grow"
              label={translate('authenticationSignUpView.form.labels.surname')}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.surname}
              />
            </FormControl>
          )}
        />
      </div>
      <Controller
        control={control}
        name="email"
        render={({ field: { value, name, onChange, onBlur } }) => (
          <FormControl
            error={errors.email && getErrorMessage(errors.email)}
            name={name}
            label={translate('authenticationSignUpView.form.labels.email')}
          >
            <TextInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!errors.email}
              type="email"
            />
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="password"
        render={({ field: { value, name, onChange, onBlur } }) => (
          <FormControl
            error={errors.password && getErrorMessage(errors.password)}
            name={name}
            label={translate('authenticationSignUpView.form.labels.password')}
          >
            <TextInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              type="password"
              error={!!errors.password}
            />
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="confirmPassword"
        render={({ field: { value, name, onChange, onBlur } }) => (
          <FormControl
            error={
              errors.confirmPassword && getErrorMessage(errors.confirmPassword)
            }
            name={name}
            label={translate(
              'authenticationSignUpView.form.labels.confirmPassword'
            )}
          >
            <TextInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              type="password"
              error={!!errors.confirmPassword}
            />
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="legalAge"
        render={({ field: { name, value, onChange } }) => (
          <div className="flex gap-2">
            <Checkbox
              name={name}
              value={value}
              onChange={onChange}
              type="checkbox"
            />
            <button
              style={theme.textVariants.caption}
              className="text-left"
              onClick={() => onChange(!value)}
            >
              {translate('authenticationSignUpView.form.checkboxText.legalAge')}
            </button>
          </div>
        )}
      />
      {/* TODO: Do we want to display any error if these are not clicked? Where?  */}
      <Controller
        control={control}
        name="termsOfService"
        render={({ field: { name, value, onChange } }) => (
          <div className="flex gap-2">
            <Checkbox
              name={name}
              value={value}
              onChange={onChange}
              type="checkbox"
            />
            <button className="text-left" onClick={() => onChange(!value)}>
              <TermsOfServiceText />
            </button>
          </div>
        )}
      />
      <div className="mt-4 flex flex-col gap-4 md:mt-12">
        <Button
          type="submit"
          style={{
            ...theme.textVariants.paragraph,
          }}
          className="flex h-14 w-full items-center justify-center"
          label={translate('authenticationSignUpView.signUpButtonTitle')}
          onClick={handleSubmit(onSubmit, onError)}
          loading={loading}
          disabled={loading || !isValid}
          variant={ButtonVariant.PRIMARY}
        />
      </div>
    </form>
  )
})
