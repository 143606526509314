import React, { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { useStores } from '../../../hooks/useStores'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { SpinnerWrapper } from '../../../components/common/SpinnerWrapper'
import { useQuery } from '../../../hooks/useQuery'
import { useAuth } from '../../../hooks/useAuth'

export const EmailVerificationView = observer(() => {
  const { setUserIsVerified } = useAuth()
  const { userStore, authenticationStore } = useStores()
  const query = useQuery()
  const navigate = useNavigate()

  const verifyToken = query.get('token')

  const verifyUser = useCallback(
    async (verificationCode: string): Promise<void> => {
      const result = await userStore.verifyUser(verificationCode)
      if (result) {
        const { userId, token } = result
        authenticationStore.loginWithTokenAndUserId(token, userId)
        setUserIsVerified(true)
        navigate(reverseUrl('feed'))
      } else {
        navigate(reverseUrl('login'))
      }
    },
    [userStore, authenticationStore, setUserIsVerified, navigate]
  )

  const loading = userStore.verifyingUser !== 'pending'

  useEffect(() => {
    const verify = async (): Promise<void> => {
      if (verifyToken) {
        await verifyUser(verifyToken)
      } else {
        navigate(reverseUrl('login'))
      }
    }
    verify()
  }, [verifyToken, verifyUser, navigate])

  return (
    <div className="h-full w-full">{loading ? <SpinnerWrapper /> : null}</div>
  )
})
