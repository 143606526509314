import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { useStores } from '../../../../../hooks/useStores'
import { ErrorView } from '../../../../../views/error/ErrorView'
import { IllustrationChoices } from '../../../../common/Illustration'

interface DocumentsListEmptyStateProps {
  section: string
  noSearchResults: boolean
  documentId?: string
}

export const DocumentsListEmptyState = observer(
  ({
    documentId,
    section,
    noSearchResults,
  }: DocumentsListEmptyStateProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { documentStore } = useStores()
    const getEmptyStateSubTitle = (): string => {
      const currentDocumentName =
        documentId && documentStore.documents.get(documentId)?.name
      if (section === 'board') {
        return translate('documentsList.emptyState.emptyFolderSubtitleBoard', {
          folderName: currentDocumentName?.trim(),
        })
      }
      return translate('documentsList.emptyState.emptyFolderSubtitle', {
        folderName: currentDocumentName?.trim(),
      })
    }

    if (noSearchResults) {
      return (
        <ErrorView
          title={translate('documentsList.emptyState.title')}
          subtitle={translate(
            'documentsList.emptyState.noSearchResultSubtitle'
          )}
          illustration={IllustrationChoices.EMPTY}
        />
      )
    }

    return (
      <ErrorView
        title={translate('documentsList.emptyState.title')}
        subtitle={getEmptyStateSubTitle()}
        illustration={IllustrationChoices.EMPTY}
      />
    )
  }
)
