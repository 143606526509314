import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useStores } from '../../../../hooks/useStores'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { LocalOfferModel } from '../../../../state/models/local-offer'
import { NLocalOffers } from '../../../../interfaces/services/local-offers.interfaces'
import { societyLocalOfferCreateSchema } from '../../../../forms/schemas/society_local_offer_create'
import { CreateUpdateLocalOfferForm } from '../CreateUpdateLocalOfferForm'

interface CreateUpdateLocalOfferModalProps {
  show: boolean
  close: () => void
  localOffer?: SnapshotOut<typeof LocalOfferModel>
}

export const CreateUpdateLocalOfferModal = observer(
  ({
    localOffer,
    show,
    close,
  }: CreateUpdateLocalOfferModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { localOffersStore } = useStores()
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!localOffer

    const showSuccessMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate(
          updateMode
            ? 'localOfferView.flashMessage.updateLocalOfferSuccess'
            : 'localOffersView.flashMessage.createLocalOfferSuccess'
        )
      )
    }

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate(
          updateMode
            ? 'localOfferView.flashMessage.updateLocalOfferFailure'
            : 'localOffersView.flashMessage.createLocalOfferFailure'
        )
      )
    }

    const onSubmit = async (
      data: InferType<typeof societyLocalOfferCreateSchema>
    ): Promise<void> => {
      if (updateMode) {
        const status = await localOffersStore.updateLocalOffer(
          localOffer._id,
          data as NLocalOffers.NPatch.IRequestBody
        )
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      } else {
        const status = await localOffersStore.createLocalOffer(
          data as NLocalOffers.NCreate.IRequestBody
        )
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      }
    }

    const onError = (): void => {
      if (updateMode) {
        showSuccessMessage()
      } else {
        showErrorMessage()
      }
    }

    const loading =
      localOffersStore.creatingLocalOffer === 'pending' ||
      localOffersStore.updatingLocalOffer === 'pending'

    return (
      <Modal show={show} size="lg">
        {{
          header: (
            <ModalHeader onClose={close}>
              {translate('localOffersView.modal.create')}
            </ModalHeader>
          ),
          body: (
            <ModalBody>
              <CreateUpdateLocalOfferForm
                onError={onError}
                onSubmit={onSubmit}
                onClose={close}
                loading={loading}
                localOffer={localOffer}
              />
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
