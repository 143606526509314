import config from '../config'

// TODO: Test
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objToQueryString = (obj: { [key: string]: any }): string => {
  const keyValuePairs: string[] = []
  Object.keys(obj).forEach((key) => {
    keyValuePairs.push(
      `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
    )
  })
  return keyValuePairs.join('&')
}

export const createUrl = (
  path: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parameters?: { [key: string]: any }
): string => {
  return `${config.BASE_URL}${path}?${
    parameters ? objToQueryString(parameters) : ''
  }`
}

export const createAtlasUrl = (path: string): string => {
  return `${config.ATLAS_BASE_URL}${path}`
}

export const convertBase64 = (
  file: File | Blob
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}
