import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { UserModel } from '../../../state/models/user'
import {
  SocietyUserNestedAvatar,
  UserAvatar,
  Avatar,
} from '../../common/Avatar'
import { SocietyModel } from '../../../state/models/society'
import { useStores } from '../../../hooks/useStores'

interface PostAvatarProps {
  role: string
  author: SnapshotOut<typeof UserModel>
  society: SnapshotOut<typeof SocietyModel>
  userHasMultipleSocieties?: boolean
  onClick?: () => void
  disabled?: boolean
  avatarSize?: 'sm' | 'md'
}

export const PostAvatar = observer(
  ({
    role,
    author,
    society,
    userHasMultipleSocieties,
    onClick,
    avatarSize = 'md',
  }: PostAvatarProps) => {
    const size = avatarSize === 'md' ? 40 : 30

    const getAvatarMedia = (): string | null => {
      switch (role) {
        case 'admin-society':
        case 'board-society':
          return society?.coverPhotoId
        default:
          return author?.avatarId
      }
    }

    const getAvatar = (): JSX.Element => {
      switch (role) {
        case 'admin':
        case 'board-member':
          return (
            <SocietyUserNestedAvatar
              user={author}
              society={society}
              onClick={onClick}
              avatarSize={avatarSize}
            />
          )
        case 'admin-society':
        case 'board-society':
          return (
            <Avatar
              placeholder="society"
              mediaId={getAvatarMedia()}
              size={size}
            />
          )

        default:
          if (userHasMultipleSocieties) {
            return (
              <SocietyUserNestedAvatar
                user={author}
                society={society}
                onClick={onClick}
                inverted
                avatarSize={avatarSize}
              />
            )
          }
          return <UserAvatar user={author} size={size} onClick={onClick} />
      }
    }

    return getAvatar()
  }
)

interface PostAvatarWithMediaIdProps {
  role: string
  authorId: string
  societyId: string
  userHasMultipleSocieties?: boolean
  onClick?: () => void
  disabled?: boolean
  avatarSize?: 'sm' | 'md'
}

export const PostAvatarWithMediaId = observer(
  ({
    role,
    authorId,
    societyId,
    userHasMultipleSocieties,
    onClick,
    avatarSize = 'md',
  }: PostAvatarWithMediaIdProps) => {
    const { societyStore, userStore } = useStores()
    const size = avatarSize === 'md' ? 40 : 30
    const society = societyStore.societies.get(societyId) as SnapshotOut<
      typeof SocietyModel
    >
    const user = userStore.users.get(authorId) as SnapshotOut<typeof UserModel>

    const getAvatarMedia = (): string | null => {
      switch (role) {
        case 'admin-society':
        case 'board-society':
          return society.coverPhotoId
        default:
          return user.avatarId
      }
    }

    const getAvatar = (): JSX.Element => {
      switch (role) {
        case 'admin':
        case 'board-member':
          return (
            <SocietyUserNestedAvatar
              user={user}
              society={society}
              onClick={onClick}
              avatarSize={avatarSize}
            />
          )
        case 'admin-society':
        case 'board-society':
          return (
            <Avatar
              placeholder="society"
              mediaId={getAvatarMedia()}
              size={size}
            />
          )

        default:
          if (userHasMultipleSocieties) {
            return (
              <SocietyUserNestedAvatar
                user={user}
                society={society}
                onClick={onClick}
                inverted
                avatarSize={avatarSize}
              />
            )
          }
          return <UserAvatar user={user} size={size} onClick={onClick} />
      }
    }

    return getAvatar()
  }
)
