import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { societyCreateGroupSchema } from '../../../forms/schemas/society_group_create'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'
import { Modal } from '../../common/Modal'
import { ModalHeader } from '../../common/Modal/ModalHeader'
import { theme } from '../../../theme/theme'
import { ModalBody } from '../../common/Modal/ModalBody'
import { CreateUpdateRegisterGroupForm } from './CreateUpdateRegisterGroupForm'
import { GroupModel } from '../../../state/models/group'
import { useWindowDimensions } from '../../../hooks/useWindowDimensions'

interface CreateUpdateRegisterGroupModalProps {
  show: boolean
  close: () => void
  group?: SnapshotOut<typeof GroupModel>
}

export const CreateUpdateRegisterGroupModal = observer(
  ({
    show,
    close,
    group,
  }: CreateUpdateRegisterGroupModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { height } = useWindowDimensions()
    const { groupStore } = useStores()
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!group

    const showSuccessMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate(
          updateMode
            ? 'registerGroup.flashMessage.updateSuccess'
            : 'registerGroup.flashMessage.createSuccess'
        )
      )
    }

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate(
          updateMode
            ? 'registerGroup.flashMessage.updateFailure'
            : 'registerGroup.flashMessage.createFailure'
        )
      )
    }

    const onSubmit = async (
      data: InferType<typeof societyCreateGroupSchema>
    ): Promise<void> => {
      const status = updateMode
        ? await groupStore.patchGroup(group._id, data)
        : await groupStore.createGroup(data)

      if (status) {
        showSuccessMessage()
        close()
      } else {
        showErrorMessage()
      }
    }

    const onError = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const loading =
      groupStore.creatingGroup === 'pending' ||
      groupStore.updatingGroup === 'pending'

    return (
      <Modal show={show} height={height * 0.8} size="lg">
        {{
          header: (
            <ModalHeader onClose={close}>
              <div>
                <p style={theme.textVariants.h3}>
                  {translate(
                    updateMode
                      ? 'registerGroup.modal.update'
                      : 'registerGroup.modal.create'
                  )}
                </p>
              </div>
            </ModalHeader>
          ),
          body: (
            <ModalBody>
              <CreateUpdateRegisterGroupForm
                onError={onError}
                onSubmit={onSubmit}
                onClose={close}
                loading={loading}
                group={group}
              />
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
