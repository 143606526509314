import React, { MouseEvent } from 'react'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { theme } from '../../../theme/theme'
import { Button, ButtonVariant } from '../Button'
import { Modal } from '../Modal'
import { ModalBody } from '../Modal/ModalBody'
import { ModalFooter } from '../Modal/ModalFooter'
import { ModalHeader } from '../Modal/ModalHeader'
import { Icon, IconChoices } from '../Icon'

interface ConfirmationModalProps {
  close: () => void
  onConfirm: () => void
  show: boolean
  title: string
  description: string
  deleteMode?: boolean
  confirmationButtonLabel?: string
}

export const ConfirmationModal = ({
  show,
  title,
  description,
  onConfirm,
  close,
  deleteMode = true,
  confirmationButtonLabel,
}: ConfirmationModalProps): JSX.Element => {
  const { translate } = useAppTranslation()

  const cancel = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault()
    e.stopPropagation()
    close()
  }

  const confirm = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault()
    e.stopPropagation()
    onConfirm()
    close()
  }

  const getConfirmationButtonLabel = (): string => {
    if (confirmationButtonLabel) {
      return confirmationButtonLabel
    }
    return translate(deleteMode ? 'common.actions.delete' : 'common.yes')
  }

  return (
    <Modal show={show} size="sm">
      {{
        header: <ModalHeader style={theme.textVariants.lead} onClose={close} />,
        body: (
          <ModalBody>
            <div className="flex items-start gap-3">
              <Icon
                icon={IconChoices.EXCLAMATION_ALERT}
                width={46}
                height={46}
              />
              <div className="flex flex-col space-y-1 text-left">
                <p style={theme.textVariants.h3}>{title}</p>
                <p className="text-neutral-40" style={theme.textVariants.base}>
                  {description}
                </p>
              </div>
            </div>
          </ModalBody>
        ),
        footer: (
          <ModalFooter className="flex flex-row gap-3">
            <Button
              label={translate('common.actions.cancel')}
              onClick={cancel}
              size="sm"
            />
            <Button
              label={getConfirmationButtonLabel()}
              size={deleteMode ? 'sm' : 'sm-wide'}
              onClick={confirm}
              variant={ButtonVariant.DANGER}
            />
          </ModalFooter>
        ),
      }}
    </Modal>
  )
}
