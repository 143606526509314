import React from 'react'
import { Button, ButtonProps } from '../../../components/common/Button'
import {
  Illustration,
  IllustrationChoices,
} from '../../../components/common/Illustration'
import { theme } from '../../../theme/theme'

export interface ButtonPropsWithId {
  id: string
  button: ButtonProps
}

export interface ErrorView {
  title: string
  titleWrapperClassName?: string
  subtitle?: string
  buttons?: ButtonPropsWithId[]
  illustration?: IllustrationChoices
  className?: string
  illustrationWidth?: number
  illustrationHeight?: number
}

export const ErrorView = ({
  title,
  titleWrapperClassName,
  subtitle,
  buttons,
  illustration,
  className,
  illustrationWidth = 300,
  illustrationHeight,
}: ErrorView): JSX.Element => {
  return (
    <div
      className={`flex h-full w-full flex-col items-center justify-center gap-3 ${
        className ?? ''
      }
      `}
    >
      {illustration && (
        <Illustration
          illustrationChoice={illustration}
          width={illustrationWidth}
          height={illustrationHeight}
        />
      )}
      <div
        className={`flex flex-col items-center ${titleWrapperClassName ?? ''}`}
      >
        <p style={theme.textVariants.h2}>{title}</p>
        {subtitle && (
          <p
            style={theme.textVariants.base}
            className="mt-3 max-w-prose text-center"
          >
            {subtitle}
          </p>
        )}
        {buttons && buttons.length > 0 ? (
          <div className="mt-6 flex gap-3">
            {buttons.map((_button) => (
              <Button key={_button.id} {..._button.button} />
            ))}
          </div>
        ) : undefined}
      </div>
    </div>
  )
}
