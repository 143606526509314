export enum BoardRole {
  PRESIDENT = 'president',
  VICE_PRESIDENT = 'vicepresident',
  TREASURY = 'treasury',
  MEMBER = 'member',
  SECRETARY = 'secretary',
  ALTERNATE = 'alternate',
  ADVISOR = 'advisor',
  NOMINATING_COMMITTEE = 'nominating-committee',
}
