import React from 'react'
import { useAtom } from 'jotai'
import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../hooks/useStores'
import { ChatRoomModel } from '../../../state/models/chat-room'
import { UserModel } from '../../../state/models/user'
import { ChatHeaderState, chatHeaderStateAtom } from '../atom'
import { UserAvatar } from '../../common/Avatar'
import { theme } from '../../../theme/theme'

interface GroupAvatarProps {
  chatRoom: SnapshotOut<typeof ChatRoomModel>
}

export const GroupAvatar = ({ chatRoom }: GroupAvatarProps): JSX.Element => {
  const { userStore } = useStores()
  const [chatHeaderState] = useAtom(chatHeaderStateAtom)
  const numberOfAvatarsToShow = 5
  const numberOfNamesToShow = numberOfAvatarsToShow - 2

  const groupChatMembers = chatRoom?.membersList
    .map((_member) => userStore.users.get(_member.userId))
    .filter((_user) => _user !== undefined)
    .sort((a, b): number => {
      if (a?.avatarId && !b?.avatarId) return -1
      if (!a?.avatarId && b?.avatarId) return 1

      if (a?.fullName && b?.fullName) {
        if (a?.fullName > b?.fullName) {
          return 1
        }

        return -1
      }
      return 0
    }) as SnapshotOut<typeof UserModel>[]

  const getNamesString = (): string => {
    const members = groupChatMembers
      .slice(0, numberOfNamesToShow)
      .map((_user) => _user.name)
      .join(', ')
    if (groupChatMembers.length > numberOfNamesToShow) {
      return `${members} +${groupChatMembers.length - numberOfNamesToShow}`
    }
    return members
  }

  const shouldRender =
    chatHeaderState === ChatHeaderState.JoinChat && groupChatMembers.length > 0

  return (
    <>
      {shouldRender && (
        <div className="flex justify-center self-center pt-8 pb-16">
          <div className="flex flex-col items-center gap-4">
            <p style={theme.textVariants.lead}>{chatRoom?.name}</p>
            <div className="flex">
              {groupChatMembers
                .slice(0, numberOfAvatarsToShow)
                .map((_user, index) => (
                  <UserAvatar
                    key={_user._id}
                    user={_user}
                    size={46}
                    className={index !== 0 ? '-ml-2' : ''}
                  />
                ))}
            </div>
            <div>
              <p className="text-neutral-40" style={theme.textVariants.base}>
                {getNamesString()}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
