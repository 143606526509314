import { AxiosResponse } from 'axios'
import { NComments } from '../interfaces/services/comments.interfaces'
import { createUrl } from './helpers'
import axiosAuth from './config'

export const COMMENT_POPULATE = ['comments', 'users.media', 'media']

export const toggleLike = (
  id: string,
  like: boolean
): Promise<AxiosResponse<NComments.NToggleLike.IResponse>> => {
  return axiosAuth.post<NComments.NToggleLike.IResponse>(
    createUrl(`/comments/toggle-like/${id}`),
    {
      value: like,
    }
  )
}

export const createComment = (
  body: NComments.NCreate.IRequestBody
): Promise<AxiosResponse<NComments.NCreate.IResponse>> => {
  return axiosAuth.post<NComments.NCreate.IResponse>(
    createUrl(`/comments/comment`, {
      populate: COMMENT_POPULATE.join(';'),
    }),
    body
  )
}

export const deleteComment = (
  id: string
): Promise<AxiosResponse<NComments.NDel.IResponse>> => {
  return axiosAuth.delete<NComments.NDel.IResponse>(
    createUrl(`/comments/comment/${id}`)
  )
}

export const patchComment = (
  id: string,
  body: NComments.NPatch.IRequestBody
): Promise<AxiosResponse<NComments.NPatch.IResponse>> => {
  return axiosAuth.patch<NComments.NPatch.IResponse>(
    createUrl(`/comments/comment/${id}`),
    body
  )
}
