import React, { useMemo } from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { InferType } from 'yup'
import { useStores } from '../../../hooks/useStores'
import { ChatRoomModel } from '../../../state/models/chat-room'
import { UserList } from '../UserList/UserList'
import { SelectDropdown } from '../../common/SelectDropdown'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useUsersWithChatRoom } from '../ChatRoom/useUsersWithChatRoom'
import { addMembersToChatSchema } from '../../../forms/schemas/chat_room'
import { theme } from '../../../theme/theme'
import { Button, ButtonVariant } from '../../common/Button'
import { useFormatUserOptionLabel } from '../../../hooks/useFormatUserOptionLabel'
import { useIsUserAdminOfGroupChat } from '../../../hooks/useIsUserAdminOfGroupChat'

interface ShowGroupMembersContentProps {
  chatRoom: SnapshotOut<typeof ChatRoomModel>
}

export const ShowGroupMembersContent = ({
  chatRoom,
}: ShowGroupMembersContentProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const { chatRoomStore, userStore } = useStores()
  const formatOptionLabel = useFormatUserOptionLabel()
  const { isUserAdminOfGroupChat } = useIsUserAdminOfGroupChat(chatRoom)

  const getDefaultValues = useMemo(() => {
    return {
      users: [],
      society: chatRoom.societyId,
    }
  }, [chatRoom.societyId])

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isValid },
  } = useForm<InferType<typeof addMembersToChatSchema>>({
    mode: 'all',
    resolver: yupResolver(addMembersToChatSchema),
    defaultValues: getDefaultValues,
  })
  const watchSociety = watch('society')
  const watchUsers = watch('users')

  const { usersWithChatRoom } = useUsersWithChatRoom({
    isGroupchat: true,
    watchRecipients: watchUsers,
    watchSociety,
  })

  const members = chatRoom.membersList
    .map((member) => userStore.users.get(member.userId))
    .filter((_member) => _member !== undefined)

  const memberIds = members.map((_member) => _member?._id)

  const usersInSocietyWithoutCurrentMembers = usersWithChatRoom.filter(
    (member) => !memberIds.includes(member.user._id)
  )

  const userOptions = usersInSocietyWithoutCurrentMembers.map((member) => ({
    value: member.user._id,
    label: member.user.fullName,
    data: member.user,
  }))

  const onSubmit = async (
    data: InferType<typeof addMembersToChatSchema>
  ): Promise<void> => {
    chatRoomStore.addChatRoomMembers(chatRoom._id, data.users)
    reset(getDefaultValues)
  }

  return (
    <div className="flex max-h-96 flex-col gap-4">
      {isUserAdminOfGroupChat && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex w-full">
            <div className="mt-[6px] flex">
              {translate('chatSettings.actions.add')}
            </div>
            <div className="flex flex-1">
              <Controller
                control={control}
                name="users"
                render={({ field: { value, onChange } }) => (
                  <SelectDropdown
                    value={value}
                    className="h-fit min-h-[40px] w-full"
                    onChange={onChange}
                    options={userOptions}
                    formatOptionLabel={formatOptionLabel}
                    isMulti
                    borderless
                    menuListHeight={350}
                    menuListWidth={325}
                    showDropdownIndicator={false}
                    menuPlacement="top"
                    minHeight="fit-content"
                    placeholder={translate(
                      'chatRoomsSidebar.createNewMessageForm.search.placeholder'
                    )}
                  />
                )}
              />
            </div>
            <Button
              label={translate('common.actions.save')}
              disabled={!isValid}
              type="submit"
              size="sm"
              variant={ButtonVariant.PRIMARY}
            />
          </div>
        </form>
      )}
      <p style={theme.textVariants.paragraph}>
        {translate('chatSettings.form.members')}
      </p>
      <div className="flex flex-col gap-6 overflow-y-auto pr-2">
        {members.map(
          (member) =>
            member && (
              <UserList key={member._id} user={member} chatRoom={chatRoom} />
            )
        )}
      </div>
    </div>
  )
}
