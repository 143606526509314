import React, { useState } from 'react'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../Icon'
import { Label } from '../Label/Label'
import { TextTooltip } from '../Tooltip'
import { FormControlNote, FormControlNoteProps } from './FormControlNote'

interface FormControlProps {
  children: React.ReactNode
  name: string
  style?: React.CSSProperties
  className?: string
  label?: string
  helperText?: string
  error?: string
  disabled?: boolean
  infoTooltipTitle?: string
  infoTooltipText?: string
  toolTipUseFlexibleWidth?: boolean
  note?: FormControlNoteProps
  labelStyle?: React.CSSProperties
  labelClassName?: string
}

export const FormControl = ({
  children,
  style,
  label,
  helperText,
  className,
  error,
  name,
  disabled,
  infoTooltipTitle,
  infoTooltipText,
  toolTipUseFlexibleWidth,
  note,
  labelStyle = theme.textVariants.caption,
  labelClassName,
}: FormControlProps): JSX.Element => {
  const [referenceElement, setReferenceElement] = useState<Element | null>(null)
  return (
    <div style={style} className={`${className ?? ''} flex flex-col`}>
      {label && (
        <div className="mb-1 flex flex-row justify-between">
          <Label
            name={name}
            className={`mb-1 flex items-center ${
              disabled ? 'opacity-50' : ''
            } ${labelClassName ?? ''}`}
            label={label}
            style={labelStyle}
          />
          <div className="flex items-center gap-1">
            {note && <FormControlNote {...note} />}
            {(infoTooltipTitle || infoTooltipText) && (
              <TextTooltip
                title={infoTooltipTitle}
                text={infoTooltipText}
                referenceElement={referenceElement}
                useFlexibleWidth={toolTipUseFlexibleWidth}
              >
                <Icon
                  icon={IconChoices.QUESTION_MARK}
                  ref={setReferenceElement}
                />
              </TextTooltip>
            )}
          </div>
        </div>
      )}
      {children}
      {error && (
        <div
          className="text-sbab-red mt-1 flex gap-2"
          style={theme.textVariants.captionBold}
        >
          <Icon
            size={20}
            className="text-sbab-red"
            icon={IconChoices.EXCLAMATION_ALERT}
          />
          <p>{error}</p>
        </div>
      )}
      {helperText && (
        <p
          style={theme.textVariants.captionBold}
          className={`text-sbab-gray-40 mt-1 ${disabled ? 'opacity-50' : ''}`}
        >
          {helperText}
        </p>
      )}
    </div>
  )
}
