import { observer } from 'mobx-react-lite'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import * as Sentry from '@sentry/react'
import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from './useStores'
import { UserModel } from '../state/models/user'
import { retrieveUserSession } from '../api/config'
import { checkIfTwilightZone } from '../helpers/authentication'

interface AuthProviderProps {
  children: React.ReactNode
}

interface IAuthContext {
  userId: string | undefined
  userIsVerified: boolean | undefined
  isBoappaSuperadmin: boolean | undefined
  setUserIsVerified: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

const AuthContext = createContext<IAuthContext>({
  userId: undefined,
  userIsVerified: undefined,
  isBoappaSuperadmin: undefined,
  setUserIsVerified: () => null,
})

const AuthProvider = observer(
  ({ children }: AuthProviderProps): JSX.Element => {
    const { adminStore, authenticationStore, userStore } = useStores()
    const initializeState = (): string | undefined => {
      authenticationStore.setUserIdFromCookie()
      return authenticationStore.userId
    }
    const [userId, setUserId] = useState(initializeState)
    const [userIsVerified, setUserIsVerified] = useState<boolean | undefined>(
      undefined
    )

    useEffect(() => {
      if (authenticationStore?.userId) adminStore.getPermissionsStatus()
    }, [adminStore, authenticationStore?.userId])

    useEffect(() => {
      setUserId(authenticationStore.userId)
      Sentry.setUser({ id: authenticationStore.userId })
    }, [authenticationStore?.userId])

    useEffect(() => {
      ;(async () => {
        if (userId) {
          const userSession = retrieveUserSession()
          adminStore.setIsTwilight(checkIfTwilightZone())
          if (userSession) {
            authenticationStore.setToken(userSession)
            const status = await userStore.getUser(userId)
            if (status === 401) {
              authenticationStore.logout()
              window.location.reload()
            }
            const _user = userId
              ? (userStore.users.get(userId) as SnapshotOut<typeof UserModel>)
              : undefined
            setUserIsVerified(_user?.isVerified)
          } else {
            authenticationStore.logout()
            window.location.reload()
          }
        }
      })()
    }, [adminStore, authenticationStore, userId, userStore, userStore.users])

    const value = useMemo(
      () => ({
        userId,
        userIsVerified,
        isBoappaSuperadmin: adminStore.isBoappaSuperadmin,
        setUserIsVerified,
      }),
      [userId, userIsVerified, adminStore.isBoappaSuperadmin, setUserIsVerified]
    )

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  }
)

AuthProvider.displayName = 'AuthProvider'
export { AuthProvider }

export const useAuth = (): IAuthContext => useContext(AuthContext)
