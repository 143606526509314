import { AxiosResponse } from 'axios'
import { createUrl } from './helpers'
import axiosAuth from './config'
import { NFaqs } from '../interfaces/services/faqs.interfaces'

export const getFaqsPerSociety = (
  societyId: string
): Promise<AxiosResponse<NFaqs.NGetPerSocieties.IResponse>> => {
  const body: NFaqs.NGetPerSocieties.IRequestBody = {
    societyIds: [societyId],
  }
  return axiosAuth.post<NFaqs.NGetPerSocieties.IResponse>(
    createUrl('/faqs/get-per-societies/'),
    body
  )
}

export const getFaq = (
  id: string
): Promise<AxiosResponse<NFaqs.NGetById.IResponse>> => {
  return axiosAuth.get<NFaqs.NGetById.IResponse>(createUrl(`/faqs/faq/${id}`))
}

export const patchFaq = (
  id: string,
  body: NFaqs.NPatch.IRequestBody
): Promise<AxiosResponse<NFaqs.NPatch.IResponse>> => {
  return axiosAuth.patch<NFaqs.NPatch.IResponse>(
    createUrl(`/faqs/faq/${id}`),
    body
  )
}

export const createFaq = (
  body: NFaqs.NCreate.IRequestBody
): Promise<AxiosResponse<NFaqs.NCreate.IResponse>> => {
  return axiosAuth.post<NFaqs.NCreate.IResponse>(createUrl(`/faqs/faq`), body)
}

export const deleteFaq = (
  id: string
): Promise<AxiosResponse<NFaqs.NDel.IResponse>> => {
  return axiosAuth.delete<NFaqs.NDel.IResponse>(createUrl(`/faqs/faq/${id}`))
}
