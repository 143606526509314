import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { TimeSlot } from '../TimeSlot'
import { FacilityModel } from '../../../../state/models/facility'
import { useTimeSlots } from './useTimeSlots'
import { ITimeSlot } from '../FacilityCalendar/interfaces'
import { Alert } from '../../../common/Alert'
import { AlertType } from '../../../common/Alert/alert-type'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { formatDateWithFormat } from '../../../../helpers/date'

export interface FacilityCalendarTimeSlots {
  facility: SnapshotOut<typeof FacilityModel>
  selectedDate: Date
  selectedTimeSlot: ITimeSlot | undefined
  setSelectedTimeslot: (timeSlot: ITimeSlot | undefined) => void
  userHasReachedMaxNumberOfBookings: boolean
}

export const FacilityCalendarTimeSlots = observer(
  ({
    facility,
    selectedDate,
    selectedTimeSlot,
    setSelectedTimeslot,
    userHasReachedMaxNumberOfBookings,
  }: FacilityCalendarTimeSlots): JSX.Element | null => {
    const { translate } = useAppTranslation()
    const slots = useTimeSlots(selectedDate, facility)

    const getDateIsBlocked = useCallback((): boolean => {
      const blockedDateStrings = facility.blockedDates
        ? facility.blockedDates.map((_date) =>
            formatDateWithFormat(new Date(_date), 'yyyy-MM-dd')
          )
        : []
      return blockedDateStrings.includes(
        formatDateWithFormat(new Date(selectedDate), 'yyyy-MM-dd')
      )
    }, [facility.blockedDates, selectedDate])

    if (slots.length === 0 || getDateIsBlocked()) {
      return (
        <Alert
          text={translate('facilityView.calendar.alert.noTimeSlots')}
          type={AlertType.DANGER}
        />
      )
    }

    const fullDayBookingGranularity = facility.bookingGranularity === 'full-day'

    return (
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7">
        {slots.map((_slot) => {
          const selected =
            selectedTimeSlot &&
            _slot.startDate.toISOString() ===
              selectedTimeSlot.startDate.toISOString() &&
            _slot.endDate.toISOString() ===
              selectedTimeSlot.endDate.toISOString()

          return (
            <TimeSlot
              key={_slot.startDate.toISOString()}
              fullDay={fullDayBookingGranularity}
              onClick={() =>
                selected
                  ? setSelectedTimeslot(undefined)
                  : setSelectedTimeslot(_slot)
              }
              userHasReachedMaxNumberOfBookings={
                userHasReachedMaxNumberOfBookings
              }
              selected={selected ?? false}
              {..._slot}
            />
          )
        })}
      </div>
    )
  }
)
