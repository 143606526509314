import React from 'react'

interface SimpleLinkProps {
  href: string
  text: string
  style?: React.CSSProperties
}

export const SimpleLink = ({
  href,
  text,
  style,
}: SimpleLinkProps): JSX.Element => {
  return (
    <a
      style={style}
      target="_blank"
      rel="noreferrer"
      href={href}
      className="text-brandGreen"
    >
      {text}
    </a>
  )
}
