/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { KeyboardEvent, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { IconChoices } from '../../../../components/common/Icon'
import { formatDate, formatDateWithFormat } from '../../../../helpers/date'
import { TableColumn } from '../../../../components/common/Table/TableHeader'
import { Dropdown } from '../../../../components/common/Dropdown'
import { Button, ButtonVariant } from '../../../../components/common/Button'
import { TableRow } from '../../../../components/common/Table/TableRows'
import { SearchBar } from '../../../../components/common/SearchBar'
import { Table } from '../../../../components/common/Table'
import { searchSociety as apiSearchSociety } from '../../../../api/admin'
import { NAdmin } from '../../../../interfaces/services/admin.interfaces'
import {
  ActivatedPartnerModel,
  SocietyModel,
} from '../../../../state/models/society'
import { useModal } from '../../../../hooks/useModal'
import { AdminListModal } from './AdminListModal'
import { SetPartnerModal } from './SetPartnerModal'
import { SetMasterAdminModal } from './SetMasterAdminModal'
import { useStores } from '../../../../hooks/useStores'
import { ConfirmationModal } from '../../../../components/common/ConfirmationModal'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../../components/common/Toast/toast-type'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { EditSocietyModal } from './EditSocietyModal'
import { EditSocietyInvoiceModal } from './EditSocietyInvoiceModal'
import { getPartnerNameId } from '../../../../components/society/ManagementPartner/management_partner'
import { DeleteSocietyModal } from './DeleteSocietyModal'
import { EditSocietyWebsiteModal } from './EditSocietyWebsiteModal'
import { SetPremiumModal } from './SetPremiumModal'
import { SetBasicModal } from './SetBasicModal'

export const Societies = observer((): JSX.Element | null => {
  const { settingsStore, adminStore } = useStores()
  const [searchString, setSearchString] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [emptySearchResult, setEmptySearchResult] = useState(false)
  const { setToastNotification } = useToastNotifications()
  const [currentAdminList, setCurrentAdminList] = useState<
    string[] | undefined
  >()
  const [currentSocietyId, setCurrentSocietyId] = useState<string>()
  const [societiesSearchResult, setSocietiesSearchResult] = useState<
    SnapshotOut<typeof SocietyModel>[]
  >([])
  const { translate } = useAppTranslation()
  const {
    show: showAdminListModal,
    open: openAdminListModal,
    close: closeAdminListModal,
  } = useModal()
  const {
    show: showSetPartnerModal,
    open: openSetPartnerModal,
    close: closeSetPartnerModal,
  } = useModal()
  const {
    show: showSetMasterAdminModal,
    open: openSetMasterAdminModal,
    close: closeSetMasterAdminModal,
  } = useModal()
  const {
    show: showInactivateSocietyModal,
    open: openInactivateSocietyModal,
    close: closeInactivateSocietyModal,
  } = useModal()
  const {
    show: showEditSocietyModal,
    open: openEditSocietyModal,
    close: closeEditSocietyModal,
  } = useModal()
  const {
    show: showEditSocietyInvoiceModal,
    open: openEditSocietyInvoiceModal,
    close: closeEditSocietyInvoiceModal,
  } = useModal()
  const {
    show: showEditSocietyWebsiteModal,
    open: openEditSocietyWebsiteModal,
    close: closeEditSocietyWebsiteModal,
  } = useModal()
  const {
    show: showDeleteSocietyModal,
    open: openDeleteSocietyModal,
    close: closeDeleteSocietyModal,
  } = useModal()
  const {
    show: showSetPremiumModal,
    open: openSetPremiumModal,
    close: closeSetPremiumModal,
  } = useModal()
  const {
    show: showSetBasicModal,
    open: openSetBasicModal,
    close: closeSetBasicModal,
  } = useModal()

  useEffect(() => {
    settingsStore.getAllSettings()
  }, [settingsStore])

  const onChangeSearchString = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    setSearchString(event.currentTarget.value)
  }

  const searchSocieties = async (query: string): Promise<void> => {
    const resp = await apiSearchSociety({ query })
    const data = resp.data as NAdmin.NSearchSociety.IResponse
    const _societies = data.data

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setSocietiesSearchResult(_societies)
    setEmptySearchResult(_societies.length === 0)
  }

  const onSubmit = async (): Promise<void> => {
    setIsSearching(true)
    try {
      await searchSocieties(searchString)
    } catch (error) {
      setIsSearching(false)
    }
    setIsSearching(false)
  }

  const handleEnterKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  const loading = isSearching

  const columns: TableColumn[] = [
    {
      key: 'actions',
      title: 'Åtgärder',
      accessor: '_id',
      renderItem: (_id: string) => {
        const society = societiesSearchResult.find(
          (_society) => _society._id === _id
        )
        const adminList =
          society?.adminsList?.map((admin) => admin.userId) || []

        return (
          <Dropdown
            dropdownClassName="w-[300px]"
            className="w-fit"
            dropdownPosition="bottom-right"
            options={[
              {
                value: 'setPartner',
                label: 'Sätt partner',
                icon: IconChoices.ADMIN,
                onClick: () => {
                  setCurrentSocietyId(_id)
                  openSetPartnerModal()
                },
              },
              {
                value: 'setMasterAdminUser',
                label: 'Uppdatera master admin',
                icon: IconChoices.ADMIN,
                onClick: () => {
                  setCurrentAdminList(adminList)
                  setCurrentSocietyId(_id)
                  openSetMasterAdminModal()
                },
              },
              {
                value: 'viewAdmins',
                label: 'Visa admins',
                icon: IconChoices.ADMIN,
                onClick: () => {
                  setCurrentAdminList(adminList)
                  openAdminListModal()
                },
              },
              {
                value: 'editSociety',
                label: 'Redigera förening',
                icon: IconChoices.EDIT,
                onClick: () => {
                  setCurrentSocietyId(_id)
                  openEditSocietyModal()
                },
              },
              {
                value: 'editSocietyInvoice',
                label: 'Redigera fakturering',
                icon: IconChoices.EDIT,
                onClick: () => {
                  setCurrentSocietyId(_id)
                  openEditSocietyInvoiceModal()
                },
              },
              {
                value: 'editSocietyWebsite',
                label: 'Redigera föreningshemsida',
                icon: IconChoices.EDIT,
                onClick: () => {
                  setCurrentSocietyId(_id)
                  openEditSocietyWebsiteModal()
                },
              },
              {
                value: 'setPremium',
                label: 'Uppgradera till premium',
                icon: IconChoices.CHEVRON_UP,
                onClick: () => {
                  setCurrentSocietyId(_id)
                  openSetPremiumModal()
                },
              },
              {
                value: 'setBasic',
                label: 'Nedgradera till basic',
                icon: IconChoices.CHEVRON_DOWN,
                onClick: () => {
                  setCurrentSocietyId(_id)
                  openSetBasicModal()
                },
              },
              {
                value: 'inactivateSociety',
                label: 'Inaktivera förening',
                icon: IconChoices.LEAVE_SOCIETY,
                onClick: () => {
                  setCurrentSocietyId(_id)
                  openInactivateSocietyModal()
                },
              },
              {
                value: 'deleteSociety',
                label: 'Radera förening',
                icon: IconChoices.DELETE_TRASH,
                destructive: true,
                onClick: () => {
                  setCurrentSocietyId(_id)
                  openDeleteSocietyModal()
                },
              },
            ]}
          >
            <Button
              label="Åtgärder"
              iconPlacement="left"
              variant={ButtonVariant.PRIMARY}
            />
          </Dropdown>
        )
      },
    },
    { key: '_id', title: 'Id' },
    { key: 'name', title: 'Namn' },
    { key: 'organisationNumber', title: 'Organisationsnummer' },
    {
      key: 'status',
      title: 'Status',
    },
    {
      key: 'masterAdminUserId',
      title: 'Master admin user',
    },
    { key: 'addressStreet', title: 'Gatunamn' },
    { key: 'addressCity', title: 'addressCity' },
    { key: 'addressZip', title: 'Postkod' },
    { key: 'companyName', title: 'BU Namn' },
    {
      key: 'activatedPartnerData',
      title: 'Partner',
      accessor: 'activatedPartnerData',
      renderItem: (
        activatedPartnerData: SnapshotOut<typeof ActivatedPartnerModel>[]
      ) => {
        return (
          <p>
            {activatedPartnerData?.length > 0
              ? activatedPartnerData
                  .map((partner) => getPartnerNameId(partner?.partnerId))
                  .join(', ')
              : ''}
          </p>
        )
      },
    },
    { key: 'accountType', title: 'Kontotyp' },
    {
      key: 'activatedAt',
      title: 'Aktiverad',
      accessor: 'activatedAt',
      renderItem: (activatedAt: string) => (
        <p>{activatedAt && formatDate(new Date(activatedAt))}</p>
      ),
    },
    { key: 'societyTypeDisplayName', title: 'Society type' },
    {
      key: 'locked',
      title: 'Låst',
      accessor: 'locked',
      renderItem: (locked: string) => <p>{locked ? 'Ja' : 'Nej'}</p>,
    },
    {
      key: 'trialPeriodEndDate',
      title: 'Provperiod slutar',
      accessor: 'trialPeriodEndDate',
      renderItem: (trialPeriodEndDate: string) => (
        <p>
          {trialPeriodEndDate &&
            formatDateWithFormat(new Date(trialPeriodEndDate), 'yyyy-MM-dd')}
        </p>
      ),
    },
    { key: 'billingRefText', title: 'Billing ref text' },
    { key: 'addressBillingStreet', title: 'Fakturering - gatunamn' },
    { key: 'addressBillingCo', title: 'Fakturering - adress co' },
    { key: 'addressBillingZip', title: 'Fakturering - postkod' },
    { key: 'addressBillingCity', title: 'Fakturering - stad' },
    { key: 'addressBillingCounty', title: 'Fakturering - Län' },
    { key: 'billingUnits', title: 'Billing units' },
    { key: 'billingEmail', title: 'Fakturering - mejl' },
  ]

  const rows: TableRow[] = societiesSearchResult.map((society) => ({
    key: society._id,
    data: society,
  }))

  const resetSearchResult = (): void => {
    setSocietiesSearchResult([])
  }

  const inactivateSociety = async (): Promise<void> => {
    const status =
      currentSocietyId &&
      (await adminStore.inactivateSociety({
        societyId: currentSocietyId,
      }))
    if (status) {
      setToastNotification(ToastType.SUCCESS, 'Inaktiverade föreningen')
      resetSearchResult()
    } else {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }
  }

  return (
    <div className="h-full w-full space-y-4">
      <div className="flex gap-1">
        <SearchBar
          placeholder={translate('adminView.searchBar.placeholders.societies')}
          value={searchString}
          onChange={onChangeSearchString}
          onKeyUp={handleEnterKeyPress}
        />
        <Button
          className="h-12"
          icon={IconChoices.SEARCH}
          loading={loading}
          onClick={onSubmit}
          disabled={searchString === ''}
        />
      </div>
      {rows?.length > 0 && <Table columns={columns} rows={rows} />}
      {emptySearchResult && (
        <p>{translate('adminView.searchResult.emptyResult')}</p>
      )}
      {showAdminListModal && (
        <AdminListModal
          show={showAdminListModal}
          close={closeAdminListModal}
          adminList={currentAdminList}
        />
      )}
      {showSetPartnerModal && (
        <SetPartnerModal
          show={showSetPartnerModal}
          close={closeSetPartnerModal}
          societyId={currentSocietyId}
        />
      )}
      {showSetMasterAdminModal && (
        <SetMasterAdminModal
          show={showSetMasterAdminModal}
          close={closeSetMasterAdminModal}
          societyId={currentSocietyId}
          adminList={currentAdminList}
        />
      )}
      {showEditSocietyModal && (
        <EditSocietyModal
          show={showEditSocietyModal}
          close={closeEditSocietyModal}
          societyId={currentSocietyId}
          resetSearchResult={resetSearchResult}
        />
      )}
      {showEditSocietyInvoiceModal && (
        <EditSocietyInvoiceModal
          show={showEditSocietyInvoiceModal}
          close={closeEditSocietyInvoiceModal}
          societyId={currentSocietyId}
        />
      )}
      {showEditSocietyWebsiteModal && (
        <EditSocietyWebsiteModal
          show={showEditSocietyWebsiteModal}
          close={closeEditSocietyWebsiteModal}
          societyId={currentSocietyId}
          resetSearchResult={resetSearchResult}
        />
      )}
      {showDeleteSocietyModal && (
        <DeleteSocietyModal
          show={showDeleteSocietyModal}
          close={closeDeleteSocietyModal}
          societyId={currentSocietyId}
        />
      )}
      {showSetPremiumModal && (
        <SetPremiumModal
          show={showSetPremiumModal}
          close={closeSetPremiumModal}
          societyId={currentSocietyId}
        />
      )}
      {showSetBasicModal && (
        <SetBasicModal
          show={showSetBasicModal}
          close={closeSetBasicModal}
          societyId={currentSocietyId}
        />
      )}
      <ConfirmationModal
        title="Inaktivera förening"
        description="Är du säker på att du vill inaktivera föreningen?"
        show={showInactivateSocietyModal}
        close={closeInactivateSocietyModal}
        onConfirm={inactivateSociety}
        confirmationButtonLabel="Inaktivera"
      />
    </div>
  )
})
