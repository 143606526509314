import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { adminSearchUserSchema } from '../../../forms/schemas/admin_tools'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { UserModel } from '../../../state/models/user'
import { theme } from '../../../theme/theme'
import { Button, ButtonVariant } from '../../common/Button'
import { HorizontalFormSection } from '../../common/HorizontalFormSection'
import { Icon, IconChoices } from '../../common/Icon'
import { ToastType } from '../../common/Toast/toast-type'
import { SearchBar } from '../../common/SearchBar'

export const BoappaSuperadmin = observer((): JSX.Element | null => {
  const { translate } = useAppTranslation()
  const { adminStore, authenticationStore } = useStores()
  const { setToastNotification } = useToastNotifications()
  const [selectedUser, setSelectedUser] = useState<SnapshotOut<
    typeof UserModel
  > | null>(null)

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(adminSearchUserSchema),
    defaultValues: { query: '' },
  })

  const onSubmit = async (
    data: InferType<typeof adminSearchUserSchema>
  ): Promise<void> => {
    adminStore.clearUsers()
    adminStore.searchUser(data.query)
  }

  const onError = (): void => {
    setToastNotification(
      ToastType.DANGER,
      translate('flashMessage.somethingWentWrongError')
    )
  }

  const onUserClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    user: SnapshotOut<typeof UserModel>
  ): void => {
    e.preventDefault()
    setSelectedUser(user)
  }

  const signInAsUser = async (): Promise<void> => {
    if (selectedUser) {
      const status = await adminStore.loginAsUser(selectedUser._id)
      status && window.location.reload()
    }
  }

  const logOutAsUser = async (): Promise<void> => {
    authenticationStore.logoutAsUser()
    window.location.reload()
  }

  const loadingFetch = adminStore.fetchingUser === 'pending'
  const loadingLoginAsUser = adminStore.loggingInAsUser === 'pending'

  if (!adminStore.isBoappaSuperadmin && !adminStore.isTwilightUser) {
    return null
  }

  return (
    <div className="space-y-4">
      {adminStore.isTwilightUser ? (
        <Button
          className="max-h-12"
          variant={ButtonVariant.PRIMARY}
          label={translate('boappaSuperadmin.logout')}
          onClick={logOutAsUser}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="flex flex-col gap-4">
            <div className="flex items-end gap-1">
              <Controller
                name="query"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SearchBar
                    placeholder={translate('boappaSuperadmin.labels.search')}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <Button
                type="submit"
                className="max-h-12"
                loading={loadingFetch}
                disabled={loadingFetch || !isValid}
                icon={IconChoices.SEARCH}
              />
            </div>
            {adminStore.listUsers.length > 0 && (
              <>
                <p style={theme.textVariants.baseBold}>
                  {translate('boappaSuperadmin.labels.matches')}
                </p>
                <div>
                  {adminStore.listUsers.map((user) => (
                    <button
                      key={user._id}
                      className={`flex max-h-12 w-72 cursor-pointer items-center justify-center
                      rounded-3xl border p-4 shadow-md hover:bg-neutral-96
                      ${selectedUser?._id === user._id ? 'bg-neutral-90' : ''}`}
                      onClick={(e) => onUserClick(e, user)}
                    >
                      {user.fullName}
                    </button>
                  ))}
                </div>
              </>
            )}
          </div>
        </form>
      )}
      {selectedUser && (
        <HorizontalFormSection
          title={translate('boappaSuperadmin.userTitle')}
          icon={IconChoices.PERSON}
          iconPlacement="left"
        >
          <p className="flex gap-1">
            <span style={theme.textVariants.baseBold}>
              {translate('boappaSuperadmin.labels.firstname')}
            </span>
            {selectedUser.name}
          </p>
          <p className="flex gap-1">
            <span style={theme.textVariants.baseBold}>
              {translate('boappaSuperadmin.labels.lastname')}
            </span>
            {selectedUser.surname}
          </p>
          <p className="flex gap-1">
            <span style={theme.textVariants.baseBold}>
              {translate('boappaSuperadmin.labels.verified')}
            </span>
            <Icon
              icon={
                selectedUser.isVerified
                  ? IconChoices.CHECKMARK
                  : IconChoices.CLOSE_CROSS
              }
            />
          </p>
          <p className="flex gap-1">
            <span style={theme.textVariants.baseBold}>
              {translate('boappaSuperadmin.labels.email')}
            </span>
            {selectedUser.email}
          </p>
          <Button
            className="max-h-12"
            variant={ButtonVariant.PRIMARY}
            label={translate('boappaSuperadmin.signin')}
            onClick={signInAsUser}
            loading={loadingLoginAsUser}
          />
        </HorizontalFormSection>
      )}
    </div>
  )
})
