import React from 'react'
import { observer } from 'mobx-react-lite'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { theme } from '../../../../theme/theme'
import { useStores } from '../../../../hooks/useStores'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { Avatar } from '../../../common/Avatar'
import { formatRelative } from '../../../../helpers/date'
import { UserListItem } from '../../../common/UserListItem/UserListItem'

interface ReadByModalProps {
  show: boolean
  close: () => void
  readBy: { userId: string; readTime: string }[]
}

export const ReadByModal = observer(
  ({ show, close, readBy }: ReadByModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { userStore } = useStores()

    return (
      <>
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                <p style={theme.textVariants.h3}>
                  {translate('chatMessageReadBy.modalTitle')}
                </p>
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                {readBy.map((readByItem) => {
                  const user = userStore.users.get(readByItem.userId)
                  if (!user) {
                    return null
                  }
                  return (
                    <UserListItem
                      key={user._id}
                      avatar={<Avatar mediaId={user.avatarId} />}
                      name={user.fullName}
                      text={formatRelative(new Date(readByItem.readTime))}
                    />
                  )
                })}
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
