import { io, Socket } from 'socket.io-client'
import { captureException } from '@sentry/react'
import config from '../config'

export enum SocketEvent {
  RECEIVE_MESSAGE = 'chat:receive_message',
  MESSAGE_READ = 'chat:message_read',
  ROOMS_NEW = 'rooms:new',
  NOTIFICATION_COUNTS = 'notification:counts',
}

const shouldReportToSentry = (error: Error): boolean => {
  const networkErrorMessages = [
    'Network Error',
    'timeout',
    'socket hang up',
    'Network request failed',
    'websocket error',
  ]

  // Ignore errors if the message indicates a network error that is common and usually transient
  if (
    networkErrorMessages.some((errorMessage) =>
      error.message.includes(errorMessage)
    )
  ) {
    return false
  }
  return true
}

class SocketService {
  protected _socket: Socket | null

  constructor() {
    this._socket = null
  }

  public async setSocket(
    token: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onEventHandlers: { event: SocketEvent; func: (data: any) => void }[]
  ): Promise<void> {
    if (token == null || token.length === 0) {
      return
    }

    try {
      const _socket = io(config.BASE_URL, {
        path: '/chat/socket',
        transports: ['websocket'],
        auth: { token },
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
      })

      const connectFn = (): void => {
        onEventHandlers.forEach((eventHandler) => {
          _socket.on(eventHandler.event, eventHandler.func)
        })
        this._socket = _socket
      }
      _socket.on('connect', connectFn)
      _socket.on('connect_error', (error: Error) => {
        // Handle connection error, e.g., attempt to reconnect or log
        if (shouldReportToSentry(error)) {
          captureException(error)
        }
        this._socket = null
      })

      _socket.on('error', (error: Error) => {
        // Handle generic socket errors
        if (shouldReportToSentry(error)) {
          captureException(error)
        }

        this._socket = null
      })
    } catch (error) {
      captureException(error)
    }
  }

  /**
   * Sets the socket for chat comms
   */
  public unsetSocket(): void {
    // no need to set a new socket if we have one already
    if (this._socket == null) {
      return
    }
    this._socket.disconnect()
    this._socket = null
  }

  /**
   * gets the socket for chat comms
   */
  public getSocket(): Socket | null {
    return this._socket
  }
}

export const socket = new SocketService()
