import React, { KeyboardEvent, MouseEvent } from 'react'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../Icon'

interface DropdownOptionProps {
  label: string
  value: string
  onClick?: (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLDivElement>) => void
  close: () => void
  destructive?: boolean
  icon?: IconChoices
  customContent?: React.ReactNode
  disabled?: boolean
  disableOuterPadding?: boolean
}

export const DropdownOption = ({
  label,
  value,
  onClick,
  close,
  destructive,
  icon,
  customContent,
  disabled,
  disableOuterPadding,
}: DropdownOptionProps): JSX.Element => {
  const onClickHelper = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault()
    e.stopPropagation()
    onClick && onClick(e)
    close()
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      onClick && onClick(e)
    }
  }

  const wrapperProps = onClick
    ? {
        onClick: onClickHelper,
        onKeyDown: handleKeyDown,
      }
    : {}

  return customContent ? (
    <>{customContent}</>
  ) : (
    <div
      className={`flex min-h-[40px]
      ${disableOuterPadding ? '' : 'px-4 first:pt-4 last:pb-4'}
      first:hover:rounded-t last:hover:rounded-b`}
    >
      <div
        key={value}
        className={`block flex w-full items-center 
        ${destructive ? 'text-red' : 'text-black-DEFAULT'}
        ${
          disabled
            ? 'pointer-events-none cursor-not-allowed opacity-50'
            : 'cursor-pointer'
        }
      `}
        role="menuitem"
        tabIndex={0}
        {...wrapperProps}
      >
        <div className="flex w-full items-center gap-3 rounded-md p-3 hover:bg-neutral-96">
          {icon && <Icon icon={icon} color={destructive ? 'red' : ''} />}
          <p style={theme.textVariants.baseBold}>{label}</p>
        </div>
      </div>
    </div>
  )
}
