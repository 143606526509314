import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ResourceModel } from '../../../../state/models/resource'
import { MarketplaceItem } from './MarketplaceItem'
import { PostCallout } from '../PostCallout'

interface PostResourcesProps {
  resources?: SnapshotOut<typeof ResourceModel>[]
}

export const PostResources = observer(
  ({ resources }: PostResourcesProps): JSX.Element | null => {
    const { translate } = useAppTranslation()

    if (!resources || resources.length === 0) {
      return null
    }

    // Always 1 resource per post
    const resource = resources[0]

    return resources && resources.length > 0 ? (
      <div className="flex w-full flex-col gap-4">
        <PostCallout text={translate('post.newMarketPlaceItemText')} />
        <MarketplaceItem resource={resource} />
      </div>
    ) : null
  }
)
