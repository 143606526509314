import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Instance, SnapshotOut } from 'mobx-state-tree'
import { Icon, IconChoices } from '../../../common/Icon'
import { PostAvatar } from '../PostAvatar'
import { PostImportant } from '../PostImportant'
import { PostAuthor } from '../PostAuthor'
import { formatDate } from '../../../../helpers/date'
import { TextTooltip } from '../../../common/Tooltip'
import { Button, ButtonVariant } from '../../../common/Button'
import { Dropdown, Option } from '../../../common/Dropdown'
import { useStores } from '../../../../hooks/useStores'
import { UserModel } from '../../../../state/models/user'
import { SocietyModel } from '../../../../state/models/society'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { GroupModel } from '../../../../state/models/group'
import { removeMarkdown, replaceHtml } from '../../../../helpers/string'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { CreateEditPostModal } from '../../CreateEditPostModal'
import { ReportPostModal } from '../../ReportPostModal'
import { ConfirmationModal } from '../../../common/ConfirmationModal'
import { useModal } from '../../../../hooks/useModal'

interface PostHeaderData {
  postId: string
  societyId: string
  accessAdminMember: boolean
  accessBoardMember: boolean
  groupsIds: string[]
  role: string
  createdAt: string
  message: string
  isAlert: boolean
}

interface PostHeaderProps {
  data: PostHeaderData
  author: Instance<typeof UserModel>
  disabled?: boolean
  societyLockedForUser?: boolean
}

export const PostHeader = observer(
  ({
    data,
    author,
    disabled,
    societyLockedForUser,
  }: PostHeaderProps): JSX.Element | null => {
    const { translate } = useAppTranslation()
    const { societyStore, groupStore, userStore, postStore } = useStores()
    const navigate = useNavigate()
    const {
      postId,
      societyId,
      accessAdminMember,
      accessBoardMember,
      groupsIds,
      role,
      createdAt,
      message,
      isAlert,
    } = data
    const { setToastNotification } = useToastNotifications()
    const [referenceElement, setReferenceElement] = useState<Element | null>(
      null
    )
    const {
      show: showEditPostModal,
      open: openEditPostModal,
      close: closeEditPostModal,
    } = useModal({ preventClose: true })
    const {
      show: showConfirmationModal,
      open: openConfirmationModal,
      close: closeConfirmationModal,
    } = useModal()
    const {
      show: showReportPostModal,
      open: openReportPostModal,
      close: closeReportPostModal,
    } = useModal()

    const society = societyStore.societies.get(societyId) as
      | SnapshotOut<typeof SocietyModel>
      | undefined

    const user = userStore.currentUser()

    const userAvatarOnClick = (): void => {
      if (!disabled) {
        navigate(
          reverseUrl('user:society-detail', {
            id: author._id,
            societyId: society?._id,
          })
        )
      }
    }

    const hasRecipients =
      accessAdminMember ||
      accessBoardMember ||
      (groupsIds && groupsIds.length > 0)

    const getRecipientsTitle = (): string | undefined => {
      if (accessAdminMember) {
        return `${translate('post.tooltip.visibility.base')} ${translate(
          'post.tooltip.visibility.admins'
        )}`
      }
      if (accessBoardMember) {
        return `${translate('post.tooltip.visibility.base')} ${translate(
          'post.tooltip.visibility.board'
        )}`
      }
      if (groupsIds && groupsIds.length > 0) {
        return `${translate('post.tooltip.visibility.base')} ${translate(
          'post.tooltip.visibility.groups'
        )}:`
      }

      return undefined
    }

    const getRecipientsText = (): string | undefined => {
      if (groupsIds && groupsIds.length > 0) {
        const _groups = groupsIds
          .map((_groupId) => groupStore.groups.get(_groupId))
          .filter((_group) => _group !== undefined) as unknown as Instance<
          typeof GroupModel
        >[]
        return `${_groups.map((_group) => _group.name).join('\n')}`
      }
      return undefined
    }

    const onCopyText = (): void => {
      navigator.clipboard.writeText(removeMarkdown(replaceHtml(message)))
      setToastNotification(
        ToastType.SUCCESS,
        translate('common.copiedToClipboard')
      )
    }

    const isAdmin = user && isUserAdminInSociety(society, user._id)

    const getDropdownOptions = (): Option[] => {
      const currentUserIsAuthor = user ? author._id === user._id : false
      const currentUserIsAdmin = user ? isAdmin : false

      const userCanDelete = currentUserIsAuthor || currentUserIsAdmin

      const options: Option[] = [
        {
          value: 'copy',
          label: translate('common.actions.copyText'),
          onClick: onCopyText,
          icon: IconChoices.COPY,
        },
        ...(currentUserIsAuthor && !disabled
          ? [
              {
                value: 'edit',
                label: translate('common.actions.edit'),
                onClick: openEditPostModal,
                icon: IconChoices.EDIT,
                disabled: societyLockedForUser,
              },
            ]
          : []),
        ...(!currentUserIsAuthor && !disabled
          ? [
              {
                value: 'report',
                label: translate('common.actions.report'),
                onClick: openReportPostModal,
                icon: IconChoices.REPORT,
              },
            ]
          : []),
        ...(userCanDelete && !disabled
          ? [
              {
                value: 'delete',
                label: translate('common.actions.delete'),
                onClick: openConfirmationModal,
                icon: IconChoices.DELETE_TRASH,
                destructive: true,
              },
            ]
          : []),
      ]
      return options
    }

    const deletePost = async (): Promise<void> => {
      const status = await postStore.remove(postId)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('post.flashMessage.deletePostSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('post.flashMessage.deletePostFailure')
        )
      }
    }

    const dropdownOptions = getDropdownOptions()

    return (
      <>
        {isAlert && <PostImportant />}
        <div className="flex justify-between">
          <div className="flex min-w-0 grow gap-2">
            {society && (
              <PostAvatar
                role={role}
                author={author}
                society={society}
                userHasMultipleSocieties={
                  societyStore.sortedSocieties.length > 1
                }
                onClick={userAvatarOnClick}
              />
            )}
            <div className="flex min-w-0 flex-col gap-1">
              <div className="flex">
                <PostAuthor role={role} author={author} />
              </div>
              <p className="text-xs">{formatDate(Date.parse(createdAt))}</p>
            </div>
          </div>
          {dropdownOptions.length > 0 && (
            <div className="flex items-center gap-3">
              {hasRecipients && (
                <TextTooltip
                  title={getRecipientsTitle()}
                  text={getRecipientsText()}
                  referenceElement={referenceElement}
                >
                  <Icon icon={IconChoices.GROUPS} ref={setReferenceElement} />
                </TextTooltip>
              )}
              <Dropdown
                options={dropdownOptions}
                dropdownContentWidth="w-64"
                disabled={disabled}
              >
                <Button
                  variant={ButtonVariant.TEXT}
                  className="h-10 w-10 border-none bg-transparent hover:bg-neutral-90"
                  icon={IconChoices.MORE}
                  disableTabIndex
                />
              </Dropdown>
            </div>
          )}
        </div>
        <CreateEditPostModal
          show={showEditPostModal}
          close={closeEditPostModal}
          postId={postId}
        />
        <ReportPostModal
          show={showReportPostModal}
          close={closeReportPostModal}
          postId={postId}
        />
        <ConfirmationModal
          title={translate('post.alert.postDeleteTitle')}
          description={translate('post.alert.postDeleteText')}
          show={showConfirmationModal}
          close={closeConfirmationModal}
          onConfirm={deletePost}
          deleteMode
        />
      </>
    )
  }
)
