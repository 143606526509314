/* eslint-disable max-len */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { v4 as uuid } from 'uuid'
import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../../../hooks/useStores'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { useModal } from '../../../../../hooks/useModal'
import { isUserAdminInSociety } from '../../../../../helpers/society'
import { useCurrentSociety } from '../../../../../hooks/useCurrentSociety'
import { useAuthenticatedUserId } from '../../../../../hooks/useAuthenticatedUserId'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../common/Toast/toast-type'
import { ConfirmationModal } from '../../../../common/ConfirmationModal'
import { AccordionSection } from '../../../../common/AccordionSection'
import { Option } from '../../../../common/Dropdown'
import { SocietyWebsiteQuestionsAndAnswersItemContent } from './SocietyWebsiteQuestionsAndAnswersItem/SocietyWebsiteQuestionsAndAnswersItemContent'
import { CreateUpdateSocietyWebsiteFAQItemModal } from './SocietyWebsiteQuestionsAndAnswersItem/CreateUpdateSocietyWebsiteFAQItemModal'
import { CreateUpdateSocietyWebsiteFAQSectionModal } from './CreateUpdateSocietyWebsiteFAQSectionModal'
import { SocietyWebsiteModel } from '../../../../../state/models/society-website'

interface SocietyWebsiteQuestionsAndAnswersSectionProps {
  title: string
  id: string
  website: SnapshotOut<typeof SocietyWebsiteModel>
}

export const SocietyWebsiteQuestionsAndAnswersSection = observer(
  ({
    title,
    id,
    website,
  }: SocietyWebsiteQuestionsAndAnswersSectionProps): JSX.Element => {
    const { societyWebsitesStore } = useStores()
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()
    const {
      show: showCreateQuestionModal,
      open: openCreateQuestionModal,
      close: closeCreateQuestionModal,
    } = useModal()
    const {
      show: showEditSectionModal,
      open: openEditSectionModal,
      close: closeEditSectionModal,
    } = useModal()
    const {
      show: showRemoveSectionConfirmationModal,
      open: openRemoveSectionConfirmationModal,
      close: closeRemoveSectionConfirmationModal,
    } = useModal()
    const authUserId = useAuthenticatedUserId()

    const isAdmin = isUserAdminInSociety(society, authUserId as string)

    const questions = societyWebsitesStore.websiteBrokerQuestionSection(
      society._id,
      id
    )?.questions

    const accordionQuestions =
      questions?.map((question) => ({
        ...question,
        content: question.answer,
        title: question.question,
      })) || []

    const removeSection = async (): Promise<void> => {
      const status = await societyWebsitesStore.patchWebsite(website._id, {
        brokerQuestion: {
          ...website.brokerQuestion,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          sections: website.brokerQuestion?.sections?.filter(
            (section) => section._id !== id
          ),
        },
      })
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate(
            'createUpdateQuestionsAndAnswers.flashMessage.deleteSectionSuccess'
          )
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate(
            'createUpdateQuestionsAndAnswers.flashMessage.deleteSectionFailure'
          )
        )
      }
    }

    const dropdownOptions: Option[] | undefined = isAdmin
      ? [
          {
            value: 'create',
            label: translate(
              'createUpdateQuestionsAndAnswers.addNewQuestionTitle'
            ),
            onClick: () => openCreateQuestionModal(),
          },
          {
            value: 'edit',
            label: translate('common.actions.edit'),
            onClick: () => openEditSectionModal(),
          },
          {
            value: 'remove',
            label: translate('common.actions.delete'),
            onClick: () => openRemoveSectionConfirmationModal(),
            destructive: true,
          },
        ]
      : undefined

    return (
      <div className="mb-10 w-full space-y-10">
        <AccordionSection
          title={title}
          items={accordionQuestions.map((_question) => ({
            id: uuid(),
            title: `${_question.title}`,
            content: (
              <SocietyWebsiteQuestionsAndAnswersItemContent
                website={website}
                sectionId={id}
                questionId={_question._id}
                content={_question.content}
              />
            ),
          }))}
          dropdownOptions={dropdownOptions}
          itemsEmptyText={translate(
            'questionsAndAnswersView.emptyState.noQuestionsAndAnswersText'
          )}
        />
        <CreateUpdateSocietyWebsiteFAQItemModal
          website={website}
          sectionId={id}
          show={showCreateQuestionModal}
          close={closeCreateQuestionModal}
        />
        <CreateUpdateSocietyWebsiteFAQSectionModal
          website={website}
          sectionId={id}
          show={showEditSectionModal}
          close={closeEditSectionModal}
        />
        <ConfirmationModal
          title={translate(
            'createUpdateQuestionsAndAnswers.removeSectionConfirmationModal.title'
          )}
          description={translate(
            'createUpdateQuestionsAndAnswers.removeSectionConfirmationModal.description'
          )}
          show={showRemoveSectionConfirmationModal}
          close={closeRemoveSectionConfirmationModal}
          onConfirm={removeSection}
          deleteMode
        />
      </div>
    )
  }
)
