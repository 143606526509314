import React, { useCallback } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Controller,
  FieldError,
  useFieldArray,
  useForm,
  UseFormSetValue,
} from 'react-hook-form'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { observer } from 'mobx-react-lite'
import { societyPollCreateSchema } from '../../../../forms/schemas/society_poll_create'
import { useStores } from '../../../../hooks/useStores'
import { NPolls } from '../../../../interfaces/services/polls.interfaces'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ToastType } from '../../../common/Toast/toast-type'
import { societyCreatePostSchema } from '../../../../forms/schemas/society_create_post'
import { FormControl } from '../../../common/FormControl'
import { TextInput } from '../../../common/TextInput'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { SelectDropdown } from '../../../common/SelectDropdown'
import { Button, ButtonVariant } from '../../../common/Button'
import { theme } from '../../../../theme/theme'
import { IconChoices } from '../../../common/Icon'

interface PollEditorProps {
  pollId?: string
  setPostValue: UseFormSetValue<InferType<typeof societyCreatePostSchema>>
  setDisplayPoll: React.Dispatch<React.SetStateAction<boolean>>
  watchSociety: string
  watchPollIds: (string | undefined)[] | undefined
}

export const PollEditor = observer(
  ({
    pollId,
    setPostValue,
    setDisplayPoll,
    watchSociety,
    watchPollIds,
  }: PollEditorProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { pollStore, authenticationStore } = useStores()
    const { getErrorMessage } = useFormErrorMessage()
    const { setToastNotification } = useToastNotifications()

    const poll = pollId ? pollStore.polls.get(pollId) : undefined
    const updateMode = pollId !== undefined

    const showErrorMessage = (): void => {
      const message = updateMode
        ? 'pollEditor.flashMessage.updatePollFailure'
        : 'pollEditor.flashMessage.createPollFailure'
      setToastNotification(ToastType.DANGER, translate(message))
    }

    const getDefaultValues = useCallback((): InferType<
      typeof societyPollCreateSchema
    > => {
      return {
        title: updateMode ? poll?.title || '' : '',
        options: updateMode
          ? poll?.options || [{ text: '' }, { text: '' }]
          : [{ text: '' }, { text: '' }],
        societyId: updateMode ? poll?.societyId || watchSociety : watchSociety,
        userId: updateMode
          ? poll?.userId || (authenticationStore.userId as string)
          : (authenticationStore.userId as string),
        type: updateMode
          ? (poll?.type as 'per-user' | undefined) || 'per-user'
          : 'per-user',
      }
    }, [poll, authenticationStore.userId, watchSociety, updateMode])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyPollCreateSchema),
      defaultValues: getDefaultValues(),
    })

    const { fields, append, remove } = useFieldArray({
      name: 'options',
      control,
    })

    const onSubmit = async (
      data: InferType<typeof societyPollCreateSchema>
    ): Promise<void> => {
      const _poll = updateMode
        ? await pollStore.patchPoll(
            poll?._id as string,
            data as NPolls.NPatch.IRequestBody
          )
        : await pollStore.createPoll(data as NPolls.NCreate.IRequestBody)

      if (_poll) {
        if (!watchPollIds?.includes(_poll._id)) {
          setPostValue('pollIds', [...(watchPollIds || []), _poll._id], {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
        setDisplayPoll(false)
      } else {
        showErrorMessage()
      }
    }

    const typeOptions = [
      {
        label: translate('pollEditor.label.perUser'),
        value: 'per-user',
      },
      {
        label: translate('pollEditor.label.perHousehold'),
        value: 'per-unit',
      },
    ]

    const onError = (error: unknown): void => {
      captureException(error)
      showErrorMessage()
    }

    const onClose = (): void => {
      setDisplayPoll(false)
    }

    const addAlternative = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void => {
      e.preventDefault()
      append({ text: '' }, { shouldFocus: true })
    }

    const loading =
      pollStore.updatingPoll === 'pending' ||
      pollStore.creatingPoll === 'pending'

    return (
      <div className="flex flex-col gap-4 rounded border border-neutral-80 p-6">
        <div className="flex gap-8">
          <Controller
            control={control}
            name="title"
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                className="flex flex-1"
                label={translate('pollEditor.form.labels.title')}
                error={errors.title && getErrorMessage(errors.title)}
                name={name}
              >
                <TextInput
                  placeholder="Aa"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={!!errors.title}
                />
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="type"
            render={({ field: { onChange, value, name } }) => (
              <FormControl
                className="flex flex-1"
                label={translate('pollEditor.form.labels.type')}
                name={name}
                error={errors.type && getErrorMessage(errors.type)}
              >
                <SelectDropdown
                  value={value}
                  onChange={onChange}
                  options={typeOptions}
                  error={!!errors.type}
                  height={49} // TODO: Align with textinput
                />
              </FormControl>
            )}
          />
        </div>
        <div>
          <p className="mb-1" style={theme.textVariants.caption}>
            {translate('pollEditor.form.headers.options')}
          </p>
          <div className="flex flex-col gap-4">
            {fields.map((field, index) => (
              <Controller
                control={control}
                key={field.id}
                name={`options.${index}.text`}
                render={({ field: { name, onChange, onBlur, value } }) => (
                  <FormControl
                    name={name}
                    error={
                      errors.options?.[index]?.text &&
                      getErrorMessage(
                        errors.options?.[index]?.text as FieldError
                      )
                    }
                  >
                    <TextInput
                      placeholder={`${translate(
                        'pollEditor.form.placeholders.option'
                      )} ${index + 1}`}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      onDeleteIconPress={
                        index > 1 ? () => remove(index) : undefined
                      }
                    />
                  </FormControl>
                )}
              />
            ))}
          </div>
        </div>
        <Button
          className="flex w-fit"
          onClick={addAlternative}
          icon={IconChoices.PLUS_SIGN}
          label={translate('pollEditor.form.buttons.addMoreOptionsButtonTitle')}
        />
        <div className="mt-4 flex flex-shrink-0 flex-wrap items-center justify-end gap-3">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
            variant={ButtonVariant.TEXT}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            loading={loading}
            disabled={!isValid || loading}
            label={translate('common.actions.save')}
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </div>
    )
  }
)
