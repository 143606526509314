import React from 'react'
import { Spinner } from '../Spinner'

interface SpinnerWrapperProps {
  color?: string
  className?: string
  wrapperClassName?: string
}

export const SpinnerWrapper = ({
  color = 'brandGreen',
  className,
  wrapperClassName,
}: SpinnerWrapperProps): JSX.Element => {
  return (
    <div
      className={`flex h-full w-full justify-center ${wrapperClassName ?? ''}`}
    >
      <div
        className={`flex h-14 w-14 items-center justify-center rounded-full bg-neutral-94 shadow-lg
        ${className ?? ''}
      `}
      >
        <Spinner color={color} />
      </div>
    </div>
  )
}
