import { isAfter, isBefore } from 'date-fns'

/**
 * General sorting function that sorts objects based on strings
 */
export const sortStringsAlphabetically = (
  strA = '',
  strB = '',
  dontLocaleCompare = false
): number => {
  const parsedA = strA.toLowerCase()
  const parsedB = strB.toLowerCase()

  if (!dontLocaleCompare) {
    if (parsedA.localeCompare != null) {
      return parsedA.localeCompare(parsedB, 'sv', {
        sensitivity: 'base',
        numeric: true,
      })
    }
  }

  if (parsedA === parsedB) {
    return 0
  }

  return parsedA < parsedB ? -1 : 1
}

/**
 * General sorting function that sorts objects based on date strings
 */
export const sortByDate = (
  dateStringA: string | Date,
  dateStringB: string | Date,
  desc = false
): number => {
  const dateA = new Date(dateStringA)
  const dateB = new Date(dateStringB)

  if (isBefore(dateA, dateB)) {
    return desc ? 1 : -1
  }

  if (isAfter(dateA, dateB)) {
    return desc ? -1 : 1
  }

  return 0
}

export const sortAdresses = (adressA = '', adressB = ''): number => {
  const adressASplit = adressA.split(' ')
  const adressBSplit = adressB.split(' ')

  if (adressASplit.length === 0 || adressBSplit.length === 0) {
    return 0
  }

  const alphabeticalOrder = sortStringsAlphabetically(
    adressASplit[0],
    adressBSplit[0]
  )
  if (alphabeticalOrder === 0) {
    try {
      const numA = adressASplit[adressASplit.length - 1]
      const numB = adressBSplit[adressBSplit.length - 1]
      return parseInt(numA, 10) - parseInt(numB, 10)
    } catch (error) {
      return sortStringsAlphabetically(adressA, adressB)
    }
  }
  return alphabeticalOrder
}
