import * as yup from 'yup'

export const societyReportSettingsSchema = yup
  .object({
    recipient: yup.string().email().required(),
    recipientsCC: yup.array().of(
      yup.object({
        email: yup.string().email().required(),
      })
    ),
  })
  .required()
