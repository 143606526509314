import React, { useCallback } from 'react'
import Fuse from 'fuse.js'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useStores } from '../../../../hooks/useStores'
import { Section, SectionList } from '../../../common/SectionList'
import { getListSectionSortedAndGroupedUsers } from '../../../../helpers/user'
import { ResidentListItem } from '../../ResidentList/ResidentListItem'
import { UserModel } from '../../../../state/models/user'
import { SocietyModel } from '../../../../state/models/society'

export type MemberListItem = { userId: string }

interface ResidentListBaseProps {
  membersList: MemberListItem[]
  setMembersListValue: (val: MemberListItem[]) => void
}

interface SectionResidentListItemProps extends ResidentListBaseProps {
  user: SnapshotOut<typeof UserModel>
  society: SnapshotOut<typeof SocietyModel>
  selected: boolean
}

export const SectionResidentListItem = observer(
  ({
    user,
    society,
    membersList,
    setMembersListValue,
    selected,
  }: SectionResidentListItemProps): JSX.Element => {
    return (
      <ResidentListItem
        key={user._id}
        user={user}
        society={society}
        selectMode
        selected={selected}
        onSelectedChange={(val) => {
          if (val) {
            setMembersListValue([...membersList, { userId: user._id }])
          } else {
            setMembersListValue(
              membersList.filter((_member) => _member.userId !== user._id)
            )
          }
        }}
      />
    )
  }
)

interface ResidentListSectionProps extends ResidentListBaseProps {
  searchString: string
}

export const ResidentListSection = observer(
  ({
    membersList,
    setMembersListValue,
    searchString,
  }: ResidentListSectionProps): JSX.Element => {
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { unitStore } = useStores()

    const selectedMemberIdsMap = membersList?.reduce(
      (res: { [key: string]: boolean }, curr): { [key: string]: boolean } => {
        if (!(curr.userId in res)) {
          res[curr.userId] = true
        }
        return res
      },
      {}
    )

    const residentUsers = unitStore.residentUsersForSocietyWithUnits(
      society._id
    )

    const getFilteredUsers = useCallback(() => {
      const options = {
        threshold: 0.2,
        keys: [
          'user.fullName',
          'user.name',
          'user.surname',
          'units.title',
          'units.titleLegal',
        ],
      }

      const fuse = new Fuse(residentUsers, options)

      const filteredUsers =
        searchString === ''
          ? residentUsers
          : fuse.search(searchString).map((result) => result.item)

      return filteredUsers.map((_user) => _user.user)
    }, [residentUsers, searchString])

    const users = getFilteredUsers()
    const usersList = getListSectionSortedAndGroupedUsers(users)

    const getSections = (): Section[] => {
      return usersList.map((section) => ({
        title: section.title,
        item: (
          <div className="space-y-4">
            {section.data.map((_user) => {
              return (
                <SectionResidentListItem
                  key={_user._id}
                  user={_user}
                  society={society}
                  selected={
                    selectedMemberIdsMap && _user._id in selectedMemberIdsMap
                  }
                  setMembersListValue={setMembersListValue}
                  membersList={membersList}
                />
              )
            })}
          </div>
        ),
      }))
    }

    return <SectionList sections={getSections()} />
  }
)
