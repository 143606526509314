import * as yup from 'yup'
import i18next from '../../i18n/i18n'

const minNumberOfBillingUnits = 3

export const societySchema = yup
  .object({
    organisationNumber: yup.string().when('societyTypeDisplayName', {
      is: (type: string) => type !== 'neighbourhood',
      then: (schema) =>
        schema
          .required()
          .matches(
            /^\d{6}-?\d{4}$/,
            `${i18next.t('form.errors.organisationNumber')}`
          ),
    }),
    companyOrganisationNumber: yup.string().when('societyTypeDisplayName', {
      is: 'housing_cooperative_construction',
      then: (schema) =>
        schema
          .required()
          .matches(
            /^\d{6}-?\d{4}$/,
            `${i18next.t('form.errors.organisationNumber')}`
          ),
    }),
    status: yup.string().required(),
    name: yup.string().required(),
    companyName: yup.string().when('societyTypeDisplayName', {
      is: 'housing_cooperative_construction',
      then: (schema) => schema.required(),
    }),
    addressCountryCode: yup.string().required(),
    addressCity: yup.string().required(),
    addressZip: yup.string().required(),
    addressStreet: yup.string().required(),
    billingEmail: yup.string(),
    billingUnits: yup.number().when('societyTypeDisplayName', {
      is: (type: string) => type !== 'neighbourhood',
      then: (schema) =>
        schema.required().min(
          3,
          i18next.t('form.errors.minNumber', {
            minNumber: minNumberOfBillingUnits,
          })
        ),
    }),
    societyTypeDisplayName: yup.string().required(),
    typeOfSociety: yup.string().required(),
    masterAdminUserId: yup.string().required(),
  })
  .required()
