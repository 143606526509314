/* eslint-disable max-len */
import React from 'react'
import { RouteObject } from 'react-router-dom'
import { SocietyHome } from '../../../views/society/SocietyHome'
import { ResidentListView } from '../../../views/society/Resident/ResidentListView'
import { MarketplaceListView } from '../../../views/society/Marketplace/MarketplaceListView'
import { BoardListView } from '../../../views/society/Resident/BoardListView'
import { ContactsView } from '../../../views/society/Resident/ContactsView'
import { FindFriendsView } from '../../../views/society/Resident/FindFriendsView'
import { QuestionsAndAnswersView } from '../../../views/society/Resident/QuestionsAndAnswersView'
import { HouseRulesView } from '../../../views/society/Resident/HouseRulesView'
import { CalendarView } from '../../../views/society/Resident/CalendarView'
import { FacilitiesView } from '../../../views/society/Resident/FacilitiesView'
import { useSocietyManagementRoutes } from './useSocietyManagementRoutes'
import { SocietyManagementRoot } from '../../../views/society/SocietyManagementRoot'
import { DocumentsView } from '../../../views/society/Resident/DocumentsView'
import { WidgetView } from '../../../views/society/Resident/WidgetView'
import { LocalOffersView } from '../../../views/society/Resident/LocalOffersView'
import { LocalOfferView } from '../../../views/society/Resident/LocalOfferView'
import { ReportProblemView } from '../../../views/society/Resident/ReportProblemView'
import { OnboardingView } from '../../../views/society/Onboarding/OnboardingView'
import { CreateUpdateFacilityView } from '../../../views/society/Resident/CreateUpdateFacilityView'
import { FacilityView } from '../../../views/society/Resident/FacilityView'
import { MarketplaceDetailView } from '../../../views/society/Marketplace/MarketplaceDetailView'
import { SocietyRoot } from '../../../views/society/SocietyRoot'
import { EventDetailView } from '../../../views/society/EventDetailView'
import { ServicesView, ServicesViewContainer } from '../../../views/services'
import { ProviderServices } from '../../../views/services/providerServices'
import { Products } from '../../../views/services/products'

const useSocietyRoutes = (): RouteObject[] => {
  const societyManagementRoutes = useSocietyManagementRoutes()

  return [
    {
      path: ':id',
      element: <SocietyRoot />,
      children: [
        {
          path: 'home',
          element: <SocietyHome />,
        },
        {
          path: 'onboarding',
          element: <OnboardingView />,
        },
        {
          path: 'management',
          element: <SocietyManagementRoot />,
          children: societyManagementRoutes,
        },
        {
          path: 'resident/neighborhood/marketplace',
          element: <MarketplaceListView />,
        },
        {
          path: 'resident/neighborhood/:marketplace/:resourceId',
          element: <MarketplaceDetailView />,
        },
        {
          path: 'resident/neighborhood/find-friends',
          element: <FindFriendsView />,
        },
        {
          path: 'resident/functions/resident-list',
          element: <ResidentListView />,
        },
        {
          path: 'resident/functions/board',
          element: <BoardListView />,
        },
        {
          path: 'resident/functions/contacts',
          element: <ContactsView />,
        },
        {
          path: 'resident/functions/calendar',
          element: <CalendarView />,
        },
        {
          path: 'resident/functions/calendar/:eventId/*',
          element: <EventDetailView />,
        },
        {
          path: 'resident/functions/documents',
          element: <DocumentsView />,
        },
        {
          path: 'resident/functions/documents/:documentId',
          element: <DocumentsView />,
        },
        {
          path: 'resident/functions/facilities',
          element: <FacilitiesView />,
        },
        {
          path: 'resident/functions/facilities/create',
          element: <CreateUpdateFacilityView />,
        },
        {
          path: 'resident/functions/facilities/:facilityId/update',
          element: <CreateUpdateFacilityView />,
        },
        {
          path: 'resident/functions/facilities/:facilityId/*',
          element: <FacilityView />,
        },
        {
          path: 'resident/functions/questions-and-answers',
          element: <QuestionsAndAnswersView />,
        },
        {
          path: 'resident/functions/house-rules',
          element: <HouseRulesView />,
        },
        {
          path: 'resident/functions/local-offers',
          element: <LocalOffersView />,
        },
        {
          path: 'resident/functions/local-offers/:offerId',
          element: <LocalOfferView />,
        },
        {
          path: 'resident/functions/services',
          element: <ServicesViewContainer />,
          children: [
            {
              element: <ServicesView />,
              index: true,
            },

            {
              path: ':service',
              children: [
                {
                  element: <ProviderServices />,
                  index: true,
                },
                {
                  path: ':product',
                  element: <Products />,
                },
              ],
            },
          ],
        },

        {
          path: 'resident/functions/report-problem',
          element: <ReportProblemView />,
        },
        {
          path: 'resident/functions/widgets/:widgetId',
          element: <WidgetView />,
        },
      ],
    },
  ]
}

useSocietyRoutes.displayName = 'useSocietyRoutes'
export { useSocietyRoutes }
