import { ImageUploadActions } from '../actions/image-upload'
import { ImageUploadState } from '../types/image-upload'

export const imageUploadReducer = (
  state: ImageUploadState,
  action: ImageUploadActions
): ImageUploadState => {
  switch (action.type) {
    case 'add':
      return { images: [...state.images, { ...action.image, uploading: true }] }
    case 'remove':
      return {
        images: state.images.filter((_image) => _image.id !== action.id),
      }
    case 'setUploaded':
      return {
        images: [
          ...state.images.map((_image) =>
            _image.uri === action.image.uri
              ? { ..._image, id: action.mediaId, uploading: false }
              : _image
          ),
        ],
      }
    case 'setDescription':
      return {
        images: [
          ...state.images.map((_image) =>
            _image.id === action.id
              ? { ..._image, description: action.description }
              : _image
          ),
        ],
      }
    case 'reset':
      return {
        images: [],
      }
    default:
      throw new Error()
  }
}
