import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../../../../hooks/useStores'
import { useToastNotifications } from '../../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../../../components/common/Toast/toast-type'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { theme } from '../../../../../../theme/theme'
import {
  Button,
  ButtonVariant,
} from '../../../../../../components/common/Button'
import { ConfirmationModal } from '../../../../../../components/common/ConfirmationModal'
import { useModal } from '../../../../../../hooks/useModal'

interface InactivateStageProps {
  societyId?: string
  close: () => void
  goToDeletePage: () => void
}

export const InactivateStage = observer(
  ({ societyId, close, goToDeletePage }: InactivateStageProps): JSX.Element => {
    const { adminStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()
    const {
      show: showInactivateSocietyModal,
      open: openInactivateSocietyModal,
      close: closeInactivateSocietyModal,
    } = useModal()

    const inactivateSociety = async (): Promise<void> => {
      const status =
        societyId &&
        (await adminStore.inactivateSociety({
          societyId,
        }))
      if (status) {
        setToastNotification(ToastType.SUCCESS, 'Förening inaktiverad')
        goToDeletePage()
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }
    }

    const loading = adminStore.inactivatingSociety === 'pending'

    return (
      <>
        <div className="flex h-full flex-col gap-6">
          <p style={theme.textVariants.base}>
            Innan föreningen kan tas bort måste den inaktiveras. Tänk igenom om
            denna förening måste tas bort eller om det räcker med att enbart
            inaktivera den.
          </p>
          <div className="flex flex-wrap justify-end gap-4">
            <Button label="Avbryt" onClick={close} />
            <Button
              variant={ButtonVariant.DANGER}
              label="Inaktivera"
              onClick={openInactivateSocietyModal}
              disabled={loading}
              loading={loading}
            />
          </div>
        </div>
        <ConfirmationModal
          title="Inaktivera förening"
          description="Är du säker på att du vill inaktivera föreningen?"
          show={showInactivateSocietyModal}
          close={closeInactivateSocietyModal}
          onConfirm={inactivateSociety}
          confirmationButtonLabel="Inaktivera"
        />
      </>
    )
  }
)
