import React, { useRef } from 'react'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../../common/Icon'
import { Modal } from '../../common/Modal'
import { ModalBody } from '../../common/Modal/ModalBody'
import { ModalHeader } from '../../common/Modal/ModalHeader'
import { SeenByModalContent } from './SeenByModalContent'

interface SeenByModalProps {
  seenBy: { userId: string }[]
  societyId: string
  show: boolean
  close: () => void
}

export const SeenByModal = ({
  seenBy,
  societyId,
  show,
  close,
}: SeenByModalProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const ref = useRef(null)
  return (
    <Modal ref={ref} show={show} size="sm">
      {{
        header: (
          <ModalHeader onClose={close}>
            <div className="flex gap-3">
              <Icon icon={IconChoices.OPEN_EYE} />
              <p style={theme.textVariants.lead}>
                {translate('post.seenBy')} {seenBy.length}
              </p>
            </div>
          </ModalHeader>
        ),
        body: (
          <ModalBody className="max-h-96 overflow-y-auto">
            <SeenByModalContent seenBy={seenBy} societyId={societyId} />
          </ModalBody>
        ),
      }}
    </Modal>
  )
}
