import { SnapshotOut } from 'mobx-state-tree'
import {
  isUserAdminInSociety,
  isUserBoardMemberInSociety,
} from '../../helpers/society'
import { useStores } from '../../hooks/useStores'
import { SocietyModel } from '../../state/models/society'

interface useUserFeedSocietiesReturnType {
  societies: SnapshotOut<typeof SocietyModel>[]
}

interface useUserFeedSocietiesProps {
  userId: string
}

export const useUserFeedSocieties = ({
  userId,
}: useUserFeedSocietiesProps): useUserFeedSocietiesReturnType => {
  const { societyStore } = useStores()

  const societies = societyStore.sortedSocieties.filter(
    (_society) =>
      _society.residentPostingDisabledByAdmin === false ||
      isUserBoardMemberInSociety(_society, userId as string) ||
      isUserAdminInSociety(_society, userId as string)
  )

  return {
    societies,
  }
}
