import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { TextInput } from '../../../common/TextInput'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import { societyCreateGroupSchema } from '../../../../forms/schemas/society_group_create'
import { GroupModel } from '../../../../state/models/group'
import { SelectDropdown, SelectOption } from '../../../common/SelectDropdown'
import { FormSpacing } from '../../../common/FormSpacing'
import { SearchBar } from '../../../common/SearchBar'
import { ResidentListSection } from './ResidentListSection'
import { UnitListSection } from './UnitListSection'
import { useModal } from '../../../../hooks/useModal'
import { ConfirmationModal } from '../../../common/ConfirmationModal'

interface CreateUpdateRegisterGroupFormProps {
  onError: () => void
  onSubmit: (data: InferType<typeof societyCreateGroupSchema>) => Promise<void>
  onClose: () => void
  loading: boolean
  group?: SnapshotOut<typeof GroupModel>
}

export const CreateUpdateRegisterGroupForm = observer(
  ({
    group,
    loading,
    onError,
    onSubmit,
    onClose,
  }: CreateUpdateRegisterGroupFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const [searchString, setSearchString] = useState('')
    const { getErrorMessage } = useFormErrorMessage()
    const {
      show: showChangeTypeConfirmationModal,
      open: openChangeTypeConfirmationModal,
      close: closeChangeTypeConfirmationModal,
    } = useModal()

    const onChangeSearchString = (
      event: React.FormEvent<HTMLInputElement>
    ): void => {
      setSearchString(event.currentTarget.value)
    }

    const updateMode = !!group

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isSamf } = society

    const getDefaultValues = useCallback((): InferType<
      typeof societyCreateGroupSchema
    > => {
      const getType = (): 'user' | 'unit' => {
        if (!group) {
          return 'user'
        }
        if (group.membersList && group.membersList.length > 0) {
          return 'user'
        }
        if (group.unitsList && group.unitsList.length > 0) {
          return 'unit'
        }
        return 'user'
      }

      return {
        name: updateMode ? group?.name || '' : '',
        societyId: updateMode ? group?.societyId || society._id : society._id,
        membersList: updateMode ? group?.membersList || [] : [],
        unitsList: updateMode ? group?.unitsList || [] : [],
        type: getType(),
      }
    }, [society, group, updateMode])

    const typeOptions = (): SelectOption[] => [
      {
        value: 'user',
        label: `${translate('registerGroup.form.options.type.user')}`,
      },
      {
        value: 'unit',
        label: `${translate(
          isSamf
            ? 'registerGroup.form.options.type.unitSamf'
            : 'registerGroup.form.options.type.unit'
        )}`,
      },
    ]

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
      watch,
      setValue,
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyCreateGroupSchema),
      defaultValues: getDefaultValues(),
    })

    const watchType = watch('type')
    const watchMembersList = watch('membersList')
    const watchUnitsList = watch('unitsList')

    const changeGroupType = (): void => {
      const currentType = watchType
      if (currentType === 'unit') {
        setValue('unitsList', [])
      } else {
        setValue('membersList', [])
      }
      setValue('type', currentType === 'user' ? 'unit' : 'user')
    }

    return (
      <>
        <FormSpacing>
          <div className="flex flex-row gap-3">
            <Controller
              control={control}
              name="name"
              render={({ field: { value, name, onChange, onBlur } }) => (
                <FormControl
                  className="flex-1"
                  label={translate('registerGroup.form.labels.name')}
                  error={errors.name && getErrorMessage(errors.name)}
                  name={name}
                >
                  <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.name}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="type"
              render={({ field: { value, name, onChange } }) => (
                <FormControl
                  className="flex-1"
                  label={translate('common.form.labels.type')}
                  error={errors.type && getErrorMessage(errors.type)}
                  name={name}
                >
                  <SelectDropdown
                    options={typeOptions()}
                    value={value}
                    onChange={(val) => {
                      if (val !== watchType) {
                        if (
                          (watchType === 'user' &&
                            watchMembersList &&
                            watchMembersList.length > 0) ||
                          (watchType === 'unit' &&
                            watchUnitsList &&
                            watchUnitsList.length > 0)
                        ) {
                          openChangeTypeConfirmationModal()
                        } else {
                          onChange(val)
                        }
                      }
                    }}
                    error={!!errors.type}
                  />
                </FormControl>
              )}
            />
          </div>

          {watchType === 'user' ? (
            <>
              <SearchBar
                className="mt-4 w-full"
                widthClassName="w-full"
                value={searchString}
                onChange={onChangeSearchString}
              />
              <ResidentListSection
                membersList={watchMembersList || []}
                searchString={searchString}
                setMembersListValue={(val) =>
                  setValue('membersList', val, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                }
              />
            </>
          ) : (
            <UnitListSection
              unitsList={watchUnitsList || []}
              setUnitsListValue={(val) =>
                setValue('unitsList', val, {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }
            />
          )}

          <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-4">
            <Button
              label={translate('common.actions.cancel')}
              onClick={onClose}
            />
            <Button
              disabled={!isValid || loading}
              variant={ButtonVariant.PRIMARY}
              label={
                updateMode
                  ? translate('common.actions.save')
                  : translate('common.actions.create')
              }
              type="submit"
              onClick={handleSubmit(onSubmit, onError)}
            />
          </div>
        </FormSpacing>
        <ConfirmationModal
          title={translate('registerGroup.confirmationDialog.changeTypeTitle')}
          description={translate(
            'registerGroup.confirmationDialog.changeTypeDescription'
          )}
          show={showChangeTypeConfirmationModal}
          close={closeChangeTypeConfirmationModal}
          onConfirm={changeGroupType}
          confirmationButtonLabel={translate('common.actions.update')}
        />
      </>
    )
  }
)
