import { AxiosResponse } from 'axios'
import { createUrl } from './helpers'
import axiosAuth from './config'
import { NBoardroomContacts } from '../interfaces/services/boardroom-contacts.interfaces'

export const CONTACTS_POPULATE = ['media']

export const getContactsPerSociety = (
  societyId: string
): Promise<AxiosResponse<NBoardroomContacts.NGetPerSocieties.IResponse>> => {
  return axiosAuth.get<NBoardroomContacts.NGetPerSocieties.IResponse>(
    createUrl(`/boardroom-contacts/get-per-society/${societyId}`, {
      populate: CONTACTS_POPULATE.join(';'),
    })
  )
}

export const getContact = (
  id: string
): Promise<AxiosResponse<NBoardroomContacts.NGetById.IResponse>> => {
  return axiosAuth.get<NBoardroomContacts.NGetById.IResponse>(
    createUrl(`/boardroom-contacts/contact/${id}`, {
      populate: CONTACTS_POPULATE.join(';'),
    })
  )
}

export const patchContact = (
  id: string,
  body: NBoardroomContacts.NPatch.IRequestBody
): Promise<AxiosResponse<NBoardroomContacts.NPatch.IResponse>> => {
  return axiosAuth.patch<NBoardroomContacts.NPatch.IResponse>(
    createUrl(`/boardroom-contacts/contact/${id}`),
    body
  )
}

export const createContact = (
  body: NBoardroomContacts.NCreate.IRequestBody
): Promise<AxiosResponse<NBoardroomContacts.NCreate.IResponse>> => {
  return axiosAuth.post<NBoardroomContacts.NCreate.IResponse>(
    createUrl(`/boardroom-contacts/contact`),
    body
  )
}

export const deleteContact = (
  id: string
): Promise<AxiosResponse<NBoardroomContacts.NDel.IResponse>> => {
  return axiosAuth.delete<NBoardroomContacts.NDel.IResponse>(
    createUrl(`/boardroom-contacts/contact/${id}`)
  )
}
