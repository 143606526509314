/* eslint-disable max-len */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { SnapshotOut } from 'mobx-state-tree'
import { Modal } from '../../../../common/Modal'
import { ModalHeader } from '../../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { useStores } from '../../../../../hooks/useStores'
import { ToastType } from '../../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { societyDocumentFolderCreateSchema } from '../../../../../forms/schemas/society_document_folder_create'
import { CreateUpdateSocietyWebsiteFolderForm } from '../CreateUpdateSocietyWebsiteFolderForm'
import { SocietyWebsiteModel } from '../../../../../state/models/society-website'

interface CreateUpdateSocietyWebsiteFolderModalProps {
  website: SnapshotOut<typeof SocietyWebsiteModel>
  show: boolean
  close: () => void
  children?: React.ReactNode
  id?: string
}

export const CreateUpdateSocietyWebsiteFolderModal = observer(
  ({
    website,
    id,
    children,
    show,
    close,
  }: CreateUpdateSocietyWebsiteFolderModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { societyWebsitesStore } = useStores()
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!id
    const folders = website.foldersList
    const folder = id
      ? folders?.find((_folder) => _folder._id === id)
      : undefined

    const showErrorMessage = (): void => {
      const message = updateMode
        ? 'createUpdateFolder.flashMessage.updateFolderFailure'
        : 'createUpdateFolder.flashMessage.createFolderFailure'
      setToastNotification(ToastType.DANGER, translate(message))
    }

    const showSuccessMessage = (): void => {
      const message = updateMode
        ? 'createUpdateFolder.flashMessage.updateFolderSuccess'
        : 'createUpdateFolder.flashMessage.createFolderSuccess'
      setToastNotification(ToastType.SUCCESS, translate(message))
    }

    const onSubmit = async (
      data: InferType<typeof societyDocumentFolderCreateSchema>
    ): Promise<void> => {
      if (updateMode) {
        const status = await societyWebsitesStore.patchWebsite(website._id, {
          foldersList: folders?.map((_folder) => {
            if (_folder._id === id) {
              return { ..._folder, name: data.name }
            }
            return _folder
          }),
        })

        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      } else {
        const status = await societyWebsitesStore.patchWebsite(website._id, {
          foldersList: [
            ...(folders || []),
            { name: data.name, documentsList: [] },
          ],
        })
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      showErrorMessage()
    }

    const loading = societyWebsitesStore.updatingWebsite === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate(
                  updateMode
                    ? 'createUpdateFolder.editFolderTitle'
                    : 'createUpdateFolder.addNewFolderTitle'
                )}
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <CreateUpdateSocietyWebsiteFolderForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  folder={folder}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
