import { AxiosResponse } from 'axios'
import { NSocieties } from '../interfaces/services/societies.interfaces'
import { createUrl } from './helpers'
import axiosAuth from './config'

const SOCIETIES_BASE_POPULATE = [
  'adminsList.media',
  'boardMembersList.media',
  'media',
  'users',
  'users.media',
]

const SOCIETIES_DETAIL_POPULATE = [
  ...SOCIETIES_BASE_POPULATE,
  ...['units', 'units.entrances'],
]

export const getUserSocieties = (
  userId: string,
  extraPopulate?: string[]
): Promise<AxiosResponse<NSocieties.NGetUserSocieties.IResponse>> => {
  return axiosAuth.get<NSocieties.NGetUserSocieties.IResponse>(
    createUrl(`/societies/get-user-societies/${userId}`, {
      populate: [...SOCIETIES_BASE_POPULATE, ...(extraPopulate || [])].join(
        ';'
      ),
    })
  )
}

export const createSociety = (
  body: NSocieties.NCreate.IRequestBody
): Promise<AxiosResponse<NSocieties.NCreate.IResponse>> => {
  return axiosAuth.post<NSocieties.NCreate.IResponse>(
    createUrl('/societies/society'),
    body
  )
}

export const getSociety = (
  id: string
): Promise<AxiosResponse<NSocieties.NGetById.IResponse>> => {
  return axiosAuth.get<NSocieties.NGetById.IResponse>(
    createUrl(`/societies/society/${id}`, {
      populate: SOCIETIES_DETAIL_POPULATE.join(';'),
    })
  )
}

export const patchSociety = (
  id: string,
  body: NSocieties.NPatch.IRequestBody
): Promise<AxiosResponse<NSocieties.NPatch.IResponse>> => {
  return axiosAuth.patch<NSocieties.NPatch.IResponse>(
    createUrl(`/societies/society/${id}`),
    body
  )
}

export const searchSocieties = (
  term: string,
  limit = 50
): Promise<AxiosResponse<NSocieties.NGetUserSocieties.IResponse>> => {
  return axiosAuth.get<NSocieties.NGetUserSocieties.IResponse>(
    createUrl(`/societies/search`, {
      term,
      limit,
    })
  )
}

const SOCIETIES_LIST_POPULATE = ['media', 'units', 'units.entrances']

export const listSocieties = (
  params: NSocieties.NList.IRequestQuery
): Promise<AxiosResponse<NSocieties.NList.IResponse>> => {
  return axiosAuth.get<NSocieties.NList.IResponse>(
    createUrl(`/societies/list`, {
      ...params,
      populate: SOCIETIES_LIST_POPULATE.join(';'),
    })
  )
}

export const addAdminUsers = (
  id: string,
  userIds: string[]
): Promise<AxiosResponse<NSocieties.NUpdateAdminUsers.IResponse>> => {
  const body: NSocieties.NUpdateAdminUsers.IRequestBody = userIds.map(
    (userId) => ({
      userId,
    })
  )
  return axiosAuth.post<NSocieties.NUpdateAdminUsers.IResponse>(
    createUrl(`/societies/update-admin-users/${id}`),
    body
  )
}

export const removeAdminUser = (
  id: string,
  userId: string
): Promise<AxiosResponse<NSocieties.NRemoveAdminUsers.IResponse>> => {
  const body: NSocieties.NRemoveAdminUsers.IRequestBody = [{ userId }]
  return axiosAuth.post<NSocieties.NRemoveAdminUsers.IResponse>(
    createUrl(`/societies/remove-admin-users/${id}`),
    body
  )
}

export const addBlockedUsers = (
  id: string,
  userIds: string[]
): Promise<AxiosResponse<NSocieties.NCreateBlockListUsers.IResponse>> => {
  const body: NSocieties.NCreateBlockListUsers.IRequestBody = userIds.map(
    (userId) => ({
      userId,
    })
  )
  return axiosAuth.post<NSocieties.NCreateBlockListUsers.IResponse>(
    createUrl(`/societies/create-block-list-users/${id}`),
    body
  )
}

export const removeBlockedUser = (
  id: string,
  userId: string
): Promise<AxiosResponse<NSocieties.NDeleteBlockListUser.IResponse>> => {
  const body: NSocieties.NDeleteBlockListUser.IRequestBody = { userId }
  return axiosAuth.post<NSocieties.NDeleteBlockListUser.IResponse>(
    createUrl(`/societies/delete-block-list-user/${id}`),
    body
  )
}

export const inviteMembers = (
  id: string,
  users: { userEmail: string }[]
): Promise<AxiosResponse<NSocieties.NAddUsersRoles.IResponse>> => {
  const body: NSocieties.NAddUsersRoles.IRequestBody = users.map((user) => ({
    societyId: id,
    userEmail: user.userEmail,
  }))
  return axiosAuth.post<NSocieties.NAddUsersRoles.IResponse>(
    createUrl(`/societies/users-roles`, { sendNotifications: true }),
    body
  )
}

export const createUserRole = (
  body: NSocieties.NAddUsersRoles.IRequestBody,
  sendNotifications = false
): Promise<AxiosResponse<NSocieties.NAddUsersRoles.IResponse>> => {
  return axiosAuth.post<NSocieties.NAddUsersRoles.IResponse>(
    createUrl(`/societies/users-roles`, { sendNotifications }),
    body
  )
}

export const deleteUserRole = (
  body: NSocieties.NRemoveUsersRoles.IRequestBody
): Promise<AxiosResponse<NSocieties.NRemoveUsersRoles.IResponse>> => {
  return axiosAuth.delete<NSocieties.NRemoveUsersRoles.IResponse>(
    createUrl(`/societies/users-roles`),
    {
      data: body,
    }
  )
}

export const removeUserFromSociety = (
  userId: string,
  societyId: string
): Promise<AxiosResponse<NSocieties.NRemoveUsersRoles.IResponse>> => {
  return deleteUserRole([
    {
      userId,
      societyId,
      removeFromSociety: true,
      unitRole: true,
      boardRole: true,
      adminRole: true,
      nominatingCommitteeRole: true,
    },
  ])
}

export const removeSelfFromSociety = (
  id: string
): Promise<AxiosResponse<NSocieties.NRemoveSelfFromSociety.IResponse>> => {
  return axiosAuth.get<NSocieties.NRemoveSelfFromSociety.IResponse>(
    createUrl(`/societies/remove-self-from-society/${id}`)
  )
}

export const removePreApprovedUser = (
  id: string,
  body: NSocieties.NRemovePreApprovedUser.IRequestBody
): Promise<AxiosResponse<NSocieties.NRemovePreApprovedUser.IResponse>> => {
  return axiosAuth.post<NSocieties.NRemovePreApprovedUser.IResponse>(
    createUrl(`/societies/remove-pre-approved-users/${id}`),
    body
  )
}

export const sendPreApprovedUserInviteReminder = (
  id: string,
  email: string
): Promise<AxiosResponse<NSocieties.NInvitePreApproved.IResponse>> => {
  const body: NSocieties.NInvitePreApproved.IRequestBody = {
    isInviteAll: false,
    isInviteHousehold: false,
    preApprovedUserEmails: [email],
  }
  return axiosAuth.post<NSocieties.NAddUsersRoles.IResponse>(
    createUrl(`/societies/invite-pre-approved/${id}`),
    body
  )
}

export const inviteAllPreApprovedUsers = (
  id: string
): Promise<AxiosResponse<NSocieties.NInvitePreApproved.IResponse>> => {
  const body: NSocieties.NInvitePreApproved.IRequestBody = {
    isInviteAll: true,
    isInviteHousehold: false,
    preApprovedUserEmails: [],
  }
  return axiosAuth.post<NSocieties.NAddUsersRoles.IResponse>(
    createUrl(`/societies/invite-pre-approved/${id}`),
    body
  )
}

export const activateSociety = (
  id: string
): Promise<AxiosResponse<NSocieties.NActivateSociety.IResponse>> => {
  return axiosAuth.post<NSocieties.NActivateSociety.IResponse>(
    createUrl(`/societies/activate-society/${id}`)
  )
}

export const getSocietyListings = (
  id: string,
  limit = 100,
  offset = 0
): Promise<AxiosResponse<NSocieties.NListings.IResponse>> => {
  return axiosAuth.get<NSocieties.NListings.IResponse>(
    createUrl(`/societies/society/${id}/listings`, { limit, offset })
  )
}

export const getSocietySold = (
  id: string,
  limit = 100,
  offset = 0
): Promise<AxiosResponse<NSocieties.NSold.IResponse>> => {
  return axiosAuth.get<NSocieties.NSold.IResponse>(
    createUrl(`/societies/society/${id}/sold`, { limit, offset })
  )
}

export const downloadMemberRegister = async (
  id: string,
  onlyResidents?: boolean
): Promise<AxiosResponse<NSocieties.NDownloadUsers.IResponse>> => {
  const societyResidentsEndpoint = createUrl(`/societies/download/${id}`, {
    onlyResidents,
  })
  return axiosAuth.post(createUrl(`/users/ephemeral-endpoint`), {
    endpoint: societyResidentsEndpoint,
    maxTimeMS: 1000 * 60 * 30,
  })
}

export const downloadApartmentRegister = async (
  id: string
): Promise<AxiosResponse<NSocieties.NDownloadApartments.IResponse>> => {
  const societyApartmentsEndpoint = createUrl(
    `/societies/download-apartments/${id}`
  )
  return axiosAuth.post(createUrl(`/users/ephemeral-endpoint`), {
    endpoint: societyApartmentsEndpoint,
    maxTimeMS: 1000 * 60 * 30,
  })
}

export const uploadMemberRegister = (
  id: string,
  body: NSocieties.NUploadUsers.IRequestBody
): Promise<AxiosResponse<NSocieties.NUploadUsers.IResponse>> => {
  return axiosAuth.post<NSocieties.NUploadUsers.IResponse>(
    createUrl(`/societies/upload/${id}`),
    body
  )
}

export const uploadApartmentRegister = (
  id: string,
  body: NSocieties.NUploadApartments.IRequestBody
): Promise<AxiosResponse<NSocieties.NUploadApartments.IResponse>> => {
  return axiosAuth.post<NSocieties.NUploadApartments.IResponse>(
    createUrl(`/societies/upload-apartments/${id}`),
    body
  )
}

export const getSocietyPremiumCost = (
  id: string,
  query: NSocieties.NCostSummary.IRequestQuery
): Promise<AxiosResponse<NSocieties.NCostSummary.IResponse>> => {
  return axiosAuth.get<NSocieties.NCostSummary.IResponse>(
    createUrl(`/societies/society/${id}/cost-summary`, query)
  )
}

export const upgradeSociety = (
  id: string
): Promise<AxiosResponse<NSocieties.NUpgrade.IResponse>> => {
  return axiosAuth.post<NSocieties.NUpgrade.IResponse>(
    createUrl(`/societies/society/${id}/upgrade`)
  )
}

export const getUserBlockedSocietyIds = (): Promise<
  AxiosResponse<NSocieties.NGetUserBlockedSocietyIds.IResponse>
> => {
  return axiosAuth.get<NSocieties.NGetUserBlockedSocietyIds.IResponse>(
    createUrl('/societies/get-user-blocked-society-ids')
  )
}
