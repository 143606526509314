import React from 'react'
import { theme } from '../../../theme/theme'
import { Button } from '../Button'
import { IconChoices } from '../Icon'

interface ModalHeaderProps {
  children?: React.ReactNode
  onClose: () => void
  className?: string
  style?: React.CSSProperties
  flexItems?: 'items-start' | 'items-center' | 'items-end'
}

export const ModalHeader = ({
  children,
  onClose,
  className,
  style,
  flexItems = 'items-center',
}: ModalHeaderProps): JSX.Element => {
  return (
    <div
      style={{ ...style, ...theme.textVariants.h3 }}
      className={`flex flex-shrink-0 ${
        children ? 'justify-between p-6' : 'justify-end pr-6 pt-6'
      } 
        ${flexItems} ${className ?? ''}
      `}
    >
      {children}
      <Button
        type="button"
        className="h-12 w-12 border-none bg-neutral-96 opacity-75 hover:opacity-100"
        size="no-padding"
        iconSize={24}
        icon={IconChoices.CLOSE_CROSS}
        onClick={onClose}
      />
    </div>
  )
}
