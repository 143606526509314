import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { InferType } from 'yup'
import { societyCreatePostSchema } from '../../../forms/schemas/society_create_post'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { theme } from '../../../theme/theme'
import { Checkbox } from '../../common/Checkbox'
import { Icon, IconChoices } from '../../common/Icon'
import { Label } from '../../common/Label'
import { useIsMobile } from '../../../hooks/useIsMobile'

interface ImportantCheckboxProps {
  control: Control<InferType<typeof societyCreatePostSchema>>
  watchRole: string
  watchIsAlert: boolean
}

export const ImportantCheckbox = ({
  control,
  watchRole,
  watchIsAlert,
}: ImportantCheckboxProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const isMobile = useIsMobile()

  const shouldRender = watchRole !== 'resident'

  return (
    <>
      {shouldRender && (
        <Controller
          control={control}
          name="isAlert"
          render={({ field: { name, value, onChange } }) => (
            <div
              className={`flex items-center justify-center gap-2 
              rounded border border-solid border-neutral-80 px-4 py-3 ${
                watchIsAlert && 'border-brandGreen'
              }`}
              style={
                watchIsAlert
                  ? { backgroundColor: theme.colors.brandLightGreen }
                  : {}
              }
            >
              <Checkbox
                name={name}
                onChange={onChange}
                value={value}
                type="checkbox"
              />
              <Label
                name={name}
                label={translate(
                  isMobile ? 'post.importantMobile' : 'post.important'
                )}
                className="cursor-pointer"
                style={theme.textVariants.base}
              />

              <Icon icon={IconChoices.INFORMATION} size={20} highlighted />
            </div>
          )}
        />
        // </div>
      )}
    </>
  )
}
