import React from 'react'
import { DateTimeInput } from '../DateTimeInput/DateTimeInput'

interface MultipleDateTimeInputProps {
  highlightedDays: Date[]
  onChange: (dates: Date[]) => void
  minDate?: Date
}

export const DateTimeInputMultiple = ({
  highlightedDays,
  onChange,
  minDate,
}: MultipleDateTimeInputProps): JSX.Element => {
  const handleDateOnChange = (date: Date): void => {
    let highlighted = highlightedDays
    const isInList = highlighted?.find(
      (highlightedDate) => highlightedDate.getTime() === date.getTime()
    )

    if (isInList) {
      highlighted = highlighted?.filter(
        (highlightedDate) => highlightedDate.getTime() !== date.getTime()
      )
    } else {
      highlighted = highlighted.length > 0 ? [...highlighted, date] : [date]
    }

    onChange(highlighted)
  }

  return (
    <DateTimeInput
      onChange={handleDateOnChange}
      highlightDates={highlightedDays}
      inline
      minDate={minDate}
    />
  )
}
