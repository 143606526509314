import { AxiosResponse } from 'axios'
import { createUrl } from './helpers'
import axiosAuth from './config'
import { NContracts } from '../interfaces/services/contracts.interfaces'

export const CONTRACT_POPULATE = ['documents']

export const getContractsPerSociety = (
  societyId: string
): Promise<AxiosResponse<NContracts.NGetPerSocieties.IResponse>> => {
  const body: NContracts.NGetPerSocieties.IRequestBody = {
    societyIds: [societyId],
  }
  return axiosAuth.post<NContracts.NGetPerSocieties.IResponse>(
    createUrl(`/contracts/get-per-societies`, {
      populate: CONTRACT_POPULATE.join(';'),
    }),
    body
  )
}

export const getContract = (
  id: string
): Promise<AxiosResponse<NContracts.NGetById.IResponse>> => {
  return axiosAuth.get<NContracts.NGetById.IResponse>(
    createUrl(`/contracts/contract/${id}`, {
      populate: CONTRACT_POPULATE.join(';'),
    })
  )
}

export const createContract = (
  body: NContracts.NCreate.IRequestBody
): Promise<AxiosResponse<NContracts.NCreate.IResponse>> => {
  return axiosAuth.post<NContracts.NCreate.IResponse>(
    createUrl(`/contracts/contract`),
    body
  )
}

export const updateContract = (
  id: string,
  body: NContracts.NPatch.IRequestBody
): Promise<AxiosResponse<NContracts.NPatch.IResponse>> => {
  return axiosAuth.patch<NContracts.NPatch.IResponse>(
    createUrl(`/contracts/contract/${id}`),
    body
  )
}

export const deleteContract = (
  id: string
): Promise<AxiosResponse<NContracts.NDelete.IResponse>> => {
  return axiosAuth.delete<NContracts.NDelete.IResponse>(
    createUrl(`/contracts/contract/${id}`)
  )
}
