import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { UserModel } from '../../../../state/models/user'
import { UserAvatar } from '../../../common/Avatar'
import { theme } from '../../../../theme/theme'
import { IconChoices } from '../../../common/Icon'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useStores } from '../../../../hooks/useStores'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { useModal } from '../../../../hooks/useModal'
import { ToastType } from '../../../common/Toast/toast-type'
import { Dropdown } from '../../../common/Dropdown'
import { CreateUpdateAdminModal } from '../CreateUpdateAdminModal'
import { Button, ButtonVariant } from '../../../common/Button'
import { ConfirmationModal } from '../../../common/ConfirmationModal'

interface AdminRightsListItemProps {
  admin: {
    user: SnapshotOut<typeof UserModel>
    role: string
  }
}

export const AdminRightsListItem = observer(
  ({ admin }: AdminRightsListItemProps): JSX.Element => {
    const { societyStore, authenticationStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const { show: showEditModal, close: closeEditModal } = useModal()
    const {
      show: showDeleteConfirmationModal,
      close: closeDeleteConfirmationModal,
      open: openDeleteConfirmationModal,
    } = useModal()
    const currentUserId = authenticationStore.userId

    const adminUser = admin.user

    const dropdownMakeAdminOption = society.masterAdminUserId ===
      currentUserId && {
      value: 'makeMasterAdmin',
      label: translate('adminRightsView.makeMasterAdminTitle'),
      onClick: async () => {
        const status = await societyStore.updateMasterAdmin(
          society._id,
          adminUser._id
        )

        if (status) {
          setToastNotification(
            ToastType.SUCCESS,
            translate('adminRightsView.flashMessage.masterAdminChangedSuccess')
          )
        } else {
          setToastNotification(
            ToastType.DANGER,
            translate('adminRightsView.flashMessage.masterAdminChangedFailure')
          )
        }
      },
    }

    const deleteUser = async (): Promise<void> => {
      const status = await societyStore.removeAdminUser(
        society._id,
        adminUser._id
      )

      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('adminRightsView.flashMessage.adminRemoveSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('adminRightsView.flashMessage.adminRemoveFailure')
        )
      }
    }

    const dropdownDeleteOption = {
      value: 'remove',
      label: translate('common.actions.remove'),
      onClick: openDeleteConfirmationModal,
      destructive: true,
    }

    const dropdownOptions = dropdownMakeAdminOption
      ? [dropdownMakeAdminOption, dropdownDeleteOption]
      : [dropdownDeleteOption]

    return (
      <div className="flex flex-row items-center border-b pb-4">
        <div className="flex flex-10 flex-row items-center">
          <UserAvatar user={adminUser} />
          <div className="ml-4 flex flex-col justify-start">
            <p style={theme.textVariants.baseBold}>{admin.role}</p>
            <p
              className="text-neutral-30"
              style={theme.textVariants.base}
              translate="no"
            >
              {adminUser.fullName}
            </p>
          </div>
        </div>
        {society.masterAdminUserId !== adminUser._id && (
          <Dropdown options={dropdownOptions} className="mr-3">
            <Button
              variant={ButtonVariant.TEXT}
              className="h-10 w-10 border-none bg-transparent hover:bg-neutral-90"
              icon={IconChoices.MORE}
              disableTabIndex
            />
          </Dropdown>
        )}
        <CreateUpdateAdminModal show={showEditModal} close={closeEditModal} />
        <ConfirmationModal
          title={translate('adminRightsView.alert.deleteAdminTitle')}
          description={translate('adminRightsView.alert.deleteAdminText')}
          show={showDeleteConfirmationModal}
          close={closeDeleteConfirmationModal}
          onConfirm={deleteUser}
        />
      </div>
    )
  }
)
