import React, { Dispatch, SetStateAction } from 'react'
import { useAtom } from 'jotai'
import { Control, UseFormSetValue } from 'react-hook-form'
import { InferType } from 'yup'
import { observer } from 'mobx-react-lite'
import { chatMessageSchema } from '../../../forms/schemas/chat_message'
import { useChatRoomAvatar } from '../../../hooks/useChatRoomAvatar'
import { useChatRoomTitle } from '../../../hooks/useChatRoomTitle'
import { UserWithSocietyIdAndRoom } from '../ChatRoom/useUsersWithChatRoom'
import { StartNewBoardChatHeader } from '../StartNewBoardChatHeader'
import { StartNewChatHeader } from '../StartNewChatHeader'
import { ChatHeaderState, chatHeaderStateAtom } from '../atom'
import { RoomChatHeader } from '../RoomChatHeader/RoomChatHeader'
import { JoinGroupChatHeader } from '../JoinGroupChatHeader'

interface ChatHeaderProps {
  usersWithChatRoom: UserWithSocietyIdAndRoom[]
  control: Control<InferType<typeof chatMessageSchema>>
  watchRecipients: string[] | undefined
  watchSociety: string | undefined
  watchIsGroupchat: boolean
  showSettings: boolean
  setShowSettings: Dispatch<SetStateAction<boolean>>
  selectedChatRoomId: string | undefined
  chatRoomSociety: string | undefined
  setValue: UseFormSetValue<InferType<typeof chatMessageSchema>>
}

export const ChatHeader = observer(
  ({
    usersWithChatRoom,
    control,
    watchRecipients,
    watchSociety,
    watchIsGroupchat,
    showSettings,
    setShowSettings,
    selectedChatRoomId,
    chatRoomSociety,
    setValue,
  }: ChatHeaderProps): JSX.Element => {
    const [chatHeaderState] = useAtom(chatHeaderStateAtom)
    const chatRoomTitle = useChatRoomTitle(selectedChatRoomId)
    const chatRoomAvatar = useChatRoomAvatar(selectedChatRoomId)

    switch (chatHeaderState) {
      case ChatHeaderState.StartNewChat:
        return (
          <StartNewChatHeader
            usersWithChatRoom={usersWithChatRoom}
            control={control}
            watchRecipients={watchRecipients}
            watchIsGroupchat={watchIsGroupchat}
            watchSociety={watchSociety}
            setValue={setValue}
          />
        )
      case ChatHeaderState.StartNewBoardChat:
        return <StartNewBoardChatHeader control={control} setValue={setValue} />
      case ChatHeaderState.JoinChat:
        return <JoinGroupChatHeader />
      default:
        return (
          <div className="flex-none">
            <RoomChatHeader
              chatRoomTitle={chatRoomTitle}
              chatRoomAvatar={chatRoomAvatar}
              chatRoomSociety={chatRoomSociety}
              showSettings={showSettings}
              setShowSettings={setShowSettings}
            />
          </div>
        )
    }
  }
)
