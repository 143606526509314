/* eslint-disable max-len */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { Modal } from '../../../../common/Modal'
import { ModalHeader } from '../../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../common/Toast/toast-type'
import { WeeklyScheduleForm } from '../WeeklyScheduleForm'
import {
  weeklySchedule as weeklyScheduleSchema,
  societyFacilityWeeklyScheduleCreateSchema,
} from '../../../../../forms/schemas/society_facility_weekly_schedule_create'

interface WeeklyScheduleModalProps {
  show: boolean
  close: () => void
  weeklySchedule: InferType<typeof weeklyScheduleSchema>
  onChange: (schedule: InferType<typeof weeklyScheduleSchema>) => void
}

export const WeeklyScheduleModal = observer(
  ({
    show,
    close,
    weeklySchedule,
    onChange,
  }: WeeklyScheduleModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const onSubmit = async (
      data: InferType<typeof societyFacilityWeeklyScheduleCreateSchema>
    ): Promise<void> => {
      const schedule = data.weeklySchedule?.map((_schedule) => ({
        ..._schedule,
        startTime: new Date(_schedule.startTime).toUTCString(),
        endTime: new Date(_schedule.endTime).toUTCString(),
      }))
      if (schedule) onChange(schedule)
      close()
    }

    const onError = (): void => {
      showErrorMessage()
    }

    return (
      <Modal show={show}>
        {{
          header: (
            <ModalHeader onClose={close}>
              {translate('createUpdateFacility.form.labels.weeklySchedule')}
            </ModalHeader>
          ),
          body: (
            <ModalBody>
              <WeeklyScheduleForm
                onClose={close}
                onSubmit={onSubmit}
                onError={onError}
                weeklySchedule={weeklySchedule}
              />
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
