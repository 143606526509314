import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Button,
  ButtonVariant,
} from '../../../../../../components/common/Button'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { useStores } from '../../../../../../hooks/useStores'
import { theme } from '../../../../../../theme/theme'
import { ConfirmationModal } from '../../../../../../components/common/ConfirmationModal'
import { useModal } from '../../../../../../hooks/useModal'
import { Spinner } from '../../../../../../components/common/Spinner'
import { Checkbox } from '../../../../../../components/common/Checkbox'

interface DeleteUserModalContentProps {
  onSubmit: () => Promise<void>
  onClose: () => void
  userId: string
}

export const DeleteUserModalContent = observer(
  ({ onSubmit, onClose, userId }: DeleteUserModalContentProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { userStore } = useStores()

    const {
      show: showDeleteUserConfirmationModal,
      open: openDeleteUserConfirmationModal,
      close: closeDeleteUserConfirmationModal,
    } = useModal()

    const [userHasEmailedConfirmation, setUserHasEmailedConfirmation] =
      useState(false)

    const onConfirmationPress = (): void => {
      closeDeleteUserConfirmationModal()
      onSubmit()
    }

    const onEmailConfirmationCheckboxClick = (): void => {
      setUserHasEmailedConfirmation(!userHasEmailedConfirmation)
    }

    const loading = userStore.fetchingUser === 'pending'

    const user = userStore.users.get(userId)

    if (loading) {
      return <Spinner />
    }

    if (!loading && !user) {
      return <p>Något gick fel.</p>
    }

    return (
      <form
        className="flex h-full flex-col gap-6"
        onSubmit={(e) => {
          e?.preventDefault()
          openDeleteUserConfirmationModal()
        }}
      >
        <div className="flex flex-col">
          <div className="flex flex-col items-center justify-center gap-10">
            <p style={theme.textVariants.h3}>
              Du tar nu bort följande användare:
            </p>
            <div>
              <p style={theme.textVariants.base}>Namn: {user?.fullName}</p>
              <p style={theme.textVariants.base}>Email: {user?.email}</p>
            </div>
            <p
              className="animate-bounce text-red"
              style={theme.textVariants.h3}
            >
              Detta går inte att ångra!
            </p>
            <button
              type="button"
              className="flex items-center gap-2 text-left"
              onClick={onEmailConfirmationCheckboxClick}
            >
              <Checkbox
                name="email-confirmation"
                value={userHasEmailedConfirmation}
                onChange={onEmailConfirmationCheckboxClick}
              />
              <p style={{ ...theme.textVariants.baseBold }}>
                Jag bekräftar att användaren har mejlat mig från{' '}
                <span className="underline">{user?.email}</span> och bett om att
                få kontot raderat.
              </p>
            </button>
          </div>
        </div>
        <div className="flex flex-wrap justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            variant={ButtonVariant.DANGER}
            disabled={loading || !userId || !userHasEmailedConfirmation}
            loading={loading}
            label={translate('common.actions.delete')}
            type="submit"
          />
        </div>
        <ConfirmationModal
          title="Radera användare"
          description="Är du säker på att du vill radera användaren? Detta går inte att ångra."
          show={showDeleteUserConfirmationModal}
          close={closeDeleteUserConfirmationModal}
          onConfirm={onConfirmationPress}
          confirmationButtonLabel="Radera"
        />
      </form>
    )
  }
)
