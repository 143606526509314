import * as yup from 'yup'

export const createChatRoomSchema = yup
  .object({
    groupChatTitle: yup.string().required(),
  })
  .required()

export const chatAskBoardSchema = yup
  .object({
    society: yup.string().required(),
    subject: yup.string().required(),
    message: yup.string().required(),
    mediaIds: yup.array(yup.string().required()),
  })
  .required()

export const chatNewBoardMessageSchema = yup
  .object({
    name: yup.string().required(),
  })
  .required()

export const addMembersToChatSchema = yup
  .object({
    users: yup.array().required().min(1),
    society: yup.string(),
  })
  .required()
