import { types } from 'mobx-state-tree'
import { captureException } from '@sentry/react'
import { timestampTypes } from '../types/common'
import i18next from '../../i18n/i18n'
import { largeNumberFormattingWithSpaces } from '../../helpers/number'

export const ResourceModel = types
  .model('Resource', {
    _id: types.identifier,
    ...timestampTypes,
    societyId: types.string,
    userId: types.string,
    unit: types.string,
    title: types.string,
    description: types.string,
    cost: types.string,
    mediaId: types.maybeNull(types.string),
    notificationSettings: types.maybe(types.string),
    isFree: types.maybe(types.boolean),
    type: types.maybe(types.string),
  })
  .views((self) => ({
    get costString(): string {
      // If only numbers, format with SEK currency.
      // If not, simply return the cost. Old app did not enforce only number cost string,
      // So we have to take that in consideration as to not break stuff.
      if (self.isFree) {
        return `${i18next.t('resource.free')}`
      }
      if (/^\d+$/.test(self.cost)) {
        try {
          const cost = Number(self.cost)
          if (cost > 0) {
            return `${largeNumberFormattingWithSpaces(cost)} kr`
          }
          return `${i18next.t('resource.free')}`
        } catch (error) {
          captureException(error)
          return self.cost
        }
      }
      return self.cost
    },
  }))
