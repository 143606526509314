import React from 'react'
import { observer } from 'mobx-react-lite'
import { theme } from '../../../theme/theme'

interface SectionListProps {
  sections: Section[]
  className?: string
}

export interface Section {
  title: string
  item: React.ReactNode
}

export const SectionList = observer(
  ({ sections, className }: SectionListProps): JSX.Element => {
    return (
      <div className={`flex h-full w-full flex-col gap-4 ${className ?? ''}`}>
        {sections.map((section) => (
          <div className="flex flex-col" key={section.title}>
            <p style={theme.textVariants.h3}>{section.title}</p>
            {section.item}
          </div>
        ))}
      </div>
    )
  }
)
