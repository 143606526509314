import { observer } from 'mobx-react-lite'
import React, { KeyboardEvent, useState } from 'react'
import { useModal } from '../../../hooks/useModal'
import { theme } from '../../../theme/theme'
import { MediaImage } from '../../common/Image'
import { MediaGalleryModal } from '../../common/MediaGalleryModal'

interface PostImagesProps {
  mediaIds: string[]
  disabled?: boolean
}

export const PostImages = observer(
  ({ mediaIds, disabled }: PostImagesProps): JSX.Element => {
    const [mediaGalleryIndex, setMediaGalleryIndex] = useState<number>(0)
    const {
      show: showMediaGalleryModal,
      open: openMediaGalleryModal,
      close: closeMediaGalleryModal,
    } = useModal()

    const getMediaWidth = (i: number): string => {
      switch (mediaIds.length) {
        case 1:
          return 'flex-1/1'
        case 2:
          return 'flex-1/2'
        case 3:
          return 'flex-1/3'
        default:
          return i === 0 || i === 1 ? 'flex-1/2' : 'flex-1/3'
      }
    }

    const handleKeyDown = (
      e: KeyboardEvent<HTMLDivElement>,
      i: number
    ): void => {
      if (e.key === 'Enter') {
        if (!disabled) {
          e.stopPropagation()
          setMediaGalleryIndex(i)
          openMediaGalleryModal()
        }
      }
    }

    const openModal = (i: number): void => {
      if (!disabled) {
        setMediaGalleryIndex(i)
        openMediaGalleryModal()
      }
    }

    return (
      <>
        {mediaIds && mediaIds.length > 0 && (
          <div className="flex w-full flex-wrap">
            {mediaIds.map((id, i) => (
              <div key={id} className={`relative ${getMediaWidth(i)}`}>
                {i < 5 && (
                  <MediaImage
                    imageClassName="max-h-[700px] rounded-md p-[2px]"
                    objectFit="object-cover"
                    mediaId={id}
                    onClick={() => openModal(i)}
                    disabled={disabled}
                  />
                )}
                {i === 4 && mediaIds.length > 5 && (
                  <>
                    <div
                      style={{
                        height: 'calc(100% - 2px)',
                        width: 'calc(100% - 2px)',
                      }}
                      className="absolute top-0 rounded-md bg-black/60"
                    />
                    <div
                      style={{
                        ...theme.textVariants.h1,
                      }}
                      className="absolute top-0 flex h-full w-full items-center
                    justify-center text-white"
                      onClick={() => openModal(i)}
                      onKeyDown={(e) => handleKeyDown(e, i)}
                      role="button"
                      aria-label="More images"
                      tabIndex={0}
                    >
                      +{mediaIds.length - 5}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        )}
        {mediaIds.length > 0 && (
          <MediaGalleryModal
            show={showMediaGalleryModal}
            close={closeMediaGalleryModal}
            mediaIds={mediaIds}
            mediaGalleryIndex={mediaGalleryIndex}
            setMediaGalleryIndex={setMediaGalleryIndex}
          />
        )}
      </>
    )
  }
)
