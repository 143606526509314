import React, { FormEvent } from 'react'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { theme } from '../../../../../../theme/theme'
import { FormControl } from '../../../../../common/FormControl'
import {
  Illustration,
  IllustrationChoices,
} from '../../../../../common/Illustration'
import { TextInput } from '../../../../../common/TextInput'

interface LastStepProps {
  onChangePassword: (confirmedPassword: string) => void
  password: string
}

export const LastStep = ({
  onChangePassword,
  password,
}: LastStepProps): JSX.Element => {
  const { translate } = useAppTranslation()

  const onChange = (e: FormEvent<HTMLInputElement>): void => {
    onChangePassword(e.currentTarget.value)
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="mb-8 flex justify-center">
        <Illustration illustrationChoice={IllustrationChoices.CHECKLIST} />
      </div>
      <p style={theme.textVariants.h3}>
        {translate('deleteAccountModal.lastWarningTitle')}
      </p>
      <form>
        <FormControl
          name="password"
          label={translate('common.form.labels.password')}
        >
          <TextInput
            className="w-80"
            value={password}
            onChange={onChange}
            onBlur={onChange}
            type="password"
          />
        </FormControl>
      </form>
    </div>
  )
}
