import * as yup from 'yup'

export const societyConfirmActivationSchema = yup
  .object({
    role: yup.string().required(),
    boardMembers: yup.array().of(
      yup.object({
        email: yup.string().email().required(),
      })
    ),
  })
  .required()
