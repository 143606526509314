import { AxiosResponse } from 'axios'
import { queryOptions } from '@tanstack/react-query'
import { createUrl } from './helpers'
import axiosAuth from './config'
import { NServices } from '../interfaces/services/services.interfaces'

export const listServices = (
  id?: string
): Promise<AxiosResponse<NServices.NGetList.IResponse>> => {
  return axiosAuth.get<NServices.NGetList.IResponse>(
    createUrl(`/services/${id}`)
  )
}

export const listServiceProviders = (): Promise<
  AxiosResponse<NServices.NGetProviderList.IResponse>
> => {
  return axiosAuth.get<NServices.NGetProviderList.IResponse>(
    createUrl('/services')
  )
}

export const queryKeys = {
  all: [{ scope: 'services' }] as const,
  providers: () =>
    queryOptions({
      queryKey: [{ ...queryKeys.all[0], entity: 'provider' }],
      queryFn: listServiceProviders,
    }),
  services: () => [{ ...queryKeys.all[0], entity: 'services' }],
  Service: (provider?: string) =>
    queryOptions({
      queryKey: [{ ...queryKeys.services()[0], provider }],
      queryFn: ({ queryKey }) => listServices(queryKey[0]?.provider),
    }),
}
