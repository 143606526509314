import axios, { AxiosResponse } from 'axios'
import { createUrl } from './helpers'
import { NTracking } from '../interfaces/services/tracking.interfaces'

export const sendBatchTrackingData = (
  body: NTracking.NSendBatchTrackingData.IRequestBody
): Promise<AxiosResponse<NTracking.NSendBatchTrackingData.IResponse>> => {
  return axios.post<NTracking.NSendBatchTrackingData.IResponse>(
    createUrl(`/tracking/track`),
    body
  )
}
