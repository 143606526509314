import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { FacilityModel } from '../../../../state/models/facility'
import { MediaImage } from '../../../common/Image'
import { theme } from '../../../../theme/theme'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { MediaGalleryModal } from '../../../common/MediaGalleryModal'
import { useModal } from '../../../../hooks/useModal'

interface FacilityInformationProps {
  facility: SnapshotOut<typeof FacilityModel>
}

export const FacilityInformation = observer(
  ({ facility }: FacilityInformationProps): JSX.Element | null => {
    const { translate } = useAppTranslation()
    const {
      show: showMediaGalleryModal,
      open: openMediaGalleryModal,
      close: closeMediaGalleryModal,
    } = useModal()

    const onImageClick = (): void => {
      openMediaGalleryModal()
    }

    return (
      <div className="md:max-w-1/3 md:max-h-1/3">
        {facility.coverPhotoId && (
          <>
            <MediaImage
              objectFit="object-contain"
              mediaId={facility.coverPhotoId}
              className="h-72 rounded border border-neutral-80 bg-neutral-96"
              onClick={onImageClick}
            />
            <div className="border-1 my-8 border-b border-neutral-80" />
          </>
        )}
        <p style={theme.textVariants.lead} className="mb-4">
          {translate('facilityView.information.sections.description.title')}
        </p>
        {facility.description ? (
          <p
            style={theme.textVariants.base}
            className="w-full whitespace-pre-line md:w-1/2"
          >
            {facility.description}
          </p>
        ) : (
          <p style={theme.textVariants.base} className="text-neutral-40">
            {translate(
              'facilityView.information.sections.description.noDescriptionText'
            )}
          </p>
        )}
        {facility.coverPhotoId && (
          <MediaGalleryModal
            show={showMediaGalleryModal}
            close={closeMediaGalleryModal}
            mediaIds={[facility.coverPhotoId]}
          />
        )}
      </div>
    )
  }
)
