import React from 'react'

interface ModalBodyProps {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  padding?: boolean
  gap?: boolean
}

export const ModalBody = ({
  children,
  className,
  style,
  padding = true,
  gap = true,
}: ModalBodyProps): JSX.Element => {
  return (
    <div
      className={`relative flex h-full flex-auto flex-col overflow-y-auto
        ${gap ? 'gap-3' : ''}
        ${padding ? 'px-6 pb-6 md:pb-8' : ''}
        ${className ?? ''}
      `}
      style={style}
    >
      {children}
    </div>
  )
}
