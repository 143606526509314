import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../hooks/useStores'
import { Modal } from '../Modal'
import { ModalBody } from '../Modal/ModalBody'
import { ModalFooter } from '../Modal/ModalFooter'
import { MediaGalleryModalBody } from './MediaGalleryModalBody'
import { MediaGalleryModalFooter } from './MediaGalleryModalFooter'
import { MediaGalleryModalHeader } from './MediaGalleryModalHeader'

interface MediaGalleryModalProps {
  show: boolean
  close: () => void
  mediaIds: string[]
  mediaGalleryIndex?: number
  setMediaGalleryIndex?: React.Dispatch<React.SetStateAction<number>>
}

export const MediaGalleryModal = observer(
  ({
    show,
    close,
    mediaIds,
    mediaGalleryIndex = 0,
    setMediaGalleryIndex,
  }: MediaGalleryModalProps): JSX.Element => {
    const { mediaStore } = useStores()
    const media = mediaStore.media.get(mediaIds[mediaGalleryIndex])

    return (
      <Modal show={show} size="xl">
        {{
          header: (
            <MediaGalleryModalHeader
              onClose={close}
              mediaIds={mediaIds}
              mediaGalleryIndex={mediaGalleryIndex}
              createdAt={media?.createdAt}
            />
          ),
          body: (
            <ModalBody
              className="relative h-[900px] overflow-y-auto"
              padding={false}
              gap={false}
            >
              <MediaGalleryModalBody
                mediaId={mediaIds[mediaGalleryIndex]}
                mediaLength={mediaIds.length}
                mediaGalleryIndex={mediaGalleryIndex}
                setMediaGalleryIndex={setMediaGalleryIndex}
              />
            </ModalBody>
          ),
          footer: (
            <ModalFooter>
              <MediaGalleryModalFooter media={media} />
            </ModalFooter>
          ),
        }}
      </Modal>
    )
  }
)
