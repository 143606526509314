import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SnapshotOut } from 'mobx-state-tree'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { useStores } from '../../../../hooks/useStores'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { SelectDropdown } from '../../../common/SelectDropdown'
import { houseRuleCreateSchema } from '../../../../forms/schemas/house_rule_create'
import { TextArea } from '../../../common/TextArea'
import { TextInput } from '../../../common/TextInput'
import { HouseRuleModel } from '../../../../state/models/house-rule'
import {
  notificationSettingsOptions,
  NOTIFICATION_SETTINGS_VALUE,
} from '../../../../helpers/notification_settings_options'

interface CreateUpdateHouseRuleFormProps {
  onError: (error: unknown) => void
  onSubmit: (data: InferType<typeof houseRuleCreateSchema>) => Promise<void>
  onClose: () => void
  loading: boolean
  houseRule?: SnapshotOut<typeof HouseRuleModel>
}

export const CreateUpdateHouseRuleForm = observer(
  ({
    houseRule,
    loading,
    onError,
    onSubmit,
    onClose,
  }: CreateUpdateHouseRuleFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { authenticationStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()
    const { getErrorMessage } = useFormErrorMessage()

    const updateMode = !!houseRule

    const isAdmin = isUserAdminInSociety(
      society,
      authenticationStore.userId as string
    )

    if (!isAdmin || (updateMode && houseRule === undefined)) {
      onClose()
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const getDefaultValues = useCallback((): InferType<
      typeof houseRuleCreateSchema
    > => {
      return {
        rule: updateMode ? houseRule?.rule || '' : '',
        section: updateMode ? houseRule?.section || '' : '',
        society: society._id,
        notificationSettings: updateMode
          ? houseRule?.notificationSettings || NOTIFICATION_SETTINGS_VALUE.NONE
          : NOTIFICATION_SETTINGS_VALUE.NONE,
      }
    }, [society, houseRule, updateMode])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(houseRuleCreateSchema),
      defaultValues: getDefaultValues(),
    })

    return (
      <>
        {!updateMode && (
          <Controller
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <FormControl
                label={translate('common.notificationSettings.label')}
                name={name}
                error={
                  errors.notificationSettings &&
                  getErrorMessage(errors.notificationSettings)
                }
              >
                <SelectDropdown
                  value={value}
                  onChange={onChange}
                  options={notificationSettingsOptions}
                  error={!!errors.notificationSettings}
                />
              </FormControl>
            )}
            name="notificationSettings"
          />
        )}
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateHouseRuleForm.labels.subject')}
              error={errors.section && getErrorMessage(errors.section)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.section}
              />
            </FormControl>
          )}
          name="section"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateHouseRuleForm.labels.rule')}
              error={errors.rule && getErrorMessage(errors.rule)}
              name={name}
            >
              <TextArea
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.rule}
              />
            </FormControl>
          )}
          name="rule"
        />
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-3">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
            variant={ButtonVariant.TEXT}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid || loading}
            label={translate('common.actions.save')}
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </>
    )
  }
)
