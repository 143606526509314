import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useNavigate } from 'react-router-dom'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ContractModel } from '../../../../state/models/contract'
import { Table } from '../../../common/Table'
import { TableColumn } from '../../../common/Table/TableHeader'
import { TableRow } from '../../../common/Table/TableRows'
import { formatDateWithFormat } from '../../../../helpers/date'
import { Icon, IconChoices } from '../../../common/Icon'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { ContractStatusBadge } from '../../ContractStatusBadge'

interface ContractsTableProps {
  contracts: SnapshotOut<typeof ContractModel>[]
}

export const ContractsTable = observer(
  ({ contracts }: ContractsTableProps): JSX.Element => {
    const navigate = useNavigate()

    const { translate } = useAppTranslation()

    const columns: TableColumn[] = [
      {
        key: 'title',
        title: translate('createUpdateContractForm.labels.title'),
      },
      {
        key: 'company',
        title: translate('createUpdateContractForm.labels.company'),
      },
      {
        key: 'running',
        title: translate('createUpdateContractForm.labels.running'),
        accessor: 'running',
        renderItem: (running: boolean) => {
          if (running) {
            return <Icon icon={IconChoices.CHECKMARK} />
          }
          return <></>
        },
      },
      {
        key: 'startDate',
        title: translate('createUpdateContractForm.labels.startDate'),
        accessor: 'startDate',
        renderItem: (startDate: string) => (
          <p>
            {startDate &&
              formatDateWithFormat(new Date(startDate), 'yyyy-MM-dd')}
          </p>
        ),
      },
      {
        key: 'endDate',
        title: translate('createUpdateContractForm.labels.endDate'),
        accessor: 'endDate',
        renderItem: (endDate: string) => (
          <p>
            {endDate && formatDateWithFormat(new Date(endDate), 'yyyy-MM-dd')}
          </p>
        ),
      },
      {
        key: 'notificationDate',
        title: translate('createUpdateContractForm.labels.notificationDate'),
        accessor: 'notificationDate',
        renderItem: (notificationDate: string) => (
          <p>
            {notificationDate &&
              formatDateWithFormat(new Date(notificationDate), 'yyyy-MM-dd')}
          </p>
        ),
      },
      {
        key: 'status',
        title: translate('createUpdateContractForm.labels.status'),
        accessor: 'status',
        renderItem: (status: string) => {
          return (
            <div className="flex">
              <ContractStatusBadge status={status} />
            </div>
          )
        },
      },
    ]

    const rows: TableRow[] = contracts.map((contract) => ({
      key: contract._id,
      data: contract,
      onPress: () =>
        navigate(
          reverseUrl('management:contracts-detail', {
            id: contract.societyId,
            contractId: contract._id,
          })
        ),
    }))

    return <Table columns={columns} rows={rows} />
  }
)
