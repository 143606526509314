import React from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { NavBar } from '../../components/common/NavBar'
import { usePageViews } from '../../hooks/usePageViews'
import { useIsMobile } from '../../hooks/useIsMobile'

const Root = observer((): JSX.Element => {
  // usePageViews depends on useHistory which must be placed inside <Router>
  usePageViews()
  const isMobile = useIsMobile()

  return (
    <div className="flex h-full flex-col">
      <NavBar />
      <div
        style={{ height: `calc(100vh - ${isMobile ? 52 : 64}px)` }}
        className="flex w-full"
      >
        <Outlet />
      </div>
    </div>
  )
})

Root.displayName = 'Root'
export { Root }
