import * as yup from 'yup'

export const chatMessageSchema = yup
  .object({
    recipients: yup.array().when('isGroupchat', {
      is: true,
      then: (schema) => schema.min(1),
    }),
    authorId: yup.string().required(),
    mediaIds: yup.array(yup.string()),
    message: yup.string().when('mediaIds', {
      is: (val: string[]) => {
        return val.length === 0
      },
      then: (schema) => schema.required(),
    }),
    isGroupchat: yup.boolean().required(),
    isBoardChat: yup.boolean().required(),
    groupChatTitle: yup
      .string()
      .when('isGroupchat', {
        is: true,
        then: (schema) => schema.min(1),
      })
      .when('isBoardChat', {
        is: true,
        then: (schema) => schema.min(1),
      }),
    society: yup.string(),
    isPrivate: yup.string(),
  })
  .required()
