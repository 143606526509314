import React from 'react'
import { observer } from 'mobx-react-lite'
import { SubcategoryMatch } from './SubcategoryMatch'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { UserMatch } from './UserMatch'
import { theme } from '../../../../theme/theme'

interface SubcategoryMatchesProps {
  subcategory: SubcategoryMatch
}

export const SubcategoryMatches = observer(
  ({ subcategory }: SubcategoryMatchesProps) => {
    const { translate } = useAppTranslation()

    return (
      <div key={subcategory.title}>
        <p
          style={theme.textVariants.base}
          className="mt-1.5 mb-4 text-neutral-30"
        >
          {`${subcategory.title} (${subcategory.users.length}
            ${
              subcategory.users.length > 1
                ? translate('findFriends.numberMatchesPlural')?.toLowerCase()
                : translate('findFriends.numberMatchesSingular')?.toLowerCase()
            })`}
        </p>
        <div className="flex flex-wrap gap-3">
          {subcategory.users.map((_user) => (
            <UserMatch key={_user._id} user={_user} />
          ))}
        </div>
      </div>
    )
  }
)
