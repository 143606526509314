import React from 'react'
import { theme } from '../../../theme/theme'
import { useAppTranslation } from '../../../hooks/useAppTranslation'

export interface FormControlNoteProps {
  note?: string
  required?: boolean
  locked?: boolean
}

export const FormControlNote = ({
  note,
  required,
  locked,
}: FormControlNoteProps): JSX.Element => {
  const { translate } = useAppTranslation()

  const textToDisplay = (): string => {
    if (required) return translate('common.form.note.required')
    if (locked) return translate('common.form.note.locked')
    return `${note}`
  }

  return (
    <p style={{ ...theme.textVariants.fineprint }} className="opacity-50">
      {textToDisplay()}
    </p>
  )
}
