/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */

// eslint-disable-next-line
// @ts-ignore
export function isObject(item): any {
  return item && typeof item === 'object' && !Array.isArray(item)
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */

// eslint-disable-next-line
// @ts-ignore
export function deepMerge(target, ...sources): any {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    /* eslint-disable no-restricted-syntax */
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        deepMerge(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return deepMerge(target, ...sources)
}
