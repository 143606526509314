import * as yup from 'yup'
import i18n from '../../i18n/i18n'

export const signUpSchema = yup
  .object({
    name: yup.string().required(),
    surname: yup.string().required(),
    email: yup.string().email().required(),
    password: yup
      .string()
      .required()
      .min(
        8,
        i18n.t('form.errors.minLength', {
          minLength: '8',
        })
      ),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('password'), undefined],
        i18n.t('form.errors.passwordConfirmation')
      ),
    legalAge: yup
      .boolean()
      .required('You must be 18 years of age or have parental approval.')
      .oneOf([true], 'You must be 18 years of age or have parental approval.'),
    termsOfService: yup
      .boolean()
      .required('The terms and conditions must be accepted.')
      .oneOf([true], 'The terms and conditions must be accepted.'),
  })
  .required()
