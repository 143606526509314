import { useAppTranslation } from '../../../../hooks/useAppTranslation'

export const useWeekdays = (): string[] => {
  const { translate } = useAppTranslation()

  const weekDays = [
    translate('common.weekDays.monday'),
    translate('common.weekDays.tuesday'),
    translate('common.weekDays.wednesday'),
    translate('common.weekDays.thursday'),
    translate('common.weekDays.friday'),
    translate('common.weekDays.saturday'),
    translate('common.weekDays.sunday'),
  ]

  return weekDays
}
