import React from 'react'
import { Popover } from '../../../../common/Popover'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { theme } from '../../../../../theme/theme'

export const FormatTextPopover = (): JSX.Element => {
  const { translate } = useAppTranslation()
  return (
    <Popover
      buttonProps={{
        label: translate('feedView.formatText.label'),
      }}
    >
      <div className="flex w-[250px] flex-col space-y-3 rounded bg-black p-4 text-white">
        <p style={theme.textVariants.baseBold}>
          {translate('feedView.formatText.title')}
        </p>
        <hr />
        <div className="flex items-center space-x-2">
          <p>**{translate('feedView.formatText.bold')}**</p>
          <p>=</p>
          <b>{translate('feedView.formatText.bold')}</b>
        </div>
        <hr />
        <div className="flex items-center space-x-2">
          <p>*{translate('feedView.formatText.italic')}*</p>
          <p>=</p>
          <i>{translate('feedView.formatText.italic')}</i>
        </div>
        <hr />
        <div className="flex items-center space-x-2">
          <p>## {translate('feedView.formatText.header')}</p>
          <p>=</p>
          <p style={theme.textVariants.h1}>
            {translate('feedView.formatText.header')}
          </p>
        </div>
        <hr />
        <div className="flex items-center space-x-2">
          <div className="flex flex-col">
            <p>- {translate('feedView.formatText.list')}</p>
            <p>- {translate('feedView.formatText.list')}</p>
            <p>- {translate('feedView.formatText.list')}</p>
          </div>
          <p>=</p>
          <div>
            <p>• {translate('feedView.formatText.list')}</p>
            <p>• {translate('feedView.formatText.list')}</p>
            <p>• {translate('feedView.formatText.list')}</p>
          </div>
        </div>
      </div>
    </Popover>
  )
}
