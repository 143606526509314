import React, { useCallback, useEffect, useState } from 'react'
import Fuse from 'fuse.js'
import { observer } from 'mobx-react-lite'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useStores } from '../../../../hooks/useStores'
import { ViewBase } from '../../../../components/common/ViewBase'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { ButtonVariant } from '../../../../components/common/Button'
import { IllustrationChoices } from '../../../../components/common/Illustration'
import { useModal } from '../../../../hooks/useModal'
import { CreateUpdateContractModal } from '../../../../components/society/Contract/CreateUpdateContractModal'
import { ContractsTable } from '../../../../components/society/Contract/ContractsTable'

export const SocietyContractsView = observer((): JSX.Element => {
  const { contractStore } = useStores()
  const { translate } = useAppTranslation()
  const [searchString, setSearchString] = useState('')
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:contracts', {
      '{{ societyName }}': society.name,
    })
  )

  const {
    show: showCreateUpdateModal,
    open: openCreateUpdateModal,
    close: closeCreateUpdateModal,
  } = useModal()

  const onChangeSearchString = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    setSearchString(event.currentTarget.value)
  }

  const contracts = contractStore.sortedContractsForSociety(society._id)

  useEffect(() => {
    contractStore.getContractsPerSociety(society._id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getFilteredContracts = useCallback(() => {
    const options = {
      threshold: 0.2,
      keys: ['title', 'company'],
    }

    const fuse = new Fuse(contracts, options)

    return searchString === ''
      ? contracts
      : fuse.search(searchString).map((result) => result.item)
  }, [contracts, searchString])

  const filteredContracts = getFilteredContracts()

  const loading = contractStore.fetchingContracts === 'pending'

  const buttons = [
    {
      variant: ButtonVariant.PRIMARY,
      onClick: openCreateUpdateModal,
      label: translate('common.actions.add'),
    },
  ]

  const errorView =
    !loading && contracts.length === 0
      ? {
          title: translate('societyContractsView.emptyState.title'),
          subtitle: translate('societyContractsView.emptyState.subtitle'),
          illustration: IllustrationChoices.EMPTY,
        }
      : undefined

  const noSearchResultErrorView =
    !loading && filteredContracts.length === 0 && searchString !== ''
      ? {
          title: translate(
            'societyContractsView.emptyState.noSearchResultsTitle'
          ),
          subtitle: translate(
            'societyContractsView.emptyState.noSearchResultsSubtitle'
          ),
          illustration: IllustrationChoices.EMPTY,
        }
      : undefined

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('societyContractsView.title')}
        buttons={buttons}
        searchBar={{
          value: searchString,
          onChange: onChangeSearchString,
        }}
        loading={loading}
        errorView={errorView}
        noSearchResultErrorView={noSearchResultErrorView}
      >
        <ContractsTable contracts={filteredContracts} />
      </ViewBase>

      <CreateUpdateContractModal
        show={showCreateUpdateModal}
        close={closeCreateUpdateModal}
      />
    </ErrorBoundary>
  )
})
