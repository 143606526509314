import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card } from '../../../../components/common/Card'
import { CardBody } from '../../../../components/common/Card/CardBody'
import { Icon, IconChoices } from '../../../../components/common/Icon'
import { theme } from '../../../../theme/theme'

interface ShortcutCardProps {
  title: string
  subtitle: string
  url: string
  handleSideBarSelection: () => void
}

export const ShortcutCard = ({
  title,
  subtitle,
  url,
  handleSideBarSelection,
}: ShortcutCardProps): JSX.Element => {
  const navigate = useNavigate()

  const navigateToRoute = (): void => {
    navigate(url)
    handleSideBarSelection()
  }

  return (
    <Card onClick={navigateToRoute} className="w-full border-none md:w-72">
      {{
        body: (
          <CardBody>
            <div className="flex h-full flex-col justify-between">
              <div className="flex justify-between">
                <p style={theme.textVariants.lead}>{title}</p>
                <Icon icon={IconChoices.ARROW} />
              </div>
              <p
                className="text-left text-neutral-40"
                style={theme.textVariants.base}
              >
                {subtitle}
              </p>
            </div>
          </CardBody>
        ),
      }}
    </Card>
  )
}
