import React from 'react'
import { observer } from 'mobx-react-lite'
import { Card } from '../../../common/Card'
import { theme } from '../../../../theme/theme'
import { IconChoices } from '../../../common/Icon'
import { ManagementPartner } from '../management_partner'
import { Button, ButtonVariant } from '../../../common/Button'
import { ManagementPartnerLogo } from '../ManagementPartnerLogo'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'

interface ManagementPartnerContactCardProps {
  partner: ManagementPartner
  text: string
}

export const ManagementPartnerContactCard = observer(
  ({ partner, text }: ManagementPartnerContactCardProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()

    const getPhoneNumber = (): string => {
      switch (partner) {
        case ManagementPartner.VILLAAGARNA:
          return '+46107500216'
        default:
          return '+46775200100'
      }
    }

    const onCopyPhone = (): void => {
      navigator.clipboard.writeText(getPhoneNumber())
      setToastNotification(
        ToastType.SUCCESS,
        translate('common.copiedToClipboard')
      )
    }

    return (
      <Card className="w-full bg-white p-5">
        {{
          body: (
            <div className="flex justify-between">
              <div className="text-left">
                <ManagementPartnerLogo partner={partner} />
                <p style={theme.textVariants.base} className="text-neutral-50">
                  {text}
                </p>
              </div>
              <Button
                onClick={onCopyPhone}
                icon={IconChoices.PHONE}
                label={translate('common.contactActions.call')}
                variant={ButtonVariant.PRIMARY}
                size="md"
              />
            </div>
          ),
        }}
      </Card>
    )
  }
)
