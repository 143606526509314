/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, getRoot, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import {
  getResourcesPerSociety as apiGetResourcesPerSociety,
  getResource as apiGetResource,
  createResource as apiCreateResource,
  patchResource as apiPatchResource,
  deleteResource as apiDeleteResource,
} from '../../api/resources'
import { stateType } from '../types/common'
import { RootStore } from './root'
import { setObject } from './helpers'
import { sortByDate } from '../../helpers/sorting'
import { ResourceModel } from '../models/resource'
import { IResourceModel } from '../../interfaces/models/resources.interfaces'
import { NResources } from '../../interfaces/services/resources.interfaces'

export const ResourceStore = types
  .model('ResourceStore')
  .props({
    resources: types.map(ResourceModel),
    fetchingResources: stateType,
    fetchingResource: stateType,
    creatingResource: stateType,
    updatingResource: stateType,
  })
  .views((self) => ({
    get sortedResources() {
      return (
        // @ts-ignore
        (values(self.resources) as SnapshotOut<typeof ResourceModel>[]).sort(
          (a, b) => sortByDate(a.createdAt, b.createdAt, true)
        )
      )
    },
  }))
  .views((self) => ({
    resourcesForSociety(
      societyId: string
    ): SnapshotOut<typeof ResourceModel>[] {
      return self.sortedResources.filter(
        (resource) => resource.societyId === societyId
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.resources.clear()
      self.fetchingResources = 'none'
      self.fetchingResource = 'none'
      self.creatingResource = 'none'
      self.updatingResource = 'none'
    },
    setResources: (resources: IResourceModel[]) => {
      resources.forEach((resource) => {
        // @ts-ignore
        setObject<typeof ResourceModel>(self.resources, ResourceModel, resource)
      })
    },
  }))
  .actions((self) => ({
    getResourcesPerSociety: flow(function* getResourcesPerSociety(
      societyId: string
    ) {
      self.fetchingResources = 'pending'
      try {
        const resp = yield apiGetResourcesPerSociety(societyId)
        const data = resp.data as NResources.NGetPerSocieties.IResponse
        const resources = data.data
        const { media } = data.populated

        const { mediaStore } = getRoot<RootStore>(self)
        if (media) {
          mediaStore.setMedia(media)
        }

        self.setResources(resources)

        self.fetchingResources = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingResources = 'error'
      }
    }),
    getResource: flow(function* getResource(id: string) {
      self.fetchingResource = 'pending'
      try {
        const resp = yield apiGetResource(id)
        const data = resp.data as NResources.NGetById.IResponse
        const resource = data.data
        const { media } = data.populated

        const { mediaStore } = getRoot<RootStore>(self)
        if (media) {
          mediaStore.setMedia(media)
        }

        if (resource !== null) {
          self.setResources([resource])
        }

        self.fetchingResource = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.fetchingResource = 'error'
        return false
      }
    }),
    patchResource: flow(function* patchResource(
      id: string,
      body: NResources.NPatch.IRequestBody
    ) {
      self.updatingResource = 'pending'
      try {
        const resp = yield apiPatchResource(id, body)
        const data = resp.data as NResources.NPatch.IResponse
        const resource = data.data

        if (resource !== null) {
          self.setResources([resource])
        }

        self.updatingResource = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingResource = 'error'
        return false
      }
    }),
    createResource: flow(function* createResource(
      body: NResources.NCreate.IRequestBody
    ) {
      self.creatingResource = 'pending'
      try {
        const resp = yield apiCreateResource(body)
        const data = resp.data as NResources.NCreate.IResponse
        const resource = data.data

        if (resource !== null) {
          self.setResources([resource])
        }

        self.creatingResource = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingResource = 'error'
        return false
      }
    }),
    deleteResource: flow(function* deleteResource(id: string) {
      try {
        yield apiDeleteResource(id)

        const { postStore } = getRoot<RootStore>(self)

        // Not sure if this is a great idea in terms of time complexity.
        const postsReferringResource = postStore.sortedPosts
          .filter((_post) =>
            // @ts-ignore
            _post.resourcesIds.includes(id)
          )
          .map((_post) => _post._id)
        postsReferringResource.forEach((_id) => postStore.deletePost(_id))

        self.resources.delete(id)
        return true
      } catch (error) {
        captureException(error)
        return false
      }
    }),
  }))
