import React from 'react'
import { theme } from '../../../theme/theme'
import { IDropdownSelectOption } from '../../common/DropdownSelect/IDropdownSelectOption'
import { Icon, IconChoices } from '../../common/Icon'

interface DropdownItemContentProps {
  option: IDropdownSelectOption
  height?: string | number
}

export const DropdownItemContent = ({
  option,
  height = 'h-8',
}: DropdownItemContentProps): JSX.Element => {
  return (
    <div
      style={{ ...theme.textVariants.base }}
      className={`flex items-center gap-2 overflow-hidden ${height ?? ''}`}
    >
      {option.image || (option.icon && <Icon icon={option.icon} />)}
      <span className="flex truncate">{option.customText || option.label}</span>
      {option.showCheckmark && (
        <Icon icon={IconChoices.CHECKMARK} highlighted />
      )}
    </div>
  )
}
