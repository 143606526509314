import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useStores } from '../../../../hooks/useStores'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { DocumentModel } from '../../../../state/models/document'
import { DocumentsListItem } from '../DocumentsListItem'
import { reverseUrl, ViewNames } from '../../../../navigation/reverseUrl'
import { Breadcrumb, BreadcrumbItemData } from '../../../common/Breadcrumb'
import { IconChoices } from '../../../common/Icon'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { DocumentsListEmptyState } from './DocumentsListEmptyState'
import { IconToggle } from '../../../common/IconToggle'
import { LocalStorageKeys } from '../../../../types/local-storage'

interface DocumentsListProps {
  section: string
  rootRoute: ViewNames
  documentRoute: ViewNames
  rootSubDocuments: SnapshotOut<typeof DocumentModel>[]
  documents: SnapshotOut<typeof DocumentModel>[]
  noSearchResults: boolean
  documentId?: string
}

export const DocumentsList = observer(
  ({
    section,
    documentId,
    rootRoute,
    documentRoute,
    rootSubDocuments,
    documents,
    noSearchResults,
  }: DocumentsListProps): JSX.Element => {
    const { documentStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { translate } = useAppTranslation()
    const [isListMode, setIsListMode] = useState(
      localStorage.getItem(LocalStorageKeys.DOCUMENTS_LIST_MODE)
    )

    const onChangeListMode = (_isListMode: boolean): void => {
      const updatedValue = _isListMode ? 'list' : 'card'
      localStorage.setItem(LocalStorageKeys.DOCUMENTS_LIST_MODE, updatedValue)
      setIsListMode(updatedValue)
    }

    const getSubDocuments = (): SnapshotOut<typeof DocumentModel>[] => {
      if (documentId) {
        return documentStore.getAllSubDocuments(documentId)
      }

      return rootSubDocuments
    }

    const getRootPath = (): string => {
      return reverseUrl(rootRoute, { id: society._id })
    }

    const getDocumentPath = (docId: string): string => {
      return reverseUrl(documentRoute, {
        id: society._id,
        documentId: docId,
      })
    }

    const findAllParents = (
      currentDocument: SnapshotOut<typeof DocumentModel>,
      previousParents: SnapshotOut<typeof DocumentModel>[]
    ): SnapshotOut<typeof DocumentModel>[] => {
      if (!currentDocument?.parentId) {
        return previousParents
      }
      const parent = rootSubDocuments.find(
        (doc) => doc._id === currentDocument.parentId
      ) as SnapshotOut<typeof DocumentModel>
      if (!parent) {
        return previousParents
      }
      return findAllParents(parent, [parent, ...previousParents])
    }

    const getAllParentDocuments = (): SnapshotOut<typeof DocumentModel>[] => {
      if (documentId) {
        const currentDocument = documentStore.documents.get(
          documentId
        ) as SnapshotOut<typeof DocumentModel>
        const children = getSubDocuments()
        if (children.length > 0) {
          return findAllParents(children[0], [])
        }
        // No children, so mocking a fake child so the empty folder think it's a parent
        return findAllParents(
          {
            parentId: currentDocument._id,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            section,
          },
          []
        )
      }
      return []
    }

    const getAllDocumentBreadcrumbs = (): BreadcrumbItemData[] => {
      const allParentDocuments = getAllParentDocuments()
      const parentBreadcrumbs = allParentDocuments.map((document) => ({
        _id: document._id,
        url: getDocumentPath(document._id),
        title: document.name || document.filename,
      }))
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const rootDocument = documentStore.getRootDocument(society._id, section)

      const rootBreadcrumb = {
        _id: rootDocument ? rootDocument._id : 'root',
        url: getRootPath(),
        title: 'Root',
      }
      return [rootBreadcrumb, ...parentBreadcrumbs]
    }

    const breadcrumbs = getAllDocumentBreadcrumbs()

    const _isListMode = isListMode === 'list'

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="flex w-full flex-col space-y-2">
          <div className="flex justify-between">
            <Breadcrumb
              items={breadcrumbs}
              rootIconSize={18}
              rootIcon={IconChoices.FOLDER}
              rootLabel={translate('documentsList.allDocuments')}
            />
            <IconToggle
              isFirstSelected={_isListMode}
              firstIcon={IconChoices.LIST}
              secondIcon={IconChoices.GRID}
              onChange={onChangeListMode}
            />
          </div>
          {documents.length === 0 ? (
            <DocumentsListEmptyState
              section={section}
              documentId={documentId}
              noSearchResults={noSearchResults}
            />
          ) : (
            <div
              className={`${
                _isListMode ? 'flex flex-col' : 'flex flex-wrap gap-6'
              }`}
            >
              {documents.map((document) => (
                <DocumentsListItem
                  isListMode={_isListMode}
                  key={document._id}
                  document={document}
                  documentRoute={documentRoute}
                />
              ))}
            </div>
          )}
        </div>
      </DndProvider>
    )
  }
)
