import * as yup from 'yup'

export const societyCreateGroupSchema = yup
  .object({
    societyId: yup.string().required(),
    name: yup.string().required(),
    membersList: yup.array(
      yup.object({
        userId: yup.string().required(),
      })
    ),
    unitsList: yup.array(
      yup.object({
        unitId: yup.string().required(),
      })
    ),
    type: yup.string().oneOf(['user', 'unit']),
  })
  .required()
