/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, SnapshotOut, getRoot } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import {
  getContractsPerSociety as apiGetContractsPerSociety,
  createContract as apiCreateContract,
  getContract as apiGetContract,
  updateContract as apiUpdateContract,
  deleteContract as apiDeleteContract,
} from '../../api/contracts'
import { stateType } from '../types/common'
import { setObject } from './helpers'
import { ContractModel } from '../models/contract'
import { IContractModel } from '../../interfaces/models/contracts.interfaces'
import { NContracts } from '../../interfaces/services/contracts.interfaces'
import { RootStore } from './root'
import { IDocumentModel } from '../../interfaces/models/documents.interfaces'

export const ContractStore = types
  .model('ContractStore')
  .props({
    contracts: types.map(ContractModel),
    fetchingContracts: stateType,
    updatingContract: stateType,
    creatingContract: stateType,
  })
  .views((self) => ({
    sortedContractsForSociety(
      societyId: string
    ): SnapshotOut<typeof ContractModel>[] {
      // @ts-ignore
      return (
        // @ts-ignore
        (values(self.contracts) as SnapshotOut<typeof ContractModel>[])
          .filter((contract) => contract.societyId === societyId)
          .sort((a, b) => {
            // Sort by status first
            if (a.status === 'active' && b.status !== 'active') {
              return -1
            }
            if (a.status !== 'active' && b.status === 'active') {
              return 1
            }

            // If both contracts are active, sort by endDate
            if (a.status === 'active' && b.status === 'active') {
              if (a.endDate && b.endDate) {
                // If both contracts have an endDate, sort them by endDate
                return new Date(a.endDate) < new Date(b.endDate) ? -1 : 1
              }
              if (a.endDate) {
                // If only a has an endDate, a comes first
                return -1
              }
              if (b.endDate) {
                // If only b has an endDate, b comes first
                return 1
              }
            }
            // If none of the above conditions are met, don't change the order
            return 0
          })
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.contracts.clear()
      self.fetchingContracts = 'none'
      self.updatingContract = 'none'
      self.creatingContract = 'none'
    },
    setContracts: (contracts: IContractModel[]) => {
      contracts.forEach((contract) => {
        // @ts-ignore
        setObject<typeof ContractModel>(self.contracts, ContractModel, contract)
      })
    },
  }))
  .actions((self) => ({
    processContracts: (
      contracts: IContractModel[],
      documents?: IDocumentModel[]
    ) => {
      const updateStore = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        func: (arg: any) => void,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        collection?: any
      ): void => {
        if (collection) {
          func(collection)
        }
      }

      const { documentStore } = getRoot<RootStore>(self)
      updateStore(documentStore.setDocuments, documents)
      self.setContracts(contracts)
    },
  }))
  .actions((self) => ({
    getContractsPerSociety: flow(function* getContractsPerSociety(
      societyId: string
    ) {
      self.fetchingContracts = 'pending'
      try {
        const resp = yield apiGetContractsPerSociety(societyId)
        const data = resp.data as NContracts.NGetPerSocieties.IResponse
        const contracts = data.data
        const { documents } = data.populated

        self.processContracts(contracts, documents)

        self.fetchingContracts = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingContracts = 'error'
      }
    }),
    getContract: flow(function* getContract(id: string) {
      self.fetchingContracts = 'pending'
      try {
        const resp = yield apiGetContract(id)
        const data = resp.data as NContracts.NGetById.IResponse
        const contract = data.data
        const { documents } = data.populated

        self.processContracts([contract], documents)

        self.fetchingContracts = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingContracts = 'error'
      }
    }),
    updateContract: flow(function* updateContract(
      id: string,
      body: NContracts.NPatch.IRequestBody
    ) {
      self.updatingContract = 'pending'
      try {
        const resp = yield apiUpdateContract(id, body)
        const data = resp.data as NContracts.NPatch.IResponse
        const contract = data.data

        if (contract !== null) {
          self.setContracts([contract])
        }

        self.updatingContract = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingContract = 'error'
        return false
      }
    }),
    createContract: flow(function* createContract(
      body: NContracts.NCreate.IRequestBody
    ) {
      self.creatingContract = 'pending'
      try {
        const resp = yield apiCreateContract(body)
        const data = resp.data as NContracts.NCreate.IResponse
        const contract = data.data

        if (contract !== null) {
          self.setContracts([contract])
        }

        self.creatingContract = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingContract = 'error'
        return false
      }
    }),
    deleteContract: flow(function* deleteContract(id: string) {
      try {
        yield apiDeleteContract(id)
        self.contracts.delete(id)
        return true
      } catch (error) {
        captureException(error)
        return false
      }
    }),
  }))
