import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Modal } from '../../../../../components/common/Modal'
import { ModalHeader } from '../../../../../components/common/Modal/ModalHeader'
import { ModalBody } from '../../../../../components/common/Modal/ModalBody'
import { useStores } from '../../../../../hooks/useStores'
import { AdminListModalContent } from './AdminListModalContent'

interface AdminListModalProps {
  show: boolean
  close: () => void
  adminList?: string[]
}

export const AdminListModal = observer(
  ({ show, close, adminList }: AdminListModalProps): JSX.Element => {
    const { userStore } = useStores()

    useEffect(() => {
      if (adminList) {
        adminList.forEach((adminId) => {
          userStore.getUser(adminId)
        })
      }
    }, [adminList, userStore])

    return (
      <Modal show={show}>
        {{
          header: <ModalHeader onClose={close}>Admins</ModalHeader>,
          body: (
            <ModalBody className="overflow-y-auto">
              <AdminListModalContent adminList={adminList} />
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
