import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Link } from 'react-router-dom'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { theme } from '../../../../theme/theme'
import { UserAvatar } from '../../../common/Avatar'
import { UserModel } from '../../../../state/models/user'
import { useUserRoleUnitsString } from '../../../../hooks/useUserRoleUnitsString'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { Icon, IconChoices } from '../../../common/Icon'

interface RegisterGroupMemberUserListItemProps {
  user: SnapshotOut<typeof UserModel>
  className?: string
}

export const RegisterGroupMemberUserListItem = observer(
  ({ user, className }: RegisterGroupMemberUserListItemProps): JSX.Element => {
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const userRoleUnit = useUserRoleUnitsString(user, society)

    return (
      <Link
        className={`border-1 flex border-b border-l border-r p-4 hover:bg-neutral-96 ${
          className ?? ''
        }`}
        to={reverseUrl('user:society-detail', {
          id: user._id,
          societyId: society._id,
        })}
      >
        <div className="flex flex-1 flex-row justify-between">
          <div className="flex items-center">
            <UserAvatar user={user} />
            <div className="ml-4 mr-1 flex flex-col justify-start space-y-1">
              <p style={theme.textVariants.baseBold}>
                {[user.surname, user.name].join(', ')}
              </p>
              <div className="flex">
                {userRoleUnit.length > 0 && (
                  <p
                    className="text-gray-600 md:line-clamp-1"
                    style={theme.textVariants.base}
                  >
                    {userRoleUnit.join(', ')}
                  </p>
                )}
              </div>
            </div>
          </div>
          <Icon icon={IconChoices.ARROW} />
        </div>
      </Link>
    )
  }
)
