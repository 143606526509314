import React, { FormEvent, KeyboardEvent } from 'react'
import { observer } from 'mobx-react-lite'
import i18next from 'i18next'
import { TextInput } from '../TextInput'

export interface SearchBar {
  value: string
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  placeholder?: string
  className?: string
  widthClassName?: string
  onFocus?: (e: FormEvent<HTMLInputElement>) => void
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void
}

export const SearchBar = observer(
  ({
    value,
    onChange,
    placeholder = `${i18next.t('searchBar.placeholder.search')}`,
    className,
    widthClassName = 'w-[300px]',
    onFocus,
    onKeyUp,
    ...props
  }: SearchBar): JSX.Element => {
    return (
      <div className={className}>
        <TextInput
          className={`${widthClassName}`}
          {...props}
          type="search"
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onChange}
          value={value}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
        />
      </div>
    )
  }
)
