import React from 'react'
import { Link } from 'react-router-dom'
import { SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { MediaImage } from '../../../../common/Image'
import { FacilityModel } from '../../../../../state/models/facility'
import { reverseUrl } from '../../../../../navigation/reverseUrl'
import { theme } from '../../../../../theme/theme'
import BookingPlaceholder from '../../../../../assets/icons/booking_placeholder.svg'

interface FacilityItemProps {
  facility: SnapshotOut<typeof FacilityModel>
}

export const FacilityItem = observer(
  ({ facility }: FacilityItemProps): JSX.Element | null => {
    return (
      <Link
        className="flex w-full cursor-pointer flex-row items-center rounded-lg p-4 hover:bg-neutral-96 
        "
        to={reverseUrl('society:facilities-detail', {
          id: facility.societyId,
          facilityId: facility._id,
        })}
        style={{ textDecoration: 'none' }}
      >
        <div className="flex flex-1 gap-4">
          {facility.coverPhotoId ? (
            <MediaImage
              mediaId={facility.coverPhotoId}
              imageClassName="rounded"
              objectFit="object-contain"
              className="h-[120px] w-[120px]"
            />
          ) : (
            <BookingPlaceholder className="h-[120px] w-[120px] rounded" />
          )}
          <div className="flex flex-1 flex-col">
            <span className="text-black" style={theme.textVariants.lead}>
              {facility.name}
            </span>
            <p
              className="mt-1 text-neutral-40 line-clamp-3"
              style={theme.textVariants.base}
            >
              {facility.description}
            </p>
          </div>
        </div>
      </Link>
    )
  }
)
