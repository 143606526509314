import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { Controller, useForm, UseFormSetError } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../common/Button'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { FormControl } from '../../../common/FormControl'
import { TextInput } from '../../../common/TextInput'
import { theme } from '../../../../theme/theme'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { societyDiscountCodeSchema } from '../../../../forms/schemas/society_discount_code'
import { Linkify } from '../../../common/Linkify'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'

interface DiscountCodeFormProps {
  onSubmit: (
    data: InferType<typeof societyDiscountCodeSchema>,
    setError: UseFormSetError<InferType<typeof societyDiscountCodeSchema>>
  ) => void
  onClose: () => void
  loading: boolean
}

export const DiscountCodeForm = observer(
  ({ onSubmit, onClose, loading }: DiscountCodeFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { getErrorMessage } = useFormErrorMessage()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isSamf } = society

    const getDefaultValues = useCallback((): InferType<
      typeof societyDiscountCodeSchema
    > => {
      return {
        discountCode: '',
      }
    }, [])

    const {
      control,
      handleSubmit,
      setError,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyDiscountCodeSchema),
      defaultValues: getDefaultValues(),
    })

    const _onSubmit = async (
      data: InferType<typeof societyDiscountCodeSchema>
    ): Promise<void> => {
      onSubmit(data, setError)
    }

    const onError = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    return (
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit(_onSubmit, onError)}
      >
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate(
                'invoiceView.forms.discountCode.labels.discountCode'
              )}
              name={name}
              error={
                errors.discountCode && getErrorMessage(errors.discountCode)
              }
            >
              <TextInput
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder={translate(
                  'invoiceView.forms.discountCode.placeholders.discountCode'
                )}
              />
            </FormControl>
          )}
          name="discountCode"
        />
        <div className="mt-2">
          <p style={theme.textVariants.baseBold}>
            {translate(
              'invoiceView.modals.discountCode.informationSection.title'
            )}
          </p>
          <Linkify>
            <p style={theme.textVariants.base} className="mt-1">
              {translate(
                isSamf
                  ? 'invoiceView.modals.discountCode.informationSection.textSamf'
                  : 'invoiceView.modals.discountCode.informationSection.textBrf'
              )}
            </p>
          </Linkify>
        </div>
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-3">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
            variant={ButtonVariant.TEXT}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid || loading}
            loading={loading}
            label={translate('common.actions.continue')}
            type="submit"
          />
        </div>
      </form>
    )
  }
)
