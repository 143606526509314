/* eslint-disable no-param-reassign */
import { captureException } from '@sentry/react'
import { flow, types } from 'mobx-state-tree'
import { createUser as apiCreateUser } from '../../api/users'
import { stateType } from '../types/common'

export const SignUpStore = types
  .model('SignUpStore')
  .props({
    name: types.maybe(types.string),
    surname: types.maybe(types.string),
    email: types.maybe(types.string),
    legalAge: types.maybe(types.boolean),
    acceptsTermsOfService: types.maybe(types.boolean),
    creatingAccount: stateType,
  })
  .actions((self) => ({
    reset: () => {
      self.name = undefined
      self.surname = undefined
      self.email = undefined
      self.legalAge = false
      self.acceptsTermsOfService = false
      self.creatingAccount = 'none'
    },
    setName(name: string) {
      self.name = name
    },
    setSurname(surname: string) {
      self.surname = surname
    },
    setEmail(email: string) {
      self.email = email
    },
    setLegalAge(legalAge: boolean) {
      self.legalAge = legalAge
    },
    setAcceptsTermsOfService(acceptsTermsOfService: boolean) {
      self.acceptsTermsOfService = acceptsTermsOfService
    },
  }))
  .actions((self) => ({
    createUser: flow(function* createUser(
      name: string,
      surname: string,
      email: string,
      password: string,
      language: 'se' | 'en'
    ) {
      self.creatingAccount = 'pending'

      try {
        const resp = yield apiCreateUser(
          {
            email,
            name,
            surname,
            password,
            language,
          },
          true
        )

        // Reset fields
        self.reset()
        return resp.data.data._id
      } catch (error) {
        captureException(error)
        self.creatingAccount = 'error'
        return false
      }
    }),
  }))
