import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { capitalize } from '../../../helpers/string'
import { theme } from '../../../theme/theme'
import { UnitModel } from '../../../state/models/unit'
import { RegisterUnitItemResidentsContent } from './RegisterUnitItemResidents'

interface RegisterUnitItemSectionContentProps {
  title: string
  text?: string
  unit?: SnapshotOut<typeof UnitModel>
}

export const RegisterUnitItemSectionContent = observer(
  ({ title, text, unit }: RegisterUnitItemSectionContentProps): JSX.Element => {
    return (
      <div>
        <p style={theme.textVariants.baseBold}>{title}</p>
        {text && (
          <p style={theme.textVariants.base}>
            {capitalize(text.toLowerCase())}
          </p>
        )}
        {unit && <RegisterUnitItemResidentsContent unit={unit} />}
      </div>
    )
  }
)
