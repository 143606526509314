import React, { useRef } from 'react'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../../common/Icon'
import { Modal } from '../../common/Modal'
import { ModalBody } from '../../common/Modal/ModalBody'
import { ModalHeader } from '../../common/Modal/ModalHeader'
import { LikesModalContent } from './LikesModalContent'

interface LikesModalProps {
  likes: string[]
  societyId: string
  show: boolean
  close: () => void
}

export const LikesModal = ({
  likes,
  societyId,
  show,
  close,
}: LikesModalProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const ref = useRef(null)
  return (
    <Modal ref={ref} show={show} size="sm">
      {{
        header: (
          <ModalHeader onClose={close}>
            <div className="flex gap-3">
              <Icon icon={IconChoices.HEART} highlighted />
              <p style={theme.textVariants.lead}>
                {likes.length} {translate('common.likes').toLowerCase()}
              </p>
            </div>
          </ModalHeader>
        ),
        body: (
          <ModalBody className="max-h-96 overflow-y-auto">
            <LikesModalContent likes={likes} societyId={societyId} />
          </ModalBody>
        ),
      }}
    </Modal>
  )
}
