import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../../../common/Button'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { societyDocumentUploadSchema } from '../../../../../../forms/schemas/society_document_upload'
import { DropZone } from '../../../../../common/DropZone'
import { FormControl } from '../../../../../common/FormControl'
import { TextInput } from '../../../../../common/TextInput'
import { useToastNotifications } from '../../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../../common/Toast/toast-type'
import { NOTIFICATION_SETTINGS_VALUE } from '../../../../../../helpers/notification_settings_options'

interface CreateSocietyWebsiteDocumentFormProps {
  onError: (error: unknown) => void
  onSubmit: (
    data: InferType<typeof societyDocumentUploadSchema>
  ) => Promise<void>
  onClose: () => void
  loading: boolean
}

export const CreateSocietyWebsiteDocumentForm = observer(
  ({
    loading,
    onError,
    onSubmit,
    onClose,
  }: CreateSocietyWebsiteDocumentFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()

    const getDefaultValues = useCallback((): InferType<
      typeof societyDocumentUploadSchema
    > => {
      return {
        files: [],
        notificationSettings: NOTIFICATION_SETTINGS_VALUE.NONE,
      }
    }, [])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyDocumentUploadSchema),
      defaultValues: getDefaultValues(),
    })

    const { fields, append, remove } = useFieldArray({
      name: 'files',
      control,
    })

    const onDocumentUpload = (
      status: 'error' | 'accepted',
      file: {
        base64: string
        name: string
        type: string
      }
    ): void => {
      if (status === 'accepted') {
        append({
          mimeType: file.type,
          base64: file.base64,
          filename: file.name,
          name: file.name,
        })
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }
    }

    return (
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Controller
          control={control}
          render={() => <DropZone onUpload={onDocumentUpload} multiple />}
          name="files"
        />
        {fields.length > 0 && (
          <p className="mb-2 mt-4">
            {translate('createUpdateDocumentForm.uploadedFiles')}
          </p>
        )}
        {fields.map((field, index) => (
          <div
            key={field.id}
            className="flex w-full flex-row items-start space-x-4"
          >
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControl
                  className="w-full"
                  error={
                    errors.files?.[index]?.name?.type === 'required'
                      ? translate('form.errors.required')
                      : ''
                  }
                  name={`files.${index}.name`}
                >
                  <TextInput
                    className="w-full"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.files?.[index]?.name}
                    onDeleteIconPress={() => remove(index)}
                  />
                </FormControl>
              )}
              name={`files.${index}.name`}
            />
          </div>
        ))}
        <div className="mt-4 flex flex-shrink-0 flex-wrap items-center justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            loading={loading}
            disabled={!isValid || loading}
            label={translate('common.actions.save')}
            type="submit"
          />
        </div>
      </form>
    )
  }
)
