/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Instance, types } from 'mobx-state-tree'
import { createContext } from 'react'
import { captureException } from '@sentry/react'
import { AdminStore } from './admin'
import { AuthenticationStore } from './authentication'
import { PostStore } from './posts'
import { CommentStore } from './comments'
import { UserStore } from './users'
import { MediaStore } from './media'
import { DocumentStore } from './documents'
import { PollStore } from './polls'
import { NotificationsStore } from './notifications'
import { SocietyStore } from './societies'
import { UnitStore } from './units'
import { EventStore } from './events'
import { ChatRoomStore } from './chat-rooms'
import { ChatMessageStore } from './chat-messages'
import { StatisticsStore } from './statistics'
import { FacilityStore } from './facilities'
import { FAQStore } from './faqs'
import { SocietyWebsiteStore } from './society-websites'
import { SocietyContactStore } from './society-contacts'
import { HouseRuleStore } from './house-rules'
import { SocietyWidgetStore } from './society-widgets'
import { SocietyEntranceStore } from './society-entrances'
import { SignUpStore } from './sign-up'
import { GroupStore } from './groups'
import { ResourceStore } from './resources'
import { BookingStore } from './bookings'
import { SocietyBoardroomContactStore } from './society-boardroom-contacts'
import { RequestStore } from './requests'
import { SettingStore } from './settings'
import { PartnerStore } from './partners'
import { ListingStore } from './listings'
import { LocalOfferStore } from './local-offers'
import { StatusStore } from './status'
import { TrackingStore } from './tracking'
import { ContractStore } from './contracts'
import { BoardRoomEventStore } from './boardroom-events'

/**
 * A RootStore model.
 */
export const RootStoreModel = types
  .model('RootStore')
  .props({
    adminStore: types.optional(AdminStore, {
      hasPermissions: false,
      isTwilight: false,
      fetchingUser: 'none',
      loggingInAsUser: 'none',
      changingUserPassword: 'none',
      changingUserEmail: 'none',
      settingSocietyPartner: 'none',
      inactivatingSociety: 'none',
      updatingSociety: 'none',
      updatingSocietyWebsite: 'none',
      downloadingSocietyList: 'none',
      deletingSociety: 'none',
      deletingUser: 'none',
      upgradingSociety: 'none',
      downgradingSociety: 'none',
    }),
    authenticationStore: types.optional(AuthenticationStore, {
      token: undefined,
      loggingIn: 'none',
    }),
    signUpStore: types.optional(SignUpStore, {
      name: undefined,
      surname: undefined,
      email: undefined,
      legalAge: false,
      acceptsTermsOfService: false,
      creatingAccount: 'none',
    }),
    postStore: types.optional(PostStore, {
      fetchingFeed: 'none',
      creatingPost: 'none',
      updatingPost: 'none',
      reportingPost: 'none',
      hasFetchedPostsOnce: false,
    }),
    commentStore: types.optional(CommentStore, {
      creatingComment: 'none',
      deletingComment: 'none',
      updatingComment: 'none',
    }),
    userStore: types.optional(UserStore, {
      fetchingUser: 'none',
      updatingUser: 'none',
      deletingUser: 'none',
      updatingEmail: 'none',
      updatingPassword: 'none',
      updatingProfilePicture: 'none',
      resendingVerificationCode: 'none',
      sendingPasswordReset: 'none',
      resettingPassword: 'none',
      checkingEmailRegistered: 'none',
      verifyingUser: 'none',
      hasFetchedUserOnce: false,
    }),
    mediaStore: types.optional(MediaStore, {
      creatingMedia: 'none',
      deletingMedia: 'none',
    }),
    documentStore: types.optional(DocumentStore, {
      fetchingDocuments: 'none',
      creatingDocument: 'none',
      creatingFolder: 'none',
      deletingDocument: 'none',
      updatingDocument: 'none',
      hasFetchedDocumentsOnce: false,
    }),
    pollStore: types.optional(PollStore, {
      voting: 'none',
      creatingPoll: 'none',
      updatingPoll: 'none',
    }),
    notificationsStore: types.optional(NotificationsStore, {
      fetchingNotifications: 'none',
      unseenCount: 0,
      unseenOneOnOneChatCount: 0,
      unseenInterestsChatCount: 0,
      unseenBoardChatCount: 0,
      unseenBoardQuestionsChatCount: 0,
      unseenResidentsQuestionsChatCount: 0,
      hasFetchedNotificationsOnce: false,
    }),
    societyStore: types.optional(SocietyStore, {
      creatingSociety: 'none',
      fetchingSocieties: 'none',
      fetchingSociety: 'none',
      updatingSociety: 'none',
      fetchingPreApprovedSocieties: 'none',
      fetchingSocietiesByOrganisationNumber: 'done',
      denyingPreApproved: 'none',
      updatingAdminUsers: 'none',
      updatingBlockedUsers: 'none',
      updatingMasterAdmin: 'none',
      searchingSocieties: 'none',
      invitingAllPreApprovedUsers: 'none',
      creatingUserRole: 'none',
      deletingUserRole: 'none',
      removingUserFromSociety: 'none',
      removingSelfFromSociety: 'none',
      activatingSociety: 'none',
      hasFetchedSocietiesOnce: false,
    }),
    requestStore: types.optional(RequestStore, {
      fetchingRequests: 'none',
      fetchingRequest: 'none',
      denyingRequest: 'none',
      acceptingRequest: 'none',
      acceptingPreApproved: 'none',
      sendingRequest: 'none',
      hasFetchedRequestsOnce: false,
    }),
    unitStore: types.optional(UnitStore, {
      fetchingUnits: 'none',
      creatingUnit: 'none',
      updatingUnit: 'none',
      deletingUnit: 'none',
      updatingUserUnits: 'none',
      hasFetchedUnitsOnce: false,
    }),
    eventStore: types.optional(EventStore, {
      fetchingEvents: 'none',
      creatingEvent: 'none',
      updatingEvent: 'none',
      updatingEventAttendance: 'none',
    }),
    chatRoomStore: types.optional(ChatRoomStore, {
      fetchingChatRoomsOneOnOne: 'none',
      fetchingChatRoomsInterests: 'none',
      fetchingChatRoomsQuestions: 'none',
      fetchingChatRoomsBoard: 'none',
      fetchingChatRoom: 'none',
      creatingChatRoom: 'none',
      updatingChatRoom: 'none',
      joiningChatRoom: 'none',
      togglingHideUntilNotification: 'none',
      hasFetchedUserRoomsOneOnOneOnce: false,
      hasFetchedUserRoomsInterestsOnce: false,
      hasFetchedUserRoomsQuestionsResidentOnce: false,
      hasFetchedUserRoomsQuestionsBoardOnce: false,
      hasFetchedUserRoomsBoardOnce: false,
      lastReceivedSocketMessageId: undefined,
    }),
    chatMessageStore: types.optional(ChatMessageStore, {
      fetchingChatMessages: 'none',
    }),
    statisticsStore: types.optional(StatisticsStore, {
      fetchingStatistics: 'none',
    }),
    facilitiesStore: types.optional(FacilityStore, {
      fetchingFacilities: 'none',
      fetchingFacility: 'none',
      deletingFacility: 'none',
      creatingFacility: 'none',
      updatingFacility: 'none',
    }),
    bookingsStore: types.optional(BookingStore, {
      fetchingBookings: 'none',
      creatingBooking: 'none',
      deletingBooking: 'none',
    }),
    faqsStore: types.optional(FAQStore, {
      fetchingFAQs: 'none',
      creatingFAQ: 'none',
      updatingFAQ: 'none',
      deletingFAQ: 'none',
    }),
    societyWebsitesStore: types.optional(SocietyWebsiteStore, {
      fetchingWebsites: 'none',
      updatingWebsite: 'none',
    }),
    societyBoardroomContactsStore: types.optional(
      SocietyBoardroomContactStore,
      {
        fetchingContacts: 'none',
        updatingContact: 'none',
        creatingContact: 'none',
      }
    ),
    societyContactsStore: types.optional(SocietyContactStore, {
      fetchingContacts: 'none',
      updatingContact: 'none',
      creatingContact: 'none',
    }),
    societyEntrancesStore: types.optional(SocietyEntranceStore, {
      fetchingEntrances: 'none',
      updatingEntrance: 'none',
      creatingEntrance: 'none',
    }),
    societyWidgetsStore: types.optional(SocietyWidgetStore, {
      fetchingWidgets: 'none',
      creatingWidget: 'none',
      updatingWidget: 'none',
      deletingWidget: 'none',
    }),
    houseRulesStore: types.optional(HouseRuleStore, {
      fetchingHouseRules: 'none',
      updatingHouseRule: 'none',
      creatingHouseRule: 'none',
    }),
    localOffersStore: types.optional(LocalOfferStore, {
      fetchingLocalOffers: 'none',
      updatingLocalOffer: 'none',
      creatingLocalOffer: 'none',
    }),
    groupStore: types.optional(GroupStore, {
      fetchingGroups: 'none',
      updatingGroup: 'none',
      creatingGroup: 'none',
    }),
    resourcesStore: types.optional(ResourceStore, {
      fetchingResources: 'none',
      fetchingResource: 'none',
      creatingResource: 'none',
      updatingResource: 'none',
    }),
    settingsStore: types.optional(SettingStore, {
      fetchingSettings: 'none',
    }),
    partnersStore: types.optional(PartnerStore, {
      activatingSociety: 'none',
      creatingSocietyFolders: 'none',
    }),
    listingsStore: types.optional(ListingStore, {
      fetchingListings: 'none',
      fetchingSold: 'none',
    }),
    statusStore: types.optional(StatusStore, {
      fetchingStatusMessages: 'none',
    }),
    trackingStore: types.optional(TrackingStore, {
      lastTrackingPathName: '/',
    }),
    contractStore: types.optional(ContractStore, {
      fetchingContracts: 'none',
      updatingContract: 'none',
      creatingContract: 'none',
    }),
    boardRoomEventStore: types.optional(BoardRoomEventStore, {
      fetchingEvents: 'none',
      updatingEvent: 'none',
      creatingEvent: 'none',
      updatingEventAttendance: 'none',
    }),
  })
  .actions((self) => ({
    reset() {
      Object.keys(self).forEach((key) => {
        // @ts-ignore
        if (self[key].reset) {
          // @ts-ignore
          self[key].reset()
        } else {
          captureException(new Error(`Missing store reset for ${key}`))
        }
      })
    },
  }))

/**
 * The RootStore instance.
 */
export type RootStore = Instance<typeof RootStoreModel>

// /**
//  * The data of a RootStore.
//  */
// export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}

/**
 * Create a context we can use to
 * - Provide access to our stores from our root component
 * - Consume stores in our screens (or other components, though it's
 *   preferable to just connect screens)
 */
export const RootStoreContext = createContext<RootStore>({} as RootStore)

/**
 * The provider our root component will use to expose the root store
 */
export const RootStoreProvider = RootStoreContext.Provider

/**
 * Setup the root state.
 */

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const setupRootStore = async () => {
  return RootStoreModel.create({})
}
