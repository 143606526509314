import { useAppTranslation } from '../../../../hooks/useAppTranslation'

interface DropdownOption {
  value: string
  label: string
}

interface DropdownOptions {
  visibilityOptions: DropdownOption[]
  bookingGranularityOptions: DropdownOption[]
  rulesOptions: DropdownOption[]
}

export const useDropdownOptions = (): DropdownOptions => {
  const { translate } = useAppTranslation()

  const visibilityOptions = [
    {
      value: 'all',
      label: translate('createUpdateFacility.form.options.visibility.all'),
    },
    {
      value: 'adminonly',
      label: translate(
        'createUpdateFacility.form.options.visibility.adminOnly'
      ),
    },
  ]

  const bookingGranularityOptions = [
    {
      value: '1hr',
      label: translate(
        'createUpdateFacility.form.options.bookingGranularity.oneHour'
      ),
    },
    {
      value: '2hr',
      label: translate(
        'createUpdateFacility.form.options.bookingGranularity.twoHours'
      ),
    },
    {
      value: '3hr',
      label: translate(
        'createUpdateFacility.form.options.bookingGranularity.threeHours'
      ),
    },
    {
      value: '4hr',
      label: translate(
        'createUpdateFacility.form.options.bookingGranularity.fourHours'
      ),
    },
    {
      value: '5hr',
      label: translate(
        'createUpdateFacility.form.options.bookingGranularity.fiveHours'
      ),
    },
    {
      value: 'full-day',
      label: translate(
        'createUpdateFacility.form.options.bookingGranularity.fullDay'
      ),
    },
  ]

  const rulesOptions = [
    {
      value: 'one-active',
      label: translate('createUpdateFacility.form.options.rules.oneActive'),
    },
    {
      value: 'two-active',
      label: translate('createUpdateFacility.form.options.rules.twoActive'),
    },
    {
      value: 'three-active',
      label: translate('createUpdateFacility.form.options.rules.threeActive'),
    },
    {
      value: 'four-active',
      label: translate('createUpdateFacility.form.options.rules.fourActive'),
    },
    {
      value: 'five-active',
      label: translate('createUpdateFacility.form.options.rules.fiveActive'),
    },
    {
      value: 'unlimited',
      label: translate('createUpdateFacility.form.options.rules.unlimited'),
    },
  ]

  return { visibilityOptions, bookingGranularityOptions, rulesOptions }
}
