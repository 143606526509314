import { useIsMobile } from '../../../../hooks/useIsMobile'
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions'

export const useMessageTextAreaRows = (): number => {
  const { height } = useWindowDimensions()
  const isMobile = useIsMobile()

  if (isMobile) {
    return 8
  }

  if (height > 1280) {
    return 15
  }
  if (height > 720) {
    return 15
  }

  return 6
}
