import React from 'react'
import { theme } from '../../../theme/theme'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { Icon, IconChoices } from '../../common/Icon'

export const PostImportant = (): JSX.Element => {
  const { translate } = useAppTranslation()

  return (
    <div
      className="flex items-center justify-center
    rounded-md border border-brandGreen bg-opacity-20 p-3"
      style={{ backgroundColor: theme.colors.brandLightGreen }}
    >
      <Icon icon={IconChoices.INFORMATION} size={20} highlighted />
      <p className="ml-2" style={theme.textVariants.captionBold}>
        {translate('post.important')}
      </p>
    </div>
  )
}
