import * as yup from 'yup'

export const societyDocumentUploadSchema = yup
  .object({
    files: yup
      .array()
      .of(
        yup.object({
          mimeType: yup.string().required(),
          base64: yup.string().required(),
          filename: yup.string().required(),
          name: yup.string().required(),
        })
      )
      .min(1)
      .required(),
    notificationSettings: yup
      .string()
      .oneOf(['none', 'feed', 'notification', 'notification+feed'])
      .required(),
  })
  .required()
