import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { PreApprovedModel } from '../../../../../state/models/society'
import { useStores } from '../../../../../hooks/useStores'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { useModal } from '../../../../../hooks/useModal'
import { SocietyInviteModal } from '../../SocietyInviteModal'
import { Icon, IconChoices } from '../../../../common/Icon'
import { Button, ButtonVariant } from '../../../../common/Button'
import { theme } from '../../../../../theme/theme'

interface SocietyInviteBannerProps {
  preApproved: SnapshotOut<typeof PreApprovedModel>
  showIcon?: boolean
}

export const SocietyInviteBanner = observer(
  ({ preApproved }: SocietyInviteBannerProps): JSX.Element | null => {
    const { societyStore } = useStores()
    const { translate } = useAppTranslation()
    const {
      show: showSocietyInviteModal,
      open: openSocietyInviteModal,
      close: closeSocietyInviteModal,
    } = useModal()

    const society = societyStore.societies.get(preApproved.societyId)

    return (
      <>
        <div
          className="flex w-full items-center justify-between bg-almostBrandGreen 
        px-4 py-4 md:h-16 md:px-20"
        >
          <Icon
            size={40}
            icon={IconChoices.INFORMATION}
            color={theme.colors.white}
            className="hidden md:block"
          />
          <p style={theme.textVariants.baseBold}>
            {translate('societyInviteBanner.invited', {
              societyName: society?.name,
            })}
          </p>
          <Button
            size="sm"
            variant={ButtonVariant.PRIMARY}
            onClick={openSocietyInviteModal}
            label={translate('societyInviteBanner.details')}
          />
        </div>
        <SocietyInviteModal
          preApproved={preApproved}
          show={showSocietyInviteModal}
          close={closeSocietyInviteModal}
        />
      </>
    )
  }
)
