import React from 'react'
import { Button, ButtonVariant } from '../Button'

export interface PaginationProps {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
  displayPages?: number
}

export const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  displayPages = 5,
}: PaginationProps): JSX.Element => {
  const canGoPrevious = currentPage > 1
  const canGoNext = currentPage < totalPages

  const handlePageChange = (page: number): void => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page)
    }
  }

  const renderPageNumbers = (): JSX.Element[] => {
    const pageNumbers = []
    const startPage = Math.max(1, currentPage - Math.floor(displayPages / 2))
    for (
      let i = startPage;
      i <= Math.min(totalPages, startPage + displayPages - 1);
      i += 1
    ) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          disabled={currentPage === i}
        >
          <span className={`${currentPage === i ? 'underline' : ''}`}>{i}</span>
        </button>
      )
    }

    if (currentPage > displayPages - 1) {
      pageNumbers.unshift(<span key="first-dots">...</span>)
    }

    if (startPage > 1) {
      pageNumbers.unshift(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          disabled={currentPage === 1}
        >
          1
        </button>
      )
    }

    if (startPage + displayPages - 1 < totalPages) {
      pageNumbers.push(<span key="last-dots">...</span>)
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          disabled={currentPage === totalPages}
        >
          {totalPages}
        </button>
      )
    }
    return pageNumbers
  }

  return (
    <div className="flex justify-center gap-2">
      <Button
        variant={ButtonVariant.PRIMARY}
        size="xs"
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
        label="<<"
      />
      <Button
        variant={ButtonVariant.PRIMARY}
        size="xs"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={!canGoPrevious}
        label="<"
      />

      <div className="mx-2 flex gap-2">{renderPageNumbers()}</div>

      <Button
        variant={ButtonVariant.PRIMARY}
        size="xs"
        label=">"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={!canGoNext}
      />
      <Button
        variant={ButtonVariant.PRIMARY}
        size="xs"
        label=">>"
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
      />
    </div>
  )
}
