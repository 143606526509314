import { AxiosResponse } from 'axios'
import { createUrl } from './helpers'
import axiosAuth from './config'
import { NBookings } from '../interfaces/services/bookings.interfaces'

export const BO0KINGS_POPULATE = ['users.media', 'units.entrances']

export const getBookingsPerFacility = (
  id: string,
  fromDate?: Date,
  toDate?: Date
): Promise<AxiosResponse<NBookings.NGetPerFacility.IResponse>> => {
  return axiosAuth.get<NBookings.NGetPerFacility.IResponse>(
    createUrl(`/bookings/get-per-facility/${id}`, {
      populate: BO0KINGS_POPULATE.join(';'),
      ...(fromDate && { fromDate: fromDate.getTime() }),
      ...(toDate && { toDate: toDate.getTime() }),
    })
  )
}

export const getBookingsPerUnit = (
  unitId: string,
  fromDate?: Date
): Promise<AxiosResponse<NBookings.NGetPerUnit.IResponse>> => {
  return axiosAuth.get<NBookings.NGetPerUnit.IResponse>(
    createUrl(`/bookings/get-per-unit/${unitId}`, {
      populate: BO0KINGS_POPULATE.join(';'),
      ...(fromDate && { fromDate: fromDate.getTime() }),
    })
  )
}

export const createBooking = (
  body: NBookings.NCreate.IRequestBody
): Promise<AxiosResponse<NBookings.NCreate.IResponse>> => {
  return axiosAuth.post<NBookings.NCreate.IResponse>(
    createUrl(`/bookings/booking`),
    body
  )
}

export const deleteBooking = (
  id: string
): Promise<AxiosResponse<NBookings.NDel.IResponse>> => {
  return axiosAuth.delete<NBookings.NDel.IResponse>(
    createUrl(`/bookings/booking/${id}`)
  )
}
