import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from './useStores'
import { SocietyModel } from '../state/models/society'
import { BoardRole } from '../types/board-roles'

export const useUserRole = (
  userId: string,
  society: SnapshotOut<typeof SocietyModel> | undefined
):
  | 'member'
  | 'president'
  | 'vicepresident'
  | 'treasury'
  | 'secretary'
  | 'alternate'
  | 'advisor'
  | 'nominating-committee'
  | BoardRole.NOMINATING_COMMITTEE
  | undefined => {
  const { userStore } = useStores()

  const user = userId ? userStore.users.get(userId) : undefined
  const userBoardRole =
    user && society?.boardMembersList
      ? society?.boardMembersList.find((_member) => _member.userId === user._id)
          ?.role
      : undefined
  const userOnNominattingCommittee =
    user && society?.nominatingCommitteeList
      ? society?.nominatingCommitteeList.find(
          (_member) => _member.userId === user._id
        )
      : undefined

  const userRole =
    userBoardRole ||
    (userOnNominattingCommittee ? BoardRole.NOMINATING_COMMITTEE : undefined)

  return userRole
}
