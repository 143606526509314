import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { enGB, sv } from 'date-fns/locale'
import { useLocale } from '../../../hooks/useLocale'

import 'react-datepicker/dist/react-datepicker.css'
// Manually override css
import './styles.css'
import {
  dateTimeAsUTCWithoutCompensation,
  ignoreTimezoneDate,
} from '../../../helpers/date'

// Register Locales
registerLocale('en', enGB)
registerLocale('sv', sv)

const commonClassNames = `
        h-12 w-full rounded border border-solid border-neutral-80
        px-[15px] hover:border-brandGreen/50 disabled:hover:border-neutral-80`

export interface DateTimeInputProps {
  value?: Date
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange: (date: Date) => void
  minDate?: Date
  maxDate?: Date
  showWeekNumbers?: boolean
  showTimeSelect?: boolean
  showTimeSelectOnly?: boolean
  dateFormat?: string
  error?: boolean
  className?: string
  selectsStart?: boolean
  selectsEnd?: boolean
  selected?: Date | null
  startDate?: Date | null
  endDate?: Date | null
  inline?: boolean
  highlightDates?: Date[]
  timeInterval?: number
  timeCaption?: string
  disableRawInput?: boolean
}

export const DateTimeInput = ({
  value,
  onBlur,
  onChange,
  minDate,
  maxDate,
  showWeekNumbers = true,
  showTimeSelect,
  showTimeSelectOnly,
  dateFormat = showTimeSelect ? "d MMMM yyyy 'kl.' HH:mm" : 'd MMMM yyyy',
  error,
  className,
  selectsStart = false,
  selectsEnd = false,
  selected,
  startDate,
  endDate,
  inline,
  highlightDates,
  timeInterval,
  timeCaption,
  disableRawInput,
}: DateTimeInputProps): JSX.Element => {
  const locale = useLocale()
  const sanitizedLocale = locale.includes('sv') ? 'sv' : 'en'

  const getSelected = (): Date | undefined => {
    if (selected) {
      return selected
    }
    return value ? ignoreTimezoneDate(value) : value
  }

  const selectedValue = getSelected()

  return (
    <DatePicker
      selected={selectedValue}
      onChange={(date) =>
        date ? onChange(dateTimeAsUTCWithoutCompensation(date)) : date
      }
      onBlur={onBlur}
      className={`${commonClassNames} ${className ?? ''}
       ${error ? 'focus:outline-red' : 'focus:outline-brandGreen/50'}`}
      minDate={minDate}
      maxDate={maxDate}
      dateFormat={dateFormat}
      showWeekNumbers={showWeekNumbers}
      showTimeSelect={showTimeSelect}
      showTimeSelectOnly={showTimeSelectOnly}
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
      startDate={startDate}
      endDate={endDate}
      inline={inline}
      highlightDates={highlightDates}
      timeIntervals={timeInterval}
      timeCaption={timeCaption}
      locale={sanitizedLocale}
      {...(disableRawInput
        ? {
            onChangeRaw: (e) => e.preventDefault(),
          }
        : {})}
    />
  )
}

export interface NullableDateTimeInputProps {
  value?: Date | null

  onChange: (date: Date | null) => void
  minDate?: Date | null
  maxDate?: Date | null
  showWeekNumbers?: boolean
  showTimeSelect?: boolean
  showTimeSelectOnly?: boolean
  dateFormat?: string
  error?: boolean
  className?: string
  selectsStart?: boolean
  selectsEnd?: boolean
  startDate?: Date | null
  endDate?: Date | null
  inline?: boolean
  highlightDates?: Date[]
  timeInterval?: number
  timeCaption?: string
}

export const NullableDateTimeInput = ({
  value,
  onChange,
  minDate,
  maxDate,
  showWeekNumbers = true,
  showTimeSelect,
  showTimeSelectOnly,
  dateFormat = showTimeSelect ? "d MMMM yyyy 'kl.' HH:mm" : 'd MMMM yyyy',
  error,
  className,
  selectsStart = false,
  selectsEnd = false,
  startDate,
  endDate,
  inline,
  highlightDates,
  timeInterval,
  timeCaption,
}: NullableDateTimeInputProps): JSX.Element => {
  const locale = useLocale()
  const sanitizedLocale = locale.includes('sv') ? 'sv' : 'en'

  const getSelected = (): Date | undefined | null => {
    if (value) {
      return ignoreTimezoneDate(value)
    }
    return undefined
  }

  const selectedValue = getSelected()

  return (
    <DatePicker
      selected={selectedValue}
      onChange={(date) => {
        if (date) {
          onChange(dateTimeAsUTCWithoutCompensation(date))
        }
      }}
      onChangeRaw={(e) => {
        if (!e.target.value) {
          onChange(null)
        }
      }}
      className={`${commonClassNames} ${className ?? ''}
       ${error ? 'focus:outline-red' : 'focus:outline-brandGreen/50'}`}
      minDate={minDate}
      maxDate={maxDate}
      dateFormat={dateFormat}
      showWeekNumbers={showWeekNumbers}
      showTimeSelect={showTimeSelect}
      showTimeSelectOnly={showTimeSelectOnly}
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
      startDate={startDate}
      endDate={endDate}
      inline={inline}
      highlightDates={highlightDates}
      timeIntervals={timeInterval}
      timeCaption={timeCaption}
      locale={sanitizedLocale}
      readOnly={false}
    />
  )
}
