import { Instance, SnapshotOut } from 'mobx-state-tree'
import { useEffect, useState } from 'react'
import { SocietyModel } from '../../../../state/models/society'
import { useStores } from '../../../../hooks/useStores'
import { getSocietiesByIds } from '../../../../api/admin'
import { NAdmin } from '../../../../interfaces/services/admin.interfaces'
import { ISocietiesModel } from '../../../../interfaces/models/societies.interfaces'

interface AdminSociety {
  adminSociety: SnapshotOut<typeof SocietyModel> | undefined
  triggerRefresh: () => void
}

export const useAdminSociety = (
  societyId: string | undefined
): AdminSociety => {
  const [refreshTrigger, setRefreshTrigger] = useState(false)
  const [societies, setSocieties] = useState<Instance<ISocietiesModel[]>>()
  const { adminStore } = useStores()

  useEffect(() => {
    const getSociety = async (): Promise<void> => {
      if (societyId) {
        const resp = await getSocietiesByIds({ societyIds: [societyId] })
        const data = resp.data as NAdmin.NGetSocietiesByIds.IResponse
        const _societies = data.data
        setSocieties(_societies)
      }
    }
    getSociety()
  }, [adminStore, societyId, refreshTrigger])

  const triggerRefresh = (): void => {
    setRefreshTrigger(!refreshTrigger)
  }

  const society = societies && societies.length > 0 ? societies[0] : undefined

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    adminSociety: society ?? undefined,
    triggerRefresh,
  }
}
