/* eslint-disable max-len */
import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SnapshotOut } from 'mobx-state-tree'
import { ToastType } from '../../../../../common/Toast/toast-type'
import { isUserAdminInSociety } from '../../../../../../helpers/society'
import { useFormErrorMessage } from '../../../../../../hooks/useFormErrorMessage'
import { useToastNotifications } from '../../../../../../hooks/useToastNotification'
import { useCurrentSociety } from '../../../../../../hooks/useCurrentSociety'
import { useStores } from '../../../../../../hooks/useStores'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { TextInput } from '../../../../../common/TextInput'
import { FormControl } from '../../../../../common/FormControl'
import { Button, ButtonVariant } from '../../../../../common/Button'
import { societyWebsiteFAQSectionCreateSchema } from '../../../../../../forms/schemas/society_website_faq_section_create'
import { SocietyWebsiteModel } from '../../../../../../state/models/society-website'

interface CreateUpdateSocietyWebsiteFAQSectionFormProps {
  website: SnapshotOut<typeof SocietyWebsiteModel>
  onError: (error: unknown) => void
  onSubmit: (
    data: InferType<typeof societyWebsiteFAQSectionCreateSchema>
  ) => Promise<void>
  onClose: () => void
  loading: boolean
  questionId?: string
  sectionId?: string
}

export const CreateUpdateSocietyWebsiteFAQSectionForm = observer(
  ({
    website,
    sectionId,
    loading,
    onError,
    onSubmit,
    onClose,
  }: CreateUpdateSocietyWebsiteFAQSectionFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { authenticationStore, societyWebsitesStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()
    const { getErrorMessage } = useFormErrorMessage()
    const section = website
      ? societyWebsitesStore.websiteBrokerQuestionSection(
          society._id,
          sectionId
        )
      : undefined

    const updateMode = !!sectionId

    const isAdmin = isUserAdminInSociety(
      society,
      authenticationStore.userId as string
    )

    if (!isAdmin || (updateMode && sectionId === undefined)) {
      onClose()
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const getDefaultValues = useCallback((): InferType<
      typeof societyWebsiteFAQSectionCreateSchema
    > => {
      return {
        title: updateMode ? section?.title || '' : '',
      }
    }, [section, updateMode])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyWebsiteFAQSectionCreateSchema),
      defaultValues: getDefaultValues(),
    })

    return (
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate(
                'createUpdateQuestionsAndAnswersSectionForm.labels.section'
              )}
              error={errors.title && getErrorMessage(errors.title)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.title}
              />
            </FormControl>
          )}
          name="title"
        />
        <div className="mt-5 flex flex-shrink-0 flex-wrap items-center justify-end gap-3">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
            variant={ButtonVariant.TEXT}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid || loading}
            label={translate('common.actions.save')}
            type="submit"
          />
        </div>
      </form>
    )
  }
)
