import { useCallback } from 'react'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { ActiveTab } from './RegisterView'

type useRegisterTabsReturnType = {
  getTabUrl: (tab: ActiveTab) => string
  getTabState: (pathname: string) => ActiveTab
}

interface useRegisterTabsProps {
  societyId: string
}

export const useRegisterTabs = ({
  societyId: id,
}: useRegisterTabsProps): useRegisterTabsReturnType => {
  const getTabUrl = useCallback(
    (tab: ActiveTab): string => {
      switch (tab) {
        case ActiveTab.ENTRANCES:
          return reverseUrl('management:register-entrance', { id })
        case ActiveTab.UNITS:
          return reverseUrl('management:register-unit', { id })
        case ActiveTab.MEMBERS:
          return reverseUrl('management:register-member', { id })
        case ActiveTab.NON_MEMBERS:
          return reverseUrl('management:register-nonmember', { id })
        case ActiveTab.GROUPS:
          return reverseUrl('management:register-group', { id })
        default:
          return reverseUrl('management:register-entrance', { id })
      }
    },
    [id]
  )

  const getTabState = useCallback(
    (pathname: string): ActiveTab => {
      if (pathname === reverseUrl('management:register-entrance', { id })) {
        return ActiveTab.ENTRANCES
      }
      if (pathname === reverseUrl('management:register-unit', { id })) {
        return ActiveTab.UNITS
      }
      if (pathname === reverseUrl('management:register-member', { id })) {
        return ActiveTab.MEMBERS
      }
      if (pathname === reverseUrl('management:register-nonmember', { id })) {
        return ActiveTab.NON_MEMBERS
      }
      if (pathname === reverseUrl('management:register-group', { id })) {
        return ActiveTab.GROUPS
      }
      return ActiveTab.ENTRANCES
    },
    [id]
  )

  return {
    getTabUrl,
    getTabState,
  }
}
