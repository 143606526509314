import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Modal } from '../../../../../components/common/Modal'
import { ModalHeader } from '../../../../../components/common/Modal/ModalHeader'
import { ModalBody } from '../../../../../components/common/Modal/ModalBody'
import { InactivateStage } from './InactivateStage'
import { DeletionStage } from './DeletionStage'

interface DeleteSocietyModalProps {
  show: boolean
  close: () => void
  societyId?: string
}

enum Stage {
  INACTIVATE = 0,
  DELETE = 1,
}

export const DeleteSocietyModal = observer(
  ({ show, close, societyId }: DeleteSocietyModalProps): JSX.Element => {
    const [deletionStage, setDeletionStage] = useState(Stage.INACTIVATE)

    const goToDeletePage = (): void => {
      setDeletionStage(Stage.DELETE)
    }

    return (
      <Modal show={show}>
        {{
          header: <ModalHeader onClose={close}>Radera förening</ModalHeader>,
          body: (
            <ModalBody className="overflow-y-auto">
              {deletionStage === Stage.INACTIVATE ? (
                <InactivateStage
                  societyId={societyId}
                  close={close}
                  goToDeletePage={goToDeletePage}
                />
              ) : (
                <DeletionStage societyId={societyId} close={close} />
              )}
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
