import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { FacilityViewTabs } from './FacilityView'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'

type useFacilityTabsReturnType = {
  getTabUrl: (tab: FacilityViewTabs) => string
  getTabState: (pathname: string) => FacilityViewTabs
}

export const useFacilityTabs = (): useFacilityTabsReturnType => {
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const { facilityId } = useParams()
  const id = society._id

  const getTabUrl = useCallback(
    (tab: FacilityViewTabs): string => {
      switch (tab) {
        case FacilityViewTabs.CALENDAR:
          return reverseUrl('society:facilities-detail-calendar', {
            id,
            facilityId,
          })
        case FacilityViewTabs.INFORMATION:
          return reverseUrl('society:facilities-detail-information', {
            id,
            facilityId,
          })
        case FacilityViewTabs.BOOKINGS:
          return reverseUrl('society:facilities-detail-bookings', {
            id,
            facilityId,
          })
        default:
          return reverseUrl('society:facilities-detail-calendar', {
            id,
            facilityId,
          })
      }
    },
    [id, facilityId]
  )

  const getTabState = useCallback(
    (pathname: string): FacilityViewTabs => {
      if (
        pathname ===
        reverseUrl('society:facilities-detail-calendar', { id, facilityId })
      ) {
        return FacilityViewTabs.CALENDAR
      }
      if (
        pathname ===
        reverseUrl('society:facilities-detail-information', { id, facilityId })
      ) {
        return FacilityViewTabs.INFORMATION
      }
      if (
        pathname ===
        reverseUrl('society:facilities-detail-bookings', { id, facilityId })
      ) {
        return FacilityViewTabs.BOOKINGS
      }
      return FacilityViewTabs.CALENDAR
    },
    [id, facilityId]
  )

  return {
    getTabUrl,
    getTabState,
  }
}
