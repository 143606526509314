import { SnapshotOut } from 'mobx-state-tree'
import {
  isUserAdminInSociety,
  translateRoles,
  userBoardMemberRoles,
} from '../helpers/society'
import { UserModel } from '../state/models/user'
import { SocietyModel } from '../state/models/society'

export const useUserRolesString = (
  user: SnapshotOut<typeof UserModel> | undefined,
  society: SnapshotOut<typeof SocietyModel> | undefined
): (string | string[] | null)[] => {
  let userRoles: string[] = []

  if (user && isUserAdminInSociety(society, user._id)) {
    userRoles = [...userRoles, 'admin']
  }

  const _userBoardMemberRoles = user && userBoardMemberRoles(society, user._id)
  if (_userBoardMemberRoles) {
    userRoles = [...userRoles, ..._userBoardMemberRoles]
  }

  return translateRoles(userRoles)
}
