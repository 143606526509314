import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { InferType } from 'yup'
import Compressor from 'compressorjs'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAtom } from 'jotai'
import { useStores } from '../../../hooks/useStores'
import { ChatMessageBar } from '../ChatMessageBar'
import { chatMessageSchema } from '../../../forms/schemas/chat_message'
import { useUsersWithChatRoom } from './useUsersWithChatRoom'
import { ChatRoomBody } from '../ChatRoomBody/ChatRoomBody'
import { ChatHeader } from '../ChatHeader'
import { IChatRoomModel } from '../../../interfaces/models/chat-rooms.interfaces'
import { ChatRoomErrorWrapper } from '../ChatRoomErrorWrapper'
import { ChatSettings } from '../ChatSettings'
import { useHamburger } from '../../../hooks/useHamburgerContext'
import { ChatHeaderState, chatHeaderStateAtom } from '../atom'
import { useIsUserInChat } from '../../../hooks/useIsUserInChat'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { imageUploadReducer } from '../../../reducers/image-upload'
import { ProcessedImage, ProcessedMedia } from '../../../types/image-upload'
import { ImageActions } from '../../../actions/image-upload'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { convertBase64 } from '../../../api/helpers'
import {
  isUserAdminInSociety,
  isUserBoardMemberInSociety,
} from '../../../helpers/society'
import { useHasUserFetchedChatRooms } from '../../../views/chat/useHasFetchedChatRooms'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { isImageType } from '../../../helpers/types'
import { useUserBlockedInSociety } from '../../../hooks/useUserBlockedInSociety'
import { BlockedCallout } from '../../blocked/BlockedCallout'

export const ChatRoom = observer((): JSX.Element => {
  const {
    chatRoomStore,
    authenticationStore,
    societyStore,
    mediaStore,
    notificationsStore,
  } = useStores()
  const selectedChatRoomId = chatRoomStore.selectedChatRoom
  const [showSettings, setShowSettings] = useState(false)
  const { translate } = useAppTranslation()
  const { setToastNotification } = useToastNotifications()
  const [chatHeaderState] = useAtom(chatHeaderStateAtom)
  const { hasUserFetchedChatRooms } = useHasUserFetchedChatRooms()
  const { isHamburgerMode } = useHamburger()
  const [messageImagesState, messageImagesDispatch] = useReducer(
    imageUploadReducer,
    {
      images: [],
    }
  )
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const recipientIdFromQuery = queryParams.get('recipientId')
  const { society } = useCurrentSociety()

  const navigate = useNavigate()
  const maxImageUploadLimit = 10
  const imageUploadDisabled =
    messageImagesState.images.length >= maxImageUploadLimit

  const chatRoom = selectedChatRoomId
    ? chatRoomStore.chatRooms.get(selectedChatRoomId)
    : undefined

  const chatRoomSociety = chatRoom?.societyId
    ? societyStore.societies.get(chatRoom.societyId)?.name
    : undefined

  const isUserInChat = useIsUserInChat(chatRoom)
  const userBlockedInChatRoomSociety = useUserBlockedInSociety(
    chatRoom?.societyId
  )

  // Prevent user from manually navigating to a chat they have left
  useEffect(() => {
    chatRoom &&
      hasUserFetchedChatRooms &&
      !isUserInChat &&
      chatHeaderState === ChatHeaderState.None &&
      navigate(reverseUrl('chat'))
  }, [
    chatHeaderState,
    chatRoom,
    chatRoomStore,
    hasUserFetchedChatRooms,
    isUserInChat,
    navigate,
  ])

  useEffect(() => {
    setShowSettings(false)
  }, [selectedChatRoomId])

  const getUpdateCountValue = (
    currentCount: number,
    unreadCount: number
  ): number => {
    let updatedCount = currentCount - unreadCount
    updatedCount = updatedCount < 0 ? 0 : updatedCount
    return updatedCount
  }

  const updateUnseenCountAfterReadingChatRoom = useCallback(
    (type: string, unreadCount: number): void => {
      switch (type) {
        case 'oneonone': {
          notificationsStore.setUnseenOneOnOneChatCount(
            getUpdateCountValue(
              notificationsStore.unseenOneOnOneChatCount,
              unreadCount
            )
          )
          break
        }
        case 'interests': {
          notificationsStore.setUnseenInterestsChatCount(
            getUpdateCountValue(
              notificationsStore.unseenInterestsChatCount,
              unreadCount
            )
          )
          break
        }
        case 'board': {
          notificationsStore.setUnseenBoardChatCount(
            getUpdateCountValue(
              notificationsStore.unseenBoardChatCount,
              unreadCount
            )
          )
          break
        }
        case 'questions': {
          notificationsStore.setUnseenBoardQuestionsChatCount(
            getUpdateCountValue(
              notificationsStore.unseenBoardQuestionsChatCount,
              unreadCount
            )
          )
          notificationsStore.setUnseenResidentsQuestionsChatCount(
            getUpdateCountValue(
              notificationsStore.unseenResidentsQuestionsChatCount,
              unreadCount
            )
          )
          break
        }
        default:
          break
      }
    },
    [notificationsStore]
  )

  useEffect(() => {
    if (chatRoom?.unreadCount && chatRoom?.unreadCount > 0) {
      updateUnseenCountAfterReadingChatRoom(
        chatRoom.type,
        chatRoom?.unreadCount
      )
      chatRoomStore.setRoomRead(chatRoom as IChatRoomModel)
    }
  }, [
    chatRoom,
    chatRoomStore,
    notificationsStore,
    updateUnseenCountAfterReadingChatRoom,
  ])

  const getDefaultValues = useMemo(() => {
    return {
      recipients: recipientIdFromQuery ? [recipientIdFromQuery] : [],
      message: '',
      authorId: authenticationStore.userId as string,
      mediaIds: [],
      groupChatTitle: '',
      society: society?._id ?? '',
      isPrivate: 'true',
      isGroupchat: false,
      isBoardChat: false,
    }
  }, [authenticationStore.userId, society?._id, recipientIdFromQuery])

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    formState: { isValid },
  } = useForm<InferType<typeof chatMessageSchema>>({
    mode: 'all',
    resolver: yupResolver(chatMessageSchema),
    defaultValues: getDefaultValues,
  })
  const watchSociety = watch('society')
  const watchRecipients = watch('recipients')
  const watchIsGroupchat = watch('isGroupchat')

  const hasLimitExceeded = (imagesLength: number): boolean => {
    return (
      imagesLength > maxImageUploadLimit ||
      messageImagesState.images.length + imagesLength > maxImageUploadLimit
    )
  }

  const uploadImage = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const images = e.target.files && e.target.files
    if (!images) {
      return
    }
    if (hasLimitExceeded(images.length)) {
      setToastNotification(
        ToastType.DANGER,
        translate('chatUpload.flashMessage.uploadImageLimitReached')
      )
    } else {
      Array.from(images).forEach((image: File | Blob) => {
        if (!isImageType(image.type)) {
          setToastNotification(
            ToastType.DANGER,
            translate('uploadImage.invalidImageType')
          )
          return
        }
        // eslint-disable-next-line no-new
        new Compressor(image, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success: async (result) => {
            const _image: ProcessedImage = {
              uri: URL.createObjectURL(image),
            }

            messageImagesDispatch({ type: ImageActions.ADD, image: _image })

            const base64 = await convertBase64(result)
            const id = await mediaStore.createMedia('image', base64 as string)

            messageImagesDispatch({
              type: ImageActions.SET_UPLOADED,
              mediaId: id as string,
              image: _image,
            })
          },
        })
      })
    }
    e.target.value = ''
  }

  const onImageRemove = (image: ProcessedMedia): void => {
    messageImagesDispatch({ type: ImageActions.REMOVE, id: image.id as string })
  }

  useEffect(() => {
    setValue(
      'mediaIds',
      messageImagesState.images
        .map((_image) => _image.id)
        .filter((_id) => _id !== undefined),
      { shouldValidate: true }
    )
  }, [messageImagesState, setValue])

  const checkImageUploading = (images: ProcessedMedia[]): boolean => {
    return images.map((image) => image.uploading).includes(true)
  }

  const imageUploading = checkImageUploading(messageImagesState.images)

  const { usersWithChatRoom } = useUsersWithChatRoom({
    isGroupchat: watchIsGroupchat || false,
    watchRecipients,
    watchSociety,
  })

  // Due to the reason that we cannot populate the society value in the default values call
  // we need to populate this value at a later stage so that the dropdowns have a default value
  useEffect(() => {
    if (
      watchSociety === '' &&
      chatHeaderState === ChatHeaderState.StartNewChat
    ) {
      societyStore.sortedSocietiesWithChatInterestsEnabled[0]?._id &&
        setValue(
          'society',
          societyStore.sortedSocietiesWithChatInterestsEnabled[0]?._id
        )
    }
    if (
      watchSociety === '' &&
      chatHeaderState === ChatHeaderState.StartNewBoardChat
    ) {
      const boardSocieties = societyStore.sortedSocieties.filter((_society) => {
        return (
          isUserAdminInSociety(
            _society,
            authenticationStore.userId as string
          ) ||
          isUserBoardMemberInSociety(
            _society,
            authenticationStore.userId as string
          )
        )
      })
      setValue('isBoardChat', true)
      boardSocieties[0]?._id && setValue('society', boardSocieties[0]?._id)
    }
  }, [
    setValue,
    watchSociety,
    chatHeaderState,
    watchIsGroupchat,
    societyStore.sortedSocietiesWithChatInterestsEnabled,
    societyStore.sortedSocieties,
    authenticationStore.userId,
  ])

  useEffect(() => {
    setValue('message', '')
  }, [setValue, selectedChatRoomId])

  const resetForm = useCallback((): void => {
    messageImagesDispatch({ type: ImageActions.RESET })
    reset(getDefaultValues)
  }, [getDefaultValues, reset])

  useEffect(() => {
    resetForm()
    messageImagesDispatch({ type: ImageActions.RESET })
  }, [chatHeaderState, resetForm])

  return (
    <div
      className={`flex flex-1
      ${isHamburgerMode ? 'flex-col' : ''}`}
    >
      <div className="flex min-h-0 flex-1 flex-col justify-between">
        <ChatRoomErrorWrapper chatRoom={chatRoom}>
          <ChatHeader
            usersWithChatRoom={usersWithChatRoom}
            control={control}
            watchRecipients={watchRecipients}
            watchIsGroupchat={watchIsGroupchat || false}
            watchSociety={watchSociety}
            showSettings={showSettings}
            setShowSettings={setShowSettings}
            selectedChatRoomId={selectedChatRoomId}
            chatRoomSociety={chatRoomSociety}
            setValue={setValue}
          />
          {userBlockedInChatRoomSociety && (
            <BlockedCallout selectedSocietyId={chatRoom?.societyId} />
          )}
          <ChatRoomBody chatRoomId={chatRoom?._id} />
          <ChatMessageBar
            usersWithChatRoom={usersWithChatRoom}
            handleSubmit={handleSubmit}
            control={control}
            disabled={
              userBlockedInChatRoomSociety || !isValid || imageUploading
            }
            roomId={chatRoom?._id}
            watchRecipients={watchRecipients}
            showSettings={showSettings}
            setShowSettings={setShowSettings}
            onUploadImage={uploadImage}
            images={messageImagesState.images}
            onImageRemove={onImageRemove}
            imageUploadDisabled={imageUploadDisabled}
            resetForm={resetForm}
          />
        </ChatRoomErrorWrapper>
      </div>
      {showSettings && chatRoom && <ChatSettings chatRoom={chatRoom} />}
    </div>
  )
})
