import { SnapshotOut } from 'mobx-state-tree'
import { captureException } from '@sentry/react'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import { useStores } from '../../../hooks/useStores'
import {
  NotificationModel,
  NotificationType,
} from '../../../state/models/notification'
import { PostModel } from '../../../state/models/post'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { NotificationData } from '../../../types/notifications'
import {
  SocietyNavigationState,
  societyNavigationStateAtom,
} from '../../../views/society/atom'

interface useNotificationHelpersProps {
  notification: SnapshotOut<typeof NotificationModel>
  notificationData: NotificationData | undefined
}

interface ReturnType {
  getTranslationKey: (
    type: SnapshotOut<typeof NotificationType>
  ) => Promise<string>
  onNotificationClick: () => void
}

export const useNotificationHelpers = ({
  notification,
  notificationData,
}: useNotificationHelpersProps): ReturnType => {
  const { authenticationStore, postStore, notificationsStore, societyStore } =
    useStores()
  const navigate = useNavigate()
  const [, setSocietyNavigationState] = useAtom(societyNavigationStateAtom)
  const userId = authenticationStore.userId as string

  const getPostForNotification = async (): Promise<
    undefined | SnapshotOut<typeof PostModel>
  > => {
    const postId = notification.primaryDocId

    if (postId) {
      const _postExistsInStore = postStore.posts.get(postId)
      const _postState = postStore.fetchingPost(postId)
      // Do not trigger fetch multiple times if it does not exist in store.
      if (!_postExistsInStore && _postState === 'none') {
        await postStore.getPost(postId)
      }
      const _post = postStore.posts.get(postId)
      return _post as SnapshotOut<typeof PostModel> | undefined
    }

    return undefined
  }

  const getTranslationKey = async (
    type: SnapshotOut<typeof NotificationType>
  ): Promise<string> => {
    switch (type) {
      case 'chat-interests-new-channel':
        return 'notifications.chatNewChannel'
      case 'hey-new-posts':
        return `notifications.newPost`
      case 'hey-new-comments':
      case 'feed-new-comments': {
        const _post = await getPostForNotification()
        if (_post && _post.author === userId) {
          return 'notifications.newCommentYourPost'
        }
        return 'notifications.newComment'
      }
      case 'hey-new-likes':
      case 'feed-new-likes':
        return 'notifications.newLike'
      case 'society-request-accepted':
        return 'notifications.societyRequestAccepted'
      case 'society-request-shared-home':
      case 'society-request-member':
      case 'society-request-non-member':
        return 'notifications.societyRequest'
      case 'society-activated-shared':
      case 'society-activated-legal':
      case 'society-activated-nonlegal':
        return 'notifications.societyActivated'
      case 'invite-all-to-household':
      case 'invite-all-to-society':
      case 'invite-to-neighbourhood':
      case 'invite-to-shared-home':
        return 'notifications.societyInvite'
      case 'service-report':
        return 'notifications.newProblemReport'
      case 'admins-society-request-accepted':
        return 'notifications.societyRequestAcceptedAdmin'
      case 'app-new-inspirations':
        return 'notifications.newInspiration'
      case 'contract-monitoring':
        return 'notifications.contractMonitoring'
      // These are not implemented in old app
      case 'user-added-to-unit':
      case 'invite-all-admin-to-society':
      case 'chat-oneonone-new-message':
      case 'chat-board-new-message':
      case 'chat-interests-new-message':
      default:
        return ''
    }
  }

  const onNotificationClick = (): void => {
    notificationsStore.setNotificationRead(notification._id)
    switch (notification.type) {
      case 'society-request-non-member':
      case 'society-request-shared-home':
      case 'society-request-member':
        societyStore.setSelectedSociety(notification.societyId as string)
        setSocietyNavigationState(SocietyNavigationState.SocietyRequests)
        navigate(
          reverseUrl('management:register-member', {
            id: notification.societyId,
          })
        )
        break
      case 'hey-new-posts':
      case 'hey-new-comments':
      case 'hey-new-likes':
      case 'feed-new-likes':
      case 'feed-new-comments':
        navigate(
          `${reverseUrl('post:detail', {
            id: notificationData?.post._id,
          })}?commentId=${notification.secondaryDocId}`
        )
        break
      case 'chat-interests-new-channel':
        navigate(reverseUrl('chat:detail', { id: notificationData?.room._id }))
        break
      case 'society-request-accepted':
        societyStore.setSelectedSociety(notification.societyId as string)
        navigate(
          reverseUrl('society:home', {
            id: notification.societyId,
          })
        )
        break
      case 'contract-monitoring':
        societyStore.setSelectedSociety(notification.societyId as string)
        navigate(
          reverseUrl('management:contracts-detail', {
            id: notification.societyId,
            contractId: notificationData?.contract._id,
          })
        )
        break
      default:
        captureException(
          new Error(
            `No onClick action defined for notification with type ${notification.type}`
          )
        )
    }
  }

  return {
    getTranslationKey,
    onNotificationClick,
  }
}
