import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { SnapshotOut } from 'mobx-state-tree'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { UserModel } from '../../../../state/models/user'
import { EditResidentUnitsForm } from '../EditResidentUnitsForm'
import {
  societyUserUnitsUpdateSchema,
  societyUserUnitUpdateSchema,
} from '../../../../forms/schemas/society_user_unit_update'
import { useStores } from '../../../../hooks/useStores'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'

interface EditResidentUnitsModalProps {
  show: boolean
  close: () => void
  children?: React.ReactNode
  user: SnapshotOut<typeof UserModel>
}

export const EditResidentUnitsModal = observer(
  ({
    children,
    show,
    close,
    user,
  }: EditResidentUnitsModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { unitStore } = useStores()
    const { setToastNotification } = useToastNotifications()

    const showSuccessMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('flashMessage.changesSaved')
      )
    }

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('flashMessage.changesNotSavedError')
      )
    }

    const onSubmit = async (
      data: InferType<typeof societyUserUnitsUpdateSchema>,
      existingUserUnits: InferType<typeof societyUserUnitUpdateSchema>[]
    ): Promise<void> => {
      const status = await unitStore.updateUserUnits(
        data.units,
        existingUserUnits
      )

      await unitStore.getUnitsPerSociety(society._id)

      if (status) {
        showSuccessMessage()
        close()
      } else {
        showErrorMessage()
      }
    }

    const onError = (): void => {
      showErrorMessage()
    }

    const loading =
      unitStore.fetchingUnits === 'pending' ||
      unitStore.updatingUserUnits === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: <ModalHeader onClose={close}>{user.fullName}</ModalHeader>,
            body: (
              <ModalBody>
                <EditResidentUnitsForm
                  onError={onError}
                  onSubmit={onSubmit}
                  loading={loading}
                  user={user}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
