import React from 'react'
import { v4 as uuid } from 'uuid'
import { SnapshotOut } from 'mobx-state-tree'
import { SocietyModel } from '../../../state/models/society'
import {
  DATA_PROCESSING_AGREEMENT_LINK,
  TERMS_OF_SERVICE_LINK,
  USER_AGREEMENT_LINK,
  KUNDO_PAYMENT_INSTRUCTIONS,
} from '../../../constants/Links'
import { AccordionSectionItem } from '../../common/AccordionSection'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { theme } from '../../../theme/theme'
import { InlineLink } from '../../common/InlineLink'

export const useAccordionSections = (
  society: SnapshotOut<typeof SocietyModel>
): AccordionSectionItem[] => {
  const { translate } = useAppTranslation()

  const commonQuestions: AccordionSectionItem[] = [
    {
      id: uuid(),
      title: translate(
        'invoiceView.accordions.common.sections.paymentInstructions.title'
      ),
      content: (
        <div
          style={theme.textVariants.base}
          className="inline flex flex-wrap md:whitespace-pre"
        >
          <span>
            {translate(
              'invoiceView.accordions.common.sections.paymentInstructions.preLinkText'
            )}{' '}
          </span>
          <InlineLink
            text={translate(
              'invoiceView.accordions.common.sections.paymentInstructions.title'
            ).toLowerCase()}
            url={KUNDO_PAYMENT_INSTRUCTIONS}
            urlIsExternal
          />
        </div>
      ),
    },
    {
      id: uuid(),
      title: translate(
        'invoiceView.accordions.common.sections.termsOfService.title'
      ),
      content: (
        <div
          style={theme.textVariants.base}
          className="inline flex flex-wrap md:whitespace-pre"
        >
          <span>
            {translate(
              'invoiceView.accordions.common.sections.termsOfService.preTermsText'
            )}{' '}
          </span>
          <InlineLink
            text={translate('common.terms.user')}
            url={USER_AGREEMENT_LINK}
            urlIsExternal
          />
          {', '}
          <InlineLink
            text={translate('common.terms.service')}
            url={TERMS_OF_SERVICE_LINK}
            urlIsExternal
          />{' '}
          <span>
            {' '}
            {translate(
              'invoiceView.accordions.common.sections.termsOfService.preDataProcessingText'
            )}{' '}
          </span>
          <InlineLink
            text={translate('common.terms.dataProcessing')}
            url={DATA_PROCESSING_AGREEMENT_LINK}
            urlIsExternal
          />
        </div>
      ),
    },
    {
      id: uuid(),
      title: translate(
        'invoiceView.accordions.common.sections.cancelSubscription.title'
      ),
      content: (
        <p style={theme.textVariants.base}>
          {translate(
            'invoiceView.accordions.common.sections.cancelSubscription.text'
          )}
        </p>
      ),
    },
  ]

  const premiumQuestions: AccordionSectionItem[] = [
    {
      id: uuid(),
      title: translate(
        'invoiceView.accordions.premium.sections.discountCode.title'
      ),
      content: (
        <p style={theme.textVariants.base}>
          {translate(
            'invoiceView.accordions.premium.sections.discountCode.text'
          )}
        </p>
      ),
    },
  ]

  const basicQuestions: AccordionSectionItem[] = [
    ...(society.societyTypeDisplayName === 'housing_cooperative'
      ? [
          {
            id: uuid(),
            title: translate(
              'invoiceView.accordions.basic.sections.bostadsratterna.title'
            ),
            content: (
              <p style={theme.textVariants.base}>
                {translate(
                  'invoiceView.accordions.basic.sections.bostadsratterna.text'
                )}
              </p>
            ),
          },
        ]
      : [
          {
            id: uuid(),
            title: translate(
              'invoiceView.accordions.basic.sections.samfalligheterna.title'
            ),
            content: (
              <p style={theme.textVariants.base}>
                {translate(
                  'invoiceView.accordions.basic.sections.samfalligheterna.text'
                )}
              </p>
            ),
          },
        ]),
  ]

  return society.accountType === 'premium'
    ? [...premiumQuestions, ...commonQuestions]
    : [...basicQuestions, ...commonQuestions]
}
