/* eslint-disable @typescript-eslint/no-explicit-any */
export const isString = (str: any): false | string => {
  if (str && typeof str === 'string') {
    return str
  }
  return false
}

const REGEXP_IMAGE_TYPE = /^image\/.+$/

/**
 * Check if the given value is a mime type of image.
 * @param {*} value - The value to check.
 * @returns {boolean} Returns `true` if the given is a mime type of image, else `false`.
 */
export const isImageType = (value: any): boolean => {
  return REGEXP_IMAGE_TYPE.test(value)
}
