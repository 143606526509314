/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree'

const objectType = types.maybe(
  types.enumeration('SocietyAccountType', [
    'Lägenhet',
    'Villa',
    'Radhus',
    'Parhus',
    'Kedjehus',
  ])
)

// A lot of duplication between Listing and Sold. As there is no inheritance in MST at time of implementation.
export const ListingModel = types.model('ListingModel', {
  _id: types.identifier,
  booliId: types.number,
  societyId: types.string,
  listingId: types.maybe(types.number),
  location: types.model({
    address: types.model({
      streetAddress: types.string,
    }),
  }),
  rooms: types.maybe(types.number),
  floor: types.maybe(types.number),
  livingArea: types.maybe(types.number),
  objectType,
  apartmentNumber: types.maybe(types.string),
  daysActive: types.number,
  listPrice: types.maybe(types.number),
  thumb: types.maybe(
    types.model({
      id: types.maybe(types.number),
    })
  ),
  published: types.string,
})

export const SoldModel = types.model('SoldModel', {
  _id: types.identifier,
  booliId: types.number,
  societyId: types.string,
  location: types.model({
    address: types.model({
      streetAddress: types.string,
    }),
  }),
  rooms: types.maybe(types.number),
  floor: types.maybe(types.number),
  livingArea: types.maybe(types.number),
  objectType,
  apartmentNumber: types.maybe(types.string),
  daysActive: types.maybe(types.number),
  published: types.string,
  soldPrice: types.maybe(types.number),
  soldDate: types.maybe(types.string),
})
