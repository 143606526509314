import React from 'react'
import { Button, ButtonProps } from '../Button'

interface FormButtonsProps {
  defaultMarginTop?: boolean
  buttonsLeft?: ButtonProps[]
  buttonsRight?: ButtonProps[]
  className?: string
}

export const FormButtons = ({
  defaultMarginTop = true,
  buttonsLeft,
  buttonsRight,
  className,
}: FormButtonsProps): JSX.Element => {
  return (
    <div
      className={`border-top flex w-full border-t pt-4
      ${defaultMarginTop ? 'my-6 md:mt-14' : ''}
      ${className ?? ''}
      `}
    >
      {buttonsLeft && buttonsLeft.length > 0 && (
        <div className="flex flex-1 items-center space-x-2">
          {buttonsLeft.map((_button) => (
            <Button key={`${_button.label}`} size="sm" {..._button} />
          ))}
        </div>
      )}
      {buttonsRight && buttonsRight.length > 0 && (
        <div className="flex flex-1 items-center justify-end space-x-2">
          {buttonsRight.map((_button) => (
            <Button key={`${_button.label}`} size="sm" {..._button} />
          ))}
        </div>
      )}
    </div>
  )
}
