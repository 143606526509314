import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { useAtom } from 'jotai'
import { societyFilterSchema } from '../../../forms/schemas/post_filter'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { theme } from '../../../theme/theme'
import { Button, ButtonVariant } from '../../common/Button'
import { Dropdown } from '../../common/Dropdown'
import { IconChoices } from '../../common/Icon'
import { ToastType } from '../../common/Toast/toast-type'
import { PostFilterDropdownOption } from '../../feed/PostFilterDropdownOption'
import { ChatHeaderState, chatHeaderStateAtom } from '../atom'

export const ChatFilter = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { setToastNotification } = useToastNotifications()
  const { societyStore, chatRoomStore } = useStores()
  const [chatHeaderState] = useAtom(chatHeaderStateAtom)
  const societies = societyStore.sortedSocieties
  const { selectedSociety } = societyStore
  const { societyFilters } = chatRoomStore

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<InferType<typeof societyFilterSchema>>({
    mode: 'all',
    defaultValues: {
      all:
        societyFilters.length === 0 || societyFilters.includes('all') || false,
      societies: [],
    },
  })

  const watchAll = watch('all')
  const watchSocieties = watch('societies')

  const selectedFilters = useMemo(() => {
    return [watchAll, ...watchSocieties]
  }, [watchAll, watchSocieties])

  // Reset filter if user tries to starts any new chat
  useEffect(() => {
    if (chatHeaderState !== ChatHeaderState.None) {
      setValue('all', true)
      setValue('societies', [])
      chatRoomStore.setSocietyFilters([])
    }
  }, [chatHeaderState, chatRoomStore, setValue])

  const onCustomChange = (id: string, checked: boolean): void => {
    // If all is selected deselect all suboptions
    if (id === 'all' && checked) {
      setValue('societies', [])
      return
    }
    // If a suboption is selected disable all
    if (id !== 'all' && checked) {
      setValue('all', false)
      setValue('societies', [...watchSocieties, id])
    }

    // If a suboption is deselected remove from array
    if (!checked) {
      const _societies = watchSocieties.filter((_society) => _society !== id)
      setValue('societies', _societies)
    }
  }

  // When no suboption is selected enable all
  useEffect(() => {
    selectedFilters.length === 1 && setValue('all', true)
  }, [selectedFilters, setValue])

  const onSubmit = (data: InferType<typeof societyFilterSchema>): void => {
    const _societyFilters = data.societies.filter(
      (_society) => !!_society
    ) as string[]
    chatRoomStore.setSocietyFilters(_societyFilters)
  }

  const onError = (): void => {
    if (!isValid) return
    setToastNotification(
      ToastType.DANGER,
      translate('flashMessage.somethingWentWrongError')
    )
  }

  const dropdownOptions = [
    {
      value: 'all',
      label: translate('feedView.filterFeed.postsFilterAllOptionValue'),
      customContent: (
        <Controller
          control={control}
          name="all"
          render={({ field: { name, value, onChange } }) => (
            <PostFilterDropdownOption
              name={name}
              onChange={onChange}
              onCustomChange={onCustomChange}
              label={translate(
                'feedView.filterFeed.societiesFilterAllOptionValue'
              )}
              value={value}
            />
          )}
        />
      ),
    },
    ...societies.map((society) => ({
      value: society._id,
      label: society.name,
      customContent: (
        <Controller
          control={control}
          name="societies"
          render={({ field: { onChange } }) => (
            <PostFilterDropdownOption
              name={society._id}
              onChange={onChange}
              onCustomChange={onCustomChange}
              label={society.name}
              value={watchSocieties.includes(society._id)}
            />
          )}
        />
      ),
    })),
  ]

  const submitButton = (
    <div className="flex h-full w-full items-center justify-center px-6 pb-6 pt-3">
      <Button
        className="px-16"
        label={translate('common.actions.confirm')}
        variant={ButtonVariant.PRIMARY}
        onClick={handleSubmit(onSubmit, onError)}
      />
    </div>
  )

  return (
    <div className="absolute top-[11px] right-4">
      <Dropdown
        className="w-fit"
        dropdownClassName="max-h-[400px] md:max-h-[800px] overflow-y-auto"
        dropdownPosition="bottom-left"
        dropdownContentWidth="w-64"
        options={dropdownOptions}
        submitButton={submitButton}
        disabled={!selectedSociety || chatHeaderState !== ChatHeaderState.None}
      >
        <Button
          variant={ButtonVariant.TEXT}
          icon={IconChoices.FILTER}
          iconSize={24}
          iconColor={
            societyFilters.length > 0 && !societyFilters.includes('all')
              ? theme.colors.brandGreen
              : 'black'
          }
          size="no-padding"
          className="p-[3px]"
          disableTabIndex
        />
      </Dropdown>
    </div>
  )
})
