/* eslint-disable max-len */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, Link } from 'react-router-dom'
import { ViewBase } from '../../../../components/common/ViewBase'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { Button, ButtonVariant } from '../../../../components/common/Button'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { Icon, IconChoices } from '../../../../components/common/Icon'
import { theme } from '../../../../theme/theme'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { KUNDO_SUPPORT_LINK } from '../../../../constants/Links'
import { LinkButton } from '../../../../components/common/LinkButton'

export const RegisterGuidanceView = observer((): JSX.Element => {
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:register-guidance', {
      '{{ societyName }}': society.name,
    })
  )
  const navigate = useNavigate()
  const id = society._id

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { isSamf } = society

  return (
    <ErrorBoundary>
      <ViewBase
        title={
          isSamf
            ? 'Så får ni ordning på fastighetsregistret'
            : 'Så får ni ordning på lägenhetsregistret'
        }
        subtitle={
          isSamf
            ? 'Ett korrekt fastighetsregister är grundläggande för användningen av Boappa. Alla fastigheter behöver ha rätt adress och korrekt fastighetsbeteckning.'
            : 'Ett korrekt lägenhetsregister är grundläggande för användningen av Boappa. Alla lägenheter behöver ha rätt adress och lantmäteriets fyrsiffriga nummer.'
        }
      >
        <div className="flex flex-col gap-3">
          <p style={theme.textVariants.base}>
            {isSamf
              ? 'Fastighetsregistret behöver vara komplett för att all funktionalitet i Boappa ska fungera och det är också genom fastighetsregistret styrelsen ser ifall alla fastigheter är representerade i Boappa.'
              : 'Lägenhetsregistret behöver vara komplett för att all funktionalitet i Boappa ska fungera och det är också genom lägenhetsregistret styrelsen ser ifall alla hushåll är representerade i Boappa.'}
          </p>

          <span style={theme.textVariants.base}>Såhär gör du:</span>
          <ol
            className="list-inside list-decimal"
            style={theme.textVariants.base}
          >
            <li>
              Gå till{' '}
              <Link
                to={reverseUrl('management:register', { id })}
                className="text-brandGreen underline"
              >
                Register
              </Link>{' '}
              i styrelseportalen och kontrollera att föreningens alla{' '}
              {isSamf ? 'vägar' : 'adresser'} är upplagda.
            </li>
            <li>
              {isSamf
                ? 'Klicka sedan på “Fastigheter” och lägg upp de beteckningar som saknas.'
                : 'Klicka sedan på “Lägenhetsnummer” och lägg upp de nummer som saknas.'}
            </li>
          </ol>

          <p style={theme.textVariants.base}>
            Kontakta vår support om ni behöver hjälp med registret.
          </p>
        </div>
        <div className="my-8 rounded-md bg-brandLightGreen p-5">
          <div className="flex items-center">
            <Icon
              icon={IconChoices.INFORMATION}
              color={theme.colors.brandGreen}
            />
            <p style={theme.textVariants.lead} className="ml-2">
              Tips
            </p>
          </div>
          <p style={theme.textVariants.base} className="mt-2">
            Om ni har många {isSamf ? 'fastigheter' : 'lägenhetsnummer'} att
            lägga upp görs det enklast via en excel-fil i webbappen.
          </p>
        </div>
        <div className="flex gap-3">
          <Button
            label="Till register"
            variant={ButtonVariant.PRIMARY}
            onClick={() => navigate(reverseUrl('management:register', { id }))}
          />
          <LinkButton
            label="Kontakta support"
            url={KUNDO_SUPPORT_LINK}
            urlIsExternal
            variant={ButtonVariant.DEFAULT}
          />
        </div>
      </ViewBase>
    </ErrorBoundary>
  )
})
