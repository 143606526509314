import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { UseFormSetError } from 'react-hook-form'
import { Modal } from '../../../common/Modal'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { ModalHeader } from '../../../common/Modal/ModalHeader'

import { useStores } from '../../../../hooks/useStores'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { DiscountCodeForm } from '../DiscountCodeForm'
import { societyDiscountCodeSchema } from '../../../../forms/schemas/society_discount_code'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'

interface DiscountCodeModalProps {
  show: boolean
  close: () => void
  setDiscountCodePartnerNameId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >
  openPaymentModal: () => void
}

export const DiscountCodeModal = observer(
  ({
    show,
    close,
    setDiscountCodePartnerNameId,
    openPaymentModal,
  }: DiscountCodeModalProps): JSX.Element | null => {
    const { settingsStore } = useStores()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const ref = useRef(null)

    const onSubmit = async (
      data: InferType<typeof societyDiscountCodeSchema>,
      setError: UseFormSetError<InferType<typeof societyDiscountCodeSchema>>
    ): Promise<void> => {
      const { discountCode } = data
      const _discountCode = settingsStore.discountCodes.get(discountCode)

      if (!_discountCode) {
        setError('discountCode', {
          type: 'manual',
          message: translate(
            'invoiceView.forms.discountCode.errors.discountCode'
          ),
        })
      } else {
        setToastNotification(
          ToastType.SUCCESS,
          translate('invoiceView.flashMessage.discountCodeApplied')
        )
        close()
        setDiscountCodePartnerNameId(_discountCode.partnerNameId)
        openPaymentModal()
      }
    }
    return (
      <Modal ref={ref} show={show} size="xl">
        {{
          header: (
            <ModalHeader onClose={close}>
              {translate('invoiceView.modals.discountCode.title')}
            </ModalHeader>
          ),
          body: (
            <ModalBody className="overflow-y-auto">
              <DiscountCodeForm
                onSubmit={onSubmit}
                onClose={close}
                loading={false}
              />
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
