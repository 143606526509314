import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { EventDetailViewTabs } from './EventDetailView'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { reverseUrl } from '../../../navigation/reverseUrl'

type useFacilityTabsReturnType = {
  getTabUrl: (tab: EventDetailViewTabs) => string
  getTabState: (pathname: string) => EventDetailViewTabs
}

export const useEventTabs = (
  boardRoomEvent?: boolean
): useFacilityTabsReturnType => {
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const { eventId } = useParams()
  const id = society._id

  const getTabUrl = useCallback(
    (tab: EventDetailViewTabs): string => {
      switch (tab) {
        case EventDetailViewTabs.INFORMATION:
          return reverseUrl(
            boardRoomEvent
              ? 'management:calendar-detail-information'
              : 'society:calendar-detail-information',
            {
              id,
              eventId,
            }
          )
        case EventDetailViewTabs.ATTENDANTS:
          return reverseUrl(
            boardRoomEvent
              ? 'management:calendar-detail-attendants'
              : 'society:calendar-detail-attendants',
            {
              id,
              eventId,
            }
          )

        default:
          return reverseUrl(
            boardRoomEvent
              ? 'management:calendar-detail-information'
              : 'society:calendar-detail-information',
            {
              id,
              eventId,
            }
          )
      }
    },
    [id, eventId, boardRoomEvent]
  )

  const getTabState = useCallback(
    (pathname: string): EventDetailViewTabs => {
      if (
        pathname ===
        reverseUrl(
          boardRoomEvent
            ? 'management:calendar-detail-information'
            : 'society:calendar-detail-information',
          { id, eventId }
        )
      ) {
        return EventDetailViewTabs.INFORMATION
      }
      if (
        pathname ===
        reverseUrl(
          boardRoomEvent
            ? 'management:calendar-detail-attendants'
            : 'society:calendar-detail-attendants',
          { id, eventId }
        )
      ) {
        return EventDetailViewTabs.ATTENDANTS
      }

      return EventDetailViewTabs.INFORMATION
    },
    [id, eventId, boardRoomEvent]
  )

  return {
    getTabUrl,
    getTabState,
  }
}
