import { SnapshotOut } from 'mobx-state-tree'
import { useMemo } from 'react'
import { UnitModel } from '../state/models/unit'
import { ExtendedUnitModel, getUnitString } from '../helpers/units'
import { useStores } from './useStores'

export const useUnitsString = (
  units: SnapshotOut<typeof UnitModel>[],
  managementMode?: boolean
): string => {
  const { societyStore, societyEntrancesStore } = useStores()

  const unitsString = useMemo(
    () =>
      units
        .map((_unit) => {
          const _society = societyStore.societies.get(_unit.societyId)
          const entrance =
            _unit.entranceId &&
            societyEntrancesStore.entrances.get(_unit.entranceId)
          return getUnitString(
            { ..._unit, entrance } as ExtendedUnitModel,
            _society?.isSamf,
            managementMode
          )
        })
        .join(', '),
    [
      managementMode,
      units,
      societyStore.societies,
      societyEntrancesStore.entrances,
    ]
  )

  return unitsString
}
