import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { captureException } from '@sentry/react'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { SocietyModel } from '../../../state/models/society'
import { societySchema } from '../../../forms/schemas/society'
import { SocietyTypes } from '../../../types/society-type'
import { useStores } from '../../../hooks/useStores'
import { UserModel } from '../../../state/models/user'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { FormControl } from '../../common/FormControl'
import { useFormErrorMessage } from '../../../hooks/useFormErrorMessage'
import { TextInput } from '../../common/TextInput'
import { Button, ButtonVariant } from '../../common/Button'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { AlreadyExistingSocietyModal } from './AlreadyExistingSocietyModal'
import { HorizontalFormSection } from '../../common/HorizontalFormSection'
import { formatOrganisationNumber } from '../../../helpers/organisation_number'
import { OrganisationNumberTextInput } from '../../common/OrganisationNumberTextInput'

interface CreateSocietyFormProps {
  societyTypeDisplayName: SocietyTypes
  user: SnapshotOut<typeof UserModel>
}

export const CreateSocietyForm = observer(
  ({ societyTypeDisplayName, user }: CreateSocietyFormProps): JSX.Element => {
    const { societyStore } = useStores()
    const { translate } = useAppTranslation()
    const { getErrorMessage } = useFormErrorMessage()
    const { setToastNotification } = useToastNotifications()
    const navigate = useNavigate()
    const [alreadyExistingSociety, setAlreadyExistingSociety] =
      useState<null | SnapshotOut<typeof SocietyModel>>(null)
    const [
      alreadyExistingOrganisationNumber,
      setAlreadyExistingOrganisationNumber,
    ] = useState<string | null>(null)
    const [isOrganisationNumberFocused, setIsOrganisationNumberFocused] =
      useState(false)

    const societyIsSamf =
      societyTypeDisplayName === SocietyTypes.HOME_OWNERS_ASSOCIATION
    const societyIsRealStateDeveloper =
      societyTypeDisplayName === SocietyTypes.HOUSING_COOPERATIVE_CONSTRUCTION
    const societyIsNeighbourhood =
      societyTypeDisplayName === SocietyTypes.NEIGHBOURHOOD

    const getDefaultValues = (): InferType<typeof societySchema> => {
      return {
        organisationNumber: '',
        companyOrganisationNumber: '',
        status: 'inactive',
        name: '',
        companyName: '',
        addressCountryCode: 'SE',
        addressCity: '',
        addressZip: '',
        addressStreet: '',
        billingEmail: user.email as string,
        billingUnits: 0,
        societyTypeDisplayName,
        typeOfSociety: 'legal_entity',
        masterAdminUserId: user._id,
      }
    }

    const {
      control,
      handleSubmit,
      setValue,
      watch,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societySchema),
      defaultValues: getDefaultValues(),
    })

    // Had to add this useEffect because useForm doesn't want to recompute its values when the type updates
    useEffect(() => {
      setValue('societyTypeDisplayName', societyTypeDisplayName)
    }, [setValue, societyTypeDisplayName])

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('createSocietyForm.flashMessage.createSocietyFailure')
      )
    }

    const showSuccessMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('createSocietyForm.flashMessage.createSocietySuccess')
      )
    }

    const getSocietyByOrganisationNumber = useCallback(
      async (
        organisationNumber: string
      ): Promise<SnapshotOut<typeof SocietyModel> | null> => {
        const societies =
          await societyStore.getSocietiesByOrganisationNumber(
            organisationNumber
          )
        return societies.length > 0 ? societies[0] : null
      },
      [societyStore]
    )

    const watchOrganisationNumber = watch('organisationNumber')

    useEffect(() => {
      if (!watchOrganisationNumber) {
        return
      }

      const trimmedOrganisationNumber = watchOrganisationNumber.replace('-', '')

      const fetchSociety = async (): Promise<void> => {
        if (isOrganisationNumberFocused) {
          const existingSociety = await getSocietyByOrganisationNumber(
            trimmedOrganisationNumber
          )

          if (existingSociety) {
            setAlreadyExistingSociety(existingSociety)
            setAlreadyExistingOrganisationNumber(trimmedOrganisationNumber)
          }
        }
      }

      if (trimmedOrganisationNumber.length === 10) {
        fetchSociety()
      }
    }, [
      isOrganisationNumberFocused,
      watchOrganisationNumber,
      getSocietyByOrganisationNumber,
    ])

    const navigateToSociety = (societyId: string): void => {
      societyStore.setSelectedSociety(societyId)
      navigate(reverseUrl('society'), { replace: true })
    }

    const navigateToSocietyConfirmActivation = (societyId: string): void => {
      navigate(
        reverseUrl('find-society:confirm-activation-detail', {
          id: societyId,
        }),
        { replace: true, state: { navigatingFromSocietyCreated: true } }
      )
    }

    const onSubmit = async (
      data: InferType<typeof societySchema>
    ): Promise<void> => {
      const existingSociety =
        data.organisationNumber &&
        (await getSocietyByOrganisationNumber(data.organisationNumber))

      if (existingSociety) {
        setAlreadyExistingSociety(existingSociety)
        if (watchOrganisationNumber) {
          setAlreadyExistingOrganisationNumber(watchOrganisationNumber)
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const society = await societyStore.createSociety(data)
        if (society) {
          const activateStatus = societyStore.activateSociety(society._id)
          const setBillingUnitsStatus = societyStore.patchSociety(society._id, {
            billingUnits: data.billingUnits,
          })
          if (!activateStatus || !setBillingUnitsStatus) {
            showErrorMessage()
          } else {
            showSuccessMessage()
            if (societyIsRealStateDeveloper || societyIsNeighbourhood) {
              navigateToSociety(society._id)
            } else {
              navigateToSocietyConfirmActivation(society._id)
            }
          }
        } else {
          showErrorMessage()
        }
      }
    }

    const onError = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const closeAlreadyExistingSocietyModal = (): void => {
      setAlreadyExistingSociety(null)
      setValue('organisationNumber', '')
    }

    const onChangeHelper = (
      e: React.FormEvent<HTMLInputElement>,
      onChange: (...event: unknown[]) => void
    ): void => {
      const str = e.currentTarget.value
      if (str !== '') {
        try {
          const val = parseInt(str, 10)
          onChange(val)
        } catch (error) {
          captureException(error)
        }
      } else {
        onChange(0)
      }
    }

    const customOrganisatioNumberError =
      watchOrganisationNumber &&
      watchOrganisationNumber.replace('-', '') ===
        alreadyExistingOrganisationNumber?.replace('-', '')
        ? {
            message: translate(
              'createSocietyForm.errors.societyAlreadyActivated'
            ),
            ref: {
              name: 'organisationNumber',
            },
            type: 'matches',
          }
        : undefined

    const loading =
      societyStore.creatingSociety === 'pending' ||
      societyStore.activatingSociety === 'pending'

    return (
      <>
        <form
          className="mb-8 space-y-6 overflow-y-auto"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          {societyIsRealStateDeveloper && (
            <HorizontalFormSection
              title={translate('createSocietyForm.headers.realEstateDeveloper')}
            >
              <div className="flex-0 space-y-4 md:flex-1">
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <FormControl
                      label={translate('common.form.labels.name')}
                      error={
                        errors.companyName &&
                        getErrorMessage(errors.companyName)
                      }
                      name={name}
                    >
                      <TextInput
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    </FormControl>
                  )}
                  name="companyName"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => {
                    const formattedValue = value
                      ? formatOrganisationNumber(value)
                      : ''
                    return (
                      <FormControl
                        label={translate(
                          'common.form.labels.organisationNumber'
                        )}
                        error={
                          errors.companyOrganisationNumber &&
                          getErrorMessage(errors.companyOrganisationNumber)
                        }
                        name={name}
                      >
                        <OrganisationNumberTextInput
                          value={formattedValue}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      </FormControl>
                    )
                  }}
                  name="companyOrganisationNumber"
                />
              </div>
            </HorizontalFormSection>
          )}
          <HorizontalFormSection
            title={
              societyIsRealStateDeveloper
                ? translate('createSocietyForm.societyOrProjectTitle')
                : ''
            }
            hideTitleColumn={!societyIsRealStateDeveloper}
          >
            {!societyIsNeighbourhood && (
              <>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => {
                    const formattedValue = value
                      ? formatOrganisationNumber(value)
                      : ''
                    return (
                      <FormControl
                        label={translate(
                          'common.form.labels.organisationNumber'
                        )}
                        error={
                          errors.organisationNumber
                            ? getErrorMessage(errors.organisationNumber)
                            : customOrganisatioNumberError &&
                              getErrorMessage(customOrganisatioNumberError)
                        }
                        name={name}
                      >
                        <OrganisationNumberTextInput
                          value={formattedValue}
                          onChange={onChange}
                          onBlur={() => {
                            setIsOrganisationNumberFocused(false)
                            if (onBlur) {
                              onBlur()
                            }
                          }}
                          onFocus={() => {
                            setIsOrganisationNumberFocused(true)
                          }}
                        />
                      </FormControl>
                    )
                  }}
                  name="organisationNumber"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <FormControl
                      label={translate(
                        societyIsSamf
                          ? 'createSocietyForm.labels.numberOfBillingUnitsSamf'
                          : 'createSocietyForm.labels.numberOfBillingUnits'
                      )}
                      error={
                        errors.billingUnits &&
                        getErrorMessage(errors.billingUnits)
                      }
                      name={name}
                    >
                      <TextInput
                        value={value ? `${value}` : ''}
                        onBlur={(e) => onChangeHelper(e, onChange)}
                        onChange={(e) => onChangeHelper(e, onChange)}
                        type="number"
                      />
                    </FormControl>
                  )}
                  name="billingUnits"
                />
              </>
            )}
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <FormControl
                  label={translate('createSocietyForm.labels.societyName')}
                  error={errors.name && getErrorMessage(errors.name)}
                  name={name}
                  infoTooltipTitle={translate(
                    'createSocietyForm.societyNameTooltipTitle'
                  )}
                  infoTooltipText={translate(
                    'createSocietyForm.societyNameTooltipText'
                  )}
                  toolTipUseFlexibleWidth
                >
                  <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormControl>
              )}
              name="name"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <FormControl
                  label={translate('common.form.labels.addressStreet')}
                  error={
                    errors.addressStreet &&
                    getErrorMessage(errors.addressStreet)
                  }
                  name={name}
                >
                  <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormControl>
              )}
              name="addressStreet"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <FormControl
                  label={translate('common.form.labels.addressZip')}
                  error={
                    errors.addressZip && getErrorMessage(errors.addressZip)
                  }
                  name={name}
                >
                  <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    type="number"
                  />
                </FormControl>
              )}
              name="addressZip"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <FormControl
                  label={translate('common.form.labels.addressCity')}
                  error={
                    errors.addressCity && getErrorMessage(errors.addressCity)
                  }
                  name={name}
                >
                  <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormControl>
              )}
              name="addressCity"
            />
            <div className="my-4 flex w-full justify-end">
              <Button
                variant={ButtonVariant.PRIMARY}
                loading={loading}
                disabled={!isValid || loading}
                label={translate('createSocietyForm.createSocietyTitle')}
                type="submit"
              />
            </div>
          </HorizontalFormSection>
        </form>
        <AlreadyExistingSocietyModal
          show={!!alreadyExistingSociety}
          close={closeAlreadyExistingSocietyModal}
          society={alreadyExistingSociety}
        />
      </>
    )
  }
)
