import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { theme } from '../../../../theme/theme'
import { Button, ButtonVariant } from '../../../common/Button'
import { Icon, IconChoices } from '../../../common/Icon'
import { Illustration, IllustrationChoices } from '../../../common/Illustration'

export const PendingRequest = (): JSX.Element => {
  const navigate = useNavigate()
  const { translate } = useAppTranslation()

  const goToFeed = (): void => {
    navigate(reverseUrl('feed'))
  }

  return (
    <div className="flex flex-col">
      <Illustration illustrationChoice={IllustrationChoices.HAPPY} />
      <p style={theme.textVariants.h2} className="mt-6">
        {translate('pendingRequest.title')}
      </p>
      <p style={theme.textVariants.h3} className="mt-2">
        {translate('pendingRequest.subtitle')}
      </p>
      <p style={theme.textVariants.base} className="mt-3">
        {translate('pendingRequest.titleAdvice')}
      </p>
      <div className="mt-5 border-t" />
      <p style={theme.textVariants.lead} className="mt-5">
        {translate('pendingRequest.whatHappensNextTitle')}
      </p>
      <div className="mt-3 flex space-x-6">
        <Icon icon={IconChoices.BELL} color={theme.colors.brandGreen} />
        <p style={theme.textVariants.base}>
          {translate('pendingRequest.notificationText')}
        </p>
      </div>
      <div className="mt-3 flex space-x-6">
        <Icon icon={IconChoices.ASSIGNMENT} color={theme.colors.brandGreen} />
        <p style={theme.textVariants.base}>
          {translate('pendingRequest.assignmentText')}
        </p>
      </div>
      <Button
        variant={ButtonVariant.PRIMARY}
        className="mt-8"
        label={translate('pendingRequest.checkOutTheApp')}
        onClick={goToFeed}
      />
    </div>
  )
}
