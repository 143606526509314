import React from 'react'
import { theme } from '../../../theme/theme'

export interface RadioButton {
  label: string
  description?: string
  value: string | number | readonly string[]
  checked: boolean
  name: string
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  disabled?: boolean
}

export const RadioButton = ({
  label,
  description,
  value,
  onChange,
  checked,
  name,
  disabled,
}: RadioButton): JSX.Element => {
  return (
    <div className="flex flex-col">
      <div className="flex gap-2">
        <input
          id={name}
          onChange={onChange}
          type="radio"
          value={value}
          checked={checked}
          className={`mt-[1px] h-[22px] w-[22px] ${
            disabled ? '' : 'cursor-pointer'
          } accent-black grayscale`}
          disabled={disabled}
        />
        <div className={`flex flex-col gap-1 ${disabled ? 'opacity-50' : ''}`}>
          <label className="cursor-pointer" htmlFor={name}>
            {label}
          </label>
          {description && (
            <p className="text-neutral-40" style={theme.textVariants.caption}>
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
