import React, { useState } from 'react'
import { usePopper } from 'react-popper'

interface PopperBaseReturnValue {
  styles: {
    [key: string]: React.CSSProperties
  }
  attributes: {
    [key: string]:
      | {
          [key: string]: string
        }
      | undefined
  }
  setReferenceElement: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLDivElement | null>
  >
  setArrowElement: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
  setPopperElement: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
}

export const usePopperBase = (
  element?: Element | null
): PopperBaseReturnValue => {
  const [referenceElement, setReferenceElement] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(
    element ?? referenceElement,
    popperElement,
    {
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
      placement: 'auto',
    }
  )

  return {
    styles,
    attributes,
    setReferenceElement,
    setArrowElement,
    setPopperElement,
  }
}
