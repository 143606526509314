import React from 'react'
import { observer } from 'mobx-react-lite'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { theme } from '../../../theme/theme'
import { useStores } from '../../../hooks/useStores'
import { UserAvatar } from '../Avatar'
import { Icon, IconChoices } from '../Icon'
import { NotificationButtonController } from '../../notifications/NotificationButtonController'
import { Dropdown } from '../Dropdown'
import { useHamburger } from '../../../hooks/useHamburgerContext'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { NotificationBadge } from '../../notifications/NotificationBadge'
import { useAuth } from '../../../hooks/useAuth'
import { Button, ButtonVariant } from '../Button'
import { ManageHouseholdModal } from '../../my-home/ManageHousehold/ManageHouseholdModal'
import { useModal } from '../../../hooks/useModal'
import { useManageHousehold } from '../../../hooks/useManageHousehold'

export const NavBar = observer((): JSX.Element => {
  const { authenticationStore, userStore, notificationsStore, adminStore } =
    useStores()
  const { translate } = useAppTranslation()
  const {
    show: showManageHouseholdModal,
    open: openManageHouseholdModal,
    close: closeManageHouseholdModal,
  } = useModal()
  const enableManageHousehold = useManageHousehold()
  const {
    isHamburgerMode,
    displaySidebar,
    setDisplaySidebar,
    isMobileNavbar,
    hamburgerIcon,
  } = useHamburger()
  const { isBoappaSuperadmin } = useAuth()

  const links = [
    {
      title: translate('feedView.title'),
      path: reverseUrl('feed'),
      icon: IconChoices.FEED,
    },
    {
      title: translate('chatView.title'),
      path: reverseUrl('chat'),
      displayNotificationBadge: notificationsStore.unseenChatCount > 0,
      icon: IconChoices.CHAT,
    },
    {
      title: translate('societyView.title'),
      path: reverseUrl('society'),
      icon: IconChoices.HOUSEHOLD,
    },
    ...(isBoappaSuperadmin
      ? [
          {
            title: translate('adminView.title'),
            path: reverseUrl('admin'),
            icon: IconChoices.ADMIN,
          },
        ]
      : []),
  ]

  const navigate = useNavigate()

  const onLogoutClick = (): void => {
    authenticationStore.logout()
    navigate(reverseUrl('login'))
    window.location.reload()
  }

  const navigateToFeed = (): void => {
    navigate(reverseUrl('feed'))
  }

  const navigateToSettings = (): void => {
    navigate(reverseUrl('settings'))
  }

  const onClickHamburger = (): void => {
    setDisplaySidebar(!displaySidebar)
  }

  const dropdownOptions = [
    {
      value: 'settings',
      label: translate('settingsView.title'),
      onClick: navigateToSettings,
      icon: IconChoices.PROFILE_BADGE,
    },
    ...(enableManageHousehold
      ? [
          {
            value: 'my-home',
            label: translate('manageHousehold.title'),
            onClick: openManageHouseholdModal,
            icon: IconChoices.HOME,
          },
        ]
      : []),
    {
      value: 'edit',
      label: translate('common.actions.logout'),
      onClick: onLogoutClick,
      icon: IconChoices.LEAVE,
    },
  ]

  const logOutAsUser = async (): Promise<void> => {
    authenticationStore.logoutAsUser()
    window.location.reload()
  }

  return (
    <>
      <nav
        className="border-1 flex h-full items-center justify-between border-b"
        style={
          isMobileNavbar
            ? { height: theme.header.mobileHeight }
            : { height: theme.header.height }
        }
      >
        <div className="flex h-full items-center">
          {isHamburgerMode && hamburgerIcon && (
            <div className="flex h-full w-12 items-center justify-center hover:bg-gray-light">
              <Icon
                className="h-full"
                icon={hamburgerIcon}
                onClick={onClickHamburger}
              />
            </div>
          )}
          {!isMobileNavbar && (
            <Icon
              size={110}
              className="ml-6 mr-14"
              icon={IconChoices.BOAPPA}
              onClick={navigateToFeed}
            />
          )}
          <div className="flex h-full">
            {links.map((link) => (
              <NavLink
                style={
                  isMobileNavbar
                    ? theme.textVariants.caption
                    : theme.textVariants.base
                }
                className={({ isActive }) =>
                  `flex h-full items-center justify-center whitespace-nowrap
                border-b border-b-2 hover:bg-gray-light
                px-6
                ${isMobileNavbar && 'px-2'}
                ${isActive ? 'border-brandGreen' : 'border-transparent'}`
                }
                key={link.path}
                to={link.path}
              >
                {isMobileNavbar ? <Icon icon={link.icon} /> : link.title}
                {link.displayNotificationBadge && (
                  <NotificationBadge
                    className="ml-2 mt-[2px]"
                    show
                    absoluteOffset={false}
                  />
                )}
              </NavLink>
            ))}
          </div>
        </div>
        <div className="flex h-full items-center">
          {adminStore.isTwilightUser && (
            <Button
              className="max-h-12"
              variant={ButtonVariant.PRIMARY}
              label={translate('boappaSuperadmin.logout')}
              onClick={logOutAsUser}
            />
          )}
          <NotificationButtonController
            navBarHeight={
              isMobileNavbar ? theme.header.mobileHeight : theme.header.height
            }
            buttonSize={isMobileNavbar ? 'no-padding' : 'md'}
          />
          <div
            className={`flex h-full items-center 
            ${isMobileNavbar ? 'px-2' : 'pl-3 pr-3'}
          `}
          >
            <Dropdown options={dropdownOptions}>
              <UserAvatar
                className="hover:cursor-pointer hover:brightness-90"
                size={isMobileNavbar ? 34 : 40}
                user={
                  authenticationStore.userId
                    ? userStore.users.get(authenticationStore.userId)
                    : undefined
                }
              />
            </Dropdown>
          </div>
        </div>
      </nav>
      {showManageHouseholdModal && (
        <ManageHouseholdModal
          show={showManageHouseholdModal}
          close={closeManageHouseholdModal}
        />
      )}
    </>
  )
})
