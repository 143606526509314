import React, { useEffect, useMemo, useReducer, useRef } from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { yupResolver } from '@hookform/resolvers/yup'
import { InferType } from 'yup'
import Compressor from 'compressorjs'
import { Controller, useForm } from 'react-hook-form'
import { useStores } from '../../../hooks/useStores'
import { SocietyModel } from '../../../state/models/society'
import { Avatar } from '../../common/Avatar'
import { DropdownSelect } from '../../common/DropdownSelect'
import { DropdownItemContent } from '../../feed/DropdownItemContent'
import { chatAskBoardSchema } from '../../../forms/schemas/chat_room'
import { FormControl } from '../../common/FormControl'
import { TextInput } from '../../common/TextInput'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { TextArea } from '../../common/TextArea'
import { Button, ButtonVariant } from '../../common/Button'
import { useFormErrorMessage } from '../../../hooks/useFormErrorMessage'
import { Callout } from '../../society/Callout'
import { Linkify } from '../../common/Linkify'
import { useUserBlockedInSociety } from '../../../hooks/useUserBlockedInSociety'
import { BlockedCallout } from '../../blocked/BlockedCallout'
import { IconChoices } from '../../common/Icon'
import { isImageType } from '../../../helpers/types'
import { imageUploadReducer } from '../../../reducers/image-upload'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'
import { ProcessedImage, ProcessedMedia } from '../../../types/image-upload'
import { formatFileSize } from '../../../helpers/number'
import { ImageActions } from '../../../actions/image-upload'
import { convertBase64 } from '../../../api/helpers'
import { PostThumbnails } from '../../feed/AttachmentsContainer/PostThumbnails'
import { Thumbnail } from '../../feed/AttachmentsContainer/Thumbnail'

interface AskBoardNewMessageModalContentProps {
  onError: () => void
  onSubmit: (data: InferType<typeof chatAskBoardSchema>) => Promise<void>
  onClose: () => void
  loading: boolean
}

const maxImageUploadLimit = 10

export const AskBoardNewMessageModalContent = ({
  onError,
  onSubmit,
  onClose,
  loading,
}: AskBoardNewMessageModalContentProps): JSX.Element => {
  const { societyStore, mediaStore } = useStores()
  const { translate } = useAppTranslation()
  const { getErrorMessage } = useFormErrorMessage()
  const { setToastNotification } = useToastNotifications()
  const [imagesState, imagesDispatch] = useReducer(imageUploadReducer, {
    images: [],
  })
  const fileInputRef = useRef<HTMLInputElement>(null)

  const societies = societyStore.sortedSocieties as SnapshotOut<
    typeof SocietyModel
  >[]

  const dropdownOptions = societies.map((society) => ({
    value: society._id,
    label: society.name,
    image: <Avatar size={30} mediaId={society.coverPhotoId} />,
  }))

  const getDefaultValues = useMemo(() => {
    return {
      society: societies[0]._id as string,
      subject: '',
      message: '',
      mediaIds: [],
    }
  }, [societies])

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<InferType<typeof chatAskBoardSchema>>({
    mode: 'all',
    resolver: yupResolver(chatAskBoardSchema),
    defaultValues: getDefaultValues,
  })

  useEffect(() => {
    setValue(
      'mediaIds',
      imagesState.images
        .map((_image) => _image.id)
        .filter((_id) => _id !== undefined) as string[],
      { shouldValidate: true, shouldDirty: true }
    )
  }, [imagesState, setValue])

  const hasImageLimitExceeded = (imagesLength: number): boolean => {
    return (
      imagesLength > maxImageUploadLimit ||
      imagesState.images.length + imagesLength > maxImageUploadLimit
    )
  }

  const uploadImage = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const images = e.target.files && e.target.files
    if (!images) {
      return
    }
    if (hasImageLimitExceeded(images.length)) {
      setToastNotification(
        ToastType.DANGER,
        translate('chatUpload.flashMessage.uploadImageLimitReached')
      )
    } else {
      Array.from(images).forEach((image: File) => {
        if (!isImageType(image.type)) {
          setToastNotification(
            ToastType.DANGER,
            translate('uploadImage.invalidImageType')
          )
          return
        }
        // eslint-disable-next-line no-new
        new Compressor(image, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success: async (result) => {
            const _image: ProcessedImage = {
              uri: URL.createObjectURL(image),
              name: image.name,
              size: formatFileSize(image.size),
            }

            imagesDispatch({ type: ImageActions.ADD, image: _image })

            const base64 = await convertBase64(result)
            const id = await mediaStore.createMedia('image', base64 as string)

            imagesDispatch({
              type: ImageActions.SET_UPLOADED,
              mediaId: id as string,
              image: _image,
            })
          },
        })
      })
    }
    e.target.value = ''
  }

  const onImageRemove = (id: string): void => {
    imagesDispatch({ type: ImageActions.REMOVE, id })
  }

  const selectedSocietyId = watch('society')
  const selectedSociety = societyStore.societies.get(selectedSocietyId)

  const userBlockedInSociety = useUserBlockedInSociety(selectedSocietyId)

  const askBoardDisabledForSociety =
    !!selectedSociety?.settings?.chatAskBoardDisabled
  const chatAskBoardDisabledResidentMessage =
    selectedSociety?.settings?.chatAskBoardDisabledResidentMessage

  const checkImageUploading = (images: ProcessedMedia[]): boolean => {
    return images.map((image) => image.uploading).includes(true)
  }
  const imageUploading = checkImageUploading(imagesState.images)

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className="mt-6 flex flex-col gap-4"
      >
        <Controller
          control={control}
          name="society"
          render={({ field: { value, onChange } }) => (
            <DropdownSelect
              value={value}
              onChange={onChange}
              options={dropdownOptions}
              dropdownWidth="w-full"
              renderItemContent={(option) => (
                <DropdownItemContent option={option} />
              )}
            />
          )}
        />
        {userBlockedInSociety && (
          <BlockedCallout selectedSocietyId={selectedSocietyId} />
        )}
        {askBoardDisabledForSociety ? (
          <div className="flex flex-col gap-4">
            <Callout
              text={translate('common.boardDisabledFeature')}
              showArrow={false}
            />
            {chatAskBoardDisabledResidentMessage && (
              <div className="whitespace-pre-wrap">
                <Linkify>{chatAskBoardDisabledResidentMessage}</Linkify>
              </div>
            )}
          </div>
        ) : (
          <>
            <Controller
              control={control}
              name="subject"
              render={({ field: { value, name, onChange, onBlur } }) => (
                <FormControl
                  label={translate(
                    'chatAskBoardNewMessage.form.labels.subject'
                  )}
                  error={errors.subject && getErrorMessage(errors.subject)}
                  name={name}
                >
                  <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.subject}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="message"
              render={({ field: { name, value, onChange, onBlur } }) => (
                <FormControl
                  name={name}
                  label={translate(
                    'chatAskBoardNewMessage.form.labels.message'
                  )}
                  error={errors.message && getErrorMessage(errors.message)}
                >
                  <TextArea
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.message}
                  />
                </FormControl>
              )}
            />

            <Button
              label={translate(
                'chatAskBoardNewMessage.media.attachImageButtonLabel'
              )}
              iconPlacement="right"
              icon={IconChoices.PLUS_SIGN}
              onClick={() => fileInputRef.current?.click()}
            />

            {imagesState.images.length > 0 && (
              <PostThumbnails
                header={translate(
                  'chatAskBoardNewMessage.media.imageHeaderTitle'
                )}
              >
                {imagesState.images.map((image, index) => (
                  <Thumbnail
                    key={`${image.uri}${image.id}`}
                    id={image.id as string}
                    uri={image.uri}
                    title={image.name || `Image-${index}`}
                    description={image.size || 'Unknown file size'}
                    index={index}
                    onThumbnailRemove={onImageRemove}
                    onThumbnailClick={() =>
                      window.open(image.uri, '_blank', 'noreferrer noopener')
                    }
                    loading={image.uploading}
                  />
                ))}
              </PostThumbnails>
            )}
            <Controller
              control={control}
              name="mediaIds"
              render={() => (
                <input
                  ref={fileInputRef}
                  id="post-image-input"
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  className="h-0 w-0 max-w-0 opacity-0"
                  tabIndex={0}
                  multiple
                  onChange={uploadImage}
                />
              )}
            />
          </>
        )}
        <div
          className={`${
            askBoardDisabledForSociety ? 'mt-2' : 'mt-10'
          } flex flex-shrink-0 flex-wrap items-center justify-end gap-4`}
        >
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            disabled={
              imageUploading ||
              userBlockedInSociety ||
              askBoardDisabledForSociety ||
              !isValid ||
              loading
            }
            variant={ButtonVariant.PRIMARY}
            label={translate('common.actions.send')}
            type="submit"
          />
        </div>
      </form>
    </div>
  )
}
