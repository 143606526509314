import * as yup from 'yup'

export const societyLocalOfferCreateSchema = yup
  .object({
    discountHeader: yup.string().required(),
    companyName: yup.string().required(),
    bodyText: yup.string().required(),
    email: yup.string().email(),
    phoneNumber: yup.string(),
    website: yup.string(),
    coverPhotoId: yup.string(),
    societyId: yup.string().required(),
    notificationSetting: yup
      .string()
      .oneOf(['none', 'feed', 'notification+feed']),
  })
  .required()
