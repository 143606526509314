import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useStores } from '../../../../hooks/useStores'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { ViewBase } from '../../../../components/common/ViewBase'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { InvoiceDetails } from '../../../../components/society/InvoiceDetails'
import { PaymentDetails } from '../../../../components/society/PaymentDetails'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { useInvoiceTabs } from './useInvoiceTabs'
import { RouteErrorView } from '../../../error/RouteErrorView'

export enum InvoiceViewTabs {
  PAYMENT = 0,
  DETAILS = 1,
}

export const InvoiceView = observer((): JSX.Element => {
  const { societyStore, settingsStore } = useStores()
  const { translate } = useAppTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { getTabUrl, getTabState } = useInvoiceTabs()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:billing', {
      '{{ societyName }}': society.name,
    })
  )

  const [activeTab, setActiveTab] = useState<InvoiceViewTabs>(
    getTabState(location.pathname)
  )

  useEffect(() => {
    settingsStore.getAllSettings()
  }, [settingsStore])

  // When a new activeTab is set, we change the navigation to that tab as well
  useEffect(() => {
    const url = getTabUrl(activeTab)
    if (location.pathname !== url) {
      navigate(url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  // When a new location is set (e.g. backbutton) we change the activeTab as well
  useEffect(() => {
    setActiveTab(getTabState(location.pathname))
  }, [getTabState, location.pathname])

  const tabs = [
    {
      title: translate('invoiceView.tabBar.payment'),
    },
    {
      title: translate('invoiceView.tabBar.details'),
    },
  ]

  const loading = societyStore.fetchingSociety === 'pending'

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('invoiceView.title')}
        subtitle={translate('invoiceView.subtitle')}
        tabBar={{ tabs, setActiveTab, activeTab }}
      >
        <Routes>
          <Route
            path="payment"
            element={<PaymentDetails loading={loading} />}
            errorElement={<RouteErrorView />}
          />
          <Route
            path="details"
            element={<InvoiceDetails loading={loading} />}
            errorElement={<RouteErrorView />}
          />
        </Routes>
      </ViewBase>
    </ErrorBoundary>
  )
})
