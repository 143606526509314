import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { SocietyModel } from '../../../state/models/society'
import { theme } from '../../../theme/theme'
import { useStores } from '../../../hooks/useStores'
import {
  getFacilityBookings,
  getSocietyResidents,
} from '../../../api/statistics'
import { ExportCard } from './ExportsCard'

interface ExportSectionTable {
  title: string
  cards: ExportCard[]
}

interface ExportSectionTableProps extends ExportSectionTable {
  className?: string
}

export const ExportSectionTable = ({
  title,
  cards,
  className,
}: ExportSectionTableProps): JSX.Element => {
  return (
    <div className={`${className ?? ''}`}>
      <p className="my-4" style={theme.textVariants.lead}>
        {title}
      </p>
      <div>
        {cards.map((_card, index) => {
          return (
            <ExportCard
              key={_card.title}
              {..._card}
              className={`${index < cards.length - 1 && 'mb-3'}`}
            />
          )
        })}
      </div>
    </div>
  )
}

interface ExportsSectionProps {
  society: SnapshotOut<typeof SocietyModel>
}

export const ExportsSection = observer(
  ({ society }: ExportsSectionProps): JSX.Element => {
    const { facilitiesStore } = useStores()
    const { translate } = useAppTranslation()

    const facilities = facilitiesStore.facilitiesForSociety(society._id)

    const onResidentListDownClick = async (): Promise<string> => {
      return getSocietyResidents(society._id)
    }

    const sections: ExportSectionTable[] = [
      {
        title: translate(
          'societyStatisticsExportsView.exports.residentList.headerTitle'
        ),
        cards: [
          {
            title: translate(
              'societyStatisticsExportsView.exports.residentList.residentListDownload'
            ),
            getExportLink: onResidentListDownClick,
          },
        ],
      },
      ...(facilities.length > 0
        ? [
            {
              title: translate(
                'societyStatisticsExportsView.exports.bookingsList.headerTitle'
              ),
              cards: facilities.map((facility) => ({
                title: facility.name,
                getExportLink: async () =>
                  getFacilityBookings(society._id, facility._id),
              })),
            },
          ]
        : []),
    ]

    return (
      <div>
        {sections.map((_section, index) => (
          <ExportSectionTable
            key={_section.title}
            title={_section.title}
            cards={_section.cards}
            className={`${index < sections.length - 1 && 'mb-6 md:mb-12'}`}
          />
        ))}
      </div>
    )
  }
)
