import { TrackingEventType, TrackingProperty } from '../types/tracking'
import { debounce } from '../helpers/performance'
import { sendBatchTrackingData } from '../api/tracking'
import config from '../config'

export interface TrackingEvent {
  event: TrackingEventType
  properties?: { [e in TrackingProperty]?: string | number }
}

class TrackingService {
  protected _events: TrackingEvent[]

  constructor() {
    this._events = []
  }

  // Debounce the sendEvents by 1 sec to reduce network usage a bit
  protected sendEvents = debounce(async () => {
    // Take all events from queue
    const events = this._events.splice(0)

    let parsedEvents: TrackingEvent[] = Array.isArray(events)
      ? (events as TrackingEvent[])
      : ([events] as TrackingEvent[])

    parsedEvents = parsedEvents.filter((event) => {
      if (event === null) {
        return false
      }
      return true
    })

    if (parsedEvents === null || parsedEvents.length === 0) {
      return
    }

    try {
      await sendBatchTrackingData(parsedEvents)
    } catch (error) {
      // Re-add events to queue for retry
      this._events = events.concat(this._events)
      // This causes a lot of errors with floods sentry. Let's disable for now.
      // captureException(error)
    }
  }, 1000)

  public track(event: TrackingEvent): void {
    if (!config.DEBUG) {
      this._events.push(event)
      this.sendEvents()
    }
  }
}

export const tracking = new TrackingService()
