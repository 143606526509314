import React from 'react'

interface ModalFooterProps {
  children: React.ReactNode
  className?: string
}

export const ModalFooter = ({
  children,
  className,
}: ModalFooterProps): JSX.Element => {
  return (
    <div
      className={`flex flex-shrink-0 flex-wrap items-center justify-end gap-2 
      rounded-b-md p-4 ${className ?? ''}`}
    >
      {children}
    </div>
  )
}
