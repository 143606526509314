import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonVariant } from '../../../components/common/Button'
import {
  Illustration,
  IllustrationChoices,
} from '../../../components/common/Illustration'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useDocumentTitle } from '../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../navigation/reverseDocumentTitle'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { theme } from '../../../theme/theme'
import { useStores } from '../../../hooks/useStores'
import { useAuth } from '../../../hooks/useAuth'

export const ActivatedSocietyConfirmationView = (): JSX.Element => {
  const { userId } = useAuth()
  const { societyStore } = useStores()
  const { hasFetchedSocietiesOnce } = societyStore
  const navigate = useNavigate()
  const { translate } = useAppTranslation()
  useDocumentTitle(reverseDocumentTitle('activated-society'))

  const navigateToSociety = (): void => {
    navigate(reverseUrl('society'), { replace: true })
  }

  useEffect(() => {
    if (userId && !hasFetchedSocietiesOnce) {
      societyStore.getUserSocieties(userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <Illustration illustrationChoice={IllustrationChoices.WINNERS} />
      <div className="mt-10 flex w-[500px] flex-col items-start">
        <p style={theme.textVariants.h1}>
          {translate('activatedSocietyConfirmationView.title')}
        </p>
        <p className="mb-10 mt-4" style={theme.textVariants.base}>
          {translate('activatedSocietyConfirmationView.subtitle')}
        </p>
        <Button
          className="w-full"
          wrapperClassName="w-full"
          variant={ButtonVariant.PRIMARY}
          onClick={navigateToSociety}
          label={translate('activatedSocietyConfirmationView.buttonTitle')}
        />
      </div>
    </div>
  )
}
