import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useStores } from '../../../../hooks/useStores'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { CreateUpdateEventForm } from '../CreateUpdateEventForm'
import { societyEventCreateSchema } from '../../../../forms/schemas/society_event_create'
import { EventModel } from '../../../../state/models/event'
import { NEvents } from '../../../../interfaces/services/events.interfaces'
import { NBoardRoomEvents } from '../../../../interfaces/services/boardroom-events.interfaces'

interface CreateUpdateEventModalProps {
  show: boolean
  close: () => void
  children?: React.ReactNode
  event?: SnapshotOut<typeof EventModel>
  boardRoomEvent?: boolean
}

export const CreateUpdateEventModal = observer(
  ({
    event,
    children,
    show,
    close,
    boardRoomEvent,
  }: CreateUpdateEventModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { eventStore, boardRoomEventStore } = useStores()
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!event

    const showErrorMessage = (): void => {
      const message = updateMode
        ? 'createUpdateEvent.flashMessage.updateEventFailure'
        : 'createUpdateEvent.flashMessage.createEventFailure'
      setToastNotification(ToastType.DANGER, translate(message))
    }

    const showSuccessMessage = (): void => {
      const message = updateMode
        ? 'createUpdateEvent.flashMessage.updateEventSuccess'
        : 'createUpdateEvent.flashMessage.createEventSuccess'
      setToastNotification(ToastType.SUCCESS, translate(message))
    }

    const onSubmit = async (
      data: InferType<typeof societyEventCreateSchema>
    ): Promise<void> => {
      if (updateMode) {
        const status = boardRoomEvent
          ? await boardRoomEventStore.patchEvent(
              event._id,
              data as NBoardRoomEvents.NPatch.IRequestBody
            )
          : await eventStore.patchEvent(
              event._id,
              data as NEvents.NPatch.IRequestBody
            )

        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      } else {
        const status = boardRoomEvent
          ? await boardRoomEventStore.createEvent(
              data as NBoardRoomEvents.NCreate.IRequestBody
            )
          : await eventStore.createEvent(data as NEvents.NCreate.IRequestBody)
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      showErrorMessage()
    }

    const loading =
      eventStore.creatingEvent === 'pending' ||
      eventStore.updatingEvent === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate(
                  updateMode
                    ? 'createUpdateEvent.modal.editTitle'
                    : 'createUpdateEvent.modal.createTitle'
                )}
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <CreateUpdateEventForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  event={event}
                  boardRoomEvent={boardRoomEvent}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
