import React from 'react'
import { useStores } from '../../../hooks/useStores'
import { LikesUserListItem } from '../LikesUserListItem'

interface SeenByModalContentProps {
  seenBy: { userId: string }[]
  societyId: string
}

export const SeenByModalContent = ({
  seenBy,
  societyId,
}: SeenByModalContentProps): JSX.Element => {
  const { userStore } = useStores()

  return (
    <div className="flex flex-col">
      {seenBy.map((entry, i) => {
        const user = userStore.users.get(entry.userId)
        return user ? (
          <LikesUserListItem
            key={user._id}
            index={i}
            user={user}
            societyId={societyId}
          />
        ) : null
      })}
    </div>
  )
}
