import React, { KeyboardEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../hooks/useStores'
import { Icon, IconChoices } from '../Icon'
import { Spinner } from '../Spinner'

interface ImageBaseProps {
  onClick?: () => void
  onDeleteClick?: () => void
  size?: number
  alt?: string
  className?: string
  style?: React.CSSProperties
  imageClassName?: string
  imageStyle?: React.CSSProperties
  shouldCloseTopRight?: boolean
  objectFit?: string
  disabled?: boolean
}
interface ImageProps extends ImageBaseProps {
  url: string | undefined
}

export const Image = observer(
  ({
    url,
    alt = 'image',
    onClick,
    onDeleteClick,
    className,
    style,
    imageClassName,
    shouldCloseTopRight = false,
    imageStyle,
    objectFit,
    disabled,
  }: ImageProps): JSX.Element => {
    const handleKeyDown = (e: KeyboardEvent<HTMLImageElement>): void => {
      if (e.key === 'Enter' && onClick) {
        onClick()
      }
    }

    return (
      <div
        className={`relative ${
          onClick && !disabled ? 'cursor-pointer' : ''
        } h-full w-full items-center justify-center ${className}`}
        role="button"
        tabIndex={onClick ? 0 : -1}
        style={{ maxWidth: '100%', maxHeight: '100%', ...style }}
        onClick={onClick}
        onKeyDown={handleKeyDown}
      >
        {url ? (
          <img
            alt={alt}
            className={`h-full w-full
            ${objectFit || 'object-cover'}
            ${imageClassName ?? ''}
          `}
            src={url}
            style={imageStyle}
          />
        ) : (
          <div className="flex h-full w-full items-center justify-center">
            <Spinner />
          </div>
        )}
        {onDeleteClick &&
          (!shouldCloseTopRight ? (
            <button
              className="absolute right-2 top-2 rounded-2xl bg-neutral-90 bg-opacity-70
                p-1 hover:bg-opacity-100"
              onClick={onDeleteClick}
              type="button"
            >
              <Icon icon={IconChoices.DELETE_TRASH} />
            </button>
          ) : (
            <button
              className="absolute -right-3 -top-3 rounded-2xl p-1 hover:bg-gray-light"
              onClick={onDeleteClick}
              type="button"
            >
              <Icon icon={IconChoices.CLOSE} />
            </button>
          ))}
      </div>
    )
  }
)

interface MediaImageProps extends ImageBaseProps {
  mediaId: string | null | undefined
}

export const MediaImage = observer(
  ({
    mediaId,
    alt,
    onClick,
    onDeleteClick,
    className,
    style,
    imageClassName,
    imageStyle,
    shouldCloseTopRight,
    objectFit,
    disabled,
  }: MediaImageProps): JSX.Element | null => {
    const { mediaStore } = useStores()
    const media = mediaId ? mediaStore.media.get(mediaId) : undefined

    return (
      <Image
        url={media?.url}
        alt={alt}
        onClick={onClick}
        onDeleteClick={onDeleteClick}
        className={className}
        style={style}
        imageClassName={imageClassName}
        imageStyle={imageStyle}
        shouldCloseTopRight={shouldCloseTopRight}
        objectFit={objectFit}
        disabled={disabled}
      />
    )
  }
)

interface FixedHeightMediaImageWithBackgroundProps extends MediaImageProps {
  wrapperClassNames: string
}

export const FixedHeightMediaImageWithBackground = observer(
  ({
    wrapperClassNames,
    ...props
  }: FixedHeightMediaImageWithBackgroundProps): JSX.Element | null => {
    return (
      <div
        className={`flex justify-center rounded-md bg-neutral-96 ${
          wrapperClassNames ?? ''
        }`}
      >
        <MediaImage objectFit="object-contain" {...props} />
      </div>
    )
  }
)
