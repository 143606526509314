import { Interests } from '../../types/interests'
import { translateEnumDict } from './helpers'

const interestTranslations: { [key: string]: string } = {
  [Interests.ActionSports]: 'interests.actionSports',
  [Interests.Animals]: 'interests.animals',
  [Interests.Art]: 'interests.art',
  [Interests.BoardGames]: 'interests.boardGames',
  [Interests.Cars]: 'interests.cars',
  [Interests.Cooking]: 'interests.cooking',
  [Interests.Dancing]: 'interests.dancing',
  [Interests.Design]: 'interests.design',
  [Interests.ElectronicsSmartHome]: 'interests.electronicsSmartHomes',
  [Interests.Entrepreneurship]: 'interests.entrepreneurship',
  [Interests.Family]: 'interests.family',
  [Interests.Film]: 'interests.film',
  [Interests.Fitness]: 'interests.fitness',
  [Interests.Gardening]: 'interests.gardening',
  [Interests.Handicraft]: 'interests.handicraft',
  [Interests.Literature]: 'interests.literature',
  [Interests.Motorcycles]: 'interests.motorcycles',
  [Interests.Music]: 'interests.music',
  [Interests.Nature]: 'interests.nature',
  [Interests.Outdoors]: 'interests.outdoors',
  [Interests.Painting]: 'interests.painting',
  [Interests.Politics]: 'interests.politics',
  [Interests.Reading]: 'interests.reading',
  [Interests.Religion]: 'interests.religion',
  [Interests.Sports]: 'interests.sports',
  [Interests.Startups]: 'interests.startups',
  [Interests.StockTrading]: 'interests.stockTrading',
  [Interests.Tech]: 'interests.tech',
  [Interests.Toddlers]: 'interests.toddlers',
  [Interests.Travel]: 'interests.travel',
}

export const getInterest = (interest: Interests): string | null => {
  return translateEnumDict(interestTranslations, interest)
}
