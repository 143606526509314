import React, { KeyboardEvent } from 'react'
import { Icon, IconChoices } from '../../../common/Icon'
import { theme } from '../../../../theme/theme'

interface ContactCardInfoItemProps {
  icon: IconChoices
  label: string
  text: string
  onClick: () => void
}

export const ContactCardInfoItem = ({
  icon,
  label,
  text,
  onClick,
}: ContactCardInfoItemProps): JSX.Element => {
  const handleKeyDown = (e: KeyboardEvent<HTMLButtonElement>): void => {
    if (e.key === 'Enter' && onClick) {
      onClick()
    }
  }

  return (
    <div className="flex w-full gap-1">
      <div className="flex gap-2 text-neutral-40">
        <Icon icon={icon} color="text-neutral-40" size={20} />
        <p style={theme.textVariants.caption}>{label}</p>
      </div>
      <button
        className="flex w-full min-w-0 hover:underline"
        style={theme.textVariants.caption}
        onClick={onClick}
        onKeyDown={handleKeyDown}
      >
        <p className="max-w-full overflow-hidden text-ellipsis">{text}</p>
      </button>
    </div>
  )
}
