import { useEffect, useState } from 'react'
import { useHamburger } from './useHamburgerContext'

interface SidebarAnimationReturnType {
  overlayAnimation: string
  sidebarAnimation: string
  sidebarStartPosition: string
}

export const useSidebarAnimation = (): SidebarAnimationReturnType => {
  const { displaySidebar, isHamburgerMode } = useHamburger()
  const [sidebar, setSidebar] = useState(!isHamburgerMode)
  const [overlay, setOverlay] = useState(isHamburgerMode)
  const [initSidebar, setInitSidebar] = useState(false)
  const [hasSidebarBeenRendered, setHasSidebarBeenRendered] = useState(false)
  const [initOverlay, setInitOverlay] = useState(false)

  useEffect(() => {
    isHamburgerMode && setInitSidebar(true)
    displaySidebar && setHasSidebarBeenRendered(true)
    initSidebar && !isHamburgerMode && setSidebar(true)
    initSidebar && isHamburgerMode && displaySidebar && setSidebar(true)
    initSidebar && isHamburgerMode && !displaySidebar && setSidebar(false)

    displaySidebar && isHamburgerMode && setInitOverlay(true)
    initSidebar && isHamburgerMode && displaySidebar && setOverlay(true)
    initSidebar && isHamburgerMode && !displaySidebar && setOverlay(false)
  }, [displaySidebar, initSidebar, isHamburgerMode])

  const sidebarAnimationClass = sidebar
    ? 'animate-slideSidebarRight'
    : 'animate-slideSidebarLeft'

  const overlayAnimationClass = overlay
    ? 'animate-toBackgroundGray'
    : 'animate-toBackgroundWhite'

  const sidebarStartPosition =
    !initSidebar || !hasSidebarBeenRendered ? 'left-[-342px]' : 'left-0'

  const overlayAnimation = initOverlay
    ? overlayAnimationClass
    : 'pointer-events-none'

  const sidebarAnimation =
    initSidebar && hasSidebarBeenRendered ? sidebarAnimationClass : ''

  return {
    overlayAnimation,
    sidebarAnimation,
    sidebarStartPosition,
  }
}
