import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { SocietyContactModel } from '../../../state/models/society-contact'
import { Card } from '../../common/Card'
import { CardBody } from '../../common/Card/CardBody'
import { CardFooter } from '../../common/Card/CardFooter'
import { Avatar } from '../../common/Avatar'
import { theme } from '../../../theme/theme'
import { Button, ButtonVariant } from '../../common/Button'
import { IconChoices } from '../../common/Icon'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { openEmailMailTo } from '../../../helpers/email'
import { openUrl } from '../../../helpers/url'
import { Dropdown } from '../../common/Dropdown'
import { isUserAdminInSociety } from '../../../helpers/society'
import { useStores } from '../../../hooks/useStores'
import { SocietyModel } from '../../../state/models/society'
import { useModal } from '../../../hooks/useModal'
import { CreateUpdateContactModal } from '../Contact/CreateUpdateContactModal'
import { ToastType } from '../../common/Toast/toast-type'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ConfirmationModal } from '../../common/ConfirmationModal'
import { ContactCardInfoItem } from './ContactCardInfoItem'

interface ContactCardProps {
  contact: SnapshotOut<typeof SocietyContactModel>
  management: boolean
}

export const ContactCard = observer(
  ({ contact, management }: ContactCardProps): JSX.Element => {
    const {
      authenticationStore,
      societyStore,
      societyBoardroomContactsStore,
      societyContactsStore,
    } = useStores()

    const { translate } = useAppTranslation()
    const {
      show: showEditModal,
      open: openEditModal,
      close: closeEditModal,
    } = useModal()
    const {
      show: showDeleteConfirmationModal,
      close: closeDeleteConfirmationModal,
      open: openDeleteConfirmationModal,
    } = useModal()
    const { setToastNotification } = useToastNotifications()

    const openEmail = (): void => {
      openEmailMailTo(contact.email)
    }

    const onCopyPhone = (): void => {
      if (contact.phone) {
        navigator.clipboard.writeText(contact.phone)
        setToastNotification(
          ToastType.SUCCESS,
          translate('common.copiedToClipboard')
        )
      }
    }

    const openWebsite = (): void => {
      const validUrl = openUrl(contact.website)
      if (!validUrl) {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }
    }

    const contactSociety = societyStore.societies.get(
      contact.societyId
    ) as SnapshotOut<typeof SocietyModel>

    const isAdmin = isUserAdminInSociety(
      contactSociety,
      authenticationStore.userId as string
    )

    const deleteContact = async (): Promise<void> => {
      const status = management
        ? await societyBoardroomContactsStore.deleteContact(contact._id)
        : await societyContactsStore.deleteContact(contact._id)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('contactCard.flashMessage.deleteContactSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('contactCard.flashMessage.deleteContactFailure')
        )
      }
    }

    const dropdownOptions = [
      {
        value: 'edit',
        label: translate('common.actions.edit'),
        onClick: () => openEditModal(),
      },
      {
        value: 'remove',
        label: translate('common.actions.delete'),
        onClick: openDeleteConfirmationModal,
        destructive: true,
      },
    ]

    return (
      <>
        <Card className="w-full md:w-[400px]">
          {{
            body: (
              <CardBody padding="p-6" className="min-h-48">
                <div className="flex flex-col">
                  <div className="flex flex-col items-start">
                    <div className="flex w-full flex-row items-start justify-between">
                      <div className="flex flex-col items-start">
                        <p style={theme.textVariants.baseBold}>
                          {contact.title}
                        </p>
                        <div className="flex w-full items-start justify-between">
                          <p
                            className="text-left text-neutral-40"
                            style={theme.textVariants.caption}
                          >
                            {contact.name}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <Avatar mediaId={contact.mediaId} size={56} />
                        {isAdmin && (
                          <Dropdown
                            dropdownPosition="bottom-left"
                            options={dropdownOptions}
                          >
                            <Button
                              icon={IconChoices.KEBAB_MENU}
                              variant={ButtonVariant.TEXT}
                              className="h-10 w-10"
                              disableTabIndex
                            />
                          </Dropdown>
                        )}
                      </div>
                    </div>
                  </div>
                  {contact.description && (
                    <div className="flex flex-col items-start">
                      <div className="my-3 w-full border-t" />
                      <p
                        className="whitespace-pre-line text-left text-neutral-40"
                        style={theme.textVariants.caption}
                        title={contact.description}
                      >
                        {contact.description}
                      </p>
                    </div>
                  )}
                </div>
              </CardBody>
            ),
            footer: (
              <CardFooter>
                <div
                  className={`mx-6 flex items-start overflow-hidden py-4 ${
                    (contact.email || contact.phone || contact.website) &&
                    'border-t'
                  }`}
                >
                  <div className="flex w-full flex-col gap-2">
                    {contact.email && (
                      <ContactCardInfoItem
                        icon={IconChoices.ENVELOPE}
                        label={translate('contactCard.email')}
                        text={contact.email}
                        onClick={openEmail}
                      />
                    )}
                    {contact.phone && (
                      <ContactCardInfoItem
                        icon={IconChoices.PHONE}
                        label={translate('contactCard.call')}
                        text={contact.phone}
                        onClick={onCopyPhone}
                      />
                    )}
                    {contact.website && (
                      <ContactCardInfoItem
                        icon={IconChoices.WEBSITE}
                        label={translate('contactCard.website')}
                        text={contact.website}
                        onClick={openWebsite}
                      />
                    )}
                  </div>
                </div>
              </CardFooter>
            ),
          }}
        </Card>
        <CreateUpdateContactModal
          show={showEditModal}
          close={closeEditModal}
          contact={contact}
          management={management}
        />
        <ConfirmationModal
          title={translate('contactCard.alert.deleteContactTitle')}
          description={translate('contactCard.alert.deleteContactText')}
          show={showDeleteConfirmationModal}
          close={closeDeleteConfirmationModal}
          onConfirm={deleteContact}
        />
      </>
    )
  }
)
