import React from 'react'
import { captureException } from '@sentry/react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { Modal } from '../../../../../components/common/Modal'
import { ModalHeader } from '../../../../../components/common/Modal/ModalHeader'
import { ModalBody } from '../../../../../components/common/Modal/ModalBody'
import { useStores } from '../../../../../hooks/useStores'
import { SetPartnerModalContent } from './SetPartnerModalContent'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { adminSetPartnerSchema } from '../../../../../forms/schemas/admin_set_partner'
import { ToastType } from '../../../../../components/common/Toast/toast-type'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import {
  ManagementPartner,
  getPartnerId,
} from '../../../../../components/society/ManagementPartner/management_partner'
import { useAdminSociety } from '../useAdminSociety'

interface SetPartnerModalProps {
  show: boolean
  close: () => void
  societyId?: string
}

export const SetPartnerModal = observer(
  ({ show, close, societyId }: SetPartnerModalProps): JSX.Element => {
    const { adminStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()
    const { settingsStore } = useStores()
    const { adminSociety, triggerRefresh } = useAdminSociety(societyId)

    const discountCodes = settingsStore.listDiscountCodes

    const onSubmit = async (
      data: InferType<typeof adminSetPartnerSchema>
    ): Promise<void> => {
      const partnerNameId = discountCodes.find(
        (discount) => discount.code === data.code
      )?.partnerNameId as ManagementPartner
      const partnerId = partnerNameId && getPartnerId(partnerNameId)
      const status =
        societyId &&
        (await adminStore.setSocietyPartner({
          partnerId,
          societyId,
        }))
      if (status) {
        close()
        triggerRefresh()
        setToastNotification(ToastType.SUCCESS, 'Uppdaterade partner')
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }
    }

    const resetPartner = async (): Promise<void> => {
      const status =
        societyId &&
        (await adminStore.setSocietyPartner({
          partnerId: undefined,
          societyId,
        }))
      if (status) {
        close()
        triggerRefresh()
        setToastNotification(ToastType.SUCCESS, 'Tog bort nuvarande partner')
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    return (
      <Modal show={show}>
        {{
          header: <ModalHeader onClose={close}>Uppdatera partner</ModalHeader>,
          body: (
            <ModalBody className="max-h-96 overflow-y-auto">
              {adminSociety && (
                <SetPartnerModalContent
                  onSubmit={onSubmit}
                  onError={onError}
                  onClose={close}
                  resetPartner={resetPartner}
                  society={adminSociety}
                />
              )}
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
