/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, getRoot, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import { InferType } from 'yup'
import { IUnitModel } from '../../interfaces/models/units.interfaces'
import {
  getUnitsPerSociety as apiGetUnitsPerSociety,
  getUnit as apiGetUnit,
  patchUnit as apiPatchUnit,
  deleteUnit as apiDeleteUnit,
  createUnit as apiCreateUnit,
  getUserUnits as apiGetUserUnits,
} from '../../api/units'
import {
  createUserRole as apiCreateUserRole,
  deleteUserRole as apiDeleteUserRole,
} from '../../api/societies'
import { NUnits } from '../../interfaces/services/units.interfaces'
import { UnitModel } from '../models/unit'
import { UserModel } from '../models/user'
import { stateType } from '../types/common'
import { RootStore } from './root'
import { setObject } from './helpers'
import { sortStringsAlphabetically } from '../../helpers/sorting'
import { uniqueBy } from '../../helpers/array'
import { societyUserUnitUpdateSchema } from '../../forms/schemas/society_user_unit_update'
import { ExtendedUnitModel, groupUnitsPerAddress } from '../../helpers/units'
import { SocietyEntranceModel } from '../models/society-entrance'

export enum ResidentUserType {
  MEMBER = 'member',
  NON_MEMBER = 'non-member',
}

export const UnitStore = types
  .model('UnitStore')
  .props({
    units: types.map(UnitModel),
    fetchingUnits: stateType,
    creatingUnit: stateType,
    updatingUnit: stateType,
    deletingUnit: stateType,
    updatingUserUnits: stateType,
    hasFetchedUnitsOnce: types.boolean,
  })
  .views((self) => ({
    residentUsersForSociety(
      societyId: string,
      typeFilter?: ResidentUserType
    ): SnapshotOut<typeof UserModel>[] {
      const societyUnits = // @ts-ignore
        (values(self.units) as SnapshotOut<typeof UnitModel>[]).filter(
          (unit) => unit.societyId === societyId
        )
      const societyResidentArrays = societyUnits.map((unit) =>
        unit.residentsList.map((resident) => ({
          ...resident,
          // A user might be on multiple units, and this appear more than once
          // Add id to be able to use uniqueBy on userId.
          _id: resident.userId,
        }))
      )
      const societyResidents = societyResidentArrays.reduce((a, b) => {
        return a.concat(b)
      }, [])

      // Do not return a user multiple times
      let uniqueResidents = uniqueBy('_id', societyResidents)

      // Apply filter
      if (typeFilter === ResidentUserType.MEMBER) {
        uniqueResidents = uniqueResidents.filter(
          (_resident) => _resident.role === 'member'
        )
      }
      if (typeFilter === ResidentUserType.NON_MEMBER) {
        uniqueResidents = uniqueResidents.filter(
          (_resident) => _resident.role !== 'member'
        )
      }

      const { userStore } = getRoot<RootStore>(self)
      return uniqueResidents
        .map(
          (resident) =>
            userStore.users.get(resident.userId) as SnapshotOut<
              typeof UserModel
            >
        )
        .filter((resident) => resident)
        .sort((a, b) =>
          sortStringsAlphabetically(
            [a.surname, a.name].join(' '),
            [b.surname, b.name].join(' ')
          )
        )
    },
    // TODO: Can definitely be improved. O(n+k)
    userUnits(
      userId: string,
      societyId: string | undefined,
      includeUnassigned = false
    ): SnapshotOut<typeof UnitModel>[] {
      const units = includeUnassigned
        ? values(self.units)
        : // @ts-ignore
          values(self.units).filter((unit) => unit.title !== 'UNASSIGNED')

      const userUnits = units.filter((unit) =>
        // @ts-ignore
        unit?.residentsList?.map((resident) => resident.userId).includes(userId)
      )

      if (societyId) {
        // @ts-ignore
        return userUnits.filter((unit) => unit.societyId === societyId)
      }
      // @ts-ignore
      return userUnits
    },
    residentUsersForSocietyWithUnits(
      societyId: string,
      typeFilter?: ResidentUserType
    ): {
      user: SnapshotOut<typeof UserModel>
      units: SnapshotOut<typeof UnitModel>[]
    }[] {
      return this.residentUsersForSociety(societyId, typeFilter).map(
        (_user) => ({
          user: _user,
          units: this.userUnits(_user._id, societyId, false),
        })
      )
    },
    unitsForSociety(societyId: string): SnapshotOut<typeof UnitModel>[] {
      // @ts-ignore
      return (values(self.units) as SnapshotOut<typeof UnitModel>[])
        .filter((unit) => unit.societyId === societyId)
        .sort((a, b) => sortStringsAlphabetically(a.titleLegal, b.titleLegal))
    },
    unitsForEntrance(
      entrance: SnapshotOut<typeof SocietyEntranceModel>
    ): SnapshotOut<typeof UnitModel>[] {
      return (
        // @ts-ignore
        (values(self.units) as SnapshotOut<typeof UnitModel>[])
          // @ts-ignore
          .filter((unit) => unit.entranceId === entrance._id)
          .sort((a, b) => sortStringsAlphabetically(a.titleLegal, b.titleLegal))
      )
    },
  }))
  .views((self) => ({
    sortedAssignedUnitsForSocietyPerAddress(
      societyId: string
    ): { address: string; units: SnapshotOut<typeof UnitModel>[] }[] {
      const { societyEntrancesStore } = getRoot<RootStore>(self)
      const sortedUnits = self
        .unitsForSociety(societyId)
        .filter((unit) => unit.isUnassigned === false)
        .map((unit) => {
          const entrance =
            unit.entranceId &&
            societyEntrancesStore.entrances.get(unit.entranceId)
          return { ...unit, entrance }
        }) as ExtendedUnitModel[]
      return groupUnitsPerAddress(sortedUnits)
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.units.clear()
      self.fetchingUnits = 'none'
      self.creatingUnit = 'none'
      self.updatingUnit = 'none'
      self.deletingUnit = 'none'
      self.updatingUserUnits = 'none'
      self.hasFetchedUnitsOnce = false
    },
    setUnits: (units: IUnitModel[]) => {
      units.forEach((unit) => {
        // @ts-ignore
        setObject<typeof UnitModel>(self.units, UnitModel, {
          ...unit,
          society: unit.societyId,
          entrance: unit.entranceId,
          residentsList: unit.residentsList?.map((resident) => ({
            ...resident,
            user: resident.userId,
          })),
        })
      })
    },
    setHasFetchedUnitsOnce: (val: boolean) => {
      self.hasFetchedUnitsOnce = val
    },
  }))
  .actions((self) => ({
    processUnits: (units: IUnitModel[]) => {
      self.setUnits(units)
    },
  }))
  .actions((self) => ({
    getUnitsPerSociety: flow(function* getUnitsPerSociety(societyId: string) {
      self.fetchingUnits = 'pending'
      try {
        const resp = yield apiGetUnitsPerSociety(societyId)
        const data = resp.data as NUnits.NGetPerSocieties.IResponse
        const units = data.data

        const users = data?.populated?.users
        const media = data?.populated?.media

        const { userStore, mediaStore, societyEntrancesStore } =
          getRoot<RootStore>(self)
        if (users) {
          userStore.setUsers(users)
        }
        if (media) {
          mediaStore.setMedia(media)
        }
        if ('society-entrances' in data.populated) {
          societyEntrancesStore.setEntrances(
            data.populated['society-entrances']
          )
        }

        self.processUnits(units)

        self.fetchingUnits = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingUnits = 'error'
      }
    }),
    // @ts-ignore
    getUserUnits: flow(function* getUserUnits(): Generator<boolean> {
      self.fetchingUnits = 'pending'
      try {
        const {
          authenticationStore,
          societyEntrancesStore,
          societyStore,
          userStore,
          mediaStore,
        } = getRoot<RootStore>(self)
        // @ts-ignore
        const resp = yield apiGetUserUnits(authenticationStore.userId)
        // @ts-ignore
        const data = resp.data as NUnits.NGetPerSocieties.IResponse
        const units = data.data
        const { societies, users, media } = data.populated

        if (societies) {
          societyStore.setSocieties(societies)
        }
        if (users) {
          userStore.setUsers(users)
        }
        if (media) {
          mediaStore.setMedia(media)
        }
        if ('society-entrances' in data.populated) {
          societyEntrancesStore.setEntrances(
            data.populated['society-entrances']
          )
        }

        self.processUnits(units)
        self.setHasFetchedUnitsOnce(true)

        self.fetchingUnits = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.fetchingUnits = 'error'
        return false
      }
    }),
    getUnit: flow(function* getUnit(id: string) {
      self.fetchingUnits = 'pending'
      try {
        const resp = yield apiGetUnit(id)
        const data = resp.data as NUnits.NGetById.IResponse
        const unit = data.data

        const users = data?.populated?.users
        const media = data?.populated?.media

        const { userStore, mediaStore, societyEntrancesStore } =
          getRoot<RootStore>(self)
        if (users) {
          userStore.setUsers(users)
        }
        if (media) {
          mediaStore.setMedia(media)
        }
        if ('society-entrances' in data.populated) {
          societyEntrancesStore.setEntrances(
            data.populated['society-entrances']
          )
        }

        if (unit !== null) {
          self.setUnits([unit])
        }

        self.fetchingUnits = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.fetchingUnits = 'error'
        return false
      }
    }),
    patchUnit: flow(function* patchUnit(
      id: string,
      body: NUnits.NPatch.IRequestBody
    ) {
      self.updatingUnit = 'pending'
      try {
        const resp = yield apiPatchUnit(id, body)
        const data = resp.data as NUnits.NPatch.IResponse
        const unit = data.data

        if (unit !== null) {
          self.setUnits([unit])
        }

        self.updatingUnit = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingUnit = 'error'
        return false
      }
    }),
    createUnit: flow(function* createUnit(body: NUnits.NCreate.IRequestBody) {
      self.creatingUnit = 'pending'
      try {
        const resp = yield apiCreateUnit(body)
        const data = resp.data as NUnits.NCreate.IResponse
        const unit = data.data

        if (unit !== null) {
          self.setUnits([unit])
        }

        self.creatingUnit = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingUnit = 'error'
        return false
      }
    }),
    deleteUnit: flow(function* deleteUnit(id: string) {
      try {
        yield apiDeleteUnit(id)
        self.units.delete(id)
        return true
      } catch (error) {
        captureException(error)
        return false
      }
    }),
    updateUserUnits: flow(function* updateUserUnits(
      formEntrances: InferType<typeof societyUserUnitUpdateSchema>[],
      existingEntrances: InferType<typeof societyUserUnitUpdateSchema>[]
    ) {
      self.updatingUserUnits = 'pending'

      try {
        // All new entrances do not have a _id
        const newEntrances = formEntrances.filter((entrance) => !entrance._id)

        // Entrances that have been updated matches on _id
        // but objects do not match
        const updatedEntrances = formEntrances.filter((formEntrance) =>
          existingEntrances.find(
            (existingEntrance) =>
              existingEntrance._id === formEntrance._id &&
              JSON.stringify(existingEntrance) !== JSON.stringify(formEntrance)
          )
        )

        // Existing entrance objects that have been updated exist in updatedEntrances
        // and matches on _id
        const existingEntrancesThatHaveBeenUpdated = existingEntrances.filter(
          (existingEntrance) =>
            updatedEntrances
              .map((updatedEntrance) => updatedEntrance._id)
              .includes(existingEntrance._id)
        )

        // Since existing entrances all have an _id, the deleted entrances
        // are the entrances that are exist in existingEntrances but are missing from formEntrances
        const deletedEntrances = existingEntrances.filter(
          (existingEntrance) =>
            formEntrances
              .map((_entrance) => _entrance._id)
              .indexOf(existingEntrance._id) === -1
        )

        const userRolesToCreate = [...updatedEntrances, ...newEntrances]
        const userRolesToDelete = [
          ...deletedEntrances,
          ...existingEntrancesThatHaveBeenUpdated,
        ]

        // @ts-ignore
        yield apiDeleteUserRole(userRolesToDelete)
        // @ts-ignore
        yield apiCreateUserRole(userRolesToCreate)

        self.updatingUserUnits = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingUserUnits = 'error'
        return false
      }
    }),
  }))
