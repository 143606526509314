import React from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, Link } from 'react-router-dom'
import { ViewBase } from '../../../../components/common/ViewBase'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { Button, ButtonVariant } from '../../../../components/common/Button'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { Icon, IconChoices } from '../../../../components/common/Icon'
import { theme } from '../../../../theme/theme'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { KUNDO_SUPPORT_LINK } from '../../../../constants/Links'
import { LinkButton } from '../../../../components/common/LinkButton'

export const MemberGuidanceView = observer((): JSX.Element => {
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:member-guidance', {
      '{{ societyName }}': society.name,
    })
  )
  const navigate = useNavigate()
  const id = society._id

  return (
    <ErrorBoundary>
      <ViewBase
        title="Så får ni in alla medlemmar i Boappa"
        subtitle="Då kommunikationen går ut via Boappa är det viktigt att se 
        till att samtliga medlemmar loggar in."
      >
        <div className="flex flex-col gap-3">
          <span style={theme.textVariants.base}>
            Här kommer några tips på hur ni gör:
          </span>
          <ol className="list-inside list-disc" style={theme.textVariants.base}>
            <li>
              Via mejl: gå till{' '}
              <Link
                to={reverseUrl('management:register', { id })}
                className="text-brandGreen underline"
              >
                Register
              </Link>{' '}
              i Styrelseportalen och klicka på “Medlemmar”. Bjud in genom att
              klicka på “+”. Det går också att ladda upp en excelfil med flera
              mejladresser på en gång.
            </li>
            <li>
              Via infoblad: ladda ner infobladet {'>'} skriv ut {'>'} promenera
              runt och dela ut till samtliga hushåll.
            </li>
          </ol>

          <p style={theme.textVariants.base}>
            Kontakta vår support om ni behöver hjälp med registret.
          </p>
        </div>
        <a
          className="my-8 flex items-center gap-1 rounded-md bg-neutral-96 p-4 hover:bg-neutral-94"
          target="_blank"
          rel="noreferrer"
          href="https://boappa.se/documents/du_har_val_inte_glomt_din_boendeapp.pdf"
        >
          <Icon icon={IconChoices.DOCUMENT} />
          <p style={theme.textVariants.base}>Ladda ner infoblad</p>
          <Icon icon={IconChoices.ARROW} highlighted />
        </a>
        <div className="flex gap-3">
          <Button
            label="Till register"
            variant={ButtonVariant.PRIMARY}
            onClick={() => navigate(reverseUrl('management:register', { id }))}
          />
          <LinkButton
            label="Kontakta support"
            url={KUNDO_SUPPORT_LINK}
            urlIsExternal
            variant={ButtonVariant.DEFAULT}
          />
        </div>
      </ViewBase>
    </ErrorBoundary>
  )
})
