import React, { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { ManagementPartner } from '../management_partner'
import { ManagementPartnerContactForm } from '../ManagementPartnerContactForm'
import { theme } from '../../../../theme/theme'

interface ManagementPartnerContactBaseProps {
  children: ReactNode
  partner: ManagementPartner
  description?: string
}

export const ManagementPartnerContactBase = observer(
  ({
    children,
    partner,
    description,
  }: ManagementPartnerContactBaseProps): JSX.Element => {
    return (
      <div className="h-full w-full lg:w-3/5">
        {description && (
          <p className="mb-6 w-full" style={theme.textVariants.base}>
            {description}
          </p>
        )}
        {children}
        <ManagementPartnerContactForm partner={partner} />
      </div>
    )
  }
)
