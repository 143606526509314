import { SnapshotOut } from 'mobx-state-tree'
import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { filterAsync } from '../../../helpers/array'
import { useAuthenticatedUserId } from '../../../hooks/useAuthenticatedUserId'
import { useModal } from '../../../hooks/useModal'
import { usePreApprovedList } from '../../../hooks/usePreApprovedList'
import { PreApprovedModel } from '../../../state/models/society'
import { CookieKeys } from '../../../types/cookies'
import { SocietyInviteModal } from './SocietyInviteModal'

interface SocietyInviteBaseProps {
  children: React.ReactNode
}

export const SocietyInviteBase = ({
  children,
}: SocietyInviteBaseProps): JSX.Element => {
  const {
    show: showSocietyInviteModal,
    open: openSocietyInviteModal,
    close: closeSocietyInviteModal,
  } = useModal()
  const [preApproved, setPreapproved] =
    useState<SnapshotOut<typeof PreApprovedModel>>()
  const userId = useAuthenticatedUserId() as string
  const preApprovedList = usePreApprovedList()

  useEffect(() => {
    const checkSocietyInviteModalOpenState = async (): Promise<void> => {
      if (preApprovedList.length > 0) {
        const unseenInvites = await filterAsync(
          preApprovedList,
          async (_preApproved) => {
            const cookies = new Cookies()
            const seen = cookies.get(
              CookieKeys.SOCIETY_INVITE_VIEWED.replace(
                '{% id %}',
                _preApproved._id
              )
            )
            return seen === null
          }
        )

        if (unseenInvites.length > 0) {
          setPreapproved(unseenInvites[0])
          openSocietyInviteModal()
        }
      }
    }

    if (userId) {
      checkSocietyInviteModalOpenState()
    }
  }, [userId, preApprovedList, openSocietyInviteModal])

  return (
    <>
      {children}
      {preApproved && (
        <SocietyInviteModal
          preApproved={preApproved}
          show={showSocietyInviteModal}
          close={closeSocietyInviteModal}
        />
      )}
    </>
  )
}
