import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { theme } from '../../../../theme/theme'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { SocietyEntranceModel } from '../../../../state/models/society-entrance'
import { CreateUpdateRegisterEntranceItemForm } from '../CreateUpdateRegisterEntranceItemForm'
import { useStores } from '../../../../hooks/useStores'
import { NSocietyEntrances } from '../../../../interfaces/services/society-entrances.interfaces'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { societyEntranceCreateSchema } from '../../../../forms/schemas/society_entrance_create'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'

interface CreateUpdateRegisterEntranceItemModalProps {
  show: boolean
  close: () => void
  entrance?: SnapshotOut<typeof SocietyEntranceModel>
  children?: React.ReactNode
}

export const CreateUpdateRegisterEntranceItemModal = observer(
  ({
    show,
    close,
    entrance,
    children,
  }: CreateUpdateRegisterEntranceItemModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { societyEntrancesStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isSamf } = society

    const updateMode = !!entrance

    const showSuccessMessage = (): void => {
      let translationKey = ''
      if (isSamf) {
        translationKey = updateMode
          ? 'registerEntrance.flashMessage.updateEntranceSamfSuccess'
          : 'registerEntrance.flashMessage.createEntranceSamfSuccess'
      } else {
        translationKey = updateMode
          ? 'registerEntrance.flashMessage.updateEntranceSuccess'
          : 'registerEntrance.flashMessage.createEntranceSuccess'
      }
      setToastNotification(ToastType.SUCCESS, translate(translationKey))
    }

    const showErrorMessage = (): void => {
      let translationKey = ''
      if (isSamf) {
        translationKey = updateMode
          ? 'registerEntrance.flashMessage.updateEntranceSamfFailure'
          : 'registerEntrance.flashMessage.createEntranceSamfFailure'
      } else {
        translationKey = updateMode
          ? 'registerEntrance.flashMessage.updateEntranceFailure'
          : 'registerEntrance.flashMessage.createEntranceFailure'
      }
      setToastNotification(ToastType.DANGER, translate(translationKey))
    }

    const onSubmit = async (
      data: InferType<typeof societyEntranceCreateSchema>
    ): Promise<void> => {
      if (updateMode) {
        const status = await societyEntrancesStore.patchEntrance(
          entrance._id,
          data as NSocietyEntrances.NPatch.IRequestBody
        )
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      } else {
        const status = await societyEntrancesStore.createEntrance(
          data as NSocietyEntrances.NCreate.IRequestBody
        )
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      }
    }

    const onError = (): void => {
      showErrorMessage()
    }

    const getModalTitle = (): string => {
      if (updateMode) {
        return translate(
          isSamf
            ? 'registerEntrance.modal.updateSamf'
            : 'registerEntrance.modal.update'
        )
      }
      return translate(
        isSamf
          ? 'registerEntrance.modal.createSamf'
          : 'registerEntrance.modal.create'
      )
    }

    const loading =
      societyEntrancesStore.creatingEntrance === 'pending' ||
      societyEntrancesStore.updatingEntrance === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                <div>
                  <p style={theme.textVariants.h3}>{getModalTitle()}</p>
                </div>
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <CreateUpdateRegisterEntranceItemForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  entrance={entrance}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
