import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { BookingModel } from '../../../../state/models/booking'
import { useStores } from '../../../../hooks/useStores'
import { FacilityModel } from '../../../../state/models/facility'
import { Avatar } from '../../../common/Avatar'
import { useIsMobile } from '../../../../hooks/useIsMobile'
import { Button, ButtonVariant } from '../../../common/Button'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useModal } from '../../../../hooks/useModal'
import { DeleteBookingConfirmationModal } from '../../Facility/DeleteBookingConfirmationModal'
import { Card } from '../../../common/Card'
import { theme } from '../../../../theme/theme'
import { capitalize } from '../../../../helpers/string'
import { formatDateWithFormat } from '../../../../helpers/date'

interface BookingListItemProps {
  booking: SnapshotOut<typeof BookingModel>
}

export const BookingListItem = observer(
  ({ booking }: BookingListItemProps): JSX.Element => {
    const { facilitiesStore } = useStores()
    const isMobile = useIsMobile()
    const { translate } = useAppTranslation()
    const {
      show: showDeleteBookingModal,
      open: openDeleteBookingModal,
      close: closeDeleteBookingModal,
    } = useModal()

    const facility = facilitiesStore.facilities.get(
      booking.facilityId
    ) as SnapshotOut<typeof FacilityModel>

    const timeSpanString =
      facility.bookingGranularity === 'full-day'
        ? translate('facilityView.calendar.fullDay')
        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          booking.getTimeSpanString()

    return (
      <>
        <Card className="p-5 hover:bg-neutral-96">
          {{
            body: (
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {facility.coverPhotoId && (
                    <Avatar
                      mediaId={facility.coverPhotoId}
                      size={isMobile ? 32 : 48}
                    />
                  )}
                  <div className="ml-4 flex flex-col gap-[1px] text-left">
                    <p style={theme.textVariants.baseBold}>{facility.name}</p>
                    <p
                      style={theme.textVariants.caption}
                      className="text-neutral-50"
                    >
                      {capitalize(
                        formatDateWithFormat(
                          new Date(booking.startDate),
                          isMobile ? 'd MMM YYY' : 'd MMMM YYY'
                        )
                      )}
                    </p>
                    <p
                      style={
                        isMobile
                          ? theme.textVariants.caption
                          : theme.textVariants.base
                      }
                    >
                      {timeSpanString}
                    </p>
                  </div>
                </div>
                <Button
                  label={translate(
                    'facilityView.bookings.buttons.deleteBooking'
                  )}
                  variant={ButtonVariant.DANGER}
                  onClick={openDeleteBookingModal}
                />
              </div>
            ),
          }}
        </Card>
        <DeleteBookingConfirmationModal
          showModal={showDeleteBookingModal}
          closeModal={closeDeleteBookingModal}
          booking={booking}
        />
      </>
    )
  }
)
