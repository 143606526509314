import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { SocietyWidgetModel } from '../../../../state/models/society-widget'
import { societyWidgetCreateSchema } from '../../../../forms/schemas/society_widget_create'
import { FormControl } from '../../../common/FormControl'
import { SelectDropdown } from '../../../common/SelectDropdown'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { TextInput } from '../../../common/TextInput'
import { TextArea } from '../../../common/TextArea'
import { WidgetType } from '../widget-type'

interface CreateUpdateWidgetFormProps {
  onError: () => void
  onSubmit: (data: InferType<typeof societyWidgetCreateSchema>) => Promise<void>
  onClose: () => void
  loading: boolean
  widget?: SnapshotOut<typeof SocietyWidgetModel>
}

export const CreateUpdateWidgetForm = observer(
  ({
    widget,
    loading,
    onError,
    onSubmit,
    onClose,
  }: CreateUpdateWidgetFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { getErrorMessage } = useFormErrorMessage()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }

    const updateMode = !!widget

    const widgetTypeOptions = [
      {
        value: 'link-widget',
        label: `${translate(
          'functionSettingsView.form.widgetTypeOptions.link'
        )}`,
      },
      {
        value: 'text-widget',
        label: `${translate(
          'functionSettingsView.form.widgetTypeOptions.text'
        )}`,
      },
    ]

    const getDefaultValues = useCallback((): InferType<
      typeof societyWidgetCreateSchema
    > => {
      return {
        societyId: updateMode ? widget?.societyId || society._id : society._id,
        type: updateMode
          ? (widget?.type as WidgetType | undefined) || 'link-widget'
          : 'link-widget',
        title: updateMode ? widget?.title || '' : '',
        link: updateMode ? widget?.link || '' : '',
        content: updateMode ? widget?.content || '' : '',
      }
    }, [society, widget, updateMode])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
      watch,
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyWidgetCreateSchema),
      defaultValues: getDefaultValues(),
    })

    const watchType = watch('type')

    return (
      <>
        <Controller
          control={control}
          name="type"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate('functionSettingsView.form.labels.function')}
              error={errors.type && getErrorMessage(errors.type)}
              name={name}
            >
              <SelectDropdown
                options={widgetTypeOptions}
                value={value}
                onChange={onChange}
                error={!!errors.type}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="title"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('functionSettingsView.form.labels.title')}
              error={errors.title && getErrorMessage(errors.title)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.title}
              />
            </FormControl>
          )}
        />
        {watchType === 'link-widget' ? (
          <Controller
            control={control}
            name="link"
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                label={translate('functionSettingsView.form.labels.link')}
                error={errors.link && getErrorMessage(errors.link)}
                name={name}
              >
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.link}
                />
              </FormControl>
            )}
          />
        ) : (
          <Controller
            control={control}
            name="content"
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                label={translate('functionSettingsView.form.labels.content')}
                error={errors.content && getErrorMessage(errors.content)}
                name={name}
              >
                <TextArea
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.content}
                />
              </FormControl>
            )}
          />
        )}
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            disabled={!isValid || loading}
            variant={ButtonVariant.PRIMARY}
            label={
              updateMode
                ? translate('common.actions.save')
                : translate('common.actions.create')
            }
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </>
    )
  }
)
