import React from 'react'
import { ProcessedMedia } from '../../../../types/image-upload'
import { Image } from '../../../common/Image'

interface MessageBarImagesProps {
  images: ProcessedMedia[]
  onImageRemove: (image: ProcessedMedia) => void
}

export const MessageBarImages = ({
  images,
  onImageRemove,
}: MessageBarImagesProps): JSX.Element => {
  return (
    <>
      {images.length > 0 && (
        <div className="flex flex-wrap gap-3 px-2 pt-3">
          {images.map((_image) => (
            <div key={_image.uri} className="h-12 w-12">
              <Image
                url={_image.uri}
                onDeleteClick={() => onImageRemove(_image)}
                shouldCloseTopRight
              />
            </div>
          ))}
        </div>
      )}
    </>
  )
}
