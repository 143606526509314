import React, { KeyboardEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { SearchBar } from '../../../../components/common/SearchBar'
import { Button, ButtonVariant } from '../../../../components/common/Button'
import { Icon, IconChoices } from '../../../../components/common/Icon'
import { Table } from '../../../../components/common/Table'
import { TableColumn } from '../../../../components/common/Table/TableHeader'
import { TableRow } from '../../../../components/common/Table/TableRows'
import { formatDate } from '../../../../helpers/date'
import { Dropdown } from '../../../../components/common/Dropdown'
import { ResetPasswordModal } from './ResetPasswordModal'
import { useModal } from '../../../../hooks/useModal'
import { searchUser as apiSearchUser } from '../../../../api/admin'
import { NAdmin } from '../../../../interfaces/services/admin.interfaces'
import { UserModel } from '../../../../state/models/user'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { DeleteUserModal } from './DeleteUserModal'
import { ChangeUserEmailModal } from './ChangeUserEmailModal'

export const Users = observer((): JSX.Element | null => {
  const { translate } = useAppTranslation()
  const [searchString, setSearchString] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [emptySearchResult, setEmptySearchResult] = useState(false)
  const [usersSearchResult, setUsersSearchResult] = useState<
    SnapshotOut<typeof UserModel>[]
  >([])
  const [currentUserId, setCurrentUserId] = useState<string>()
  const {
    show: showResetPasswordModal,
    open: openResetPasswordModal,
    close: closeResetPasswordModal,
  } = useModal()
  const {
    show: showDeleteUserModal,
    open: openDeleteUserModal,
    close: closeDeleteUserModal,
  } = useModal()
  const {
    show: showChangeUserEmailModal,
    open: openChangeUserEmailModal,
    close: closeChangeUserEmailModal,
  } = useModal()

  const onChangeSearchString = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    setSearchString(event.currentTarget.value)
  }

  const resetSearchResult = (): void => {
    setUsersSearchResult([])
  }

  const searchUsers = async (query: string): Promise<void> => {
    const resp = await apiSearchUser({ query })
    const data = resp.data as NAdmin.NSearchUser.IResponse
    const _users = data.data

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setUsersSearchResult(_users)
    setEmptySearchResult(_users.length === 0)
  }

  const onSubmit = async (): Promise<void> => {
    setIsSearching(true)
    try {
      await searchUsers(searchString)
    } catch (error) {
      setIsSearching(false)
    }
    setIsSearching(false)
  }

  const handleEnterKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  const loading = isSearching

  const columns: TableColumn[] = [
    {
      key: 'createdAt',
      title: 'Användare skapad',
      accessor: 'createdAt',
      renderItem: (createdAt: string) => (
        <p>{createdAt && formatDate(new Date(createdAt))}</p>
      ),
    },
    {
      key: 'isVerified',
      title: 'Verifierad',
      accessor: 'isVerified',
      renderItem: (isVerified: boolean) => (
        <Icon
          icon={isVerified ? IconChoices.CHECKMARK : IconChoices.CLOSE_CROSS}
        />
      ),
    },
    { key: 'surname', title: 'Efternamn' },
    { key: 'name', title: 'Förnamn' },
    { key: 'mobile', title: 'Mobilnummer' },
    { key: '_id', title: 'Id' },
    {
      key: 'email',
      title: 'Mejl',
    },
    {
      key: 'updatedAt',
      title: 'Uppdaterad',
      accessor: 'updatedAt',
      renderItem: (updatedAt: string) => (
        <p>{updatedAt && formatDate(new Date(updatedAt))}</p>
      ),
    },
    {
      key: 'actions',
      title: 'Åtgärder',
      accessor: '_id',
      renderItem: (_id: string) => (
        <Dropdown
          className="w-fit"
          options={[
            {
              value: 'resetPassword',
              label: 'Ändra lösenord',
              icon: IconChoices.ADMIN,
              onClick: () => {
                setCurrentUserId(_id)
                openResetPasswordModal()
              },
            },
            {
              value: 'changeEmail',
              label: 'Ändra mejladress',
              icon: IconChoices.EMAIL,
              onClick: () => {
                setCurrentUserId(_id)
                openChangeUserEmailModal()
              },
            },
            {
              value: 'deleteUser',
              label: 'Radera användare',
              icon: IconChoices.DELETE_TRASH,
              destructive: true,
              onClick: () => {
                setCurrentUserId(_id)
                openDeleteUserModal()
              },
            },
          ]}
        >
          <Button
            label="Åtgärder"
            iconPlacement="left"
            variant={ButtonVariant.PRIMARY}
          />
        </Dropdown>
      ),
    },
  ]

  const rows: TableRow[] = usersSearchResult.map((user) => ({
    key: user._id,
    data: user,
  }))

  return (
    <div className="h-full w-full space-y-4">
      <div className="flex items-end gap-1">
        <SearchBar
          placeholder={translate('adminView.searchBar.placeholders.users')}
          value={searchString}
          onChange={onChangeSearchString}
          onKeyUp={handleEnterKeyPress}
        />
        <Button
          className="h-12"
          icon={IconChoices.SEARCH}
          loading={loading}
          disabled={searchString === ''}
          onClick={onSubmit}
        />
      </div>
      {rows?.length > 0 && <Table columns={columns} rows={rows} />}
      {emptySearchResult && (
        <p>{translate('adminView.searchResult.emptyResult')}</p>
      )}
      {showResetPasswordModal && (
        <ResetPasswordModal
          show={showResetPasswordModal}
          close={closeResetPasswordModal}
          userId={currentUserId}
        />
      )}
      {showDeleteUserModal && currentUserId && (
        <DeleteUserModal
          show={showDeleteUserModal}
          close={closeDeleteUserModal}
          userId={currentUserId}
          resetSearchResult={resetSearchResult}
        />
      )}
      {showChangeUserEmailModal && currentUserId && (
        <ChangeUserEmailModal
          show={showChangeUserEmailModal}
          close={closeChangeUserEmailModal}
          userId={currentUserId}
          resetSearchResult={resetSearchResult}
        />
      )}
    </div>
  )
})
