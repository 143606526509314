import React from 'react'
import { Link } from 'react-router-dom'
import { BreadcrumbItemData } from '../Breadcrumb'
import { theme } from '../../../../theme/theme'
import { Icon, IconChoices } from '../../Icon'
import { useDocumentDroppable } from '../../../society/Documents/DocumentsListItem/useDocumentDroppable'

interface BreadcrumbItemProps {
  item: BreadcrumbItemData
  index: number
  rootIcon: IconChoices
  totalItems: number
  rootIconSize?: number
  rootLabel?: string
}

export const BreadcrumbItem = ({
  item,
  index,
  rootIcon,
  rootIconSize,
  rootLabel,
  totalItems,
}: BreadcrumbItemProps): JSX.Element => {
  const { isOver, drop } = useDocumentDroppable(item._id)

  const isNotLastItem = index !== totalItems
  const isLastItem = index === totalItems

  return (
    <div className="flex flex items-center space-x-2">
      <div
        className={`flex items-center rounded p-2 ${
          isOver ? 'bg-neutral-80' : 'bg-white'
        }`}
        ref={drop}
      >
        {index === 0 ? (
          <Link to={item.url} className="flex space-x-2">
            <Icon size={rootIconSize} icon={rootIcon} />
            {rootLabel && (
              <p
                className={`${
                  isNotLastItem ? 'text-neutral-40 hover:text-black' : ''
                }`}
              >
                {rootLabel}
              </p>
            )}
          </Link>
        ) : (
          <Link
            to={item.url}
            className={`${isLastItem ? 'pointer-events-none' : ''}`}
          >
            <p
              style={theme.textVariants.base}
              className={`${
                isNotLastItem ? 'text-neutral-40 hover:text-black' : ''
              }`}
            >
              {item.title.trim()}
            </p>
          </Link>
        )}
      </div>
      {isNotLastItem && (
        <p style={theme.textVariants.base} className="text-neutral-40">
          /
        </p>
      )}
    </div>
  )
}
