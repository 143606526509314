import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { UserAvatar } from '../../common/Avatar'
import { useStores } from '../../../hooks/useStores'
import { theme } from '../../../theme/theme'
import { formatDate } from '../../../helpers/date'
import { Icon, IconChoices } from '../../common/Icon'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useModal } from '../../../hooks/useModal'
import { LikesModal } from '../LikesModal'
import { Dropdown, Option } from '../../common/Dropdown'
import { SocietyModel } from '../../../state/models/society'
import { isUserAdminInSociety } from '../../../helpers/society'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'
import { ConfirmationModal } from '../../common/ConfirmationModal'
import { CommentEdit } from '../CommentEdit'
import { removeMarkdown, replaceHtml } from '../../../helpers/string'
import { Button, ButtonVariant } from '../../common/Button'
import { Linkify } from '../../common/Linkify'
import { COMMENTS_PER_LOAD } from '../Post'

interface CommentProps {
  commentId: string
  society: SnapshotOut<typeof SocietyModel>
  loadMoreCounter: number
  index: number
  endReached: boolean
  setEndReached: React.Dispatch<React.SetStateAction<boolean>>
  length: number
}

export const Comment = observer(
  ({
    commentId,
    society,
    loadMoreCounter,
    index,
    endReached,
    setEndReached,
    length,
  }: CommentProps): JSX.Element | null => {
    const { translate } = useAppTranslation()
    const { userStore, commentStore, authenticationStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const [editMode, setEditMode] = useState<boolean>(false)
    const {
      show: showLikesModal,
      open: openLikesModal,
      close: closeLikesModal,
    } = useModal()
    const {
      show: showConfirmationModal,
      open: openConfirmationModal,
      close: closeConfirmationModal,
    } = useModal()

    const userId = authenticationStore.userId as string
    const comment = commentStore.comments.get(commentId)

    const commentShouldNotLoad = useCallback((): boolean => {
      return !endReached && index >= loadMoreCounter * COMMENTS_PER_LOAD
    }, [endReached, index, loadMoreCounter])

    useEffect(() => {
      if (length - 1 === index && !commentShouldNotLoad()) {
        setEndReached(true)
      }
    }, [commentShouldNotLoad, index, length, setEndReached])

    if (!comment || commentShouldNotLoad()) {
      return null
    }

    const author = userStore.users.get(comment.author)
    const userLikesComment = !!comment.likes.includes(userId)
    const processedMessage = removeMarkdown(replaceHtml(comment.message))

    const toggleLike = (): void => {
      commentStore.toggleLike(comment._id, !userLikesComment)
    }

    const currentUserIsAuthor = comment.author === userId
    const currentUserIsAdmin = society
      ? isUserAdminInSociety(society, userId)
      : false

    const userCanDelete = currentUserIsAuthor || currentUserIsAdmin

    const onLikesClick = (): void => {
      comment.likes.length > 0 && openLikesModal()
    }

    const deleteComment = async (): Promise<void> => {
      const status = await commentStore.deleteComment(comment._id)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('post.flashMessage.deleteCommentSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('post.flashMessage.deleteCommentFailure')
        )
      }
    }

    const dropdownOptions = [
      ...(currentUserIsAuthor
        ? [
            {
              value: 'edit',
              label: translate('common.actions.edit'),
              onClick: () => setEditMode(true),
              icon: IconChoices.EDIT,
            },
          ]
        : []),
      ...(userCanDelete
        ? [
            {
              value: 'delete',
              label: translate('common.actions.delete'),
              onClick: openConfirmationModal,
              icon: IconChoices.DELETE_TRASH,
              destructive: true,
            },
          ]
        : []),
    ] as Option[]

    return (
      <div className="flex gap-4">
        <UserAvatar user={author} />
        <div
          style={{ backgroundColor: theme.colors.feedBackground }}
          className="flex w-full max-w-[600px] flex-col rounded-xl p-4"
        >
          <div className="flex justify-between">
            <div className="flex flex-1 flex-col gap-1">
              <div>
                <p>{author?.fullName}</p>
                <p className="text-xs">
                  {comment.createdAt &&
                    formatDate(Date.parse(comment.createdAt))}
                </p>
              </div>
              {editMode ? (
                <CommentEdit comment={comment} setEditMode={setEditMode} />
              ) : (
                <Linkify>
                  <p className="whitespace-pre-line">{processedMessage}</p>
                </Linkify>
              )}
              {!editMode && comment.likes.length !== 0 && (
                <div className="mt-2 flex">
                  <button
                    className={`${
                      comment.likes.length === 0
                        ? 'cursor-default'
                        : 'hover:underline'
                    }`}
                    onClick={onLikesClick}
                    style={theme.textVariants.caption}
                  >
                    {comment.likes.length}{' '}
                    {translate(
                      comment.likes.length === 1
                        ? 'common.singularPlural.like.singular'
                        : 'common.singularPlural.like.plural'
                    )?.toLowerCase()}
                  </button>
                </div>
              )}
            </div>
            {!editMode && (
              <div className="flex flex-col items-center gap-5">
                <Icon
                  size={20}
                  icon={IconChoices.LIKE}
                  highlighted={userLikesComment}
                  onClick={toggleLike}
                  flexItems="items-start"
                />
                {dropdownOptions.length > 0 && (
                  <Dropdown
                    options={dropdownOptions}
                    dropdownContentWidth="w-64"
                  >
                    <Button
                      className="bg-transparent hover:bg-neutral-90"
                      variant={ButtonVariant.TEXT}
                      size="xs"
                      icon={IconChoices.MORE}
                      disableTabIndex
                    />
                  </Dropdown>
                )}
              </div>
            )}
          </div>
        </div>
        <LikesModal
          likes={comment.likes}
          societyId={society._id}
          show={showLikesModal}
          close={closeLikesModal}
        />
        <ConfirmationModal
          title={translate('post.alert.commentDeleteTitle')}
          description={translate('post.alert.commentDeleteText')}
          show={showConfirmationModal}
          close={closeConfirmationModal}
          onConfirm={deleteComment}
        />
      </div>
    )
  }
)
