import * as yup from 'yup'

export const societyWidgetCreateSchema = yup
  .object({
    societyId: yup.string().required(),
    type: yup.string().oneOf(['text-widget', 'link-widget']).required(),
    title: yup.string().required(),
    link: yup.string().when('type', {
      is: 'link-widget',
      then: (schema) => schema.required(),
    }),
    content: yup.string().when('type', {
      is: 'text-widget',
      then: (schema) => schema.required(),
    }),
  })
  .required()
