import { observer } from 'mobx-react-lite'
import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { ErrorView } from '../../../views/error/ErrorView'
import { IllustrationChoices } from '../../common/Illustration'
import { InfiniteScroll } from '../../common/InfiniteScroll'
import { ChatRoomsListItem } from '../ChatRoomsListItem'
import { useFindArchivedChatsData } from './useFindArchivedChatsData'
import { ChatRoomModel } from '../../../state/models/chat-room'
import { useStores } from '../../../hooks/useStores'
import { ToastType } from '../../common/Toast/toast-type'
import { useToastNotifications } from '../../../hooks/useToastNotification'

interface FindArchiveChatsModalContentProps {
  modalRef: React.MutableRefObject<HTMLDivElement | null>
  close: () => void
}

export const FindArchiveChatsModalContent = observer(
  ({ modalRef, close }: FindArchiveChatsModalContentProps): JSX.Element => {
    const { chatRoomStore } = useStores()
    const { translate } = useAppTranslation()
    const { currentChatRooms, loadMoreChatRooms, showSpinner } =
      useFindArchivedChatsData()
    const { setToastNotification } = useToastNotifications()

    const loading = currentChatRooms.length > 0 ? false : showSpinner
    const noRooms = !showSpinner && currentChatRooms.length === 0

    const activateChatRoom = async (
      chatRoom: SnapshotOut<typeof ChatRoomModel>
    ): Promise<void> => {
      const status = await chatRoomStore.toggleHideUntilNotification(
        chatRoom._id,
        false
      )
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate(
            'findArchivedChats.flashMessage.activateArchivedChatSuccess'
          )
        )
        chatRoomStore.setSelectedChatRoom(chatRoom._id)
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate(
            'findArchivedChats.flashMessage.activateArchivedChatFailure'
          )
        )
      }
    }

    const onItemSelect =
      (chatRoom: SnapshotOut<typeof ChatRoomModel>) => () => {
        activateChatRoom(chatRoom)
        close()
      }

    return noRooms ? (
      <ErrorView
        title={translate('findArchivedChats.errorViews.noChatRooms.title')}
        subtitle={translate(
          'findArchivedChats.errorViews.noChatRooms.subtitle'
        )}
        illustration={IllustrationChoices.EMPTY}
        illustrationHeight={250}
        illustrationWidth={187}
      />
    ) : (
      <div className="flex flex-col">
        <InfiniteScroll
          key="infiniteGroupChatScroll" // Add key to prompt rerendering of component on state change
          onEndReached={loadMoreChatRooms}
          loading={loading || false}
          rootRef={modalRef}
        >
          {currentChatRooms?.map((_chatRoom) => (
            <ChatRoomsListItem
              className="border-b border-neutral-80 p-4 hover:bg-neutral-96"
              key={_chatRoom._id}
              chatRoomId={_chatRoom._id}
              customOnClick={onItemSelect(_chatRoom)}
            />
          ))}
        </InfiniteScroll>
      </div>
    )
  }
)
