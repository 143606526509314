export const addGATracking = (
  url: string,
  source: string,
  medium: string,
  campaign: string
): string => {
  const urlObject = new URL(url)
  urlObject.searchParams.append('utm_source', source)
  urlObject.searchParams.append('utm_medium', medium)
  urlObject.searchParams.append('utm_campaign', campaign)

  return urlObject.toString()
}
