import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { ErrorBoundary } from '../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { FindSociety } from '../../../components/find-society/FindSociety'
import { theme } from '../../../theme/theme'
import { BackButton } from '../../../components/common/BackButton'
import { useDocumentTitle } from '../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../navigation/reverseDocumentTitle'
import { useAuth } from '../../../hooks/useAuth'
import { useStores } from '../../../hooks/useStores'

export const FindSocietyView = observer((): JSX.Element => {
  const { userId } = useAuth()
  const { societyStore, requestStore } = useStores()
  const { hasFetchedSocietiesOnce } = societyStore
  const { hasFetchedRequestsOnce } = requestStore
  const { translate } = useAppTranslation()
  useDocumentTitle(reverseDocumentTitle('find-society'))
  const navigate = useNavigate()

  useEffect(() => {
    if (userId && !hasFetchedSocietiesOnce && hasFetchedRequestsOnce) {
      societyStore.getUserSocieties(userId)
      requestStore.getRequestsPerUser(userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goBack = (): void => {
    navigate(-1)
  }

  return (
    <ErrorBoundary>
      <div className="h-full w-full p-6">
        <div className="flex items-center space-x-4">
          <BackButton onClick={goBack} />
          <p style={theme.textVariants.h2}>
            {translate('findSocietyView.title')}
          </p>
        </div>
        <FindSociety />
      </div>
    </ErrorBoundary>
  )
})
