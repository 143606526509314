import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import Fuse from 'fuse.js'
import { useStores } from '../../../../hooks/useStores'
import { SpinnerWrapper } from '../../../../components/common/SpinnerWrapper'
import { ButtonVariant } from '../../../../components/common/Button'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useModal } from '../../../../hooks/useModal'
import { AdminRightsList } from '../../../../components/society/AdminRightsList'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { CreateUpdateAdminModal } from '../../../../components/society/AdminRightsList/CreateUpdateAdminModal'
import { useSortedAdminsList } from './useSortedAdminsList'
import { ViewBase } from '../../../../components/common/ViewBase'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { IllustrationChoices } from '../../../../components/common/Illustration'

export const AdminRightsView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { societyStore } = useStores()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:admin-rights', {
      '{{ societyName }}': society.name,
    })
  )
  const [searchString, setSearchString] = useState('')
  const sortedAdminsList = useSortedAdminsList()
  const {
    show: showCreateModal,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModal()

  const onChangeSearchString = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    setSearchString(event.currentTarget.value)
  }

  const getFilteredAdmins = useCallback(() => {
    const options = {
      threshold: 0.2,
      keys: ['user.fullName', 'role'],
    }

    // If searchString contains only spaces or is empty
    if (searchString.replace(/ /g, '') === '') {
      return sortedAdminsList
    }

    const fuse = new Fuse(sortedAdminsList, options)

    const admins = fuse.search(searchString).map((result) => result.item)
    return admins.filter(
      (admin) =>
        admin?.user?._id != null || (admin?.user?._id as string).length > 0
    )
  }, [sortedAdminsList, searchString])

  const loading =
    societyStore.updatingAdminUsers === 'pending' ||
    societyStore.updatingMasterAdmin === 'pending'

  if (societyStore.fetchingSocieties === 'none') {
    return <SpinnerWrapper />
  }

  const errorView =
    !loading && sortedAdminsList.length === 0
      ? {
          title: translate('adminRightsView.emptyState.title'),
          subtitle: translate('adminRightsView.emptyState.subtitle'),
          illustration: IllustrationChoices.EMPTY,
        }
      : undefined

  const filteredAdmins = getFilteredAdmins()

  const noSearchResultErrorView =
    !loading && filteredAdmins.length === 0 && searchString !== ''
      ? {
          title: translate('adminRightsView.emptyState.noSearchResultsTitle'),
          subtitle: translate(
            'adminRightsView.emptyState.noSearchResultsSubtitle'
          ),
          illustration: IllustrationChoices.EMPTY,
        }
      : undefined

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('adminRightsView.title')}
        searchBar={{ value: searchString, onChange: onChangeSearchString }}
        buttons={[
          {
            variant: ButtonVariant.PRIMARY,
            onClick: openCreateModal,
            label: translate('common.actions.add'),
          },
        ]}
        errorView={errorView}
        noSearchResultErrorView={noSearchResultErrorView}
      >
        {loading ? (
          <SpinnerWrapper />
        ) : (
          <AdminRightsList admins={getFilteredAdmins()} />
        )}
      </ViewBase>
      <CreateUpdateAdminModal show={showCreateModal} close={closeCreateModal} />
    </ErrorBoundary>
  )
})
