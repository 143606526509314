/* eslint-disable no-param-reassign */
import { captureException } from '@sentry/react'
import { flow, getRoot, types } from 'mobx-state-tree'
import Cookies from 'universal-cookie'
import { login as apiLogin } from '../../api/authentication'
import {
  setSecureBearerCookies,
  setSecureUserIdCookie,
} from '../../helpers/authentication'
import { CookieKeys } from '../../types/cookies'
import { stateType } from '../types/common'
import { TWILIGHT_PREFIX } from './admin'
import { RootStore } from './root'
import { socket } from '../../sockets'

export const AuthenticationStore = types
  .model('AuthenticationStore')
  .props({
    token: types.maybe(types.string),
    userId: types.maybe(types.string),
    loggingIn: stateType,
  })
  .views((self) => {
    return {
      get isAuthenticated() {
        return self.token !== undefined
      },
    }
  })
  .actions((self) => ({
    reset: () => {
      self.token = undefined
      self.userId = undefined
      self.loggingIn = 'none'
    },
    logout() {
      self.token = undefined
      const cookies = new Cookies()
      cookies.remove(CookieKeys.HEADER_PAYLOAD, { path: '/' })
      cookies.remove(CookieKeys.SIGNATURE, { path: '/' })
      cookies.remove(CookieKeys.USER_ID, { path: '/' })

      cookies.remove(`${TWILIGHT_PREFIX}${CookieKeys.HEADER_PAYLOAD}`, {
        path: '/',
      })
      cookies.remove(`${TWILIGHT_PREFIX}${CookieKeys.SIGNATURE}`, { path: '/' })
      cookies.remove(`${TWILIGHT_PREFIX}${CookieKeys.USER_ID}`, { path: '/' })

      socket.unsetSocket()
      const { reset } = getRoot<RootStore>(self)
      reset()
    },
    logoutAsUser() {
      self.token = undefined
      const cookies = new Cookies()

      cookies.remove(`${TWILIGHT_PREFIX}${CookieKeys.HEADER_PAYLOAD}`, {
        path: '/',
      })
      cookies.remove(`${TWILIGHT_PREFIX}${CookieKeys.SIGNATURE}`, { path: '/' })
      cookies.remove(`${TWILIGHT_PREFIX}${CookieKeys.USER_ID}`, { path: '/' })

      const { reset } = getRoot<RootStore>(self)
      reset()
    },
  }))
  .actions((self) => ({
    login: flow(function* login(email: string, password: string) {
      self.loggingIn = 'pending'
      try {
        const resp = yield apiLogin({
          email,
          password,
        })
        const { token } = resp.data.data
        const userId = resp.data.data.user._id

        self.token = token
        self.userId = userId

        setSecureBearerCookies(token)
        setSecureUserIdCookie(userId)

        self.loggingIn = 'done'
        return { userId, genericError: false }
      } catch (error) {
        self.loggingIn = 'error'
        // Don't report invalid credentials
        if (error?.response?.status !== 401) {
          captureException(error)
          return { userId: null, genericError: true }
        }
        return { userId: null, genericError: false }
      }
    }),
    loginWithTokenAndUserId(token: string, userId: string) {
      self.loggingIn = 'pending'
      try {
        self.token = token
        self.userId = userId

        setSecureBearerCookies(token)
        setSecureUserIdCookie(userId)

        self.loggingIn = 'done'
        return true
      } catch (error) {
        self.loggingIn = 'error'
        return false
      }
    },
    setToken(token: string) {
      self.token = token
    },
    setUserIdFromCookie() {
      const cookies = new Cookies()
      const userIdTwilight = cookies.get(
        `${TWILIGHT_PREFIX}${CookieKeys.USER_ID}` // Login as user via superadmin
      )
      const userId = cookies.get(CookieKeys.USER_ID)
      if (userId === undefined || userId === null) {
        self.logout()
      } else {
        self.userId = userIdTwilight || userId
      }
    },
  }))
