import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { SnapshotOut } from 'mobx-state-tree'
import { captureException } from '@sentry/react'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useStores } from '../../../../hooks/useStores'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { CreateDocumentForm } from '../CreateUpdateDocumentForm/CreateDocumentForm'
import { UpdateDocumentForm } from '../CreateUpdateDocumentForm/UpdateDocumentForm'
import { DocumentModel } from '../../../../state/models/document'
import { societyDocumentUpdateSchema } from '../../../../forms/schemas/society_document_update'
import { societyDocumentUploadSchema } from '../../../../forms/schemas/society_document_upload'

interface CreateUpdateDocumentModalProps {
  show: boolean
  close: () => void
  section: string
  parentId: string
  children?: React.ReactNode
  id?: string
  isBoardDocuments?: boolean
}

export const CreateUpdateDocumentModal = observer(
  ({
    id,
    parentId,
    section,
    children,
    show,
    close,
    isBoardDocuments = false,
  }: CreateUpdateDocumentModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { documentStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!id
    const document = id
      ? (documentStore.documents.get(id) as SnapshotOut<typeof DocumentModel>)
      : undefined

    const showErrorMessage = (): void => {
      const message = updateMode
        ? 'createUpdateDocument.flashMessage.updateDocumentFailure'
        : 'createUpdateDocument.flashMessage.createDocumentFailure'
      setToastNotification(ToastType.DANGER, translate(message))
    }

    const showSuccessMessage = (): void => {
      const message = updateMode
        ? 'createUpdateDocument.flashMessage.updateDocumentSuccess'
        : 'createUpdateDocument.flashMessage.createDocumentSuccess'
      setToastNotification(ToastType.SUCCESS, translate(message))
    }

    const handleRequestMessages = (status: boolean): void => {
      if (status) {
        close()
        showSuccessMessage()
      } else {
        showErrorMessage()
      }
    }

    const onSubmitUpdateDocument = async (
      data: InferType<typeof societyDocumentUpdateSchema>
    ): Promise<void> => {
      const status = id && (await documentStore.patchDocument(id, { ...data }))
      if (status) {
        handleRequestMessages(status)
      }
    }

    const showMessage = (type: ToastType, message: string): void => {
      setToastNotification(type, message)
    }

    const getSuccessMessage = (isSingular: boolean): string => {
      return `${translate(
        isSingular
          ? 'createUpdateDocument.flashMessage.createDocumentSingularSuccess'
          : 'createUpdateDocument.flashMessage.createDocumentPluralSuccess'
      )}`
    }

    const getFailureMessage = (isSingular: boolean): string => {
      return `${translate(
        isSingular
          ? 'createUpdateDocument.flashMessage.createDocumentSingularFailure'
          : 'createUpdateDocument.flashMessage.createDocumentPluralFailure'
      )}`
    }

    const onSubmitCreateDocument = async (
      data: InferType<typeof societyDocumentUploadSchema>
    ): Promise<void> => {
      if (!data.files) {
        showMessage(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
        return
      }

      const promises = data.files.map(async (_file) => {
        const docId = await documentStore.createDocument(
          _file.mimeType,
          _file.base64,
          _file.name,
          _file.filename,
          parentId,
          society._id,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          section,
          data.notificationSettings
        )
        return docId
      })

      const isOneFile = data.files.length === 1

      if (promises && promises.length > 0) {
        const ids = await Promise.all(promises)
        const filteredIds = ids.filter((x) => x !== undefined)
        if (filteredIds.length > 0) {
          showMessage(ToastType.SUCCESS, getSuccessMessage(isOneFile))
          close()
        } else {
          showMessage(ToastType.DANGER, getFailureMessage(isOneFile))
        }
      } else {
        showMessage(ToastType.DANGER, getFailureMessage(isOneFile))
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      showErrorMessage()
    }

    const loading =
      documentStore.creatingFolder === 'pending' ||
      documentStore.updatingDocument === 'pending' ||
      documentStore.creatingDocument === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate(
                  updateMode
                    ? 'createUpdateDocument.editDocumentTitle'
                    : 'createUpdateDocument.addNewDocumentTitle'
                )}
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                {updateMode && document ? (
                  <UpdateDocumentForm
                    onError={onError}
                    onSubmit={onSubmitUpdateDocument}
                    onClose={close}
                    loading={loading}
                    document={document}
                  />
                ) : (
                  <CreateDocumentForm
                    onError={onError}
                    onSubmit={onSubmitCreateDocument}
                    onClose={close}
                    loading={loading}
                    isBoardDocuments={isBoardDocuments}
                  />
                )}
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
