import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../../hooks/useStores'
import { FacilityModel } from '../../../../state/models/facility'
import { UnitModel } from '../../../../state/models/unit'

export const useUserReachedMaxBookings = (
  userUnits: SnapshotOut<typeof UnitModel>[],
  facility: SnapshotOut<typeof FacilityModel>
): boolean => {
  const { bookingsStore } = useStores()

  if (!facility) {
    return false
  }

  if (facility?.rules === 'unlimited') {
    return false
  }

  const futureFacilityBookings =
    bookingsStore.futureBookingsForUnitsAndFacility(
      userUnits.map((_unit) => _unit._id),
      facility._id
    )

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const maxNumberOfBookings = facility?.getRuleAsInteger()

  return futureFacilityBookings.length >= maxNumberOfBookings
}
