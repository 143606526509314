import React, { KeyboardEvent } from 'react'
import ChatIcon from '../../../assets/icons/chat.svg'
import Chat2Icon from '../../../assets/icons/chat_2.svg'
import CommentIcon from '../../../assets/icons/comment.svg'
import LikeIcon from '../../../assets/icons/like.svg'
import MoreIcon from '../../../assets/icons/more.svg'
import ImportantIcon from '../../../assets/icons/important.svg'
import FeedIcon from '../../../assets/icons/feed.svg'
import GavelIcon from '../../../assets/icons/gavel.svg'
import PersonIcon from '../../../assets/icons/person.svg'
import HomeIcon from '../../../assets/icons/home.svg'
import DocumentIcon from '../../../assets/icons/document.svg'
import DocumentAddIcon from '../../../assets/icons/document_add.svg'
import ArrowIcon from '../../../assets/icons/arrow.svg'
import FilterIcon from '../../../assets/icons/filter.svg'
import BellIcon from '../../../assets/icons/bell.svg'
import EmailIcon from '../../../assets/icons/email.svg'
import InfoIcon from '../../../assets/icons/info.svg'
import InformationIcon from '../../../assets/icons/information.svg'
import PasswordIcon from '../../../assets/icons/password.svg'
import WebsiteIcon from '../../../assets/icons/website.svg'
import RowArrowIcon from '../../../assets/icons/row_arrow.svg'
import HouseholdIcon from '../../../assets/icons/household.svg'
import ImageIcon from '../../../assets/icons/image.svg'
import CloseIcon from '../../../assets/icons/close.svg'
import CloseCrossIcon from '../../../assets/icons/close_cross.svg'
import CheckmarkIcon from '../../../assets/icons/checkmark.svg'
import CheckCircleIcon from '../../../assets/icons/check_circle.svg'
import CrossIcon from '../../../assets/icons/cross.svg'
import BoappaIcon from '../../../assets/icons/boappa.svg'
import ExternalLinkIcon from '../../../assets/icons/external_link.svg'
import PlusSignIcon from '../../../assets/icons/plus_sign.svg'
import BoardIcon from '../../../assets/icons/board.svg'
import DownloadIcon from '../../../assets/icons/download.svg'
import SendIcon from '../../../assets/icons/send.svg'
import BoardPortalIcon from '../../../assets/icons/board_portal.svg'
import ResidentListIcon from '../../../assets/icons/resident_list.svg'
import CalendarIcon from '../../../assets/icons/calendar.svg'
import ChevronDownIcon from '../../../assets/icons/chevron_down.svg'
import ChevronRightIcon from '../../../assets/icons/chevron_right.svg'
import ChevronLeftIcon from '../../../assets/icons/chevron_left.svg'
import ChevronUpIcon from '../../../assets/icons/chevron_up.svg'
import AdminIcon from '../../../assets/icons/admin.svg'
import SearchIcon from '../../../assets/icons/search.svg'
import FolderIcon from '../../../assets/icons/folder.svg'
import ErrorIcon from '../../../assets/icons/error.svg'
import SettingsIcon from '../../../assets/icons/settings.svg'
import HashtagIcon from '../../../assets/icons/hashtag.svg'
import QuestionMarkIcon from '../../../assets/icons/question_mark.svg'
import GroupIcon from '../../../assets/icons/group.svg'
import ListIcon from '../../../assets/icons/list.svg'
import BadgeIcon from '../../../assets/icons/badge.svg'
import ImagesIcon from '../../../assets/icons/images.svg'
import LocationIcon from '../../../assets/icons/location.svg'
import AssignmentIcon from '../../../assets/icons/assignment.svg'
import PaymentIcon from '../../../assets/icons/payment.svg'
import GroupsIcon from '../../../assets/icons/groups.svg'
import DomainIcon from '../../../assets/icons/domain.svg'
import TuneIcon from '../../../assets/icons/tune.svg'
import PriorityIcon from '../../../assets/icons/priority.svg'
import LockIcon from '../../../assets/icons/lock.svg'
import EqualizerIcon from '../../../assets/icons/equalizer.svg'
import GreenMortageIcon from '../../../assets/icons/green_mortage.svg'
import SavingsAccountIcon from '../../../assets/icons/savings_account.svg'
import HeartIcon from '../../../assets/icons/heart.svg'
import SchoolIcon from '../../../assets/icons/school.svg'
import TaskIcon from '../../../assets/icons/task.svg'
import PlusIcon from '../../../assets/icons/plus.svg'
import EnvelopeIcon from '../../../assets/icons/envelope.svg'
import CallIcon from '../../../assets/icons/call.svg'
import DeleteIcon from '../../../assets/icons/delete.svg'
import NumberPlateIcon from '../../../assets/icons/number_plate.svg'
import PlayCircleIcon from '../../../assets/icons/play_circle.svg'
import OpenEyeIcon from '../../../assets/icons/open_eye.svg'
import ClosedEyeIcon from '../../../assets/icons/closed_eye.svg'
import ExclamationAlertIcon from '../../../assets/icons/exclamation_alert.svg'
import IdentityIcon from '../../../assets/icons/identity.svg'
import UserAssignmentIcon from '../../../assets/icons/user_assignment.svg'
import CameraIcon from '../../../assets/icons/camera.svg'
import BallotIcon from '../../../assets/icons/ballot.svg'
import FlagIcon from '../../../assets/icons/flag.svg'
import StoreIcon from '../../../assets/icons/store.svg'
import LaundryIcon from '../../../assets/icons/laundry.svg'
import PhoneIcon from '../../../assets/icons/phone.svg'
import NewMessageIcon from '../../../assets/icons/new_message.svg'
import ManageAccountIcon from '../../../assets/icons/manage_account.svg'
import RefreshIcon from '../../../assets/icons/refresh.svg'
import LogoutIcon from '../../../assets/icons/logout.svg'
import PersonAddIcon from '../../../assets/icons/person_add.svg'
import DeleteTrashIcon from '../../../assets/icons/delete_trash.svg'
import InventoryIcon from '../../../assets/icons/inventory.svg'
import CorporateFareIcon from '../../../assets/icons/corporate_fare.svg'
import ClockIcon from '../../../assets/icons/clock.svg'
import LocalOfferIcon from '../../../assets/icons/local_offer.svg'
import CloudUploadIcon from '../../../assets/icons/cloud_upload.svg'
import HamburgerIcon from '../../../assets/icons/hamburger_icon.svg'
import ArrowLeftIcon from '../../../assets/icons/arrow_left.svg'
import ArrowRightIcon from '../../../assets/icons/arrow_right.svg'
import HouseHouseIcon from '../../../assets/icons/house_house.svg'
import ExternalLink2Icon from '../../../assets/icons/external_link_2.svg'
import KebabMenuIcon from '../../../assets/icons/kebab_menu.svg'
import BugIcon from '../../../assets/icons/bug.svg'
import SuggestionIcon from '../../../assets/icons/suggestion.svg'
import SupportIcon from '../../../assets/icons/support.svg'
import Document2Icon from '../../../assets/icons/document_2.svg'
import BarChartIcon from '../../../assets/icons/bar_chart.svg'
import NotificationAlertIcon from '../../../assets/icons/notification_alert.svg'
import FileUploadIcon from '../../../assets/icons/file_upload.svg'
import ReportIcon from '../../../assets/icons/report.svg'
import EditIcon from '../../../assets/icons/edit.svg'
import LeaveSocietyIcon from '../../../assets/icons/leave_society.svg'
import CheckmarkRoundIcon from '../../../assets/icons/checkmark_round.svg'
import CopyIcon from '../../../assets/icons/copy.svg'
import ExclamationIcon from '../../../assets/icons/exclamation.svg'
import QuestionMarkNoOutlineIcon from '../../../assets/icons/questionmark_no_outline.svg'
import InterestsIcon from '../../../assets/icons/interests.svg'
import ContactPageIcon from '../../../assets/icons/contact_page.svg'
import WorkIcon from '../../../assets/icons/work.svg'
import BostadsratternaIcon from '../../../assets/icons/bostadsratterna.svg'
import WarningAmberIcon from '../../../assets/icons/warning_amber.svg'
import LeaveIcon from '../../../assets/icons/leave.svg'
import ProfileBadgeIcon from '../../../assets/icons/profile_badge.svg'
import IconToggleIcon from '../../../assets/icons/icon_toggle.svg'
import GridIcon from '../../../assets/icons/grid.svg'
import BlockedUserIcon from '../../../assets/icons/blocked_user.svg'
import OfferIcon from '../../../assets/icons/offer.svg'

import { theme } from '../../../theme/theme'

export enum IconChoices {
  LIKE = 'like',
  CHAT = 'chat',
  CHAT_2 = 'chat_2',
  COMMENT = 'comment',
  MORE = 'more',
  IMPORTANT = 'important',
  FEED = 'feed',
  GAVEL = 'gavel',
  PERSON = 'person',
  HOME = 'home',
  DOCUMENT = 'document',
  DOCUMENT_ADD = 'document_add',
  ARROW = 'arrow',
  BELL = 'bell',
  FILTER = 'filter',
  EMAIL = 'email',
  INFO = 'info',
  INFORMATION = 'information',
  PASSWORD = 'password',
  WEBSITE = 'website',
  ROW_ARROW = 'row_arrow',
  HOUSEHOLD = 'household',
  IMAGE = 'image',
  CLOSE = 'close',
  CLOSE_CROSS = 'close_cross',
  CHECKMARK = 'checkmark',
  CHECKMARK_ROUND = 'checkmark_round',
  CHECK_CIRLE = 'check_circle',
  CROSS = 'cross',
  BOAPPA = 'boappa',
  EXTERNAL_LINK = 'external_link',
  PLUS_SIGN = 'plus_sign',
  PLUS = 'plus',
  BOARD = 'board',
  DOWNLOAD = 'download',
  SEND = 'send',
  BOARD_PORTAL = 'board_portal',
  RESIDENT_LIST = 'resident_list',
  CALENDAR = 'calendar',
  CHEVRON_DOWN = 'chevron_down',
  CHEVRON_RIGHT = 'chevron_right',
  CHEVRON_LEFT = 'chevron_left',
  CHEVRON_UP = 'chevron_up',
  ADMIN = 'admin',
  SEARCH = 'search',
  FOLDER = 'folder',
  ERROR = 'error',
  SETTINGS = 'settings',
  HASHTAG = 'hashtag',
  QUESTION_MARK = 'question_mark',
  GROUP = 'group',
  LIST = 'list',
  BADGE = 'badge',
  IMAGES = 'images',
  LOCATION = 'location',
  ASSIGNMENT = 'assignment',
  PAYMENT = 'payment',
  GROUPS = 'groups',
  DOMAIN = 'domain',
  TUNE = 'tune',
  PRIORITY = 'priority',
  LOCK = 'lock',
  EQUALIZER = 'equalizer',
  GREEN_MORTAGE = 'green_mortage',
  SAVINGS_ACCOUNT = 'savings_account',
  HEART = 'heart',
  SCHOOL = 'school',
  TASK = 'task',
  ENVELOPE = 'envelope',
  CALL = 'call',
  DELETE = 'delete',
  NUMBER_PLATE = 'number_plate',
  PLAY_CIRCLE = 'play_circle',
  OPEN_EYE = 'open_eye',
  CLOSED_EYE = 'closed_eye',
  EXCLAMATION_ALERT = 'exclamation_alert',
  USER_ASSIGNMENT = 'user_assignment',
  IDENTITY = 'identity',
  CAMERA = 'camera',
  BALLOT = 'ballot',
  FLAG = 'flag',
  STORE = 'store',
  LAUNDRY = 'laundry',
  PHONE = 'phone',
  NEW_MESSAGE = 'new_message',
  MANAGE_ACCOUNT = 'manage_account',
  REFRESH = 'refresh',
  LOGOUT = 'logout',
  PERSON_ADD = 'person_add',
  DELETE_TRASH = 'delete_trash',
  INVENTORY = 'inventory',
  CORPORATE_FARE = 'corporate_fare',
  CLOCK = 'clock',
  LOCAL_OFFER = 'local_offer',
  CLOUD_UPLOAD = 'cloud_upload',
  HAMBURGER = 'hamburger',
  ARROW_LEFT = 'arrow_left',
  ARROW_RIGHT = 'arrow_right',
  HOUSE_HOUSE = 'house_house',
  EXTERNAL_LINK_2 = 'external_link_2',
  KEBAB_MENU = 'kebab_menu',
  BUG = 'bug',
  SUGGESTION = 'suggestion',
  SUPPORT = 'support',
  DOCUMENT_2 = 'document_2',
  BAR_CHART = 'bar_chart',
  NOTIFICATION_ALERT = 'notification_alert',
  FILE_UPLOAD = 'file_upload',
  REPORT = 'report',
  EDIT = 'edit',
  LEAVE_SOCIETY = 'leave_society',
  COPY = 'copy',
  EXCLAMATION = 'exclamation',
  QUESTION_MARK_NO_OUTLINE = 'question_mark_no_outline',
  INTERESTS = 'interests',
  CONTACT_PAGE = 'contact_page',
  WORK = 'work',
  BOSTADSRATTERNA = 'bostadsratterna',
  WARNING_AMBER = 'warning_amber',
  LEAVE = 'leave',
  PROFILE_BADGE = 'profile_badge',
  ICON_TOGGLE = 'icon_toggle',
  GRID = 'grid',
  BLOCKED_USER = 'blocked_user',
  OFFER = 'offer',
}

interface IconProps {
  icon: IconChoices
  color?: string
  highlighted?: boolean
  size?: number
  width?: number
  height?: number
  className?: string
  onClick?: () => void
  flexItems?: 'items-start' | 'items-center' | 'items-end'
}

export const Icon = React.forwardRef<HTMLDivElement, IconProps>(function Icon(
  {
    className,
    icon,
    color,
    highlighted,
    size = 24,
    width,
    height,
    onClick,
    flexItems = 'items-center',
  }: IconProps,
  ref
): JSX.Element {
  const getIcon = ():
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    | undefined => {
    switch (icon) {
      case IconChoices.LIKE:
        return LikeIcon
      case IconChoices.CHAT:
        return ChatIcon
      case IconChoices.CHAT_2:
        return Chat2Icon
      case IconChoices.COMMENT:
        return CommentIcon
      case IconChoices.MORE:
        return MoreIcon
      case IconChoices.IMPORTANT:
        return ImportantIcon
      case IconChoices.FEED:
        return FeedIcon
      case IconChoices.GAVEL:
        return GavelIcon
      case IconChoices.PERSON:
        return PersonIcon
      case IconChoices.HOME:
        return HomeIcon
      case IconChoices.DOCUMENT:
        return DocumentIcon
      case IconChoices.DOCUMENT_ADD:
        return DocumentAddIcon
      case IconChoices.ARROW:
        return ArrowIcon
      case IconChoices.BELL:
        return BellIcon
      case IconChoices.FILTER:
        return FilterIcon
      case IconChoices.EMAIL:
        return EmailIcon
      case IconChoices.INFO:
        return InfoIcon
      case IconChoices.INFORMATION:
        return InformationIcon
      case IconChoices.PASSWORD:
        return PasswordIcon
      case IconChoices.WEBSITE:
        return WebsiteIcon
      case IconChoices.ROW_ARROW:
        return RowArrowIcon
      case IconChoices.HOUSEHOLD:
        return HouseholdIcon
      case IconChoices.IMAGE:
        return ImageIcon
      case IconChoices.CLOSE:
        return CloseIcon
      case IconChoices.CLOSE_CROSS:
        return CloseCrossIcon
      case IconChoices.CHECKMARK:
        return CheckmarkIcon
      case IconChoices.CHECKMARK_ROUND:
        return CheckmarkRoundIcon
      case IconChoices.CHECK_CIRLE:
        return CheckCircleIcon
      case IconChoices.CROSS:
        return CrossIcon
      case IconChoices.BOAPPA:
        return BoappaIcon
      case IconChoices.EXTERNAL_LINK:
        return ExternalLinkIcon
      case IconChoices.PLUS_SIGN:
        return PlusSignIcon
      case IconChoices.PLUS:
        return PlusIcon
      case IconChoices.BOARD:
        return BoardIcon
      case IconChoices.DOWNLOAD:
        return DownloadIcon
      case IconChoices.SEND:
        return SendIcon
      case IconChoices.BOARD_PORTAL:
        return BoardPortalIcon
      case IconChoices.RESIDENT_LIST:
        return ResidentListIcon
      case IconChoices.CALENDAR:
        return CalendarIcon
      case IconChoices.CHEVRON_DOWN:
        return ChevronDownIcon
      case IconChoices.CHEVRON_RIGHT:
        return ChevronRightIcon
      case IconChoices.CHEVRON_LEFT:
        return ChevronLeftIcon
      case IconChoices.CHEVRON_UP:
        return ChevronUpIcon
      case IconChoices.ADMIN:
        return AdminIcon
      case IconChoices.SEARCH:
        return SearchIcon
      case IconChoices.FOLDER:
        return FolderIcon
      case IconChoices.ERROR:
        return ErrorIcon
      case IconChoices.SETTINGS:
        return SettingsIcon
      case IconChoices.HASHTAG:
        return HashtagIcon
      case IconChoices.QUESTION_MARK:
        return QuestionMarkIcon
      case IconChoices.GROUP:
        return GroupIcon
      case IconChoices.LIST:
        return ListIcon
      case IconChoices.BADGE:
        return BadgeIcon
      case IconChoices.IMAGES:
        return ImagesIcon
      case IconChoices.LOCATION:
        return LocationIcon
      case IconChoices.ASSIGNMENT:
        return AssignmentIcon
      case IconChoices.PAYMENT:
        return PaymentIcon
      case IconChoices.GROUPS:
        return GroupsIcon
      case IconChoices.DOMAIN:
        return DomainIcon
      case IconChoices.TUNE:
        return TuneIcon
      case IconChoices.PRIORITY:
        return PriorityIcon
      case IconChoices.LOCK:
        return LockIcon
      case IconChoices.EQUALIZER:
        return EqualizerIcon
      case IconChoices.GREEN_MORTAGE:
        return GreenMortageIcon
      case IconChoices.SAVINGS_ACCOUNT:
        return SavingsAccountIcon
      case IconChoices.HEART:
        return HeartIcon
      case IconChoices.SCHOOL:
        return SchoolIcon
      case IconChoices.TASK:
        return TaskIcon
      case IconChoices.ENVELOPE:
        return EnvelopeIcon
      case IconChoices.CALL:
        return CallIcon
      case IconChoices.DELETE:
        return DeleteIcon
      case IconChoices.NUMBER_PLATE:
        return NumberPlateIcon
      case IconChoices.PLAY_CIRCLE:
        return PlayCircleIcon
      case IconChoices.CLOSED_EYE:
        return ClosedEyeIcon
      case IconChoices.OPEN_EYE:
        return OpenEyeIcon
      case IconChoices.EXCLAMATION_ALERT:
        return ExclamationAlertIcon
      case IconChoices.USER_ASSIGNMENT:
        return UserAssignmentIcon
      case IconChoices.IDENTITY:
        return IdentityIcon
      case IconChoices.CAMERA:
        return CameraIcon
      case IconChoices.BALLOT:
        return BallotIcon
      case IconChoices.FLAG:
        return FlagIcon
      case IconChoices.STORE:
        return StoreIcon
      case IconChoices.LAUNDRY:
        return LaundryIcon
      case IconChoices.PHONE:
        return PhoneIcon
      case IconChoices.NEW_MESSAGE:
        return NewMessageIcon
      case IconChoices.MANAGE_ACCOUNT:
        return ManageAccountIcon
      case IconChoices.REFRESH:
        return RefreshIcon
      case IconChoices.LOGOUT:
        return LogoutIcon
      case IconChoices.PERSON_ADD:
        return PersonAddIcon
      case IconChoices.DELETE_TRASH:
        return DeleteTrashIcon
      case IconChoices.INVENTORY:
        return InventoryIcon
      case IconChoices.CORPORATE_FARE:
        return CorporateFareIcon
      case IconChoices.CLOCK:
        return ClockIcon
      case IconChoices.LOCAL_OFFER:
        return LocalOfferIcon
      case IconChoices.CLOUD_UPLOAD:
        return CloudUploadIcon
      case IconChoices.HAMBURGER:
        return HamburgerIcon
      case IconChoices.ARROW_LEFT:
        return ArrowLeftIcon
      case IconChoices.ARROW_RIGHT:
        return ArrowRightIcon
      case IconChoices.HOUSE_HOUSE:
        return HouseHouseIcon
      case IconChoices.EXTERNAL_LINK_2:
        return ExternalLink2Icon
      case IconChoices.KEBAB_MENU:
        return KebabMenuIcon
      case IconChoices.BUG:
        return BugIcon
      case IconChoices.SUGGESTION:
        return SuggestionIcon
      case IconChoices.SUPPORT:
        return SupportIcon
      case IconChoices.DOCUMENT_2:
        return Document2Icon
      case IconChoices.BAR_CHART:
        return BarChartIcon
      case IconChoices.NOTIFICATION_ALERT:
        return NotificationAlertIcon
      case IconChoices.FILE_UPLOAD:
        return FileUploadIcon
      case IconChoices.REPORT:
        return ReportIcon
      case IconChoices.EDIT:
        return EditIcon
      case IconChoices.LEAVE_SOCIETY:
        return LeaveSocietyIcon
      case IconChoices.COPY:
        return CopyIcon
      case IconChoices.EXCLAMATION:
        return ExclamationIcon
      case IconChoices.QUESTION_MARK_NO_OUTLINE:
        return QuestionMarkNoOutlineIcon
      case IconChoices.INTERESTS:
        return InterestsIcon
      case IconChoices.CONTACT_PAGE:
        return ContactPageIcon
      case IconChoices.WORK:
        return WorkIcon
      case IconChoices.BOSTADSRATTERNA:
        return BostadsratternaIcon
      case IconChoices.WARNING_AMBER:
        return WarningAmberIcon
      case IconChoices.LEAVE:
        return LeaveIcon
      case IconChoices.PROFILE_BADGE:
        return ProfileBadgeIcon
      case IconChoices.ICON_TOGGLE:
        return IconToggleIcon
      case IconChoices.GRID:
        return GridIcon
      case IconChoices.BLOCKED_USER:
        return BlockedUserIcon
      case IconChoices.OFFER:
        return OfferIcon
      default:
        return undefined
    }
  }
  const calculatedColor = highlighted ? theme.colors.brandGreen : 'black'

  const handleKeyDown = (e: KeyboardEvent<HTMLImageElement>): void => {
    if (e.key === 'Enter' && onClick) {
      onClick()
    }
  }

  const SelectedIcon = getIcon()

  return (
    <div ref={ref} className={`flex ${flexItems}`}>
      {SelectedIcon &&
        (!onClick ? (
          <SelectedIcon
            className={className}
            width={width || size}
            height={height || size}
            color={color || calculatedColor}
          />
        ) : (
          <div
            role="button"
            onClick={() => onClick()}
            onKeyDown={handleKeyDown}
            tabIndex={0}
          >
            <SelectedIcon
              className={className}
              width={width || size}
              height={height || size}
              color={color || calculatedColor}
            />
          </div>
        ))}
    </div>
  )
})
