import React from 'react'
import { Link } from 'react-router-dom'
import { Button, ButtonVariant } from '../Button'
import { Card } from '../Card'
import { Dropdown, Option } from '../Dropdown'
import { IconChoices } from '../Icon'

interface ListCardProps {
  children: JSX.Element
  toPath: string
  dropdownOptions?: Option[]
}

export const ListCard = ({
  children,
  toPath,
  dropdownOptions,
}: ListCardProps): JSX.Element => {
  return (
    <Card>
      {{
        body: (
          <Link
            to={toPath}
            className="flex flex-1 justify-between hover:bg-gray-light"
          >
            <div className="relative flex flex-1 items-center justify-between p-4">
              <div className="mr-10 w-full">{children}</div>
              {dropdownOptions && (
                <Dropdown options={dropdownOptions}>
                  <Button
                    variant={ButtonVariant.TEXT}
                    size="xs"
                    icon={IconChoices.MORE}
                    disableTabIndex
                  />
                </Dropdown>
              )}
            </div>
          </Link>
        ),
      }}
    </Card>
  )
}
