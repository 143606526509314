import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useStores } from '../../../hooks/useStores'
import { StatusMessage } from '../StatusMessage/StatusMessage'

interface StatusMessagesProps {
  authenticated: boolean
}

export const StatusMessages = observer(
  ({ authenticated }: StatusMessagesProps): JSX.Element => {
    const { statusStore } = useStores()
    const { i18n } = useTranslation()

    const messages = authenticated
      ? statusStore.authenticatedMessages
      : statusStore.nonAuthenticatedMessages

    return (
      <>
        {messages.length > 0 && (
          <div className="my-4 flex flex-col gap-2">
            {messages.map((_message) => (
              <StatusMessage
                key={_message.id}
                text={i18n.language.includes('en') ? _message.en : _message.sv}
              />
            ))}
          </div>
        )}
      </>
    )
  }
)
