import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button, ButtonVariant } from '../../../../components/common/Button'
import { IconChoices } from '../../../../components/common/Icon'
import { useStores } from '../../../../hooks/useStores'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../../components/common/Toast/toast-type'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'

export const DataExport = observer((): JSX.Element => {
  const { adminStore } = useStores()
  const { setToastNotification } = useToastNotifications()
  const { translate } = useAppTranslation()

  const downloadSociety = async (): Promise<void> => {
    const status = await adminStore.downloadSocietyList()
    if (!status) {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }
  }

  const loading = adminStore.downloadingSocietyList === 'pending'

  return (
    <div className="space-y-4">
      <Button
        variant={ButtonVariant.PRIMARY}
        icon={IconChoices.DOWNLOAD}
        label="Ladda ner lista över aktiverade föreningar"
        onClick={downloadSociety}
        loading={loading}
      />
    </div>
  )
})
