import { SnapshotOut } from 'mobx-state-tree'
import { SocietyModel } from '../../../state/models/society'
import {
  isUserAdminInSociety,
  isUserBoardMemberInSociety,
} from '../../../helpers/society'
import { useStores } from '../../../hooks/useStores'

export const useAdminOrBoardMemberInAtLeastOneSociety = (): boolean => {
  const { authenticationStore, societyStore } = useStores()

  return societyStore.sortedSocieties
    .map(
      (_society: SnapshotOut<typeof SocietyModel>) =>
        isUserAdminInSociety(_society, authenticationStore.userId as string) ||
        isUserBoardMemberInSociety(
          _society,
          authenticationStore.userId as string
        )
    )
    .includes(true)
}
