import config from '../../../config'

export enum ManagementPartner {
  BOSTADSRATTERNA = 'bostadsratterna',
  VILLAAGARNA = 'villaagarna',
  FREE_SAMF = 'free-samf',
  TELENOR = 'telenor',
  BOSTADSUTVECKLARE = 'bostadsutvecklare',
}

export const getPartnerId = (partner: ManagementPartner): string => {
  switch (partner) {
    case ManagementPartner.VILLAAGARNA:
      return config.VILLAAGARNA_PARTNER_ID
    case ManagementPartner.FREE_SAMF:
      return config.FREE_SAMF_PARTNER_ID
    case ManagementPartner.TELENOR:
      return config.TELENOR_PARTNER_ID
    case ManagementPartner.BOSTADSUTVECKLARE:
      return config.BOSTADSUTVECKLARE_PARTNER_ID
    default:
      return config.BOSTADSRATTERNA_PARTNER_ID
  }
}

export const getPartnerNameId = (partnerId: string): string | null => {
  switch (partnerId) {
    case config.BOSTADSRATTERNA_PARTNER_ID:
      return ManagementPartner.BOSTADSRATTERNA
    case config.VILLAAGARNA_PARTNER_ID:
      return ManagementPartner.VILLAAGARNA
    case config.FREE_SAMF_PARTNER_ID:
      return ManagementPartner.FREE_SAMF
    case config.TELENOR_PARTNER_ID:
      return ManagementPartner.TELENOR
    case config.BOSTADSUTVECKLARE_PARTNER_ID:
      return ManagementPartner.BOSTADSUTVECKLARE
    default:
      return null
  }
}
