export const weeklySchedule = [
  {
    allowed: true,
    startTime: new Date(Date.UTC(2018, 3, 2, 8)),
    endTime: new Date(Date.UTC(2018, 3, 2, 18)),
  }, // Monday
  {
    allowed: true,
    startTime: new Date(Date.UTC(2018, 3, 3, 8)),
    endTime: new Date(Date.UTC(2018, 3, 3, 18)),
  }, // Tuesday
  {
    allowed: true,
    startTime: new Date(Date.UTC(2018, 3, 4, 8)),
    endTime: new Date(Date.UTC(2018, 3, 4, 18)),
  }, // Wednesday
  {
    allowed: true,
    startTime: new Date(Date.UTC(2018, 3, 5, 8)),
    endTime: new Date(Date.UTC(2018, 3, 5, 18)),
  }, // Thursday
  {
    allowed: true,
    startTime: new Date(Date.UTC(2018, 3, 6, 8)),
    endTime: new Date(Date.UTC(2018, 3, 6, 18)),
  }, // Friday
  {
    allowed: true,
    startTime: new Date(Date.UTC(2018, 3, 7, 8)),
    endTime: new Date(Date.UTC(2018, 3, 7, 18)),
  }, // Saturday
  {
    allowed: true,
    startTime: new Date(Date.UTC(2018, 3, 1, 8)),
    endTime: new Date(Date.UTC(2018, 3, 1, 18)),
  }, // Sunday
]
