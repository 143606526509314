import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { societyFilterSchema } from '../../../forms/schemas/post_filter'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { theme } from '../../../theme/theme'
import { Button, ButtonVariant } from '../../common/Button'
import { Dropdown } from '../../common/Dropdown'
import { IconChoices } from '../../common/Icon'
import { ToastType } from '../../common/Toast/toast-type'
import { PostFilterDropdownOption } from '../PostFilterDropdownOption'

interface PostFilterSocietyButtonProps {
  societyFilters: string[]
  isMobile: boolean
}

export const PostFilterSocietyButton = observer(
  ({ societyFilters, isMobile }: PostFilterSocietyButtonProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const { postStore, societyStore } = useStores()

    const societies = societyStore.sortedSocieties

    const {
      control,
      handleSubmit,
      setValue,
      watch,
      formState: { isValid },
    } = useForm<InferType<typeof societyFilterSchema>>({
      mode: 'all',
      defaultValues: {
        all:
          societyFilters.length === 0 ||
          societyFilters.includes('all') ||
          false,
        societies: [],
      },
    })

    const watchAll = watch('all')
    const watchSocieties = watch('societies')

    const selectedFilters = useMemo(() => {
      return [watchAll, ...watchSocieties]
    }, [watchAll, watchSocieties])

    const onCustomChange = (id: string, checked: boolean): void => {
      // If all is selected deselect all suboptions
      if (id === 'all' && checked) {
        setValue('societies', [])
        return
      }
      // If a suboption is selected disable all
      if (id !== 'all' && checked) {
        setValue('all', false)
        setValue('societies', [...watchSocieties, id])
      }

      // If a suboption is deselected remove from array
      if (!checked) {
        const _societies = watchSocieties.filter((_society) => _society !== id)
        setValue('societies', _societies)
      }
    }

    // When no suboption is selected enable all
    useEffect(() => {
      selectedFilters.length === 1 && setValue('all', true)
    }, [selectedFilters, setValue])

    const onSubmit = (data: InferType<typeof societyFilterSchema>): void => {
      const _societyFilters = data.societies.filter(
        (_society) => !!_society
      ) as string[]
      postStore.setSocietyFilters(_societyFilters)
      postStore.getFeed()
    }

    const onError = (): void => {
      if (!isValid) return
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const dropdownOptions = [
      {
        value: 'all',
        label: translate('feedView.filterFeed.postsFilterAllOptionValue'),
        customContent: (
          <Controller
            control={control}
            name="all"
            render={({ field: { name, value, onChange } }) => (
              <PostFilterDropdownOption
                name={name}
                onChange={onChange}
                onCustomChange={onCustomChange}
                label={translate(
                  'feedView.filterFeed.societiesFilterAllOptionValue'
                )}
                value={value}
              />
            )}
          />
        ),
      },
      ...societies.map((society) => ({
        value: society._id,
        label: society.name,
        customContent: (
          <Controller
            control={control}
            name="societies"
            render={({ field: { onChange } }) => (
              <PostFilterDropdownOption
                name={society._id}
                onChange={onChange}
                onCustomChange={onCustomChange}
                label={society.name}
                value={watchSocieties.includes(society._id)}
              />
            )}
          />
        ),
      })),
    ]

    const submitButton = (
      <div className="flex h-full w-full items-center justify-center px-6 pb-6 pt-3">
        <Button
          className="px-16"
          label={translate('common.actions.confirm')}
          variant={ButtonVariant.PRIMARY}
          onClick={handleSubmit(onSubmit, onError)}
        />
      </div>
    )

    return (
      <div className="flex flex-col gap-4">
        <Dropdown
          className="w-fit"
          dropdownPosition="bottom-left"
          dropdownContentWidth="w-64"
          options={dropdownOptions}
          submitButton={submitButton}
        >
          <Button
            label={
              isMobile
                ? undefined
                : translate('feedView.filterFeed.societiesFilterTitle')
            }
            disableTabIndex
            iconPlacement="left"
            icon={IconChoices.GAVEL}
            iconColor={
              societyFilters.length > 0 ? theme.colors.brandGreen : 'black'
            }
          />
        </Dropdown>
      </div>
    )
  }
)
