import React from 'react'
import { observer } from 'mobx-react-lite'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { theme } from '../../../../theme/theme'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { Accordion } from '../../../../components/common/Accordion'
import { AccordionItem } from '../../../../components/common/AccordionItem'
import { MoreAbout } from './MoreAbout'
import { PersonalDataAdvice } from './PersonalDataAdvice'
import { BoappaVendor } from './BoappaVendor'
import { DataProtectionOfficer } from './DataProtectionOfficer'
import { ViewBase } from '../../../../components/common/ViewBase'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'

export const GDPRView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:gdpr', {
      '{{ societyName }}': society.name,
    })
  )

  const itemClassName = 'border-t p-5'

  return (
    <ErrorBoundary>
      <ViewBase title={translate('gdprView.title')}>
        <p style={theme.textVariants.base} className="mb-10 w-full md:w-1/2">
          {translate('gdprView.content')}
        </p>
        <div className="flex w-full flex-col gap-3 md:w-1/2">
          <Accordion hasBorder>
            <AccordionItem hasBorder index={0} className="p-5">
              {{
                header: <div>{translate('gdprView.moreAbout.title')}</div>,
                content: (
                  <div className="p-5">
                    <MoreAbout />
                  </div>
                ),
              }}
            </AccordionItem>
            <AccordionItem hasBorder index={1} className={itemClassName}>
              {{
                header: (
                  <div>{translate('gdprView.personalDataAdvice.title')}</div>
                ),
                content: (
                  <div className="p-5">
                    <PersonalDataAdvice />
                  </div>
                ),
              }}
            </AccordionItem>
            <AccordionItem hasBorder index={2} className={itemClassName}>
              {{
                header: <div>{translate('gdprView.boappaVendor.title')}</div>,
                content: (
                  <div className="p-5">
                    <BoappaVendor />
                  </div>
                ),
              }}
            </AccordionItem>
            <AccordionItem hasBorder index={3} className={itemClassName}>
              {{
                header: (
                  <div>{translate('gdprView.dataProtectionOfficer.title')}</div>
                ),
                content: (
                  <div className="p-5">
                    <DataProtectionOfficer />
                  </div>
                ),
              }}
            </AccordionItem>
          </Accordion>
        </div>
      </ViewBase>
    </ErrorBoundary>
  )
})
