import { useCallback, useEffect, useState } from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../hooks/useStores'
import { ChatRoomModel } from '../../../state/models/chat-room'

type TabFetchingStatus = 'done' | 'none' | 'pending' | 'error'

interface JoinRoomDataReturnType {
  currentChatRooms: SnapshotOut<typeof ChatRoomModel>[]
  loadMoreChatRooms: () => void
  showSpinner: boolean | null
}

export const useJoinRoomData = (): JoinRoomDataReturnType => {
  const { authenticationStore, chatRoomStore } = useStores()
  const [showSpinner, setShowSpinner] = useState<boolean | null>(true)
  const userId = authenticationStore.userId as string

  const isCurrentTabStatus = useCallback(
    (status: TabFetchingStatus): boolean => {
      return chatRoomStore.fetchingChatRoomsInterests === status
    },
    [chatRoomStore]
  )

  const getListData = (): SnapshotOut<typeof ChatRoomModel>[] => {
    return chatRoomStore.openInterestsChatRoomsNotParticipatingIn(userId)
  }

  const filterArchived = (
    chatRooms: SnapshotOut<typeof ChatRoomModel>[]
  ): SnapshotOut<typeof ChatRoomModel>[] => {
    return chatRooms.filter(
      (_chatRoom) =>
        _chatRoom.userSettings.find(
          (_setting) =>
            _setting.userId === userId &&
            _setting.hideUntilNotification === true
        ) === undefined
    )
  }

  const getUserRoomsData = useCallback(
    async (fetchNextPage: boolean): Promise<void> => {
      if (isCurrentTabStatus('pending')) {
        return
      }
      await chatRoomStore.getUserRoomsInterests(fetchNextPage, true)
    },
    [chatRoomStore, isCurrentTabStatus]
  )

  const loadMoreChatRooms = (): void => {
    !isCurrentTabStatus('pending') && getUserRoomsData(true)
  }

  useEffect(() => {
    getUserRoomsData(false)
  }, [getUserRoomsData])

  const currentChatRooms = filterArchived(getListData())

  const dataFetchDone = isCurrentTabStatus('done')

  useEffect(() => {
    setShowSpinner(!dataFetchDone)
  }, [currentChatRooms, dataFetchDone])

  return {
    loadMoreChatRooms,
    currentChatRooms,
    showSpinner,
  }
}
