import * as yup from 'yup'

export const societyEventCreateSchema = yup
  .object({
    title: yup.string().required(),
    location: yup.string(),
    description: yup.string(),
    isAllDay: yup.boolean(),
    startDate: yup.date().when('isAllDay', {
      is: false,
      then: (schema) => schema.required(),
    }),
    endDate: yup.date().when('isAllDay', {
      is: false,
      then: (schema) => schema.required(),
    }),
    attendanceEnabled: yup.boolean(),
    notificationSettings: yup
      .string()
      .oneOf(['none', 'feed', 'notification', 'notification+feed'])
      .required(),
    societyId: yup.string().required(),
    userId: yup.string().required(),
  })
  .required()
