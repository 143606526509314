import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Link, useNavigate } from 'react-router-dom'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import BoappaLogo from '../../../assets/icons/boappa.svg'
import { theme } from '../../../theme/theme'
import { SignUpForm } from './SignUpForm'
import { LanguagePicker } from '../../../components/authentication/LanguagePicker'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { useDocumentTitle } from '../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../navigation/reverseDocumentTitle'
import { useAuth } from '../../../hooks/useAuth'

export const SignUpView = observer((): JSX.Element => {
  const { userId } = useAuth()
  const navigate = useNavigate()
  const { translate } = useAppTranslation()
  useDocumentTitle(reverseDocumentTitle('signup'))

  useEffect(() => {
    if (userId) {
      navigate(reverseUrl('verify-email'))
    }
  }, [userId, navigate])

  return (
    <div className="flex h-full flex-col md:flex-row">
      <BoappaLogo
        className="absolute inset-x-8 w-16 md:w-40"
        alt="boappa logo"
      />
      <div className="flex flex-1 flex-col items-center overflow-y-auto pb-4 md:pb-8">
        <div className="flex shrink self-end p-4 md:p-8">
          <LanguagePicker />
        </div>
        <div className="-mt-10 flex w-[90%] flex-1 flex-col justify-center md:w-[80%] xl:w-[50%]">
          <div className="flex flex-col gap-4">
            <p style={theme.textVariants.h1}>
              {translate('authenticationSignUpView.title')}
            </p>
            <p style={theme.textVariants.paragraph}>
              {translate('authenticationSignUpView.subTitle')}
            </p>
            <SignUpForm />
            <p
              className="mt-4 mb-4 flex justify-center gap-2 md:mb-0"
              style={theme.textVariants.paragraph}
            >
              {translate('authenticationSignUpView.loginButtonTitle')}
              <Link
                className="underline hover:text-blue"
                to={reverseUrl('login')}
              >
                {translate('authenticationSignUpView.loginCTAButtonTitle')}
              </Link>
            </p>
            <p
              className="mb-4 flex justify-center md:mb-0"
              style={theme.textVariants.paragraph}
            >
              <Link
                className="underline hover:text-blue"
                to={reverseUrl('reset-password')}
              >
                {translate(
                  'authenticationSignUpView.forgotPasswordButtonTitle'
                )}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
})
