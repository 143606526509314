import { SocietyTypes } from '../../types/society-type'
import { translateEnumDict } from './helpers'

const societyTypesTranslations: { [key: string]: string } = {
  [SocietyTypes.HOUSING_COOPERATIVE]: 'societyType.housingCooperative',
  [SocietyTypes.HOUSING_COOPERATIVE_CONSTRUCTION]:
    'societyType.housingCooperativeConstruction',
  [SocietyTypes.HOME_OWNERS_ASSOCIATION]: 'societyType.homeOwnersAssociation',
  [SocietyTypes.NON_PROFIT_ASSOCIATION]: 'societyType.nonProfitAssociation',
  [SocietyTypes.ECONOMIC_ASSOCIATION]: 'societyType.economicAssociation',
  [SocietyTypes.NEIGHBOURHOOD]: 'societyType.neighbourhood',
}

export const getSocietyType = (societyType: SocietyTypes): string | null => {
  return translateEnumDict(societyTypesTranslations, societyType)
}
