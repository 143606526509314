import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { IconChoices } from '../components/common/Icon'
import { useWindowDimensions } from './useWindowDimensions'

const sideBarBreakpoint = 904
const sidebarUrls = [
  { path: 'society', icon: IconChoices.HAMBURGER },
  { path: 'settings', icon: IconChoices.HAMBURGER },
  { path: 'chat', icon: IconChoices.HAMBURGER },
]

interface IHamburgerContext {
  isHamburgerMode: boolean
  displaySidebar: boolean
  setDisplaySidebar: (value: boolean) => void
  isMobileNavbar: boolean
  hamburgerIcon: IconChoices | undefined
}

const HamburgerContext = createContext<IHamburgerContext>({
  isHamburgerMode: window.innerHeight < sideBarBreakpoint,
  displaySidebar: window.innerHeight > sideBarBreakpoint,
  setDisplaySidebar: () => null,
  isMobileNavbar: window.innerHeight < sideBarBreakpoint,
  hamburgerIcon: undefined,
})

interface HamburgerContextProviderProps {
  children: React.ReactNode
}

export const HamburgerContextProvider = ({
  children,
}: HamburgerContextProviderProps): JSX.Element => {
  const location = useLocation()
  const { width } = useWindowDimensions()
  const [isHamburgerMode, setIsHamburgerMode] = useState<boolean>(
    window.innerWidth < sideBarBreakpoint
  )
  const [displaySidebar, setDisplaySidebar] = useState<boolean>(
    window.innerWidth > sideBarBreakpoint
  )
  const [hasSidebar, setHasSidebar] = useState<boolean>(false)
  const [isMobileNavbar, setIsMobileNavbar] = useState<boolean>(
    window.innerWidth < sideBarBreakpoint
  )
  const [hamburgerIcon, setHamburgerIcon] = useState<IconChoices>()

  useEffect(() => {
    setHasSidebar(
      sidebarUrls.some((url) => {
        if (location.pathname.includes(url.path)) {
          setHamburgerIcon(url.icon)
          return true
        }
        return false
      })
    )
  }, [location.pathname])

  useEffect(() => {
    setIsMobileNavbar(width < sideBarBreakpoint)
    !hasSidebar && setIsHamburgerMode(false)
    hasSidebar && width < sideBarBreakpoint && setIsHamburgerMode(true)
    width < sideBarBreakpoint && !isHamburgerMode && setDisplaySidebar(false)
    width > sideBarBreakpoint && setIsHamburgerMode(false)
    width > sideBarBreakpoint && setDisplaySidebar(true)
  }, [hasSidebar, isHamburgerMode, width])

  const hamburgerValue = useMemo(
    () => ({
      isHamburgerMode,
      displaySidebar,
      setDisplaySidebar,
      isMobileNavbar,
      hamburgerIcon,
    }),
    [isHamburgerMode, displaySidebar, isMobileNavbar, hamburgerIcon]
  )

  return (
    <HamburgerContext.Provider value={hamburgerValue}>
      {children}
    </HamburgerContext.Provider>
  )
}

export const useHamburger = (): IHamburgerContext =>
  useContext(HamburgerContext)
