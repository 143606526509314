import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { ChatMessage } from '../ChatMessage'
import { GroupAvatar } from '../GroupAvatar'
import { useStores } from '../../../hooks/useStores'
import { InfiniteScroll } from '../../common/InfiniteScroll'
import { ChatRoomModel } from '../../../state/models/chat-room'
import { GET_ROOM_MESSAGE_LIMIT } from '../../../state/stores/chat-messages'
import { useModal } from '../../../hooks/useModal'
import { ReadByModal } from './ReadByModal'
import { IChatRoomModel } from '../../../interfaces/models/chat-rooms.interfaces'

interface ChatRoomBodyProps {
  chatRoomId: string | undefined
}

export const ChatRoomBody = observer(
  ({ chatRoomId }: ChatRoomBodyProps): JSX.Element => {
    const { chatMessageStore, chatRoomStore, authenticationStore } = useStores()
    const [hasDoneInitialScroll, setHasDoneInitialScroll] = useState(false)
    const [hasDoneInitialFetch, setHasDoneInitialFetch] = useState(false)
    const [hasMore, setHasMore] = useState<boolean>(false)
    const [lastChatRoom, setLastChatRoom] = useState<undefined | string>(
      undefined
    )
    const [readByModalContent, setReadByModalContent] = useState<
      { userId: string; readTime: string }[]
    >([])
    const {
      show: showReadByModal,
      open: openReadByModal,
      close: closeReadByModal,
    } = useModal()
    const ref = useRef(null)
    const bottomRef = useRef<HTMLDivElement>(null)

    const chatRoom =
      chatRoomId &&
      (chatRoomStore.chatRooms.get(chatRoomId) as
        | SnapshotOut<typeof ChatRoomModel>
        | undefined)

    // eslint-disable-next-line
    const messages = chatRoom
      ? chatMessageStore.getChatRoomMessages(chatRoom._id)
      : []

    const loadMoreChatMessages = useCallback((): void => {
      if (
        chatMessageStore.fetchingChatMessages !== 'pending' &&
        chatRoom &&
        hasMore
      ) {
        chatMessageStore
          .getRoomMessages(chatRoomId, true)
          .then((chatMessagesSize) => {
            setHasMore(chatMessagesSize === GET_ROOM_MESSAGE_LIMIT)
          })
      }
    }, [chatMessageStore, chatRoom, chatRoomId, hasMore])

    useEffect(() => {
      const initialFetch = async (): Promise<void> => {
        if (chatRoomId && lastChatRoom !== chatRoomId) {
          await chatMessageStore.getRoomMessages(chatRoomId)
          setHasDoneInitialFetch(true)
        }
      }
      initialFetch()
    }, [chatMessageStore, chatRoomId, lastChatRoom])

    useEffect(() => {
      if (lastChatRoom === undefined) {
        setLastChatRoom(chatRoomId)
        setHasMore(true)
      }
      if (lastChatRoom !== undefined && lastChatRoom !== chatRoomId) {
        setHasDoneInitialScroll(false)
        setLastChatRoom(chatRoomId)
        setHasDoneInitialFetch(false)
        setHasMore(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatRoomId, lastChatRoom])

    useEffect(() => {
      if (!hasDoneInitialScroll) {
        if (bottomRef.current) {
          bottomRef.current.scrollIntoView({
            behavior: 'auto',
            block: 'nearest',
            inline: 'start',
          })
          setHasDoneInitialScroll(true)
        }
      }
    }, [
      chatMessageStore,
      chatMessageStore.chatMessages,
      chatRoomId,
      hasDoneInitialScroll,
      setHasDoneInitialScroll,
      hasDoneInitialFetch,
    ])

    useEffect(() => {
      const lastMessage = messages.length > 0 ? messages[0] : undefined
      // If user haven't read last message, read it.
      if (
        lastMessage &&
        lastMessage.readBy &&
        chatMessageStore.readingMessage(lastMessage._id) === 'none' &&
        !lastMessage.readBy
          .map((_readBy) => _readBy.userId)
          .includes(authenticationStore.userId as string)
      ) {
        chatMessageStore.setChatMessageReadByUser(
          lastMessage._id,
          authenticationStore.userId as string
        )
        chatRoomStore.setRoomRead(chatRoom as IChatRoomModel)
      }
    }, [
      messages,
      chatRoom,
      chatRoomStore,
      chatMessageStore,
      authenticationStore,
    ])

    const hideContent =
      !hasDoneInitialScroll ||
      (lastChatRoom !== undefined && lastChatRoom !== chatRoomId)

    const readByUsers: { [userId: string]: boolean } = {
      [authenticationStore.userId as string]: true,
    }

    return (
      <>
        <div className="flex min-h-0 flex-1 flex-col">
          <div ref={ref} className="flex h-full bg-neutral-98">
            <InfiniteScroll
              key="infiniteChatRoomScroll" // Add key to prompt rerendering of component on state change
              className={`flex flex-col flex-col-reverse items-start bg-neutral-98 pl-10 pr-4 pt-8 opacity-0
              md:pl-12 md:pr-6
              ${hideContent ? 'opacity-0' : 'opacity-100'}
            `}
              overflow="overflow-y-scroll"
              onEndReached={loadMoreChatMessages}
              rootRef={ref}
            >
              <div ref={bottomRef} />
              {messages.map((_message, index) => {
                const previousMessage =
                  index === 0 ? undefined : messages[index - 1]

                if (previousMessage) {
                  previousMessage.readBy?.forEach((_readBy) => {
                    readByUsers[_readBy.userId] = true
                  })
                }

                return (
                  <ChatMessage
                    key={_message._id}
                    message={_message}
                    previousMessage={previousMessage}
                    nextMessage={
                      index === messages.length - 1
                        ? undefined
                        : messages[index + 1]
                    }
                    firstMessageInChat={index === 0}
                    isGroupChat={
                      chatRoom ? chatRoom.type !== 'oneonone' : false
                    }
                    previousMessageReads={{ ...readByUsers }}
                    onReadByClick={(readBy) => {
                      setReadByModalContent(
                        readBy.filter(
                          (_readBy) => _readBy.userId !== _message.authorId
                        )
                      )
                      openReadByModal()
                    }}
                  />
                )
              })}
              {chatRoom && <GroupAvatar chatRoom={chatRoom} />}
            </InfiniteScroll>
          </div>
        </div>
        {showReadByModal && (
          <ReadByModal
            show={showReadByModal}
            close={closeReadByModal}
            readBy={readByModalContent}
          />
        )}
      </>
    )
  }
)
