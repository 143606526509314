import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { InferType } from 'yup'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { SelectDropdown } from '../../common/SelectDropdown'
import { Button } from '../../common/Button'
import { IconChoices } from '../../common/Icon'
import { UserModel } from '../../../state/models/user'
import { chatMessageSchema } from '../../../forms/schemas/chat_message'
import { DropdownSelect } from '../../common/DropdownSelect'
import { TextInput } from '../../common/TextInput'
import { UserWithSocietyIdAndRoom } from '../ChatRoom/useUsersWithChatRoom'
import { Avatar } from '../../common/Avatar'
import { DropdownItemContent } from '../../feed/DropdownItemContent'
import { useFormatUserOptionLabel } from '../../../hooks/useFormatUserOptionLabel'
import { useUserBlockedInSociety } from '../../../hooks/useUserBlockedInSociety'
import { BlockedCallout } from '../../blocked/BlockedCallout'

export interface UserWithSocietyId extends SnapshotOut<typeof UserModel> {
  societyId: string
}

interface StartNewChatHeaderProps {
  usersWithChatRoom: UserWithSocietyIdAndRoom[]
  control: Control<InferType<typeof chatMessageSchema>>
  watchRecipients: string[] | undefined
  watchIsGroupchat: boolean
  watchSociety: string | undefined
  setValue: UseFormSetValue<InferType<typeof chatMessageSchema>>
}

export const StartNewChatHeader = observer(
  ({
    usersWithChatRoom,
    control,
    watchRecipients,
    watchIsGroupchat,
    watchSociety,
    setValue,
  }: StartNewChatHeaderProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { chatRoomStore, societyStore } = useStores()
    const formatOptionLabel = useFormatUserOptionLabel()

    const userIsBlockedInSociety = useUserBlockedInSociety(watchSociety)

    const noGroupchatSocieties =
      societyStore.sortedSocietiesWithChatInterestsEnabled.length === 0

    const userOptions = usersWithChatRoom.map((userWithChatRoom) => ({
      value: userWithChatRoom.user._id,
      label: userWithChatRoom.user.fullName,
      data: userWithChatRoom.user,
    }))

    const societyDropdownOptions =
      societyStore.sortedSocietiesWithChatInterestsEnabled.map((society) => ({
        value: society._id,
        label: society.name,
        image: <Avatar size={30} mediaId={society.coverPhotoId} />,
      }))

    const privateDropdownOptions = [
      {
        label: translate('startNewChat.openGroupChat'),
        value: 'false',
        icon: IconChoices.OPEN_EYE,
      },
      {
        label: translate('startNewChat.closedGroupChat'),
        value: 'true',
        icon: IconChoices.CLOSED_EYE,
      },
    ]

    const setChatRoom = (userId: string): void => {
      const [userRoom] = usersWithChatRoom.filter(
        (user) => user.user._id === userId
      )
      const roomId = userRoom.room?._id
      chatRoomStore.setSelectedChatRoom(roomId)
    }

    const onCustomChange = (value: unknown): void => {
      if (Array.isArray(value)) {
        value.length === 0 && chatRoomStore.setSelectedChatRoom(undefined)
        if (value.length === 1) {
          const [userId] = value
          value.length === 1 && !watchIsGroupchat && setChatRoom(userId)
        }
        if (value.length > 1) {
          if (noGroupchatSocieties) {
            const newUserId = value[1]
            setValue('recipients', [newUserId])
            setChatRoom(newUserId)
            return
          }
          setValue('isGroupchat', true)
          chatRoomStore.setSelectedChatRoom(undefined)
        }
        setValue('recipients', value)
      }
    }

    const handleChangeToGroupChat = (): void => {
      setValue('isGroupchat', true)
      chatRoomStore.setSelectedChatRoom(undefined)
    }

    const shouldRenderChangeToGroupChatButton =
      watchRecipients &&
      watchRecipients.length <= 1 &&
      !watchIsGroupchat &&
      !noGroupchatSocieties

    const shouldRenderGroupChatHeader =
      (watchRecipients &&
        watchRecipients.length > 1 &&
        !watchIsGroupchat &&
        !noGroupchatSocieties) ||
      watchIsGroupchat

    return (
      <div className="my-4 mx-6 flex flex-col gap-2">
        <div className="flex w-full">
          <div className="mt-[6px] flex">
            {translate('chatRoomsSidebar.createNewMessageForm.labels.sendTo')}
          </div>
          <div className="flex flex-1">
            <Controller
              control={control}
              name="recipients"
              render={({ field: { value } }) => (
                <SelectDropdown
                  value={value}
                  className="h-fit min-h-[40px] w-full"
                  onChange={(val: unknown) => onCustomChange(val)}
                  options={userOptions}
                  formatOptionLabel={formatOptionLabel}
                  isMulti
                  borderless
                  menuListHeight={420}
                  menuListWidth={325}
                  showDropdownIndicator={false}
                  minHeight="fit-content"
                  placeholder={translate(
                    'chatRoomsSidebar.createNewMessageForm.search.placeholder'
                  )}
                />
              )}
            />
          </div>
        </div>
        {shouldRenderChangeToGroupChatButton && (
          <Button
            label={translate('startNewChat.changeToGroupChat')}
            icon={IconChoices.CHAT}
            iconSize={22}
            onClick={handleChangeToGroupChat}
          />
        )}
        {shouldRenderGroupChatHeader && (
          <>
            <div className="flex items-center">
              <div>{translate('startNewChat.groupChatInputLabel')}</div>
              <div className="w-60">
                <Controller
                  control={control}
                  name="groupChatTitle"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextInput
                      className="pl-2"
                      borderless
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-wrap gap-3">
              <div className="flex w-fit">
                <Controller
                  control={control}
                  name="society"
                  render={({ field: { value, onChange } }) => (
                    <DropdownSelect
                      value={value}
                      onChange={onChange}
                      options={societyDropdownOptions}
                      dropdownWidth="w-60"
                      renderItemContent={(option) => (
                        <DropdownItemContent option={option} />
                      )}
                    />
                  )}
                />
              </div>
              <div className="flex w-fit">
                <Controller
                  control={control}
                  name="isPrivate"
                  render={({ field: { value, onChange } }) => (
                    <DropdownSelect
                      value={value}
                      onChange={onChange}
                      options={privateDropdownOptions}
                      dropdownWidth="w-60"
                      renderItemContent={(option) => (
                        <DropdownItemContent option={option} />
                      )}
                    />
                  )}
                />
              </div>
            </div>
          </>
        )}
        {userIsBlockedInSociety && (
          <BlockedCallout selectedSocietyId={watchSociety} />
        )}
      </div>
    )
  }
)
