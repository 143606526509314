import { AxiosResponse } from 'axios'
import { NStats } from '../interfaces/services/stats.interfaces'
import { createUrl, objToQueryString } from './helpers'
import axiosAuth from './config'

export const getSocietyStatistics = (
  societyId: string
): Promise<AxiosResponse<NStats.NGetSocietyStatistics.IResponse>> => {
  return axiosAuth.get<NStats.NGetSocietyStatistics.IResponse>(
    createUrl(`/stats/get-society-statistics/${societyId}`)
  )
}

export const getSocietyResidents = async (
  societyId: string
): Promise<string> => {
  const societyResidentsEndpoint = createUrl(`/stats/get-societies-residents`)
  const resp = await axiosAuth.post(createUrl(`/users/ephemeral-endpoint`), {
    endpoint: societyResidentsEndpoint,
    maxTimeMS: 1000 * 60 * 30,
  })

  return `${resp.data.data.endpoint}&${objToQueryString({
    format: 'csv',
    societyId,
  })}`
}

export const getFacilityBookings = async (
  societyId: string,
  facilityId: string
): Promise<string> => {
  const societyResidentsEndpoint = createUrl(`/stats/get-societies-bookings`)
  const resp = await axiosAuth.post(createUrl(`/users/ephemeral-endpoint`), {
    endpoint: societyResidentsEndpoint,
    maxTimeMS: 1000 * 60 * 30,
  })

  return `${resp.data.data.endpoint}&${objToQueryString({
    format: 'csv',
    societyId,
    facilityId,
  })}`
}
