import { SnapshotOut } from 'mobx-state-tree'
import { useEffect, useState } from 'react'
import { SocietyModel } from '../../../state/models/society'

export const usePaymentDates = (
  society: SnapshotOut<typeof SocietyModel>,
  cost: number | undefined
): {
  nextPaymentDate: Date | null
} => {
  const [nextPaymentDate, setNextPaymentDate] = useState<Date | null>(null)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { lastSentInvoiceDate } = society
    if (lastSentInvoiceDate || society.accountTypeChangeDate) {
      // We have an existing invoice, add 1 year to it.
      if (lastSentInvoiceDate) {
        const _nextPaymentDate = new Date(lastSentInvoiceDate)
        _nextPaymentDate.setFullYear(_nextPaymentDate.getFullYear() + 1)
        setNextPaymentDate(_nextPaymentDate)
      } else if (
        // We don't have an existing invoice and the user did not just upgrade
        cost &&
        !lastSentInvoiceDate &&
        society.accountTypeChangeDate &&
        new Date(society.accountTypeChangeDate) <
          new Date(new Date().setHours(0, 0, 0, 0))
      ) {
        setNextPaymentDate(new Date())
      } else if (society.accountTypeChangeDate) {
        // User have no invoice and no cost.
        const _accountTypeChangeDate = new Date(society.accountTypeChangeDate)
        _accountTypeChangeDate.setFullYear(
          new Date(_accountTypeChangeDate).getFullYear() + 1
        )
        setNextPaymentDate(_accountTypeChangeDate)
      }
    }
  }, [society, cost])

  return {
    nextPaymentDate,
  }
}
