import React from 'react'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { theme } from '../../../theme/theme'
import { Accordion } from '../Accordion'
import { AccordionItem } from '../AccordionItem'
import { Button, ButtonVariant } from '../Button'
import { Dropdown, Option } from '../Dropdown'
import { Icon, IconChoices } from '../Icon'
import { Linkify } from '../Linkify'

export interface AccordionSectionItem {
  id: string
  title: string
  showAlert?: boolean
  subtitle?: string
  content: JSX.Element
}

export interface AccordionSection {
  title?: string
  subtitle?: string
  items: AccordionSectionItem[]
  itemsEmptyText?: string
  dropdownOptions?: Option[]
  className?: string
}

export const AccordionSection = ({
  title,
  subtitle,
  items,
  itemsEmptyText,
  dropdownOptions,
  className,
}: AccordionSection): JSX.Element => {
  const { translate } = useAppTranslation()

  const displaySectionHeader = title || subtitle
  return (
    <div className={`w-full w-full md:max-w-screen-sm ${className ?? ''}`}>
      <div className="mb-2 flex items-center justify-between">
        {displaySectionHeader && (
          <div>
            {title && <p style={theme.textVariants.h3}>{title}</p>}
            {subtitle && <p style={theme.textVariants.base}>{subtitle}</p>}
          </div>
        )}
        {dropdownOptions && dropdownOptions.length > 0 && (
          <Dropdown dropdownPosition="bottom-left" options={dropdownOptions}>
            <Button
              label={translate('common.options')}
              variant={ButtonVariant.DEFAULT}
              size="sm"
              icon={IconChoices.MORE}
              iconPlacement="right"
              disableTabIndex
            />
          </Dropdown>
        )}
      </div>
      {items.length > 0 ? (
        <Accordion hasBorder>
          {items.map((item, i) => (
            <AccordionItem
              className={`${i !== 0 ? 'border-t' : ''} p-5`}
              key={item.id}
              index={i}
              hasBorder
            >
              {{
                header: (
                  <div className="flex flex-1 justify-between">
                    <div>
                      <div className="flex break-all">
                        <p style={theme.textVariants.lead}>{item.title}</p>
                      </div>
                      {item.subtitle && (
                        <p
                          style={theme.textVariants.base}
                          className="break-all opacity-90"
                        >
                          {item.subtitle}
                        </p>
                      )}
                    </div>
                    {item.showAlert && (
                      <Icon icon={IconChoices.EXCLAMATION_ALERT} />
                    )}
                  </div>
                ),
                content: (
                  <div className="whitespace-pre-wrap px-5 py-2">
                    <Linkify>{item.content}</Linkify>
                  </div>
                ),
              }}
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        <p className="mt-4">
          {itemsEmptyText ||
            translate('accordionSection.defaultItemsEmptyText')}
        </p>
      )}
    </div>
  )
}
