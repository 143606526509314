import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { theme } from '../../../../theme/theme'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { CreateUpdateRegisterUnitItemForm } from '../CreateUpdateRegisterUnitItemForm'
import { useStores } from '../../../../hooks/useStores'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { UnitModel } from '../../../../state/models/unit'
import { unitCreateSchema } from '../../../../forms/schemas/society_unit_create'
import { NUnits } from '../../../../interfaces/services/units.interfaces'

interface CreateUpdateRegisterUnitItemModalProps {
  show: boolean
  close: () => void
  unit?: SnapshotOut<typeof UnitModel>
  children?: React.ReactNode
}

export const CreateUpdateRegisterUnitItemModal = observer(
  ({
    show,
    close,
    unit,
    children,
  }: CreateUpdateRegisterUnitItemModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { unitStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isSamf } = society

    const updateMode = !!unit

    const showSuccessMessage = (): void => {
      let translationKey = ''
      if (isSamf) {
        translationKey = updateMode
          ? 'registerUnit.flashMessage.updateUnitSamfSuccess'
          : 'registerUnit.flashMessage.createUnitSamfSuccess'
      } else {
        translationKey = updateMode
          ? 'registerUnit.flashMessage.updateUnitSuccess'
          : 'registerUnit.flashMessage.createUnitSuccess'
      }
      setToastNotification(ToastType.SUCCESS, translate(translationKey))
    }

    const showErrorMessage = (): void => {
      let translationKey = ''
      if (isSamf) {
        translationKey = updateMode
          ? 'registerUnit.flashMessage.updateUnitSamfFailure'
          : 'registerUnit.flashMessage.createUnitSamfFailure'
      } else {
        translationKey = updateMode
          ? 'registerUnit.flashMessage.updateUnitFailure'
          : 'registerUnit.flashMessage.createUnitFailure'
      }
      setToastNotification(ToastType.SUCCESS, translate(translationKey))
    }

    const onSubmit = async (
      data: InferType<typeof unitCreateSchema>
    ): Promise<void> => {
      if (updateMode) {
        const status = await unitStore.patchUnit(
          unit._id,
          data as NUnits.NPatch.IRequestBody
        )
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      } else {
        const status = await unitStore.createUnit(
          data as NUnits.NCreate.IRequestBody
        )
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      }
    }

    const onError = (): void => {
      if (updateMode) {
        setToastNotification(
          ToastType.DANGER,
          translate(
            isSamf
              ? 'registerUnit.flashMessage.updateUnitSamfFailure'
              : 'registerUnit.flashMessage.updateUnitFailure'
          )
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate(
            isSamf
              ? 'registerUnit.flashMessage.createUnitSamfFailure'
              : 'registerUnit.flashMessage.createUnitFailure'
          )
        )
      }
    }

    const getModalTitle = (): string => {
      if (updateMode) {
        return translate(
          isSamf ? 'registerUnit.modal.updateSamf' : 'registerUnit.modal.update'
        )
      }
      return translate(
        isSamf ? 'registerUnit.modal.createSamf' : 'registerUnit.modal.create'
      )
    }

    const loading =
      unitStore.creatingUnit === 'pending' ||
      unitStore.updatingUnit === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                <div>
                  <p style={theme.textVariants.h3}>{getModalTitle()}</p>
                </div>
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <CreateUpdateRegisterUnitItemForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  unit={unit}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
