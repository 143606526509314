import React from 'react'
import { observer } from 'mobx-react-lite'
import { captureException } from '@sentry/react'
import { InferType } from 'yup'
import { useStores } from '../../../hooks/useStores'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { SpinnerWrapper } from '../../common/SpinnerWrapper'
import { Alert } from '../../common/Alert'
import { theme } from '../../../theme/theme'
import { InvoiceDetailsForm } from './InvoiceDetailsForm'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'
import { invoiceDetailsSchema } from '../../../forms/schemas/invoice_details'

interface InvoiceDetails {
  loading: boolean
}

export const InvoiceDetails = observer(
  ({ loading }: InvoiceDetails): JSX.Element => {
    const { societyStore } = useStores()
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()

    const showSuccessMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('flashMessage.changesSaved')
      )
    }
    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.changesNotSavedError')
      )
    }

    const onSubmit = async (
      data: InferType<typeof invoiceDetailsSchema>
    ): Promise<void> => {
      const status = await societyStore.patchSociety(society._id, data)
      if (status) {
        showSuccessMessage()
      } else {
        showErrorMessage()
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      showErrorMessage()
    }

    if (loading) {
      return <SpinnerWrapper />
    }

    return (
      <div className="w-full">
        <div className="lg:w-1/3">
          <p style={theme.textVariants.lead} className="mb-2">
            {translate('invoiceView.details.title')}
          </p>
          <Alert text={translate('invoiceView.details.alert')} />
        </div>
        <div className="mt-4 md:mt-8">
          <InvoiceDetailsForm
            onError={onError}
            onSubmit={onSubmit}
            loading={societyStore.updatingSociety === 'pending'}
          />
        </div>
      </div>
    )
  }
)
