import { SnapshotOut } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import config from '../../../../config'
import { societyHasActivatedPartner } from '../../../../helpers/society'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { SocietyModel } from '../../../../state/models/society'
import { IconChoices } from '../../../common/Icon'
import { LinkSection } from '../LinkSection'

export const useSamfalligheternaLinks = (
  society: SnapshotOut<typeof SocietyModel>
): LinkSection[] => {
  const { translate } = useAppTranslation()

  const id = society._id

  const samfalligheternaLinks: LinkSection[] = societyHasActivatedPartner(
    society,
    config.VILLAAGARNA_PARTNER_ID
  )
    ? [
        {
          id: uuid(),
          title: 'Samfälligheterna',
          sublinks: [
            {
              title: translate(
                'samfalligheterna.samfalligheternaGuidanceView.title'
              ),
              icon: IconChoices.QUESTION_MARK,
              url: reverseUrl('management:samfalligheterna-guidance', { id }),
            },
            {
              title: translate(
                'samfalligheterna.samfalligheternaBenefitsView.title'
              ),
              icon: IconChoices.HEART,
              url: reverseUrl('management:samfalligheterna-benefits', { id }),
            },
            {
              title: translate(
                'samfalligheterna.samfalligheternaSchoolView.title'
              ),
              icon: IconChoices.SCHOOL,
              url: reverseUrl('management:samfalligheterna-school', { id }),
            },
          ],
        },
      ]
    : []
  return samfalligheternaLinks
}
