import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { RegisterGroupMemberListItem } from './RegisterGroupMemberListItem'
import { RegisterGroupUnitListItem } from './RegisterGroupUnitListItem'
import { GroupModel } from '../../../state/models/group'
import { useModal } from '../../../hooks/useModal'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { Option } from '../../common/Dropdown'
import { CreateUpdateRegisterGroupModal } from '../CreateUpdateRegisterGroupModal'
import { ConfirmationModal } from '../../common/ConfirmationModal'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'

interface RegisterGroupListItemBaseProps {
  group: SnapshotOut<typeof GroupModel>
}

export const RegisterGroupListItemBase = observer(
  ({ group }: RegisterGroupListItemBaseProps): JSX.Element => {
    const {
      show: showEditModal,
      open: openEditModal,
      close: closeEditModal,
    } = useModal()
    const {
      show: showDeleteConfirmationModal,
      open: openDeleteConfirmationModal,
      close: closeDeleteConfirmationModal,
    } = useModal()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const { groupStore } = useStores()

    const deleteGroup = async (): Promise<void> => {
      const status = await groupStore.deleteGroup(group._id)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('registerGroup.flashMessage.deleteSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('registerGroup.flashMessage.deleteFailure')
        )
      }
    }

    const dropdownOptions: Option[] = [
      {
        value: 'edit',
        label: translate('common.actions.edit'),
        onClick: openEditModal,
      },
      {
        value: 'remove',
        label: translate('common.actions.delete'),
        onClick: () => openDeleteConfirmationModal(),
        destructive: true,
      },
    ]

    return (
      <>
        {group.unitsList && group.unitsList.length > 0 ? (
          <RegisterGroupUnitListItem
            group={group}
            dropdownOptions={dropdownOptions}
          />
        ) : (
          <RegisterGroupMemberListItem
            group={group}
            dropdownOptions={dropdownOptions}
          />
        )}
        <CreateUpdateRegisterGroupModal
          show={showEditModal}
          close={closeEditModal}
          group={group}
        />
        <ConfirmationModal
          title={translate('registerGroup.confirmationDialog.deleteTitle')}
          description={translate(
            'registerGroup.confirmationDialog.deleteDescription'
          )}
          show={showDeleteConfirmationModal}
          close={closeDeleteConfirmationModal}
          onConfirm={deleteGroup}
          deleteMode
        />
      </>
    )
  }
)
