import React, { KeyboardEvent, ReactNode, useState, useEffect } from 'react'
import { useAccordion } from '../Accordion/useAccordionContext'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../Icon'

interface AccordionItemProps {
  children: {
    header?: ReactNode
    content: ReactNode
  }
  index: number
  className?: string
  hasBorder?: boolean
  disabled?: boolean
  rounded?: boolean
  alwaysOpen?: boolean
}

export const AccordionItem = ({
  children,
  index,
  className,
  hasBorder,
  disabled,
  rounded,
  alwaysOpen,
}: AccordionItemProps): JSX.Element => {
  const { activeItem, setActiveItem } = useAccordion()
  const [rotate, setRotate] = useState(false)
  const [initAnimation, setInitAnimation] = useState(false)
  const { header, content } = children

  useEffect(() => {
    if (activeItem !== index) {
      setRotate(false)
    }
    if (activeItem === index) {
      setInitAnimation(true)
      setRotate(true)
    }
  }, [activeItem, index])

  const onClick = (): void => {
    if (!disabled) {
      if (activeItem === index) {
        setActiveItem(null)
      } else {
        setInitAnimation(true)
        setRotate(true)
        setActiveItem(index)
      }
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (!disabled) {
      if (e.key === 'Enter') {
        if (activeItem === index) {
          setActiveItem(null)
        } else {
          setActiveItem(index)
        }
      }
    }
  }

  const getChevronAnimationClass = (): string => {
    if (hasBorder && rotate) return 'animate-rotateHalf'
    if (rotate) return 'animate-rotateRight'
    return 'animate-rotateLeft'
  }

  const animationClass = getChevronAnimationClass()

  return (
    <div
      className={`${activeItem === index && hasBorder ? 'bg-neutral-98' : ''} ${
        disabled ? 'opacity-50' : ''
      }`}
    >
      {header && (
        <div
          onClick={onClick}
          onKeyDown={handleKeyDown}
          tabIndex={0}
          role="menuitem"
          style={theme.textVariants.baseBold}
          className={`flex items-center justify-between ${
            rounded ? 'rounded' : ''
          } ${!disabled ? 'hover:cursor-pointer hover:bg-neutral-96' : ''} ${
            className ?? ''
          }`}
        >
          {header}
          <Icon
            className={`${hasBorder ? 'ml-3 rounded-full border' : ''} ${
              initAnimation ? animationClass : ''
            }`}
            icon={
              hasBorder ? IconChoices.CHEVRON_DOWN : IconChoices.CHEVRON_RIGHT
            }
            size={hasBorder ? 24 : 12}
          />
        </div>
      )}
      {(activeItem === index || alwaysOpen) && (
        <div className="flex flex-col">{content}</div>
      )}
    </div>
  )
}
