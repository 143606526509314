import React, { useEffect, useState } from 'react'
import { captureException } from '@sentry/react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { Modal } from '../../../../../components/common/Modal'
import { ModalHeader } from '../../../../../components/common/Modal/ModalHeader'
import { ModalBody } from '../../../../../components/common/Modal/ModalBody'
import { useStores } from '../../../../../hooks/useStores'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../../components/common/Toast/toast-type'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { EditSocietyWebsiteModalContent } from './EditSocietyWebsiteModalContent'
import { useAdminSociety } from '../useAdminSociety'
import { getWebsitePerSociety as apiGetWebsitePerSociety } from '../../../../../api/society-websites'
import { NSocietiesWebsites } from '../../../../../interfaces/services/societies-websites.interfaces'
import { ISocietyWebsiteModel } from '../../../../../interfaces/models/societies-websites.interfaces'
import { adminEditSocietyWebsiteSchema } from '../../../../../forms/schemas/admin_edit_society_website'
import { Spinner } from '../../../../../components/common/Spinner'

interface EditSocietyWebsiteModalProps {
  show: boolean
  close: () => void
  resetSearchResult: () => void
  societyId?: string
}

export const EditSocietyWebsiteModal = observer(
  ({ show, close, societyId }: EditSocietyWebsiteModalProps): JSX.Element => {
    const { adminStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()
    const [website, setWebsite] = useState<ISocietyWebsiteModel>()
    const { triggerRefresh } = useAdminSociety(societyId)
    const [updatingWebsite, setUpdatingWebsite] = useState(false)

    useEffect(() => {
      ;(async () => {
        const resp = await apiGetWebsitePerSociety(societyId as string)
        const data = resp.data as NSocietiesWebsites.NGetPerSocieties.IResponse
        setWebsite(data.data[0])
      })()
    }, [societyId, adminStore])

    const onSubmit = async (
      data: InferType<typeof adminEditSocietyWebsiteSchema>
    ): Promise<void> => {
      setUpdatingWebsite(true)
      const dataTosend: Partial<ISocietyWebsiteModel> = { ...data }
      // Backend doesn't support setting slug to existing value
      if (data.slug === website?.slug) {
        delete dataTosend.slug
      }

      const status = await adminStore.patchSocietyWebsite({
        societyId: societyId as string,
        data: dataTosend,
      })

      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('Föreningshemsida uppdaterad')
        )
        triggerRefresh()
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }
      close()
    }

    const onError = (error: unknown): void => {
      captureException(error)
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    return (
      <Modal show={show} size="xl">
        {{
          header: (
            <ModalHeader onClose={close}>
              Uppdatera föreningshemsida
            </ModalHeader>
          ),
          body: (
            <ModalBody className="overflow-y-auto">
              {updatingWebsite || !website ? (
                <Spinner />
              ) : (
                <EditSocietyWebsiteModalContent
                  onSubmit={onSubmit}
                  onError={onError}
                  onClose={close}
                  website={website}
                />
              )}
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
