import React from 'react'
import { observer } from 'mobx-react-lite'
import { Modal } from '../../../../../components/common/Modal'
import { ModalHeader } from '../../../../../components/common/Modal/ModalHeader'
import { ModalBody } from '../../../../../components/common/Modal/ModalBody'
import { SetPremiumModalContent } from './SetPremiumModalContent'

interface SetPremiumModalProps {
  show: boolean
  close: () => void
  societyId?: string
}

export const SetPremiumModal = observer(
  ({ show, close, societyId }: SetPremiumModalProps): JSX.Element => {
    return (
      <Modal show={show}>
        {{
          header: (
            <ModalHeader onClose={close}>Uppgradera till premium</ModalHeader>
          ),
          body: (
            <ModalBody className="overflow-y-auto">
              <SetPremiumModalContent societyId={societyId} close={close} />
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
