import { SnapshotOut } from 'mobx-state-tree'
import { useEffect, useState } from 'react'
import { useStores } from './useStores'
import { UnitModel } from '../state/models/unit'

export const useManageHousehold = (): {
  enableHouseholdSettings: boolean
} => {
  const { authenticationStore, unitStore } = useStores()
  const [enableHouseholdSettings, setEnableHouseholdSettings] = useState(false)
  const userId = authenticationStore.userId as string

  const userUnits = userId
    ? unitStore.userUnits(userId, undefined, false)
    : undefined

  const [selectedUnit, setSelectedUnit] = useState<
    SnapshotOut<typeof UnitModel> | undefined
  >(userUnits && userUnits.length > 0 ? userUnits[0] : undefined)

  useEffect(() => {
    if (selectedUnit === undefined && userUnits && userUnits.length > 0) {
      setSelectedUnit(userUnits[0])
    }
  }, [selectedUnit, userUnits])

  useEffect(() => {
    if (userUnits && userUnits.length > 0 && !enableHouseholdSettings) {
      setEnableHouseholdSettings(true)
    }
  }, [userUnits, enableHouseholdSettings])

  return {
    enableHouseholdSettings,
  }
}
