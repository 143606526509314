import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { NotificationModel } from '../../../state/models/notification'
import { theme } from '../../../theme/theme'
import { NotificationAvatar } from '../NotificationAvatar'
import { NotificationData } from '../../../types/notifications'
import { formatDate } from '../../../helpers/date'
import { useNotificationMessage } from './useNotificationMessage'
import { useNotificationHelpers } from './useNotificationHelpers'

interface NotificationProps {
  notification: SnapshotOut<typeof NotificationModel>
}

export const Notification = observer(
  ({ notification }: NotificationProps): JSX.Element => {
    const notificationData = notification.data
      ? (JSON.parse(notification.data) as NotificationData)
      : undefined

    const { message, renderError, notificationMessageLoading } =
      useNotificationMessage({
        notification,
        notificationData,
      })
    const { onNotificationClick } = useNotificationHelpers({
      notification,
      notificationData,
    })

    const onClick = (): void => {
      onNotificationClick()
    }

    return (
      <>
        {!renderError && !notificationMessageLoading && (
          <div
            className={`block flex min-h-[40px] w-80 cursor-pointer gap-2 text-sm hover:bg-neutral-96
            first:hover:rounded-t last:hover:rounded-b md:w-96
          `}
          >
            <button
              style={{
                backgroundColor: !notification.isRead
                  ? theme.colors.unreadNotificationBackground
                  : 'inherit',
              }}
              className="flex w-full gap-3 border-b border-neutral-96 px-4 pt-3 pb-4"
              onClick={onClick}
            >
              <NotificationAvatar
                notification={notification}
                notificationData={notificationData}
              />

              <div className="-mt-[2px] flex w-full flex-col items-start gap-1">
                <div className="text-left line-clamp-4 md:line-clamp-3">
                  {message}
                </div>
                <p
                  className="text-neutral-50"
                  style={theme.textVariants.caption}
                >
                  {formatDate(Date.parse(notification.createdAt), 'short')}
                </p>
              </div>
            </button>
          </div>
        )}
      </>
    )
  }
)
