import { captureException } from '@sentry/react'

export const toBase64 = (
  file: File
): Promise<{ name: string; base64: string }> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () =>
      resolve({ name: file.name, base64: reader.result as string })
    reader.onabort = (error) => reject(captureException(error))
    reader.onerror = (error) => reject(captureException(error))
  })
