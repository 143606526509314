import { SnapshotOut } from 'mobx-state-tree'
import { ChatRoomModel } from '../state/models/chat-room'
import { UserModel } from '../state/models/user'
import { useStores } from './useStores'

interface useIsUserAdminOfGroupChatReturnType {
  adminUser: SnapshotOut<typeof UserModel> | undefined
  isUserAdminOfGroupChat: boolean
}

export const useIsUserAdminOfGroupChat = (
  chatRoom: SnapshotOut<typeof ChatRoomModel>
): useIsUserAdminOfGroupChatReturnType => {
  const { authenticationStore, userStore } = useStores()
  const userId = authenticationStore.userId as string
  const adminUser = chatRoom.adminId
    ? userStore.users.get(chatRoom.adminId)
    : undefined

  return { adminUser, isUserAdminOfGroupChat: adminUser?._id === userId }
}
