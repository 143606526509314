import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useStores } from '../../../../hooks/useStores'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { CreateUpdateBoardMemberForm } from '../CreateUpdateBoardMemberForm'
import { societyBoardCreateSchema } from '../../../../forms/schemas/society_board_create'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { BoardRole } from '../../../../types/board-roles'
import { ESocietyBoardRole } from '../../../../interfaces/models/societies.interfaces'

interface CreateUpdateBoardMemberModalProps {
  show: boolean
  close: () => void
  children?: React.ReactNode
  userId?: string
}

export const CreateUpdateBoardMemberModal = observer(
  ({
    userId,
    children,
    show,
    close,
  }: CreateUpdateBoardMemberModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { societyStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!userId

    const showErrorMessage = (): void => {
      const message = updateMode
        ? 'createUpdateBoardMemberModal.flashMessage.updateRoleFailure'
        : 'createUpdateBoardMemberModal.flashMessage.createRoleFailure'
      setToastNotification(ToastType.DANGER, translate(message))
    }

    const showSuccessMessage = (): void => {
      const message = updateMode
        ? 'createUpdateBoardMemberModal.flashMessage.updateRoleSuccess'
        : 'createUpdateBoardMemberModal.flashMessage.createRoleSuccess'
      setToastNotification(ToastType.SUCCESS, translate(message))
    }

    const onSubmit = async (
      data: InferType<typeof societyBoardCreateSchema>
    ): Promise<void> => {
      const deleteStatus = await societyStore.deleteUserRole([
        {
          societyId: society._id as string,
          userId: data.userId,
          boardRole: true,
          nominatingCommitteeRole: true,
        },
      ])

      if (deleteStatus) {
        const roleData =
          data.role === BoardRole.NOMINATING_COMMITTEE
            ? { nominatingCommitteeRole: true }
            : { boardRole: data.role as ESocietyBoardRole }
        const createStatus = await societyStore.createUserRole([
          {
            societyId: society._id as string,
            userId: data.userId,
            ...roleData,
          },
        ])

        if (createStatus) {
          await societyStore.getSociety(society?._id as string)
          showSuccessMessage()
          close()
        } else {
          showErrorMessage()
        }
      } else {
        showErrorMessage()
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const loading =
      societyStore.creatingUserRole === 'pending' ||
      societyStore.deletingUserRole === 'pending' ||
      societyStore.fetchingSociety === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate(
                  updateMode
                    ? 'createUpdateBoardMemberModal.editBoardMemberTitle'
                    : 'createUpdateBoardMemberModal.addNewBoardMemberTitle'
                )}
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <CreateUpdateBoardMemberForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  userId={userId}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
