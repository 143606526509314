import * as yup from 'yup'

export const societyContractCreateSchema = yup
  .object({
    title: yup.string().required(),
    company: yup.string(),
    description: yup.string(),

    documentIds: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          name: yup.string(),
        })
      )
      .required(),
    societyId: yup.string().required(),

    running: yup.boolean(),
    startDate: yup.date().nullable(),
    endDate: yup.date().nullable(),
    notificationDate: yup.date().nullable(),

    contactName: yup.string(),
    contactEmail: yup.string().when('email', (val) => {
      const emailValue = val[0]
      if (emailValue !== '' && emailValue !== undefined) {
        return yup.string().email()
      }
      return yup.string().notRequired()
    }),
    contactPhone: yup.string(),
    contactWebsite: yup.string(),

    status: yup.string().oneOf(['active', 'terminated']).required(),
  })
  .required()
