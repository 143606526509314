import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { LocalOfferModel } from '../../../state/models/local-offer'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../../common/Icon'
import { Avatar } from '../../common/Avatar'
import { Card } from '../../common/Card'
import { CardBody } from '../../common/Card/CardBody'
import { Illustration, IllustrationChoices } from '../../common/Illustration'

interface LocalOfferListItemProps {
  localOffer: SnapshotOut<typeof LocalOfferModel>
}

export const LocalOfferListItem = observer(
  ({ localOffer }: LocalOfferListItemProps): JSX.Element => {
    return (
      <Card
        url={reverseUrl('society:local-offers-detail', {
          id: localOffer.societyId,
          offerId: localOffer._id,
        })}
        className="w-[300px]"
      >
        {{
          body: (
            <CardBody className="flex flex-col gap-4">
              {localOffer.coverPhotoId ? (
                <Avatar mediaId={localOffer.coverPhotoId} size={56} />
              ) : (
                <Illustration
                  illustrationChoice={IllustrationChoices.LEMONADE}
                  height={56}
                  width={56}
                />
              )}
              <div className="flex justify-between">
                <div className="flex flex-1 flex-col items-start text-left">
                  <div className="flex w-full justify-between">
                    <p style={theme.textVariants.baseBold}>
                      {localOffer.companyName}
                    </p>
                    <Icon icon={IconChoices.ARROW} />
                  </div>
                  <p
                    className="text-neutral-50"
                    style={theme.textVariants.caption}
                  >
                    {localOffer.discountHeader}
                  </p>
                </div>
              </div>
            </CardBody>
          ),
        }}
      </Card>
    )
  }
)
