import { Genders } from '../../types/gender'
import { translateEnumDict } from './helpers'

const genderTranslations: { [key: string]: string } = {
  [Genders.Male]: 'genders.male',
  [Genders.Female]: 'genders.female',
  [Genders.Other]: 'genders.other',
}

export const getGender = (gender: Genders): string | null => {
  return translateEnumDict(genderTranslations, gender)
}
