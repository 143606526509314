import React from 'react'
import { observer } from 'mobx-react-lite'
import { castToSnapshot, SnapshotOut } from 'mobx-state-tree'
import { RadioButton } from '../../../common/RadioButton'
import { SocietyEntranceModel } from '../../../../state/models/society-entrance'
import { UnitModel } from '../../../../state/models/unit'
import { useStores } from '../../../../hooks/useStores'

export interface FacilityCalendarUnitSelect {
  selectedUnitId: string | undefined
  setSelectedUnitId: (unitId: string | undefined) => void
  userUnits: SnapshotOut<typeof UnitModel>[]
}

export const FacilityCalendarUnitSelect = observer(
  ({
    selectedUnitId,
    setSelectedUnitId,
    userUnits,
  }: FacilityCalendarUnitSelect): JSX.Element | null => {
    const { societyEntrancesStore } = useStores()

    return (
      <div className="flex flex-col gap-2">
        {userUnits.map((_unit) => {
          const entrance =
            _unit.entranceId &&
            societyEntrancesStore.entrances.get(_unit.entranceId)

          return (
            <RadioButton
              key={_unit._id}
              name={_unit._id}
              value={_unit._id}
              checked={_unit._id === selectedUnitId}
              label={`${[
                (
                  castToSnapshot(entrance) as SnapshotOut<
                    typeof SocietyEntranceModel
                  >
                ).addressStreet,
                _unit.title
                  ? `${_unit.titleLegal} (${_unit.title})`
                  : _unit.titleLegal,
              ].join(', ')}`}
              onChange={() => setSelectedUnitId(_unit._id)}
            />
          )
        })}
      </div>
    )
  }
)
