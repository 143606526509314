export enum LocalStorageKeys {
  LOCALE = 'locale',
  VERIFY_EMAIL_RESEND_STATE = 'verify_email_{% id %}',
  DOCUMENTS_LIST_MODE = 'document_list_mode',
  NOTIFICATIONS_ONE_ON_ONE_CHAT_UNSEEN_COUNT = 'notifications_one_on_one_chat_unseen_count',
  NOTIFICATIONS_INTERESTS_CHAT_UNSEEN_COUNT = 'notifications_interests_chat_unseen_count',
  NOTIFICATIONS_BOARD_CHAT_UNSEEN_COUNT = 'notifications_board_chat_unseen_count',
  NOTIFICATIONS_BOARD_QUESTIONS_CHAT_UNSEEN_COUNT = 'notifications_board_questions_chat_unseen_count',
  NOTIFICATIONS_RESIDENTS_QUESTIONS_CHAT_UNSEEN_COUNT = 'notifications_residents_questions_chat_unseen_count',
}
