import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { ChatMessageModel } from '../../../../state/models/chat-message'
import { useStores } from '../../../../hooks/useStores'
import { Avatar } from '../../../common/Avatar'
import { formatRelative } from '../../../../helpers/date'
import { Badge } from '../../../common/Badge'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'

interface ChatMessageReadByProps {
  message: SnapshotOut<typeof ChatMessageModel>
  onClick: (readBy: { userId: string; readTime: string }[]) => void
  previousMessageReads?: { [userId: string]: boolean }
}

export const ChatMessageReadBy = observer(
  ({
    message,
    onClick,
    previousMessageReads,
  }: ChatMessageReadByProps): JSX.Element | null => {
    const { userStore } = useStores()
    const { translate } = useAppTranslation()
    const maxLength = 5

    if (!message.readBy || message.readBy.length === 0) {
      return null
    }

    const filteredReadBy = message.readBy.filter(
      (readBy) => !previousMessageReads?.[readBy.userId]
    )

    if (filteredReadBy.length === 0) {
      return null
    }

    return (
      <button
        className="mt-1 mb-1 flex flex-1 items-center gap-0.5 self-end"
        onClick={() => onClick(filteredReadBy)}
      >
        {filteredReadBy.length > maxLength && (
          <Badge
            text={`+${filteredReadBy.length - maxLength}`}
            className="px-1.5"
            colorClass="bg-neutral-90"
            textColorClass="text-neutral-20"
          />
        )}
        {filteredReadBy.slice(0, maxLength).map((readBy) => {
          if (readBy.userId === message.authorId) {
            return null
          }

          const user = userStore.users.get(readBy.userId)
          if (!user) {
            return null
          }
          return (
            <div
              title={`${translate('chatMessageReadBy.readBy')} ${
                user.fullName
              } ${formatRelative(new Date(readBy.readTime))}`}
              key={readBy.userId}
            >
              <Avatar
                size={16}
                mediaId={user.avatarId}
                onClick={() => onClick(filteredReadBy)}
              />
            </div>
          )
        })}
      </button>
    )
  }
)
