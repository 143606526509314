import { useCallback, useEffect } from 'react'
import { captureException } from '@sentry/react'
import { IChatMessageModel } from '../interfaces/models/chat-messages.interfaces'
import { socket, SocketEvent } from '../sockets'
import { useStores } from './useStores'

interface INotificationObject {
  unread: { [key: string]: number }
  unseen: { [key: string]: number }
  chat: { [key: string]: number }
  iconBadge: number
}

const useWebsocket = (): void => {
  const {
    chatMessageStore,
    chatRoomStore,
    userStore,
    mediaStore,
    authenticationStore,
    notificationsStore,
  } = useStores()

  const setWebsocket = useCallback(
    async (_token: string): Promise<void> => {
      // Initiate socket
      await socket.setSocket(_token, [
        {
          event: SocketEvent.MESSAGE_READ,
          func: async (message: IChatMessageModel): Promise<void> => {
            const parsedMessage =
              typeof message === 'string'
                ? (JSON.parse(message) as IChatMessageModel)
                : message
            chatMessageStore?.setChatMessages([parsedMessage])
          },
        },
        {
          event: SocketEvent.RECEIVE_MESSAGE,
          func: async (message: IChatMessageModel): Promise<void> => {
            const parsedMessage =
              typeof message === 'string'
                ? (JSON.parse(message) as IChatMessageModel)
                : message

            // This commit resolves the issue with double emitting from the socket.
            // The issue is that the socket sends a message to the server, which then sends
            // it back to the client(s) (whome are two).
            // This means that the client receives the same message twice.
            // This is a temporary fix, and should be removed when the socket is refactored.
            if (
              chatRoomStore?.lastReceivedSocketMessageId === parsedMessage._id
            ) {
              return
            }
            chatRoomStore?.setLastReceivedSocketMessageId(parsedMessage._id)

            try {
              // Create room in store if it's a new room
              if (!chatRoomStore?.chatRooms.has(parsedMessage.roomId)) {
                await chatRoomStore?.getRoom(parsedMessage.roomId)
                // Update lastMessageDate if not and increase unread count
              } else {
                const chatRoom = chatRoomStore.chatRooms.get(
                  parsedMessage.roomId
                )
                chatRoom?.updateLastMessageDate(
                  Date.parse(parsedMessage.createdAt)
                )
              }
              // Create user in store if it's a new user
              if (!userStore?.users.has(parsedMessage.authorId)) {
                await userStore?.getUser(parsedMessage.authorId)
              }

              // Find new media files
              const promises = parsedMessage.mediaIds
                ?.map((id): Promise<void> | undefined => {
                  if (!mediaStore?.media.has(id)) {
                    return mediaStore?.getMedia(id)
                  }
                  return undefined
                })
                .filter((promise) => promise !== undefined)

              // And make sure they're fetched before creating the message
              if (promises && promises.length > 0) {
                await Promise.all(promises)
              }

              chatMessageStore?.setChatMessages([parsedMessage])
            } catch (error) {
              captureException(error)
            }
          },
        },
        {
          event: SocketEvent.ROOMS_NEW,
          func: (id: string): void => {
            chatRoomStore?.getRoom(id)
          },
        },
        {
          event: SocketEvent.NOTIFICATION_COUNTS,
          func: (notificationCount: string): void => {
            const notificationObject = JSON.parse(
              notificationCount
            ) as INotificationObject
            const { chat: chatRooms, unseen } = notificationObject
            Object.keys(chatRooms).map(async (id: string): Promise<void> => {
              const chatRoom = chatRoomStore.chatRooms.get(id)
              chatRoom?.setUnreadCount(chatRooms[id])
            })
            notificationsStore.updateUnseenCount(unseen)
          },
        },
      ])
    },
    [chatRoomStore, userStore, chatMessageStore, mediaStore, notificationsStore]
  )

  useEffect(() => {
    if (authenticationStore?.token) {
      setWebsocket(authenticationStore.token)
    }
  }, [authenticationStore?.token, setWebsocket])
}

useWebsocket.displayName = 'useWebsocket'
export { useWebsocket }
