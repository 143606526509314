import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { RegisterMemberBadgeCardBase } from './RegisterMemberBadgeCardBase'
import { useModal } from '../../../hooks/useModal'
import { PreApprovedModal } from './PreApprovedModal'
import { PreApprovedModel } from '../../../state/models/society'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { ResidentUserType } from '../../../state/stores/units'

interface RegisterMemberPreApprovedCardProps {
  residentUserType: ResidentUserType
}

export const RegisterMemberPreApprovedCard = observer(
  ({
    residentUserType,
  }: RegisterMemberPreApprovedCardProps): JSX.Element | null => {
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { translate } = useAppTranslation()
    const {
      show: showPreApprovedModal,
      open: openPreApprovedModal,
      close: closePreApprovedModal,
    } = useModal()
    const preApproved = society?.preApprovedList as
      | SnapshotOut<typeof PreApprovedModel>[]
      | []

    const filteredPreApproved = preApproved.filter((_preApproved) =>
      residentUserType === ResidentUserType.MEMBER
        ? _preApproved.role === 'member'
        : _preApproved.role !== 'member'
    )

    if (!filteredPreApproved || filteredPreApproved.length === 0) {
      return null
    }

    return (
      <PreApprovedModal
        show={showPreApprovedModal}
        close={closePreApprovedModal}
        preApproved={filteredPreApproved}
      >
        <RegisterMemberBadgeCardBase
          onClick={openPreApprovedModal}
          count={filteredPreApproved.length}
          label={translate('preApproved.title')}
        />
      </PreApprovedModal>
    )
  }
)
