import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import React from 'react'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useModal } from '../../../../hooks/useModal'
import { useStores } from '../../../../hooks/useStores'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { HouseRuleModel } from '../../../../state/models/house-rule'
import { Button, ButtonVariant } from '../../../common/Button'
import { ConfirmationModal } from '../../../common/ConfirmationModal'
import { ToastType } from '../../../common/Toast/toast-type'
import { CreateUpdateHouseRuleModal } from '../CreateUpdateHouseRuleModal'
import { Linkify } from '../../../common/Linkify'

interface HouseRuleContentProps {
  houseRule: SnapshotOut<typeof HouseRuleModel>
}

export const HouseRuleContent = observer(
  ({ houseRule }: HouseRuleContentProps): JSX.Element => {
    const { societyStore, houseRulesStore, authenticationStore } = useStores()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const {
      show: showEditModal,
      open: openEditModal,
      close: closeEditModal,
    } = useModal()
    const {
      show: showConfirmationModal,
      open: openConfirmationModal,
      close: closeConfirmationModal,
    } = useModal()

    const isAdmin = isUserAdminInSociety(
      society,
      authenticationStore.userId as string
    )

    const removeHouseRule = async (): Promise<void> => {
      const status = await houseRulesStore.deleteHouseRule(houseRule._id)
      if (status) {
        await societyStore.getSociety(society?._id as string)

        setToastNotification(
          ToastType.SUCCESS,
          translate('createUpdateHouseRule.flashMessage.deleteHouseRuleSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('createUpdateHouseRule.flashMessage.deleteHouseRuleFailure')
        )
      }
    }

    return (
      <>
        <div className="flex flex-col p-5">
          <Linkify>
            <p className="whitespace-pre-wrap break-words">{houseRule.rule}</p>
          </Linkify>
          {isAdmin && (
            <div className="mt-6 flex flex-row space-x-3">
              <Button
                onClick={openEditModal}
                label={translate('common.actions.edit')}
              />
              <Button
                variant={ButtonVariant.DANGER}
                onClick={openConfirmationModal}
                label={translate('common.actions.delete')}
              />
            </div>
          )}
        </div>
        <CreateUpdateHouseRuleModal
          houseRule={houseRule}
          show={showEditModal}
          close={closeEditModal}
        />
        <ConfirmationModal
          title={translate('createUpdateHouseRule.confirmationModal.title')}
          description={translate(
            'createUpdateHouseRule.confirmationModal.description'
          )}
          show={showConfirmationModal}
          close={closeConfirmationModal}
          onConfirm={removeHouseRule}
          deleteMode
        />
      </>
    )
  }
)
