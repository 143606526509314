import React from 'react'
import { observer } from 'mobx-react-lite'
import { Card } from '../../../common/Card'
import { theme } from '../../../../theme/theme'
import { Icon, IconChoices } from '../../../common/Icon'

interface ManagementPartnerCardProps {
  url: string
  title: string
  text: string
}

export const ManagementPartnerCard = observer(
  ({ url, title, text }: ManagementPartnerCardProps): JSX.Element => {
    return (
      <Card
        className="w-full cursor-pointer bg-white p-5 hover:bg-neutral-96"
        url={url}
        openInNewTab
      >
        {{
          body: (
            <div className="flex justify-between gap-2">
              <div className="text-left">
                <p style={theme.textVariants.baseBold}>{title}</p>
                <p
                  style={theme.textVariants.caption}
                  className="text-neutral-50"
                >
                  {text}
                </p>
              </div>
              <Icon icon={IconChoices.EXTERNAL_LINK_2} />
            </div>
          ),
        }}
      </Card>
    )
  }
)
