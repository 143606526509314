import React, { useCallback, useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { theme } from '../../../theme/theme'
import { ShortcutCard } from './ShortcutCard'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { useAuthenticatedUserId } from '../../../hooks/useAuthenticatedUserId'
import { isUserAdminInSociety } from '../../../helpers/society'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { Button, ButtonVariant } from '../../../components/common/Button'
import { IconChoices } from '../../../components/common/Icon'
import {
  managementActiveAccordionItemStateAtom,
  residentActiveAccordionItemStateAtom,
  selectedSocietySidebarMenuItemStateAtom,
} from '../../../components/society/SocietySideBarContent/societySideBarSharedState'
import { TextTooltip } from '../../../components/common/Tooltip'
import { useDocumentTitle } from '../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../navigation/reverseDocumentTitle'
import { useSocietyLockedForCurrentUser } from '../../../hooks/useSocietyLockedForCurrentUser'
import { IllustrationChoices } from '../../../components/common/Illustration'
import { ErrorView } from '../../error/ErrorView'

export const SocietyHome = observer((): JSX.Element => {
  useDocumentTitle(reverseDocumentTitle('society'))
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const { translate } = useAppTranslation()
  const userId = useAuthenticatedUserId() as string
  const navigate = useNavigate()
  const isAdmin = isUserAdminInSociety(society, userId)
  const societyLocked = useSocietyLockedForCurrentUser(society)
  const [, setManagementActiveAccordionIndex] = useAtom(
    managementActiveAccordionItemStateAtom
  )
  const [, setResidentActiveAccordionIndex] = useAtom(
    residentActiveAccordionItemStateAtom
  )
  const [, setSelectedMenuItem] = useAtom(
    selectedSocietySidebarMenuItemStateAtom
  )
  const [referenceElement, setReferenceElement] = useState<Element | null>(null)

  const navigateToOnboarding = useCallback((): void => {
    setSelectedMenuItem(undefined)
    navigate(reverseUrl('society:onboarding', { id: society._id }))
  }, [navigate, society, setSelectedMenuItem])

  // TODO: For now disabling this as users are confused, they can reach it by clicking the question mark icon
  // useEffect(() => {
  //   const cookies = new Cookies()
  //   const seen = cookies.get(
  //     CookieKeys.ONBOARDING_VIEWED.replace('{% id %}', userId)
  //   )
  //   if (!seen && isUserAdminOrBoardMember) {
  //     navigateToOnboarding()
  //   }
  // }, [userId, navigate, navigateToOnboarding, isUserAdminOrBoardMember])

  useEffect(() => {
    setSelectedMenuItem(undefined)
    setResidentActiveAccordionIndex(null)
    setManagementActiveAccordionIndex(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const documentsUrl = reverseUrl('management:documents', { id: society._id })
  const registerUrl = reverseUrl('management:register', { id: society._id })
  const societyWebsiteUrl = reverseUrl('management:website', {
    id: society._id,
  })
  const residentListUrl = reverseUrl('society:resident-list', {
    id: society._id,
  })

  const cardData = [
    ...((isAdmin && [
      {
        title: translate('societyRoot.titles.boardDocuments'),
        subtitle: translate('societyRoot.subtitles.boardDocuments'),
        url: documentsUrl,
        handleSideBarSelection: () => {
          setSelectedMenuItem(documentsUrl)
        },
      },
      {
        title: translate('societyRoot.titles.register'),
        subtitle: translate('societyRoot.subtitles.register'),
        url: registerUrl,
        handleSideBarSelection: () => {
          setSelectedMenuItem(registerUrl)
        },
      },
      {
        title: translate('societyRoot.titles.societyWebsite'),
        subtitle: translate('societyRoot.subtitles.societyWebsite'),
        url: societyWebsiteUrl,
        handleSideBarSelection: () => {
          setSelectedMenuItem(societyWebsiteUrl)
        },
      },
    ]) ||
      []),
    {
      title: translate('societyRoot.titles.residentList'),
      subtitle: translate('societyRoot.subtitles.residentList'),
      url: residentListUrl,
      handleSideBarSelection: () => {
        setSelectedMenuItem(residentListUrl)
      },
    },
  ]

  return (
    <div className="flex w-full flex-col justify-between space-y-4 md:h-full md:space-y-2">
      <div className="flex flex-col">
        {societyLocked ? (
          <ErrorView
            title={translate('invoiceView.basic.locked.title')}
            subtitle={translate('invoiceView.basic.locked.subtitle')}
            illustration={IllustrationChoices.EMPTY}
            className="px-4 py-16"
            buttons={[
              {
                id: 'pay-annual-fee',
                button: {
                  label: translate('common.button.toPayment'),
                  onClick: () =>
                    navigate(
                      reverseUrl('management:billing-payment', {
                        id: society._id,
                      })
                    ),
                  variant: ButtonVariant.PRIMARY,
                },
              },
            ]}
          />
        ) : (
          <>
            <p style={theme.textVariants.h2} translate="no">
              {society.name}
            </p>
            <p className="text-neutral-40" style={theme.textVariants.base}>
              {translate('societyRoot.subtitle')}
            </p>
            <div className="flex w-full flex-col space-y-2">
              <p className="mt-6" style={theme.textVariants.baseBold}>
                {translate('societyRoot.shortcutTitle')}
              </p>
              <div className="flex flex-wrap gap-4">
                {cardData.map((card) => (
                  <ShortcutCard key={card.url} {...card} />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      {!societyLocked && isAdmin && (
        <div className="flex flex-row-reverse">
          <TextTooltip
            title={translate('societyRoot.onboardingButtonTitle')}
            referenceElement={referenceElement}
            className="inline-block"
          >
            <Button
              ref={setReferenceElement}
              onClick={navigateToOnboarding}
              size="no-padding"
              round
              className="h-14 w-14"
              variant={ButtonVariant.PRIMARY}
              icon={IconChoices.QUESTION_MARK_NO_OUTLINE}
            />
          </TextTooltip>
        </div>
      )}
    </div>
  )
})
