import { AxiosResponse } from 'axios'
import { NUsers } from '../interfaces/services/users.interfaces'
import { createUrl } from './helpers'
import axiosAuth from './config'

export const login = (
  data: NUsers.NLogin.IRequestBody
): Promise<AxiosResponse<NUsers.NLogin.IResponse>> => {
  return axiosAuth.post<NUsers.NLogin.IResponse>(
    createUrl('/users/login'),
    data
  )
}
