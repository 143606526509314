import React, { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { useStores } from '../../../hooks/useStores'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { IllustrationChoices } from '../../../components/common/Illustration'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { ButtonVariant } from '../../../components/common/Button'
import { SpinnerWrapper } from '../../../components/common/SpinnerWrapper'
import { ErrorView } from '../ErrorView'

interface SocietyErrorViewWrapperProps {
  children: ReactNode
}

const SocietyErrorViewWrapper = observer(
  ({ children }: SocietyErrorViewWrapperProps): JSX.Element => {
    const navigate = useNavigate()
    const { authenticationStore, societyStore, requestStore } = useStores()
    const { translate } = useAppTranslation()
    const { selectedSociety, sortedSocieties, hasFetchedSocietiesOnce } =
      societyStore
    const userId = authenticationStore.userId as string
    const userHasNoSocieties = sortedSocieties.length === 0
    const userPendingRequests = requestStore.userPendingSocietyRequests(userId)

    const noSocietyErrorView =
      !selectedSociety && hasFetchedSocietiesOnce
        ? {
            title: translate('societyView.emptyState.noSociety.title'),
            subtitle: translate('societyView.emptyState.noSociety.subtitle'),
            illustration: IllustrationChoices.EMPTY,
            buttons: [
              {
                id: uuid(),
                button: {
                  label: translate(
                    'societyView.emptyState.noSociety.buttonTitle'
                  ),
                  onClick: () => navigate(reverseUrl('find-society')),
                  variant: ButtonVariant.PRIMARY,
                },
              },
            ],
          }
        : undefined

    const pendingRequestErrorView =
      userPendingRequests.length > 0 &&
      userHasNoSocieties &&
      hasFetchedSocietiesOnce
        ? {
            title: translate('societyView.emptyState.pendingRequest.title'),
            subtitle: translate(
              'societyView.emptyState.pendingRequest.subtitle'
            ),
            illustration: IllustrationChoices.WAITING,
            buttons: [
              {
                id: uuid(),
                button: {
                  label: translate(
                    'societyView.emptyState.pendingRequest.buttonTitle'
                  ),
                  onClick: () => navigate(reverseUrl('find-society')),
                  variant: ButtonVariant.PRIMARY,
                },
              },
            ],
          }
        : undefined

    if (!hasFetchedSocietiesOnce) return <SpinnerWrapper className="mt-16" />
    if (pendingRequestErrorView)
      return <ErrorView {...pendingRequestErrorView} />
    if (noSocietyErrorView) return <ErrorView {...noSocietyErrorView} />
    return <>{children}</>
  }
)

SocietyErrorViewWrapper.displayName = 'SocietyErrorViewWrapper'
export { SocietyErrorViewWrapper }
