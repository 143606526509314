import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import BoappaLogo from '../../../assets/icons/boappa.svg'
import { theme } from '../../../theme/theme'
import { LoginForm } from './LoginForm'
import { LanguagePicker } from '../../../components/authentication/LanguagePicker'
import { useDocumentTitle } from '../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../navigation/reverseDocumentTitle'
import { LocalStorageKeys } from '../../../types/local-storage'
import { StatusMessages } from '../../../components/status/StatusMessages'
import i18n from '../../../i18n/i18n'

export const LoginView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const localStorageValue = localStorage.getItem(LocalStorageKeys.LOCALE)

  useEffect(() => {
    if (!localStorageValue) {
      localStorage.setItem(LocalStorageKeys.LOCALE, 'sv-SE')
    }
    if (localStorageValue) i18n.changeLanguage(localStorageValue)
  }, [localStorageValue])

  useDocumentTitle(reverseDocumentTitle('login'))

  return (
    <div className="flex h-full flex-col md:flex-row">
      <BoappaLogo
        className="absolute inset-x-8 w-16 md:w-40"
        alt="boappa logo"
      />
      <div className="flex flex-1 flex-col items-center overflow-y-auto pb-4 md:pb-8">
        <div className="flex shrink self-end p-4 md:p-8">
          <LanguagePicker />
        </div>
        <div className="flex w-[90%] flex-1 flex-col justify-center md:-mt-10 md:w-[50%] xl:w-[30%]">
          <div className="flex flex-col gap-4">
            <StatusMessages authenticated={false} />
            <p style={theme.textVariants.h1}>
              {translate('authenticationSignInView.signInButtonTitle')}
            </p>
            <p style={theme.textVariants.paragraph}>
              {translate('authenticationSignInView.welcome')}
            </p>
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  )
})
