import React, { KeyboardEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Icon, IconChoices } from '../../../../common/Icon'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { useModal } from '../../../../../hooks/useModal'
import { Dropdown } from '../../../../common/Dropdown'
import { useStores } from '../../../../../hooks/useStores'
import { ToastType } from '../../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { ConfirmationModal } from '../../../../common/ConfirmationModal'
import { CreateUpdateSocietyWebsiteDocumentModal } from '../CreateUpdateSocietyWebsiteDocumentModal'
import { openUrl, parseUrlWhitespaces } from '../../../../../helpers/url'
import { SocietyWebsiteModel } from '../../../../../state/models/society-website'
import { theme } from '../../../../../theme/theme'
import { Button } from '../../../../common/Button'

interface SocietyWebsiteDocumentListItemProps {
  website: SnapshotOut<typeof SocietyWebsiteModel>
  documentId: string
  folderId: string
}

export const SocietyWebsiteDocumentListItem = observer(
  ({
    website,
    documentId,
    folderId,
  }: SocietyWebsiteDocumentListItemProps): JSX.Element => {
    const { societyWebsitesStore, documentStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()
    const {
      show: showEditDocumentModal,
      open: openEditDocumentModal,
      close: closeEditDocumentModal,
    } = useModal()
    const {
      show: showRemoveConfirmationModal,
      open: openRemoveConfirmationModal,
      close: closeRemoveConfirmationModal,
    } = useModal()

    const folders = website ? website.foldersList : []
    const document = documentStore.documents.get(documentId)

    const icon = IconChoices.DOCUMENT

    const openFile = (): void => {
      if (document?.url) {
        openUrl(parseUrlWhitespaces(document.url))
      }
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
      if (e.key === 'Enter') {
        openFile()
      }
    }

    const removeDocument = async (): Promise<void> => {
      const status = await societyWebsitesStore.patchWebsite(website._id, {
        foldersList: folders?.map((_f) => {
          if (_f._id === folderId) {
            return {
              ..._f,
              documentsList: _f.documentsList.filter(
                (_document) => _document.documentId !== documentId
              ),
            }
          }
          return _f
        }),
      })
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('createUpdateDocument.flashMessage.deleteDocumentSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('createUpdateDocument.flashMessage.deleteDocumentFailure')
        )
      }
    }

    const dropdownOptions = [
      {
        value: 'edit',
        label: translate('common.actions.edit'),
        onClick: () => openEditDocumentModal(),
      },
      {
        value: 'remove',
        label: translate('common.actions.delete'),
        onClick: async () => openRemoveConfirmationModal(),
        destructive: true,
      },
    ]

    return (
      <>
        <div
          role="button"
          tabIndex={0}
          className="flex w-full cursor-pointer cursor-pointer items-center 
          justify-between rounded-sm border-b py-5 px-4 hover:bg-neutral-96"
          onClick={openFile}
          onKeyDown={handleKeyDown}
        >
          <div className="flex items-center">
            <div className="flex w-60 items-center gap-2 md:w-80">
              <Icon size={32} icon={icon} />
              <div className="flex flex-col">
                <p style={theme.textVariants.base}>{document?.name}</p>
              </div>
            </div>
          </div>
          <div className="flex space-x-20">
            <Dropdown options={dropdownOptions}>
              <Button
                icon={IconChoices.MORE}
                className="h-10 w-10 border-none bg-transparent hover:bg-neutral-90"
                disableTabIndex
              />
            </Dropdown>
          </div>
        </div>
        <CreateUpdateSocietyWebsiteDocumentModal
          website={website}
          show={showEditDocumentModal}
          close={closeEditDocumentModal}
          id={document?._id}
          folderId={folderId}
        />
        <ConfirmationModal
          title={translate(
            'createUpdateDocument.removeDocumentConfirmationModal.title'
          )}
          description={translate(
            'createUpdateDocument.removeDocumentConfirmationModal.description'
          )}
          show={showRemoveConfirmationModal}
          close={closeRemoveConfirmationModal}
          onConfirm={removeDocument}
          deleteMode
        />
      </>
    )
  }
)
