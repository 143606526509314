import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { isAlive, SnapshotOut } from 'mobx-state-tree'
import { Link } from 'react-router-dom'
import { ChatRoomModel } from '../../../state/models/chat-room'
import { useStores } from '../../../hooks/useStores'
import { removeMarkdown, replaceHtml } from '../../../helpers/string'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useChatRoomTitle } from '../../../hooks/useChatRoomTitle'
import { useChatRoomAvatar } from '../../../hooks/useChatRoomAvatar'
import { theme } from '../../../theme/theme'
import { formatRelative } from '../../../helpers/date'
import { NotificationBadge } from '../../notifications/NotificationBadge'
import { reverseUrl } from '../../../navigation/reverseUrl'

interface ChatRoomsListItemProps {
  chatRoomId: string
  customOnClick?: () => void
  className?: string
}

export const ChatRoomsListItem = observer(
  ({
    chatRoomId,
    customOnClick,
    className,
  }: ChatRoomsListItemProps): JSX.Element | null => {
    const { translate } = useAppTranslation()
    const { chatMessageStore, chatRoomStore, userStore, authenticationStore } =
      useStores()
    const chatRoom = chatRoomStore.chatRooms.get(chatRoomId) as
      | SnapshotOut<typeof ChatRoomModel>
      | undefined

    const title = useChatRoomTitle(chatRoom?._id)
    const avatar = useChatRoomAvatar(chatRoom?._id)

    const onChatRoomClick = (): void => {
      isAlive(chatRoom) && chatRoomStore.setSelectedChatRoom(chatRoom?._id)
      customOnClick && customOnClick()
    }

    const selected = chatRoomStore.selectedChatRoom === chatRoom?._id

    const messages = chatRoom
      ? chatMessageStore.getChatRoomMessages(chatRoom._id)
      : []
    const lastMessage = messages.length > 0 ? messages[0] : undefined
    const lastMessageAuthor = lastMessage
      ? userStore.users.get(lastMessage.authorId)
      : undefined
    const lastMessageAuthorIsCurrentUser =
      lastMessageAuthor?._id === authenticationStore.userId

    const lastMessageString = useMemo(() => {
      if (!lastMessage?.message) {
        return null
      }
      const _processedLastMessage = removeMarkdown(
        replaceHtml(lastMessage.message)
      )

      if (lastMessageAuthorIsCurrentUser) {
        return `${translate('common.prefixes.you')}: ${_processedLastMessage}`
      }

      if (lastMessageAuthor) {
        return `${lastMessageAuthor.name}: ${_processedLastMessage}`
      }
      return _processedLastMessage
    }, [
      lastMessage?.message,
      lastMessageAuthor,
      lastMessageAuthorIsCurrentUser,
      translate,
    ])

    const unreadCount = chatRoom?.unreadCount ?? 0

    return (
      <>
        {chatRoom && (
          <Link
            className={`flex justify-between gap-4 break-all  hover:bg-neutral-96
            ${className ?? ''}
            ${selected && 'bg-gray-light'}
          `}
            onClick={onChatRoomClick}
            to={reverseUrl('chat:detail', { id: chatRoom._id })}
          >
            {avatar}
            <div className="flex flex-1 flex-col">
              <p
                style={{ ...theme.textVariants.baseBold }}
                className="line-clamp-1"
              >
                {title}
              </p>
              {lastMessageString && (
                <p
                  style={
                    unreadCount
                      ? theme.textVariants.captionBold
                      : theme.textVariants.caption
                  }
                  className="line-clamp-1"
                >
                  {lastMessageString}
                </p>
              )}
            </div>
            <div className="flex flex-col">
              <div>
                <p
                  style={{ ...theme.textVariants.fineprint }}
                  className="line-clamp-1"
                >
                  {formatRelative(
                    chatRoom.lastMessageDate
                      ? chatRoom.lastMessageDate
                      : Date.parse(chatRoom.createdAt)
                  )}
                </p>
              </div>
              {unreadCount > 0 && (
                <div className="flex h-full items-center justify-end">
                  <NotificationBadge
                    show={unreadCount ? unreadCount > 0 : false}
                    absoluteOffset={false}
                  />
                </div>
              )}
            </div>
          </Link>
        )}
      </>
    )
  }
)
