import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import Fuse from 'fuse.js'
import { SnapshotOut } from 'mobx-state-tree'
import { captureException } from '@sentry/react'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ViewBase } from '../../../../components/common/ViewBase'
import { DocumentsList } from '../../../../components/society/Documents/DocumentsList'
import {
  ButtonProps,
  ButtonVariant,
} from '../../../../components/common/Button'
import { IconChoices } from '../../../../components/common/Icon'
import { useModal } from '../../../../hooks/useModal'
import {
  getSocietyPartnerData,
  isUserAdminInSociety,
  societyHasActivatedPartner,
} from '../../../../helpers/society'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useStores } from '../../../../hooks/useStores'
import { DocumentModel } from '../../../../state/models/document'
import { IllustrationChoices } from '../../../../components/common/Illustration'
import { DocumentType } from '../../../../components/society/Documents/DocumentsList/document_type'
import { CreateUpdateFolderModal } from '../../../../components/society/Documents/CreateUpdateFolderModal'
import { CreateUpdateDocumentModal } from '../../../../components/society/Documents/CreateUpdateDocumentModal'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../../components/common/Toast/toast-type'
import config from '../../../../config'

export const ManagementDocumentsView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { authenticationStore, partnersStore, documentStore } = useStores()
  const { documentId } = useParams()
  const [searchString, setSearchString] = useState('')
  const {
    show: showCreateFolderModal,
    open: openCreateFolderModal,
    close: closeCreateFolderModal,
  } = useModal()
  const {
    show: showCreateDocumentModal,
    open: openCreateDocumentModal,
    close: closeCreateDocumentModal,
  } = useModal()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const { hasFetchedDocumentsOnce } = documentStore
  const { setToastNotification } = useToastNotifications()
  const [createFolderStructureLoading, setCreateFolderStructureLoading] =
    useState(false)

  const rootRoute = 'management:documents'
  const documentRoute = 'management:documents-detail'

  const isAdmin = isUserAdminInSociety(
    society,
    authenticationStore.userId as string
  )

  useEffect(() => {
    society._id && documentStore.getDocumentsForSociety(society._id)
  }, [society._id, documentStore])

  const section = DocumentType.BOARD

  const onChangeSearchString = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    setSearchString(event.currentTarget.value)
  }

  const getRootSubDocuments = (): SnapshotOut<typeof DocumentModel>[] => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const rootDocument = documentStore.getRootDocument(society._id, section)

    if (rootDocument) {
      return documentStore.getAllSubDocuments(rootDocument._id)
    }
    return []
  }

  const getDocuments = (): SnapshotOut<typeof DocumentModel>[] => {
    return documentId
      ? documentStore.getSocietyDocuments(
          society._id,
          documentId,
          undefined,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          section
        )
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        documentStore.getRootDocuments(society._id, section)
  }

  const rootSubDocuments = getRootSubDocuments()
  const documents = getDocuments()

  const isRoot = !documentId
  const currentDocument = documentId
    ? documentStore.documents.get(documentId)
    : undefined

  useDocumentTitle(
    isRoot
      ? reverseDocumentTitle('management:documents', {
          '{{ societyName }}': society.name,
        })
      : reverseDocumentTitle('management:documents-detail', {
          '{{ societyName }}': society.name,
          '{{ documentName }}': currentDocument?.name,
        })
  )

  const getRootDocumentId = (): string => {
    if (isRoot && documents.length > 0) {
      const firstChild = documents[0]
      const { parentId } = firstChild
      return parentId || ''
    }
    return ''
  }

  const rootDocumentId = getRootDocumentId()

  const fuseOptions = {
    threshold: 0.3,
    keys: ['name'],
  }
  const fuse = new Fuse(documents, fuseOptions)

  const searchDocuments = fuse.search(searchString).map((result) => result.item)

  const loading = !hasFetchedDocumentsOnce

  const errorView =
    !loading && rootSubDocuments.length === 0
      ? {
          title: translate('documentsList.emptyState.title'),
          subtitle: translate(
            'documentsList.emptyState.noDocumentsSubtitleBoard'
          ),
          illustration: IllustrationChoices.CHECKLIST,
        }
      : undefined

  const showBostadsratternaFolderStructureErrorMessage = (): void => {
    setToastNotification(
      ToastType.DANGER,
      translate(
        'managementDocumentsView.flashMessage.createBostadsratternaFolderStructureFailure'
      )
    )
  }

  const showBostadsratternaFolderStructureSuccessMessage = (): void => {
    setToastNotification(
      ToastType.SUCCESS,
      translate(
        'managementDocumentsView.flashMessage.createBostadsratternaFolderStructureSuccess'
      )
    )
  }

  const bostadsratternaSociety = societyHasActivatedPartner(
    society,
    config.BOSTADSRATTERNA_PARTNER_ID
  )

  const bostadsratternaPartnerData = getSocietyPartnerData(
    society,
    config.BOSTADSRATTERNA_PARTNER_ID
  )

  const addBostadsratternaFolderStructure = async (): Promise<void> => {
    setCreateFolderStructureLoading(true)
    try {
      const status = await partnersStore.createSocietyFolders(
        society._id,
        config.BOSTADSRATTERNA_PARTNER_ID
      )
      if (status) {
        await documentStore.getDocumentsForSociety(society._id)
        showBostadsratternaFolderStructureSuccessMessage()
      } else {
        showBostadsratternaFolderStructureErrorMessage()
      }
    } catch (error) {
      setCreateFolderStructureLoading(false)
      captureException(error)
      showBostadsratternaFolderStructureErrorMessage()
    }
    setCreateFolderStructureLoading(false)
  }

  const getButtons = (): ButtonProps[] => {
    let buttons: ButtonProps[] =
      bostadsratternaSociety &&
      !bostadsratternaPartnerData?.boardFolderSructureCreated
        ? [
            {
              label: translate(
                'managementDocumentsView.bostadsratternaFolderStructure'
              ),
              variant: ButtonVariant.DEFAULT,
              icon: IconChoices.BOSTADSRATTERNA,
              iconSize: 20,
              onClick: () => addBostadsratternaFolderStructure(),
              loading: createFolderStructureLoading,
            },
          ]
        : []
    buttons = [
      ...buttons,
      {
        label: translate('managementDocumentsView.addNewFolder'),
        variant: ButtonVariant.PRIMARY,
        icon: IconChoices.FOLDER,
        iconSize: 20,
        onClick: () => openCreateFolderModal(),
      },
      {
        label: translate('managementDocumentsView.addNewDocument'),
        variant: ButtonVariant.PRIMARY,
        icon: IconChoices.DOCUMENT_ADD,
        onClick: () => openCreateDocumentModal(),
      },
    ]
    return isAdmin ? buttons : []
  }

  return (
    <ErrorBoundary>
      <ViewBase
        searchBar={{ value: searchString, onChange: onChangeSearchString }}
        title={translate('managementDocumentsView.title')}
        buttons={getButtons()}
        loading={loading}
        errorView={errorView}
      >
        <DocumentsList
          section={section}
          documentId={documentId}
          rootRoute={rootRoute}
          documentRoute={documentRoute}
          rootSubDocuments={rootSubDocuments}
          documents={searchString === '' ? documents : searchDocuments}
          noSearchResults={searchString !== '' && searchDocuments.length === 0}
        />
      </ViewBase>
      <CreateUpdateFolderModal
        show={showCreateFolderModal}
        close={closeCreateFolderModal}
        parentId={isRoot ? rootDocumentId : documentId}
        section={section}
      />
      <CreateUpdateDocumentModal
        show={showCreateDocumentModal}
        close={closeCreateDocumentModal}
        parentId={isRoot ? rootDocumentId : documentId}
        section={section}
        isBoardDocuments
      />
    </ErrorBoundary>
  )
})
