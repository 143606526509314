import React from 'react'
import { observer } from 'mobx-react-lite'
import { CategoryMatch } from './CategoryMatch'
import { theme } from '../../../../theme/theme'
import { SubcategoryMatches } from './SubcategoryMatches'

interface MatchesProps {
  categoryMatches: CategoryMatch[]
}

export const Matches = observer(({ categoryMatches }: MatchesProps) => {
  return (
    <div>
      {categoryMatches.map((_category) => {
        return (
          <div key={_category.category}>
            <p style={theme.textVariants.lead} className="mt-8">
              {_category.category}
            </p>
            <div className="space-y-6">
              {_category.subcategories.map((_subcategory) => {
                return (
                  <SubcategoryMatches
                    key={_subcategory.title}
                    subcategory={_subcategory}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
})
