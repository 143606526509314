import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { PreApprovedModel } from '../../../../state/models/society'
import { SocietyInviteBanner } from './SocietyInviteBanner'

interface SocietyInvitesProps {
  preApproved: SnapshotOut<typeof PreApprovedModel>[]
}

export const SocietyInvites = ({
  preApproved,
}: SocietyInvitesProps): JSX.Element | null => {
  return (
    <div className="w-full space-y-1">
      {preApproved.map((_preApproved) => {
        return (
          <SocietyInviteBanner
            key={_preApproved._id}
            preApproved={_preApproved}
          />
        )
      })}
    </div>
  )
}
