import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { queryKeys } from '../../api/services'
import { useLocale } from '../../hooks/useLocale'
import { theme } from '../../theme/theme'
import { Icon, IconChoices } from '../../components/common/Icon'
import { reverseUrl } from '../../navigation/reverseUrl'
import ServiceTitle from './Title'
import { Spinner } from '../../components/common/Spinner'
import { useCurrentSociety } from '../../hooks/useCurrentSociety'

const ServicesView = (): JSX.Element => {
  const { data } = useQuery({ ...queryKeys.providers(), throwOnError: true })
  const providers = data?.data.data
  const locale = useLocale()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }

  return (
    <>
      <ServiceTitle />
      <ul>
        {!providers && (
          <div className="grid place-items-center">
            <Spinner />
          </div>
        )}
        {providers?.map((provider) => {
          return (
            <li key={provider.id}>
              <Link
                to={reverseUrl('society:services-service', {
                  service: provider.id,
                  id: society._id,
                })}
                className="w-full mb-2 overflow-hidden rounded-md shadow-md border block"
              >
                <div className="flex gap-2">
                  <img
                    className="w-40 object-cover object-center hidden sm:block"
                    src={provider.imageUrl}
                    alt={
                      locale === 'en' ? provider.title.en : provider.title.sv
                    }
                  />
                  <div className="w-full">
                    <div className="flex justify-between p-2">
                      <img
                        className="w-20 w-20 h-8 object-scale-down object-left"
                        src={provider.logoUrl}
                        alt={
                          locale === 'en'
                            ? provider.title.en
                            : provider.title.sv
                        }
                      />
                      <p style={theme.textVariants.fineprint}>
                        {locale === 'en' ? provider.tag.en : provider.tag.sv}
                      </p>
                    </div>
                    <div className="flex w-full p-2 gap-4 justify-between">
                      <div>
                        <p style={theme.textVariants.baseBold}>
                          {locale === 'en'
                            ? provider.title.en
                            : provider.title.sv}
                        </p>
                        <p
                          style={theme.textVariants.base}
                          className="text-neutral-40"
                        >
                          {locale === 'en'
                            ? provider.description.en
                            : provider.description.sv}
                        </p>
                      </div>
                      <Icon
                        flexItems="items-start"
                        icon={IconChoices.ARROW_RIGHT}
                      />
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export { ServicesView }
