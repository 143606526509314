import React from 'react'
import { TableColumn, TableHeader } from './TableHeader'
import { TableRow, TableRows } from './TableRows'

interface TableProps {
  columns: TableColumn[]
  rows: TableRow[]
}

export const Table = ({ columns, rows }: TableProps): JSX.Element | null => {
  return (
    <div className="h-full w-full overflow-x-auto pr-4 pb-2">
      <div className="min-w-max">
        <table className="h-full w-full table-auto">
          <TableHeader columns={columns} />
          <TableRows columns={columns} rows={rows} />
        </table>
      </div>
    </div>
  )
}
