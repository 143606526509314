/* eslint-disable no-param-reassign */
import { SnapshotOut, flow, types } from 'mobx-state-tree'
import { captureException } from '@sentry/react'
import {
  NStatus,
  getStatusMessages as apiGetStatusMessages,
} from '../../api/status'
import { stateType } from '../types/common'
import { StatusMessageModel } from '../models/status-message'

export const StatusStore = types
  .model('StatusStore')
  .props({
    statusMessages: types.array(StatusMessageModel),
    fetchingStatusMessages: stateType,
  })
  .views((self) => ({
    get nonAuthenticatedMessages(): SnapshotOut<typeof StatusMessageModel>[] {
      return self.statusMessages.filter(
        (_message) => _message.display_in_app === false
      )
    },
    get authenticatedMessages(): SnapshotOut<typeof StatusMessageModel>[] {
      return self.statusMessages.filter(
        (_message) => _message.display_in_app === true
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.fetchingStatusMessages = 'none'
    },
  }))
  .actions((self) => ({
    getStatusMessages: flow(function* getStatusMessages() {
      self.fetchingStatusMessages = 'pending'
      try {
        const resp = yield apiGetStatusMessages()
        const data = resp.data as NStatus.NGetMessages.IResponse

        self.statusMessages.replace(data.data)

        self.fetchingStatusMessages = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.fetchingStatusMessages = 'error'
        return false
      }
    }),
  }))
