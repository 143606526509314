import { useCallback } from 'react'
import { reverseUrl } from '../../navigation/reverseUrl'
import { ActiveTab } from './SettingsView'

type useSettingsTabsReturnType = {
  getTabUrl: (tab: ActiveTab) => string
  getTabState: (pathname: string) => ActiveTab
}

export const useSettingsTabs = (): useSettingsTabsReturnType => {
  const getTabUrl = useCallback((tab: ActiveTab): string => {
    switch (tab) {
      case ActiveTab.PROFILE_SETTINGS:
        return reverseUrl('settings-account')
      case ActiveTab.MY_INFO:
        return reverseUrl('settings-my-info')
      case ActiveTab.CHANGE_LANGUAGE:
        return reverseUrl('settings-language')
      case ActiveTab.NOTIFICATION_SETTINGS:
        return reverseUrl('settings-notifications')
      case ActiveTab.ADMIN_TOOLS:
        return reverseUrl('settings-superadmin')
      default:
        return reverseUrl('settings-account')
    }
  }, [])

  const getTabState = useCallback((pathname: string): ActiveTab => {
    if (pathname === reverseUrl('settings-account')) {
      return ActiveTab.PROFILE_SETTINGS
    }
    if (pathname === reverseUrl('settings-my-info')) {
      return ActiveTab.MY_INFO
    }
    if (pathname === reverseUrl('settings-language')) {
      return ActiveTab.CHANGE_LANGUAGE
    }
    if (pathname === reverseUrl('settings-notifications')) {
      return ActiveTab.NOTIFICATION_SETTINGS
    }
    if (pathname === reverseUrl('settings-superadmin')) {
      return ActiveTab.ADMIN_TOOLS
    }
    return ActiveTab.PROFILE_SETTINGS
  }, [])

  return {
    getTabUrl,
    getTabState,
  }
}
