import { AxiosResponse } from 'axios'
import { NResources } from '../interfaces/services/resources.interfaces'
import { createUrl } from './helpers'
import axiosAuth from './config'

export const RESOURCES_POPULATE = ['media']

export const getResourcesPerSociety = (
  societyId: string
): Promise<AxiosResponse<NResources.NGetPerSocieties.IResponse>> => {
  const body: NResources.NGetPerSocieties.IRequestBody = {
    societyIds: [societyId],
  }
  return axiosAuth.post<NResources.NGetPerSocieties.IResponse>(
    createUrl('/resources/get-per-societies', {
      populate: RESOURCES_POPULATE.join(';'),
    }),
    body
  )
}

export const getResource = (
  id: string
): Promise<AxiosResponse<NResources.NGetById.IResponse>> => {
  return axiosAuth.get<NResources.NGetById.IResponse>(
    createUrl(`/resources/resource/${id}`, {
      populate: RESOURCES_POPULATE.join(';'),
    })
  )
}

export const patchResource = (
  id: string,
  body: NResources.NPatch.IRequestBody
): Promise<AxiosResponse<NResources.NPatch.IResponse>> => {
  return axiosAuth.patch<NResources.NPatch.IResponse>(
    createUrl(`/resources/resource/${id}`),
    body
  )
}

export const createResource = (
  body: NResources.NCreate.IRequestBody
): Promise<AxiosResponse<NResources.NCreate.IResponse>> => {
  return axiosAuth.post<NResources.NCreate.IResponse>(
    createUrl(`/resources/resource`),
    body
  )
}

export const deleteResource = (
  id: string
): Promise<AxiosResponse<NResources.NDel.IResponse>> => {
  return axiosAuth.delete<NResources.NDel.IResponse>(
    createUrl(`/resources/resource/${id}`)
  )
}
