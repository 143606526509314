import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { EventModel } from '../../../state/models/event'
import { BoardRoomEventModel } from '../../../state/models/boardroom-event'
import { Icon, IconChoices } from '../../../components/common/Icon'
import { theme } from '../../../theme/theme'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useEventUserAttendance } from './useEventUserAttendance'
import { Button } from '../../../components/common/Button'
import { EventDetailViewTabs } from './EventDetailView'
import { EventDetailRow } from './EventDetailInformation'
import { EventAttendanceButtons } from './EventAttendanceButtons'

interface EventAttendancePreviewProps {
  event:
    | SnapshotOut<typeof EventModel>
    | SnapshotOut<typeof BoardRoomEventModel>
  boardRoomEvent: boolean | undefined
  setActiveTab: (tab: EventDetailViewTabs) => void
}

export const EventAttendancePreview = observer(
  ({
    event,
    boardRoomEvent,
    setActiveTab,
  }: EventAttendancePreviewProps): JSX.Element => {
    const { translate } = useAppTranslation()

    const { attending, notAttending } = useEventUserAttendance(
      event?.attendance
    )

    const previewLimit = 8
    const attendantsPreview = [...attending, ...notAttending].slice(
      0,
      previewLimit
    )

    return (
      <EventDetailRow
        title={`${translate('eventDetailView.headers.attendants')} (${
          attending.length || 0
        })`}
        content={
          <div className="flex flex-col gap-4">
            {attendantsPreview.length > 0 && (
              <div className="flex flex-col gap-2">
                {attendantsPreview.map((attendant) => (
                  <div
                    key={attendant.user._id}
                    className="flex items-center gap-1"
                  >
                    <Icon
                      size={16}
                      color={
                        attendant.status === 'attending'
                          ? theme.colors.brandGreen
                          : theme.colors.red
                      }
                      icon={
                        attendant.status === 'attending'
                          ? IconChoices.CHECK_CIRLE
                          : IconChoices.CLOSE
                      }
                    />
                    <p>{attendant.user.fullName}</p>
                  </div>
                ))}
                {attending.length > previewLimit && (
                  <Button
                    label={translate(
                      'eventDetailView.buttons.seeAllAttendants'
                    )}
                    onClick={() => setActiveTab(EventDetailViewTabs.ATTENDANTS)}
                  />
                )}
              </div>
            )}
            <EventAttendanceButtons
              event={event}
              boardRoomEvent={boardRoomEvent}
            />
          </div>
        }
      />
    )
  }
)
