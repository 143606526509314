import { SnapshotOut } from 'mobx-state-tree'
import { useCallback, useEffect } from 'react'
import { useStores } from './useStores'
import { useAuthenticatedUserId } from './useAuthenticatedUserId'
import { UserModel } from '../state/models/user'
import { PreApprovedModel } from '../state/models/society'

export const usePreApprovedList = (): SnapshotOut<
  typeof PreApprovedModel
>[] => {
  const { societyStore, userStore } = useStores()
  const userId = useAuthenticatedUserId() as string

  const user = userStore.users.get(userId) as SnapshotOut<typeof UserModel>

  useEffect(() => {
    const fetchUserSocietyInvites = async (): Promise<void> => {
      if (userId && societyStore.fetchingPreApprovedSocieties !== 'pending') {
        await societyStore.getPreApprovedSocieties(userId)
      }
    }
    // Ensure that current user exists as this data is required to show
    // the user initials avatar.
    // If the feed is empty, user is never fetched as it isn't fetched via populate.
    fetchUserSocietyInvites()
  }, [societyStore, userId])

  const getPreApprovedList = useCallback(() => {
    return user && user.email ? societyStore.getUserPreApproved(user.email) : []
  }, [societyStore, user])

  const preApprovedList = getPreApprovedList()

  return preApprovedList
}
