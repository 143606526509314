import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { InferType } from 'yup'
import { chatMessageSchema } from '../../../../forms/schemas/chat_message'
import { Icon, IconChoices } from '../../../common/Icon'

interface MessageBarUploadProps {
  control: Control<InferType<typeof chatMessageSchema>>
  imageUploadDisabled: boolean
  onUploadImage: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const MessageBarUpload = ({
  control,
  imageUploadDisabled,
  onUploadImage,
}: MessageBarUploadProps): JSX.Element => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onUploadImage(e)
  }

  return (
    <div className="mb-[1px] flex self-end p-2 hover:bg-gray-light">
      <label htmlFor="chat-image-input">
        <Icon icon={IconChoices.IMAGE} size={26} />
      </label>
      <Controller
        control={control}
        name="mediaIds"
        render={() => (
          <input
            id="chat-image-input"
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            className="w-0 max-w-0 opacity-0"
            tabIndex={0}
            multiple
            disabled={imageUploadDisabled}
            onChange={onChange}
          />
        )}
      />
    </div>
  )
}
