import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useAtom } from 'jotai'
import { SwitchTabBar } from '../../common/SwitchTabBar'
import { ChatRooms } from '../ChatRooms'
import { useAdminOrBoardMemberInAtLeastOneSociety } from './useAdminOrBoardMemberInAtLeastOneSociety'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { TabBar } from '../../common/TabBar'
import { ChatHeaderButtons } from '../ChatHeaderButtons'
import { useTabBarOptions } from './useTabBarOptions'
import { ChatHeaderState, chatHeaderStateAtom } from '../atom'
import { ChatFilter } from '../ChatFilter/ChatFilter'
import { useStores } from '../../../hooks/useStores'

export enum ActiveTabBar {
  RESIDENT = 0,
  BOARD = 1,
}

export enum ActiveTabResident {
  CHAT = 0,
  ASK_BOARD = 1,
}

export enum ActiveTabBoard {
  BOARD_CHAT = 0,
  ANSWER_RESIDENT = 1,
}

interface ChatSideBarContentProps {
  chatDisabled: boolean
}

export const ChatSideBarContent = observer(
  ({ chatDisabled }: ChatSideBarContentProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { notificationsStore } = useStores()
    const [, setChatHeaderState] = useAtom(chatHeaderStateAtom)

    const ActiveTabTitle = {
      0: translate('chatRoomsSidebar.activeTabBar.resident'),
      1: translate('chatRoomsSidebar.activeTabBar.board'),
    }
    const [activeTabBar, setActiveTabBar] = useState<ActiveTabBar>(
      ActiveTabBar.RESIDENT
    )
    const [activeTab, setActiveTab] = useState<
      ActiveTabResident | ActiveTabBoard
    >(!chatDisabled ? ActiveTabResident.CHAT : ActiveTabResident.ASK_BOARD)

    const [activeTabIndex, setActiveTabIndex] = useState(0)

    useEffect(() => {
      if (
        chatDisabled &&
        activeTabIndex === 0 &&
        activeTabBar === ActiveTabBar.RESIDENT
      ) {
        setActiveTab(ActiveTabResident.ASK_BOARD)
      } else {
        setActiveTab(activeTabIndex)
      }
    }, [activeTabBar, activeTabIndex, chatDisabled])

    const isAdminOrBoardMemberInAtLeastOneSociety =
      useAdminOrBoardMemberInAtLeastOneSociety()
    const { getTabs } = useTabBarOptions(activeTabBar, chatDisabled)

    useEffect(() => {
      setActiveTabIndex(0)
    }, [activeTabBar, chatDisabled])

    const resetStartChatAtoms = (): void => {
      setChatHeaderState(ChatHeaderState.None)
    }

    const getUnseenCountString = (type: 'resident' | 'board'): string => {
      const _count =
        type === 'resident'
          ? notificationsStore.unseenResidentChatCount
          : notificationsStore.unseenBoardCount

      if (_count > 0) {
        return ` (${_count})`
      }

      return ''
    }

    return (
      <div className="flex h-full flex-col space-y-6 pt-8">
        {isAdminOrBoardMemberInAtLeastOneSociety && (
          <>
            <SwitchTabBar
              tabs={[
                {
                  title: `${ActiveTabTitle[ActiveTabBar.RESIDENT]}${getUnseenCountString('resident')}`,
                },
                {
                  title: `${ActiveTabTitle[ActiveTabBar.BOARD]}${getUnseenCountString('board')}`,
                },
              ]}
              activeTabBar={activeTabBar}
              setActiveTabBar={setActiveTabBar}
              customOnClick={resetStartChatAtoms}
            />
          </>
        )}
        <TabBar
          activeTab={activeTabIndex}
          setActiveTab={setActiveTabIndex}
          tabs={getTabs()}
          disableScroll
          className="relative"
        >
          <ChatFilter />
        </TabBar>
        <ChatHeaderButtons activeTabBar={activeTabBar} activeTab={activeTab} />
        <ChatRooms activeTabBar={activeTabBar} activeTab={activeTab} />
      </div>
    )
  }
)
