import React from 'react'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { theme } from '../../../theme/theme'
import { Button } from '../Button'
import { IconChoices } from '../Icon'
import { PostDateTime } from './PostDateTime'

interface MediaGalleryModalHeaderProps {
  onClose: () => void
  mediaIds: string[]
  mediaGalleryIndex: number
  createdAt: string | undefined
}

export const MediaGalleryModalHeader = ({
  onClose,
  mediaIds,
  mediaGalleryIndex,
  createdAt,
}: MediaGalleryModalHeaderProps): JSX.Element => {
  const { translate } = useAppTranslation()
  return (
    <div className="flex flex-shrink-0 items-center justify-between p-6 md:px-12 md:py-8">
      <div>
        {mediaIds.length > 1 && (
          <p style={theme.textVariants.caption} className="m-0">
            {translate('mediaGallery.imageGalleryPositionLabel')}{' '}
            {mediaGalleryIndex + 1}/{mediaIds.length}
          </p>
        )}
      </div>
      {createdAt && <PostDateTime createdAt={createdAt} />}
      <Button
        className="h-12 w-12 border-none bg-neutral-96 opacity-75 hover:opacity-100"
        size="no-padding"
        iconSize={24}
        icon={IconChoices.CLOSE_CROSS}
        onClick={onClose}
      />
    </div>
  )
}
