import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { FormControl } from '../../../common/FormControl'
import { SelectDropdown } from '../../../common/SelectDropdown'
import { useUniqueNonAdminUsers } from '../../../../hooks/useUniqueNonAdminUsers'
import { societyBlockCreateSchema } from '../../../../forms/schemas/society_block_create'

interface CreateUpdateBlockedUserFormProps {
  onError: (error: unknown) => void
  onSubmit: (data: InferType<typeof societyBlockCreateSchema>) => Promise<void>
  onClose: () => void
  loading: boolean
}

export const CreateUpdateBlockedUserForm = observer(
  ({
    loading,
    onError,
    onSubmit,
    onClose,
  }: CreateUpdateBlockedUserFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const uniqueNonAdminUsers = useUniqueNonAdminUsers(society)
    const blockedUserIds = new Set(
      (society?.blockList || []).map((user) => user.userId)
    )

    const userOptions = uniqueNonAdminUsers
      .filter((user) => !blockedUserIds.has(user._id)) // Exclude users in blockList
      .map((user) => ({
        value: user._id,
        label: user.fullName,
      }))

    const getDefaultValues = useCallback((): InferType<
      typeof societyBlockCreateSchema
    > => {
      return {
        userIds: [],
      }
    }, [])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyBlockCreateSchema),
      defaultValues: getDefaultValues(),
    })

    return (
      <>
        <Controller
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <FormControl
              label={translate('createUpdateBlockedUserForm.labels.users')}
              name={name}
              error={errors.userIds && errors.userIds.message}
            >
              <SelectDropdown
                value={value}
                onChange={onChange}
                options={userOptions}
                error={!!errors.userIds}
                isMulti
                menuListHeight={200}
              />
            </FormControl>
          )}
          name="userIds"
        />
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-3">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
            variant={ButtonVariant.TEXT}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid || loading}
            label={translate('common.actions.save')}
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </>
    )
  }
)
