import React, { useEffect } from 'react'
import { captureException } from '@sentry/react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { Modal } from '../../../../../components/common/Modal'
import { ModalHeader } from '../../../../../components/common/Modal/ModalHeader'
import { ModalBody } from '../../../../../components/common/Modal/ModalBody'
import { useStores } from '../../../../../hooks/useStores'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../../components/common/Toast/toast-type'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { SetMasterAdminModalContent } from './SetMasterAdminModalContent'
import { adminSetMasterAdminSchema } from '../../../../../forms/schemas/admin_set_master_admin'
import { useAdminSociety } from '../useAdminSociety'

interface SetMasterAdminModalProps {
  show: boolean
  close: () => void
  societyId?: string
  adminList?: string[]
}

export const SetMasterAdminModal = observer(
  ({
    show,
    close,
    societyId,
    adminList,
  }: SetMasterAdminModalProps): JSX.Element => {
    const { adminStore, userStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()
    const { adminSociety, triggerRefresh } = useAdminSociety(societyId)

    const onSubmit = async (
      data: InferType<typeof adminSetMasterAdminSchema>
    ): Promise<void> => {
      const status =
        societyId && (await adminStore.patchSociety({ societyId, data }))

      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('Master admin uppdaterad')
        )
        triggerRefresh()
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }
      close()
    }

    const onError = (error: unknown): void => {
      captureException(error)
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    useEffect(() => {
      if (adminList) {
        adminList.forEach((adminId) => {
          userStore.getUser(adminId)
        })
      }
    }, [adminList, userStore])

    return (
      <Modal show={show}>
        {{
          header: (
            <ModalHeader onClose={close}>Uppdatera master admin</ModalHeader>
          ),
          body: (
            <ModalBody className="max-h-96 overflow-y-auto">
              {adminSociety && (
                <SetMasterAdminModalContent
                  onSubmit={onSubmit}
                  onError={onError}
                  onClose={close}
                  society={adminSociety}
                  adminsList={adminList}
                />
              )}
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
