import React from 'react'

interface LabelProps {
  label: string
  name: string
  className?: string
  style?: React.CSSProperties
}

export const Label = ({
  label,
  name,
  className,
  style,
}: LabelProps): JSX.Element => {
  return (
    <label className={className} style={style} htmlFor={name}>
      {label}
    </label>
  )
}
