import React from 'react'
import { Navigate } from 'react-router-dom'
import { reverseUrl } from '../../reverseUrl'
import { useAuth } from '../../../hooks/useAuth'

interface AuthenticatedRouteProps {
  children: React.ReactNode
}

const AuthenticatedRoute = ({
  children,
}: AuthenticatedRouteProps): JSX.Element | null => {
  const { userId, userIsVerified } = useAuth()

  if (!userId) {
    return <Navigate to={reverseUrl('login')} />
  }

  if (!userIsVerified && typeof userIsVerified !== 'undefined') {
    return <Navigate to={reverseUrl('verify-email')} />
  }

  return <>{children}</>
}

AuthenticatedRoute.displayName = 'AuthenticatedRoute'
export { AuthenticatedRoute }
