import { AxiosResponse } from 'axios'
import { createUrl } from './helpers'
import axiosAuth from './config'
import { NSocietiesWebsites } from '../interfaces/services/societies-websites.interfaces'

export const WEBSITES_POPULATE = ['media', 'users', 'user.media', 'documents']

export const getWebsitePerSociety = (
  societyId: string
): Promise<AxiosResponse<NSocietiesWebsites.NGetPerSocieties.IResponse>> => {
  return axiosAuth.get<NSocietiesWebsites.NGetPerSocieties.IResponse>(
    createUrl(`/societies-websites/get-per-society/${societyId}`, {
      populate: WEBSITES_POPULATE.join(';'),
    })
  )
}

export const patchWebsite = (
  id: string,
  body: NSocietiesWebsites.NPatch.IRequestBody
): Promise<AxiosResponse<NSocietiesWebsites.NPatch.IResponse>> => {
  return axiosAuth.patch<NSocietiesWebsites.NPatch.IResponse>(
    createUrl(`/societies-websites/website/${id}`),
    body
  )
}
