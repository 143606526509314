import { addHours, addMilliseconds, addMinutes, getUnixTime } from 'date-fns'
import { SnapshotOut } from 'mobx-state-tree'
import { dateTimeAsUTCWithoutCompensation } from '../../../../helpers/date'
import { useStores } from '../../../../hooks/useStores'
import { FacilityModel } from '../../../../state/models/facility'
import { ITimeSlot } from '../FacilityCalendar/interfaces'
import { getNumberOfBookingRows } from './helpers'

export const useTimeSlots = (
  selectedDate: Date,
  facility: SnapshotOut<typeof FacilityModel>
): ITimeSlot[] => {
  const { bookingsStore } = useStores()
  if (!facility.weeklySchedule || facility.weeklySchedule.length !== 7) {
    return []
  }

  const bookings = bookingsStore.bookingsForFacility(facility._id)

  // getDay() returns startOfWeek as sunday, hence the modulus.
  // Get day of week, Monday = 0, Sunday = 6
  const dayOfWeekIndex = (selectedDate.getDay() + 6) % 7

  const dailySchedule = facility.weeklySchedule[dayOfWeekIndex]

  if (!dailySchedule.allowed) {
    return []
  }

  const fullDayBookingGranularity = facility.bookingGranularity === 'full-day'
  const bookingGranularity: number =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    facility.getBookingGranularityHours()

  const _scheduleStartTime = new Date(dailySchedule.startTime)
  const _scheduleEndTime = new Date(dailySchedule.endTime)

  const startOfDay = (date: Date): Date => {
    const _date = new Date(date)

    _date.setHours(0)
    _date.setMinutes(0)
    _date.setSeconds(0)
    _date.setMilliseconds(0)

    return _date
  }

  const slots = Array(
    getNumberOfBookingRows(
      _scheduleStartTime,
      _scheduleEndTime,
      bookingGranularity,
      fullDayBookingGranularity
    )
  )
    .fill(0)
    .map((__, timeSlotIndex) => {
      const startDateAndTime = addMinutes(
        addHours(
          dateTimeAsUTCWithoutCompensation(startOfDay(selectedDate)),
          _scheduleStartTime.getUTCHours() + bookingGranularity * timeSlotIndex
        ),
        _scheduleStartTime.getUTCMinutes()
      )

      const getFullDayBookingGranularityEndDateAndTime = (): Date => {
        const endDateAndTime = new Date(startDateAndTime)
        endDateAndTime.setHours(23)
        endDateAndTime.setMinutes(59)
        endDateAndTime.setSeconds(59)
        endDateAndTime.setMilliseconds(59)
        return endDateAndTime
      }

      const endDateAndTime = fullDayBookingGranularity
        ? getFullDayBookingGranularityEndDateAndTime()
        : addMilliseconds(addHours(startDateAndTime, bookingGranularity), -1)

      const booking = bookings.find((_booking) => {
        return (
          getUnixTime(new Date(_booking.startDate)) ===
          getUnixTime(startDateAndTime)
        )
      })

      const timeSlot: ITimeSlot = {
        startDate: startDateAndTime,
        endDate: endDateAndTime,
        bookingUserId: booking?.userId,
      }
      return timeSlot
    })
    .filter(
      (_slot) => _slot.endDate >= dateTimeAsUTCWithoutCompensation(new Date())
    )

  return slots
}
