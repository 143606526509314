import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { SpinnerWrapper } from '../../common/SpinnerWrapper'
import { PremiumSection } from './PremiumSection'
import { AccordionSection } from '../../common/AccordionSection'
import { useAccordionSections } from './useAccordionSections'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { BasicSection } from './BasicSection'
import { PaymentModal } from './PaymentModal'
import { useSocietyPremiumCost } from './useSocietyPremiumCost'
import { useModal } from '../../../hooks/useModal'
import { DiscountCodeModal } from './DiscountCodeModal'
import { SocietyTypes } from '../../../types/society-type'
import { Illustration, IllustrationChoices } from '../../common/Illustration'
import { theme } from '../../../theme/theme'

interface PaymentDetails {
  loading: boolean
}

export const PaymentDetails = observer(
  ({ loading }: PaymentDetails): JSX.Element => {
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { translate } = useAppTranslation()
    const { costSummary, fetchingCost } = useSocietyPremiumCost(society._id)
    const accordionSectionItems = useAccordionSections(society)
    const [discountCodePartnerNameId, setDiscountCodePartnerNameId] = useState<
      undefined | string
    >(undefined)
    const { billingEmail } = society
    const {
      show: showPaymentModal,
      open: openPaymentModal,
      close: closePaymentModal,
    } = useModal()
    const {
      show: showDiscountCodeModal,
      open: openDiscountCodeModal,
      close: closeDiscountCodeModal,
    } = useModal()

    if (loading) {
      return <SpinnerWrapper />
    }

    const isNeighbourhood =
      society.societyTypeDisplayName === SocietyTypes.NEIGHBOURHOOD

    return (
      <>
        <div className="w-full">
          {!isNeighbourhood ? (
            <>
              {society.accountType === 'premium' ? (
                <PremiumSection openDiscountCodeModal={openDiscountCodeModal} />
              ) : (
                <BasicSection
                  openPaymentModal={openPaymentModal}
                  openDiscountCodeModal={openDiscountCodeModal}
                  cost={costSummary?.cost}
                  fetchingCost={fetchingCost}
                  billingEmail={billingEmail}
                  society={society}
                />
              )}
            </>
          ) : (
            <div className="flex items-center gap-6">
              <Illustration
                illustrationChoice={IllustrationChoices.SUN}
                width={100}
              />
              <p style={theme.textVariants.lead}>
                {translate('invoiceView.neighbourhood.title')}
              </p>
            </div>
          )}
          <AccordionSection
            className="mt-10"
            title={translate('invoiceView.accordions.title')}
            items={accordionSectionItems}
          />
        </div>
        <PaymentModal
          show={showPaymentModal}
          close={closePaymentModal}
          discountCodePartnerNameId={discountCodePartnerNameId}
        />
        <DiscountCodeModal
          show={showDiscountCodeModal}
          setDiscountCodePartnerNameId={setDiscountCodePartnerNameId}
          openPaymentModal={openPaymentModal}
          close={closeDiscountCodeModal}
        />
      </>
    )
  }
)
