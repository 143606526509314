import React from 'react'
import { castToSnapshot, SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import {
  PollModel,
  PollOptionModel,
  PollVotesType,
} from '../../../state/models/poll'
import { ProgressBar } from '../../common/ProgressBar'
import { useStores } from '../../../hooks/useStores'

interface ResultBarProps {
  option: SnapshotOut<typeof PollOptionModel>
  poll: SnapshotOut<typeof PollModel>
}

export const ResultBar = observer(
  ({ option, poll }: ResultBarProps): JSX.Element => {
    const { authenticationStore } = useStores()

    const votesForOption = (): SnapshotOut<typeof PollVotesType> => {
      if (poll.votes) {
        return poll.votes.filter((_vote) => {
          const _option = castToSnapshot(_vote.option) as SnapshotOut<
            typeof PollOptionModel
          >
          return _option._id === option._id
        })
      }
      return []
    }

    const percentageVotesForOption = (): number => {
      if (poll.votes) {
        const percentage = (votesForOption().length / poll.votes.length) * 100
        if (Number.isNaN(percentage)) {
          return 0
        }
        return percentage
      }
      return 0
    }

    const userVotedForOption = (): boolean => {
      return votesForOption()
        .map((_option) => _option.userId)
        .includes(authenticationStore.userId as string)
    }

    return (
      <ProgressBar
        height="h-[50px]"
        backgroundColor="bg-brandGreen/60"
        percentage={percentageVotesForOption()}
        text={option.text}
        animate={userVotedForOption()}
        duration={800}
      />
    )
  }
)
