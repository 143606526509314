import * as yup from 'yup'
import { unitRoleOptions } from './helpers'

export const societyPreApprovedCreateSchema = yup
  .object({
    societyId: yup.string().required(),
    userEmail: yup.string().email().required(),
    entranceId: yup.string().required(),
    unitIdOrTitle: yup.string().required(),
    unitRole: yup.string().oneOf(unitRoleOptions).required(),
  })
  .required()
