import React from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { SnapshotOut } from 'mobx-state-tree'
import { useDrag } from 'react-dnd'
import { ItemType } from '../../item-type'
import { IconChoices } from '../../../../common/Icon'
import { openUrl, parseUrlWhitespaces } from '../../../../../helpers/url'
import { ViewNames, reverseUrl } from '../../../../../navigation/reverseUrl'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { DocumentModel } from '../../../../../state/models/document'
import { useAuthenticatedUserId } from '../../../../../hooks/useAuthenticatedUserId'
import { useStores } from '../../../../../hooks/useStores'
import { useCurrentSociety } from '../../../../../hooks/useCurrentSociety'
import { isUserAdminInSociety } from '../../../../../helpers/society'
import { DocumentListRowItem } from './DocumentListRowItem'
import { DocumentListCardItem } from './DocumentListCardItem'

interface DocumentsListItemDraggableProps {
  document: SnapshotOut<typeof DocumentModel>
  documentRoute: ViewNames
  openEditFolderModal: () => void
  openEditDocumentModal: () => void
  openRemoveConfirmationModal: () => void
  isListMode: boolean
  isOver?: boolean
}

export const DocumentsListItemDraggable = observer(
  ({
    document,
    documentRoute,
    openEditFolderModal,
    openEditDocumentModal,
    openRemoveConfirmationModal,
    isListMode,
    isOver,
  }: DocumentsListItemDraggableProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { documentStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const userId = useAuthenticatedUserId() as string
    const navigate = useNavigate()

    const getType = (): ItemType => {
      switch (document.type) {
        case 'folder':
          return ItemType.FOLDER
        default:
          return ItemType.DOCUMENT
      }
    }

    const documentType = getType()

    const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
      type: documentType,
      item: document,
      collect: (monitor) => ({
        item: monitor.getItem(),
        isDragging: monitor.isDragging(),
      }),
    }))

    const childDocuments = documentStore.getChildDocuments(document._id)

    const isAdmin = isUserAdminInSociety(society, userId)

    const icon =
      documentType === ItemType.FOLDER
        ? IconChoices.FOLDER
        : IconChoices.DOCUMENT

    const isFolder = documentType === ItemType.FOLDER

    const openFolder = (): void => {
      if (isFolder) {
        navigate(
          reverseUrl(documentRoute, {
            id: society._id,
            documentId: document._id,
          })
        )
      }
    }

    const openFile = (): void => {
      if (!isFolder && document.url) {
        openUrl(parseUrlWhitespaces(document.url))
      }
    }

    const openItem = (): void => {
      if (isFolder) {
        openFolder()
      }
      if (!isFolder) {
        openFile()
      }
    }

    const dropdownOptions = [
      {
        value: 'edit',
        label: translate('common.actions.edit'),
        onClick: () =>
          isFolder ? openEditFolderModal() : openEditDocumentModal(),
      },
      {
        value: 'remove',
        label: translate('common.actions.delete'),
        onClick: async () => openRemoveConfirmationModal(),
        destructive: true,
      },
    ]

    const getDocumentSubTitleText = (): string => {
      const numFolders = childDocuments.filter(
        (_document) => _document.type === 'folder'
      ).length
      const numFiles = childDocuments.length - numFolders

      const items = []
      if (numFolders > 0) {
        items.push(
          `${numFolders} ${
            numFolders === 1
              ? translate('documentsList.document.folderSingular')
              : translate('documentsList.document.folderPlural')
          }`
        )
      }
      if (numFiles > 0) {
        items.push(
          `${numFiles} ${
            numFolders === 1
              ? translate('documentsList.document.documentSingular')
              : translate('documentsList.document.documentPlural')
          }`
        )
      }

      return items.join(', ')
    }

    const documentSubtitle = getDocumentSubTitleText()

    return (
      <>
        {isListMode ? (
          <DocumentListRowItem
            isAdmin={isAdmin}
            openItem={openItem}
            documentTitle={document.name || ''}
            documentSubtitle={documentSubtitle}
            documentIcon={icon}
            dropdownOptions={dropdownOptions}
            isDragging={isDragging}
            dragPreview={dragPreview}
            drag={drag}
            isFolder={isFolder}
            isOver={isOver}
          />
        ) : (
          <DocumentListCardItem
            isAdmin={isAdmin}
            openItem={openItem}
            documentTitle={document.name || ''}
            documentSubtitle={documentSubtitle}
            documentIcon={icon}
            dropdownOptions={dropdownOptions}
            isDragging={isDragging}
            dragPreview={dragPreview}
            drag={drag}
            isOver={isOver}
          />
        )}
      </>
    )
  }
)
