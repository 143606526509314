import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { LocalOfferModel } from '../../../../state/models/local-offer'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { PostNavigationItem } from '../PostNavigationItem'
import { PostCallout } from '../PostCallout'

interface PostLocalOffersProps {
  localOffers?: SnapshotOut<typeof LocalOfferModel>[]
}

export const PostLocalOffers = observer(
  ({ localOffers }: PostLocalOffersProps): JSX.Element | null => {
    const { translate } = useAppTranslation()

    if (!localOffers || localOffers.length === 0) {
      return null
    }

    return (
      <div className="flex flex-col gap-4">
        <PostCallout text={translate('post.newLocalOfferText')} />
        {localOffers.map((_localOffer) => (
          <PostNavigationItem
            key={_localOffer._id}
            text={`${translate('localOfferView.title')}: ${
              _localOffer.companyName
            } - ${_localOffer.discountHeader}`}
            url={reverseUrl('society:local-offers-detail', {
              id: _localOffer.societyId,
              offerId: _localOffer._id,
            })}
          />
        ))}
      </div>
    )
  }
)
