import { SnapshotOut } from 'mobx-state-tree'
import {
  isUserAdminInSociety,
  isUserBoardMemberInSociety,
} from '../helpers/society'
import { useAuthenticatedUserId } from './useAuthenticatedUserId'
import { SocietyModel } from '../state/models/society'

export const useSocietyLockedForCurrentUser = (
  society: SnapshotOut<typeof SocietyModel> | undefined
): boolean => {
  const userId = useAuthenticatedUserId() as string
  const isAdmin = isUserAdminInSociety(society, userId)
  const isBoard = isUserBoardMemberInSociety(society, userId)
  const isAdminOrBoard = isAdmin || isBoard

  if (!society) {
    return false
  }

  return (society.locked && isAdminOrBoard) || false
}
