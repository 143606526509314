import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import Cookies from 'universal-cookie'
import { addDays } from 'date-fns'
import { useStores } from '../../../../hooks/useStores'
import { Modal } from '../../../common/Modal'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { SocietyInviteModalContent } from './SocietyInviteModalContent'
import { ModalFooter } from '../../../common/Modal/ModalFooter'
import { Button, ButtonVariant } from '../../../common/Button'
import {
  PreApprovedModel,
  SocietyModel,
} from '../../../../state/models/society'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { CookieKeys } from '../../../../types/cookies'

interface SocietyInviteModalProps {
  preApproved: SnapshotOut<typeof PreApprovedModel>
  show: boolean
  close: () => void
}

export const SocietyInviteModal = observer(
  ({ show, close, preApproved }: SocietyInviteModalProps): JSX.Element => {
    const { requestStore, societyStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const userId = useAuthenticatedUserId() as string
    const { translate } = useAppTranslation()
    const societyInviteViewedCookie = CookieKeys.SOCIETY_INVITE_VIEWED.replace(
      '{% id %}',
      preApproved._id
    )

    const society = preApproved
      ? (societyStore.societies.get(preApproved.societyId) as SnapshotOut<
          typeof SocietyModel
        >)
      : undefined

    useEffect(() => {
      const setInviteSeen = async (): Promise<void> => {
        const cookies = new Cookies()
        cookies.set(societyInviteViewedCookie, true, {
          sameSite: true,
          secure: true,
          expires: addDays(new Date(), 30),
        })
      }
      setInviteSeen()
    }, [societyInviteViewedCookie])

    const showSuccessMessage = (message: string): void => {
      setToastNotification(ToastType.SUCCESS, message)
    }

    const showErrorMessage = (message: string): void => {
      setToastNotification(ToastType.DANGER, message)
    }

    const onAcceptPress = async (): Promise<void> => {
      const status = await requestStore.acceptPreApproved(
        preApproved as SnapshotOut<typeof PreApprovedModel>,
        userId
      )
      if (status) {
        showSuccessMessage(
          translate('societyInviteModal.flashMessage.acceptPreApprovedSuccess')
        )
        societyStore.getUserSocieties(userId)
        close()
      } else {
        showErrorMessage(
          translate('societyInviteModal.flashMessage.acceptPreApprovedFailure')
        )
      }
    }

    const onDeclinePress = async (): Promise<void> => {
      const status = await societyStore.denyPreApproved(
        preApproved as SnapshotOut<typeof PreApprovedModel>
      )
      if (status) {
        showSuccessMessage(
          translate('societyInviteModal.flashMessage.denyPreApprovedSuccess')
        )
        close()
      } else {
        showErrorMessage(
          translate('societyInviteModal.flashMessage.denyPreApprovedFailure')
        )
      }
    }

    const acceptLoading = requestStore.acceptingPreApproved === 'pending'
    const denyLoading = societyStore.denyingPreApproved === 'pending'
    const buttonsDisabled = acceptLoading || denyLoading

    return (
      <Modal show={show} size="md">
        {{
          header: (
            <ModalHeader onClose={close}>
              {translate('societyInviteModal.title')}
            </ModalHeader>
          ),
          body: (
            <ModalBody>
              <SocietyInviteModalContent
                society={society}
                preApproved={preApproved}
              />
            </ModalBody>
          ),
          footer: (
            <ModalFooter>
              <Button
                onClick={onDeclinePress}
                loading={denyLoading}
                disabled={buttonsDisabled}
                label={translate('societyInviteModal.buttons.deny')}
                variant={ButtonVariant.DEFAULT}
              />
              <Button
                variant={ButtonVariant.PRIMARY}
                onClick={onAcceptPress}
                loading={acceptLoading}
                disabled={buttonsDisabled}
                label={translate('societyInviteModal.buttons.accept')}
              />
            </ModalFooter>
          ),
        }}
      </Modal>
    )
  }
)
