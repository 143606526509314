import React, { useCallback, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { HorizontalFormSection } from '../../common/HorizontalFormSection'
import { IconChoices } from '../../common/Icon'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useAuthenticatedUserId } from '../../../hooks/useAuthenticatedUserId'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import {
  isUserAdminInSociety,
  isUserBoardMemberInSociety,
} from '../../../helpers/society'
import { userNotificationsSchema } from '../../../forms/schemas/user_notifications'
import { SocietyModel } from '../../../state/models/society'
import { ToastType } from '../../common/Toast/toast-type'
import { theme } from '../../../theme/theme'
import { DropdownSelect } from '../../common/DropdownSelect'
import { useSocietyDropdownOptions } from './useSocietyDropdownOptions'
import { NotificationSettingsRow } from './NotificationSettingsRow'
import { useNotificationSettingsHelpers } from './helpers'
import { UserModel } from '../../../state/models/user'
import { SocietyErrorViewWrapper } from '../../../views/error/SocietyErrorViewWrapper'

export const NotificationSettings = observer((): JSX.Element => {
  const { userStore, societyStore } = useStores()
  const { translate } = useAppTranslation()
  const { setToastNotification } = useToastNotifications()
  const societyOptions = useSocietyDropdownOptions()
  const [societyId, setSocietyId] = useState<undefined | string>(undefined)
  const userId = useAuthenticatedUserId() as string

  useEffect(() => {
    if (societyStore.hasFetchedSocietiesOnce && societyId === undefined) {
      setSocietyId(societyOptions[0]?.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyOptions])

  const society = societyId
    ? (societyStore.societies.get(societyId) as SnapshotOut<
        typeof SocietyModel
      >)
    : undefined

  const user = userStore.users.get(userId) as
    | SnapshotOut<typeof UserModel>
    | undefined

  const {
    createPatchFormData,
    getDefaultFormValues,
    getOnMasterSwitchChangeResetValues,
  } = useNotificationSettingsHelpers(societyId, user)

  const isAdmin = isUserAdminInSociety(society, userId)
  const isBoardMember = isUserBoardMemberInSociety(society, userId)

  const getDefaultValues = useCallback((): InferType<
    typeof userNotificationsSchema
  > => {
    return getDefaultFormValues()
  }, [getDefaultFormValues])

  const { control, reset, watch } = useForm({
    resolver: yupResolver(userNotificationsSchema),
    defaultValues: getDefaultValues(),
  })

  useEffect(() => {
    reset(getDefaultValues())
  }, [reset, getDefaultValues])

  useEffect(() => {
    const subscription = watch(async (data) => {
      if (!user) {
        return
      }
      const formData = createPatchFormData(
        data as InferType<typeof userNotificationsSchema>
      )
      const status = await userStore.patchUser(user._id, formData)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('flashMessage.changesSaved')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.changesNotSavedError')
        )
      }
    })
    return () => subscription.unsubscribe()
  }, [
    watch,
    user,
    userStore,
    translate,
    setToastNotification,
    createPatchFormData,
  ])

  const onMasterSwitchChange = (
    field: 'email' | 'mobile',
    value: boolean | undefined
  ): void => {
    reset(getOnMasterSwitchChangeResetValues(getDefaultValues(), field, value))
  }

  return (
    <SocietyErrorViewWrapper>
      <form>
        <div className="mt-4 mb-8 md:mb-2">
          <p className="mb-2" style={theme.textVariants.captionBold}>
            {translate('common.form.labels.society')}
          </p>
          <DropdownSelect
            value={societyId}
            options={societyOptions}
            onChange={(value) => setSocietyId(value)}
            dropdownWidth="w-full md:w-60"
            dropdownContentWidth="w-fit"
            dropdownPosition="bottom-right"
            dropdownClassName="w-min-full"
          />
        </div>
        <HorizontalFormSection
          title={translate('notificationSettings.sections.all.title')}
          icon={IconChoices.NOTIFICATION_ALERT}
          iconPlacement="left"
          borderBottom
        >
          <Controller
            control={control}
            render={({ field: { value, name } }) => (
              <NotificationSettingsRow
                label={translate(
                  'notificationSettings.sections.all.allNotificationsTitle'
                )}
                name={name}
                value={value}
                onChange={(_value: {
                  mobile?: boolean | undefined
                  email?: boolean | undefined
                }) => {
                  const field =
                    value.email !== _value.email ? 'email' : 'mobile'
                  onMasterSwitchChange(field, _value[field])
                }}
              />
            )}
            name="masterSwitch"
          />
        </HorizontalFormSection>
        {isAdmin && (
          <HorizontalFormSection
            title={translate('notificationSettings.sections.admin.title')}
            icon={IconChoices.ADMIN}
            iconPlacement="left"
            borderBottom
          >
            <Controller
              control={control}
              render={({ field: { value, name, onChange } }) => (
                <NotificationSettingsRow
                  label={translate(
                    'notificationSettings.sections.admin.membershipRequestTitle'
                  )}
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              )}
              name="general.membershipRequests"
            />
          </HorizontalFormSection>
        )}
        <HorizontalFormSection
          title={translate('notificationSettings.sections.feed.title')}
          icon={IconChoices.FEED}
          iconPlacement="left"
          borderBottom
        >
          <Controller
            control={control}
            render={({ field: { value, name, onChange } }) => (
              <NotificationSettingsRow
                label={translate(
                  'notificationSettings.sections.feed.boardNewPostTitle'
                )}
                name={name}
                value={value}
                onChange={onChange}
                divider
                disableMobile
              />
            )}
            name="feed.postFromAdmins"
          />
          <Controller
            control={control}
            render={({ field: { value, name, onChange } }) => (
              <NotificationSettingsRow
                label={translate(
                  'notificationSettings.sections.feed.boardNewCommentTitle'
                )}
                name={name}
                value={value}
                onChange={onChange}
                divider
              />
            )}
            name="feed.commentOnAdminPosts"
          />
          <Controller
            control={control}
            render={({ field: { value, name, onChange } }) => (
              <NotificationSettingsRow
                label={translate(
                  'notificationSettings.sections.feed.neighbourNewPostTitle'
                )}
                name={name}
                value={value}
                onChange={onChange}
                divider
              />
            )}
            name="feed.postFromNeighbours"
          />
          <Controller
            control={control}
            render={({ field: { value, name, onChange } }) => (
              <NotificationSettingsRow
                label={translate(
                  'notificationSettings.sections.feed.neighbourNewCommentTitle'
                )}
                name={name}
                value={value}
                onChange={onChange}
              />
            )}
            name="feed.commentOnNeighbourPosts"
          />
        </HorizontalFormSection>
        <HorizontalFormSection
          title={translate('notificationSettings.sections.chat.title')}
          icon={IconChoices.CHAT}
          iconPlacement="left"
        >
          <Controller
            control={control}
            render={({ field: { value, name, onChange } }) => (
              <NotificationSettingsRow
                label={translate(
                  'notificationSettings.sections.chat.neighbourNewMessageTitle'
                )}
                name={name}
                value={value}
                onChange={onChange}
                divider
              />
            )}
            name="chat.neighbours"
          />
          <Controller
            control={control}
            render={({ field: { value, name, onChange } }) => (
              <NotificationSettingsRow
                label={translate(
                  'notificationSettings.sections.chat.groupNewMessageTitle'
                )}
                name={name}
                value={value}
                onChange={onChange}
                divider
              />
            )}
            name="chat.groups"
          />
          {(isAdmin || isBoardMember) && (
            <>
              <Controller
                control={control}
                render={({ field: { value, name, onChange } }) => (
                  <NotificationSettingsRow
                    label={translate(
                      'notificationSettings.sections.chat.boardNewMessageTitle'
                    )}
                    name={name}
                    value={value}
                    onChange={onChange}
                    divider
                  />
                )}
                name="chat.board"
              />
              <Controller
                control={control}
                render={({ field: { value, name, onChange } }) => (
                  <NotificationSettingsRow
                    label={translate(
                      'notificationSettings.sections.chat.questionsNewMessageTitle'
                    )}
                    name={name}
                    value={value}
                    onChange={onChange}
                    divider
                  />
                )}
                name="chat.questions"
              />
            </>
          )}
        </HorizontalFormSection>
      </form>
    </SocietyErrorViewWrapper>
  )
})
