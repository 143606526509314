import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useStores } from '../../../../hooks/useStores'
import { HouseRules } from '../../../../components/society/HouseRules'
import { useModal } from '../../../../hooks/useModal'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { ButtonVariant } from '../../../../components/common/Button'
import { IconChoices } from '../../../../components/common/Icon'
// eslint-disable-next-line max-len
import { CreateUpdateHouseRuleModal } from '../../../../components/society/HouseRules/CreateUpdateHouseRuleModal'
import { ViewBase } from '../../../../components/common/ViewBase'
import { IllustrationChoices } from '../../../../components/common/Illustration'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'

export const HouseRulesView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { houseRulesStore, authenticationStore } = useStores()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('society:house-rules', {
      '{{ societyName }}': society.name,
    })
  )
  const {
    show: showCreateModal,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModal()

  const isAdmin = isUserAdminInSociety(
    society,
    authenticationStore.userId as string
  )

  useEffect(() => {
    if (society._id) houseRulesStore.getHouseRulesPerSociety(society._id)
  }, [houseRulesStore, society._id])

  const buttons = [
    ...(isAdmin
      ? [
          {
            icon: IconChoices.PLUS_SIGN,
            variant: ButtonVariant.PRIMARY,
            onClick: openCreateModal,
            label: translate('common.actions.add'),
          },
        ]
      : []),
  ]

  const loading =
    houseRulesStore.fetchingHouseRules === 'pending' ||
    houseRulesStore.fetchingHouseRules === 'none'

  const rules = houseRulesStore.sortedHouseRulesForSociety(society._id)

  const errorView =
    !loading && rules.length === 0
      ? {
          title: translate('houseRulesView.emptyState.title'),
          subtitle: translate('houseRulesView.emptyState.subtitle'),
          illustration: IllustrationChoices.CHECKLIST,
        }
      : undefined

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('houseRulesView.title')}
        buttons={buttons}
        loading={loading}
        errorView={errorView}
      >
        <HouseRules />
      </ViewBase>
      {isAdmin && (
        <CreateUpdateHouseRuleModal
          show={showCreateModal}
          close={closeCreateModal}
        />
      )}
    </ErrorBoundary>
  )
})
