import React, { KeyboardEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd'
import { Dropdown, Option } from '../../../../../common/Dropdown'
import { Icon, IconChoices } from '../../../../../common/Icon'
import { Button } from '../../../../../common/Button'
import { theme } from '../../../../../../theme/theme'

interface DocumentListRowItemProps {
  isAdmin: boolean
  openItem: () => void
  documentTitle: string
  documentSubtitle: string
  documentIcon: IconChoices
  dropdownOptions: Option[]
  isDragging: boolean
  dragPreview: ConnectDragPreview
  drag: ConnectDragSource
  isFolder: boolean
  isOver?: boolean
}

export const DocumentListRowItem = observer(
  ({
    isAdmin,
    openItem,
    documentTitle,
    documentSubtitle,
    documentIcon,
    dropdownOptions,
    isDragging,
    dragPreview,
    drag,
    isFolder,
    isOver,
  }: DocumentListRowItemProps): JSX.Element => {
    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
      if (e.key === 'Enter' && openItem && isFolder) {
        openItem()
      }
    }

    return (
      <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }}>
        <div
          role="presentation"
          ref={isAdmin ? drag : null}
          className={`flex w-full items-center justify-between rounded-sm border-b py-5 px-4 
          hover:bg-neutral-96 ${isAdmin ? 'cursor-move' : 'cursor-pointer'} ${
            isOver ? 'bg-neutral-90' : 'bg-white'
          }`}
          onClick={openItem}
          onKeyDown={handleKeyDown}
        >
          <div className="flex items-center">
            <div className="flex items-center gap-3 md:max-w-xl">
              <Icon size={32} icon={documentIcon} />
              <div className="flex flex-col">
                <p style={theme.textVariants.base}>{documentTitle}</p>
                <p
                  style={theme.textVariants.caption}
                  className="text-neutral-50"
                >
                  {documentSubtitle}
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            {isAdmin && (
              <Dropdown options={dropdownOptions}>
                <Button
                  icon={IconChoices.MORE}
                  className="h-10 w-10 border-none bg-transparent hover:bg-neutral-85"
                  disableTabIndex
                />
              </Dropdown>
            )}
          </div>
        </div>
      </div>
    )
  }
)
