export enum Interests {
  ActionSports = 'actionsports',
  Animals = 'animals',
  Art = 'art',
  BoardGames = 'board-games',
  Cars = 'cars',
  Cooking = 'cooking',
  Dancing = 'dancing',
  Design = 'design',
  ElectronicsSmartHome = 'electronics-smart-home',
  Entrepreneurship = 'entrepreneurship',
  Family = 'family',
  Film = 'film',
  Fitness = 'fitness',
  Gardening = 'gardening',
  Handicraft = 'handicraft',
  Literature = 'literature',
  Motorcycles = 'motorcycles',
  Music = 'music',
  Nature = 'nature',
  Outdoors = 'outdoors',
  Painting = 'painting',
  Politics = 'politics',
  Reading = 'reading',
  Religion = 'religion',
  Sports = 'sports',
  Startups = 'startups',
  StockTrading = 'stock-trading',
  Tech = 'tech',
  Toddlers = 'toddlers',
  Travel = 'travel',
}
