import React, { Dispatch, SetStateAction } from 'react'
import { IconChoices } from '../../common/Icon'
import { UserListItem } from '../../common/UserListItem/UserListItem'

interface RoomChatHeaderProps {
  chatRoomTitle: string
  chatRoomAvatar: JSX.Element
  chatRoomSociety: string | undefined
  showSettings: boolean
  setShowSettings: Dispatch<SetStateAction<boolean>>
}

export const RoomChatHeader = ({
  chatRoomTitle,
  chatRoomAvatar,
  chatRoomSociety,
  showSettings,
  setShowSettings,
}: RoomChatHeaderProps): JSX.Element => {
  const onSettingsClick = (): void => {
    setShowSettings(!showSettings)
  }

  return (
    <UserListItem
      avatar={chatRoomAvatar}
      name={chatRoomTitle}
      text={chatRoomSociety || ''}
      icon={IconChoices.MORE}
      onIconClick={onSettingsClick}
      className="p-4 md:py-4 md:px-6"
    />
  )
}
