/* eslint-disable @typescript-eslint/ban-ts-comment */
import { captureException } from '@sentry/react'
import {
  SnapshotIn,
  IMapType,
  IModelType,
  _NotCustomized,
} from 'mobx-state-tree'
import { stringifyObject } from '../../helpers/object'

export const setObject = <T>(
  collectionMap: IMapType<
    IModelType<
      // @ts-ignore
      T,
      // eslint-disable-next-line @typescript-eslint/ban-types
      {},
      _NotCustomized,
      _NotCustomized
    >
  >,
  collectionMapObjectModel: IModelType<
    // @ts-ignore
    T,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {},
    _NotCustomized,
    _NotCustomized
  >,
  // @ts-ignore
  object: SnapshotIn<typeof T>
): void => {
  try {
    // @ts-ignore
    collectionMap.set(object._id, collectionMapObjectModel.create(object))
  } catch (error) {
    captureException(error, {
      extra: {
        object: stringifyObject(object),
      },
    })
  }
}
