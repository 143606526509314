import { SnapshotOut } from 'mobx-state-tree'
import { SocietyModel } from '../state/models/society'
import { useStores } from './useStores'

export const useCurrentSociety = (): {
  society: SnapshotOut<typeof SocietyModel> | undefined
} => {
  const { societyStore } = useStores()
  return { society: societyStore.selectedSociety }
}
