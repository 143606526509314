import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

interface IAccordionContext {
  activeItem: null | number
  setActiveItem: (index: number | null) => void
}

const AccordionContext = createContext<IAccordionContext>({
  activeItem: null,
  setActiveItem: () => null,
})

interface AccordionContextProviderProps {
  children: React.ReactNode
  activeItemForceUpdated?: number | null
}

export const AccordionContextProvider = ({
  activeItemForceUpdated,
  children,
}: AccordionContextProviderProps): JSX.Element => {
  const [activeItem, setActiveItem] = useState<null | number>(null)

  const accordionValue = useMemo(
    () => ({ activeItem, setActiveItem }),
    [activeItem]
  )

  useLayoutEffect(() => {
    if (activeItemForceUpdated !== undefined)
      setActiveItem(activeItemForceUpdated)
  }, [activeItemForceUpdated])

  return (
    <AccordionContext.Provider value={accordionValue}>
      {children}
    </AccordionContext.Provider>
  )
}

export const useAccordion = (): IAccordionContext =>
  useContext(AccordionContext)
