import React, { KeyboardEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Icon, IconChoices } from '../../../../common/Icon'
import { theme } from '../../../../../theme/theme'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { useModal } from '../../../../../hooks/useModal'
import { Dropdown } from '../../../../common/Dropdown'
import { useStores } from '../../../../../hooks/useStores'
import { ToastType } from '../../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { ConfirmationModal } from '../../../../common/ConfirmationModal'
import {
  SocietyWebsiteFolder,
  SocietyWebsiteModel,
} from '../../../../../state/models/society-website'
import { CreateUpdateSocietyWebsiteFolderModal } from '../CreateUpdateSocietyWebsiteFolderModal'
import { Button } from '../../../../common/Button'

interface SocietyWebsiteFolderListItemProps {
  website: SnapshotOut<typeof SocietyWebsiteModel>
  folder: SnapshotOut<typeof SocietyWebsiteFolder>
  onChangeCurrentFolder: (id: string) => void
}

export const SocietyWebsiteFolderListItem = observer(
  ({
    website,
    folder,
    onChangeCurrentFolder,
  }: SocietyWebsiteFolderListItemProps): JSX.Element => {
    const { societyWebsitesStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()
    const {
      show: showEditFolderModal,
      open: openEditFolderModal,
      close: closeEditFolderModal,
    } = useModal()
    const {
      show: showRemoveConfirmationModal,
      open: openRemoveConfirmationModal,
      close: closeRemoveConfirmationModal,
    } = useModal()

    const folders = website ? website.foldersList : []

    const icon = IconChoices.FOLDER

    const openFolder = (): void => {
      onChangeCurrentFolder(folder._id)
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
      if (e.key === 'Enter') {
        openFolder()
      }
    }

    const removeFolder = async (): Promise<void> => {
      const status = await societyWebsitesStore.patchWebsite(website._id, {
        foldersList: folders?.filter((_folder) => _folder._id !== folder._id),
      })
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('createUpdateFolder.flashMessage.deleteFolderSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('createUpdateFolder.flashMessage.deleteFolderFailure')
        )
      }
    }

    const dropdownOptions = [
      {
        value: 'edit',
        label: translate('common.actions.edit'),
        onClick: () => openEditFolderModal(),
      },
      {
        value: 'remove',
        label: translate('common.actions.delete'),
        onClick: async () => openRemoveConfirmationModal(),
        destructive: true,
      },
    ]

    const getDocumentSubTitleText = (): string => {
      const numFiles = folder.documentsList.length

      const items = []
      if (numFiles > 0) {
        items.push(
          `${numFiles} ${
            numFiles === 1
              ? translate('documentsList.document.documentSingular')
              : translate('documentsList.document.documentPlural')
          }`
        )
      }

      return items.join(', ')
    }

    return (
      <>
        <div
          role="button"
          tabIndex={0}
          className="flex w-full cursor-pointer cursor-pointer 
          items-center justify-between rounded-sm border-b py-5 px-4 hover:bg-neutral-96"
          onClick={openFolder}
          onKeyDown={handleKeyDown}
        >
          <div className="flex items-center">
            <div className="flex w-60 items-center gap-2 md:w-80">
              <Icon size={32} icon={icon} />
              <div className="flex flex-col">
                <p style={theme.textVariants.base}>{folder.name}</p>
                <p
                  style={theme.textVariants.caption}
                  className="text-neutral-50"
                >
                  {getDocumentSubTitleText()}
                </p>
              </div>
            </div>
          </div>
          <div className="flex space-x-20">
            <Dropdown options={dropdownOptions}>
              <Button
                icon={IconChoices.MORE}
                className="h-10 w-10 border-none bg-transparent hover:bg-neutral-90"
                disableTabIndex
              />
            </Dropdown>
          </div>
        </div>
        <CreateUpdateSocietyWebsiteFolderModal
          website={website}
          show={showEditFolderModal}
          close={closeEditFolderModal}
          id={folder._id}
        />
        <ConfirmationModal
          title={translate(
            'createUpdateFolder.removeFolderConfirmationModal.title'
          )}
          description={translate(
            'createUpdateFolder.removeFolderConfirmationModal.description'
          )}
          show={showRemoveConfirmationModal}
          close={closeRemoveConfirmationModal}
          onConfirm={removeFolder}
          deleteMode
        />
      </>
    )
  }
)
