import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { useStores } from '../../../hooks/useStores'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../../components/common/Toast/toast-type'
import { ErrorBoundary } from '../../../components/common/ErrorBoundary'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../../../components/common/Icon'
import { Button, ButtonVariant } from '../../../components/common/Button'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { LocalStorageKeys } from '../../../types/local-storage'
import { useDocumentTitle } from '../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../navigation/reverseDocumentTitle'
import { useAuth } from '../../../hooks/useAuth'
import {
  Illustration,
  IllustrationChoices,
} from '../../../components/common/Illustration'

const TEN_MINUTES_IN_SECONDS = 600

const getTimeDifferenceSinceLastEmail = (dateValue: string): number => {
  if (dateValue) {
    const timestampDifference = Math.abs(
      new Date().getTime() - Date.parse(dateValue)
    )
    return TEN_MINUTES_IN_SECONDS - Math.floor(timestampDifference / 1000)
  }
  return 0
}

export const VerifyEmailView = observer(() => {
  const { setUserIsVerified, userId } = useAuth()
  const { userStore, authenticationStore } = useStores()
  const { translate } = useAppTranslation()
  useDocumentTitle(reverseDocumentTitle('verify-email'))
  const { setToastNotification } = useToastNotifications()
  const localStorageKey = LocalStorageKeys.VERIFY_EMAIL_RESEND_STATE.replace(
    '{% id %}',
    userId ?? ''
  )
  const localStorageValue = localStorage.getItem(localStorageKey)
  const lastSentEmailTimeInSeconds = localStorageValue
    ? getTimeDifferenceSinceLastEmail(localStorageValue)
    : 0
  const [resendVerificationCodeTimeLeft, setResendVerificationCodeTimeLeft] =
    useState(lastSentEmailTimeInSeconds ?? 0)
  const navigate = useNavigate()
  const [navigateToFeed, setNavigateToFeed] = useState(false)

  const user = userId ? userStore.users.get(userId) : undefined

  useEffect(() => {
    const updatedTime = localStorageValue
      ? getTimeDifferenceSinceLastEmail(localStorageValue)
      : 0
    setResendVerificationCodeTimeLeft(updatedTime)
  }, [localStorageValue])

  useEffect(() => {
    if (navigateToFeed) {
      navigate(reverseUrl('feed'))
    }
  }, [navigate, navigateToFeed])

  // Fetch user every 3 seconds to check if verified
  useEffect(() => {
    const interval = setInterval(() => {
      if (userId && userStore.fetchingUser !== 'pending') {
        userStore.getUser(userId).then(() => {
          const _user = userStore.users.get(userId)
          if (_user && _user.isVerified) {
            setUserIsVerified(true)
            setNavigateToFeed(true)
          }
        })
      }
    }, 3000)
    return () => {
      clearInterval(interval)
    }
  }, [setUserIsVerified, userId, userStore])

  const showErrorMessage = (): void => {
    setToastNotification(
      ToastType.DANGER,
      translate('verifyEmailView.flashMessage.resendVerifyEmailFailure')
    )
  }

  const showSuccessMessage = (): void => {
    setToastNotification(
      ToastType.SUCCESS,
      translate('verifyEmailView.flashMessage.resendVerifyEmailSuccess')
    )
  }

  const onResendVerificationCodeClick = async (): Promise<void> => {
    if (user && user.email) {
      setResendVerificationCodeTimeLeft(TEN_MINUTES_IN_SECONDS)
      const status = await userStore.resendVerificationCode(user.email)
      localStorage.setItem(localStorageKey, new Date().toString())
      if (status) {
        showSuccessMessage()
      } else {
        showErrorMessage()
      }
    } else {
      showErrorMessage()
    }
  }

  const clearLocalStorageValue = useCallback((): void => {
    localStorage.removeItem(localStorageKey)
  }, [localStorageKey])

  useEffect(() => {
    setTimeout(() => {
      if (resendVerificationCodeTimeLeft > 0)
        setResendVerificationCodeTimeLeft(resendVerificationCodeTimeLeft - 1)
    }, 1000)
  })

  useEffect(() => {
    if (
      resendVerificationCodeTimeLeft <= 0 &&
      localStorageValue !== undefined
    ) {
      setResendVerificationCodeTimeLeft(0)
      clearLocalStorageValue()
    }
  }, [
    resendVerificationCodeTimeLeft,
    localStorageValue,
    clearLocalStorageValue,
  ])

  const getTimeString = (seconds: number): string => {
    const _minutes = Math.floor(seconds / 60)
    const _seconds = seconds - _minutes * 60
    const _secondsString = `${_seconds}`.padStart(2, '0')
    return `${_minutes}:${_secondsString}`
  }

  const logout = (): void => {
    authenticationStore.logout()
  }

  useEffect(() => {
    if (!userId) {
      navigate(reverseUrl('login'), { replace: true })
    }
  }, [navigate, userId])

  return (
    <ErrorBoundary>
      <div className="flex h-full w-full flex-row">
        <div className="mx-6 h-full flex-1">
          <Icon
            className="mt-8"
            icon={IconChoices.BOAPPA}
            width={123}
            height={45}
          />
          <div className="mt-8 flex flex-col md:ml-32 md:mt-52">
            <div className="flex flex-col">
              <p style={theme.textVariants.h2} className="mt-3">
                {translate('verifyEmailView.title')}
              </p>
              <p
                style={theme.textVariants.base}
                className="mt-2 max-w-[500px] [overflow-wrap:anywhere]"
              >
                {translate('verifyEmailView.subtitle')}
                <span
                  className="ml-1"
                  style={theme.textVariants.baseBold}
                  translate="no"
                >
                  {user?.email}
                </span>
              </p>
            </div>
            <div className="flex flex-col">
              <p style={theme.textVariants.base} className="mt-6 max-w-[500px]">
                {translate('verifyEmailView.cantFindEmail')}
              </p>
              <Button
                variant={ButtonVariant.PRIMARY}
                className="mt-3 w-full md:w-80"
                label={
                  resendVerificationCodeTimeLeft > 0
                    ? `${translate(
                        'verifyEmailView.preTimeLeftUntilResend'
                      )} ${getTimeString(resendVerificationCodeTimeLeft)}`
                    : translate('verifyEmailView.sendAgain')
                }
                onClick={
                  resendVerificationCodeTimeLeft === 0
                    ? onResendVerificationCodeClick
                    : undefined
                }
                disabled={resendVerificationCodeTimeLeft > 0}
                icon={IconChoices.REFRESH}
              />
            </div>
            <div className="mt-10 space-y-3">
              <p style={theme.textVariants.base}>
                {translate('verifyEmailView.invalidEmail')}
              </p>
              <Button
                wrapperClassName="w-full md:w-80"
                className="w-full"
                onClick={logout}
                label={translate('verifyEmailView.startOverButtonTitle')}
              />
            </div>
          </div>
        </div>
        <div
          className="flex hidden h-full w-full flex-1 items-center
         justify-center bg-brandLightGreen lg:flex"
        >
          <Illustration
            illustrationChoice={IllustrationChoices.SUN}
            width={400}
          />
        </div>
      </div>
    </ErrorBoundary>
  )
})
