import React, {
  FormEvent,
  KeyboardEvent,
  MutableRefObject,
  Ref,
  useEffect,
  useRef,
  useState,
} from 'react'
import { theme } from '../../../theme/theme'

interface TextAreaProps {
  onChange: (e: React.FormEvent<HTMLTextAreaElement>) => void
  onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  className?: string
  rows?: number
  height?: number
  value?: string
  error?: boolean
  label?: string
  placeholder?: string
  disabled?: boolean
  removeBorders?: boolean
  fixedHeight?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit?: () => void
  roundedClassName?: string
}

const assignRefs = <T,>(...refs: Ref<T | null>[]) => {
  return (node: T | null) => {
    refs.forEach((r) => {
      if (typeof r === 'function') {
        r(node)
      } else if (r) {
        // eslint-disable-next-line no-param-reassign
        ;(r as MutableRefObject<T | null>).current = node
      }
    })
  }
}

export const TextArea = ({
  onChange,
  onBlur,
  className,
  rows = 4,
  height,
  value,
  error,
  label,
  placeholder,
  disabled,
  removeBorders = false,
  fixedHeight = true,
  handleSubmit,
  roundedClassName = 'rounded',
}: TextAreaProps): JSX.Element => {
  const [focused, setFocused] = useState(false)
  const [hasValue, setHasValue] = useState(false)
  const [smallLabel, setSmallLabel] = useState(false)
  const [initAnimation, setInitAnimation] = useState(false)
  const [hideLabel, setHideLabel] = useState(false)
  const localRef = useRef<HTMLTextAreaElement>()

  useEffect(() => {
    if (localRef?.current) {
      const { clientHeight, scrollHeight } = localRef.current
      setHideLabel(scrollHeight > clientHeight)
    }
  }, [value])

  useEffect(() => {
    if (value === '' && localRef?.current && !fixedHeight) {
      localRef.current.style.height = 'inherit'
    }
    setHasValue(value !== '' && value !== undefined)
  }, [fixedHeight, value])

  useEffect(() => {
    hasValue && setInitAnimation(true)
    setSmallLabel(focused || (!focused && hasValue))
  }, [focused, hasValue])

  const onBlurEvent = (
    e: React.FocusEvent<HTMLTextAreaElement, Element>
  ): void => {
    setFocused(false)
    onBlur(e)
  }

  const onChangeEvent = (e: FormEvent<HTMLTextAreaElement>): void => {
    if (fixedHeight) {
      return
    }
    const maxHeightBeforeScroll = 300
    e.currentTarget.style.height = 'inherit'
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`
    e.currentTarget.style.height = `${Math.min(
      e.currentTarget.scrollHeight,
      maxHeightBeforeScroll
    )}px`
  }

  const checkSubmit = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
    if (
      e.key === 'Enter' &&
      e.shiftKey === false &&
      e.altKey === false &&
      handleSubmit
    ) {
      e.preventDefault()
      handleSubmit()
    }
    // This is to allow alt/option + enter to linebreak, does not work perfectly when we get
    // to height limit on the scroll but it was the only workaround that almost worked
    if (e.key === 'Enter' && e.altKey === true) {
      const event = {
        ...e,
        currentTarget: {
          ...e.currentTarget,
          value: (e.currentTarget.value += '\n'),
        },
      }
      onChange(event)
      onChangeEvent(e)
    }
  }

  const onFocusEvent = (): void => {
    setFocused(true)
    setInitAnimation(true)
  }
  const animationClass = smallLabel ? 'animate-smallLabel' : 'animate-bigLabel'

  return (
    <div className={`flex w-full flex-col ${disabled ? 'opacity-50' : ''}`}>
      <div className="relative flex">
        <p
          style={theme.textVariants.lead}
          className={`text-gray-40 pointer-events-none absolute inset-4
              h-min w-fit
              ${initAnimation && animationClass}
          `}
        >
          {!placeholder && !hideLabel && label}
        </p>
        <textarea
          style={{ height, ...theme.textVariants.base }}
          className={`w-full resize-none overflow-auto border border-solid border-neutral-80 px-[15px]
            leading-6 hover:border-brandGreen/50 
            disabled:hover:border-neutral-80 ${className} ${roundedClassName}
            ${
              !placeholder && !hideLabel && label
                ? 'pt-6 pb-[10px]'
                : 'py-[9px]'
            }
            ${error ? 'focus:outline-red' : 'focus:outline-blue/50'}
            ${
              removeBorders
                ? 'border-none hover:border-none focus:outline-0'
                : ''
            }`}
          rows={rows}
          onChange={(e: FormEvent<HTMLTextAreaElement>) => {
            onChange(e)
            onChangeEvent(e)
          }}
          onFocus={onFocusEvent}
          onBlur={onBlurEvent}
          ref={assignRefs(localRef)}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onKeyDown={checkSubmit}
        />
      </div>
    </div>
  )
}
