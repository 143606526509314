import * as yup from 'yup'
import i18n from '../../i18n/i18n'

export const adminResetPasswordSchema = yup
  .object({
    password: yup
      .string()
      .required()
      .min(
        8,
        i18n.t('form.errors.minLength', {
          minLength: '8',
        })
      ),
  })
  .required()
