import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { ChatRoomModel } from '../../../state/models/chat-room'
import { Modal } from '../../common/Modal'
import { ModalBody } from '../../common/Modal/ModalBody'
import { ModalHeader } from '../../common/Modal/ModalHeader'
import { ShowGroupMembersContent } from '../ShowGroupMembersContent'

interface ShowGroupMembersModalProps {
  show: boolean
  close: () => void
  chatRoom: SnapshotOut<typeof ChatRoomModel>
}

export const ShowGroupMembersModal = ({
  show,
  close,
  chatRoom,
}: ShowGroupMembersModalProps): JSX.Element => {
  const { translate } = useAppTranslation()
  return (
    <Modal show={show}>
      {{
        header: (
          <ModalHeader onClose={close}>
            {translate('chatSettings.form.title')}
          </ModalHeader>
        ),
        body: (
          <ModalBody>
            <ShowGroupMembersContent chatRoom={chatRoom} />
          </ModalBody>
        ),
      }}
    </Modal>
  )
}
