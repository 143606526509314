import React from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { useStores } from '../../../../../../hooks/useStores'
import { UserModel } from '../../../../../../state/models/user'
import { getTimestamp } from '../../../../../../helpers/date'
import { IconChoices } from '../../../../../common/Icon'
import { Button } from '../../../../../common/Button'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { PostImages } from '../../../../PostImages'
import { PostDocuments } from '../../../../PostDocuments'
import { PostEvents } from '../../../../PostEvents'
import { PostPolls } from '../../../../PostPolls'
import { theme } from '../../../../../../theme/theme'
import '../../../../Post/post.css'
import { PostHeader } from '../../../../Post/PostHeader'
import { PostText } from '../../../../Post/PostText'
import { PostData } from '../post-data'

interface PostProps {
  postData: PostData
}

export const PreviewPostModalContent = observer(
  ({ postData }: PostProps): JSX.Element | null => {
    const { translate } = useAppTranslation()
    const { userStore } = useStores()

    const author = userStore.users.get(postData.author) as
      | Instance<typeof UserModel>
      | undefined

    if (!author) {
      return null
    }

    const mediaIds = postData.media
      ? (postData.media.filter((_media) => _media !== undefined) as string[])
      : []

    const documentsIds = postData.documentsIds
      ? (postData.documentsIds.filter(
          (_documentId) => _documentId !== undefined
        ) as string[])
      : []

    const eventsIds = postData.eventsIds
      ? (postData.eventsIds.filter(
          (_eventId) => _eventId !== undefined
        ) as string[])
      : []

    const pollIds = postData.pollIds
      ? (postData.pollIds.filter(
          (_pollId) => _pollId !== undefined
        ) as string[])
      : []

    const groupsIds = postData.groupsIds
      ? (postData.groupsIds.filter(
          (_groupId) => _groupId !== undefined
        ) as string[])
      : []

    const postHeaderData = {
      postId: '',
      isAlert: !!postData.isAlert,
      createdAt: postData.createdAt,
      role: postData.role,
      message: postData.message || '',
      groupsIds,
      accessAdminMember: !!postData.accessAdminMember,
      accessBoardMember: !!postData.accessBoardMember,
      societyId: postData.society,
    }

    return (
      <div className="my-4 flex flex-col gap-4 rounded-md border border-neutral-80 bg-white p-4 md:p-6">
        <PostHeader data={postHeaderData} author={author} disabled />
        <div className="post-body mt-0 flex max-w-full flex-col gap-4">
          <PostText
            message={postData.message || ''}
            isAutomaticallyCreatedPost={false}
          />
          <PostImages mediaIds={mediaIds} disabled />
          <PostDocuments documentsIds={documentsIds} disabled />
          <PostEvents eventsIds={eventsIds} disabled />
          <PostPolls pollIds={pollIds} disabled />
        </div>
        <div className="border-b border-neutral-80" />
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
          <div className="flex gap-3">
            <Button
              label={translate('common.like')}
              icon={IconChoices.LIKE}
              disabled
            />
            <Button
              label={translate('common.makeComment')}
              icon={IconChoices.COMMENT}
              disabled
            />
          </div>
          {postData.updatedAt && postData.updatedAt > postData.createdAt && (
            <p
              style={theme.textVariants.fineprint}
              className="mt-2 text-neutral-30 md:mt-0"
            >
              {translate('post.editedPrefix')}{' '}
              {getTimestamp(new Date(postData.updatedAt))}
            </p>
          )}
        </div>
      </div>
    )
  }
)
