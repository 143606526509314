import { DocumentUploadActions } from '../actions/document-upload'
import { DocumentUploadState } from '../types/document-upload'

export const documentUploadReducer = (
  state: DocumentUploadState,
  action: DocumentUploadActions
): DocumentUploadState => {
  switch (action.type) {
    case 'add':
      return {
        documents: [
          ...state.documents,
          { ...action.document, uploading: true },
        ],
      }
    case 'remove':
      return {
        documents: state.documents.filter(
          (_document) => _document.id !== action.id
        ),
      }
    case 'setUploaded':
      return {
        documents: [
          ...state.documents.map((_document) =>
            _document.uri === action.document.uri
              ? { ..._document, id: action.id, uploading: false }
              : _document
          ),
        ],
      }
    default:
      throw new Error()
  }
}
