import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { Button, ButtonVariant } from '../../common/Button'
import { FormControl } from '../../common/FormControl'
import { HorizontalFormSection } from '../../common/HorizontalFormSection'
import { IconChoices } from '../../common/Icon'
import { TextInput } from '../../common/TextInput'
import { ToastType } from '../../common/Toast/toast-type'
import { updateEmailSchema } from '../../../forms/schemas/update_email'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useAuthenticatedUserId } from '../../../hooks/useAuthenticatedUserId'
import { useFormErrorMessage } from '../../../hooks/useFormErrorMessage'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { UserModel } from '../../../state/models/user'

export const ChangeEmail = observer((): JSX.Element => {
  const { userStore } = useStores()
  const { translate } = useAppTranslation()
  const { setToastNotification } = useToastNotifications()
  const { getErrorMessage } = useFormErrorMessage()

  const userId = useAuthenticatedUserId() as string
  const user = userStore.users.get(userId) as
    | SnapshotOut<typeof UserModel>
    | undefined

  const getDefaultValues = (): InferType<typeof updateEmailSchema> => {
    return {
      currentPassword: '',
      newEmail: '',
      newEmailConfirmation: '',
    }
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(updateEmailSchema),
    defaultValues: getDefaultValues(),
  })

  const onSubmit = async (
    data: InferType<typeof updateEmailSchema>
  ): Promise<void> => {
    const status = await userStore.updateEmail(
      userId,
      data.newEmail,
      data.currentPassword
    )
    if (status) {
      setToastNotification(
        ToastType.SUCCESS,
        translate('changeEmail.flashMessage.updateEmailSuccess')
      )
      reset()
    } else {
      setToastNotification(
        ToastType.DANGER,
        translate('changeEmail.flashMessage.updateEmailFailure')
      )
    }
  }

  const onError = (): void => {
    setToastNotification(
      ToastType.DANGER,
      translate('flashMessage.somethingWentWrongError')
    )
  }

  const loading = userStore.updatingEmail === 'pending'

  return (
    <>
      {user && (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <HorizontalFormSection
            title={translate('changeEmail.title')}
            icon={IconChoices.EMAIL}
            iconPlacement="left"
            borderBottom
          >
            <FormControl
              name="email"
              label={translate('changeEmail.labels.currentEmail')}
              disabled
            >
              <TextInput
                value={user.email}
                onChange={() => null}
                onBlur={() => null}
                disabled
                type="email"
              />
            </FormControl>
            <Controller
              control={control}
              render={({ field: { value, name, onChange, onBlur } }) => (
                <FormControl
                  label={translate('changeEmail.labels.newEmail')}
                  error={errors.newEmail && getErrorMessage(errors.newEmail)}
                  name={name}
                >
                  <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.newEmail}
                    type="email"
                  />
                </FormControl>
              )}
              name="newEmail"
            />
            <Controller
              control={control}
              render={({ field: { value, name, onChange, onBlur } }) => (
                <FormControl
                  label={translate('changeEmail.labels.repeatNewEmail')}
                  error={
                    errors.newEmailConfirmation &&
                    getErrorMessage(errors.newEmailConfirmation)
                  }
                  name={name}
                >
                  <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.newEmailConfirmation}
                    type="email"
                  />
                </FormControl>
              )}
              name="newEmailConfirmation"
            />
            <Controller
              control={control}
              render={({ field: { value, name, onChange, onBlur } }) => (
                <FormControl
                  label={translate('common.form.labels.password')}
                  error={
                    errors.currentPassword &&
                    getErrorMessage(errors.currentPassword)
                  }
                  name={name}
                >
                  <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.currentPassword}
                    type="password"
                  />
                </FormControl>
              )}
              name="currentPassword"
            />
            <div className="mt-4 flex justify-end">
              <Button
                label={translate('common.actions.saveChanges')}
                type="submit"
                loading={loading}
                disabled={loading || !isValid}
                variant={ButtonVariant.PRIMARY}
              />
            </div>
          </HorizontalFormSection>
        </form>
      )}
    </>
  )
})
