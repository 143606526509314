import React from 'react'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { theme } from '../../../../../../theme/theme'
import {
  Illustration,
  IllustrationChoices,
} from '../../../../../common/Illustration'

export const FirstStep = (): JSX.Element => {
  const { translate } = useAppTranslation()
  return (
    <div className="flex flex-col gap-3">
      <div className="mb-8 flex justify-center">
        <Illustration illustrationChoice={IllustrationChoices.CHECKLIST} />
      </div>
      <div className="flex flex-col">
        <p style={theme.textVariants.h3}>
          {translate('deleteAccountModal.warningTitle')}
        </p>
        <p style={theme.textVariants.h3}>
          {translate('deleteAccountModal.warningSubtitle')}
        </p>
      </div>
      <p style={theme.textVariants.base}>
        {translate('deleteAccountModal.warningText')}
      </p>
    </div>
  )
}
