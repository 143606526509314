import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { castToSnapshot, SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ViewBase } from '../../../../components/common/ViewBase'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { useStores } from '../../../../hooks/useStores'
import { SocietyModel } from '../../../../state/models/society'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { IconChoices } from '../../../../components/common/Icon'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { useFacilityTabs } from './useFacilityTabs'
import { FacilityInformation } from '../../../../components/society/Facility/FacilityInformation'
import { FacilityCalendar } from '../../../../components/society/Facility/FacilityCalendar'
import { FacilityBookings } from '../../../../components/society/Facility/FacilityBookings'
import { RouteErrorView } from '../../../error/RouteErrorView'

export enum FacilityViewTabs {
  CALENDAR = 0,
  INFORMATION = 1,
  BOOKINGS = 2,
}

export const FacilityView = observer((): JSX.Element | null => {
  const params = useParams()
  const { translate } = useAppTranslation()
  const { facilitiesStore, societyStore } = useStores()
  const { facilityId } = useParams()
  const navigate = useNavigate()
  const userId = useAuthenticatedUserId() as string
  const location = useLocation()
  const { getTabUrl, getTabState } = useFacilityTabs()

  const facility = facilityId
    ? facilitiesStore.facilities.get(facilityId)
    : undefined

  const society = facility
    ? societyStore.societies.get(facility.societyId)
    : undefined

  useDocumentTitle(
    reverseDocumentTitle('society:facilities-detail', {
      '{{ societyName }}': society?.name,
      '{{ facilityName }}': facility?.name,
    })
  )

  const snapshotSociety = society
    ? (castToSnapshot(society) as SnapshotOut<typeof SocietyModel>)
    : undefined

  const [activeTab, setActiveTab] = useState<FacilityViewTabs>(
    getTabState(location.pathname)
  )

  useEffect(() => {
    params.facilityId && facilitiesStore.getFacility(params.facilityId)
  }, [params.facilityId, facilitiesStore])

  // When a new activeTab is set, we change the navigation to that tab as well
  useEffect(() => {
    const url = getTabUrl(activeTab)
    if (location.pathname !== url) {
      navigate(url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  // When a new location is set (e.g. backbutton) we change the activeTab as well
  useEffect(() => {
    setActiveTab(getTabState(location.pathname))
  }, [getTabState, location.pathname])

  const tabs = [
    {
      title: translate('facilityView.tabBar.calendar'),
    },
    {
      title: translate('facilityView.tabBar.information'),
    },
    {
      title: translate('facilityView.tabBar.bookings'),
    },
  ]

  if (!facility || !snapshotSociety) {
    return null
  }

  const isAdmin = isUserAdminInSociety(snapshotSociety, userId)

  const loading = facilitiesStore.fetchingFacility === 'pending'

  return (
    <ViewBase
      backUrl={reverseUrl('society:facilities', { id: facility.societyId })}
      title={facility?.name}
      tabBar={{ tabs, setActiveTab, activeTab }}
      loading={loading}
      buttons={
        isAdmin
          ? [
              {
                label: translate('common.actions.edit'),
                icon: IconChoices.EDIT,
                onClick: () =>
                  navigate(
                    reverseUrl('society:facilities-update', {
                      id: facility.societyId,
                      facilityId: facility._id,
                    })
                  ),
              },
            ]
          : undefined
      }
    >
      <Routes>
        <Route
          path="calendar"
          element={
            <FacilityCalendar facility={facility} society={snapshotSociety} />
          }
          errorElement={<RouteErrorView />}
        />
        <Route
          path="information"
          element={<FacilityInformation facility={facility} />}
          errorElement={<RouteErrorView />}
        />
        <Route
          path="bookings"
          element={<FacilityBookings facility={facility} />}
          errorElement={<RouteErrorView />}
        />
      </Routes>
    </ViewBase>
  )
})
