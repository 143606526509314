import { observer } from 'mobx-react-lite'
import React from 'react'
import { isUserAdminInSociety } from '../../../../../../helpers/society'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { useCurrentSociety } from '../../../../../../hooks/useCurrentSociety'
import { useModal } from '../../../../../../hooks/useModal'
import { useStores } from '../../../../../../hooks/useStores'
import { useToastNotifications } from '../../../../../../hooks/useToastNotification'
import { Button, ButtonVariant } from '../../../../../common/Button'
import { ConfirmationModal } from '../../../../../common/ConfirmationModal'
import { ToastType } from '../../../../../common/Toast/toast-type'
import { CreateUpdateQuestionsAndAnswersItemModal } from '../CreateUpdateQuestionsAndAnswersItemModal'
import { Linkify } from '../../../../../common/Linkify'

interface QuestionsAndAnswersItemContentProps {
  questionId: string
  sectionId: string
  content: string | undefined
}

export const QuestionsAndAnswersItemContent = observer(
  ({
    questionId,
    sectionId,
    content,
  }: QuestionsAndAnswersItemContentProps): JSX.Element => {
    const { societyStore, authenticationStore, faqsStore } = useStores()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const {
      show: showEditModal,
      open: openEditModal,
      close: closeEditModal,
    } = useModal()
    const {
      show: showConfirmationModal,
      open: openConfirmationModal,
      close: closeConfirmationModal,
    } = useModal()

    const isAdmin = isUserAdminInSociety(
      society,
      authenticationStore.userId as string
    )

    const removeQuestion = async (): Promise<void> => {
      const status = await faqsStore.deleteFaqQuestion(questionId)
      if (status) {
        await societyStore.getSociety(society?._id as string)

        setToastNotification(
          ToastType.SUCCESS,
          translate(
            'createUpdateQuestionsAndAnswers.flashMessage.deleteQuestionSuccess'
          )
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate(
            'createUpdateQuestionsAndAnswers.flashMessage.deleteQuestionFailure'
          )
        )
      }
    }

    return (
      <>
        <div className="flex flex-col py-4">
          <p className="inline-block break-words">
            <Linkify textClassName="text-brandGreen">
              <span>{content}</span>
            </Linkify>
          </p>
          {isAdmin && (
            <div className="mt-6 flex flex-row space-x-3">
              <Button
                onClick={openEditModal}
                label={translate('common.actions.edit')}
              />
              <Button
                variant={ButtonVariant.DANGER}
                onClick={openConfirmationModal}
                label={translate('common.actions.delete')}
              />
            </div>
          )}
        </div>
        <CreateUpdateQuestionsAndAnswersItemModal
          questionId={questionId}
          sectionId={sectionId}
          show={showEditModal}
          close={closeEditModal}
        />
        <ConfirmationModal
          title={translate(
            'createUpdateQuestionsAndAnswers.confirmationModal.title'
          )}
          description={translate(
            'createUpdateQuestionsAndAnswers.confirmationModal.description'
          )}
          show={showConfirmationModal}
          close={closeConfirmationModal}
          onConfirm={removeQuestion}
          deleteMode
        />
      </>
    )
  }
)
