/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, SnapshotOut, getRoot } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import {
  getLocalOffersPerSocieties as apiGetLocalOffersPerSocieties,
  getLocalOffer as apiGetLocalOffer,
  patchLocalOffer as apiPatchLocalOffer,
  createLocalOffer as apiCreateLocalOffer,
  deleteLocalOffer as apiDeleteLocalOffer,
} from '../../api/local-offers'
import { stateType } from '../types/common'
import { setObject } from './helpers'
import { sortStringsAlphabetically } from '../../helpers/sorting'
import { LocalOfferModel } from '../models/local-offer'
import { ILocalOfferModel } from '../../interfaces/models/local-offers.interfaces'
import { NLocalOffers } from '../../interfaces/services/local-offers.interfaces'
import { RootStore } from './root'

export const LocalOfferStore = types
  .model('LocalOfferStore')
  .props({
    localOffers: types.map(LocalOfferModel),
    fetchingLocalOffers: stateType,
    updatingLocalOffer: stateType,
    creatingLocalOffer: stateType,
  })
  .views((self) => ({
    sortedLocalOffersForSociety(
      societyId: string
    ): SnapshotOut<typeof LocalOfferModel>[] {
      // @ts-ignore
      return (values(self.localOffers) as SnapshotOut<typeof LocalOfferModel>[])
        .filter((rule) => rule.societyId === societyId)
        .sort((a, b) => sortStringsAlphabetically(a.companyName, b.companyName))
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.localOffers.clear()
      self.fetchingLocalOffers = 'none'
      self.updatingLocalOffer = 'none'
      self.creatingLocalOffer = 'none'
    },
    setLocalOffers: (localOffers: ILocalOfferModel[]) => {
      localOffers.forEach((localOffer) => {
        setObject<typeof LocalOfferModel>(
          // @ts-ignore
          self.localOffers,
          LocalOfferModel,
          localOffer
        )
      })
    },
  }))
  .actions((self) => ({
    getLocalOffersPerSociety: flow(function* getLocalOffersPerSociety(
      societyId: string
    ) {
      self.fetchingLocalOffers = 'pending'
      try {
        const resp = yield apiGetLocalOffersPerSocieties(societyId)
        const data = resp.data as NLocalOffers.NGetPerSocieties.IResponse
        const localOffers = data.data
        const { media } = data.populated

        const { mediaStore } = getRoot<RootStore>(self)
        if (media) {
          mediaStore.setMedia(media)
        }

        self.setLocalOffers(localOffers)

        self.fetchingLocalOffers = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingLocalOffers = 'error'
      }
    }),
    getLocalOffer: flow(function* getLocalOffer(id: string) {
      self.fetchingLocalOffers = 'pending'
      try {
        const resp = yield apiGetLocalOffer(id)
        const data = resp.data as NLocalOffers.NGetById.IResponse
        const localOffer = data.data
        const { media } = data.populated

        const { mediaStore } = getRoot<RootStore>(self)
        if (media) {
          mediaStore.setMedia(media)
        }

        self.setLocalOffers([localOffer])

        self.fetchingLocalOffers = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingLocalOffers = 'error'
      }
    }),
    updateLocalOffer: flow(function* updateLocalOffer(
      id: string,
      body: NLocalOffers.NPatch.IRequestBody
    ) {
      self.updatingLocalOffer = 'pending'
      try {
        const resp = yield apiPatchLocalOffer(id, body)
        const data = resp.data as NLocalOffers.NPatch.IResponse
        const localOffer = data.data

        if (localOffer !== null) {
          self.setLocalOffers([localOffer])
        }

        self.updatingLocalOffer = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingLocalOffer = 'error'
        return false
      }
    }),
    createLocalOffer: flow(function* createLocalOffer(
      body: NLocalOffers.NCreate.IRequestBody
    ) {
      self.creatingLocalOffer = 'pending'
      try {
        const resp = yield apiCreateLocalOffer(body)
        const data = resp.data as NLocalOffers.NCreate.IResponse
        const localOffer = data.data

        if (localOffer !== null) {
          self.setLocalOffers([localOffer])
        }

        self.creatingLocalOffer = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingLocalOffer = 'error'
        return false
      }
    }),
    deleteLocalOffer: flow(function* deleteLocalOffer(id: string) {
      try {
        yield apiDeleteLocalOffer(id)

        const { postStore } = getRoot<RootStore>(self)
        const postsReferringLocalOffer = postStore.sortedPosts
          // @ts-ignore
          .filter((_post) => _post.localOfferIds.includes(id))
          .map((_post) => _post._id)
        postsReferringLocalOffer.forEach((_id) => postStore.deletePost(_id))

        self.localOffers.delete(id)
        return true
      } catch (error) {
        captureException(error)
        return false
      }
    }),
  }))
