import { useCallback, useEffect, useState } from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../hooks/useStores'
import { ChatRoomModel } from '../../../state/models/chat-room'
import { ActiveTabBar } from '../ChatSideBarContent'
import { useChatErrorState } from './useChatErrorState'

type TabFetchingStatus = 'done' | 'none' | 'pending' | 'error'

interface ChatRoomsDataProps {
  activeTabBar: ActiveTabBar
  activeTab: number
}

interface ChatRoomsDataReturnType {
  currentChatRooms: SnapshotOut<typeof ChatRoomModel>[]
  loadMoreChatRooms: () => void
  showSpinner: boolean | null
}

export const useChatRoomsData = ({
  activeTabBar,
  activeTab,
}: ChatRoomsDataProps): ChatRoomsDataReturnType => {
  const {
    chatRoomStore,
    notificationsStore,
    authenticationStore,
    societyStore,
  } = useStores()
  const { hasFetchedSocietiesOnce } = societyStore
  const [showSpinner, setShowSpinner] = useState<boolean | null>(true)
  const userId = authenticationStore.userId as string
  const societies = societyStore.sortedSocieties

  const isCurrentTabStatus = useCallback(
    (status: TabFetchingStatus): boolean => {
      if (activeTabBar === ActiveTabBar.RESIDENT) {
        switch (activeTab) {
          case 1:
            return chatRoomStore.fetchingChatRoomsQuestions === status
          default:
            return (
              chatRoomStore.fetchingChatRoomsOneOnOne === status &&
              chatRoomStore.fetchingChatRoomsInterests === status
            )
        }
      } else {
        switch (activeTab) {
          case 1:
            return chatRoomStore.fetchingChatRoomsQuestions === status
          default:
            return chatRoomStore.fetchingChatRoomsBoard === status
        }
      }
    },
    [chatRoomStore, activeTab, activeTabBar]
  )

  const societiesWithDisabledOneOnOneChats = societies
    .filter((_society) => _society.settings?.chatOneOnOneDisabled)
    .map((_society) => _society._id)

  const societiesWithDisabledGroupChats = societies
    .filter((_society) => _society.settings?.chatInterestsDisabled)
    .map((_society) => _society._id)

  const getListData = (): SnapshotOut<typeof ChatRoomModel>[] => {
    if (activeTabBar === ActiveTabBar.RESIDENT) {
      switch (activeTab) {
        case 1:
          return chatRoomStore.residentQuestionsChatRooms(userId)
        default:
          return chatRoomStore.sortChatRooms([
            ...chatRoomStore.oneOnOneChatRooms.filter(
              (_room) =>
                !societiesWithDisabledOneOnOneChats.includes(_room.societyId)
            ),
            ...chatRoomStore
              .interestsChatRoomsParticipatingIn(userId)
              .filter(
                (_room) =>
                  !societiesWithDisabledGroupChats.includes(_room.societyId)
              ),
          ])
      }
    } else {
      switch (activeTab) {
        case 1:
          return chatRoomStore.questionsChatRooms
        default:
          return chatRoomStore.boardChatRooms
      }
    }
  }

  const filterArchived = (
    chatRooms: SnapshotOut<typeof ChatRoomModel>[]
  ): SnapshotOut<typeof ChatRoomModel>[] => {
    return chatRooms.filter(
      (_chatRoom) =>
        _chatRoom.userSettings.find(
          (_setting) =>
            _setting.userId === userId &&
            _setting.hideUntilNotification === true
        ) === undefined
    )
  }

  const getUserRoomsData = useCallback(
    async (fetchNextPage: boolean): Promise<void> => {
      if (isCurrentTabStatus('pending')) {
        return
      }
      if (activeTabBar === ActiveTabBar.RESIDENT) {
        switch (activeTab) {
          case 1:
            if (
              !chatRoomStore.hasFetchedUserRoomsQuestionsResidentOnce ||
              fetchNextPage
            ) {
              await chatRoomStore.getUserRoomsQuestions(
                userId,
                'resident',
                fetchNextPage
              )
              notificationsStore.setNotificationTypesSeen([
                'chat-questions-new-message-residents',
              ])
            }
            break
          default:
            if (
              (!chatRoomStore.hasFetchedUserRoomsOneOnOneOnce &&
                !chatRoomStore.hasFetchedUserRoomsInterestsOnce) ||
              fetchNextPage
            ) {
              await Promise.all([
                chatRoomStore.getUserRoomsOneOnOne(fetchNextPage),
                chatRoomStore.getUserRoomsInterests(fetchNextPage),
              ])
              notificationsStore.setNotificationTypesSeen([
                'chat-oneonone-new-message',
                'chat-interests-new-message',
              ])
            }
            break
        }
      } else {
        switch (activeTab) {
          case 1:
            if (
              !chatRoomStore.hasFetchedUserRoomsQuestionsBoardOnce ||
              fetchNextPage
            ) {
              await chatRoomStore.getUserRoomsQuestions(
                userId,
                'board',
                fetchNextPage
              )
              notificationsStore.setNotificationTypesSeen([
                'chat-questions-new-message-board',
              ])
            }
            break
          default:
            if (!chatRoomStore.hasFetchedUserRoomsBoardOnce || fetchNextPage) {
              await chatRoomStore.getUserRoomsBoard(fetchNextPage)
              notificationsStore.setNotificationTypesSeen([
                'chat-board-new-message',
              ])
            }
            break
        }
      }
    },
    [
      activeTab,
      activeTabBar,
      chatRoomStore,
      isCurrentTabStatus,
      notificationsStore,
      userId,
    ]
  )

  const loadMoreChatRooms = (): void => {
    !isCurrentTabStatus('pending') && getUserRoomsData(true)
  }

  const currentChatRooms = filterArchived(getListData())

  const dataFetchDone = isCurrentTabStatus('done')
  const dataFetchNone = isCurrentTabStatus('none')

  useEffect(() => {
    getUserRoomsData(false)
  }, [activeTabBar, activeTab, getUserRoomsData])

  useEffect(() => {
    if (dataFetchDone && hasFetchedSocietiesOnce) {
      setShowSpinner(false)
    }
    if (dataFetchNone) {
      setShowSpinner(true)
    }
  }, [currentChatRooms, dataFetchDone, dataFetchNone, hasFetchedSocietiesOnce])

  useChatErrorState({ activeTabBar, activeTab, currentChatRooms, showSpinner })

  return {
    loadMoreChatRooms,
    currentChatRooms,
    showSpinner,
  }
}
