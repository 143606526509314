import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { PostModel } from '../../../state/models/post'
import { Modal } from '../../common/Modal'
import { ModalBody } from '../../common/Modal/ModalBody'
import { ModalHeader } from '../../common/Modal/ModalHeader'
import { CreateEditPostModalContent } from './CreateEditPostModalContent'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { useStores } from '../../../hooks/useStores'
import { societyCreatePostSchema } from '../../../forms/schemas/society_create_post'
import { NPosts } from '../../../interfaces/services/posts.interfaces'
import { ToastType } from '../../common/Toast/toast-type'

interface CreateEditPostModalProps {
  postId?: string
  show: boolean
  close: () => void
}

export const CreateEditPostModal = observer(
  ({ postId, show, close }: CreateEditPostModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const { postStore } = useStores()

    const updateMode = !!postId

    const onSubmit = async (
      data: InferType<typeof societyCreatePostSchema>
    ): Promise<void> => {
      // TODO: Delete dangling nodes (poll/documents/events/images)
      if (updateMode) {
        const body: NPosts.NPatch.IRequestBody =
          data as NPosts.NPatch.IRequestBody
        const status = await postStore.patchPost(postId, body)
        if (status) {
          close()
          setToastNotification(
            ToastType.SUCCESS,
            translate('postCreateUpdateBase.flashMessage.updatePostSuccess')
          )
        } else {
          setToastNotification(
            ToastType.DANGER,
            translate('postCreateUpdateBase.flashMessage.updatePostFailure')
          )
        }
      } else {
        const body: NPosts.NCreate.IRequestBody =
          data as NPosts.NCreate.IRequestBody
        const status = await postStore.createPost(body)
        if (status) {
          close()
          setToastNotification(
            ToastType.SUCCESS,
            translate('postCreateUpdateBase.flashMessage.createPostSuccess')
          )
        } else {
          setToastNotification(
            ToastType.DANGER,
            translate('postCreateUpdateBase.flashMessage.createPostFailure')
          )
        }
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      if (updateMode) {
        setToastNotification(
          ToastType.DANGER,
          translate('postCreateUpdateBase.flashMessage.updatePostFailure')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('postCreateUpdateBase.flashMessage.createPostFailure')
        )
      }
    }

    const loading =
      postStore.creatingPost === 'pending' ||
      postStore.updatingPost === 'pending'

    const post =
      (postId &&
        (postStore.posts.get(postId) as SnapshotOut<typeof PostModel>)) ||
      undefined

    return (
      <Modal show={show} size="xl">
        {{
          header: (
            <ModalHeader onClose={close}>
              {translate('feedView.modalTitle')}
            </ModalHeader>
          ),
          body: (
            <ModalBody className="flex h-[850px]" padding={false}>
              <CreateEditPostModalContent
                post={post}
                onError={onError}
                onSubmit={onSubmit}
                onClose={close}
                loading={loading}
              />
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
