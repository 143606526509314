import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { observer } from 'mobx-react-lite'
import { CommentModel } from '../../../state/models/comment'
import { TextArea } from '../../common/TextArea'
import { Button, ButtonVariant } from '../../common/Button'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { commentSchema } from '../../../forms/schemas/comment'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'

interface CommentEditProps {
  comment: SnapshotOut<typeof CommentModel>
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>
}

export const CommentEdit = observer(
  ({ comment, setEditMode }: CommentEditProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { commentStore } = useStores()
    const { setToastNotification } = useToastNotifications()

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const {
      control,
      handleSubmit,
      setValue,
      formState: { isValid },
    } = useForm<InferType<typeof commentSchema>>({
      mode: 'all',
      resolver: yupResolver(commentSchema),
      defaultValues: { comment: comment.message },
    })

    const onSubmit = async (
      data: InferType<typeof commentSchema>
    ): Promise<void> => {
      const success = await commentStore.patchComment(comment._id, {
        message: data.comment,
      })
      if (success) {
        setValue('comment', '')
        setEditMode(false)
      } else {
        showErrorMessage()
      }
    }

    const onError = (): void => {
      showErrorMessage()
    }

    // const onTextAreaEnterSubmit = (): void => {
    //   handleSubmit(onSubmit)()
    // }

    const onClose = (): void => {
      setEditMode(false)
    }

    return (
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Controller
          control={control}
          name="comment"
          render={({ field: { value, onChange, onBlur } }) => (
            <TextArea
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              fixedHeight={false}
              // handleSubmit={onTextAreaEnterSubmit}
            />
          )}
        />
        <div className="flex flex-wrap justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
            size="sm"
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid}
            label={translate('common.actions.save')}
            type="submit"
            size="sm"
          />
        </div>
      </form>
    )
  }
)
