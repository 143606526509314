/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'

export const PollOptionModel = types.model('PollOption', {
  _id: types.identifier,
  text: types.string,
})

export const PollVoteType = types.model({
  userId: types.string,
  option: types.reference(PollOptionModel),
})

export const PollVotesType = types.array(PollVoteType)

export const PollModel = types
  .model('Poll', {
    _id: types.identifier,
    ...timestampTypes,
    title: types.string,
    options: types.array(types.reference(PollOptionModel)),
    societyId: types.string,
    userId: types.string,
    votes: types.maybe(PollVotesType),
    type: types.maybe(types.enumeration('PollType', ['per-user', 'per-unit'])),
  })
  .actions((self) => ({
    addVote(userId: string, optionId: string) {
      const vote = {
        userId,
        option: optionId,
      }
      if (self.votes) {
        self.votes.push(vote)
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        self.votes = [vote]
      }
    },
    removeVote(userId: string) {
      if (self.votes) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        self.votes = self.votes.filter((vote) => vote.userId !== userId)
      }
    },
  }))
