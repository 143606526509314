import React from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { useAuthenticatedUserId } from '../../../hooks/useAuthenticatedUserId'
import { isUserAdminInSociety } from '../../../helpers/society'
import { UnauthorizedView } from '../../error/UnauthorizedView'

export const SocietyAdminRoot = observer((): JSX.Element => {
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const authUserId = useAuthenticatedUserId()

  const currentUserIsAdmin = isUserAdminInSociety(society, authUserId as string)

  if (!currentUserIsAdmin) {
    return <UnauthorizedView />
  }

  return <Outlet context={{ society }} />
})
