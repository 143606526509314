import React, { useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import { societyBoardCreateSchema } from '../../../../forms/schemas/society_board_create'
import { BoardRole } from '../../../../types/board-roles'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { useStores } from '../../../../hooks/useStores'
import {
  isUserAdminInSociety,
  translateRole,
} from '../../../../helpers/society'
import { SelectDropdown } from '../../../common/SelectDropdown'
import { useUserRole } from '../../../../hooks/useUserRole'
// eslint-disable-next-line max-len
import { useNonBoardMemberResidentUsersOptions } from '../../../../hooks/useNonBoardMemberResidentUsersOptions'
import { FormSpacing } from '../../../common/FormSpacing'

interface CreateUpdateBoardMemberFormProps {
  onError: (error: unknown) => void
  onSubmit: (data: InferType<typeof societyBoardCreateSchema>) => Promise<void>
  onClose: () => void
  loading: boolean
  userId?: string
}

export const CreateUpdateBoardMemberForm = observer(
  ({
    userId,
    loading,
    onError,
    onSubmit,
    onClose,
  }: CreateUpdateBoardMemberFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { userStore, authenticationStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()
    const { getErrorMessage } = useFormErrorMessage()
    const userRole = userId && useUserRole(userId, society)
    const nonBoardMemberResidentUsersOptions =
      useNonBoardMemberResidentUsersOptions(society)

    const updateMode = !!userId
    const user = userId ? userStore.users.get(userId) : undefined

    const isAdmin = isUserAdminInSociety(
      society,
      authenticationStore.userId as string
    )

    if (
      !isAdmin ||
      (updateMode && user === undefined) ||
      (updateMode && userRole === undefined)
    ) {
      onClose()
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const getDefaultValues = useCallback((): InferType<
      typeof societyBoardCreateSchema
    > => {
      return {
        role: updateMode ? (userRole as string) : BoardRole.MEMBER,
        userId: updateMode ? user?._id || '' : '',
      }
    }, [updateMode, user, userRole])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyBoardCreateSchema),
      defaultValues: getDefaultValues(),
    })

    const boardRoleOptions = useMemo(
      () =>
        Object.values(BoardRole).map((val) => ({
          value: val as string,
          label: translateRole(val) as string | '',
        })),
      []
    )

    return (
      <FormSpacing>
        <Controller
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <FormControl
              label={translate('createUpdateBoardMemberForm.labels.role')}
              name={name}
              error={errors.role && getErrorMessage(errors.role)}
            >
              <SelectDropdown
                value={value}
                onChange={onChange}
                options={boardRoleOptions}
                error={!!errors.role}
              />
            </FormControl>
          )}
          name="role"
        />
        {!updateMode && (
          <Controller
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <FormControl
                label={translate('createUpdateBoardMemberForm.labels.user')}
                name={name}
                error={errors.userId && getErrorMessage(errors.userId)}
              >
                <SelectDropdown
                  value={value}
                  onChange={onChange}
                  options={nonBoardMemberResidentUsersOptions}
                  isSearchable
                  error={!!errors.userId}
                />
              </FormControl>
            )}
            name="userId"
          />
        )}
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-3">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
            variant={ButtonVariant.TEXT}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid || loading}
            label={translate(
              updateMode ? 'common.actions.save' : 'common.actions.add'
            )}
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </FormSpacing>
    )
  }
)
