import React, {
  KeyboardEvent,
  useState,
  MutableRefObject,
  useRef,
  FocusEvent,
  MouseEvent,
  useEffect,
} from 'react'
import { IconChoices } from '../Icon'
import { DropdownOption } from './DropdownOption'

export interface Option {
  label: string
  value: string
  onClick?: (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLDivElement>) => void
  destructive?: boolean
  icon?: IconChoices
  customContent?: React.ReactNode
  disabled?: boolean
}

interface DropdownProps {
  options: Option[]
  children: React.ReactNode
  dropdownPosition?: 'bottom-left' | 'bottom-right'
  dropdownContentWidth?: string
  className?: string
  dropdownClassName?: string
  style?: React.CSSProperties
  submitButton?: React.ReactNode
  disabled?: boolean
  openChanged?: (open: boolean) => void
}

export const Dropdown = ({
  options,
  children,
  dropdownPosition = 'bottom-left',
  dropdownContentWidth = 'w-48',
  className,
  dropdownClassName,
  style,
  submitButton,
  disabled,
  openChanged,
}: DropdownProps): JSX.Element => {
  const [show, setShow] = useState(false)
  const iconDivRef = useRef() as MutableRefObject<HTMLDivElement>
  const ulRef = useRef() as MutableRefObject<HTMLUListElement>

  const toggle = (): void => {
    setShow(!show)
    openChanged?.(!show)
  }

  const close = (): void => {
    setShow(false)
    openChanged?.(false)
  }

  const onBlur = (event: FocusEvent<HTMLUListElement>): void => {
    if (
      !event.currentTarget.contains(event.relatedTarget) &&
      event.relatedTarget !== iconDivRef.current &&
      !iconDivRef.current.contains(event.relatedTarget)
    ) {
      close()
    }
  }

  useEffect(() => {
    show && ulRef?.current?.focus()
  }, [show])

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter' && !disabled) {
      e.preventDefault()
      e.stopPropagation()
      toggle()
    }
  }

  const onClick = (event: MouseEvent<HTMLElement>): void => {
    if (!disabled) {
      event.preventDefault()
      event.stopPropagation()
      toggle()
    }
  }

  const getDropdownPosition = (_size: string): string => {
    switch (_size) {
      case 'bottom-right':
        return 'left-0 top-full mt-3'
      default:
        return 'right-0 top-full mt-3' // bottom-left
    }
  }

  const onSubmitClick = (
    e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ): void => {
    e.stopPropagation()
    toggle()
  }

  return (
    <div
      className={`relative flex ${className ?? ''} ${
        disabled ? 'pointer-events-none opacity-50' : ''
      }`}
      style={style}
    >
      <div
        ref={iconDivRef}
        className="inline-flex w-full justify-center"
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        onClick={onClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
      {show && (
        <ul
          ref={ulRef}
          onBlur={onBlur}
          className={`absolute ${getDropdownPosition(dropdownPosition)}
            ${dropdownContentWidth} ${dropdownClassName || ''}
            z-30 origin-top-right rounded-md bg-white shadow-lg
            ring-1 ring-black ring-opacity-5 focus:outline-none`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          {options.map((_option) => (
            <DropdownOption
              key={_option.value}
              label={_option.label}
              value={_option.value}
              onClick={_option.onClick}
              close={close}
              destructive={_option.destructive}
              icon={_option.icon}
              customContent={_option.customContent}
              disabled={_option.disabled}
              disableOuterPadding={options.length === 1}
            />
          ))}
          {submitButton && (
            <div
              onKeyDown={handleKeyDown}
              role="button"
              tabIndex={0}
              onClick={onSubmitClick}
            >
              {submitButton}
            </div>
          )}
        </ul>
      )}
    </div>
  )
}
