import React, { useCallback, useState } from 'react'
import Fuse from 'fuse.js'
import { observer } from 'mobx-react-lite'
import { v4 as uuid } from 'uuid'
import { SpinnerWrapper } from '../../common/SpinnerWrapper'
import { ErrorView } from '../../../views/error/ErrorView'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { IllustrationChoices } from '../../common/Illustration'
import { useStores } from '../../../hooks/useStores'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { AccordionSection } from '../../common/AccordionSection'
import { RegisterEntranceItem } from '../RegisterEntranceItem/RegisterEntranceItem'
import { SearchBar } from '../../common/SearchBar'

export const RegisterEntranceList = observer((): JSX.Element => {
  const [searchString, setSearchString] = useState('')
  const { translate } = useAppTranslation()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const { societyStore, societyEntrancesStore } = useStores()

  const loading = societyStore.fetchingSociety === 'pending'

  const entrances = societyEntrancesStore.entrancesForSociety(society._id)

  const getFilteredEntrances = useCallback(() => {
    const options = {
      threshold: 0.0,
      keys: ['addressStreet'],
    }

    const fuse = new Fuse(entrances, options)

    const filteredEntrances =
      searchString === ''
        ? entrances
        : fuse.search(searchString).map((result) => result.item)

    return filteredEntrances
  }, [entrances, searchString])

  const onSearchBarChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setSearchString(e.currentTarget.value)
  }

  if (loading) {
    return <SpinnerWrapper />
  }

  if (!loading && entrances.length === 0) {
    return (
      <ErrorView
        title={translate('common.emptyState.title')}
        subtitle={translate('common.emptyState.subtitle')}
        illustration={IllustrationChoices.EMPTY}
      />
    )
  }

  const _filteredEntrances = getFilteredEntrances()

  return (
    <div className="w-full space-y-4">
      <SearchBar
        className="w-full md:w-80"
        widthClassName="flex grow"
        value={searchString}
        onChange={onSearchBarChange}
      />
      {_filteredEntrances.length > 0 && (
        <AccordionSection
          items={_filteredEntrances.map((_entrance) => ({
            id: uuid(),
            title: `${_entrance.addressStreet}`,
            content: (
              <RegisterEntranceItem key={_entrance._id} entrance={_entrance} />
            ),
          }))}
        />
      )}
    </div>
  )
})
