import React from 'react'
import { theme } from '../../../theme/theme'

interface MenuHeaderProps {
  label: string
  className?: string
}

export const MenuHeader = ({
  label,
  className,
}: MenuHeaderProps): JSX.Element => {
  return (
    <p
      style={theme.textVariants.lead}
      className={`flex h-10 items-center ${className ?? ''}`}
    >
      {label}
    </p>
  )
}
