import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useStores } from '../../../../hooks/useStores'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { CalendarList } from '../../../../components/society/CalendarList'
import { ViewBase } from '../../../../components/common/ViewBase'
import { IconChoices } from '../../../../components/common/Icon'
import { useModal } from '../../../../hooks/useModal'
import { ButtonVariant } from '../../../../components/common/Button'
import { CreateUpdateEventModal } from '../../../../components/society/Event/CreateUpdateEventModal'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { BoardRoomEventModel } from '../../../../state/models/boardroom-event'

export enum ActiveTab {
  UPCOMING = 0,
  PREVIOUS = 1,
}

export const BoardCalendarView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { boardRoomEventStore } = useStores()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:calendar', {
      '{{ societyName }}': society.name,
    })
  )
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.UPCOMING)
  const tabs = [
    {
      title: translate('societyCalendarListView.activeTabBar.upcomingTitle'),
    },
    {
      title: translate('societyCalendarListView.activeTabBar.previousTitle'),
    },
  ]

  const {
    show: showCreateModal,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModal()

  useEffect(() => {
    if (society._id) {
      boardRoomEventStore.getEventsPerSociety(society._id)
    }
  }, [boardRoomEventStore, society._id])

  const getEvents = useCallback((): {
    [key: string]: SnapshotOut<typeof BoardRoomEventModel>[]
  } => {
    const now = new Date()
    return boardRoomEventStore.eventsForSociety(society._id).reduce(
      (
        prev: {
          [key: string]: SnapshotOut<typeof BoardRoomEventModel>[]
        },
        event
      ) => {
        const group = new Date(event.endDate) < now ? 'previous' : 'upcoming'
        prev[group].push(event)
        return prev
      },
      { previous: [], upcoming: [] }
    )
  }, [boardRoomEventStore, society])

  const segmentedEvents = getEvents()

  const events =
    activeTab === ActiveTab.UPCOMING
      ? segmentedEvents.upcoming.reverse()
      : segmentedEvents.previous

  const loading = boardRoomEventStore.fetchingEvents === 'pending'

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('boardCalendarView.title')}
        tabBar={{ tabs, setActiveTab, activeTab }}
        buttons={[
          {
            label: translate('societyCalendarListView.buttons.create'),
            variant: ButtonVariant.PRIMARY,
            icon: IconChoices.PLUS_SIGN,
            onClick: openCreateModal,
          },
        ]}
      >
        <CalendarList
          events={events}
          userIsAdmin
          boardRoomEvent
          loading={loading}
        />
      </ViewBase>
      {showCreateModal && (
        <CreateUpdateEventModal
          show={showCreateModal}
          close={closeCreateModal}
          boardRoomEvent
        />
      )}
    </ErrorBoundary>
  )
})
