import React from 'react'
import { observer } from 'mobx-react-lite'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { ViewBase } from '../../../../components/common/ViewBase'
import { ButtonVariant } from '../../../../components/common/Button'
import { IconChoices } from '../../../../components/common/Icon'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { theme } from '../../../../theme/theme'
import { FunctionSettingsViewForm } from './FunctionSettingsViewForm'
import { FunctionSettingsCustomWidgets } from './FunctionSettingsCustomWidgets'
import { useModal } from '../../../../hooks/useModal'
// eslint-disable-next-line max-len
import { CreateUpdateWidgetModal } from '../../../../components/society/FunctionSettings/CreateUpdateWidgetModal'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'

export const FunctionSettingsView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:function-settings', {
      '{{ societyName }}': society.name,
    })
  )
  const {
    show: showCreateWidgetModal,
    open: openCreateWidgetModal,
    close: closeCreateWidgetModal,
  } = useModal()

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('functionSettingsView.title')}
        subtitle={translate('functionSettingsView.subtitle')}
        buttons={[
          {
            label: translate('functionSettingsView.buttons.addFunction'),
            variant: ButtonVariant.PRIMARY,
            icon: IconChoices.PLUS_SIGN,
            onClick: openCreateWidgetModal,
          },
        ]}
      >
        <div className="w-full lg:w-2/5">
          <div className="flex justify-between pb-3">
            <p style={theme.textVariants.baseBold}>
              {translate('functionSettingsView.table.titles.function')}
            </p>
            <p style={theme.textVariants.baseBold}>
              {translate('functionSettingsView.table.titles.visible')}
            </p>
          </div>
          <FunctionSettingsViewForm />
          <FunctionSettingsCustomWidgets />
        </div>
      </ViewBase>
      <CreateUpdateWidgetModal
        show={showCreateWidgetModal}
        close={closeCreateWidgetModal}
      />
    </ErrorBoundary>
  )
})
