import React from 'react'
import { SimpleLink } from '../../../../../components/common/SimpleLink'
import { theme } from '../../../../../theme/theme'

export const MoreAbout = (): JSX.Element => {
  return (
    <div>
      <p>
        Det kan kännas överväldigande att sätta sig in i allt vad GDPR innebär.
        Nedan listas några bra källor för er som vill läsa på lite mer. Boappa
        ansvarar inte för innehållet på länkarna nedan.
      </p>
      <p style={theme.textVariants.captionBold} className="mt-4">
        Allmänt
      </p>
      <p>
        Läs om alla aspekter av GDPR på{' '}
        <SimpleLink
          href="https://www.imy.se/"
          text="Integritetsskyddsmyndighetens hemsida"
        />
      </p>
      <p style={theme.textVariants.captionBold} className="mt-4">
        Samfälligheter
      </p>
      <p>
        Läs GDPR för samfällighetsföreningar från{' '}
        <SimpleLink
          // eslint-disable-next-line max-len
          href="https://www.villaagarna.se/contentassets/e9d593342e77455fa02dc1b24041f388/gdpr-for-samfalligheter.pdf"
          text="Villaägarna"
        />
      </p>
    </div>
  )
}
