import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { MarketplaceListItem } from './MarketplaceListItem'
import { ResourceModel } from '../../../state/models/resource'

interface MarketplaceListProps {
  resources: SnapshotOut<typeof ResourceModel>[]
}

export const MarketplaceList = observer(
  ({ resources }: MarketplaceListProps): JSX.Element => {
    return (
      <div className="w-full space-y-4">
        {resources.map((_resource) => (
          <MarketplaceListItem key={_resource._id} resource={_resource} />
        ))}
      </div>
    )
  }
)
