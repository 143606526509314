import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { captureException } from '@sentry/react'
import { getPartnerId, ManagementPartner } from '../management_partner'
import { contactBostadsratternaSchema } from '../../../../forms/schemas/contact_bostadsratterna'
import { TextArea } from '../../../common/TextArea'
import { Button, ButtonVariant } from '../../../common/Button'
import { FormControl } from '../../../common/FormControl'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { NPartners } from '../../../../interfaces/services/partners.interfaces'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { askSupport } from '../../../../api/partners'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { Toggle } from '../../../common/Toggle'
import { HorizontalFormSection } from '../../../common/HorizontalFormSection'
import { Icon, IconChoices } from '../../../common/Icon'
import { TextTooltip } from '../../../common/Tooltip'

interface ManagementPartnerContactFormProps {
  partner: ManagementPartner
}

export const ManagementPartnerContactForm = observer(
  ({ partner }: ManagementPartnerContactFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const [sendingMessage, setSendingMessage] = useState(false)
    const { setToastNotification } = useToastNotifications()
    const { getErrorMessage } = useFormErrorMessage()
    const [referenceElement, setReferenceElement] = useState<Element | null>(
      null
    )

    const showErrorToast = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate(
          'societyManagementPartnersContactBaseScreen.flashMessage.messageSendFailure'
        )
      )
    }

    const showSuccessToast = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate(
          'societyManagementPartnersContactBaseScreen.flashMessage.messageSendSuccess'
        )
      )
    }

    const getDefaultValues = useCallback((): InferType<
      typeof contactBostadsratternaSchema
    > => {
      return {
        message: '',
        shareInformation: true,
      }
    }, [])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
      reset,
      watch,
    } = useForm({
      mode: 'all',
      resolver: yupResolver(contactBostadsratternaSchema),
      defaultValues: getDefaultValues(),
    })

    const onSubmit = async (
      data: InferType<typeof contactBostadsratternaSchema>
    ): Promise<void> => {
      setSendingMessage(true)

      if (!society) {
        showErrorToast()
        setSendingMessage(false)
        return
      }

      const body: NPartners.NAskSupport.IRequestBody = {
        message: data.message,
        partnerId: getPartnerId(partner),
        societyId: society._id,
      }

      try {
        await askSupport(body, {
          shareInformation: data.shareInformation,
        })
        setSendingMessage(false)

        reset(getDefaultValues())
        showSuccessToast()
      } catch (error) {
        setSendingMessage(false)
        captureException(error, {
          extra: { object: JSON.stringify({ data, body }) },
        })
        showErrorToast()
      }
    }

    const onError = (): void => {
      setSendingMessage(false)
      showErrorToast()
    }

    const titleIcon = (
      <TextTooltip
        title={translate(
          partner === ManagementPartner.BOSTADSRATTERNA
            ? 'managementPartnerContactForm.shareInformationTooltipBostadsratterna'
            : 'managementPartnerContactForm.shareInformationTooltipSamfalligheterna'
        )}
        referenceElement={referenceElement}
      >
        <Icon icon={IconChoices.QUESTION_MARK} ref={setReferenceElement} />
      </TextTooltip>
    )

    const watchShareInformation = watch('shareInformation')

    return (
      <div className="flex h-3/5 w-full flex-col justify-start md:justify-between">
        <div className="flex w-full flex-col">
          <Controller
            control={control}
            name="message"
            render={({ field: { name, value, onChange, onBlur } }) => (
              <FormControl
                className="mt-6 md:mt-10"
                name={name}
                helperText={translate(
                  'managementPartnerContactForm.helperText'
                )}
                label={translate(
                  'managementPartnerContactForm.writeQuestionTitle'
                )}
                error={errors.message && getErrorMessage(errors.message)}
              >
                <TextArea
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label={translate(
                    'managementPartnerContactForm.writeQuestionTitle'
                  )}
                  error={!!errors.message}
                  placeholder={translate(
                    'managementPartnerContactForm.writeQuestionPlaceholder'
                  )}
                />
              </FormControl>
            )}
          />
          <Controller
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <HorizontalFormSection
                title={translate(
                  'managementPartnerContactForm.shareInformationTitle'
                )}
                className="md:items-center"
                customIcon={titleIcon}
                fillWidth
              >
                <Toggle
                  name={name}
                  enabled={value || false}
                  onChange={onChange}
                />
              </HorizontalFormSection>
            )}
            name="shareInformation"
          />
        </div>
        <div className="mt-6 space-y-6 md:mt-10">
          <div className="border-t" />
          <div className="flex justify-end">
            <Button
              variant={ButtonVariant.PRIMARY}
              label={translate(
                'managementPartnerContactForm.sendQuestionTitle'
              )}
              disabled={sendingMessage || !isValid || !watchShareInformation}
              loading={sendingMessage}
              onClick={handleSubmit(onSubmit, onError)}
            />
          </div>
        </div>
      </div>
    )
  }
)
