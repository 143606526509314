/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import {
  getGroupsPerSociety as apiGetGroupsPerSociety,
  getGroup as apiGetGroup,
  deleteGroup as apiDeleteGroup,
  patchGroup as apiPatchGroup,
  createGroup as apiCreateGroup,
} from '../../api/groups'
import { stateType } from '../types/common'
import { setObject } from './helpers'
import { sortStringsAlphabetically } from '../../helpers/sorting'
import { GroupModel } from '../models/group'
import { IGroupModel } from '../../interfaces/models/groups.interfaces'
import { NGroups } from '../../interfaces/services/groups.interfaces'

export const GroupStore = types
  .model('GroupStore')
  .props({
    groups: types.map(GroupModel),
    fetchingGroups: stateType,
    updatingGroup: stateType,
    creatingGroup: stateType,
  })
  .views((self) => ({
    get sortedGroups() {
      // @ts-ignore
      return (values(self.groups) as SnapshotOut<typeof GroupModel>[]).sort(
        (a, b) => sortStringsAlphabetically(a.name, b.name)
      )
    },
  }))
  .views((self) => ({
    groupsForSociety(societyId: string): SnapshotOut<typeof GroupModel>[] {
      return self.sortedGroups.filter(
        // @ts-ignore
        (group) => group.societyId === societyId
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.groups.clear()
      self.fetchingGroups = 'none'
      self.updatingGroup = 'none'
      self.creatingGroup = 'none'
    },
    setGroups: (groups: IGroupModel[]) => {
      groups.forEach((group) => {
        // @ts-ignore
        setObject<typeof GroupModel>(self.groups, GroupModel, {
          ...group,
        })
      })
    },
  }))
  .actions((self) => ({
    getGroupsPerSociety: flow(function* getGroupsPerSociety(societyId: string) {
      self.fetchingGroups = 'pending'
      try {
        const resp = yield apiGetGroupsPerSociety(societyId)
        const data = resp.data as NGroups.NGetPerSocieties.IResponse
        const groups = data.data

        if (groups) {
          self.setGroups(groups)
        }

        self.fetchingGroups = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingGroups = 'error'
      }
    }),
    getGroup: flow(function* getGroup(id: string) {
      self.fetchingGroups = 'pending'
      try {
        const resp = yield apiGetGroup(id)
        const data = resp.data as NGroups.NGetById.IResponse
        const group = data.data

        self.setGroups([group])

        self.fetchingGroups = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.fetchingGroups = 'error'
        return false
      }
    }),
    createGroup: flow(function* createGroup(
      body: NGroups.NCreate.IRequestBody
    ) {
      self.creatingGroup = 'pending'
      try {
        const resp = yield apiCreateGroup(body)
        const data = resp.data as NGroups.NCreate.IResponse
        const group = data.data

        if (group !== null) {
          self.setGroups([group])
        }

        self.creatingGroup = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingGroup = 'error'
        return false
      }
    }),
    patchGroup: flow(function* patchGroup(
      id: string,
      body: NGroups.NPatch.IRequestBody
    ) {
      self.updatingGroup = 'pending'
      try {
        const resp = yield apiPatchGroup(id, body)
        const data = resp.data as NGroups.NPatch.IResponse
        const group = data.data

        if (group !== null) {
          self.setGroups([group])
        }

        self.updatingGroup = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingGroup = 'error'
        return false
      }
    }),
    deleteGroup: flow(function* deleteGroup(id: string) {
      try {
        yield apiDeleteGroup(id)
        self.groups.delete(id)
        return true
      } catch (error) {
        captureException(error)
        return false
      }
    }),
  }))
