import React, { HTMLInputTypeAttribute } from 'react'
import Checkmark from '../../../assets/images/checkmark.svg'

interface CheckboxProps {
  name: string
  value?: boolean
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  type?: HTMLInputTypeAttribute
}

export const Checkbox = ({
  name,
  type = 'checkbox',
  value,
  onChange,
}: CheckboxProps): JSX.Element => {
  return (
    <>
      <div className="flex">
        <label
          htmlFor={name}
          className="relative flex cursor-pointer text-sm font-medium"
        >
          <input
            id={name}
            type={type}
            onChange={onChange}
            checked={value}
            className="h-[18px] w-[18px]
              appearance-none rounded rounded
              border
              border-gray-dark
              bg-gray-100
              bg-gray-light
              checked:border"
          />
          <span className="absolute flex h-[18px] w-[18px] items-center justify-center">
            {value && <Checkmark className="h-[13px] w-[13px]" alt="enabled" />}
          </span>
        </label>
      </div>
    </>
  )
}
