import React from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { Icon, IconChoices } from '../../../common/Icon'
import { theme } from '../../../../theme/theme'
import { Avatar } from '../../../common/Avatar'

interface PostNavigationItemProps {
  text: string
  url: string
  mediaId?: string
  showAvatar?: boolean
}

export const PostNavigationItem = observer(
  ({
    text,
    url,
    mediaId,
    showAvatar,
  }: PostNavigationItemProps): JSX.Element | null => {
    return (
      <Link
        className="flex min-w-0 flex-row items-center gap-3 whitespace-nowrap 
        rounded bg-neutral-96 py-4 px-5 hover:bg-neutral-90"
        to={url}
        style={{ textDecoration: 'none' }}
      >
        {showAvatar && <Avatar size={30} mediaId={mediaId} />}
        <span
          className="overflow-hidden text-ellipsis text-black"
          style={theme.textVariants.baseBold}
        >
          {text}
        </span>
        <Icon icon={IconChoices.ARROW} size={24} highlighted />
      </Link>
    )
  }
)
