import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../common/Button'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { DropZone, DropZoneVariant } from '../../common/DropZone'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'
import { societyRegisterUploadSchema } from '../../../forms/schemas/society_register_upload'
import { TextInput } from '../../common/TextInput'
import { FormSpacing } from '../../common/FormSpacing'

interface RegisterExcelImportFormProps {
  onError: (error: unknown) => void
  onSubmit: (
    data: InferType<typeof societyRegisterUploadSchema>
  ) => Promise<void>
  onClose: () => void
  loading: boolean
  className?: string
}

export const RegisterExcelImportForm = observer(
  ({
    loading,
    onError,
    onSubmit,
    onClose,
    className,
  }: RegisterExcelImportFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()

    const getDefaultValues = useCallback((): InferType<
      typeof societyRegisterUploadSchema
    > => {
      return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        file: undefined,
      }
    }, [])

    const {
      control,
      handleSubmit,
      watch,
      setValue,
      formState: { isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyRegisterUploadSchema),
      defaultValues: getDefaultValues(),
    })

    const watchFile = watch('file')

    return (
      <FormSpacing className={`${className ?? ''}`}>
        <Controller
          control={control}
          render={({ field: { onChange } }) => (
            <DropZone
              onUpload={(status, file) => {
                if (status === 'accepted') {
                  onChange({
                    mimeType: file.type,
                    base64: file.base64,
                    filename: file.name,
                    name: file.name,
                  })
                } else {
                  setToastNotification(
                    ToastType.DANGER,
                    translate('flashMessage.somethingWentWrongError')
                  )
                }
              }}
              variant={DropZoneVariant.EXCEL}
            />
          )}
          name="file"
        />
        {watchFile && (
          <div className="flex w-full flex-row items-center space-x-4">
            <div className="w-full">
              <TextInput
                value={watchFile.filename}
                onChange={() => null}
                onBlur={() => null}
                disabled
                onDeleteIconPress={() =>
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setValue('file', undefined, {
                    shouldValidate: true,
                  })
                }
              />
            </div>
          </div>
        )}
        <div className="mt-4 flex flex-shrink-0 flex-wrap items-center justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            loading={loading}
            disabled={!isValid || loading}
            label={translate('registerView.upload')}
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </FormSpacing>
    )
  }
)
