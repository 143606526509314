import { reverseUrl } from '../../../navigation/reverseUrl'

export enum SuperAdminViewTabs {
  USERS = 0,
  SOCIETIES = 1,
  TWILIGHT_ZONE = 2,
  PENDING_INVOICES = 3,
  DISCOUNT_CODES = 4,
  AUDIT_LOGS = 5,
  DATA_EXPORT = 6,
  WEBINARS = 7,
}

type useSuperAdminTabsReturnType = {
  getTabUrl: (tab: SuperAdminViewTabs) => string
  getTabState: (pathname: string) => SuperAdminViewTabs
}

export const useSuperAdminTabs = (): useSuperAdminTabsReturnType => {
  const getTabUrl = (tab: SuperAdminViewTabs): string => {
    switch (tab) {
      case SuperAdminViewTabs.USERS:
        return reverseUrl('admin:users')
      case SuperAdminViewTabs.SOCIETIES:
        return reverseUrl('admin:societies')
      case SuperAdminViewTabs.TWILIGHT_ZONE:
        return reverseUrl('admin:twilight-zone')
      case SuperAdminViewTabs.PENDING_INVOICES:
        return reverseUrl('admin:pending-invoices')
      case SuperAdminViewTabs.DISCOUNT_CODES:
        return reverseUrl('admin:discount-codes')
      case SuperAdminViewTabs.AUDIT_LOGS:
        return reverseUrl('admin:audit-logs')
      case SuperAdminViewTabs.DATA_EXPORT:
        return reverseUrl('admin:data-export')
      case SuperAdminViewTabs.WEBINARS:
        return reverseUrl('admin:webinars')
      default:
        return reverseUrl('admin:users')
    }
  }

  const getTabState = (pathname: string): SuperAdminViewTabs => {
    if (pathname === reverseUrl('admin:users')) {
      return SuperAdminViewTabs.USERS
    }
    if (pathname === reverseUrl('admin:societies')) {
      return SuperAdminViewTabs.SOCIETIES
    }
    if (pathname === reverseUrl('admin:discount-codes')) {
      return SuperAdminViewTabs.DISCOUNT_CODES
    }
    if (pathname === reverseUrl('admin:pending-invoices')) {
      return SuperAdminViewTabs.PENDING_INVOICES
    }
    if (pathname === reverseUrl('admin:twilight-zone')) {
      return SuperAdminViewTabs.TWILIGHT_ZONE
    }
    if (pathname === reverseUrl('admin:audit-logs')) {
      return SuperAdminViewTabs.AUDIT_LOGS
    }
    if (pathname === reverseUrl('admin:data-export')) {
      return SuperAdminViewTabs.DATA_EXPORT
    }
    if (pathname === reverseUrl('admin:webinars')) {
      return SuperAdminViewTabs.WEBINARS
    }
    return SuperAdminViewTabs.USERS
  }

  return {
    getTabUrl,
    getTabState,
  }
}
