import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../hooks/useStores'
import { Avatar, UserAvatar } from '../../common/Avatar'
import { NotificationModel } from '../../../state/models/notification'
import { NotificationData } from '../../../types/notifications'

interface NotificationAvatarProps {
  notification: SnapshotOut<typeof NotificationModel>
  notificationData: NotificationData | undefined
}

export const NotificationAvatar = observer(
  ({
    notification,
    notificationData,
  }: NotificationAvatarProps): JSX.Element => {
    const { societyStore, userStore } = useStores()

    const notificationFromUser = notificationData && notificationData.from
    const notificationSociety = notificationData && notificationData.society
    const notificationRoom = notificationData && notificationData.room

    const society = societyStore.societies.get(
      notificationRoom?.societyId as string
    )
    const _society = societyStore.societies.get(
      notification.societyId as string
    )
    const user = userStore.users.get(notificationFromUser?._id as string)

    let media

    if (notificationSociety) {
      media = notificationSociety.coverPhotoId
    } else if (notificationRoom) {
      media = society?.coverPhotoId
    } else if (notification.societyId) {
      media = _society?.coverPhotoId
    }

    if (
      notification.type === 'invite-all-to-society' &&
      notificationSociety?.coverPhotoId
    ) {
      return <Avatar mediaId={notificationSociety.coverPhotoId} />
    }

    return notificationFromUser ? (
      <UserAvatar user={user} />
    ) : (
      <Avatar mediaId={media} />
    )
  }
)
