/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, getRoot, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import {
  getWebsitePerSociety as apiGetWebsitePerSociety,
  patchWebsite as apiPatchWebsite,
} from '../../api/society-websites'
import { stateType } from '../types/common'
import { RootStore } from './root'
import { setObject } from './helpers'
import {
  SocietyWebsiteBrokerQuestionsSectionModel,
  SocietyWebsiteModel,
} from '../models/society-website'
import { ISocietyWebsiteModel } from '../../interfaces/models/societies-websites.interfaces'
import { NSocietiesWebsites } from '../../interfaces/services/societies-websites.interfaces'

export const SocietyWebsiteStore = types
  .model('SocietyWebsiteStore')
  .props({
    websites: types.map(SocietyWebsiteModel),
    fetchingWebsites: stateType,
    updatingWebsite: stateType,
  })
  .views((self) => ({
    websiteForSociety(
      societyId: string
    ): SnapshotOut<typeof SocietyWebsiteModel> | undefined {
      return (
        // @ts-ignore
        (values(self.websites) as SnapshotOut<typeof SocietyWebsiteModel>[]) //
          // @ts-ignore
          .find((website) => website.society._id === societyId)
      )
    },
  }))
  .views((self) => ({
    websiteBrokerQuestionSection(
      societyId: string,
      sectionId: string | undefined
    ):
      | SnapshotOut<typeof SocietyWebsiteBrokerQuestionsSectionModel>
      | undefined {
      const website = self.websiteForSociety(societyId)
      return website?.brokerQuestion?.sections?.find(
        (_section) => _section._id === sectionId
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.websites.clear()
      self.fetchingWebsites = 'none'
      self.updatingWebsite = 'none'
    },
    setWebsites: (websites: ISocietyWebsiteModel[]) => {
      websites.forEach((website) => {
        setObject<typeof SocietyWebsiteModel>(
          // @ts-ignore
          self.websites,
          SocietyWebsiteModel,
          {
            ...website,
            society: website.societyId,
            pictureMain: website.pictureMainId,
            societyPictures: {
              ...website.societyPictures,
              pictures: website.societyPictures?.pictures.map((picture) => ({
                ...picture,
                media: picture.mediaId,
              })),
            },
          }
        )
      })
    },
  }))
  .actions((self) => ({
    getWebsitePerSociety: flow(function* getWebsitePerSociety(
      societyId: string
    ) {
      self.fetchingWebsites = 'pending'
      try {
        const resp = yield apiGetWebsitePerSociety(societyId)
        const data = resp.data as NSocietiesWebsites.NGetPerSocieties.IResponse
        const websites = data.data
        const { media, users, documents } = data.populated

        const { mediaStore, userStore, documentStore } =
          getRoot<RootStore>(self)
        if (media) {
          mediaStore.setMedia(media)
        }
        if (users) {
          userStore.setUsers(users)
        }
        if (documents) {
          documentStore.setDocuments(documents)
        }

        self.setWebsites(websites)

        self.fetchingWebsites = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingWebsites = 'error'
      }
    }),
    patchWebsite: flow(function* patchWebsite(
      id: string,
      body: NSocietiesWebsites.NPatch.IRequestBody
    ) {
      self.updatingWebsite = 'pending'
      try {
        const resp = yield apiPatchWebsite(id, body)
        const data = resp.data as NSocietiesWebsites.NPatch.IResponse
        const website = data.data

        if (website !== null) {
          self.setWebsites([website])
        }

        self.updatingWebsite = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingWebsite = 'error'
        return false
      }
    }),
  }))
