import { useEffect, useState } from 'react'

interface ModalHelperReturnType {
  show: boolean
  open: () => void
  close: () => void
}

interface ModalHelperProps {
  preventClose?: boolean
  closeAction?: () => void
}

export const useModal = ({
  preventClose,
  closeAction,
}: ModalHelperProps = {}): ModalHelperReturnType => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const close = (e: globalThis.KeyboardEvent): void => {
      if (e.key === 'Escape' && !preventClose) {
        if (closeAction) closeAction()
        setShow(false)
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [setShow, closeAction, preventClose])

  const open = (): void => {
    setShow(true)
  }

  const close = (): void => {
    setShow(false)
  }

  return { show, open, close }
}
