import React from 'react'
import { theme } from '../../../../theme/theme'

export interface TableColumn {
  key: string
  title: string
  accessor?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderItem?: (value: any) => JSX.Element
}

interface TableHeaderProps {
  columns: TableColumn[]
}

export const TableHeader = ({ columns }: TableHeaderProps): JSX.Element => {
  return (
    <thead className="h-12 rounded-t bg-neutral-96">
      <tr>
        {columns.map((column) => (
          <th
            key={column.key}
            className="pl-2 text-left"
            style={theme.textVariants.baseBold}
          >
            {column.title}
          </th>
        ))}
      </tr>
    </thead>
  )
}
