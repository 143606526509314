import React from 'react'
import { observer } from 'mobx-react-lite'
import { SpinnerWrapper } from '../../common/SpinnerWrapper'
import { ErrorView } from '../../../views/error/ErrorView'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { IllustrationChoices } from '../../common/Illustration'
import { useStores } from '../../../hooks/useStores'
import { useAuthenticatedUserId } from '../../../hooks/useAuthenticatedUserId'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { BookingListItem } from './BookingListItem'

export const BookingsList = observer((): JSX.Element => {
  const { bookingsStore, unitStore } = useStores()
  const { translate } = useAppTranslation()
  const userId = useAuthenticatedUserId() as string
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }

  const futureBookings = bookingsStore.futureBookingsForUnits(
    unitStore.userUnits(userId, society._id).map((_unit) => _unit._id)
  )

  const loading =
    unitStore.fetchingUnits === 'pending' ||
    bookingsStore.fetchingBookings === 'pending'

  if (loading) {
    return <SpinnerWrapper />
  }

  if (!loading && futureBookings.length === 0) {
    return (
      <ErrorView
        title={translate('facilitiesView.emptyState.noBookingsTitle')}
        subtitle={translate('facilitiesView.emptyState.noBookingsSubtitle')}
        illustration={IllustrationChoices.CHECKLIST}
        titleWrapperClassName="mt-8"
      />
    )
  }

  return (
    <div className="w-full space-y-4">
      {futureBookings.map((booking) => (
        <BookingListItem key={booking._id} booking={booking} />
      ))}
    </div>
  )
})
