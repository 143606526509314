import { AxiosResponse } from 'axios'
import { createUrl } from './helpers'
import axiosAuth from './config'
import { NRequests } from '../interfaces/services/requests.interfaces'

export const REQUESTS_POPULATE = ['users']

export const getRequestsPerSociety = (
  societyIds: string[]
): Promise<AxiosResponse<NRequests.NGetPerSocieties.IResponse>> => {
  const body: NRequests.NGetPerSocieties.IRequestBody = {
    societyIds,
  }
  return axiosAuth.post<NRequests.NGetPerSocieties.IResponse>(
    createUrl(`/requests/get-per-societies`, {
      populate: REQUESTS_POPULATE.join(';'),
    }),
    body
  )
}

export const getRequestsPerUser = (
  userId: string
): Promise<AxiosResponse<NRequests.NGetPerUser.IResponse>> => {
  return axiosAuth.get<NRequests.NGetPerUser.IResponse>(
    createUrl(`/requests/get-per-user/${userId}`)
  )
}

export const getRequest = (
  id: string
): Promise<AxiosResponse<NRequests.NGetById.IResponse>> => {
  return axiosAuth.get<NRequests.NGetById.IResponse>(
    createUrl(`/requests/request/${id}`, {
      populate: REQUESTS_POPULATE.join(';'),
    })
  )
}

export const denyRequest = (
  id: string
): Promise<AxiosResponse<NRequests.NDel.IResponse>> => {
  return axiosAuth.delete<NRequests.NDel.IResponse>(
    createUrl(`/requests/request/${id}`)
  )
}

export const acceptUsers = (
  body: NRequests.NAcceptUsers.IRequestBody
): Promise<AxiosResponse<NRequests.NAcceptUsers.IResponse>> => {
  return axiosAuth.post<NRequests.NAcceptUsers.IResponse>(
    createUrl(`/requests/accept-users`),
    body
  )
}

export const createRequest = (
  body: NRequests.NCreate.IRequestBody
): Promise<AxiosResponse<NRequests.NCreate.IResponse>> => {
  return axiosAuth.post<NRequests.NCreate.IResponse>(
    createUrl(`/requests/request`),
    body
  )
}
