import { observer } from 'mobx-react-lite'
import React from 'react'
import { theme } from '../../../theme/theme'

interface NotificationBadgeProps {
  children?: React.ReactNode
  show: boolean
  className?: string
  size?: number
  count?: number
  absoluteOffset?: boolean
}

export const NotificationBadge = observer(
  ({
    children,
    show,
    className,
    size,
    count,
    absoluteOffset = true,
  }: NotificationBadgeProps): JSX.Element => {
    const getSize = (): number => {
      if (size) return size
      if (count) return 17
      return 12
    }
    return (
      <div className={`relative ${className ?? ''}`}>
        {children}
        {show && (
          <div
            style={{
              height: getSize(),
              width: getSize(),
              backgroundColor: theme.colors.brandGreen,
              ...theme.textVariants.fineprint,
            }}
            className={`flex items-center
            justify-center rounded-full text-white
            ${absoluteOffset ? 'absolute top-[18%] right-[15%]' : ''}  
          `}
          >
            {count && count}
          </div>
        )}
      </div>
    )
  }
)
