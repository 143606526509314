import { AxiosResponse } from 'axios'
import { NNotifications } from '../interfaces/services/notifications.interfaces'
import { createUrl } from './helpers'
import axiosAuth from './config'

const prefix = '/notifications'

export const NOTIFICATION_POPULATE = ['users.media']

export const list = (
  query?: NNotifications.NList.IRequestQuery
): Promise<AxiosResponse<NNotifications.NList.IResponse>> => {
  return axiosAuth.get<NNotifications.NList.IResponse>(
    createUrl(`${prefix}/list`, {
      ...query,
      populate: NOTIFICATION_POPULATE.join(';'),
    })
  )
}

export const getNotification = (
  id: string
): Promise<AxiosResponse<NNotifications.NList.IResponse>> => {
  return axiosAuth.get<NNotifications.NList.IResponse>(
    createUrl(`${prefix}/notification/${id}`)
  )
}

export const setRead = (
  body: NNotifications.NSetRead.IRequestBody
): Promise<AxiosResponse<NNotifications.NSetRead.IResponse>> => {
  return axiosAuth.post<NNotifications.NSetRead.IResponse>(
    createUrl(`${prefix}/set-read`),
    body
  )
}

export const setSeen = (
  body: NNotifications.NSetSeen.IRequestBody
): Promise<AxiosResponse<NNotifications.NSetSeen.IResponse>> => {
  return axiosAuth.post<NNotifications.NSetSeen.IResponse>(
    createUrl(`${prefix}/set-seen`),
    body
  )
}

export const setTypesSeen = (
  body: NNotifications.NSetTypesSeen.IRequestBody
): Promise<AxiosResponse<NNotifications.NSetTypesSeen.IResponse>> => {
  return axiosAuth.post<NNotifications.NSetTypesSeen.IResponse>(
    createUrl(`${prefix}/set-types-seen`),
    body
  )
}
