import React from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../../common/Icon'

export interface SocietySideBarCallout {
  text: string
  path: string
  disabled?: boolean
  warning?: boolean
}

export const SocietySideBarCallout = observer(
  ({ text, path, disabled, warning }: SocietySideBarCallout): JSX.Element => {
    return (
      <Link
        to={path}
        className={`${disabled ? 'pointer-events-none opacity-50' : ''}`}
      >
        <div
          className={`flex items-center rounded-md 
          ${
            warning
              ? 'bg-red/40 hover:bg-red/30'
              : 'bg-brandLightGreen hover:bg-brandGreen/20'
          }
          py-2 pl-3 pr-1.5
         `}
        >
          <Icon
            icon={warning ? IconChoices.WARNING_AMBER : IconChoices.INFORMATION}
            color={theme.colors.brandGreen}
          />
          <p style={theme.textVariants.captionBold} className="w-full px-2">
            {text}
          </p>
          <Icon icon={IconChoices.ARROW} highlighted={!warning} />
        </div>
      </Link>
    )
  }
)
