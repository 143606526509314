import { SnapshotOut } from 'mobx-state-tree'
import { capitalize } from '../../../helpers/string'
import { UnitModel } from '../../../state/models/unit'

export const getUnitTitle = (unit: SnapshotOut<typeof UnitModel>): string => {
  const _titleLegal = unit.titleLegal
    ? capitalize(unit.titleLegal.toLowerCase())
    : undefined
  const _title = capitalize(unit.title.toLowerCase())

  if (_titleLegal) {
    return `${_titleLegal} (${_title})`
  }
  return _title
}
