import * as yup from 'yup'

export const societyResourceCreateSchema = yup
  .object({
    societyId: yup.string().required(),
    unit: yup.string().required(),
    userId: yup.string().required(),
    title: yup.string().required(),
    description: yup.string().required(),
    cost: yup.string().required(),
    mediaId: yup.string().required(),
    type: yup
      .string()
      .oneOf(['giveaway', 'lend', 'sell', 'rent-out'])
      .required(),
    isFree: yup.boolean().required(),
    notificationSettings: yup
      .string()
      .oneOf(['none', 'feed', 'notification+feed']),
  })
  .required()
