import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'

export const LocalOfferModel = types.model('LocalOfferModel', {
  _id: types.identifier,
  ...timestampTypes,
  bodyText: types.string,
  companyName: types.string,
  discountHeader: types.string,
  notificationSetting: types.string,
  societyId: types.maybe(types.string),
  email: types.maybe(types.string),
  phoneNumber: types.maybe(types.string),
  website: types.maybe(types.string),
  coverPhotoId: types.maybe(types.string),
})
