import React from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../../common/Icon'

export interface Callout {
  text: string
  path?: string
  disabled?: boolean
  warning?: boolean
  showArrow?: boolean
}

export const Callout = observer(
  ({
    text,
    warning,
    disabled,
    path,
    showArrow = true,
  }: Callout): JSX.Element => {
    const content = (
      <div
        className={`flex items-center rounded-md 
    ${warning ? 'bg-red/40' : 'bg-brandLightGreen'}
    ${path && !warning && 'hover:bg-brandGreen/20'}
    ${path && warning && 'hover:bg-red/30'}
    py-2 pl-3 pr-1.5
   `}
      >
        <Icon
          icon={warning ? IconChoices.WARNING_AMBER : IconChoices.INFORMATION}
          color={theme.colors.brandGreen}
        />
        <p style={theme.textVariants.captionBold} className="w-full px-2">
          {text}
        </p>
        {showArrow && <Icon icon={IconChoices.ARROW} highlighted={!warning} />}
      </div>
    )
    if (path) {
      return (
        <Link
          to={path}
          aria-disabled={disabled}
          className={`${disabled ? 'pointer-events-none opacity-50' : ''}`}
        >
          {content}
        </Link>
      )
    }
    return content
  }
)
