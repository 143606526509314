import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { InferType } from 'yup'
import {
  Button,
  ButtonVariant,
} from '../../../../../../components/common/Button'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { useStores } from '../../../../../../hooks/useStores'
import { theme } from '../../../../../../theme/theme'
import { ConfirmationModal } from '../../../../../../components/common/ConfirmationModal'
import { useModal } from '../../../../../../hooks/useModal'
import { Spinner } from '../../../../../../components/common/Spinner'
import { Checkbox } from '../../../../../../components/common/Checkbox'
import { updateUserEmailSchema } from '../../../../../../forms/schemas/update_user_email'
import { FormControl } from '../../../../../../components/common/FormControl'
import { TextInput } from '../../../../../../components/common/TextInput'
import { useFormErrorMessage } from '../../../../../../hooks/useFormErrorMessage'

interface ChangeUserEmailModalContentProps {
  onSubmit: (newUserEmail: string) => Promise<void>
  onClose: () => void
  userId: string
}

export const ChangeUserEmailModalContent = observer(
  ({
    onSubmit,
    onClose,
    userId,
  }: ChangeUserEmailModalContentProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { userStore } = useStores()
    const { getErrorMessage } = useFormErrorMessage()

    const {
      show: showChangeUserEmailConfirmationModal,
      open: openChangeUserEmailConfirmationModal,
      close: closeChangeUserEmailConfirmationModal,
    } = useModal()

    const [userHasEmailedConfirmation, setUserHasEmailedConfirmation] =
      useState(false)

    const getDefaultValues = (): InferType<typeof updateUserEmailSchema> => {
      return {
        newUserEmail: '',
      }
    }

    const {
      control,
      watch,
      formState: { isValid, errors },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(updateUserEmailSchema),
      defaultValues: getDefaultValues(),
    })

    const watchNewUserEmail = watch('newUserEmail')

    const onConfirmationPress = (): void => {
      closeChangeUserEmailConfirmationModal()
      onSubmit(watchNewUserEmail)
    }

    const onEmailConfirmationCheckboxClick = (): void => {
      setUserHasEmailedConfirmation(!userHasEmailedConfirmation)
    }

    const loading = userStore.fetchingUser === 'pending'

    const user = userStore.users.get(userId)

    if (loading) {
      return <Spinner />
    }

    if (!loading && !user) {
      return <p>Något gick fel.</p>
    }

    return (
      <form
        className="flex h-full flex-col gap-6"
        onSubmit={(e) => {
          e?.preventDefault()
          openChangeUserEmailConfirmationModal()
        }}
      >
        <div className="flex flex-col">
          <div className="flex flex-col  justify-center gap-10">
            <p style={theme.textVariants.h3}>
              Du ändrar nu mejladress för följande användare:
            </p>
            <div>
              <p style={theme.textVariants.base}>Namn: {user?.fullName}</p>
              <p style={theme.textVariants.base}>Email: {user?.email}</p>
            </div>
            <p
              className="animate-bounce text-red"
              style={theme.textVariants.h3}
            >
              Detta går inte att ångra!
            </p>
            <Controller
              control={control}
              render={({ field: { value, name, onChange, onBlur } }) => (
                <FormControl
                  label={translate('changeEmail.labels.newEmail')}
                  error={
                    errors.newUserEmail && getErrorMessage(errors.newUserEmail)
                  }
                  name={name}
                >
                  <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.newUserEmail}
                    type="email"
                  />
                </FormControl>
              )}
              name="newUserEmail"
            />
            <button
              type="button"
              className="flex items-center gap-2 text-left"
              onClick={onEmailConfirmationCheckboxClick}
            >
              <Checkbox
                name="email-confirmation"
                value={userHasEmailedConfirmation}
                onChange={onEmailConfirmationCheckboxClick}
              />
              <p style={{ ...theme.textVariants.baseBold }}>
                Jag bekräftar att användaren har mejlat mig från den gamla
                adressen <span className="underline">{user?.email}</span>, och
                den nya adressen{' '}
                {watchNewUserEmail && (
                  <span className="underline">{watchNewUserEmail}</span>
                )}{' '}
                ,och bett om att få mejladressen uppdaterad.
              </p>
            </button>
          </div>
        </div>
        <div className="flex flex-wrap justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            variant={ButtonVariant.DANGER}
            disabled={
              loading || !isValid || !userId || !userHasEmailedConfirmation
            }
            loading={loading}
            label={translate('common.actions.update')}
            type="submit"
          />
        </div>
        <ConfirmationModal
          title="Ändra mejladress"
          description="Är du säker på att du vill ändra användarens mejladress? Detta går inte att ångra."
          show={showChangeUserEmailConfirmationModal}
          close={closeChangeUserEmailConfirmationModal}
          onConfirm={onConfirmationPress}
          confirmationButtonLabel="Uppdatera"
        />
      </form>
    )
  }
)
