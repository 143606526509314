import {
  EducationLevel,
  EducationDegree,
  EducationField,
} from '../../types/education'
import { translateEnumDict } from './helpers'

const educationLevelTranslations: { [key: string]: string } = {
  [EducationLevel.HIGH_SCHOOL]: 'education.level.highSchool',
  [EducationLevel.PROFESSIONAL_SCHOOL]: 'education.level.professionalSchool',
  [EducationLevel.UNIVERSITY]: 'education.level.university',
  [EducationLevel.OTHER]: 'common.other',
  [EducationLevel.NONE]: 'common.none',
}

export const getEducationLevel = (education: EducationLevel): string | null => {
  return translateEnumDict(educationLevelTranslations, education)
}

const educationDegreeTranslations: { [key: string]: string } = {
  [EducationDegree.BACHELOR]: 'education.degree.bachelor',
  [EducationDegree.MASTER]: 'education.degree.master',
  [EducationDegree.PHD]: 'education.degree.phd',
  [EducationDegree.OTHER]: 'common.other',
  [EducationDegree.NONE]: 'common.none',
}

export const getEducationDegree = (degree: EducationDegree): string | null => {
  return translateEnumDict(educationDegreeTranslations, degree)
}

const educationFieldTranslations: { [key: string]: string } = {
  [EducationField.AIR_PILOT]: 'education.field.airPilot',
  [EducationField.ANIMAL_KEEPER]: 'education.field.animalKeeper',
  [EducationField.ARCHITECTURE]: 'education.field.architecture',
  [EducationField.BSC_OF_ENGINEERING]: 'education.field.bachelorOfEngineeering',
  [EducationField.BIO_MEDICINE]: 'education.field.bioMedicine',
  [EducationField.BUSINESS]: 'education.field.business',
  [EducationField.CIVIL_ENGINEERING]: 'education.field.civilEngineering',
  [EducationField.DENTAL_HYGIENE]: 'education.field.dentalHygiene',
  [EducationField.DENTIST]: 'education.field.dentist',
  [EducationField.DIETETICS]: 'education.field.dietetics',
  [EducationField.ENGINEERING]: 'education.field.engineering',
  [EducationField.ENVIRONMENTAL_SCIENCE]:
    'education.field.environmentalScience',
  [EducationField.FINANCE]: 'education.field.finance',
  [EducationField.FIRE_SCIENCE]: 'education.field.fireScience',
  [EducationField.FORESTERY]: 'education.field.forestry',
  [EducationField.FOREST_ENGINEERING]: 'education.field.forestEngineering',
  [EducationField.HEALTH_SCIENCE]: 'education.field.healthScience',
  [EducationField.HIPPOLOGY]: 'education.field.hippology',
  [EducationField.HUMANITIES]: 'education.field.humanities',
  [EducationField.HUMAN_RESOURCES]: 'education.field.humanResources',
  [EducationField.JOURNALISM]: 'education.field.journalism',
  [EducationField.LAW]: 'education.field.law',
  [EducationField.LIB_AND_INFO_SCIENCE]:
    'education.field.libraryAndInformationScience',
  [EducationField.LOGISTICS]: 'education.field.logistics',
  [EducationField.MARINE_SCIENCE]: 'education.field.marineScience',
  [EducationField.MEDIA_SCIENCE]:
    'education.field.mediaAndCommunicationScience',
  [EducationField.MEDICAL_DOCTOR]: 'education.field.medicalDoctor',
  [EducationField.NATURE_SCIENCE]: 'education.field.natureScience',
  [EducationField.NURSING]: 'education.field.nursing',
  [EducationField.OCCUPATIONAL_THERAPY]: 'education.field.occupationalTherapy',
  [EducationField.OPTOMETRY]: 'education.field.optometry',
  [EducationField.PHARMACIST]: 'education.field.pharmacist',
  [EducationField.PHYSIOTHERAPY]: 'education.field.physiotherapy',
  [EducationField.POLICE]: 'education.field.police',
  [EducationField.PSYCHOLOGY]: 'education.field.psychology',
  [EducationField.REAL_ESTATE]: 'education.field.realEstate',
  [EducationField.SOCIAL_SCIENCE]: 'education.field.socialScience',
  [EducationField.SOCIAL_WORKER]: 'education.field.socialWorkEducation',
  [EducationField.L337_AKA_DEVELOPER]: 'education.field.softwareDeveloper',
  [EducationField.STATISTICS]: 'education.field.statistics',
  [EducationField.SYSTEMS_ENGINEERING]: 'education.field.systemsEngineering',
  [EducationField.TEACHER]: 'education.field.teacher',
  [EducationField.THEOLOGY]: 'education.field.theology',
  [EducationField.VETERINARY]: 'education.field.veterinary',
  [EducationField.OTHER]: 'common.other',
  [EducationField.NONE]: 'common.none',
}

export const getEducationField = (field: EducationField): string | null => {
  return translateEnumDict(educationFieldTranslations, field)
}
