import React from 'react'
import { observer } from 'mobx-react-lite'

import { useAdminSociety } from '../../useAdminSociety'
import {
  Button,
  ButtonVariant,
} from '../../../../../../components/common/Button'
import { useStores } from '../../../../../../hooks/useStores'
import { useToastNotifications } from '../../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../../../components/common/Toast/toast-type'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'

interface SetPremiumModalContentProps {
  close: () => void
  societyId: string | undefined
}

export const SetPremiumModalContent = observer(
  ({ close, societyId }: SetPremiumModalContentProps): JSX.Element => {
    const { adminStore } = useStores()
    const { adminSociety } = useAdminSociety(societyId)
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()

    const upgradeSociety = async (): Promise<void> => {
      const status =
        societyId && (await adminStore.upgradeSociety({ societyId }))
      if (status) {
        setToastNotification(ToastType.SUCCESS, 'Förening uppgraderad')
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }
      close()
    }

    if (!adminSociety) {
      return <p>Föreningen kunde inte hittas</p>
    }

    const alreadyPremium = adminSociety.accountType === 'premium'
    const loading = adminStore.upgradingSociety === 'pending'

    return (
      <>
        <div className="flex h-full w-full flex-col gap-6">
          {alreadyPremium ? (
            <p>Föreningen är redan premium.</p>
          ) : (
            <>
              <p>
                {adminSociety.name} kommer att uppgraderas till <b>premium</b>.
              </p>
              <p>Föreningen kommer även att att låsas upp om den är låst.</p>
            </>
          )}
          <div className="flex flex-wrap justify-end gap-4">
            <Button label="Avbryt" onClick={close} />
            <Button
              variant={ButtonVariant.PRIMARY}
              label="Uppgradera"
              onClick={upgradeSociety}
              loading={loading}
              disabled={loading || alreadyPremium}
            />
          </div>
        </div>
      </>
    )
  }
)
