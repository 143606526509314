import React from 'react'

interface SpinnerProps {
  color?: string
}

export const Spinner = ({ color }: SpinnerProps): JSX.Element => {
  const spinnerColor = `border-${color || 'brandGreen'}`
  return (
    <div
      className={`h-10 w-10 animate-spin 
        rounded-full border-4 border-solid border-t-transparent ${spinnerColor}`}
    />
  )
}
