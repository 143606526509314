import React, { KeyboardEvent, useState } from 'react'
import { SearchBar } from '../../../../components/common/SearchBar'
import { Button } from '../../../../components/common/Button'
import { IconChoices } from '../../../../components/common/Icon'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { IAuditLogModel } from '../../../../interfaces/models/audit-logs.interfaces'
import { searchSocietyAuditLogs } from '../../../../api/admin'
import { NAdmin } from '../../../../interfaces/services/admin.interfaces'
import { AuditLog } from './AuditLog'
import { DropdownSelect } from '../../../../components/common/DropdownSelect'
import { Pagination } from '../../../../components/common/Pagination'

export const AuditLogs = (): JSX.Element => {
  const { translate } = useAppTranslation()
  const [searchString, setSearchString] = useState('')
  const [type, setType] = useState<IAuditLogModel['type'] | undefined>(
    undefined
  )
  const [auditLogsSearchResult, setAuditLogsSearchResultSearchResult] =
    useState<NAdmin.NSearchAuditLogs.IResponseInternal | undefined>(undefined)
  const [isSearching, setIsSearching] = useState(false)

  const onChangeSearchString = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    setSearchString(event.currentTarget.value)
    setAuditLogsSearchResultSearchResult(undefined)
  }

  const searchAuditLogs = async (
    societyId: string,
    page: number
  ): Promise<void> => {
    setIsSearching(true)
    const resp = await searchSocietyAuditLogs(societyId, {
      page,
      type: type || undefined,
    })

    // eslint-disable-next-line prefer-destructuring
    const data = resp.data.data

    setAuditLogsSearchResultSearchResult(data)
    setIsSearching(false)
  }

  const onSubmit = async (): Promise<void> => {
    try {
      await searchAuditLogs(searchString, 1)
    } catch (error) {
      setIsSearching(false)
    }
  }

  const handleEnterKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  const loadPage = (page: number): void => {
    searchAuditLogs(searchString, page)
  }

  const onTypeChange = (val: string): void => {
    setType(val as IAuditLogModel['type'])
    setAuditLogsSearchResultSearchResult(undefined)
  }

  return (
    <div className="h-5/6 w-full space-y-4">
      <div className="flex gap-1">
        <SearchBar
          placeholder={translate('adminView.searchBar.placeholders.auditLogs')}
          value={searchString}
          onChange={onChangeSearchString}
          onKeyUp={handleEnterKeyPress}
        />
        <DropdownSelect
          options={['', 'POST_DELETE', 'DOCUMENT_DELETE', 'USER_DELETE'].map(
            (_type) => ({
              label: _type,
              value: _type,
            })
          )}
          value={type}
          onChange={onTypeChange}
        />
        <Button
          className="h-12"
          icon={IconChoices.SEARCH}
          loading={isSearching}
          onClick={onSubmit}
          disabled={searchString === ''}
        />
      </div>

      {!auditLogsSearchResult && !isSearching && searchString.length > 0 && (
        <p>Inget resultat.</p>
      )}

      {auditLogsSearchResult?.results &&
        auditLogsSearchResult.results.length > 0 && (
          <div className="flex flex-col gap-4">
            {auditLogsSearchResult.results.map((auditLog) => (
              <AuditLog key={auditLog._id} auditLog={auditLog} />
            ))}
            <Pagination
              currentPage={auditLogsSearchResult.pagination.currentPage}
              totalPages={auditLogsSearchResult.pagination.totalPages}
              onPageChange={loadPage}
            />
          </div>
        )}
    </div>
  )
}
