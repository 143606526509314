import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { ViewBase } from '../../../../components/common/ViewBase'
import { RegisterMemberList } from '../../../../components/society/RegisterMemberList'
import { ResidentUserType } from '../../../../state/stores/units'
import { RegisterUnitList } from '../../../../components/society/RegisterUnitList'
import { RegisterEntranceList } from '../../../../components/society/RegisterEntranceList'
import { RegisterGroupList } from '../../../../components/society/RegisterGroupList'
import {
  ButtonProps,
  ButtonVariant,
} from '../../../../components/common/Button'
import { IconChoices } from '../../../../components/common/Icon'
import { useModal } from '../../../../hooks/useModal'
// eslint-disable-next-line max-len
import { CreateUpdateRegisterEntranceItemModal } from '../../../../components/society/RegisterEntranceItem/CreateUpdateRegisterEntranceItemModal'
// eslint-disable-next-line max-len
import { CreateUpdateRegisterUnitItemModal } from '../../../../components/society/RegisterUnitItem/CreateUpdateRegisterUnitItemModal'
import {
  ExcelImportModalVariant,
  RegisterExcelImportModal,
} from '../../../../components/society/RegisterExcelImportModal'
import { InviteModal } from '../../../../components/society/RegisterMemberList/InviteModal'
import { CreateUpdateRegisterGroupModal } from '../../../../components/society/CreateUpdateRegisterGroupModal'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { useRegisterTabs } from './useRegisterTabs'
import { RouteErrorView } from '../../../error/RouteErrorView'
import { useStores } from '../../../../hooks/useStores'

export enum ActiveTab {
  ENTRANCES = 0,
  UNITS = 1,
  MEMBERS = 2,
  NON_MEMBERS = 3,
  GROUPS = 4,
}

export const RegisterView = observer((): JSX.Element => {
  const { societyEntrancesStore, groupStore, requestStore, unitStore } =
    useStores()
  const { translate } = useAppTranslation()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const societyId = society._id
  const { getTabUrl, getTabState } = useRegisterTabs({ societyId })
  const navigate = useNavigate()
  const location = useLocation()
  useDocumentTitle(
    reverseDocumentTitle('management:register', {
      '{{ societyName }}': society.name,
    })
  )
  const {
    show: showEntrancesModal,
    open: openEntrancesModal,
    close: closeEntrancesModal,
  } = useModal()
  const {
    show: showUnitsModal,
    open: openUnitsModal,
    close: closeUnitsModal,
  } = useModal()
  const {
    show: showInviteMemberModal,
    open: openInviteMemberModal,
    close: closeInviteMemberModal,
  } = useModal()
  const {
    show: showInviteNonMemberModal,
    open: openInviteNonMemberModal,
    close: closeInviteNonMemberModal,
  } = useModal()
  const {
    show: showExcelImportModal,
    open: openExcelImportModal,
    close: closeExcelImportModal,
  } = useModal()
  const {
    show: showGroupsModal,
    open: openGroupsModal,
    close: closeGroupsModal,
  } = useModal()

  const [activeTab, setActiveTab] = useState<ActiveTab>(
    getTabState(location.pathname)
  )

  useEffect(() => {
    if (societyId) {
      societyEntrancesStore.getEntrancesPerSociety(societyId)
      groupStore.getGroupsPerSociety(societyId)
      requestStore.getRequestsPerSocieties([societyId])
      unitStore.getUnitsPerSociety(societyId)
    }
  }, [groupStore, requestStore, societyEntrancesStore, societyId, unitStore])

  // When a new activeTab is set, we change the navigation to that tab as well
  useEffect(() => {
    const url = getTabUrl(activeTab)
    if (location.pathname !== url) {
      navigate(url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  // When a new location is set (e.g. backbutton) we change the activeTab as well
  useEffect(() => {
    setActiveTab(getTabState(location.pathname))
  }, [getTabState, location.pathname])

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { isSamf } = society

  const tabs = [
    {
      title: translate(
        isSamf
          ? 'registerView.tabBar.entrancesSamf'
          : 'registerView.tabBar.entrances'
      ),
    },
    {
      title: translate(
        isSamf ? 'registerView.tabBar.unitSamf' : 'registerView.tabBar.unit'
      ),
    },
    {
      title: translate('registerView.tabBar.members'),
    },
    {
      title: translate('registerView.tabBar.nonMembers'),
    },
    {
      title: translate('registerView.tabBar.groups'),
    },
  ]

  const getViewBaseButtons = (): ButtonProps[] => {
    switch (activeTab) {
      case ActiveTab.ENTRANCES:
        return [
          {
            label: translate(
              isSamf
                ? 'registerEntrance.modal.createSamf'
                : 'registerEntrance.modal.create'
            ),
            icon: IconChoices.PLUS_SIGN,
            onClick: openEntrancesModal,
          },
          {
            label: translate(
              isSamf
                ? 'registerExcelImport.modal.entranceUnitTitleSamf'
                : 'registerExcelImport.modal.entranceUnitTitle'
            ),
            icon: IconChoices.DOCUMENT_ADD,
            variant: ButtonVariant.PRIMARY,
            onClick: openExcelImportModal,
          },
        ]
      case ActiveTab.UNITS:
        return [
          {
            label: translate(
              isSamf
                ? 'registerUnit.modal.createSamf'
                : 'registerUnit.modal.create'
            ),

            icon: IconChoices.PLUS_SIGN,
            onClick: openUnitsModal,
          },
          {
            label: translate(
              isSamf
                ? 'registerExcelImport.modal.entranceUnitTitleSamf'
                : 'registerExcelImport.modal.entranceUnitTitle'
            ),
            icon: IconChoices.DOCUMENT_ADD,
            variant: ButtonVariant.PRIMARY,
            onClick: openExcelImportModal,
          },
        ]
      case ActiveTab.MEMBERS:
        return [
          {
            label: translate('registerInvite.modal.inviteMember'),
            icon: IconChoices.PERSON_ADD,
            onClick: openInviteMemberModal,
          },
          {
            label: translate('registerExcelImport.modal.memberTitle'),
            icon: IconChoices.DOCUMENT_ADD,
            variant: ButtonVariant.PRIMARY,
            onClick: openExcelImportModal,
          },
        ]
      case ActiveTab.NON_MEMBERS:
        return [
          {
            label: translate('registerInvite.modal.inviteNonMember'),
            icon: IconChoices.PERSON_ADD,
            variant: ButtonVariant.PRIMARY,
            onClick: openInviteNonMemberModal,
          },
        ]
      case ActiveTab.GROUPS:
        return [
          {
            label: translate('registerGroup.modal.create'),
            icon: IconChoices.PLUS_SIGN,
            variant: ButtonVariant.PRIMARY,
            onClick: openGroupsModal,
          },
        ]
      default:
        return []
    }
  }

  const getTopLevelModal = (): JSX.Element | null => {
    switch (activeTab) {
      case ActiveTab.ENTRANCES:
        return (
          <>
            <CreateUpdateRegisterEntranceItemModal
              show={showEntrancesModal}
              close={closeEntrancesModal}
            />
            <RegisterExcelImportModal
              show={showExcelImportModal}
              close={closeExcelImportModal}
              variant={ExcelImportModalVariant.ENTRANCE_UNIT}
            />
          </>
        )
      case ActiveTab.UNITS:
        return (
          <>
            <CreateUpdateRegisterUnitItemModal
              show={showUnitsModal}
              close={closeUnitsModal}
            />
            <RegisterExcelImportModal
              show={showExcelImportModal}
              close={closeExcelImportModal}
              variant={ExcelImportModalVariant.ENTRANCE_UNIT}
            />
          </>
        )
      case ActiveTab.MEMBERS:
        return (
          <>
            <InviteModal
              show={showInviteMemberModal}
              close={closeInviteMemberModal}
              residentType={ResidentUserType.MEMBER}
            />
            <RegisterExcelImportModal
              show={showExcelImportModal}
              close={closeExcelImportModal}
              variant={ExcelImportModalVariant.MEMBER}
            />
          </>
        )
      case ActiveTab.NON_MEMBERS:
        return (
          <InviteModal
            show={showInviteNonMemberModal}
            close={closeInviteNonMemberModal}
            residentType={ResidentUserType.NON_MEMBER}
          />
        )
      case ActiveTab.GROUPS:
        return (
          <CreateUpdateRegisterGroupModal
            show={showGroupsModal}
            close={closeGroupsModal}
          />
        )
      default:
        return null
    }
  }

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('registerView.title')}
        tabBar={{ tabs, setActiveTab, activeTab }}
        buttons={getViewBaseButtons()}
      >
        <Routes>
          <Route
            path="entrance-list"
            element={<RegisterEntranceList />}
            errorElement={<RouteErrorView />}
          />
          <Route
            path="unit-list"
            element={<RegisterUnitList />}
            errorElement={<RouteErrorView />}
          />
          <Route
            path="member-list"
            element={
              <RegisterMemberList residentUserType={ResidentUserType.MEMBER} />
            }
            errorElement={<RouteErrorView />}
          />
          <Route
            path="non-member-list"
            element={
              <RegisterMemberList
                residentUserType={ResidentUserType.NON_MEMBER}
              />
            }
            errorElement={<RouteErrorView />}
          />
          <Route
            path="group-list"
            element={<RegisterGroupList />}
            errorElement={<RouteErrorView />}
          />
        </Routes>
      </ViewBase>
      {getTopLevelModal()}
    </ErrorBoundary>
  )
})
