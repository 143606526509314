import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../../hooks/useStores'
import { Modal } from '../../../common/Modal'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalFooter } from '../../../common/Modal/ModalFooter'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { Button, ButtonVariant } from '../../../common/Button'
import { DeleteAccountModalContent } from './DeleteAccountModalContent'

export enum DeleteAccountStage {
  FIRST = 0,
  SECOND = 1,
  LAST = 2,
}

interface DeleteAccountModalProps {
  show: boolean
  close: () => void
}

export const DeleteAccountModal = observer(
  ({ show, close }: DeleteAccountModalProps): JSX.Element => {
    const { unitStore, societyStore, authenticationStore, userStore } =
      useStores()
    const { setToastNotification } = useToastNotifications()
    const userId = useAuthenticatedUserId() as string
    const { translate } = useAppTranslation()
    const [stage, setStage] = useState<DeleteAccountStage>(
      DeleteAccountStage.FIRST
    )
    const [fetchingUnits, setFetchingUnits] = useState(false)
    const [password, setPassword] = useState('')
    const societies = societyStore.sortedSocieties

    useEffect(() => {
      /*
      To determine if an account can be deleted,
      we must know about the units in the societies they are part of.
      */
      const fetchSocietyUnits = async (): Promise<void> => {
        const ids = societies.map((_society) => _society._id)
        const promises = ids.map((_id) => unitStore.getUnitsPerSociety(_id))
        Promise.all(promises).then(() => {
          setFetchingUnits(false)
        })
      }
      fetchSocietyUnits()
    }, [societies, unitStore])

    const societiesPreventingAccountDeletion = societies.filter(
      (_society) => !societyStore.canRemoveUserFromSociety(userId, _society._id)
    )
    const canNotDeleteAccount = societiesPreventingAccountDeletion.length > 0

    const showSuccessMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('deleteAccountModal.flashMessage.deleteAccountSuccess')
      )
    }

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('deleteAccountModal.flashMessage.confirmPasswordFailure')
      )
    }

    const onClose = (): void => {
      setStage(DeleteAccountStage.FIRST)
      close()
    }

    const onDeleteClick = async (): Promise<void> => {
      const status = await userStore.deleteUser(userId, { password })
      if (status) {
        showSuccessMessage()
        authenticationStore.logout()
        onClose()
      } else {
        showErrorMessage()
      }
    }

    const updateStage = (): void => {
      setStage(stage + 1)
    }

    const onChangePassword = (confirmedPassword: string): void => {
      setPassword(confirmedPassword)
    }

    const onForwardButtonClick = (): void => {
      if (stage === DeleteAccountStage.LAST) {
        onDeleteClick()
      } else {
        updateStage()
      }
    }

    const deletingUser = userStore.deletingUser === 'pending'

    return (
      <Modal show={show} size="md">
        {{
          header: (
            <ModalHeader onClose={onClose}>
              {translate('deleteAccountModal.title')}
            </ModalHeader>
          ),
          body: (
            <ModalBody>
              <DeleteAccountModalContent
                stage={stage}
                societiesPreventingAccountDeletion={
                  societiesPreventingAccountDeletion
                }
                loading={fetchingUnits}
                onChangePassword={onChangePassword}
                password={password}
              />
            </ModalBody>
          ),
          footer: (
            <ModalFooter>
              <Button
                onClick={onClose}
                label={translate('common.actions.cancel')}
                variant={ButtonVariant.DEFAULT}
              />
              <Button
                loading={fetchingUnits || deletingUser}
                variant={
                  stage === DeleteAccountStage.LAST
                    ? ButtonVariant.DANGER
                    : ButtonVariant.PRIMARY
                }
                label={
                  stage === DeleteAccountStage.LAST
                    ? translate('deleteAccountModal.title')
                    : translate('common.actions.continue')
                }
                disabled={
                  (stage === DeleteAccountStage.LAST &&
                    password.length === 0) ||
                  fetchingUnits ||
                  canNotDeleteAccount
                }
                onClick={onForwardButtonClick}
              />
            </ModalFooter>
          ),
        }}
      </Modal>
    )
  }
)
