import * as yup from 'yup'
import i18next from '../../i18n/i18n'

export const updateEmailSchema = yup
  .object({
    currentPassword: yup.string().required(),
    newEmail: yup.string().email().required(),
    newEmailConfirmation: yup
      .string()
      .required()
      .oneOf([yup.ref('newEmail')], i18next.t('form.errors.emailConfirmation')),
  })
  .required()
