import { OccupationIndustry } from '../../types/occupation'
import { translateEnumDict } from './helpers'

const occupationIndustryTranslations: { [key: string]: string } = {
  [OccupationIndustry.MARKETING]: 'occupation.industry.marketing',
  [OccupationIndustry.ADMIN_OFFICE_MANAGEMENT]:
    'occupation.industry.adminOfficeManagement',
  [OccupationIndustry.HEALTHCARE]: 'occupation.industry.healthcare',
  [OccupationIndustry.CUSTOMER_SUPPORT]: 'occupation.industry.customerSupport',
  [OccupationIndustry.HUMAN_RESOURCES_LEGAL]:
    'occupation.industry.humanResourcesLegal',
  [OccupationIndustry.SERVICE_RETAIL_TOURISM]:
    'occupation.industry.serviceRetailTourism',
  [OccupationIndustry.FINANCE_BANKING_INSURANCE]:
    'occupation.industry.financeBankingInsurance',
  [OccupationIndustry.EDUCATION]: 'occupation.industry.education',
  [OccupationIndustry.INDUSTRY_PRODUCTION]:
    'occupation.industry.industryProduction',
  [OccupationIndustry.IT_DATA]: 'occupation.industry.itData',
  [OccupationIndustry.ENGINEERING]: 'education.field.engineering',
  [OccupationIndustry.LOGISTICS_PURCHASING]:
    'occupation.industry.logisticsPurchasing',
  [OccupationIndustry.MANAGEMENT]: 'occupation.industry.management',
  [OccupationIndustry.ENVIRONMENT]: 'occupation.industry.environment',
  [OccupationIndustry.CONSTRUCTION]: 'occupation.industry.construction',
  [OccupationIndustry.CULTURE_MEDIA]: 'occupation.industry.cultureMedia',
  [OccupationIndustry.OTHER]: 'common.other',
  [OccupationIndustry.NONE]: 'common.none',
}

export const getOccupationIndustry = (
  industry: OccupationIndustry
): string | null => {
  return translateEnumDict(occupationIndustryTranslations, industry)
}
