import React from 'react'
import { observer } from 'mobx-react-lite'
import { Image } from '../../../common/Image'
import { ProcessedMedia } from '../../../../types/image-upload'
import { Icon, IconChoices } from '../../../common/Icon'
import { theme } from '../../../../theme/theme'
import { Spinner } from '../../../common/Spinner'

interface ReportProblemFormImagesProps {
  images: ProcessedMedia[]
  deleteImage: (image: ProcessedMedia) => Promise<void>
}

export const ReportProblemFormImages = observer(
  ({ images, deleteImage }: ReportProblemFormImagesProps): JSX.Element => {
    return (
      <>
        {images.length > 0 && (
          <div className="mt-4 flex flex-col gap-4">
            {images.map((_image: ProcessedMedia) => (
              <div
                key={_image.uri}
                className="border-1 flex items-center justify-between rounded-md border p-4"
              >
                {_image.uploading ? (
                  <Spinner />
                ) : (
                  <>
                    <div className="flex items-center">
                      <div className="h-10 w-10">
                        <Image url={_image.uri} />
                      </div>
                      {_image.name && (
                        <p
                          className="ml-4 break-all line-clamp-2"
                          style={theme.textVariants.caption}
                        >
                          {_image.name}
                        </p>
                      )}
                    </div>
                    <Icon
                      className="ml-4 hover:cursor-pointer"
                      icon={IconChoices.DELETE_TRASH}
                      color={theme.colors.red}
                      onClick={() => deleteImage(_image)}
                    />
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </>
    )
  }
)
