import * as yup from 'yup'
import { unitRoleOptions } from './helpers'

export const societyRequestSchema = yup
  .object({
    unitId: yup.string().required(),
    entranceId: yup.string().required(),
    role: yup.string().oneOf(unitRoleOptions).required(),
  })
  .required()
