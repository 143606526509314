import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { PreApprovedModel } from '../../../../state/models/society'
import { theme } from '../../../../theme/theme'
import { Icon, IconChoices } from '../../../common/Icon'
import { Spinner } from '../../../common/Spinner'
import { useStores } from '../../../../hooks/useStores'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useModal } from '../../../../hooks/useModal'
import { ConfirmationModal } from '../../../common/ConfirmationModal'
import { Button, ButtonVariant } from '../../../common/Button'
import { ExtendedUnitModel, getUnitString } from '../../../../helpers/units'
import { UnitModel } from '../../../../state/models/unit'

interface PreApprovedListItemProps {
  preApproved: SnapshotOut<typeof PreApprovedModel>
  disableButtons?: boolean
  className?: string
}

export const PreApprovedListItem = observer(
  ({
    preApproved,
    disableButtons,
    className,
  }: PreApprovedListItemProps): JSX.Element => {
    const { societyStore, unitStore, societyEntrancesStore } = useStores()
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()
    const [sendingReminder, setSendingReminder] = useState(false)
    const [reminderSent, setReminderSent] = useState(false)
    const {
      show: showDeleteConfirmationModal,
      open: openDeleteConfirmationModal,
      close: closeDeleteConfirmationModal,
    } = useModal()
    const {
      show: showRemindConfirmationModal,
      open: openRemindConfirmationModal,
      close: closeRemindConfirmationModal,
    } = useModal()

    const sendReminder = async (): Promise<void> => {
      setSendingReminder(true)
      const status = await societyStore.sendPreApprovedUserInviteReminder(
        society._id,
        preApproved._id
      )
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('preApproved.flashMessage.sendReminderSuccess')
        )
        setReminderSent(true)
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('preApproved.flashMessage.sendReminderFailure')
        )
      }
      setSendingReminder(false)
    }

    const deletePreApproved = async (): Promise<void> => {
      const status = await societyStore.removePreApprovedUser(
        society._id,
        preApproved._id
      )
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('preApproved.flashMessage.deletePreApprovedSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('preApproved.flashMessage.deletePreApprovedFailure')
        )
      }
    }

    const renderReminderStatus = (): JSX.Element => {
      if (reminderSent) {
        return <Icon icon={IconChoices.CHECKMARK} />
      }
      if (sendingReminder) {
        return <Spinner />
      }
      return (
        <Button
          icon={IconChoices.NOTIFICATION_ALERT}
          size="xs"
          variant={ButtonVariant.TEXT}
          onClick={openRemindConfirmationModal}
          disabled={disableButtons}
        />
      )
    }

    useEffect(() => {
      if (preApproved.unitId) {
        unitStore.getUnit(preApproved.unitId)
      }
    }, [preApproved.unitId, unitStore])

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isSamf } = society

    const unit =
      (preApproved.unitId &&
        (unitStore.units.get(preApproved.unitId) as SnapshotOut<
          typeof UnitModel
        >)) ||
      undefined
    const entrance =
      unit?.entranceId && societyEntrancesStore.entrances.get(unit.entranceId)
    const unitString =
      unit && getUnitString({ ...unit, entrance } as ExtendedUnitModel, isSamf)

    return (
      <>
        <div
          className={`border-1 flex items-center justify-between border-t py-4 ${
            className ?? ''
          }`}
        >
          <div className="flex space-x-1">
            <p style={theme.textVariants.baseBold}>{preApproved.userEmail}</p>
            <p style={theme.textVariants.base} className="text-neutral-40">
              ({unitString})
            </p>
          </div>
          <div className="flex">
            {renderReminderStatus()}
            <Button
              icon={IconChoices.DELETE}
              iconColor={theme.colors.red}
              size="xs"
              variant={ButtonVariant.TEXT}
              onClick={openDeleteConfirmationModal}
              disabled={disableButtons}
            />
          </div>
        </div>
        {showDeleteConfirmationModal && (
          <ConfirmationModal
            title={translate('preApproved.confirmationDialog.deleteTitle')}
            description={translate(
              'preApproved.confirmationDialog.deleteDescription'
            )}
            show={showDeleteConfirmationModal}
            close={closeDeleteConfirmationModal}
            onConfirm={deletePreApproved}
          />
        )}
        {showRemindConfirmationModal && (
          <ConfirmationModal
            title={translate('preApproved.confirmationDialog.remindTitle')}
            description={translate(
              'preApproved.confirmationDialog.remindDescription'
            )}
            show={showRemindConfirmationModal}
            close={closeRemindConfirmationModal}
            onConfirm={sendReminder}
            confirmationButtonLabel={translate('common.actions.send')}
          />
        )}
      </>
    )
  }
)
