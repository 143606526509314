import { isAlive, SnapshotOut } from 'mobx-state-tree'
import { useCallback } from 'react'
import { InferType } from 'yup'
import { userNotificationsSchema } from '../../../forms/schemas/user_notifications'
import { deepMerge } from '../../../helpers/deepMerge'
import { INotificationPreferencesAuthors } from '../../../interfaces/models/notifications.interfaces'
import { NUsers } from '../../../interfaces/services/users.interfaces'
import { NotificationPreferencesAuthor } from '../../../state/models/notification'
import { UserModel } from '../../../state/models/user'

type useNotificationSettingsHelpersReturnType = {
  getDefaultFormValues: () => InferType<typeof userNotificationsSchema>
  getOnMasterSwitchChangeResetValues: (
    defaultValues: InferType<typeof userNotificationsSchema>,
    field: 'email' | 'mobile',
    value: boolean | undefined
  ) => InferType<typeof userNotificationsSchema>
  createPatchFormData: (
    data: InferType<typeof userNotificationsSchema>
  ) => NUsers.NPatch.IRequestBody
}

export const useNotificationSettingsHelpers = (
  societyId: string | undefined,
  user: SnapshotOut<typeof UserModel> | undefined
): useNotificationSettingsHelpersReturnType => {
  const checkPreference = useCallback(
    (
      type:
        | 'society-request-member'
        | 'hey-new-posts'
        | 'hey-new-comments'
        | 'chat-oneonone-new-message'
        | 'chat-board-new-message'
        | 'chat-questions-new-message'
        | 'chat-interests-new-message',
      author: SnapshotOut<typeof NotificationPreferencesAuthor>,
      delivery: 'mobile' | 'email'
    ): boolean => {
      const societyPreferences =
        isAlive(user) && isAlive(user?.notificationPreferences)
          ? user?.notificationPreferences?.filter(
              (preference) => preference.societyId === societyId
            )
          : []

      const preference = societyPreferences?.find(
        (setting) => setting.type === type
      )

      if (preference === null || preference === undefined) {
        return true
      }

      // Get array which includes preferences
      const dontArr =
        delivery === 'mobile' ? preference.dontPush : preference.dontEmail

      // Each array includes the various authors that we can update our settings for.
      // Check if specificed author is included in the "don't" array.
      const dontAuthor = (dontArr == null ? [] : dontArr).filter(
        (_author) => _author === author
      )[0]
      if (dontAuthor != null) {
        return false
      }

      return true
    },
    [societyId, user]
  )

  const getDefaultFormValues = useCallback((): InferType<
    typeof userNotificationsSchema
  > => {
    if (!societyId) {
      return {
        general: {
          membershipRequests: {
            mobile: undefined,
            email: undefined,
          },
        },
        feed: {
          postFromAdmins: {
            mobile: undefined,
            email: undefined,
          },
          commentOnAdminPosts: {
            mobile: undefined,
            email: undefined,
          },
          postFromNeighbours: {
            mobile: undefined,
            email: undefined,
          },
          commentOnNeighbourPosts: {
            mobile: undefined,
            email: undefined,
          },
        },
        chat: {
          neighbours: {
            mobile: undefined,
            email: undefined,
          },
          groups: {
            mobile: undefined,
            email: undefined,
          },
          board: {
            mobile: undefined,
            email: undefined,
          },
          questions: {
            mobile: undefined,
            email: undefined,
          },
        },
        masterSwitch: {
          mobile: undefined,
          email: undefined,
        },
      }
    }

    const fetchedPreferences = {
      general: {
        membershipRequests: {
          mobile: checkPreference(
            'society-request-member',
            'meAsAuthor',
            'mobile'
          ),
          email: checkPreference(
            'society-request-member',
            'meAsAuthor',
            'email'
          ),
        },
      },
      feed: {
        postFromAdmins: {
          mobile: true,
          email: checkPreference('hey-new-posts', 'admin', 'email'),
        },
        commentOnAdminPosts: {
          mobile: checkPreference('hey-new-comments', 'admin', 'mobile'),
          email: checkPreference('hey-new-comments', 'admin', 'email'),
        },
        postFromNeighbours: {
          mobile: checkPreference('hey-new-posts', 'residents', 'mobile'),
          email: checkPreference('hey-new-posts', 'residents', 'email'),
        },
        commentOnNeighbourPosts: {
          mobile: checkPreference('hey-new-comments', 'residents', 'mobile'),
          email: checkPreference('hey-new-comments', 'residents', 'email'),
        },
      },
      chat: {
        neighbours: {
          mobile: checkPreference(
            'chat-oneonone-new-message',
            'residents',
            'mobile'
          ),
          email: checkPreference(
            'chat-oneonone-new-message',
            'residents',
            'email'
          ),
        },
        groups: {
          mobile: checkPreference(
            'chat-interests-new-message',
            'residents',
            'mobile'
          ),
          email: checkPreference(
            'chat-interests-new-message',
            'residents',
            'email'
          ),
        },
        board: {
          mobile: checkPreference('chat-board-new-message', 'board', 'mobile'),
          email: checkPreference('chat-board-new-message', 'board', 'email'),
        },
        questions: {
          mobile: checkPreference(
            'chat-questions-new-message',
            'residents',
            'mobile'
          ),
          email: checkPreference(
            'chat-questions-new-message',
            'residents',
            'email'
          ),
        },
      },
    }
    const computedPreferences = {
      masterSwitch: {
        mobile:
          fetchedPreferences.general.membershipRequests.mobile &&
          fetchedPreferences.feed.commentOnAdminPosts.mobile &&
          fetchedPreferences.feed.postFromNeighbours.mobile &&
          fetchedPreferences.feed.commentOnNeighbourPosts.mobile &&
          fetchedPreferences.chat.neighbours.mobile &&
          fetchedPreferences.chat.groups.mobile &&
          fetchedPreferences.chat.board.mobile &&
          fetchedPreferences.chat.questions.mobile,
        email:
          fetchedPreferences.general.membershipRequests.email &&
          fetchedPreferences.feed.postFromAdmins.email &&
          fetchedPreferences.feed.commentOnAdminPosts.email &&
          fetchedPreferences.feed.postFromNeighbours.email &&
          fetchedPreferences.feed.commentOnNeighbourPosts.email &&
          fetchedPreferences.chat.neighbours.email &&
          fetchedPreferences.chat.groups.email &&
          fetchedPreferences.chat.board.email &&
          fetchedPreferences.chat.questions.email,
      },
    }

    return { ...fetchedPreferences, ...computedPreferences }
  }, [checkPreference, societyId])

  const getOnMasterSwitchChangeResetValues = useCallback(
    (
      defaultValues: InferType<typeof userNotificationsSchema>,
      field: 'email' | 'mobile',
      value: boolean | undefined
    ): InferType<typeof userNotificationsSchema> => {
      return deepMerge(defaultValues, {
        general: {
          membershipRequests: {
            [field]: value,
          },
        },
        feed: {
          postFromAdmins: {
            [field]: value,
            mobile: true,
          },
          commentOnAdminPosts: {
            [field]: value,
          },
          postFromNeighbours: {
            [field]: value,
          },
          commentOnNeighbourPosts: {
            [field]: value,
          },
        },
        chat: {
          neighbours: {
            [field]: value,
          },
          groups: {
            [field]: value,
          },
          board: {
            [field]: value,
          },
          questions: {
            [field]: value,
          },
        },
        masterSwitch: {
          [field]: value,
        },
      })
    },
    []
  )

  const createPatchFormData = useCallback(
    (
      data: InferType<typeof userNotificationsSchema>
    ): NUsers.NPatch.IRequestBody => {
      const otherSocietyPreferences = (
        user?.notificationPreferences?.filter(
          (preference) => preference.societyId !== societyId
        ) || []
      ).map((_preference) => ({
        ..._preference,
        // Force string | undefined as to comply with interfaces.
        societyId:
          _preference.societyId === null ? undefined : _preference.societyId,
      }))
      // The ts-ignore for type is required as interfaces are
      // invalid. Interfaces have to be updated.
      const formData: NUsers.NPatch.IRequestBody = {
        notificationPreferences: [
          // eslint-disable-next-line
          // @ts-ignore
          ...otherSocietyPreferences,
          // General
          {
            // eslint-disable-next-line
            // @ts-ignore
            type: 'society-request-member',
            societyId,
            dontPush: [
              data?.general?.membershipRequests?.mobile ? null : 'meAsAuthor',
            ].filter(
              (val) => val !== null
            ) as INotificationPreferencesAuthors[],
            dontEmail: [
              data?.general?.membershipRequests?.email ? null : 'meAsAuthor',
            ].filter(
              (val) => val !== null
            ) as INotificationPreferencesAuthors[],
          },
          // Feed
          {
            // eslint-disable-next-line
            // @ts-ignore
            type: 'hey-new-posts',
            societyId,
            dontPush: [
              data?.feed?.postFromNeighbours?.mobile ? null : 'residents',
              data?.feed?.postFromAdmins?.mobile ? null : 'admin',
            ].filter(
              (val) => val !== null
            ) as INotificationPreferencesAuthors[],
            dontEmail: [
              data?.feed?.postFromNeighbours?.email ? null : 'residents',
              data?.feed?.postFromAdmins?.email ? null : 'admin',
            ].filter(
              (val) => val !== null
            ) as INotificationPreferencesAuthors[],
          },
          {
            // eslint-disable-next-line
            // @ts-ignore
            type: 'hey-new-comments',
            societyId,
            dontPush: [
              data?.feed?.commentOnNeighbourPosts?.mobile ? null : 'residents',
              data?.feed?.commentOnAdminPosts?.mobile ? null : 'admin',
            ].filter(
              (val) => val !== null
            ) as INotificationPreferencesAuthors[],
            dontEmail: [
              data?.feed?.commentOnNeighbourPosts?.email ? null : 'residents',
              data?.feed?.commentOnAdminPosts?.email ? null : 'admin',
            ].filter(
              (val) => val !== null
            ) as INotificationPreferencesAuthors[],
          },
          // Chat
          {
            // eslint-disable-next-line
            // @ts-ignore
            type: 'chat-oneonone-new-message',
            societyId,
            dontPush: [
              data?.chat?.neighbours?.mobile ? null : 'residents',
            ].filter(
              (val) => val !== null
            ) as INotificationPreferencesAuthors[],
            dontEmail: [
              data?.chat?.neighbours?.email ? null : 'residents',
            ].filter(
              (val) => val !== null
            ) as INotificationPreferencesAuthors[],
          },
          {
            // eslint-disable-next-line
            // @ts-ignore
            type: 'chat-interests-new-message',
            societyId,
            dontPush: [data?.chat?.groups?.mobile ? null : 'residents'].filter(
              (val) => val !== null
            ) as INotificationPreferencesAuthors[],
            dontEmail: [data?.chat?.groups?.email ? null : 'residents'].filter(
              (val) => val !== null
            ) as INotificationPreferencesAuthors[],
          },
          {
            // eslint-disable-next-line
            // @ts-ignore
            type: 'chat-board-new-message',
            societyId,
            dontPush: [data?.chat?.board?.mobile ? null : 'board'].filter(
              (val) => val !== null
            ) as INotificationPreferencesAuthors[],
            dontEmail: [data?.chat?.board?.email ? null : 'board'].filter(
              (val) => val !== null
            ) as INotificationPreferencesAuthors[],
          },
          {
            // eslint-disable-next-line
            // @ts-ignore
            type: 'chat-questions-new-message',
            societyId,
            dontPush: [
              data?.chat?.questions?.mobile ? null : 'residents',
            ].filter(
              (val) => val !== null
            ) as INotificationPreferencesAuthors[],
            dontEmail: [
              data?.chat?.questions?.email ? null : 'residents',
            ].filter(
              (val) => val !== null
            ) as INotificationPreferencesAuthors[],
          },
        ],
      }

      return formData
    },
    [societyId, user?.notificationPreferences]
  )

  return {
    getDefaultFormValues,
    getOnMasterSwitchChangeResetValues,
    createPatchFormData,
  }
}
