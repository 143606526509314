import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { reverseUrl } from '../../../../../navigation/reverseUrl'
import { theme } from '../../../../../theme/theme'
import { SocietyContactModel } from '../../../../../state/models/society-contact'
import { Avatar } from '../../../../common/Avatar'

interface ContactItemProps {
  contact: SnapshotOut<typeof SocietyContactModel>
  type: 'society' | 'management'
}

export const ContactItem = observer(
  ({ contact, type }: ContactItemProps): JSX.Element | null => {
    return (
      <Link
        className="flex w-full cursor-pointer flex-row items-center rounded-lg p-4 hover:bg-neutral-96"
        to={reverseUrl(
          type === 'society' ? 'society:contacts' : 'management:contacts',
          {
            id: contact.societyId,
          }
        )}
        style={{ textDecoration: 'none' }}
      >
        <div className="flex items-center gap-4">
          <Avatar mediaId={contact.mediaId} size={120} />
          <div>
            <p
              className="mb-1 text-black line-clamp-1"
              style={theme.textVariants.lead}
            >
              {contact.name}
            </p>
            <p
              className="m-0 text-neutral-50 line-clamp-1"
              style={theme.textVariants.base}
            >
              {contact.title}
            </p>
          </div>
        </div>
      </Link>
    )
  }
)
