import React from 'react'
import { useAuth } from '../../../hooks/useAuth'

interface SuperAdminRouteProps {
  children: React.ReactNode
}

const SuperAdminRoute = ({
  children,
}: SuperAdminRouteProps): JSX.Element | null => {
  const { isBoappaSuperadmin } = useAuth()

  if (!isBoappaSuperadmin) {
    return null
  }

  return <>{children}</>
}

SuperAdminRoute.displayName = 'SuperAdminRoute'
export { SuperAdminRoute }
