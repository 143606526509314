import React, { ReactNode } from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { SocietyWebsiteBottomBar } from '../SocietyWebsiteBottomBar'
import { SocietyWebsiteModel } from '../../../../state/models/society-website'

interface SocietyWebsiteBaseProps {
  children: ReactNode
  website: SnapshotOut<typeof SocietyWebsiteModel>
  isValid?: boolean
  loading?: boolean
  className?: string
}

export const SocietyWebsiteBase = observer(
  ({
    children,
    website,
    loading,
    isValid,
    className,
  }: SocietyWebsiteBaseProps): JSX.Element => {
    return (
      <div
        className={`flex h-4/6 w-full flex-col justify-between
        ${className ?? ''}`}
      >
        {children}
        <SocietyWebsiteBottomBar
          website={website}
          isValid={isValid}
          loading={loading}
        />
      </div>
    )
  }
)
