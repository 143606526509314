/* eslint-disable no-param-reassign */

import { captureException } from '@sentry/react'
import { flow, getRoot, SnapshotOut, types } from 'mobx-state-tree'
import { values } from 'mobx'
import {
  hasPermissions as apiHasPermissions,
  loginAsUser,
  searchUser as apiSearchUser,
  changeUserPassword as apiChangeUserPassword,
  changeUserEmail as apiChangeUserEmail,
  setSocietyPartner as apiSetSocietyPartner,
  inactivateSociety as apiInactivateSociety,
  deleteSociety as apiDeleteSociety,
  deleteUser as apiDeleteUser,
  patchSociety as apiPatchSociety,
  patchSocietyWebsite as apiPatchSocietyWebsite,
  downloadSocietyList as apiDownloadSocietyList,
  upgradeSociety as apiUpgradeSociety,
  downgradeSociety as apiDowngradeSociety,
} from '../../api/admin'
import { NAdmin } from '../../interfaces/services/admin.interfaces'
import { stateType } from '../types/common'
import { IUserModel } from '../../interfaces/models/users.interfaces'
import { UserModel } from '../models/user'
import { setObject } from './helpers'
import {
  setSecureBearerCookies,
  setSecureUserIdCookie,
} from '../../helpers/authentication'
import { RootStore } from './root'

export const TWILIGHT_PREFIX = 'twilight:'

export const AdminStore = types
  .model('AdminStore')
  .props({
    users: types.map(UserModel),
    hasPermissions: types.boolean,
    isTwilight: types.boolean,
    fetchingUser: stateType,
    loggingInAsUser: stateType,
    changingUserPassword: stateType,
    changingUserEmail: stateType,
    settingSocietyPartner: stateType,
    inactivatingSociety: stateType,
    updatingSociety: stateType,
    updatingSocietyWebsite: stateType,
    downloadingSocietyList: stateType,
    deletingSociety: stateType,
    deletingUser: stateType,
    upgradingSociety: stateType,
    downgradingSociety: stateType,
  })
  .views((self) => ({
    get isBoappaSuperadmin() {
      return self.hasPermissions === true
    },
    get isTwilightUser() {
      return self.isTwilight === true
    },
    get listUsers(): SnapshotOut<typeof UserModel>[] {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return values(self.users as SnapshotOut<typeof UserModel>[])
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.users.clear()
      self.hasPermissions = false
      self.isTwilight = false
      self.fetchingUser = 'none'
      self.loggingInAsUser = 'none'
      self.changingUserPassword = 'none'
      self.settingSocietyPartner = 'none'
      self.inactivatingSociety = 'none'
      self.updatingSociety = 'none'
      self.updatingSocietyWebsite = 'none'
      self.downloadingSocietyList = 'none'
      self.deletingSociety = 'none'
    },
    setUsers: (users: IUserModel[]) => {
      users.forEach((user) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setObject<typeof UserModel>(self.users, UserModel, {
          ...user,
          fullName: [user.name, user.surname].join(' '),
          avatarId: user.avatarId,
        })
      })
    },
    setIsTwilight: (isTwilight: boolean) => {
      self.isTwilight = isTwilight
    },
    clearUsers: () => {
      self.users.clear()
    },
  }))
  .actions((self) => ({
    getPermissionsStatus: flow(function* getPermissionsStatus() {
      try {
        const resp = yield apiHasPermissions()
        const data = resp.data as NAdmin.NHasPermissions.IResponse

        self.hasPermissions = data.data
      } catch (error) {
        captureException(error)
      }
    }),
    searchUser: flow(function* searchUser(query: string) {
      self.fetchingUser = 'pending'
      try {
        const resp = yield apiSearchUser({ query })
        const data = resp.data as NAdmin.NSearchUser.IResponse
        const users = data.data

        self.setUsers(users)

        self.fetchingUser = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingUser = 'error'
      }
    }),
    loginAsUser: flow(function* login(id: string) {
      self.loggingInAsUser = 'pending'
      try {
        const resp = yield loginAsUser({
          userId: id,
        })
        const { token, user } = resp.data.data
        const userId = user._id

        setSecureBearerCookies(token, TWILIGHT_PREFIX)
        setSecureUserIdCookie(userId, TWILIGHT_PREFIX)

        const { reset } = getRoot<RootStore>(self)
        reset()

        self.loggingInAsUser = 'done'
        return userId
      } catch (error) {
        // Don't report invalid credentials
        if (error?.response?.status !== 401) {
          captureException(error)
        }
        self.loggingInAsUser = 'error'
        return false
      }
    }),
    changeUserPassword: flow(function* changeUserPassword(
      body: NAdmin.NChangeUserPassword.IRequestBody
    ) {
      self.changingUserPassword = 'pending'
      try {
        yield apiChangeUserPassword(body)

        self.changingUserPassword = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.changingUserPassword = 'error'
        return false
      }
    }),
    changeUserEmail: flow(function* changeUserEmail(
      body: NAdmin.NChangeUserEmail.IRequestBody
    ) {
      self.changingUserPassword = 'pending'
      try {
        yield apiChangeUserEmail(body)

        self.changingUserPassword = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.changingUserPassword = 'error'
        return false
      }
    }),
    setSocietyPartner: flow(function* setSocietyPartner(
      body: NAdmin.NSetSocietyPartner.IRequestBody
    ) {
      self.settingSocietyPartner = 'pending'
      try {
        yield apiSetSocietyPartner(body)

        self.settingSocietyPartner = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.settingSocietyPartner = 'error'
        return false
      }
    }),
    inactivateSociety: flow(function* inactivateSociety(
      body: NAdmin.NInactivateSociety.IRequestBody
    ) {
      self.inactivatingSociety = 'pending'
      try {
        yield apiInactivateSociety(body)

        self.inactivatingSociety = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.inactivatingSociety = 'error'
        return false
      }
    }),
    deleteSociety: flow(function* deleteSociety(
      body: NAdmin.NInactivateSociety.IRequestBody
    ) {
      self.deletingSociety = 'pending'
      try {
        yield apiDeleteSociety(body)

        self.deletingSociety = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.deletingSociety = 'error'
        return false
      }
    }),
    patchSociety: flow(function* patchSociety(
      body: NAdmin.NPatchSociety.IRequestBody
    ) {
      self.updatingSociety = 'pending'
      try {
        yield apiPatchSociety(body)

        self.updatingSociety = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingSociety = 'error'
        return false
      }
    }),
    patchSocietyWebsite: flow(function* patchSociety(
      body: NAdmin.NPatchSocietyWebsite.IRequestBody
    ) {
      self.updatingSocietyWebsite = 'pending'
      try {
        yield apiPatchSocietyWebsite(body)

        self.updatingSocietyWebsite = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingSocietyWebsite = 'error'
        return false
      }
    }),
    deleteUser: flow(function* deleteUser(
      body: NAdmin.NDeleteUser.IRequestBody
    ) {
      self.deletingUser = 'pending'
      try {
        yield apiDeleteUser(body)

        self.deletingUser = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.deletingUser = 'error'
        return false
      }
    }),
    downloadSocietyList: flow(function* downloadSocietyList() {
      self.downloadingSocietyList = 'pending'
      try {
        yield apiDownloadSocietyList()

        self.downloadingSocietyList = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.downloadingSocietyList = 'error'
        return false
      }
    }),
    upgradeSociety: flow(function* UpgradeSociety(
      body: NAdmin.NUpgradeSociety.IRequestBody
    ) {
      self.upgradingSociety = 'pending'
      try {
        yield apiUpgradeSociety(body)

        self.upgradingSociety = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.upgradingSociety = 'error'
        return false
      }
    }),
    downgradeSociety: flow(function* UpgradeSociety(
      body: NAdmin.NDowngradeSociety.IRequestBody
    ) {
      self.downgradingSociety = 'pending'
      try {
        yield apiDowngradeSociety(body)

        self.downgradingSociety = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.downgradingSociety = 'error'
        return false
      }
    }),
  }))
