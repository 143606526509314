import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { SocietyModel } from '../../../../state/models/society'
import { theme } from '../../../../theme/theme'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { formatOrganisationNumber } from '../../../../helpers/organisation_number'
import { getSocietyType } from '../../../../helpers/translations/societyType'
import { SocietyTypes } from '../../../../types/society-type'

interface SocietyInformationProps {
  society: SnapshotOut<typeof SocietyModel>
  alignTextStart?: boolean
}

export const SocietyInformation = ({
  society,
  alignTextStart,
}: SocietyInformationProps): JSX.Element => {
  const { translate } = useAppTranslation()

  const societyType = society?.societyTypeDisplayName
    ? translate(
        getSocietyType(society.societyTypeDisplayName as SocietyTypes) as string
      )
    : undefined

  return (
    <div
      className={`flex flex-col gap-1 ${
        alignTextStart ? 'items-start' : 'items-center'
      }`}
    >
      <p style={theme.textVariants.baseBold} translate="no">
        {society?.name}
      </p>
      <p style={theme.textVariants.caption}>{`${societyType} ${translate(
        'common.in'
      )} ${society?.addressCity}`}</p>
      <p style={theme.textVariants.caption}>
        {translate('common.form.labels.organisationNumber')}:{' '}
        {formatOrganisationNumber(society?.organisationNumber || '')}
      </p>
    </div>
  )
}
