import React from 'react'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { InferType } from 'yup'
import { observer } from 'mobx-react-lite'
import { chatMessageSchema } from '../../../forms/schemas/chat_message'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { TextInput } from '../../common/TextInput'
import { DropdownSelect } from '../../common/DropdownSelect'
import { DropdownItemContent } from '../../feed/DropdownItemContent'
import { Avatar } from '../../common/Avatar'
import { useStores } from '../../../hooks/useStores'
import {
  isUserAdminInSociety,
  isUserBoardMemberInSociety,
} from '../../../helpers/society'

interface StartNewBoardChatHeaderProps {
  control: Control<InferType<typeof chatMessageSchema>>
  setValue: UseFormSetValue<InferType<typeof chatMessageSchema>>
}

export const StartNewBoardChatHeader = observer(
  ({ control, setValue }: StartNewBoardChatHeaderProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { authenticationStore, societyStore } = useStores()

    const societyDropdownOptions = societyStore.sortedSocieties
      .filter((_society) => {
        return (
          isUserAdminInSociety(
            _society,
            authenticationStore.userId as string
          ) ||
          isUserBoardMemberInSociety(
            _society,
            authenticationStore.userId as string
          )
        )
      })
      .map((society) => ({
        value: society._id,
        label: society.name,
        image: <Avatar size={30} mediaId={society.coverPhotoId} />,
      }))

    return (
      <div className="my-4 mx-6 flex flex-col gap-2">
        <div className="flex w-full">
          <div>
            <div className="flex items-center">
              <div>{translate('startNewChat.boardChatInputLabel')}</div>
              <div className="w-60">
                <Controller
                  control={control}
                  name="groupChatTitle"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextInput
                      className="pl-2"
                      borderless
                      value={value}
                      onChange={(e) => {
                        // For some reason, onChange would not trigger in StartNewBoardChatHeader
                        // I've added a manual setValue for now.
                        setValue('groupChatTitle', e.currentTarget.value)
                        onChange(e)
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-fit">
          <Controller
            control={control}
            name="society"
            render={({ field: { value, onChange } }) => (
              <DropdownSelect
                value={value}
                onChange={(e) => {
                  // For some reason, onChange would not trigger in StartNewBoardChatHeader
                  // I've added a manual setValue for now.
                  onChange(e)
                  setValue('society', e)
                }}
                options={societyDropdownOptions}
                dropdownWidth="w-60"
                renderItemContent={(option) => (
                  <DropdownItemContent option={option} />
                )}
              />
            )}
          />
        </div>
      </div>
    )
  }
)
