import React, { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import FocusTrap from 'focus-trap-react'
import { useDisableBodyScroll } from '../../../hooks/useDisableBodyScroll'

const MODAL_ROOT_ID = 'modal-root'

interface ModalProps {
  children: {
    header?: ReactNode
    body?: ReactNode
    footer?: ReactNode
  }
  show: boolean
  minHeight?: number
  height?: number
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'full'
}

export const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
  function Modal(
    { children, show, minHeight, height, size = 'md' }: ModalProps,
    ref
  ): JSX.Element | null {
    const { header, body, footer } = children
    useDisableBodyScroll(show)

    const getModalSize = (): string => {
      switch (size) {
        case 'sm':
          return '600px'
        case 'lg':
          return '1000px'
        case 'xl':
          return '1200px'
        case 'full':
          return '100%'
        default: // md
          return '800px'
      }
    }

    return (
      <>
        {show &&
          createPortal(
            <FocusTrap>
              <div className="relative z-50" role="dialog" aria-modal="true">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                <div
                  className="fixed top-0 left-0 z-40 h-full w-full overflow-y-auto overflow-x-hidden
            outline-none"
                >
                  <div
                    style={{ maxWidth: getModalSize() }}
                    className="pointer-events-none relative my-6 mx-auto flex h-[calc(100%-3rem)]
              w-auto items-center px-3"
                  >
                    <div
                      ref={ref}
                      className={`pointer-events-auto relative flex max-h-full w-full flex-col overflow-clip
                rounded-md border-none bg-white text-current shadow-lg outline-none`}
                      style={{ minHeight, height }}
                    >
                      {header}
                      {body}
                      {footer}
                    </div>
                  </div>
                </div>
              </div>
            </FocusTrap>,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            document.getElementById(MODAL_ROOT_ID)!
          )}
      </>
    )
  }
)
