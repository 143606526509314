import * as yup from 'yup'

export const weeklySchedule = yup.array().of(
  yup.object({
    allowed: yup.boolean().required(),
    startTime: yup.string().required(),
    endTime: yup.string().required(),
  })
)

export const societyFacilityWeeklyScheduleCreateSchema = yup
  .object({
    weeklySchedule,
  })
  .required()
