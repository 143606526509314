import React from 'react'
import { useAtom } from 'jotai'
import { ActiveTabBar } from '../ChatSideBarContent'
import { IconChoices } from '../../common/Icon'
import { Button } from '../../common/Button'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { ChatHeaderState, chatHeaderStateAtom } from '../atom'
import { useHamburger } from '../../../hooks/useHamburgerContext'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { JoinGroupModal } from '../JoinGroupModal'
import { useModal } from '../../../hooks/useModal'
import { FindArchiveChatsModal } from '../FindArchivedChatsModal'
import { AskBoardNewMessageModal } from '../AskBoardNewMessageModal'

interface ChatHeaderButtonsProps {
  activeTabBar: ActiveTabBar
  activeTab: number
}

export const ChatHeaderButtons = ({
  activeTabBar,
  activeTab,
}: ChatHeaderButtonsProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const { chatRoomStore, societyStore } = useStores()
  const [, setChatHeaderState] = useAtom(chatHeaderStateAtom)
  const { setDisplaySidebar } = useHamburger()
  const isMobile = useIsMobile()
  const { selectedSociety } = societyStore
  const {
    show: showJoinGroupModal,
    open: openJoinGroupModal,
    close: closeJoinGroupModal,
  } = useModal()
  const {
    show: showFindArchivedChatsModal,
    open: openFindArchivedChatsModal,
    close: closeFindArchivedChatsModal,
  } = useModal()
  const {
    show: showAskBoardNewMessageModal,
    open: openAskBoardNewMessageModal,
    close: closeAskBoardNewMessageModal,
  } = useModal()

  const getTranslation = (): string => {
    if (activeTabBar === ActiveTabBar.RESIDENT) {
      switch (activeTab) {
        case 1:
          return 'chatRoomsSidebar.startNewAskBoardChat'
        default:
          return 'chatRoomsSidebar.startNewChat'
      }
    } else {
      switch (activeTab) {
        default:
          return 'chatRoomsSidebar.startNewBoardChat'
      }
    }
  }

  const hideSideBarOnMobile = (): void => {
    if (isMobile) {
      setDisplaySidebar(false)
    }
  }

  const onStartNewChatClick = (): void => {
    chatRoomStore.setSelectedChatRoom(undefined)
    if (activeTabBar === ActiveTabBar.BOARD && activeTab === 0) {
      setChatHeaderState(ChatHeaderState.StartNewBoardChat)
      hideSideBarOnMobile()
    } else if (activeTabBar === ActiveTabBar.RESIDENT && activeTab === 0) {
      setChatHeaderState(ChatHeaderState.StartNewChat)
      hideSideBarOnMobile()
    } else if (activeTabBar === ActiveTabBar.RESIDENT && activeTab === 1) {
      openAskBoardNewMessageModal()
      setChatHeaderState(ChatHeaderState.AskBoardNewMessage)
    }
  }

  const onJoinGroupChatClick = (): void => {
    openJoinGroupModal()
  }

  const onJoinGroupModalItemSelect = (): void => {
    setChatHeaderState(ChatHeaderState.JoinChat)
    closeJoinGroupModal()
  }

  const onFindArchivedChatClick = (): void => {
    openFindArchivedChatsModal()
    setChatHeaderState(
      activeTabBar === ActiveTabBar.RESIDENT
        ? ChatHeaderState.FindArchivedChatsResident
        : ChatHeaderState.FindArchivedChats
    )
  }

  const onCloseFindArchivedChatsModal = (): void => {
    setChatHeaderState(ChatHeaderState.None)
    closeFindArchivedChatsModal()
  }

  const onCloseAskBoardNewMessageModal = (): void => {
    setChatHeaderState(ChatHeaderState.None)
    closeAskBoardNewMessageModal()
  }

  const shouldNotRender = activeTabBar === ActiveTabBar.BOARD && activeTab === 1

  return (
    <>
      <div className="mx-6 flex justify-between">
        {!shouldNotRender && (
          <Button
            disabled={!selectedSociety}
            label={translate(getTranslation())}
            size="sm-wide"
            icon={IconChoices.PLUS_SIGN}
            onClick={onStartNewChatClick}
          />
        )}
        {!shouldNotRender &&
          activeTabBar === ActiveTabBar.RESIDENT &&
          activeTab === 0 && (
            <Button
              disabled={!selectedSociety}
              label={translate('chatRoomsSidebar.joinGroupChat')}
              size="sm-wide"
              icon={IconChoices.ARROW}
              onClick={onJoinGroupChatClick}
            />
          )}
        {((activeTabBar === ActiveTabBar.RESIDENT && activeTab === 1) ||
          shouldNotRender) && (
          <Button
            disabled={!selectedSociety}
            label={translate('chatRoomsSidebar.findQuestionsChat')}
            className={`${shouldNotRender ? 'w-full' : ''}`}
            wrapperClassName={`${shouldNotRender ? 'w-full' : ''}`}
            icon={IconChoices.ARROW}
            size="sm-wide"
            onClick={onFindArchivedChatClick}
          />
        )}
      </div>
      <JoinGroupModal
        show={showJoinGroupModal}
        close={closeJoinGroupModal}
        onItemSelect={onJoinGroupModalItemSelect}
      />
      <FindArchiveChatsModal
        show={showFindArchivedChatsModal}
        close={onCloseFindArchivedChatsModal}
        onItemSelect={onCloseFindArchivedChatsModal}
      />
      <AskBoardNewMessageModal
        show={showAskBoardNewMessageModal}
        close={onCloseAskBoardNewMessageModal}
      />
    </>
  )
}
