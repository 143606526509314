import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { useStores } from '../../../../../hooks/useStores'
import { useCurrentSociety } from '../../../../../hooks/useCurrentSociety'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../common/Toast/toast-type'
import { societyFAQSectionCreateSchema } from '../../../../../forms/schemas/society_faq_section_create'
import { Modal } from '../../../../common/Modal'
import { ModalHeader } from '../../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../../common/Modal/ModalBody'
import { CreateUpdateQuestionsAndAnswersSectionForm } from '../CreateUpdateQuestionsAndAnswersSectionForm'

interface CreateUpdateQuestionsAndAnswersSectionModalProps {
  show: boolean
  close: () => void
  children?: React.ReactNode
  sectionId?: string
}

export const CreateUpdateQuestionsAndAnswersSectionModal = observer(
  ({
    sectionId,
    children,
    show,
    close,
  }: CreateUpdateQuestionsAndAnswersSectionModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { faqsStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!sectionId

    const showErrorMessage = (): void => {
      const message = updateMode
        ? 'createUpdateQuestionsAndAnswers.flashMessage.updateSectionFailure'
        : 'createUpdateQuestionsAndAnswers.flashMessage.createSectionFailure'
      setToastNotification(ToastType.DANGER, translate(message))
    }

    const showSuccessMessage = (): void => {
      const message = updateMode
        ? 'createUpdateQuestionsAndAnswers.flashMessage.updateSectionSuccess'
        : 'createUpdateQuestionsAndAnswers.flashMessage.createSectionSuccess'
      setToastNotification(ToastType.SUCCESS, translate(message))
    }

    const onSubmit = async (
      data: InferType<typeof societyFAQSectionCreateSchema>
    ): Promise<void> => {
      if (updateMode) {
        const status = await faqsStore.patchFaqSection(sectionId, data)

        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      } else {
        const status = await faqsStore.createFaqSection({
          ...data,
          societyId: society._id,
        })
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      showErrorMessage()
    }

    const loading =
      faqsStore.creatingFAQ === 'pending' || faqsStore.updatingFAQ === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate(
                  updateMode
                    ? 'createUpdateQuestionsAndAnswers.editSectionTitle'
                    : 'createUpdateQuestionsAndAnswers.addNewSectionTitle'
                )}
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <CreateUpdateQuestionsAndAnswersSectionForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  sectionId={sectionId}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
