import React, { ReactNode, KeyboardEvent } from 'react'
import { useHamburger } from '../../../hooks/useHamburgerContext'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { useSidebarAnimation } from '../../../hooks/useSidebarAnimation'

interface SideBarProps {
  headerTitle?: string
  className?: string
  children: ReactNode
  padding?: boolean
  headerClassName?: string
}

const SideBar = ({
  headerTitle,
  className,
  children,
  padding = true,
  headerClassName,
}: SideBarProps): JSX.Element => {
  const isMobile = useIsMobile()
  const { isHamburgerMode, setDisplaySidebar } = useHamburger()
  const { overlayAnimation, sidebarAnimation, sidebarStartPosition } =
    useSidebarAnimation()

  const onClick = (): void => {
    setDisplaySidebar(false)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      setDisplaySidebar(false)
    }
  }

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        onClick={onClick}
        onKeyDown={handleKeyDown}
        className={`
          ${isHamburgerMode ? 'absolute z-10 h-full w-full' : ''}
          ${overlayAnimation}
        `}
      />
      <div
        className={`${className || ''}
          w-sidebar min-w-sidebar flex-col border-r
          ${isHamburgerMode ? 'absolute z-20  bg-white' : ''}
          ${sidebarStartPosition}
          ${sidebarAnimation}
        `}
        style={{ height: `calc(100vh - ${isMobile ? 52 : 64}px)` }}
      >
        <div
          className={`flex h-full flex-col space-y-3 ${padding ? 'p-6' : ''}`}
        >
          {headerTitle && (
            <div className={`flex items-center ${headerClassName ?? ''}`}>
              <h2 className="text-xl font-bold">{headerTitle}</h2>
            </div>
          )}
          {children}
        </div>
      </div>
    </>
  )
}

SideBar.displayName = 'SideBar'
export { SideBar }
