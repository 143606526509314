import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'

export const SocietyWidgetModel = types.model('SocietyWidgetModel', {
  _id: types.identifier,
  ...timestampTypes,
  title: types.string,
  societyId: types.string,
  type: types.maybe(
    types.enumeration('WidgetType', ['text-widget', 'link-widget'])
  ),
  link: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
})
