/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'

export const ChatMessageModel = types.model('ChatMessage', {
  _id: types.identifier,
  ...timestampTypes,
  message: types.string,
  authorId: types.string,
  roomId: types.string,
  mediaIds: types.maybe(types.array(types.string)),
  readBy: types.maybe(
    types.array(
      types.model({
        userId: types.string,
        readTime: types.string,
      })
    )
  ),
})
