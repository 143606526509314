import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import Fuse from 'fuse.js'
import { useStores } from '../../../../hooks/useStores'
import { ResidentList } from '../../../../components/society/ResidentList'
import { SpinnerWrapper } from '../../../../components/common/SpinnerWrapper'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ViewBase } from '../../../../components/common/ViewBase'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'

export const ResidentListView = observer((): JSX.Element => {
  const [searchString, setSearchString] = useState('')
  const { translate } = useAppTranslation()
  const { unitStore, societyStore } = useStores()
  const { hasFetchedSocietiesOnce } = societyStore
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const societyId = society._id as string
  useDocumentTitle(
    reverseDocumentTitle('society:resident-list', {
      '{{ societyName }}': society.name,
    })
  )

  const residentUsers = unitStore.residentUsersForSocietyWithUnits(
    societyStore?.selectedSociety?._id as string
  )

  // const dataIsBeingFetched = unitStore.fetchingUnits === 'pending'

  const getFilteredUsers = useCallback(() => {
    const options = {
      threshold: 0.2,
      keys: [
        'user.fullName',
        'user.name',
        'user.surname',
        'units.title',
        'units.titleLegal',
      ],
    }

    const fuse = new Fuse(residentUsers, options)

    const filteredUsers =
      searchString === ''
        ? residentUsers
        : fuse.search(searchString).map((result) => result.item)

    return filteredUsers.map((_user) => _user.user)
  }, [residentUsers, searchString])

  useEffect(() => {
    unitStore.getUnitsPerSociety(societyId)
    societyStore.getSociety(societyId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeSearchString = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    setSearchString(event.currentTarget.value)
  }

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('residentListView.title')}
        searchBar={{ value: searchString, onChange: onChangeSearchString }}
      >
        {!hasFetchedSocietiesOnce ? (
          <SpinnerWrapper />
        ) : (
          <ResidentList users={getFilteredUsers()} society={society} />
        )}
      </ViewBase>
    </ErrorBoundary>
  )
})
