import { useStores } from '../../../hooks/useStores'
import { IDropdownSelectOption } from '../../common/DropdownSelect/IDropdownSelectOption'

export const useSocietyDropdownOptions = (): IDropdownSelectOption[] => {
  const { societyStore } = useStores()

  const societyOptions = societyStore.sortedSocieties.map((society) => ({
    value: society._id,
    label: society.name,
  }))

  return societyOptions
}
