import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { ViewNames } from '../../../../../navigation/reverseUrl'
import { DocumentModel } from '../../../../../state/models/document'
import { DocumentsListItemDraggable } from '../DocumentListItemDraggable'
import { useDocumentDroppable } from '../useDocumentDroppable'

interface FolderDropWrapperProps {
  document: SnapshotOut<typeof DocumentModel>
  documentRoute: ViewNames
  openEditFolderModal: () => void
  openEditDocumentModal: () => void
  openRemoveConfirmationModal: () => void
  isListMode: boolean
}

export const FolderDropWrapper = observer(
  ({
    document,
    documentRoute,
    openEditFolderModal,
    openEditDocumentModal,
    openRemoveConfirmationModal,
    isListMode,
  }: FolderDropWrapperProps): JSX.Element => {
    const { isOver, drop } = useDocumentDroppable(document._id)

    return (
      <div ref={drop} role="drop">
        <DocumentsListItemDraggable
          document={document}
          documentRoute={documentRoute}
          openEditDocumentModal={openEditDocumentModal}
          openEditFolderModal={openEditFolderModal}
          openRemoveConfirmationModal={openRemoveConfirmationModal}
          isListMode={isListMode}
          isOver={isOver}
        />
      </div>
    )
  }
)
