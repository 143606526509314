/* eslint-disable max-len */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { SnapshotOut } from 'mobx-state-tree'
import { captureException } from '@sentry/react'
import { Modal } from '../../../../common/Modal'
import { ModalHeader } from '../../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { useStores } from '../../../../../hooks/useStores'
import { ToastType } from '../../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { DocumentModel } from '../../../../../state/models/document'
import { societyDocumentUpdateSchema } from '../../../../../forms/schemas/society_document_update'
import { societyDocumentUploadSchema } from '../../../../../forms/schemas/society_document_upload'
import { UpdateSocietyWebsiteDocumentForm } from '../CreateUpdateSocietyWebsiteDocumentForm/UpdateSocietyWebsiteDocumentForm'
import { CreateSocietyWebsiteDocumentForm } from '../CreateUpdateSocietyWebsiteDocumentForm/CreateSocietyWebsiteDocumentForm'
import { useCurrentSociety } from '../../../../../hooks/useCurrentSociety'
import { SocietyWebsiteModel } from '../../../../../state/models/society-website'

interface CreateUpdateSocietyWebsiteDocumentModalProps {
  website: SnapshotOut<typeof SocietyWebsiteModel>
  show: boolean
  close: () => void
  folderId: string
  children?: React.ReactNode
  id?: string
}

export const CreateUpdateSocietyWebsiteDocumentModal = observer(
  ({
    website,
    id,
    folderId,
    children,
    show,
    close,
  }: CreateUpdateSocietyWebsiteDocumentModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { documentStore, societyWebsitesStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!id
    const document = id
      ? (documentStore.documents.get(id) as SnapshotOut<typeof DocumentModel>)
      : undefined

    const folders = website ? website.foldersList : []

    const showErrorMessage = (): void => {
      const message = updateMode
        ? 'createUpdateDocument.flashMessage.updateDocumentFailure'
        : 'createUpdateDocument.flashMessage.createDocumentFailure'
      setToastNotification(ToastType.DANGER, translate(message))
    }

    const showSuccessMessage = (): void => {
      const message = updateMode
        ? 'createUpdateDocument.flashMessage.updateDocumentSuccess'
        : 'createUpdateDocument.flashMessage.createDocumentSuccess'
      setToastNotification(ToastType.SUCCESS, translate(message))
    }

    const handleRequestMessages = (status: boolean): void => {
      if (status) {
        close()
        showSuccessMessage()
      } else {
        showErrorMessage()
      }
    }

    const onSubmitUpdateDocument = async (
      data: InferType<typeof societyDocumentUpdateSchema>
    ): Promise<void> => {
      const status = id && (await documentStore.patchDocument(id, { ...data }))
      if (status) {
        handleRequestMessages(status)
      }
    }

    const showMessage = (type: ToastType, message: string): void => {
      setToastNotification(type, message)
    }

    const getSuccessMessage = (isSingular: boolean): string => {
      return translate(
        isSingular
          ? 'createUpdateDocument.flashMessage.createDocumentSingularSuccess'
          : 'createUpdateDocument.flashMessage.createDocumentPluralSuccess'
      )
    }

    const getFailureMessage = (isSingular: boolean): string => {
      return translate(
        isSingular
          ? 'createUpdateDocument.flashMessage.createDocumentSingularFailure'
          : 'createUpdateDocument.flashMessage.createDocumentPluralFailure'
      )
    }

    const onSubmitCreateDocument = async (
      data: InferType<typeof societyDocumentUploadSchema>
    ): Promise<void> => {
      const promises = data.files.map(async (_file) => {
        const docId = await documentStore.createDocument(
          _file.mimeType,
          _file.base64,
          _file.name,
          _file.filename,
          folderId,
          society._id,
          undefined,
          'none'
        )
        return docId
      })

      const isOneFile = data.files.length === 1

      const ids = await Promise.all(promises)

      if (!ids) {
        showMessage(ToastType.DANGER, getFailureMessage(isOneFile))
        close()
        return
      }

      const status = await societyWebsitesStore.patchWebsite(website._id, {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        foldersList: folders?.map((_f: any) => {
          if (_f._id === folderId) {
            return {
              ..._f,
              documentsList: [
                ..._f.documentsList,
                ...ids.map((_id) => ({ documentId: _id })),
              ],
            }
          }
          return _f
        }),
      })
      if (status) {
        showMessage(ToastType.SUCCESS, getSuccessMessage(isOneFile))
      } else {
        showMessage(ToastType.DANGER, getFailureMessage(isOneFile))
      }
      close()
    }

    const onError = (error: unknown): void => {
      captureException(error)
      showErrorMessage()
    }

    const loading =
      documentStore.creatingFolder === 'pending' ||
      documentStore.updatingDocument === 'pending' ||
      documentStore.creatingDocument === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate(
                  updateMode
                    ? 'createUpdateDocument.editDocumentTitle'
                    : 'createUpdateDocument.addNewDocumentTitle'
                )}
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                {updateMode && document ? (
                  <UpdateSocietyWebsiteDocumentForm
                    onError={onError}
                    onSubmit={onSubmitUpdateDocument}
                    onClose={close}
                    loading={loading}
                    document={document}
                  />
                ) : (
                  <CreateSocietyWebsiteDocumentForm
                    onError={onError}
                    onSubmit={onSubmitCreateDocument}
                    onClose={close}
                    loading={loading}
                  />
                )}
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
