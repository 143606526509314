export enum EducationLevel {
  HIGH_SCHOOL = 'high-school',
  PROFESSIONAL_SCHOOL = 'professional-school',
  UNIVERSITY = 'university',
  OTHER = 'other',
  NONE = 'none',
}

export enum EducationDegree {
  BACHELOR = 'bsc',
  MASTER = 'msc',
  PHD = 'phd',
  OTHER = 'other',
  NONE = 'none',
}

export enum EducationField {
  AIR_PILOT = 'air-pilot',
  ANIMAL_KEEPER = 'animal-keeper',
  ARCHITECTURE = 'architecture',
  BSC_OF_ENGINEERING = 'bsc-of-engineering',
  BIO_MEDICINE = 'bio-medicine',
  BUSINESS = 'business',
  CIVIL_ENGINEERING = 'civil-engineering',
  DENTAL_HYGIENE = 'dental-hygiene',
  DENTIST = 'dentist',
  DIETETICS = 'dietetics',
  ENGINEERING = 'engineering',
  ENVIRONMENTAL_SCIENCE = 'environmental-science',
  FINANCE = 'finance',
  FIRE_SCIENCE = 'fire-science',
  FORESTERY = 'forestery',
  FOREST_ENGINEERING = 'forest-engineering',
  HEALTH_SCIENCE = 'health-science',
  HIPPOLOGY = 'hippology',
  HUMANITIES = 'humanities',
  HUMAN_RESOURCES = 'human-resources',
  JOURNALISM = 'journalism',
  LAW = 'law',
  LIB_AND_INFO_SCIENCE = 'lib-and-info-science',
  LOGISTICS = 'logistics',
  MARINE_SCIENCE = 'marine-science',
  MEDIA_SCIENCE = 'media-science',
  MEDICAL_DOCTOR = 'medical-doctor',
  NATURE_SCIENCE = 'nature-science',
  NURSING = 'nursing',
  OCCUPATIONAL_THERAPY = 'occupational-therapy',
  OPTOMETRY = 'optometry',
  PHARMACIST = 'pharmacist',
  PHYSIOTHERAPY = 'physiotherapy',
  POLICE = 'police',
  PSYCHOLOGY = 'psychology',
  REAL_ESTATE = 'real-estate',
  SOCIAL_SCIENCE = 'social-science',
  SOCIAL_WORKER = 'social-worker',
  L337_AKA_DEVELOPER = 'l337-aka-developer',
  STATISTICS = 'statistics',
  SYSTEMS_ENGINEERING = 'systems-engineering',
  TEACHER = 'teacher',
  THEOLOGY = 'theology',
  VETERINARY = 'veterinary',
  OTHER = 'other',
  NONE = 'none',
}
