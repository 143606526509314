import * as yup from 'yup'

export const houseRuleCreateSchema = yup
  .object({
    rule: yup.string().required(),
    section: yup.string().required(),
    society: yup.string().required(),
    notificationSettings: yup
      .string()
      .oneOf(['none', 'feed', 'notification+feed']),
  })
  .required()
