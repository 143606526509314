/* eslint-disable max-len */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { ErrorBoundary } from '../../../../../components/common/ErrorBoundary'
import { ViewBase } from '../../../../../components/common/ViewBase'
import { ManagementPartner } from '../../../../../components/society/ManagementPartner/management_partner'
import { ManagementPartnerContactCard } from '../../../../../components/society/ManagementPartner/ManagementPartnerContactCard'
import { ManagementPartnerContactBase } from '../../../../../components/society/ManagementPartner/ManagementPartnerContactBase'
import { useCurrentSociety } from '../../../../../hooks/useCurrentSociety'
import { useDocumentTitle } from '../../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../../navigation/reverseDocumentTitle'

export const BostadsratternaGuidanceView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:bostadsratterna-guidance', {
      '{{ societyName }}': society.name,
    })
  )
  const partner = ManagementPartner.BOSTADSRATTERNA

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('bostadsratterna.bostadsratternaGuidanceView.title')}
      >
        <ManagementPartnerContactBase partner={partner}>
          <ManagementPartnerContactCard
            partner={partner}
            text={translate(
              'bostadsratterna.bostadsratternaGuidanceView.guidanceText'
            )}
          />
        </ManagementPartnerContactBase>
      </ViewBase>
    </ErrorBoundary>
  )
})
