import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { TextInput } from '../../../common/TextInput'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import { useStores } from '../../../../hooks/useStores'
import { SelectDropdown, SelectOption } from '../../../common/SelectDropdown'
import { societyPreApprovedCreateSchema } from '../../../../forms/schemas/society_pre_approved_create'
import { ResidentUserType } from '../../../../state/stores/units'
import { UnitRole } from '../../../../types/unit-roles'
import { getUnitRole } from '../../../../helpers/translations/unit-roles'
import { FormSpacing } from '../../../common/FormSpacing'

interface InviteFormProps {
  onError: () => void
  onSubmit: (
    data: InferType<typeof societyPreApprovedCreateSchema>
  ) => Promise<void>
  onClose: () => void
  loading: boolean
  residentType: ResidentUserType
}

export const InviteForm = observer(
  ({
    loading,
    onError,
    onSubmit,
    onClose,
    residentType,
  }: InviteFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { getErrorMessage } = useFormErrorMessage()
    const { societyEntrancesStore, unitStore } = useStores()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isSamf } = society

    const entrances = societyEntrancesStore.entrancesForSociety(society._id)

    const getDefaultValues = useCallback((): InferType<
      typeof societyPreApprovedCreateSchema
    > => {
      return {
        societyId: society._id,
        userEmail: '',
        entranceId: entrances.length > 0 ? entrances[0]._id : '',
        unitIdOrTitle: '',
        unitRole:
          residentType === ResidentUserType.NON_MEMBER
            ? UnitRole.RESIDENT
            : UnitRole.MEMBER,
      }
    }, [society, entrances, residentType])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
      watch,
      setValue,
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyPreApprovedCreateSchema),
      defaultValues: getDefaultValues(),
    })

    const units = unitStore.unitsForSociety(society._id)
    const watchEntranceId = watch('entranceId')

    const entranceOptions = entrances.map((_entrance) => ({
      label: _entrance.addressStreet as string,
      value: _entrance._id,
    }))

    const entranceUnitOptions: SelectOption[] = units
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .filter((_unit) => _unit.entranceId === watchEntranceId)
      .map((_unit) => ({
        value: _unit._id,
        label: `${_unit.titleLegal} (${_unit.title})`,
      }))

    const allowedUnitRules = (): { [key: string]: UnitRole } => {
      if (!residentType) {
        return { ...UnitRole }
      }

      const { MEMBER, ...rest } = UnitRole

      if (residentType === ResidentUserType.MEMBER) {
        return { MEMBER }
      }

      return { ...rest }
    }

    const unitRoleOptions = Object.values(allowedUnitRules()).map((val) => ({
      value: val as string,
      label: translate(getUnitRole(val) as string),
    }))

    return (
      <FormSpacing>
        <Controller
          control={control}
          name="entranceId"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate(
                isSamf
                  ? 'registerView.unit.labels.entranceSamf'
                  : 'registerView.unit.labels.entrance'
              )}
              error={errors.entranceId && getErrorMessage(errors.entranceId)}
              name={name}
            >
              <SelectDropdown
                options={entranceOptions}
                value={value}
                onChange={(val) => {
                  onChange(val)
                  setValue('unitIdOrTitle', '', {
                    shouldValidate: true,
                  })
                }}
                error={!!errors.entranceId}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="unitIdOrTitle"
          render={({ field: { value, name, onChange } }) => (
            <FormControl
              label={translate(
                isSamf
                  ? 'registerInvite.form.unitSamf'
                  : 'registerInvite.form.unit'
              )}
              error={
                errors.unitIdOrTitle && getErrorMessage(errors.unitIdOrTitle)
              }
              name={name}
            >
              <SelectDropdown
                options={entranceUnitOptions}
                value={value}
                onChange={onChange}
                error={!!errors.unitIdOrTitle}
              />
            </FormControl>
          )}
        />
        {residentType !== ResidentUserType.MEMBER && (
          <Controller
            control={control}
            name="unitRole"
            render={({ field: { value, name, onChange } }) => (
              <FormControl
                label={translate('common.form.labels.role')}
                error={errors.unitRole && getErrorMessage(errors.unitRole)}
                name={name}
              >
                <SelectDropdown
                  options={unitRoleOptions}
                  value={value}
                  onChange={onChange}
                  error={!!errors.unitRole}
                />
              </FormControl>
            )}
          />
        )}
        <Controller
          control={control}
          name="userEmail"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('common.form.labels.email')}
              error={errors.userEmail && getErrorMessage(errors.userEmail)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.userEmail}
                type="email"
              />
            </FormControl>
          )}
        />

        <div className="mt-2 flex items-center justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            disabled={!isValid || loading}
            variant={ButtonVariant.PRIMARY}
            label={translate('common.actions.invite')}
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </FormSpacing>
    )
  }
)
