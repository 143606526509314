import React, { createContext, useContext, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { v4 as uuidv4 } from 'uuid'
import { useTransition, animated } from '@react-spring/web'
import { Toast } from '../components/common/Toast'
import { ToastType } from '../components/common/Toast/toast-type'

interface IToastNotificationContext {
  toastNotifications: ToastRepresentationInterface[]
  setToastNotification: (type: ToastType, text: string) => void
}

const ToastNotificationContext = createContext<IToastNotificationContext>({
  toastNotifications: [],
  setToastNotification: () => null,
})

interface ToastNotificationProviderProps {
  children: React.ReactNode
}

interface ToastRepresentationInterface {
  id: string
  type: ToastType
  title: string
  text?: string
  key: number
}

let id = 0

const ToastNotificationProvider = observer(
  ({ children }: ToastNotificationProviderProps): JSX.Element | null => {
    const [toastNotifications, setToastNotifications] = useState<
      ToastRepresentationInterface[]
    >([])

    const setToastNotification = (
      type: ToastType,
      title: string,
      text?: string
    ): void => {
      setToastNotifications((_notifications) => [
        ..._notifications,
        {
          id: uuidv4(),
          type,
          title,
          text,
          // eslint-disable-next-line no-plusplus
          key: id++,
        },
      ])
    }

    const notificationValue = useMemo(
      () => ({ toastNotifications, setToastNotification }),
      [toastNotifications]
    )

    const refMap = useMemo(() => new WeakMap(), [])
    const cancelMap = useMemo(() => new WeakMap(), [])

    const transitions = useTransition(toastNotifications, {
      from: { opacity: 0, x: 300, life: '100%', right: 10, top: 40 },
      keys: (item) => item.key,
      enter: (item) => async (next, cancel) => {
        cancelMap.set(item, cancel)
        await next({
          opacity: 1,
          x: -8,
          top:
            40 +
            refMap.get(item).offsetHeight * (toastNotifications.length - 1),
        })
        await next({ life: '0%' })
      },
      leave: { opacity: 0, x: 300 },
      onRest: (result, ctrl, item) => {
        setToastNotifications((state) =>
          state.filter((i) => {
            return i.key !== item.key
          })
        )
      },
      config: (item, index, phase) => (key) =>
        phase === 'enter' && key === 'life'
          ? { duration: 5000 }
          : { tension: 125, friction: 20, precision: 0.1 },
    })

    return (
      <ToastNotificationContext.Provider value={notificationValue}>
        <div className="relative z-50 h-full overflow-hidden">
          {transitions(({ life, ...style }, toast) => (
            <animated.div
              ref={(ref: HTMLDivElement) => ref && refMap.set(toast, ref)}
              key={toast.id}
              style={style}
              className="absolute z-50 h-fit w-fit flex-col space-y-3"
            >
              <animated.div
                style={{ right: life }}
                className="absolute bottom-0 left-0 h-[6px] w-auto rounded-b-lg bg-white opacity-50"
              />
              <Toast
                title={toast.title}
                type={toast.type}
                text={toast.text}
                customClose={(
                  e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
                ) => {
                  e.stopPropagation()
                  if (cancelMap.has(toast) && life.get() !== '0%')
                    cancelMap.get(toast)()
                }}
              />
            </animated.div>
          ))}
          {children}
        </div>
      </ToastNotificationContext.Provider>
    )
  }
)

ToastNotificationProvider.displayName = 'ToastNotificationProvider'
export { ToastNotificationProvider }

export const useToastNotifications = (): IToastNotificationContext =>
  useContext(ToastNotificationContext)
