import React, { ReactNode } from 'react'
import { theme } from '../../../../theme/theme'
import { Divider } from '../../../common/Divider'
import { Spinner } from '../../../common/Spinner'

interface FacilityCalendarProps {
  title: string
  children: ReactNode
  dividerTop?: boolean
  loading?: boolean
  hideHeader?: boolean
  className?: string
}

export const FacilityCalendarSection = ({
  title,
  children,
  dividerTop,
  loading,
  hideHeader,
  className,
}: FacilityCalendarProps): JSX.Element | null => {
  return (
    <div className={className}>
      {dividerTop && <Divider className="mb-10" />}
      <p style={theme.textVariants.lead} className="mb-3">
        {!hideHeader && title}
      </p>
      {loading ? (
        <div className="mt-4">
          <Spinner />
        </div>
      ) : (
        children
      )}
    </div>
  )
}
