import { SnapshotOut } from 'mobx-state-tree'
import i18n from '../../../i18n/i18n'
import { GroupModel } from '../../../state/models/group'

export const getGroupSubtitle = (
  group: SnapshotOut<typeof GroupModel>
): string => {
  if (group.unitsList && group.unitsList.length > 0) {
    return `${group.unitsList.length} ${(group.unitsList.length > 1
      ? i18n.t('registerView.group.unitsPlural')
      : i18n.t('registerView.group.unitsSingular')
    )?.toLowerCase()}`
  }
  if (group.membersList && group.membersList.length > 0) {
    return `${group.membersList.length} ${(group.membersList.length > 1
      ? i18n.t('registerView.group.membersPlural')
      : i18n.t('registerView.group.membersSingular')
    )?.toLowerCase()}`
  }
  return ''
}
