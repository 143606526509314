/* eslint-disable no-param-reassign */
import { types, flow } from 'mobx-state-tree'
import { captureException } from '@sentry/react'
import { getSocietyStatistics as apiGetSocietyStatistics } from '../../api/statistics'
import { NStats } from '../../interfaces/services/stats.interfaces'
import { stateType } from '../types/common'
import { setObject } from './helpers'
import { StatisticsModel } from '../models/statistics'

export const StatisticsStore = types
  .model('StatisticsStore')
  .props({
    statistics: types.map(StatisticsModel),
    fetchingStatistics: stateType,
  })
  .actions((self) => ({
    reset: () => {
      self.statistics.clear()
      self.fetchingStatistics = 'none'
    },
    setStatistics: (
      statistics: NStats.NGetSocietyStatistics.IResponseInternal[],
      societyId: string
    ) => {
      statistics.forEach((statistic) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setObject<typeof StatisticsModel>(self.statistics, StatisticsModel, {
          _id: societyId,
          ...statistic,
        })
      })
    },
  }))
  .actions((self) => ({
    getSocietyStatistics: flow(function* getSocietyStatistics(
      societyId: string
    ) {
      self.fetchingStatistics = 'pending'
      try {
        const resp = yield apiGetSocietyStatistics(societyId)
        const data = resp.data as NStats.NGetSocietyStatistics.IResponse

        self.setStatistics([data.data], societyId)

        self.fetchingStatistics = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingStatistics = 'error'
      }
    }),
  }))
