import React from 'react'
import { theme } from '../../../theme/theme'

interface BadgeProps {
  text?: string
  colorClass?: string
  textColorClass?: string
  borderColorClass?: string
  style?: React.CSSProperties
  padding?: string
  className?: string
}

export const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  function Badge(
    {
      text,
      colorClass = 'bg-brandGreen',
      textColorClass = 'text-white',
      borderColorClass,
      style,
      padding,
      className,
    }: BadgeProps,
    ref
  ): JSX.Element {
    let _padding = !text ? 'px-2.5' : 'px-3'
    if (padding) {
      _padding = padding
    }
    return (
      <span
        ref={ref}
        style={{
          ...theme.textVariants.fineprintBold,
          ...style,
        }}
        className={`flex items-center rounded-full text-center font-bold leading-none
          ${colorClass} ${textColorClass} ${_padding}
          ${className ?? ''}
          ${borderColorClass ? `border ${borderColorClass}` : ''}
        `}
      >
        {text}
      </span>
    )
  }
)
