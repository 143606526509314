import React from 'react'
import { observer } from 'mobx-react-lite'
import { Matches } from './Matches'
import { CategoryMatch } from './Matches/CategoryMatch'

interface FindFriendsProps {
  categoryMatches: CategoryMatch[]
}

export const FindFriends = observer(
  ({ categoryMatches }: FindFriendsProps): JSX.Element => {
    return <Matches categoryMatches={categoryMatches} />
  }
)
