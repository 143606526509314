/* eslint-disable max-len */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { ErrorBoundary } from '../../../../../components/common/ErrorBoundary'
import { ViewBase } from '../../../../../components/common/ViewBase'
import { ManagementPartnerBase } from '../../../../../components/society/ManagementPartner/ManagementPartnerBase'
import { ManagementPartner } from '../../../../../components/society/ManagementPartner/management_partner'
import { ManagementPartnerCard } from '../../../../../components/society/ManagementPartner/ManagementPartnerCard'
import { BOSTADSRATTERNA_BENEFITS_LINK } from '../../../../../constants/Links'
import { useCurrentSociety } from '../../../../../hooks/useCurrentSociety'
import { useDocumentTitle } from '../../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../../navigation/reverseDocumentTitle'

export const BostadsratternaBenefitsView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('management:bostadsratterna-benefits', {
      '{{ societyName }}': society.name,
    })
  )

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('bostadsratterna.bostadsratternaBenefitsView.title')}
      >
        <ManagementPartnerBase partner={ManagementPartner.BOSTADSRATTERNA}>
          <ManagementPartnerCard
            url={BOSTADSRATTERNA_BENEFITS_LINK}
            title={translate(
              'bostadsratterna.bostadsratternaFormsView.formsTitle'
            )}
            text={translate(
              'bostadsratterna.bostadsratternaFormsView.formsText'
            )}
          />
        </ManagementPartnerBase>
      </ViewBase>
    </ErrorBoundary>
  )
})
