import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'

export const SocietyContactModel = types.model('SocietyContactModel', {
  _id: types.identifier,
  ...timestampTypes,
  societyId: types.string,
  name: types.string,
  title: types.string,
  description: types.maybe(types.string),
  phone: types.maybe(types.string),
  email: types.maybe(types.string),
  website: types.maybe(types.string),
  mediaId: types.maybeNull(types.string),
  notificationSettings: types.maybe(types.string),
})
