import { UnitRole } from '../../types/unit-roles'
import { translateEnumDict } from './helpers'

const unitRoleTranslations: { [key: string]: string } = {
  [UnitRole.MEMBER]: 'unitRoles.member',
  [UnitRole.RESIDENT]: 'unitRoles.resident',
  [UnitRole.TENANT]: 'unitRoles.tentant',
  [UnitRole.RELATIVE]: 'unitRoles.relative',
  [UnitRole.PROPERTY_MANAGER]: 'unitRoles.propertyManager',
  [UnitRole.CUSTOMER_REPRESENTATIVE]: 'unitRoles.customerRepresentative',
  [UnitRole.OTHER]: 'unitRoles.other',
}

export const getUnitRole = (unitRole: UnitRole): string | null => {
  return translateEnumDict(unitRoleTranslations, unitRole)
}
