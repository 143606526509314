import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card } from '../../../../components/common/Card'
import { CardBody } from '../../../../components/common/Card/CardBody'
import {
  Illustration,
  IllustrationChoices,
} from '../../../../components/common/Illustration'
import { Icon, IconChoices } from '../../../../components/common/Icon'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { SocietyTypes } from '../../../../types/society-type'
import { SocietyType } from '../SocietyType'
import { theme } from '../../../../theme/theme'

interface CreateSocietyTypeCardProps {
  title: string
  type: SocietyType
  illustration: IllustrationChoices
  className?: string
}

export const CreateSocietyTypeCard = ({
  title,
  type,
  illustration,
  className,
}: CreateSocietyTypeCardProps): JSX.Element => {
  const navigate = useNavigate()

  const getSocietyType = (): SocietyTypes => {
    switch (type) {
      case SocietyType.HOUSING_COOPERATIVE:
        return SocietyTypes.HOUSING_COOPERATIVE
      case SocietyType.HOMEOWNERS_ASSOCIATION:
        return SocietyTypes.HOME_OWNERS_ASSOCIATION
      case SocietyType.NEIGHBOURHOOD:
        return SocietyTypes.NEIGHBOURHOOD
      default:
        return SocietyTypes.HOUSING_COOPERATIVE_CONSTRUCTION
    }
  }

  const navigateToForm = (): void => {
    const societyTypeDisplayName = getSocietyType()
    navigate(reverseUrl('create-society'), {
      state: { societyTypeDisplayName },
    })
  }

  return (
    <Card onClick={navigateToForm} className={`${className ?? ''}`}>
      {{
        body: (
          <CardBody className="h-full">
            <div className="flex h-full flex-col justify-between space-y-5">
              <div className="flex flex-1 items-center justify-center">
                <Illustration illustrationChoice={illustration} />
              </div>
              <div className="flex justify-between">
                <p style={theme.textVariants.lead}>{title}</p>
                <Icon icon={IconChoices.ARROW} />
              </div>
            </div>
          </CardBody>
        ),
      }}
    </Card>
  )
}
