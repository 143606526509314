import { SnapshotOut } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import { prependHttp } from '../../../../helpers/url'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useStores } from '../../../../hooks/useStores'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import {
  HiddenWidgetRole,
  SocietyModel,
} from '../../../../state/models/society'
import { IconChoices } from '../../../common/Icon'
import { LinkSection } from '../LinkSection'
import {
  isUserAdminInSociety,
  isUserBoardMemberInSociety,
} from '../../../../helpers/society'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'

export const useResidentLinks = (
  society: SnapshotOut<typeof SocietyModel>,
  societyLockedForUser: boolean
): LinkSection[] => {
  const { translate } = useAppTranslation()
  const { societyWidgetsStore } = useStores()
  const authUserId = useAuthenticatedUserId()
  const id = society._id

  const widgetIsHidden = (
    widgetType: SnapshotOut<typeof HiddenWidgetRole>
  ): boolean => {
    if (!society.hiddenSocietyWidgets) {
      return false
    }
    return society.hiddenSocietyWidgets?.includes(widgetType)
  }

  const currentUserIsAdmin = isUserAdminInSociety(society, authUserId as string)
  const currentUserIsBoard = isUserBoardMemberInSociety(
    society,
    authUserId as string
  )

  const widgets = societyWidgetsStore.widgetsForSociety(id)

  const residentLinks: LinkSection[] = [
    {
      id: uuid(),
      ...((currentUserIsAdmin || currentUserIsBoard) && {
        title: translate(
          'societySideBar.sections.resident.residentFunctionsTitle'
        ),
      }),
      alwaysOpen: !currentUserIsAdmin && !currentUserIsBoard && true,
      sublinks: [
        {
          title: translate('residentListView.title'),
          icon: IconChoices.RESIDENT_LIST,
          url: reverseUrl('society:resident-list', { id }),
          disabled: societyLockedForUser,
        },
        ...[
          widgetIsHidden('board')
            ? undefined
            : {
                title: translate('boardListView.title'),
                icon: IconChoices.GROUPS,
                url: reverseUrl('society:board', { id }),
                disabled: societyLockedForUser,
              },
        ],
        ...[
          widgetIsHidden('contacts')
            ? undefined
            : {
                title: translate('contactsView.title'),
                icon: IconChoices.GROUP,
                url: reverseUrl('society:contacts', { id }),
                disabled: societyLockedForUser,
              },
        ],
        ...[
          widgetIsHidden('calendar')
            ? undefined
            : {
                title: translate('societyCalendarListView.title'),
                icon: IconChoices.CALENDAR,
                url: reverseUrl('society:calendar', { id }),
                disabled: societyLockedForUser,
              },
        ],
        ...[
          widgetIsHidden('documents')
            ? undefined
            : {
                title: translate('documentsView.title'),
                icon: IconChoices.FOLDER,
                url: reverseUrl('society:documents', { id }),
                disabled: societyLockedForUser,
              },
        ],
        ...[
          widgetIsHidden('facilities')
            ? undefined
            : {
                title: translate('facilitiesView.title'),
                icon: IconChoices.LAUNDRY,
                url: reverseUrl('society:facilities', { id }),
                disabled: societyLockedForUser,
              },
        ],
        ...[
          widgetIsHidden('faqs')
            ? undefined
            : {
                title: translate('questionsAndAnswersView.title'),
                icon: IconChoices.QUESTION_MARK,
                url: reverseUrl('society:faq', { id }),
                disabled: societyLockedForUser,
              },
        ],
        ...[
          widgetIsHidden('rules')
            ? undefined
            : {
                title: translate('houseRulesView.title'),
                icon: IconChoices.LIST,
                url: reverseUrl('society:house-rules', { id }),
                disabled: societyLockedForUser,
              },
        ],
        ...[
          widgetIsHidden('local-offers')
            ? undefined
            : {
                title: translate('localOffersView.title'),
                icon: IconChoices.LOCAL_OFFER,
                url: reverseUrl('society:local-offers', { id }),
                disabled: societyLockedForUser,
              },
        ],
        ...[
          widgetIsHidden('reportProblem')
            ? undefined
            : {
                title: translate('reportProblemView.title'),
                icon: IconChoices.REPORT,
                url: reverseUrl('society:report-problem', { id }),
                disabled: societyLockedForUser,
              },
        ],
        ...[
          widgetIsHidden('marketplace')
            ? undefined
            : {
                title: translate('marketplaceListView.title'),
                icon: IconChoices.STORE,
                url: reverseUrl('society:marketplace', { id }),
                disabled: societyLockedForUser,
              },
        ],
        ...[
          widgetIsHidden('services')
            ? undefined
            : {
                title: translate('serviceView.title'),
                icon: IconChoices.OFFER,
                url: reverseUrl('society:services', { id }),
                disabled: societyLockedForUser,
              },
        ],
        ...[
          widgetIsHidden('find-friends')
            ? undefined
            : {
                title: translate('findFriendsView.title'),
                icon: IconChoices.GROUP,
                url: reverseUrl('society:find-friends', { id }),
                disabled: societyLockedForUser,
              },
        ],
        ...(widgets
          ? widgets.map((widget) => ({
              icon:
                widget.type === 'link-widget'
                  ? IconChoices.EXTERNAL_LINK_2
                  : IconChoices.DOCUMENT,
              widgetType: widget.type,
              title: widget.title,
              url:
                widget.type === 'link-widget'
                  ? prependHttp(widget.link || '')
                  : reverseUrl('society:widgets-detail', {
                      id,
                      widgetId: widget._id,
                    }),
              urlIsExternal: widget.type === 'link-widget',
              widgetId: uuid(),
              disabled: societyLockedForUser,
            }))
          : []),
      ].filter((item) => item !== undefined),
    },
  ]

  return residentLinks
}
