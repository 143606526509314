import React, { ReactNode } from 'react'
import { useAtom } from 'jotai'
import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { ErrorView } from '../../../views/error/ErrorView'
import { IllustrationChoices as Illustration } from '../../common/Illustration'
import { ChatErrorState, chatErrorStateAtom } from '../atom'
import { ChatRoomModel } from '../../../state/models/chat-room'

interface ChatRoomErrorWrapperProps {
  chatRoom: SnapshotOut<typeof ChatRoomModel> | undefined
  children: ReactNode
}

export const ChatRoomErrorWrapper = ({
  chatRoom,
  children,
}: ChatRoomErrorWrapperProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const [chatErrorState] = useAtom(chatErrorStateAtom)

  switch (chatErrorState) {
    case ChatErrorState.PendingRequest:
      return (
        <ErrorView
          title={translate('common.errorViews.pendingRequest.title')}
          subtitle={translate('chatRooms.pendingRequest.subtitle')}
          illustration={Illustration.WAITING}
          className="bg-neutral-96 px-4 pb-32"
        />
      )
    case ChatErrorState.NoSociety:
      return (
        <ErrorView
          title={translate('common.errorViews.userNotInSociety.title')}
          subtitle={translate('common.errorViews.userNotInSociety.subtitle')}
          illustration={Illustration.EMPTY}
          className="bg-neutral-96 px-4 pb-32"
        />
      )
    case !chatRoom && ChatErrorState.ChatDisabled:
      return (
        <ErrorView
          title={translate('chatRooms.chatDisabled.title')}
          subtitle={translate('chatRooms.chatDisabled.subtitle')}
          illustration={Illustration.EMPTY}
          className="bg-neutral-96 px-4 pb-32"
        />
      )
    // TODO: Look over copy now that we have merged one-on-one and group chats?
    case ChatErrorState.EmptyChat:
      return (
        <ErrorView
          title={translate('chatRooms.userHasNoChats.title')}
          subtitle={translate('chatRooms.userHasNoChats.subtitle')}
          illustration={Illustration.EMPTY}
          className="bg-neutral-96 px-4 pb-32"
        />
      )
    case ChatErrorState.EmptyAskBoard:
      return (
        <ErrorView
          title={translate('chatRooms.noBoardQuestions.title')}
          subtitle={translate('chatRooms.noBoardQuestions.subtitle')}
          illustration={Illustration.EMPTY}
          className="bg-neutral-96 px-4 pb-32"
        />
      )
    case ChatErrorState.EmptyBoardChat:
      return (
        <ErrorView
          title={translate('chatRooms.noBoardChats.title')}
          subtitle={translate('chatRooms.noBoardChats.subtitle')}
          illustration={Illustration.EMPTY}
          className="bg-neutral-96 px-4 pb-32"
        />
      )
    case ChatErrorState.EmptyBoardAnswerResident:
      return (
        <ErrorView
          title={translate('chatRooms.noBoardAnswerResidentChats.title')}
          subtitle={translate('chatRooms.noBoardAnswerResidentChats.subtitle')}
          illustration={Illustration.EMPTY}
          className="bg-neutral-96 px-4 pb-32"
        />
      )
    default:
      return <>{children}</>
  }
}
