import React from 'react'
import { theme } from '../../../theme/theme'
import { Button } from '../Button'
import { IconChoices } from '../Icon'

interface UserListItemProps {
  avatar: JSX.Element
  name: string
  text: string
  textStyle?: React.CSSProperties
  icon?: IconChoices
  onIconClick?: () => void
  className?: string
  disabled?: boolean
}

export const UserListItem = ({
  avatar,
  name,
  text,
  textStyle,
  icon,
  onIconClick,
  className,
  disabled = false,
}: UserListItemProps): JSX.Element => {
  return (
    <div className={`flex items-center justify-between ${className ?? ''}`}>
      <div className="flex items-center gap-4">
        {avatar}
        <div className="flex flex-col">
          <p style={textStyle || theme.textVariants.lead}>{name}</p>
          <p style={theme.textVariants.caption} className="text-neutral-50">
            {text}
          </p>
        </div>
      </div>
      {icon && (
        <Button
          type="button"
          className="h-10 w-10"
          size="no-padding"
          iconSize={18}
          icon={icon}
          onClick={onIconClick}
          disabled={disabled}
        />
      )}
    </div>
  )
}
