/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useCallback, useRef } from 'react'

const usePrevious = (value: any, initialValue: any): any => {
  const ref = useRef(initialValue)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useEffectDebugger = (
  effectHook: any,
  dependencies: any[],
  dependencyNames = []
): any => {
  const previousDeps = usePrevious(dependencies, [])

  const changedDeps = dependencies.reduce(
    (accum: any, dependency: any, index: number) => {
      if (dependency !== previousDeps[index]) {
        const keyName = dependencyNames[index] || index
        return {
          ...accum,
          [keyName]: {
            before: previousDeps[index],
            after: dependency,
          },
        }
      }

      return accum
    },
    {}
  )

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps)
  }

  useEffect(effectHook, dependencies)
}

export const useCallbackDebugger = (
  effectHook: any,
  dependencies: any[],
  dependencyNames = []
): any => {
  const previousDeps = usePrevious(dependencies, [])

  const changedDeps = dependencies.reduce(
    (accum: any, dependency: any, index: number) => {
      if (dependency !== previousDeps[index]) {
        const keyName = dependencyNames[index] || index
        return {
          ...accum,
          [keyName]: {
            before: previousDeps[index],
            after: dependency,
          },
        }
      }

      return accum
    },
    {}
  )

  if (Object.keys(changedDeps).length) {
    console.log('[use-callback-debugger] ', changedDeps)
  }

  return useCallback(effectHook, dependencies)
}
