import i18next from 'i18next'
import React, { CSSProperties } from 'react'
import Select, {
  ControlProps,
  FormatOptionLabelMeta,
  OptionProps,
  components,
  DropdownIndicatorProps,
  GroupBase,
} from 'react-select'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../Icon'

interface SelectProps {
  options: SelectOption[]
  onChange: (val: unknown) => void
  value: unknown
  isSearchable?: boolean
  error?: boolean
  placeholder?: string
  isMulti?: boolean
  className?: string
  menuListHeight?: number
  menuListWidth?: string | number
  borderless?: boolean
  showDropdownIndicator?: boolean
  menuPlacement?: 'bottom' | 'auto' | 'top'
  formatOptionLabel?:
    | ((
        data: unknown,
        formatOptionLabelMeta: FormatOptionLabelMeta<unknown>
      ) => React.ReactNode)
    | undefined
  disabled?: boolean
  height?: number | string
  minHeight?: number | string
}

export interface SelectOption {
  label: string
  value: string | number
}

const DropdownIndicator = (
  props: JSX.IntrinsicAttributes &
    DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
): JSX.Element => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon icon={IconChoices.CHEVRON_DOWN} />
    </components.DropdownIndicator>
  )
}

export const SelectDropdown = ({
  options,
  onChange,
  value,
  isSearchable,
  error,
  isMulti,
  menuPlacement = 'bottom',
  placeholder = i18next.t('common.actions.select'),
  menuListHeight = 300,
  menuListWidth = '100%',
  className,
  borderless = false,
  showDropdownIndicator = true,
  formatOptionLabel,
  disabled,
  height = 'fit-content',
  minHeight = 48,
}: SelectProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const customStyles = {
    option: (provided: CSSProperties, state: OptionProps<unknown>) => {
      const getBackgroundColor = (): string => {
        if (state.isSelected) return '#F2F2F2'
        if (state.isFocused) return '#F2F2F2'
        return 'white'
      }
      const backgroundColor = getBackgroundColor()
      return {
        ...provided,
        cursor: 'pointer',
        backgroundColor,
        color: 'black',
        outline: 'unset',
        ':active': {
          backgroundColor: '#F2F2F2',
        },
      }
    },
    singleValue: (provided: CSSProperties) => {
      return {
        ...provided,
        ...theme.textVariants.base,
        color: '#010101',
      }
    },
    multiValue: (provided: CSSProperties) => {
      return {
        ...provided,
        ...theme.textVariants.baseBold,
      }
    },
    multiValueLabel: (provided: CSSProperties) => {
      return {
        ...provided,
        ...theme.textVariants.baseBold,
      }
    },
    multiValueRemove: (provided: CSSProperties) => {
      return {
        ...provided,
        paddingLeft: 8,
        paddingRight: 8,
      }
    },
    menu: (provided: CSSProperties) => {
      return {
        ...provided,
        width: menuListWidth,
        overflow: 'hidden',
      }
    },
    menuPortal: (provided: CSSProperties) => {
      return {
        ...provided,
        zIndex: 20,
      }
    },
    menuList: (provided: CSSProperties) => {
      return {
        ...provided,
        maxHeight: menuListHeight,
        padding: 0,
      }
    },
    control: (provided: CSSProperties, state: ControlProps<unknown>) => {
      const getBorder = (): string => {
        if (error) return '1px solid #DD3322'
        if (state.isFocused) return '2px solid rgba(17, 102, 187, 0.5)'
        if (state.menuIsOpen) return '2px solid rgba(17, 102, 187, 0.5)'
        if (state.isDisabled) return '1px solid #CCCCCC'
        return '1px solid #D1D5DB'
      }
      const getBackgroundColor = (): string => {
        if (state.isDisabled) return '#FAFAFA'
        return 'white'
      }
      const getHoverBorder = (): string => {
        if (borderless) return 'none'
        if (state.isFocused) return '2px solid rgba(17, 102, 187, 0.5)'
        return '1px solid #B3B3B3'
      }
      const backgroundColor = getBackgroundColor()
      const border = getBorder()
      const hoverBorder = getHoverBorder()
      return {
        ...provided,
        ...(disabled ? { opacity: '50%' } : {}),
        cursor: 'pointer',
        border: borderless ? 'none' : border,
        ':hover': {
          border: hoverBorder,
        },
        boxShadow: 'none',
        minHeight,
        height: height || 'fit-content',
        backgroundColor,
        paddingLeft: 6,
        paddingRight: 8,
      }
    },
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: () => ({
      display: showDropdownIndicator ? 'inline-block' : 'none',
      marginRight: 7,
    }),
  }

  const selectedValue = isMulti
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options.filter((_option) => value.includes(_option.value))
    : options.find((_option) => _option.value === value) || ''

  const onChangeValue = (option: unknown): void => {
    if (isMulti) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange(option.map((_option) => _option.value))
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange(option.value)
    }
  }

  const getNoOptionsMessage = (): string => {
    return translate('select.noOptions')
  }

  return (
    <Select
      className={className ?? ''}
      options={options}
      onChange={onChangeValue}
      value={selectedValue}
      defaultValue={value}
      isSearchable={isSearchable}
      components={{ DropdownIndicator }}
      minMenuHeight={menuListHeight}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      styles={customStyles}
      menuPosition="fixed"
      placeholder={placeholder}
      isMulti={isMulti}
      menuPlacement={menuPlacement}
      noOptionsMessage={getNoOptionsMessage}
      formatOptionLabel={formatOptionLabel ?? undefined}
      isDisabled={disabled}
    />
  )
}
