export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const truncateTextWithDots = (str: string, length: number): string => {
  const sanitizedLength = Math.max(length, 4)
  if (str.length < sanitizedLength) {
    return str
  }
  return str.length < sanitizedLength
    ? str
    : `${str.substring(0, sanitizedLength - 3)}...`
}

export const replaceHtml = (str: string): string => {
  return str.replace(/&amp;/g, '&').replace(/&gt;/g, '>').replace(/&lt;/g, '<')
}

export const removeMarkdown = (str: string): string => {
  return str.replace(/\*\*([^ ]*)\*\*/g, '$1').replace(/_([^ ]*)_/g, '$1')
}

export const isEmptyString = (str: string): boolean => {
  return !/\S/.test(str)
}
