import React, { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { differenceInHours } from 'date-fns'
import { ChatMessageModel } from '../../../state/models/chat-message'
import { useStores } from '../../../hooks/useStores'
import { removeMarkdown, replaceHtml } from '../../../helpers/string'
import { MediaModel } from '../../../state/models/media'
import { Linkify } from '../../common/Linkify'
import { getTimestamp } from '../../../helpers/date'
import { theme } from '../../../theme/theme'
import { ChatMessageImages } from './ChatMessageImages'
import { UserModel } from '../../../state/models/user'
import { UserAvatar } from '../../common/Avatar'
import { TextTooltip } from '../../common/Tooltip'
import { ChatMessageReadBy } from './ChatMessageReadBy'

interface ChatMessageBaseProps {
  message: SnapshotOut<typeof ChatMessageModel>
  className?: string
}

interface ChatMessageInnerProps extends ChatMessageBaseProps {
  messageAuthorIsCurrentUser: boolean
  showTimestamp: boolean
  media?: SnapshotOut<typeof MediaModel>[]
}

export const ChatMessageInner = observer(
  ({
    message,
    media,
    messageAuthorIsCurrentUser,
    className,
  }: ChatMessageInnerProps): JSX.Element => {
    const processedMessage = useMemo(
      () => removeMarkdown(replaceHtml(message.message)),
      [message.message]
    )

    const emptyMessage = processedMessage === ('' || ' ')

    return (
      <>
        {!emptyMessage && (
          <div
            className={`inline-block  max-w-xs flex-col whitespace-pre-wrap
          break-words rounded-2xl py-3 px-[13px] md:max-w-xl
        ${
          messageAuthorIsCurrentUser
            ? 'self-end bg-chat-blue text-white'
            : 'bg-neutral-92'
        }
        ${className ?? ''}
      `}
          >
            <Linkify
              textClassName={`${
                messageAuthorIsCurrentUser ? 'text-white' : 'text-brandGreen'
              }`}
            >
              <span>{processedMessage}</span>
            </Linkify>
          </div>
        )}
        <ChatMessageImages
          media={media}
          messageAuthorIsCurrentUser={messageAuthorIsCurrentUser}
        />
      </>
    )
  }
)

interface ChatMessageProps extends ChatMessageBaseProps {
  previousMessage: SnapshotOut<typeof ChatMessageModel> | undefined
  nextMessage: SnapshotOut<typeof ChatMessageModel> | undefined
  firstMessageInChat?: boolean
  isGroupChat?: boolean
  previousMessageReads?: { [userId: string]: boolean }
  onReadByClick: (readBy: { userId: string; readTime: string }[]) => void
}

export const ChatMessage = observer(
  ({
    message,
    previousMessage,
    nextMessage,
    firstMessageInChat,
    isGroupChat,
    previousMessageReads,
    onReadByClick,
  }: ChatMessageProps): JSX.Element | null => {
    const [referenceElement, setReferenceElement] = useState<Element | null>(
      null
    )
    const { authenticationStore, mediaStore, userStore } = useStores()

    const author = userStore.users.get(message.authorId) as
      | SnapshotOut<typeof UserModel>
      | undefined

    const messageAuthorIsCurrentUser =
      message.authorId === authenticationStore.userId

    const previousMessageSentBySameUser = previousMessage
      ? previousMessage.authorId === message.authorId
      : false

    const nextMessageSentBySameUser = nextMessage
      ? nextMessage.authorId === message.authorId
      : false

    const previousMessageSentWithinSameHour = previousMessage
      ? Math.abs(
          differenceInHours(
            Date.parse(previousMessage.createdAt),
            Date.parse(message.createdAt)
          )
        ) > 0
      : false

    const nextMessageSentWithinSameHour = nextMessage
      ? Math.abs(
          differenceInHours(
            Date.parse(nextMessage.createdAt),
            Date.parse(message.createdAt)
          )
        ) > 0
      : false

    const media = (
      message?.mediaIds && message.mediaIds.length > 0
        ? message.mediaIds
            .map((_id) => mediaStore.media.get(_id))
            .filter((_media) => _media !== undefined)
        : undefined
    ) as SnapshotOut<typeof MediaModel>[] | undefined

    const timestamp = useMemo(
      () => getTimestamp(new Date(message.createdAt)),
      [message.createdAt]
    )

    const displayAvatar =
      !nextMessageSentBySameUser && !messageAuthorIsCurrentUser

    const displayName =
      isGroupChat &&
      !messageAuthorIsCurrentUser &&
      !previousMessageSentBySameUser

    const showTimestamp = (): boolean => {
      if (nextMessage === undefined) {
        return true
      }
      return nextMessageSentWithinSameHour
    }

    return (
      <div className="flex w-full flex-col">
        {showTimestamp() && (
          <p
            style={theme.textVariants.captionBold}
            className={`flex
            h-[46px] min-w-full items-center justify-center text-neutral-50
            ${firstMessageInChat ? 'mb-5' : 'my-5'}`}
          >
            {timestamp}
          </p>
        )}
        <div
          className={`flex flex-row 
          ${
            !nextMessageSentBySameUser && !firstMessageInChat
              ? 'mt-6'
              : 'mt-[3px]'
          }
          ${messageAuthorIsCurrentUser ? 'self-end' : ''}
          `}
        >
          {displayAvatar && (
            <TextTooltip
              title={author?.fullName}
              className="mb-1 mr-2 -ml-8 self-end"
              referenceElement={referenceElement}
              useFlexibleWidth
            >
              <div ref={setReferenceElement}>
                <UserAvatar user={author} size={24} />
              </div>
            </TextTooltip>
          )}
          <div className="flex flex-col">
            {displayName && (
              <p
                style={theme.textVariants.fineprint}
                className="ml-[10px] text-neutral-30"
              >
                {author?.name || ''}
              </p>
            )}
            <ChatMessageInner
              message={message}
              media={media}
              messageAuthorIsCurrentUser={messageAuthorIsCurrentUser}
              showTimestamp={previousMessageSentWithinSameHour}
            />
          </div>
        </div>
        <ChatMessageReadBy
          message={message}
          previousMessageReads={previousMessageReads}
          onClick={onReadByClick}
        />
      </div>
    )
  }
)
