import * as yup from 'yup'

export const societyWebsiteContactSchema = yup
  .object({
    show: yup.boolean().required(),
    recipientId: yup.string(),
    recipientsCC: yup.array().of(
      yup.object({
        userEmail: yup.string().email().required(),
      })
    ),
  })
  .required()
