/* eslint-disable no-param-reassign */
import { types, flow } from 'mobx-state-tree'
import { captureException } from '@sentry/react'
import { NMedia } from '../../interfaces/services/media.interfaces'
import { IMediaModel } from '../../interfaces/models/media.interfaces'
import {
  getMedia as apiGetMedia,
  createMedia as apiCreateMedia,
  deleteMedia as apiDeleteMedia,
} from '../../api/media'
import { MediaModel } from '../models/media'
import { stateType } from '../types/common'
import { setObject } from './helpers'

export const MediaStore = types
  .model('MediaStore')
  .props({
    media: types.map(MediaModel),
    creatingMedia: stateType,
    deletingMedia: stateType,
  })
  .actions((self) => ({
    reset: () => {
      self.media.clear()
      self.creatingMedia = 'none'
      self.deletingMedia = 'none'
    },
    setMedia: (media: IMediaModel[]) => {
      media.forEach((_media) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setObject<typeof MediaModel>(self.media, MediaModel, _media)
      })
    },
  }))
  .actions((self) => ({
    getMedia: flow(function* getMedia(id: string) {
      try {
        const resp = yield apiGetMedia(id)
        const data = resp.data as NMedia.NGetById.IResponse
        const mediaData = data.data
        self.setMedia([mediaData])
      } catch (error) {
        captureException(error)
      }
    }),
    createMedia: flow(function* createMedia(
      mediaType: 'image' | 'video',
      base64: string
    ) {
      self.creatingMedia = 'pending'
      try {
        const resp = yield apiCreateMedia(mediaType, base64)
        const data = resp.data as NMedia.NCreate.IResponse
        const mediaData = data.data as IMediaModel
        self.setMedia([mediaData])
        self.creatingMedia = 'done'
        return mediaData._id
      } catch (error) {
        captureException(error)
        self.creatingMedia = 'error'
        return undefined
      }
    }),
    deleteMedia: flow(function* deleteMedia(id: string) {
      self.deletingMedia = 'pending'
      try {
        yield apiDeleteMedia(id)
        self.deletingMedia = 'done'
      } catch (error) {
        captureException(error)
        self.deletingMedia = 'error'
      }
    }),
  }))
