import { AxiosResponse } from 'axios'
import { createUrl } from './helpers'
import axiosAuth from './config'
import { NFacilities } from '../interfaces/services/facilities.interfaces'

export const FACILITIES_POPULATE = ['media']

export const getFacilitiesPerSociety = (
  societyId: string
): Promise<AxiosResponse<NFacilities.NGetPerSocieties.IResponse>> => {
  const body: NFacilities.NGetPerSocieties.IRequestBody = {
    societyIds: [societyId],
  }
  return axiosAuth.post<NFacilities.NGetPerSocieties.IResponse>(
    createUrl('/facilities/get-per-societies/', {
      populate: FACILITIES_POPULATE.join(';'),
    }),
    body
  )
}

export const createFacility = (
  body: NFacilities.NCreate.IRequestBody
): Promise<AxiosResponse<NFacilities.NCreate.IResponse>> => {
  return axiosAuth.post<NFacilities.NCreate.IResponse>(
    createUrl(`/facilities/facility`),
    body
  )
}

export const patchFacility = (
  id: string,
  body: NFacilities.NPatch.IRequestBody
): Promise<AxiosResponse<NFacilities.NPatch.IResponse>> => {
  return axiosAuth.patch<NFacilities.NPatch.IResponse>(
    createUrl(`/facilities/facility/${id}`),
    body
  )
}

export const getFacility = (
  id: string
): Promise<AxiosResponse<NFacilities.NGetById.IResponse>> => {
  return axiosAuth.get<NFacilities.NGetById.IResponse>(
    createUrl(`/facilities/facility/${id}`, {
      populate: FACILITIES_POPULATE.join(';'),
    })
  )
}

export const deleteFacility = (
  id: string
): Promise<AxiosResponse<NFacilities.NDel.IResponse>> => {
  return axiosAuth.delete<NFacilities.NDel.IResponse>(
    createUrl(`/facilities/facility/${id}`)
  )
}
