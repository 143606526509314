import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'
import { SocietyModel } from './society'

export const HouseRuleModel = types.model('HouseRuleModel', {
  _id: types.identifier,
  ...timestampTypes,
  rule: types.string,
  section: types.string,
  society: types.reference(SocietyModel),
  notificationSettings: types.maybe(types.string),
})
