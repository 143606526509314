import React from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { SnapshotOut } from 'mobx-state-tree'
import { theme } from '../../../../theme/theme'
import { ResourceModel } from '../../../../state/models/resource'
import { MediaImage } from '../../../common/Image'
import { formatRelative } from '../../../../helpers/date'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { reverseUrl } from '../../../../navigation/reverseUrl'

interface MarketplaceListItemProps {
  resource: SnapshotOut<typeof ResourceModel>
}

export const MarketplaceListItem = observer(
  ({ resource }: MarketplaceListItemProps): JSX.Element => {
    const { translate } = useAppTranslation()

    return (
      <Link
        to={reverseUrl('society:marketplace-detail', {
          id: resource.societyId,
          resourceId: resource._id,
        })}
        className="flex flex-row items-center rounded-lg border p-4 hover:bg-neutral-96"
      >
        <div className="relative flex flex-1">
          <MediaImage
            mediaId={resource.mediaId}
            className="h-[100px] w-[100px] md:h-[150px] md:w-[150px]"
            imageClassName="rounded"
          />
          <div className="ml-3 flex flex-1 flex-col justify-between md:ml-4">
            <div>
              <p className="mb-1.5" style={theme.textVariants.fineprint}>
                {formatRelative(Date.parse(resource.createdAt))}
              </p>
              <div className="flex justify-between">
                <p
                  className="break-all line-clamp-2"
                  style={theme.textVariants.baseBold}
                >
                  {resource.title}
                </p>
              </div>
              <p
                className="line-clamp-1 md:mt-1"
                style={{
                  ...theme.textVariants.base,
                  ...{ color: theme.colors.darkGrey },
                }}
              >
                {resource.description}
              </p>
            </div>
            <p style={theme.textVariants.baseBold} className="mt-1">
              {resource.isFree || resource.cost === '0'
                ? translate('marketplaceListView.item.free')
                : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //  @ts-ignore
                  resource.costString}
            </p>
          </div>
        </div>
      </Link>
    )
  }
)
