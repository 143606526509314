/* eslint-disable no-param-reassign */
import { captureException } from '@sentry/react'
import axios, { AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'
import { getBearerTokenFromCookies } from '../helpers/authentication'

export const retrieveUserSession = (): string | null | undefined => {
  try {
    return getBearerTokenFromCookies()
  } catch (error) {
    captureException(error)
  }
  return null
}

const getAxiosApiInstance = (): AxiosInstance => {
  const axiosInstance = axios.create()

  // Don't include token on routes that don't accept invalid tokens.
  axiosInstance.interceptors.request.use(async (config) => {
    if (!config.url?.includes('/users/login')) {
      // Temporary solution to be able to make requests
      const token = retrieveUserSession()
      if (config?.headers) {
        config.headers.Authorization =
          token != null && token.length > 0 ? `Bearer ${token}` : ''
      }
    }

    return config
  })

  // Retry on network errors and 5XX responses
  axiosRetry(axiosInstance, { retries: 2 })

  return axiosInstance
}

const axiosAuth = getAxiosApiInstance()

export default axiosAuth
