import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../../hooks/useStores'
import { IconChoices } from '../../../common/Icon'
import { PreApprovedModel } from '../../../../state/models/society'
import { ConfirmationModal } from '../../../common/ConfirmationModal'
import { useModal } from '../../../../hooks/useModal'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { theme } from '../../../../theme/theme'
import { Button, ButtonVariant } from '../../../common/Button'

interface PreApprovedListItemProps {
  preApproved: SnapshotOut<typeof PreApprovedModel>
}

export const PreApprovedListItem = observer(
  ({ preApproved }: PreApprovedListItemProps): JSX.Element => {
    const { societyStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()
    const {
      show: showSendReminderConfirmationModal,
      open: openSendReminderConfirmationModal,
      close: closeSendReminderConfirmationModal,
    } = useModal()

    const showSuccessMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('manageHousehold.flashMessage.userReminderSuccess')
      )
    }

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('manageHousehold.flashMessage.userReminderFailure')
      )
    }

    const sendReminder = async (id: string): Promise<void> => {
      if (preApproved.societyId) {
        const status = await societyStore.sendPreApprovedUserInviteReminder(
          preApproved.societyId,
          id
        )
        if (status) {
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      } else {
        showErrorMessage()
      }
    }

    const buttonIcon =
      preApproved.reminderStatus === 'notSent'
        ? IconChoices.ENVELOPE
        : IconChoices.CHECKMARK

    return (
      <>
        <div className="flex items-center justify-between">
          <p style={theme.textVariants.base}>{preApproved.userEmail}</p>
          <Button
            onClick={openSendReminderConfirmationModal}
            disabled={
              preApproved.reminderStatus !== 'notSent' ||
              societyStore.invitingAllPreApprovedUsers === 'pending'
            }
            icon={buttonIcon}
            loading={preApproved.reminderStatus === 'sending'}
            variant={ButtonVariant.PRIMARY}
          />
        </div>
        <ConfirmationModal
          title={translate('manageHousehold.reminder.confirmationModal.title')}
          description={translate(
            'manageHousehold.reminder.confirmationModal.description',
            { email: preApproved.userEmail }
          )}
          show={showSendReminderConfirmationModal}
          close={closeSendReminderConfirmationModal}
          onConfirm={() => sendReminder(preApproved._id)}
          confirmationButtonLabel={translate('common.actions.send')}
        />
      </>
    )
  }
)
