import { useAtom } from 'jotai'
import React from 'react'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { theme } from '../../../theme/theme'
import { BackButton } from '../../common/BackButton'
import { ChatHeaderState, chatHeaderStateAtom } from '../atom'

export const JoinGroupChatHeader = (): JSX.Element => {
  const { translate } = useAppTranslation()
  const { chatRoomStore } = useStores()
  const [, setChatHeaderState] = useAtom(chatHeaderStateAtom)
  const selectedChatRoomId = chatRoomStore.selectedChatRoom

  const onBackClick = (): void => {
    setChatHeaderState(ChatHeaderState.None)
    chatRoomStore.setSelectedChatRoom(undefined)
  }

  return (
    <>
      {selectedChatRoomId && (
        <div className="flex items-center gap-4 py-5 px-6">
          <BackButton onClick={onBackClick} />
          <p style={theme.textVariants.h3}>{translate('joinGroupChat.join')}</p>
        </div>
      )}
    </>
  )
}
