import React, { useRef } from 'react'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { Modal } from '../../common/Modal'
import { ModalBody } from '../../common/Modal/ModalBody'
import { ModalHeader } from '../../common/Modal/ModalHeader'
import { FindArchiveChatsModalContent } from '../FindArchivedChatsModalContent'

interface FindArchiveChatsModalProps {
  show: boolean
  close: () => void
  onItemSelect: () => void
}

export const FindArchiveChatsModal = ({
  show,
  close,
  onItemSelect,
}: FindArchiveChatsModalProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const ref = useRef(null)
  return (
    <Modal ref={ref} show={show}>
      {{
        header: (
          <ModalHeader onClose={close}>
            {translate('findArchivedChats.header')}
          </ModalHeader>
        ),
        body: (
          <ModalBody className="max-h-96 overflow-y-auto">
            <FindArchiveChatsModalContent modalRef={ref} close={onItemSelect} />
          </ModalBody>
        ),
      }}
    </Modal>
  )
}
