import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { SocietyContactModel } from '../../../state/models/society-contact'
import { ContactCard } from '../ContactCard'

interface ContactListProps {
  contacts: SnapshotOut<typeof SocietyContactModel>[]
  management: boolean
}

export const ContactList = observer(
  ({ contacts, management }: ContactListProps): JSX.Element => {
    return (
      <div className="flex flex-row flex-wrap gap-8 pb-4">
        {contacts.map((contact) => (
          <ContactCard
            key={contact._id}
            contact={contact}
            management={management}
          />
        ))}
      </div>
    )
  }
)
