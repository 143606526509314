import React from 'react'
import { observer } from 'mobx-react-lite'
import { Modal } from '../../../../../components/common/Modal'
import { ModalHeader } from '../../../../../components/common/Modal/ModalHeader'
import { ModalBody } from '../../../../../components/common/Modal/ModalBody'
import { SetBasicModalContent } from './SetBasicModalContent'

interface SetBasicModalProps {
  show: boolean
  close: () => void
  societyId?: string
}

export const SetBasicModal = observer(
  ({ show, close, societyId }: SetBasicModalProps): JSX.Element => {
    return (
      <Modal show={show}>
        {{
          header: (
            <ModalHeader onClose={close}>Nedgradera till basic</ModalHeader>
          ),
          body: (
            <ModalBody className="overflow-y-auto">
              <SetBasicModalContent societyId={societyId} close={close} />
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
