export const openPhoneNumber = (phoneNumber: string | undefined): void => {
  if (phoneNumber) {
    window.open(`tel:${phoneNumber}`)
  }
}

export const sanitizeMobileNumber = (
  number: string | undefined | null
): string => {
  if (!number) {
    return ''
  }

  const onlyNumbersNumber = number.replace(/[^0-9.]/g, '')

  try {
    return `${onlyNumbersNumber.slice(-9)}`
  } catch (error) {
    return onlyNumbersNumber
  }
}
