/* eslint-disable max-len */
import React from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { LoginView } from '../../views/authentication/login'
import { ChatView } from '../../views/chat'
import { FeedView } from '../../views/feed'
import { Root } from '../../views/root'
import { SettingsView } from '../../views/settings'
import { SocietyView } from '../../views/society/SocietyView'
import { UserView } from '../../views/user'
import { AuthenticatedRoute } from './AuthenticatedRoute'
import { NotFoundView } from '../../views/error/NotFound'
import { useSocietyRoutes } from '../society/hooks/useSocietyRoutes'
import { SignUpView } from '../../views/authentication/signup'
import { HamburgerContextProvider } from '../../hooks/useHamburgerContext'
import { RouteErrorView } from '../../views/error/RouteErrorView'
import { FindSocietyView } from '../../views/society/FindSocietyView'
import { FindSocietyViewConfirmActivationView } from '../../views/society/FindSocietyConfirmActivationView'
import { CreateSocietyView } from '../../views/society/CreateSocietyView'
import { ActivatedSocietyConfirmationView } from '../../views/society/ActivatedSocietyConfirmationView'
import { CreateSocietyTypeSelectionView } from '../../views/society/CreateSocietyTypeSelectionView'
import { SocietyInviteBase } from '../../components/society/SocietyInvite'
import { ResetPasswordView } from '../../views/authentication/resetPassword'
import { PostView } from '../../views/post'
import { VerifyEmailView } from '../../views/authentication/verifyEmail'
import { RetrievePasswordView } from '../../views/authentication/retrievePassword'
import { EmailVerificationView } from '../../views/authentication/emailVerification'
import { LoginRoute } from './LoginRoute'
import { SuperAdminView } from '../../views/admin/SuperAdminView'
import { SuperAdminRoute } from './SuperAdminRoute'

const AppRoutes = (): JSX.Element | null => {
  const societyRoutes = useSocietyRoutes()
  const router = createBrowserRouter([
    {
      path: '/login',
      element: (
        <LoginRoute>
          <LoginView />
        </LoginRoute>
      ),
      errorElement: <RouteErrorView />,
    },
    {
      path: '/signup',
      element: <SignUpView />,
      errorElement: <RouteErrorView />,
    },
    {
      path: '/reset-password',
      element: <ResetPasswordView />,
      errorElement: <RouteErrorView />,
    },
    {
      path: '/retrieve-password',
      element: <RetrievePasswordView />,
      errorElement: <RouteErrorView />,
    },
    {
      path: '/verify-email',
      element: <VerifyEmailView />,
      errorElement: <RouteErrorView />,
    },
    {
      path: '/email-verification',
      element: <EmailVerificationView />,
      errorElement: <RouteErrorView />,
    },
    {
      path: '/',
      element: <Navigate to="/feed" replace />,
    },
    {
      element: (
        <AuthenticatedRoute>
          <HamburgerContextProvider>
            <SocietyInviteBase>
              <Root />
            </SocietyInviteBase>
          </HamburgerContextProvider>
        </AuthenticatedRoute>
      ),
      errorElement: <RouteErrorView />,
      children: [
        {
          path: 'feed',
          element: <FeedView />,
          errorElement: <RouteErrorView />,
        },
        {
          path: 'admin/*',
          element: (
            <SuperAdminRoute>
              <SuperAdminView />
            </SuperAdminRoute>
          ),
          errorElement: <RouteErrorView />,
        },
        {
          path: 'find-society',
          element: <FindSocietyView />,
          errorElement: <RouteErrorView />,
        },
        {
          path: 'create-society',
          element: <CreateSocietyView />,
          errorElement: <RouteErrorView />,
        },
        {
          path: 'post/:id',
          element: <PostView />,
          errorElement: <RouteErrorView />,
        },
        {
          path: 'create-society/pick-type',
          element: <CreateSocietyTypeSelectionView />,
          errorElement: <RouteErrorView />,
        },
        {
          path: 'activated-society',
          element: <ActivatedSocietyConfirmationView />,
          errorElement: <RouteErrorView />,
        },
        {
          path: 'find-society/confirm-activation/:id',
          element: <FindSocietyViewConfirmActivationView />,
          errorElement: <RouteErrorView />,
        },
        {
          path: 'society',
          element: <SocietyView />,
          children: societyRoutes,
          errorElement: <RouteErrorView />,
        },
        {
          path: 'user/:id/society/:societyId',
          element: <UserView />,
          errorElement: <RouteErrorView />,
        },
        {
          path: 'chat',
          element: <ChatView />,
          children: [
            {
              path: ':id',
              element: <ChatView />,
            },
          ],
          errorElement: <RouteErrorView />,
        },
        {
          path: 'settings/*',
          element: <SettingsView />,
          errorElement: <RouteErrorView />,
        },
      ],
    },
    {
      path: 'not-found',
      element: (
        <AuthenticatedRoute>
          <NotFoundView />
        </AuthenticatedRoute>
      ),
      errorElement: <RouteErrorView />,
    },
    {
      path: '*',
      element: <Navigate to="/not-found" replace />,
    },
  ])

  return <RouterProvider router={router} />
}

AppRoutes.displayName = 'AppRoutes'
export { AppRoutes }
