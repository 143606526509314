import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../hooks/useStores'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { Accordion } from '../../common/Accordion'
import { AccordionItem } from '../../common/AccordionItem'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { HouseRuleContent } from './HouseRuleContent'

export const HouseRules = observer((): JSX.Element => {
  const { houseRulesStore } = useStores()
  const { translate } = useAppTranslation()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }

  const rules = houseRulesStore.sortedHouseRulesForSociety(society._id)

  return (
    <div className="w-full">
      {rules.length > 0 ? (
        <Accordion hasBorder className="w-full md:max-w-screen-sm">
          {rules.map((item, i) => (
            <AccordionItem
              className={`${i !== 0 ? 'border-t' : ''} p-5`}
              key={item._id}
              index={i}
              hasBorder
            >
              {{
                header: <div>{item.section}</div>,
                content: <HouseRuleContent houseRule={item} />,
              }}
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        <div>{translate('houseRulesView.noHouseRulesText')}</div>
      )}
    </div>
  )
})
