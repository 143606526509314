import { types } from 'mobx-state-tree'

export const ResidentRoleType = types.enumeration('ResidentRoleType', [
  '',
  'member',
  'resident',
  'tenant',
  'relative',
  'propertymanager',
  'customer-rep',
  'other',
])
