import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import React from 'react'
import { SocietyModel } from '../../../../../state/models/society'
import { SpinnerWrapper } from '../../../../common/SpinnerWrapper'
import { CanNotDeleteAccount } from '../CanNotDeleteAccount'
import { DeleteAccountStage } from '../DeleteAccountModal'
import { FirstStep } from './FirstStep'
import { LastStep } from './LastStep'
import { SecondStep } from './SecondStep'

interface DeleteAccountModalContentProps {
  stage: DeleteAccountStage
  loading: boolean
  societiesPreventingAccountDeletion: SnapshotOut<typeof SocietyModel>[]
  onChangePassword: (confirmedPassword: string) => void
  password: string
}

export const DeleteAccountModalContent = observer(
  ({
    stage,
    loading,
    societiesPreventingAccountDeletion,
    onChangePassword,
    password,
  }: DeleteAccountModalContentProps): JSX.Element => {
    if (societiesPreventingAccountDeletion.length > 0) {
      return (
        <CanNotDeleteAccount
          societiesPreventingAccountDeletion={
            societiesPreventingAccountDeletion
          }
        />
      )
    }

    if (loading) {
      return <SpinnerWrapper />
    }

    switch (stage) {
      case DeleteAccountStage.SECOND:
        return <SecondStep />
      case DeleteAccountStage.LAST:
        return (
          <LastStep onChangePassword={onChangePassword} password={password} />
        )
      default:
        return <FirstStep />
    }
  }
)
