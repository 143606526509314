import { observer } from 'mobx-react-lite'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import type { TFunction } from 'i18next'
import { LocalStorageKeys } from '../types/local-storage'
import { useStores } from './useStores'
import { useAuth } from './useAuth'

interface AppTranslationProviderProps {
  children: React.ReactNode
  isStorybook?: boolean
}

interface IAppTranslationContext {
  translate: TFunction<'translation', undefined>
}

const AppTranslationContext = createContext<IAppTranslationContext>({
  // eslint-disable-next-line
  // @ts-expect-error do not expect any type here.
  translate: () => null,
})

const AppTranslationProvider = observer(
  ({
    children,
    isStorybook = false,
  }: AppTranslationProviderProps): JSX.Element => {
    const { userId } = useAuth()
    const { t, i18n } = useTranslation()
    const { userStore } = useStores()
    const value = useMemo(() => ({ translate: t }), [t])

    // Return early if used with storybook to avoid store operations
    if (isStorybook) {
      return (
        <AppTranslationContext.Provider value={value}>
          {children}
        </AppTranslationContext.Provider>
      )
    }

    const getLanguage = useCallback((): string => {
      const localStorageValue = localStorage.getItem(LocalStorageKeys.LOCALE)
      const user = userId ? userStore.users.get(userId) : undefined
      if (user && user.language) {
        return user.language
      }
      if (localStorageValue) {
        return localStorageValue
      }

      return 'sv'
    }, [userId, userStore.users])

    useEffect(() => {
      i18n.changeLanguage(getLanguage())
      document.documentElement.lang = i18n.language.includes('sv') ? 'sv' : 'en'
    }, [userId, i18n, getLanguage])

    return (
      <AppTranslationContext.Provider value={value}>
        {children}
      </AppTranslationContext.Provider>
    )
  }
)

AppTranslationProvider.displayName = 'AppTranslationProvider'
export default AppTranslationProvider

export const useAppTranslation = (): IAppTranslationContext =>
  useContext(AppTranslationContext)
