import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'

interface LoginRouteProps {
  children: React.ReactNode
}

const LoginRoute = ({ children }: LoginRouteProps): JSX.Element | null => {
  const { userId, userIsVerified } = useAuth()

  if (userId && userIsVerified) {
    return <Navigate to="/feed" replace />
  }
  if (userId && userIsVerified === false) {
    return <Navigate to="/verify-email" replace />
  }
  return <>{children}</>
}

LoginRoute.displayName = 'LoginRoute'
export { LoginRoute }
