import React, { useCallback } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { captureException } from '@sentry/react'
import { addHours } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { Controller, useForm, UseFormSetValue } from 'react-hook-form'
import { InferType } from 'yup'
import { societyEventCreateSchema } from '../../../../forms/schemas/society_event_create'
import { nearestHourDate, parsedOrNewDate } from '../../../../helpers/date'
import { NOTIFICATION_SETTINGS_VALUE } from '../../../../helpers/notification_settings_options'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { useStores } from '../../../../hooks/useStores'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { NEvents } from '../../../../interfaces/services/events.interfaces'
import { Button, ButtonVariant } from '../../../common/Button'
import { DateTimeInput } from '../../../common/DateTimeInput'
import { FormControl } from '../../../common/FormControl'
import { TextArea } from '../../../common/TextArea'
import { TextInput } from '../../../common/TextInput'
import { ToastType } from '../../../common/Toast/toast-type'
import { Toggle } from '../../../common/Toggle'
import { societyCreatePostSchema } from '../../../../forms/schemas/society_create_post'

interface EventPlannerProps {
  eventId?: string
  setPostValue: UseFormSetValue<InferType<typeof societyCreatePostSchema>>
  setDisplayEvent: React.Dispatch<React.SetStateAction<boolean>>
  watchSociety: string
  watchEventsIds: (string | undefined)[] | undefined
}

export const EventPlanner = observer(
  ({
    eventId,
    setPostValue,
    setDisplayEvent,
    watchSociety,
    watchEventsIds,
  }: EventPlannerProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { eventStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const { getErrorMessage } = useFormErrorMessage()
    const userId = useAuthenticatedUserId() as string

    const event = eventId ? eventStore.events.get(eventId) : undefined

    const showErrorMessage = (): void => {
      const message = event
        ? 'createUpdateEvent.flashMessage.updateEventFailure'
        : 'createUpdateEvent.flashMessage.createEventFailure'
      setToastNotification(ToastType.DANGER, translate(message))
    }

    const getDefaultValues = useCallback((): InferType<
      typeof societyEventCreateSchema
    > => {
      return {
        title: event?.title || '',
        location: event?.location || '',
        description: event?.description || '',
        isAllDay: event?.isAllDay || false,
        startDate: event
          ? parsedOrNewDate(event?.startDate)
          : addHours(nearestHourDate(), 2),
        endDate: event
          ? parsedOrNewDate(event?.endDate)
          : addHours(nearestHourDate(), 3),
        notificationSettings: NOTIFICATION_SETTINGS_VALUE.NONE,
        societyId: event?.societyId || watchSociety,
        userId: event?.userId || userId,
      }
    }, [event, userId, watchSociety])

    const {
      control,
      handleSubmit,
      watch,
      setValue,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyEventCreateSchema),
      defaultValues: getDefaultValues(),
    })

    const watchIsAllDay = watch('isAllDay')
    const watchStartDate = watch('startDate')
    const watchEndDate = watch('endDate')

    const onSubmit = async (
      data: InferType<typeof societyEventCreateSchema>
    ): Promise<void> => {
      const _event = event
        ? await eventStore.patchEvent(
            event._id,
            data as NEvents.NPatch.IRequestBody
          )
        : await eventStore.createEvent(data as NEvents.NCreate.IRequestBody)

      if (_event) {
        if (!watchEventsIds?.includes(_event._id)) {
          setPostValue('eventsIds', [...(watchEventsIds || []), _event._id], {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
        setDisplayEvent(false)
      } else {
        showErrorMessage()
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      showErrorMessage()
    }

    const onClose = (): void => {
      setDisplayEvent(false)
    }

    const loading =
      eventStore.creatingEvent === 'pending' ||
      eventStore.updatingEvent === 'pending'
    return (
      <div className="flex flex-col gap-4 rounded border border-neutral-80 p-6">
        <div className="flex gap-8">
          <Controller
            control={control}
            name="title"
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                className="flex flex-1"
                label={translate('createUpdateEvent.form.labels.title')}
                error={errors.title && getErrorMessage(errors.title)}
                name={name}
              >
                <TextInput
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={!!errors.title}
                />
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="location"
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                className="flex flex-1"
                label={translate('createUpdateEvent.form.labels.location')}
                error={errors.location && getErrorMessage(errors.location)}
                name={name}
              >
                <TextInput
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={!!errors.location}
                />
              </FormControl>
            )}
          />
        </div>
        <Controller
          control={control}
          name="description"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateEvent.form.labels.description')}
              error={errors.description && getErrorMessage(errors.description)}
              name={name}
            >
              <TextArea
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={!!errors.description}
              />
            </FormControl>
          )}
        />
        <div className="flex gap-8">
          <Controller
            control={control}
            name="startDate"
            render={({ field: { onChange, value, name } }) => (
              <FormControl
                className="flex flex-1"
                label={
                  watchIsAllDay
                    ? translate('createUpdateEvent.form.labels.startDay')
                    : translate('createUpdateEvent.form.labels.startDate')
                }
                error={
                  errors.description && getErrorMessage(errors.description)
                }
                name={name}
              >
                <DateTimeInput
                  onChange={(date) => {
                    onChange(date)
                    // startDate should always be before endDate
                    if (watchEndDate && date >= watchEndDate) {
                      setValue('endDate', addHours(date, 1))
                    }
                  }}
                  minDate={new Date()}
                  value={value}
                  showTimeSelect={!watchIsAllDay}
                />
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="endDate"
            render={({ field: { onChange, value, name } }) => (
              <FormControl
                className="flex flex-1"
                label={
                  watchIsAllDay
                    ? translate('createUpdateEvent.form.labels.endDay')
                    : translate('createUpdateEvent.form.labels.endDate')
                }
                error={
                  errors.description && getErrorMessage(errors.description)
                }
                name={name}
              >
                <DateTimeInput
                  onChange={(date) => {
                    onChange(date)
                    // endDate should always be after startDate
                    if (watchStartDate && date <= watchStartDate) {
                      setValue('startDate', addHours(date, -1))
                    }
                  }}
                  minDate={new Date()}
                  value={value}
                  showTimeSelect={!watchIsAllDay}
                />
              </FormControl>
            )}
          />
        </div>
        <Controller
          control={control}
          name="isAllDay"
          render={({ field: { onChange, value, name } }) => (
            <Toggle
              wrapperClassName="w-fit mt-1"
              label={translate('createUpdateEvent.form.labels.isAllDay')}
              name={name}
              enabled={value || false}
              onChange={onChange}
            />
          )}
        />
        <div className="mt-8 flex flex-shrink-0 flex-wrap items-center justify-end gap-3">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
            variant={ButtonVariant.TEXT}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid || loading}
            label={translate('common.actions.save')}
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </div>
    )
  }
)
