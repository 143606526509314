import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { Button, ButtonVariant } from '../../common/Button'
import { SocietyEntranceModel } from '../../../state/models/society-entrance'
import { useModal } from '../../../hooks/useModal'
import { ConfirmationModal } from '../../common/ConfirmationModal'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { theme } from '../../../theme/theme'
import { CreateUpdateRegisterEntranceItemModal } from './CreateUpdateRegisterEntranceItemModal'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'
import { Alert } from '../../common/Alert'
import { AlertType } from '../../common/Alert/alert-type'

interface RegisterEntranceItemProps {
  entrance: SnapshotOut<typeof SocietyEntranceModel>
}

export const RegisterEntranceItem = observer(
  ({ entrance }: RegisterEntranceItemProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { unitStore, societyEntrancesStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const {
      show: showConfirmationModal,
      open: openConfirmationModal,
      close: closeConfirmationModal,
    } = useModal()
    const {
      show: showEditModal,
      open: openEditModal,
      close: closeEditModal,
    } = useModal()

    const entranceUnits = unitStore.unitsForEntrance(entrance)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isSamf } = society

    const showSuccessMessage = (): void => {
      const translationKey = isSamf
        ? 'registerEntrance.flashMessage.deleteEntranceSamfSuccess'
        : 'registerEntrance.flashMessage.deleteEntranceSuccess'
      setToastNotification(ToastType.SUCCESS, translate(translationKey))
    }

    const showErrorMessage = (): void => {
      const translationKey = isSamf
        ? 'registerEntrance.flashMessage.deleteEntranceSamfFailure'
        : 'registerEntrance.flashMessage.deleteEntranceFailure'
      setToastNotification(ToastType.DANGER, translate(translationKey))
    }

    const deleteEntrance = async (): Promise<void> => {
      await unitStore.getUnitsPerSociety(society._id)
      const status = await societyEntrancesStore.deleteEntrance(entrance._id)
      if (status) {
        showSuccessMessage()
      } else {
        showErrorMessage()
      }
    }

    const entranceUnitsWithoutUnassigned = entranceUnits.filter(
      (unit) => !unit.isUnassigned
    )

    const getUnitText = (): string => {
      if (entranceUnitsWithoutUnassigned.length === 1) {
        return translate(
          isSamf
            ? 'common.singularPlural.unit.singularSamf'
            : 'common.singularPlural.unit.singular'
        )
      }
      return translate(
        isSamf
          ? 'common.singularPlural.unit.pluralSamf'
          : 'common.singularPlural.unit.plural'
      )
    }

    const getConnectText = (): string => {
      if (entranceUnitsWithoutUnassigned.length === 1) {
        return translate('common.singularPlural.connected.singular')
      }
      return translate('common.singularPlural.connected.plural')
    }

    return (
      <div>
        <p style={theme.textVariants.base}>
          {translate(
            isSamf
              ? 'registerEntrance.numberOfUnits.samf'
              : 'registerEntrance.numberOfUnits.brf',
            {
              number: entranceUnitsWithoutUnassigned.length,
              unitText: getUnitText().toLowerCase(),
              connectText: getConnectText(),
            }
          )}
        </p>
        <div
          className={`mt-4 space-x-3 ${
            entranceUnits.length === 0 ? 'mb-2' : ''
          }`}
        >
          <Button
            label={translate('common.actions.change')}
            onClick={openEditModal}
          />
          <Button
            label={translate('common.actions.delete')}
            variant={ButtonVariant.DANGER}
            disabled={entranceUnits.length > 0}
            onClick={openConfirmationModal}
          />
        </div>
        {entranceUnitsWithoutUnassigned.length > 0 && (
          <p style={theme.textVariants.caption} className="my-2 text-red">
            {translate(
              isSamf
                ? 'registerEntrance.warnings.unableDeleteWithUnitsSamf'
                : 'registerEntrance.warnings.unableDeleteWithUnits'
            )}
          </p>
        )}
        {entranceUnits.length === 1 &&
          entranceUnitsWithoutUnassigned.length === 0 && (
            <Alert
              className="my-3"
              text={translate(
                isSamf
                  ? 'registerEntrance.warnings.unableToDeleteWithUnassignedSamf'
                  : 'registerEntrance.warnings.unableToDeleteWithUnassigned'
              )}
              type={AlertType.WARNING}
            />
          )}
        <ConfirmationModal
          title={translate(
            isSamf
              ? 'registerEntrance.confirmationDialog.deleteSamfTitle'
              : 'registerEntrance.confirmationDialog.deleteTitle'
          )}
          description={translate(
            isSamf
              ? 'registerEntrance.confirmationDialog.deleteSamfDescription'
              : 'registerEntrance.confirmationDialog.deleteDescription'
          )}
          show={showConfirmationModal}
          close={closeConfirmationModal}
          onConfirm={deleteEntrance}
          deleteMode
        />
        <CreateUpdateRegisterEntranceItemModal
          show={showEditModal}
          close={closeEditModal}
          entrance={entrance}
        />
      </div>
    )
  }
)
