/* eslint-disable no-param-reassign */
import { types, flow, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import { getAll as apiGetAll } from '../../api/settings'
import { stateType } from '../types/common'
import { NSettings } from '../../interfaces/services/settings.interfaces'
import { DiscountCodeModel } from '../models/setting'

export const SettingStore = types
  .model('SettingStore')
  .props({
    discountCodes: types.map(DiscountCodeModel),
    fetchingSettings: stateType,
  })
  .views((self) => ({
    get listDiscountCodes(): SnapshotOut<typeof DiscountCodeModel>[] {
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        values(self.discountCodes) as SnapshotOut<typeof DiscountCodeModel>[]
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.discountCodes.clear()
      self.fetchingSettings = 'none'
    },
  }))
  .actions((self) => ({
    getAllSettings: flow(function* getResourcesPerSocietygetAllSettings() {
      self.fetchingSettings = 'pending'
      try {
        const resp = yield apiGetAll()
        const data = resp.data as NSettings.NGetAll.IResponse

        data.data.forEach((_setting) => {
          if (_setting.key === 'discount-codes') {
            // TODO: Updated interfaces started complaining here. Not sure if false-positive
            // but out of context for the PR I'm currently working on.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            _setting.val.forEach(
              (_val: { code: string; partnerNameId: string }) =>
                self.discountCodes.set(
                  _val.code,
                  DiscountCodeModel.create(_val)
                )
            )
          }
        })
        self.fetchingSettings = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingSettings = 'error'
      }
    }),
  }))
