import React from 'react'
import { Link } from 'react-router-dom'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../Icon'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { useHamburger } from '../../../hooks/useHamburgerContext'
import { useAppTranslation } from '../../../hooks/useAppTranslation'

export interface MenuitemTitleProps {
  title: string
  icon: IconChoices
  isSelected?: boolean
  showAlert?: boolean
  destructive?: boolean
  className?: string
  justifyCenter?: boolean
  newFeature?: boolean
}

const MenuItemTitle = ({
  isSelected,
  className,
  icon,
  title,
  showAlert,
  destructive,
  justifyCenter,
  newFeature,
}: MenuitemTitleProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const getIconColor = (): string => {
    if (destructive) {
      return 'red'
    }
    if (isSelected) {
      return 'white'
    }
    return 'black'
  }
  return (
    <div
      className={`flex h-12 items-center rounded ${
        justifyCenter ? 'justify-center' : 'justify-between'
      } px-4
      ${
        isSelected
          ? 'border border-brandGreen bg-brandGreen text-white'
          : 'hover:bg-neutral-96'
      } ${className ?? ''}`}
    >
      <div className="flex items-center gap-2">
        <Icon icon={icon} color={getIconColor()} />
        <span
          style={theme.textVariants.captionBold}
          className={`${destructive ? 'text-red' : ''}`}
        >
          {title}
        </span>
        {newFeature && (
          <span
            style={theme.textVariants.fineprintBold}
            className="text-brandGreen"
          >
            {translate('common.newFeature')}
          </span>
        )}
      </div>
      {showAlert && <Icon icon={IconChoices.EXCLAMATION_ALERT} />}
    </div>
  )
}

export interface MenuItem {
  title: string
  icon: IconChoices
  onSelect?: () => void
  isSelected?: boolean
  url?: string
  urlIsExternal?: boolean
  onClick?: () => void
  showAlert?: boolean
  className?: string
  destructive?: boolean
  trackingCallback?: () => void
  disabled?: boolean
  newFeature?: boolean
}

export const MenuItem = ({
  title,
  icon,
  url,
  urlIsExternal,
  onClick,
  showAlert,
  className,
  isSelected,
  destructive,
  trackingCallback,
  onSelect,
  disabled,
  newFeature,
}: MenuItem): JSX.Element | null => {
  const isMobile = useIsMobile()
  const { setDisplaySidebar } = useHamburger()

  const _onClick = (): void => {
    if (!disabled) {
      if (trackingCallback) trackingCallback()
      if (onSelect) onSelect()
      if (isMobile) setDisplaySidebar(false)
    }
  }

  if (onClick) {
    return (
      <button className="w-full" onClick={onClick} disabled={disabled}>
        <MenuItemTitle
          title={title}
          icon={icon}
          showAlert={showAlert}
          className={className}
          isSelected={isSelected}
          destructive={destructive}
          newFeature={newFeature}
          justifyCenter
        />
      </button>
    )
  }

  if (url) {
    return urlIsExternal ? (
      <a
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        className={`${disabled ? 'pointer-events-none opacity-50' : ''}`}
      >
        <MenuItemTitle
          title={title}
          icon={icon}
          showAlert={showAlert}
          className={className}
          isSelected={isSelected}
          destructive={destructive}
          newFeature={newFeature}
        />
      </a>
    ) : (
      <Link
        to={url}
        onClick={_onClick}
        className={`${disabled ? 'pointer-events-none opacity-50' : ''}`}
      >
        <MenuItemTitle
          title={title}
          icon={icon}
          showAlert={showAlert}
          className={className}
          isSelected={isSelected}
          destructive={destructive}
          newFeature={newFeature}
        />
      </Link>
    )
  }

  return null
}
