// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = (func: Function, wait?: number): (() => void) => {
  let timeout: NodeJS.Timeout

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function executedFunction(...args: any[]) {
    const later = (): void => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait || 300)
  }
}
