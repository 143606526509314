import React from 'react'
import { useRouteError } from 'react-router-dom'
import { captureException } from '@sentry/react'
import { FallbackErrorView } from '../../../components/common/ErrorBoundary'

export const RouteErrorView = (): JSX.Element => {
  const error = useRouteError()
  captureException(error)
  return <FallbackErrorView />
}
