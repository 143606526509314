/* eslint-disable @typescript-eslint/no-explicit-any */
import { captureException } from '@sentry/react'
import { useCallback } from 'react'
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'
import { useAppTranslation } from './useAppTranslation'

interface FormErrorMessageReturnType {
  getErrorMessage: (
    error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined
  ) => string
}

export function useFormErrorMessage(): FormErrorMessageReturnType {
  const { translate } = useAppTranslation()

  const getErrorMessage = useCallback(
    (
      error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined
    ): string => {
      if (!error) {
        return translate('form.errors.unknown')
      }
      if (typeof error === 'string') {
        return error
      }
      switch (error.type) {
        case 'required':
          return translate('form.errors.required')
        case 'email':
          return translate('form.errors.invalidEmail')
        case 'oneOf':
          return error.message
            ? error.message.toString()
            : translate('form.errors.unknown')
        case 'min':
          return error.message
            ? error.message.toString()
            : translate('form.errors.unknown')
        case 'manual':
          return error.message
            ? error.message.toString()
            : translate('form.errors.unknown')
        case 'matches':
          return error.message
            ? error.message.toString()
            : translate('form.errors.unknown')
        case 'typeError':
          return translate('form.errors.unknown')
        default:
          captureException(new Error(`unkown form error type`), {
            extra: error,
          })
          return translate('form.errors.unknown')
      }
    },
    [translate]
  )

  return { getErrorMessage }
}
