import { AxiosResponse } from 'axios'
import { NPolls } from '../interfaces/services/polls.interfaces'
import { createUrl } from './helpers'
import axiosAuth from './config'

export const vote = (
  id: string,
  optionId: string
): Promise<AxiosResponse<NPolls.NVote.IResponse>> => {
  const body: NPolls.NVote.IRequestBody = {
    optionId,
  }
  return axiosAuth.post<NPolls.NVote.IResponse>(
    createUrl(`/polls/poll/${id}/vote`),
    body as NPolls.NVote.IRequestBody
  )
}

export const unvote = (
  id: string
): Promise<AxiosResponse<NPolls.NUnVote.IResponse>> => {
  return axiosAuth.post<NPolls.NUnVote.IResponse>(
    createUrl(`/polls/poll/${id}/unvote`)
  )
}

export const create = (
  body: NPolls.NCreate.IRequestBody
): Promise<AxiosResponse<NPolls.NCreate.IResponse>> => {
  return axiosAuth.post<NPolls.NCreate.IResponse>(
    createUrl(`/polls/poll`),
    body
  )
}

export const update = (
  id: string,
  body: NPolls.NPatch.IRequestBody
): Promise<AxiosResponse<NPolls.NPatch.IResponse>> => {
  return axiosAuth.patch<NPolls.NPatch.IResponse>(
    createUrl(`/polls/poll/${id}`),
    body
  )
}
