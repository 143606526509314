/* eslint-disable no-param-reassign */
import { flow, types, getRoot } from 'mobx-state-tree'
import { captureException } from '@sentry/react'
import {
  activateSociety as apiActivateSociety,
  createSocietyFolders as apiCreateSocietyFolders,
} from '../../api/partners'
import { RootStore } from './root'
import { stateType } from '../types/common'
import { NPartners } from '../../interfaces/services/partners.interfaces'

export const PartnerStore = types
  .model('PartnerStore')
  .props({
    activatingSociety: stateType,
    creatingSocietyFolders: stateType,
  })
  .actions((self) => ({
    reset: () => {
      self.activatingSociety = 'none'
      self.creatingSocietyFolders = 'none'
    },
  }))
  .actions((self) => ({
    activateSociety: flow(function* activateSociety(
      societyId: string,
      partnerNameId: string
    ) {
      self.activatingSociety = 'pending'
      try {
        const { societyStore } = getRoot<RootStore>(self)

        const resp = yield apiActivateSociety({
          societyId,
          partnerNameId,
          setSocietyPremium: true,
        })
        const data = resp.data as NPartners.NActivateSociety.IResponse
        const society = data.data

        if (society) {
          societyStore.setSocieties([society])
        }

        self.activatingSociety = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.activatingSociety = 'error'
        return false
      }
    }),
    createSocietyFolders: flow(function* createSocietyFolders(
      societyId: string,
      partnerId: string
    ) {
      self.creatingSocietyFolders = 'pending'
      try {
        const { societyStore } = getRoot<RootStore>(self)

        const resp = yield apiCreateSocietyFolders(
          { section: 'board' },
          { societyId, partnerId }
        )
        const data = resp.data as NPartners.NActivateSociety.IResponse
        const society = data.data

        if (society) {
          societyStore.setSocieties([society])
        }

        self.creatingSocietyFolders = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingSocietyFolders = 'error'
        return false
      }
    }),
  }))
