/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, getRoot, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import { NSocieties } from '../../interfaces/services/societies.interfaces'
import {
  getSocietySold as apiGetSocietySold,
  getSocietyListings as apiGetSocietyListings,
} from '../../api/societies'
import { stateType } from '../types/common'
import { RootStore } from './root'
import { setObject } from './helpers'
import { sortByDate } from '../../helpers/sorting'
import { ListingModel, SoldModel } from '../models/listings'
import { IListing, ISold } from '../../interfaces/models/sbab.interfaces'

export const ListingStore = types
  .model('ListingStore')
  .props({
    listings: types.map(ListingModel),
    sold: types.map(SoldModel),
    fetchingListings: stateType,
    fetchingSold: stateType,
  })
  .views((self) => ({
    get sortedListings(): SnapshotOut<typeof ListingModel>[] {
      // @ts-ignore
      return (values(self.listings) as SnapshotOut<typeof ListingModel>[]).sort(
        (a, b) => sortByDate(new Date(a.published), new Date(b.published), true)
      )
    },
    get sortedSold(): SnapshotOut<typeof SoldModel>[] {
      // @ts-ignore
      return (values(self.sold) as SnapshotOut<typeof SoldModel>[]).sort(
        (a, b) => sortByDate(new Date(a.published), new Date(b.published), true)
      )
    },
  }))
  .views((self) => ({
    listingsForSociety(societyId: string): SnapshotOut<typeof ListingModel>[] {
      return self.sortedListings.filter(
        (_listing) => _listing.societyId === societyId
      )
    },
    soldForSociety(societyId: string): SnapshotOut<typeof SoldModel>[] {
      return self.sortedSold.filter((_sold) => _sold.societyId === societyId)
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.listings.clear()
      self.sold.clear()
      self.fetchingListings = 'none'
      self.fetchingSold = 'none'
    },
    setListings: (listings: IListing[], societyId: string) => {
      listings.forEach((listing) => {
        // @ts-ignore
        setObject<typeof ListingModel>(self.listings, ListingModel, {
          ...listing,
          _id: `${listing.booliId}`,
          societyId,
        })
      })
    },
    setSold: (sold: ISold[], societyId: string) => {
      sold.forEach((_sold) => {
        // @ts-ignore
        setObject<typeof SoldModel>(self.sold, SoldModel, {
          ..._sold,
          _id: `${_sold.booliId}`,
          societyId,
        })
      })
    },
  }))
  .actions((self) => ({
    getSocietyListings: flow(function* getSocietyListings(
      societyId: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Generator<any> {
      self.fetchingListings = 'pending'
      try {
        const { societyStore } = getRoot<RootStore>(self)
        const society = societyStore.societies.get(societyId)
        if (!society) {
          throw new Error(`Society not found for society with ${societyId}`)
        }
        if (!society?.organisationNumber) {
          throw new Error(`No organisation number for society ${societyId}`)
        }

        const resp = yield apiGetSocietyListings(society._id)
        // @ts-ignore
        const data = resp.data as NSocieties.NListings.IResponse
        const listings = data.data?.listings

        if (listings) {
          self.setListings(listings, societyId)
        }

        self.fetchingListings = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingListings = 'error'
      }
    }),
    getSocietySold: flow(function* getSocietySold(
      societyId: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Generator<any> {
      self.fetchingSold = 'pending'
      try {
        const { societyStore } = getRoot<RootStore>(self)
        const society = societyStore.societies.get(societyId)
        if (!society) {
          throw new Error(`Society not found for society with ${societyId}`)
        }
        if (!society?.organisationNumber) {
          throw new Error(`No organisation number for society ${societyId}`)
        }

        const resp = yield apiGetSocietySold(society._id)
        // @ts-ignore
        const data = resp.data as NSocieties.NSold.IResponse
        const sold = data.data?.sold

        if (sold) {
          self.setSold(sold, societyId)
        }

        self.fetchingSold = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingSold = 'error'
      }
    }),
  }))
