import React from 'react'
import { IAuditLogModel } from '../../../../interfaces/models/audit-logs.interfaces'
import { Card } from '../../../../components/common/Card'
import { formatDate } from '../../../../helpers/date'
import { Divider } from '../../../../components/common/Divider'
import { theme } from '../../../../theme/theme'

interface AuditLogProps {
  auditLog: IAuditLogModel
}

export const AuditLog = ({ auditLog }: AuditLogProps): JSX.Element => {
  const {
    type,
    level,
    invokedBy,
    createdAt,
    userId,
    primaryDocId,
    primaryDocIdCreatedAt,
    data,
  } = auditLog
  return (
    <Card>
      {{
        body: (
          <div className="flex flex-col gap-2 p-2 text-left">
            <div className="flex items-center justify-between">
              <div className="flex gap-1">
                <p style={theme.textVariants.baseBold}>
                  <span>{type}</span> | <span>{level} </span>
                  {invokedBy && (
                    <>
                      | <span>Trigger: {invokedBy}</span>
                    </>
                  )}
                </p>
              </div>
              <p style={theme.textVariants.caption} className="text-neutral-40">
                {formatDate(Date.parse(createdAt))}
              </p>
            </div>

            <Divider />

            {userId && (
              <>
                <p>User: {userId}</p>
                <Divider />
              </>
            )}

            {(primaryDocId || primaryDocIdCreatedAt || data) && (
              <div>
                {primaryDocId && <p>Resource id: {primaryDocId}</p>}
                {primaryDocIdCreatedAt && (
                  <p>
                    Resource createdAt:{' '}
                    {formatDate(Date.parse(primaryDocIdCreatedAt))}
                  </p>
                )}
                {data && <p>Resource data: {data}</p>}
              </div>
            )}
          </div>
        ),
      }}
    </Card>
  )
}
