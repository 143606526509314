import { observer } from 'mobx-react-lite'
import React, { KeyboardEvent } from 'react'
import { IDropdownSelectOption } from './IDropdownSelectOption'

interface DropdownSelectOptionProps {
  option: IDropdownSelectOption
  selected: boolean
  onChange: (value: string) => void
  getTruncatedLabel: (label: string) => string | JSX.Element | undefined
  renderItemContent?: (option: IDropdownSelectOption) => JSX.Element | string
}

export const DropdownSelectOption = observer(
  ({
    option,
    selected,
    onChange,
    getTruncatedLabel,
    renderItemContent,
  }: DropdownSelectOptionProps): JSX.Element => {
    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
      if (e.key === 'Enter') {
        onChange(option.value)
      }
    }

    const onClick = (): void => {
      onChange(option.value)
    }

    return (
      <div
        key={option.value}
        role="option"
        className={`relative flex min-h-[40px] items-center py-2 px-4 text-sm text-gray-700 ${
          selected
            ? 'bg-neutral-95 first:rounded-t last:rounded-b'
            : 'hover:bg-neutral-95'
        } cursor-pointer first:hover:rounded-t last:hover:rounded-b`}
        aria-selected={selected}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        {renderItemContent
          ? renderItemContent(option)
          : getTruncatedLabel(option.label)}
      </div>
    )
  }
)
