import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { SnapshotOut } from 'mobx-state-tree'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { ViewBase } from '../../../../components/common/ViewBase'
import { FacilitiesList } from '../../../../components/society/FacilitiesList'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { ButtonVariant } from '../../../../components/common/Button'
import { IconChoices } from '../../../../components/common/Icon'
import { BookingsList } from '../../../../components/society/BookingsList'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { useAuth } from '../../../../hooks/useAuth'
import { UnitModel } from '../../../../state/models/unit'
import { useStores } from '../../../../hooks/useStores'

export enum ActiveTab {
  FACILITIES = 0,
  BOOKINGS = 1,
}

export const FacilitiesView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { facilitiesStore, unitStore, bookingsStore } = useStores()
  const { userId } = useAuth()
  const navigate = useNavigate()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('society:facilities', {
      '{{ societyName }}': society.name,
    })
  )
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.FACILITIES)
  const tabs = [
    {
      title: translate('facilitiesView.tabBar.newBooking'),
    },
    {
      title: translate('facilitiesView.tabBar.myBookings'),
    },
  ]

  const isAdmin = isUserAdminInSociety(society, userId as string)

  useEffect(() => {
    if (society._id && userId) {
      facilitiesStore.getFacilitiesPerSociety(society._id)
      unitStore.getUnitsPerSociety(society._id).then(() => {
        unitStore
          .userUnits(userId, society._id)
          .forEach((_unit: SnapshotOut<typeof UnitModel>) => {
            bookingsStore.getBookingsPerUnit(_unit._id, new Date())
          })
      })
    }
  }, [bookingsStore, facilitiesStore, unitStore, society._id, userId])

  const navigateToCreateUpdateFacilityView = (): void => {
    navigate(
      reverseUrl('society:facilities-create', {
        id: society._id,
      })
    )
  }

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('facilitiesView.title')}
        tabBar={{ tabs, setActiveTab, activeTab }}
        buttons={[
          ...(isAdmin
            ? [
                {
                  label: translate('facilitiesView.buttons.create'),
                  variant: ButtonVariant.PRIMARY,
                  icon: IconChoices.PLUS_SIGN,
                  onClick: navigateToCreateUpdateFacilityView,
                },
              ]
            : []),
        ]}
      >
        {activeTab === ActiveTab.FACILITIES ? (
          <FacilitiesList />
        ) : (
          <BookingsList />
        )}
      </ViewBase>
    </ErrorBoundary>
  )
})
