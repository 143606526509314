import React from 'react'
import { captureException } from '@sentry/react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { reportPostSchema } from '../../../forms/schemas/report_post'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { theme } from '../../../theme/theme'
import { Modal } from '../../common/Modal'
import { ModalBody } from '../../common/Modal/ModalBody'
import { ModalHeader } from '../../common/Modal/ModalHeader'
import { ToastType } from '../../common/Toast/toast-type'
import { ReportPostModalContent } from './ReportPostModalContent'

interface ReportPostModalProps {
  show: boolean
  close: () => void
  postId: string
}

export const ReportPostModal = observer(
  ({ show, close, postId }: ReportPostModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const { postStore } = useStores()

    const onSubmit = async (
      data: InferType<typeof reportPostSchema>
    ): Promise<void> => {
      const status = await postStore.reportPost(postId, data.message)
      if (status) {
        close()
        setToastNotification(
          ToastType.SUCCESS,
          translate('reportPost.flashMessage.reportSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('reportPost.flashMessage.reportFailure')
        )
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const loading = postStore.reportingPost === 'pending'

    return (
      <Modal show={show} size="md">
        {{
          header: (
            <ModalHeader flexItems="items-start" onClose={close}>
              <div className="flex flex-col gap-2">
                {translate('reportPost.title')}
                <p style={theme.textVariants.base}>
                  {translate('reportPost.subtitle')}
                </p>
              </div>
            </ModalHeader>
          ),
          body: (
            <ModalBody>
              <ReportPostModalContent
                onError={onError}
                onSubmit={onSubmit}
                onClose={close}
                loading={loading}
              />
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
