import React from 'react'
import { observer } from 'mobx-react-lite'
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd'
import { Dropdown, Option } from '../../../../../common/Dropdown'
import { Icon, IconChoices } from '../../../../../common/Icon'
import { Button } from '../../../../../common/Button'
import { theme } from '../../../../../../theme/theme'
import { Card } from '../../../../../common/Card'
import { CardBody } from '../../../../../common/Card/CardBody'
import { CardFooter } from '../../../../../common/Card/CardFooter'

interface DocumentListCardItemProps {
  isAdmin: boolean
  openItem: () => void
  documentTitle: string
  documentSubtitle: string
  documentIcon: IconChoices
  dropdownOptions: Option[]
  isDragging: boolean
  dragPreview: ConnectDragPreview
  drag: ConnectDragSource
  isOver?: boolean
}

export const DocumentListCardItem = observer(
  ({
    isAdmin,
    openItem,
    documentTitle,
    documentSubtitle,
    documentIcon,
    dropdownOptions,
    isDragging,
    dragPreview,
    drag,
    isOver,
  }: DocumentListCardItemProps): JSX.Element => {
    return (
      <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }}>
        <Card
          ref={isAdmin ? drag : null}
          className={`flex h-[200px] w-full w-[240px] items-center ${
            isAdmin ? 'cursor-move' : 'cursor-pointer'
          } ${isOver ? 'bg-neutral-90' : 'bg-white'}
          `}
          onClick={openItem}
        >
          {{
            body: (
              <CardBody className="flex h-full w-full items-center justify-center rounded-t-lg bg-neutral-94">
                <Icon size={64} icon={documentIcon} />
              </CardBody>
            ),
            footer: (
              <CardFooter className="flex h-4/6 w-full items-start justify-between p-4">
                <div className="flex w-5/6 min-w-0 flex-col items-start overflow-hidden">
                  <p
                    className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap"
                    style={theme.textVariants.base}
                  >
                    {documentTitle}
                  </p>
                  <p
                    style={theme.textVariants.caption}
                    className="text-neutral-50"
                  >
                    {documentSubtitle}
                  </p>
                </div>
                {isAdmin && (
                  <Dropdown options={dropdownOptions}>
                    <Button
                      icon={IconChoices.KEBAB_MENU}
                      className="h-10 w-10 border-none bg-transparent hover:bg-neutral-85"
                      disableTabIndex
                    />
                  </Dropdown>
                )}
              </CardFooter>
            ),
          }}
        </Card>
      </div>
    )
  }
)
