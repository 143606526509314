import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { endOfMonth } from 'date-fns'
import { FacilityModel } from '../../../../state/models/facility'
import { useStores } from '../../../../hooks/useStores'
import { SpinnerWrapper } from '../../../common/SpinnerWrapper'
import { FacilityBookingListItem } from '../FacilityBookingListItem'
import { ErrorView } from '../../../../views/error/ErrorView'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { IllustrationChoices } from '../../../common/Illustration'

interface FacilityBookingsListProps {
  facility: SnapshotOut<typeof FacilityModel>
  startOfMonthDate: Date
}

export const FacilityBookingsList = observer(
  ({
    facility,
    startOfMonthDate,
  }: FacilityBookingsListProps): JSX.Element | null => {
    const { bookingsStore } = useStores()
    const { translate } = useAppTranslation()

    const bookings = bookingsStore
      .bookingsForFacilityWithinTimespan(
        facility._id,
        startOfMonthDate,
        new Date(
          endOfMonth(startOfMonthDate).getTime() -
            new Date().getTimezoneOffset() * 60 * 1000
        )
      )
      .reverse()

    const loading = bookingsStore.fetchingBookings === 'pending'

    if (loading) {
      return <SpinnerWrapper />
    }

    if (bookings.length === 0) {
      return (
        <ErrorView
          title={translate('facilityView.bookings.errorView.noBookings.title')}
          subtitle={translate(
            'facilityView.bookings.errorView.noBookings.subtitle'
          )}
          illustration={IllustrationChoices.CHECKLIST}
          titleWrapperClassName="mt-10"
          className="pt-32"
        />
      )
    }

    return (
      <>
        {bookings.map((_booking) => (
          <FacilityBookingListItem
            key={_booking._id}
            booking={_booking}
            facility={facility}
          />
        ))}
      </>
    )
  }
)
