import { AxiosResponse } from 'axios'
import { NUnits } from '../interfaces/services/units.interfaces'
import { createUrl } from './helpers'
import axiosAuth from './config'

const UNITS_POPULATE = ['users', 'users.media', 'entrances']

export const getUnitsPerSociety = (
  societyId: string
): Promise<AxiosResponse<NUnits.NGetPerSocieties.IResponse>> => {
  return axiosAuth.get<NUnits.NGetPerSocieties.IResponse>(
    createUrl(`/units/get-per-society/${societyId}`, {
      populate: UNITS_POPULATE.join(';'),
    })
  )
}

const GET_USER_UNITS_POPULATE = [
  'societies',
  'societies.adminsList.media',
  'societies.boardMembersList.media',
  'entrances',
  'users.media',
]

export const getUserUnits = (
  userId: string
): Promise<AxiosResponse<NUnits.NGetUserUnits.IResponse>> => {
  return axiosAuth.get<NUnits.NGetUserUnits.IResponse>(
    createUrl(`/units/get-user-units/${userId}`, {
      populate: GET_USER_UNITS_POPULATE.join(';'),
    })
  )
}

export const getUnit = (
  id: string
): Promise<AxiosResponse<NUnits.NGetById.IResponse>> => {
  return axiosAuth.get<NUnits.NGetById.IResponse>(
    createUrl(`/units/unit/${id}`, {
      populate: UNITS_POPULATE.join(';'),
    })
  )
}

export const patchUnit = (
  id: string,
  body: NUnits.NPatch.IRequestBody
): Promise<AxiosResponse<NUnits.NPatch.IResponse>> => {
  return axiosAuth.patch<NUnits.NPatch.IResponse>(
    createUrl(`/units/unit/${id}`),
    body
  )
}

export const createUnit = (
  body: NUnits.NCreate.IRequestBody
): Promise<AxiosResponse<NUnits.NCreate.IResponse>> => {
  return axiosAuth.post<NUnits.NCreate.IResponse>(
    createUrl(`/units/unit`),
    body
  )
}

export const deleteUnit = (
  id: string
): Promise<AxiosResponse<NUnits.NDel.IResponse>> => {
  return axiosAuth.delete<NUnits.NDel.IResponse>(createUrl(`/units/unit/${id}`))
}

export const getUnitEstimate = (
  id: string
): Promise<AxiosResponse<NUnits.NEstimate.IResponse>> => {
  return axiosAuth.get<NUnits.NEstimate.IResponse>(
    createUrl(`/units/unit/${id}/estimate`)
  )
}

export const getUnitTrend = (
  id: string
): Promise<AxiosResponse<NUnits.NTrend.IResponse>> => {
  return axiosAuth.get<NUnits.NTrend.IResponse>(
    createUrl(`/units/unit/${id}/trend`)
  )
}
