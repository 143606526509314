/* eslint-disable no-param-reassign */
import { types, flow, getRoot, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import {
  getBookingsPerFacility as apiGetBookingsPerFacility,
  getBookingsPerUnit as apiGetBookingsPerUnit,
  deleteBooking as apiDeleteBooking,
  createBooking as apiCreateBooking,
} from '../../api/bookings'
import { stateType } from '../types/common'
import { RootStore } from './root'
import { setObject } from './helpers'
import { sortByDate } from '../../helpers/sorting'
import { BookingModel } from '../models/booking'
import { IBookingModel } from '../../interfaces/models/bookings.interfaces'
import { NBookings } from '../../interfaces/services/bookings.interfaces'
import { dateTimeAsUTCWithoutCompensation } from '../../helpers/date'

// bookingsStartDateTimestamps is used as a lookup table for a specific unix timestamp
// and facility as to have O(1) lookup when searching for a booking at a specific timeslot.
export const BookingStore = types
  .model('BookingStore')
  .props({
    bookings: types.map(BookingModel),
    fetchingBookings: stateType,
    creatingBooking: stateType,
    deletingBooking: stateType,
  })
  .views((self) => ({
    get sortedBookings() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return (values(self.bookings) as SnapshotOut<typeof BookingModel>[]).sort(
        (a, b) => sortByDate(a.startDate, b.startDate)
      )
    },
  }))
  .views((self) => ({
    bookingsForFacility(
      facilityId: string
    ): SnapshotOut<typeof BookingModel>[] {
      return self.sortedBookings.filter(
        (booking) => booking.facilityId === facilityId
      )
    },
    bookingsForUser(userId: string): SnapshotOut<typeof BookingModel>[] {
      return self.sortedBookings.filter((booking) => booking.userId === userId)
    },
    bookingsForUnit(unitId: string): SnapshotOut<typeof BookingModel>[] {
      return self.sortedBookings.filter((booking) => booking.unitId === unitId)
    },
  }))
  .views((self) => ({
    futureBookingsForUser(userId: string): SnapshotOut<typeof BookingModel>[] {
      return self.sortedBookings.filter(
        (booking) =>
          booking.userId === userId && new Date(booking.endDate) > new Date()
      )
    },
    futureBookingsForFacility(
      facilityId: string
    ): SnapshotOut<typeof BookingModel>[] {
      return self.sortedBookings.filter(
        (booking) =>
          booking.facilityId === facilityId &&
          new Date(booking.endDate) > new Date()
      )
    },
    bookingsForFacilityWithinTimespan(
      facilityId: string,
      startDate: Date,
      endDate: Date
    ): SnapshotOut<typeof BookingModel>[] {
      return self.sortedBookings.filter(
        (booking) =>
          booking.facilityId === facilityId &&
          new Date(booking.startDate) > startDate &&
          new Date(booking.endDate) < endDate
      )
    },
    futureBookingsForUnits(
      unitIds: string[]
    ): SnapshotOut<typeof BookingModel>[] {
      return self.sortedBookings.filter(
        (booking) =>
          unitIds.includes(booking.unitId) &&
          new Date(booking.endDate) >
            dateTimeAsUTCWithoutCompensation(new Date())
      )
    },
  }))
  .views((self) => ({
    bookingsForUserAndSociety(
      userId: string,
      societyId: string
    ): SnapshotOut<typeof BookingModel>[] {
      return self
        .bookingsForUser(userId)
        .filter((_booking) => _booking.societyId === societyId)
    },
    bookingsForUserAndFacility(
      userId: string,
      facilityId: string
    ): SnapshotOut<typeof BookingModel>[] {
      return self
        .bookingsForUser(userId)
        .filter((_booking) => _booking.facilityId === facilityId)
    },
    bookingsForUserAndFacilityForSpecificDate(
      userId: string,
      facilityId: string,
      startDate: Date,
      endDate: Date
    ): SnapshotOut<typeof BookingModel>[] {
      return self
        .bookingsForUser(userId)
        .filter(
          (booking) =>
            booking.facilityId === facilityId &&
            new Date(booking.startDate) > startDate &&
            new Date(booking.endDate) < endDate
        )
    },
  }))
  .views((self) => ({
    futureBookingsForUserAndSociety(
      userId: string,
      societyId: string
    ): SnapshotOut<typeof BookingModel>[] {
      return self
        .futureBookingsForUser(userId)
        .filter((_booking) => _booking.societyId === societyId)
    },
    futureBookingsForUserAndFacility(
      userId: string,
      facilityId: string
    ): SnapshotOut<typeof BookingModel>[] {
      return self
        .futureBookingsForUser(userId)
        .filter((_booking) => _booking.facilityId === facilityId)
    },
    futureBookingsForUnitsAndFacility(
      unitIds: string[],
      facilityId: string
    ): SnapshotOut<typeof BookingModel>[] {
      return self
        .futureBookingsForUnits(unitIds)
        .filter((_booking) => _booking.facilityId === facilityId)
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.bookings.clear()
      self.fetchingBookings = 'none'
      self.creatingBooking = 'none'
      self.deletingBooking = 'none'
    },
    setBookings: (bookings: IBookingModel[]) => {
      bookings.forEach((booking) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setObject<typeof BookingModel>(self.bookings, BookingModel, {
          ...booking,
        })
      })
    },
  }))
  .actions((self) => ({
    getBookingsPerFacility: flow(function* getBookingsPerFacility(
      facilityId: string,
      fromDate?: Date,
      toDate?: Date
    ) {
      self.fetchingBookings = 'pending'
      try {
        const resp = yield apiGetBookingsPerFacility(
          facilityId,
          fromDate,
          toDate
        )
        const data = resp.data as NBookings.NGetPerFacility.IResponse
        const bookings = data.data

        const users = data?.populated?.users
        const media = data?.populated?.media
        const units = data?.populated?.units

        const { userStore, mediaStore, unitStore, societyEntrancesStore } =
          getRoot<RootStore>(self)
        if (users) {
          userStore.setUsers(users)
        }
        if (media) {
          mediaStore.setMedia(media)
        }
        if ('society-entrances' in data.populated) {
          societyEntrancesStore.setEntrances(
            data.populated['society-entrances']
          )
        }
        if (units) {
          unitStore.setUnits(units)
        }

        // If a booking has been removed by another user
        // we want to make sure that we remove it in our store
        // to keep everything in sync.
        const currentBookings = self.bookingsForFacility(facilityId)
        const currentBookingIdsInTimespan = (
          fromDate
            ? currentBookings.filter((_booking) => {
                const conditions = []
                if (fromDate) {
                  conditions.push(
                    new Date(_booking.startDate) > new Date(fromDate)
                  )
                }
                if (toDate) {
                  conditions.push(new Date(_booking.endDate) < new Date(toDate))
                }
                return conditions.every((_condition) => _condition === true)
              })
            : currentBookings
        ).map((_booking) => _booking._id)
        const bookingIds = bookings.map((_booking) => _booking._id)

        const bookingsToRemove = currentBookingIdsInTimespan.filter(
          (_id) => !bookingIds.includes(_id)
        )

        self.setBookings(bookings)
        bookingsToRemove.forEach((_id) => self.bookings.delete(_id))

        self.fetchingBookings = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingBookings = 'error'
      }
    }),
    getBookingsPerUnit: flow(function* getBookingsPerUnit(
      unitId: string,
      fromDate?: Date
    ) {
      self.fetchingBookings = 'pending'
      try {
        const resp = yield apiGetBookingsPerUnit(unitId, fromDate)
        const data = resp.data as NBookings.NGetPerUser.IResponse
        const bookings = data.data
        const { units, facilities, media, users } = data.populated

        const {
          unitStore,
          societyEntrancesStore,
          mediaStore,
          facilitiesStore,
          userStore,
        } = getRoot<RootStore>(self)
        if ('society-entrances' in data.populated) {
          societyEntrancesStore.setEntrances(
            data.populated['society-entrances']
          )
        }
        if (users) {
          userStore.setUsers(users)
        }
        if (units) {
          unitStore.setUnits(units)
        }
        if (media) {
          mediaStore.setMedia(media)
        }
        if (facilities) {
          facilitiesStore.setFacilities(facilities)
        }

        // If a booking has been removed by another user
        // we want to make sure that we remove it in our store
        // to keep everything in sync.
        const currentBookings = self.bookingsForUnit(unitId)
        const currentBookingIdsInTimespan = (
          fromDate
            ? currentBookings.filter(
                (_booking) => new Date(_booking.startDate) > new Date(fromDate)
              )
            : currentBookings
        ).map((_booking) => _booking._id)
        const bookingIds = bookings.map((_booking) => _booking._id)

        const bookingsToRemove = currentBookingIdsInTimespan.filter(
          (_id) => !bookingIds.includes(_id)
        )

        self.setBookings(bookings)
        bookingsToRemove.forEach((_id) => self.bookings.delete(_id))

        self.fetchingBookings = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingBookings = 'error'
      }
    }),
    createBooking: flow(function* createBooking(
      body: NBookings.NCreate.IRequestBody
    ) {
      self.creatingBooking = 'pending'
      try {
        const resp = yield apiCreateBooking(body)
        const data = resp.data as NBookings.NCreate.IResponse
        const booking = data.data

        if (booking !== null) {
          self.setBookings([booking])
        }

        self.creatingBooking = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingBooking = 'error'
        return false
      }
    }),
    deleteBooking: flow(function* deleteBooking(id: string) {
      self.deletingBooking = 'pending'
      try {
        yield apiDeleteBooking(id)
        self.bookings.delete(id)
        self.deletingBooking = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.deletingBooking = 'error'
        return false
      }
    }),
  }))
