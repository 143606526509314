import i18next from 'i18next'

export enum NOTIFICATION_SETTINGS_VALUE {
  NONE = 'none',
  FEED = 'feed',
  NOTIFICATION_AND_FEED = 'notification+feed',
}

export const notificationSettingsOptions: {
  value: NOTIFICATION_SETTINGS_VALUE
  label: string
}[] = [
  {
    value: NOTIFICATION_SETTINGS_VALUE.NONE,
    label: i18next.t('common.notificationSettings.none'),
  },
  {
    value: NOTIFICATION_SETTINGS_VALUE.FEED,
    label: i18next.t('common.notificationSettings.feed'),
  },
  {
    value: NOTIFICATION_SETTINGS_VALUE.NOTIFICATION_AND_FEED,
    label: i18next.t('common.notificationSettings.notificationAndFeed'),
  },
]
