export const translateEnumDict = (
  dict: { [key: string]: string },
  key: string
): string | null => {
  if (!(key in dict)) {
    return null
  }

  return dict[key]
}

// Backend cannot handle react-18next supported language codes, so need to use either se or en
export const getLanguageCode = (
  selectedLocale: string | undefined | null
): string => {
  if (!selectedLocale) {
    return 'se'
  }
  return selectedLocale === 'sv-SE' ? 'se' : 'en'
}
