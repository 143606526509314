import React, { useCallback } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { TextInput } from '../../../common/TextInput'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import { societyReportSettingsSchema } from '../../../../forms/schemas/society_report_settings'
import { IconChoices } from '../../../common/Icon'
import { theme } from '../../../../theme/theme'

interface ReportProblemSettingsFormProps {
  onError: () => void
  onSubmit: (
    data: InferType<typeof societyReportSettingsSchema>
  ) => Promise<void>
  onClose: () => void
  loading: boolean
}

export const ReportProblemSettingsForm = ({
  loading,
  onError,
  onSubmit,
  onClose,
}: ReportProblemSettingsFormProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const { getErrorMessage } = useFormErrorMessage()

  const getDefaultValues = useCallback((): InferType<
    typeof societyReportSettingsSchema
  > => {
    if (!society.reportProblemEmailCC) {
      return {
        recipient: '',
        recipientsCC: [],
      }
    }

    const recipient =
      society.reportProblemEmailCC.length > 0
        ? society.reportProblemEmailCC[0]
        : ''
    const recipientsCC =
      society.reportProblemEmailCC.length > 1
        ? society.reportProblemEmailCC.slice(1).map((email) => ({ email }))
        : []
    return {
      recipient,
      recipientsCC,
    }
  }, [society])

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(societyReportSettingsSchema),
    defaultValues: getDefaultValues(),
  })
  const { fields, append, remove } = useFieldArray({
    name: 'recipientsCC',
    control,
  })

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      className="flex flex-col gap-3"
    >
      <p style={theme.textVariants.lead} className="mt-1 mb-2">
        {translate('reportProblemSettings.form.headers.recipient')}
      </p>

      <Controller
        control={control}
        name="recipient"
        render={({ field: { value, name, onChange, onBlur } }) => (
          <FormControl
            label={translate('common.form.labels.email')}
            error={errors.recipient && getErrorMessage(errors.recipient)}
            name={name}
          >
            <TextInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!errors.recipient}
              type="email"
            />
          </FormControl>
        )}
      />

      <div className="mt-4 mb-2 border-b" />

      <p style={theme.textVariants.lead} className="mb-2">
        {translate('reportProblemSettings.form.headers.cc')}
      </p>

      {fields.map((field, index) => (
        <Controller
          key={field.id}
          control={control}
          render={({ field: { onChange, onBlur, value, name } }) => {
            const _fieldError = errors.recipientsCC?.[index]?.email
            const _error = _fieldError
              ? getErrorMessage(_fieldError)
              : undefined

            return (
              <FormControl
                label={translate('common.form.labels.email')}
                error={_error}
                name={name}
              >
                <div className="flex items-center gap-3">
                  <TextInput
                    wrapperClassName="flex-1"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    type="email"
                    onDeleteIconPress={() => remove(index)}
                  />
                </div>
              </FormControl>
            )
          }}
          name={`recipientsCC.${index}.email`}
        />
      ))}
      <Button
        label={translate('common.actions.addMore')}
        onClick={() => append({ email: '' }, { shouldFocus: true })}
        icon={IconChoices.PLUS_SIGN}
      />

      <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-4">
        <Button label={translate('common.actions.cancel')} onClick={onClose} />
        <Button
          disabled={!isValid || loading}
          label={translate('common.actions.save')}
          variant={ButtonVariant.PRIMARY}
          type="submit"
        />
      </div>
    </form>
  )
}
