import { AxiosResponse } from 'axios'
import { createUrl } from './helpers'
import axiosAuth from './config'
import { NServiceReport } from '../interfaces/services/service-report.interfaces'

export const createReport = (
  body: NServiceReport.NCreate.IRequestBody
): Promise<AxiosResponse<NServiceReport.NCreate.IResponse>> => {
  return axiosAuth.post<NServiceReport.NCreate.IResponse>(
    createUrl('/service-report/report'),
    body
  )
}
