import React from 'react'
import reactStringReplace from 'react-string-replace'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import {
  PRIVACY_POLICY_LINK,
  USER_AGREEMENT_LINK,
} from '../../../../constants/Links'
import { theme } from '../../../../theme/theme'
import { Icon, IconChoices } from '../../../../components/common/Icon'

export const TermsOfServiceText = (): JSX.Element => {
  const { translate } = useAppTranslation()

  const text = `${translate(
    'authenticationSignUpView.form.checkboxText.termsOfService'
  )}`

  const processedTermsOfUse = reactStringReplace(
    text,
    '{{termsOfUse}}',
    (match, i) => (
      <div key={`${match}-${i}`} className="inline-flex gap-1 hover:text-blue">
        <a
          className="underline"
          href={USER_AGREEMENT_LINK}
          target="_blank"
          rel="noreferrer"
        >
          {translate(
            'authenticationSignUpView.form.checkboxText.termsOfUseTitle'
          )}
        </a>
        <Icon size={15} icon={IconChoices.EXTERNAL_LINK} />
      </div>
    )
  )

  const processedPrivacyPolicy = reactStringReplace(
    processedTermsOfUse,
    '{{privacyPolicy}}',
    (match, i) => (
      <div key={`${match}-${i}`} className="inline-flex gap-1 hover:text-blue">
        <a
          className="underline"
          href={PRIVACY_POLICY_LINK}
          target="_blank"
          rel="noreferrer"
        >
          {translate(
            'authenticationSignUpView.form.checkboxText.privacyPolicyTitle'
          )}
        </a>
        <Icon size={15} icon={IconChoices.EXTERNAL_LINK} />
      </div>
    )
  )
  return <div style={theme.textVariants.caption}>{processedPrivacyPolicy}</div>
}
