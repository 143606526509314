import * as yup from 'yup'

export const societyUpgradeSchema = yup
  .object({
    billingEmail: yup.string().email().required(),
    acceptsLegal: yup
      .boolean()
      .required('Villkoren måste godkännas.')
      .oneOf([true], 'Villkoren måste godkännas.'),
    discountCodePartnerNameId: yup.string().nullable(),
  })
  .required()
