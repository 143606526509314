import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useStores } from '../../../../hooks/useStores'
import { CreateUpdateMarketplaceItemForm } from '../CreateUpdateMarketplaceItemForm'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { societyResourceCreateSchema } from '../../../../forms/schemas/society_resource_create'
import { ResourceModel } from '../../../../state/models/resource'
import { NResources } from '../../../../interfaces/services/resources.interfaces'

interface CreateUpdateMarketplaceItemModalProps {
  show: boolean
  close: () => void
  children?: React.ReactNode
  resource?: SnapshotOut<typeof ResourceModel>
}

export const CreateUpdateMarketplaceItemModal = observer(
  ({
    resource,
    children,
    show,
    close,
  }: CreateUpdateMarketplaceItemModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { societyBoardroomContactsStore, resourcesStore } = useStores()
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!resource

    const onSubmit = async (
      data: InferType<typeof societyResourceCreateSchema>
    ): Promise<void> => {
      if (updateMode) {
        const status = await resourcesStore.patchResource(
          resource._id,
          data as NResources.NPatch.IRequestBody
        )
        if (status) {
          close()
          setToastNotification(
            ToastType.SUCCESS,
            translate(
              'societyCreateUpdateResourceScreens.flashMessage.updateResourceSuccess'
            )
          )
        } else {
          setToastNotification(
            ToastType.DANGER,
            translate(
              'societyCreateUpdateResourceScreens.flashMessage.updateResourceFailure'
            )
          )
        }
      } else {
        const status = await resourcesStore.createResource(
          data as NResources.NCreate.IRequestBody
        )
        if (status) {
          close()
          setToastNotification(
            ToastType.SUCCESS,
            translate(
              'societyCreateUpdateResourceScreens.flashMessage.createResourceSuccess'
            )
          )
        } else {
          setToastNotification(
            ToastType.DANGER,
            translate(
              'societyCreateUpdateResourceScreens.flashMessage.createResourceFailure'
            )
          )
        }
      }
    }

    const onError = (): void => {
      if (updateMode) {
        setToastNotification(
          ToastType.DANGER,
          translate(
            'societyCreateUpdateResourceScreens.flashMessage.updateResourceFailure'
          )
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate(
            'societyCreateUpdateResourceScreens.flashMessage.createResourceFailure'
          )
        )
      }
    }

    const loading =
      societyBoardroomContactsStore.creatingContact === 'pending' ||
      societyBoardroomContactsStore.updatingContact === 'pending'

    return (
      <>
        {children}
        <Modal show={show} size="xl">
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate('createUpdateResourceModal.title')}
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <CreateUpdateMarketplaceItemForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  resource={resource}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
