import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../hooks/useStores'
import { theme } from '../../../theme/theme'
import { SocietyWidgetModel } from '../../../state/models/society-widget'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useModal } from '../../../hooks/useModal'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'
import { Dropdown, Option } from '../../common/Dropdown'
import { Icon, IconChoices } from '../../common/Icon'
import { Button } from '../../common/Button'
import { ConfirmationModal } from '../../common/ConfirmationModal'
import { CreateUpdateWidgetModal } from './CreateUpdateWidgetModal'
import { WidgetType } from './widget-type'

interface FunctionSettingsCustomWidgetProps {
  widget: SnapshotOut<typeof SocietyWidgetModel>
}

export const FunctionSettingsCustomWidget = observer(
  ({ widget }: FunctionSettingsCustomWidgetProps): JSX.Element => {
    const [deletingWidget, setDeletingWidget] = useState(false)
    const { societyWidgetsStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()
    const {
      show: showEditModal,
      open: openEditModal,
      close: closeEditModal,
    } = useModal()
    const {
      show: showDeleteConfirmationModal,
      open: openDeleteConfirmationModal,
      close: closeDeleteConfirmationModal,
    } = useModal()

    const onDelete = async (): Promise<void> => {
      setDeletingWidget(true)
      const status = await societyWidgetsStore.deleteWidget(widget._id)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('functionSettingsView.flashMessage.deleteWidgetSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('functionSettingsView.flashMessage.deleteWidgetFailure')
        )
      }
      setDeletingWidget(false)
    }

    const dropdownOptions: Option[] = [
      {
        value: 'edit',
        label: translate('common.actions.edit'),
        onClick: () => openEditModal(),
      },
      {
        value: 'delete',
        label: translate('common.actions.delete'),
        onClick: openDeleteConfirmationModal,
        destructive: true,
      },
    ]

    let widgetType = widget.type

    if (!widgetType) {
      if (widget.content) {
        widgetType = WidgetType.TEXT
      } else if (widget.link) {
        widgetType = WidgetType.LINK
      }
    }

    return (
      <>
        <div
          key={widget._id}
          className="flex w-full items-center rounded bg-neutral-96 px-4 py-2"
        >
          <div className="flex flex-1 items-center">
            <div className="rounded-full bg-white p-2">
              <Icon
                icon={
                  widgetType === WidgetType.LINK
                    ? IconChoices.EXTERNAL_LINK
                    : IconChoices.DOCUMENT
                }
              />
            </div>
            <p className="ml-2 mr-4" style={theme.textVariants.baseBold}>
              {widget.title}
            </p>
          </div>
          {dropdownOptions.length > 0 && (
            <Dropdown options={dropdownOptions}>
              <Button
                className="h-10 w-10"
                iconSize={16}
                icon={IconChoices.MORE}
                loading={deletingWidget}
                disabled={deletingWidget}
                disableTabIndex
              />
            </Dropdown>
          )}
        </div>
        <CreateUpdateWidgetModal
          show={showEditModal}
          close={closeEditModal}
          widget={{ ...widget, type: widgetType }}
        />
        <ConfirmationModal
          title={translate(
            'functionSettingsView.confirmationModal.deleteTitle'
          )}
          description={translate(
            'functionSettingsView.confirmationModal.deleteDescription'
          )}
          show={showDeleteConfirmationModal}
          close={closeDeleteConfirmationModal}
          onConfirm={onDelete}
          deleteMode
        />
      </>
    )
  }
)
