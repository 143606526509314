import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useNavigate, Link } from 'react-router-dom'
import { UserModel } from '../../../../state/models/user'
import { UserAvatar } from '../../../common/Avatar'
import { SocietyModel } from '../../../../state/models/society'
import { theme } from '../../../../theme/theme'
import { Checkbox } from '../../../common/Checkbox'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { Badge } from '../../../common/Badge'
import { useUserUnitsString } from '../../../../hooks/useUserUnitsString'
import { useUserRolesString } from '../../../../hooks/useUserRolesString'
import { ResidentUserType } from '../../../../state/stores/units'
import { Dropdown, Option } from '../../../common/Dropdown'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useModal } from '../../../../hooks/useModal'
import { Button } from '../../../common/Button'
import { IconChoices } from '../../../common/Icon'
import { EditResidentUnitsModal } from '../../RegisterMemberList/EditResidentUnitsModal'
import { useStores } from '../../../../hooks/useStores'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { ConfirmationModal } from '../../../common/ConfirmationModal'

interface ResidentListItemProps {
  user: SnapshotOut<typeof UserModel>
  society: SnapshotOut<typeof SocietyModel> | undefined
  selectMode?: boolean
  selected?: boolean
  onSelectedChange?: (selected: boolean) => void
  editUserUnitsMode?: ResidentUserType
  deleteUserOption?: boolean
  showUserRoles?: boolean
  surnameFirst?: boolean
}

export const ResidentListItem = observer(
  ({
    user,
    society,
    selectMode,
    selected,
    onSelectedChange,
    editUserUnitsMode,
    deleteUserOption = false,
    showUserRoles = true,
    surnameFirst = true,
  }: ResidentListItemProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { societyStore, unitStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const userUnitsString = useUserUnitsString(user, society)
    const userRolesString = useUserRolesString(user, society)
    const navigate = useNavigate()
    const {
      show: showEditUserUnitModal,
      open: openEditUserUnitModal,
      close: closeEditUserUnitModal,
    } = useModal()
    const {
      show: showDeleteConfirmationModal,
      close: closeDeleteConfirmationModal,
      open: openDeleteConfirmationModal,
    } = useModal()

    const getUserRoute = (): string => {
      return reverseUrl('user:society-detail', {
        id: user._id,
        societyId: society?._id,
      })
    }

    const userOnClick = (): void => {
      return navigate(getUserRoute())
    }

    const removeUserFromSociety = async (): Promise<void> => {
      if (society) {
        const status = await societyStore.removeUserFromSociety(
          society._id,
          user._id
        )
        if (status) {
          setToastNotification(
            ToastType.SUCCESS,
            translate('registerView.flashMessage.removeUserSuccess')
          )
          // Update to remove user from society, units, etc.
          await unitStore.getUnitsPerSociety(society._id)
          await societyStore.getSociety(society._id)
          return
        }
        setToastNotification(
          ToastType.DANGER,
          translate('registerView.flashMessage.removeUserFailure')
        )
      }
    }

    const dropdownOptions: Option[] = [
      ...(editUserUnitsMode
        ? [
            {
              value: 'edit_units',
              label: translate('common.actions.edit'),
              onClick: (
                e:
                  | React.MouseEvent<HTMLElement>
                  | React.KeyboardEvent<HTMLDivElement>
              ) => {
                e.stopPropagation()
                openEditUserUnitModal()
              },
            },
          ]
        : []),
      ...(deleteUserOption
        ? [
            {
              value: 'delete',
              label: translate('common.actions.remove'),
              onClick: () => openDeleteConfirmationModal(),
              destructive: true,
            },
          ]
        : []),
    ]

    return (
      <>
        <div className="flex flex-row items-center rounded-sm border-b p-4">
          <div className="flex flex-10 flex-row items-center gap-4">
            <UserAvatar user={user} onClick={userOnClick} />
            <div className="flex flex-col justify-start gap-1">
              <div className="flex items-center gap-2">
                <Link
                  className="hover:underline"
                  to={getUserRoute()}
                  style={theme.textVariants.baseBold}
                >
                  {surnameFirst
                    ? [user.surname, user.name].join(', ')
                    : [user.name, user.surname].join(' ')}
                </Link>
                {showUserRoles && (
                  <div>
                    {userRolesString.length > 0 && (
                      <Badge
                        text={userRolesString.join(', ')}
                        colorClass="bg-badge-green"
                        textColorClass="text-green"
                      />
                    )}
                  </div>
                )}
              </div>
              {userUnitsString && (
                <p className="text-neutral-50" style={theme.textVariants.base}>
                  {userUnitsString}
                </p>
              )}
            </div>
          </div>
          {selectMode && onSelectedChange && (
            <div
              className="flex flex-col items-end"
              style={theme.textVariants.base}
            >
              <Checkbox
                value={selected}
                name={user._id}
                type="checkbox"
                onChange={() => {
                  onSelectedChange(!selected)
                }}
              />
            </div>
          )}
          {dropdownOptions.length > 0 && (
            <>
              <Dropdown options={dropdownOptions}>
                <Button
                  icon={IconChoices.MORE}
                  className="h-10 w-10 border-none bg-transparent hover:bg-neutral-90"
                />
              </Dropdown>
            </>
          )}
        </div>
        {editUserUnitsMode && showEditUserUnitModal && (
          <EditResidentUnitsModal
            show={showEditUserUnitModal}
            close={closeEditUserUnitModal}
            user={user}
          />
        )}
        <ConfirmationModal
          title={translate('registerView.alert.removeUserTitle')}
          description={translate('registerView.alert.removeUserText', {
            name: user.name,
          })}
          show={showDeleteConfirmationModal}
          close={closeDeleteConfirmationModal}
          onConfirm={removeUserFromSociety}
        />
      </>
    )
  }
)
