import { useEffect, useState } from 'react'
import { getSocietyPremiumCost } from '../../../api/societies'
import { NSocieties } from '../../../interfaces/services/societies.interfaces'

export const useSocietyPremiumCost = (
  societyId: string,
  discountCodePartnerNameId?: string
): {
  costSummary: NSocieties.NCostSummary.IResponseInternal
  fetchingCost: boolean
} => {
  const [costSummary, setCostSummary] =
    useState<NSocieties.NCostSummary.IResponseInternal>(null)
  const [fetchingCost, setFetchingCost] = useState(false)

  useEffect(() => {
    const _getCostSummary = async (): Promise<void> => {
      setFetchingCost(true)
      const response = await getSocietyPremiumCost(societyId, {
        partnerNameId: discountCodePartnerNameId,
      })

      setCostSummary(response.data.data)

      setFetchingCost(false)
    }
    _getCostSummary()
  }, [societyId, discountCodePartnerNameId])

  return {
    costSummary,
    fetchingCost,
  }
}
