import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { castToSnapshot, SnapshotOut } from 'mobx-state-tree'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import { useStores } from '../../../../hooks/useStores'
import { SelectDropdown } from '../../../common/SelectDropdown'
import { UnitRole } from '../../../../types/unit-roles'
import { getUnitRole } from '../../../../helpers/translations/unit-roles'
import { FormSpacing } from '../../../common/FormSpacing'
import { UserModel } from '../../../../state/models/user'
import {
  societyUserUnitsUpdateSchema,
  societyUserUnitUpdateSchema,
} from '../../../../forms/schemas/society_user_unit_update'
import { SocietyEntranceModel } from '../../../../state/models/society-entrance'
import { Divider } from '../../../common/Divider'

interface EditResidentUnitsFormProps {
  onError: () => void
  onSubmit: (
    data: InferType<typeof societyUserUnitsUpdateSchema>,
    existingUserUnits: InferType<typeof societyUserUnitUpdateSchema>[]
  ) => Promise<void>
  loading: boolean
  user: SnapshotOut<typeof UserModel>
}

export const EditResidentUnitsForm = observer(
  ({
    loading,
    onError,
    onSubmit,
    user,
  }: EditResidentUnitsFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { getErrorMessage } = useFormErrorMessage()
    const { societyEntrancesStore, unitStore } = useStores()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isSamf } = society

    const entrances = societyEntrancesStore.entrancesForSociety(society._id)

    const getUserUnits = useCallback(() => {
      return unitStore.userUnits(user._id, society._id)
    }, [user, society, unitStore])

    const userUnits = getUserUnits()
    const existingUserUnits = userUnits.map((unit) => {
      return {
        _id: unit._id,
        societyId: society._id,
        entranceId: unit.entranceId,
        unitIdOrTitle: unit._id,
        userId: user._id,
        unitRole:
          unit.residentsList.find((resident) => resident.userId === user._id)
            ?.role || '',
      }
    })

    const getDefaultValues = useCallback((): InferType<
      typeof societyUserUnitsUpdateSchema
    > => {
      return {
        // TODO: How to handle "forced" types that do not conform to yup?
        // In this case, we want to force some undefined.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        units:
          userUnits.length === 0
            ? [
                {
                  _id: undefined,
                  societyId: society._id,
                  entranceId:
                    entrances.length > 0 ? entrances[0]._id : undefined,
                  unitIdOrTitle: undefined,
                  userId: user._id,
                  unitRole: UnitRole.MEMBER as string,
                },
              ]
            : existingUserUnits,
      }
    }, [society, entrances, userUnits, existingUserUnits, user._id])

    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors, isValid },
      watch,
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyUserUnitsUpdateSchema),
      defaultValues: getDefaultValues(),
    })

    const { fields, append, remove } = useFieldArray({
      name: 'units',
      control,
    })

    const watchUnits = watch('units')
    const societyUnits = unitStore.unitsForSociety(society._id)
    const availableUnitsForWatchedUnit = watchUnits.map((_unit) =>
      societyUnits
        .filter((unit) => {
          const entrance =
            unit.entranceId &&
            societyEntrancesStore.entrances.get(unit.entranceId)
          return entrance
            ? (
                castToSnapshot(entrance) as SnapshotOut<
                  typeof SocietyEntranceModel
                >
              )._id === _unit.entranceId
            : false
        })
        .map((unit) => {
          if (unit.isUnassigned) {
            return {
              value: unit._id,
              label: translate('common.unit.unassigned'),
            }
          }
          return {
            value: unit._id,
            label: `${unit.titleLegal} (${unit.title})`,
          }
        })
    )

    const entranceOptions = entrances.map((_entrance) => ({
      label: _entrance.addressStreet as string,
      value: _entrance._id,
    }))

    const unitRoleOptions = Object.values(UnitRole).map((val) => ({
      value: val as string,
      label: translate(getUnitRole(val) as string),
    }))

    const _onSubmit = async (
      data: InferType<typeof societyUserUnitsUpdateSchema>
    ): Promise<void> => {
      onSubmit(data, existingUserUnits)
    }

    return (
      <FormSpacing>
        {fields.map((field, index) => (
          <FormSpacing key={field._id}>
            <Controller
              control={control}
              name={`units.${index}.entranceId`}
              render={({ field: { value, name, onChange } }) => (
                <FormControl
                  label={translate(
                    isSamf
                      ? 'registerView.unit.labels.entranceSamf'
                      : 'registerView.unit.labels.entrance'
                  )}
                  error={
                    errors.units?.[index]?.entranceId &&
                    getErrorMessage(errors.units[index]?.entranceId)
                  }
                  name={name}
                >
                  <SelectDropdown
                    options={entranceOptions}
                    value={value}
                    onChange={(val) => {
                      onChange(val)
                      const updatedUnits = watchUnits.map((_unit, _index) =>
                        _index === index
                          ? { ..._unit, unitIdOrTitle: '' }
                          : _unit
                      )
                      setValue('units', updatedUnits)
                    }}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name={`units.${index}.unitIdOrTitle`}
              render={({ field: { value, name, onChange } }) => (
                <FormControl
                  label={translate(
                    isSamf
                      ? 'registerView.unit.labels.unitSamf'
                      : 'registerView.unit.labels.unit'
                  )}
                  error={
                    errors.units?.[index]?.entranceId &&
                    getErrorMessage(errors.units[index]?.unitIdOrTitle)
                  }
                  name={name}
                >
                  <SelectDropdown
                    options={availableUnitsForWatchedUnit[index]}
                    value={value}
                    onChange={onChange}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name={`units.${index}.unitRole`}
              render={({ field: { value, name, onChange } }) => (
                <FormControl
                  label={translate('common.form.labels.role')}
                  error={
                    errors.units?.[index]?.entranceId &&
                    getErrorMessage(errors.units[index]?.unitRole)
                  }
                  name={name}
                >
                  <SelectDropdown
                    options={unitRoleOptions}
                    value={value}
                    onChange={onChange}
                  />
                </FormControl>
              )}
            />
            {index > 0 && (
              <Button
                label={translate('common.actions.delete')}
                variant={ButtonVariant.DANGER}
                onClick={() => remove(index)}
              />
            )}
            <Divider className="my-2" />
          </FormSpacing>
        ))}
        <Button
          onClick={() =>
            append({
              _id: undefined,
              societyId: society._id,
              // Ignores required as we want to force undefined.
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              entranceId: entrances.length > 0 ? entrances[0]._id : undefined,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              unitIdOrTitle: undefined,
              userId: user._id,
              unitRole: UnitRole.MEMBER,
            })
          }
          label={translate('common.actions.add')}
        />

        <div className="mt-2 flex items-center justify-end gap-4">
          <Button
            disabled={!isValid || loading}
            variant={ButtonVariant.PRIMARY}
            label={translate('common.actions.save')}
            type="submit"
            loading={loading}
            onClick={handleSubmit(_onSubmit, onError)}
          />
        </div>
      </FormSpacing>
    )
  }
)
