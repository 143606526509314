import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { theme } from '../../../../theme/theme'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { useStores } from '../../../../hooks/useStores'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { ResidentUserType } from '../../../../state/stores/units'
import { InviteForm } from '../InviteForm'
import { societyPreApprovedCreateSchema } from '../../../../forms/schemas/society_pre_approved_create'

interface InviteModalProps {
  show: boolean
  close: () => void
  residentType: ResidentUserType
  children?: React.ReactNode
}

export const InviteModal = observer(
  ({ show, close, residentType, children }: InviteModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { unitStore, societyStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()

    const showSuccessMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('registerInvite.flashMessage.inviteSentSuccess')
      )
    }

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.SUCCESS,
        translate('registerInvite.flashMessage.inviteSentFailure')
      )
    }

    const onSubmit = async (
      data: InferType<typeof societyPreApprovedCreateSchema>
    ): Promise<void> => {
      const status = await societyStore.createUserRole([
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        {
          ...data,
          userEmail: data.userEmail.toLowerCase(),
        },
      ])
      if (status) {
        showSuccessMessage()
        societyStore.getSociety(society._id)
        close()
      } else {
        showErrorMessage()
      }
    }

    const onError = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const loading =
      unitStore.creatingUnit === 'pending' ||
      unitStore.updatingUnit === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                <div>
                  <p style={theme.textVariants.h3}>
                    {translate(
                      residentType === ResidentUserType.MEMBER
                        ? 'registerInvite.modal.inviteMember'
                        : 'registerInvite.modal.inviteNonMember'
                    )}
                  </p>
                </div>
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <InviteForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  residentType={residentType}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
