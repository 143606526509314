import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate } from 'react-router-dom'
import { ErrorBoundary } from '../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { theme } from '../../../theme/theme'
import { BackButton } from '../../../components/common/BackButton'
import { CreateSocietyForm } from '../../../components/find-society/CreateSocietyForm'
import { SocietyTypes } from '../../../types/society-type'
import { useDocumentTitle } from '../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../navigation/reverseDocumentTitle'
import { useStores } from '../../../hooks/useStores'
import { useAuth } from '../../../hooks/useAuth'

export const CreateSocietyView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { userId } = useAuth()
  const { societyStore, userStore } = useStores()
  const { hasFetchedSocietiesOnce } = societyStore
  const navigate = useNavigate()
  useDocumentTitle(reverseDocumentTitle('create-society'))
  const { state } = useLocation()
  const { societyTypeDisplayName } = state || {}

  const user = userId ? userStore.users.get(userId) : undefined

  const goBack = (): void => {
    navigate(-1)
  }

  useEffect(() => {
    if (userId && !hasFetchedSocietiesOnce) {
      societyStore.getUserSocieties(userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const societyIsSamf =
    societyTypeDisplayName === SocietyTypes.HOME_OWNERS_ASSOCIATION
  const societyIsRealStateDeveloper =
    societyTypeDisplayName === SocietyTypes.HOUSING_COOPERATIVE_CONSTRUCTION
  const isNeighbourhood = societyTypeDisplayName === SocietyTypes.NEIGHBOURHOOD

  const getTitle = (): string => {
    if (societyIsSamf) {
      return translate('createSocietyForm.samfTitle')
    }
    if (societyIsRealStateDeveloper) {
      return translate('createSocietyForm.realEstateDeveloperTitle')
    }
    if (isNeighbourhood) {
      return translate('createSocietyForm.neighbourhoodTitle')
    }
    return translate('createSocietyForm.brfTitle')
  }

  const title = getTitle()

  if (!societyTypeDisplayName) {
    return <></>
  }

  return (
    <ErrorBoundary>
      <div className="h-full w-full p-6">
        <div className="flex items-center space-x-4">
          <BackButton onClick={goBack} />
          <p style={theme.textVariants.h2}>{title}</p>
        </div>
        <div className="h-full w-full overflow-y-auto py-2 pr-4 md:py-4">
          {user && (
            <CreateSocietyForm
              societyTypeDisplayName={societyTypeDisplayName}
              user={user}
            />
          )}
        </div>
      </div>
    </ErrorBoundary>
  )
})
