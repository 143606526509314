import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { TextInput } from '../../../common/TextInput'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import { societyEntranceCreateSchema } from '../../../../forms/schemas/society_entrance_create'
import { SocietyEntranceModel } from '../../../../state/models/society-entrance'

interface CreateUpdateRegisterEntranceItemFormProps {
  onError: () => void
  onSubmit: (
    data: InferType<typeof societyEntranceCreateSchema>
  ) => Promise<void>
  onClose: () => void
  loading: boolean
  entrance?: SnapshotOut<typeof SocietyEntranceModel>
}

export const CreateUpdateRegisterEntranceItemForm = observer(
  ({
    entrance,
    loading,
    onError,
    onSubmit,
    onClose,
  }: CreateUpdateRegisterEntranceItemFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { getErrorMessage } = useFormErrorMessage()

    const updateMode = !!entrance

    const getDefaultValues = useCallback((): InferType<
      typeof societyEntranceCreateSchema
    > => {
      return {
        addressStreet: updateMode ? entrance?.addressStreet || '' : '',
        societyId: updateMode
          ? entrance?.societyId || society._id
          : society._id,
      }
    }, [society, entrance, updateMode])

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyEntranceCreateSchema),
      defaultValues: getDefaultValues(),
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isSamf } = society

    return (
      <>
        <Controller
          control={control}
          name="addressStreet"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={
                isSamf
                  ? translate('registerEntrance.form.labels.addressStreetSamf')
                  : translate('registerEntrance.form.labels.addressStreet')
              }
              error={
                errors.addressStreet && getErrorMessage(errors.addressStreet)
              }
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.addressStreet}
              />
            </FormControl>
          )}
        />

        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            disabled={!isValid || loading}
            variant={ButtonVariant.PRIMARY}
            label={
              updateMode
                ? translate('common.actions.save')
                : translate('common.actions.create')
            }
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </>
    )
  }
)
