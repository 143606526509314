import React, { useEffect } from 'react'
import { saveAs } from 'file-saver'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useStores } from '../../../../hooks/useStores'
import { ViewBase } from '../../../../components/common/ViewBase'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { IllustrationChoices } from '../../../../components/common/Illustration'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { theme } from '../../../../theme/theme'
import { Icon, IconChoices } from '../../../../components/common/Icon'
import { useModal } from '../../../../hooks/useModal'
import { ButtonVariant } from '../../../../components/common/Button'
import { ConfirmationModal } from '../../../../components/common/ConfirmationModal'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../../components/common/Toast/toast-type'
import { CreateUpdateContractModal } from '../../../../components/society/Contract/CreateUpdateContractModal'
import { Divider } from '../../../../components/common/Divider'
import { formatDateWithFormat } from '../../../../helpers/date'
import { ContractStatusBadge } from '../../../../components/society/ContractStatusBadge'
import { createUrl } from '../../../../api/helpers'
import { prependHttp } from '../../../../helpers/url'

export const SocietyContractDetailView = observer((): JSX.Element => {
  const { contractStore, documentStore } = useStores()
  const { translate } = useAppTranslation()
  const { society } = useCurrentSociety()
  const navigate = useNavigate()
  const { setToastNotification } = useToastNotifications()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }

  const {
    show: showCreateUpdateModal,
    open: openCreateUpdateModal,
    close: closeCreateUpdateModal,
  } = useModal()

  const {
    show: showDeleteConfirmationModal,
    open: openDeleteConfirmationModal,
    close: closeDeleteConfirmationModal,
  } = useModal()

  const { contractId } = useParams()
  const contract = contractId
    ? contractStore.contracts.get(contractId)
    : undefined

  useDocumentTitle(
    reverseDocumentTitle('management:contracts-detail', {
      '{{ contractTitle }}': contract?.title,
      '{{ societyName }}': society.name,
    })
  )

  useEffect(() => {
    if (contractId && !contract) {
      contractStore.getContract(contractId)
    }
  }, [contractStore, contractId, contract])

  const deleteContract = async (): Promise<void> => {
    if (!contractId) {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
      return
    }
    const status = await contractStore.deleteContract(contractId)
    if (status) {
      setToastNotification(
        ToastType.SUCCESS,
        translate('deleteContract.flashMessage.deleteContractSuccess')
      )
      navigate(reverseUrl('management:contracts', { id: society._id }))
    } else {
      setToastNotification(
        ToastType.DANGER,
        translate('deleteContract.flashMessage.deleteContractFailure')
      )
    }
  }

  const downloadDocument = (id: string, filename: string): void => {
    saveAs(createUrl(`/documents/fetch/${id}`), filename)
  }

  const loading = contractStore.fetchingContracts === 'pending'

  const errorView =
    !loading && !contract
      ? {
          title: translate('societyContractDetailView.emptyState.title'),
          subtitle: translate('societyContractDetailView.emptyState.subtitle'),
          illustration: IllustrationChoices.EMPTY,
        }
      : undefined

  return (
    <ErrorBoundary>
      <ViewBase
        showBackButton
        backUrl={reverseUrl('management:contracts', { id: society._id })}
        title={translate('societyContractsView.title')}
        loading={loading}
        errorView={errorView}
        buttons={[
          {
            label: translate('common.actions.edit'),
            icon: IconChoices.EDIT,
            onClick: openCreateUpdateModal,
          },
          {
            label: translate('common.actions.delete'),
            icon: IconChoices.DELETE_TRASH,
            variant: ButtonVariant.DANGER,
            onClick: openDeleteConfirmationModal,
          },
        ]}
      >
        {contract && (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <div className="flex gap-2">
                <p style={theme.textVariants.h2}>{contract.title}</p>
                <ContractStatusBadge status={contract.status} />
              </div>
              {contract.company && (
                <p
                  style={theme.textVariants.lead}
                  className="w-full whitespace-pre-line md:w-1/2"
                >
                  {contract.company}
                </p>
              )}
              {contract.description && (
                <p
                  style={theme.textVariants.base}
                  className="mt-1 whitespace-pre-line 2xl:w-1/3"
                >
                  {contract.description}
                </p>
              )}
            </div>
            {(contract.startDate ||
              contract.endDate ||
              contract.notificationDate ||
              contract.running) && (
              <>
                <Divider />
                <div className="flex flex-col gap-1">
                  {contract.startDate && (
                    <p>
                      <span style={theme.textVariants.lead}>
                        {translate('createUpdateContractForm.labels.startDate')}
                        :
                      </span>
                      <span style={theme.textVariants.base}>
                        {' '}
                        {formatDateWithFormat(
                          new Date(contract.startDate),
                          'yyyy-MM-dd'
                        )}
                      </span>
                    </p>
                  )}
                  {contract.endDate && (
                    <p>
                      <span style={theme.textVariants.lead}>
                        {translate('createUpdateContractForm.labels.endDate')}:
                      </span>
                      <span style={theme.textVariants.base}>
                        {' '}
                        {formatDateWithFormat(
                          new Date(contract.endDate),
                          'yyyy-MM-dd'
                        )}
                      </span>
                    </p>
                  )}
                  {contract.notificationDate && (
                    <p>
                      <span style={theme.textVariants.lead}>
                        {translate(
                          'createUpdateContractForm.labels.notificationDate'
                        )}
                        :
                      </span>
                      <span style={theme.textVariants.base}>
                        {' '}
                        {formatDateWithFormat(
                          new Date(contract.notificationDate),
                          'yyyy-MM-dd'
                        )}
                      </span>
                    </p>
                  )}
                  {contract.running && (
                    <div className="flex flex-row items-center gap-1">
                      <p style={theme.textVariants.lead}>
                        {translate('createUpdateContractForm.labels.running')}:
                      </p>
                      <Icon icon={IconChoices.CHECKMARK} />
                    </div>
                  )}
                </div>
              </>
            )}
            {(contract.contactName ||
              contract.contactEmail ||
              contract.contactPhone ||
              contract.contactWebsite) && (
              <>
                <Divider />
                <div className="flex flex-col gap-1">
                  <p style={theme.textVariants.h3} className="mb-1">
                    {translate(
                      'createUpdateContractForm.headers.contactDetails'
                    )}
                  </p>
                  {contract.contactName && (
                    <p>
                      <span style={theme.textVariants.lead}>
                        {translate(
                          'createUpdateContractForm.labels.contactName'
                        )}
                        :
                      </span>
                      <span style={theme.textVariants.base}>
                        {' '}
                        {contract.contactName}
                      </span>
                    </p>
                  )}
                  {contract.contactPhone && (
                    <p>
                      <span style={theme.textVariants.lead}>
                        {translate(
                          'createUpdateContractForm.labels.contactPhone'
                        )}
                        :
                      </span>
                      <span style={theme.textVariants.base}>
                        {' '}
                        {contract.contactPhone}
                      </span>
                    </p>
                  )}
                  {contract.contactEmail && (
                    <p>
                      <span style={theme.textVariants.lead}>
                        {translate(
                          'createUpdateContractForm.labels.contactEmail'
                        )}
                        :{' '}
                      </span>
                      <a
                        style={theme.textVariants.base}
                        className="text-brandGreen underline"
                        href={`mailto:${contract?.contactEmail}`}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {contract?.contactEmail}
                      </a>
                    </p>
                  )}
                  {contract.contactWebsite && (
                    <p>
                      <span style={theme.textVariants.lead}>
                        {translate(
                          'createUpdateContractForm.labels.contactWebsite'
                        )}
                        :{' '}
                      </span>
                      <a
                        style={theme.textVariants.base}
                        className="text-brandGreen underline"
                        href={prependHttp(contract?.contactWebsite)}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {prependHttp(contract?.contactWebsite)}
                      </a>
                    </p>
                  )}
                </div>
              </>
            )}
            {contract.documentIds && contract.documentIds.length > 0 && (
              <>
                <Divider />
                <div className="flex flex-col gap-2">
                  <p style={theme.textVariants.h3}>
                    {translate('createUpdateContractForm.headers.documents')}
                  </p>
                  <div className="flex flex-col gap-2 xl:w-1/2">
                    {contract.documentIds.map((id) => {
                      const document = documentStore.documents.get(id)
                      if (document) {
                        return (
                          <button
                            key={document._id}
                            className={`flex min-w-0 flex-row items-center gap-3 whitespace-nowrap rounded 
                        bg-neutral-96 py-4 px-5`}
                            onClick={() =>
                              downloadDocument(document._id, document.filename)
                            }
                          >
                            <Icon icon={IconChoices.DOCUMENT} size={24} />
                            <span
                              className="overflow-hidden text-ellipsis"
                              style={theme.textVariants.baseBold}
                            >
                              {document.name}
                            </span>
                            <Icon
                              icon={IconChoices.ARROW}
                              size={24}
                              highlighted
                            />
                          </button>
                        )
                      }
                      return null
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {showDeleteConfirmationModal && (
          <ConfirmationModal
            title={translate(
              'deleteContract.removeContractConfirmationModal.title'
            )}
            description={translate(
              'deleteContract.removeContractConfirmationModal.description'
            )}
            show={showDeleteConfirmationModal}
            close={closeDeleteConfirmationModal}
            onConfirm={deleteContract}
            deleteMode
          />
        )}
        {showCreateUpdateModal && (
          <CreateUpdateContractModal
            contract={contract}
            show={showCreateUpdateModal}
            close={closeCreateUpdateModal}
          />
        )}
      </ViewBase>
    </ErrorBoundary>
  )
})
