import React from 'react'
import { observer } from 'mobx-react-lite'
import { FormControl } from '../../common/FormControl'
import { NotificationSettingsSwitch } from './NotificationSettingsSwitch'
import { theme } from '../../../theme/theme'

interface NotificationSettingsRowProps {
  label: string
  name: string
  value: { mobile?: boolean | undefined; email?: boolean | undefined }
  onChange: (value: {
    mobile?: boolean | undefined
    email?: boolean | undefined
  }) => void
  divider?: boolean
  disableMobile?: boolean
}

export const NotificationSettingsRow = observer(
  ({
    label,
    name,
    value,
    onChange,
    divider,
    disableMobile,
  }: NotificationSettingsRowProps): JSX.Element => {
    const formControlLabelStyle = theme.textVariants.base
    const formControlLabelClassName = 'mb-6'
    const formControlDividerClasses = 'border-1 mb-2 border-b pb-6'

    return (
      <FormControl
        label={label}
        name={name}
        labelStyle={formControlLabelStyle}
        labelClassName={formControlLabelClassName}
        className={divider ? formControlDividerClasses : ''}
      >
        <NotificationSettingsSwitch
          value={value}
          onChange={onChange}
          name={name}
          disableMobile={disableMobile}
        />
      </FormControl>
    )
  }
)
