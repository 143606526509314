import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { Modal } from '../../../common/Modal'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useStores } from '../../../../hooks/useStores'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { CreateUpdateAdminForm } from '../CreateUpdateAdminForm'
import { societyAdminCreateSchema } from '../../../../forms/schemas/society_admin_create'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'

interface CreateUpdateAdminModalProps {
  show: boolean
  close: () => void
  children?: React.ReactNode
}

export const CreateUpdateAdminModal = observer(
  ({ children, show, close }: CreateUpdateAdminModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { societyStore, unitStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()

    useEffect(() => {
      unitStore.getUnitsPerSociety(society._id as string)
    }, [unitStore, society._id])

    const showErrorMessage = (isPlural: boolean): void => {
      const message = isPlural
        ? translate('createUpdateAdminModal.flashMessage.adminAddFailurePlural')
        : translate(
            'createUpdateAdminModal.flashMessage.adminAddFailureSingular'
          )
      setToastNotification(ToastType.DANGER, message)
    }

    const showSuccessMessage = (isPlural: boolean): void => {
      const message = isPlural
        ? translate('createUpdateAdminModal.flashMessage.adminAddSuccessPlural')
        : translate(
            'createUpdateAdminModal.flashMessage.adminAddSuccessSingular'
          )
      setToastNotification(ToastType.SUCCESS, message)
    }

    const onSubmit = async (
      data: InferType<typeof societyAdminCreateSchema>
    ): Promise<void> => {
      const selectedUserIds = data.userIds
        .map((_userId) => _userId)
        .filter((_userId) => _userId !== undefined) as string[]
      const status = await societyStore.addAdminUsers(
        society._id,
        selectedUserIds
      )
      if (status) {
        showSuccessMessage(selectedUserIds.length > 1)
        close()
      } else {
        showErrorMessage(selectedUserIds.length > 1)
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const loading =
      societyStore.fetchingSociety === 'pending' ||
      unitStore.fetchingUnits === 'pending' ||
      societyStore.updatingAdminUsers === 'pending'

    return (
      <>
        {children}
        <Modal show={show} size="md" minHeight={400}>
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate('createUpdateAdminModal.addNewAdminTitle')}
              </ModalHeader>
            ),
            body: (
              <ModalBody className="justify-between">
                <CreateUpdateAdminForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
