import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import Compressor from 'compressorjs'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useChatRoomAvatar } from '../../../hooks/useChatRoomAvatar'
import { useChatRoomTitle } from '../../../hooks/useChatRoomTitle'
import { theme } from '../../../theme/theme'
import { ChatRoomModel } from '../../../state/models/chat-room'
import { useStores } from '../../../hooks/useStores'
import { InformationTable } from '../../common/InformationTable'
import { useModal } from '../../../hooks/useModal'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'
import { ConfirmationModal } from '../../common/ConfirmationModal'
import { ShowGroupMembersModal } from '../ShowGroupMembersModal/ShowGroupMembersModal'
import { useInfoRows } from './useInfoRows'
import { IconChoices } from '../../common/Icon'
import { Button } from '../../common/Button'
import { useHamburger } from '../../../hooks/useHamburgerContext'
import { useIsUserAdminOfGroupChat } from '../../../hooks/useIsUserAdminOfGroupChat'
import { Divider } from '../../common/Divider'
import { convertBase64 } from '../../../api/helpers'
import { isImageType } from '../../../helpers/types'

interface ChatSettingsProps {
  chatRoom: SnapshotOut<typeof ChatRoomModel>
}

export const ChatSettings = observer(
  ({ chatRoom }: ChatSettingsProps): JSX.Element => {
    const { chatRoomStore } = useStores()
    const { translate } = useAppTranslation()
    const { isHamburgerMode } = useHamburger()
    const chatRoomTitle = useChatRoomTitle(chatRoom._id)
    const chatRoomAvatar = useChatRoomAvatar(chatRoom._id, 60)
    const isGroupchat = chatRoom.type === 'interests'
    const { isUserAdminOfGroupChat } = useIsUserAdminOfGroupChat(chatRoom)
    const { setToastNotification } = useToastNotifications()
    const {
      show: showArchiveModal,
      open: openArchiveModal,
      close: closeArchiveModal,
    } = useModal()
    const {
      show: showMembersModal,
      open: openMembersModal,
      close: closeMembersModal,
    } = useModal()
    const {
      show: showRemoveChatModal,
      open: openRemoveChatModal,
      close: closeRemoveChatModal,
    } = useModal()
    const {
      show: showLeaveChatModal,
      open: openLeaveChatModal,
      close: closeLeaveChatModal,
    } = useModal()

    const { informationRows, actionRows } = useInfoRows({
      chatRoom,
      openArchiveModal,
      openMembersModal,
      openRemoveChatModal,
      openLeaveChatModal,
    })

    const archiveEvent = async (): Promise<void> => {
      const status = await chatRoomStore.toggleHideUntilNotification(
        chatRoom._id,
        true
      )
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('chatSettings.flashMessage.archiveChatRoomSuccess')
        )
        chatRoomStore.setSelectedChatRoom(undefined)
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('chatSettings.flashMessage.archiveChatRoomFailure')
        )
      }
    }

    const removeChatEvent = async (): Promise<void> => {
      const status = await chatRoomStore.removeRoom(chatRoom._id)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('chatSettings.flashMessage.deleteChatRoomSuccess')
        )
        chatRoomStore.setSelectedChatRoom(undefined)
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('chatSettings.flashMessage.deleteChatRoomFailure')
        )
      }
    }

    const leaveChatEvent = async (): Promise<void> => {
      const status = await chatRoomStore.leaveRoom(chatRoom._id)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('chatSettings.flashMessage.leaveChatRoomSuccess')
        )
        chatRoomStore.setSelectedChatRoom(undefined)
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('chatSettings.flashMessage.leaveChatRoomFailure')
        )
      }
    }

    const onUploadImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (!e.target.files) {
        return
      }
      const images = Array.from(e.target.files)
      if (images.length === 0) {
        return
      }
      const image = images[0]
      if (!isImageType(image.type)) {
        setToastNotification(
          ToastType.DANGER,
          translate('uploadImage.invalidImageType')
        )
        return
      }
      // eslint-disable-next-line no-new
      new Compressor(image, {
        quality: 0.8,
        success: async (result) => {
          const base64 = await convertBase64(result)
          await chatRoomStore.updateChatRoomAvatar(
            chatRoom._id,
            'image',
            base64 as string
          )
        },
      })
    }

    return (
      <div
        className={`
          ${
            isHamburgerMode
              ? 'h-56 w-full flex-none overflow-y-scroll pb-2'
              : 'w-sidebar'
          }
        `}
      >
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-6 px-6 pt-8">
            {isGroupchat && (
              <p style={theme.textVariants.lead}>
                {translate('chatSettings.tables.titles.groupChatInfo')}
              </p>
            )}
            <div className="flex flex-row items-center gap-5">
              {!isGroupchat && chatRoomAvatar}
              {isGroupchat && (
                <label
                  htmlFor="chat-avatar-image-input"
                  className={`cursor-pointer rounded-full p-1
                    ${isUserAdminOfGroupChat ? 'hover:bg-neutral-90' : ''}
                  `}
                >
                  {chatRoomAvatar}
                  <input
                    id="chat-avatar-image-input"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    className="hidden cursor-pointer"
                    onChange={onUploadImage}
                    disabled={!isUserAdminOfGroupChat}
                  />
                </label>
              )}
              <p className="text-center" style={theme.textVariants.lead}>
                {chatRoomTitle}
              </p>
            </div>
            {isGroupchat && isUserAdminOfGroupChat && (
              <div className="flex flex-col items-center gap-2">
                <Button
                  type="button"
                  label={translate('chatSettings.tables.titles.addMembers')}
                  wrapperClassName="w-full"
                  className="h-11 w-full"
                  size="no-padding"
                  iconSize={18}
                  icon={IconChoices.PERSON_ADD}
                  onClick={openMembersModal}
                />
              </div>
            )}
          </div>
          <div className="mx-3 flex flex-col">
            <InformationTable className="px-3" rows={informationRows} />
          </div>
          {actionRows.length > 0 && (
            <>
              <Divider className="mx-6" />
              <div className="mx-3 mt-3 flex flex-col">
                <p className="px-3 py-1" style={theme.textVariants.lead}>
                  {translate('chatSettings.tables.titles.actions')}
                </p>
                <InformationTable className="px-3" rows={actionRows} />
              </div>
            </>
          )}
        </div>
        <ShowGroupMembersModal
          chatRoom={chatRoom}
          show={showMembersModal}
          close={closeMembersModal}
        />
        <ConfirmationModal
          title={translate('chatSettings.alert.archiveChatRoomTitle')}
          description={translate('chatSettings.alert.archiveChatRoomText')}
          show={showArchiveModal}
          close={closeArchiveModal}
          onConfirm={archiveEvent}
          confirmationButtonLabel={translate('chatSettings.form.archive')}
        />
        <ConfirmationModal
          title={translate('chatSettings.alert.chatRoomDeleteTitle')}
          description={translate('chatSettings.alert.chatRoomDeleteText')}
          show={showRemoveChatModal}
          close={closeRemoveChatModal}
          onConfirm={removeChatEvent}
        />
        <ConfirmationModal
          title={translate('chatSettings.alert.leaveChatRoomTitle')}
          description={translate('chatSettings.alert.leaveChatRoomText')}
          show={showLeaveChatModal}
          close={closeLeaveChatModal}
          onConfirm={leaveChatEvent}
          confirmationButtonLabel={translate('common.actions.leave')}
        />
      </div>
    )
  }
)
