import React, { KeyboardEvent } from 'react'
import { theme } from '../../../../theme/theme'
import { Icon, IconChoices } from '../../../common/Icon'
import { ThumbnailImageIcon } from './ThumbnailImageIcon'

interface ThumbnailProps {
  id: string
  icon?: IconChoices
  uri?: string
  title: string
  description: string
  index: number
  onThumbnailRemove: (id: string) => void
  onThumbnailClick?: (id: string) => void
  loading?: boolean
}

export const Thumbnail = ({
  id,
  icon = IconChoices.DOCUMENT,
  uri,
  title,
  description,
  index,
  onThumbnailRemove,
  onThumbnailClick,
  loading,
}: ThumbnailProps): JSX.Element => {
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter' && onThumbnailClick) {
      onThumbnailClick(id)
    }
  }

  const onClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.stopPropagation()
    onThumbnailRemove(id)
  }

  const _onThumbnailClick = (): void => {
    onThumbnailClick && onThumbnailClick(id)
  }

  const additionalProps = {
    ...(onThumbnailClick && { onClick: _onThumbnailClick }),
    ...(onThumbnailClick && { role: 'button' }),
    ...(onThumbnailClick && { tabIndex: index }),
    ...(onThumbnailClick && { onKeyDown: handleKeyDown }),
  }

  return (
    <div
      className="relative flex gap-2 rounded border p-2"
      {...additionalProps}
    >
      <div className="flex h-14 w-14 items-center justify-center">
        <ThumbnailImageIcon icon={icon} uri={uri} loading={loading} />
      </div>
      <div className="flex flex w-32 flex-col justify-center">
        <p
          style={theme.textVariants.baseBold}
          className="overflow-hidden text-ellipsis whitespace-nowrap"
        >
          {title}
        </p>
        <p style={theme.textVariants.base} className="text-neutral-50">
          {description}
        </p>
      </div>
      <button className="flex" onClick={onClick}>
        <Icon
          className="rounded-2xl hover:bg-gray-light"
          icon={IconChoices.CLOSE_CROSS}
        />
      </button>
    </div>
  )
}
