import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Modal } from '../../../../../components/common/Modal'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { ModalHeader } from '../../../../../components/common/Modal/ModalHeader'
import { ModalBody } from '../../../../../components/common/Modal/ModalBody'
import { ChangeUserEmailModalContent } from './ChangeUserEmailModalContent'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../../components/common/Toast/toast-type'
import { useStores } from '../../../../../hooks/useStores'
import { Spinner } from '../../../../../components/common/Spinner'

interface ChangeUserEmailModalProps {
  show: boolean
  close: () => void
  resetSearchResult: () => void
  userId: string
}

export const ChangeUserEmailModal = observer(
  ({
    show,
    close,
    userId,
    resetSearchResult,
  }: ChangeUserEmailModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { adminStore, userStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const [updatingEmail, setUpdatingEmail] = useState(false)

    const onSubmit = async (newUserEmail: string): Promise<void> => {
      setUpdatingEmail(true)
      const status = await adminStore.changeUserEmail({ userId, newUserEmail })
      if (status) {
        resetSearchResult()
        close()
        setToastNotification(ToastType.SUCCESS, 'Mejladress uppdaterad')
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }
      setUpdatingEmail(false)
    }

    useEffect(() => {
      userStore.getUser(userId)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Modal show={show}>
        {{
          header: (
            <ModalHeader onClose={close}>Uppdatera mejladress</ModalHeader>
          ),
          body: (
            <ModalBody className="overflow-y-auto">
              {updatingEmail ? (
                <Spinner />
              ) : (
                <ChangeUserEmailModalContent
                  onSubmit={onSubmit}
                  onClose={close}
                  userId={userId}
                />
              )}
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
