/* eslint-disable react/no-unused-prop-types */
import React, { KeyboardEvent, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../hooks/useStores'
import { MediaModel } from '../../../state/models/media'
import { theme } from '../../../theme/theme'
import { UserModel } from '../../../state/models/user'
import { SocietyModel } from '../../../state/models/society'
import { getUserInitials } from '../../../helpers/user'
import { AvatarImage } from './AvatarImage'

interface AvatarBaseProps {
  onClick?: () => void
  size?: number
  alt?: string
  className?: string
  avatarClassName?: string
  initials?: string
  placeholder?: 'avatar' | 'society' | 'booking' | 'interests'
}
interface AvatarInnerProps extends AvatarBaseProps {
  media: SnapshotOut<typeof MediaModel> | undefined
}

export const AvatarInner = observer(
  ({
    media,
    alt = 'avatar',
    size = 40,
    onClick,
    className,
    avatarClassName,
    initials,
    placeholder = 'avatar',
  }: AvatarInnerProps): JSX.Element => {
    const handleKeyDown = (e: KeyboardEvent<HTMLImageElement>): void => {
      if (e.key === 'Enter' && onClick) {
        onClick()
      }
    }

    const getBackgroundColor = (): string => {
      switch (placeholder) {
        case 'interests':
          return 'transparent'

        case 'avatar':
          if (!initials) {
            return 'white'
          }
          return theme.colors.almostBrandGreen

        default:
          return theme.colors.almostBrandGreen
      }
    }

    return (
      <div
        className={`flex shrink-0 items-center justify-center rounded-full text-white
        ${onClick ? 'cursor-pointer hover:brightness-90' : 'cursor-default'}
        ${className ?? ''}
      `}
        style={{
          backgroundColor: getBackgroundColor(),
          height: size,
          width: size,
          maxWidth: size,
        }}
        role="button"
        onClick={onClick}
        tabIndex={onClick ? 0 : -1}
        onKeyDown={handleKeyDown}
      >
        <AvatarImage
          alt={alt}
          url={media?.url}
          initials={initials}
          placeholder={placeholder}
          size={size}
          avatarClassName={avatarClassName}
        />
      </div>
    )
  }
)

interface AvatarProps extends AvatarBaseProps {
  mediaId: string | null | undefined
}

export const Avatar = observer(
  ({
    mediaId,
    alt,
    onClick,
    size,
    className,
    avatarClassName,
    initials,
    placeholder = 'avatar',
  }: AvatarProps): JSX.Element | null => {
    const { mediaStore } = useStores()
    const media = mediaId ? mediaStore.media.get(mediaId) : undefined

    return (
      <AvatarInner
        media={media}
        alt={alt}
        onClick={onClick}
        size={size}
        className={className}
        avatarClassName={avatarClassName}
        initials={initials}
        placeholder={placeholder}
      />
    )
  }
)

interface UserAvatarProps extends AvatarBaseProps {
  user?: SnapshotOut<typeof UserModel>
  avatarClassName?: string
}

export const UserAvatar = observer(
  ({
    user,
    onClick,
    size,
    avatarClassName,
    className,
  }: UserAvatarProps): JSX.Element => {
    const initials = useMemo(() => {
      return getUserInitials(user)
    }, [user])
    return (
      <Avatar
        alt={`${user?.fullName} avatar`}
        mediaId={user?.avatarId}
        onClick={onClick}
        size={size}
        className={className}
        avatarClassName={avatarClassName}
        initials={initials}
      />
    )
  }
)

interface SocietyAvatarProps extends AvatarBaseProps {
  society?: SnapshotOut<typeof SocietyModel> | null
}

export const SocietyAvatar = observer(
  ({ society, onClick, size }: SocietyAvatarProps): JSX.Element => {
    return (
      <Avatar
        placeholder="society"
        mediaId={society?.coverPhotoId}
        onClick={onClick}
        size={size}
      />
    )
  }
)

interface SocietyAvatarWithMediaIdProps extends AvatarBaseProps {
  mediaId?: string | null
}

export const SocietyAvatarWithMediaId = observer(
  ({ mediaId, onClick, size }: SocietyAvatarWithMediaIdProps): JSX.Element => {
    return (
      <Avatar
        placeholder="society"
        mediaId={mediaId}
        onClick={onClick}
        size={size}
      />
    )
  }
)

interface NestedAvatarProps extends AvatarBaseProps {
  primaryAvatar: JSX.Element
  secondaryAvatar?: JSX.Element
  avatarSize: 'sm' | 'md'
}

export const NestedAvatar = observer(
  ({
    primaryAvatar,
    secondaryAvatar,
    onClick,
    avatarSize,
  }: NestedAvatarProps): JSX.Element => {
    const handleKeyDown = (e: KeyboardEvent<HTMLImageElement>): void => {
      if (e.key === 'Enter' && onClick) {
        onClick()
      }
    }

    return (
      <div
        className={`relative ${
          onClick ? 'cursor-pointer hover:brightness-90' : ''
        }`}
        role="presentation"
        onClick={onClick}
        onKeyDown={handleKeyDown}
      >
        {primaryAvatar}
        <div
          className={`absolute rounded-full border border-white
            ${avatarSize === 'md' ? 'top-5 left-4' : 'top-3 left-3'}`}
          style={{ borderColor: theme.colors.brandLightGreen }}
        >
          {secondaryAvatar}
        </div>
      </div>
    )
  }
)

interface SocietyUserNestedAvatarProps extends AvatarBaseProps {
  user?: SnapshotOut<typeof UserModel>
  society?: SnapshotOut<typeof SocietyModel>
  inverted?: boolean
  avatarSize: 'sm' | 'md'
}

export const SocietyUserNestedAvatar = observer(
  ({
    user,
    society,
    onClick,
    inverted,
    avatarSize,
  }: SocietyUserNestedAvatarProps): JSX.Element => {
    const primaryAvatarSize = avatarSize === 'md' ? 40 : 30
    const secondaryAvatarSize = avatarSize === 'md' ? 20 : 18
    const societyAvatar = (
      <SocietyAvatar
        society={society}
        size={inverted ? secondaryAvatarSize : primaryAvatarSize}
      />
    )
    const userAvatar = (
      <UserAvatar
        user={user}
        size={inverted ? primaryAvatarSize : secondaryAvatarSize}
      />
    )

    return (
      <NestedAvatar
        primaryAvatar={inverted ? userAvatar : societyAvatar}
        secondaryAvatar={inverted ? societyAvatar : userAvatar}
        avatarSize={avatarSize}
        onClick={onClick}
      />
    )
  }
)
