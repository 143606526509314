import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import React from 'react'
import { useIsMobile } from '../../../../../hooks/useIsMobile'
import { useStores } from '../../../../../hooks/useStores'
import { useUnitsString } from '../../../../../hooks/useUnitsString'
import { MediaModel } from '../../../../../state/models/media'
import {
  PreApprovedModel,
  SocietyModel,
} from '../../../../../state/models/society'
import { theme } from '../../../../../theme/theme'
import { Icon, IconChoices } from '../../../../common/Icon'
import {
  Illustration,
  IllustrationChoices,
} from '../../../../common/Illustration'

interface SocietyInviteModalContentProps {
  society: SnapshotOut<typeof SocietyModel> | undefined
  preApproved: SnapshotOut<typeof PreApprovedModel>
}

export const SocietyInviteModalContent = observer(
  ({ society, preApproved }: SocietyInviteModalContentProps): JSX.Element => {
    const { mediaStore, unitStore } = useStores()
    const isMobile = useIsMobile()

    const societyCoverPhoto =
      (society?.coverPhotoId &&
        (mediaStore.media.get(society.coverPhotoId) as SnapshotOut<
          typeof MediaModel
        >)) ||
      undefined

    const unit =
      preApproved && preApproved.unitId
        ? unitStore.units.get(preApproved.unitId)
        : undefined

    const unitString = useUnitsString(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [unit].filter((_unit) => _unit !== undefined)
    )

    const rows: { icon: IconChoices; text: string | undefined }[] = [
      {
        icon: IconChoices.HOME,
        text: society?.name,
      },
      {
        icon: IconChoices.HOUSEHOLD,
        text: unitString,
      },
    ]

    const illustrationWidth = isMobile ? 305 : 710

    return (
      <div className="h-full w-full">
        {societyCoverPhoto?.url ? (
          <img
            style={{ width: illustrationWidth }}
            src={societyCoverPhoto?.url}
            alt="society banner"
          />
        ) : (
          <Illustration
            width={illustrationWidth}
            illustrationChoice={IllustrationChoices.SOCIETY_PLACEHOLDER}
          />
        )}
        <div className="mt-4 flex flex-col">
          {rows.map((_row) => (
            <div key={_row.text} className="flex items-end space-y-2">
              <Icon icon={_row.icon} />
              <p style={theme.textVariants.base} className="ml-2">
                {_row.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    )
  }
)
