import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { CalendarListItem } from './CalendarListItem'
import { EventModel } from '../../../state/models/event'
import { SpinnerWrapper } from '../../common/SpinnerWrapper'
import { ErrorView } from '../../../views/error/ErrorView'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { IllustrationChoices } from '../../common/Illustration'
import { BoardRoomEventModel } from '../../../state/models/boardroom-event'

interface CalendarListProps {
  events:
    | SnapshotOut<typeof EventModel>[]
    | SnapshotOut<typeof BoardRoomEventModel>[]
  loading?: boolean
  userIsAdmin?: boolean
  boardRoomEvent?: boolean
}

export const CalendarList = observer(
  ({
    events,
    loading,
    userIsAdmin,
    boardRoomEvent,
  }: CalendarListProps): JSX.Element => {
    const { translate } = useAppTranslation()
    if (loading) {
      return <SpinnerWrapper />
    }

    if (!loading && events.length === 0) {
      return (
        <ErrorView
          title={translate('societyCalendarListView.noCalendarEvents.title')}
          subtitle={translate(
            'societyCalendarListView.noCalendarEvents.subtitle'
          )}
          illustration={IllustrationChoices.CHECKLIST}
          className="mt-4 md:mt-8"
        />
      )
    }

    return (
      <div className="w-full space-y-4">
        {events.map((_event) => (
          <CalendarListItem
            key={_event._id}
            event={_event}
            displayEdit={userIsAdmin}
            boardRoomEvent={boardRoomEvent}
          />
        ))}
      </div>
    )
  }
)
