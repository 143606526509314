import { AxiosResponse } from 'axios'
import { NUsers } from '../interfaces/services/users.interfaces'
import { createUrl } from './helpers'
import axiosAuth from './config'

export const USER_POPULATE = ['media']

export const getUser = (
  id: string
): Promise<AxiosResponse<NUsers.NGetById.IResponse>> => {
  return axiosAuth.get<NUsers.NGetById.IResponse>(
    createUrl(`/users/user/${id}`, {
      populate: USER_POPULATE.join(';'),
    })
  )
}

export const createUser = (
  body: NUsers.NCreate.IRequestBody,
  sendLink: boolean
): Promise<AxiosResponse<NUsers.NCreate.IResponse>> => {
  return axiosAuth.post<NUsers.NCreate.IResponse>(
    createUrl(`/users/user`, {
      sendLink,
    }),
    body
  )
}

export const patchUser = (
  id: string,
  body: NUsers.NPatch.IRequestBody,
  parameters?: {
    [key: string]: string
  }
): Promise<AxiosResponse<NUsers.NPatch.IResponse>> => {
  return axiosAuth.patch<NUsers.NPatch.IResponse>(
    createUrl(`/users/user/${id}`, {
      populate: USER_POPULATE.join(';'),
      ...parameters,
    }),
    body
  )
}

export const deleteUser = (
  id: string,
  body: NUsers.NDelete.IRequestBody
): Promise<AxiosResponse<NUsers.NDelete.IResponse>> => {
  return axiosAuth.delete<NUsers.NDelete.IResponse>(
    createUrl(`/users/user/${id}`),
    {
      data: body,
    }
  )
}

export const updateEmail = (
  email: string,
  password: string
): Promise<AxiosResponse<NUsers.NUpdateEmail.IResponse>> => {
  const body: NUsers.NUpdateEmail.IRequestBody = {
    newEmail: email,
    password,
  }
  return axiosAuth.post<NUsers.NUpdateEmail.IResponse>(
    createUrl(`/users/update-email`),
    body
  )
}

export const updatePassword = (
  newPassword: string,
  oldPassword: string
): Promise<AxiosResponse<NUsers.NUpdatePassword.IResponse>> => {
  const body: NUsers.NUpdatePassword.IRequestBody = {
    newPassword,
    oldPassword,
  }
  return axiosAuth.post<NUsers.NUpdatePassword.IResponse>(
    createUrl(`/users/update-password`),
    body
  )
}

export const isEmailRegistered = (
  email: string
): Promise<AxiosResponse<NUsers.NIsEmailRegistered.IResponse>> => {
  const body: NUsers.NIsEmailRegistered.IRequestBody = {
    email,
  }
  return axiosAuth.post<NUsers.NIsEmailRegistered.IResponse>(
    createUrl(`/users/is-email-registered`),
    body
  )
}

export const sendResetPassword = (
  email: string
): Promise<AxiosResponse<NUsers.NSendResetPassword.IResponse>> => {
  const body: NUsers.NSendResetPassword.IRequestBody = {
    email,
    sendLink: true,
  }
  return axiosAuth.post<NUsers.NSendResetPassword.IResponse>(
    createUrl(`/users/send-reset-password`),
    body
  )
}

export const resetPassword = (
  code: string,
  password: string
): Promise<AxiosResponse<NUsers.NResetPassword.IResponse>> => {
  const body: NUsers.NResetPassword.IRequestBody = {
    code,
    password,
  }
  return axiosAuth.post<NUsers.NResetPassword.IResponse>(
    createUrl(`/users/reset-password`),
    body
  )
}

export const resendVerifyCode = (
  email: string
): Promise<AxiosResponse<NUsers.NResendVerifyCode.IResponse>> => {
  const body: NUsers.NResendVerifyCode.IRequestBody = {
    email,
    sendLink: true,
  }
  return axiosAuth.post<NUsers.NResendVerifyCode.IResponse>(
    createUrl(`/users/resend-verify-code`),
    body
  )
}

export const createUserDevice = (
  userId: string,
  body: NUsers.NAddUserDevice.IRequestBody
): Promise<AxiosResponse<NUsers.NAddUserDevice.IResponse>> => {
  return axiosAuth.post<NUsers.NAddUserDevice.IResponse>(
    createUrl(`/users/user/${userId}/devices`),
    body
  )
}

export const verifyUser = (
  body: NUsers.NVerifyUser.IRequestBody
): Promise<AxiosResponse<NUsers.NVerifyUser.IResponse>> => {
  return axiosAuth.post<NUsers.NVerifyUser.IResponse>(
    createUrl(`/users/verify-user`),
    body
  )
}
