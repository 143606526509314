import { AxiosResponse } from 'axios'
import { NGroups } from '../interfaces/services/groups.interfaces'
import { createUrl } from './helpers'
import axiosAuth from './config'

export const getGroupsPerSociety = (
  societyId: string
): Promise<AxiosResponse<NGroups.NGetPerSocieties.IResponse>> => {
  const body: NGroups.NGetPerSocieties.IRequestBody = {
    societyIds: [societyId],
  }
  return axiosAuth.post<NGroups.NGetPerSocieties.IResponse>(
    createUrl('/groups/get-per-societies/'),
    body
  )
}

export const createGroup = (
  body: NGroups.NCreate.IRequestBody
): Promise<AxiosResponse<NGroups.NCreate.IResponse>> => {
  return axiosAuth.post<NGroups.NCreate.IResponse>(
    createUrl(`/groups/group`),
    body
  )
}

export const getGroup = (
  id: string
): Promise<AxiosResponse<NGroups.NGetById.IResponse>> => {
  return axiosAuth.get<NGroups.NGetById.IResponse>(
    createUrl(`/groups/group/${id}`)
  )
}

export const deleteGroup = (
  id: string
): Promise<AxiosResponse<NGroups.NDel.IResponse>> => {
  return axiosAuth.delete<NGroups.NDel.IResponse>(
    createUrl(`/groups/group/${id}`)
  )
}

export const patchGroup = (
  id: string,
  body: NGroups.NPatch.IRequestBody
): Promise<AxiosResponse<NGroups.NPatch.IResponse>> => {
  return axiosAuth.patch<NGroups.NPatch.IResponse>(
    createUrl(`/groups/group/${id}`),
    body
  )
}
