import React from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../../common/Icon'
import { useStores } from '../../../hooks/useStores'
import { SocietyModel } from '../../../state/models/society'
import { useAppTranslation } from '../../../hooks/useAppTranslation'

interface BlockedCalloutProps {
  selectedSocietyId?: string
  className?: string
}

export const BlockedCallout = observer(
  ({
    selectedSocietyId,
    className,
  }: BlockedCalloutProps): JSX.Element | null => {
    const { societyStore } = useStores()
    const { translate } = useAppTranslation()

    const { userIsBlockedSocietyIds } = societyStore

    if (userIsBlockedSocietyIds.length === 0) {
      return null
    }

    const blockedSocieties = userIsBlockedSocietyIds
      .map((societyId) => societyStore.societies.get(societyId))
      .filter((society) => society) as Instance<typeof SocietyModel>[]

    const filteredBlockedSocieties = !selectedSocietyId
      ? blockedSocieties
      : blockedSocieties.filter((society) => society._id === selectedSocietyId)

    if (filteredBlockedSocieties.length === 0) {
      return null
    }

    const societyNames = filteredBlockedSocieties
      .map((society) => society.name)
      .join(', ')

    return (
      <div
        className={`flex items-center rounded bg-yellow p-4 ${className}`}
        role="alert"
      >
        <div>
          <Icon icon={IconChoices.INFORMATION} size={30} />
        </div>
        <p style={theme.textVariants.base} className="ml-3 text-black">
          {translate('blockedCallout.message', { societyNames })}
        </p>
      </div>
    )
  }
)
