import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import BoappaLogo from '../../../assets/icons/boappa.svg'
import { theme } from '../../../theme/theme'
import { RetrievePasswordForm } from './RetrievePasswordForm'
import { useDocumentTitle } from '../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../navigation/reverseDocumentTitle'
import { useStores } from '../../../hooks/useStores'
import { useQuery } from '../../../hooks/useQuery'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../../components/common/Toast/toast-type'
import {
  Illustration,
  IllustrationChoices,
} from '../../../components/common/Illustration'

export const RetrievePasswordView = (): JSX.Element => {
  const { translate } = useAppTranslation()
  useDocumentTitle(reverseDocumentTitle('reset-password'))
  const { setToastNotification } = useToastNotifications()
  const { userStore, authenticationStore } = useStores()
  const navigate = useNavigate()
  const query = useQuery()

  const token = query.get('token')
  if (!token) {
    setToastNotification(
      ToastType.DANGER,
      translate(
        translate(
          'authenticationRetrievePasswordView.flashMessage.invalidToken'
        )
      )
    )
    return <Navigate to="/not-found" replace />
  }

  const resetPassword = async (password: string): Promise<void> => {
    const response = await userStore.resetPassword(token, password)
    if (response) {
      setToastNotification(
        ToastType.SUCCESS,
        translate(
          'authenticationRetrievePasswordView.flashMessage.passwordResetSuccess'
        )
      )
      if (authenticationStore.isAuthenticated) {
        authenticationStore.logout()
      }
      navigate('/login')
    } else {
      setToastNotification(
        ToastType.DANGER,
        translate(
          'authenticationRetrievePasswordView.flashMessage.passwordResetFailure'
        )
      )
    }
  }

  return (
    <div className="flex h-full flex-col md:flex-row">
      <div className="mt-24 flex flex-1 justify-center overflow-y-auto md:mt-0 md:items-center">
        <div className="flex w-[90%] flex-col gap-4 md:w-[80%] xl:w-[60%]">
          <BoappaLogo
            className="absolute inset-x-8 w-16 md:w-40"
            alt="boappa logo"
          />
          <p style={theme.textVariants.h1}>
            {translate('authenticationResetPasswordView.title')}
          </p>
          <RetrievePasswordForm resetPassword={resetPassword} />
        </div>
      </div>
      <div
        style={{ backgroundColor: theme.colors.brandLightGreen }}
        className="flex hidden h-40 items-center justify-center md:relative md:h-full md:flex-1 lg:flex"
      >
        <Illustration
          illustrationChoice={IllustrationChoices.SUN}
          width={400}
        />
      </div>
    </div>
  )
}
