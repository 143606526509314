import React, { useCallback, useEffect } from 'react'
import { useAtom } from 'jotai'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { MenuItem } from '../../../common/MenuItem'
import { Accordion } from '../../../common/Accordion'
import { AccordionItem } from '../../../common/AccordionItem'
import { LinkSection } from '../LinkSection'
import {
  managementActiveAccordionItemStateAtom,
  residentActiveAccordionItemStateAtom,
  selectedSocietySidebarMenuItemStateAtom,
} from '../societySideBarSharedState'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'

interface SocietySideBarAccordionProps {
  links: LinkSection[]
  activeAccordionIndex: number | null
}

const SocietySideBarAccordion = observer(
  ({
    links,
    activeAccordionIndex,
  }: SocietySideBarAccordionProps): JSX.Element => {
    const location = useLocation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const societyId = society._id
    const [selectedMenuItem, setSelectedMenuItem] = useAtom(
      selectedSocietySidebarMenuItemStateAtom
    )
    const [, setManagementActiveAccordionIndex] = useAtom(
      managementActiveAccordionItemStateAtom
    )
    const [, setResidentActiveAccordionIndex] = useAtom(
      residentActiveAccordionItemStateAtom
    )

    const isCurrentlyInOnboarding = societyId
      ? location.pathname ===
        reverseUrl('society:onboarding', { id: societyId })
      : false

    const getRegisterMenuUrl = useCallback(
      (pathname: string): string => {
        if (societyId && pathname.includes('management/admin/register')) {
          return reverseUrl('management:register', { id: societyId })
        }
        return pathname
      },
      [societyId]
    )

    const getBillingMenuUrl = useCallback(
      (pathname: string): string => {
        if (societyId && pathname.includes('management/admin/billing')) {
          return reverseUrl('management:billing', { id: societyId })
        }
        return pathname
      },
      [societyId]
    )

    const getDocumentMenuUrl = useCallback(
      (pathname: string): string => {
        if (societyId && pathname.includes('board/documents')) {
          return reverseUrl('management:documents', { id: societyId })
        }
        if (pathname.includes('functions/documents')) {
          return reverseUrl('society:documents', { id: societyId })
        }
        return pathname
      },
      [societyId]
    )

    const getFacilityMenuUrl = useCallback(
      (pathname: string): string => {
        if (societyId && pathname.includes('functions/facilities')) {
          return reverseUrl('society:facilities', { id: societyId })
        }
        return pathname
      },
      [societyId]
    )

    useEffect(() => {
      if (isCurrentlyInOnboarding) {
        return
      }

      const activeLinkIndex = links.findIndex((link) =>
        link.sublinks.some(
          (sublink) =>
            sublink?.url.toLowerCase() === location.pathname.toLowerCase()
        )
      )
      if (activeLinkIndex !== -1) {
        if (location.pathname.includes('management/')) {
          setManagementActiveAccordionIndex(activeLinkIndex)
        } else if (location.pathname.includes('resident/')) {
          setResidentActiveAccordionIndex(activeLinkIndex)
        }
      }

      // Required to set the sidebar selection for nested menus
      setSelectedMenuItem(getRegisterMenuUrl(location.pathname))
      setSelectedMenuItem(getBillingMenuUrl(location.pathname))
      // Required to set the sidebar selection for sub documents
      setSelectedMenuItem(getDocumentMenuUrl(location.pathname))
      setSelectedMenuItem(getFacilityMenuUrl(location.pathname))
    }, [
      links,
      getDocumentMenuUrl,
      getRegisterMenuUrl,
      getBillingMenuUrl,
      getFacilityMenuUrl,
      isCurrentlyInOnboarding,
      location.pathname,
      setManagementActiveAccordionIndex,
      setResidentActiveAccordionIndex,
      setSelectedMenuItem,
    ])

    return (
      <>
        {societyId && (
          <Accordion activeItem={activeAccordionIndex}>
            {links.map((group, i) => (
              <AccordionItem
                className="h-12 px-2"
                key={group.id}
                index={i}
                disabled={isCurrentlyInOnboarding}
                alwaysOpen={group.alwaysOpen}
                rounded
              >
                {{
                  ...(group.title && { header: <div>{group.title}</div> }),
                  content: group.sublinks
                    .filter((_link) => {
                      if (!isCurrentlyInOnboarding) {
                        return true
                      }
                      if (
                        isCurrentlyInOnboarding &&
                        _link?.url === selectedMenuItem
                      ) {
                        return true
                      }
                      return false
                    })
                    .map((link): JSX.Element | null => {
                      if (!link) {
                        return null
                      }
                      const key = link.urlIsExternal ? link.widgetId : link.url
                      return (
                        <MenuItem
                          key={key}
                          title={link.title}
                          icon={link.icon}
                          url={link.url}
                          urlIsExternal={link.urlIsExternal}
                          trackingCallback={link.trackingCallback}
                          isSelected={selectedMenuItem?.includes(link.url)}
                          onSelect={() => setSelectedMenuItem(link.url)}
                          disabled={isCurrentlyInOnboarding || link.disabled}
                          newFeature={link.newFeature}
                        />
                      )
                    }),
                }}
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </>
    )
  }
)

SocietySideBarAccordion.displayName = 'SocietySidebarAccordion'
export { SocietySideBarAccordion }
