import React from 'react'

export interface Divider {
  className?: string
}

export const Divider = ({ className }: Divider): JSX.Element => {
  return (
    <div className={`border-1 border-b border-neutral-80 ${className ?? ''}`} />
  )
}
