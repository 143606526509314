import React from 'react'
import { RadioButton } from '../../common/RadioButton'

interface LanguageListItemProps {
  language: string
  flag: string
  locale: string
  setSelectedLocale: (locale: string) => void
  selected: boolean
  description?: string
}

export const LanguageListItem = ({
  language,
  flag,
  locale,
  setSelectedLocale,
  selected,
  description,
}: LanguageListItemProps): JSX.Element => {
  const onChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setSelectedLocale((e.currentTarget as HTMLInputElement).value)
  }
  return (
    <div>
      <RadioButton
        label={`${language} ${flag}`}
        description={description}
        value={locale}
        checked={selected}
        onChange={onChange}
        name={language}
      />
    </div>
  )
}
