/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { captureException } from '@sentry/react'
import { formatDateWithFormat } from '../../../../helpers/date'
import { TableColumn } from '../../../../components/common/Table/TableHeader'
import { TableRow } from '../../../../components/common/Table/TableRows'
import { Table } from '../../../../components/common/Table'
import { getPendingInvoices } from '../../../../api/admin'
import { IPendingInvoicesModel } from '../../../../interfaces/models/pending-invoices.interfaces'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { largeNumberFormattingWithSpaces } from '../../../../helpers/number'
import { Button, ButtonVariant } from '../../../../components/common/Button'
import { Dropdown } from '../../../../components/common/Dropdown'
import { IconChoices } from '../../../../components/common/Icon'
import { useModal } from '../../../../hooks/useModal'
import { EditSocietyInvoiceModal } from '../Societies/EditSocietyInvoiceModal'

export const PendingInvoices = observer((): JSX.Element | null => {
  const [loading, setLoading] = useState(false)
  const [pendingInvoices, setPendingInvoices] = useState<
    IPendingInvoicesModel[]
  >([])
  const { translate } = useAppTranslation()

  const _getPendingInvoices = async (): Promise<void> => {
    try {
      setLoading(true)
      const invoices = await getPendingInvoices()
      setPendingInvoices(invoices.data.data)
    } catch (err) {
      captureException(err)
    }
    setLoading(false)
  }
  const [currentSocietyId, setCurrentSocietyId] = useState<string>()
  const {
    show: showEditSocietyInvoiceModal,
    open: openEditSocietyInvoiceModal,
    close: closeEditSocietyInvoiceModal,
  } = useModal()

  useEffect(() => {
    _getPendingInvoices()
  }, [])

  const columns: TableColumn[] = [
    {
      key: 'actions',
      title: 'Åtgärder',
      accessor: '_id',
      renderItem: (_id: string) => {
        return (
          <Dropdown
            dropdownClassName="w-[300px]"
            className="w-fit"
            dropdownPosition="bottom-right"
            options={[
              {
                value: 'editSocietyInvoice',
                label: 'Redigera fakturering',
                icon: IconChoices.EDIT,
                onClick: () => {
                  setCurrentSocietyId(_id)
                  openEditSocietyInvoiceModal()
                },
              },
            ]}
          >
            <Button
              label="Åtgärder"
              iconPlacement="left"
              variant={ButtonVariant.PRIMARY}
            />
          </Dropdown>
        )
      },
    },
    { key: '_id', title: 'ID' },
    { key: 'name', title: 'Name' },
    { key: 'organisationNumber', title: 'Organisationsnummer' },
    { key: 'masterAdminUserId', title: 'Master Admin ID' },
    { key: 'societyTypeDisplayName', title: 'Föreningstyp' },
    { key: 'addressBillingStreet', title: 'Gatuadress' },
    { key: 'addressBillingCo', title: 'C/o' },
    { key: 'addressBillingZip', title: 'Postnummer' },
    { key: 'addressBillingCity', title: 'Postort' },
    { key: 'billingEmail', title: 'Fakturamejl' },
    { key: 'cost', title: 'Belopp' },
  ]

  const rows: TableRow[] = pendingInvoices.map((invoice) => ({
    key: invoice.society._id,
    data: {
      ...invoice,
      ...invoice.society,
      cost: `${invoice.cost} kr`,
      invoiceDate: invoice.invoiceDate
        ? formatDateWithFormat(new Date(invoice.invoiceDate), 'yyyy-MM-dd')
        : undefined,
    },
  }))

  const invoiceSumTotal = rows?.reduce((prev, curr) => {
    // data.cost format: 1234 kr
    const costCurrencyString: string = curr.data.cost
    const costString = costCurrencyString.split(' ')[0]
    const cost: number = +costString

    return prev + cost
  }, 0)

  return (
    <div className="h-full w-full space-y-4">
      {rows?.length > 0 && (
        <>
          <p>
            Antal fakturor: {rows.length}. Totalt belopp:{' '}
            {largeNumberFormattingWithSpaces(invoiceSumTotal)} kr
          </p>

          <Table columns={columns} rows={rows} />
        </>
      )}
      {!loading && pendingInvoices.length === 0 && (
        <p>{translate('adminView.searchResult.emptyResult')}</p>
      )}
      {showEditSocietyInvoiceModal && (
        <EditSocietyInvoiceModal
          show={showEditSocietyInvoiceModal}
          close={closeEditSocietyInvoiceModal}
          societyId={currentSocietyId}
        />
      )}
    </div>
  )
})
