import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { QuestionsAndAnswers } from '../../../../components/society/QuestionsAndAnswers'
import { useStores } from '../../../../hooks/useStores'
import { ViewBase } from '../../../../components/common/ViewBase'
// eslint-disable-next-line max-len
import { CreateUpdateQuestionsAndAnswersSectionModal } from '../../../../components/society/QuestionsAndAnswers/QuestionsAndAnswersSection/CreateUpdateQuestionsAndAnswersSectionModal'
import { useModal } from '../../../../hooks/useModal'
import { ButtonVariant } from '../../../../components/common/Button'
import { IconChoices } from '../../../../components/common/Icon'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { IllustrationChoices } from '../../../../components/common/Illustration'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'

export const QuestionsAndAnswersView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { faqsStore } = useStores()
  const userId = useAuthenticatedUserId()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  useDocumentTitle(
    reverseDocumentTitle('society:faq', {
      '{{ societyName }}': society.name,
    })
  )
  const {
    show: showCreateSectionModal,
    open: openCreateSectionModal,
    close: closeCreateSectionModal,
  } = useModal()

  const isAdmin = isUserAdminInSociety(society, userId as string)
  const sections = faqsStore.faqSectionsForSociety(society._id)

  useEffect(() => {
    if (society._id) faqsStore.getFaqsPerSociety(society._id)
  }, [faqsStore, society._id])

  const loading =
    faqsStore.fetchingFAQs === 'pending' || faqsStore.fetchingFAQs === 'none'

  const errorView =
    !loading && sections.length === 0
      ? {
          title: translate('questionsAndAnswersView.emptyState.title'),
          subtitle: translate('questionsAndAnswersView.emptyState.subtitle'),
          illustration: IllustrationChoices.CHECKLIST,
        }
      : undefined

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('questionsAndAnswersView.title')}
        buttons={[
          ...(isAdmin
            ? [
                {
                  label: translate(
                    'createUpdateQuestionsAndAnswers.addNewSectionTitle'
                  ),
                  variant: ButtonVariant.PRIMARY,
                  icon: IconChoices.PLUS_SIGN,
                  onClick: openCreateSectionModal,
                },
              ]
            : []),
        ]}
        loading={loading}
        errorView={errorView}
      >
        <QuestionsAndAnswers />
      </ViewBase>
      <CreateUpdateQuestionsAndAnswersSectionModal
        show={showCreateSectionModal}
        close={closeCreateSectionModal}
      />
    </ErrorBoundary>
  )
})
