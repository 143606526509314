import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Modal } from '../../../common/Modal'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { PaymentForm } from '../PaymentForm'
import { upgradeSociety } from '../../../../api/societies'
import { useStores } from '../../../../hooks/useStores'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useSocietyPremiumCost } from '../useSocietyPremiumCost'

interface PaymentModalProps {
  show: boolean
  close: () => void
  discountCodePartnerNameId: string | undefined
}

export const PaymentModal = observer(
  ({
    show,
    close,
    discountCodePartnerNameId,
  }: PaymentModalProps): JSX.Element | null => {
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const [upgradeError, setUpgradeError] = useState(false)
    const [upgrading, setUpgrading] = useState(false)
    const { societyStore, partnersStore } = useStores()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const ref = useRef(null)

    const { costSummary, fetchingCost } = useSocietyPremiumCost(
      society._id,
      discountCodePartnerNameId
    )

    if (!costSummary) {
      return null
    }

    const onSubmit = async (): Promise<void> => {
      setUpgrading(true)

      // If discount is is applied, activate via partner, if not
      // trigger "regular" update
      const status = discountCodePartnerNameId
        ? await partnersStore.activateSociety(
            society._id,
            discountCodePartnerNameId
          )
        : await upgradeSociety(society._id)

      if (status) {
        await societyStore.getSociety(society._id)
        setToastNotification(
          ToastType.SUCCESS,
          translate('invoiceView.flashMessage.upgradeSuccess')
        )
        close()
      } else {
        setUpgradeError(true)
      }
      setUpgrading(false)
    }

    return (
      <Modal ref={ref} show={show} size="xl">
        {{
          header: (
            <ModalHeader onClose={close}>
              {translate('invoiceView.modals.payment.title')}
            </ModalHeader>
          ),
          body: (
            <ModalBody className="overflow-y-auto">
              <PaymentForm
                onSubmit={onSubmit}
                onClose={close}
                loading={upgrading || fetchingCost}
                costSummary={costSummary}
                discountCodePartnerNameId={discountCodePartnerNameId}
                upgradeErrored={upgradeError}
              />
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
