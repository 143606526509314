import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useStores } from '../../../../hooks/useStores'
import { Section, SectionList } from '../../../common/SectionList'
import { UnitModel } from '../../../../state/models/unit'
import { Checkbox } from '../../../common/Checkbox'
import { theme } from '../../../../theme/theme'

export type UnitListItem = { unitId: string }

interface UnitListBaseProps {
  unitsList: UnitListItem[]
  setUnitsListValue: (val: UnitListItem[]) => void
}

interface UnitListItemProps extends UnitListBaseProps {
  unit: SnapshotOut<typeof UnitModel>
  unitsList: UnitListItem[]
  setUnitsListValue: (val: UnitListItem[]) => void
  selected: boolean
}

export const UnitListItem = observer(
  ({
    unit,
    unitsList,
    setUnitsListValue,
    selected,
  }: UnitListItemProps): JSX.Element => {
    const triggerSelect = (): void => {
      if (!selected) {
        setUnitsListValue([...unitsList, { unitId: unit._id }])
      } else {
        setUnitsListValue(
          unitsList.filter((_unit) => _unit.unitId !== unit._id)
        )
      }
    }
    return (
      <div className="border-1 flex w-full items-center justify-between border-b py-4">
        <button onClick={triggerSelect}>
          <p style={theme.textVariants.base}>
            {unit.titleLegal} {unit.title ? `(${unit.title})` : ''}
          </p>
        </button>
        <Checkbox
          name={unit._id}
          value={selected}
          type="checkbox"
          onChange={triggerSelect}
        />
      </div>
    )
  }
)

export const UnitListSection = observer(
  ({ unitsList, setUnitsListValue }: UnitListBaseProps): JSX.Element => {
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { unitStore } = useStores()

    const selectedUnitIdsMap = unitsList.reduce(
      (res: { [key: string]: boolean }, curr): { [key: string]: boolean } => {
        if (!(curr.unitId in res)) {
          res[curr.unitId] = true
        }
        return res
      },
      {}
    )

    const addressesUnits = society
      ? unitStore.sortedAssignedUnitsForSocietyPerAddress(society._id)
      : []

    const getSections = (): Section[] => {
      return addressesUnits.map((address) => ({
        title: address.address,
        item: (
          <div>
            {address.units.map((_unit) => (
              <UnitListItem
                key={_unit._id}
                unit={_unit}
                unitsList={unitsList}
                setUnitsListValue={setUnitsListValue}
                selected={selectedUnitIdsMap && _unit._id in selectedUnitIdsMap}
              />
            ))}
          </div>
        ),
      }))
    }

    return (
      <SectionList sections={getSections()} className="flex flex-col gap-4" />
    )
  }
)
