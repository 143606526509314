import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from './useStores'
import { useUnitsString } from './useUnitsString'
import { UserModel } from '../state/models/user'
import { SocietyModel } from '../state/models/society'

export const useUserUnitsString = (
  user: SnapshotOut<typeof UserModel> | undefined,
  society: SnapshotOut<typeof SocietyModel> | undefined
): string => {
  const { unitStore } = useStores()
  const userUnits = user && unitStore.userUnits(user._id, society?._id)
  const unitsString = useUnitsString(userUnits || [], false)

  return unitsString
}
