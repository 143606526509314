import * as yup from 'yup'

export const societyAboutSchema = yup
  .object({
    name: yup.string().required(),
    organisationNumber: yup.string().required(),
    billingUnits: yup.number().required(),
    addressCo: yup.string(),
    addressStreet: yup.string(),
    addressZip: yup.string(),
    addressCity: yup.string(),
    coverPhotoId: yup.string(),
  })
  .required()
