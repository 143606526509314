import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { Modal } from '../../../common/Modal'
import { ModalBody } from '../../../common/Modal/ModalBody'
import { ModalHeader } from '../../../common/Modal/ModalHeader'
import { ManageHouseholdModalContent } from './ManageHouseholdModalContent'
import { useStores } from '../../../../hooks/useStores'
import { Spinner } from '../../../common/Spinner'

interface ManageHouseholdModalProps {
  show: boolean
  close: () => void
}

export const ManageHouseholdModal = observer(
  ({ show, close }: ManageHouseholdModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const [loading, setLoading] = useState<boolean>(true)
    const { unitStore } = useStores()

    useEffect(() => {
      unitStore.getUserUnits().then(() => {
        setLoading(false)
      })
    }, [unitStore])

    return (
      <Modal show={show} size="lg">
        {{
          header: (
            <ModalHeader onClose={close}>
              {translate('manageHousehold.modalTitle')}
            </ModalHeader>
          ),
          body: (
            <ModalBody>
              {loading ? (
                <div className="flex items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <ManageHouseholdModalContent />
              )}
            </ModalBody>
          ),
        }}
      </Modal>
    )
  }
)
