import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useStores } from '../../hooks/useStores'
import { reverseUrl } from '../../navigation/reverseUrl'
import { SocietyErrorViewWrapper } from '../error/SocietyErrorViewWrapper'
import { useSocietyLoader } from '../../navigation/society/hooks/useSocietyLoader'

const SocietyView = observer((): JSX.Element => {
  const { societyStore } = useStores()
  const navigate = useNavigate()
  const { id } = useParams()
  const location = useLocation()
  useSocietyLoader(id)

  useEffect(() => {
    !id &&
      societyStore.selectedSociety?._id &&
      location.pathname === `/society` &&
      navigate(
        reverseUrl('society:detail', { id: societyStore.selectedSociety._id }),
        { replace: true }
      )
  }, [id, location.pathname, navigate, societyStore.selectedSociety])

  return (
    <SocietyErrorViewWrapper>
      <Outlet />
    </SocietyErrorViewWrapper>
  )
})

SocietyView.displayName = 'SocietyView'
export { SocietyView }
