/* eslint-disable max-len */
// GENERAL
export const BUG_REPORT_FORM_LINK = 'https://forms.gle/VLnoriTYEqVAR7JE6'
export const SUBMIT_FEEDBACK_FORM_LINK = 'https://forms.gle/UAcVuJu2H9rvghY67'
export const USER_AGREEMENT_LINK =
  'https://boappa.se/documents/anvandarvillkor.pdf'
export const PRIVACY_POLICY_LINK =
  'https://boappa.se/sidor/personuppgiftspolicy'
export const TERMS_OF_SERVICE_LINK =
  'https://boappa.se/documents/tjanstevillkor.pdf'
export const DATA_PROCESSING_AGREEMENT_LINK =
  'https://boappa.se/documents/personuppgiftbitradesavtal.pdf'

// Bostadsrätterna
export const BOSTADSRATTERNA_SCHOOL_COURSES_LINK =
  'https://www.bostadsratterna.se/bostadsrattsskolan'
export const BOSTADSRATTERNA_STATUTES_LINK =
  'https://www.bostadsratterna.se/medlemsformaner/monsterstadgar'
export const BOSTADSRATTERNA_FORMS_LINK =
  'https://www.bostadsratterna.se/medlemsformaner/blanketter'
export const BOSTADSRATTERNA_BENEFITS_LINK =
  'https://www.bostadsratterna.se/medlemsformaner'

// Samfälligheterna
export const SAMFALLIGHETERNA_SCHOOL_COURSES_LINK =
  'https://samfalligheterna.villaagarna.se/for-styrelsen/utbildningar/'
export const SAMFALLIGHETERNA_BENEFITS_LINK =
  'https://samfalligheterna.villaagarna.se/for-styrelsen/rabatter/'

// Kundo
export const KUNDO_SUPPORT_LINK = 'https://boappa.kb.kundo.se/'
export const KUNDO_PAYMENT_INSTRUCTIONS =
  'https://boappa.kb.kundo.se/guide/fakturering-av-boappa?category=priser-och-samarbeten'
