// eslint-disable-next-line
export const stringifyObject = (object: any): { [key: string]: string } => {
  return Object.keys({ ...object })
    .map((key) => ({
      [key]: JSON.stringify(object[key]),
    }))
    .reduce((result, current) => {
      return Object.assign(result, current)
    }, {})
}
