import * as yup from 'yup'

export const userInfoSchema = yup
  .object({
    name: yup.string().required(),
    surname: yup.string().required(),
    occupationCompany: yup.string(),
    occupationTitle: yup.string(),
    occupationIndustry: yup.string(),
    educationLevel: yup.string(),
    educationCountry: yup.string(),
    educationInstitute: yup.string(),
    educationField: yup.string(),
    educationDegree: yup.string(),
    presentation: yup.string(),
    interests: yup.array(yup.string()),
    birthDate: yup.date(),
    gender: yup.string(),
    mobile: yup.string(),
    mobileCountry: yup.string(),
  })
  .required()
