import React, { useCallback, useState } from 'react'
import Fuse from 'fuse.js'
import { observer } from 'mobx-react-lite'
import { v4 as uuid } from 'uuid'
import { SpinnerWrapper } from '../../common/SpinnerWrapper'
import { ErrorView } from '../../../views/error/ErrorView'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { IllustrationChoices } from '../../common/Illustration'
import { useStores } from '../../../hooks/useStores'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { AccordionSection } from '../../common/AccordionSection'
import { getUnitTitle } from './helpers'
import { RegisterUnitItem } from '../RegisterUnitItem'
import { SearchBar } from '../../common/SearchBar'

export const RegisterUnitList = observer((): JSX.Element => {
  const [searchString, setSearchString] = useState('')
  const { translate } = useAppTranslation()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const { societyStore, unitStore } = useStores()

  const loading = societyStore.fetchingSociety === 'pending'

  const addressesUnits = unitStore.sortedAssignedUnitsForSocietyPerAddress(
    society._id
  )

  const getFilteredAddressesUnits = useCallback(() => {
    const options = {
      threshold: 0.0,
      keys: ['title', 'titleLegal'],
    }

    return addressesUnits.map((addressUnit) => {
      const fuse = new Fuse(addressUnit.units, options)
      const filteredUnits =
        searchString === ''
          ? addressUnit.units
          : fuse.search(searchString).map((result) => result.item)

      return { ...addressUnit, units: filteredUnits }
    })
  }, [addressesUnits, searchString])

  const onSearchBarChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setSearchString(e.currentTarget.value)
  }

  const filteredAddressesUnits = getFilteredAddressesUnits()

  if (loading) {
    return <SpinnerWrapper />
  }

  if (!loading && addressesUnits.length === 0) {
    return (
      <ErrorView
        title={translate('common.emptyState.title')}
        subtitle={translate('common.emptyState.subtitle')}
        illustration={IllustrationChoices.EMPTY}
      />
    )
  }

  const sections: AccordionSection[] = filteredAddressesUnits.map((_item) => {
    return {
      title: _item.address,
      items: _item.units.map((unit) => ({
        id: uuid(),
        title: getUnitTitle(unit),
        showAlert: unit.residentsList.length === 0,
        content: <RegisterUnitItem unit={unit} enableDelete enableUpdate />,
      })),
    }
  })

  const nonEmptySections = sections.filter(
    (section) => section.items.length > 0
  )

  return (
    <div className="w-full space-y-10">
      <SearchBar
        className="w-full md:w-80"
        widthClassName="flex grow"
        value={searchString}
        onChange={onSearchBarChange}
      />
      {nonEmptySections.map((section) => (
        <AccordionSection key={section.title} {...section} />
      ))}
    </div>
  )
})
