import { SnapshotOut } from 'mobx-state-tree'
import { BoardMemberModel, SocietyModel } from '../../../state/models/society'

export const sortBoardMembersList = (
  society: SnapshotOut<typeof SocietyModel> | undefined
): SnapshotOut<typeof BoardMemberModel>[] => {
  const sortList = [
    'admin',
    'president',
    'vicepresident',
    'treasury',
    'secretary',
    'member',
    'alternate',
    'advisor',
    'nominating-committee',
  ]

  const boardMemberList = society?.boardMembersList
    ? society.boardMembersList
    : []

  return [...boardMemberList].sort((a, b) => {
    return sortList.indexOf(a.role) - sortList.indexOf(b.role)
  })
}
