/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { adminQueryKeys, patchWebinar } from '../../../../api/admin'
import { IWebinarModel } from '../../../../interfaces/models/webinars.interface'
import { Button, ButtonVariant } from '../../../../components/common/Button'
import { formatDate, ignoreTimezoneDate } from '../../../../helpers/date'
import { Card } from '../../../../components/common/Card'
import { CardBody } from '../../../../components/common/Card/CardBody'
import { theme } from '../../../../theme/theme'
import { Toggle } from '../../../../components/common/Toggle'
import { ToastType } from '../../../../components/common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { useModal } from '../../../../hooks/useModal'
import { EditWebinarModal } from './EditWebinarModal'

const ListItem = (item: IWebinarModel): ReactNode => {
  const {
    close: closeEditWebinar,
    open: openEditWebinar,
    show: showEditWebinar,
  } = useModal()
  const client = useQueryClient()
  const { setToastNotification } = useToastNotifications()
  const { mutate, isPending } = useMutation({
    mutationFn: patchWebinar,
    onMutate: ({ body }) => {
      setToastNotification(
        ToastType.INFORMATION,
        body.published ? `Publicerar webinar` : `Avpublicerar webinar`
      )
    },
    onError: () => {
      setToastNotification(ToastType.DANGER, 'Något gick fel')
    },
    onSuccess: (data) => {
      const published = data.data.data?.published

      setToastNotification(
        ToastType.SUCCESS,
        published ? `Webinar publicerat` : `Webinar avpublicerat`
      )
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: adminQueryKeys.webinars().queryKey })
    },
  })
  const { startDate, published, _id } = item
  const onToggle = ({ id, value }: { id: string; value: boolean }): void => {
    mutate({
      id: _id,
      body: {
        published: value,
      },
    })
  }
  return (
    <>
      <Card className="w-[580px]">
        {{
          body: (
            <CardBody className="flex flex-row justify-between gap-4 items-center">
              <p
                style={theme.textVariants.base}
              >{`${formatDate(ignoreTimezoneDate(new Date(startDate)))}`}</p>

              <div className="flex flex-row gap-3">
                <Toggle
                  enabled={published}
                  label="publicerad"
                  onChange={(value) => onToggle({ id: _id, value })}
                  disabled={isPending}
                  name={_id}
                />
                <Button
                  variant={ButtonVariant.DEFAULT}
                  label="Redigera"
                  onClick={() => openEditWebinar()}
                />
              </div>
            </CardBody>
          ),
        }}
      </Card>
      {showEditWebinar && (
        <EditWebinarModal
          item={item}
          close={closeEditWebinar}
          show={showEditWebinar}
        />
      )}
    </>
  )
}

const WebinarList = (): ReactNode => {
  const { data } = useQuery(adminQueryKeys.webinars())

  const intros = data?.data?.data?.intro || []
  const gettingStarted = data?.data?.data?.gettingStarted || []
  return (
    <div className="flex flex-wrap gap-6">
      <div>
        <p style={theme.textVariants.baseBold}>Intro</p>
        <div className="flex flex-col gap-1">
          {intros.map((item) => (
            <ListItem key={item._id} {...item} />
          ))}
        </div>
      </div>
      <div>
        <p style={theme.textVariants.baseBold}>Kom-igång</p>
        <div className="flex flex-col gap-1">
          {gettingStarted.map((item) => (
            <ListItem key={item._id} {...item} />
          ))}
        </div>
      </div>
    </div>
  )
}

export { WebinarList }
