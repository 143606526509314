import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, Link } from 'react-router-dom'
import { InferType } from 'yup'
import { observer } from 'mobx-react-lite'
import { Button, ButtonVariant } from '../../../../components/common/Button'
import { TextInput } from '../../../../components/common/TextInput'
import { signInSchema } from '../../../../forms/schemas/sign_in'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { useStores } from '../../../../hooks/useStores'
import { theme } from '../../../../theme/theme'
import { ToastType } from '../../../../components/common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { FormControl } from '../../../../components/common/FormControl'
import { reverseUrl } from '../../../../navigation/reverseUrl'

export const LoginForm = observer((): JSX.Element => {
  const { authenticationStore, userStore, societyStore } = useStores()
  const { translate } = useAppTranslation()
  const navigate = useNavigate()
  const { getErrorMessage } = useFormErrorMessage()
  const { setToastNotification } = useToastNotifications()

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(signInSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = async (
    data: InferType<typeof signInSchema>
  ): Promise<void> => {
    const { email, password } = data
    const { userId, genericError } = await authenticationStore.login(
      email.toLowerCase(),
      password
    )

    if (userId) {
      societyStore.reset()
      navigate(reverseUrl('feed'))
      await userStore.getUser(userId)
    } else {
      setToastNotification(
        ToastType.DANGER,
        translate(
          genericError
            ? 'flashMessage.somethingWentWrongError'
            : 'authenticationSignInView.flashMessage.signInFailure'
        )
      )
    }
  }

  const onError = (): void => {
    if (!isValid) return
    setToastNotification(
      ToastType.DANGER,
      translate('flashMessage.somethingWentWrongError')
    )
  }

  const navigateToSignUp = (): void => {
    navigate(reverseUrl('signup'))
  }

  const loading = authenticationStore.loggingIn === 'pending'

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      className="flex flex-col gap-4"
    >
      <Controller
        control={control}
        name="email"
        render={({ field: { value, name, onChange, onBlur } }) => (
          <FormControl
            error={errors.email && getErrorMessage(errors.email)}
            name={name}
            label={translate('authenticationSignInView.form.labels.email')}
          >
            <TextInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              type="email"
              error={!!errors.email}
            />
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="password"
        render={({ field: { value, name, onChange, onBlur } }) => (
          <FormControl
            error={errors.password && getErrorMessage(errors.password)}
            name={name}
            label={translate('authenticationSignInView.form.labels.password')}
          >
            <TextInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              type="password"
              error={!!errors.password}
            />
          </FormControl>
        )}
      />
      <div className="mt-4 flex flex-col gap-4 md:mt-12">
        <Button
          type="submit"
          style={{
            ...theme.textVariants.paragraph,
          }}
          className="flex h-14 w-full items-center justify-center"
          label={`${translate('authenticationSignInView.signInButtonTitle')}`}
          loading={loading}
          variant={ButtonVariant.PRIMARY}
        />
        <Button
          type="button"
          style={{
            ...theme.textVariants.paragraph,
          }}
          className="flex h-14 w-full items-center justify-center"
          label={`${translate(
            'authenticationSignInView.createAccountButtonTitle'
          )}`}
          onClick={navigateToSignUp}
        />
      </div>
      <div className="mt-2 mb-4 flex justify-center md:mb-0">
        <Link
          className="underline hover:text-blue"
          to={reverseUrl('reset-password')}
        >
          {translate('authenticationSignInView.forgotPasswordButtonTitle')}
        </Link>
      </div>
    </form>
  )
})
