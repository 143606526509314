import { ProcessedImage } from '../types/image-upload'

export enum ImageActions {
  ADD = 'add',
  REMOVE = 'remove',
  SET_UPLOADED = 'setUploaded',
  SET_DESCRIPTION = 'setDescription',
  RESET = 'reset',
}

interface ImageActionBase {
  image: ProcessedImage
}
interface AddImageAction extends ImageActionBase {
  type: ImageActions.ADD
}

interface RemoveImageAction {
  type: ImageActions.REMOVE
  id: string
}

interface SetUploadedImageAction extends ImageActionBase {
  type: ImageActions.SET_UPLOADED
  mediaId: string
}

interface SetDescriptionImageAction {
  type: ImageActions.SET_DESCRIPTION
  description: string
  id: string
}

interface ResetAction {
  type: ImageActions.RESET
}

export type ImageUploadActions =
  | AddImageAction
  | RemoveImageAction
  | SetUploadedImageAction
  | SetDescriptionImageAction
  | ResetAction
