import * as yup from 'yup'

export const societyRegisterUploadSchema = yup
  .object({
    file: yup.object({
      mimeType: yup.string().required(),
      base64: yup.string().required(),
      filename: yup.string().required(),
      name: yup.string().required(),
    }),
  })
  .required()
