import React from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
// eslint-disable-next-line max-len
import { societyFacilityNotificationEmailsCreateSchema } from '../../../../../forms/schemas/society_facility_notification_emails_create'
import { Button, ButtonVariant } from '../../../../common/Button'
import { TextInput } from '../../../../common/TextInput'
import { IconChoices } from '../../../../common/Icon'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
import { FormControl } from '../../../../common/FormControl'
import { useFormErrorMessage } from '../../../../../hooks/useFormErrorMessage'
import { theme } from '../../../../../theme/theme'

interface NotificationEmailsFormProps {
  onError: (error: unknown) => void
  onSubmit: (
    data: InferType<typeof societyFacilityNotificationEmailsCreateSchema>
  ) => Promise<void>
  onClose: () => void
  emails: (string | undefined)[] | undefined
}

export const NotificationEmailsForm = observer(
  ({
    emails,
    onError,
    onSubmit,
    onClose,
  }: NotificationEmailsFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { getErrorMessage } = useFormErrorMessage()

    const getDefaultValues = (): InferType<
      typeof societyFacilityNotificationEmailsCreateSchema
    > => {
      return {
        primaryRecipient: emails && emails.length > 0 ? emails[0] : '',
        ccEmails: emails && emails.length > 0 ? emails.splice(1) : [],
      }
    }

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyFacilityNotificationEmailsCreateSchema),
      defaultValues: getDefaultValues(),
    })

    const { fields, append, remove } = useFieldArray({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      name: 'ccEmails',
      control,
    })

    return (
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className="flex flex-col space-y-4"
      >
        <div className="flex flex-col">
          <p style={theme.textVariants.baseBold}>
            {translate('createUpdateFacility.form.headers.recipient')}
          </p>
          <Controller
            control={control}
            name="primaryRecipient"
            render={({ field: { value, name, onChange, onBlur } }) => (
              <FormControl
                error={
                  errors.primaryRecipient &&
                  getErrorMessage(errors.primaryRecipient)
                }
                name={name}
              >
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.primaryRecipient}
                  type="email"
                />
              </FormControl>
            )}
          />
        </div>
        <div className="mt-4 mb-2 border-b" />
        <div className="flex w-full flex-col space-y-2">
          <p style={theme.textVariants.baseBold}>
            {translate('createUpdateFacility.form.headers.cc')}
          </p>
          <div className="flex w-full flex-col space-y-2">
            {fields.map((field, index) => (
              <div className="w-full" key={field.id}>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => {
                    const _fieldError = errors.ccEmails?.[index]
                    const _error = _fieldError
                      ? getErrorMessage(_fieldError)
                      : undefined

                    return (
                      <FormControl
                        name={name}
                        error={_error}
                        label={translate('common.form.labels.email')}
                        className="flex w-full"
                      >
                        <div className="flex w-full items-center gap-3">
                          <TextInput
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            type="email"
                            wrapperClassName="flex-1"
                          />
                          <Button
                            label={translate('common.actions.delete')}
                            onClick={() => remove(index)}
                            icon={IconChoices.DELETE_TRASH}
                            size="sm"
                            variant={ButtonVariant.DANGER}
                          />
                        </div>
                      </FormControl>
                    )
                  }}
                  name={`ccEmails.${index}`}
                />
              </div>
            ))}
          </div>
          <Button
            className="mt-4"
            label={translate('common.actions.addMore')}
            onClick={() => append('', { shouldFocus: true })}
            icon={IconChoices.PLUS_SIGN}
          />
        </div>
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            disabled={!isValid}
            label={translate('common.actions.save')}
            variant={ButtonVariant.PRIMARY}
            type="submit"
          />
        </div>
      </form>
    )
  }
)
