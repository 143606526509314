import { isWebUri } from 'valid-url'

export const prependHttp = (url: string): string => {
  if (!/^https?:\/\//i.test(url)) {
    return `http://${url}`
  }
  return url
}

export const prependHttpWww = (url: string): string => {
  if (!/^https?:\/\/www/i.test(url)) {
    return `http://www.${url}`
  }
  return url
}

export const parseUrlWhitespaces = (url: string): string => {
  return url.replace(/\s/g, '%20')
}

export const openUrl = (url: string | undefined, safe = true): boolean => {
  const features = safe ? 'noopener,noreferrer' : undefined
  if (url && isWebUri(url)) {
    window.open(url, '_blank', features)
    return true
  }
  if (url && url.startsWith('www')) {
    window.open(prependHttp(url), '_blank', features)
    return true
  }
  if (url && !url.startsWith('www') && !url.startsWith('http')) {
    window.open(prependHttpWww(url), '_blank', features)
    return true
  }
  return false
}
