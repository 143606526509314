import { SnapshotOut } from 'mobx-state-tree'
import { ChatRoomModel } from '../state/models/chat-room'

export const getOneOnOneUserId = (
  authenticatedUserId: string | undefined,
  chatRoom: SnapshotOut<typeof ChatRoomModel> | undefined
): string | undefined => {
  const oneOnOneUserId = chatRoom?.membersList
    .map((_member) => _member.userId)
    .find((_userId) => _userId !== authenticatedUserId)

  if (!oneOnOneUserId) {
    return undefined
  }

  return oneOnOneUserId
}
