import * as yup from 'yup'

export const societyCreatePostSchema = yup
  .object({
    author: yup.string().required(),
    role: yup.string().required(),
    society: yup.string().required(),
    isAlert: yup.boolean(),
    pollIds: yup.array(yup.string()),
    eventsIds: yup.array(yup.string()),
    groupsIds: yup.array(yup.string()),
    media: yup.array(yup.string()),
    documentsIds: yup.array(yup.string()),
    message: yup
      .string()
      .when(['pollIds', 'eventsIds', 'documentsIds', 'media'], {
        is: (
          pollIds: string[],
          eventsIds: string[],
          documentsIds: string[],
          media: string[]
        ) =>
          pollIds.length === 0 &&
          eventsIds.length === 0 &&
          documentsIds.length === 0 &&
          media.length === 0,
        then: (schema) => schema.required(),
      }),
    accessBoardMember: yup.boolean(),
    accessAdminMember: yup.boolean(),
    accessUsersIds: yup.array(yup.string()),
  })
  .required()
