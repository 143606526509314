import React from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { InferType } from 'yup'
import { SnapshotOut } from 'mobx-state-tree'
import { adminSetPartnerSchema } from '../../../../../../forms/schemas/admin_set_partner'
import { FormControl } from '../../../../../../components/common/FormControl'
import { useFormErrorMessage } from '../../../../../../hooks/useFormErrorMessage'
import {
  Button,
  ButtonVariant,
} from '../../../../../../components/common/Button'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { useStores } from '../../../../../../hooks/useStores'
import { getPartnerNameId } from '../../../../../../components/society/ManagementPartner/management_partner'
import { SelectDropdown } from '../../../../../../components/common/SelectDropdown'
import { SocietyModel } from '../../../../../../state/models/society'

interface SetPartnerModalContentProps {
  onError: (error: unknown) => void
  onSubmit: (data: InferType<typeof adminSetPartnerSchema>) => Promise<void>
  onClose: () => void
  resetPartner: () => void
  society?: SnapshotOut<typeof SocietyModel>
}

export const SetPartnerModalContent = observer(
  ({
    onError,
    onSubmit,
    onClose,
    resetPartner,
    society,
  }: SetPartnerModalContentProps): JSX.Element => {
    const { settingsStore } = useStores()
    const { translate } = useAppTranslation()
    const { getErrorMessage } = useFormErrorMessage()

    const discountCodes = settingsStore.listDiscountCodes.map((discount) => ({
      value: discount.code,
      label: discount.partnerNameId,
    }))

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(adminSetPartnerSchema),
      defaultValues: {
        code: discountCodes[0]?.value,
      },
    })

    const partnerIds =
      society &&
      society.activatedPartnerData?.map((partner) => partner.partnerId)

    const partnerNameId =
      partnerIds && partnerIds?.length > 0 && getPartnerNameId(partnerIds[0])

    return (
      <form
        className="flex h-full flex-col gap-6"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        {(!partnerIds || partnerIds.length === 0) && (
          <Controller
            control={control}
            name="code"
            render={({ field: { value, name, onChange } }) => (
              <FormControl
                label="Partner"
                name={name}
                error={errors.code && getErrorMessage(errors.code)}
              >
                <SelectDropdown
                  options={discountCodes}
                  value={value}
                  onChange={onChange}
                  error={!!errors.code}
                />
              </FormControl>
            )}
          />
        )}
        {partnerIds && partnerIds.length > 0 && (
          <div className="flex flex-col gap-2">
            Nuvarande partner: {partnerNameId}
            <Button
              variant={ButtonVariant.DANGER}
              label="Nollställ partner"
              onClick={resetPartner}
            />
          </div>
        )}
        {(!partnerIds || partnerIds.length === 0) && (
          <div className="flex flex-wrap justify-end gap-4">
            <Button
              label={translate('common.actions.cancel')}
              onClick={onClose}
            />
            <Button
              variant={ButtonVariant.PRIMARY}
              disabled={!isValid}
              label={translate('common.actions.save')}
              type="submit"
            />
          </div>
        )}
      </form>
    )
  }
)
