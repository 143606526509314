import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './translations/en'
import sv from './translations/sv'

export const resources = {
  sv: {
    translation: sv,
  },
  en: {
    translation: en,
  },
} as const

i18n.use(initReactI18next).init({
  lng: 'sv',
  fallbackLng: 'sv',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
})

export default i18n
