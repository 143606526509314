import { SnapshotOut } from 'mobx-state-tree'
import { formatDateWithFormat, ignoreTimezoneDate } from '../helpers/date'
import { EventModel } from '../state/models/event'
import { BoardRoomEventModel } from '../state/models/boardroom-event'

export const useAddToCalendarConfig = (
  event:
    | SnapshotOut<typeof EventModel>
    | SnapshotOut<typeof BoardRoomEventModel>
): object => {
  const getConfig = (): object => {
    return {
      name: event.title,
      description: event.description,
      location: event.location || '',
      timeZone: 'CET',
      startDate: formatDateWithFormat(
        ignoreTimezoneDate(new Date(event.startDate)),
        'yyyy-MM-dd'
      ),
      startTime: !event.isAllDay
        ? formatDateWithFormat(
            ignoreTimezoneDate(new Date(event.startDate)),
            'HH:mm'
          )
        : undefined,
      endDate: !event.isAllDay
        ? formatDateWithFormat(
            ignoreTimezoneDate(new Date(event.endDate)),
            'yyyy-MM-dd'
          )
        : undefined,
      endTime: !event.isAllDay
        ? formatDateWithFormat(
            ignoreTimezoneDate(new Date(event.endDate)),
            'HH:mm'
          )
        : undefined,
      options: [
        'Apple',
        'Google',
        'Yahoo',
        'iCal',
        'Microsoft365',
        'Outlook.com',
      ],
    }
  }

  const config = getConfig()

  return config
}
