import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { SocietyWebsiteModel } from '../../../../state/models/society-website'
import { SocietyWebsiteQuestionsAndAnswersSection } from './SocietyWebsiteQuestionsAndAnswersSection'
import { useStores } from '../../../../hooks/useStores'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { Toggle } from '../../../common/Toggle'

interface SocietyWebsiteQuestionsAndAnswersProps {
  website: SnapshotOut<typeof SocietyWebsiteModel>
}

export const SocietyWebsiteQuestionsAndAnswers = observer(
  ({ website }: SocietyWebsiteQuestionsAndAnswersProps): JSX.Element => {
    const { societyWebsitesStore } = useStores()
    const { translate } = useAppTranslation()
    const [show, setShow] = useState<boolean>(!!website.brokerQuestion?.show)
    const { setToastNotification } = useToastNotifications()
    const sections = website.brokerQuestion?.sections?.map((_section) => ({
      ..._section,
    }))

    const onShowChange = async (value: boolean): Promise<void> => {
      setShow(value)
      const status = await societyWebsitesStore.patchWebsite(website._id, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        brokerQuestion: {
          ...website.brokerQuestion,
          show: value,
        },
      })

      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate(
            'societyWebsiteQuestionsAndAnswers.flashMessage.updateSuccess'
          )
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate(
            'societyWebsiteQuestionsAndAnswers.flashMessage.updateSuccess'
          )
        )
      }
    }

    return (
      <div className="w-full space-y-6">
        <Toggle
          label={translate('societyWebsite.showOnWebsite')}
          wrapperClassName="w-fit"
          name="societyWebsiteFAQShow"
          enabled={show}
          onChange={onShowChange}
        />
        {sections?.map((item) => (
          <SocietyWebsiteQuestionsAndAnswersSection
            key={item._id}
            id={item._id}
            title={item.title || ''}
            website={website}
          />
        ))}
      </div>
    )
  }
)
