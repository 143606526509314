import { useStores } from './useStores'

export const useUserBlockedInSociety = (
  societyId: string | undefined
): boolean => {
  const { societyStore } = useStores()

  if (!societyId) {
    return false
  }

  return societyStore.userIsBlockedSocietyIds.includes(societyId)
}
