import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useIsUserAdminOfGroupChat } from '../../../hooks/useIsUserAdminOfGroupChat'
import { useStores } from '../../../hooks/useStores'
import { ChatRoomModel } from '../../../state/models/chat-room'
import { theme } from '../../../theme/theme'
import { IconChoices } from '../../common/Icon'
import { InformationRowOptions } from '../../common/InformationTable'
import { useIsUserInChat } from '../../../hooks/useIsUserInChat'
import { isUserAdminInSociety } from '../../../helpers/society'
import { SocietyModel } from '../../../state/models/society'
import { useAuth } from '../../../hooks/useAuth'

type useInfoRowsReturnType = {
  informationRows: InformationRowOptions[]
  actionRows: InformationRowOptions[]
}

interface useInfoRowsProps {
  chatRoom: SnapshotOut<typeof ChatRoomModel>
  openArchiveModal: () => void
  openMembersModal: () => void
  openRemoveChatModal: () => void
  openLeaveChatModal: () => void
}

export const useInfoRows = ({
  chatRoom,
  openArchiveModal,
  openMembersModal,
  openRemoveChatModal,
  openLeaveChatModal,
}: useInfoRowsProps): useInfoRowsReturnType => {
  const { societyStore } = useStores()
  const { translate } = useAppTranslation()
  const { adminUser, isUserAdminOfGroupChat } =
    useIsUserAdminOfGroupChat(chatRoom)
  const isUserInChat = useIsUserInChat(chatRoom)
  const society = societyStore.societies.get(chatRoom?.societyId) as
    | SnapshotOut<typeof SocietyModel>
    | undefined
  const { userId } = useAuth()
  const isUserAdmin = userId && isUserAdminInSociety(society, userId)

  const isGroupOrBoardChatAndUserIsAdmin =
    (chatRoom?.type === 'interests' && isUserAdminOfGroupChat) ||
    (chatRoom?.type === 'board' && isUserAdmin)

  const informationRows = [
    ...(adminUser?.fullName
      ? [
          {
            title: translate('chatSettings.form.chatCreator'),
            text: adminUser.fullName,
          },
        ]
      : []),
    {
      title: translate('chatSettings.form.society'),
      text: society?.name || '',
    },
    ...(chatRoom.type === 'interests'
      ? [
          {
            title: translate('chatSettings.form.type'),
            text: chatRoom.isPrivate
              ? translate('chatSettings.form.closedGroupChat')
              : translate('chatSettings.form.openGroupChat'),
          },
          {
            title: translate('chatSettings.form.showMembers'),
            icon: IconChoices.GROUPS,
            onClick: openMembersModal,
          },
        ]
      : []),
  ]

  const actionRows = [
    {
      title: translate('chatSettings.form.archive'),
      icon: IconChoices.INVENTORY,
      onClick: openArchiveModal,
      hideElementRight: true,
    },
    ...(isGroupOrBoardChatAndUserIsAdmin
      ? [
          {
            title: translate('chatSettings.form.remove'),
            icon: IconChoices.DELETE_TRASH,
            iconColor: theme.colors.danger,
            titleStyle: { color: theme.colors.danger },
            onClick: openRemoveChatModal,
            hideElementRight: true,
          },
        ]
      : []),
    ...(chatRoom?.type === 'interests' &&
    !isUserAdminOfGroupChat &&
    isUserInChat
      ? [
          {
            title: translate('chatSettings.form.leave'),
            icon: IconChoices.LOGOUT,
            iconColor: theme.colors.danger,
            titleStyle: { color: theme.colors.danger },
            onClick: openLeaveChatModal,
            hideElementRight: true,
          },
        ]
      : []),
  ]

  return { informationRows, actionRows }
}
