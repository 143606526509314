import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { observer } from 'mobx-react-lite'
import {
  Button,
  ButtonVariant,
} from '../../../../../../components/common/Button'
import { adminResetPasswordSchema } from '../../../../../../forms/schemas/admin_reset_password'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { FormControl } from '../../../../../../components/common/FormControl'
import { useFormErrorMessage } from '../../../../../../hooks/useFormErrorMessage'
import { TextInput } from '../../../../../../components/common/TextInput'
import { useStores } from '../../../../../../hooks/useStores'

interface ResetPasswordModalContentProps {
  onError: (error: unknown) => void
  onSubmit: (data: InferType<typeof adminResetPasswordSchema>) => Promise<void>
  onClose: () => void
}

export const ResetPasswordModalContent = observer(
  ({
    onError,
    onSubmit,
    onClose,
  }: ResetPasswordModalContentProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { getErrorMessage } = useFormErrorMessage()
    const { adminStore, userStore } = useStores()

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(adminResetPasswordSchema),
      defaultValues: {
        password: '',
      },
    })

    const loading =
      adminStore.changingUserPassword === 'pending' ||
      userStore.fetchingUser === 'pending'

    return (
      <form
        className="flex h-full flex-col gap-6"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Controller
          control={control}
          name="password"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label="Nytt lösenord"
              name={name}
              error={errors.password && getErrorMessage(errors.password)}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.password}
              />
            </FormControl>
          )}
        />
        <div className="flex flex-wrap justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid || loading}
            loading={loading}
            label={translate('common.actions.save')}
            type="submit"
          />
        </div>
      </form>
    )
  }
)
