import * as yup from 'yup'

export const unitCreateSchema = yup
  .object({
    societyId: yup.string().required(),
    entranceId: yup.string().required(),
    title: yup.string(),
    titleLegal: yup.string().required(),
    floor: yup.number(),
  })
  .required()
