import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { SocietyWebsiteModel } from '../../../../state/models/society-website'
import { SocietyWebsiteFolderListItem } from './SocietyWebsiteFolderListItem'
import { SocietyWebsiteDocumentListItem } from './SocietyWebsiteDocumentListItem'
import { Button, ButtonVariant } from '../../../common/Button'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { IconChoices } from '../../../common/Icon'
import { CreateUpdateSocietyWebsiteDocumentModal } from './CreateUpdateSocietyWebsiteDocumentModal'
import { useModal } from '../../../../hooks/useModal'
import { CreateUpdateSocietyWebsiteFolderModal } from './CreateUpdateSocietyWebsiteFolderModal'
import { ErrorView } from '../../../../views/error/ErrorView'
import { IllustrationChoices } from '../../../common/Illustration'

interface SocietyWebsiteDocumentsErrorViewsProps {
  showFolderEmptyState: boolean
  showDocumentsEmptyState: boolean
  folderName?: string
}

const SocietyWebsiteDocumentsErrorViews = ({
  showFolderEmptyState,
  folderName,
  showDocumentsEmptyState,
}: SocietyWebsiteDocumentsErrorViewsProps): JSX.Element => {
  const { translate } = useAppTranslation()
  return (
    <>
      {showFolderEmptyState && (
        <ErrorView
          title={translate('societyWebsiteDocuments.emptyState.title')}
          subtitle={translate(
            'societyWebsiteDocuments.emptyState.emptyFolderSubtitle',
            { folderName }
          )}
          illustration={IllustrationChoices.EMPTY}
        />
      )}
      {showDocumentsEmptyState && (
        <ErrorView
          title={translate('societyWebsiteDocuments.emptyState.title')}
          subtitle={translate(
            'societyWebsiteDocuments.emptyState.noDocumentsSubtitle'
          )}
          illustration={IllustrationChoices.EMPTY}
        />
      )}
    </>
  )
}

interface SocietyWebsiteDocumentsProps {
  website: SnapshotOut<typeof SocietyWebsiteModel>
}

export const SocietyWebsiteDocuments = observer(
  ({ website }: SocietyWebsiteDocumentsProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const [currentDocument, setCurrentFolder] = useState<string | null>(null)
    const {
      show: showCreateDocumentModal,
      open: openCreateDocumentModal,
      close: closeCreateDocumentModal,
    } = useModal()
    const {
      show: showCreateFolderModal,
      open: openCreateFolderModal,
      close: closeCreateFolderModal,
    } = useModal()

    const folders = website ? website.foldersList : []

    const isRoot = !currentDocument

    // If not root, we are inside one of the folders
    const folder = folders?.find((_folder) => _folder._id === currentDocument)

    const onChangeCurrentFolder = (id: string): void => {
      setCurrentFolder(id)
    }

    const goToRoot = (): void => {
      setCurrentFolder(null)
    }

    return (
      <div className="w-full">
        {!isRoot && (
          <div className="mb-3 flex space-x-3">
            <Button
              label={translate('common.actions.back')}
              onClick={goToRoot}
              icon={IconChoices.ARROW_LEFT}
            />
            <Button
              variant={ButtonVariant.PRIMARY}
              label={translate('societyWebsiteDocuments.addNewDocument')}
              onClick={openCreateDocumentModal}
              icon={IconChoices.FILE_UPLOAD}
            />
          </div>
        )}
        {isRoot && (
          <div className="mb-3 flex space-x-3">
            <Button
              variant={ButtonVariant.PRIMARY}
              label={translate('societyWebsiteDocuments.addNewFolder')}
              onClick={openCreateFolderModal}
              icon={IconChoices.PLUS_SIGN}
            />
          </div>
        )}
        <SocietyWebsiteDocumentsErrorViews
          folderName={folder?.name}
          showFolderEmptyState={!!folder && folder.documentsList.length === 0}
          showDocumentsEmptyState={isRoot && folders?.length === 0}
        />
        {isRoot
          ? folders?.map((_folder) => (
              <SocietyWebsiteFolderListItem
                website={website}
                key={_folder._id}
                folder={_folder}
                onChangeCurrentFolder={onChangeCurrentFolder}
              />
            ))
          : folder?.documentsList?.map((_document) => (
              <SocietyWebsiteDocumentListItem
                website={website}
                key={_document.documentId}
                documentId={_document.documentId}
                folderId={folder._id}
              />
            ))}
        {folder && (
          <CreateUpdateSocietyWebsiteDocumentModal
            website={website}
            show={showCreateDocumentModal}
            close={closeCreateDocumentModal}
            folderId={folder._id}
          />
        )}
        <CreateUpdateSocietyWebsiteFolderModal
          website={website}
          show={showCreateFolderModal}
          close={closeCreateFolderModal}
        />
      </div>
    )
  }
)
