/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'
import { NotificationPreferencesAuthor, NotificationType } from './notification'

export const UserModelEducation = types.maybeNull(
  types.model({
    level: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    institute: types.maybeNull(types.string),
    field: types.maybeNull(types.string),
    degree: types.maybeNull(types.string),
  })
)

export const UserModelOccupation = types.maybeNull(
  types.model({
    company: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    industry: types.maybeNull(types.string),
  })
)

export const UserModelInterests = types.maybeNull(types.array(types.string))

export const UserModelNotificationPreference = types.model({
  _id: types.identifier,
  type: NotificationType,
  societyId: types.maybeNull(types.string),
  dontEmail: types.array(NotificationPreferencesAuthor),
  dontPush: types.array(NotificationPreferencesAuthor),
})

export const UserModel = types
  .model('User', {
    _id: types.identifier,
    ...timestampTypes,
    email: types.maybe(types.string),
    emailSecondary: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    surname: types.maybeNull(types.string),
    fullName: types.string,
    birthDate: types.maybeNull(types.string),
    gender: types.maybeNull(types.string),
    mobile: types.maybeNull(types.string),
    mobileCountry: types.maybeNull(types.string),
    aboutInfo: types.maybeNull(
      types.model({
        interests: UserModelInterests,
        presentation: types.maybeNull(types.string),
        education: UserModelEducation,
        occupation: UserModelOccupation,
      })
    ),
    avatarId: types.maybeNull(types.string),
    notificationPreferences: types.maybeNull(
      types.array(UserModelNotificationPreference)
    ),
    language: types.maybeNull(types.string),
    isVerified: types.maybe(types.boolean),
  })
  .actions((self) => ({
    updateEmail(email: string) {
      self.email = email
    },
  }))
