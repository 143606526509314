import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { observer } from 'mobx-react-lite'
import { AxiosError } from 'axios'
import config from './config'
import './forms/validation'
import {
  RootStore,
  RootStoreProvider,
  setupRootStore,
} from './state/stores/root'
import { AppRoutes } from './navigation/root/AppRoutes'
import AppTranslationProvider from './hooks/useAppTranslation'
import { useWebsocket } from './hooks/useWebsocket'
import { ToastNotificationProvider } from './hooks/useToastNotification'
import { TrackingProvider } from './hooks/useTracking'
import { useAppInitializedTracking } from './hooks/useAppInitializedTracking'
import { useStatusMessages } from './hooks/useStatusMessages'
import { isDevEnv } from './helpers/environment'
import { AuthProvider } from './hooks/useAuth'
import { useBlockedSocietyIds } from './hooks/useBlockedSocietyIds'

const PROD_BASE_URL = 'https://app.boappa.se'

Sentry.init({
  enabled: !isDevEnv(),
  dsn: config.SENTRY_DSN,
  debug: config.DEBUG,
  environment: config.SENTRY_ENVIRONMENT,
  release: import.meta.env.VITE_APP_RELEASE,
  tracesSampleRate: 0.1,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  beforeSend(event, hint) {
    if (hint && hint.originalException instanceof Error) {
      const axiosError = hint.originalException as AxiosError
      if (
        axiosError.isAxiosError &&
        ['Request aborted', 'timeout exceeded'].some(
          (el) => el === axiosError.message
        )
      ) {
        /* 
        We ignore these errors due to:
         - High volume, low impact
         - Possible transient network issues or app in background
         - No actionable data
         - Resource optimization and decluttering in Sentry 
        */
        return null
      }
    }
    return event
  },
})

const AppInitialized = observer((): JSX.Element | null => {
  useWebsocket()
  useAppInitializedTracking()
  useStatusMessages()
  useBlockedSocietyIds()

  // Solution for societies that mask their society website under their own domain
  if (!isDevEnv() && window.location.origin !== PROD_BASE_URL) {
    window.location.href = PROD_BASE_URL
  }

  // Setup providers
  return <AppRoutes />
})

AppInitialized.displayName = 'AppInitialized'

const queryClient = new QueryClient()

export const App = observer((): JSX.Element | null => {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)

  // Initial loading of RootStore
  useEffect(() => {
    ;(async () => {
      setupRootStore().then(setRootStore)
    })()
  }, [])

  if (!rootStore) return null

  // Setup providers
  return (
    <RootStoreProvider value={rootStore as RootStore}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <TrackingProvider>
            <AppTranslationProvider>
              <ToastNotificationProvider>
                <AppInitialized />
              </ToastNotificationProvider>
            </AppTranslationProvider>
          </TrackingProvider>
        </AuthProvider>
        <ReactQueryDevtools buttonPosition="bottom-left" />
      </QueryClientProvider>
    </RootStoreProvider>
  )
})

export default App
