import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ViewBase } from '../../../../components/common/ViewBase'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useStores } from '../../../../hooks/useStores'
import { IconChoices } from '../../../../components/common/Icon'
import { theme } from '../../../../theme/theme'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { Button, ButtonVariant } from '../../../../components/common/Button'
import { ErrorView } from '../../../error/ErrorView'
import { IllustrationChoices } from '../../../../components/common/Illustration'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { useModal } from '../../../../hooks/useModal'
// eslint-disable-next-line max-len
import { CreateUpdateLocalOfferModal } from '../../../../components/society/LocalOffer/CreateUpdateLocalOfferModal'
import { FixedHeightMediaImageWithBackground } from '../../../../components/common/Image'
import { openEmailMailTo } from '../../../../helpers/email'
import { openUrl, prependHttp } from '../../../../helpers/url'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../../components/common/Toast/toast-type'
import { ConfirmationModal } from '../../../../components/common/ConfirmationModal'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'

export const LocalOfferView = observer((): JSX.Element => {
  const { translate } = useAppTranslation()
  const { localOffersStore, authenticationStore } = useStores()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const { setToastNotification } = useToastNotifications()
  const navigate = useNavigate()
  const { offerId } = useParams()
  const {
    show: showCreateModal,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModal()
  const {
    show: showDeleteConfirmationModal,
    open: openDeleteConfirmationModal,
    close: closeDeleteConfirmationModal,
  } = useModal()

  const isAdmin = isUserAdminInSociety(
    society,
    authenticationStore.userId as string
  )

  useEffect(() => {
    if (society._id) localOffersStore.getLocalOffersPerSociety(society._id)
  }, [localOffersStore, society._id])

  const localOffer = offerId
    ? localOffersStore.localOffers.get(offerId)
    : undefined

  useDocumentTitle(
    reverseDocumentTitle('society:local-offers-detail', {
      '{{ societyName }}': society.name,
      '{{ localOfferName }}': localOffer?.companyName,
    })
  )

  const openEmail = (): void => {
    openEmailMailTo(localOffer?.email)
  }

  const onCopyPhone = (): void => {
    if (localOffer?.phoneNumber) {
      navigator.clipboard.writeText(localOffer.phoneNumber)
      setToastNotification(
        ToastType.SUCCESS,
        translate('common.copiedToClipboard')
      )
    }
  }

  const openWebsite = (): void => {
    if (localOffer?.website) {
      openUrl(prependHttp(localOffer.website))
    } else {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }
  }

  const deleteLocalOffer = async (): Promise<void> => {
    if (localOffer) {
      const status = await localOffersStore.deleteLocalOffer(localOffer._id)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('localOfferView.flashMessage.deleteLocalOfferSuccess')
        )
        navigate(reverseUrl('society:local-offers', { id: society._id }))
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('localOfferView.flashMessage.deleteLocalOfferFailure')
        )
      }
    } else {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }
  }

  const loading = localOffersStore.fetchingLocalOffers === 'pending'

  return (
    <ErrorBoundary>
      <ViewBase
        title={translate('localOffersView.title')}
        showBackButton
        backUrl={reverseUrl('society:local-offers', { id: society._id })}
        buttons={
          isAdmin
            ? [
                {
                  label: translate('common.actions.edit'),
                  icon: IconChoices.EDIT,
                  onClick: openCreateModal,
                },
                {
                  label: translate('common.actions.delete'),
                  icon: IconChoices.DELETE_TRASH,
                  variant: ButtonVariant.DANGER,
                  onClick: openDeleteConfirmationModal,
                },
              ]
            : undefined
        }
      >
        {!loading && !localOffer ? (
          <ErrorView
            title={translate('localOfferView.errors.notFound.title')}
            subtitle={translate('localOfferView.errors.notFound.subtitle')}
            illustration={IllustrationChoices.EMPTY}
          />
        ) : (
          <div className="w-full md:w-[550px]">
            {localOffer?.coverPhotoId && (
              <FixedHeightMediaImageWithBackground
                mediaId={localOffer.coverPhotoId}
                wrapperClassNames="md:h-1/2 lg:h-1/4"
              />
            )}
            <p className="mt-2" style={theme.textVariants.paragraph}>
              {localOffer?.companyName}
            </p>
            <p className="mt-1" style={theme.textVariants.h3}>
              {localOffer?.discountHeader}
            </p>
            <div className="mt-4 flex gap-2">
              {localOffer?.phoneNumber && (
                <Button
                  label={translate('common.contactActions.call')}
                  icon={IconChoices.PHONE}
                  onClick={onCopyPhone}
                />
              )}
              {localOffer?.email && (
                <Button
                  label={translate('common.contactActions.email')}
                  icon={IconChoices.ENVELOPE}
                  onClick={openEmail}
                  size="sm"
                />
              )}
              {localOffer?.website && (
                <Button
                  label={translate('common.contactActions.website')}
                  icon={IconChoices.WEBSITE}
                  onClick={openWebsite}
                  size="sm"
                />
              )}
            </div>
            <p
              className="mt-6 whitespace-pre-wrap break-words"
              style={theme.textVariants.base}
            >
              {localOffer?.bodyText}
            </p>
          </div>
        )}
      </ViewBase>
      <CreateUpdateLocalOfferModal
        show={showCreateModal}
        close={closeCreateModal}
        localOffer={localOffer}
      />
      <ConfirmationModal
        title={translate('localOfferView.confirmationModal.deleteTitle')}
        description={translate(
          'localOfferView.confirmationModal.deleteDescription'
        )}
        show={showDeleteConfirmationModal}
        close={closeDeleteConfirmationModal}
        onConfirm={deleteLocalOffer}
        deleteMode
      />
    </ErrorBoundary>
  )
})
