import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { theme } from '../../../theme/theme'
import { UserAvatar } from '../../common/Avatar'

interface PostCreateButtonProps {
  userId: string
  onClick: () => void
}

export const PostCreateButton = observer(
  ({ userId, onClick }: PostCreateButtonProps): JSX.Element => {
    const { userStore } = useStores()
    const { translate } = useAppTranslation()

    const user = userStore.users.get(userId)

    return (
      <button
        className="flex h-16 w-full items-center gap-2 rounded-2xl
          border border-neutral-80 bg-white px-6 py-3 text-neutral-40 shadow"
        onClick={onClick}
      >
        <UserAvatar size={40} user={user} />
        <p
          className="flex flex-1 rounded-2xl px-3 py-2 hover:bg-neutral-96"
          style={theme.textVariants.baseBold}
        >
          {translate('feedView.newPost')}
        </p>
      </button>
    )
  }
)
