import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { DocumentModel } from '../../../../state/models/document'
import { ViewNames } from '../../../../navigation/reverseUrl'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useModal } from '../../../../hooks/useModal'
import { CreateUpdateFolderModal } from '../CreateUpdateFolderModal'
import { useStores } from '../../../../hooks/useStores'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ConfirmationModal } from '../../../common/ConfirmationModal'
import { CreateUpdateDocumentModal } from '../CreateUpdateDocumentModal'
import { ItemType } from '../item-type'
import { DocumentsListItemDraggable } from './DocumentListItemDraggable'
import { FolderDropWrapper } from './FolderDropWrapper'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'

interface DocumentsListItemProps {
  document: SnapshotOut<typeof DocumentModel>
  documentRoute: ViewNames
  isListMode: boolean
}

export const DocumentsListItem = observer(
  ({
    document,
    documentRoute,
    isListMode,
  }: DocumentsListItemProps): JSX.Element => {
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()
    const { documentStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const {
      show: showEditFolderModal,
      open: openEditFolderModal,
      close: closeEditFolderModal,
    } = useModal()
    const {
      show: showEditDocumentModal,
      open: openEditDocumentModal,
      close: closeEditDocumentModal,
    } = useModal()
    const {
      show: showRemoveConfirmationModal,
      open: openRemoveConfirmationModal,
      close: closeRemoveConfirmationModal,
    } = useModal()

    const getType = (): ItemType => {
      switch (document.type) {
        case 'folder':
          return ItemType.FOLDER
        default:
          return ItemType.DOCUMENT
      }
    }

    const documentType = getType()

    const isFolder = documentType === ItemType.FOLDER

    const removeFolder = async (): Promise<void> => {
      const status = await documentStore.deleteDocument(document._id)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate(
            isFolder
              ? 'createUpdateFolder.flashMessage.deleteFolderSuccess'
              : 'createUpdateDocument.flashMessage.deleteDocumentSuccess'
          )
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate(
            isFolder
              ? 'createUpdateFolder.flashMessage.deleteFolderFailure'
              : 'createUpdateDocument.flashMessage.deleteDocumentFailure'
          )
        )
      }
    }

    return (
      <>
        {documentType === ItemType.DOCUMENT ? (
          <DocumentsListItemDraggable
            document={document}
            documentRoute={documentRoute}
            openEditDocumentModal={openEditDocumentModal}
            openEditFolderModal={openEditFolderModal}
            openRemoveConfirmationModal={openRemoveConfirmationModal}
            isListMode={isListMode}
          />
        ) : (
          <FolderDropWrapper
            document={document}
            documentRoute={documentRoute}
            openEditDocumentModal={openEditDocumentModal}
            openEditFolderModal={openEditFolderModal}
            openRemoveConfirmationModal={openRemoveConfirmationModal}
            isListMode={isListMode}
          />
        )}
        {document.parentId && document.section && (
          <CreateUpdateFolderModal
            show={showEditFolderModal}
            close={closeEditFolderModal}
            id={document._id}
            parentId={document.parentId}
            section={document.section}
          />
        )}
        {document.parentId && document.section && (
          <CreateUpdateDocumentModal
            show={showEditDocumentModal}
            close={closeEditDocumentModal}
            parentId={document.parentId}
            section={document.section}
            id={document._id}
          />
        )}
        <ConfirmationModal
          title={translate(
            isFolder
              ? 'createUpdateFolder.removeFolderConfirmationModal.title'
              : 'createUpdateDocument.removeDocumentConfirmationModal.title'
          )}
          description={translate(
            isFolder
              ? 'createUpdateFolder.removeFolderConfirmationModal.description'
              : 'createUpdateDocument.removeDocumentConfirmationModal.description'
          )}
          show={showRemoveConfirmationModal}
          close={closeRemoveConfirmationModal}
          onConfirm={removeFolder}
          deleteMode
        />
      </>
    )
  }
)
