import React from 'react'
import { observer } from 'mobx-react-lite'
import { easings } from '@react-spring/web'
import { Icon, IconChoices } from '../../common/Icon'
import { theme } from '../../../theme/theme'
import { Card } from '../../common/Card'
import { Button } from '../../common/Button'
import { Badge } from '../../common/Badge'
import { largeNumberFormattingWithSpaces } from '../../../helpers/number'
import { Spinner } from '../../common/Spinner'
import { useSocietyPremiumCost } from './useSocietyPremiumCost'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { daysBetween } from '../../../helpers/date'
import { usePaymentDates } from './usePaymentDates'
import { ProgressBar } from '../../common/ProgressBar'
import { capitalize } from '../../../helpers/string'

interface PremiumSectionProps {
  openDiscountCodeModal: () => void
}

export const PremiumSection = observer(
  ({ openDiscountCodeModal }: PremiumSectionProps): JSX.Element => {
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { costSummary, fetchingCost } = useSocietyPremiumCost(society._id)
    const { nextPaymentDate } = usePaymentDates(society, costSummary?.cost)
    const { translate } = useAppTranslation()
    const daysUntilPayment = nextPaymentDate
      ? Math.max(1, daysBetween(new Date(), nextPaymentDate))
      : undefined

    const showProgressBar = daysUntilPayment
      ? !Number.isNaN(daysUntilPayment)
      : false

    return (
      <div className="md:w-1/2">
        <div className="mb-6 flex items-center">
          <Icon icon={IconChoices.CHECKMARK} highlighted size={80} />
          <div className="ml-2">
            <p style={theme.textVariants.h1}>
              {translate('invoiceView.premium.great')}
            </p>
            <p style={theme.textVariants.base} className="mt-1">
              {translate('invoiceView.premium.annualFeePayed')}
            </p>
          </div>
        </div>
        <Card>
          {{
            body: (
              <div className="flex flex-col p-6 text-left">
                <div className="flex items-center gap-2">
                  <p style={theme.textVariants.lead}>
                    {translate('invoiceView.premium.paymentPlan')}
                  </p>
                  <Badge
                    text={translate('common.fee.annually')}
                    style={{
                      backgroundColor: '#D9F2E3',
                      color: '#0E6D2A',
                      ...theme.textVariants.captionBold,
                    }}
                  />
                </div>
                {society.billingEmail && (
                  <p
                    style={theme.textVariants.base}
                    className="text-neutral-40"
                  >
                    {translate('invoiceView.premium.invoiceSentTo')}{' '}
                    {society.billingEmail}
                  </p>
                )}
                <div className="mt-4 flex items-center gap-2">
                  <p style={theme.textVariants.lead}>
                    {translate('invoiceView.commonCard.annualFee')}
                  </p>
                  {costSummary?.partner && (
                    <Badge
                      text={translate('common.fee.discountViaString', {
                        partnerName: capitalize(costSummary.partner.name),
                      })}
                      style={{
                        backgroundColor: '#D9F2E3',
                        color: '#0E6D2A',
                        ...theme.textVariants.captionBold,
                      }}
                    />
                  )}
                </div>
                <div className="flex items-center">
                  {fetchingCost && (
                    <div className="mr-2">
                      <Spinner />
                    </div>
                  )}
                  {!fetchingCost && costSummary !== null && (
                    <div className="flex-row">
                      <p style={theme.textVariants.h1}>
                        <span>
                          {largeNumberFormattingWithSpaces(costSummary.cost)}
                        </span>{' '}
                        SEK{' '}
                        <span style={theme.textVariants.paragraph}>
                          {translate('invoiceView.commonCard.excludingVat')}
                        </span>
                      </p>
                      {costSummary.baseCost > costSummary.cost && (
                        <p
                          style={theme.textVariants.h3}
                          className="line-through"
                        >
                          <span>
                            (
                            {largeNumberFormattingWithSpaces(
                              costSummary.baseCost
                            )}
                          </span>{' '}
                          SEK )
                        </p>
                      )}
                    </div>
                  )}
                </div>

                {!fetchingCost &&
                  costSummary !== null &&
                  costSummary.cost > 0 &&
                  daysUntilPayment &&
                  !Number.isNaN(daysUntilPayment) &&
                  showProgressBar && (
                    <>
                      <p style={theme.textVariants.lead} className="mt-4 mb-2">
                        {daysUntilPayment}{' '}
                        {daysUntilPayment > 1
                          ? translate('common.days.plural')
                          : translate('common.days.singular')}{' '}
                        {translate('invoiceView.premium.untilNextPayment')}
                      </p>
                      <ProgressBar
                        percentage={(daysUntilPayment / 365) * 100}
                        height="h-2"
                        animate
                        easing={easings.easeOutQuad}
                      />
                    </>
                  )}
                {/* Disabled for now. Will enable later when 
                we've discussed how to handle this case internally. */}
                {false &&
                  (!society?.activatedPartnerData ||
                    society?.activatedPartnerData?.length === 0) && (
                    <Button
                      label={translate(
                        'invoiceView.commonCard.buttons.useCode'
                      )}
                      className="mt-6"
                      onClick={openDiscountCodeModal}
                    />
                  )}
              </div>
            ),
          }}
        </Card>
      </div>
    )
  }
)
