import React, { HTMLInputTypeAttribute, useEffect, useState } from 'react'
import { theme } from '../../../theme/theme'
import { Button, ButtonVariant } from '../Button'
import { Icon, IconChoices } from '../Icon'

const getInputTypeProps = (
  type: HTMLInputTypeAttribute,
  showPassword: boolean
): React.InputHTMLAttributes<HTMLInputElement> => {
  if (type === 'password' && showPassword) {
    return { type: 'text' }
  }
  // The email validation logic differs between browsers. The spec also says
  // that non-ASCII characters should be punycoded but not all browsers do
  // this. To ensure consistent behavior we use a text input and perform the
  // validation using yup.
  if (type === 'email') {
    return { type: 'text', inputMode: 'email', autoComplete: 'email' }
  }
  return { type }
}

export interface TextInputProps {
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void
  type?: HTMLInputTypeAttribute
  className?: string
  wrapperClassName?: string
  placeholder?: string
  value?: string
  style?: React.CSSProperties
  error?: boolean
  label?: string
  disabled?: boolean
  borderless?: boolean
  onDeleteIconPress?: () => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  disableOnWheel?: boolean
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

export const TextInput = ({
  type,
  onChange,
  onBlur,
  onFocus,
  className,
  wrapperClassName,
  placeholder,
  value,
  style,
  error,
  label,
  disabled,
  borderless = false,
  onDeleteIconPress,
  disableOnWheel,
  onKeyUp,
}: TextInputProps): JSX.Element => {
  const [focused, setFocused] = useState(false)
  const [hasValue, setHasValue] = useState(false)
  const [smallLabel, setSmallLabel] = useState(false)
  const [initAnimation, setInitAnimation] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    setHasValue(value !== '' && value !== undefined)
  }, [value])

  useEffect(() => {
    hasValue && setInitAnimation(true)
    setSmallLabel(focused || (!focused && hasValue))
  }, [focused, hasValue])

  useEffect(() => {
    smallLabel && setInitAnimation(true)
  }, [smallLabel])

  const onBlurEvent = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ): void => {
    setFocused(false)
    onBlur(e)
  }

  const onFocusEvent = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ): void => {
    setFocused(true)
    if (onFocus) {
      onFocus(e)
    }
  }

  const onEyeClick = (): void => {
    setShowPassword(!showPassword)
  }

  const animationClass = smallLabel ? 'animate-smallLabel' : 'animate-bigLabel'

  return (
    <div
      className={`flex flex-col gap-2.5
      ${disabled ? 'opacity-50' : ''}
      ${wrapperClassName ?? ''}`}
    >
      <div className="relative flex gap-4">
        <div
          style={theme.textVariants.lead}
          className={`text-gray-40 pointer-events-none absolute inset-4 h-min w-fit ${
            (initAnimation && animationClass) ?? ''
          }`}
        >
          <p>{!placeholder && label}</p>
        </div>
        <div className="flex grow">
          <input
            className={`w-full rounded border border-solid border-neutral-80
            px-[15px] hover:border-brandGreen/50 disabled:hover:border-neutral-80 ${
              className ?? ''
            }
            ${error ? 'focus:outline-red' : 'focus:outline-blue/50'}
            ${!placeholder && label ? 'h-14 pt-5' : 'h-12 pt-0'}
            ${borderless ? 'border-none focus:outline-0' : ''}
            ${type === 'password' ? 'pr-[52px]' : ''}
            ${
              type === 'number'
                ? // eslint-disable-next-line max-len
                  '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
                : ''
            }
            `}
            style={{
              ...theme.textVariants.base,
              ...style,
            }}
            {...getInputTypeProps(type ?? 'text', showPassword)}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlurEvent}
            onFocus={onFocusEvent}
            onKeyUp={onKeyUp}
            disabled={disabled}
            {...(disableOnWheel
              ? {
                  onWheel: (event: React.WheelEvent<HTMLInputElement>) =>
                    event.currentTarget.blur(),
                }
              : {})}
          />
          {type === 'password' && (
            <Icon
              className="absolute right-4 top-[13px]"
              icon={
                showPassword ? IconChoices.OPEN_EYE : IconChoices.CLOSED_EYE
              }
              onClick={onEyeClick}
            />
          )}
        </div>
        {onDeleteIconPress && (
          <Button
            className="h-12 w-12"
            icon={IconChoices.DELETE_TRASH}
            variant={ButtonVariant.DANGER}
            onClick={onDeleteIconPress}
          />
        )}
      </div>
    </div>
  )
}
