import React, { useCallback, useMemo, useReducer } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { TextArea } from '../../../common/TextArea'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import { useStores } from '../../../../hooks/useStores'
import {
  DropZone,
  DropZoneFile,
  DropZoneVariant,
} from '../../../common/DropZone'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { societyReportCreateSchema } from '../../../../forms/schemas/society_report_create'
import { ImageActions } from '../../../../actions/image-upload'
import { imageUploadReducer } from '../../../../reducers/image-upload'
import { ProcessedImage, ProcessedMedia } from '../../../../types/image-upload'
import { theme } from '../../../../theme/theme'
import { SelectDropdown } from '../../../common/SelectDropdown'
import { ReportProblemSpace } from '../../../../types/report-problem-space'
import { getReportProblemSpace } from '../../../../helpers/translations/report-problem-space'
import { ReportProblemFormImages } from './ReportProblemFormImages'

interface CreateReportProblemFormProps {
  onError: () => void
  onSubmit: (
    data: InferType<typeof societyReportCreateSchema>,
    mediaIds: string[]
  ) => Promise<boolean>
  loading: boolean
}

export const CreateReportProblemForm = observer(
  ({
    loading,
    onError,
    onSubmit,
  }: CreateReportProblemFormProps): JSX.Element => {
    const { authenticationStore, mediaStore } = useStores()
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { getErrorMessage } = useFormErrorMessage()
    const [imagesState, imagesDispatch] = useReducer(imageUploadReducer, {
      images: [],
    })

    const getDefaultValues = useCallback((): InferType<
      typeof societyReportCreateSchema
    > => {
      return {
        societyId: society._id,
        userId: authenticationStore.userId as string,
        message: '',
        space: 'apartment',
      }
    }, [society, authenticationStore])

    const {
      control,
      handleSubmit,
      reset,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyReportCreateSchema),
      defaultValues: getDefaultValues(),
    })

    const reportProblemSpaceOptions = useMemo(
      () =>
        Object.values(ReportProblemSpace).map((val) => ({
          value: val as string,
          label: translate(getReportProblemSpace(val) as string),
        })),
      [translate]
    )

    const uploadImage = async (file: DropZoneFile): Promise<void> => {
      try {
        const image: ProcessedImage = { ...file, type: 'image' }
        imagesDispatch({ type: ImageActions.ADD, image })

        const id = await mediaStore.createMedia('image', image.base64 as string)

        imagesDispatch({
          type: ImageActions.SET_UPLOADED,
          mediaId: id as string,
          image,
        })
      } catch (error) {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }
    }

    const deleteImage = async (image: ProcessedMedia): Promise<void> => {
      const id = image.id as string
      mediaStore.deleteMedia(id)
      imagesDispatch({ type: ImageActions.REMOVE, id })
    }

    const _onSubmit = async (
      data: InferType<typeof societyReportCreateSchema>
    ): Promise<void> => {
      const imageIds = imagesState.images
        .map((image) => image.id)
        .filter((id) => id !== undefined) as string[]
      const reportSent = await onSubmit(data, imageIds)

      if (reportSent) {
        reset()
        imagesDispatch({ type: ImageActions.RESET })
      }
    }

    const imagesAreBeingUploaded = imagesState.images
      .map((image) => image.uploading)
      .includes(true)

    return (
      <form
        onSubmit={handleSubmit(_onSubmit, onError)}
        className="flex w-full flex-col gap-3 2xl:w-1/3"
      >
        <Controller
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <FormControl
              label={translate('reportProblemView.form.labels.space')}
              name={name}
              error={errors.space && getErrorMessage(errors.space)}
            >
              <SelectDropdown
                value={value}
                onChange={onChange}
                options={reportProblemSpaceOptions}
                error={!!errors.space}
              />
            </FormControl>
          )}
          name="space"
        />

        <Controller
          control={control}
          name="message"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('reportProblemView.form.labels.message')}
              name={name}
              error={errors.message && getErrorMessage(errors.message)}
            >
              <TextArea
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                rows={10}
                error={!!errors.message}
              />
            </FormControl>
          )}
        />

        <div>
          <FormControl
            label={translate('reportProblemView.form.labels.images')}
            name="images"
          >
            <DropZone
              multiple
              onUpload={(status, file) => {
                if (status === 'accepted') {
                  uploadImage(file)
                } else {
                  setToastNotification(
                    ToastType.DANGER,
                    translate('flashMessage.somethingWentWrongError')
                  )
                }
              }}
              variant={DropZoneVariant.MEDIA}
            />
          </FormControl>
          <ReportProblemFormImages
            images={imagesState.images}
            deleteImage={deleteImage}
          />
        </div>

        <div className="flex flex-shrink-0 flex-wrap items-center">
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid || loading || imagesAreBeingUploaded}
            loading={loading}
            label={translate('common.actions.send')}
            type="submit"
          />
        </div>

        <p style={theme.textVariants.caption} className="text-neutral-40">
          {translate('reportProblemView.helperTexts.contactInformation')}
        </p>
      </form>
    )
  }
)
