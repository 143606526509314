import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { SearchSocietyModel } from '../../../../state/models/society'
import { Card } from '../../../common/Card'
import { CardBody } from '../../../common/Card/CardBody'
import { Icon, IconChoices } from '../../../common/Icon'
import { theme } from '../../../../theme/theme'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useStores } from '../../../../hooks/useStores'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { getSocietyType } from '../../../../helpers/translations/societyType'
import { SocietyTypes } from '../../../../types/society-type'

interface CardContentProps {
  society: SnapshotOut<typeof SearchSocietyModel>
}

const CardContent = observer(({ society }: CardContentProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const { societyStore, requestStore } = useStores()
  const userId = useAuthenticatedUserId() as string

  const userSocietyIds = societyStore.sortedSocieties.map(
    (_society) => _society._id
  )
  const userPendingRequestSocietyIds =
    requestStore.userPendingSocietyRequests(userId)

  const societyIsActive = society.status === 'active'

  const userMemberOfSociety = userSocietyIds.includes(society._id)
  const userHasPendingRequest = userPendingRequestSocietyIds.includes(
    society._id
  )

  const getIconText = (): string => {
    if (userHasPendingRequest) {
      return translate('findSociety.labels.hasPendingRequest')
    }
    if (userMemberOfSociety) {
      return translate('findSociety.labels.userMemberOfSociety')
    }
    if (societyIsActive) {
      return translate('common.active')
    }

    return ''
  }

  const iconText = getIconText()

  const societyType = society?.societyTypeDisplayName
    ? translate(
        getSocietyType(society.societyTypeDisplayName as SocietyTypes) as string
      )
    : undefined

  const showIcon =
    societyIsActive || userMemberOfSociety || userHasPendingRequest

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex flex-col items-start">
        <p style={theme.textVariants.baseBold} translate="no">
          {society?.name}
        </p>
        <p style={theme.textVariants.caption}>{`${societyType} ${translate(
          'common.in'
        )} ${society?.addressCity}`}</p>
      </div>
      {showIcon && (
        <div className="flex w-10 flex-col items-center">
          <Icon
            icon={IconChoices.CHECKMARK_ROUND}
            color={theme.colors.semanticGreen}
          />
          <p style={theme.textVariants.captionBold}>{iconText}</p>
        </div>
      )}
    </div>
  )
})

interface FindSocietyListItemProps {
  society: SnapshotOut<typeof SearchSocietyModel>
  selected: boolean
  onClick?: () => void
}

export const FindSocietyListItem = observer(
  ({ society, selected, onClick }: FindSocietyListItemProps): JSX.Element => {
    return (
      <Card
        className={`border-none shadow-none ${selected ? 'bg-neutral-96' : ''}`}
        onClick={onClick}
      >
        {{
          body: (
            <CardBody>
              <CardContent society={society} />
            </CardBody>
          ),
        }}
      </Card>
    )
  }
)
