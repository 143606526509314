import { sv, enGB } from 'date-fns/locale' // eslint-disable-line import/no-duplicates
import {
  addDays,
  format,
  formatRelative as dateFnsFormatRelative,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns'
import { captureException } from '@sentry/react'
import i18next from '../i18n/i18n'

export const formatDate = (
  date: number | Date,
  dateType: 'long' | 'short' = 'long'
): string => {
  if (!date) {
    return ''
  }
  const dateFormat =
    dateType === 'long' ? "d MMMM yyyy 'kl.' HH:mm" : "MMM d',' yyyy HH:mm"

  return format(date, dateFormat, {
    locale: i18next.language === 'en' ? enGB : sv,
  })
}

export const formatDateWithoutTime = (date: number | Date): string => {
  if (!date) {
    return ''
  }
  const dateFormat = "d MMMM',' yyyy"

  return format(date, dateFormat, {
    locale: i18next.language === 'en' ? enGB : sv,
  })
}

export const getDateDate = (date: number | Date): string => {
  return format(date, 'd MMMM yyyy', {
    locale: i18next.language === 'en' ? enGB : sv,
  })
}

export const getDateTime = (date: number | Date): string => {
  return format(date, 'HH:mm', {
    locale: i18next.language === 'en' ? enGB : sv,
  })
}

export const getDateDay = (date: number | Date): string => {
  return format(date, 'eeee', {
    locale: i18next.language === 'en' ? enGB : sv,
  })
}

export const ignoreTimezoneDate = (date: Date): Date => {
  return new Date(
    // Ignore timezone
    date.valueOf() + date.getTimezoneOffset() * 60 * 1000
  )
}

export const dateTimeAsUTCWithoutCompensation = (date: Date): Date => {
  return new Date(
    // Ignore timezone
    date.valueOf() + Math.abs(date.getTimezoneOffset() * 60 * 1000)
  )
}

export const formatDateWithFormat = (
  date: number | Date,
  _format: string
): string => {
  try {
    return format(date, _format, {
      locale: i18next.language === 'en' ? enGB : sv,
    })
  } catch (error) {
    captureException(error, { extra: { date, format: _format } })
    return date.toString()
  }
}

export const formatRelative = (date: number | Date): string => {
  return dateFnsFormatRelative(new Date(date), new Date(), {
    locale: i18next.language === 'en' ? enGB : sv,
  })
}

export const sameDay = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}

export const isDateToday = (date: Date): boolean => {
  return sameDay(date, new Date())
}

export const parsedOrNewDate = (val: string | undefined): Date => {
  return val ? new Date(val) : new Date()
}

export const parsedDateOrUndefined = (
  val: string | undefined | null
): Date | undefined => {
  return val ? new Date(val) : undefined
}

export const nearestHourDate = (): Date => {
  const _date = new Date()
  _date.setMinutes(_date.getMinutes() + 30)
  _date.setMinutes(0)
  return _date
}

export const daysBetween = (date1: Date, date2: Date): number => {
  const utcDate1 = Date.UTC(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate()
  )
  const utcDate2 = Date.UTC(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate()
  )

  return Math.ceil((utcDate2 - utcDate1) / (1000 * 3600 * 24))
}

export const getTimestamp = (date: Date): string | undefined => {
  if (!date) {
    return undefined
  }

  if (isDateToday(date)) {
    return formatDateWithFormat(date, 'HH:mm')
  }
  if (date > addDays(new Date(), -7)) {
    return formatDateWithFormat(date, 'EEEE HH:mm')
  }
  return formatDateWithFormat(date, 'EEEE, d MMM yyyy')
}
