import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import {
  getEducationDegree,
  getEducationField,
  getEducationLevel,
} from '../../../../helpers/translations/education'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { UserModelEducation } from '../../../../state/models/user'
import { theme } from '../../../../theme/theme'
import {
  EducationDegree,
  EducationField,
  EducationLevel,
} from '../../../../types/education'

interface UserEducationProps {
  education: SnapshotOut<typeof UserModelEducation> | undefined
}

export const UserEducation = observer(({ education }: UserEducationProps) => {
  const { translate } = useAppTranslation()

  const degree = education?.degree
    ? translate(
        getEducationDegree(education.degree as EducationDegree) as string
      )
    : null

  const field = education?.field
    ? translate(getEducationField(education.field as EducationField) as string)
    : null

  const level = education?.field
    ? translate(getEducationLevel(education.level as EducationLevel) as string)
    : null

  const degreeFieldText = [degree, field]
    .filter((str) => str !== null && str !== '')
    .join('. ')

  if (
    education === undefined ||
    education === null ||
    [education.level, education.degree, education.institute, education.field]
      .map((str) => str || null)
      .filter((str) => str !== null).length === 0
  ) {
    return null
  }

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex items-center gap-3">
        <p className="text-neutral-30" style={theme.textVariants.caption}>
          {translate('userView.educationTitle')}
        </p>
      </div>
      <div className="flex flex-col gap-3">
        {level && <p style={theme.textVariants.base}>{level}</p>}
        {degreeFieldText && (
          <p style={theme.textVariants.base}>{degreeFieldText}</p>
        )}
        {education.institute && (
          <p style={theme.textVariants.base}>{education.institute}</p>
        )}
      </div>
    </div>
  )
})
