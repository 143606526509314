import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { captureException } from '@sentry/react'
import { Modal } from '../../../../../common/Modal'
import { ModalHeader } from '../../../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../../../hooks/useAppTranslation'
import { useStores } from '../../../../../../hooks/useStores'
import { ToastType } from '../../../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../../../hooks/useToastNotification'
import { CreateUpdateQuestionsAndAnswersItemForm } from '../CreateUpdateQuestionsAndAnswersItemForm'
// eslint-disable-next-line max-len
import { societyWebsiteFAQQuestionCreateSchema } from '../../../../../../forms/schemas/society_website_faq_question_create'
import { NFaqs } from '../../../../../../interfaces/services/faqs.interfaces'
import { useCurrentSociety } from '../../../../../../hooks/useCurrentSociety'

interface CreateUpdateQuestionsAndAnswersItemModalProps {
  show: boolean
  close: () => void
  children?: React.ReactNode
  questionId?: string
  sectionId?: string
}

export const CreateUpdateQuestionsAndAnswersItemModal = observer(
  ({
    questionId,
    sectionId,
    children,
    show,
    close,
  }: CreateUpdateQuestionsAndAnswersItemModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { faqsStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()

    const updateMode = !!questionId

    const showErrorMessage = (): void => {
      const message = updateMode
        ? 'createUpdateQuestionsAndAnswers.flashMessage.updateQuestionFailure'
        : 'createUpdateQuestionsAndAnswers.flashMessage.createQuestionFailure'
      setToastNotification(ToastType.DANGER, translate(message))
    }

    const showSuccessMessage = (): void => {
      const message = updateMode
        ? 'createUpdateQuestionsAndAnswers.flashMessage.updateQuestionSuccess'
        : 'createUpdateQuestionsAndAnswers.flashMessage.createQuestionSuccess'
      setToastNotification(ToastType.SUCCESS, translate(message))
    }

    const onSubmit = async (
      data: InferType<typeof societyWebsiteFAQQuestionCreateSchema>
    ): Promise<void> => {
      if (updateMode) {
        const status =
          sectionId &&
          (await faqsStore.patchFaqQuestion(questionId, data, sectionId))

        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      } else {
        const status =
          sectionId &&
          (await faqsStore.createFaqQuestion(
            {
              ...data,
              societyId: society?._id,
            } as NFaqs.NCreate.IRequestBody,
            sectionId
          ))
        if (status) {
          close()
          showSuccessMessage()
        } else {
          showErrorMessage()
        }
      }
    }

    const onError = (error: unknown): void => {
      captureException(error)
      showErrorMessage()
    }

    const loading =
      faqsStore.creatingFAQ === 'pending' || faqsStore.updatingFAQ === 'pending'

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate(
                  updateMode
                    ? 'createUpdateQuestionsAndAnswers.editQuestionTitle'
                    : 'createUpdateQuestionsAndAnswers.addNewQuestionTitle'
                )}
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <CreateUpdateQuestionsAndAnswersItemForm
                  onError={onError}
                  onSubmit={onSubmit}
                  onClose={close}
                  loading={loading}
                  sectionId={sectionId}
                  questionId={questionId}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
