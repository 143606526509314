import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useAtom } from 'jotai'
import { useStores } from '../../../hooks/useStores'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { ResidentUserType } from '../../../state/stores/units'
import { RegisterMemberBadgeCardBase } from './RegisterMemberBadgeCardBase'
import { RequestsModal } from './RequestsModal'
import { useModal } from '../../../hooks/useModal'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import {
  SocietyNavigationState,
  societyNavigationStateAtom,
} from '../../../views/society/atom'

interface RegisterMemberRequestCardProps {
  residentUserType: ResidentUserType
}

export const RegisterMemberRequestCard = observer(
  ({
    residentUserType,
  }: RegisterMemberRequestCardProps): JSX.Element | null => {
    const { translate } = useAppTranslation()
    const { requestStore } = useStores()
    const [societyNavigationState, setSocietyNavigationState] = useAtom(
      societyNavigationStateAtom
    )
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { show: showModal, open: openModal, close: closeModal } = useModal()

    const requests = requestStore
      .requestsForSociety(society._id, residentUserType)
      .filter((request) => request.status !== 'accepted')

    useEffect(() => {
      if (societyNavigationState === SocietyNavigationState.SocietyRequests) {
        openModal()
      }
    }, [openModal, societyNavigationState])

    const onClose = (): void => {
      setSocietyNavigationState(SocietyNavigationState.None)
      closeModal()
    }

    if (requests.length === 0) {
      return null
    }

    return (
      <RequestsModal show={showModal} close={onClose} requests={requests}>
        <RegisterMemberBadgeCardBase
          onClick={openModal}
          count={requests.length}
          label={translate('registerRequest.modal.title')}
        />
      </RequestsModal>
    )
  }
)
