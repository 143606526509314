/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import {
  getWidgetsPerSociety as apiGetWidgetsPerSociety,
  createWidget as apiCreateWidget,
  getWidget as apiGetWidget,
  patchWidget as apiPatchWidget,
  deleteWidget as apiDeleteWidget,
} from '../../api/society-widgets'
import { stateType } from '../types/common'
import { setObject } from './helpers'
import { SocietyWidgetModel } from '../models/society-widget'
import { ISocietyWidgetModel } from '../../interfaces/models/society-widgets.interfaces'
import { NSocietyWidgets } from '../../interfaces/services/society-widgets.interfaces'
import { prependHttp } from '../../helpers/url'

export const SocietyWidgetStore = types
  .model('SocietyWidgetStore')
  .props({
    widgets: types.map(SocietyWidgetModel),
    fetchingWidgets: stateType,
    updatingWidget: stateType,
    creatingWidget: stateType,
    deletingWidget: stateType,
  })
  .views((self) => ({
    widgetsForSociety(
      societyId: string
    ): SnapshotOut<typeof SocietyWidgetModel>[] {
      return (
        // @ts-ignore
        (values(self.widgets) as SnapshotOut<typeof SocietyWidgetModel>[]) //
          .filter((widget) => widget.societyId === societyId)
      )
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.widgets.clear()
      self.fetchingWidgets = 'none'
      self.updatingWidget = 'none'
      self.creatingWidget = 'none'
      self.deletingWidget = 'none'
    },
    setWidgets: (widgets: ISocietyWidgetModel[]) => {
      widgets.forEach((widget) => {
        // @ts-ignore
        setObject<typeof SocietyWidgetModel>(self.widgets, SocietyWidgetModel, {
          ...widget,
          society: widget.societyId,
        })
      })
    },
  }))
  .actions((self) => ({
    getWidgetsPerSociety: flow(function* getWidgetsPerSociety(
      societyId: string
    ) {
      self.fetchingWidgets = 'pending'
      try {
        const resp = yield apiGetWidgetsPerSociety(societyId)
        const data = resp.data as NSocietyWidgets.NGetPerSocieties.IResponse
        const widgets = data.data

        self.setWidgets(widgets)

        self.fetchingWidgets = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingWidgets = 'error'
      }
    }),
    createWidget: flow(function* createWidget(
      body: NSocietyWidgets.NCreate.IRequestBody
    ) {
      self.creatingWidget = 'pending'
      try {
        const resp = yield apiCreateWidget({
          ...body,
          ...(body.link ? { link: prependHttp(body.link) } : {}),
        })
        const data = resp.data as NSocietyWidgets.NCreate.IResponse
        const widget = data.data

        if (widget !== null) {
          self.setWidgets([widget])
        }

        self.creatingWidget = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingWidget = 'error'
        return false
      }
    }),
    getWidget: flow(function* getWidget(id: string) {
      self.fetchingWidgets = 'pending'
      try {
        const resp = yield apiGetWidget(id)
        const data = resp.data as NSocietyWidgets.NGetById.IResponse
        const widget = data.data

        self.setWidgets([widget])

        self.fetchingWidgets = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingWidgets = 'error'
      }
    }),
    updateWidget: flow(function* updateWidget(
      id: string,
      body: NSocietyWidgets.NPatch.IRequestBody
    ) {
      self.updatingWidget = 'pending'
      try {
        const resp = yield apiPatchWidget(id, {
          ...body,
          ...(body.link ? { link: prependHttp(body.link) } : {}),
        })
        const data = resp.data as NSocietyWidgets.NPatch.IResponse
        const widget = data.data

        if (widget !== null) {
          self.setWidgets([widget])
        }

        self.updatingWidget = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingWidget = 'error'
        return false
      }
    }),
    deleteWidget: flow(function* deleteWidget(id: string) {
      self.deletingWidget = 'pending'
      try {
        yield apiDeleteWidget(id)
        self.widgets.delete(id)
        self.deletingWidget = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.deletingWidget = 'error'
        return false
      }
    }),
  }))
