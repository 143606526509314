import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { SocietyModel } from '../../../state/models/society'
import { useStores } from '../../../hooks/useStores'
import { theme } from '../../../theme/theme'
import { Card } from '../../common/Card'

interface StatisticsSectionProps {
  society: SnapshotOut<typeof SocietyModel>
}

export const StatisticsSection = observer(
  ({ society }: StatisticsSectionProps): JSX.Element => {
    const { statisticsStore } = useStores()
    const { translate } = useAppTranslation()

    const statistics = statisticsStore.statistics.get(society._id)

    const cards = [
      ...(statistics?.totalUsers
        ? [
            {
              title: translate(
                'societyStatisticsExportsView.statistics.numberLogins'
              ),
              text: statistics.totalUsers,
            },
          ]
        : []),
      ...(statistics?.posts
        ? [
            {
              title: translate(
                'societyStatisticsExportsView.statistics.numberPosts'
              ),
              text: statistics.posts,
            },
          ]
        : []),
      ...(statistics?.postLikes
        ? [
            {
              title: translate(
                'societyStatisticsExportsView.statistics.numberLikes'
              ),
              text: statistics.postLikes,
            },
          ]
        : []),
      ...(statistics?.postComments
        ? [
            {
              title: translate(
                'societyStatisticsExportsView.statistics.numberComments'
              ),
              text: statistics.postComments,
            },
          ]
        : []),
    ]

    return (
      <div>
        <p className="my-4" style={theme.textVariants.lead}>
          {translate('societyStatisticsExportsView.statistics.headerTitle')}
        </p>
        <div className="grid grid-cols-1 gap-4 md:max-w-5xl md:grid-cols-2">
          {cards.map((_card) => {
            return (
              <Card key={_card.title} className="w-full max-w-none">
                {{
                  body: (
                    <div className="flex flex-1 flex-col p-4 text-left">
                      <p style={theme.textVariants.h2}>{_card.text}</p>
                      <p
                        style={theme.textVariants.base}
                        className="mt-1 text-neutral-40"
                      >
                        {_card.title}
                      </p>
                    </div>
                  ),
                }}
              </Card>
            )
          })}
        </div>
      </div>
    )
  }
)
