/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { types, flow, getRoot, SnapshotOut } from 'mobx-state-tree'
import { values } from 'mobx'
import { captureException } from '@sentry/react'
import {
  createFacility as apiCreateFacility,
  getFacility as apiGetFacility,
  deleteFacility as apiDeleteFacility,
  getFacilitiesPerSociety as apiGetFacilitiesPerSociety,
  patchFacility as apiPatchFacility,
} from '../../api/facilities'
import { stateType } from '../types/common'
import { RootStore } from './root'
import { setObject } from './helpers'
import { FacilityModel } from '../models/facility'
import { NFacilities } from '../../interfaces/services/facilities.interfaces'
import { IFacilityModel } from '../../interfaces/models/facilities.interfaces'
import { sortStringsAlphabetically } from '../../helpers/sorting'
import { isUserAdminInSociety } from '../../helpers/society'
import { SocietyModel } from '../models/society'

export const FacilityStore = types
  .model('FacilityStore')
  .props({
    facilities: types.map(FacilityModel),
    fetchingFacilities: stateType,
    fetchingFacility: stateType,
    deletingFacility: stateType,
    creatingFacility: stateType,
    updatingFacility: stateType,
  })
  .views((self) => ({
    get sortedFacilities() {
      return (
        // @ts-ignore
        (values(self.facilities) as SnapshotOut<typeof FacilityModel>[]).sort(
          (a, b) => sortStringsAlphabetically(a.name, b.name)
        )
      )
    },
  }))
  .views((self) => ({
    facilitiesForSociety(
      societyId: string
    ): SnapshotOut<typeof FacilityModel>[] {
      return self.sortedFacilities.filter((facility) => {
        if (facility.societyId !== societyId) {
          return false
        }

        if (facility.visibility === 'all') {
          return true
        }

        const { userStore, societyStore } = getRoot<RootStore>(self)
        const userId = userStore.currentUser()?._id
        if (!userId) {
          return false
        }
        const society = societyStore.societies.get(societyId) as
          | SnapshotOut<typeof SocietyModel>
          | undefined

        return isUserAdminInSociety(society, userId)
      })
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.facilities.clear()
      self.fetchingFacilities = 'none'
      self.fetchingFacility = 'none'
      self.deletingFacility = 'none'
      self.creatingFacility = 'none'
      self.updatingFacility = 'none'
    },
    setFacilities: (facilities: IFacilityModel[]) => {
      facilities.forEach((facility) => {
        setObject<typeof FacilityModel>(
          // @ts-ignore
          self.facilities,
          FacilityModel,
          facility
        )
      })
    },
  }))
  .actions((self) => ({
    createFacility: flow(function* createFacility(
      body: NFacilities.NCreate.IRequestBody
    ) {
      self.creatingFacility = 'pending'
      try {
        const resp = yield apiCreateFacility(body)
        const data = resp.data as NFacilities.NCreate.IResponse
        const facility = data.data

        if (facility !== null) {
          self.setFacilities([facility])
        }

        self.creatingFacility = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.creatingFacility = 'error'
        return false
      }
    }),
    getFacility: flow(function* getFacility(id: string) {
      self.fetchingFacility = 'pending'
      try {
        const resp = yield apiGetFacility(id)
        const data = resp.data as NFacilities.NGetById.IResponse
        const facility = data.data
        const { media } = data.populated

        const { mediaStore } = getRoot<RootStore>(self)
        if (media) {
          mediaStore.setMedia(media)
        }

        self.setFacilities([facility])

        self.fetchingFacility = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingFacility = 'error'
      }
    }),
    patchFacility: flow(function* patchFacility(
      id: string,
      body: NFacilities.NPatch.IRequestBody
    ) {
      self.updatingFacility = 'pending'
      try {
        const resp = yield apiPatchFacility(id, body)
        const data = resp.data as NFacilities.NPatch.IResponse
        const facility = data.data

        if (facility) {
          self.setFacilities([facility])
        }

        self.updatingFacility = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.updatingFacility = 'error'
        return false
      }
    }),
    deleteFacility: flow(function* deleteFacility(id: string) {
      self.deletingFacility = 'pending'
      try {
        yield apiDeleteFacility(id)

        const { postStore } = getRoot<RootStore>(self)

        const postsReferringFacility = postStore.sortedPosts
          .filter((_post) => _post.facilitiesIds.includes(id))
          .map((_post) => _post._id)
        postsReferringFacility.forEach((_id) => postStore.deletePost(_id))

        self.facilities.delete(id)
        self.deletingFacility = 'done'
        return true
      } catch (error) {
        captureException(error)
        self.deletingFacility = 'error'
        return false
      }
    }),
    getFacilitiesPerSociety: flow(function* getFacilitiesPerSociety(
      societyId: string
    ) {
      self.fetchingFacilities = 'pending'
      try {
        const resp = yield apiGetFacilitiesPerSociety(societyId)
        const data = resp.data as NFacilities.NGetPerSocieties.IResponse
        const facilities = data.data
        const { media } = data.populated

        const { mediaStore } = getRoot<RootStore>(self)
        if (media) {
          mediaStore.setMedia(media)
        }

        self.setFacilities(facilities)

        self.fetchingFacilities = 'done'
      } catch (error) {
        captureException(error)
        self.fetchingFacilities = 'error'
      }
    }),
  }))
