import { useCallback } from 'react'
import { reverseUrl } from '../../../../navigation/reverseUrl'
import { InvoiceViewTabs } from './InvoiceView'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'

type useInvoiceTabsReturnType = {
  getTabUrl: (tab: InvoiceViewTabs) => string
  getTabState: (pathname: string) => InvoiceViewTabs
}

export const useInvoiceTabs = (): useInvoiceTabsReturnType => {
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const id = society._id

  const getTabUrl = useCallback(
    (tab: InvoiceViewTabs): string => {
      switch (tab) {
        case InvoiceViewTabs.PAYMENT:
          return reverseUrl('management:billing-payment', { id })
        case InvoiceViewTabs.DETAILS:
          return reverseUrl('management:billing-details', { id })
        default:
          return reverseUrl('management:billing-payment', { id })
      }
    },
    [id]
  )

  const getTabState = useCallback(
    (pathname: string): InvoiceViewTabs => {
      if (pathname === reverseUrl('management:billing-payment', { id })) {
        return InvoiceViewTabs.PAYMENT
      }
      if (pathname === reverseUrl('management:billing-details', { id })) {
        return InvoiceViewTabs.DETAILS
      }
      return InvoiceViewTabs.PAYMENT
    },
    [id]
  )

  return {
    getTabUrl,
    getTabState,
  }
}
