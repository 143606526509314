import React from 'react'
import { observer } from 'mobx-react-lite'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../../common/Icon'
import { Toggle } from '../../common/Toggle'

interface FunctionSettingsRowProps {
  label: string
  name: string
  icon: IconChoices
  disabled?: boolean
  value?: boolean
  onChange?: (value: boolean) => void
}

export const FunctionSettingsRow = observer(
  ({
    label,
    name,
    icon,
    disabled,
    value,
    onChange,
  }: FunctionSettingsRowProps): JSX.Element => {
    return (
      <div className="flex w-full items-center rounded bg-neutral-96 px-4 py-2">
        <div className="flex flex-1 items-center">
          <div className="rounded-full bg-white p-2">
            <Icon icon={icon} />
          </div>
          <p className="ml-2 mr-4" style={theme.textVariants.baseBold}>
            {label}
          </p>
        </div>
        <Toggle
          disabled={disabled}
          name={name}
          enabled={value || false}
          // eslint-disable-next-line
          onChange={onChange ? onChange : () => {}}
        />
      </div>
    )
  }
)
