import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { SocietyContactModel } from '../../../../state/models/society-contact'
import { PostCallout } from '../PostCallout'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ContactItem } from './ContactItem'

interface PostContactsProps {
  societyContacts?: SnapshotOut<typeof SocietyContactModel>[]
  boardroomContacts?: SnapshotOut<typeof SocietyContactModel>[]
}

export const PostContacts = observer(
  ({
    societyContacts,
    boardroomContacts,
  }: PostContactsProps): JSX.Element | null => {
    const { translate } = useAppTranslation()
    if (societyContacts?.length === 0 && boardroomContacts?.length === 0) {
      return null
    }

    return (
      <div className="flex flex-col gap-4">
        <PostCallout text={translate('post.newContactText')} />
        {societyContacts?.map((_contact) => (
          <ContactItem key={_contact._id} contact={_contact} type="society" />
        ))}
        {boardroomContacts?.map((_contact) => (
          <ContactItem
            key={_contact._id}
            contact={_contact}
            type="management"
          />
        ))}
      </div>
    )
  }
)
