import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { PollModel, PollOptionModel } from '../../../state/models/poll'
import { RadioButton } from '../../common/RadioButton'
import { ResultBar } from './ResultBar'
import { Spinner } from '../../common/Spinner'

interface PollProps {
  option: SnapshotOut<typeof PollOptionModel>
  poll: SnapshotOut<typeof PollModel>
  index: number
  userVoted: boolean
  householdMemberVoted: boolean
  showResults: boolean
  selectedValue: string | null
  setSelectedValue: React.Dispatch<React.SetStateAction<string | null>>
  vote: (option: SnapshotOut<typeof PollOptionModel>) => Promise<void>
  loading?: boolean
  disabled?: boolean
}

export const Poll = ({
  option,
  poll,
  index,
  userVoted,
  householdMemberVoted,
  showResults,
  selectedValue,
  setSelectedValue,
  vote,
  loading,
  disabled,
}: PollProps): JSX.Element => {
  const onClick = (): void => {
    if (!disabled) {
      setSelectedValue(option.text)
      vote(option)
    }
  }

  const shouldDisplayResultOption =
    userVoted || householdMemberVoted || showResults

  return shouldDisplayResultOption ? (
    <ResultBar option={option} poll={poll} />
  ) : (
    <button
      tabIndex={index}
      className={`flex h-[50px] items-center rounded-md border border-neutral-80 p-3
        ${
          option.text === selectedValue
            ? 'border-brandGreen'
            : 'border-neutral-80'
        }
      `}
      onClick={onClick}
      disabled={loading}
    >
      <div className="flex w-fit">
        {loading && option.text === selectedValue ? (
          <Spinner />
        ) : (
          <RadioButton
            name={option.text}
            label={option.text}
            value={option.text}
            checked={option.text === selectedValue}
            onChange={onClick}
            disabled={loading || disabled}
          />
        )}
      </div>
    </button>
  )
}
