import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { UserModel } from '../../../state/models/user'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { Badge } from '../../common/Badge'
import { theme } from '../../../theme/theme'

interface AnonymousAdminBoardAuthorProps {
  roleString: string
}

const AnonymousAdminBoardAuthor = ({
  roleString,
}: AnonymousAdminBoardAuthorProps): JSX.Element => {
  return (
    <Badge
      padding="px-1.5"
      colorClass="bg-brandGreen/10"
      borderColorClass="border-brandGreen"
      textColorClass="text-black"
      text={roleString}
      className="h-5"
    />
  )
}

interface NonAnonymousAdminBoardAuthorProps {
  roleString: string
  author?: SnapshotOut<typeof UserModel>
}

const NonAnonymousAdminBoardAuthor = ({
  roleString,
  author,
}: NonAnonymousAdminBoardAuthorProps): JSX.Element => {
  const { translate } = useAppTranslation()
  return (
    <div className="flex items-center gap-1">
      <AnonymousAdminBoardAuthor roleString={roleString} />
      <p style={theme.textVariants.base}>
        {translate('society.postedBy')} {author?.fullName}
      </p>
    </div>
  )
}

interface AuthorTextProps {
  role: string
  author?: SnapshotOut<typeof UserModel>
}

const AuthorText = observer(
  ({ role, author }: AuthorTextProps): JSX.Element => {
    const { translate } = useAppTranslation()
    switch (role) {
      case 'admin':
        return (
          <NonAnonymousAdminBoardAuthor
            roleString={translate('society.roles.admin')}
            author={author}
          />
        )
      case 'admin-society':
        return (
          <AnonymousAdminBoardAuthor
            roleString={translate('society.roles.admin')}
          />
        )
      case 'board-member':
        return (
          <NonAnonymousAdminBoardAuthor
            roleString={translate('society.roles.board')}
            author={author}
          />
        )
      case 'board-society':
        return (
          <AnonymousAdminBoardAuthor
            roleString={translate('society.roles.board')}
          />
        )
      default:
        return <p style={theme.textVariants.base}>{author?.fullName || ''}</p>
    }
  }
)

interface PostAuthorProps {
  role: string
  author?: SnapshotOut<typeof UserModel>
}

const AuthorHeader = observer(
  ({ role, author }: PostAuthorProps): JSX.Element => {
    return (
      <div className="inline-block overflow-hidden text-ellipsis whitespace-nowrap">
        <AuthorText role={role} author={author} />
      </div>
    )
  }
)

export const PostAuthor = observer(({ role, author }: PostAuthorProps) => {
  return <AuthorHeader role={role} author={author} />
})

interface PostAuthorWithIdProps {
  role: string
  authorId: string
}

export const PostAuthorWithId = observer(
  ({ role, authorId }: PostAuthorWithIdProps) => {
    const { userStore } = useStores()
    const user = userStore.users.get(authorId) as SnapshotOut<typeof UserModel>
    return <AuthorHeader role={role} author={user} />
  }
)
