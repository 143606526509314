import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import Fuse from 'fuse.js'
import { SnapshotOut } from 'mobx-state-tree'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ViewBase } from '../../../../components/common/ViewBase'
import { DocumentsList } from '../../../../components/society/Documents/DocumentsList'
import { ButtonVariant } from '../../../../components/common/Button'
import { IconChoices } from '../../../../components/common/Icon'
import { useModal } from '../../../../hooks/useModal'
import { isUserAdminInSociety } from '../../../../helpers/society'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useStores } from '../../../../hooks/useStores'
import { DocumentModel } from '../../../../state/models/document'
import { IllustrationChoices } from '../../../../components/common/Illustration'
import { DocumentType } from '../../../../components/society/Documents/DocumentsList/document_type'
import { CreateUpdateFolderModal } from '../../../../components/society/Documents/CreateUpdateFolderModal'
import { CreateUpdateDocumentModal } from '../../../../components/society/Documents/CreateUpdateDocumentModal'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'

export const DocumentsView = observer((): JSX.Element => {
  const { documentStore } = useStores()
  const { translate } = useAppTranslation()
  const { authenticationStore } = useStores()
  const { documentId } = useParams()
  const [searchString, setSearchString] = useState('')
  const {
    show: showCreateFolderModal,
    open: openCreateFolderModal,
    close: closeCreateFolderModal,
  } = useModal()
  const {
    show: showCreateDocumentModal,
    open: openCreateDocumentModal,
    close: closeCreateDocumentModal,
  } = useModal()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }

  const rootRoute = 'society:documents'
  const documentRoute = 'society:documents-detail'

  const isAdmin = isUserAdminInSociety(
    society,
    authenticationStore.userId as string
  )

  useEffect(() => {
    if (society._id) {
      documentStore.getDocumentsForSociety(society._id)
    }
  }, [documentStore, society._id])

  const section = DocumentType.SOCIETY

  const onChangeSearchString = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    setSearchString(event.currentTarget.value)
  }

  const getRootSubDocuments = (): SnapshotOut<typeof DocumentModel>[] => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const rootDocument = documentStore.getRootDocument(society._id, section)

    if (rootDocument) {
      return documentStore.getAllSubDocuments(rootDocument._id)
    }
    return []
  }

  const getDocuments = (): SnapshotOut<typeof DocumentModel>[] => {
    return documentId
      ? documentStore.getSocietyDocuments(
          society._id,
          documentId,
          undefined,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          section
        )
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        documentStore.getRootDocuments(society._id, section)
  }

  const rootSubDocuments = getRootSubDocuments()
  const documents = getDocuments()

  const isRoot = !documentId
  const currentDocument = documentId
    ? documentStore.documents.get(documentId)
    : undefined

  useDocumentTitle(
    isRoot
      ? reverseDocumentTitle('society:documents', {
          '{{ societyName }}': society.name,
        })
      : reverseDocumentTitle('society:documents-detail', {
          '{{ societyName }}': society.name,
          '{{ documentName }}': currentDocument?.name,
        })
  )

  const getRootDocumentId = (): string => {
    if (isRoot && documents.length > 0) {
      const firstChild = documents[0]
      const { parentId } = firstChild
      return parentId || ''
    }
    return ''
  }

  const rootDocumentId = getRootDocumentId()

  const fuseOptions = {
    threshold: 0.3,
    keys: ['name'],
  }
  const fuse = new Fuse(documents, fuseOptions)

  const searchDocuments = fuse.search(searchString).map((result) => result.item)

  const loading =
    documentStore.fetchingDocuments === 'none' ||
    documentStore.fetchingDocuments === 'pending'

  const errorView =
    !loading && rootSubDocuments.length === 0
      ? {
          title: translate('documentsList.emptyState.title'),
          subtitle: translate('documentsList.emptyState.noDocumentsSubtitle'),
          illustration: IllustrationChoices.CHECKLIST,
        }
      : undefined

  return (
    <ErrorBoundary>
      <ViewBase
        searchBar={{ value: searchString, onChange: onChangeSearchString }}
        title={translate('documentsView.title')}
        buttons={[
          ...(isAdmin
            ? [
                {
                  label: translate('documentsView.addNewFolder'),
                  variant: ButtonVariant.PRIMARY,
                  icon: IconChoices.FOLDER,
                  iconSize: 20,
                  onClick: () => openCreateFolderModal(),
                },
                {
                  label: translate('documentsView.addNewDocument'),
                  variant: ButtonVariant.PRIMARY,
                  icon: IconChoices.DOCUMENT_ADD,
                  onClick: () => openCreateDocumentModal(),
                },
              ]
            : []),
        ]}
        loading={loading}
        errorView={errorView}
      >
        <DocumentsList
          section={DocumentType.SOCIETY}
          documentId={documentId}
          rootRoute={rootRoute}
          documentRoute={documentRoute}
          rootSubDocuments={rootSubDocuments}
          documents={searchString === '' ? documents : searchDocuments}
          noSearchResults={searchString !== '' && searchDocuments.length === 0}
        />
      </ViewBase>
      <CreateUpdateFolderModal
        show={showCreateFolderModal}
        close={closeCreateFolderModal}
        parentId={isRoot ? rootDocumentId : documentId}
        section={section}
      />
      <CreateUpdateDocumentModal
        show={showCreateDocumentModal}
        close={closeCreateDocumentModal}
        parentId={isRoot ? rootDocumentId : documentId}
        section={section}
      />
    </ErrorBoundary>
  )
})
