import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../../hooks/useStores'
import { BookingModel } from '../../../../state/models/booking'
import { UserModel } from '../../../../state/models/user'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ConfirmationModal } from '../../../common/ConfirmationModal'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'

interface DeleteBookingConfirmationModalProps {
  showModal: boolean
  closeModal: () => void
  booking: SnapshotOut<typeof BookingModel>
  otherUsersOwnsBooking?: boolean
}

export const DeleteBookingConfirmationModal = observer(
  ({
    showModal,
    closeModal,
    booking,
    otherUsersOwnsBooking,
  }: DeleteBookingConfirmationModalProps): JSX.Element | null => {
    const { userStore, bookingsStore } = useStores()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()

    const user = userStore.users.get(booking.userId) as SnapshotOut<
      typeof UserModel
    >

    const deleteBooking = async (): Promise<void> => {
      const status = await bookingsStore.deleteBooking(booking._id)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('facilityView.bookings.flashMessage.deleteBookingSuccess')
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('facilityView.bookings.flashMessage.deleteBookingFailure')
        )
      }
    }

    return (
      <ConfirmationModal
        title={translate('facilityView.bookings.alert.deleteTitle')}
        description={
          otherUsersOwnsBooking
            ? translate(
                'facilityView.bookings.alert.deleteOtherUserBookingDescription',
                { name: user.fullName }
              )
            : translate('facilityView.bookings.alert.deleteDescription')
        }
        show={showModal}
        close={closeModal}
        onConfirm={deleteBooking}
        confirmationButtonLabel={translate(
          'facilityView.bookings.alert.deleteBookingButtonLabel'
        )}
      />
    )
  }
)
