import { AxiosResponse } from 'axios'
import { createUrl } from './helpers'
import axiosAuth from './config'
import { NPartners } from '../interfaces/services/partners.interfaces'

export const askSupport = (
  body: NPartners.NAskSupport.IRequestBody,
  query: NPartners.NAskSupport.IRequestQuery
): Promise<AxiosResponse<NPartners.NAskSupport.IResponse>> => {
  return axiosAuth.post<NPartners.NAskSupport.IResponse>(
    createUrl('/partners/ask-support', query),
    body
  )
}

export const activateSociety = (
  body: NPartners.NActivateSociety.IRequestBody
): Promise<AxiosResponse<NPartners.NActivateSociety.IResponse>> => {
  return axiosAuth.post<NPartners.NActivateSociety.IResponse>(
    createUrl('/partners/activate-society'),
    body
  )
}

export const createSocietyFolders = (
  query: NPartners.NCreateSocietyFolders.IRequestQuery,
  body: NPartners.NCreateSocietyFolders.IRequestBody
): Promise<AxiosResponse<NPartners.NCreateSocietyFolders.IResponse>> => {
  return axiosAuth.post<NPartners.NCreateSocietyFolders.IResponse>(
    createUrl('/partners/create-society-folders', query),
    body
  )
}
