/* eslint-disable max-len */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { SocietyWebsiteBase } from '../SocietyWebsiteBase'
import { SocietyWebsiteModel } from '../../../../state/models/society-website'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { societyWebsiteNameDescriptionSchema } from '../../../../forms/schemas/society_website_name_description'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import { TextInput } from '../../../common/TextInput'
import { TextArea } from '../../../common/TextArea'
import { useStores } from '../../../../hooks/useStores'
import { ToastType } from '../../../common/Toast/toast-type'

interface SocietyWebsiteGeneralInformationProps {
  website: SnapshotOut<typeof SocietyWebsiteModel>
}

export const SocietyWebsiteGeneralInformation = observer(
  ({ website }: SocietyWebsiteGeneralInformationProps): JSX.Element => {
    const { societyWebsitesStore } = useStores()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const { getErrorMessage } = useFormErrorMessage()

    const getDefaultValues = (): InferType<
      typeof societyWebsiteNameDescriptionSchema
    > => {
      return {
        displayName: website.displayName || '',
        description: website.description || '',
      }
    }

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyWebsiteNameDescriptionSchema),
      defaultValues: getDefaultValues(),
    })

    const onSubmit = async (
      data: InferType<typeof societyWebsiteNameDescriptionSchema>
    ): Promise<void> => {
      const status = await societyWebsitesStore.patchWebsite(website._id, data)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate(
            'societyWebsiteGeneralInformation.flashMessage.updateSuccess'
          )
        )
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate(
            'societyWebsiteGeneralInformation.flashMessage.updateFailure'
          )
        )
      }
    }

    const onError = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('societyWebsiteGeneralInformation.flashMessage.updateFailure')
      )
    }

    const loading = societyWebsitesStore.updatingWebsite === 'pending'

    return (
      <form
        className="w-full max-w-3xl"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <SocietyWebsiteBase
          website={website}
          loading={loading}
          isValid={isValid}
        >
          <div className="max-w-screen-sm space-y-6">
            <Controller
              control={control}
              name="displayName"
              render={({ field: { value, onChange, onBlur, name } }) => (
                <FormControl
                  name={name}
                  error={
                    errors.displayName && getErrorMessage(errors.displayName)
                  }
                  label={translate(
                    'societyWebsiteGeneralInformation.form.displayName'
                  )}
                >
                  <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="description"
              render={({ field: { value, onChange, onBlur, name } }) => (
                <FormControl
                  name={name}
                  error={
                    errors.description && getErrorMessage(errors.description)
                  }
                  label={translate(
                    'societyWebsiteGeneralInformation.form.description'
                  )}
                >
                  <TextArea
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    rows={10}
                  />
                </FormControl>
              )}
            />
          </div>
        </SocietyWebsiteBase>
      </form>
    )
  }
)
