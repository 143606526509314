import React from 'react'
import { observer } from 'mobx-react-lite'
import { InferType } from 'yup'
import { Modal } from '../../../../common/Modal'
import { ModalHeader } from '../../../../common/Modal/ModalHeader'
import { ModalBody } from '../../../../common/Modal/ModalBody'
import { useAppTranslation } from '../../../../../hooks/useAppTranslation'
// eslint-disable-next-line max-len
import { societyFacilityNotificationEmailsCreateSchema } from '../../../../../forms/schemas/society_facility_notification_emails_create'
import { useToastNotifications } from '../../../../../hooks/useToastNotification'
import { ToastType } from '../../../../common/Toast/toast-type'
import { NotificationEmailsForm } from '../NotificationEmailsForm'

interface NotificationEmailsModalProps {
  show: boolean
  close: () => void
  children?: React.ReactNode
  emails: (string | undefined)[] | undefined
  onChange: (updatedEmails: string[]) => void
}

export const NotificationEmailsModal = observer(
  ({
    children,
    show,
    close,
    emails,
    onChange,
  }: NotificationEmailsModalProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()

    const showErrorMessage = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const onSubmit = async (
      data: InferType<typeof societyFacilityNotificationEmailsCreateSchema>
    ): Promise<void> => {
      onChange(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [data.primaryRecipient, ...data.ccEmails].filter(
          (_val) => _val !== undefined
        )
      )
      close()
    }

    const onError = (): void => {
      showErrorMessage()
    }

    return (
      <>
        {children}
        <Modal show={show}>
          {{
            header: (
              <ModalHeader onClose={close}>
                {translate(
                  'createUpdateFacility.form.labels.notificationEmails'
                )}
              </ModalHeader>
            ),
            body: (
              <ModalBody>
                <NotificationEmailsForm
                  onClose={close}
                  onSubmit={onSubmit}
                  onError={onError}
                  emails={emails}
                />
              </ModalBody>
            ),
          }}
        </Modal>
      </>
    )
  }
)
