import React from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { ErrorBoundary } from '../../../../components/common/ErrorBoundary'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { ViewBase } from '../../../../components/common/ViewBase'
import { useStores } from '../../../../hooks/useStores'
import { ErrorView } from '../../../error/ErrorView'
import { IllustrationChoices } from '../../../../components/common/Illustration'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../../navigation/reverseDocumentTitle'
import { Linkify } from '../../../../components/common/Linkify'

export const WidgetView = observer((): JSX.Element => {
  const { societyWidgetsStore } = useStores()
  const { translate } = useAppTranslation()
  const { widgetId } = useParams()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }

  const widget = widgetId
    ? societyWidgetsStore.widgets.get(widgetId)
    : undefined

  useDocumentTitle(
    reverseDocumentTitle('society:widgets-detail', {
      '{{ societyName }}': society.name,
      '{{ widgetName }}': widget?.title,
    })
  )

  return (
    <ErrorBoundary>
      <ViewBase title={widget?.title || ''}>
        {widget ? (
          <Linkify>
            <p className="whitespace-pre-line">{widget.content}</p>
          </Linkify>
        ) : (
          <ErrorView
            title={translate('widgetView.emptyState.title')}
            subtitle={translate('widgetView.emptyState.subtitle')}
            illustration={IllustrationChoices.EMPTY}
          />
        )}
      </ViewBase>
    </ErrorBoundary>
  )
})
