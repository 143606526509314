import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../../hooks/useStores'
import { getGroupSubtitle } from '../helpers'
import { GroupModel } from '../../../../state/models/group'
import { theme } from '../../../../theme/theme'
import { RegisterGroupMemberUserListItem } from './RegisterGroupMemberUserListItem'
import { Dropdown, Option } from '../../../common/Dropdown'
import { Button, ButtonVariant } from '../../../common/Button'
import { IconChoices } from '../../../common/Icon'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'

interface RegisterGroupMemberListItemProps {
  group: SnapshotOut<typeof GroupModel>
  dropdownOptions: Option[]
  className?: string
}

export const RegisterGroupMemberListItem = observer(
  ({
    group,
    dropdownOptions,
    className,
  }: RegisterGroupMemberListItemProps): JSX.Element => {
    const { userStore } = useStores()
    const { translate } = useAppTranslation()

    return (
      <div className={`md:w-1/2 ${className ?? ''}`}>
        <div className="flex items-center justify-between">
          <div>
            <p style={theme.textVariants.h3}>{group.name}</p>
            <p style={theme.textVariants.base}>{getGroupSubtitle(group)}</p>
          </div>
          <Dropdown dropdownPosition="bottom-left" options={dropdownOptions}>
            <Button
              label={translate('common.options')}
              variant={ButtonVariant.DEFAULT}
              size="sm"
              icon={IconChoices.MORE}
              iconPlacement="right"
            />
          </Dropdown>
        </div>
        {group.membersList?.map((_member, index) => {
          const _user = userStore.users.get(_member.userId)
          if (_user) {
            return (
              <RegisterGroupMemberUserListItem
                key={_member._id}
                user={_user}
                className={`
                  ${index === 0 ? 'mt-2 rounded-t-md border-t' : ''}
                  ${
                    group.membersList && index === group.membersList.length - 1
                      ? 'rounded-b-md'
                      : ''
                  }
                `}
              />
            )
          }
          return null
        })}
      </div>
    )
  }
)
