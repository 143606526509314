import countries from 'i18n-iso-countries'
import english from 'i18n-iso-countries/langs/en.json'
import swedish from 'i18n-iso-countries/langs/sv.json'

countries.registerLocale(english)
countries.registerLocale(swedish)
export const getCountries = (
  locale: 'en' | 'sv'
): { value: string; label: string }[] => {
  return Object.entries(countries.getNames(locale, { select: 'official' })).map(
    (entry) => ({ value: entry[0], label: entry[1] })
  )
}
