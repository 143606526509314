import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { UnitModel, UnitResidentModel } from '../../../state/models/unit'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { getUnitRole } from '../../../helpers/translations/unit-roles'
import { UnitRole } from '../../../types/unit-roles'
import { useStores } from '../../../hooks/useStores'
import { UserAvatar } from '../../common/Avatar'
import { theme } from '../../../theme/theme'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { Icon, IconChoices } from '../../common/Icon'
import { Card } from '../../common/Card'

interface RegisterUnitItemResidentProps {
  resident: SnapshotOut<typeof UnitResidentModel>
}

const RegisterUnitItemResident = observer(
  ({ resident }: RegisterUnitItemResidentProps): JSX.Element | null => {
    const { translate } = useAppTranslation()
    const { userStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const user = userStore.users.get(resident.userId)

    if (!user) {
      return null
    }

    return (
      <Card
        url={reverseUrl('user:society-detail', {
          id: resident.userId,
          societyId: society._id,
        })}
      >
        {{
          body: (
            <div className="flex items-center justify-between p-4">
              <div className="flex items-center gap-3">
                <UserAvatar user={user} size={42} />
                <div className="flex flex-col text-left">
                  {resident.role && (
                    <p style={theme.textVariants.baseBold}>
                      {translate(
                        getUnitRole(resident.role as UnitRole) as string
                      )}
                    </p>
                  )}
                  <p style={theme.textVariants.base} translate="no">
                    {user.fullName}
                  </p>
                </div>
              </div>
              <Icon icon={IconChoices.ARROW} />
            </div>
          ),
        }}
      </Card>
    )
  }
)

interface RegisterUnitItemResidentsContent {
  unit: SnapshotOut<typeof UnitModel>
}

export const RegisterUnitItemResidentsContent = observer(
  ({ unit }: RegisterUnitItemResidentsContent): JSX.Element => {
    return (
      <div className="mt-1 flex flex-col gap-2">
        {unit.residentsList?.map((_resident) => (
          <RegisterUnitItemResident
            key={_resident.userId}
            resident={_resident}
          />
        ))}
      </div>
    )
  }
)
