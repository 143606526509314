import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../../hooks/useStores'
import {
  SearchSocietyModel,
  SocietyModel,
} from '../../../../state/models/society'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { RequestJoinSocietyForm } from '../RequestiJoinSocietyForm'
import { PendingRequest } from '../PendingRequest'
import { InactiveSociety } from '../InactiveSociety'

interface FindSocietyContentProps {
  society: SnapshotOut<typeof SearchSocietyModel>
}

export const FindSocietyContent = observer(
  ({ society }: FindSocietyContentProps): JSX.Element => {
    const { societyStore, requestStore } = useStores()
    const userId = useAuthenticatedUserId() as string

    const societyIsActive = society.status === 'active'

    const userPendingRequestSocietyIds =
      requestStore.userPendingSocietyRequests(userId)

    const userHasPendingRequest =
      society && userPendingRequestSocietyIds.includes(society._id)

    const fullSociety = societyStore.societies.get(society._id) as SnapshotOut<
      typeof SocietyModel
    >

    if (userHasPendingRequest) return <PendingRequest />

    if (societyIsActive)
      return (
        <>{fullSociety && <RequestJoinSocietyForm society={fullSociety} />}</>
      )

    return <>{society && <InactiveSociety society={fullSociety} />}</>
  }
)
