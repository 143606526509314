import * as yup from 'yup'

export const societyReportCreateSchema = yup
  .object({
    societyId: yup.string().required(),
    userId: yup.string().required(),
    message: yup.string().required(),
    space: yup
      .string()
      .oneOf(['apartment', 'building', 'surroundings', 'common-areas', 'other'])
      .required(),
  })
  .required()
