import { observer } from 'mobx-react-lite'
import React, {
  FocusEvent,
  KeyboardEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import { theme } from '../../../theme/theme'
import { Button } from '../Button'
import { Icon, IconChoices } from '../Icon'
import { DropdownSelectOption } from './DropdownSelectOption'
import { IDropdownSelectOption } from './IDropdownSelectOption'

interface DropdownSelectProps {
  customLabel?: string | undefined | JSX.Element
  value: string | undefined | JSX.Element
  options: IDropdownSelectOption[]
  onChange: (value: string) => void
  renderItemContent?: (option: IDropdownSelectOption) => JSX.Element | string
  dropdownPosition?: 'bottom-left' | 'bottom-right'
  dropdownWidth?: string
  dropdownContentWidth?: 'w-fit' | 'w-full'
  dropdownClassName?: string
  submitButton?: React.ReactNode
}

export const DropdownSelect = observer(
  ({
    customLabel,
    value,
    options,
    onChange,
    renderItemContent,
    dropdownPosition = 'bottom-left',
    dropdownWidth = 'w-48',
    dropdownContentWidth = 'w-full',
    dropdownClassName,
    submitButton,
  }: DropdownSelectProps): JSX.Element => {
    const [show, setShow] = useState(false)
    const [label, setLabel] = useState<string | undefined>(undefined)
    const [avatar, setAvatar] = useState<JSX.Element | undefined>(undefined)
    const ulRef = useRef() as MutableRefObject<HTMLUListElement>
    const buttonRef = useRef() as MutableRefObject<HTMLButtonElement>

    useEffect(() => {
      const selectedOption = options.filter(
        (option) => option.value === value
      )[0]
      setLabel(selectedOption?.label)
      setAvatar(
        selectedOption?.icon ? (
          <Icon icon={selectedOption?.icon} />
        ) : (
          selectedOption?.image
        )
      )
    }, [options, value])

    const close = (): void => {
      setShow(false)
    }

    useEffect(() => {
      show && ulRef?.current?.focus()
    }, [show])

    const toggle = (): void => {
      setShow(!show)
    }

    const onBlur = (event: FocusEvent<HTMLUListElement>): void => {
      if (
        !event.currentTarget.contains(event.relatedTarget) &&
        event.relatedTarget !== buttonRef.current
      ) {
        close()
      }
    }

    const handleOnChange = (updatedValue: string): void => {
      onChange(updatedValue)
      close()
    }

    const getDropdownPosition = (_size: string): string => {
      switch (_size) {
        case 'bottom-right':
          return 'left-0 top-full'
        default:
          return 'right-0 top-full' // bottom-left
      }
    }

    const getTruncatedLabel = (
      _label: string | JSX.Element | undefined
    ): JSX.Element | undefined => {
      if (typeof _label === 'string' || _label instanceof String) {
        return (
          <div className="inline-block overflow-hidden text-ellipsis whitespace-nowrap">
            <p style={theme.textVariants.base}>{_label}</p>
          </div>
        )
      }
      return _label
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
      if (e.key === 'Enter') {
        e.stopPropagation()
        toggle()
      }
    }

    const onSubmitClick = (
      e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>
    ): void => {
      e.stopPropagation()
      toggle()
    }

    return (
      <div className={`relative flex ${dropdownWidth || 'w-full'}`}>
        <Button
          ref={buttonRef}
          wrapperClassName="w-full"
          className={`max-h-12 w-full hover:border-neutral-70 hover:bg-white
          ${show ? 'outline outline-1 outline-blue/50' : ''}
        `}
          aria-expanded="true"
          aria-haspopup="true"
          onClick={toggle}
          icon={IconChoices.CHEVRON_DOWN}
          avatar={avatar}
          label={
            customLabel
              ? getTruncatedLabel(customLabel)
              : getTruncatedLabel(label)
          }
          iconPlacement="right"
          tabIndex={0}
          justifyContent="justify-between"
          useFocus
        />
        {show && (
          <ul
            ref={ulRef}
            onBlur={onBlur}
            className={`absolute ${getDropdownPosition(dropdownPosition)}
            z-10 mt-2 ${dropdownContentWidth} 
            max-h-72 origin-top-right overflow-y-auto rounded-md 
            bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none
            ${dropdownClassName || ''}
          `}
            role="menu"
            tabIndex={-1}
          >
            {options.map((_option) => (
              <DropdownSelectOption
                key={_option.value}
                option={_option}
                selected={_option.value === value}
                onChange={handleOnChange}
                renderItemContent={renderItemContent}
                getTruncatedLabel={getTruncatedLabel}
              />
            ))}
            {submitButton && (
              <div
                onKeyDown={handleKeyDown}
                role="button"
                tabIndex={0}
                onClick={onSubmitClick}
              >
                {submitButton}
              </div>
            )}
          </ul>
        )}
      </div>
    )
  }
)
