import * as yup from 'yup'

export const invoiceDetailsSchema = yup
  .object({
    addressBillingCo: yup.string(),
    addressBillingStreet: yup.string(),
    addressBillingZip: yup.string(),
    addressBillingCity: yup.string(),
    billingEmail: yup.string().email().required(),
    billingCompany: yup.string(),
    billingOrgNo: yup.string(),
    billingRefText: yup.string(),
  })
  .required()
