import { SnapshotOut } from 'mobx-state-tree'
import { ChatRoomModel } from '../state/models/chat-room'
import { useStores } from './useStores'

export const useIsUserInChat = (
  chatRoom: SnapshotOut<typeof ChatRoomModel> | undefined
): boolean => {
  const { authenticationStore } = useStores()
  const userId = authenticationStore.userId as string
  const isUserInChat = chatRoom?.membersList.find(
    (_member) => _member.userId === userId
  )
  return !!isUserInChat
}
