import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { FacilityModel } from '../../../../state/models/facility'
import { FacilityItem } from './FacilityItem'
import { PostCallout } from '../PostCallout'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'

interface PostFacilitiesProps {
  facilities?: SnapshotOut<typeof FacilityModel>[]
}

export const PostFacilities = observer(
  ({ facilities }: PostFacilitiesProps): JSX.Element | null => {
    const { translate } = useAppTranslation()

    if (!facilities || facilities.length === 0) {
      return null
    }

    return facilities && facilities.length > 0 ? (
      <div className="flex w-full flex-col gap-4">
        <PostCallout text={translate('post.newFacilityText')} />
        {facilities.map((facility) => (
          <FacilityItem facility={facility} key={facility._id} />
        ))}
      </div>
    ) : null
  }
)
