import React from 'react'
import { Link } from 'react-router-dom'
import { SnapshotOut } from 'mobx-state-tree'
import { theme } from '../../../theme/theme'
import { Card } from '../../common/Card'
import { Button, ButtonVariant } from '../../common/Button'
import { Illustration, IllustrationChoices } from '../../common/Illustration'
import { Spinner } from '../../common/Spinner'
import { largeNumberFormattingWithSpaces } from '../../../helpers/number'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { Alert } from '../../common/Alert'
import { AlertType } from '../../common/Alert/alert-type'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { SocietyModel } from '../../../state/models/society'

interface BasicSectionProps {
  cost: number | undefined
  fetchingCost: boolean
  openPaymentModal: () => void
  openDiscountCodeModal: () => void
  billingEmail: string | null
  society: SnapshotOut<typeof SocietyModel>
}

export const BasicSection = ({
  cost,
  fetchingCost,
  openPaymentModal,
  openDiscountCodeModal,
  billingEmail,
  society,
}: BasicSectionProps): JSX.Element => {
  const { translate } = useAppTranslation()

  return (
    <>
      <div className="md:w-1/2">
        <div className="mb-6 flex items-center">
          <Illustration illustrationChoice={IllustrationChoices.CHECKLIST} />
          <div className="ml-4">
            <p style={theme.textVariants.h1}>
              {translate(
                society.locked
                  ? 'invoiceView.basic.locked.title'
                  : 'invoiceView.basic.remember'
              )}
            </p>
            <p style={theme.textVariants.base} className="mt-1">
              {translate(
                society.locked
                  ? 'invoiceView.basic.locked.subtitle'
                  : 'invoiceView.basic.payYourAnnualFee'
              )}
            </p>
          </div>
        </div>
        <Card>
          {{
            body: (
              <div className="flex flex-col p-6 text-left">
                <p style={theme.textVariants.lead}>
                  {translate('invoiceView.commonCard.annualFee')}
                </p>
                <div className="flex items-center">
                  {fetchingCost && (
                    <div className="mr-2">
                      <Spinner />
                    </div>
                  )}
                  {!fetchingCost && cost && (
                    <p style={theme.textVariants.h1}>
                      <span>{largeNumberFormattingWithSpaces(cost)}</span> SEK{' '}
                      <span style={theme.textVariants.paragraph}>
                        {translate('invoiceView.commonCard.excludingVat')}
                      </span>
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-6">
                  <div className="mt-6 flex gap-3">
                    <Button
                      label={translate(
                        'invoiceView.commonCard.buttons.useCode'
                      )}
                      onClick={openDiscountCodeModal}
                    />
                    <Button
                      label={translate('common.actions.pay')}
                      disabled={!cost || fetchingCost || !billingEmail}
                      variant={ButtonVariant.PRIMARY}
                      onClick={openPaymentModal}
                    />
                  </div>
                  {!billingEmail && (
                    <Alert
                      type={AlertType.INFO}
                      customText={
                        <p>
                          <span style={theme.textVariants.baseBold}>
                            {translate(
                              'invoiceView.commonCard.missingEmail.title'
                            )}
                          </span>{' '}
                          {translate(
                            'invoiceView.commonCard.missingEmail.description'
                          )}
                          <Link
                            className="underline"
                            to={reverseUrl('management:billing-details', {
                              id: society._id,
                            })}
                          >
                            {translate(
                              'invoiceView.commonCard.missingEmail.link'
                            )}
                          </Link>
                        </p>
                      }
                    />
                  )}
                </div>
              </div>
            ),
          }}
        </Card>
      </div>
    </>
  )
}
