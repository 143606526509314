import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { addDays, endOfDay, startOfDay } from 'date-fns'
import { FacilityModel } from '../../../../state/models/facility'
import { theme } from '../../../../theme/theme'
import { DateTimeInput } from '../../../common/DateTimeInput'
import { SocietyModel } from '../../../../state/models/society'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { FacilityCalendarSection } from './FacilityCalendarSection'
import { useStores } from '../../../../hooks/useStores'
import { FacilityCalendarTimeSlots } from '../FacilityCalendarTimeSlots'
import { ITimeSlot } from './interfaces'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { NBookings } from '../../../../interfaces/services/bookings.interfaces'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { FacilityCalendarUnitSelect } from '../FacilityCalendarUnitSelect'
import { useUserReachedMaxBookings } from './useUserReachedMaxBookings'
import { useUserUnitBookings } from './useUserUnitBookings'
import { Alert } from '../../../common/Alert'
import { AlertType } from '../../../common/Alert/alert-type'
import { FacilityCalendarBookingCard } from '../FacilityCalendarBookingCard'

interface FacilityCalendarProps {
  facility: SnapshotOut<typeof FacilityModel>
  society: SnapshotOut<typeof SocietyModel>
}

export const FacilityCalendar = observer(
  ({ facility, society }: FacilityCalendarProps): JSX.Element | null => {
    const { bookingsStore, unitStore } = useStores()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    useUserUnitBookings(facility)
    const userId = useAuthenticatedUserId() as string
    const userUnitsForSociety = unitStore.userUnits(
      userId,
      society?._id as string
    )

    const [selectedUnitId, setSelectedUnitId] = useState<string | undefined>(
      userUnitsForSociety && userUnitsForSociety.length > 0
        ? userUnitsForSociety[0]._id
        : undefined
    )

    const userHasReachedMaxNumberOfBookings = useUserReachedMaxBookings(
      userUnitsForSociety,
      facility
    )

    const [selectedDate, _setSelectedDate] = useState(new Date())
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<
      ITimeSlot | undefined
    >(undefined)

    const setSelectedDate = (date: Date): void => {
      _setSelectedDate(date)
      setSelectedTimeSlot(undefined)
    }

    const fetchBookings = (): void => {
      if (facility) {
        const _startOfSelectedDate = startOfDay(selectedDate)
        bookingsStore.getBookingsPerFacility(
          facility._id,
          _startOfSelectedDate,
          addDays(_startOfSelectedDate, 1)
        )
      }
    }

    const userBookingsForDate =
      bookingsStore.bookingsForUserAndFacilityForSpecificDate(
        userId,
        facility._id,
        startOfDay(selectedDate),
        endOfDay(selectedDate)
      )

    useEffect(() => {
      fetchBookings()
      // don't fetch multiple times
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])

    // Set default unitId if not present on initial load
    useEffect(() => {
      if (!selectedUnitId && userUnitsForSociety.length > 0) {
        setSelectedUnitId(userUnitsForSociety[0]._id)
      }
    }, [selectedUnitId, userUnitsForSociety])

    useEffect(() => {
      if (facility.societyId) {
        unitStore.getUnitsPerSociety(facility.societyId)
        if (userId) {
          unitStore.userUnits(userId, facility.societyId).forEach((_unit) => {
            bookingsStore.getBookingsPerUnit(_unit._id, new Date())
          })
        }
      }
      // don't fetch multiple times
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const societyIsSamf = society.isSamf

    const facilityBookingDisabled = facility.booking === false
    const bookingEnabled =
      selectedTimeSlot &&
      facility._id &&
      userId &&
      society._id &&
      selectedUnitId

    const createBooking = async (): Promise<void> => {
      if (!bookingEnabled) {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
        return
      }

      const body: NBookings.NCreate.IRequestBody = {
        // Interface is invalid
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        startDate: selectedTimeSlot.startDate.getTime(),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        endDate: selectedTimeSlot.endDate.getTime(),
        facilityId: facility._id,
        societyId: facility.societyId,
        unitId: selectedUnitId,
        userId,
      }

      const status = await bookingsStore.createBooking(body)
      if (status) {
        setToastNotification(
          ToastType.SUCCESS,
          translate('facilityView.calendar.flashMessage.createBookingSuccess')
        )
        fetchBookings()
        setSelectedTimeSlot(undefined)
      } else {
        setToastNotification(
          ToastType.DANGER,
          translate('facilityView.calendar.flashMessage.createBookingFailure')
        )
      }
    }

    const fetchingBookings = bookingsStore.fetchingBookings === 'pending'
    const creatingBooking = bookingsStore.creatingBooking === 'pending'
    const deletingBooking = bookingsStore.deletingBooking === 'pending'

    if (!selectedUnitId) {
      return (
        <Alert
          text={translate(
            societyIsSamf
              ? 'facilityView.calendar.alert.noUnitSamf'
              : 'facilityView.calendar.alert.noUnit'
          )}
          type={AlertType.DANGER}
        />
      )
    }

    if (facilityBookingDisabled) {
      return (
        <Alert
          text={translate('facilityView.calendar.alert.bookingDisabledText')}
          type={AlertType.DANGER}
        />
      )
    }

    const getBookingAvailability = ():
      | { startDate: string | null; endDate: string | null }
      | undefined => {
      const bookingAvailability = facility?.bookingAvailability
      if (
        bookingAvailability === undefined ||
        bookingAvailability.length === 0
      ) {
        return undefined
      }
      return bookingAvailability[0]
    }

    const bookingAvailability = getBookingAvailability()

    const getIsSelectedDateGreaterThanFacilityStartDate = (): boolean => {
      if (bookingAvailability?.startDate) {
        return selectedDate > new Date(bookingAvailability?.startDate)
      }
      return true
    }

    const isSelectedDateGreaterThanFacilityStartDate =
      getIsSelectedDateGreaterThanFacilityStartDate()

    return (
      <div className="flex w-full flex-col gap-10">
        {userUnitsForSociety.length > 1 && (
          <FacilityCalendarSection
            title={translate(
              societyIsSamf
                ? 'facilityView.calendar.sections.chooseUnit.titleSamf'
                : 'facilityView.calendar.sections.chooseUnit.title'
            )}
          >
            <FacilityCalendarUnitSelect
              selectedUnitId={selectedUnitId}
              setSelectedUnitId={setSelectedUnitId}
              userUnits={userUnitsForSociety}
            />
          </FacilityCalendarSection>
        )}
        <FacilityCalendarSection
          title={translate('facilityView.calendar.sections.chooseDate.title')}
          className="flex flex-col lg:w-1/6"
        >
          <DateTimeInput
            minDate={
              bookingAvailability?.startDate
                ? new Date(bookingAvailability?.startDate)
                : new Date()
            }
            maxDate={
              bookingAvailability?.endDate
                ? new Date(bookingAvailability?.endDate)
                : undefined
            }
            value={selectedDate}
            onChange={setSelectedDate}
          />
        </FacilityCalendarSection>
        {isSelectedDateGreaterThanFacilityStartDate && (
          <FacilityCalendarSection
            title={translate('facilityView.calendar.sections.chooseSlot.title')}
            loading={fetchingBookings}
          >
            <FacilityCalendarTimeSlots
              facility={facility}
              selectedDate={selectedDate}
              selectedTimeSlot={selectedTimeSlot}
              setSelectedTimeslot={setSelectedTimeSlot}
              userHasReachedMaxNumberOfBookings={
                userHasReachedMaxNumberOfBookings
              }
            />
          </FacilityCalendarSection>
        )}
        {facility.bookingMessage && (
          <FacilityCalendarSection
            title={translate(
              'facilityView.calendar.sections.bookingMessage.title'
            )}
            dividerTop
          >
            <p
              className="w-full whitespace-pre-line md:w-1/2"
              style={theme.textVariants.base}
            >
              {facility.bookingMessage}
            </p>
          </FacilityCalendarSection>
        )}
        <div className="flex flex-col gap-4">
          {userHasReachedMaxNumberOfBookings && (
            <Alert
              type={AlertType.DANGER}
              text={translate(
                'facilityView.calendar.alert.unitMaxNumberBookings'
              )}
            />
          )}
          <FacilityCalendarSection
            title={translate(
              'facilityView.calendar.sections.yourBooking.title'
            )}
            dividerTop
            hideHeader={!selectedTimeSlot && userBookingsForDate.length === 0}
          >
            <div className="flex flex-wrap gap-4">
              {userBookingsForDate.map((booking) => (
                <FacilityCalendarBookingCard
                  key={booking._id}
                  type="booking"
                  booking={booking}
                  facility={facility}
                  startDate={new Date(booking.startDate)}
                  endDate={new Date(booking.endDate)}
                  loading={deletingBooking}
                />
              ))}
              {selectedTimeSlot && (
                <FacilityCalendarBookingCard
                  type="timeSlot"
                  facility={facility}
                  createBooking={createBooking}
                  startDate={selectedTimeSlot.startDate}
                  endDate={selectedTimeSlot.endDate}
                  loading={creatingBooking}
                />
              )}
            </div>
          </FacilityCalendarSection>
        </div>
      </div>
    )
  }
)
