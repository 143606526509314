const palette = {
  brandGreen: '#469253',
  brandLightGreen: '#e6f1ec',
  almostBrandGreen: '#7EB87D',
  white: '#FFFFFF',
  red: '#DD3322',
  babyBlue: '#E7F0F8',
  highlight: '#F2F2F2',
  dark: '#1A1A1A',
  lightDark: '#999999',
  darkGrey: '#808080',
  mediumGray: '#F5F5F5',
  semanticGreen: '#118834',
  warning: '#DD3322',
  linkBlue: '#1166BB',
  successSignal: '#29CC97',
  sunSignal: '#FEC400',
  errorSignal: '#DC3545',
}

export const theme = {
  colors: {
    dark: palette.dark,
    lightDark: palette.lightDark,
    darkGrey: palette.darkGrey,
    white: palette.white,
    red: palette.red,
    brandGreen: palette.brandGreen,
    brandLightGreen: palette.brandLightGreen,
    almostBrandGreen: palette.almostBrandGreen,
    feedBackground: palette.highlight,
    lightGray: palette.highlight,
    mediumGray: palette.mediumGray,
    unreadNotificationBackground: palette.babyBlue,
    semanticGreen: palette.semanticGreen,
    link: palette.linkBlue,
    success: palette.semanticGreen,
    danger: palette.errorSignal,
    warning: palette.sunSignal,
    flashMessage: {
      success: palette.successSignal,
      warning: palette.sunSignal,
      danger: palette.errorSignal,
    },
  },
  spacing: {
    xxs: 2,
    xs: 4,
    s: 8,
    m: 16,
    l: 24,
    xl: 40,
    xxl: 88,
  },
  screen: {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
  },
  textVariants: {
    display: {
      fontSize: 48,
      lineHeight: '56px',
      fontWeight: '600',
      fontFamily: 'DM Sans',
    },
    h1: {
      fontSize: 36,
      lineHeight: '46px',
      fontFamily: 'DM Sans',
      fontWeight: '600',
    },
    h2: {
      fontSize: 24,
      lineHeight: '32px',
      fontFamily: 'DM Sans',
      fontWeight: '600',
    },
    h3: {
      fontSize: 20,
      lineHeight: '28px',
      fontFamily: 'DM Sans',
      fontWeight: '600',
    },
    lead: {
      fontSize: 18,
      lineHeight: '26px',
      fontFamily: 'DM Sans',
      fontWeight: '600',
    },
    paragraph: {
      fontSize: 18,
      lineHeight: '26px',
      fontFamily: 'DM Sans',
      fontWeight: '400',
    },
    base: {
      fontSize: 16,
      lineHeight: '24px',
      fontFamily: 'DM Sans',
      fontWeight: '400',
    },
    baseBold: {
      fontSize: 16,
      lineHeight: '24px',
      fontFamily: 'DM Sans',
      fontWeight: '600',
    },
    caption: {
      fontSize: 14,
      lineHeight: '20px',
      fontFamily: 'DM Sans',
      fontWeight: '400',
    },
    captionBold: {
      fontSize: 14,
      lineHeight: '20px',
      fontFamily: 'DM Sans',
      fontWeight: '600',
    },
    fineprint: {
      fontSize: 12,
      lineHeight: '26px',
      fontFamily: 'DM Sans',
      fontWeight: '400',
    },
    fineprintBold: {
      fontSize: 12,
      lineHeight: '18px',
      fontFamily: 'DM Sans',
      fontWeight: '600',
    },
  },
  boxShadows: {
    soft: {
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.05,
      shadowRadius: 4,
    },
  },
  header: {
    height: 64,
    mobileHeight: 52,
  },
}

export const darkTheme = {
  ...theme,
  //   colors: {
  //     ...theme.colors,
  //     background: palette.black,
  //     foreground: palette.white,
  //   },
}
