import { observer } from 'mobx-react-lite'
import React, { KeyboardEvent, useCallback } from 'react'
import { Button } from '../Button'
import { IconChoices } from '../Icon'
import { MediaImage } from '../Image'

interface MediaGalleryModalBodyProps {
  mediaId: string
  mediaLength: number
  mediaGalleryIndex: number
  setMediaGalleryIndex?: React.Dispatch<React.SetStateAction<number>>
}

export const MediaGalleryModalBody = observer(
  ({
    mediaId,
    mediaLength,
    mediaGalleryIndex,
    setMediaGalleryIndex,
  }: MediaGalleryModalBodyProps): JSX.Element => {
    const previousMediaGalleryImage = (): void => {
      if (setMediaGalleryIndex && mediaGalleryIndex - 1 >= 0) {
        setMediaGalleryIndex(mediaGalleryIndex - 1)
      }
    }

    const nextMediaGalleryImage = (): void => {
      if (setMediaGalleryIndex && mediaGalleryIndex + 1 < mediaLength) {
        setMediaGalleryIndex(mediaGalleryIndex + 1)
      }
    }

    const handleKeyDown = useCallback(
      (e: KeyboardEvent<HTMLButtonElement>): void => {
        if (e.code === 'ArrowLeft' && setMediaGalleryIndex) {
          if (mediaGalleryIndex < 1) {
            setMediaGalleryIndex(mediaLength - 1)
          } else {
            setMediaGalleryIndex(mediaGalleryIndex - 1)
          }
        } else if (e.code === 'ArrowRight' && setMediaGalleryIndex) {
          if (mediaGalleryIndex > mediaLength - 2) {
            setMediaGalleryIndex(0)
          } else {
            setMediaGalleryIndex(mediaGalleryIndex + 1)
          }
        }
      },
      [mediaGalleryIndex, mediaLength, setMediaGalleryIndex]
    )

    return (
      <>
        <MediaImage
          className="h-full border-y border-neutral-90 bg-white"
          objectFit="object-contain"
          mediaId={mediaId}
        />
        {setMediaGalleryIndex && mediaLength > 1 && (
          <>
            <Button
              className="absolute top-0 bottom-0 my-auto ml-4 h-12 w-12 items-center
            justify-between border-none bg-black hover:bg-black/80 md:ml-8"
              size="no-padding"
              round
              iconSize={20}
              icon={IconChoices.CHEVRON_LEFT}
              iconColor="white"
              onClick={previousMediaGalleryImage}
              onKeyDown={handleKeyDown}
              tabIndex={0}
              autoFocus
              name="left-arrow"
            />
            <Button
              className="absolute top-0 bottom-0 right-0 my-auto mr-4 h-12 w-12 items-center
            justify-between border-none bg-black hover:bg-black/80 md:mr-8"
              size="no-padding"
              round
              iconSize={20}
              icon={IconChoices.CHEVRON_RIGHT}
              iconColor="white"
              onClick={nextMediaGalleryImage}
              onKeyDown={handleKeyDown}
              tabIndex={0}
              name="right-arrow"
            />
          </>
        )}
      </>
    )
  }
)
