import React from 'react'
import styles from './Avatar.module.css'
import AvatarPlaceholder from '../../../assets/icons/avatar_placeholder.svg'
import SocietyPlaceholder from '../../../assets/icons/society_placeholder.svg'
import BookingPlaceholder from '../../../assets/icons/booking_placeholder.svg'
import GroupPlaceholder from '../../../assets/icons/placeholder_groupavatar.svg'
import { theme } from '../../../theme/theme'

interface AvatarImageProps {
  alt?: string
  url?: string
  initials?: string
  placeholder?: string
  size: number
  avatarClassName?: string
}

export const AvatarImage = ({
  alt,
  url,
  initials,
  placeholder,
  size,
  avatarClassName,
}: AvatarImageProps): JSX.Element => {
  if (url) {
    return (
      <img
        alt={alt}
        src={url}
        style={{
          height: size,
          width: size,
          maxWidth: size,
        }}
        className={`${avatarClassName ?? ''} ${styles.avatar}`}
      />
    )
  }
  if (initials) {
    return (
      <p
        style={{
          ...theme.textVariants.base,
          fontSize: size / 2.8,
        }}
        translate="no"
      >
        {initials}
      </p>
    )
  }

  if (placeholder === 'interests') {
    return <GroupPlaceholder width={size} height={size} />
  }
  if (placeholder === 'booking') {
    return <BookingPlaceholder width={size} height={size} />
  }
  if (placeholder === 'society') {
    return <SocietyPlaceholder width={size} height={size} />
  }
  return <AvatarPlaceholder width={size} height={size} />
}
