import React from 'react'
import { observer } from 'mobx-react-lite'
import { useAtom } from 'jotai'
import { InfiniteScroll } from '../../common/InfiniteScroll'
import { ChatRoomsListItem } from '../ChatRoomsListItem'
import { ActiveTabBar } from '../ChatSideBarContent'
import { useChatRoomsData } from './useChatRoomsData'
import { ChatHeaderState, chatHeaderStateAtom } from '../atom'

interface ChatRoomsProp {
  activeTabBar: ActiveTabBar
  activeTab: number
}

export const ChatRooms = observer(
  ({ activeTabBar, activeTab }: ChatRoomsProp): JSX.Element => {
    const [, setChatHeaderState] = useAtom(chatHeaderStateAtom)

    const { currentChatRooms, loadMoreChatRooms, showSpinner } =
      useChatRoomsData({
        activeTabBar,
        activeTab,
      })

    const customOnClick = (): void => {
      setChatHeaderState(ChatHeaderState.None)
    }

    const loading = currentChatRooms.length > 0 ? false : showSpinner

    return (
      <InfiniteScroll
        key="infiniteChatScroll" // Add key to prompt rerendering of component on state change
        className="px-2"
        onEndReached={loadMoreChatRooms}
        loading={loading || false}
      >
        {currentChatRooms?.map((_chatRoom) => (
          <ChatRoomsListItem
            className="rounded-md p-4"
            key={_chatRoom._id}
            chatRoomId={_chatRoom._id}
            customOnClick={customOnClick}
          />
        ))}
      </InfiniteScroll>
    )
  }
)
