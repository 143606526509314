import React from 'react'
import { observer } from 'mobx-react-lite'
import { FacilityListItem } from './FacilityListItem'
import { SpinnerWrapper } from '../../common/SpinnerWrapper'
import { ErrorView } from '../../../views/error/ErrorView'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { IllustrationChoices } from '../../common/Illustration'
import { useStores } from '../../../hooks/useStores'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { isUserAdminInSociety } from '../../../helpers/society'
import { useAuthenticatedUserId } from '../../../hooks/useAuthenticatedUserId'

export const FacilitiesList = observer((): JSX.Element => {
  const { facilitiesStore } = useStores()
  const { society } = useCurrentSociety()
  if (society === undefined) {
    throw new Error('useCurrentSociety returned undefined')
  }
  const { translate } = useAppTranslation()
  const userId = useAuthenticatedUserId() as string

  const userIsAdmin = isUserAdminInSociety(society, userId as string)
  const facilities = facilitiesStore.facilitiesForSociety(society._id)
  const loading = facilitiesStore.fetchingFacilities === 'pending'

  if (loading) {
    return <SpinnerWrapper />
  }

  if (!loading && facilities.length === 0) {
    return (
      <ErrorView
        title={translate('facilitiesView.emptyState.noFacilitiesTitle')}
        subtitle={translate('facilitiesView.emptyState.noFacilitiesSubtitle')}
        illustration={IllustrationChoices.CHECKLIST}
      />
    )
  }

  return (
    <div className="w-full space-y-4">
      {facilities.map((facility) => (
        <FacilityListItem
          key={facility._id}
          facility={facility}
          userIsAdmin={userIsAdmin}
        />
      ))}
    </div>
  )
})
