import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { TextInput } from '../../../common/TextInput'
import { TextArea } from '../../../common/TextArea'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import { useStores } from '../../../../hooks/useStores'
import { DropZone, DropZoneVariant } from '../../../common/DropZone'
import { ToastType } from '../../../common/Toast/toast-type'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { FixedHeightMediaImageWithBackground } from '../../../common/Image'
import {
  notificationSettingsOptions,
  NOTIFICATION_SETTINGS_VALUE,
} from '../../../../helpers/notification_settings_options'
import { societyLocalOfferCreateSchema } from '../../../../forms/schemas/society_local_offer_create'
import { LocalOfferModel } from '../../../../state/models/local-offer'
import { sanitizeMobileNumber } from '../../../../helpers/phone'
import { theme } from '../../../../theme/theme'
import { SelectDropdown } from '../../../common/SelectDropdown'

interface CreateUpdateLocalOfferFormProps {
  onError: () => void
  onSubmit: (
    data: InferType<typeof societyLocalOfferCreateSchema>
  ) => Promise<void>
  onClose: () => void
  loading: boolean
  localOffer?: SnapshotOut<typeof LocalOfferModel>
}

export const CreateUpdateLocalOfferForm = observer(
  ({
    localOffer,
    loading,
    onError,
    onSubmit,
    onClose,
  }: CreateUpdateLocalOfferFormProps): JSX.Element => {
    const { mediaStore } = useStores()
    const [uploadingPicture, setUploadingPicture] = useState(false)
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { getErrorMessage } = useFormErrorMessage()

    const updateMode = !!localOffer

    const getDefaultValues = useCallback((): InferType<
      typeof societyLocalOfferCreateSchema
    > => {
      return {
        discountHeader: updateMode ? localOffer?.discountHeader || '' : '',
        companyName: updateMode ? localOffer?.companyName || '' : '',
        bodyText: updateMode ? localOffer?.bodyText || '' : '',
        phoneNumber: updateMode
          ? sanitizeMobileNumber(localOffer?.phoneNumber)
          : '',
        email: updateMode ? localOffer?.email || '' : '',
        website: updateMode ? localOffer?.website || '' : '',
        coverPhotoId: updateMode ? localOffer?.coverPhotoId : undefined,
        societyId: updateMode
          ? localOffer?.societyId || society._id
          : society._id,
        notificationSetting: updateMode
          ? localOffer?.notificationSetting ||
            NOTIFICATION_SETTINGS_VALUE.NOTIFICATION_AND_FEED
          : NOTIFICATION_SETTINGS_VALUE.NOTIFICATION_AND_FEED,
      }
    }, [society, localOffer, updateMode])

    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyLocalOfferCreateSchema),
      defaultValues: getDefaultValues(),
    })

    const uploadImage = async (base64: string): Promise<void> => {
      setUploadingPicture(true)

      try {
        const mediaId = await mediaStore.createMedia('image', base64)
        if (!mediaId) {
          throw new Error('no mediaId returned by createMedia')
        }

        setValue('coverPhotoId', mediaId, {
          shouldValidate: true,
          shouldDirty: true,
        })
      } catch (error) {
        setToastNotification(
          ToastType.DANGER,
          translate('flashMessage.somethingWentWrongError')
        )
      }

      setUploadingPicture(false)
    }

    return (
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className="flex flex-col gap-3"
      >
        <Controller
          control={control}
          render={({ field: { value } }) => {
            if (value) {
              return (
                <FixedHeightMediaImageWithBackground
                  wrapperClassNames="h-40 md:h-80"
                  mediaId={value}
                  onDeleteClick={() =>
                    setValue('coverPhotoId', '', {
                      shouldValidate: true,
                      shouldDirty: true,
                    })
                  }
                />
              )
            }

            return (
              <div className="mb-1">
                <DropZone
                  loading={uploadingPicture}
                  onUpload={(status, file) => {
                    if (status === 'accepted') {
                      uploadImage(file.base64)
                    } else {
                      setToastNotification(
                        ToastType.DANGER,
                        translate('flashMessage.somethingWentWrongError')
                      )
                    }
                  }}
                  variant={DropZoneVariant.MEDIA}
                />
              </div>
            )
          }}
          name="coverPhotoId"
        />
        {!updateMode && (
          <Controller
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <FormControl
                label={translate('common.notificationSettings.label')}
                name={name}
                error={
                  errors.notificationSetting &&
                  getErrorMessage(errors.notificationSetting)
                }
              >
                <SelectDropdown
                  value={value}
                  onChange={onChange}
                  options={notificationSettingsOptions}
                  error={!!errors.notificationSetting}
                />
              </FormControl>
            )}
            name="notificationSetting"
          />
        )}
        <Controller
          control={control}
          name="companyName"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('localOffersView.form.labels.companyName')}
              error={errors.companyName && getErrorMessage(errors.companyName)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.companyName}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="discountHeader"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('localOffersView.form.labels.benefit')}
              error={
                errors.discountHeader && getErrorMessage(errors.discountHeader)
              }
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.discountHeader}
                placeholder={translate(
                  'localOffersView.form.placeholders.discount'
                )}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="bodyText"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('localOffersView.form.labels.body')}
              name={name}
              error={errors.bodyText && getErrorMessage(errors.bodyText)}
            >
              <TextArea
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.bodyText}
              />
            </FormControl>
          )}
        />
        <div className="mt-4 border-b" />
        <p style={theme.textVariants.lead} className="mt-1 mb-2">
          {translate('localOffersView.form.headers.contactDetails')}
        </p>
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('common.contact.phoneNumber')}
              error={errors.phoneNumber && getErrorMessage(errors.phoneNumber)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.phoneNumber}
                type="tel"
                // TODO: add support to display this textLeft="SE +46"
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="email"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('common.contact.email')}
              error={errors.email && getErrorMessage(errors.email)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.email}
                type="email"
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="website"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('common.contact.website')}
              error={errors.website && getErrorMessage(errors.website)}
              name={name}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.website}
                type="url"
              />
            </FormControl>
          )}
        />
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-4">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
          />
          <Button
            disabled={!isValid || loading}
            label={
              updateMode
                ? translate('common.actions.save')
                : translate('common.actions.create')
            }
            variant={ButtonVariant.PRIMARY}
            type="submit"
          />
        </div>
      </form>
    )
  }
)
