/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree'
import { roleTypes, timestampTypes } from '../types/common'

export const CommentModel = types
  .model('Comment', {
    _id: types.identifier,
    author: types.string,
    ...timestampTypes,
    likes: types.array(types.string),
    message: types.string,
    postId: types.string,
    role: roleTypes,
  })
  .actions((self) => ({
    toggleLike(userId: string, like: boolean) {
      if (like && !self.likes.includes(userId)) {
        self.likes.push(userId)
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        self.likes = self.likes.filter((_like) => _like !== userId) // TODO: Types?
      }
    },
  }))
