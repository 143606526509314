import { AxiosResponse } from 'axios'
import { createUrl } from './helpers'
import axiosAuth from './config'
import { NSettings } from '../interfaces/services/settings.interfaces'

export const getAll = (): Promise<
  AxiosResponse<NSettings.NGetAll.IResponse>
> => {
  return axiosAuth.get<NSettings.NGetAll.IResponse>(
    createUrl(`/settings/get-all`)
  )
}
