import React from 'react'
import { Icon, IconChoices } from '../../../common/Icon'
import { Image } from '../../../common/Image'
import { Spinner } from '../../../common/Spinner'

interface ThumbnailImageIconProps {
  icon?: IconChoices
  uri?: string
  loading?: boolean
}

export const ThumbnailImageIcon = ({
  icon = IconChoices.DOCUMENT,
  uri,
  loading,
}: ThumbnailImageIconProps): JSX.Element => {
  if (loading) {
    return (
      <div className="flex h-14 w-14 items-center justify-center rounded-full">
        <Spinner />
      </div>
    )
  }

  if (uri) {
    return <Image url={uri} imageClassName="rounded" />
  }

  return <Icon icon={icon} size={36} />
}
