import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { InferType } from 'yup'
import { useAtom } from 'jotai'
import { RequestModel } from '../../../../state/models/request'
import { RequestForm } from './RequestForm'
import { societyRequestSchema } from '../../../../forms/schemas/society_request'
import { useStores } from '../../../../hooks/useStores'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import {
  SocietyNavigationState,
  societyNavigationStateAtom,
} from '../../../../views/society/atom'

enum Action {
  APPROVE = 'approve',
  DENY = 'deny',
}

interface RequestAccordionSectionContentProps {
  request: SnapshotOut<typeof RequestModel>
  className?: string
}

export const RequestAccordionSectionContent = observer(
  ({
    request,
    className,
  }: RequestAccordionSectionContentProps): JSX.Element | null => {
    const { requestStore, unitStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()
    const { translate } = useAppTranslation()
    const [, setSocietyNavigationState] = useAtom(societyNavigationStateAtom)

    const showSuccessMessage = (action: Action): void => {
      setToastNotification(
        ToastType.SUCCESS,
        action === Action.APPROVE
          ? translate('registerRequest.flashMessage.approveRequestSuccess')
          : translate('registerRequest.flashMessage.denyRequestSuccess')
      )
    }

    const showErrorMessage = (action: Action): void => {
      setToastNotification(
        ToastType.DANGER,
        action === Action.APPROVE
          ? translate('registerRequest.flashMessage.approveRequestFailure')
          : translate('registerRequest.flashMessage.denyRequestFailure')
      )
    }

    const onError = (): void => {
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const onApprove = async (
      data: InferType<typeof societyRequestSchema>
    ): Promise<void> => {
      const status = await requestStore.acceptRequest(
        request._id,
        data.role,
        data.entranceId,
        data.unitId
      )
      if (status) {
        unitStore.getUnitsPerSociety(society._id)
        showSuccessMessage(Action.APPROVE)
      } else {
        showErrorMessage(Action.APPROVE)
      }
      setSocietyNavigationState(SocietyNavigationState.None)
    }

    const onDeny = async (): Promise<void> => {
      const status = await requestStore.denyRequest(request._id)
      if (status) {
        showSuccessMessage(Action.DENY)
      } else {
        showErrorMessage(Action.DENY)
      }
      setSocietyNavigationState(SocietyNavigationState.None)
    }

    const loading =
      requestStore.denyingRequest === 'pending' ||
      requestStore.acceptingRequest === 'pending'

    return (
      <div className={`mb-4 ${className ?? ''}`}>
        <RequestForm
          loading={loading}
          request={request}
          onError={onError}
          onApprove={onApprove}
          onDeny={onDeny}
        />
      </div>
    )
  }
)
